import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import search from 'assets/images/Icon ionic-ios-search.svg';
import closeIcon from 'assets/images/close-01.svg';
import Pending from 'assets/images/wall-clock.svg';
import { Link } from 'react-router-dom';
import Check from 'assets/images/check (3).svg';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import { CancelRounded } from '@material-ui/icons';

import {
  SERVER,
  getCatalogDetails,
  getSchemesOnStatus,
  get_schemes,
  setLocaleWithFallback,
} from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import { LinearProgress, Pagination, Stack } from '@mui/material';
import PaginationFunction from './Pagination';
import moment from 'moment';
import { showToast } from 'utils/tools';
const { Row, Col } = require('react-bootstrap');

const BenificiarySchemesNew = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  console.log('userrrrrrrrrrrrrrr ', users);

  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    activestep: 1,
    percentComplete: users.profile_details.percentComplete,
    editmode: users.profile_details.editmode,
    statusActive: 'active',
    statusActiveLi: '',
    circleActiveLi: null,
    schemeID: null,
    appli_ref_no: null,
    start_date: null,
    end_date: null,
    dateSelected: false,
    schemesSelected: null,
    mobile_no: null,
  });

  const [getUserMappedToCircle, setgetUserMappedToCircle] = useState(null);
  const [anchorElschemes, setAnchorElschemes] = useState(null);
  const [appNoError, setAppNoError] = useState('');
  const [mobError, setMobError] = useState('');
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noResults, setNoResults] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [fetchSchemes, setFetchSchemes] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [query, setQuery] = useState(null);
  const PER_PAGE = 10;

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    console.log(startDate, '------------', endDate);
    if (startDate !== null) {
      setAllValues({
        ...allValues,
        start_date: new Date(startDate).toISOString(),
        dateSelected: true,
      });

      console.log('start_date_one', allValues.start_date);
    }
  }, [startDate]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))

    if (endDate !== null) {
      let tempDate = endDate;
      setAllValues({
        ...allValues,
        end_date: new Date(tempDate).toISOString(),
        dateSelected: true,
      });

      console.log('start_date_one', allValues.end_date);
    }
  }, [endDate]);

  const handleChange = (event) => {
    if (event.target.name === 'start_data') {
      setAllValues({
        ...allValues,
        [event.target.name]: new Date(event.target.value),
        dateSelected: true,
      });
    } else {
      setAllValues({
        ...allValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleClosekboc = (event, data) => {
    if (data === 'schemes') {
      setAnchorElschemes(null);
    }
  };

  const onSelectClickHandlerkboc1 = (event, data) => {
    setAnchorElschemes(event.currentTarget);
  };

  const onSelectSchemesHandler = (e, data, row) => {
    if (data !== undefined && data !== null) {
      setAllValues({
        ...allValues,
        schemesSelected: data.id,
      });
    }
    setAnchorElschemes(null);
  };

  const onSubmit = async (e) => {
    setSelectedStatus(allValues.statusActiveLi);
    let status = users.catalog_details.find((val) =>
      val.description === allValues.statusActiveLi ? val.value_id : null,
    );

    if (!status?.value_id) {
      showToast('ERROR', 'Please Select All The Fields');
      return;
    }
    setNoResults(false);
    setLoading(true);
    let dataToSubmit = {
      board_id: users.getDeptUserRole.data[0].board_id,
      circle_id: allValues.circleActiveLi,
      role_id: users.getDeptUserRole.data[0].role_id,
      catalog_value_approval_status: status?.value_id,
      department_user_id: users.getDeptUserRole.data[0].department_user_id,
      scheme_id: allValues.schemesSelected,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      acck_no: allValues.appli_ref_no,
      mobile_no: allValues.mob_no,
    };
    console.log('onsubmit----->', dataToSubmit);

    axios
      .post(`${SERVER}/schemes/get_schemes_on_status`, dataToSubmit, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        console.log(data, 'SchemesData:');

        const updatedArray =
          data.recordsets[0] &&
          data.recordsets[0].map((data) => ({
            ...data,
            // gsc_no: data.gsc_no ? data.gsc_no : 'N/A',
            gsc_no: data.gsc_no ?? 'N/A',
          }));
        setFetchSchemes(updatedArray);
      })
      .catch((err) => {
        console.log(err);
      });

    await dispatch(getSchemesOnStatus(dataToSubmit));

    setLoading(false);
    setNoResults(true);
  };

  useEffect(() => {
    if (query) {
      const keys = ['labour_name', 'registration_code', 'gsc_no'];
      const result = fetchSchemes.filter((item) =>
        keys.some((key) => item[key].includes(query)),
      );
      console.log(result, 'RESULT:');
      setFilteredData(result);
    }
  }, [query, fetchSchemes]);

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: '350px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  // const data =
  //   users &&
  //   users.getSchemesOnStatus &&
  //   users.getSchemesOnStatus.data &&
  //   users.getSchemesOnStatus.data.recordsets &&
  //   users.getSchemesOnStatus.data.recordsets[0];
  // console.log(data, 'DATASCHEMES');

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : fetchSchemes && Math.ceil(fetchSchemes.length / PER_PAGE);
  // console.log(count, 'count');
  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(fetchSchemes || [], PER_PAGE);

  // console.log(_DATA, 'PAGIN');
  // console.log(_DATA.maxPage, 'maxPage');

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    users.getSchemesOnStatus = null;

    let dataForCatalog = { board_id: 1, catalog_name: 'Registration Status' };
    dispatch(getCatalogDetails(dataForCatalog));

    let datatoSubmit = users.getDeptUserRole.data[0].board_id;
    dispatch(get_schemes(datatoSubmit));

    if (
      users.getDeptUserRole !== null &&
      users.getDeptUserRole.data !== undefined &&
      users.getDeptUserRole.data.length > 0
    ) {
      let data = JSON.stringify({
        board_id: users.getDeptUserRole.data[0].board_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
      });
      var config = {
        method: 'post',
        url: SERVER + '/config/circle/get_user_mapped_to_circle',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      console.log('USerData', data);
      axios(config)
        .then(function (response) {
          console.log(
            'getUserMappedToCircle response: ' +
              JSON.stringify(response.data, undefined, 2),
          );

          setgetUserMappedToCircle(response.data.data);
        })
        .catch(function (error) {
          console.error('getUserMappedToCircle error: ' + error);
        });
    }
  }, []);

  return (
    <Row className="kbocwwb-page">
      <Col xs={12}>
        <p className="title">Beneficiaries Schemes Details</p>
      </Col>
      <Col xs={2} className="benificiaries-col ">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <Select
            style={{ width: '100%', height: '30px' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.circleActiveLi}
            name="circleActiveLi"
            displayEmpty
            onChange={handleChange}
          >
            <MenuItem value={'' || null}>
              <ListItemText primary="-Circle-" />
            </MenuItem>
            {getUserMappedToCircle !== null &&
              getUserMappedToCircle.length > 0 &&
              getUserMappedToCircle.map((row) => (
                <MenuItem value={row.circle_id}>
                  <ListItemText primary={row.circle_name} />
                </MenuItem>
              ))}
          </Select>
        </div>
      </Col>

      <Col xs={2} className="benificiaries-col">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <Select
            style={{ width: '100%', height: '30px' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.statusActiveLi}
            name="statusActiveLi"
            displayEmpty
            onChange={handleChange}
          >
            <MenuItem value="">
              <ListItemText primary="-Status-" />
            </MenuItem>
            <MenuItem value="Pending">
              <ListItemText primary="Pending" />
            </MenuItem>
            <MenuItem value="Approved">
              <ListItemText primary="Approved" />
            </MenuItem>
            <MenuItem value="Rejected">
              <ListItemText primary="Rejected" />
            </MenuItem>
            {users.getDeptUserRole.data[0].role_id === 1 ? (
              <MenuItem value="Transferred Scheme Application">
                <ListItemText primary="Transferred Scheme Application" />
              </MenuItem>
            ) : null}
            <MenuItem value="Forwarded">
              <ListItemText primary="Forwarded" />
            </MenuItem>
          </Select>
        </div>
      </Col>

      <Col xs={2} className="benificiaries-col ">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className="datepicker"
              format="DD/MM/YYYY"
              value={allValues.start_date}
              placeholder="Start Date"
              onChange={(data) => setstartDate(data)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Col>

      <Col xs={2} className="benificiaries-col ">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className="datepicker"
              format="DD/MM/YYYY"
              value={allValues.end_date}
              onChange={(data) => setendDate(data)}
              placeholder="End Date"
            />
          </MuiPickersUtilsProvider>
        </div>
      </Col>

      <Col xs={3} className="kbocwwb-col">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className={`kbocwwb-col-div hvr-icon-hang ${allValues.statusActive}`}
          onClick={(e) => onSelectClickHandlerkboc1(e, 'schemes')}
          style={{ height: '50px' }}
        >
          <p>Schemes</p>
          <img alt="..." className="hvr-icon" />
        </div>
        <StyledMenu
          id="customized-menu"
          className="customized-menu"
          anchorEl={anchorElschemes}
          keepMounted
          open={Boolean(anchorElschemes)}
          onClose={(e) => handleClosekboc(e, 'schemes')}
        >
          {users?.get_schemes?.data &&
            users.get_schemes.data.map((row) => (
              <MenuItem
                onClick={(e) => onSelectSchemesHandler(e, row)}
                className={allValues.schemesSelected === row ? 'active' : null}
              >
                <ListItemText primary={row.name} />
              </MenuItem>
            ))}
        </StyledMenu>
      </Col>

      <Col xs={3} className="benificiaries-col ">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <TextField
            variant="outlined"
            labelId="demo-simple-select-required-label"
            placeholder="App No"
            name="appli_ref_no"
            value={allValues.appli_ref_no}
            onChange={handleChange}
            error={appNoError}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9A-Z]/gi, '');
              if (e.target.value.length === 0) {
                setAppNoError('');
              } else if (e.target.value.length < 18) {
                setAppNoError(
                  'Application Number should contain 18 characters',
                );
              } else {
                setAppNoError('');
              }
            }}
            inputProps={{ minLength: 0, maxLength: 18 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {allValues.appli_ref_no === null ||
                  allValues.appli_ref_no === '' ? (
                    <img alt="..." src={search} className="language-img" />
                  ) : (
                    <img
                      alt="..."
                      src={closeIcon}
                      onClick={handleChange}
                      className="language-img closebtnsearch"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          >
            {' '}
          </TextField>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Typography style={{ color: '#CA0027' }}>{appNoError}</Typography>
        </div>
      </Col>

      <Col xs={3} className="benificiaries-col ">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="benificiaries-col-div hvr-icon-hang"
          style={{ height: '50px' }}
        >
          <TextField
            variant="outlined"
            labelId="demo-simple-select-required-label"
            placeholder="Mobile No"
            name="mob_no"
            value={allValues.mobile_no}
            onChange={handleChange}
            error={mobError}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/gi, '');
              if (e.target.value.length === 0) {
                setMobError('');
              } else if (e.target.value.length < 10) {
                setMobError('Mobile number should contain 10 digits');
              } else {
                setMobError('');
              }
            }}
            inputProps={{ minLength: 0, maxLength: 10 }}
          ></TextField>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Typography style={{ color: '#CA0027' }}>{mobError}</Typography>
        </div>
      </Col>
      <Col xs={2} className="kbocwwb-col">
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={onSubmit}
          className="benificiaries-col-div hvr-icon-hang"
          style={{ backgroundColor: 'red', color: 'white', height: '50px' }}
        >
          <div variant="contained" color="secondary" style={{ color: 'white' }}>
            Search
          </div>
        </div>
      </Col>

      <Col xs={12}>
        {Loading ? (
          <>
            <div>
              <LinearProgress style={{ marginTop: '30px' }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                  marginBottom: '50px',
                }}
              >
                <h1 style={{ marginTop: '24px', fontStyle: 'italic' }}>
                  Loading.....
                </h1>
              </div>
            </div>
          </>
        ) : (
          count > 1 && (
            <div
              className="footer-buttons "
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={2} className="p-2 pagination_noti">
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          )
        )}

        <TableContainer
        // component={Paper}
        >
          <Table
            className="mt-4"
            aria-label="simple table"
            style={{ border: '0.5px solid #D9D4D4' }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Application Date
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Applicant Name
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Schemes
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  View
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Sakala Number
                </TableCell>
                <TableCell style={{ color: '#4169e1' }} align="center">
                  Sakala Timeline
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Loading ? (
                <></>
              ) : (
                <>
                  {
                    {
                      Pending: (
                        <>
                          {users.getSchemesOnStatus !== null &&
                          users.getSchemesOnStatus !== 'Unauthorized' &&
                          users.getSchemesOnStatus !== 'Network Error' &&
                          users.getSchemesOnStatus.error === undefined ? (
                            // &&
                            // users.getLabourStatusDetails !== undefined &&
                            // users.getLabourStatusDetails !== null &&
                            // users.getLabourStatusDetails.length > 0
                            <>
                              {_DATA.currentData() &&
                                _DATA.currentData().map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(row.applied_date).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.labour_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.scheme_name}
                                    </TableCell>

                                    <TableCell align="center">
                                      <img
                                        alt="..."
                                        src={Pending}
                                        className="mr-2"
                                        style={{ marginRight: '8px' }}
                                      />
                                      Pending
                                    </TableCell>

                                    <TableCell align="center">
                                      <Link
                                        to={{
                                          pathname: '/admin/scheme-details',
                                          userid: row.labour_user_id,
                                          showbuttons: true,
                                          scheme_avail_id: row.scheme_avail_id,
                                          schemeDetails: row,
                                        }}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>

                                    <TableCell align="center">
                                      <div className="d-flex justify-content-center align-items-center align-self-center">
                                        {row.sla_days} days left for Approval
                                        <img
                                          className="img-sakala"
                                          alt="..."
                                          src={Sakala}
                                        />
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ) : // <Box sx={{ width: '100%' }}>
                          //     <LinearProgress />
                          // </Box>
                          null}
                        </>
                      ),
                      Approved: (
                        <>
                          {users.getSchemesOnStatus !== null &&
                          users.getSchemesOnStatus !== 'Unauthorized' &&
                          users.getSchemesOnStatus !== 'Network Error' &&
                          users.getSchemesOnStatus.error === undefined ? (
                            // &&
                            // users.getLabourStatusDetails !== undefined &&
                            // users.getLabourStatusDetails !== null &&
                            // users.getLabourStatusDetails.length > 0
                            <>
                              {users.getSchemesOnStatus !== undefined &&
                                users.getSchemesOnStatus.data !== undefined &&
                                users.getSchemesOnStatus.data &&
                                _DATA.currentData() &&
                                _DATA.currentData().map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(row.applied_date).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.labour_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.scheme_name}
                                    </TableCell>

                                    <TableCell align="center">
                                      <img
                                        alt="..."
                                        src={Check}
                                        className="mr-2"
                                        style={{ marginRight: '8px' }}
                                      />
                                      Approved
                                    </TableCell>

                                    <TableCell align="center">
                                      <Link
                                        to={{
                                          pathname: '/admin/scheme-details',
                                          userid: row.labour_user_id,
                                          showbuttons: false,
                                          scheme_avail_id: row.scheme_avail_id,
                                          schemeDetails: row,
                                        }}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {'N/A'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ) : null}
                        </>
                      ),
                      Forwarded: (
                        <>
                          {users.getSchemesOnStatus !== null &&
                          users.getSchemesOnStatus !== 'Unauthorized' &&
                          users.getSchemesOnStatus !== 'Network Error' &&
                          users.getSchemesOnStatus.error === undefined ? (
                            // &&
                            // users.getLabourStatusDetails !== undefined &&
                            // users.getLabourStatusDetails !== null &&
                            // users.getLabourStatusDetails.length > 0
                            <>
                              {users.getSchemesOnStatus !== undefined &&
                                users.getSchemesOnStatus.data !== undefined &&
                                users.getSchemesOnStatus.data &&
                                _DATA.currentData() &&
                                _DATA.currentData().map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(row.applied_date).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.labour_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.scheme_name}
                                    </TableCell>
                                    {users &&
                                      (users.getDeptUserRole.data[0]
                                        .role_name === 'Labour Officer' ||
                                        users.getDeptUserRole.data[0]
                                          .role_name ===
                                          'Assistant Labour Commissioner') && (
                                        <TableCell align="center">
                                          <img
                                            alt="..."
                                            src={Pending}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          Pending
                                        </TableCell>
                                      )}
                                    {users &&
                                      users.getDeptUserRole.data[0]
                                        .role_name === 'Labour Inspector' && (
                                        <TableCell align="center">
                                          <img
                                            alt="..."
                                            src={Check}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          Forwarded
                                        </TableCell>
                                      )}
                                    <TableCell align="center">
                                      <Link
                                        to={{
                                          pathname: '/admin/scheme-details',
                                          userid: row.labour_user_id,
                                          showbuttons: true,
                                          scheme_avail_id: row.scheme_avail_id,
                                          schemeDetails: row,
                                        }}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {'N/A'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ) : null}
                        </>
                      ),
                      Rejected: (
                        <>
                          {users.getSchemesOnStatus !== null &&
                          users.getSchemesOnStatus !== 'Unauthorized' &&
                          users.getSchemesOnStatus !== 'Network Error' &&
                          users.getSchemesOnStatus.error === undefined ? (
                            // &&
                            // users.getLabourStatusDetails !== undefined &&
                            // users.getLabourStatusDetails !== null &&
                            // users.getLabourStatusDetails.length > 0
                            <>
                              {users.getSchemesOnStatus !== undefined &&
                                users.getSchemesOnStatus.data !== undefined &&
                                users.getSchemesOnStatus.data &&
                                _DATA.currentData() &&
                                _DATA.currentData().map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(row.applied_date).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.labour_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.scheme_name}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ color: '#DB0909' }}
                                    >
                                      <CancelRounded
                                        style={{
                                          color: '#DB0909',
                                          marginRight: '8px',
                                        }}
                                        className="mr-2"
                                      />
                                      Rejected
                                    </TableCell>
                                    <TableCell align="center">
                                      <Link
                                        to={{
                                          pathname: '/admin/scheme-details',
                                          userid: row.labour_user_id,
                                          showbuttons: false,
                                          scheme_avail_id: row.scheme_avail_id,
                                          schemeDetails: row,
                                        }}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {'N/A'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ) : null}
                        </>
                      ),
                      'Seek Clarification': (
                        <>
                          {/* {
                                    users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                    <>
                                    {users.getLabourStatusDetails.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="center">{row.labour_full_name}</TableCell>
                                            <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                            <TableCell align="center">
                                                <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/>Seek Clarification</TableCell>
                                            <TableCell align="center">
                                                <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>Details</TableCell>
                                        </TableRow>
                                    ))}
                                    </>

                                    :

                                    <p>No 'Seek Clarification' applications</p>
                                } */}
                        </>
                      ),
                      'Transferred Scheme Application': (
                        <>
                          {users.getSchemesOnStatus !== null &&
                          users.getSchemesOnStatus !== 'Unauthorized' &&
                          users.getSchemesOnStatus !== 'Network Error' &&
                          users.getSchemesOnStatus.error === undefined ? (
                            // &&
                            // users.getLabourStatusDetails !== undefined &&
                            // users.getLabourStatusDetails !== null &&
                            // users.getLabourStatusDetails.length > 0
                            <>
                              {users.getSchemesOnStatus !== undefined &&
                                users.getSchemesOnStatus.data !== undefined &&
                                users.getSchemesOnStatus.data &&
                                _DATA.currentData() &&
                                _DATA.currentData().map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(row.applied_date).format(
                                        'DD/MM/YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.labour_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.scheme_name}
                                    </TableCell>

                                    <TableCell align="center">
                                      <img
                                        alt="..."
                                        src={Pending}
                                        className="mr-2"
                                        style={{ marginRight: '8px' }}
                                      />
                                      Pending
                                    </TableCell>

                                    <TableCell align="center">
                                      <Link
                                        to={{
                                          pathname: '/admin/scheme-details',
                                          userid: row.labour_user_id,
                                          showbuttons: true,
                                          scheme_avail_id: row.scheme_avail_id,
                                          schemeDetails: row,
                                        }}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.sla_days} days left for Approval
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ) : null}
                        </>
                      ),
                    }[selectedStatus]
                  }
                </>
              )}

              {noResults && count === 0 && (
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    fontStyle: 'italic',
                    color: 'red',
                  }}
                >
                  No Data Found
                </p>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export default BenificiarySchemesNew;
