import React from 'react';
import Paper from '@mui/material/Paper';
import { Translate } from 'react-redux-i18n';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  TextareaAutosize,
} from '@material-ui/core';
import { Required } from 'utils/tools';
import { Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FolderIcon from '@mui/icons-material/Folder';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
const BankDetails = ({
  bankDetails,
  handleBank,
  bankPassbookDoc,
  bankPassbookRef,
  nomineePassbookDoc,
  nomineePassbookRef,
  handleFileUpload,
  handleRemoveFile,
  addNomineeDetails,
  handleBankCheckBox,
  removeBankNomineeDetails,
  handleVerfiyIFSC,
  resetIFSCCODE,
}) => {
  return (
    <>
      <Paper
        elevation={3}
        style={{ margin: '20px', borderRadius: '10px', padding: '20px 0' }}
      >
        <Container>
          <Row className="g-3 px-3 form-row py-5">
            <Typography
              variant="h5"
              color={'GrayText'}
              className="text-left d-flex justify-content-start align-items-center my-2"
            >
              <AccountBalanceIcon
                style={{ width: '30px', height: '30px' }}
                sx={{
                  color: 'white',

                  backgroundColor: 'GrayText',
                  borderRadius: '100%',
                  padding: '5px',
                }}
              />{' '}
              <span className="px-2" style={{ fontWeight: 'bolder' }}>
                Bank Details
              </span>
            </Typography>
            {bankDetails &&
              bankDetails.length &&
              bankDetails.map((row, index) => (
                <>
                  {index === 1 && (
                    <Typography
                      variant="h5"
                      color={'GrayText'}
                      className="text-left d-flex justify-content-start align-items-center my-2"
                    >
                      <AccountBalanceIcon
                        style={{ width: '30px', height: '30px' }}
                        sx={{
                          color: 'white',

                          backgroundColor: 'GrayText',
                          borderRadius: '100%',
                          padding: '5px',
                        }}
                      />{' '}
                      <span className="px-2" style={{ fontWeight: 'bolder' }}>
                        Nominee Bank Details
                      </span>
                    </Typography>
                  )}
                  <Col md={6}>
                    <Required title={<Translate value="Account number" />} />
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        variant="outlined"
                        name="bankAccountNumber"
                        placeholder="Enter account number"
                        onChange={(ev) => handleBank(index, ev)}
                        value={row.bankAccountNumber}
                        // inputProps={{ minLength: 0, maxLength: 15 }}
                      />
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <Required title={<Translate value="IFSC CODE" />} />
                    <FormControl variant="outlined" fullWidth className="w-75">
                      <TextField
                        variant="outlined"
                        name="bankIFSCCODE"
                        placeholder="Enter IFSC CODE"
                        onChange={(ev) => handleBank(index, ev)}
                        value={row.bankIFSCCODE}
                        // inputProps={{ minLength: 0, maxLength: 15 }}
                      />
                    </FormControl>
                    {row.isVerified ? (
                      <>
                        <IconButton disabled>
                          <VerifiedIcon
                            style={{ width: '30px', height: '30px' }}
                            sx={{ color: 'green', width: '30' }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => resetIFSCCODE(index, row.bankIFSCCODE)}
                        >
                          <RestartAltIcon
                            sx={{ color: 'red' }}
                            style={{ width: '30px', height: '30px' }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <Button
                        className="mx-3 py-2 px-3 my-1"
                        onClick={() =>
                          handleVerfiyIFSC(index, row.bankIFSCCODE)
                        }
                      >
                        Verify
                      </Button>
                    )}
                  </Col>
                  <Col md={6}>
                    <Required title={<Translate value="Bank name" />} />
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        variant="outlined"
                        name="bankName"
                        placeholder="Enter bank name"
                        onChange={(ev) => handleBank(index, ev)}
                        value={row.bankName}
                        // inputProps={{ minLength: 0, maxLength: 15 }}
                      />
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <Required title={<Translate value="Bank branch" />} />
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        variant="outlined"
                        name="bankBranchName"
                        placeholder="Enter bank branch"
                        onChange={(ev) => handleBank(index, ev)}
                        value={row.bankBranchName}
                        // inputProps={{ minLength: 0, maxLength: 15 }}
                      />
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <Required title={<Translate value="Bank Address" />} />
                    <>
                      <TextareaAutosize
                        className="w-100"
                        variant="outlined"
                        name="bankAddress"
                        placeholder="Enter bank address"
                        onChange={(ev) => handleBank(index, ev)}
                        value={row.bankAddress}
                        rows={3}
                        // inputProps={{ minLength: 0, maxLength: 15 }}
                      />
                    </>
                  </Col>
                  <Col md={6}>
                    {index === 0 && (
                      <div className="">
                        <Required title={<Translate value="Bank Passbook" />} />
                        {bankPassbookDoc && bankPassbookDoc.length > 0 ? (
                          bankPassbookDoc.map((file, index) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className="w-75"
                            >
                              <TextField
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                // name="mail_id"
                                disabled
                                value={file.name}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveFile(
                                              index,
                                              'bankPassbook',
                                            )
                                          }
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        ) : (
                          <FormControl fullWidth className="w-75">
                            <TextField
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              disabled
                            />
                          </FormControl>
                        )}

                        <input
                          type="file"
                          ref={bankPassbookRef}
                          onChange={handleFileUpload}
                          multiple
                          hidden
                          name="bankPassbook"
                        />
                        <Button
                          className="mx-3 py-2"
                          onClick={() => bankPassbookRef.current.click()}
                        >
                          <FolderIcon />
                          Browse
                        </Button>
                      </div>
                    )}
                    {index === 1 && (
                      <div className="">
                        <Required
                          title={<Translate value="Nominee Bank Passbook" />}
                        />
                        {nomineePassbookDoc && nomineePassbookDoc.length > 0 ? (
                          nomineePassbookDoc.map((file, index) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className="w-75"
                            >
                              <TextField
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                // name="mail_id"
                                disabled
                                value={file.name}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveFile(
                                              index,
                                              'nomineePassbook',
                                            )
                                          }
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        ) : (
                          <FormControl fullWidth className="w-75">
                            <TextField
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              disabled
                            />
                          </FormControl>
                        )}

                        <input
                          type="file"
                          ref={nomineePassbookRef}
                          onChange={handleFileUpload}
                          multiple
                          hidden
                          name="nomineePassbook"
                        />
                        <Button
                          className="mx-3 py-2"
                          onClick={() => nomineePassbookRef.current.click()}
                        >
                          <FolderIcon />
                          Browse
                        </Button>
                      </div>
                    )}
                  </Col>
                </>
              ))}
            {bankDetails && bankDetails.length > 1 ? (
              <Typography
                variant="button"
                color={'GrayText'}
                className="text-left d-flex justify-content-start align-items-center my-3"
              >
                <span
                  className=""
                  style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                  onClick={() => removeBankNomineeDetails(1)}
                >
                  <RemoveCircleIcon
                    style={{ width: '30px', height: '30px' }}
                    sx={{
                      color: 'red',

                      borderRadius: '100%',
                    }}
                  />{' '}
                  Remove Nominee Bank Details
                </span>
              </Typography>
            ) : (
              <Typography
                variant="button"
                color={'GrayText'}
                className="text-left d-flex justify-content-start align-items-center my-3"
              >
                <span
                  className=""
                  style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                  onClick={() => addNomineeDetails('')}
                >
                  <AddCircleIcon
                    style={{ width: '30px', height: '30px' }}
                    sx={{
                      color: 'green',

                      borderRadius: '100%',
                    }}
                  />{' '}
                  Add Nominee Bank Details
                </span>
              </Typography>
            )}
          </Row>
        </Container>
      </Paper>
    </>
  );
};

export default BankDetails;
