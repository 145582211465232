import {
    GENERATE_OTP,
    LOGIN_USER,
    ERROR_LOGIN,
    ERROR_GENERATE_OTP,
    GET_MINISTRY,
    UPDATE_USER_PROFILE,
    GET_BOARD,
    GENERATE_OTP_REGISTRATION,
    ERROR_GENERATE_OTP_REGISTRATION,
    REGISTER_USER,
    ERROR_REGISTER,
    UPDATE_PERSONAL,
    USER,
    ERROR_GET_USER,
    CATALOG_DETAILS,
    ERROR_CATALOG_DETAILS,
    STATE_DETAILS,
    ERROR_STATE_DETAILS,
    UPLOAD_FILE,
    ERROR_UPLOAD_FILE,
    ADD_LABOUR_PERSONAL,
    ERROR_ADD_LABOUR_PERSONAL,
    UPDATE_ADDRESS,
    DISTRICT_DETAILS,
    ERROR_DISTRICT_DETAILS,
    TALUK_DETAILS,
    ERROR_TALUK_DETAILS,
    VILLAGE_DETAILS,
    ERROR_VILLAGE_DETAILS,
    CITY_DETAILS,
    ERROR_CITY_DETAILS,
    DISTRICT_DETAILS2,
    ERROR_DISTRICT_DETAILS2,
    TALUK_DETAILS2,
    ERROR_TALUK_DETAILS2,
    VILLAGE_DETAILS2,
    ERROR_VILLAGE_DETAILS2,
    CITY_DETAILS2,
    ERROR_CITY_DETAILS2,
    ERROR_ADD_LABOUR_ADDRESS,
    ADD_LABOUR_ADDRESS,
    UPDATE_FAMILY,
    ADD_LABOUR_FAMILY,
    ERROR_ADD_LABOUR_FAMILY,
    ADD_LABOUR_BANK,
    ERROR_ADD_LABOUR_BANK,
    ADD_LABOUR_90DAYS,
    ERROR_ADD_LABOUR_90DAYS,
    UPDATE_90DAYS,
    UPDATE_BANK,
    GET_INSPECTOR,
    ERROR_GET_INSPECTOR,
    GET_UNION,
    ERROR_GET_UNION,
    UPDATE_PROFILE,
    GET_APPLICATION_NO,
    ERROR_GET_APPLICATION_NO,
    UPDATE_UPLOAD_FILE,
    UPDATE_Review,
    GET_USER_REGISTRATION_DETAILS,
    ERROR_GET_USER_REGISTRATION_DETAILS,
    GET_FILE,
    ERROR_GET_FILE,
    ERROR_LOGIN_ADMIN,
    LOGIN_ADMIN,
    GET_LABOUR_STATUS,
    ERROR_GET_LABOUR_STATUS,
    GET_RENEWAL_LABOUR_DETAILS,
    ERROR_GET_RENEWAL_LABOUR_DETAILS,
    FINAL_SUBMIT,
    ERROR_FINAL_SUBMIT,
    SAVE_ACK_ID,
    ERROR_SAVE_ACK_ID,
    UPDATE_GET_FILE,
    UPDATE_USER,
    GET_CONFIG_MINISTRY_DETAILS,
    ERROR_GET_CONFIG_MINISTRY_DETAILS,
    ERROR_GET_CONFIG_BOARD_DETAILS,
    GET_CONFIG_BOARD_DETAILS,
    GET_STATE_MAPPING,
    ERROR_GET_STATE_MAPPING,
    ERROR_SEARCH_USER,
    SEARCH_USER,
    GET_SCHEMES,
    ERROR_GET_SCHEMES,
    ERROR_GET_SCHEMES_ON_STATUS,
    GET_SCHEMES_ON_STATUS,
    GET_DEPT_USER_ROLE,
    ERROR_GET_DEPT_USER_ROLE,
    GET_DEPT_USERS,
    ERROR_GET_DEPT_USERS,
    INSERT_DEPT_USER,
    ERROR_INSERT_DEPT_USER,
    UPDATE_ADMIN_DASHBOARD
} from '../types';

export const getDeptUsers = (data) => ({
    type: GET_DEPT_USERS,
    payload: data
})
export const errorGetDeptUsers = (data) => ({
    type: ERROR_GET_DEPT_USERS,
    payload: data
});
export const insertDeptUser = (data) => ({
    type: INSERT_DEPT_USER,
    payload: data
})
export const errorinsertDeptUser = (data) => ({
    type: ERROR_INSERT_DEPT_USER,
    payload: data
});
export const getDeptUserRole = (data) => ({
    type: GET_DEPT_USER_ROLE,
    payload: data
})
export const errorgetDeptUserRole = (data) => ({
    type: ERROR_GET_DEPT_USER_ROLE,
    payload: data
})
export const getSchemesOnStatus = (data) => ({
    type: GET_SCHEMES_ON_STATUS,
    payload: data
})
export const errorgetSchemesOnStatus = (data) => ({
    type: ERROR_GET_SCHEMES_ON_STATUS,
    payload: data
})
export const get_schemes = (data) => ({
    type: GET_SCHEMES,
    payload: data
})
export const errorget_schemes = (data) => ({
    type: ERROR_GET_SCHEMES,
    payload: data
})
export const searchuser = (data) => ({
    type: SEARCH_USER,
    payload: data
})
export const errorsearchuser = (data) => ({
    type: ERROR_SEARCH_USER,
    payload: data
})
export const getStateMapping = (data) => ({
    type: GET_STATE_MAPPING,
    payload: data
})
export const errorgetStateMapping = (data) => ({
    type: ERROR_GET_STATE_MAPPING,
    payload: data
})
export const getConfigBoardDetails = (data) => ({
    type: GET_CONFIG_BOARD_DETAILS,
    payload: data
})
export const errorgetConfigBoardDetails = (data) => ({
    type: ERROR_GET_CONFIG_BOARD_DETAILS,
    payload: data
})
export const getConfigMinistryDetails = (data) => ({
    type: GET_CONFIG_MINISTRY_DETAILS,
    payload: data
})
export const errorgetConfigMinistryDetails = (data) => ({
    type: ERROR_GET_CONFIG_MINISTRY_DETAILS,
    payload: data
})
export const saveAcknowledgementId = (data) => ({
    type: SAVE_ACK_ID,
    payload: data
})
export const errorsaveAcknowledgementId = (data) => ({
    type: ERROR_SAVE_ACK_ID,
    payload: data
})
export const finalSubmit = (data) => ({
    type: FINAL_SUBMIT,
    payload: data
})
export const errorfinalSubmit = (data) => ({
    type: ERROR_FINAL_SUBMIT,
    payload: data
})
export const getLabourStatusDetails = (data) => ({
    type: GET_LABOUR_STATUS,
    payload: data
})
export const errorgetLabourStatusDetails = (data) => ({
    type: ERROR_GET_LABOUR_STATUS,
    payload: data
})
export const getRenewalLabourDetails = (data) => ({
    type: GET_RENEWAL_LABOUR_DETAILS,
    payload: data
})
export const errorgetRenewalLabourDetails = (data) => ({
    type: ERROR_GET_RENEWAL_LABOUR_DETAILS,
    payload: data
})
export const getUserRegistrationDetails = (data) => ({
    type: GET_USER_REGISTRATION_DETAILS,
    payload: data
})
export const errorgetUserRegistrationDetails = (data) => ({
    type: ERROR_GET_USER_REGISTRATION_DETAILS,
    payload: data
})
export const getApplicationNo = (data) => ({
    type: GET_APPLICATION_NO,
    payload: data
})
export const errorgetApplicationNo = (data) => ({
    type: ERROR_GET_APPLICATION_NO,
    payload: data
})
export const getUnionDetails = (data) => ({
    type: GET_UNION,
    payload: data
})
export const errorgetUnionDetails = (data) => ({
    type: ERROR_GET_UNION,
    payload: data
})
export const getInspectorDetails = (data) => ({
    type: GET_INSPECTOR,
    payload: data
})
export const errorgetInspectorDetails = (data) => ({
    type: ERROR_GET_INSPECTOR,
    payload: data
})

export const updateGetFile = (data) => ({
    type: UPDATE_GET_FILE,
    payload: data
})
export const updateProfileData = (data) => ({
    type: UPDATE_PROFILE,
    payload: data
})
export const update90DaysData = (data) => ({
    type: UPDATE_90DAYS,
    payload: data
})

export const updateBankData = (data) => ({
    type: UPDATE_BANK,
    payload: data
})

export const addLabour90DaysDetails = (data) => ({
    type: ADD_LABOUR_90DAYS,
    payload: data
})
export const erroraddLabour90DaysDetails = (data) => ({
    type: ERROR_ADD_LABOUR_90DAYS,
    payload: data
})

export const addLabourBankDetails = (data) => ({
    type: ADD_LABOUR_BANK,
    payload: data
})
export const erroraddLabourBankDetails = (data) => ({
    type: ERROR_ADD_LABOUR_BANK,
    payload: data
})

export const addLabourFamilyDetails = (data) => ({
    type: ADD_LABOUR_FAMILY,
    payload: data
})
export const erroraddLabourFamilyDetails = (data) => ({
    type: ERROR_ADD_LABOUR_FAMILY,
    payload: data
})

export const updateFamilyData = (data) => ({
    type: UPDATE_FAMILY,
    payload: data
})

export const addLabourAddressDetails = (data) => ({
    type: ADD_LABOUR_ADDRESS,
    payload: data
})
export const erroraddLabourAddressDetails = (data) => ({
    type: ERROR_ADD_LABOUR_ADDRESS,
    payload: data
})

export const getVillageDetails2 = (data) => ({
    type: VILLAGE_DETAILS2,
    payload: data
})
export const errorVillageDetails2 = (data) => ({
    type: ERROR_VILLAGE_DETAILS2,
    payload: data
})
export const getVillageDetails = (data) => ({
    type: VILLAGE_DETAILS,
    payload: data
})
export const errorVillageDetails = (data) => ({
    type: ERROR_VILLAGE_DETAILS,
    payload: data
})

export const getCityDetails2 = (data) => ({
    type: CITY_DETAILS2,
    payload: data
})
export const errorCityDetails2 = (data) => ({
    type: ERROR_CITY_DETAILS2,
    payload: data
})

export const getCityDetails = (data) => ({
    type: CITY_DETAILS,
    payload: data
})
export const errorCityDetails = (data) => ({
    type: ERROR_CITY_DETAILS,
    payload: data
})


export const getTalukDetails2 = (data) => ({
    type: TALUK_DETAILS2,
    payload: data
})
export const errorTalukDetails2 = (data) => ({
    type: ERROR_TALUK_DETAILS2,
    payload: data
})

export const getTalukDetails = (data) => ({
    type: TALUK_DETAILS,
    payload: data
})
export const errorTalukDetails = (data) => ({
    type: ERROR_TALUK_DETAILS,
    payload: data
})

export const getDistrictDetails2 = (data) => ({
    type: DISTRICT_DETAILS2,
    payload: data
})
export const errorDistrictDetails2 = (data) => ({
    type: ERROR_DISTRICT_DETAILS2,
    payload: data
})

export const getDistrictDetails = (data) => ({
    type: DISTRICT_DETAILS,
    payload: data
})
export const errorDistrictDetails = (data) => ({
    type: ERROR_DISTRICT_DETAILS,
    payload: data
})

export const updateAddress = (data) => ({
    type: UPDATE_ADDRESS,
    payload: data
})

export const updatePersonal = (data) => ({
    type: UPDATE_PERSONAL,
    payload: data
})
export const updateUploadFile = (data) => ({
    type: UPDATE_UPLOAD_FILE,
    payload: data
})

export const addLabourPersonalDetails = (data) => ({
    type: ADD_LABOUR_PERSONAL,
    payload: data
})
export const errorAddLabourPersonalDetails = (data) => ({
    type: ERROR_ADD_LABOUR_PERSONAL,
    payload: data
})

export const uploadFile = (data) => ({
    type: UPLOAD_FILE,
    payload: data
})
export const errorUploadFile = (data) => ({
    type: ERROR_UPLOAD_FILE,
    payload: data
})

export const getStateDetails = (data) => ({
    type: STATE_DETAILS,
    payload: data
})
export const errorStateDetails = (data) => ({
    type: ERROR_STATE_DETAILS,
    payload: data
})

export const getCatalogDetails = (data) => ({
    type: CATALOG_DETAILS,
    payload: data
})

export const errorCatalogDetails = (data) => ({
    type: ERROR_CATALOG_DETAILS,
    payload: data
});


export const updateReview = (data) => ({
    type: UPDATE_Review,
    payload: data
})

export const updateAdminDashboard = (data) => ({
    type: UPDATE_ADMIN_DASHBOARD,
    payload: data
})

export const registerUser = (data) => ({
    type: REGISTER_USER,
    payload: data
});

export const errorRegister = (data) => ({
    type: ERROR_REGISTER,
    payload: data
});

export const generateOtpRegistration = (data) => ({
    type: GENERATE_OTP_REGISTRATION,
    payload: data
});

export const errorGenerateOtpRegistration = (data) => ({
    type: ERROR_GENERATE_OTP_REGISTRATION,
    payload: data
});

export const getBoardDetails = (data) => ({
    type: GET_BOARD,
    payload: data
});

export const getMinistryDetails = (data) => ({
    type: GET_MINISTRY,
    payload: data
});

export const errorGenerateOtp = (data) => ({
    type: ERROR_GENERATE_OTP,
    payload: data
});


export const errorloginAdmin = (data) => ({
    type: ERROR_LOGIN_ADMIN,
    payload: data
});
export const loginAdmin = (data) => ({
    type: LOGIN_ADMIN,
    payload: data
});


export const errorLogin = (data) => ({
    type: ERROR_LOGIN,
    payload: data
});

export const loginUser = (data) => ({
    type: LOGIN_USER,
    payload: data
});

export const generateOtp = (data) => ({
    type: GENERATE_OTP,
    payload: data
});

export const userUpdateProfile = (data) => ({
    type: UPDATE_USER_PROFILE,
    payload: data
})

export const getFile = (data) => ({
    type: GET_FILE,
    payload: data
});
export const errorgetFile = (data) => ({
    type: ERROR_GET_FILE,
    payload: data
});

export const updateUser = (data) => ({
    type: UPDATE_USER,
    payload: data
});
export const getUser = (data) => ({
    type: USER,
    payload: data
});

export const errorGetUser = (data) => ({
    type: ERROR_GET_USER,
    payload: data
});