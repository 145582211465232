// /* eslint-disable no-throw-literal */

// import uploadFileAPI from 'utils/fileupload';

// export const backlogAccidentDeathFileUpload = async (
//   labourCardDoc,
//   nomineeaadhaarCardDoc,
//   deathcertificateDoc,
//   beneficiaryaadharDoc
// ) => {
//   // Check if all required documents are present
//   if (
//     labourCardDoc &&
//     labourCardDoc.length > 0 &&
//     nomineeaadhaarCardDoc &&
//     nomineeaadhaarCardDoc.length > 0 &&
//     deathcertificateDoc &&
//     deathcertificateDoc.length > 0 &&
//     beneficiaryaadharDoc &&
//     beneficiaryaadharDoc.length > 0
//   ) {
//     const promises1 = [];
//     labourCardDoc.forEach((doc) => {
//       promises1.push(uploadFileAPI(doc));
//     });

//     const promises2 = [];
//     nomineeaadhaarCardDoc.forEach((doc) => {
//       promises2.push(uploadFileAPI(doc));
//     });

//     const promises3 = [];
//     beneficiaryaadharDoc.forEach((doc) => {
//       promises3.push(uploadFileAPI(doc));
//     });

//     const promises4 = [];
//     deathcertificateDoc.forEach((doc) => {
//       promises4.push(uploadFileAPI(doc)); // Fixed: Changed promises3 to promises4
//     });

//     const allPromises = [...promises1, ...promises2, ...promises3, ...promises4];

//     return Promise.all(allPromises)
//       .then((responses) => {
//         const responses1 = responses.slice(0, promises1.length);
//         const responses2 = responses.slice(
//           promises1.length,
//           promises1.length + promises2.length
//         );
//         const responses3 = responses.slice(
//           promises1.length + promises2.length,
//           promises1.length + promises2.length + promises3.length
//         );
//         const responses4 = responses.slice(
//           promises1.length + promises2.length + promises3.length
//         );

//         const labourCardDocResponses = responses1.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const nomineeaadhaarCardDoc = responses2.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const beneficiaryaadharDoc = responses3.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const deathcertificateDoc = responses4.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         return {
//           success: true,
//           labourCardDoc: labourCardDocResponses,
//           nomineeaadhaarCardDoc: nomineeaadhaarCardDoc,
//           beneficiaryaadharDoc: beneficiaryaadharDoc,
//           deathcertificateDoc: deathcertificateDoc,
//         };
//       })
//       .catch((error) => {
//         throw {
//           success: false,
//           msg: 'Error in uploading file! Please try again',
//         };
//       });
//   } else {
//     return null;
//   }
// };


/* eslint-disable no-throw-literal */

import uploadFileAPI from 'utils/fileupload';

export const backlogAccidentFileUpload = async (
  // labourCardDoc,
  // nomineeaadhaarCardDoc,
  // deathcertificateDoc,
  // beneficiaryaadharDoc,
  // fircopyDoc,
  // postmortemDoc,
  // oldLabourCardDoc
  labourCardDoc,
  // nomineeaadhaarCardDoc,
  deathcertificateDoc,
  // beneficiaryaadharDoc,
  fircopyDoc,
  postmortemDoc,
  // oldLabourCardDoc,
  // daysWorkCertDoc,

) => {

  // Check if all required documents are present
  if (
    labourCardDoc?.length > 0 &&
    // nomineeaadhaarCardDoc?.length > 0 &&
    deathcertificateDoc?.length > 0 &&
    // beneficiaryaadharDoc?.length > 0 &&
    fircopyDoc?.length > 0 &&
    postmortemDoc?.length > 0
    // oldLabourCardDoc?.length > 0 &&
    // daysWorkCertDoc?.length > 0
  ) {
    const documentGroups = [
      { docs: labourCardDoc, key: 'labourCardDoc' },
      // { docs: nomineeaadhaarCardDoc, key: 'nomineeaadhaarCardDoc' },
      { docs: deathcertificateDoc, key: 'deathcertificateDoc' },
      // { docs: beneficiaryaadharDoc, key: 'beneficiaryaadharDoc' },
      { docs: fircopyDoc, key: 'fircopyDoc' },
      { docs: postmortemDoc, key: 'postmortemDoc' },
      // { docs: oldLabourCardDoc, key: 'oldLabourCardDoc' },
      // { docs: daysWorkCertDoc, key: 'daysWorkCertDoc' }
    ];


    const allPromises = documentGroups.flatMap(({ docs }) =>
      docs.map((doc) => uploadFileAPI(doc))
    );

    return Promise.all(allPromises)
      .then((responses) => {
        const result = {};
        let startIndex = 0;

        documentGroups.forEach(({ key, docs }) => {
          const groupResponses = responses.slice(startIndex, startIndex + docs.length);
          startIndex += docs.length;

          result[key] = groupResponses.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
            mime_type: doc.image.mimeType,
          }));
        });

        return {
          success: true,
          ...result,
        };
      })
      .catch((error) => {
        throw {
          success: false,
          msg: 'Error in uploading file! Please try again',
        };
      });
  } else {
    return null;
  }
};


/* eslint-disable no-throw-literal */

// import uploadFileAPI from 'utils/fileupload';

// export const backlogAccidentFileUpload = async (
//   labourCardDoc,
//   nomineeaadhaarCardDoc,
//   deathcertificateDoc,
//   beneficiaryaadharDoc,
//   fircopyDoc,
//   postmortemDoc,
//   oldLabourCardDoc,
//   daysWorkCertDoc,
// ) => {
//   // Check if all required documents are present
//   if (
//     labourCardDoc &&
//     labourCardDoc.length > 0 &&
//     nomineeaadhaarCardDoc &&
//     nomineeaadhaarCardDoc.length > 0 &&
//     deathcertificateDoc &&
//     deathcertificateDoc.length > 0 &&
//     beneficiaryaadharDoc &&
//     beneficiaryaadharDoc.length > 0 &&
//     fircopyDoc &&
//     fircopyDoc.length > 0 &&
//     postmortemDoc &&
//     postmortemDoc.length > 0 &&
//     oldLabourCardDoc &&
//     oldLabourCardDoc.length > 0 &&
//     daysWorkCertDoc &&
//     daysWorkCertDoc.length > 0
//   ) {
//     const promises1 = [];
//     labourCardDoc.forEach((doc) => {
//       promises1.push(uploadFileAPI(doc));
//     });

//     const promises2 = [];
//     nomineeaadhaarCardDoc.forEach((doc) => {
//       promises2.push(uploadFileAPI(doc));
//     });

//     const promises3 = [];
//     beneficiaryaadharDoc.forEach((doc) => {
//       promises3.push(uploadFileAPI(doc));
//     });

//     const promises4 = [];
//     deathcertificateDoc.forEach((doc) => {
//       promises4.push(uploadFileAPI(doc));
//     });

//     const promises5 = [];
//     daysWorkCertDoc.forEach((doc) => {
//       promises5.push(uploadFileAPI(doc));
//     });


//     const promises6 = [];
//     oldLabourCardDoc.forEach((doc) => {
//       promises6.push(uploadFileAPI(doc));
//     });


//     const promises7 = [];
//     postmortemDoc.forEach((doc) => {
//       promises7.push(uploadFileAPI(doc));
//     });

//     const promises8 = [];
//     fircopyDoc.forEach((doc) => {
//       promises8.push(uploadFileAPI(doc));
//     });

//     const allPromises = [...promises1, ...promises2, ...promises3, ...promises4, ...promises5, ...promises6, ...promises7, ...promises8];

//     return Promise.all(allPromises)
//       .then((responses) => {
//         const responses1 = responses.slice(0, promises1.length);
//         const responses2 = responses.slice(
//           promises1.length,
//           promises1.length + promises2.length
//         );
//         const responses3 = responses.slice(
//           promises1.length + promises2.length,
//           promises1.length + promises2.length + promises3.length
//         );
//         const responses4 = responses.slice(
//           promises1.length + promises2.length + promises3.length,
//           promises1.length + promises2.length + promises3.length + promises4.length
//         );

//         const responses5 = responses.slice(
//           promises1.length + promises2.length + promises3.length + promises4.length,
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length
//         );

//         const responses6 = responses.slice(
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length,
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length + promises6.length
//         );

//         const responses7 = responses.slice(
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length + promises6.length + promises7.length,
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length + promises6.length + promises7.length
//         );

//         const responses8 = responses.slice(
//           promises1.length + promises2.length + promises3.length + promises4.length + promises5.length + promises6.length + promises7.length + promises8.length
//         );

//         const labourCardDocResponses = responses1.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const nomineeaadhaarCardDoc = responses2.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const beneficiaryaadharDoc = responses3.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const deathcertificateDoc = responses4.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const daysWorkCertDoc = responses5.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const oldLabourCardDoc = responses6.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const postmortemDoc = responses7.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));

//         const fircopyDoc = responses8.map((doc) => ({
//           file_id: doc.image.fileId,
//           file_name: doc.image.originalFileName,
//           mime_type: doc.image.mimeType,
//         }));







//         return {
//           success: true,
//           labourCardDoc: labourCardDocResponses,
//           nomineeaadhaarCardDoc: nomineeaadhaarCardDoc,
//           beneficiaryaadharDoc: beneficiaryaadharDoc,
//           deathcertificateDoc: deathcertificateDoc,
//           fircopyDoc: fircopyDoc,
//           oldLabourCardDoc: oldLabourCardDoc,
//           postmortemDoc: postmortemDoc,
//           daysWorkCertDoc: daysWorkCertDoc
//         };
//       })
//       .catch((error) => {
//         throw {
//           success: false,
//           msg: 'Error in uploading file! Please try again',
//         };
//       });
//   } else {
//     return null;
//   }
// };
