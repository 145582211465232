import React, { useEffect } from 'react'

const MobileVerified = () => {

// const handleClick = () => {
//     window.location.href = '/goback/aadhar-verified'
// }
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     // 👇️ redirects to an external URL
  //     window.location.replace('/');
  //   }, 5000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const handleLoad = () => {
    console.log(window.location.href);
  }

  return (
     <div className="ekyc-verify d-flex justify-content-center">
                                    <h3 onLoad={handleLoad} style = {{marginRight : "20px", marginTop : "10px"}}>Redirecting to App Please wait...</h3>
                                       
                                      {/* <button onLoad={handleLoad}>Go Back</button> */}
                                    </div>
  )
}

export default MobileVerified