import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { SERVER, getDeptUserRole } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import {
  Container,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ReportDashboard from './ReportDashboard';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Col, Row } from 'react-bootstrap';
import {
  ListItemText,
  withStyles,
  Menu,
  CircularProgress,
  TextField,
  InputAdornment,
  Backdrop,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import CountReport from './CountReports';
import { CSVLink } from 'react-csv';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

import { useRef } from 'react';

//import Excel from "../../assets/images/excel.png";
export default function CountRegistrationReport() {
  const [fetchRegistrationDistrictReport, setFetchRegistrationDistrictReport] =
    useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('All');
  const users = useSelector((state) => state.users);
  const [fetchRegistrationTalukReport, setFetchRegistrationTalukReport] =
    useState({});
  const [fetchRegistrationCircleReport, setFetchRegistrationCircleReport] =
    useState({});
  const [showDistrictTable, setShowDistrictTable] = useState(true);
  const [showTalukTable, setShowTalukTable] = useState(false);
  const [showCircleTable, setShowCircleTable] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [reportSelect, setReportSelect] = useState('');
  const [showRegistrationReports, setRegistrationReports] = useState('');
  const [showRenewalReports, setRenewalReports] = useState('');
  const [showSchemeReports, setSchemeReports] = useState('');
  const [showTypeDropDown, setShowTypeDropDown] = useState(false);
  const [showTypeBackButton, setShowTypeBackButton] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [confirmed, setConfirmed] = useState(false);

  //CSVHeaders for DISTRICT, TALUK & CIRCLE
  const csvHeaders = [
    { label: 'District', key: 'district' },
    { label: 'Registration Type', key: 'registrationType' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
    //{label:'Grand Total',key:'grand total'},
  ];

  const csvHeaders_taluk = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Registration Type', key: 'registrationType' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  const csvHeaders_circle = [
    { label: 'Circle', key: 'circle' },
    { label: 'Registration Type', key: 'registrationType' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Data for District
  const prepareCSVData = (data) => {
    const csvData = [];
    Object.keys(data).forEach((district) => {
      const registrationData = data[district];
      registrationData.forEach((entry) => {
        csvData.push({
          district,
          registrationType: entry.Registration_Type,
          total: entry.total || 0,
          approved: entry.Approved || 0,
          rejected: entry.Rejected || 0,
          pending: entry.Pending || 0,
        });
      });
    });
    csvData.push({
      district: 'Grand Totals',
      registrationType: districtTotals.transfered,
      total: districtTotals.total || 0,
      approved: districtTotals.approved || 0,
      rejected: districtTotals.rejected || 0,
      pending: districtTotals.pending || 0,
    });

    return csvData;
  };

  //CSV data for Taluk
  const prepareTalukCSVData = (data) => {
    const csvData = [];
    Object.keys(data).forEach((taluk) => {
      const registrationData = data[taluk];
      registrationData.forEach((entry) => {
        csvData.push({
          taluk,
          registrationType: entry.Registration_Type,
          total: entry.total || 0,
          approved: entry.Approved || 0,
          rejected: entry.Rejected || 0,
          pending: entry.Pending || 0,
        });
      });
      csvData.push({
        taluk: 'Grand Totals',
        registrationType: talukTotals.transfered,
        total: talukTotals.total || 0,
        approved: talukTotals.approved || 0,
        rejected: talukTotals.rejected || 0,
        pending: talukTotals.pending || 0,
      });
    });
    return csvData;
  };

  //CSV data for Circle
  const prepareCircleCSVData = (data) => {
    const csvData = [];
    Object.keys(data).forEach((circle) => {
      const registrationData = data[circle];
      registrationData.forEach((entry) => {
        csvData.push({
          circle,
          registrationType: entry.Registration_Type,
          total: entry.total || 0,
          approved: entry.Approved || 0,
          rejected: entry.Rejected || 0,
          pending: entry.Pending || 0,
        });
      });
      csvData.push({
        circle: 'Grand Totals',
        registrationType: circleTotals.transfered,
        total: circleTotals.total || 0,
        approved: circleTotals.approved || 0,
        rejected: circleTotals.rejected || 0,
        pending: circleTotals.pending || 0,
      });
    });
    return csvData;
  };

  const handleReportChange = (e) => {
    setReportSelect(e.target.value);
  };

  //Circle API
  const handleCircleClick = async (districtId, talukId, registrationType) => {
    try {
      const circlePayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'CIRCLE',
        districtId: districtId,
        talukId: talukId,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };
      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/registration`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: circlePayload,
      };

      const circleResponse = await axios(config);

      const filteredCircleData = Object.keys(
        circleResponse.data.data || {},
      ).reduce((result, district) => {
        const registrationData_Circle = circleResponse.data.data[district];
        const filteredRegistrationData_Circle = Object.values(
          registrationData_Circle,
        ).filter((data) => {
          if (!registrationType || registrationType === 'All') {
            return true;
          } else {
            return registrationType === data.Registration_Type;
          }
        });

        if (filteredRegistrationData_Circle.length > 0) {
          result[district] = filteredRegistrationData_Circle;
        }

        return result;
      }, {});

      setFetchRegistrationCircleReport(filteredCircleData);
      setShowTalukTable(false);
      setShowCircleTable(true);
    } catch (error) {
      console.log('Error fetching circle data:', error);
    }
  };

  //TalukAPI
  const handleTalukClick = async (districtID, registrationType) => {
    try {
      const talukPayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'TALUK',
        districtId: districtID,
        talukId: null,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const talukConfig = {
        method: 'POST',
        url: `${SERVER}/reports/count/registration`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: talukPayload,
      };

      const talukResponse = await axios(talukConfig);

      const filteredTalukData = Object.keys(
        talukResponse.data.data || {},
      ).reduce((result, district) => {
        const registrationData_Taluk = talukResponse.data.data[district];
        const filteredRegistrationData_Taluk = Object.values(
          registrationData_Taluk,
        ).filter((data) => {
          if (registrationType === '' || registrationType === 'All') {
            return true;
          } else if (
            registrationType === 'New Registration' &&
            data.Registration_Type === 'New Registration'
          ) {
            return true;
          } else if (
            registrationType === 'Transferred from Seva Sindhu' &&
            data.Registration_Type === 'Transferred from Seva Sindhu'
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (filteredRegistrationData_Taluk.length > 0) {
          result[district] = filteredRegistrationData_Taluk;
        }

        return result;
      }, {});

      setFetchRegistrationTalukReport(filteredTalukData);
      setShowDistrictTable(false);
      setShowTalukTable(true);
    } catch (error) {
      console.log('Error fetching taluk data:', error);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleBack = () => {
    setShowDashboard(true);
    setShowTypeBackButton(false);
    setShowDistrictTable(false);
    setShowTalukTable(false);
    setShowCircleTable(false);
  };

  //Distrcit API
  const fetchData = async () => {
    setLoading(true);
    try {
      const payLoad = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'DISTRICT',
        districtId: '',
        talukId: '',
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/registration`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payLoad,
      };

      const response = await axios(config);
      setFetchRegistrationDistrictReport(response.data.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    console.log('in use');
    if (isFirstRun.current) {
      fetchData();
      isFirstRun.current = false;
    }
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  const filteredData = Object.keys(
    fetchRegistrationDistrictReport || {},
  ).reduce((result, district) => {
    const registrationData = fetchRegistrationDistrictReport[district];
    const filteredRegistrationData = Object.values(registrationData).filter(
      (data) => {
        if (!filter || filter === 'All') {
          return true;
        } else {
          return filter === data.Registration_Type;
        }
      },
    );

    if (filteredRegistrationData.length > 0) {
      result[district] = filteredRegistrationData;
    }

    return result;
  }, {});

  const calculateTotals = (data) => {
    let total = 0;
    let approved = 0;
    let rejected = 0;
    let pending = 0;

    Object.keys(data || {}).forEach((district) => {
      const registrationData = data[district];
      Object.values(registrationData || {}).forEach((data) => {
        if (!filter || filter === 'All' || filter === data.Registration_Type) {
          total += data.total || 0;
          approved += data.Approved || 0;
          rejected += data.Rejected || 0;
          pending += data.Pending || 0;
        }
      });
    });

    return { total, approved, rejected, pending };
  };

  const districtTotals = calculateTotals(
    fetchRegistrationDistrictReport,
    filter,
  );
  const talukTotals = calculateTotals(fetchRegistrationTalukReport, filter);
  const circleTotals = calculateTotals(fetchRegistrationCircleReport, filter);

  const filteredData_Taluk = Object.keys(
    fetchRegistrationTalukReport || {},
  ).reduce((result, district) => {
    const registrationData_Taluk = fetchRegistrationTalukReport[district];
    const filteredRegistrationData_Taluk = Object.values(
      registrationData_Taluk,
    ).filter((data) => {
      if (!filter || filter === 'All') {
        return true;
      } else {
        return filter === data.Registration_Type;
      }
    });

    if (filteredRegistrationData_Taluk.length > 0) {
      result[district] = filteredRegistrationData_Taluk;
    }

    return result;
  }, {});

  const filteredData_circle = Object.keys(
    fetchRegistrationCircleReport || {},
  ).reduce((result, district) => {
    const registrationData_Circle = fetchRegistrationCircleReport[district];
    const filteredRegistrationData_Circle = Object.values(
      registrationData_Circle,
    ).filter((data) => {
      if (!filter || filter === 'All') {
        return true;
      } else {
        return filter === data.Registration_Type;
      }
    });

    if (filteredRegistrationData_Circle.length > 0) {
      result[district] = filteredRegistrationData_Circle;
    }

    return result;
  }, {});

  const handleReportsType = (selectedValue) => {
    console.log('Selected Reports Type:', selectedValue);
    if (selectedValue === '100') {
      setRegistrationReports(true);
      setRenewalReports(false);
      setSchemeReports(false);
      console.log(1);
    } else if (selectedValue === '200') {
      setRegistrationReports(false);
      setRenewalReports(true);
      setSchemeReports(false);
      console.log(2);
    } else if (selectedValue === '300') {
      setRegistrationReports(false);
      setRenewalReports(false);
      setSchemeReports(true);
      console.log(3);
    }
  };

  const handleDate = (ev) => {
    console.log(moment(ev).format(), 'DATE');
  };
  console.log(moment(startDate).format(), 'startDate');

  return (
    <>
      {!showDashboard && !showTypeDropDown && (
        <div
          style={{
            padding: '10px 15px',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <button
            style={{
              background: 'transparent',
              outline: 'none',
              border: 'none',
            }}
            onClick={handleBack}
          >
            <img alt="..." className="cal-icon hover-icn" src={back} />
          </button>

          {showDistrictTable && (
            <>
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col
                    xs={2}
                    className="benificiaries-col "
                    justifyContent="center"
                  >
                    <div
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      className="benificiaries-col-div hvr-icon-hang"
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="datepicker"
                          margin="normal"
                          format="DD/MM/YYYY"
                          value={startDate}
                          placeholder="Start Date"
                          onChange={(ev) => setStartDate(ev)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Col>

                  <Col
                    xs={2}
                    className="benificiaries-col "
                    justifyContent="center"
                  >
                    <div
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      className="benificiaries-col-div hvr-icon-hang"
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="datepicker"
                          margin="normal"
                          format="DD/MM/YYYY"
                          value={endDate}
                          placeholder="End Date"
                          onChange={(ev) => setEndDate(ev)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Col>
                </div>

                {/* Confirm Button */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '10vh',
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={fetchData}
                  >
                    Confirm
                  </Button>
                </div>
              </>

              {/* Dropdown for Filters */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px 15px',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                <div>
                  <InputLabel
                    htmlFor="registration-type"
                    style={{ display: 'block' }}
                  >
                    Filter by Registration Type
                  </InputLabel>
                </div>
                <div>
                  <Select
                    native
                    value={filter}
                    onChange={handleFilterChange}
                    inputProps={{
                      name: 'registration-type',
                      id: 'registration-type',
                    }}
                    style={{ display: 'inline-block' }}
                  >
                    <option value="All">All</option>
                    <option value="Transferred from Seva Sindhu">
                      Transferred from Seva Sindhu
                    </option>
                    <option value="New Registration">New Registration</option>
                  </Select>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        ></Box>

        {showTypeBackButton && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTypeDropDown(true);
            }}
          >
            Back to Select Reports Type
          </Button>
        )}

        {/* District Table */}
        {showDistrictTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">District</TableCell>
                  <TableCell align="center">Registration Type</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (filteredData || []).length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'blue', fontWeight: 'bold' }}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {Object.keys(filteredData).map((district) => {
                        const registrationData = filteredData[district];
                        return registrationData.map((data) => (
                          <TableRow
                            key={`${district}-${data.Registration_Type}`}
                          >
                            <TableCell
                              align="center"
                              onClick={() =>
                                handleTalukClick(
                                  data.district_id,
                                  data.Registration_Type,
                                )
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              {district}
                            </TableCell>
                            <TableCell align="center">
                              {data.Registration_Type}
                            </TableCell>
                            <TableCell align="center">
                              {data.total || 0}
                            </TableCell>
                            <TableCell align="center">
                              {data.Approved || 0}
                            </TableCell>
                            <TableCell align="center">
                              {data.Rejected || 0}
                            </TableCell>
                            <TableCell align="center">
                              {data.Pending || 0}
                            </TableCell>
                          </TableRow>
                        ));
                      })}

                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          Grand Total
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.total}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.approved}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.rejected}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.pending}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button for District */}
              <CSVLink
                data={prepareCSVData(filteredData)}
                headers={csvHeaders}
                filename={'registration_data.csv'}
                className="btn btn-primary"
                target="_blank"
                //startIcon={<img src={Excel} width="20" height="20" />}
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Taluk Table */}
        {showTalukTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">Taluk</TableCell>
                  <TableCell align="center">Registration Type</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    Object.keys(filteredData_Taluk).map((taluk) => {
                      const registrationData = filteredData_Taluk[taluk];
                      return registrationData.map((data) => (
                        <TableRow key={`${taluk}-${data.Registration_Type}`}>
                          <TableCell
                            align="center"
                            onClick={() =>
                              handleCircleClick(
                                data.district_id,
                                data.taluk_id,
                                data.Registration_Type,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {taluk}
                          </TableCell>
                          <TableCell align="center">
                            {data.Registration_Type}
                          </TableCell>
                          <TableCell align="center">
                            {data.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Rejected || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Pending || 0}
                          </TableCell>
                        </TableRow>
                      ));
                    })
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button for Taluk */}
              <CSVLink
                data={prepareTalukCSVData(filteredData_Taluk)}
                headers={csvHeaders_taluk}
                filename={'taluk_registration_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Circle Table */}
        {showCircleTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">Circle</TableCell>
                  <TableCell align="center">Registration Type</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    Object.keys(filteredData_circle).map((circle) => {
                      const registrationData = filteredData_circle[circle];
                      return registrationData.map((data) => (
                        <TableRow key={`${circle}-${data.Registration_Type}`}>
                          <TableCell align="center">{circle}</TableCell>
                          <TableCell align="center">
                            {data.Registration_Type}
                          </TableCell>
                          <TableCell align="center">
                            {data.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Rejected || 0}
                          </TableCell>
                          <TableCell align="center">
                            {data.Pending || 0}
                          </TableCell>
                        </TableRow>
                      ));
                    })
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    ></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button for Circle */}
              <CSVLink
                data={prepareCircleCSVData(filteredData_circle)}
                headers={csvHeaders_circle}
                filename={'circle_registration_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {showTalukTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTalukTable(false);
              setShowDistrictTable(true);
            }}
          >
            Back to District
          </Button>
        )}

        {showCircleTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowCircleTable(false);
              setShowTalukTable(true);
            }}
          >
            Back to Taluk
          </Button>
        )}
      </Container>
      {showDashboard ? <ReportDashboard /> : null}
      {showTypeDropDown ? <CountReport /> : null}
    </>
  );
}
