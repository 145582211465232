import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { PhoneAndroid, CameraAlt, Visibility } from "@material-ui/icons";

import { Col, Row } from "react-bootstrap";

import personal from "assets/images/f1-01.svg";
import pan from "assets/images/PAN Black-01.svg";

import Moment from "react-moment";
import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import { getTokenCookie } from "utils/tools";
import { Translate } from "react-redux-i18n";
import PopOver from "./PopOver";

const PersonalDetails = ({ data }) => {
  const [educationDocs, setEducationDocs] = React.useState({});

  // console.log(data);
  const [imageType, setImageType] = useState("");
  const [base64Content, setBase64Content] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleNewTab = (url) => {
    window.open(url, "_blank", "noopener");
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/v2/file/${fileID}?token=${getTokenCookie()}`;
    window.open(url, "_blank", "noopener");
  };

  useEffect(() => {
    if (data.user_photo_id) {
      axios
        .get(`${SERVER}/upload/download_base_64/${data.user_photo_id}`)
        .then((res) => {
          setImageType(res.data.data.contentType);
          setBase64Content(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (data.qualification_document_id) {
      let jsondocs = JSON.parse(data.qualification_document_id);
      setEducationDocs(jsondocs);
    }
  }, [data]);

  const base64Img = `data:${imageType};base64,${base64Content}`;

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      <Row className='final all-details-subtitle-row'>
        <Col xs={12} md={12} className='profile-title'>
          <img alt='...' src={personal} />
          <h1>Personal Details</h1>
        </Col>
      </Row>

      <Row className='form-row-final mt-4'>
        <Col xs={12} md={4} className='final-personal-col-1 px-3'>
          <p className='mb-0'>Name</p>
          <FormControl fullWidth>
            <TextField
              name='firstname'
              value={`${data.first_name} ${data.middle_name ?? ""} ${
                data.last_name ?? ""
              }`}
              disabled
            />
          </FormControl>

          <p className='mt-3 mb-0'>PAN Card Number</p>
          <FormControl fullWidth className='pan-div'>
            <TextField
              disabled
              value={data.pan_no}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img alt='...' src={pan} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          {data.is_ayushman_card_holder ? (
            <>
              <p className='mt-3 mb-0'>Ayushman Card Number</p>
              <FormControl fullWidth>
                <TextField value={data.ayushman_card_number} disabled />
              </FormControl>
            </>
          ) : (
            <FormControl
              className='mt-2 mb-2 interstateRadio formcontrol9'
              variant='outlined'
              fullWidth
              component='fieldset'
            >
              {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
              <RadioGroup
                row
                name='isMigrant'
                value={data.is_ayushman_card_holder === false ? "0" : "1"}
              >
                <div className='d-flex justify-content-center align-align-items-center flex-column'>
                  <p className=''>Ayushman Card Holder</p>
                  <div className='d-flex justify-content-start align-align-items-center flex-row'>
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label={"No"}
                    />
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          )}

          <p>Educational Document</p>
          {educationDocs && educationDocs.length ? (
            educationDocs.map((row, index) => (
              <>
                <div className='browsebutton2-div final'>
                  <FormControl>
                    <TextField
                      variant='outlined'
                      value={row.file_name}
                      style={{ width: "-webkit-fill-available" }}
                      disabled
                    />
                  </FormControl>
                  {!row.file_name.includes(".pdf") ? (
                    <label
                      className='browse-button2'
                      onClick={(ev) =>
                        handleViewImage(ev, row.file_id, row.file_name)
                      }
                    >
                      <Translate value='view' /> <Visibility />
                    </label>
                  ) : (
                    <label
                      className='browse-button2'
                      onClick={() => handleViewPDF(row.file_id)}
                    >
                      <Translate value='view' /> <Visibility />
                    </label>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className='browsebutton2-div final'>
              <FormControl>
                <TextField
                  variant='outlined'
                  value={"N/A"}
                  style={{ width: "-webkit-fill-available" }}
                  disabled
                />
              </FormControl>
            </div>
          )}
        </Col>

        {/* <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3"> */}

        {/* </Col> */}

        <Col xs={12} md={4} className='final-personal-col-2 px-3'>
          <p className='mb-0'>Mobile Number </p>
          <FormControl fullWidth className='formcontrol2'>
            <TextField
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' className='mb-1 mt-1'>
                    <PhoneAndroid className='phone-icon' />
                    +91
                  </InputAdornment>
                ),
              }}
              value={data.mobile_no}
            />
          </FormControl>

          <p className='mt-3 mb-0'>
            Email Address <span className='optional'>(Optional)</span>
          </p>
          <FormControl fullWidth className='formcontrol3'>
            <TextField name='email' value={data.mail_id} disabled />
          </FormControl>

          {data.is_inter_state_migrant ? (
            <>
              <p className='mt-3 mb-0'>Migrant From State</p>
              <FormControl fullWidth>
                <TextField value={data.state} disabled />
              </FormControl>
            </>
          ) : (
            <FormControl
              className='mb-2 interstateRadio formcontrol9'
              variant='outlined'
              fullWidth
              component='fieldset'
            >
              {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
              <RadioGroup
                row
                name='isMigrant'
                value={data.is_inter_state_migrant === false ? "0" : "1"}
              >
                <div className='d-flex justify-content-center align-align-items-center flex-column'>
                  <p className=''>Interstate Migrant Worker</p>
                  <div className='d-flex justify-content-start align-align-items-center flex-row'>
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label={"No"}
                    />
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          )}

          {data.is_inter_district ? (
            <>
              <p className='mt-3 mb-0'> Inter District</p>
              <FormControl fullWidth>
                <TextField value={data.inter_district_name} disabled />
              </FormControl>
            </>
          ) : (
            <FormControl
              className='mb-2 interstateRadio formcontrol9'
              variant='outlined'
              fullWidth
              component='fieldset'
            >
              {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
              <RadioGroup
                row
                name='isMigrant'
                value={data.is_inter_district === false ? "0" : "1"}
              >
                <div className='d-flex justify-content-center align-align-items-center flex-column'>
                  <p className=''>Inter District Worker</p>
                  <div className='d-flex justify-content-start align-align-items-center flex-row'>
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label={"No"}
                    />
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          )}

          <p className='mt-3 mb-0'>Education</p>
          <FormControl fullWidth>
            <TextField
              placeholder='Education'
              name='education'
              value={data.qualification}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='final-personal-col-3 px-3'>
          <div className='picupload'>
            {!base64Img ? (
              <CameraAlt className='cameraAlt2' />
            ) : (
              <img
                alt='...'
                className='avatar border-gray'
                // src={`data:image/png;base64,${sessionStorage.getItem('profilePic')}`}

                src={base64Img}
                // src={users.personal_details.imgfile}
              />
            )}
          </div>

          <div className='mt-3 final-display-flex'>
            <p className='m-0'>Marital Status</p>
            <p className='m-0 p-0'>{data.marital_status}</p>
          </div>

          <div className='mt-3 final-display-flex'>
            <p className='m-0'>Gender</p>
            <p className='m-0 p-0'>{data.gender}</p>
          </div>

          <div className='mt-3 final-display-flex'>
            <p className='m-0'>DOB</p>
            <p className='m-0 p-0'>
              <Moment format='DD.MM.YYYY'>{data.date_of_birth}</Moment>
            </p>
          </div>

          <div className='mt-3 final-display-flex'>
            <p className='m-0'>Category</p>
            <p className='m-0 p-0'>{data.caste}</p>
          </div>

          <div className='mt-3 final-display-flex'>
            {/* <p className="m-0" >Category</p> */}
            <p className='m-0'>Religion</p>
            <p className='m-0 p-0'>
              {data.catalog_value_religion_id === 1143
                ? "Hindu"
                : data.catalog_value_religion_id === 1144
                  ? "Muslim"
                  : data.catalog_value_religion_id === 1145
                    ? "Christian"
                    : data.catalog_value_religion_id === 1146
                      ? "Others"
                      : ""}
            </p>
          </div>
          <div className='mt-3 final-display-flex'>
            {/* <p className="m-0" >Category</p> */}
            <p className='m-0'>Revenue Division Number</p>
            <p className='m-0 p-0'>{data.rd_no ? data.rd_no : "N/A"}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PersonalDetails;
