import 'date-fns';
import { withStyles, Menu, IconButton, FormControl, TextField, InputAdornment } from '@material-ui/core';
import React, { useEffect,  useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Pagination, Stack } from '@mui/material';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import right from 'assets/images/Icon feather-arrow-right-circle (1).svg';
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import Edit from 'assets/images/Icon material-edit.svg';
import search from 'assets/images/Icon ionic-ios-search.svg'
import closeIcon from 'assets/images/close-01.svg'
import Tick from 'assets/images/check (3) 2.svg'
import home from 'assets/images/Icon material-home.svg' 
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getDeptUserDetails, getDeptUsers, getFile, getLabourStatusDetails, setLocaleWithFallback } from 'store/actions/user.actions';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { Translate } from 'react-redux-i18n';



const MappingMaster = (props) => { 
    const dispatch = useDispatch();
    const users = useSelector(state => state.users); 
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.getDeptUsers,
        searchValueError: false,
        searchValue: "",
    });
    const [rows, setRows] = useState([]);
    const [rowsBackup, setrowsBackup] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(0);

    const handleChange = (event) => {
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {

        console.log("rows: ",rows )

    }, [rows]);

    useEffect(() => {

        console.log("allValues.searchValue: ",allValues.searchValue )
        if(allValues.searchValue !== ""){
            console.log("in if searchValue filter item")

            console.log(allValues.users.filter(row => row.username.toLowerCase().includes(allValues.searchValue.toLowerCase())))

            var newUsers= allValues.users.filter(row => row.username.toLowerCase().includes(allValues.searchValue.toLowerCase()))
            var data= JSON.parse(JSON.stringify(newUsers));
            // let data= JSON.parse(JSON.stringify(allValues.users.filter(row => row.username.toLowerCase().includes(allValues.searchValue.toLowerCase()))))
            // let data= JSON.parse(JSON.stringify(allValues.users.filter(row => row.username.toLowerCase().includes(allValues.searchValue.toLowerCase()))));
            data = data.slice(0,newUsers.length);

            console.log("data:",data)
            setRows(data);
        }
        else{
            console.log("in else searchValue filter item")
            if(allValues.users && allValues.users.length>0){
                if(allValues.users.length<10){
                    setTotalPages(1);
                    let data= JSON.parse(JSON.stringify(allValues.users));
                    data = data.slice(0,allValues.users.length);
                    setRows(data);
                }else{
                    const total = allValues.users.length/rowsPerPage;
                    setTotalPages(Math.ceil(total));
                    //set initial page data
                    let data= JSON.parse(JSON.stringify(allValues.users));
                    data = data.slice(0,rowsPerPage);
                    setRows(data);
                } 
            } 
        }

    }, [allValues.searchValue]);

    useEffect(() => {
        console.log("in useEffect [users.user]");
        // dispatch(getDeptUsers());
    
        sessionStorage.clear();
        deleteReduxData("getFile")

        dispatch(getFile("delete"))
    }, []);

    useEffect(()=>{
        console.log(allValues.users); 
        const usersData = users['getDeptUsers'];
        if(
            usersData !== undefined &&
            usersData !== null &&
            usersData.data !== undefined &&
            usersData.data !== null &&
            usersData.data.length>0){
         setAllValues({...allValues, users: usersData.data});
        } 
    },[users]);

    useEffect(()=>{
        if(
            users !== undefined &&
            users !== null &&
            users.login_admin !== undefined &&
            users.login_admin !== null &&
            users.login_admin.data !== undefined &&
            users.login_admin.data !== null &&
            users.login_admin.data.department_user_id !== undefined &&
            users.login_admin.data.department_user_id !== null
        ){
            dispatch(getDeptUserDetails(users.login_admin.data.department_user_id));
        }
    },[users.login_admin]);
 
    useEffect(()=>{ 
        if(allValues.users && allValues.users.length>0){
            if(allValues.users.length<10){
                setTotalPages(1);
                let data= JSON.parse(JSON.stringify(allValues.users));
                data = data.slice(0,allValues.users.length);
                setRows(data);
            }else{
                const total = allValues.users.length/rowsPerPage;
                setTotalPages(Math.ceil(total));
                //set initial page data
                let data= JSON.parse(JSON.stringify(allValues.users));
                data = data.slice(0,rowsPerPage);
                setRows(data);
            } 
        } 
    },[allValues.users])

    const handleNext=()=>{
        console.log('next');
        if(page+1<=totalPages){
            let data= JSON.parse(JSON.stringify(allValues.users));
            data = data.slice(page*rowsPerPage, page*rowsPerPage+rowsPerPage);
            setRows(data);
            setPage(page+1);
        }
    }

    const handleBack=()=>{
        console.log('back');
        if(page-1>=1){
            let data= JSON.parse(JSON.stringify(allValues.users));
            data = data.slice((page-1)*rowsPerPage-rowsPerPage, (page-1)*rowsPerPage);
            setRows(data);
            setPage(page-1);
        }
    }

    const handlePageChange = (event, value) => {
        console.log("event: ",event)
        console.log("value: ",value)
        
        let data= JSON.parse(JSON.stringify(allValues.users));
        data = data.slice((value)*rowsPerPage-rowsPerPage, (value)*rowsPerPage);
        setRows(data);
        setPage(value);
    };

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a><h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)
                            </h1>
                            </div>

                        </Col> 
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Link to="/admin/user-role-mapping-form" className="add-div-link">
                                <div className="add-div">
                                <img alt="..." className="cal-icon" src={add}/>
                                <p>Add User</p>
                                </div>
                            </Link>
                        </Col>
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                    <Col xs={8} className="title-col">
                        <Link to="/admin/home">
                            <img alt="admin" className="cal-icon hover-icn" src={back}/>
                        </Link>
                            <p className="title-p">Registered Users</p>
                    </Col>
                    <Col xs={4} className="title-col search-col">
                        <p className="mb-0 fieldtitle searchcircle-title">
                            <Translate value="Search User"/></p>
                        <FormControl fullWidth className="phno-field">
                            <TextField
                                // className="size21"
                                name="searchValue"
                                value={allValues.searchValue}
                                placeholder="Example : admin@kbocwwb"
                                type="text"
                                onChange={handleChange}
                                // error={allValues.searchValueError}
                                // helperText={allValues.searchValueError ? "Please enter correct data" : ""}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">

                                            {
                                                allValues.searchValue === "" ?
                                                    <img alt="..." src={search} className="language-img" />
                                                    :
                                                    <img alt="..." src={closeIcon} 
                                                    onClick={() => {
                                                        setAllValues({
                                                            ...allValues,
                                                            searchValue: ""
                                                        })
                                                    }}
                                                    className="language-img closebtnsearch" />
                                            }


                                        </InputAdornment>
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Col>
                
                </Row>

               {/* <div className="config-page-row-title">        */}
               <Paper sx={{ width: '100%', mb: 2 }}>     
               <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                        <TableHead>
                        <TableRow>
                          
                            <TableCell className="custom-font" align="center">
                            <Link to="/admin/home">
                                <span className="" style={{position:'absolute',left:'0',marginLeft:'90px'}}>  
                                    <img alt="..." className="cal-icon hover-icn" src={home}/>
                                </span>
                            </Link>
                            Officer Name</TableCell>
                            <TableCell className="custom-font" >User Name</TableCell>
                            <TableCell className="custom-font" >Mobile Number</TableCell>
                            <TableCell className="custom-font" >KGID No</TableCell>
                            <TableCell className="custom-font" >Status</TableCell>
                            <TableCell className="custom-font" >Options</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        rows.length > 0 &&
                        rows.map((row) => (
                            <TableRow
                            key={row.department_user_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                         
                            <TableCell align="center">
                                {[row.first_name,row.middle_name,row.last_name].join(' ')}
                            </TableCell>
                            <TableCell >{row.username}</TableCell>
                            <TableCell >{row.mobile_no}</TableCell>
                            <TableCell > {row.employee_number || '__'}</TableCell>
                            <TableCell  className="active-cell">
                                    <img alt="status" src={Tick} className="mr-2"/> Active</TableCell>
                            <TableCell>  
                        
                                <Link to ={{ 
                                        pathname: "/admin/user-role-mapping-form", 
                                        userId: row.department_user_id,
                                        userrow: row
                                        }}
                                    className="td-link"
                                >
                                    <img alt="edit" src={Edit} className="mr-2"/> Edit
                                </Link> 
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody> 
                    </Table>
            </TableContainer> 
            </Paper>
          
            <div className="footer-buttons">
                <Stack 
                    spacing={2} 
                    className="p-2 pagination_noti"
                    >
                    <Pagination 
                        // showFirstButton showLastButton
                        color="primary"
                        count={totalPages} 
                        page={page} 
                        variant="outlined" 
                        onChange={handlePageChange} />
                </Stack>
            </div>

            
          
        </>
    )
}

export default MappingMaster;