import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap';



function PrivacyPolicy(props) {

    


    
    

    return (
        <>
            
            <div className="root container-fluid p-2">
                {/* <Col className='change-number-page'>
                       
                    </Col>
                */}

                <h1 style={{marginTop:"20px", marginLeft:"30px"}}>Privacy Policy</h1>
                <p style={{marginTop:"20px", marginLeft:"30px"}}>
<br></br>Karnataka Building And Other Construction Workers Welfare Board built the KBOCWWB ADMIN APP & KBOCWWB LABOUR APP app as a Free app. This SERVICE is provided by Karnataka Building And Other Construction Workers Welfare Board at no cost and is intended for use as is.
This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
<br></br>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at KBOCWWB ADMIN APP & KBOCWWB LABOUR APP unless otherwise defined in this Privacy Policy.
Information Collection and Use<br></br>
For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to<br></br>
<br></br><br></br>1. When you register on KBOCWWB Labour Mobile Application, the following personal information is collected from you and stored securely on Karnataka State Data Centre (KSDC) Server operated and managed by the State Government <br></br>i). Mobile Number <br></br>ii). Aadhaar Number <br></br>iii). Date of Birth <br></br>iv). Profile Photo <br></br>v). Address details <br></br>vi). Unique ID <br></br>vii). Document we are capturing in Aadhaar image URL to upload only png or jpeg format only then we are using local storage create new folders to save an image into mobile local. Image after upload is deleted from the local storage.
<br></br><br></br>2. In Address details we are showing a permanent address taken from the Aadhaar card In Address Details page we are capturing Permanent and Residential address Details Permanent Address: State, District, Taluk, Gram Panchayath, Village, Nature of Work, Pincode, Landmark, door no, street name Residential Address: State, District, Taluk, Gram Panchayath, Village, Nature of Work, Pincode, Landmark, door no, street name
<br></br><br></br>3. In Family details page we are capturing the ration card image (it is optional) from the user. <br></br>i). Document method type is png or jpeg format. <br></br>ii). User Family details also captures family members details <br></br>iii). The document we are capturing in the mobile gallery to upload png or jpeg format only, then we are using local storage create new folders to save an image into mobile local storage.<br></br> iv). Image after upload is deleted from local storage.
<br></br><br></br>4. Bank Details page we are capturing data to from the user. The following fields are being captured: <br></br>i). Account Number <br></br>ii). Bank IFSC Code Based on the Ifsc code we fetch the following data. <br></br>i). Bank Name <br></br>ii). Bank Address<br></br> iii). Bank Branch Name <br></br>iv). Users have to upload the passbook document in png or jpeg format<br></br> v). After users register they can apply for scheme on the Labour application Just for verification for the scheme's sanction amount transfer to their account. <br></br>vi). Document we are capturing in the mobile gallery to upload in png or jpeg format only, then we are using local storage create new folders to save the image into mobile local storage <br></br>vii). Image after upload is deleted from local storage.
<br></br><br></br>5. 90 Days Work certificate Work details are being captured as below mentioned: - 90 Day’s Work Details for user : <br></br>i). Employer/Owner Details <br></br>ii). Employer/Owner Name <br></br>iii). Employer/Owner Company Name <br></br>iv). Mobile Number ( Incharge Person )<br></br> v).Workplace/ Site Address - State, District, Taluk, Gram Panchayath, Village, Nature of Work, Pincode - 90 Day Work Certificate for User :<br></br> i). Type Of Issuer ii). Issued Date iii). Users have to upload the 90 Days Certificate document in png or jpeg format <br></br>iv). The document we are capturing in the mobile gallery to uploaded in png or jpeg format only, then we are using local storage create new folders to save an image into mobile local storage <br></br>vii). Image after upload is deleted from local storage. <br></br>
<br></br><br></br>6. Schemes also labour can apply only based on the requirements we are showing in schemes form in mobile application <br></br>i). Few Schemes have time restrictions while applying for schemes <br></br>ii) Few schemes they can apply multiple times in our application<br></br> iii). In the schemes users have to submit a few documents based on selected schemes.<br></br> iv). Schemes document uploaded in png or Jpeg format only. <br></br>v). Document we are capturing in the mobile gallery to uploaded in png or jpeg format only then we are using local storage to create new folders to save an image into mobile local storage <br></br>vi).Image after upload is deleted from local storage.. The information that we request will be retained by us and used as described in this privacy policy.
The app does use third-party services that may collect information used to identify you.
<br></br><br></br>Link to the privacy policy of third-party service providers used by the app
<br></br>Google Play Services
<br></br>Google Analytics for Firebase
<br></br>Firebase Crashlytics
<br></br><br></br>Log Data
<br></br>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
<br></br><br></br>Cookies
<br></br>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
Service Providers
We may employ third-party companies and individuals due to the following reasons:
To facilitate our Service;
To provide the Service on our behalf;
To perform Service-related services; or
To assist us in analyzing how our Service is used.
We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
Security
We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
Links to Other Sites
This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
Children’s Privacy
These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.
Changes to This Privacy Policy
We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
This policy is effective as of 2022-07-18
<br></br><br></br>
Contact Us
If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at it.kbocwwb@gmail.com.</p>
            </div>
        </>
    )
}
export default PrivacyPolicy
