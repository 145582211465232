import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

const columnHelper = createColumnHelper();
export const columns = [
  columnHelper.group({
    header: 'Survey',
    columns: [
      columnHelper.accessor('id', {
        header: 'S.No',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('ss_user_id', {
        header: 'User ID',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('name_ss', {
        header: 'Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('dob_ss', {
        header: 'DOB',
        cell: (info) => moment(info.getValue()).format('DD/MM/YYYY') ?? '',
      }),
      columnHelper.accessor('gender_ss', {
        header: 'Gender',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('registrationNo', {
        header: 'Registration No',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('dob_match_status', {
        header: 'DOB Match',
        cell: (info) => info.getValue() ?? 0,
      }),
      columnHelper.accessor('refNo', {
        header: 'Reference No',
        cell: (info) => info.getValue() ?? 0,
      }),
      columnHelper.accessor('labour_card_file_id', {
        header: 'Labour Card File',
        cell: (info) => {
          return (
            <a
              href={`${SERVER}/upload/v2/file/${info.getValue()}?token=${getTokenCookie()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          );
        },
      }),
      columnHelper.accessor('govt_doc_file_id', {
        header: 'Government ID',
        cell: (info) => {
          return (
            <>
              {info.getValue() ? (
                <a
                  href={`${SERVER}/upload/v2/file/${info.getValue()}?token=${getTokenCookie()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      }),
    ],
  }),
  columnHelper.group({
    header: 'Aadhaar',
    columns: [
      columnHelper.accessor('name_aadhaar', {
        header: 'Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('dob_aadhaar', {
        header: 'DOB',
        cell: (info) => moment(info.getValue()).format('DD/MM/YYYY') ?? '',
      }),
      columnHelper.accessor('gender_aadhaar', {
        header: 'Gender',
        cell: (info) => info.getValue(),
      }),
    ],
  }),
];
