import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import count from '../../../assets/images/business-report.png';
import datareport from '../../../assets/images/data-report.png';
import sevasindhu from '../../../assets/images/seva-sindhu.png';
import dbt from '../../../assets/images/dbt.jpg';
import KBOCWWB from '../reports';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DBTMISReports from '../DBTMISReports';
import CountReport from './CountReports';
import { useSelector } from 'react-redux';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MISCountReport from './MISCountReport';
import { DataContext } from 'context/config';
import DOBSurveyReport from './DOBSurveyReport';
import { Poll } from '@mui/icons-material';

const ReportDashboard = () => {
  const { data: configData } = useContext(DataContext);
  // console.log('ReportDashboard ~ data:', data);
  const users = useSelector((state) => state.users);

  const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id;
  const roleID = users?.getDeptUserRole?.data[0]?.role_id;

  const [showDashboard, setShowDashboard] = useState(true);
  const [showDataReports, setShowDataReports] = useState(false);
  const [showDBTReports, setShowDBTReports] = useState(false);
  const [showCountReports, setShowCountReports] = useState(false);
  const [showMisReports, setShowMisReports] = useState(false);
  const [showDOBSurvey, setShowDOBSurvey] = useState(false);

  const handleReports = (from) => {
    if (from === 'data-reports') {
      setShowDataReports(true);
      setShowMisReports(false);
      setShowDashboard(false);
      setShowDOBSurvey(false);
    } else if (from === 'dbt-reports') {
      setShowDataReports(false);
      setShowDashboard(false);
      setShowDBTReports(true);
      setShowMisReports(false);
      setShowDOBSurvey(false);
    } else if (from === 'count-reports') {
      setShowDataReports(false);
      setShowDashboard(false);
      setShowDBTReports(false);
      setShowCountReports(true);
      setShowMisReports(false);
      setShowDOBSurvey(false);
    } else if (from === 'mis-count-reports') {
      setShowDataReports(false);
      setShowDashboard(false);
      setShowDBTReports(false);
      setShowCountReports(false);
      setShowMisReports(true);
      setShowDOBSurvey(false);
    } else if (from === 'dob-survey') {
      setShowDataReports(false);
      setShowDashboard(false);
      setShowDBTReports(false);
      setShowCountReports(false);
      setShowMisReports(false);
      setShowDOBSurvey(true);
    }
  };

  return (
    <>
      {showDashboard ? (
        <div className="d-flex justify-content-center align-items-center">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 200,
              },
            }}
          >
            <Paper>
              <div
                onClick={() => handleReports('count-reports')}
                className="d-flex justify-content-center align-items-center flex-column p-3"
              >
                <img src={count} alt="" width={'100'} height={'100'} />
                <p style={{ fontSize: '25px' }}>Count Reports</p>
              </div>
            </Paper>
            {configData?.showMIS === 'Y' && (
              <Paper>
                <div
                  onClick={() => handleReports('mis-count-reports')}
                  className="d-flex justify-content-center align-items-center flex-column p-3"
                >
                  <img src={count} alt="" width={'100'} height={'100'} />
                  <p style={{ fontSize: '25px' }}>Counts (MIS)</p>
                </div>
              </Paper>
            )}
            <Paper>
              <div
                onClick={() => handleReports('data-reports')}
                className="d-flex justify-content-center align-items-center flex-column p-3"
              >
                <img src={datareport} alt="" width={'100'} height={'100'} />
                <p style={{ fontSize: '25px' }}>Data Reports</p>
              </div>
            </Paper>
            {configData?.showDOBSurvey === 'Y' && (
              <Paper sx={{ cursor: 'pointer' }}>
                <div
                  className="d-flex justify-content-center align-items-center flex-column p-3"
                  onClick={() => handleReports('dob-survey')}
                >
                  <Poll sx={{ width: '48px', height: '48px' }} />
                  {/* <img src={sevasindhu} alt="" width={'100'} height={'100'} /> */}
                  <p style={{ fontSize: '25px', textAlign: 'center' }}>
                    DOB Survey
                  </p>
                </div>
              </Paper>
            )}
            {/* <Paper>
              <a
                style={{ color: 'inherit' }}
                href={`http://13.82.17.107/ReportServer/Pages/ReportViewer.aspx?%2fdata_report%2freport_status_wise_registration_sevasindu_labour_details_deprt_userid_2&department_user_id=${deptUserID}&role_id=${roleID}&rs:Command=Render`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex justify-content-center align-items-center flex-column p-3">
                  <AssessmentIcon sx={{ fontSize: 100 }} />
                  <p style={{ fontSize: '25px', textAlign: 'center' }}>
                    SSRS Reports
                  </p>
                </div>
              </a>
            </Paper> */}
            <Paper>
              <div
                onClick={() => handleReports('dbt-reports')}
                className="d-flex justify-content-center align-items-center flex-column p-3"
              >
                <img src={dbt} alt="" width={'100'} height={'100'} />
                <p style={{ fontSize: '25px' }}>DBT Reports</p>
              </div>
            </Paper>
          </Box>
        </div>
      ) : null}

      {showDataReports ? <KBOCWWB /> : null}
      {showDBTReports ? <DBTMISReports /> : null}
      {showCountReports ? <CountReport /> : null}
      {showMisReports ? <MISCountReport /> : null}
      {showDOBSurvey ? (
        <DOBSurveyReport
          handleBack={() => {
            setShowDOBSurvey(false);
            setShowDashboard(true);
          }}
        />
      ) : null}
    </>
  );
};

export default ReportDashboard;
