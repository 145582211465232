import 'date-fns';
import { withStyles, Menu, MenuItem,ListItemText, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, Backdrop, CircularProgress, makeStyles, FormControlLabel, Checkbox} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PhoneAndroid } from '@material-ui/icons'; 
import { showToast, getTokenCookie, Required } from 'utils/tools';
import { SERVER } from 'store/actions/user.actions'
import axios from 'axios';
import validator from 'validator';


const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: '350px'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const RoleMasterForm = (props) => {  
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffffee',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));
    const classes = useStyles();

    const dispatch = useDispatch()
    const users = useSelector(state => state.users);
    const [loading, setLoading] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [changePasswordCheckbox, setchangePasswordCheckbox] = React.useState(false); 

    const [anchorEl, setAnchorEl] = React.useState(null); 
    const {location} = props; 
    const [saveBtnText, updateSaveBtnText] = useState("Save & Continue");
    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        name: "",
        code: "",
        short_name: "",
        description: "",
        is_edit: false,
        recieved_id: ""

    })


    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
        props.history.push("/admin/dashboard");
    };

    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };

    useEffect(() => {
        console.log("location: ",location)
        if(location.rowdata !== undefined && location.rowdata !== null ){
            console.log("location.rowdata: ",location.rowdata)
            var v = location.rowdata
            setAllValues({
                ...allValues,
                name: v.name,
                code: v.code,
                short_name: v.short_name,
                description: v.decription,
                is_edit: true,
                recieved_id: v.id
            })   
        }
    }, []);
    
    const handleChangeCheckbox = (event) => {

        
            setchangePasswordCheckbox(event.target.checked)
            
    }
 
    const handleChange = (e) => {
        // if (e.target.name == "user_name"){
        //     if (validator.isAlpha(e.target.value) && (e.target.value.length) < 30){
        //         //  medicalAlphaError = false
        //         //  e.targ
        //         setAllValues({
        //             ...allValues,
        //             [e.target.name]: e.target.value,
        //             userNameError: false
        //         })

        //     }
        //     else{
        //         setAllValues({
        //             ...allValues,
        //             [e.target.name]: e.target.value,
        //             userNameError: true
        //         })
        //     }
        // }
        if (e.target.name == "first_name"){
            if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && (e.target.value.length) < 30){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    firstnameError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    firstnameError: true
                })
            }
        }
        else if (e.target.name == "middle_name"){
            if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && (e.target.value.length) < 30){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    middle_nameError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    middle_nameError: true
                })
            }
        }
        else if (e.target.name == "mobile_number"){
            if (validator.isNumeric(e.target.value) && (e.target.value.length) == 10){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    mobile_numberError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    mobile_numberError: true
                })
            }
        }
        else if (e.target.name == "last_name"){
            if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && (e.target.value.length) < 30){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    last_nameError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    last_nameError: true
                })
            }
        }
        else if (e.target.name == "email"){
            if (validator.isEmail(e.target.value)){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    emailError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    emailError: true
                })
            }
        }        
        else if (e.target.name == "kgid_no"){
            if (validator.isNumeric(e.target.value) && (e.target.value.length < 20)){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    kgid_noError: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name]: e.target.value,
                    kgid_noError: true
                })
            }
        }
        else{
            setAllValues({
                ...allValues,
                [e.target.name]: e.target.value
            })  
        } 
    }

    const handleClosekboc = (event,data) =>{
        setAnchorEl(null);
    }

    const onSelectStatusHandler = (event,data) =>{ 
        setAllValues({
            ...allValues,
            activeBoardItem: data
        })
        handleClosekboc();
    }

    const onSelectClickHandlerkboc = (event,data) =>{
        setAnchorEl(event.currentTarget);
    }

    const isNotUndefinedEmptyAndNotNull = (value) => {
        if(value !== undefined && value !== null && value !== '') {
            return true ;
        } else {
            return false ;
        }
    }

    const checkFormValues = () => {
        return new Promise((resolve, reject) => {
            const { user_name, first_name, last_name, password, confirm_password, mobile_number, email, kgid_no, address } = allValues

            if(location.userrow !== undefined){
                if(changePasswordCheckbox){
                    if(
                        isNotUndefinedEmptyAndNotNull(user_name) && 
                        isNotUndefinedEmptyAndNotNull(first_name) && 
                        isNotUndefinedEmptyAndNotNull(last_name) && 
                        isNotUndefinedEmptyAndNotNull(password) && 
                        isNotUndefinedEmptyAndNotNull(confirm_password) && 
                        isNotUndefinedEmptyAndNotNull(mobile_number) && 
                        isNotUndefinedEmptyAndNotNull(email) && 
                        isNotUndefinedEmptyAndNotNull(kgid_no) && 
                        isNotUndefinedEmptyAndNotNull(address)
                        ) {
                        resolve("success");
                    } else {
                        reject("Please fill all mandatory fields!");
                    }
                }
                else{
                    if(
                        isNotUndefinedEmptyAndNotNull(user_name) && 
                        isNotUndefinedEmptyAndNotNull(first_name) && 
                        isNotUndefinedEmptyAndNotNull(last_name) && 
                        // isNotUndefinedEmptyAndNotNull(password) && 
                        // isNotUndefinedEmptyAndNotNull(confirm_password) && 
                        isNotUndefinedEmptyAndNotNull(mobile_number) && 
                        isNotUndefinedEmptyAndNotNull(email) && 
                        isNotUndefinedEmptyAndNotNull(kgid_no) && 
                        isNotUndefinedEmptyAndNotNull(address)
                        ) {
                        resolve("success");
                    } else {
                        reject("Please fill all mandatory fields!");
                    }
                }
            } 
            else{
                if(
                    isNotUndefinedEmptyAndNotNull(user_name) && 
                    isNotUndefinedEmptyAndNotNull(first_name) && 
                    isNotUndefinedEmptyAndNotNull(last_name) && 
                    isNotUndefinedEmptyAndNotNull(password) && 
                    isNotUndefinedEmptyAndNotNull(confirm_password) && 
                    isNotUndefinedEmptyAndNotNull(mobile_number) && 
                    isNotUndefinedEmptyAndNotNull(email) && 
                    isNotUndefinedEmptyAndNotNull(kgid_no) && 
                    isNotUndefinedEmptyAndNotNull(address)
                    ) {
                    resolve("success");
                } else {
                    reject("Please fill all mandatory fields!");
                }
            }
            
            
        })
    }

    const onSaveHandler=()=>{
        setLoading(true);
        // checkFormValues().then((res) => {

            if(allValues.is_edit){

                var data = JSON.stringify({
                    "id": allValues.recieved_id,
                    "name": allValues.name,
                    "code": allValues.code,
                    "short_name": allValues.short_name,
                    "description":  allValues.description,
                    "user_id": users.getDeptUserRole.data[0].department_user_id,
                });
                var config = {
                method: 'post',
                url: SERVER +'/config/role/update_role',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
                };
                axios(config)
                .then(function (response) {
                    console.log("update_role response:",response)
                    setLoading(false);
                    showToast("SUCCESS", "Role Updated Successfully !")
                    props.history.push("/admin/role-master")
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                    showToast("ERROR", error.response)
                });
                
            }
            else{
                var data = JSON.stringify({
                    "name": allValues.name,
                    "code": allValues.code,
                    "short_name": allValues.short_name,
                    "description":  allValues.description,
                    "user_id": users.getDeptUserRole.data[0].department_user_id,
                });
                var config = {
                method: 'post',
                url: SERVER +'/config/role/insert_role',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
                };
                axios(config)
                .then(function (response) {
                    console.log("insert_role response:",response)
                    setLoading(false);
                    showToast("SUCCESS", "Role Added Successfully !")
                    props.history.push("/admin/role-master")
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                    showToast("ERROR", error.response)
                });

            }
        // })
        // .catch(err => {
        //     setLoading(false);
        //     showToast("ERROR", err)
        // })
       
    }

    return (
        <>
            <div className="root"> 
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a><h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                 
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                    </Link>
                        <p className="title m-0 p-0">Role Master</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
            <Col xs={12} md={4} className="personal-col-1">
                       <Required className="mb-2 mt-3" title ="Name"/> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Name"
                                name="name"
                                value={allValues.name} 
                                onChange={handleChange} 
                                // error = {allValues.userNameError}
                            //   helperText = {allValues.userNameError ? "Please enter alphabets only" : ""}
                            />
                        </FormControl>
                </Col>
                
                <Col xs={12} md={4} className="personal-col-1">
                       <Required className="mb-2 mt-3" title="Code" /> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Code"
                                name="code"
                                value={allValues.code} 
                                onChange={handleChange} 
                                // helperText = {allValues.firstnameError ? "Please enter alphabets only" : "" }
                                // error = {allValues.firstnameError}


                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                       <Required className="mb-2 mt-3" title="Short Name" /> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Short Name"
                                name="short_name"
                                value={allValues.short_name} 
                                onChange={handleChange} 
                                // helperText = {allValues.firstnameError ? "Please enter alphabets only" : "" }
                                // error = {allValues.firstnameError}


                            />
                        </FormControl>
                </Col>
                
                <Col xs={12} md={8} className="personal-col-1">
                        <Required className="mb-2 mt-3" title ="Description" /> 
                        <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                    }
                                }}>
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3} 
                                    placeholder="Enter Description"
                                    name="description"
                                    value={allValues.description}
                                    onChange={handleChange}
                                />
                            </FormControl> 
                </Col>


                
                <Col xs={12} className="mt-4" align="center">  
                    {
                        loading ?
                            
                            <CircularProgress color="inherit" />
                            : 
                            <Button variant="outline-primary" className="save-button" onClick={onSaveHandler}>
                                Submit
                            </Button> 
                    }
                    
                </Col> 

                
            </Row>

        </>
    )
}

export default RoleMasterForm;