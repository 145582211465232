import React, { useState } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { IconButton, TextField, Typography } from '@mui/material';
import { SkipNext, SkipPrevious } from '@mui/icons-material';

export const TableRoot = ({
  data = [],
  columns = [],
  pageSize = 10,
  rowStyles = {},
  tableStyle = {},
  cellStyles = {},
  options = {},
  containerStyles = {},
  paginationStyles = {},
}) => {
  console.log({
    data,
    columns,
    pageSize,
    rowStyles,
    tableStyle,
    cellStyles,
    options,
    containerStyles,
    paginationStyles,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0, // Initial page index
    pageSize, // Default page size
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination, // Update pagination state
    state: {
      pagination,
    },
  });

  return (
    <>
      <div style={containerStyles}>
        <table style={tableStyle}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} style={rowStyles}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={cellStyles}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} style={rowStyles}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={cellStyles}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {data?.length === 0 && (
          <Typography
            textAlign="center"
            variant="h6"
            letterSpacing={'-1px'}
            sx={{ py: 4 }}
          >
            No Data Found
          </Typography>
        )}
      </div>
      <div style={{ ...paginationStyles }}>
        <IconButton
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <SkipPrevious />
        </IconButton>
        <IconButton
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <SkipNext />
        </IconButton>
        <input
          style={{
            maxWidth: '3rem',
            paddingInline: '8px',
            border: '1px solid gray',
            borderRadius: '0.25rem',
          }}
          type="number"
          value={pagination.pageIndex + 1}
          onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
        />
        <select
          style={{
            maxWidth: '6rem',
            paddingInline: '8px',
            border: '1px solid gray',
            borderRadius: '0.25rem',
          }}
          value={pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
