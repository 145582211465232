import "date-fns";
import {
  withStyles,
  Menu,
  MenuItem,
  ListItemText,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  FormControl,
  TextField,
  TextareaAutosize,
  InputAdornment,
  Backdrop,
  CircularProgress,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row, Button, OverlayTrigger } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import home from "assets/images/Icon material-home.svg";
import add from "assets/images/Icon ionic-ios-add-circle.svg";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import Edit from "assets/images/Icon material-edit.svg";
import mobile from "assets/images/Phone 1-01.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import tick from "assets/images/check (3) 2.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CameraAlt, PhoneAndroid } from "@material-ui/icons";
import {
  showToast,
  getTokenCookie,
  Required,
  dataURLtoFile,
} from "utils/tools";
import Webcam from "react-webcam";
import webcam from "assets/images/web cam-01.svg";
import { getFile, SERVER } from "store/actions/user.actions";
import axios from "axios";
import validator from "validator";
import { Translate } from "react-redux-i18n";
import { DialogTitle } from "@mui/material";

const videoConstraints = {
  width: 720,
  height: 720,
  facingMode: "user",
  // facingMode: { exact: "environment" }
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "350px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const UserRoleMappingForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [loading, setLoading] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [changePasswordCheckbox, setchangePasswordCheckbox] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { location } = props;
  const [saveBtnText, updateSaveBtnText] = useState("Save & Continue");
  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    user_name: "",
    password: "",
    confirm_password: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    kgid_no: "",
    address: "",
    activeBoardItem: "KBOCWWB",
    // userNameError:false,
    firstnameError: false,
    password_Error: false,
    confirm_password_Error: false,
    middle_nameError: false,
    last_nameError: false,
    mobile_numberError: false,
    emailError: false,
    sakaladesignatedcode: "",
    kgid_noError: false,
  });

  const [signPic, setsignPic] = React.useState("");

  const myRef = React.createRef();

  const handleFileChange = (event) => {
    if (event.target.files[0] !== null) {
      if (event.target.files[0].size > 2000001) {
        showToast("ERROR", "Please upload file of size less than 2MB.");
      } else if (!event.target.files[0].type.includes("image")) {
        showToast("ERROR", "Please upload file in JPEG or PNG format.");
      } else {
        // var selectedblob = URL.createObjectURL(event.target.files[0])
        var selectedblob = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        reader.onloadend = function () {
          var base64data = reader.result;
          console.log(base64data);

          sessionStorage.setItem("signPic", base64data);
          setsignPic(base64data);
          setAllValues({
            ...allValues,
            // imgfile: URL.createObjectURL(event.target.files[0])
            // imgfile: base64data,
            documentName: event.target.files[0].name,
          });

          // uploadsignPic(base64data)
        };
      }
    }
  };

  const handleClickOpen = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const handleClose = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const capture = () => {
    const imageSrc = myRef.current.getScreenshot();
    // console.log(imageSrc)

    sessionStorage.setItem("signPic", imageSrc);
    setsignPic(imageSrc);
    setAllValues({
      ...allValues,
      // imgfile: imageSrc,
      open: !allValues.open,
    });
  };

  const getimage = (data) => {
    console.error("getimage data: " + data);
    if (data !== null) {
      dispatch(getFile(data, "signPic"));
    }
  };

  useEffect(() => {
    if (users.getFile !== null) {
      sessionStorage.setItem("signPic", users.getFile.data);
      setsignPic(users.getFile.data);
    }
  }, [users.getFile]);

  useEffect(() => {
    console.log("signPic: ", signPic);
    if (signPic !== undefined && signPic !== "" && signPic !== null) {
      sessionStorage.setItem("signPic", signPic);
    }
  }, [signPic]);

  useEffect(() => {
    setsignPic(sessionStorage.getItem("signPic"));
  }, [sessionStorage.getItem("signPic")]);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    props.history.push("/admin/dashboard");
  };

  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  useEffect(() => {
    sessionStorage.clear();
    console.log("location: ", location);
    if (location.userId && location.userrow) {
      console.log("location.userId: ", location.userId);
      console.log("location.userrow: ", location.userrow);
      // updateSaveBtnText(SAVEANDCONTINUE);
      var v = location.userrow;
      getimage(v.signature_upload_id);
      setAllValues({
        ...allValues,
        user_name: v.username,
        password: v.password,
        confirm_password: v.confirm_password,
        first_name: v.first_name,
        middle_name: v.middle_name,
        last_name: v.last_name,
        mobile_number: v.mobile_no,
        email: v.mail_id,
        kgid_no: v.employee_number,
        address: v.address,
        sakaladesignatedcode: v.designated_officer_code,

        // activeBoardItem: "KBOCWWB"
      });
    }
    // if( location.userId !== undefined &&
    //     location.userId !== null &&
    //     users.getDeptUsers !== undefined &&
    //     users.getDeptUsers.length > 0){
    //         var index = users.getConfigMinistryDetails.map( function(e) {return e.id;} ).indexOf(location.userId)
    //     // var val = users.getConfigMinistryDetails[index]
    // }
  }, []);

  useEffect(() => {
    console.log("allValues: ", allValues);
  }, [allValues]);

  const handleChangeCheckbox = (event) => {
    setchangePasswordCheckbox(event.target.checked);
  };

  const handleChange = (e) => {
    // if (e.target.name == "user_name"){
    //     if (validator.isAlpha(e.target.value) && (e.target.value.length) < 30){
    //         //  medicalAlphaError = false
    //         //  e.targ
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             userNameError: false
    //         })

    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             userNameError: true
    //         })
    //     }
    // }
    if (e.target.name === "first_name") {
      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          firstnameError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          firstnameError: true,
        });
      }
    } else if (e.target.name === "middle_name") {
      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          middle_nameError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          middle_nameError: true,
        });
      }
    } else if (e.target.name === "password") {
      if (e.target.value.length <= 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          password_Error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          password_Error: true,
        });
      }
    } else if (e.target.name === "confirm_password") {
      if (e.target.value.length <= 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          confirm_password_Error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          confirm_password_Error: true,
        });
      }
    } else if (e.target.name === "mobile_number") {
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          mobile_numberError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          mobile_numberError: true,
        });
      }
    } else if (e.target.name === "last_name") {
      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          last_nameError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          last_nameError: true,
        });
      }
    } else if (e.target.name === "email") {
      if (validator.isEmail(e.target.value)) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          emailError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          emailError: true,
        });
      }
    } else if (e.target.name === "kgid_no") {
      if (
        validator.isAlphanumeric(e.target.value) &&
        e.target.value.length <= 20
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          kgid_noError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          kgid_noError: true,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClosekboc = (event, data) => {
    setAnchorEl(null);
  };

  const onSelectStatusHandler = (event, data) => {
    setAllValues({
      ...allValues,
      activeBoardItem: data,
    });
    handleClosekboc();
  };

  const onSelectClickHandlerkboc = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const isNotUndefinedEmptyAndNotNull = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      return true;
    } else {
      return false;
    }
  };

  const checkFormValues = () => {
    return new Promise((resolve, reject) => {
      const {
        user_name,
        first_name,
        last_name,
        password,
        confirm_password,
        mobile_number,
        email,
        kgid_no,
        address,
        sakaladesignatedcode,
      } = allValues;

      if (location.userrow !== undefined) {
        if (changePasswordCheckbox) {
          if (
            isNotUndefinedEmptyAndNotNull(user_name) &&
            isNotUndefinedEmptyAndNotNull(first_name) &&
            isNotUndefinedEmptyAndNotNull(last_name) &&
            isNotUndefinedEmptyAndNotNull(password) &&
            isNotUndefinedEmptyAndNotNull(confirm_password) &&
            isNotUndefinedEmptyAndNotNull(mobile_number) &&
            isNotUndefinedEmptyAndNotNull(email) &&
            isNotUndefinedEmptyAndNotNull(kgid_no) &&
            isNotUndefinedEmptyAndNotNull(sakaladesignatedcode) &&
            allValues.mobile_numberError !== true
          ) {
            resolve("success");
          } else {
            reject("Please fill all mandatory fields correctly!");
          }
        } else {
          if (
            isNotUndefinedEmptyAndNotNull(user_name) &&
            isNotUndefinedEmptyAndNotNull(first_name) &&
            isNotUndefinedEmptyAndNotNull(last_name) &&
            // isNotUndefinedEmptyAndNotNull(password) &&
            // isNotUndefinedEmptyAndNotNull(confirm_password) &&
            isNotUndefinedEmptyAndNotNull(mobile_number) &&
            isNotUndefinedEmptyAndNotNull(email) &&
            isNotUndefinedEmptyAndNotNull(kgid_no) &&
            isNotUndefinedEmptyAndNotNull(address) &&
            isNotUndefinedEmptyAndNotNull(sakaladesignatedcode) &&
            allValues.mobile_numberError !== true
          ) {
            resolve("success");
          } else {
            reject("Please fill all mandatory fields correctly!");
          }
        }
      } else {
        if (
          isNotUndefinedEmptyAndNotNull(user_name) &&
          isNotUndefinedEmptyAndNotNull(first_name) &&
          isNotUndefinedEmptyAndNotNull(last_name) &&
          isNotUndefinedEmptyAndNotNull(password) &&
          isNotUndefinedEmptyAndNotNull(confirm_password) &&
          isNotUndefinedEmptyAndNotNull(mobile_number) &&
          isNotUndefinedEmptyAndNotNull(email) &&
          isNotUndefinedEmptyAndNotNull(address) &&
          // isNotUndefinedEmptyAndNotNull(kgid_no) &&
          // isNotUndefinedEmptyAndNotNull(sakaladesignatedcode) &&
          allValues.mobile_numberError !== true
        ) {
          resolve("success");
        } else {
          reject("Please fill all mandatory fields correctly!");
        }
      }
    });
  };

  const updatePassword = (insertUserResponse) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        username: allValues.user_name,
        password: allValues.password,
        next_expiry_date: "12-12-2030",
      });
      var config = {
        method: "post",
        url: SERVER + "/config/dept_user/update_dept_user_password",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const onSaveHandler = () => {
    if (allValues.password !== allValues.confirm_password) {
      showToast(
        "ERROR",
        "Password and Confirm Password field value does not match."
      );
    } else if (signPic === "" || signPic === null) {
      showToast("ERROR", "Please upload the Signature Image.");
    } else {
      setLoading(true);
      checkFormValues()
        .then((res) => {
          console.log("in then after checkFormValues");
          var FormData = require("form-data");

          var data = new FormData();
          // data.append('file', dataURLtoFile(data1.file, 'newfile'));
          // data.append('userId', data1.userId);
          // data.append('fileType', data1.fileType);
          data.append(
            "file",
            dataURLtoFile(sessionStorage.getItem("signPic"), "newfile")
          );
          data.append("userId", users.login_admin.data.department_user_id);
          data.append("fileType", "sign_pic");

          var config = {
            method: "post",
            url: SERVER + "/upload/file",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log("file upload response: ", response.data);

              if (response.data.success === true) {
                if (location.userrow !== undefined) {
                  var data = JSON.stringify({
                    id: location.userrow.department_user_id,
                    first_name: allValues.first_name,
                    middle_name: allValues.middle_name,
                    last_name: allValues.last_name,
                    username: allValues.user_name,
                    mobile_no: allValues.mobile_number,
                    password: allValues.password,
                    address: allValues.address,
                    mail_id: allValues.email,
                    employee_number: allValues.kgid_no,
                    user_id: users.getDeptUserRole.data[0].department_user_id,
                    signature_upload_id: response.data.image.fileId,
                    designated_officer_code: allValues.sakaladesignatedcode,
                  });
                  var config = {
                    method: "post",
                    url: SERVER + "/config/dept_user/update_dept_user",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };
                  axios(config)
                    .then(function (response) {
                      if (changePasswordCheckbox) {
                        updatePassword(response)
                          .then((pwdRes) => {
                            setLoading(false);
                            showToast(
                              "SUCCESS",
                              "User and Password Updated Successfully"
                            );
                            // props.history.goBack()
                            props.history.push("/admin/user-role-master", {
                              user_name: allValues.user_name,
                              user_id: response.data.data.recordset[0].user_id,
                              userId: location.userId,
                              userrow: location.userrow,
                            });
                          })
                          .catch((err) => {
                            setLoading(false);
                            showToast("ERROR", err.response);
                          });
                      } else {
                        setLoading(false);
                        showToast("SUCCESS", "User Updated Successfully");
                        // props.history.goBack()
                        props.history.push("/admin/user-role-master", {
                          user_name: allValues.user_name,
                          user_id: location.userrow.department_user_id,
                          userId: location.userId,
                          userrow: location.userrow,
                        });
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      setLoading(false);
                      showToast("ERROR", error.response);
                    });
                } else {
                  var data = JSON.stringify({
                    first_name: allValues.first_name,
                    middle_name: allValues.middle_name,
                    last_name: allValues.last_name,
                    username: allValues.user_name,
                    password: allValues.password,
                    mobile_no: allValues.mobile_number,
                    address: allValues.address,
                    mail_id: allValues.email,
                    employee_number: allValues.kgid_no,
                    user_id: users.getDeptUserRole.data[0].department_user_id,
                    signature_upload_id: response.data.image.fileId,
                    designated_officer_code: allValues.sakaladesignatedcode,
                  });
                  var config = {
                    method: "post",
                    url: SERVER + "/config/dept_user/insert_dept_user",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };
                  axios(config)
                    .then(function (response) {
                      console.log("insert_dept_user_response:", response);
                      updatePassword(response)
                        .then((pwdRes) => {
                          setLoading(false);
                          showToast("SUCCESS", "User Added Successfully");
                          // props.history.goBack()
                          props.history.push("/admin/user-role-master", {
                            user_name: allValues.user_name,
                            user_id: response.data.data.recordset[0].user_id,
                            userId: location.userId,
                            userrow: location.userrow,
                          });
                        })
                        .catch((err) => {
                          setLoading(false);
                          showToast("ERROR", err.response);
                        });
                    })
                    .catch(function (error) {
                      console.log(error);
                      setLoading(false);
                      showToast("ERROR", error.response);
                    });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
              showToast("ERROR", error);
            });
        })
        .catch((err) => {
          setLoading(false);
          showToast("ERROR", err);
        });
    }
  };

  return (
    <>
      <div className='root'>
        <Row className='top-div'>
          <Row className='topbar-row'>
            <Col xs={12} md={8} lg={9} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/admin/home'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  Government of Karnataka <br />
                  Karnataka Building & Other Construction Workers Welfare Board
                  (KBOCWWB)
                </h1>
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      <Row className='config-page-row-title'>
        <Col xs={12} className='title-col'>
          <Link
            to='#'
            onClick={() => props.history.push("/admin/userrolemapping")}
          >
            <img alt='...' className='cal-icon hover-icn' src={back} />
            <p className='title'>User Registration</p>
          </Link>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form'>
        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='User Name' />
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
              }
            }}
          >
            <TextField
              variant='outlined'
              placeholder='Enter User Name'
              name='user_name'
              value={allValues.user_name}
              onChange={handleChange}
              // error = {allValues.userNameError}
              //   helperText = {allValues.userNameError ? "Please enter alphabets only" : ""}
            />
          </FormControl>
        </Col>

        {location.userrow !== undefined ? null : (
          <>
            <Col xs={12} md={4} className='personal-col-1'>
              <Required className='mb-2 mt-3' title='Password' />
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  placeholder='Enter Password'
                  name='password'
                  type='password'
                  value={allValues.password}
                  onChange={handleChange}
                  helperText={
                    allValues.password_Error ? "Only 20 Characters allowed" : ""
                  }
                  error={allValues.password_Error}
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4} className='personal-col-1'>
              <Required className='mb-2 mt-3' title='Confirm Password' />
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  placeholder='Enter confirm password'
                  name='confirm_password'
                  type='password'
                  value={allValues.confirm_password}
                  onChange={handleChange}
                  helperText={
                    allValues.confirm_password_Error
                      ? "Only 20 Characters allowed"
                      : ""
                  }
                  error={allValues.confirm_password_Error}
                />
              </FormControl>
            </Col>
          </>
        )}

        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='First Name' />
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant='outlined'
              placeholder='Enter First Name'
              name='first_name'
              value={allValues.first_name}
              onChange={handleChange}
              helperText={
                allValues.firstnameError ? "Please enter alphabets only" : ""
              }
              error={allValues.firstnameError}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='personal-col-1'>
          <p className='mb-2 mt-3'>Middle Name</p>
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant='outlined'
              placeholder='Enter Middle Name'
              name='middle_name'
              value={allValues.middle_name}
              onChange={handleChange}
              helperText={
                allValues.middle_nameError ? "Please enter alphabets only" : ""
              }
              error={allValues.middle_nameError}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='Last Name' />
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant='outlined'
              placeholder='Enter Last Name'
              name='last_name'
              value={allValues.last_name}
              onChange={handleChange}
              helperText={
                allValues.last_nameError ? "Please enter alphabets only" : ""
              }
              error={allValues.last_nameError}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='personal-col-1'>
          {/* <Required className="mb-2 mt-3" title="Phone Number" /> */}
          <Required className='mb-2 mt-3' title='Mobile No' />
          <FormControl fullWidth className='formcontrol2'>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img
                      src={mobile}
                      alt='country code'
                      className='phone-icon'
                    />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              name='mobile_number'
              value={allValues.mobile_number}
              onChange={handleChange}
              helperText={
                allValues.mobile_numberError
                  ? "Please enter correct number"
                  : ""
              }
              error={allValues.mobile_numberError}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='Email Id' />
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              placeholder='Enter Email'
              name='email'
              value={allValues.email}
              onChange={handleChange}
              helperText={
                allValues.emailError ? "Please enter correct E-Mail" : ""
              }
              error={allValues.emailError}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='KGID NO' />
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              placeholder='Enter KGID NO'
              name='kgid_no'
              value={allValues.kgid_no}
              onChange={handleChange}
              helperText={
                allValues.kgid_noError ? "Only 20 Characters is allowed" : ""
              }
              error={allValues.kgid_noError}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={6} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='Address' />
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // handleEnter();
              }
            }}
          >
            <TextareaAutosize
              variant='outlined'
              multiline
              rows={3}
              placeholder='Enter Address'
              name='address'
              value={allValues.address}
              onChange={handleChange}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='personal-col-1'>
          <Required className='mb-2 mt-3' title='Sakala Designated Code' />
          <FormControl
            fullWidth
            className='formcontrol1'
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // handleEnter();
              }
            }}
          >
            <TextareaAutosize
              variant='outlined'
              placeholder='Enter Sakala Designated Code'
              name='sakaladesignatedcode'
              value={allValues.sakaladesignatedcode}
              onChange={handleChange}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={6} className='personal-col-2'>
          <Required className='mb-2 mt-3' title='Signature' />
          <div className='form-inner-card-right mt-0 p-0'>
            <div className='picupload'>
              {signPic !== undefined && signPic !== "" && signPic !== null ? (
                <>
                  <img
                    alt='...'
                    className='avatar border-gray'
                    src={`${signPic}`}
                    key={signPic}
                  />
                </>
              ) : (
                <>
                  <CameraAlt className='cameraAlt' />

                  <p
                    className='upload-title required-p'
                    style={{ width: "auto" }}
                  >
                    <Translate value='Upload Your Signature' /> <span>*</span>
                  </p>
                </>
              )}

              <div className='browse-buttons-div my-2'>
                <label htmlFor='upload-photo' className='browse-button m-0'>
                  <img alt='...' src={folder} />
                  <Translate value='browse' />
                </label>
                <input
                  type='file'
                  name='photo'
                  id='upload-photo'
                  onChange={handleFileChange}
                />
                {/* <div className="or-between-buttons" >
                                    or
                                </div>
                                <p onClick={handleClickOpen} className="webcam-button" >
                                    <img alt="..." src={webcam} /> <Translate value="useWebCamera"/>
                                </p> */}
              </div>

              <Dialog
                open={allValues.open}
                onClose={handleClose}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
                // fullWidth={true}
                // maxWidth='750px'
              >
                <DialogTitle id='scroll-dialog-title'>
                  <Translate value='captureImageforProfilePicture' />
                </DialogTitle>
                <DialogContent>
                  <Webcam
                    audio={false}
                    height={400}
                    ref={myRef}
                    screenshotFormat='image/jpeg'
                    // width={400}
                    mirrored
                    fullWidth
                    videoConstraints={videoConstraints}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={capture} color='primary'>
                    Capture
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <p className='instructions'>
              <span>
                <Translate value='instructions' />*
              </span>
              (Upload your signature picture in JPG / PNG format only)
              {/* <Translate value="uploadyourprofilepictureinjpgpngformatonly"/> */}
            </p>
          </div>
        </Col>

        {location.userrow !== undefined ? (
          <>
            <Col xs={12} className='personal-col-1 mt-5'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePasswordCheckbox}
                    onChange={handleChangeCheckbox}
                    name='changePasswordCheckbox'
                    // color="primary"
                  />
                }
                label='Change Existing Password'
              />
            </Col>

            {!changePasswordCheckbox ? null : (
              <>
                <Col xs={12} md={6} className='personal-col-1'>
                  <p className='mb-2 mt-3'>New Password</p>
                  <FormControl fullWidth className='formcontrol1'>
                    <TextField
                      variant='outlined'
                      placeholder='Enter Password'
                      name='password'
                      type='password'
                      value={allValues.password}
                      onChange={handleChange}
                      helperText={
                        allValues.password_Error
                          ? "Only 20 Characters allowed"
                          : ""
                      }
                      error={allValues.password_Error}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6} className='personal-col-1'>
                  <p className='mb-2 mt-3'>Confirm New Password</p>
                  <FormControl fullWidth className='formcontrol1'>
                    <TextField
                      variant='outlined'
                      placeholder='Enter confirm password'
                      name='confirm_password'
                      type='password'
                      value={allValues.confirm_password}
                      onChange={handleChange}
                      helperText={
                        allValues.confirm_password_Error
                          ? "Only 20 Characters allowed"
                          : ""
                      }
                      error={allValues.confirm_password_Error}
                    />
                  </FormControl>
                </Col>
              </>
            )}
          </>
        ) : null}

        <Col xs={12} className='mt-4' align='center'>
          {loading ? (
            <CircularProgress color='inherit' />
          ) : (
            <Button
              variant='outline-primary'
              className='save-button'
              onClick={onSaveHandler}
            >
              {saveBtnText}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserRoleMappingForm;
