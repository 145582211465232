import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from './Header'
import kbocwwb from 'assets/images/Image 8.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import construction from 'assets/images/construction.svg'
import welfare from 'assets/images/welfare.svg'
import unorganised from 'assets/images/unorganised.svg'
import migrant from 'assets/images/migrant.svg'
import { ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg'
import { setLocaleWithFallback } from 'store/actions/user.actions'
import Header2 from './Header2'
const Migrant = (props) => {
   

        const [isHover1, setIsHover1] = useState(false);
        const [isHover2, setIsHover2] = useState(false);
        const [isHover3, setIsHover3] = useState(false);
        const [isHover4, setIsHover4] = useState(false);
    const handlemouseOver1 = () =>{
    setIsHover1(true);
    }
    
    const handlemouseOut1 = () => {
    setIsHover1(false);
    }
    
    
    const handlemouseOver2 = () =>{
        setIsHover2(true);
        }
        
        const handlemouseOut2 = () => {
        setIsHover2(false);
        }
    
        const handlemouseOver3 = () =>{
            setIsHover3(true);
            }
            
            const handlemouseOut3 = () => {
            setIsHover3(false);
            }
    
            const handlemouseOver4 = () =>{
                setIsHover4(true);
                }
                
                const handlemouseOut4 = () => {
                setIsHover4(false);
                }
                const dispatch = useDispatch()
  const users = useSelector(state => state.users)
  const i18nlang = useSelector(state => state.i18n)

  const [allValues, setAllValues] = useState({
      language: users.profile_details.language,
      users: users.profile_details.users,
  })
  const handleChange1 = (e) => {
      console.log("kkkkk", e.target.value)
      if (e.target.value === "ka") {

          setAllValues({
              ...allValues,
              descriptionsKannada: true,
              [e.target.name]: e.target.value,

          })

      }
      else {
          setAllValues({
              ...allValues,
              descriptionsKannada: false,
              [e.target.name]: e.target.value,

          })
      }


      if (e.target.name === "language")
          dispatch(setLocaleWithFallback(e.target.value))
  }
    // const handleClick = () =>{
    //     props.history.push('/registration',{})
    // }
    const handleClick2 = () =>{
        props.history.push('/unorganized-registration')
    }
  return (
    <div  onClick={() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }}>
        <Header2 lang={allValues.language}/>
        <div className='d-flex justify-content-end container-fluid'>
        <Col xs={12} md={4} lg={3} className="d-flex justify-content-end mt-2 me-2">
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange1}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        </div>
    <Row className='container-fluid'>  
    <div className='schemes-home-div container-fluid m-auto ps-4'>
        <h3 className='schemesHome-title container-fluid'>About Migrant Worker</h3>
        <p className='schemes-instructions container-fluid'>
        The state government framed rules in interest of the construction labourers in 2007 in order to take up many welfare and development schemes. Keeping in mind the interest of the construction labourers,
        National Construction Labour Welfare Act of 1996 has been brought into force. In tune with this National Construction Labour Welfare Act of 1996, The state government has formulated certain guidelines and
        has been striving to take care of the labourers health issues, social security, education and other basic facilities. The Department of Labour, under the able guidance and leadership of the Hon’ble Minister and
        the Board has been moving forward in protecting the interest of the labourers. The Department of Labour has been moving forward in protecting the interest of the labourers. The Department of Labour has been
        working in tandem with Legal Services Authority, Credai and construction sector to protect the interest of the labourers.
        </p>
        <p className='schemes-instructions container-fluid'>
        The Department of Labour is committed towards the interest of labourers and hence has been formulating meaningful and concrete programmes, In this context, the funds meant for the benefit of the
        labourers is being directly credited to the account of the labourers.

        </p>
        <p className='schemes-instructions container-fluid'>
        We have taken steps to conduct a survey of labourers including those who are in construction sector. Our primary goal is to provide relief to the families of labourers well in time in order to mitigate their
        problems.
        </p>
        <p className='schemes-instructions container-fluid'>

        In the present atmosphere of global crisis on account of Covid-19, we have given fillip to the departmental activities in order to make government labour-friendly. The entire department involved in providing
        relief and succor in the form of food packets and water to the distressed labour. The Department of Labour made transport arrangements, provided food packets and water to the migrant labourers who were
        returning to their native places.The Department of Labour will continue to work in the interest of labourers with more zeal and dynamism in the next coming days.
        </p>
    </div>
    </Row>
    <div>
    <Row className="homepage-row main-screens-boardsHome migrant-boards container-fluid">

   
<Col xs={6} sm={6} md={6} lg={6} xl={6} >
<Link to="/migrant-registration">
    
<div className={isHover1 ? "homepage-image-div boards-hover":"homepage-image-div boards"} 
                onMouseOver={handlemouseOver1} onMouseOut={handlemouseOut1} style={{height:'230px'}}
                >
                    {isHover1 ?<div className='outer-img-boards-hover mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={construction} onMouseOver={handlemouseOver1} /></div> : <div className='outer-img-boards mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={construction} onMouseOut={handlemouseOut1}/></div>}
                    {/* <p>Karnataka Building & Other<br />Construction Workers Welfare Board</p> */}
                    <p></p>
                    <p className={isHover1 ? "text-hover":"text-nohover" } onMouseOver={handlemouseOver1} onMouseOut={handlemouseOut1} >Register as Construction Worker</p>
                </div>
    </Link>

</Col>




<Col xs={6} sm={6} md={6} lg={6} xl={6}>
<Link to={{pathname:"/unorganized-registration", state:{title:"Migrant Registration Form"}}}>
<div className={isHover3 ? "homepage-image-div boards-hover":"homepage-image-div boards"} 
                onMouseOver={handlemouseOver3} onMouseOut={handlemouseOut3} style={{height:'230px'}} >
    {isHover3 ?<div className='outer-img-boards-hover mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={unorganised} onMouseOver={handlemouseOver3} /></div> :<div className='outer-img-boards mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={unorganised} onMouseOut={handlemouseOut3}/></div> }
        <p className={isHover3 ? "text-hover":"text-nohover" } onMouseOver={handlemouseOver3} onMouseOut={handlemouseOut3}>Register as Unorganized Worker</p>
    </div>
</Link>
</Col>



</Row>
    </div>
    <div className='d-flex justify-content-end mt-5 me-5 mb-5'>
    <Link to='/'>
    <Button className='back-btn-mainScreens'><ArrowBackIosIcon color='secondary' className='back-icon'/>Back</Button>
    </Link>
    </div>
    </div>
  )
}

export default Migrant