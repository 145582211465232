import {
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

const MarriageAssistance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  disableSelf,
  loading,
  kannadaSelected,
}) => {
  console.log('disableSelf', disableSelf);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3">
            Please wait fetching fields....
          </p>
        </div>
      ) : (
        dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map((field) => (
          <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={field.id}>
            <Required
              className="mb-2 mt-4"
              title={
                kannadaSelected ? field.field_name_kannada : field.field_name
              }
            />
            {field.field_type === 'Text' && (
              <>
                {formData &&
                formData['Marriage Assistance is for'] === 8 &&
                field.field_name === 'Groom Name' ? (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                    >
                      <MenuItem value="">
                        <ListItemText primary="Select" />
                      </MenuItem>
                      {familyDetails &&
                        familyDetails
                          .filter(
                            (member) =>
                              member.catalog_value_parent_child_type_id === 54,
                          )
                          .map((fam) => (
                            <MenuItem
                              value={`${fam.first_name.trim() ?? ''} ${
                                fam?.middle_name?.trim() ?? ''
                              } ${fam?.last_name?.trim() ?? ''}`}
                            >
                              <ListItemText
                                primary={`${fam.first_name.trim() ?? ''} ${
                                  fam?.middle_name?.trim() ?? ''
                                } ${fam?.last_name?.trim() ?? ''}`}
                              />
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                ) : formData &&
                  formData['Marriage Assistance is for'] === 9 &&
                  field.field_name === 'Bride Name' ? (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                    >
                      <MenuItem value="">
                        <ListItemText primary="Select" />
                      </MenuItem>
                      {familyDetails &&
                        familyDetails
                          .filter(
                            (member) =>
                              member.catalog_value_parent_child_type_id === 53,
                          )
                          .map((fam) => (
                            <MenuItem
                              value={`${fam.first_name.trim() ?? ''} ${
                                fam?.middle_name?.trim() ?? ''
                              } ${fam?.last_name?.trim() ?? ''}`}
                            >
                              <ListItemText
                                primary={`${fam.first_name.trim() ?? ''} ${
                                  fam?.middle_name?.trim() ?? ''
                                } ${fam?.last_name?.trim() ?? ''}`}
                              />
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      type={'text'}
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                      variant="outlined"
                      disabled={field.field_name === 'Age'}
                      // helperText={allValues['error_' + field.id]}
                      // error={allValues.globalError}
                      // disabled={
                      //   field.field_name.includes(
                      //     'Name of the dependent ',
                      //   ) && allValues.medicalAssistanceSelf
                      // }
                    />
                  </FormControl>
                )}
              </>
            )}

            {/* {formData && formData['Marriage Assistance is for'] === 8
              ? field.field_name === 'Groom Name' && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                    >
                      <MenuItem value="">
                        <ListItemText primary="Select" />
                      </MenuItem>
                      {familyDetails &&
                        familyDetails
                          .filter(
                            (member) =>
                              member.catalog_value_parent_child_type_id === 53,
                          )
                          .map((fam) => (
                            <MenuItem value={fam.first_name.trim()}>
                              <ListItemText
                                primary={[
                                  fam.first_name,
                                  fam.middle_name,
                                  fam.last_name,
                                ].join(' ')}
                              />
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                )
              : formData && formData['Marriage Assistance is for'] === 9
                ? field.field_name === 'Bride Name' && (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontrol5"
                    >
                      <Select
                        className="select-marital"
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        name={field.field_name}
                        onChange={(ev) =>
                          handleChange(ev, field, field.field_name)
                        }
                        value={formData && formData[field.field_name]}
                      >
                        <MenuItem value="">
                          <ListItemText primary="Select" />
                        </MenuItem>
                        {familyDetails &&
                          familyDetails
                            .filter(
                              (member) =>
                                member.catalog_value_parent_child_type_id ===
                                54,
                            )
                            .map((fam) => (
                              <MenuItem value={fam.first_name.trim()}>
                                <ListItemText
                                  primary={[
                                    fam.first_name,
                                    fam.middle_name,
                                    fam.last_name,
                                  ].join(' ')}
                                />
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )
                : null} */}

            {field.field_type === 'Date' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDate={
                    field.field_name.includes('Date Of Marriage')
                      ? moment('2023-10-17', 'YYYY-MM-DD').subtract(6, 'months')
                      : new Date(-999999)
                  }
                  maxDate={new Date()}
                  className="datepicker"
                  margin="normal"
                  format="DD/MM/YYYY"
                  name={field.field_name}
                  value={
                    formData && formData[field.field_name]
                      ? formData[field.field_name]
                      : null
                  }
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  // onChange={setDate}
                  placeholder="DD/MM/YYYY"
                  // error={allValues.globalError}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}

            {(field.field_type === 'Dropdown' ||
              field.field_type === 'Radio Button') && (
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital"
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name={field.field_name}
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  value={(formData && formData[field.field_name]) || '0'}
                >
                  <MenuItem value="0">
                    <ListItemText primary={`Select`} />
                  </MenuItem>
                  {field.field_data.map((field) =>
                    field.field_name === 'Marriage Assistance is for' ? (
                      disableSelf > 0 ? (
                        field.field_value !== 'Self' && (
                          <MenuItem value={field.field_value_id}>
                            <ListItemText primary={field.field_value} />
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem value={field.field_value_id}>
                          <ListItemText primary={field.field_value} />
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem value={field.field_value_id}>
                        <ListItemText primary={field.field_value} />
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
          </Col>
        ))
      )}
    </>
  );
};

export default MarriageAssistance;
