import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Backdrop, Paper, makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AddCircleRounded, CheckCircle } from '@material-ui/icons';

const SuccessBackdrop = ({ open, handleClose }) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffff',

      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Backdrop
        color="white"
        open={open}
        onClick={handleClose}
        className={classes.backdrop}
      >
        <div style={{ display: 'block' }}>
          <CheckCircle
            className={classes.backdropCheck}
            style={{ height: 'auto', width: '200px' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="final-success-title">Successfully Submitted</p>
            <Link to={'/login'}>
              <Typography variant="h4">
                {' '}
                Please login to access your E-Card
              </Typography>
            </Link>
          </div>
        </div>
      </Backdrop>
    </>
  );
};

export default SuccessBackdrop;
