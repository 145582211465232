/* eslint-disable no-throw-literal */

import welfareUploadFileAPI from 'utils/welfareUpload';

export const SevaSindhuFileUpload = async (
  bankData,
  existingCard,
  bankPassbook,
  nomineePassbook,
) => {
  // Check if bankData length is 2 and existingCard and bankPassbook have length > 0
  if (
    bankData.bankDetails.length === 1 &&
    existingCard &&
    existingCard.length > 0 &&
    bankPassbook &&
    bankPassbook.length > 0
  ) {
    const promises1 = existingCard.map((doc) => welfareUploadFileAPI(doc));
    const promises2 = bankPassbook.map((doc) => welfareUploadFileAPI(doc));

    // Upload existing card and bank passbook documents
    return Promise.all([...promises1, ...promises2])
      .then((responses) => {
        const existingCardResponses = responses
          .slice(0, promises1.length)
          .map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));
        const bankPassbookResponses = responses
          .slice(promises1.length)
          .map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));

        // Return the responses
        return {
          success: true,
          sevaSindhuCard: existingCardResponses,
          bankPassbook: bankPassbookResponses,
        };
      })
      .catch((error) => {
        // Handle error
        throw {
          success: false,
          msg: 'Error in uploading file! Please try again',
        };
      });
  } else if (
    bankData.bankDetails.length === 2 &&
    existingCard &&
    existingCard.length > 0 &&
    bankPassbook &&
    bankPassbook.length > 0 &&
    nomineePassbook &&
    nomineePassbook.length > 0
  ) {
    const promises1 = [];
    existingCard.forEach((doc) => {
      promises1.push(welfareUploadFileAPI(doc));
    });
    const promises2 = [];
    bankPassbook.forEach((doc) => {
      promises2.push(welfareUploadFileAPI(doc));
    });

    const promises3 = [];
    nomineePassbook.forEach((doc) => {
      promises3.push(welfareUploadFileAPI(doc));
    });
    const allPromises = [...promises1, ...promises2, ...promises3];

    return Promise.all(allPromises)
      .then((responses) => {
        const responses1 = responses.slice(0, promises1.length);
        const responses2 = responses.slice(
          promises1.length,
          promises1.length + promises2.length,
        );
        const responses3 = responses.slice(promises1.length + promises2.length);

        const existingCardResponses = responses1.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
        }));
        const bankPassbookResponses = responses2.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
        }));
        const nomineePassbookResponses = responses3.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
        }));
        // Continue processing or handle the responses as needed
        return {
          success: true,
          sevaSindhuCard: existingCardResponses,
          bankPassbook: bankPassbookResponses,
          nomineePassbook: nomineePassbookResponses,
        };
      })
      .catch((error) => {
        throw {
          success: false,
          msg: 'Error in uploading file! Please try again',
        };
      });
  } else {
    // Return null if conditions are not met
    return null;
  }
};
