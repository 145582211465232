import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
const AccidentUnorganizedForm = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    worker_name: "",
    worker_name_error: false,
    mobile_number: "",
    mobile_number_error: false,
    organization_name: "",
    organization_name_error: false,
    designation: "",
    designation_error: "",
    monthly_salary: "",
    monthly_salary_error: "",
    nature_disease: "",
    nature_disease_error: "",
    duration_treatment: "",
    duration_treatment_error: "",
    amount_spent: "",
    amount_spent_error: "",
    account_no: "",
    account_no_error: "",
    ifsc_code: "",
    ifsc_code_error: "",
    openDatePicker: false,
  });

  const inputRef = useRef(null);
  const [fileObj, setFileObj] = useState("");
  const [selectedfiles, setselectedfiles] = React.useState([]);
  const [selectedFilesPostmortem, setselectedFilesPostmortem] = React.useState(
    {}
  );
  const [selectedDeath, setselectedDeath] = React.useState({});
  const [selectedDeathCert, setselectedDeathCert] = React.useState({});
  const [selectedNomine, setselectedNomine] = React.useState({});
  const [selectedRation, setselectedRation] = React.useState({});
  const [selectedBank, setselectedBank] = React.useState({});
  const [selectedLeir, setselectedLeir] = React.useState({});
  const [UserDetails, setUserDetails] = React.useState({});
  const [datefieldname, setdatefieldname] = React.useState("");
  const [datefieldid, setdatefieldid] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [appNo, setappNo] = React.useState("");

  const [Age, setAge] = React.useState({});
  const [nameOfDateElement, setnameOfDateElement] = React.useState("");
  const [currentdocname, setcurrentdocname] = React.useState({});

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false);
  const [fileuploadresults, setfileuploadresults] = React.useState({});
  const [educationSelected, setEducationSelected] = React.useState(false);
  const [role_id_from_hirarchy, setrole_id_from_hirarchy] = React.useState("");

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [photoUpload, setphotoUpload] = React.useState({});
  const [attestedDoc, setAttestedDoc] = React.useState({});
  const [medicalDoc, setMedicalDoc] = React.useState({});
  const [medicalDocCert, setMedicalDocCert] = React.useState({});
  const [filesXerox, setFilesXerox] = React.useState({});
  const [filesRation, setFilesRation] = React.useState({});
  const [filesBank, setFilesBank] = React.useState({});
  const [filesLeir, setFilesLeir] = React.useState({});
  const [dob, setDob] = React.useState(users.personal_details.dob);

  const [filelist, setfilelist] = React.useState([]);
  const [filelistAttested, setfilelistAttested] = React.useState({});
  const [filelistMedical, setfilelistMedical] = React.useState({});
  const [filelistMedicalCert, setfilelistMedicalCert] = React.useState({});
  const [filelistFilesXerox, setfilelistFilesXerox] = React.useState({});
  const [filelistFilesRation, setfilelistFilesRation] = React.useState({});
  const [filelistFilesBank, setfilelistFilesBank] = React.useState({});
  const [filelistFilesLeir, setfilelistFilesLeir] = React.useState({});

  useEffect(() => {
    console.log("selectedfiles", selectedRation);
  }, [selectedRation]);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };
  // const handleChange = event => {
  //     const fileUploaded = event.target.files[0];
  //     props.handleFile(fileUploaded);
  // };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  const handleChange = (e, field) => {
    console.log(e.target);
    console.log("SchemeName: ", e.target.name);
    console.log(e.target.name + " : " + e.target.value);

    var nameError = false;
    var nameNomineeError = false;
    var phoneError = false;
    var phoneNomineeError = false;
    var medicalAssistanceSelf = false;

    // var medicalAlphaError = true

    if (e.target.name === "Benefit Type") {
      if (e.target.value === 183) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
        });
        setEducationSelected(true);
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
        });
        setEducationSelected(false);
      }
    } else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
        nameError: nameError,
        nameNomineeError: nameNomineeError,
        phoneError: phoneError,
        phoneNomineeError: phoneNomineeError,
        medicalAssistanceSelf: medicalAssistanceSelf,
        // medicalAlphaError : medicalAlphaError,
        // medicalCostError : medicalCostError
      });
    }

    // if(e.target.value == 62 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value ==  63 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value == 66 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value == 68){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }

    // var medicalCostError = false
    // if(e.target.value == 67){
    //     if (validator.isNumeric(e.target.value) && e.target.value.length < 30){
    //         medicalCostError = true
    //     }
    // }

    // setAllValues({
    //     ...allValues,
    //     [e.target.name]: e.target.value,
    //     nameError: nameError,
    //     nameNomineeError: nameNomineeError,
    //     phoneError: phoneError,
    //     phoneNomineeError: phoneNomineeError,
    //     medicalAssistanceSelf:medicalAssistanceSelf,
    //     medicalAlphaError : medicalAlphaError,
    //     // medicalCostError : medicalCostError
    // })

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  useEffect(() => {
    if (selectedfilesPhoto) {
      nextButtonPressed();
    }
  }, [selectedfilesPhoto]);

  // useEffect (() => {
  //     var data = JSON.stringify({
  //         "key": "user_id",
  //         "value": users.user.id,
  //         "board_id": 4,
  //         "procedure_name": "all"
  //     });
  //     var config = {
  //         method: 'post',
  //         url: SERVER + '/user/get-user-registration-details',
  //         headers: {
  //             'Authorization': `Bearer ${getTokenCookie()}`
  //         },
  //         data: data
  //     };
  //     axios(config)
  //         .then(function (response) {
  //             console.log("get-user-registration-details response:" + JSON.stringify(response.data, undefined, 2));
  //             setUserDetails(response.data.data)
  //         })
  //         .catch(function (error) {
  //             console.error("get-user-registration-details error:" + error);
  //         });
  // },[])
  useEffect(() => {
    console.log(
      "useeffect UserDetails: " + JSON.stringify(UserDetails, undefined, 2)
    );
    if (
      UserDetails !== null &&
      UserDetails.personal_details !== undefined &&
      UserDetails.personal_details.length > 0
    ) {
      console.log(
        "useeffect UserDetails.personal_details : " +
          UserDetails.personal_details.length
      );
      var dateString = UserDetails.personal_details[0].date_of_birth;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format("DD-MM-YYYY");
      console.log("momentString: " + momentString);
      var age = moment().diff(momentObj, "years");
      console.log("age: " + age);

      setAge(age);
      // console.log("age: " + UserDetails.address_details[0].ward_area_village)
    }
    // console.log("USerStatus1", UserDetails.certificate_details[0].registration_status)
  }, [UserDetails]);

  const onChangeDate = (event, field) => {
    console.log("field ", field);

    setdatefieldname(field.field_name);
    setdatefieldid(field.id);

    setPickerStatus(true);
    let name = event.currentTarget.parentElement.textContent;
    name = name.split("*")[0].trim();
    console.log(name);
    setnameOfDateElement(name);
  };
  const setDate = (date) => {
    console.log("in setDate: date= " + date);
    setDob(date);
  };

  const [customError, setCustomError] = useState("");
  const handleChangeValidation = (ev) => {
    if (ev.target.name === "worker_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: true,
        });
      }
    } else if (ev.target.name === "mobile_number") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: true,
        });
      }
    } else if (ev.target.name === "organization_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: true,
        });
      }
    } else if (ev.target.name === "designation") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: true,
        });
      }
    } else if (ev.target.name === "monthly_salary") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: true,
        });
      }
    } else if (ev.target.name === "nature_disease") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: true,
        });
      }
    } else if (ev.target.name === "duration_treatment") {
      if (
        validator.isAlphanumeric(ev.target.value) &&
        ev.target.value.length <= 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: true,
        });
      }
    } else if (ev.target.name === "amount_spent") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: true,
        });
      }
    } else if (ev.target.name === "account_no") {
      if (validator.isNumeric(ev.target.value) && ev.target.value.length < 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: true,
        });
      }
    } else if (ev.target.name === "ifsc_code") {
      if (validator.matches(ev.target.value, "[A-Z]{4}0[A-Z0-9]{6}$")) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: true,
        });
      }
    }
  };
  const getFieldValues = async (field) => {
    return new Promise((resolve, reject) => {
      var dataNEW = JSON.stringify({
        board_id: 4,
        field_id: field.id,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_fields_value",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          resolve({
            fieldName: field.field_name,
            data: response.data,
          });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const handleFileChange = (event, data) => {
    console.log("handleFileChange data: " + data);
    // console.log("selectedfiles.length: " + selectedfiles.length)
    // console.log("selectedfiles[data]: " + selectedfiles[data])
    // console.log("selectedfiles[data].length: " + selectedfiles[data].length)
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      var joined = {};
      if (selectedfiles[data] !== undefined) {
        // joined = selectedfiles[data]
        console.log("here");
        console.log(
          Array.from(selectedfiles[data]).concat(Array.from(event.target.files))
        );
        joined = {
          ...selectedfiles,
          [data]: Array.from(selectedfiles[data]).concat(
            Array.from(event.target.files)
          ),
        };
      } else {
        joined = {
          ...selectedfiles,
          [data]: Array.from(event.target.files),
        };
      }

      // joined[data] = Array.from(event.target.files)

      // console.log("joined: ")
      // console.log(joined)

      setselectedfiles(joined);
    }

    setloadingfiles(false);

    // var selectedblob = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(selectedblob);
    // reader.onloadend = function () {
    //     var base64data = reader.result;
    //     console.log(base64data);

    //     sessionStorage.setItem(data, base64data)
    //     setAllValues({
    //         ...allValues,
    //         // imgfile: base64data,
    //         [data]: event.target.files[0].name
    //     })
    // }
  };
  function returnComponent() {
    if (allValues.fields !== undefined) {
      // console.log(allValues.fields,"sgssfhgsdfdgtt")
      return allValues.fields.map((field, i) => {
        console.log("allValues.fields-> field:" + JSON.stringify(field));
        switch (field.field_type) {
          case "Dropdown":
          case "Radio Button":
            console.log("in drop");
            return (
              <>
                {educationSelected ? (
                  <>
                    {field.field_name.includes("Studying In") ||
                    field.field_name.includes("Benefit Type") ? (
                      <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                        {field.is_required ? (
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                        ) : (
                          <p className='mb-2 mt-4'>{field.field_name} </p>
                        )}
                        <FormControl
                          variant='outlined'
                          fullWidth
                          className='formcontrol5'
                        >
                          <Select
                            className='select-marital'
                            labelId='demo-simple-select-required-label'
                            id='demo-simple-select-required'
                            // value={allValues.marriage_assistance_for}
                            // name="marriage_assistance_for"
                            name={field.field_name}
                            value={
                              allValues[field.field_name] !== undefined
                                ? allValues[field.field_name]
                                : ""
                            }
                            displayEmpty
                            onChange={handleChange}
                          >
                            <MenuItem value=''>
                              <ListItemText primary='Select' />
                            </MenuItem>

                            {allValues.promResponses.map((i) =>
                              i.fieldName === field.field_name
                                ? i.data.data.map((j) => (
                                    <MenuItem value={j.id}>
                                      <ListItemText primary={j.value} />
                                    </MenuItem>
                                  ))
                                : null
                            )}
                          </Select>
                        </FormControl>
                      </Col>
                    ) : null}
                  </>
                ) : (
                  <>
                    {field.field_name.includes("Benefit Type") ? (
                      <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                        {field.is_required ? (
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                        ) : (
                          <p className='mb-2 mt-4'>{field.field_name} </p>
                        )}
                        <FormControl
                          variant='outlined'
                          fullWidth
                          className='formcontrol5'
                        >
                          <Select
                            className='select-marital'
                            labelId='demo-simple-select-required-label'
                            id='demo-simple-select-required'
                            // value={allValues.marriage_assistance_for}
                            // name="marriage_assistance_for"
                            name={field.field_name}
                            value={
                              allValues[field.field_name] !== undefined
                                ? allValues[field.field_name]
                                : ""
                            }
                            displayEmpty
                            onChange={handleChange}
                          >
                            <MenuItem value=''>
                              <ListItemText primary='Select' />
                            </MenuItem>

                            {allValues.promResponses.map((i) =>
                              i.fieldName === field.field_name
                                ? i.data.data.map((j) => (
                                    <MenuItem value={j.id}>
                                      <ListItemText primary={j.value} />
                                    </MenuItem>
                                  ))
                                : null
                            )}
                          </Select>
                        </FormControl>
                      </Col>
                    ) : null}
                  </>
                )}
              </>
            );

          case "Text":
            console.log("in text, field.field_name: ", field.field_name);

            return (
              <>
                {console.log(" in101")}
                {educationSelected ? (
                  <>
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in104")}
                      <Required
                        className='mb-2 mt-4'
                        title={
                          allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name
                        }
                      />
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          // placeholder="Enter Bank Name"
                          name={field.field_name}
                          value={allValues[field.field_name]}
                          // value={
                          //     field.field_name.includes("Name of the dependent  ") &&
                          //     allValues.medicalAssistanceSelf ? allValues."" : allValues[field.field_name]}
                          onChange={(e) => handleChange(e, field)}
                          // type = {field.field_name.includes("Cost") ? "number": "text"}
                          // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                          helperText={allValues["error_" + field.id]}
                          error={allValues.globalError}
                          disabled={
                            field.field_name.includes(
                              "Name of the dependent "
                            ) && allValues.medicalAssistanceSelf
                          }

                          //value={allValues[field.field_name]}
                          //my
                          // {
                          //     field.field_name == "From(Source address)"?
                          //     inputProps={{ maxLength: 12 }}
                          //     :null
                          // }
                        />
                      </FormControl>
                    </Col>
                  </>
                ) : null}
              </>
            );

          case "Date":
            console.log("in date", field.id);
            console.log("in date1", educationSelected);

            return (
              <>
                {educationSelected ? (
                  <>
                    {field.id === 162 ? (
                      <>
                        <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                          {field.is_required ? (
                            <Required
                              className='mb-2 mt-4'
                              title={
                                allValues.descriptionsKannada
                                  ? field.field_nake_kannada
                                  : field.field_name
                              }
                            />
                          ) : (
                            <p className='mb-2 mt-4'>{field.field_name} </p>
                          )}
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              maxDate={new Date()}
                              onClick={(e) => onChangeDate(e, field)}
                              onClose={() => setPickerStatus(false)}
                              open={allValues.openDatePicker}
                              className='datepicker'
                              margin='normal'
                              format='DD/MM/YYYY'
                              name={field.field_name}
                              // id={id}
                              value={
                                allValues[field.field_name] !== undefined
                                  ? moment(allValues[field.field_name])
                                  : null
                              }
                              onChange={setDate}
                              placeholder='DD/MM/YYYY'
                              error={allValues.globalError}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {field.id === 162 ? null : (
                      <>
                        <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                          {field.is_required ? (
                            <Required
                              className='mb-2 mt-4'
                              title={
                                allValues.descriptionsKannada
                                  ? field.field_nake_kannada
                                  : field.field_name
                              }
                            />
                          ) : (
                            <p className='mb-2 mt-4'>{field.field_name} </p>
                          )}
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              maxDate={new Date()}
                              onClick={(e) => onChangeDate(e, field)}
                              onClose={() => setPickerStatus(false)}
                              open={allValues.openDatePicker}
                              className='datepicker'
                              margin='normal'
                              format='DD/MM/YYYY'
                              name={field.field_name}
                              // id={id}
                              value={
                                allValues[field.field_name] !== undefined
                                  ? moment(allValues[field.field_name])
                                  : null
                              }
                              onChange={setDate}
                              placeholder='DD/MM/YYYY'
                              error={allValues.globalError}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            );

          default:
            console.log("in default");
            return null;
        }
      });
    }
  }

  const nextButtonPressed = () => {};

  const dynamicFields = () => {
    return returnComponent();
  };

  useEffect(() => {
    console.log("dob changed: dob= " + dob);
    console.log("datefieldid: ", datefieldid);
    // if (role_id === 1) {

    var minimumDateAdmissionMedical = "01-01-1990";
    var minimumDateAdmissionMedicalDis = moment();

    if (datefieldid === 64) {
      var dateStringMed = dob;
      console.log("dateStringMed: ", dateStringMed);
      var dateObjMed = new Date(dateStringMed);
      var momentObjMed = moment(dateObjMed);
      // var momentAddDay = moment(momentObjMed).add(1, 'days');
      // var momentStringMed = momentAddDay.format('DD-MM-YYYY');
      var momentObjStringMed = momentObjMed.format("DD-MM-YYYY");
      minimumDateAdmissionMedical = momentObjMed;
    } else if (datefieldid === 65) {
      var dateStringMed1 = dob;
      console.log("dateStringMed1: ", dateStringMed1);
      var dateObjMed1 = new Date(dateStringMed1);
      var momentObjMed1 = moment(dateObjMed1);
      var momentObjStringMed1 = momentObjMed1.format("DD-MM-YYYY");

      minimumDateAdmissionMedicalDis = momentObjMed1;
    }

    console.log("momentStringMedObjad == " + minimumDateAdmissionMedical);
    console.log("momentStringMedObjdis == " + minimumDateAdmissionMedicalDis);

    setAllValues({
      ...allValues,
      [nameOfDateElement]: dob,
    });
    // }
  }, [dob]);

  // const submitButtonPressed = (e) => {
  //     console.log("mandatoryforDeath0")

  //         var mandatoryFilled = true
  //         allValues.fields.forEach(field => {

  //             console.log("field: ", field)
  //             console.log("field.is_required: ", field.is_required)
  //             if (field.is_required === true) {
  //                 console.log("allValues[field.field_name]: ", allValues[field.field_name])
  //                 if (
  //                     allValues[field.field_name] === undefined ||
  //                     allValues[field.field_name] === ""
  //                 ) {
  //                     console.log("mandatoryforDeath1")
  //                     if (allValues.showDeathfields) {
  //                         console.log("mandatoryLoop00 ")
  //                         if (field.field_name.includes("Applicant Type") ||
  //                             field.field_name.includes("Name of the Applicant") ||
  //                             field.field_name.includes("Phone Number") ||
  //                             field.field_name.includes("Age of Applicant") ||
  //                             field.field_name.includes("Permanent Address") ||
  //                             field.field_name.includes("Relationship with Beneficiary") ||
  //                             field.field_name.includes("Aadhaar Number") ||
  //                             field.field_name.includes("Applicant Bank Name") ||
  //                             field.field_name.includes("Applicant Account Number") ||
  //                             field.field_name.includes("Applicant Bank Branch") ||
  //                             field.field_name.includes("Applicant  Bank IFSC Code")

  //                         ) {
  //                             console.log("mandatoryLoop1 ")
  //                             mandatoryFilled = false
  //                         }
  //                         else if (field.field_name.includes("Employer Name at the time of accident") ||
  //                             field.field_name.includes("Employer address at the time of accident") ||
  //                             field.field_name.includes("Accident Resulted In")) {
  //                             mandatoryFilled = false
  //                         }

  //                     }
  //                     // else if (!allValues.showDeathfields && idName === "Accident Assistance") {
  //                     //     if (field.field_name.includes("Employer Name at the time of accident") ||
  //                     //         field.field_name.includes("Employer address at the time of accident") ||
  //                     //         field.field_name.includes("Accident Resulted In")) {
  //                     //         console.log("mandatoryLoopD1")
  //                     //         mandatoryFilled = false
  //                     //     }

  //                     // }
  //                     else if (allValues.showBrideData) {
  //                         console.log("mandatoryLoop9 ")

  //                         console.log("mandatoryLoop4 ")

  //                         if (
  //                             field.field_name.includes("Bride")
  //                         ) {
  //                             if (
  //                                 allValues[field.field_name] === undefined ||
  //                                 allValues[field.field_name] === ""
  //                             ) {
  //                                 mandatoryFilled = false
  //                             }
  //                         }

  //                     }
  //                     else {
  //                         console.log("mandatoryLoop8")
  //                         mandatoryFilled = false
  //                     }

  //                 }

  //             }

  //         })
  //         console.log("mandatoryLoop10")

  //         var minus_one_doc_for_affidavit1 = false
  //         var minus_one_doc_for_postmortem1 = false
  //         var minus_one_doc_for_disability1 = false

  //         if (allValues["Married Outside Karnataka"] !== undefined &&
  //             allValues["Married Outside Karnataka"] !== 177) {
  //             minus_one_doc_for_affidavit1 = true

  //         }
  //         else if (allValues["Accident Resulted In"] !== undefined &&
  //             allValues["Accident Resulted In"] !== 27) {
  //             minus_one_doc_for_postmortem1 = true
  //         }
  //         else if (allValues["Accident Resulted In"] !== undefined &&
  //             allValues["Accident Resulted In"] === 27) {
  //             minus_one_doc_for_disability1 = true
  //         }

  //         console.log("minus_one_doc_for_postmortem1: ", minus_one_doc_for_postmortem1)
  //         console.log("minus_one_doc_for_affidavit1: ", minus_one_doc_for_affidavit1)
  //         console.log("allValues.schemeDetails.document_details.length: ", allValues.schemeDetails.document_details.length)
  //         console.log("Object.keys(selectedfiles).length: ", Object.keys(selectedfiles).length)
  //         console.log("(allValues.schemeDetails.document_details.length - 1): ", (allValues.schemeDetails.document_details.length - 1))

  //         if (!mandatoryFilled) {
  //             console.log("in if 1")
  //             showToast("ERROR", "Please fill all the mandatory fields.")
  //         }
  //         else if (
  //             allValues["Married Outside Karnataka"] === 177 && !minus_one_doc_for_affidavit1 &&
  //             Object.keys(selectedfiles).length !== allValues.schemeDetails.document_details.length
  //         ) {
  //             console.log("in else 5")
  //             showToast("ERROR", "Please upload the documents.")
  //         }

  //         else if (
  //             minus_one_doc_for_affidavit1 &&
  //             Object.keys(selectedfiles).length !== (allValues.schemeDetails.document_details.length - 1)
  //         ) {
  //             console.log("in else 2")
  //             showToast("ERROR", "Please upload the documents.")
  //         }
  //         else if (
  //             minus_one_doc_for_postmortem1 &&
  //             Object.keys(selectedfiles).length !== (allValues.schemeDetails.document_details.length - 1)
  //         ) {
  //             console.log("in else 3")
  //             showToast("ERROR", "Please upload the documents.")
  //         }
  //         else if ((allValues["Accident Resulted In"] !== undefined &&
  //             allValues["Accident Resulted In"] === 27) && !Object.keys(selectedfiles).includes("Postmortem Report") && !minus_one_doc_for_postmortem1) {
  //             console.log("in else 4")
  //             showToast("ERROR", "Please upload the documents.")
  //         }
  //         else if ((allValues["Accident Resulted In"] !== undefined &&
  //             allValues["Accident Resulted In"] === 27) && !Object.keys(selectedfiles).includes("FIR COPY") && !minus_one_doc_for_postmortem1) {
  //             console.log("in else 4")
  //             showToast("ERROR", "Please upload the documents.")
  //         }
  //         //   else if(Object.keys(selectedfiles).length !== (allValues.schemeDetails.document_details.length - 1)
  //         //     ){
  //         //     console.log("in else 1")
  //         //     if(!minus_one_doc_for_postmortem1 ){
  //         //         showToast("ERROR","Please upload all the documents.")
  //         //     }
  //         // }
  //         else if (
  //             Object.keys(selectedfiles).length !== (allValues.schemeDetails.document_details.length)
  //         ) {
  //             console.log("in else 9")
  //             console.log("in else 9", minus_one_doc_for_postmortem1)

  //             if ( allValues["Accident Resulted In"] === 27) {
  //                 if(!minus_one_doc_for_postmortem1){
  //                     submitButtonPressedFinal()
  //                 }

  //             }
  //             else if(minus_one_doc_for_postmortem1){
  //                 submitButtonPressedFinal()
  //             }
  //             else if (minus_one_doc_for_affidavit1) {
  //                 submitButtonPressedFinal()
  //             }
  //             else {
  //                 showToast("ERROR", "Please upload the documents.")
  //             }
  //         }

  //         else if (
  //             allValues["Date of admission to Hospital /Clinic"] !== undefined &&
  //             allValues["Date of Discharge from Hospital/Clinic"] !== undefined
  //         ) {
  //             console.log("in else 3")

  //             var adDate = moment(
  //                 new Date(allValues["Date of admission to Hospital /Clinic"])
  //             )
  //             var disDate = moment(
  //                 new Date(allValues["Date of Discharge from Hospital/Clinic"])
  //             )
  //             var dayDiffMed = disDate.diff(adDate, 'days');

  //             var dayad = 1
  //             var dayadMoment = new Date(dayad)
  //             console.log("dayDiffMed Loop: ", dayDiffMed)
  //             if (dayDiffMed < 2) {
  //                 showToast("ERROR", "Minimum required date is 48 hours after admission.")

  //             }
  //             else if (allValues["Date of admission to Hospital /Clinic"] !== undefined) {
  //                 var todaysDate = new Date()
  //                 console.log("Admission datee", adDate)
  //                 console.log("Today's datee", todaysDate)
  //                 var dayDiffMed1 = adDate.diff(todaysDate, 'months');
  //                 if (dayDiffMed1 < -6) {
  //                     showToast("ERROR", "Maximum period is 6 months from admission date to apply.")
  //                 }
  //                 else {
  //                     console.log("Submit button click")
  //                     submitButtonPressedFinal()
  //                 }
  //             }

  //             else {
  //                 console.log("Submit button click")
  //                 submitButtonPressedFinal()
  //             }
  //         }

  //         else if (
  //             allValues.fields[0] !== undefined &&
  //             allValues.fields[0].id === 45
  //         ) {
  //             console.log("in else 4")

  //             var birthDate = moment(
  //                 new Date(allValues["Date of Child Birth"])
  //             )
  //             console.log("Birth2 Date", birthDate)
  //             var todayDate = new Date()
  //             console.log("Today's Date", todayDate)
  //             var dayDiff = birthDate.diff(todayDate, 'months');

  //             // var dayad = 1
  //             // var dayadMoment = new Date(dayad)
  //             console.log("dayDiffMed Loop: ", dayDiff)
  //             if (dayDiff < -6) {
  //                 showToast("ERROR", "Maximum date to apply is 6 months after delivery of child.")

  //             }

  //             else {
  //                 submitButtonPressedFinal()
  //             }
  //         }

  //         else if (
  //             allValues["Date of death"] !== undefined
  //             // allValues.fields[0].id == 54
  //         ) {
  //             console.log("in else 5")
  //             console.log("in death date loop")
  //             var deathDate = moment(
  //                 new Date(allValues["Date of death"])
  //             )
  //             console.log("Death Date1", deathDate)
  //             var todaysDate = new Date()
  //             console.log("Today's Date", todayDate)
  //             var dayDiff = deathDate.diff(todaysDate, 'years');

  //             // var dayad = 1
  //             // var dayadMoment = new Date(dayad)
  //             console.log("dayDiffMed Loop: ", dayDiff)
  //             if (dayDiff <= -1) {
  //                 showToast("ERROR", "Maximum date to apply is 1 year after the date of death.")

  //             }

  //             else {
  //                 submitButtonPressedFinal()
  //             }
  //         }

  //         else if (
  //             allValues.fields[0] !== undefined &&
  //             allValues.fields[0].id === 54
  //         ) {
  //             console.log("in else 6")

  //             var birthDate1 = moment(
  //                 new Date(allValues["Date of Child Birth"])
  //             )
  //             console.log("Birth Date1", birthDate1)
  //             var todaysDate = new Date()
  //             console.log("Today's Date", todayDate)
  //             var dayDiff = birthDate1.diff(todaysDate, 'years');

  //             // var dayad = 1
  //             // var dayadMoment = new Date(dayad)
  //             console.log("dayDiffMed Loop: ", dayDiff)
  //             if (dayDiff < -3) {
  //                 showToast("ERROR", "Maximum date to apply is 3 year after the date of delivery.")

  //             }

  //             else {
  //                 submitButtonPressedFinal()
  //             }
  //         }

  //         else {
  //             console.log("in else 7 final")
  //             submitButtonPressedFinal()
  //         }

  // }

  // useEffect(() => {
  //     // console.error("in useEffect imgfile ")
  //     if (users.user.id !== undefined && users.user.id !== "" && users.user.id !== null) {

  //         let dataForRegDetails = {
  //             "key": "user_id",
  //             "value": users.user.id,
  //             "board_id": 4,
  //             "procedure_name": "all"
  //         }
  //         dispatch(getUserRegistrationDetails(dataForRegDetails))

  //         var dataNEW = JSON.stringify({
  //             "board_id": 4,
  //             "scheme_id": 38,
  //             "role_id": null,
  //             "tab_id": null
  //         });
  //         var config = {
  //             method: 'post',
  //             url: SERVER + '/schemes/get_dynamic_fields',
  //             headers: {
  //                 'Authorization': `Bearer ${getTokenCookie()}`
  //             },
  //             data: dataNEW
  //         };

  //         axios(config)
  //             .then(function (response) {
  //                 console.error("response get_dynamic_fields: " + JSON.stringify(response.data, undefined, 2));
  //                 // setAllValues({
  //                 //     ...allValues,
  //                 //     get_dynamic_fields: JSON.stringify(response.data)
  //                 // })

  //                 var arr1 = ""
  //                 console.log('Responsse', response.data.data);
  //                 var fields = response.data.data;

  //                 const myPromises = [];

  //                 fields.forEach((field) => {
  //                     if (field.is_text_or_value === true) {
  //                         myPromises.push(getFieldValues(field))
  //                     }
  //                 })

  //                 Promise.all(myPromises).then((promResponses) => {
  //                     console.log('Promise Responses', promResponses);

  //                     var config = {
  //                         method: 'get',
  //                         url: SERVER + '/schemes/get_scheme_details?board_id=4&scheme_id=38' ,
  //                         headers: {
  //                             'Authorization': `Bearer ${getTokenCookie()}`
  //                         },
  //                         // data : dataNEW
  //                     };

  //                     axios(config)
  //                         .then(function (response) {
  //                             console.log('get_scheme_details Responses', response);

  //                             setAllValues({
  //                                 ...allValues,
  //                                 promResponses: promResponses,
  //                                 fields: fields,
  //                                 schemeDetails: response.data.data
  //                             })
  //                         })
  //                         .catch(err => {
  //                             console.log('Error in get_scheme_details', err)
  //                         })

  //                     // setAllValues({
  //                     //     ...allValues,
  //                     //     promResponses: promResponses,
  //                     //     fields: fields
  //                     //     // promResponses: JSON.stringify(promResponses)
  //                     // })

  //                 })
  //                     .catch(err => {
  //                         console.log('Error in Resolve Promise', err)
  //                     })

  //             })
  //             .catch(function (error) {
  //                 console.error(error);
  //             });
  //     }
  // }, [users.user])

  useEffect(() => {
    console.log("filelist_in_useeffect", filelist);

    if (fileuploadresults !== null && fileuploadresults.length > 0) {
      console.log("filelist_fileuploads", filelist);
      var filelist1 = filelist;
      var filelist2 = [];
      fileuploadresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };
        filelist2.push(filejson);
        console.log("filelist_in_useeffect1", fileuploadresults);
        console.log("filelist_in_useeffect3", filelist2);

        // filelist1.push({[currentdocname] : filejson })
      });

      filelist1.push({ [currentdocname]: filelist2 });

      console.log("filelist1: " + JSON.stringify(filelist1));
      setfilelist(filelist1);
      console.log("filelist_after_set: " + JSON.stringify(filelist));

      if (filelist1.length !== 0) {
        var data = JSON.stringify({
          board_id: 4,
          role_hierarchy_master_id: 38,
        });
        var config = {
          method: "post",
          url: SERVER + "/schemes/get_role_hirarchy",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(
              "get_role_hirarchy response:" +
                JSON.stringify(response.data.data[0].role_id)
            );

            // Call get_department_user_by_area

            setrole_id_from_hirarchy(response.data.data[0].role_id);

            var dataDepUser = JSON.stringify({
              board_id: 4,
              role_id_from_hirarchy: response.data.data[0].role_id,
              village_area_id:
                users.getUserRegistrationDetails.address_details[0]
                  .village_area_id,
            });

            var configDepUser = {
              method: "post",
              url: SERVER + "/schemes/get_department_user_by_area",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: dataDepUser,
            };

            axios(configDepUser)
              .then(function (response2) {
                console.log(
                  "get_department_user_by_area response:" +
                    JSON.stringify(response2.data)
                );

                // setTimeout(function() {
                console.log("filelist1_before_call: ", filelist1);
                submitButtonPressed1(response2.data.data, null, filelist1);
                // }.bind(this), 3000)
              })
              .catch(function (error) {
                console.error("get_department_user_by_area error:" + error);
              });
          })
          .catch(function (error) {
            console.error("get_role_hirarchy error:" + error);
          });
      }
    }
  }, [fileuploadresults]);
  const submitButtonPressedFinal = (e) => {
    // console.log("momentStringMedObjSubmit == " + minimumDateAdmissionMedicalDis);
    // var dayDiffMed = minimumDateAdmissionMedicalDis.diff(minimumDateAdmissionMedical, 'days');

    // if (allValues.declaration !== undefined && allValues.declaration === true) {

    setOpenBackdrop(true);
    setLoading(true);
    setSubmitSuccess(false);

    if (allValues.schemeDetails.document_details.length > 0) {
      allValues.schemeDetails.document_details.forEach((field) => {
        console.log("INFOREACH1");
        console.log("INFOREACH1", allValues["Accident Resulted In"]);

        if (educationSelected) {
          if (field.document_name.includes("F.I.R Copy") && educationSelected) {
            //Do nothing if Death is selected and Medical Disability is not mandatory
          } else if (
            field.document_name.includes("Postmortem Report") &&
            educationSelected
          ) {
          } else if (
            field.document_name.includes("Driving License Copy") &&
            educationSelected
          ) {
          } else if (
            field.document_name.includes("Nominee Aadhar Card") &&
            educationSelected
          ) {
          } else if (
            field.document_name.includes("Ration Card") &&
            educationSelected
          ) {
          } else if (
            field.document_name.includes(
              "Bank Account Passbook Copy of Nominee’s"
            ) &&
            educationSelected
          ) {
          } else {
            selectedfiles[field.document_name].forEach(async (i) => {
              var selectedblob = i;
              var reader = new FileReader();
              reader.readAsDataURL(selectedblob);
              var formdataobj = "";
              var fileName = "";
              reader.onloadend = await function (event) {
                fileName = selectedblob.name;
                var base64data = event.target.result;
                formdataobj = {
                  file: base64data,
                  userId: users.user.id,
                  fileType: fileName,
                };
                promises.push(uploadfile(formdataobj, fileName));
                if (
                  promises.length === selectedfiles[field.document_name].length
                ) {
                  Promise.all(promises).then((results) => {
                    console.log("results of promise: ", results);
                    console.log(results);
                    setcurrentdocname(field.document_name);
                    setfileuploadresults(results);
                  });
                }
              };
            });
          }
        } else {
          if (
            field.document_name.includes(
              "Bank Account Passbook Copy of Nominee’s"
            ) ||
            field.document_name.includes("F.I.R Copy") ||
            field.document_name.includes("Ration Card") ||
            field.document_name.includes("Nominee Aadhar Card") ||
            field.document_name.includes("Driving License Copy") ||
            field.document_name.includes("Postmortem Report") ||
            field.document_name.includes(
              "Legal Heir Certificate of Deceased Person"
            ) ||
            field.document_name.includes("Death Certificate")
          ) {
            console.log("INACCIDENTDOC");
            selectedfiles[field.document_name].forEach(async (i) => {
              var selectedblob = i;
              var reader = new FileReader();
              reader.readAsDataURL(selectedblob);
              var formdataobj = "";
              var fileName = "";
              reader.onloadend = await function (event) {
                fileName = selectedblob.name;
                var base64data = event.target.result;
                formdataobj = {
                  file: base64data,
                  userId: users.user.id,
                  fileType: fileName,
                };
                promises.push(uploadfile(formdataobj, fileName));
                if (
                  promises.length === selectedfiles[field.document_name].length
                ) {
                  Promise.all(promises).then((results) => {
                    console.log("results of promise: ", results);
                    console.log(results);
                    setcurrentdocname(field.document_name);
                    setfileuploadresults(results);
                  });
                }
              };
            });
          }
        }

        let promises = [];
      });
    } else {
      var data = JSON.stringify({
        board_id: 4,
        role_hierarchy_master_id: 38,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_role_hirarchy",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "get_role_hirarchy response:" + JSON.stringify(response.data)
          );

          // Call get_department_user_by_area

          setrole_id_from_hirarchy(response.data.data[0].role_id);

          var dataDepUser = JSON.stringify({
            board_id: 4,
            role_id_from_hirarchy: 38,
            village_area_id:
              users.getUserRegistrationDetails.address_details[0]
                .village_area_id,
          });

          var configDepUser = {
            method: "post",
            url: SERVER + "/schemes/get_department_user_by_area",
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: dataDepUser,
          };

          axios(configDepUser)
            .then(function (response2) {
              console.log(
                "get_department_user_by_area response:" +
                  JSON.stringify(response2.data)
              );

              // setTimeout(function() {
              submitButtonPressed1(response2.data.data, null, "");
              // }.bind(this), 3000)
            })
            .catch(function (error) {
              console.error("get_department_user_by_area error:" + error);
            });
        })
        .catch(function (error) {
          console.error("get_role_hirarchy error:" + error);
        });
    }

    // }
    // else {

    //     showToast("WARN", "Please Accept the Declaration!")
    // }
  };

  const submitButtonPressed1 = (deptUserDetails, DocsIdMap, filelistNew) => {
    // console.log("param_in_schemeform: " + id)
    console.log(
      "deptUserDetails: " + JSON.stringify(deptUserDetails, undefined, 2)
    );

    var datadocs = [];
    console.log("filelistNew: ", filelistNew);

    if (
      allValues.schemeDetails.document_details.length > 0 &&
      filelistNew !== undefined &&
      filelistNew !== ""
    ) {
      // console.log("filelist_in_submit: ",filelist)
      console.log("filelist_in_submit: ", filelistNew);
      allValues.schemeDetails.document_details.forEach((field, index) => {
        console.log("index: " + index);
        console.log("field.document_name: ", field.document_name);
        console.log("document_name_all: ", JSON.stringify(allValues));
        console.log(
          "`${field.document_name}_fileid`: ",
          `${field.document_name}_fileid`
        );
        console.log(
          "`selectedfiles[field.document_name]`: ",
          selectedfiles[field.document_name]
        );
        // console.log("filelist[field.document_name]: "+ filelist[index][field.document_name])
        // console.log("filelistNew[field.document_name]: ", filelistNew[index][field.document_name])
        //     })
        // })
        // console.log("fileIdFound: " + fileIdFound)

        var filesObj = [];
        filelistNew.forEach((ob, i) => {
          console.log("ob: ", ob);
          if (ob[field.document_name] !== undefined) {
            filesObj = ob[field.document_name];
          }
        });
        console.log("filesObj: ", filesObj);
        if (educationSelected) {
          if (field.document_name === "F.I.R Copy" && educationSelected) {
            // do Nothing
          } else if (
            field.document_name === "Postmortem Report" &&
            educationSelected
          ) {
            // do Nothing
          } else if (
            field.document_name === "Driving License Copy" &&
            educationSelected
          ) {
            // do Nothing
          } else if (
            field.document_name === "Nominee Aadhar Card" &&
            educationSelected
          ) {
            // do Nothing
          } else if (
            field.document_name === "Ration Card" &&
            educationSelected
          ) {
            // do Nothing
          } else if (
            field.document_name === "Bank Account Passbook Copy of Nominee’s" &&
            educationSelected
          ) {
            // do Nothing
          } else {
            datadocs.push({
              document_upload_id: "-",
              description: field.document_name,
              document_type: field.document_name,
              scheme_document_id: field.id,
              files: filesObj,
            });
          }
        } else {
          if (
            field.document_name.includes(
              "Bank Account Passbook Copy of Nominee’s"
            ) ||
            field.document_name.includes("F.I.R Copy") ||
            field.document_name.includes("Ration Card") ||
            field.document_name.includes("Nominee Aadhar Card") ||
            field.document_name.includes("Driving License Copy") ||
            field.document_name.includes("Postmortem Report") ||
            field.document_name.includes(
              "Legal Heir Certificate of Deceased Person"
            ) ||
            (field.document_name.includes("Death Certificate") &&
              field.id === 94)
          ) {
            datadocs.push({
              document_upload_id: "-",
              description: field.document_name,
              document_type: field.document_name,
              scheme_document_id: field.id,
              files: filesObj,
            });
          }
        }

        // datadocs.push(
        //     {
        //         "document_upload_id": "-",
        //         "description": field.document_name,
        //         "document_type": field.document_name,
        //         "scheme_document_id": field.id,
        //         "files": filesObj
        //     },
        // )
      });

      console.log("datadocs: " + JSON.stringify(datadocs, undefined, 2));
    }

    var dataNEW = {
      board_id: users.user.board_id,
      scheme_id: 38,
      labour_user_id: users.user.id,
      is_self: allValues.selfselected ? 1 : 0, ///////// TO BE CHANGED
      applied_for_person_id: users.user.id, ///////// TO BE CHANGED
      applied_date: moment(new Date()).format("YYYY-MM-DD"),
      scheme_approval_status_id: 45,
      unique_id: 12456899, ///////// TO BE CHANGED
      circle_id: deptUserDetails[0].circle_id,
      role_id_from_hirarchy: 1, ///////// TO BE CHANGED
      department_user_id: deptUserDetails[0].department_user_id,
      is_renewal: 0,
      documents: datadocs,
    };

    console.log("dataNEW in else : " + JSON.stringify(dataNEW, undefined, 2));

    var config = {
      method: "post",
      url: SERVER + "/schemes/create_scheme_txn",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: dataNEW,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "response create_scheme_txn: " + JSON.stringify(response.data)
        );
        console.log("in_create_scheme_txn: ");

        console.log(
          "response.data.data.scheme_avail_details[0].Error: " +
            response.data.data.scheme_avail_details[0].Error
        );

        if (response.data.data.scheme_avail_details[0].Error !== undefined) {
          // showToast("ERROR", response.data.data.scheme_avail_details[0].Error)
          setLoading(false);
        } else {
          console.log("in_else_create_Scheme");
          console.log(
            "in response.data.data.scheme_avail_details[0].Scheme_Avail_id_acknowledgement_id: " +
              response.data.data.scheme_avail_details[0]
                .Scheme_Avail_id_acknowledgement_id
          );
          // var Scheme_Avail_id_acknowledgement_id = response.data.data[0].Scheme_Avail_id_acknowledgement_id
          var Scheme_Avail_id_acknowledgement_id =
            response.data.data.scheme_avail_details[0]
              .Scheme_Avail_id_acknowledgement_id;

          console.log(
            "in Scheme_Avail_id_acknowledgement_id: " +
              Scheme_Avail_id_acknowledgement_id
          );

          // appNo = response.data.data.scheme_aplication_details[0].scheme_application_code
          setappNo(
            response.data.data.scheme_aplication_details[0]
              .scheme_application_code
          );
          console.log("in appNo: " + appNo);

          setappNo(
            response.data.data.scheme_aplication_details[0]
              .scheme_application_code
          );
          console.log("after setappNo ");

          console.log(
            "Scheme_Avail_id_acknowledgement_id: " +
              Scheme_Avail_id_acknowledgement_id
          );
          console.log("appNo: " + appNo);

          submitButtonPressed2(Scheme_Avail_id_acknowledgement_id);
        }
      })
      .catch(function (error) {
        console.error(error);
        console.error("response error: " + JSON.stringify(error.response));

        showToast("ERROR", "Some error occured while applying for Scheme!");
        setLoading(false);
      });
  };

  const submitButtonPressed = () => {
    submitButtonPressedFinal();
  };

  const submitButtonPressed2 = (Scheme_Avail_id_acknowledgement_id) => {
    var scheme_avail_id = "";
    var data1 = JSON.stringify({
      board_id: 4,
      labour_user_id: users.user.id,
    });

    var config1 = {
      method: "post",
      url: SERVER + "/schemes/get_schemes_by_labor",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data1,
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        scheme_avail_id = Scheme_Avail_id_acknowledgement_id;
        console.log("scheme_avail_id: " + scheme_avail_id);

        var fieldMap = [];

        allValues.fields.forEach((field) => {
          if (educationSelected) {
            if (
              field.id === 158 ||
              field.id === 161 ||
              field.id === 162 ||
              field.id === 163 ||
              field.id === 164 ||
              field.id === 165 ||
              field.id === 166 ||
              field.id === 167 ||
              field.id === 168
            ) {
              fieldMap.push({
                field_id: field.id,
                field_value: allValues[field.field_name],
                is_text_or_value: field.is_text_or_value === false ? 0 : 1,
              });
            }
          } else {
            if (field.id === 158 || field.id === 159 || field.id === 160) {
              fieldMap.push({
                field_id: field.id,
                field_value: allValues[field.field_name],
                is_text_or_value: field.is_text_or_value === false ? 0 : 1,
              });
            }
          }
        });

        var dataNEW = JSON.stringify({
          board_id: 4,
          labour_id: users.user.id,
          scheme_avail_id: scheme_avail_id,
          session_user_id: users.user.id,
          fields: fieldMap,
        });
        var config = {
          method: "post",
          url: SERVER + "/schemes/insert_scheme_values",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: dataNEW,
        };

        axios(config)
          .then(function (response) {
            console.error(
              "response insert_scheme_values: " + JSON.stringify(response.data)
            );

            showToast("SUCCESS", "Applied for Scheme Successfully!");

            setLoading(false);
            setSubmitSuccess(true);
          })
          .catch(function (error) {
            console.error("response error: " + JSON.stringify(error));

            showToast("ERROR", "Some error occured while applying for Scheme!");
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/boardsHome'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='header.titleungoranized' />
                  <br />
                  <Translate value='header.subungoranized' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={allValues.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          <h1>
            {" "}
            <Translate value='schemes' /> |
            <Translate value='accidentTitle' />
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='personalDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='nameoftheApplicant' />{" "}
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.personal_details !== undefined &&
                UserDetails.personal_details.length > 0 &&
                UserDetails.personal_details[0].first_name
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='fatherName' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.family_details !== undefined &&
                UserDetails.family_details.length > 0 &&
                UserDetails.family_details[0].first_name
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='mobileNumber' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.personal_details !== undefined &&
                UserDetails.personal_details.length > 0 &&
                UserDetails.personal_details[0].mobile_no
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2 mt-2'>
            <Translate value='dateofbirth' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails.personal_details !== undefined &&
                UserDetails.personal_details.length > 0
                  ? moment(
                      UserDetails.personal_details[0].date_of_birth
                    ).format("DD/MM/YYYY")
                  : ""
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2 mt-2'>
            <Translate value='age' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={Age} disabled />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='address' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='state' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.address_details !== undefined &&
                UserDetails.address_details.length > 0 &&
                `${UserDetails.address_details[0].state}`
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='district' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.address_details !== undefined &&
                UserDetails.address_details.length > 0 &&
                `${UserDetails.address_details[0].district}`
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='taluk' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.address_details !== undefined &&
                UserDetails.address_details.length > 0 &&
                `${UserDetails.address_details[0].taluk}`
              }
              disabled
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='gramPanchayat' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.address_details !== undefined &&
                UserDetails.address_details.length > 0 &&
                `${UserDetails.address_details[0].panhayat_city_town}`
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='villageWard' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.address_details !== undefined &&
                UserDetails.address_details.length > 0 &&
                `${UserDetails.address_details[0].ward_area_village}`
              }
              disabled
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='bankDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2'>
            <Translate value='bankName' />
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.bank_details !== undefined &&
                UserDetails.bank_details.length > 0 &&
                `${UserDetails.bank_details[0].bank_name}`
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='bankBranch' />
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.bank_details !== undefined &&
                UserDetails.bank_details.length > 0 &&
                `${UserDetails.bank_details[0].bank_branch}`
              }
              disabled
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='accountNumber' />
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.bank_details !== undefined &&
                UserDetails.bank_details.length > 0 &&
                `${UserDetails.bank_details[0].account_no}`
              }
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='iFSCCode' />
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={
                UserDetails !== null &&
                UserDetails.bank_details !== undefined &&
                UserDetails.bank_details.length > 0 &&
                `${UserDetails.bank_details[0].ifsc_code}`
              }
              disabled
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='accAndEdu' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        {allValues.fields !== undefined ? (
          dynamicFields()
        ) : (
          <>Please Wait! Fetching...</>
        )}
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='documents' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        {console.log(allValues.schemeDetails, "documents")}
        {allValues.schemeDetails !== undefined &&
          allValues.schemeDetails.document_details.map((item) => (
            <>
              {educationSelected ? (
                <>
                  {item.document_name.includes(
                    "Education Provisional Certificate"
                  ) ||
                  item.document_name.includes("Birth Certificate") ||
                  item.document_name.includes("Smart Card") ||
                  item.document_name.includes("Ksrtc") ||
                  item.document_name.includes("Death Certificate from") ||
                  item.document_name.includes("Death Certificate") ||
                  item.document_name.includes(
                    "Legal Heir Certificate of Deceased Person"
                  ) ? (
                    <Col
                      xs={12}
                      md={6}
                      className='final-personal-col-1 px-3 mt-4 browsecol'
                    >
                      <Required
                        className='mb-2'
                        title={
                          allValues.descriptionsKannada
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className='browsebutton-outerdiv'>
                        <div className='browsebutton2-div filesNames'>
                          {loadingfiles ? (
                            <></>
                          ) : (
                            selectedfiles !== null &&
                            selectedfiles[item.document_name] !== undefined &&
                            selectedfiles[item.document_name] !== null &&
                            selectedfiles[item.document_name].length > 0 &&
                            Array.from(selectedfiles[item.document_name]).map(
                              (i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant='outlined'
                                    name='passbookDocumentName'
                                    value={i.name}
                                    onChange={handleChange}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='start'></InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              )
                            )
                          )}
                        </div>
                        <div className='browsebutton2-div'>
                          <label
                            htmlFor={`upload-photo${item.id}`}
                            className='browse-button btn2 schemes'
                          >
                            <img alt='...' src={folder} />
                            <Translate value='browse' />
                          </label>
                          {/* <input type="file" name="photo" id={`upload-photo${item.id}`}
                                    onChange={(e) => handleFileChange(e, item.document_name)}
                                /> */}
                          <input
                            type='file'
                            name='photo'
                            id={`upload-photo${item.id}`}
                            onChange={(e) =>
                              handleFileChange(e, item.document_name)
                            }
                            multiple
                          />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </>
              ) : (
                <>
                  {item.document_name.includes(
                    "Education Provisional Certificate"
                  ) ||
                  item.document_name.includes("Birth Certificate") ||
                  item.document_name.includes("Smart Card") ||
                  item.document_name.includes("Ksrtc") ||
                  item.document_name.includes(
                    "Death Certificate from"
                  ) ? null : (
                    <Col
                      xs={12}
                      md={6}
                      className='final-personal-col-1 px-3 mt-4 browsecol'
                    >
                      <Required
                        className='mb-2'
                        title={
                          allValues.descriptionsKannada
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className='browsebutton-outerdiv'>
                        <div className='browsebutton2-div filesNames'>
                          {loadingfiles ? (
                            <></>
                          ) : (
                            selectedfiles !== null &&
                            selectedfiles[item.document_name] !== undefined &&
                            selectedfiles[item.document_name] !== null &&
                            selectedfiles[item.document_name].length > 0 &&
                            Array.from(selectedfiles[item.document_name]).map(
                              (i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant='outlined'
                                    name='passbookDocumentName'
                                    value={i.name}
                                    onChange={handleChange}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='start'></InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              )
                            )
                          )}
                        </div>
                        <div className='browsebutton2-div'>
                          <label
                            htmlFor={`upload-photo${item.id}`}
                            className='browse-button btn2 schemes'
                          >
                            <img alt='...' src={folder} />
                            <Translate value='browse' />
                          </label>
                          {/* <input type="file" name="photo" id={`upload-photo${item.id}`}
                                    onChange={(e) => handleFileChange(e, item.document_name)}
                                /> */}
                          <input
                            type='file'
                            name='photo'
                            id={`upload-photo${item.id}`}
                            onChange={(e) =>
                              handleFileChange(e, item.document_name)
                            }
                            multiple
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </>
              )}
            </>
          ))}

        <Col xs={12} className='final-personal-col-1 px-3 mt-4'>
          <p className='note-scheme'>
            <Translate value='note' />
            <Translate value='documentshouldbeinJPEGPNGandPDFformatonlyandlessthan50KB' />
          </p>
        </Col>
      </Row>

      <Row className='search-subtitle-row pb-5'>
        <Col xs={12} className='next-back-button-row mt-4'>
          <Link
            to='#'
            // onClick={() => props.history.push('/schemes-home')}
            style={{ flexGrow: "0.5" }}
          >
            <Button variant='outline-primary' className='back-button'>
              <Translate value='cancel' />
            </Button>
          </Link>

          <Link
            to='#'
            onClick={submitButtonPressed}
            style={{ flexGrow: "0.5" }}
          >
            <Button variant='outline-primary' className='save-button'>
              <Translate value='submit' />
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default AccidentUnorganizedForm;
