import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Tooltip } from "@mui/material";
import { Translate } from "react-redux-i18n";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  SERVER,
  addLabourAddressDetails,
  addLabourBankDetails,
  addLabourFamilyDetails,
  addLabourPersonalDetails,
  erroraddLabourBankDetails,
  getUserRegistrationDetails,
} from "store/actions/user.actions";
import { Required, getTokenCookie, showToast } from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import Header from "./Header";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import closeIcon from "assets/images/close.png";
import {
  AddCircleRounded,
  CameraAlt,
  Close,
  PhoneAndroid,
} from "@material-ui/icons";
import Webcam from "react-webcam";
import folder from "assets/images/Folderwhite-01-01.svg";
// import webcam from 'assets/images/web-cam.svg'
import webcam from "assets/images/web cam-01.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import uploadFileAPI from "utils/fileupload";
import FolderIcon from "@mui/icons-material/Folder";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import avatar from "assets/images/avatar.png";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CancelIcon from "@mui/icons-material/Cancel";
import SimpleBackdrop from "components/loadingScreen/Backdrop";

const BankDetails = () => {
  const { labourUserID, isEdited } = useParams();
  // const { isEdited } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);

  const bankPassbookRef = useRef(null);
  const nomineeBankPassbookRef = useRef(null);
  const [bankPassbookDoc, setBankPassbookDoc] = useState([]);
  const [nomineeBankPassbookDoc, setNomineeBankPassbookDoc] = useState([]);

  console.log({bankPassbookDoc})

  const [bankPassbookID, setBankPassbookID] = useState(null);
  const [nomineeBankPassbookID, setNomineeBankPassbookID] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    accountNumber: null,
    IFSC_Code: null,
    bankName: null,
    bankBranch: null,
    bankAddress: null,
    IFSC_Is_Verified: false,
  });

  const [nomineeBankDetails, setNomineeBankDetails] = useState({
    nomineeAccountNumber: null,
    nominee_IFSC_Code: null,
    nomineeBankName: null,
    nomineeBankBranch: null,
    nomineeBankAddress: null,
    nomineeIFSCS_Is_Verified: false,
  });

  const [nomineeCheck, setNomineeCheck] = useState(false);

  // ERROR and Success USEEFFECT

  // useEffect(() => {
  //   dispatch(addLabourBankDetails(null, 'delete'));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (
  //     users &&
  //     users.addLabourBankDetails.success === false &&
  //     users.addLabourBankDetails.error
  //   ) {
  //     showToast('ERROR', `${users.addLabourBankDetails.errors.msg}`);
  //     dispatch(addLabourBankDetails(null));
  //   } else {
  //     showToast('SUCCESS', 'Bank Details Saved Successfully');
  //     dispatch(addLabourBankDetails(null));
  //   }
  // }, [users]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "bank_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        if (data.data.length > 0) {
          const { data: data_ } = data;
          // const res = data_.filter((record) => !record.is_nominee);
          // console.log(res);

          setBankDetails((prev) => ({
            ...prev,
            IFSC_Code: data_[0].ifsc_code,
            bankName: data_[0].bank_name,
            bankBranch: data_[0].bank_branch,
            accountNumber: data_[0].account_no,
            bankAddress: data_[0].bank_address,
            IFSC_Is_Verified: data_[0].is_bank_verified,
            bank_upload_files_xml: data_[0].bank_upload_files_xml,
          }));
          setNomineeCheck(data_.length > 1 && data_[1].is_nominee ? 1 : 0);

          if (data_[1]) {
            setNomineeBankDetails((prev) => ({
              ...prev,
              nominee_IFSC_Code: data_[1].ifsc_code,
              nomineeAccountNumber: data_[1].account_no,
              nomineeBankName: data_[1].bank_name,
              nomineeBankBranch: data_[1].bank_branch,
              nomineeBankAddress: data_[1].bank_address,
              nomineeIFSCS_Is_Verified: data_[1].is_bank_verified,
              bank_upload_files_xml: data_[0].bank_upload_files_xml,
            }));
          }

          if (data_.length > 0 && Array.isArray(data_) && data_.length > 0) {
            const bankPassBookDoc = JSON.parse(data_[0].bank_upload_files_xml);

            if (Array.isArray(bankPassBookDoc) && bankPassBookDoc.length > 0) {
              setBankPassbookID(bankPassBookDoc);
            }

            if (data_[1]) {
              const nomineeBankPassbook = JSON.parse(
                data_[1].bank_upload_files_xml
              );

              console.log({ nomineeBankPassbook });

              if (
                Array.isArray(nomineeBankPassbook) &&
                nomineeBankPassbook.length > 0
              ) {
                setNomineeBankPassbookID(nomineeBankPassbook);
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err) {
          console.log("ERROR", "Something went wrong");
        }
      });
  }, [labourUserID]);

  const handleBankPassbookFile = (ev) => {
    if (
      ev.target.files[0].type === "image/png" ||
      ev.target.files[0].type === "image/jpeg" ||
      ev.target.files[0].type === "image/jpg" ||
      ev.target.files[0].type === "application/pdf"
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...bankPassbookDoc, ...ev.target.files];
        setBankPassbookDoc(newFiles);
      } else {
        showToast("ERROR", "Please upload file size less than 2MB");
      }
    } else {
      showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
    }
  };

  const handleNomineeBankPassbookFile = (ev) => {
    if (
      ev.target.files[0].type === "image/png" ||
      ev.target.files[0].type === "image/jpeg" ||
      ev.target.files[0].type === "image/jpg" ||
      ev.target.files[0].type === "application/pdf"
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...nomineeBankPassbookDoc, ...ev.target.files];
        setNomineeBankPassbookDoc(newFiles);
      } else {
        showToast("ERROR", "Please upload file size less than 2MB");
      }
    } else {
      showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
    }
  };

  const handleBankDetails = (ev) => {
    if (ev.target.name === "accountNumber") {
      setBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value.replace(/[^0-9]/g, ""),
      }));
    } else {
      setBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    }
  };

  const handleNomineeBankDetails = (ev) => {
    if (ev.target.name === "nomineeAccountNumber") {
      setNomineeBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    } else {
      setNomineeBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    }
  };

  // console.log(bankDetails);

  const handleFetchIFSC = () => {
    if (bankDetails.IFSC_Code) {
      axios
        .get(`${SERVER}/global/get_ifsc_details?ifsc=${bankDetails.IFSC_Code}`)
        .then(({ data }) => {
          const { data: _data } = data;
          setBankDetails((prev) => ({
            ...prev,
            bankName: _data.BANK,
            bankBranch: _data.BRANCH,
            bankAddress: _data.ADDRESS,
            IFSC_Is_Verified: true,
          }));
        })
        .catch((err) => {
          console.log(err);
          showToast("ERROR", "Please enter valid IFSC CODE");
        });
    } else {
      showToast("ERROR", "Please Enter IFSC Code");
    }
  };

  const handleNomineeFetchIFSC = () => {
    if (nomineeBankDetails.nominee_IFSC_Code) {
      axios
        .get(
          `${SERVER}/global/get_ifsc_details?ifsc=${nomineeBankDetails.nominee_IFSC_Code}`
        )
        .then(({ data }) => {
          const { data: _data } = data;
          setNomineeBankDetails((prev) => ({
            ...prev,
            nomineeBankName: _data.BANK,
            nomineeBankBranch: _data.BRANCH,
            nomineeBankAddress: _data.ADDRESS,
            nomineeIFSCS_Is_Verified: true,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast("ERROR", "Please Enter IFSC Code");
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...bankPassbookDoc];
    newFiles.splice(index, 1);
    setBankPassbookDoc(newFiles);
    bankPassbookRef.current.value = "";
  };

  const handleRemoveNomineeFile = (index) => {
    const newFiles = [...nomineeBankPassbookDoc];
    newFiles.splice(index, 1);
    setNomineeBankPassbookDoc(newFiles);
    nomineeBankPassbookRef.current.value = "";
  };

  const uploadFiles = async () => {
    if (nomineeCheck) {
      if (
        nomineeBankPassbookDoc &&
        nomineeBankPassbookDoc.length > 0 &&
        bankPassbookDoc &&
        bankPassbookDoc
      ) {
        const fileUpload = [];
        const promises1 = [];
        const bankUploadAPI = bankPassbookDoc.forEach((doc) => {
          promises1.push(uploadFileAPI(doc));
        });
        const promises2 = [];
        const nomineeBankUploadAPI = nomineeBankPassbookDoc.forEach((doc) => {
          promises2.push(uploadFileAPI(doc));
        });

        const allPromises = [...promises1, ...promises2];

        Promise.all(allPromises)
          .then((responses) => {
            // Handle responses from all promises

            // Split the responses for promises1 and promises2 if needed
            const responses1 = responses.slice(0, promises1.length);
            const responses2 = responses.slice(promises1.length);

            const bankResponse = responses1.map((doc) => doc.image);
            const nomineeBankResponse = responses2.map((doc) => doc.image);
            // Continue processing or handle the responses as needed
            insertBankDetails(bankResponse, nomineeBankResponse);
          })
          .catch((error) => {
            // Handle errors from any promise in allPromises
            console.error("Error:", error);
          });
      } else {
        insertBankDetails();
      }

      // console.log('FILESRESPONSE', fileUpload);
      // insertBankDetails(fileUpload);
    } else {
      if (bankPassbookDoc && bankPassbookDoc.length > 0) {
        const promises = [];
        bankPassbookDoc.forEach((doc) => {
          promises.push(uploadFileAPI(doc));
        });
        Promise.all(promises)
          .then((promiseResponse) => {
            const mappedData = promiseResponse.map((doc) => doc.image);

            insertBankDetails(mappedData);
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
      } else {
        insertBankDetails();
      }
    }
  };

  const insertBankDetails = (bankPassbook, nomineePassbook) => {
    if (isEdited === "edit" || isEdited === "back") {
      // Update
      const payLoad = {
        user_id: labourUserID,
        banks: [
          {
            ifsc_code: bankDetails.IFSC_Code,
            account_no: bankDetails.accountNumber,
            bank_name: bankDetails.bankName,
            bank_branch: bankDetails.bankBranch,
            bank_address: bankDetails.bankAddress,
            files: bankPassbookID,
            bank_passbook_upload_id: "-",
            is_nominee: 0,
            is_verified: bankDetails.IFSC_Is_Verified,
          },
          // {
          //   ifsc_code: nomineeBankDetails.nominee_IFSC_Code,
          //   account_no: nomineeBankDetails.nomineeAccountNumber,
          //   bank_name: nomineeBankDetails.nomineeBankName,
          //   bank_branch: nomineeBankDetails.nomineeBankBranch,
          //   bank_address: nomineeBankDetails.nomineeBankAddress,
          //   files: nomineeBankPassbookID,
          //   bank_passbook_upload_id: '-',
          //   is_nominee: 1,
          //   is_verified: nomineeBankDetails.nomineeIFSCS_Is_Verified,
          // },
        ],
      };
      try {
        axios
          .patch(`${SERVER}/user/update-labour-bank-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            showToast("SUCCESS", "Bank Details Updated Successfully");
            setSubmitLoading(false);
            history.push(
              `/profile/${
                isEdited === "back"
                  ? "nintyDays"
                  : isEdited === "edit"
                    ? "review"
                    : ""
              }/${labourUserID}`
            );
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast("ERROR", `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              showToast("ERROR", "Something went wrong");
              setSubmitLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      //Insert
      if (nomineeCheck) {
        const bankPassbook_ = bankPassbook
          ? bankPassbook?.map((doc) => ({
              file_id: doc.fileId,
              file_name: doc.originalFileName,
            }))
          : bankDetails.bank_upload_files_xml
            ? JSON.parse(bankDetails.bank_upload_files_xml)
            : [];
        const nomineePassbook_ = nomineePassbook
          ? nomineePassbook?.map((doc) => ({
              file_id: doc.fileId,
              file_name: doc.originalFileName,
            }))
          : nomineeBankDetails.bank_upload_files_xml
            ? JSON.parse(nomineeBankDetails.bank_upload_files_xml)
            : [];
        const payLoad = {
          user_id: labourUserID,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankPassbook_,
              bank_passbook_upload_id: "-",
              is_nominee: 0,
              is_verified: true,
            },
            {
              ifsc_code: nomineeBankDetails.nominee_IFSC_Code,
              account_no: nomineeBankDetails.nomineeAccountNumber,
              bank_name: nomineeBankDetails.nomineeBankName,
              bank_branch: nomineeBankDetails.nomineeBankBranch,
              bank_address: nomineeBankDetails.nomineeBankAddress,
              files: nomineePassbook_,
              bank_passbook_upload_id: "-",
              is_nominee: 1,
              is_verified: true,
            },
          ],
        };

        axios
          .post(`${SERVER}/user/add-labour-bank-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            showToast("SUCCESS", "Bank Details Saved Successfully");
            setSubmitLoading(false);
            history.push(`/profile/nintyDays/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast("ERROR", `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              showToast("ERROR", "Something went wrong");
              setSubmitLoading(false);
            }
          });
      } else {
        const bankPassbook_ = bankPassbook
          ? bankPassbook?.map((doc) => ({
              file_id: doc.fileId,
              file_name: doc.originalFileName,
            }))
          : bankDetails.bank_upload_files_xml
            ? JSON.parse(bankDetails.bank_upload_files_xml)
            : [];
        const payLoad = {
          user_id: labourUserID,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankPassbook_,
              bank_passbook_upload_id: "-",
              is_nominee: 0,
              is_verified: true,
            },
          ],
        };

        axios
          .post(`${SERVER}/user/add-labour-bank-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            showToast("SUCCESS", "Bank Details Saved Successfully");
            setSubmitLoading(false);
            history.push(`/profile/nintyDays/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast("ERROR", `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              showToast("ERROR", "Something went wrong");
              setSubmitLoading(false);
            }
          });
      }
    }
  };

  const handleSubmit = () => {
    if (nomineeCheck) {
      if (
        bankDetails.accountNumber &&
        bankDetails.IFSC_Code &&
        bankDetails.bankName &&
        bankDetails.bankBranch &&
        bankDetails.bankAddress &&
        nomineeBankDetails.nomineeAccountNumber &&
        nomineeBankDetails.nomineeBankName &&
        nomineeBankDetails.nominee_IFSC_Code &&
        nomineeBankDetails.nomineeBankBranch &&
        nomineeBankDetails.nomineeBankAddress
      ) {
        if (
          (bankPassbookDoc && bankPassbookDoc.length > 0) ||
          (bankPassbookID && bankPassbookID.length > 0)
        ) {
          if (
            (nomineeBankPassbookDoc && nomineeBankPassbookDoc.length > 0) ||
            (nomineeBankPassbookID && nomineeBankPassbookID.length > 0)
          ) {
            setSubmitLoading(true);
            uploadFiles();
          } else {
            setSubmitLoading(true);
            showToast("ERROR", "Please upload nominee passbook document");
          }
        } else {
          showToast("ERROR", "Please upload passbook document");
        }
      } else {
        showToast("Please fill all mandatory details");
      }
    } else {
      if (
        bankDetails.accountNumber &&
        bankDetails.IFSC_Code &&
        bankDetails.bankName &&
        bankDetails.bankBranch &&
        bankDetails.bankAddress
      ) {
        if (
          (bankPassbookDoc && bankPassbookDoc.length > 0) ||
          (bankPassbookID && bankPassbookID.length > 0)
        ) {
          setSubmitLoading(true);
          uploadFiles();
        } else {
          showToast("ERROR", "Please upload bank passbook document");
        }
      } else {
        showToast("ERROR", "Please fill all mandatory details");
      }
    }
  };

  const handleBack = () => {
    history.push(`/profile/family/${labourUserID}/back`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  console.log(nomineeBankPassbookID);

  return (
    <>
      <Row className="personal-div">
        {submitLoading ? <SimpleBackdrop /> : null}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: "100%" }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              // value={allValues.language}
              name="language"
              displayEmpty
              // onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>
        <Row className="m-0">
          {/* {loadingBackDrop ? <SimpleBackdrop /> : null} */}
          <Row className="bank-title-row">
            <Col xs={12} className="profile-title">
              {/* <h1>Bank Passbook / Statement</h1> */}
              <h1>
                <Translate value="bankPassbookStatement" />
              </h1>
            </Col>
          </Row>

          <Row className="form-row">
            <Col xs={12} md={6} className="bank-col-1">
              <Row className="form-inner-card-right">
                <Col xs={12}>
                  {/* <p className="mt-3 mb-2">Account Number*</p> */}
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="accountNumber" />}
                  />
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      placeholder="Enter Your Account Number"
                      name="accountNumber"
                      value={bankDetails.accountNumber}
                      onChange={handleBankDetails}
                      // error={allValues.accountNumberError}
                      // helperText={
                      //   allValues.accountNumberError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                    />
                  </FormControl>
                </Col>

                <Col xs={12}>
                  {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                  <Required
                    className="mt-4 mb-2"
                    title={<Translate value="enterYourBankName" />}
                  />
                  <FormControl
                    fullWidth
                    className="formcontrol1"
                    // onKeyPress={(event) => {
                    //   if (event.key === 'Enter') {
                    //     handleEnter();
                    //   }
                    // }}
                  >
                    <Tooltip
                      title="Field is disabled,Click FETCH IFSC CODE Button"
                      placement="top-end"
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Enter Your Bank Name"
                        name="bankName"
                        value={bankDetails.bankName}
                        onChange={handleBankDetails}
                        disabled

                        // error={allValues.bankNameError}
                        // helperText={
                        //   allValues.bankNameError
                        //     ? 'Please enter in correct format'
                        //     : ''
                        // }
                      />
                    </Tooltip>
                  </FormControl>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="bank-col-2">
              <Row className="form-inner-card-right">
                <Col xs={8} md={12}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="enterYourIFSCcode" />}
                  />
                  <div className="d-flex justify-content-center align-items-center">
                    <FormControl
                      fullWidth
                      className="w-100"
                      // onKeyPress={(event) => {
                      //   if (event.key === 'Enter') {
                      //     handleEnter();
                      //   }
                      // }}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Enter Your Bank's IFSC Code"
                        name="IFSC_Code"
                        value={bankDetails.IFSC_Code}
                        onChange={handleBankDetails}
                      />
                    </FormControl>
                    <Button
                      className="px-2 mx-2 w-50 py-2 text-nowrap"
                      variant="contained"
                      onClick={handleFetchIFSC}
                    >
                      {bankDetails.IFSC_Is_Verified
                        ? "Verified"
                        : "Fetch Bank Details"}
                    </Button>
                  </div>
                </Col>

                <Col xs={12}>
                  {/* <Col xs={6} md={12}>
                                {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                  <Required
                    className="mt-4 mb-2"
                    title={<Translate value="enterYourBankBranch" />}
                  />
                  <FormControl fullWidth>
                    <Tooltip
                      title="Field is disabled,Click FETCH IFSC CODE Button"
                      placement="top-end"
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Enter Your Bank Branch"
                        name="bankBranch"
                        value={bankDetails.bankBranch}
                        onChange={handleBankDetails}
                        disabled
                      />
                    </Tooltip>
                  </FormControl>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="bank-col-3">
              <div
                className="form-inner-card-right row"
                style={{ marginLeft: "4px", marginRight: "2px" }}
              >
                {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                <Required
                  className="mt-0 mb-2"
                  title={<Translate value="enterYourBankAddress" />}
                />
                <FormControl
                  fullWidth
                  className="formcontrol1"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  <Tooltip
                    title="Field is disabled,Click FETCH IFSC CODE Button"
                    placement="top-end"
                  >
                    <TextareaAutosize
                      variant="outlined"
                      multiline
                      rows={3}
                      // rowsMax={4}
                      // defaultValue="Default Value"
                      placeholder="Enter Your Bank Address"
                      name="bankAddress"
                      value={bankDetails.bankAddress}
                      onChange={handleBankDetails}
                      disabled
                      style={{ resize: "none" }}
                    />
                  </Tooltip>
                </FormControl>
              </div>
            </Col>
            <Col xs={12} md={6} className="bank-col-4">
              <div
                className=" mt-4"
                style={{ marginLeft: "22px", marginRight: "40px" }}
              >
                {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                <Required
                  className="mt-4 mb-2"
                  title={<Translate value="uploadYourPassbookDocument" />}
                />

                <div className="browsebutton-outerdiv">
                  <div className="w-100">
                    {bankPassbookDoc &&
                      bankPassbookDoc.length > 0 &&
                      bankPassbookDoc.map((file, index) => (
                        <FormControl fullWidth className="formcontrol3 w-100">
                          <TextField
                            className="w-100"
                            variant="outlined"
                            placeholder="Click Browse to Upload"
                            // name="mail_id"
                            disabled
                            value={file.name}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    <IconButton
                                      onClick={() => handleRemoveFile(index)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      ))}
                    {bankPassbookID && bankPassbookID.length > 0 && (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          disabled
                          value={
                            bankPassbookID &&
                            bankPassbookID.length > 0 &&
                            bankPassbookID[0].file_name
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <IconButton
                                    onClick={() => setBankPassbookID(null)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                    {bankPassbookDoc && bankPassbookDoc.length === 0 && (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          disabled
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className="browsebutton2-div">
                    <div className="">
                      <input
                        type="file"
                        ref={bankPassbookRef}
                        onChange={handleBankPassbookFile}
                        multiple
                        hidden
                      />
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className="py-2 mx-2"
                        startIcon={<FolderIcon />}
                        onClick={() => bankPassbookRef.current.click()}
                      >
                        Browse
                      </Button>
                    </div>
                  </div>
                </div>

                <p className="mt-3 instructions2">
                  {/* <span><Translate value="instructions"/>*</span><br />
                                Format supporting document should be in JPEG, PNG, or PDF<br />
                                (Size contains 500 KB to 2 MB) */}
                  <span>
                    <Translate value="instructions" />*
                  </span>
                  <br />
                  <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                  <br />
                  <Translate value="sizecontains500KBto2MB" />
                </p>
              </div>
            </Col>
            <Col xs={12} className="note2 mt-4 ml-3 bank-col-5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nomineeCheck}
                    onChange={(ev) => setNomineeCheck(ev.target.checked)}
                    name="nomineeCheck"
                    // color="primary"
                  />
                }
                label={
                  <Translate value="clickheretoaddtheNomineeBankDetails" />
                }
              />
            </Col>
          </Row>

          {nomineeCheck ? (
            <>
              <Row className="bank-title-row">
                <Col xs={12} className="profile-title">
                  {/* <h1>Nominee's Bank Passbook / Statement</h1> */}
                  <h1>
                    <Translate value="nomineesBankPassbookStatement" />
                  </h1>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} md={6} className="bank-col-1">
                  <Row className="form-inner-card-right">
                    <Col xs={12}>
                      {/* <p className="mt-3 mb-2">Account Number*</p> */}
                      <Required
                        className="mt-3 mb-2"
                        title={<Translate value="accountNumber" />}
                      />
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          placeholder="Enter Your Account Number"
                          name="nomineeAccountNumber"
                          value={nomineeBankDetails.nomineeAccountNumber}
                          onChange={handleNomineeBankDetails}
                          // error={allValues.accountNumberNomineeError}
                          // helperText={
                          //   allValues.accountNumberNomineeError
                          //     ? 'Please enter in correct format'
                          //     : ''
                          // }
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                      <Required
                        className="mt-4 mb-2"
                        title={<Translate value="enterYourBankName" />}
                      />
                      <FormControl
                        fullWidth
                        className="formcontrol1"
                        // onKeyPress={(event) => {
                        //   if (event.key === 'Enter') {
                        //     handleEnter();
                        //   }
                        // }}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Enter your Bank Name"
                          name="nomineeBankName"
                          value={nomineeBankDetails.nomineeBankName}
                          onChange={handleNomineeBankDetails}
                          disabled
                          // error={allValues.bankNameNomineeError}
                          // helperText={
                          //   allValues.bankNameNomineeError
                          //     ? 'Please enter in correct format'
                          //     : ''
                          // }
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className="bank-col-2">
                  <Row className="form-inner-card-right">
                    <Col xs={8} md={12}>
                      {/* <p className="mt-3 mb-2">IFSC Code*</p> */}
                      <Required
                        className="mt-3 mb-2"
                        title={<Translate value="enterYourIFSCcode" />}
                      />
                      <div className="d-flex justify-content-center align-items-center">
                        <FormControl
                          fullWidth
                          className="w-100"
                          // onKeyPress={(event) => {
                          //   if (event.key === 'Enter') {
                          //     handleEnter();
                          //   }
                          // }}
                        >
                          <TextField
                            variant="outlined"
                            placeholder="Enter Your Bank's IFSC Code"
                            name="nominee_IFSC_Code"
                            value={nomineeBankDetails.nominee_IFSC_Code}
                            onChange={handleNomineeBankDetails}
                          />
                        </FormControl>
                        <Button
                          className="px-2 mx-2 w-50 py-2 text-nowrap"
                          variant="contained"
                          onClick={handleNomineeFetchIFSC}
                        >
                          {nomineeBankDetails.nomineeIFSCS_Is_Verified
                            ? "Verified"
                            : "Fetch Bank Details"}
                        </Button>
                      </div>
                    </Col>

                    <Col xs={12}>
                      {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                      <Required
                        className="mt-4 mb-2"
                        title={<Translate value="enterYourBankBranch" />}
                      />
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          placeholder="Enter Your Bank Branch"
                          name="nomineeBankBranch"
                          value={nomineeBankDetails.nomineeBankBranch}
                          onChange={handleNomineeBankDetails}
                          disabled
                          // error={allValues.bankBranchNomineeError}
                          // helperText={allValues.bankBranchNomineeError ? "Please enter in correct format" : ""}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className="bank-col-3">
                  <div
                    className="form-inner-card-right row"
                    style={{ marginLeft: "5px" }}
                  >
                    {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                    <Required
                      className="mt-0 mb-2"
                      title={<Translate value="enterYourBankAddress" />}
                    />
                    <FormControl
                      fullWidth
                      className="formcontrol1"
                      // onKeyPress={(event) => {
                      //   if (event.key === 'Enter') {
                      //     handleEnter();
                      //   }
                      // }}
                    >
                      <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={3}
                        // rowsMax={4}
                        // defaultValue="Default Value"
                        placeholder="Enter Your Bank Address"
                        name="nomineeBankAddress"
                        value={nomineeBankDetails.nomineeBankAddress}
                        onChange={handleNomineeBankDetails}
                        disabled
                        style={{ resize: "none" }}
                      />
                    </FormControl>
                  </div>
                </Col>
                <Col xs={12} md={6} className="bank-col-4">
                  <div className="form-inner-card-right mt-4">
                    <Required
                      className="mt-4 mb-2"
                      title={<Translate value="uploadYourPassbookDocument" />}
                    />

                    <div className="browsebutton-outerdiv">
                      <div className="w-100">
                        {nomineeBankPassbookDoc &&
                          nomineeBankPassbookDoc.length > 0 &&
                          nomineeBankPassbookDoc.map((file, index) => (
                            <FormControl
                              fullWidth
                              className="formcontrol3 w-100"
                            >
                              <TextField
                                className="w-100"
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                // name="mail_id"
                                disabled
                                value={file.name}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveNomineeFile(index)
                                          }
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))}
                        {nomineeBankPassbookDoc &&
                          nomineeBankPassbookDoc.length === 0 && (
                            <FormControl
                              fullWidth
                              className="formcontrol3 w-100"
                            >
                              <TextField
                                className="w-100"
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                disabled
                              />
                            </FormControl>
                          )}

                        {nomineeBankPassbookID &&
                          nomineeBankPassbookID.length > 0 && (
                            <FormControl
                              fullWidth
                              className="formcontrol3 w-100"
                            >
                              <TextField
                                className="w-100"
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                disabled
                                value={
                                  nomineeBankPassbookID &&
                                  nomineeBankPassbookID.length > 0 &&
                                  nomineeBankPassbookID[0].file_name
                                }
                              />
                            </FormControl>
                          )}
                      </div>
                      <div className="browsebutton2-div">
                        <div className="">
                          <input
                            type="file"
                            ref={nomineeBankPassbookRef}
                            onChange={handleNomineeBankPassbookFile}
                            multiple
                            hidden
                          />
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="py-2 mx-2"
                            startIcon={<FolderIcon />}
                            onClick={() =>
                              nomineeBankPassbookRef.current.click()
                            }
                          >
                            Browse
                          </Button>
                        </div>
                      </div>
                    </div>

                    <p className="mt-3 instructions2">
                      <span>
                        <Translate value="instructions" />*
                      </span>
                      <br />
                      <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                      <br />
                      <Translate value="sizecontains500KBto2MB" />
                    </p>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}

          <Row className="form-row">
            <Col xs={12} className="note2 text-center mt-4 bank-col-6">
              <p>
                <span>
                  <Translate value="note" />
                </span>
                <Translate value="noteAftercompletion" />
              </p>
            </Col>
          </Row>

          <Row className="button-inside-form-row mb-5">
            <Col xs={12} className="next-back-button-row mt-4 ">
              <Button
                variant="contained"
                color="error"
                style={{ width: "15%" }}
                className="py-3 mx-1"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: "15%", whiteSpace: "nowrap" }}
                color="success"
                className="py-3 mx-1"
                type="button"
                onClick={handleSubmit}
              >
                Save your details
              </Button>
            </Col>
          </Row>
        </Row>
      </Row>
    </>
  );
};

export default BankDetails;
