export default function stringSimilarity(str1, str2, gramSize = 3) {
  function getNGrams(s, len) {
    s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
    let v = [];
    for (let i = 0; i <= s.length - len; i++) {
      v.push(s.slice(i, i + len));
    }
    return v;
  }

  if (!str1?.length || !str2?.length) {
    return 0.0;
  }

  let s1 = str1.length < str2.length ? str1 : str2;
  let s2 = str1.length < str2.length ? str2 : str1;

  let pairs1 = getNGrams(s1, gramSize);
  let pairs2 = getNGrams(s2, gramSize);

  let freqMap = new Map();

  for (let gram of pairs1) {
    freqMap.set(gram, (freqMap.get(gram) || 0) + 1);
  }

  let total = pairs2.length;
  let hits = 0;

  for (let gram of pairs2) {
    if (freqMap.get(gram) > 0) {
      hits++;
      freqMap.set(gram, freqMap.get(gram) - 1);
    }
  }

  return hits / total;
}
