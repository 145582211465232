import 'date-fns';
import {
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
  ListItem,
  withStyles,
  Menu,
  SwipeableDrawer,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
// import home from 'assets/images/Icon material-dashboard 2.svg'
import home from 'assets/images/Icon material-home.svg';

import homewhite from 'assets/images/Icon material-homewhite.svg';
import user from 'assets/images/Icon awesome-user-edit.svg';
import userwhite from 'assets/images/Icon awesome-user-edit 2.svg';
import board from 'assets/images/Icon awesome-chalkboard-teacher.svg';
import boardwhite from 'assets/images/Icon awesome-chalkboard-teacher 2.svg';
import config from 'assets/images/Icon awesome-tools.svg';
import configwhite from 'assets/images/Icon awesome-tools 2.svg';
import mapping from 'assets/images/Icon awesome-link.svg';
import mappingwhite from 'assets/images/Icon awesome-link 2.svg';
import passWhite from 'assets/images/Password White.svg';
import passGrey from 'assets/images/Password Grey.svg';
import reports from 'assets/images/Icon material-report.svg';
import reportswhite from 'assets/images/Icon material-report.svg';
import vector from 'assets/images/Wall post-amico.svg';
import kbocwwb from 'assets/images/Image 8.png';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setLocaleWithFallback } from 'store/actions/user.actions';
import usericon from 'assets/images/Icon awesome-user.svg';
import logout from 'assets/images/Logout.svg';
import { Tooltip } from '@material-ui/core';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import NotificationIcon from 'assets/images/NotificationIcon.svg';
import Badge from '@mui/material/Badge';
// import NotificationsIcon from '@material-ui/icons';

import MenuIcon from '@material-ui/icons/Menu';
import { Pagination, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import cookie from 'react-cookies';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangePassword from 'components/adminPortal/changePassword';
import TransactionPassword from './transactionPassword';

const Profile = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [allnotifications, setallNotifications] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [notification, setNotification] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostPerPage] = React.useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    notification !== undefined &&
    notification.slice(indexOfFirstPost, indexOfLastPost);
  // const handleOpenDrawer = () => setopenDrawer(true);
  const toggleOpenDrawer = () => setopenDrawer(!openDrawer);

  const [profilebtn, setprofilebtn] = React.useState(false);
  const [circlename, setcirclename] = React.useState(null);

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    // activestep: users.profile_details.activestep,
    activestep: 1,
    percentComplete: users.profile_details.percentComplete,
    editmode: users.profile_details.editmode,
  });

  const onItemClickHandler = (num) => {
    setAllValues({
      ...allValues,
      activestep: num,
    });
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: '350px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElksu, setAnchorElksu] = React.useState(null);
  const [anchorElconfig, setAnchorElconfig] = React.useState(null);
  const [anchorElmapping, setAnchorElmapping] = React.useState(null);

  const onSelectClickHandlerkboc = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosekboc = () => {
    setAnchorEl(null);
  };

  const onSelectClickHandlerksu = (event) => {
    setAnchorElksu(event.currentTarget);
  };
  const handleCloseksu = () => {
    setAnchorElksu(null);
  };

  const onSelectClickHandlerconfig = (event) => {
    setAnchorElconfig(event.currentTarget);
  };
  const handleCloseconfig = () => {
    setAnchorElconfig(null);
  };

  const onSelectClickHandlermapping = (event) => {
    setAnchorElmapping(event.currentTarget);
  };
  const handleClosemapping = () => {
    setAnchorElmapping(null);
  };

  const toggleDrawerNew = () => {
    console.log('Drawer Toggle');
    setopenDrawer(false);
  };

  const getNotifications = (data, page) => {
    var config = {
      method: 'post',
      url: `${SERVER}/notifications/get_user_notifications?limit=5&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log('notification', response);

        setallNotifications(response.data);

        setNotification(response.data.docs);
      })
      .catch(function (error) {
        console.error('notification error: ' + error);
      });
  };

  useEffect(() => {
    // console.log("in useEffect [users.user]")
    // if (users.user !== null && users.user.board_id !== null) {

    // let dataForCatalog = { "board_id": 1, "catalog_name": "Registration Status" };
    // // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    // dispatch(getCatalogDetails(dataForCatalog))
    // }
    // }

    if (
      users.getDeptUserRole !== null &&
      users.getDeptUserRole.data !== undefined &&
      users.getDeptUserRole.data.length > 0
    ) {
      let data = JSON.stringify({
        board_id: users.getDeptUserRole.data[0].board_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
      });
      var config = {
        method: 'post',
        url: SERVER + '/config/circle/get_user_mapped_to_circle',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            'getUserMappedToCircle response: ' +
              JSON.stringify(response.data, undefined, 2),
          );

          setcirclename(response.data.data);
        })
        .catch(function (error) {
          console.error('getUserMappedToCircle error: ' + error);
        });
    }
  }, [users.user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (users.login_admin !== undefined && users.login_admin !== null) {
      var data = {
        receiver_id: users.login_admin.data.department_user_id,
        is_global: false,
        is_labour: true,
      };
      getNotifications(data, 1);
    } else {
      props.history.push('/admin');
    }
  }, []);

  const updateNotification = async (_id) => {
    var config = {
      method: 'patch',
      url: SERVER + '/notifications/update_notification_read',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: {
        id: _id,
        reader_id: users.login_admin.data.department_user_id,
      },
    };
    await axios(config)
      .then(function (response) {
        console.log(response);
        console.log('updated notification', response.data.data);
      })
      .catch(function (error) {
        console.error('notification error: ' + error);
      });

    var data = {
      receiver_id: users.login_admin.data.department_user_id,
      is_global: false,
      is_labour: true,
    };
    getNotifications(data, 1);
  };

  const [openNotifications, setOpenNotifications] = React.useState(false);
  // const handleOpenNotifications = () => setOpenNotifications(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenNotifications(open);
  };

  const handlePageChange = (event, value) => {
    console.log('event: ', event);
    console.log('value: ', value);

    var data = {
      receiver_id: users.login_admin.data.department_user_id,
      is_global: false,
      is_labour: true,
    };
    getNotifications(data, value);
  };

  const goBackDashboard = () => {
    setprofilebtn(false);
  };

  return (
    <>
      <SwipeableDrawer
        anchor={'right'}
        open={openNotifications}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          sx={{ width: 'auto', maxWidth: 412 }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className="list_notifications_box"
        >
          <div className="list_notifications_header">
            <div>Notifications</div>
            <CloseIcon
              className="closeNoti mr-2"
              onClick={toggleDrawer(false)}
            />
          </div>
          {/* <li key="index" className="list_notifications_clearall">
                        <Tooltip title="Clear All Notifications" placement="bottom-start" arrow interactive> 
                            <Link to="#">Clear All</Link>
                        </Tooltip>
                    </li> */}
          {console.log('allnotificationshere: ', allnotifications)}

          {allnotifications !== undefined &&
            allnotifications.docs !== undefined &&
            allnotifications.docs.map((x, index) => (
              <li key={index} className="list_notifications">
                <div>
                  <Tooltip
                    title="Clear Notification"
                    placement="left"
                    arrow
                    interactive
                  >
                    <CloseIcon
                      className="dismissNoti"
                      onClick={() => updateNotification(x._id)}
                    />
                  </Tooltip>
                  <p className="notification_time m-0 mb-2 p-0">
                    {moment(x.updatedAt).format('DD/MM/YYYY  hh:mm  a')}
                  </p>
                  <p className="m-0 p-0">{x.message}</p>
                </div>
              </li>
            ))}
          <nav>
            <Stack
              // spacing={2}
              className="p-2 pagination_noti"
            >
              <Pagination
                // showFirstButton showLastButton
                color="primary"
                count={allnotifications.totalPages}
                page={allnotifications.page}
                variant="outlined"
                onChange={handlePageChange}
              />
            </Stack>
          </nav>
        </Box>
      </SwipeableDrawer>

      <div className="root">
        {/* <CssBaseline /> */}
        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={7} lg={7} className="top-bar-col">
              <div className="dashboard-drawer">
                <MenuIcon fontSize="large" onClick={toggleOpenDrawer} />
              </div>
              <div className="logo-div-profile">
                {/* <a href=""> */}
                <img id="logo" src={logo} alt="..." className="logo-img" />
                {/* </a> */}
                {/* <img id="logo" src={logo} alt="..." className="logo-img" /> */}
                <h1 className="logo-text">
                  {/* {users.getDeptUserRole !== undefined && users.getDeptUserRole !== null  && users.getDeptUserRole.data !== undefined && users.getDeptUserRole.data !== null && users.getDeptUserRole.data[0].role_id === 2 ? "Karnataka State Unorganized Worker Social Security Board (KSUWSSB)" : users.getDeptUserRole !== undefined && users.getDeptUserRole !== null  && users.getDeptUserRole.data !== undefined && users.getDeptUserRole.data !== null && users.getDeptUserRole.data[0].role_id === 1 ? "Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)" : "Karnataka Labour Welfare Board (KLWB) "  }<br /> */}
                  Labour Department, Government of Karnataka
                </h1>
              </div>
            </Col>

            <Col className="notifications-logout-usericon">
              {/* <div style={{display:"flex",alignItems:"center", marginRight:"40px", marginTop:"3px"}}>
                                <Badge badgeContent={notification.length} color="primary">
                                OLD<img onClick={handleOpen} src={NotificationIcon} alt="image"/>
                                </Badge>
                            </div> */}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                  marginTop: '3px',
                }}
              >
                <Badge
                  badgeContent={
                    allnotifications !== undefined && allnotifications.totalDocs
                  }
                  color="primary"
                >
                  <img
                    onClick={toggleDrawer(true)}
                    src={NotificationIcon}
                    alt="image"
                  />
                </Badge>
              </div>

              <div className="usericon-header-logout">
                <img
                  alt="..."
                  src={usericon}
                  onClick={() => setprofilebtn(true)}
                  className="usericon-img"
                />
                <Modal
                  open={profilebtn}
                  onClose={() => setprofilebtn(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="modal-circle-main"
                >
                  <Box className="modal_box1">
                    {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                    </Typography> */}
                    <div className="logo-div-modal">
                      {/* <a href="/admin/dashboard"> */}
                      <img
                        id="logo"
                        style={{ marginTop: '60px' }}
                        src={logo}
                        alt="..."
                        className="logo-modal"
                      />
                      {/* </a> */}
                      {/* <img id="logo" src={logo} alt="..." className="logo-img" /> */}
                      <h1 className="logo-modal1" style={{ marginTop: '60px' }}>
                        {users.getDeptUserRole !== undefined &&
                        users.getDeptUserRole !== null &&
                        users.getDeptUserRole.data[0].role_id === 2
                          ? 'Karnataka State Unorganized Worker Social Security Board (KSUWSSB)'
                          : 'Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)'}
                        <br />
                        Government of Karnataka
                      </h1>
                      <div
                        style={{ marginLeft: '50px', marginTop: '50px' }}
                        onClick={goBackDashboard}
                      >
                        <CancelIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="modal_box-circle">
                      <div className="modal-username">
                        Username
                        <div className="modal-username1">
                          {users?.login_admin?.data?.name}
                        </div>
                      </div>

                      <div className="modal-circle1">
                        <div className="modal-circle2">
                          Circle Name
                          <div className="modal-circle3">
                            {circlename !== null &&
                              circlename.map((x, index) => (
                                <li key={index} className="list_circle">
                                  {x.circle_name}
                                </li>
                              ))}
                          </div>
                        </div>
                        <div className="modal-circle4">
                          Circle ID
                          <div className="modal-circle3">
                            {circlename !== null &&
                              circlename.map((x, index) => (
                                <li key={index} className="list_circle">
                                  {x.circle_id}
                                </li>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <p>{users?.login_admin?.data?.name}</p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    alt="..."
                    src={logout}
                    className="logout-img"
                    onClick={() => {
                      props.history.push('/admin');
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>

        <Drawer
          anchor="left"
          open={openDrawer}
          // className="dashboard-drawer"
          onClose={() => toggleDrawerNew()}
        >
          <div className="drawerContainer">
            <List>
              {users.getDeptUserRole !== undefined &&
              users.getDeptUserRole !== null &&
              users.getDeptUserRole.data !== undefined &&
              users.getDeptUserRole.data !== null &&
              users.getDeptUserRole.data[0] !== undefined &&
              users.getDeptUserRole.data[0] !== null &&
              users.getDeptUserRole.data[0].role_name !== undefined &&
              (users.getDeptUserRole.data[0].role_name === 'Admin' ||
                users.getDeptUserRole.data[0].role_name ===
                  'Joint Secretary') ? null : (
                <>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 1 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                </>
              )}
              {users.getDeptUserRole !== undefined &&
              users.getDeptUserRole !== null &&
              users.getDeptUserRole.data !== undefined &&
              users.getDeptUserRole.data !== null &&
              users.getDeptUserRole.data[0] !== undefined &&
              users.getDeptUserRole.data[0] !== null &&
              users.getDeptUserRole.data[0].role_name !== undefined &&
              (users.getDeptUserRole.data[0].role_name === 'Admin' ||
                users.getDeptUserRole.data[0].role_name ===
                  'Joint Secretary') ? (
                <>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 1 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  {/* <ListItem button key="" className={`listitem 
                                    ${allValues.activestep === 2 && 'activeitem'}`} 
                                    onClick={() => onItemClickHandler(2)} >
                                    <ListItemIcon>
                                        <img alt="..."
                                            src={allValues.activestep === 2 ? userwhite : user} />
                                    </ListItemIcon>
                                    <ListItemText primary="User" />
                                </ListItem> */}
                  {/* <ListItem button key="" className={`listitem 
                                    ${allValues.activestep === 3 && 'activeitem'}`} 
                                    onClick={() => onItemClickHandler(3)} >
                                    <ListItemIcon>
                                        <img alt="..."
                                            src={allValues.activestep === 3 ? boardwhite : board} />
                                    </ListItemIcon>
                                    <ListItemText primary="Board" />
                                </ListItem> */}
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 4 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(4)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 4 ? configwhite : config}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Configuration" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                            ${
                                              allValues.activestep === 5 &&
                                              'activeitem'
                                            }`}
                    onClick={() => onItemClickHandler(5)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={
                          allValues.activestep === 5 ? mappingwhite : mapping
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Mapping" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                            ${
                                              allValues.activestep === 6 &&
                                              'activeitem'
                                            }`}
                    onClick={() => onItemClickHandler(6)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={
                          allValues.activestep === 6 ? mappingwhite : mapping
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItem>
                </>
              ) : null}
            </List>

            {/* <div className="drawer-img-div">
                            <img alt="..." src={vector} />
                        </div> */}
          </div>
        </Drawer>

        <Drawer className="drawer" variant="permanent">
          <div className="drawerContainer">
            <List>
              {users.getDeptUserRole !== undefined &&
              users.getDeptUserRole !== null &&
              users.getDeptUserRole.data !== undefined &&
              users.getDeptUserRole.data !== null &&
              users.getDeptUserRole.data[0] !== undefined &&
              users.getDeptUserRole.data[0] !== null &&
              users.getDeptUserRole.data[0].role_name !== undefined &&
              (users.getDeptUserRole.data[0].role_name === 'Admin' ||
                users.getDeptUserRole.data[0].role_name ===
                  'Joint Secretary') ? null : (
                <>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 1 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                            ${
                                              allValues.activestep === 6 &&
                                              'activeitem'
                                            }`}
                    onClick={() => onItemClickHandler(6)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 6 ? passWhite : passGrey}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItem>
                  {/* <ListItem button key="" className={`listitem 
                                            ${allValues.activestep === 7 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(7)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    src={allValues.activestep === 7 ? passWhite : passGrey} />
                                            </ListItemIcon>
                                            <ListItemText primary="Transcation Password" />
                                        </ListItem> */}
                </>
              )}
              {users.getDeptUserRole !== undefined &&
              users.getDeptUserRole !== null &&
              users.getDeptUserRole.data !== undefined &&
              users.getDeptUserRole.data !== null &&
              users.getDeptUserRole.data[0] !== undefined &&
              users.getDeptUserRole.data[0] !== null &&
              users.getDeptUserRole.data[0].role_name !== undefined &&
              (users.getDeptUserRole.data[0].role_name === 'Admin' ||
                users.getDeptUserRole.data[0].role_name ===
                  'Joint Secretary') ? (
                <>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 1 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  {/* <ListItem button key="" className={`listitem 
                                    ${allValues.activestep === 2 && 'activeitem'}`} 
                                    onClick={() => onItemClickHandler(2)} >
                                    <ListItemIcon>
                                        <img alt="..."
                                            src={allValues.activestep === 2 ? userwhite : user} />
                                    </ListItemIcon>
                                    <ListItemText primary="User" />
                                </ListItem>
                                <ListItem button key="" className={`listitem 
                                    ${allValues.activestep === 3 && 'activeitem'}`} 
                                    onClick={() => onItemClickHandler(3)} >
                                    <ListItemIcon>
                                        <img alt="..."
                                            src={allValues.activestep === 3 ? boardwhite : board} />
                                    </ListItemIcon>
                                    <ListItemText primary="Board" />
                                </ListItem> */}
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 4 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(4)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 4 ? configwhite : config}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Configuration" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                    ${
                                      allValues.activestep === 5 && 'activeitem'
                                    }`}
                    onClick={() => onItemClickHandler(5)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={
                          allValues.activestep === 5 ? mappingwhite : mapping
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Mapping" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem 
                                            ${
                                              allValues.activestep === 6 &&
                                              'activeitem'
                                            }`}
                    onClick={() => onItemClickHandler(6)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 6 ? passWhite : passGrey}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItem>
                  {/* <ListItem button key="" className={`listitem 
                                            ${allValues.activestep === 7 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(7)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    src={allValues.activestep === 7 ? passWhite : passGrey} />
                                            </ListItemIcon>
                                            <ListItemText primary="Transcation Password" />
                                        </ListItem> */}
                </>
              ) : null}
            </List>

            {/* <div className="drawer-img-div">
                            <img alt="..." src={vector} />
                        </div>
                         */}
          </div>
        </Drawer>
        <main className="content">
          {
            {
              1: (
                <Row className="homepage-row">
                  {users.getDeptUserRole !== undefined &&
                    users.getDeptUserRole !== null &&
                    users.getDeptUserRole.data !== undefined &&
                    users.getDeptUserRole.data !== null &&
                    users.getDeptUserRole.data.length > 0 &&
                    users.getDeptUserRole.data.map((item, index) => (
                      <Col xs={6} sm={6} md={6}>
                        {/* <Link to="/admin/kbocwwb"> */}
                        <Link
                          to={
                            item.board_id === 4
                              ? '/admin/unorganized-dashboard'
                              : item.board_id === 1006
                                ? '/welfaredashboard'
                                : '/admin/dashboard'
                          }
                        >
                          <div className="homepage-image-div">
                            <img alt="..." src={kbocwwb} />
                            {/* <p>Karnataka Building & Other<br />Construction Workers Welfare Board</p> */}
                            <p>
                              {users.getDeptUserRole.data[index].board_name}
                            </p>
                            <p className={'m-0'}>
                              {
                                users.getDeptUserRole.data[index]
                                  .board_short_name
                              }
                            </p>
                          </div>
                        </Link>
                      </Col>
                    ))}

                  {/* <Col xs={6} sm={6} md={6} >
                                        <Link to="/admin/dashboard">
                                            <div className="homepage-image-div">
                                                <img alt="..." src={kbocwwb} />
                                                <p>{users.getDeptUserRole !== undefined && 
                                                users.getDeptUserRole.data[0].board_name}</p>
                                            </div>
                                        </Link>
                                    </Col> */}

                  {/* <Col xs={6} sm={6} md={6} >
                                        <div className="homepage-image-div">
                                            <img alt="..." src={kbocwwb} />
                                            <p>Karnataka State Unorganized Workers<br />Social Security Board</p>
                                        </div>
                                    </Col> */}
                </Row>
              ),

              2: (
                <Row className="userpage-row">
                  <Col xs={12} className="title">
                    Select User
                  </Col>
                  <Col xs={6} sm={6} md={6} className="userpage-col">
                    <div className="userpage-col-div hvr-icon-wobble-horizontal">
                      <p>User Registration</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={6} className="userpage-col">
                    <div className="userpage-col-div hvr-icon-wobble-horizontal">
                      <p>User Management</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                  </Col>
                </Row>
              ),
              3: (
                <Row className="boardpage-row">
                  <Col xs={12} className="title">
                    Select Your Board
                  </Col>
                  <Col xs={6} sm={6} md={6} className="boardpage-col">
                    <div
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      className="boardpage-col-div hvr-icon-hang"
                      onClick={(e) => onSelectClickHandlerkboc(e, 'kbocwwb')}
                    >
                      <p>K.B.O.C.W.W.B</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                      id="customized-menu"
                      className="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={(e) => handleClosekboc(e, 'kbocwwb')}
                    >
                      <MenuItem>
                        <ListItemText primary="KBOCWWB" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Registration Details" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Approval Details" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Schemes" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Schemes Approval" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Payment" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Officer Management" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Reports" />
                      </MenuItem>
                    </StyledMenu>
                  </Col>
                  <Col xs={6} sm={6} md={6} className="boardpage-col">
                    <div
                      onClick={(e) => onSelectClickHandlerksu(e, 'ksuwssb')}
                      aria-controls="customized-menu2"
                      aria-haspopup="true"
                      className="boardpage-col-div hvr-icon-hang"
                    >
                      <p>KSUWSSB</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                      id="customized-menu2"
                      className="customized-menu"
                      anchorEl={anchorElksu}
                      keepMounted
                      open={Boolean(anchorElksu)}
                      onClose={(e) => handleCloseksu(e, 'ksuwssb')}
                    >
                      <MenuItem>
                        <ListItemText primary="KSUWSSB" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Registration Details" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Approval Details" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Schemes" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Schemes Approval" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Payment" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Officer Management" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemText primary="Reports" />
                      </MenuItem>
                    </StyledMenu>
                  </Col>
                </Row>
              ),
              4: (
                <Row className="boardpage-row">
                  <Col xs={12} className="title">
                    Select Your Configuration
                  </Col>
                  <Col xs={6} sm={6} md={6} className="boardpage-col">
                    <div
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="boardpage-col-div hvr-icon-hang"
                      onClick={(e) => onSelectClickHandlerconfig(e, 'config')}
                    >
                      <p>Configuration</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                      id="simple-menu"
                      className="customized-menu"
                      anchorEl={anchorElconfig}
                      keepMounted
                      open={Boolean(anchorElconfig)}
                      onClose={(e) => handleCloseconfig(e, 'config')}
                    >
                      <MenuItem>
                        <Link to="/admin/ministry-master">
                          <ListItemText primary="Ministry Master" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/board-master">
                          <ListItemText primary="Board Master" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/catalog-master">
                          <ListItemText primary="Catalog Master" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/role-master">
                          <ListItemText primary="Role Master" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/circle-master">
                          <ListItemText primary="Circle Master" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/unionList">
                          <ListItemText primary="Union Master" />
                        </Link>
                      </MenuItem>
                    </StyledMenu>
                  </Col>
                </Row>
              ),
              5: (
                <Row className="boardpage-row">
                  <Col xs={12} className="title">
                    Select Your Mapping
                  </Col>
                  <Col xs={6} sm={6} md={6} className="boardpage-col">
                    <div
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="boardpage-col-div hvr-icon-hang"
                      onClick={(e) => onSelectClickHandlermapping(e, 'config')}
                    >
                      <p>Mapping</p>
                      <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                      id="simple-menu"
                      className="customized-menu mapping"
                      anchorEl={anchorElmapping}
                      keepMounted
                      open={Boolean(anchorElmapping)}
                      onClose={(e) => handleClosemapping(e, 'mapping')}
                    >
                      <MenuItem>
                        <Link to="/admin/mapping-master">
                          <ListItemText primary="State / District / Taluk / City / GMP mapping" />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/admin/userrolemapping">
                          <ListItemText primary="User Role mapping" />
                        </Link>
                      </MenuItem>
                      {/* <MenuItem>
                                            <ListItemText primary="Area / Circle mapping" />
                                        </MenuItem> */}
                    </StyledMenu>
                  </Col>
                </Row>
              ),
              6: <ChangePassword />,
              7: <TransactionPassword />,
            }[allValues.activestep]
          }
        </main>
      </div>
    </>
  );
};

export default Profile;
