import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Translate } from 'react-redux-i18n';

import TextField from '@mui/material/TextField';
import { FormControl, Paper } from '@material-ui/core';

import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import moment from 'moment';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import backIcon from 'assets/images/Icon awesome-arrow-circle-left.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PopOver from '../SchemesAdmin/PopOver';

const PensionDetails = () => {
  //   console.log(labourUserID)

  const currentUrl = window.location.href;

  const searchParams = new URLSearchParams(
    currentUrl.slice(currentUrl.indexOf('?')),
  );

  const { registrationNumber } = Object.fromEntries(searchParams.entries());

  const users = useSelector((state) => state.users);

  const [fetchPensionData, setFetchPensionData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, from) => {
    setAnchorEl(event.currentTarget);
    setFileID(from);
  };

  const handleNewTab = (url) => {
    window.open(url, '_blank', 'noopener');
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/file/${fileID}`;
    window.open(url, '_blank', 'noopener');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (registrationNumber && registrationNumber) {
      setLoading(true);
      const payLoad = {
        alcId: users && users.getDeptUserRole.data[0].department_user_id,
        registrationNumber: registrationNumber,
        mobile: null,
      };
      axios
        .post(`${SERVER}/schemes/continuation-pension`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then((res) => {
          setFetchPensionData(res.data.data);
          setLoading(false);

          //   console.log(mappedAahaarResponse, 'aadhaarResponse');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [registrationNumber, users]);

  // const name1 = 'Shivappa Doddabasannavar';
  // const name2 = 'PARAVVA BASAPPA DODABASANNAVAR';
  // console.log(fuzzyLogic(name1.toLowerCase(), name2.toLowerCase()));

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />

      {loading ? <SimpleBackdrop /> : null}
      <div className="root">
        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={4} lg={6} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/dashboard-user">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
          </Row>
        </Row>
        <div className="d-flex justify-content-start align-items-start ps-3 mt-3">
          <Link to={'/admin/dashboard/12'}>
            <img src={backIcon} alt="" />
          </Link>
        </div>
        <div className="text-center">
          <h2>Continuation of Pension Details</h2>
        </div>
      </div>
      {fetchPensionData && fetchPensionData.length && (
        <div className="d-flex justify-content-center align-items-center px-3 py-2">
          <Paper
            elevation={3}
            style={{
              width: '100%',
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              flexDirection: 'column',
            }}
          >
            <Row className="px-5 py-2 container-fluid w-100 py-3 g-3">
              <p className="fs-4 bg-light w-100">Beneficiary Details</p>
              <Col xs={12} md={4}>
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Name of the Beneficiary as per aadhar"
                  variant="standard"
                  value={fetchPensionData[0].beneficiary_name}
                  disabled
                  // onChange={(ev) => setBeneficiaryName(ev.target.value)}
                />
              </Col>

              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Mobile Number"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].mobile_number}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Registration Number"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].registration_no}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Reference Number"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].reference_no}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Date of Birth as per aadhar"
                  variant="standard"
                  disabled
                  value={moment(fetchPensionData[0].dob).format('DD/MM/YYYY')}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Permanent Address"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].permanent_address}
                  // onChange={(ev) => setPermanentAddress(ev.target.value)}
                />
              </Col>

              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Registration Date"
                  variant="standard"
                  disabled
                  value={moment(fetchPensionData[0].registrationdate).format(
                    'DD/MM/YYYY',
                  )}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Validity From Date"
                  variant="standard"
                  disabled
                  value={moment(fetchPensionData[0].validity_fromdate).format(
                    'DD/MM/YYYY',
                  )}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Validity To Date"
                  variant="standard"
                  disabled
                  value={moment(fetchPensionData[0].validity_todate).format(
                    'DD/MM/YYYY',
                  )}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Pension Availing From Date"
                  variant="standard"
                  disabled
                  value={moment(
                    fetchPensionData[0].pension_availing_from_date,
                  ).format('DD/MM/YYYY')}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Last Pension Recevied Date"
                  variant="standard"
                  disabled
                  value={moment(
                    fetchPensionData[0].last_pension_availed_date,
                  ).format('DD/MM/YYYY')}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="State"
                  variant="standard"
                  disabled
                  value={'Karnataka'}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>

              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="District"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].district_name}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Taluk"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].taluk_name}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>

              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="City"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].gram_panchayath_name}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Village"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].village_area_ward}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              {/* <Col xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  className="w-100"
                  label="Circle"
                  variant="standard"
                  disabled
                  value={fetchPensionData[0].circle_name}
                  // onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col> */}
            </Row>
            <Row className="px-5 py-2 container-fluid g-3">
              <p className="fs-4 bg-light w-100">Uploaded Documents</p>

              <Col xs={12} md={6}>
                <Typography>Labour Card</Typography>
                <div className="browsebutton-outerdiv">
                  <div className="w-100">
                    {fetchPensionData[0]?.labour_card_document_id &&
                    fetchPensionData[0]?.labour_card_document_id.length > 0 ? (
                      fetchPensionData[0]?.labour_card_document_id.map(
                        (row) => (
                          <FormControl fullWidth>
                            <TextField
                              style={{ border: '1px solid grey' }}
                              disabled
                              value={row.file_name}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {(row.file_name.includes('.png') ||
                                      row.file_name.includes('.jpeg') ||
                                      row.file_name.includes('.jpg')) && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handleViewImage(
                                            ev,
                                            row.file_id,
                                            row.file_name,
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                    {row.file_name.includes('.pdf') && (
                                      <IconButton
                                        onClick={() =>
                                          handleViewPDF(row.file_id)
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ),
                      )
                    ) : (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            style={{ border: '1px solid grey' }}
                            value={'Document not uploaded by the ALC'}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Typography>Labour Photo</Typography>
                <div className="browsebutton-outerdiv">
                  <div className="w-100">
                    {fetchPensionData[0]?.labour_latest_photo_id &&
                    fetchPensionData[0]?.labour_latest_photo_id.length > 0 ? (
                      fetchPensionData[0]?.labour_latest_photo_id.map((row) => (
                        <FormControl fullWidth>
                          <TextField
                            style={{ border: '1px solid grey' }}
                            disabled
                            value={row.file_name}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {(row.file_name.includes('.png') ||
                                    row.file_name.includes('.jpeg') ||
                                    row.file_name.includes('.jpg')) && (
                                    <IconButton
                                      onClick={(ev) =>
                                        handleViewImage(
                                          ev,
                                          row.file_id,
                                          row.file_name,
                                        )
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  )}
                                  {row.file_name.includes('.pdf') && (
                                    <IconButton
                                      onClick={() => handleViewPDF(row.file_id)}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      ))
                    ) : (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            style={{ border: '1px solid grey' }}
                            value={'Document not uploaded by the ALC'}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Typography>Pension Order</Typography>
                <div className="browsebutton-outerdiv">
                  <div className="w-100">
                    {fetchPensionData[0]?.latest_pesion_order_document &&
                    fetchPensionData[0]?.latest_pesion_order_document.length >
                      0 ? (
                      fetchPensionData[0]?.latest_pesion_order_document.map(
                        (row) => (
                          <FormControl fullWidth>
                            <TextField
                              style={{ border: '1px solid grey' }}
                              disabled
                              value={row.file_name}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {(row.file_name.includes('.png') ||
                                      row.file_name.includes('.jpeg') ||
                                      row.file_name.includes('.jpg')) && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handleViewImage(
                                            ev,
                                            row.file_id,
                                            row.file_name,
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                    {row.file_name.includes('.pdf') && (
                                      <IconButton
                                        onClick={() =>
                                          handleViewPDF(row.file_id)
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ),
                      )
                    ) : (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            style={{ border: '1px solid grey' }}
                            value={'Document not uploaded by the ALC'}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Paper>
        </div>
      )}
    </>
  );
};

export default PensionDetails;
