import {
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Required } from 'utils/tools';

const EducationAssitance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  loading,
  handleAadhaar,
  aadhaarNumber,
  handleVerifyClick,
  isAPICalled,
  isVerified,
  handleReset,
  disableBtn,
  kannadaSelected,
}) => {
  return (
    <>
      <Row className="mx-0">
        <Col>
          <p className="mb-2 mt-4">
            Enter Child's Aadhaar Number to Verify the Details
          </p>
          <div className="d-flex justify-content-start align-items-center">
            <FormControl>
              <TextField
                type="text"
                placeholder="Enter Aadhaar Number"
                onChange={handleAadhaar}
                value={aadhaarNumber}
                inputProps={{ minLength: 0, maxLength: 12 }}
                variant="outlined"
                disabled={disableBtn}
              />
            </FormControl>
            {isVerified ? (
              <Button className="mx-2 py-2" onClick={handleVerifyClick}>
                Verified
              </Button>
            ) : (
              <Button
                color="success"
                className="mx-2 py-2"
                onClick={handleVerifyClick}
                disabled={disableBtn}
              >
                Verify
              </Button>
            )}
            <Button
              disabled={
                aadhaarNumber && aadhaarNumber.length > 0 ? false : true
              }
              color="success"
              className="py-2 bg-danger"
              onClick={handleReset}
            >
              <RestartAltIcon />
              Reset
            </Button>
          </div>
        </Col>
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3">
            Please wait fetching fields....
          </p>
        </div>
      ) : (
        isAPICalled &&
        dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map((field) => (
          <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
            <Required
              className="mb-2 mt-4"
              title={
                kannadaSelected ? field.field_name_kannada : field.field_name
              }
            />
            {field.field_type === 'Text' && (
              <>
                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    type={'text'}
                    name={field.field_name}
                    onChange={(ev) => handleChange(ev, field, field.field_name)}
                    value={
                      field.field_name === 'Child Aadhaar Number'
                        ? aadhaarNumber
                        : formData && formData[field.field_name]
                    }
                    variant="outlined"
                    // helperText={allValues['error_' + field.id]}
                    // error={allValues.globalError}
                    disabled={
                      field.field_name === 'Age of the child' ||
                      field.field_name === 'Child Aadhaar Number'
                        ? true
                        : false
                    }
                  />
                </FormControl>
              </>
            )}
            {field.field_type === 'Date' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDate={
                    field.field_name.includes('Date Of Marriage')
                      ? moment().subtract(6, 'months')
                      : new Date(-999999)
                  }
                  maxDate={new Date()}
                  // onClick={(e) => onChangeDate(e, field)}
                  // onClose={() => setPickerStatus(false)}
                  // open={allValues.openDatePicker}
                  className="datepicker"
                  margin="normal"
                  format="DD/MM/YYYY"
                  name={field.field_name}
                  // id={id}
                  value={
                    formData && formData[field.field_name]
                      ? formData[field.field_name]
                      : null
                  }
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  // onChange={setDate}
                  placeholder="DD/MM/YYYY"
                  // error={allValues.globalError}
                />
              </MuiPickersUtilsProvider>
            )}

            {(field.field_type === 'Dropdown' ||
              field.field_type === 'Radio Button') && (
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital"
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name={field.field_name}
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  value={(formData && formData[field.field_name]) || '0'}
                >
                  <MenuItem value="0">
                    <ListItemText primary={`Select`} />
                  </MenuItem>
                  {field.field_data.map((field) => (
                    <MenuItem value={field.field_value_id}>
                      <ListItemText primary={field.field_value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Col>
        ))
      )}
    </>
  );
};

export default EducationAssitance;
