import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  TextareaAutosize,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import checkwhite from "assets/images/Subtraction 1.svg";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import checkgreen from "assets/images/Subtraction 1.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  getFile,
  addLabourBankDetails,
  TS_USERNAME,
  updateBankData,
  getUser,
  SERVER,
  setLocaleWithFallback,
  uploadFile,
  addLabourFamilyDetails,
  updateFamilyData,
  getCatalogDetails,
  BASE_URL,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { finalSubmit } from "store/actions";
import close from "assets/images/closeIcon.svg";
import checkgrey from "assets/images/Subtraction 2.svg";
import aadhar from "assets/images/Aadhar black-01.svg";
import { AddCircleRounded } from "@material-ui/icons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import stringSimilarity from "utils/nameMatching";
// import { session } from 'electron';

function RegistrationAddress(props) {
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [residenceType, setResidenceType] = useState([]);
  const [residenceId, setResidenceId] = useState();
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState();
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [taluk, setTaluk] = useState([]);
  const [talukId, setTalukId] = useState();
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState();
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState([]);

  const [loadingfiles, setloadingfiles] = useState(true);
  const [selectedLabour, setSelectedLabour] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [aadharDuplicate, setaadharDuplicate] = React.useState(false);
  const [aadharValidated, setAadharValidated] = React.useState(false);
  const [vaultRefNumber, setVaultRefNumber] = React.useState(null);
  const [circleCode, setCircleCode] = React.useState(null);
  const [circleData, setCircleData] = React.useState(null);
  const [imageEKYC, setImageEKYC] = React.useState(null);
  const [circleDataFound, setCircleDataFound] = React.useState(null);

  const [circleDataFoundDistrict, setCircleDataFoundDistrict] =
    React.useState(null);
  const [circleDataFoundTaluk, setCircleDataFoundTaluk] = React.useState(null);
  const [circleDataFoundCity, setCircleDataFoundCity] = React.useState(null);
  const [circleDataFoundVillage, setCircleDataFoundVillage] =
    React.useState(null);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [nameMatched, setNameMatched] = useState(false);
  const [labourDoc, setLabourDoc] = React.useState(null);
  const [uploadImageid, setUploadImageid] = React.useState(null);
  const [familyData, setFamilyData] = React.useState(null);
  const [bankData, setBankData] = React.useState(null);
  const [final, setFinal] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [Religion, setReligion] = React.useState([]);
  const [natureOfWork, setNatureOfWork] = useState([]);

  const [isCaste, setIsCaste] = useState(false);
  const [isReligion, setIsReligion] = useState(false);
  const [isNatureOfWork, setIsNatureOfWork] = useState(false);
  // const [isCaste,setIsCaste] = useState(false)

  const [pinError, setPinError] = React.useState("");
  const [accError, setAccError] = React.useState("");

  const [tempID, setTempID] = React.useState(null);

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [phone, setPhone] = React.useState("");
  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [filesxmlPresentNominee, setfilesxmlPresentNominee] =
    React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState({});
  const [selectedfilesNominee, setselectedfilesNominee] = React.useState({});

  const [passbookuploadresults, setpassbookuploadresults] = React.useState({});
  const [passbookuploadNomineeresults, setpassbookuploadNomineeresults] =
    React.useState({});

  const [filelist, setfilelist] = React.useState({});

  const [removedFile, setRemovedFile] = React.useState(false);

  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    state_name: "",
    district_name: "",
    taluk_name: "",
    village_name: "",
    street_name: "",
    landmark: "",
    house_no: "",
    pincode: "",
    state_name_error: "",
    district_name_error: "",
    taluk_name_error: "",
    village_name_error: "",
    street_name_error: "",
    landmark_error: "",
    house_no_error: false,
    pincode_error: false,
    category: "",
    religion: "",
    gender: null,
    natureOfWork: "",
    isAyushMan: false,
    ayushManCard: "",
    ifscCode: users.bank_details.ifscCode,
    accountNumber: users.bank_details.accountNumber,
    bankName: users.bank_details.bankName,
    bankBranch: users.bank_details.bankBranch,
    bankAddress: users.bank_details.bankAddress,
    imgfile: users.bank_details.imgfile,
    passbookDocumentName: users.bank_details.passbookDocumentName,
    passbookUploadResponse: users.bank_details.passbookUploadResponse,
    nomineeCheck: users.bank_details.nomineeCheck,

    ifscCode_nominee: users.bank_details.ifscCode_nominee,
    accountNumber_nominee: users.bank_details.accountNumber_nominee,
    bankName_nominee: users.bank_details.bankName_nominee,
    bankBranch_nominee: users.bank_details.bankBranch_nominee,
    bankAddress_nominee: users.bank_details.bankAddress_nominee,
    imgfile_nominee: users.bank_details.imgfile_nominee,
    passbookDocumentName_nominee:
      users.bank_details.passbookDocumentName_nominee,
    passbookUploadResponse_nominee:
      users.bank_details.passbookUploadResponse_nominee,

    accountNumberError: false,
    bankNameError: false,
    // bankBranchError:false,
    accountNumberNomineeError: false,
    bankNameNomineeError: false,
    // bankBranchNomineeError:false

    //family
    rationCardNo: users.family_details.rationCardNo,
    rationCardType: users.family_details.rationCardType,
    imgfile: users.family_details.imgfile,
    webcamEnabled: users.family_details.webcamEnabled,
    open: users.family_details.open,
    setOpen: users.family_details.setOpen,
    openDatePicker: users.family_details.openDatePicker,

    rationDocumentName: users.family_details.rationDocumentName,

    formcontrolnum: 1,

    membersList: users.family_details.membersList,
    // membersList: [{
    //     "id": 0,
    //     "relation0": users.family_details.membersList[0]['relation0'],
    //     "firstname0": users.family_details.membersList[0]['firstname0'],
    //     "middlename0": users.family_details.membersList[0]['middlename0'],
    //     "lastname0": users.family_details.membersList[0]['lastname0'],
    //     "dob0": users.family_details.membersList[0]['dob0'],
    //     "profession0": users.family_details.membersList[0]['profession0'],
    //     "education0": users.family_details.membersList[0]['education0'],
    //     "nominee0": users.family_details.membersList[0]['nominee0'],
    //     "alreadyRegistered0": users.family_details.membersList[0]['alreadyRegistered0'],
    //     "registrationNo0": users.family_details.membersList[0]['registrationNo0'],
    //     "aadharnum1_0": users.family_details.membersList[0]['aadharnum1_0'],
    //     "aadharnum2_0": users.family_details.membersList[0]['aadharnum2_0'],
    //     "aadharnum3_0": users.family_details.membersList[0]['aadharnum3_0'],
    //     "aadhar0": users.family_details.membersList[0]['aadhar0'],
    //     "formcontrolnum0": users.family_details.membersList[0]['formcontrolnum0'],
    // }],
    noOfFamilyMembers: users.family_details.noOfFamilyMembers,

    rationCardTypes: [],
    relations: [],
    professions: [],
    educations: [],
    documentUploadResponse: "",
    firstnameError: false,
    lastnameError: false,
    middlenameError: false,
    migrantState: "",
    isMigrant: "",
  });
  console.log("ALLVALUESSEVA", allValues);
  // useEffect(() => {
  //     if(final){
  //         submitFinal()
  //         // setFinal(false)
  //     }

  // },[final])

  const [aadhaarToken, setAadhaarToken] = React.useState(null);
  const [redirectback, setRedirectback] = React.useState(false);

  console.log("AllValues", allValues);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const handleValidation = (ev) => {
    if (ev.target.name === "street_name") {
      setAllValues({
        ...allValues,
        [ev.target.name]: ev.target.value,
      });
    } else if (ev.target.name === "landmark") {
      setAllValues({
        ...allValues,
        [ev.target.name]: ev.target.value,
      });
    } else if (ev.target.name === "house_no") {
      if (ev.target.value.length < 16) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          house_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          house_no_error: true,
        });
      }
    } else if (ev.target.name === "pincode") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 6
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          pincode_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          pincode_error: true,
        });
      }
    }
  };

  console.log("selectedfiles", selectedfiles);

  const getResidenceType = async () => {
    try {
      const data = {
        key: "user_id",
        board_id: 1,
        procedure_name: "all",
        catalog_name: "Type of Residence",
      };
      const response = await axios.post(
        `${SERVER}/global/get_catalog_details`,
        data
      );
      console.log("responseResidence:", response.data.data);
      setResidenceType(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const handleResidence = (event) => {
    const getResidenceId = event.target.value;
    console.log(getResidenceId);
    setResidenceId(getResidenceId);
  };

  const getStateDetails = async () => {
    try {
      const data = {
        key: "user_id",
        board_id: 1,
        procedure_name: "all",
        catalog_name: "Type of Residence",
      };
      const response = await axios.get(
        `${SERVER}/global/get_state_details`,
        data
      );
      console.log("responseState:", response.data.data);

      setStates(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const handleState = (event) => {
    const getStateId = event.target.value;
    console.log(getStateId);
    setStateId(getStateId);
  };

  console.log("MIgrant Dropdown", allValues.isMigrant);

  const getDistrictDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_district_details?state_id=${stateId}`
      );
      console.log("responseDistrict:", response.data.data);
      // setDistrict(response.data.data)
      console.log(circleDataFoundDistrict, "circleDataFoundDistrictLoop");
      if (circleDataFoundDistrict !== null) {
        var districtData = response.data.data;
        console.log("districtData", districtData);
        getDistrictFilteredData(districtData, circleDataFoundDistrict);
      } else {
        setDistrict(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getDistrictFilteredData = (districtData, circleDataFoundDistrict) => {
    var fieldArray = [];
    districtData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArray");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundDistrict.indexOf(e) > -1;
    });
    console.log("intersection", intersection);
    // setDistrict(intersection)
    // districtData.forEach((field) => {
    //     if(field.dis)
    // })
    var districtDataFinal = districtData.filter((value) =>
      value.name.includes(intersection)
    );
    // var districtDataFinal = []
    console.log("districtDataFinal", districtDataFinal);
    setDistrict(districtDataFinal);
  };

  const handleDistrict = (event) => {
    const getDistrictId = event.target.value;
    console.log(getDistrictId);
    setDistrictId(getDistrictId);
  };

  const getTalukDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_taluk_details?district_id=${districtId}`
      );
      console.log("responseTaluk:", response.data.data);

      if (circleDataFoundTaluk !== null) {
        var talukData = response.data.data;
        getTalukFilteredData(talukData, circleDataFoundTaluk);
      } else {
        setTaluk(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getTalukFilteredData = (talukData, circleDataFoundTaluk) => {
    var fieldArray = [];
    talukData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArray");
    console.log(circleDataFoundTaluk, "circleDataFoundTaluk");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundTaluk.indexOf(e) > -1;
    });
    console.log("intersectionTaluk", intersection);
    var talukDataFinal = talukData.filter((value) =>
      value.name.includes(intersection)
    );
    var talukDataFinal = [];
    intersection.forEach((field) => {
      let talukDataFina = talukData.filter((value) => value.name === field);
      talukDataFinal.push(...talukDataFina);
    });
    console.log("talukDataFinal", talukDataFinal);
    setTaluk(talukDataFinal);
  };

  const handleTaluk = (event) => {
    const getTalukId = event.target.value;
    setTalukId(getTalukId);
  };

  const getCityDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_city_details?taluk_id=${talukId}`
      );
      console.log("responseCity:", response.data.data);
      if (circleDataFoundCity !== null) {
        var cityData = response.data.data;
        getCityFilteredData(cityData, circleDataFoundCity);
      } else {
        setCity(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getCityFilteredData = (cityData, circleDataFoundCity) => {
    var fieldArray = [];
    cityData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArraygetCityFilteredData");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundCity.indexOf(e) > -1;
    });
    console.log("intersectiongetCityFilteredData", intersection);
    var cityDataFinal = cityData.filter((value) =>
      value.name.includes(intersection)
    );
    var cityDataFinal = [];
    intersection.forEach((field) => {
      let cityDataFina = cityData.filter((value) => value.name === field);
      cityDataFinal.push(...cityDataFina);
    });
    console.log("talukDataFinalgetCityFilteredData", cityDataFinal);
    setCity(cityDataFinal);
  };

  const handleCity = (event) => {
    const getCityId = event.target.value;
    setCityId(getCityId);
  };

  const getVillageDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_village_details?city_id=${cityId}`
      );
      console.log("responseVillage:", response.data.data);
      if (circleDataFoundVillage !== null) {
        var villageData = response.data.data;
        console.log("Village_get_village_details", villageData);
        getVillageFilteredData(villageData, circleDataFoundVillage);
      } else {
        setVillage(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };
  const getVillageFilteredData = (villageData, circleDataFoundVillage) => {
    console.log("circleDataFoundVillage", circleDataFoundVillage);
    var fieldArray = [];
    villageData.forEach((field) => {
      // console.log("fieldValues",field.name)

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArrayV");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundVillage.indexOf(e) > -1;
    });
    console.log("intersectionVillage008", intersection);
    // var villageDataFinal= villageData.filter(value => value.name.includes(intersection))
    var villageDataFinal = villageData.filter((value) =>
      circleDataFoundVillage.some((item) => value.name === item)
    );

    // var villageDataFinal = []
    // intersection.forEach((field) => {
    //     console.log("FOREACHVILLAGE:",field);
    //     let villageDataFina= villageData.filter(value => value.id === field)
    //     console.log("",villageDataFina);
    //     villageDataFinal.push(...villageDataFina)

    // })
    // var villageDataFinal= villageData.filter(value => value.id === 19107)
    console.log("villageDataFinal", villageDataFinal);
    setVillage(villageDataFinal);
  };

  const handleVillage = (event) => {
    console.log("event.target.value", event.target.value);
    const getVillageId = event.target.value;
    setVillageId(getVillageId);
  };

  // const submitButton = () => {
  //     console.log("allValues", allValues)
  //     console.log("allValues", residenceId)
  //     console.log("allValues", villageId)
  //     console.log("allValues", cityId)
  //     console.log("allValues", talukId)
  //     console.log("allValues", stateId)
  //     console.log("allValues", uploadImageid)

  //     console.log("allValues",tempID)
  //     console.log("allValues", imageEKYC)

  //     setFinal(!final)

  // }

  console.log("LocalStorageTempID", localStorage.getItem("TempID"));

  const submitButton = () => {
    var families = [];

    for (var i = 0; i < users.family_details.noOfFamilyMembers; i++) {
      if (
        // users.family_details.rationCardNo === "" ||
        // users.family_details.rationCardType === "" ||
        // users.family_details.imgfile === "" ||
        // sessionStorage.getItem('rationCard') === null ||

        users.family_details.membersList[i]["firstname" + i] === "" ||
        // users.family_details.membersList[i]["lastname" + i] === "" ||
        // users.family_details.membersList[i]["relation" + i] === "" ||
        users.family_details.membersList[i]["aadhar" + i] === "" ||
        users.family_details.membersList[i]["dob" + i] === null ||
        users.family_details.membersList[i]["profession" + i] === "" ||
        users.family_details.membersList[i]["education" + i] === ""
        // users.family_details.membersList[i]["alreadyRegistered" + i] === "" ||

        // users.family_details.membersList[i]["alreadyRegistered" + i] === ""
      ) {
        console.log(
          "users.family_details.membersList.firstname=" +
            users.family_details.membersList[i]["firstname" + i]
        );
        showToast("WARN", "Please Fill All the Required Fields to Continue!");
        if (!vaultRefNumber) {
          showToast("WARN", "Please Verify Aadhaar !!");
        }
      }

      if (users.family_details.membersList[i].relation_id !== undefined) {
        console.log("Called Family001");
        families.push({
          relation_id: users.family_details.membersList[i].relation_id,
          labour_user_id: 0,
          parent_child_user_id:
            users.family_details.membersList[i].parent_child_user_id,
          isinsert: 0,
          isdeleted: 0,
          isactive: 1,
          relation_type_id:
            users.family_details.membersList[i]["relation_id" + i],
          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          en_aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered: 0,
          registeration_no: sessionStorage.getItem("registrationID"),
        });
      } else {
        console.log("Called Family002");
        families.push({
          relation_id: 0,
          labour_user_id: 0,
          parent_child_user_id: 0,
          isinsert: 1,
          isdeleted: 0,
          isactive: 1,
          relation_type_id:
            users.family_details.membersList[i]["relation_id" + i],
          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          en_aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered: 0,
          registeration_no: sessionStorage.getItem("registrationID"),
        });
      }

      console.log("DataFamily", users.family_details.membersList);
      console.log("DataBank", bankData);

      console.log("FAMILYDETAILS002", families);
    }
    if (
      (residenceId !== undefined && residenceId.length > 0) ||
      (residenceId !== undefined && residenceId.length > 0) ||
      allValues.house_no !== "" ||
      allValues.street_name !== "" ||
      (villageId !== undefined && villageId.length > 0) ||
      (cityId !== undefined && cityId.length > 0) ||
      (talukId !== undefined && talukId.length > 0) ||
      (districtId !== undefined && districtId.length > 0) ||
      (stateId !== undefined && stateId.length > 0) ||
      // allValues.landmark !== "" ||
      allValues.pincode !== "" ||
      uploadImageid !== null ||
      allValues.ifscCode ||
      allValues.accountNumber !== "" ||
      allValues.bankName ||
      allValues.bankBranch ||
      allValues.bankAddress ||
      filelist.length > 0 ||
      filelist.length !== undefined
    ) {
      console.log("filelist", filelist);
      setOpenBackdrop(true);
      console.log("FamilyDetails001", families);
      // setLoading(true)
      //    if(tempID !== undefined){
      var data = {
        board_id: 1,
        temp_id: sessionStorage.getItem("TempID"),
        vault_ref_no: vaultRefNumber,
        verified_mobile_no: sessionStorage.getItem("mobileNumber"),
        old_reg_card: uploadImageid,
        user_profile_pic: "null",
        address_details_xml: [
          {
            address_base_type_id: 10,
            address_type_id: residenceId,
            door_no: allValues.house_no,
            street_name: allValues.street_name,
            village_area_id: villageId,
            city_town_gram_panchayat_id: cityId,
            taluk_id: talukId,
            district_id: districtId,
            state_id: stateId,
            land_mark: allValues.landmark,
            pin_code: allValues.pincode,
          },
        ],
        family_details_xml: families,
        bank_details_xml: [
          {
            ifsc_code: allValues.ifscCode,
            account_no: allValues.accountNumber,
            bank_name: allValues.bankName,
            bank_branch: allValues.bankBranch,
            bank_address: allValues.bankAddress,
            bank_passbook_upload_id: null,
            bank_upload_files_xml: JSON.stringify(filelist),
            is_nominee: 1,
          },
        ],
        is_inter_state_migrant: allValues.isMigrant,
        migrate_from_state_id:
          allValues.isMigrant === "1" ? allValues.migrantState : null,
        natureofwork: allValues.natureOfWork,
        is_ayushman_card_holder: allValues.isAyushMan,
        ayushman_card_number:
          allValues.isAyushMan === "1" ? allValues.ayushManCard : null,
        gender_id: allValues.gender,
        catalog_value_religion_id: allValues.religion,
        catalog_value_caste_id: allValues.category,
      };

      var config = {
        method: "post",
        url: SERVER + "/seva_sindhu/update_labour_to_master",

        data: data,
      };

      console.log("DATACOPYLABOUR", config);

      axios(config)
        .then(function (response) {
          console.log("checkAadhaarDuplicate", JSON.stringify(response));
          if (response.data.data.recordsets[0][0].error) {
            showToast("ERROR", response.data.data.recordsets[0][0].error);
          } else {
            console.log("checkAadhaarDuplicate" + response);
            dispatch(updateFamilyData(init_family_details));

            setSubmitSuccess(true);
            // localStorage.removeItem('TempID')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (allValues.pincode.length < 6) {
      showToast("ERROR", "Pin code must contain 6 digits");
    }

    // }
    else {
      showToast("ERROR", "Please fill all the fields.");
    }
  };

  const VerifyButton = () => {
    var currentDateTime = Date.now();
    var randNum = Math.floor(100000 + Math.random() * 900000);
    console.log("randNum: " + randNum);

    console.log(currentDateTime, "currentDateTime");

    //Production Data

    var data = JSON.stringify({
      deptCode: "9",
      integrationKey: "0dac67ae-00bb-47fb-8c10-f5fc1776e91d",
      integrationPassword: "-t-69hpP8K?Do-VX",
      txnNo: randNum,
      txnDateTime: currentDateTime,
      serviceCode: "3",
      responseRedirectURL: `${BASE_URL}/register-address`,
    });

    // Local Production Data

    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "0dac67ae-00bb-47fb-8c10-f5fc1776e91d",
    //     "integrationPassword": "-t-69hpP8K?Do-VX",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "http://localhost:3000/register-address"
    // });

    //Dev Data

    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    //     "integrationPassword": "@895u5Xxe-2!7!!Y",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "https://gcdmsalpha.hostg.in:7701/register-address"
    // });

    // local host data

    // var data = JSON.stringify({
    // "deptCode": "9",
    // "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    // "integrationPassword": "@895u5Xxe-2!7!!Y",
    // "txnNo": randNum,
    // "txnDateTime": currentDateTime,
    // "serviceCode": "3",
    // "responseRedirectURL": "http://localhost:3000/register-address"
    // });

    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    //     "integrationPassword": "@895u5Xxe-2!7!!Y",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "https://gcdmsdev.web.app/register-address"

    // });

    var config = {
      method: "post",
      url: SERVER + "/global/get_ekyc_token",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log(
          "checkAadhaarDuplicate response:" +
            JSON.stringify(response.data.token.Token)
        );
        setAadhaarToken(response.data.token.Token);
        var aadharToken = response.data.token.Token;
        if (aadharToken !== null) {
          // window.open("https://dbt.karnataka.gov.in:8443/HSM_Service_ASPX/EKYCService.aspx?key=a6ed232e-0bd9-4c24-89d7-67ba59bef639&token=" + aadharToken, "_self")

          //Production Data

          window.open(
            "https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=" +
              aadharToken,
            "_self"
          );

          setRedirectback(true);
        }
      })
      .catch((error) => {
        showToast("warn", "Pleasr Verify Aadhaar !!");
      });
  };

  useEffect(() => {
    getResidenceType();
    getStateDetails();
    getDistrictDetails();
    getTalukDetails();
    getCityDetails();
    getVillageDetails();
  }, [residenceId, stateId, districtId, talukId, cityId]);

  useEffect(() => {
    if (circleCode !== null && circleCode !== undefined) {
      var data = JSON.stringify({
        ref_seva_sindhu_circle_id: circleCode,
      });

      var config = {
        method: "post",
        url: SERVER + "/seva_sindhu/get_circle_mapped_to_villages",

        data: data,
      };

      axios(config).then(function (response) {
        console.log(
          "Circle_data" + JSON.stringify(response.data, undefined, 2)
        );
        setCircleData(response.data.data);
      });
    }
  }, [circleCode]);

  useEffect(() => {
    if (circleData !== null && circleData !== undefined) {
      if (circleData.length < 0) {
      } else {
        setCircleDataFound(circleData);
      }
    }
  }, [circleData]);

  useEffect(() => {
    if (circleDataFound !== null && circleDataFound !== undefined) {
      console.log("circleDataFound", circleDataFound);
      // let circleDataFoundDistrict = circleDataFoundDistrict.forEach(
      //     filter(o => o.district_name
      // )
      const circleDataFoundDistrict = [];

      const circleDataFoundTaluk = [];
      const circleDataFoundCity = [];
      const circleDataFoundVillage = [];

      circleDataFound.forEach((field) => {
        console.log("field", field.village_area_ward);
        circleDataFoundDistrict.push(field.district_name);
        circleDataFoundTaluk.push(field.taluk_name);
        circleDataFoundCity.push(field.gram_panchayatcity_town_name);
        // circleDataFoundVillage.push((field.village_area_ward_id))
        circleDataFoundVillage.push(field.village_area_ward);

        setCircleDataFoundDistrict(circleDataFoundDistrict);
        setCircleDataFoundTaluk(circleDataFoundTaluk);
        setCircleDataFoundCity(circleDataFoundCity);
        setCircleDataFoundVillage(circleDataFoundVillage);

        // if(field.district_name === true){
        //     circleDataFoundDistrict.push((field))
        //     console.log("circleDataFoundDistrict", field)

        // }
      });
      console.log("circleDataFoundDistrict", circleDataFoundDistrict);

      // let circleDataFoundDistrict = circleDataFoundDistrict.filter(o => o.district_name )
      // let circleDataFoundTaluk = circleDataFound.filter(o => o.taluk_name)
      // let circleDataFoundCity = circleDataFound.filter(o => o.gram_panchayatcity_town_name)
      // let circleDataFoundVillage = circleDataFound.filter(o => o.village_area_ward)
      console.log("circleDataFoundDistrict", circleDataFoundDistrict);
      console.log("circleDataFoundTaluk", circleDataFoundTaluk);
      console.log("circleDataFoundCity", circleDataFoundCity);
      console.log("circleDataFoundVillage", circleDataFoundVillage);
    }
  }, [circleDataFound]);

  useEffect(() => {
    if (window.location.toString().includes("Success") && !aadharValidated) {
      var queryString = JSON.stringify(props.location.search);

      var vaultRefN = queryString
        .substring(queryString.lastIndexOf("refno=") + 6)
        .split(" ")[0];
      var vaultRefNo = vaultRefN.substring(0, vaultRefN.length - 1);
      setVaultRefNumber(vaultRefNo);

      var data = JSON.stringify({
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      });

      var config = {
        method: "post",
        url: SERVER + "/user/get-user",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config).then(function (response) {
        setLoading(false);
        console.log(
          "checkAadhaarDuplicate response:" +
            JSON.stringify(response.data, undefined, 2)
        );

        var check = true;
        var aadharDuplicate = response.data.data;
        console.log("aadharDuplicate:" + response.data.data);
        setaadharDuplicate(response.data);
      });
    } else if (window.location.toString().includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.");
    }

    setdocumentUploaded(false);

    dispatch(addLabourFamilyDetails("delete"));

    // console.log("in useEffect users.family_details.noOfFamilyMembers: " + users.family_details.noOfFamilyMembers)
    // if (users.family_details.noOfFamilyMembers > 1) {
    //     for (var i = 1; i <= (users.family_details.noOfFamilyMembers - 1 ); i++) {
    //         addNewFamilyMember(i)
    //         console.error("in useEffect [addNewFamilyMember]: i=" + i)
    //     }
    // }

    let dataForCatalog = { board_id: 1, catalog_name: "Ration card type" };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    dispatch(getCatalogDetails(dataForCatalog));

    let dataForCatalog2 = { board_id: 1, catalog_name: "Relation" };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    dispatch(getCatalogDetails(dataForCatalog2));

    let dataForCatalog3 = { board_id: 1, catalog_name: "Profession" };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    dispatch(getCatalogDetails(dataForCatalog3));

    let dataForCatalog4 = { board_id: 1, catalog_name: "Education" };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    dispatch(getCatalogDetails(dataForCatalog4));
    let dataForCatalog5 = { board_id: 1, catalog_name: "Category" };

    dispatch(getCatalogDetails(dataForCatalog5));

    let dataForCatalog6 = { board_id: 1, catalog_name: "Religion" };
    dispatch(getCatalogDetails(dataForCatalog6));

    let dataForCatalog7 = { board_id: 1, catalog_name: "Nature of Work" };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    dispatch(getCatalogDetails(dataForCatalog7));
  }, []);

  // useEffect(() => {
  //     console.log("in useEffect5")

  //     if(aadharValidated){
  //         var data1 = JSON.stringify({

  //             "labour_user_id": users.user.id,

  //     });

  //     var config = {
  //         method: 'post',
  //         url: SERVER + "/user/get_ad_details",
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${getTokenCookie()}`

  //         },
  //         data : data1

  //       };
  //       axios(config)
  //       .then(function (response) {
  //         // showToast("SUCCESS", "Aadhar Verified")
  //         console.log("ResponseEKYC", response.data.data[0].id)
  //         if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
  //             showToast("SUCCESS", "Aadhaar Verified Successfully")

  //             // var first_name = ""
  //             // var middle_name = ""
  //             // var last_name = ""
  //             // var nameArr = response.data.data[0].name.split(' ')
  //             // console.log("nameArr: "+nameArr[0])
  //             // if (nameArr.length === 1) {
  //             //     first_name = nameArr[0]
  //             // }
  //             // else if (nameArr.length === 2) {
  //             //     first_name = nameArr[0]
  //             //     last_name = nameArr[1]
  //             // }
  //             // else if (nameArr.length >= 3) {
  //             //     first_name = nameArr[0]
  //             //     middle_name = nameArr[1]
  //             //     last_name = nameArr[2]
  //             // }
  //             // console.log("first_name: "+first_name)
  //             // console.log("middle_name: "+middle_name)
  //             // console.log("last_name: "+last_name)

  //             // var gender2 = null
  //             // if(response.data.data[0].gender === "M"){
  //             //     gender2 = "27"
  //             // }
  //             // else if(response.data.data[0].gender === "F"){
  //             //     gender2 = "28"
  //             // }
  //             // else{
  //             //     gender2 = "29"
  //             // }
  //             // console.log("gender: "+gender2)

  //             // cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })

  //             // setAllValues({
  //             //     ...allValues,
  //             //     aadharcheck: true,
  //             //     aadhaarFetchedData: response.data.data[0],
  //             //     is_aadhar_verified: true,
  //             //     firstname: first_name,
  //             //     middlename: middle_name,
  //             //     lastname: last_name,
  //             //     gender: gender2

  //             // })
  //             // setDob(response.data.data[0].dob)

  //             setLoading(false)
  //             // var base64Str = response.data.data[0].document_link
  //             // setprofilePic(`data:image/png;base64,${base64Str}`)
  //             // getimage(response.data.data[0].image)

  //             setImageEKYC (response.data.data[0].image)

  //             // sessionStorage.setItem('profilePicEKYC', response.data.data[0].document_link)

  //         }

  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         // if(error.response.data.errors !== undefined){
  //         //     showToast("ERROR", error.response.data.errors)
  //         // }
  //       });
  //     }

  // },[aadharValidated])

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    props.history.push("/");
  };

  useEffect(() => {
    if (aadharDuplicate) {
      console.log("inAadharDuplicate", aadharDuplicate.data);
      if (aadharDuplicate.data.length === 0) {
        console.log(aadharDuplicate.data, "aadhaarData");
        // // setaadharDuplicateError(true)
        // // showToast("ERROR", "Aadhaar Already Registered!")
        // check = false
        // var queryString = props.location.search

        console.log("props12", vaultRefNumber);

        // var data1 = JSON.stringify({

        //     "labour_user_id": users.user.id,
        //     "vault_ref_no": vaultRefNumber

        // });

        // var config = {
        //     method: 'post',
        //     url: SERVER + "/global/update_ekyc_vault_number",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     data: data1

        // };
        // axios(config)
        //     .then(function (response) {
        //         // showToast("SUCCESS", "Aadhar Verified")
        //         // prePopulateAadharData()

        //         setAadharValidated(true)

        console.log("regID", sessionStorage.getItem("registrationID"));
        var registration_seva_id = sessionStorage.getItem("registrationID");
        var reference_seva_id = sessionStorage.getItem("referenceID");

        var data = JSON.stringify({
          registration_number: registration_seva_id,
          application_reference_number: reference_seva_id,
        });

        var config = {
          method: "post",
          url: SERVER + "/seva_sindhu/check_user_in_seva_sindhu",
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log("Response_SEVA_Sindhu", response.data.data);
            console.log(
              "response_referencesubmissionlocation",
              response.data.data[0].submissionlocation
            );
            setCircleCode(response.data.data[0].submissionlocation);
            setTempID(response.data.data[0].id);

            setAadharValidated(true);
            //  setCircleCode(1262945)
            // showToast("SUCCESS", "Aadhar Verified")
          })
          .catch(function (error) {
            console.log(error);
            showToast("ERROR", "There was an error connecting to the sever.");
          });

        // })
        // .catch(function (error) {
        //     console.log(error);
        //     if (error.response.data.errors.msg.msg !== undefined) {
        //         showToast("ERROR", error.response.data.errors.msg.msg)
        //     }
        // });
      } else {
        // setaadharDuplicateError(true)
        showToast("ERROR", "Aadhaar Already Registered!");
      }
    }
  }, [aadharDuplicate]);

  useEffect(() => {
    console.log("in useEffect5");

    if (aadharValidated) {
      axios
        .get(`${SERVER}/user/get_ad_details/${vaultRefNumber}`)
        .then(function (response) {
          console.log("ResponseEKYCDATA003", response);
          // showToast("SUCCESS", "Aadhar Verified")
          console.log("ResponseEKYCDATA:", response.data.data[0]);
          // console.log("ResponseEKYC", response.data.data[0].id)
          if (
            response.data.data[0] !== undefined &&
            response.data.data[0] !== null
          ) {
            // showToast("SUCCESS", "Aadhaar Verified Successfully")

            // console.log("NAMEMATCH",response.data.data[0].name,sessionStorage.getItem("LabourName"));
            let sevaSindhuName = sessionStorage
              .getItem("LabourName")
              .toLowerCase();
            let aadhaarName = response.data.data[0].name.toLowerCase();
            // let string1 = "Murali R Bharadwaj"
            // let string2 = "Bharadwaj R Murali"

            // stringSimilarity(string1,string2)
            console.log(sevaSindhuName, aadhaarName, "LOWERCASE");
            if (stringSimilarity(sevaSindhuName, aadhaarName) >= 0.5) {
              showToast("SUCCESS", "Name Matched as per the Aadhaar");
              showToast("SUCCESS", "Aadhar Verified");
              setNameMatched(true);
            } else {
              setNameMatched(false);
              showToast(
                "ERROR",
                "Name Matching Failed,Name did not matched as per the aadhaar"
              );
              showToast("WARN", "Try again with your Aadhaar number");
              // setTimeout(()=>{
              //     history.push("/register-id")

              // },7000)
            }

            var gender2 = null;
            if (response.data.data[0].gender === "M") {
              gender2 = "27";
            } else if (response.data.data[0].gender === "F") {
              gender2 = "28";
            } else {
              gender2 = "29";
            }
            console.log("gender: " + gender2);

            // cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })

            setAllValues({
              ...allValues,

              gender: gender2,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.errors !== undefined) {
            showToast("ERROR", error.response.data.errors);
          }
        });
    }
  }, [aadharValidated]);

  console.log("StateID", stateId);
  console.log("DistrictID", districtId);
  console.log("TalukID", talukId);
  console.log("CityID", cityId);
  console.log("VillageID", villageId);
  console.log("ResidenceID", residenceId);

  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleLabourChange = (event) => {
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedLabour).concat(
        Array.from(event.target.files)
      );
      console.log("joined", joined);
      setSelectedLabour(joined);
    }

    setloadingfiles(false);
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data, "uploadFile"));
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          // showToast("WARN","Please Upload Labour Card !!")
          reject(error);
        });
    });
  };

  useEffect(() => {
    console.log("SelectedLabour_out", selectedLabour);

    if (
      selectedLabour !== null &&
      selectedLabour !== undefined &&
      selectedLabour.length > 0
    ) {
      console.log("SelectedLabour_in");
      let promises = [];

      selectedLabour.forEach(async (i) => {
        var selectedblob = i;
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        var formdataobj = "";
        var fileName = "";
        reader.onloadend = await function (event) {
          fileName = selectedblob.name;
          var base64data = event.target.result;
          formdataobj = {
            file: base64data,
            userId: 120,
            fileType: fileName,
          };
          promises.push(uploadfile(formdataobj, fileName));
          if (promises.length === selectedLabour.length) {
            Promise.all(promises).then((results) => {
              console.log("results of promise1: ");
              console.log(results);
              setLabourDoc(results);
            });
          }
        };
      });
    }
  }, [selectedLabour]);
  useEffect(() => {
    console.log("SelectedLabour_Doc_OUT ");

    if (labourDoc !== null && labourDoc.length > 0) {
      console.log("SelectedLabour_Doc_OUT ");

      var filelist1 = [];

      labourDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist1: " + JSON.stringify(filelist1[0].file_id));
      setUploadImageid(filelist1[0].file_id);

      // setfilelistAttested(filelist1)

      // submitFiles(filelist, filelist1)
    }
  }, [labourDoc]);

  //Bank Details

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone });
  }, [phone]);

  // useEffect(() => {
  //     // console.log("in useEffect []")
  //     if (users.generate_otp !== null) {
  //         setPhone(users.generate_otp.data.phone_number)
  //         dispatch(getUser(users.generate_otp.data.phone_number))
  //     }
  //     else if (users.generate_otp_registration !== null) {
  //         setPhone(users.generate_otp_registration.data.phone_number)
  //         dispatch(getUser(users.generate_otp_registration.data.phone_number))
  //     }
  //     else {
  //         props.history.push("/")
  //     }

  //     dispatch(getFile("delete"))
  //     dispatch(addLabourBankDetails("delete"))

  // }, []);

  // useEffect(() => {
  //     // console.log("in useEffect [users.user]")

  //     if(users.user !== null && users.user !== null && users.user !== undefined  && users.user.board_id !== null){

  //         let dataForRegDetails = {
  //             "key": "user_id",
  //             "value": users.user.id,
  //             "board_id": 1,
  //             "procedure_name": "all"
  //         }
  //         dispatch(getUserRegistrationDetails(dataForRegDetails))

  //     }
  // },[users.user]);

  // useEffect(() => {
  //     // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
  //     // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
  //     if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.bank_details !== undefined ){

  //     // if(users.getUserRegistrationDetails !== null){
  //         if(users.getUserRegistrationDetails.bank_details !== undefined && users.getUserRegistrationDetails.bank_details[0] !== undefined && users.getUserRegistrationDetails.bank_details.length > 0){

  //             var val = users.getUserRegistrationDetails.bank_details[0]

  //             if(
  //                 users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== undefined &&
  //                 users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== null &&
  //                 users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml.length > 0
  //                 ){

  //                 if(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml.length > 1){
  //                     if(users.getUserRegistrationDetails.bank_details[0].is_nominee){
  //                         setfilesxmlPresentNominee(true)
  //                     }
  //                     else if(
  //                         users.getUserRegistrationDetails.bank_details[1] !== undefined &&
  //                         users.getUserRegistrationDetails.bank_details[1].is_nominee){
  //                         setfilesxmlPresentNominee(true)
  //                     }

  //                     if(!users.getUserRegistrationDetails.bank_details[0].is_nominee){
  //                         setfilesxmlPresent(true)
  //                     }
  //                     else if(
  //                         users.getUserRegistrationDetails.bank_details[1] !== undefined &&
  //                         !users.getUserRegistrationDetails.bank_details[1].is_nominee){
  //                         setfilesxmlPresent(true)
  //                     }

  //                 }
  //                 else{
  //                     setfilesxmlPresent(true)
  //                 }
  //             }

  //             if(users.getUserRegistrationDetails.bank_details.length > 1){

  //                 var val1 = users.getUserRegistrationDetails.bank_details[1]

  //                 setAllValues({
  //                     ...allValues,
  //                     ifscCode: val1.ifsc_code,
  //                     accountNumber: val1.account_no,
  //                     bankName: val1.bank_name,
  //                     bankBranch: val1.bank_branch,
  //                     bankAddress: val1.bank_address,
  //                     passbookDocumentName: val1.bank_passbook_upload_id,
  //                     // bank_upload_files_xml: JSON.parse(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml),
  //                     passbookUploadResponse:{
  //                         fileId: val1.bank_passbook_upload_id
  //                     },

  //                     nomineeCheck: true,
  //                     ifscCode_nominee: val.ifsc_code,
  //                     accountNumber_nominee: val.account_no,
  //                     bankName_nominee: val.bank_name,
  //                     bankBranch_nominee: val.bank_branch,
  //                     bankAddress_nominee: val.bank_address,
  //                     passbookDocumentName_nominee: val.bank_passbook_upload_id,
  //                     // nomineebank_upload_files_xml: JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml),
  //                     passbookUploadResponse_nominee: {
  //                         fileId: val.bank_passbook_upload_id
  //                     },
  //                 })

  //             }
  //             else{
  //                 setAllValues({
  //                     ...allValues,
  //                     ifscCode: val.ifsc_code,
  //                     accountNumber: val.account_no,
  //                     bankName: val.bank_name,
  //                     bankBranch: val.bank_branch,
  //                     bankAddress: val.bank_address,
  //                     passbookDocumentName: val.bank_passbook_upload_id,
  //                     passbookUploadResponse:{
  //                         fileId: val.bank_passbook_upload_id
  //                     },
  //                 })
  //             }

  //         }
  //     }
  // }, [users.getUserRegistrationDetails]);

  // useEffect(() => {
  //     console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
  //     console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
  //     console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)
  //     if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.length > 0 ){

  //     }
  // }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    console.log(
      "in useEffect [allValues]: " + JSON.stringify(allValues, undefined, 2)
    );
    dispatch(updateBankData(allValues));
  }, [allValues]);

  useEffect(() => {
    console.log("in useEffect [selectedfiles]: " + selectedfiles);
    if (selectedfiles !== null && selectedfiles.length > 0) {
      uploadPassbook();
    } else if (
      selectedfilesNominee !== null &&
      selectedfilesNominee.length > 0
    ) {
      uploadPassbookNominee();
    }
  }, [selectedfiles]);

  const handleDateChange = (date) => {
    console.log(date);
    console.log(new Date(date));
    setAllValues({
      ...allValues,
      dob: new Date(date),
    });
  };

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleClickOpen = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const handleClose = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const toggleWebcam = () =>
    setAllValues({
      ...allValues,
      webcamEnabled: !allValues.webcamEnabled,
    });

  const capture = () => {
    const imageSrc = myRef.current.getScreenshot();
    console.log(imageSrc);
    sessionStorage.setItem("passbook", imageSrc);
    setAllValues({
      ...allValues,
      // imgfile: imageSrc,
      open: !allValues.open,
    });
  };

  const myFunction = () => {
    props.updateItem(allValues);
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });

    // if (event.target.checked) {
    //     setAllValues({ ...allValues,
    //         permanentData: state.residentialData
    //     }));
    // }
    // else {
    //     setAllValues({ ...allValues,
    //         permanentData: initData
    //     }));
    // }
  };

  const handleChangeBank = (event) => {
    console.log("eventTarget", event.target.value);

    if (event.target.name === "ifscCode") {
      setLoadingVerifyBank(false);
      setcheckBank(false);
    }
    if (event.target.name === "ifscCode_nominee") {
      setLoadingVerifyBank_nominee(false);
      setcheckBank_nominee(false);
    }

    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const changeHandler = (e) => {
    if (e.target.name === "accountNumber") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(e.target.value) &&
        e.target.value.length !== 0 &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          accountNumberError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          accountNumberError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          bankNameError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankNameError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankBranch") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (validator.isAlpha(e.target.value, "en-US", { ignore: " " })) {
        setAllValues({
          ...allValues,
          bankBranchError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankBranchError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "accountNumber_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(e.target.value) &&
        e.target.value.length !== 0 &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          accountNumberNomineeError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          accountNumberNomineeError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankName_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          bankNameNomineeError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankNameNomineeError: true,
          [e.target.name]: e.target.value,
        });
      }
    }

    // else if (e.target.name === "bankBranch_nominee") {
    //     // console.error("validate:" + validator.isNumeric(e.target.value))
    //     // console.error("phone.length:" + e.target.value.length)

    //     if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '})){
    //         setAllValues({
    //             ...allValues,
    //             bankBranchNomineeError: false,
    //             [e.target.name]: e.target.value
    //         })}
    //         else{
    //         setAllValues({
    //             ...allValues,
    //             bankBranchNomineeError: true,
    //             [e.target.name]: e.target.value
    //         })}
    // }
    else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );
      console.log("joined1", joined);

      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleFileChange_nominee = (event) => {
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesNominee).concat(
        Array.from(event.target.files)
      );

      setselectedfilesNominee(joined);
    }
    setloadingfiles(false);
  };

  // const handleFileChange_nominee = (event) => {
  //     if(event.target.files[0] !== null)
  //     {
  //     var selectedblob = event.target.files[0]
  //     var reader = new FileReader();
  //     reader.readAsDataURL(selectedblob);
  //     reader.onloadend = function () {
  //         var base64data = reader.result;
  //         console.log(base64data);

  //         sessionStorage.setItem('passbook_nominee', base64data);
  //         setAllValues({
  //             ...allValues,
  //             // imgfile_nominee: base64data,
  //             passbookDocumentName_nominee: event.target.files[0].name
  //         })
  //     }
  // }
  // }

  const handleEnter = (event) => {
    console.log("in handleEnter");

    // const nextSibling = document.querySelector(
    //     `.formcontrol${allValues.residentialData.formcontrolnum + 1}>div>div>input`
    // );
    // console.log("nextSibling:" + nextSibling.className)

    // if (nextSibling !== null) {
    //     nextSibling.focus();
    // }
  };

  // useEffect(() => {
  //     // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'passbook') {
  //         if (users.upload_file.userFileName === "passbook") {
  //             setAllValues({
  //                 ...allValues,
  //                 passbookUploadResponse: users.upload_file.image
  //             })

  //             if(users.bank_details.nomineeCheck){
  //                 uploadPassbookNominee()
  //             }
  //             else{
  //                 submitBank(users.upload_file.image, null)
  //             }
  //         }
  //     }
  //     else if(users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR","Cannot upload the passbook image. Please try later.")
  //     }

  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'passbook_nominee') {
  //         if (users.upload_file.userFileName === "passbook_nominee") {
  //             setAllValues({
  //                 ...allValues,
  //                 passbookUploadResponse_nominee: users.upload_file.image
  //             })

  //             submitBank(null, users.upload_file.image)

  //             // if (users.profile_details.editmode) {
  //             //     props.saveEditButtonPressed()
  //             // }
  //             // else {
  //             //     props.nextButtonPressed()
  //             // }
  //         }
  //     }
  //     else if(users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR","Cannot upload the passbook image of nominee. Please try later.")
  //     }
  // }, [users.upload_file]);

  useEffect(() => {
    if (passbookuploadresults !== null && passbookuploadresults.length > 0) {
      // setAllValues({
      //     ...allValues,
      //     passbookUploadResponse: users.upload_file.image
      // })

      if (users.bank_details.nomineeCheck) {
        var filelist = [];

        passbookuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };

          filelist.push(filejson);
        });

        console.log("filelist: " + JSON.stringify(filelist));

        setfilelist(filelist);

        // uploadPassbookNominee()

        if (selectedfilesNominee !== null && selectedfilesNominee.length > 0) {
          uploadPassbookNominee();
        }
      } else {
        var filelist = [];

        passbookuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };

          filelist.push(filejson);
        });

        console.log("filelist: " + JSON.stringify(filelist));

        setfilelist(filelist);

        // submitBank(filelist, null)
      }
    }
  }, [passbookuploadresults]);

  useEffect(() => {
    if (
      passbookuploadNomineeresults !== null &&
      passbookuploadNomineeresults.length > 0
    ) {
      // setAllValues({
      //     ...allValues,
      //     passbookUploadResponse: users.upload_file.image
      // })

      // if(users.bank_details.nomineeCheck){
      //     uploadPassbookNominee()
      // }
      // else{

      var filelist1 = [];

      passbookuploadNomineeresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist));
      console.log("filelist1: " + JSON.stringify(filelist1));

      // if(filelist !== null && filelist.length > 0 ){
      //     submitBank(filelist, filelist1)
      // }

      // }
    }
  }, [passbookuploadNomineeresults]);

  const nextButtonPressed = () => {
    try {
      if (
        users.bank_details.ifscCode === "" ||
        users.bank_details.accountNumber === "" ||
        users.bank_details.bankName === "" ||
        users.bank_details.bankBranch === "" ||
        users.bank_details.bankAddress === "" ||
        users.bank_details.accountNumberError === true ||
        users.bank_details.bankNameError === true ||
        users.bank_details.bankBranchError === true
        // ||
        // sessionStorage.getItem('passbook') === null
        // users.bank_details.passbookUploadResponse.fileId === ""
      ) {
        console.log("bank1");
        showToast("WARN", "Please Fill All the Required Fields to Continue1!");
      } else if (
        users.bank_details.nomineeCheck &&
        (users.bank_details.ifscCode_nominee === "" ||
          users.bank_details.accountNumber_nominee === "" ||
          users.bank_details.bankName_nominee === "" ||
          users.bank_details.bankBranch_nominee === "" ||
          users.bank_details.bankAddress_nominee === "" ||
          users.bank_details.accountNumberNomineeError === true ||
          users.bank_details.bankNameNomineeError === true ||
          users.bank_details.bankBranchNomineeError === true)
        // ||
        // sessionStorage.getItem('passbook_nominee') === null
        // users.bank_details.passbookUploadResponse_nominee.fileId === ""
      ) {
        showToast("WARN", "Please Fill All the Required Fields to Continue2!");
      } else {
        if (selectedfiles !== null && selectedfiles.length > 0) {
          uploadPassbook();
        } else if (
          selectedfilesNominee !== null &&
          selectedfilesNominee.length > 0
        ) {
          uploadPassbookNominee();
        }

        // uploadPassbook()
      }
    } catch (err) {
      console.log("bank2");

      showToast("ERROR", "Please fill all the mandatory fields");
      console.error("in catch-> Error:", err);
    }
  };

  const uploadfile1 = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const uploadPassbook = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile1(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setpassbookuploadresults(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('passbook') !== null && users.user !== null){
    // let formdata = {
    //     'file': sessionStorage.getItem('passbook'),
    //     'userId': users.user.id,
    //     'fileType': 'passbook'
    // }
    //     console.error("formdata passbook: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"passbook"))
    // }
  };
  const uploadPassbookNominee = () => {
    let promises = [];

    selectedfilesNominee.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile1(formdataobj, fileName));
        if (promises.length === selectedfilesNominee.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setpassbookuploadNomineeresults(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('passbook_nominee') !== null && users.user !== null){
    //     let formdata = {
    //         'file': sessionStorage.getItem('passbook_nominee'),
    //         'userId': users.user.id,
    //         'fileType': 'passbook_nominee'
    //     }
    //     console.error("formdata passbook_nominee: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"passbook_nominee"))
    // }
  };

  // const submitBank = (passbookUploadResponse, passbookUploadResponse_nominee) => {

  //     if(passbookUploadResponse === null) {
  //         passbookUploadResponse = allValues.passbookUploadResponse
  //     }

  //     let dataToSubmit = {
  //         "user_id": users.user.id,
  //         "banks": [
  //             {
  //                 "labour_user_id": users.user.id,
  //                 "nominee_for_user_id": null,
  //                 "nominee_first_name": null,
  //                 "nominee_middle_name": null,
  //                 "nominee_last_name": null,
  //                 "ifsc_code": users.bank_details.ifscCode,
  //                 "account_no": users.bank_details.accountNumber,
  //                 "bank_name": users.bank_details.bankName,
  //                 "bank_branch": users.bank_details.bankBranch,
  //                 "bank_address": users.bank_details.bankAddress,
  //                 // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
  //                 "bank_passbook_upload_id": passbookUploadResponse.fileId,
  //                 "files": [{
  //                     "file_id": "-",
  //                     "file_name": "-"
  //                 }],
  //                 "is_nominee": 0,
  //                 "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //             },
  //         ]
  //     }

  //     if(users.bank_details.nomineeCheck && passbookUploadResponse_nominee !== null ){

  //         let bank_nominee = {
  //             "labour_user_id": users.user.id,
  //             "nominee_for_user_id": null,
  //             "nominee_first_name": null,
  //             "nominee_middle_name": null,
  //             "nominee_last_name": null,
  //             "ifsc_code": users.bank_details.ifscCode_nominee,
  //             "account_no": users.bank_details.accountNumber_nominee,
  //             "bank_name": users.bank_details.bankName_nominee,
  //             "bank_branch": users.bank_details.bankBranch_nominee,
  //             "bank_address": users.bank_details.bankAddress_nominee,
  //             // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse_nominee.fileId,
  //             "bank_passbook_upload_id": passbookUploadResponse_nominee.fileId,
  //             "files": [{
  //                 "file_id": "-",
  //                 "file_name": "-"
  //             }],
  //             "is_nominee": 1,
  //             "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //         }

  //         dataToSubmit.banks.push(bank_nominee)
  //     }

  //     console.error("dataToSubmit", JSON.stringify(dataToSubmit))

  //     if(users.getUserRegistrationDetails.bank_details === []){
  //         dispatch(addLabourBankDetails(dataToSubmit))
  //     }
  //     else{
  //         dispatch(addLabourBankDetails(dataToSubmit, "update"))
  //     }
  // }

  // const submitBank = (passbookUploadResponse, passbookUploadResponse_nominee) => {

  //     // if(passbookUploadResponse === null) {
  //     //     passbookUploadResponse = allValues.passbookUploadResponse
  //     // }

  //     let dataToSubmit = {
  //         // "user_id": users.user.id,
  //         "banks": [
  //             {
  //                 "labour_user_id": users.user.id,
  //                 "nominee_for_user_id": null,
  //                 "nominee_first_name": null,
  //                 "nominee_middle_name": null,
  //                 "nominee_last_name": null,
  //                 "ifsc_code": users.bank_details.ifscCode,
  //                 "account_no": users.bank_details.accountNumber,
  //                 "bank_name": users.bank_details.bankName,
  //                 "bank_branch": users.bank_details.bankBranch,
  //                 "bank_address": users.bank_details.bankAddress,
  //                 // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
  //                 "bank_passbook_upload_id": '-',
  //                 // "files": [{
  //                 //     "file_id": "-",
  //                 //     "file_name": "-"
  //                 // }],
  //                 "files": passbookUploadResponse,
  //                 "is_nominee": 0,
  //                 "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //             },
  //         ]
  //     }

  //     if(users.bank_details.nomineeCheck && passbookUploadResponse_nominee !== null ){

  //         let bank_nominee = {
  //             "labour_user_id": users.user.id,
  //             "nominee_for_user_id": null,
  //             "nominee_first_name": null,
  //             "nominee_middle_name": null,
  //             "nominee_last_name": null,
  //             "ifsc_code": users.bank_details.ifscCode_nominee,
  //             "account_no": users.bank_details.accountNumber_nominee,
  //             "bank_name": users.bank_details.bankName_nominee,
  //             "bank_branch": users.bank_details.bankBranch_nominee,
  //             "bank_address": users.bank_details.bankAddress_nominee,
  //             // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse_nominee.fileId,
  //             // "bank_passbook_upload_id": passbookUploadResponse_nominee.fileId,
  //             "bank_passbook_upload_id": '-',
  //             "files": passbookUploadResponse_nominee,
  //             "is_nominee": 1,
  //             "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //         }

  //         dataToSubmit.banks.push(bank_nominee)
  //     }

  //     dispatch(addLabourBankDetails(dataToSubmit))

  //     console.error("dataToSubmit", JSON.stringify(dataToSubmit))

  // }

  const init_bank_details = {
    ifscCode: "",
    accountNumber: "",
    bankName: "",
    bankBranch: "",
    bankAddress: "",
    imgfile: "",
    passbookDocumentName: "",
    passbookUploadResponse: "",
    nomineeCheck: false,

    ifscCode_nominee: "",
    accountNumber_nominee: "",
    bankName_nominee: "",
    bankBranch_nominee: "",
    bankAddress_nominee: "",
    imgfile_nominee: "",
    passbookDocumentName_nominee: "",
    passbookUploadResponse_nominee: "",
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.success !== undefined &&
      users.addLabourBankDetails.success !== null &&
      users.addLabourBankDetails.success === true
    ) {
      showToast("SUCCESS", "Bank Details Saved Successfully!");
      // props.nextButtonPressed()
      dispatch(updateBankData(init_bank_details));
      dispatch(addLabourBankDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.success !== undefined &&
      users.addLabourBankDetails.success !== null &&
      users.addLabourBankDetails.success === "false"
    ) {
      showToast("ERROR", "Error Saving Bank Details!");
    } else if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.error !== undefined &&
      users.addLabourBankDetails.error === true
    ) {
      showToast("ERROR", "Error Saving Bank Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabourBankDetails]);

  const [checkBank_nominee, setcheckBank_nominee] = React.useState(false);
  const [loadingVerifyBank_nominee, setLoadingVerifyBank_nominee] =
    React.useState(false);

  const [checkBank, setcheckBank] = React.useState(false);
  const [loadingVerifyBank, setLoadingVerifyBank] = React.useState(false);

  const verifyBank1 = () => {
    if (
      allValues.accountNumber !== null &&
      allValues.ifscCode !== null &&
      allValues.accountNumber !== "" &&
      allValues.ifscCode !== "" &&
      allValues.accountNumber !== "null" &&
      allValues.ifscCode !== "null"
    ) {
      setLoadingVerifyBank(true);

      var randNum = Math.floor(100000 + Math.random() * 900000);
      console.log("randNum: " + randNum);

      // var data = JSON.stringify({
      //     "transID": randNum,
      //     "docType": "92",
      //     "beneAccNo": allValues.accountNumber,
      //     "ifsc": allValues.ifscCode
      //   });
      var data = JSON.stringify({
        transID: randNum,
        docType: "430",
        accountNumber: allValues.accountNumber,
        ifscCode: allValues.ifscCode,
      });

      var config = {
        method: "post",
        url: SERVER + "/global/encrypt_string",
        headers: {
          "Content-Type": "application/json",
          username: TS_USERNAME,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data, undefined, 2)
          );

          var data2 = JSON.stringify({
            requestData: response.data.data,
          });
          var config2 = {
            method: "post",
            url: SERVER + "/global/auth_bridge_bank_request",
            headers: {
              "Content-Type": "application/json",
              username: TS_USERNAME,
            },
            data: data2,
          };

          axios(config2)
            .then(function (response2) {
              console.log(
                "bank step2 response: " +
                  JSON.stringify(response2.data, undefined, 2)
              );

              var data3 = JSON.stringify({
                responseData: response2.data.data.responseData,
              });
              var config3 = {
                method: "post",
                url: SERVER + "/global/decrypt_string",
                headers: {
                  "Content-Type": "application/json",
                  username: TS_USERNAME,
                },
                data: data3,
              };

              axios(config3)
                .then(function (response3) {
                  console.log(
                    "bank step3 response: " +
                      JSON.stringify(response3.data, undefined, 2)
                  );

                  // if(response3.data.data.msg.description === "Success" && response3.data.data.msg.status === "Verified" ){
                  if (response3.data.data.status === 1) {
                    showToast("SUCCESS", "Bank Account Verified Successfully");
                    setLoadingVerifyBank(false);
                    setcheckBank(true);
                    // setAllValues({
                    //     ...allValues,

                    // })
                    setAllValues({
                      ...allValues,
                      bankFetchedData: response3.data.data.msg,
                      is_bank_verified: true,
                      bankName: response3.data.data.msg["Bank Name"],
                      bankBranch:
                        response3.data.data.msg["Bank Branch - Address"].Branch,
                      bankAddress:
                        response3.data.data.msg["Bank Branch - Address"]
                          .Address,
                    });
                  } else {
                    console.error("bank step3 fail");
                    // saveLog(
                    //     allValues.getUserDetails.personal_details[0].mobile_no,
                    //     allValues.getUserDetails.personal_details[0].first_name,
                    //     allValues.getUserDetails.personal_details[0].id,
                    //     response3.data.data.msg
                    //     )
                    setLoadingVerifyBank(false);
                    showToast(
                      "ERROR",
                      "Bank Verification Failed:  " +
                        response3.data.data.msg.status
                    );
                  }
                })
                .catch(function (error3) {
                  console.error("bank step3 error: " + error3);
                  // saveLog(
                  //     allValues.getUserDetails.personal_details[0].mobile_no,
                  //     allValues.getUserDetails.personal_details[0].first_name,
                  //     allValues.getUserDetails.personal_details[0].id,
                  //     error3
                  //     )
                  setLoadingVerifyBank(false);
                  showToast(
                    "ERROR",
                    "Some error occurred while verifying bank details!"
                  );
                });
            })
            .catch(function (error2) {
              console.error("bank step2 error: " + error2);
              // saveLog(
              //     allValues.getUserDetails.personal_details[0].mobile_no,
              //     allValues.getUserDetails.personal_details[0].first_name,
              //     allValues.getUserDetails.personal_details[0].id,
              //     error2
              //     )
              setLoadingVerifyBank(false);
              showToast(
                "ERROR",
                "Some error occurred while verifying bank details!"
              );
            });
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          // saveLog(
          //     allValues.getUserDetails.personal_details[0].mobile_no,
          //     allValues.getUserDetails.personal_details[0].first_name,
          //     allValues.getUserDetails.personal_details[0].id,
          //     error
          //     )
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Some error occurred while verifying bank details!"
          );
        });
    } else {
      showToast("ERROR", "Please fill Correct Bank Account Details !");
    }
  };

  const verifyBank = () => {
    if (
      allValues.ifscCode !== null &&
      allValues.ifscCode !== "" &&
      allValues.ifscCode !== "null"
    ) {
      setLoadingVerifyBank(true);

      var config = {
        method: "get",
        url: SERVER + "/global/get_ifsc_details?ifsc=" + allValues.ifscCode,
        headers: {
          "Content-Type": "application/json",
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          if (
            response.data.success === true &&
            response.data.data !== undefined &&
            response.data.data.BANK !== undefined
          ) {
            showToast("SUCCESS", "Bank Account Details Fetched Successfully");
            setLoadingVerifyBank(false);
            setcheckBank(true);

            setAllValues({
              ...allValues,
              bankFetchedData: response.data.data,
              is_bank_verified: true,
              bankName: response.data.data.BANK,
              bankBranch: response.data.data.BRANCH,
              bankAddress: response.data.data.ADDRESS,
            });
          } else {
            setLoadingVerifyBank(false);
            showToast("ERROR", "Bank Details Fetching Failed!");
            // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
          }
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          console.error(
            "bank step1 error.response: " +
              JSON.stringify(error.response, undefined, 2)
          );
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Bank Details Fetching Failed:  " + error.response.data.errors.msg
          );
          // showToast("ERROR", "Some error occurred while verifying bank details!")
        });
    } else {
      showToast("ERROR", "Please Fill IFSC Code!");
    }
  };

  const verifyBankNominee1 = () => {
    if (
      allValues.accountNumber_nominee !== null &&
      allValues.ifscCode_nominee !== null &&
      allValues.accountNumber_nominee !== "" &&
      allValues.ifscCode_nominee !== "" &&
      allValues.accountNumber_nominee !== "null" &&
      allValues.ifscCode_nominee !== "null"
    ) {
      setLoadingVerifyBank(true);

      var randNum = Math.floor(100000 + Math.random() * 900000);
      console.log("randNum: " + randNum);

      // var data = JSON.stringify({
      //     "transID": randNum,
      //     "docType": "92",
      //     "beneAccNo": allValues.accountNumber_nominee,
      //     "ifsc": allValues.ifscCode_nominee
      //   });
      var data = JSON.stringify({
        transID: randNum,
        docType: "430",
        accountNumber: allValues.accountNumber,
        ifscCode: allValues.ifscCode,
      });

      var config = {
        method: "post",
        url: SERVER + "/global/encrypt_string",
        headers: {
          "Content-Type": "application/json",
          username: TS_USERNAME,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data, undefined, 2)
          );

          var data2 = JSON.stringify({
            requestData: response.data.data,
          });
          var config2 = {
            method: "post",
            url: SERVER + "/global/auth_bridge_bank_request",
            headers: {
              "Content-Type": "application/json",
              username: TS_USERNAME,
            },
            data: data2,
          };

          axios(config2)
            .then(function (response2) {
              console.log(
                "bank step2 response: " +
                  JSON.stringify(response2.data, undefined, 2)
              );

              var data3 = JSON.stringify({
                responseData: response2.data.data.responseData,
              });
              var config3 = {
                method: "post",
                url: SERVER + "/global/decrypt_string",
                headers: {
                  "Content-Type": "application/json",
                  username: TS_USERNAME,
                },
                data: data3,
              };

              axios(config3)
                .then(function (response3) {
                  console.log(
                    "bank step3 response: " +
                      JSON.stringify(response3.data, undefined, 2)
                  );

                  // if(response3.data.data.msg.description === "Success" && response3.data.data.msg.status === "Verified" ){
                  //     showToast("SUCCESS", "Bank Account Verified Successfully")
                  //     setLoadingVerifyBank_nominee(false)
                  //     setcheckBank_nominee(true)
                  //     // setAllValues({
                  //     //     ...allValues,

                  //     // })
                  //     setAllValues({
                  //         ...allValues,
                  //         bankFetchedData: response3.data.data.msg,
                  //         is_bank_verified_nominee: true
                  //     })
                  // }
                  if (response3.data.data.status === 1) {
                    showToast("SUCCESS", "Bank Account Verified Successfully");
                    setLoadingVerifyBank(false);
                    setcheckBank(true);
                    // setAllValues({
                    //     ...allValues,

                    // })
                    setAllValues({
                      ...allValues,
                      bankFetchedData: response3.data.data.msg,
                      is_bank_verified_nominee: true,
                      bankName_nominee: response3.data.data.msg["Bank Name"],
                      bankBranch_nominee:
                        response3.data.data.msg["Bank Branch - Address"].Branch,
                      bankAddress_nominee:
                        response3.data.data.msg["Bank Branch - Address"]
                          .Address,
                    });
                  } else {
                    console.error("bank step3 fail");
                    // saveLog(
                    //     allValues.getUserDetails.personal_details[0].mobile_no,
                    //     allValues.getUserDetails.personal_details[0].first_name,
                    //     allValues.getUserDetails.personal_details[0].id,
                    //     response3.data.data.msg
                    //     )
                    setLoadingVerifyBank_nominee(false);
                    showToast(
                      "ERROR",
                      "Bank Verification Failed:  " +
                        response3.data.data.msg.status
                    );
                  }
                })
                .catch(function (error3) {
                  console.error("bank step3 error: " + error3);
                  // saveLog(
                  //     allValues.getUserDetails.personal_details[0].mobile_no,
                  //     allValues.getUserDetails.personal_details[0].first_name,
                  //     allValues.getUserDetails.personal_details[0].id,
                  //     error3
                  //     )
                  setLoadingVerifyBank_nominee(false);
                  showToast(
                    "ERROR",
                    "Some error occurred while verifying bank details!"
                  );
                });
            })
            .catch(function (error2) {
              console.error("bank step2 error: " + error2);
              // saveLog(
              //     allValues.getUserDetails.personal_details[0].mobile_no,
              //     allValues.getUserDetails.personal_details[0].first_name,
              //     allValues.getUserDetails.personal_details[0].id,
              //     error2
              //     )
              setLoadingVerifyBank_nominee(false);
              showToast(
                "ERROR",
                "Some error occurred while verifying bank details!"
              );
            });
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          // saveLog(
          //     allValues.getUserDetails.personal_details[0].mobile_no,
          //     allValues.getUserDetails.personal_details[0].first_name,
          //     allValues.getUserDetails.personal_details[0].id,
          //     error
          //     )
          setLoadingVerifyBank_nominee(false);
          showToast(
            "ERROR",
            "Some error occurred while verifying bank details!"
          );
        });
    } else {
      showToast("ERROR", "Please fill Correct Bank Account Details !");
    }
  };

  const verifyBankNominee = () => {
    if (
      allValues.ifscCode_nominee !== null &&
      allValues.ifscCode_nominee !== "" &&
      allValues.ifscCode_nominee !== "null"
    ) {
      setLoadingVerifyBank_nominee(true);

      var config = {
        method: "get",
        url:
          SERVER +
          "/global/get_ifsc_details?ifsc=" +
          allValues.ifscCode_nominee,
        headers: {
          "Content-Type": "application/json",
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          if (
            response.data.success === true &&
            response.data.data !== undefined &&
            response.data.data.BANK !== undefined
          ) {
            showToast("SUCCESS", "Bank Account Details Fetched Successfully");
            setLoadingVerifyBank_nominee(false);
            setcheckBank_nominee(true);

            setAllValues({
              ...allValues,
              bankFetchedData_nominee: response.data.data,
              is_bank_verified_nominee: true,
              bankName_nominee: response.data.data.BANK,
              bankBranch_nominee: response.data.data.BRANCH,
              bankAddress_nominee: response.data.data.ADDRESS,
            });
          } else {
            setLoadingVerifyBank_nominee(false);
            showToast("ERROR", "Bank Details Fetching Failed!");
            // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
          }
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          console.error(
            "bank step1 error.response: " +
              JSON.stringify(error.response, undefined, 2)
          );
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Bank Details Fetching Failed:  " + error.response.data.errors.msg
          );
          // showToast("ERROR", "Some error occurred while verifying bank details!")
        });
    } else {
      showToast("ERROR", "Please Fill IFSC Code!");
    }
  };

  const removefile = (index) => {
    setloadingfiles(true);
    setRemovedFile(true);
    console.log("removefile index: " + index);
    // selectedfiles.splice(1,index)
    selectedfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };
  const removefileNominee = (index) => {
    setloadingfiles(true);
    console.log("removefileNominee index: " + index);
    // selectedfiles.splice(1,index)
    selectedfilesNominee.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  //family details

  useEffect(() => {
    console.log("in useEffect [allValues]: " + JSON.stringify(allValues));
    dispatch(updateFamilyData(allValues));
  }, [allValues]);

  // const [dob1, setDob1] = React.useState(users.getUserRegistrationDetails.personal_details[0].date_of_birth)
  // const dob2 = moment(dob1).subtract(18, "years");
  // const dob3 = moment(dob2).toDate()
  // console.log("dob2",dob2);
  // console.log("dob3",dob3);

  const [phone1, setPhone1] = React.useState("");
  // const [rationCardTypes, setRationCardTypes] = React.useState([])
  // const [relations, setRelations] = React.useState([])
  // const [professions, setProfessions] = React.useState([])
  // const [educations, setEducations] = React.useState([])

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone1 });
  }, [phone1]);

  const [documentUploaded, setdocumentUploaded] = React.useState(false);

  // useEffect(() => {
  //     // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
  //     // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
  //     // console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)
  //     if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.family_details !== undefined && users.getUserRegistrationDetails.family_details.length > 0 ){

  //         if(users.getUserRegistrationDetails.family_details !== undefined && users.getUserRegistrationDetails.family_details[0] !== undefined){

  //             console.error("in useeffect users.getUserRegistrationDetails.family_details: "+JSON.stringify(users.getUserRegistrationDetails.family_details))

  //             var val = users.getUserRegistrationDetails.family_details

  //             var membersListData = []

  //             for(var i=0; i < val.length; i++){

  //                 var str = val[i].aadhar_no;
  //                 var aadharArr = [];
  //                 var chunkSize = 4;

  //                 while (str) {
  //                     if (str.length < chunkSize) {
  //                         aadharArr.push(str);
  //                         break;
  //                     }
  //                     else {
  //                         aadharArr.push(str.substr(0, chunkSize));
  //                         str = str.substr(chunkSize);
  //                     }
  //                 }

  //                 console.error("aadharArr: "+aadharArr)

  //                 var Data = {
  //                     "id": i,
  //                     ["relation" + i]: val[i].catalog_value_parent_child_type_id,
  //                     ["firstname" + i]: val[i].first_name,
  //                     ["middlename" + i]: val[i].middle_name,
  //                     ["lastname" + i]: val[i].last_name,
  //                     ["dob" + i]: val[i].date_of_birth,
  //                     ["profession" + i]: val[i].catalog_value_profession_id,
  //                     ["education" + i]: val[i].catalog_value_qualification_id,
  //                     ["nominee" + i]: val[i].is_nominee === true ? 1 : 0,
  //                     ["alreadyRegistered" + i]: 0,
  //                     ["registrationNo" + i]: val[i].registration_code,
  //                     ["aadharnum1_" + i]: aadharArr[0],
  //                     ["aadharnum2_" + i]: aadharArr[1],
  //                     ["aadharnum3_" + i]: aadharArr[2],
  //                     ["aadhar" + i]: val[i].aadhar_no,
  //                     relation_id: val[i].relation_id,
  //                     labour_user_id: val[i].labour_user_id,
  //                     parent_child_user_id: val[i].parent_child_user_id

  //                 }

  //                 membersListData.push(Data)

  //             }

  //             var rationCardNo = ""
  //             var catalog_value_ration_card_type_id = ""
  //             var ration_card_document_upload_id = ""

  //             if(users.getUserRegistrationDetails.ration_card_details !== undefined && users.getUserRegistrationDetails.ration_card_details[0] !== undefined){

  //                 var val1 = users.getUserRegistrationDetails.ration_card_details[0]
  //                 rationCardNo = val1.ration_card_no
  //                 catalog_value_ration_card_type_id = val1.catalog_value_ration_card_type_id
  //                 ration_card_document_upload_id = val1.ration_card_document_upload_id
  //             }

  //             setAllValues({
  //                 ...allValues,
  //                 rationCardNo: rationCardNo,
  //                 rationCardType: catalog_value_ration_card_type_id,

  //                 // imgfile: users.family_details.imgfile,
  //                 // webcamEnabled: users.family_details.webcamEnabled,
  //                 // open: users.family_details.open,
  //                 // setOpen: users.family_details.setOpen,
  //                 // openDatePicker: users.family_details.openDatePicker,

  //                 rationDocumentName: ration_card_document_upload_id,

  //                 membersList: membersListData,
  //                 // membersList: [{
  //                 //     "id": 0,
  //                 //     "relation0": users.family_details.membersList[0]['relation0'],
  //                 //     "firstname0": users.family_details.membersList[0]['firstname0'],
  //                 //     "middlename0": users.family_details.membersList[0]['middlename0'],
  //                 //     "lastname0": users.family_details.membersList[0]['lastname0'],
  //                 //     "dob0": users.family_details.membersList[0]['dob0'],
  //                 //     "profession0": users.family_details.membersList[0]['profession0'],
  //                 //     "education0": users.family_details.membersList[0]['education0'],
  //                 //     "nominee0": users.family_details.membersList[0]['nominee0'],
  //                 //     "alreadyRegistered0": users.family_details.membersList[0]['alreadyRegistered0'],
  //                 //     "registrationNo0": users.family_details.membersList[0]['registrationNo0'],
  //                 //     "aadharnum1_0": users.family_details.membersList[0]['aadharnum1_0'],
  //                 //     "aadharnum2_0": users.family_details.membersList[0]['aadharnum2_0'],
  //                 //     "aadharnum3_0": users.family_details.membersList[0]['aadharnum3_0'],
  //                 //     "aadhar0": users.family_details.membersList[0]['aadhar0'],
  //                 //     "formcontrolnum0": users.family_details.membersList[0]['formcontrolnum0'],
  //                 // }],
  //                 noOfFamilyMembers: val.length,
  //             })
  //         }
  //     }
  // }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.catalog_details !== null &&
      users.catalog_details.error !== undefined &&
      users.catalog_details.error !== null &&
      users.catalog_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.catalog_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Ration card type"
    ) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setRationCardTypes(users.catalog_details)
      setAllValues({
        ...allValues,
        rationCardTypes: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Relation"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setRelations(users.catalog_details)
      setAllValues({
        ...allValues,
        relations: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Profession"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setProfessions(users.catalog_details)
      setAllValues({
        ...allValues,
        professions: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Education"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setEducations(users.catalog_details)
      setAllValues({
        ...allValues,
        educations: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Category"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      setCategory(users.catalog_details);
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Religion"
    ) {
      console.log("Value set");
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      setReligion(users.catalog_details);
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Nature of Work"
    ) {
      setNatureOfWork(users.catalog_details);
    }
  }, [users.catalog_details]);

  const setPickerStatus1 = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleChangeCheckbox1 = (event) => {
    for (var i = 1; i < users.family_details.noOfFamilyMembers; i++) {
      console.error("in handleChangeCheckbox1 i=" + i);
      console.error(
        "nominee:" + allValues.family_details.membersList[i]["nominee" + i]
      );
    }

    // setAllValues({ ...allValues, [event.target.name]: event.target.checked });
  };

  const handleChange2 = (event) => {
    if (event.target.name.includes("rationCardNo")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone1.length:" + e.target.value.length)

      if (
        validator.isAlphanumeric(event.target.value) &&
        event.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          rationCardNoError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          rationCardNoError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeMemberList = (e, index) => {
    console.log("in handleChangeMemberList: index=" + index);
    console.log(
      "in handleChangeMemberList: e=" + JSON.stringify(e.target.value)
    );

    if (e.target.name.includes("firstname")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone1.length:" + e.target.value.length)

      if (validator.isAlpha(e.target.value) && e.target.value.length < 20) {
        setAllValues({
          ...allValues,
          firstnameError: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          firstnameError: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
          // [e.target.name]: e.target.value
        });
      }
    } else if (e.target.name.includes("lastname")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone1.length:" + e.target.value.length)

      if (validator.isAlpha(e.target.value) && e.target.value.length !== 0) {
        setAllValues({
          ...allValues,
          lastnameError: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          lastnameError: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else if (e.target.name.includes("middlename")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone1.length:" + e.target.value.length)

      if (validator.isAlpha(e.target.value) && e.target.value.length !== 0) {
        setAllValues({
          ...allValues,
          middlenameError: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          middlenameError: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else {
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.map((obj) =>
          obj.id === index
            ? Object.assign(obj, {
                [e.target.name]: e.target.value,
              })
            : obj
        ),
      });
    }
  };

  const handleChangeCheckboxMemberList = (event, index) => {
    console.log("in handleChangeCheckboxMemberList: index=" + index);
    console.log(
      "in handleChangeCheckboxMemberList: checked=" +
        JSON.stringify(event.target.checked)
    );

    var check = event.target.checked === true ? 1 : 0;

    setAllValues({
      ...allValues,
      membersList: allValues.membersList.map((obj) =>
        obj.id === index
          ? Object.assign(obj, { [event.target.name]: check })
          : Object.assign(obj, { ["nominee" + obj.id]: 0 })
      ),
    });
  };

  const handleDateChangeMemberList = (date, index) => {
    console.log("in handleDateChangeMemberList: index=" + index);
    console.log("in handleDateChangeMemberList: event=" + JSON.stringify(date));
    console.log(new Date(date));
    setAllValues({
      ...allValues,
      membersList: allValues.membersList.map((obj) =>
        obj.id === index
          ? Object.assign(obj, {
              ["dob" + index]: new Date(date),
            })
          : obj
      ),
    });
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  const handleAAdharChangeMemberList = (e, index, num) => {
    console.log("handleAadharChange num:" + num);
    console.log("handle change called");
    console.log(e.target.value);
    console.log("Length", e.target.value.length);

    var formcontnum = 0;
    // ["formcontrolnum" + index]: formcontnum,

    if (num === 1) {
      formcontnum = 1;
    } else if (num === 2) {
      formcontnum = 2;
    } else if (num === 3) {
      formcontnum = 3;
    }

    if (e.target.value.length === 4) {
      console.log("inside");
      console.log("formcontnum:" + formcontnum);
      console.log(`${formcontnum + 1}`);
      const nextSibling = document.querySelector(
        `.row${index} .aadhardiv${formcontnum + 1}>div>input`
      );

      if (formcontnum === 1) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum1_" + index]: e.target.value,
                })
              : obj
          ),
        });
        if (nextSibling !== null) {
          nextSibling.focus();
          setAllValues({
            ...allValues,
            membersList: allValues.membersList.map((obj) =>
              obj.id === index
                ? Object.assign(obj, {
                    ["formcontrolnum" + index]:
                      obj["formcontrolnum" + index] + 1,
                  })
                : obj
            ),
          });
        }
      }
      // else if (allValues.membersList[index]["formcontrolnum" + index] === 2) {
      else if (formcontnum === 2) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum2_" + index]: e.target.value,
                })
              : obj
          ),
        });
        if (nextSibling !== null) {
          nextSibling.focus();
          setAllValues({
            ...allValues,
            membersList: allValues.membersList.map((obj) =>
              obj.id === index
                ? Object.assign(obj, {
                    ["formcontrolnum" + index]:
                      obj["formcontrolnum" + index] + 1,
                  })
                : obj
            ),
          });
        }
      }
      // else if (allValues.membersList[index]["formcontrolnum" + index] === 3) {
      else if (formcontnum === 3) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum3_" + index]: e.target.value,
                  ["aadhar" + index]:
                    allValues.membersList[index]["aadharnum1_" + index] +
                    allValues.membersList[index]["aadharnum2_" + index] +
                    e.target.value,
                })
              : obj
          ),
        });

        console.log(allValues);
        // const nextSibling = document.querySelector(
        //     `.datepicker-family>div>input`
        // );
        // nextSibling.focus();
      }
    }
    // else if (e.target.value.length > 4) {
    //     const nextSibling = document.querySelector(
    //         `.row${index} .datepicker-family>div>input`
    //     );
    //     nextSibling.focus();
    // }
    else if (e.target.value.length < 4) {
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.map((obj) =>
          obj.id === index
            ? Object.assign(obj, {
                [e.target.name]: e.target.value,
              })
            : obj
        ),
      });
    }
  };

  const handleFileChange2 = (event) => {
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      // var selectedblob = URL.createObjectURL(event.target.files[0])
      var selectedblob = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      reader.onloadend = function () {
        var base64data = reader.result;
        console.log(base64data);

        sessionStorage.setItem("rationCard", base64data);
        setAllValues({
          ...allValues,
          // imgfile: URL.createObjectURL(event.target.files[0])
          // imgfile: base64data,
          rationDocumentName: event.target.files[0].name,
        });
      };
    }
  };

  // useEffect(() => {
  //     // console.error("in useEffect imgfile ")
  //     // if (users.imgfile !== null && users.user !== null) {
  //     if (sessionStorage.getItem('rationCard') !== null && users.user !== null) {

  //         let formdata = {
  //             'file': sessionStorage.getItem('rationCard'),
  //             'userId': users.user.id,
  //             'fileType': 'ration_card_image'
  //         }

  //         console.error("formdata: " + JSON.stringify(formdata))
  //         dispatch(uploadFile(formdata))
  //     }
  // }, [users.family_details.imgfile])

  const handleEnter2 = () => {
    console.log("in handleEnter2");

    const nextSibling = document.querySelector(
      `.formcontrol${
        allValues.residentialData.formcontrolnum + 1
      }>div>div>input`
    );
    console.log("nextSibling:" + nextSibling.className);

    if (nextSibling !== null) {
      nextSibling.focus();
    }
  };

  const addNewFamilyMember = (memberNumber) => {
    console.log("in addNewFamilyMember");
    console.error("in addNewFamilyMember memberNumber=" + memberNumber);

    if (
      memberNumber !== undefined &&
      memberNumber !== null &&
      memberNumber !== ""
    ) {
      // var familymem = memberNumber
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.concat({
          id: memberNumber,
          ["relation_id" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "relation_id" + memberNumber
            ],
          ["firstname" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "firstname" + memberNumber
            ],
          ["middlename" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "middlename" + memberNumber
            ],
          ["lastname" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "lastname" + memberNumber
            ],
          ["dob" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "dob" + memberNumber
            ],
          ["profession" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "profession" + memberNumber
            ],
          ["education" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "education" + memberNumber
            ],
          ["nominee" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "nominee" + memberNumber
            ],
          ["alreadyRegistered" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "alreadyRegistered" + memberNumber
            ],
          ["registrationNo" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "registrationNo" + memberNumber
            ],
          ["aadharnum1_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum1_" + memberNumber
            ],
          ["aadharnum2_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum2_" + memberNumber
            ],
          ["aadharnum3_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum3_" + memberNumber
            ],
          ["aadhar" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadhar" + memberNumber
            ],
        }),
        // noOfFamilyMembers: familymem
      });
    } else {
      var familymem = allValues.noOfFamilyMembers + 1;
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.concat({
          id: allValues.noOfFamilyMembers,
          ["relation_id" + allValues.noOfFamilyMembers]: "",
          ["firstname" + allValues.noOfFamilyMembers]: "",
          ["middlename" + allValues.noOfFamilyMembers]: "",
          ["lastname" + allValues.noOfFamilyMembers]: "",
          ["dob" + allValues.noOfFamilyMembers]: null,
          ["profession" + allValues.noOfFamilyMembers]: "",
          ["education" + allValues.noOfFamilyMembers]: "",
          ["nominee" + allValues.noOfFamilyMembers]: 0,
          ["alreadyRegistered" + allValues.noOfFamilyMembers]: 0,
          ["registrationNo" + allValues.noOfFamilyMembers]: "",
          ["aadharnum1_" + allValues.noOfFamilyMembers]: "",
          ["aadharnum2_" + allValues.noOfFamilyMembers]: "",
          ["aadharnum3_" + allValues.noOfFamilyMembers]: "",
          ["aadhar" + allValues.noOfFamilyMembers]: "",
          ["formcontrolnum" + allValues.noOfFamilyMembers]: 1,
        }),
        noOfFamilyMembers: familymem,
      });
    }
  };

  const removeFamilyMember = (item) => {
    console.log("in removeFamilyMember: item=" + item);
    var array = [...allValues.membersList];
    var index = array.indexOf(item);
    array.splice(index, 1);
    setAllValues({
      ...allValues,
      membersList: array,
      noOfFamilyMembers: allValues.noOfFamilyMembers - 1,
    });
  };

  const familyMembers = allValues.membersList.map((id, i) => {
    return (
      <>
        <img
          alt='...'
          src={close}
          className='closebtn'
          onClick={() => removeFamilyMember(i)}
        />
        <Row className={"familymember-card row" + i}>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Relation :</p> */}
                <Required title={<Translate value='relation' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    value={allValues.membersList[i]["relation_id" + i]}
                    name={"relation_id" + i}
                    displayEmpty
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.relations.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >First Name :</p> */}
                <Required title={<Translate value='firstName' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["firstname" + i]}
                    name={"firstname" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={allValues.firstnameError}
                    helperText={
                      allValues.firstnameError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                <p>
                  <Translate value='middleName' /> :
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["middlename" + i]}
                    name={"middlename" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={allValues.middlenameError}
                    helperText={
                      allValues.middlenameError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Last Name :</p> */}
                <p className='mt-4 mb-1'>
                  <Translate value='lastName' />{" "}
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["lastname" + i]}
                    name={"lastname" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={allValues.lastnameError}
                    helperText={
                      allValues.lastnameError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Aadhar :</p> */}
                <Required title={<Translate value='aadhar' />} />
              </Col>
              <Col xs={9}>
                <div className='aadhar-div'>
                  <img alt='...' src={aadhar} />
                  <FormControl>
                    <TextField
                      className='aadhardiv1'
                      placeholder='1234'
                      type={"number"}
                      value={allValues.membersList[i]["aadharnum1_" + i]}
                      name={"aadharnum1_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 1);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className='aadhardiv2'
                      placeholder='2345'
                      value={allValues.membersList[i]["aadharnum2_" + i]}
                      name={"aadharnum2_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 2);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className='aadhardiv3'
                      placeholder='3456'
                      value={allValues.membersList[i]["aadharnum3_" + i]}
                      name={"aadharnum3_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 3);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  {allValues.membersList[i]["aadharcheck" + i] ? (
                    <img alt='...' src={checkgreen} />
                  ) : (
                    <img alt='...' src={checkgrey} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >DOB :</p> */}
                <Required title={<Translate value='dOB' />} />
              </Col>
              <Col xs={9} style={{ borderBottom: "1px solid grey" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    className='datepicker1'
                    format='dd/MM/yyyy'
                    onChange={(date) => {
                      handleDateChangeMemberList(date, i);
                    }}
                    value={
                      allValues.membersList[i]["dob" + i] !== null
                        ? new Date(allValues.membersList[i]["dob" + i])
                        : null
                    }
                    // maxDate={ allValues.membersList[i]["relation" + i] === 49 || allValues.membersList[i]["relation" + i] === 50 ? dob3 : new Date()}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                <p>
                  <Translate value='profession' /> :
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    displayEmpty
                    value={allValues.membersList[i]["profession" + i]}
                    name={"profession" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.professions.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                <p>
                  <Translate value='education' /> :
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    displayEmpty
                    value={allValues.membersList[i]["education" + i]}
                    name={"education" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.educations.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className='member-p'>
            <FormControlLabel
              control={
                <Checkbox
                  // onChange={handleChangeCheckbox1}
                  // color="primary"
                  checked={allValues.membersList[i]["nominee" + i] === 1}
                  name={"nominee" + i}
                  onChange={(e) => {
                    handleChangeCheckboxMemberList(e, i);
                  }}
                />
              }
              label={<Translate value='nominee' />}
            />
          </Col>
        </Row>
      </>
    );
  });

  // const [documentUploaded, setdocumentUploaded] = React.useState(false)
  // useEffect(() => {
  //     if(documentUploaded){
  //             if (users.profile_details.editmode) {
  //                 props.saveEditButtonPressed()
  //             }
  //             else {
  //                 props.nextButtonPressed()
  //             }
  //         }
  // }, [documentUploaded]);

  useEffect(() => {
    // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
    if (
      users.upload_file !== null &&
      users.upload_file.success !== undefined &&
      users.upload_file.success === true &&
      users.upload_file.image.fileType === "rationCard"
    ) {
      if (users.upload_file.userFileName === "rationCard") {
        // setAllValues({
        //     ...allValues,
        //     documentUploadResponse: users.upload_file.image
        // })
        setdocumentUploaded(true);

        submitFamily(users.upload_file.image);
        // if (users.profile_details.editmode) {
        //     props.saveEditButtonPressed()
        // }
        // else {
        //     props.nextButtonPressed()
        // }
      }
    } else if (
      users.upload_file !== null &&
      users.upload_file.success === undefined
    ) {
      showToast("ERROR", "Cannot upload the ration image. Please try later.");
    }
  }, [users.upload_file]);

  const nextButtonPressed2 = () => {
    try {
      var i = 0;

      for (i = 0; i < users.family_details.noOfFamilyMembers; i++) {
        if (
          // users.family_details.rationCardNo === "" ||
          // users.family_details.rationCardType === "" ||
          // users.family_details.imgfile === "" ||
          // sessionStorage.getItem('rationCard') === null ||

          users.family_details.membersList[i]["firstname" + i] === "" ||
          users.family_details.membersList[i]["relation_id" + i] === "" ||
          users.family_details.membersList[i]["aadhar" + i] === "" ||
          users.family_details.membersList[i]["dob" + i] === "" ||
          users.personal_details.firstnameError === true ||
          users.personal_details.lastnameError === true ||
          users.family_details.membersList[i]["profession" + i] === "" ||
          users.family_details.membersList[i]["education" + i] === "" ||
          users.family_details.membersList[i]["nominee" + i] === 0

          // users.family_details.membersList[i]["alreadyRegistered" + i] === ""
        ) {
          console.log(
            "users.family_details.membersList.firstname=" +
              users.family_details.membersList[i]["firstname" + i]
          );
          showToast(
            "WARN",
            "Please Fill All the Required Fields to Continue5!"
          );
          break;
        }
      }
      console.log("var i=" + i);

      if (i === users.family_details.noOfFamilyMembers) {
        // submitFamily()

        uploadRationCard();
      }
    } catch (err) {
      showToast("ERROR", "Error Saving the Details");
      console.error("in catch-> Error:", err);
    }
  };

  const uploadRationCard = () => {
    if (
      sessionStorage.getItem("rationCard") !== null &&
      sessionStorage.getItem("rationCard") !== undefined &&
      users.user !== null
    ) {
      let formdata = {
        file: sessionStorage.getItem("rationCard"),
        userId: users.user.id,
        fileType: "rationCard",
      };
      console.error("formdata rationCard: " + JSON.stringify(formdata));
      dispatch(uploadFile(formdata, "rationCard"));
    } else {
      submitFamily();
    }
  };

  const submitFamily = (documentUploadResponse) => {
    let families = [];
    // let families = [{
    //     "relation_type_id": users.family_details.membersList[0]["relation0"],
    //     "first_name": users.family_details.membersList[0]["firstname0"],
    //     "last_name": users.family_details.membersList[0]["lastname0"],
    //     "middle_name": users.family_details.membersList[0]["middlename0"],
    //     "aadhar_no": users.family_details.membersList[0]["aadhar0"],
    //     "dob": users.family_details.membersList[0]["dob0"],
    //     "profession": users.family_details.membersList[0]["profession0"],
    //     "education": users.family_details.membersList[0]["education0"],
    //     "is_nominee": users.family_details.membersList[0]["nominee0"],
    //     "is_registered": users.family_details.membersList[0]["alreadyRegistered0"],
    //     "registeration_no": users.family_details.membersList[0]["registrationNo0"],
    //     "labour_user_id": users.user.id,
    //     // "isinsert": 0,
    // 	// "isdeleted": 0,
    // 	// "isactive": 1,
    // }]

    for (var i = 0; i < users.family_details.noOfFamilyMembers; i++) {
      if (users.family_details.membersList[i].relation_id !== undefined) {
        families.push({
          relation_id: users.family_details.membersList[i].relation_id,
          labour_user_id: 0,
          parent_child_user_id:
            users.family_details.membersList[i].parent_child_user_id,
          isinsert: 0,
          isdeleted: 0,
          isactive: 1,
          relation_type_id:
            users.family_details.membersList[i]["relation_id" + i],
          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          en_aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered: 0,
          registeration_no: sessionStorage.getItem("registrationID"),
        });
      } else {
        families.push({
          relation_id: 0,
          labour_user_id: 0,
          parent_child_user_id: 0,
          isinsert: 1,
          isdeleted: 0,
          isactive: 1,
          relation_type_id:
            users.family_details.membersList[i]["relation_id" + i],
          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          en_aadhar_no: users.family_details.membersList[i]["aadhar" + i],
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered: 0,
          registeration_no: sessionStorage.getItem("registrationID"),
        });
      }
    }

    var documentUploadResponse1 = null;
    if (
      documentUploadResponse !== null &&
      documentUploadResponse !== undefined
    ) {
      documentUploadResponse1 = documentUploadResponse.fileId;
    }

    let dataToSubmit = {
      user_id: users.user.id,
      ration_card_no: users.family_details.rationCardNo,
      // "ration_card_image_id": users.upload_file.image.fileId,
      ration_card_image_id: documentUploadResponse1,
      ration_card_type: users.family_details.rationCardType,
      families: families,
    };

    console.error("dataToSubmit", JSON.stringify(dataToSubmit));

    // dispatch(addLabourFamilyDetails(dataToSubmit))

    setFamilyData(families);
  };

  const init_family_details = {
    rationCardNo: "",
    rationCardType: "",
    imgfile: "",
    webcamEnabled: false,
    open: false,
    setOpen: false,
    openDatePicker: false,

    rationDocumentName: "",

    formcontrolnum: 1,

    membersList: [
      {
        id: 0,
        relation0: "",
        firstname0: "",
        middlename0: "",
        lastname0: "",
        dob0: null,
        profession0: "",
        education0: "",
        nominee0: "",
        alreadyRegistered0: 0,
        registrationNo0: "",
        aadharnum1_0: "",
        aadharnum2_0: "",
        aadharnum3_0: "",
        aadhar0: "",
        formcontrolnum0: 1,
      },
    ],
    noOfFamilyMembers: 1,
    documentUploadResponse: "",
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.success !== undefined &&
      users.addLabourFamilyDetails.success !== null &&
      users.addLabourFamilyDetails.success === true
    ) {
      // showToast("SUCCESS","Family Details Saved Successfully!")
      // props.nextButtonPressed()

      showToast("SUCCESS", "Family Details Saved Successfully!");
      // props.nextButtonPressed()
      dispatch(updateFamilyData(init_family_details));
      dispatch(addLabourFamilyDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.success !== undefined &&
      users.addLabourFamilyDetails.success !== null &&
      users.addLabourFamilyDetails.success === "false"
    ) {
      if (users.addLabourFamilyDetails.errors.msg.length > 0) {
        const details = users.addLabourFamilyDetails.errors.msg;
        for (let i = 0; i < details.length; i++) {
          if (details[i].param.indexOf("families") > -1) {
            showToast("ERROR", "Please make someone nominee!");
            break;
          }
        }
      }
    } else if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.error !== undefined &&
      users.addLabourFamilyDetails.error === true
    ) {
      showToast("ERROR", "Error Saving Family Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabourFamilyDetails]);

  return (
    <>
      <div className='root'>
        {loading ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        ) : null}

        {submitSuccess ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <div style={{ display: "block" }}>
              <CheckCircle
                className={classes.backdropCheck}
                style={{ height: "auto", width: "200px" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className='final-success-title'>Successfully submitted</p>{" "}
                <br />
                <p className='final-success-title'>
                  Please login to access your E-Card
                </p>
                {/* <a  onClick={() => props.history.push("/")}> login </a> */}
              </div>
              <Link
                to='#'
                // onClick={printDocument}
                style={{ flexGrow: "0.5" }}
              >
                <a
                  variant='outline-primary'
                  className='final-success-link'
                  onClick={() => props.history.push("/login")}
                >
                  {" "}
                  Login{" "}
                </a>
              </Link>
              {/* <p className="final-success-link-p">View Acknowledgement Letter <Link to="#"
                                    onClick={printDocument}
                                    style={{ flexGrow: "0.5" }}
                                >

                                    <span variant="outline-primary" className="final-success-link"> Click Here</span>
                                </Link>
                                </p> */}
            </div>
          </Backdrop>
        ) : null}

        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/login'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='header.commonTitle' />
                  <br />
                  <Translate value='header.subcommonTitle' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={allValues.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>
      {vaultRefNumber && nameMatched ? (
        <div className='ekyc-verify d-flex justify-content-center p-2'>
          <img alt='...' src={checkwhite} className='p-2' />
          <h3 className='p-1 mt-3'>Aadhaar has been verified</h3>

          {loading && <CircularProgress size={24} className='buttonProgress' />}
        </div>
      ) : vaultRefNumber && nameMatched === false ? (
        <div className='ekyc-verify d-flex justify-content-center p-2'>
          <img alt='...' src={closeicon} className='p-2' />
          <h3 className='p-1 mt-3'>Name Matching Failed !! Try Again</h3>

          {loading && <CircularProgress size={24} className='buttonProgress' />}

          {/* <h3 style = {{marginRight : "20px", marginTop : "10px"}}>Try Again</h3> */}
          <Button
            className='aadhar-verify-btn'
            variant='outline-primary'
            // Rowor="primary"
            // className={buttonClassname}

            // onClick={handleValidateClick}
            onClick={VerifyButton}
          >
            Verify
          </Button>
          {loading && <CircularProgress size={24} className='buttonProgress' />}
        </div>
      ) : (
        <div className='ekyc-verify d-flex justify-content-center'>
          <h3 style={{ marginRight: "20px", marginTop: "10px" }}>
            Click here to verify your Aadhaar
          </h3>
          <Button
            className='aadhar-verify-btn'
            variant='outline-primary'
            // Rowor="primary"
            // className={buttonClassname}

            // onClick={handleValidateClick}
            onClick={VerifyButton}
          >
            Verify
          </Button>
          {loading && <CircularProgress size={24} className='buttonProgress' />}
        </div>
      )}

      {vaultRefNumber && nameMatched ? (
        <>
          <div>
            <Row className='residential-title-row'>
              <Col xs={12} className='profile-title'>
                <h1>
                  <Translate value='residentialAddress' />
                </h1>
              </Col>
            </Row>

            <Row className='form-row'>
              <Col xs={12} md={6}>
                <div className='residential-div mt-auto'>
                  {/* <p className="mt-0 mb-2">Type of Residence*</p> */}
                  <Required
                    className='mt-0 mb-1'
                    title={<Translate value='typeOfresidence' />}
                  />
                  <FormControl
                    variant='outlined'
                    fullWidth
                    style={{ marginBottom: "26px" }}
                  >
                    <Select
                      // className="select-marital"
                      // labelId="demo-simple-select-required-label"
                      // id="demo-simple-select-required"

                      // value={allValues.residentialData.residenceType}
                      // name="residenceType"
                      displayEmpty
                      onChange={handleResidence}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {residenceType !== undefined &&
                        residenceType !== null &&
                        residenceType.length > 0 &&
                        residenceType.map((i) => (
                          <MenuItem value={i.value_id}>
                            <ListItemText primary={i.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  {/* <p className="mt-5 mb-2">Enter Your District*</p> */}
                  <Required
                    className='mt-4 mb-2'
                    title={<Translate value='enterYourDistrict' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      labelId='demo-simple-select-required-label'
                      id='demo-simple-select-required'
                      // value={allValues.residentialData.district}
                      // name="district"
                      // displayEmpty
                      onChange={handleDistrict}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {district !== undefined &&
                        district !== null &&
                        district.length > 0 &&
                        district.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.districtHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">Enter Your City Name*</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='enterYourCityName' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      labelId='demo-simple-select-required-label'
                      id='demo-simple-select-required'
                      // value={allValues.residentialData.city}
                      // name="city"
                      // displayEmpty
                      onChange={handleCity}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {city !== undefined &&
                        city !== null &&
                        city.length > 0 &&
                        city.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.cityHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">Street Name / Road Name*</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='streetRoadName' />}
                  />
                  <FormControl
                    fullWidth
                    className='formcontrol1'
                    // onKeyPress={event => {
                    //     if (event.key === "Enter") {
                    //         handleEnter();
                    //     }
                    // }}
                  >
                    <TextField
                      variant='outlined'
                      placeholder='Enter Your Street/Road Name'
                      name='street_name'
                      value={allValues.street_name}
                      onChange={handleValidation}
                      // value={allValues.residentialData.streetRoad}
                      // onChange={handleChangeResidential}
                      // helperText={allValues.streetlength ? "Please enter in correct length" : ""}
                      // error = {allValues.streetlength}
                    />
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.streetHelperText}</FormHelperText> */}

                  <p className='mt-5 mb-2'>
                    <Translate value='landmark' />{" "}
                  </p>
                  <FormControl fullWidth className='formcontrol2'>
                    <TextField
                      variant='outlined'
                      placeholder='Enter Your Landmark'
                      name='landmark'
                      value={allValues.landmark}
                      onChange={handleValidation}
                    />
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.landmarkHelperText}</FormHelperText> */}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className='residential-div mt-auto'>
                  {/* <p className="mt-0 mb-2">Enter Your State*</p> */}
                  <Required
                    className='mt-0 mb-2'
                    title={<Translate value='state' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      labelId='demo-simple-select-required-label'
                      id='demo-simple-select-required'
                      // value={allValues.residentialData.state}
                      // name="state"
                      // displayEmpty
                      onChange={handleState}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {states !== undefined &&
                        states !== null &&
                        states.length > 0 &&
                        states.map(
                          (i) =>
                            i.name === "Karnataka" && (
                              <MenuItem value={i.id}>
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.stateHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">Enter Your Taluk*</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='taluk' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      labelId='demo-simple-select-required-label'
                      id='demo-simple-select-required'
                      // value={allValues.residentialData.taluk}
                      // name="taluk"
                      // displayEmpty
                      onChange={handleTaluk}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {taluk !== undefined &&
                        taluk !== null &&
                        taluk.length > 0 &&
                        taluk.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.talukHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">Enter Your Area / Village Name*</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='villageName' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      labelId='demo-simple-select-required-label'
                      id='demo-simple-select-required'
                      // value={allValues.residentialData.areaVillage}
                      // name="areaVillage"
                      // displayEmpty
                      onChange={handleVillage}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {village !== undefined &&
                        village !== null &&
                        village.length > 0 &&
                        village.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                      {console.log(village, "village12")}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.villageHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">House / Building Number*</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='houseBuildingNumber' />}
                  />
                  <FormControl fullWidth>
                    <TextField
                      variant='outlined'
                      placeholder='Enter Your House / Building Number'
                      name='house_no'
                      value={allValues.house_no}
                      onChange={handleValidation}
                      error={allValues.house_no_error}
                      helperText={
                        allValues.house_no_error
                          ? "Please enter in numbers only"
                          : ""
                      }
                      inputProps={{ minLength: 0, maxLength: 15 }}
                    />
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.houseHelperText}</FormHelperText> */}

                  {/* <p className="mt-5 mb-2">Pincode</p> */}
                  <Required
                    className='mt-5 mb-2'
                    title={<Translate value='pincode' />}
                  />
                  <FormControl fullWidth>
                    <TextField
                      variant='outlined'
                      placeholder='Enter Your Pincode'
                      name='pincode'
                      value={allValues.pincode}
                      onChange={handleValidation}
                      error={pinError}
                      helperText={pinError}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/gi, "");
                        if (e.target.value.length === 0) {
                          setPinError("Please Enter Pin Code");
                        } else if (e.target.value.length < 6) {
                          setPinError("Pin code must contain 6 digits");
                        } else {
                          setPinError("");
                        }
                      }}
                      inputProps={{ minLength: 0, maxLength: 6 }}
                    />
                  </FormControl>
                  {/* <FormHelperText className="helperTextAadhar">{allValues.pincodeHelperText}</FormHelperText> */}
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} xl={6}>
                <div className='residential-div mt-5'>
                  <p className='mb-2 w-50'>
                    <Translate
                      value='uploadLabourCard'
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </p>
                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedLabour !== null &&
                        selectedLabour.length > 0 &&
                        selectedLabour !== undefined &&
                        Array.from(selectedLabour).map((i, index) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='birth'
                              value={i.name}
                              onChange={handleChange}
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      alt='...'
                                      src={close}
                                      className='removefilebtn'
                                      onClick={() => removefile(index)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))
                      )}
                    </div>

                    <div className='browsebutton2-div'>
                      <label
                        htmlFor={`upload-photo4`}
                        className='browse-button btn2 schemes'
                      >
                        <img alt='...' src={folder} />
                        <Translate value='upload' />
                      </label>
                      <input
                        type='file'
                        id={`upload-photo4`}
                        name='photo'
                        onChange={handleLabourChange}
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </Col>

              {/* fill emptyFIelds */}

              <Col xs={12} md={6}>
                <Required
                  className='mt-5 mb-0'
                  title={<Translate value='selectYourGender' />}
                />

                <div className='gender-div'>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='27'
                      id='male'
                      onChange={handleChange}
                      checked={allValues.gender === "27"}
                      disabled={
                        aadharValidated ||
                        users.personal_details.is_aadhar_verified
                          ? true
                          : false
                      }
                    />
                    <label htmlFor='male'>
                      {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                      <img alt='...' src={male} />
                      <span>
                        <Translate value='male' />
                      </span>
                    </label>
                  </div>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='28'
                      id='female'
                      onChange={handleChange}
                      checked={allValues.gender === "28"}
                      disabled={
                        aadharValidated ||
                        users.personal_details.is_aadhar_verified
                          ? true
                          : false
                      }
                    />
                    <label htmlFor='female'>
                      {/* <img alt="..."  className="gender-img" src="https://www.pngkey.com/png/full/203-2037403_flat-faces-icons-circle-girl-flat-icon-png.png" /> */}
                      <img alt='...' src={female} />
                      <span>
                        <Translate value='female' />
                      </span>
                    </label>
                  </div>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='29'
                      id='others'
                      onChange={handleChange}
                      checked={allValues.gender === "29"}
                      disabled={
                        aadharValidated ||
                        users.personal_details.is_aadhar_verified
                          ? true
                          : false
                      }
                    />
                    <label htmlFor='others'>
                      {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                      <img alt='...' src={radioon} />
                      <span>
                        <Translate value='others' />
                      </span>
                    </label>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={12} lg={6} xl={6}>
                <Required
                  className='mt-5 mb-0'
                  title={<Translate value='category' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol6'
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    }
                  }}
                >
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={allValues.category}
                    name='category'
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='Select' />
                    </MenuItem>

                    {category.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    <MenuItem value="General">
                                        <ListItemText primary="General" />
                                    </MenuItem>
                                    <MenuItem value="OBC">
                                        <ListItemText primary="OBC" />
                                    </MenuItem>
                                    <MenuItem value="SC">
                                        <ListItemText primary="SC" />
                                    </MenuItem>
                                    <MenuItem value="ST">
                                        <ListItemText primary="ST" />
                                    </MenuItem>
                                    <MenuItem value="Minority">
                                        <ListItemText primary="Minority" />
                                    </MenuItem> */}
                  </Select>
                </FormControl>

                <Required
                  className='mt-4 mb-2'
                  title={<Translate value='religion' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol5'
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    }
                  }}
                >
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={allValues.religion}
                    name='religion'
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='Select' />
                    </MenuItem>

                    {Religion &&
                      Religion.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.description} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='natureoftheWorkplace' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol5'
                >
                  <Select
                    className='select-marital'
                    value={allValues.natureOfWork}
                    name='natureOfWork'
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {natureOfWork &&
                      natureOfWork.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xs={12} md={6}>
                <div className='form-inner-card-right mt-5'>
                  <FormControl
                    className='mt-2 mb-2 interstateRadio formcontrol9'
                    variant='outlined'
                    fullWidth
                    component='fieldset'
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleEnter();
                      }
                    }}
                  >
                    {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                    <RadioGroup
                      row
                      name='isMigrant'
                      value={allValues.isMigrant}
                      onChange={handleChange}
                    >
                      <p className='mt-2 mb-2' style={{ flexGrow: 1 }}>
                        <Translate value='interstateMigrantWorker' />
                      </p>
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label={<Translate value='yes' />}
                      />
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={<Translate value='no' />}
                      />
                    </RadioGroup>
                  </FormControl>

                  {allValues.isMigrant === "1" ? (
                    <>
                      <p className='mt-2 mb-2'>
                        <Translate value='migrantFromState' />
                      </p>
                      <FormControl variant='outlined' fullWidth>
                        <Select
                          className='select-state'
                          labelId='demo-simple-select-required-label'
                          id='demo-simple-select-required'
                          value={allValues.migrantState}
                          name='migrantState'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='Select' />
                          </MenuItem>

                          {states.map(
                            (i) =>
                              i.name !== "Karnataka" && (
                                <MenuItem value={i.id}>
                                  <ListItemText
                                    primary={i.short_name + " | " + i.name}
                                  />
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    </>
                  ) : null}
                </div>
                <div className='form-inner-card-right mt-0'>
                  <FormControl
                    className='mt-2 mb-2 interstateRadio formcontrol9'
                    variant='outlined'
                    fullWidth
                    component='fieldset'
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleEnter();
                      }
                    }}
                  >
                    {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                    <RadioGroup
                      row
                      name='isAyushMan'
                      value={allValues.isAyushMan}
                      onChange={handleChange}
                    >
                      <p className='mt-2 mb-2' style={{ flexGrow: 1 }}>
                        <Translate value='ayushmanholder' />
                      </p>
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label={<Translate value='yes' />}
                      />
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={<Translate value='no' />}
                      />
                    </RadioGroup>
                  </FormControl>

                  {allValues.isAyushMan === "1" ? (
                    <>
                      <p className='mt-3 mb-2'>
                        <Translate value='ayushmancardnumber' />{" "}
                      </p>
                      <FormControl fullWidth className='formcontrol2'>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Ayushman Bharath Card Number'
                          name='ayushManCard'
                          value={allValues.ayushManCard}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>

          <Row className='m-0'>
            <Row className='bank-title-row'>
              <Col xs={12} className='profile-title'>
                {/* <h1>Bank Passbook / Statement</h1> */}
                <h1>Bank Details</h1>
              </Col>
            </Row>

            <Row className='form-row'>
              <Col xs={12} md={6} className='bank-col-1'>
                <Row className='form-inner-card-right'>
                  <Col xs={12}>
                    {/* <p className="mt-3 mb-2">Account Number*</p> */}
                    <Required
                      className='mt-3 mb-2'
                      title={<Translate value='accountNumber' />}
                    />
                    <FormControl fullWidth>
                      <TextField
                        variant='outlined'
                        placeholder='Enter Your Account Number'
                        name='accountNumber'
                        value={allValues.accountNumber}
                        onChange={changeHandler}
                        error={accError}
                        helperText={accError}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/gi,
                            ""
                          );
                          if (e.target.value.length === 0) {
                            setAccError("Please Enter Account Number");
                          } else {
                            setAccError("");
                          }
                        }}
                        // inputProps={{minLength:0,maxLength:6}}
                      />
                    </FormControl>
                  </Col>

                  <Col xs={12}>
                    {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                    <Required
                      className='mt-4 mb-2'
                      title={<Translate value='enterYourBankName' />}
                    />
                    <FormControl
                      fullWidth
                      className='formcontrol1'
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleEnter();
                        }
                      }}
                    >
                      <TextField
                        variant='outlined'
                        placeholder='Enter Your Bank Name'
                        name='bankName'
                        value={allValues.bankName}
                        onChange={changeHandler}
                        error={allValues.bankNameError}
                        helperText={
                          allValues.bankNameError
                            ? "Please enter in correct format"
                            : ""
                        }
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className='bank-col-2'>
                <Row className='form-inner-card-right'>
                  <Col xs={8} md={10}>
                    <Required
                      className='mt-3 mb-2'
                      title={<Translate value='enterYourIFSCcode' />}
                    />
                    <FormControl
                      fullWidth
                      className='formcontrol1'
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleEnter();
                        }
                      }}
                    >
                      <TextField
                        variant='outlined'
                        placeholder="Enter Your Bank's IFSC Code"
                        name='ifscCode'
                        value={allValues.ifscCode}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={4} md={2}>
                    <div className='checkDiv mt-3'>
                      {checkBank ? (
                        <>
                          {/* <Tooltip title="Bank Account is Verified !"> */}
                          <img
                            alt='...'
                            src={checkgreen}
                            style={{ marginTop: "30px" }}
                          />
                          {/* </Tooltip> */}
                        </>
                      ) : (
                        <Button
                          variant='outline-primary'
                          disabled={loadingVerifyBank}
                          onClick={verifyBank}
                          style={{ marginTop: "20px" }}
                        >
                          {loadingVerifyBank ? (
                            <>
                              <Translate value='fetching' />
                            </>
                          ) : (
                            <>
                              <Translate value='fetchDetails' />
                            </>
                          )}
                        </Button>
                      )}
                      {loadingVerifyBank && (
                        <CircularProgress
                          size={24}
                          style={{ marginTop: "30px" }}
                          className='buttonProgress'
                        />
                      )}
                    </div>
                  </Col>

                  <Col xs={12}>
                    {/* <Col xs={6} md={12}>
                            {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                    <Required
                      className='mt-4 mb-2'
                      title={<Translate value='enterYourBankBranch' />}
                    />
                    <FormControl fullWidth>
                      <TextField
                        variant='outlined'
                        placeholder='Enter Your Bank Branch'
                        name='bankBranch'
                        value={allValues.bankBranch}
                        onChange={changeHandler}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className='bank-col-3'>
                <div
                  className='form-inner-card-right row'
                  style={{ marginLeft: "4px", marginRight: "2px" }}
                >
                  {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                  <Required
                    className='mt-0 mb-2'
                    title={<Translate value='enterYourBankAddress' />}
                  />
                  <FormControl
                    fullWidth
                    className='formcontrol1'
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleEnter();
                      }
                    }}
                  >
                    <TextareaAutosize
                      variant='outlined'
                      multiline
                      rows={3}
                      // rowsMax={4}
                      // defaultValue="Default Value"
                      placeholder='Enter Your Bank Address'
                      name='bankAddress'
                      value={allValues.bankAddress}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </Col>
              <Col xs={12} md={6} className='bank-col-4'>
                <div
                  className=' mt-4'
                  style={{ marginLeft: "22px", marginRight: "40px" }}
                >
                  {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                  <Required
                    className='mt-4 mb-2'
                    title={<Translate value='uploadYourPassbookDocument' />}
                  />

                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedfiles !== null &&
                        selectedfiles.length > 0 &&
                        Array.from(selectedfiles).map((i, index) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='passbookDocumentName'
                              value={i.name}
                              onChange={handleChangeBank}
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      alt='...'
                                      src={close}
                                      className='removefilebtn'
                                      onClick={() => removefile(index)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))
                      )}
                    </div>
                    <div className='browsebutton2-div'>
                      <label
                        htmlFor='upload-photo'
                        className='browse-button btn2'
                      >
                        <img alt='...' src={folder} />{" "}
                        <Translate value='browse' />
                      </label>
                      <input
                        type='file'
                        name='photo'
                        id='upload-photo'
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>
                  </div>

                  <p className='mt-3 instructions2'>
                    {/* <span><Translate value="instructions"/>*</span><br />
                                Format supporting document should be in JPEG, PNG, or PDF<br />
                                (Size contains 500 KB to 2 MB) */}
                    <span>
                      <Translate value='instructions' />*
                    </span>
                    <br />
                    <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                    <br />
                    <Translate value='sizecontains500KBto2MB' />
                  </p>
                </div>
              </Col>
            </Row>

            <Row className='form-row'>
              <Col xs={12} className='note2 text-center mt-4 bank-col-6'>
                <p>
                  <span>
                    <Translate value='note' />
                  </span>
                  <Translate value='noteAftercompletion' />
                </p>
              </Col>
            </Row>
          </Row>

          <Row className='m-0'>
            <Row className='family-title-row'>
              <Col xs={12} className='profile-title'>
                <h1>Family Details</h1>
              </Col>
            </Row>

            <Row className='form-row'>
              <Col xs={12} className='family-col-3 form-inner-card mt-4'>
                <p>
                  <Translate value='listofFamilyMembers' />
                </p>
              </Col>

              <Col xs={12} className='family-col-4'>
                {familyMembers}
              </Col>

              <Col
                xs={12}
                className='addMemberCol mt-4 family-col-10'
                onClick={() => addNewFamilyMember("")}
              >
                <AddCircleRounded />
                <p>
                  <Translate value='addMoreFamilyMembers' />
                </p>
              </Col>

              <Col xs={12} className='note2 text-center mt-4 family-col-11'>
                <p>
                  <span>
                    <Translate value='note' />
                    <Translate value='noteafterfurtherprocess' />
                  </span>
                </p>
              </Col>
            </Row>
            <Row className='form-row'></Row>

            <Row className='button-inside-form-row mb-5'>
              <Col xs={12} className='next-back-button-row mt-4 '></Col>
            </Row>
          </Row>

          <div className='d-flex justify-content-center mt-5 mb-5'>
            <Button
              className='aadhar-verify-btn2'
              size='lg'
              onClick={submitButton}
            >
              Submit
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
}
export default RegistrationAddress;
