import { FormControl, TextareaAutosize, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { singleRecordAPI } from '../services/schemes.service';

const LabourDetails = ({ labourData }) => {
  const { schemeName, schemeAvailID, schemeTransactionID } = useParams();
  const [schemeInfo, setSchemeInfo] = useState(null);
  // const [showBankDetails, setShowBankDetails] = useState(false);

  // if (labourData && labourData.bank_details[0]?.bank_name) {
  //   setShowBankDetails(true);
  // }

  const callGetSingleRecord = async (schemeAvailID, schemeTransactionID) => {
    const { data } = await singleRecordAPI(schemeAvailID, schemeTransactionID);
    // console.log(data);

    setSchemeInfo(data);
  };

  useEffect(() => {
    callGetSingleRecord(schemeAvailID, schemeTransactionID);
  }, [schemeAvailID, schemeTransactionID]);

  return (
    <>
      <>
        <Row className="scheme-subtitle-row mt-4">
          <Col xs={12} className="searchform-subtitle">
            <p>
              {schemeName === 'Accident Assistance' ? (
                <Translate value="applicantOrBeneficaryDetails" />
              ) : (
                <Translate value="applicantDetails" />
              )}
            </p>
          </Col>
        </Row>

        <Row className="form-row mt-4 config-form searchform">
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="nameoftheApplicant" />{' '}
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  `${labourData.personal_details[0].first_name} ${labourData.personal_details[0].middle_name} ${labourData.personal_details[0].last_name}`
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="dateofbirth" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(labourData.personal_details[0].date_of_birth).format(
                    'DD-MM-YYYY',
                  )
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="ageofApplicant" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment().diff(
                    labourData.personal_details[0].date_of_birth,
                    'years',
                  )
                }
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="Age at time of the application of the scheme" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(schemeInfo?.applied_date, 'YYYY-MM-DD').diff(
                    labourData.personal_details[0].date_of_birth,
                    'years',
                  )
                }
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="mobileNumber" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={labourData && labourData.personal_details[0].mobile_no}
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="rationCardNumber" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                // value={allValues.ration}
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={12}>
            {/* <Required className="mb-2" title="Registration Number" /> */}
            {/* <p className="mb-2 mt-4">Present Address of Applicant</p> */}
            <p className="mb-2 mt-4">
              <Translate value="presentAddressofApplicant" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  `${labourData.address_details[0].door_no ?? ''}, ${labourData.address_details[0].street_name ?? ''
                  }, ${labourData.address_details[0].land_mark ?? ''}, ${labourData.address_details[0].panhayat_city_town ?? ''
                  }, ${labourData.address_details[0].district ?? ''}, ${labourData.address_details[0].ward_area_village ?? ''
                  }, ${labourData.address_details[0].taluk ?? ''}, ${labourData.address_details[0].state ?? ''
                  }, ${labourData.address_details[0].pin_code ?? ''}`
                }
                disabled
              />
            </FormControl>
          </Col>
        </Row>

        <Row className="scheme-subtitle-row">
          <Col xs={12} className="searchform-subtitle">
            <p>
              <Translate value="addressWhereApplicantisRegistered" />
            </p>
          </Col>
        </Row>

        <Row className="form-row mt-4 config-form searchform">
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="state" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={labourData && labourData.address_details[0].state}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="district" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={labourData && labourData.address_details[0].district}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="taluk" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={labourData && labourData.address_details[0].taluk}
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="gramPanchayat" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData && labourData.address_details[0].panhayat_city_town
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="villageWard" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData && labourData.address_details[0].ward_area_village
                }
                disabled
              />
            </FormControl>
          </Col>
        </Row>

        {schemeName != 'Funeral Expense and Death Assistance' && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>
                  <Translate value="bankDetails" />
                </p>
              </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} md={4}>
                <p className="mb-2">
                  <Translate value="bankName" />
                </p>

                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    variant="outlined"
                    value={labourData && labourData.bank_details[0]?.bank_name}
                    disabled
                  />
                </FormControl>
              </Col>
              <Col xs={12} md={4}>
                <p className="mb-2">
                  <Translate value="accountNumber" />
                </p>

                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    variant="outlined"
                    value={labourData && labourData.bank_details[0]?.account_no}
                    disabled
                  />
                </FormControl>
              </Col>
              <Col xs={12} md={4}>
                <p className="mb-2">
                  <Translate value="branchName" />
                </p>

                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    variant="outlined"
                    value={labourData && labourData.bank_details[0]?.bank_name}
                    disabled
                  />
                </FormControl>
              </Col>
              <Col xs={12} md={4}>
                <p className="mb-2 mt-4">
                  <Translate value="iFSCCode" />
                </p>
                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    variant="outlined"
                    value={labourData && labourData.bank_details[0]?.ifsc_code}
                    disabled
                  />
                </FormControl>
              </Col>
            </Row>
          </>
        )}


        <Row className="scheme-subtitle-row">
          <Col xs={12} className="searchform-subtitle">
            <p>
              <Translate value="registrationDetails" />
            </p>
          </Col>
        </Row>

        <Row className="form-row mt-4 config-form searchform">
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="registrationNumber" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData && labourData.personal_details[0].registration_code
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="dateofRegistration" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(
                    labourData.personal_details[0].approved_date
                      ? labourData.personal_details[0].approved_date
                      : labourData.personal_details[0].validity_from_date
                  ).format('DD-MM-YYYY')
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2">
              <Translate value="dateofCompletionof60Years" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(labourData.personal_details[0].date_of_birth)
                    .add(60, 'Y')
                    .format('DD-MM-YYYY')
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="ageattheTimeofRegistration" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(
                    labourData.personal_details[0].approved_date
                      ? labourData.personal_details[0].approved_date
                      : labourData.personal_details[0].validity_from_date

                  ).diff(
                    labourData.personal_details[0].date_of_birth,
                    'years',
                  )
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="numberofyearsRegistered" />
            </p>

            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment().diff(
                    labourData.personal_details[0].approved_date
                      ? labourData.personal_details[0].approved_date
                      : labourData.personal_details[0].validity_from_date,
                    'years',
                  )
                }
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-2 mt-4">
              <Translate value="renewaldateofRegistration" />
            </p>
            <FormControl fullWidth className="formcontrol1">
              <TextField
                variant="outlined"
                value={
                  labourData &&
                  moment(
                    labourData.personal_details[0].validity_to_date,
                  ).format('DD-MM-YYYY')
                }
                disabled
              />
            </FormControl>
          </Col>
          {schemeName === 'Continuation of Pension' && (
            <Col xs={12} md={4}>
              <p className="mb-2 mt-4">
                <Translate value="pensionNumber" />
              </p>
              <FormControl fullWidth className="formcontrol1">
                <TextareaAutosize
                  variant="outlined"
                  value={
                    labourData &&
                    (labourData.personal_details[0].pension_no ??
                      'Pension number not available for the manually updated user')
                  }
                  disabled
                />
              </FormControl>
            </Col>
          )}
          {schemeName === 'Continuation of Disability Pension' && (
            <Col xs={12} md={4}>
              <p className="mb-2 mt-4">
                <Translate value="disabilityPensionNumber" />
              </p>
              <FormControl fullWidth className="formcontrol1">
                <TextareaAutosize
                  variant="outlined"
                  value={
                    labourData &&
                    (labourData.personal_details[0].disability_pension_no ??
                      'Disability pension number not available for the manually registered user')
                  }
                  disabled
                />
              </FormControl>
            </Col>
          )}
        </Row>
      </>
    </>
  );
};

export default LabourDetails;
