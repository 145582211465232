import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, InputAdornment, Select, MenuItem, ListItemIcon, ListItemText, CircularProgress, makeStyles, Backdrop, Tooltip, Dialog, DialogTitle, DialogContent, TextareaAutosize, DialogActions, DialogContentText, Stepper, Step, StepLabel, StepContent, Typography, Paper, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import MomentUtils from '@date-io/moment';
import tick from 'assets/images/check (3) 2.svg'
import userCircle from 'assets/images/Icon awesome-user-circle.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getCityDetails, getConfigBoardDetails, getConfigMinistryDetails, getDistrictDetails, getLabourStatusDetails, getStateDetails, getTalukDetails, getVillageDetails, searchuser, SERVER_2, TS_USERNAME, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link, useParams } from 'react-router-dom';
import { decrypt, encrypt, Required, encryptAuthbridge, decryptAuthbridge, showToast, saveTokenCookie, saveLog } from 'utils/tools';
import { AccountCircle, Cached, CheckCircle, EventNote, Fastfood, FormatListBulletedRounded, Hotel, LaptopMac, PhoneAndroid, Repeat, TrendingUpRounded } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import validator from 'validator';
import axios from 'axios';
import moment from 'moment';
import cookie from 'react-cookies';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';

import male from 'assets/images/Mask Group 1.svg'
import female from 'assets/images/Mask Group 2.svg'
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg'


const SearchDataForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            // backgroundColor: '#ffffffee',
            backgroundColor: '#ffffffff',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));

    const [backdropText, setbackdropText] = React.useState("Fetching Data. Please Wait.");
    const [captcha, setcaptcha] = React.useState("");
    const [secretToken, setsecretToken] = React.useState("");
    const [tsTransID, settsTransID] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loadingVerifyBank, setLoadingVerifyBank] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [captchaDivShow, setcaptchaDivShow] = React.useState(true);
    const [count, setCount] = React.useState(true);
    const timer = React.useRef();

    const classes = useStyles();

    // const [loginData, setloginData] = React.useState({})
    // const [departmentUserRoleData, setdepartmentUserRoleData] = React.useState({})


    const [openLogin, setopenLogin] = React.useState(false)
    const handleCloseLogin = () => {
        setopenLogin(false)
    }

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,

        nameError: false,
        nameNomineeError: false,

        phoneError: false,
        phoneNomineeError: false,

        formcontrolnum: "",

        aadharnum1: "",
        aadharnum2: "",
        aadharnum3: "",
        aadhar: "",

        openDatePicker: false,

        // states: [],
        // districts: [],
        // taluks: [],
        // cities: [],
        // villages: [],
        relations: [],
        naturesOfWork: [],

        checkmarkShow: false,
        Message1: "No Labour Found!",
        Message2: "Please Try Searching With Other Mobile Number.",

        registration_code: null,

        first_name: null,
        middle_name: null,
        last_name: null,
        mobile_no: null,
        natureOfWork: null,
        dob: null,

        state: "",
        district: "",
        taluk: "",
        city: "",
        village: "",
        pincode: null,

        first_name_nominee: null,
        middle_name_nominee: null,
        last_name_nominee: null,
        relation_nominee: null,
        phone_nominee: null,

        accountNumber: null,
        ifsc: null,
        bankName: null,
        branch: null,

        captchaText: "",

        labourFound: false,

        is_aadhar_verified: false,
        is_bank_verified: false,

        aadhaarFetchedData: {},

        username: "",
        password: "",

        usernameError: false,
        usernameErrorMessage: "Please enter correct Username",
        passwordError: false,
        passwordErrorMessage: "Please enter correct Password",

        appHistory: null

    })

    const [loadingBackdrop, setLoadingBackdrop] = React.useState(true);
    const [dob, setDob] = React.useState("")
    
    const [Relations, setRelations] = React.useState({})
    const [NatureOfWork, setNatureOfWork] = React.useState({})
    
    const [states, setstates] = React.useState([])
    const [districts, setdistricts] = React.useState([])
    const [taluks, settaluks] = React.useState([])
    const [cities, setcities] = React.useState([])
    const [villages, setvillages] = React.useState([])


    const [AppHistory, setAppHistory] = React.useState({})
    
    const [checkBank, setcheckBank] = React.useState(false);

    useEffect(() => {
        console.log("useeffect dob: " + dob)
        if (allValues.getUserDetails !== undefined && allValues.getUserDetails.personal_details !== undefined) {
            // console.error("useeffect dob: " + dob)

            var dateString = dob
            var dateObj = new Date(dateString);
            var momentObj = moment(dateObj);
            // var momentString = momentObj.format('DD-MM-YYYY'); 
            var momentString = momentObj.format('YYYY-MM-DD');
            console.log("momentString: " + momentString)

            setAllValues({
                ...allValues,
                dob: momentString
            })

            // setAllValues({
            //     ...allValues,
            //     getUserDetails: {
            //         personal_details: [
            //             {
            //                 ...allValues.getUserDetails.personal_details[0],
            //                 date_of_birth: momentString
            //             }
            //         ]
            //     }
            // })
        }
    }, [dob]);

    let { id } = useParams()
    
    useEffect(() => {

        if(cookie.load('DepartmentUserJWT') === undefined){

            setopenLogin(true)
            // login_func()

        }
        else{
            // console.error("cookie.load('DepartmentUserJWT'): "+cookie.load('DepartmentUserJWT'))
            initialLoad()
        }


        // console.log("date: "+moment().toDate())
    }, [])



    const initialLoad = () => {
        // console.error("id: " + id)
            var str = id.split("mob=").pop()
            // console.error("str: " + str)

            if (str.length === 10) {
                let dataForGetUser = {
                    "key": "mobile",
                    "value": str,
                    "board_id": 1
                }
                axios.post(SERVER_2 + "/user/get-user", dataForGetUser)
                    .then((response) => {
                        console.log("get-user response: " + JSON.stringify(response))
                        console.log("response.data.data[0].id.toString(): " + response.data.data[0].id.toString())


                        let dataForgetuserdetails = {
                            "key": "user_id",
                            "value": response.data.data[0].id,
                            "board_id": 1,
                            "procedure_name": "all"
                        }
                        axios.post(SERVER_2 + "/user/get-user-registration-details", dataForgetuserdetails)
                            .then((response) => {
                                // console.log("get-user response: " + JSON.stringify(response))

                                var str = response.data.data.personal_details.aadhar_no;
                                var aadharArr = [];
                                var chunkSize = 4;
                                while (str) {
                                    if (str.length < chunkSize) {
                                        aadharArr.push(str);
                                        break;
                                    }
                                    else {
                                        aadharArr.push(str.substr(0, chunkSize));
                                        str = str.substr(chunkSize);
                                    }
                                }
                                // console.error("aadharArr: " + aadharArr)

                                // if (response.data.data.personal_details[0].is_sent_approval) {
                                if (response.data.data.personal_details[0].catalog_value_application_status !== undefined && response.data.data.personal_details[0].catalog_value_application_status === 47) {
                                    setLoadingBackdrop(false)
                                    setAllValues({
                                        ...allValues,
                                        labourFound: false,
                                        checkmarkShow: true,
                                        Message1: "Labour Details are Already Updated!",
                                        Message2: "",

                                    })
                                }
                                else {
                                    setLoadingBackdrop(false)
                                    setAllValues({
                                        ...allValues,
                                        getUserDetails: response.data.data,
                                        labourFound: true,
                                        checkmarkShow: false,
                                        Message1: "No Labour Found!",
                                        Message2: "Please Try Searching With Other Mobile Number.",

                                    })

                                }

                                getNewCaptcha()


                            })
                            .catch((error) => {
                                console.error("get-user error: " + JSON.stringify(error))
                                if(allValues.getUserDetails !== undefined){
                                saveLog(
                                    allValues.getUserDetails.personal_details[0].mobile_no,
                                    allValues.getUserDetails.personal_details[0].first_name,
                                    allValues.getUserDetails.personal_details[0].id,
                                    error,
                                    "get-user-registration-details API call"
                                    )
                                }
                                setLoadingBackdrop(false)
                            })


                    })
                    .catch((error) => {
                        console.error("get-user error: " + JSON.stringify(error))
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        setLoadingBackdrop(false)
                    })

            }
            else {
                console.error("Mobile number not 10 digits")
                setLoadingBackdrop(false)
                setAllValues({
                    ...allValues,
                    labourFound: false,
                    checkmarkShow: false,
                    Message1: "No Labour Found!",
                    Message2: "Please Try Searching With Other Mobile Number.",

                })
            }

            dispatch(getStateDetails())

            let dataForCatalog = { "board_id": 1, "catalog_name": "Relation" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog))

            let dataForCatalog2 = { "board_id": 1, "catalog_name": "Nature of Work" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog2))
    }
    
    
    const login_func = () => {

        // console.log("in login_func: hash: " + JSON.stringify(users.generate_otp.data.hash))
        // const hash = users.generate_otp.data.hash

        if (allValues.username !== "" && allValues.password !== "") {
            
            var data = JSON.stringify({
                "username": allValues.username,
                "password": allValues.password
              });
              var config = {
                method: 'post',
                url: SERVER_2+'/auth/username-login',
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log("login response:"+JSON.stringify(response.data));
                
                var departmentUserRoleData = null

                var cookiejwt = response.data.jwt
                // console.error("cookiejwt: "+ cookiejwt)

                var data2 = JSON.stringify({
                    "id": response.data.data.department_user_id
                  });
                  
                  var config2 = {
                    method: 'post',
                    url: SERVER_2+'/admin/get-dept-user-role',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${cookiejwt}` 
                    },
                    data : data2
                  };
                  axios(config2)
                  .then(function (response2) {
                    console.log("get-dept-user-role response: "+JSON.stringify(response2.data));
                    console.log("response2.data.data[0]: "+JSON.stringify(response2.data.data[0]));

                    departmentUserRoleData = response2.data.data[0]

                  })
                  .catch(function (error) {
                    console.error("get-dept-user-role error: "+error);
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error
                        )}
                  });

                  
                //   setdepartmentUserRoleData(departmentUserRoleData)

                // setAllValues({
                //     ...allValues,
                //     loginData: response.data.data,
                //     departmentUserRoleData: departmentUserRoleData
                // })

                cookie.save('DepartmentUserJWT', response.data.jwt, { path: '/' })
                cookie.save('LoginData', JSON.stringify(response.data.data), { path: '/' })
                showToast("SUCCESS", "Login Successfull!")
                setopenLogin(false)
                // setloginData(response.data.data)
                initialLoad()

              })
              .catch(function (error) {
                  
                if(error.response !== undefined){
                    console.error("login error:"+error);
                    console.error("login error:"+JSON.stringify(error.response));
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error.response
                        )}
                    showToast("ERROR", error.response.data.message)

                    if(error.response.data.message === "No User found"){
                        setAllValues({
                            ...allValues,
                            usernameError: true,
                            usernameErrorMessage: "User Not Found",
                        })
                    }
                    else if(error.response.data.message === "Invalid Password"){
                        setAllValues({
                            ...allValues,
                            passwordError: true,
                            passwordErrorMessage: "Invalid Password",
                        })
                    }
                }

              });

        }
        else if (allValues.username === '') {
            setAllValues({
                ...allValues,
                usernameError: true,
                usernameErrorMessage: "Please enter correct Username",
            })
        }
        else if (allValues.password === '') {
            setAllValues({
                ...allValues,
                passwordError: true,
                passwordErrorMessage: "Please enter correct Password",
            })
        }
        else {
            setAllValues({
                ...allValues,
                usernameError: true,
                usernameErrorMessage: "Some Error Occured!",
                passwordError: true
            })
        }

    }

    // useEffect(() => {
    //     // console.log("allValues: " + JSON.stringify(allValues))
    //     console.log("loginData: " + JSON.stringify(loginData, undefined, 2))
    // }, [loginData]);


    useEffect(() => {
        // console.log("allValues: " + JSON.stringify(allValues))
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
    }, [allValues]);

    useEffect(() => {
        console.log("count: " + count)

        if (
            allValues.getUserDetails !== null &&
            allValues.getUserDetails !== undefined &&
            allValues.getUserDetails.personal_details !== undefined &&
            allValues.getUserDetails.personal_details.length > 0 && count
        ) {
            var aadharcheck = false
            var first_name = allValues.getUserDetails.personal_details[0].first_name
            var middle_name = allValues.getUserDetails.personal_details[0].middle_name
            var last_name = allValues.getUserDetails.personal_details[0].last_name

            var nameArr = allValues.getUserDetails.personal_details[0].first_name.split(' ')

            if (nameArr.length === 1) {
                setCount(false)
                first_name = nameArr[0]
            }
            else if (nameArr.length === 2) {
                setCount(false)
                first_name = nameArr[0]
                middle_name = nameArr[1]
            }
            else if (nameArr.length >= 3) {
                setCount(false)
                first_name = nameArr[0]
                middle_name = nameArr[1]
                last_name = nameArr[2]
            }


            if(allValues.getUserDetails.personal_details[0].date_of_birth !== undefined && allValues.getUserDetails.personal_details[0].date_of_birth !== null){
                var dateString = allValues.getUserDetails.personal_details[0].date_of_birth
                var dateObj = new Date(dateString);
                var momentObj = moment(dateObj);
                // var momentString = momentObj.format('DD-MM-YYYY'); 
                var momentString = momentObj.format('YYYY-MM-DD');
                console.log("momentString: " + momentString)

                setDob(momentString)
                // moment(new Date()).format("YYYY-MM-DD")
            }

            if (allValues.getUserDetails.personal_details[0].is_aadhar_verified) {
                aadharcheck = true
                setcaptchaDivShow(false)
            }

            if (allValues.getUserDetails.personal_details[0].is_bank_verified) {
                setcheckBank(true)
            }



            var registration_code = allValues.getUserDetails.personal_details[0].registration_code
            var mobile_no = allValues.getUserDetails.personal_details[0].mobile_no
            var nature_of_work_id = allValues.getUserDetails.personal_details[0].nature_of_work_id

            var aadhar_no = allValues.getUserDetails.personal_details[0].aadhar_no
            var aadharnum1 = null
            var aadharnum2 = null
            var aadharnum3 = null

            if(aadhar_no !== undefined && aadhar_no !== null){
                // console.log("aadhar_no.length:" + aadhar_no.length)
                if (aadhar_no.length === 12) {

                    var numArr = aadhar_no.match(/.{1,4}/g);

                    // console.log("aadhar:" + numArr)
                    aadharnum1 = numArr[0]
                    aadharnum2 = numArr[1]
                    aadharnum3 = numArr[2]
                }
            }

            var first_name_nominee = null
            var middle_name_nominee = null
            var last_name_nominee = null
            var relation_nominee = null
            if (allValues.getUserDetails.family_details !== undefined &&
                allValues.getUserDetails.family_details.length > 0) {

                first_name_nominee = allValues.getUserDetails.family_details[0].first_name
                middle_name_nominee = allValues.getUserDetails.family_details[0].middle_name
                last_name_nominee = allValues.getUserDetails.family_details[0].last_name
                relation_nominee = allValues.getUserDetails.family_details[0].catalog_value_parent_child_type_id

            }


            var account_no = null
            var ifsc_code = null
            var bank_name = null
            var bank_branch = null
            if (allValues.getUserDetails.bank_details !== undefined &&
                allValues.getUserDetails.bank_details.length > 0) {

                account_no = allValues.getUserDetails.bank_details[0].account_no
                ifsc_code = allValues.getUserDetails.bank_details[0].ifsc_code
                bank_name = allValues.getUserDetails.bank_details[0].bank_name
                bank_branch = allValues.getUserDetails.bank_details[0].bank_branch

            }


            var state = null
            var district = null
            var taluk = null
            var city = null
            var village = null
            var pincode = null
            if (allValues.getUserDetails.address_details !== undefined &&
                allValues.getUserDetails.address_details.length > 0) {

                state = allValues.getUserDetails.address_details[0].state_id
                district = allValues.getUserDetails.address_details[0].district_id
                taluk = allValues.getUserDetails.address_details[0].taluk_id
                city = allValues.getUserDetails.address_details[0].city_town_gram_panchayat_id
                village = allValues.getUserDetails.address_details[0].village_area_id
                pincode = allValues.getUserDetails.address_details[0].pin_code

            }

            // var dataHistory = JSON.stringify({
            //     "labour_user_id": 1817
            // });

            // var cookiejwt = response.data.jwt
            var cookiejwt = cookie.load('DepartmentUserJWT')
            // console.error("cookiejwt: "+ cookiejwt)

            var configHistory = {
            method: 'get',
            url: SERVER_2+'/user/get_application_history?labour_user_id='+allValues.getUserDetails.personal_details[0].id,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookiejwt}` 
            },
            // data : dataHistory
            };

            axios(configHistory)
            .then(function (responseHistory) {
                setAppHistory(responseHistory.data)
                console.log("responseHistory: "+JSON.stringify(responseHistory.data,undefined,2));
                // AppHistory = responseHistory.data.data
            })
            .catch(function (errorHistory) {
                console.error("errorHistory: "+errorHistory);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    errorHistory
                    )}
            })
                

            setAllValues({
                ...allValues,
                // states: allValues.states,
                aadharcheck: aadharcheck,
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                mobile_no: mobile_no,
                registration_code: registration_code,
                natureOfWork: nature_of_work_id,
                aadhar_no: aadhar_no,
                aadhar: aadhar_no,

                aadharnum1: aadharnum1,
                aadharnum2: aadharnum2,
                aadharnum3: aadharnum3,

                accountNumber: account_no,
                ifsc: ifsc_code,
                bankName: bank_name,
                branch: bank_branch,

                first_name_nominee: first_name_nominee,
                middle_name_nominee: middle_name_nominee,
                last_name_nominee: last_name_nominee,
                relation_nominee: relation_nominee,

                state: state,
                district: district,
                taluk: taluk,
                city: city,
                village: village,
                pincode: pincode,
            })


            // setAllValues({
            //     ...allValues,
            //     aadharcheck: aadharcheck,
            //     getUserDetails: {
            //         personal_details: [
            //             {
            //                 ...allValues.getUserDetails.personal_details[0],
            //                 first_name: first_name,
            //                 middle_name: middle_name,
            //                 last_name: last_name
            //             }
            //         ]
            //     }

            // })



        }
    }, [allValues.getUserDetails])

    useEffect(() => {
        if (users.state_details !== null && users.state_details.error !== undefined && users.state_details.error !== null && users.state_details.error === true && allValues.getUserDetails !== undefined) {
            console.error("ERROR: " + JSON.stringify(users.state_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.state_details.errors
                )}
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.state_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.state_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.state_details.errors
                )}
            showToast("ERROR", "Network Error, Please Try Again Later!")
        }
        else if (users.state_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setDIstrict(users.district_details)
            // setAllValues({
            //     ...allValues,
            //     states: users.state_details
            // })
            setstates(users.state_details)
        }
    }, [users.state_details]);

    useEffect(() => {
        console.log("allValues.state: " + allValues.state)
        if (allValues.state !== "" && allValues.state !== null){
            dispatch(getDistrictDetails(allValues.state))}
    }, [allValues.state]);

    useEffect(() => {
        console.log("in useEffect users.district_details", JSON.stringify(users.district_details))
        if (users.district_details !== null && users.district_details.error !== undefined && users.district_details.error !== null && users.district_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.district_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.district_details.errors
                )}

            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.district_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.district_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.district_details.errors
                )}
            showToast("ERROR", "Network Error, Please Try Again Later!")
        }
        else if (users.district_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setdistricts(users.district_details)
            // setAllValues({
            //     ...allValues,
            //     districts: users.district_details
            // })
        }
    }, [users.district_details]);

    useEffect(() => {
        if (allValues.district !== "" && allValues.district !== null){
            dispatch(getTalukDetails(allValues.district))}
    }, [allValues.district]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.taluk_details !== null && users.taluk_details.error !== undefined && users.taluk_details.error !== null && users.taluk_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.taluk_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.taluk_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.taluk_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.taluk_details.errors
                )}
            showToast("ERROR", "Network Error, Please Try Again Later!")
        }
        else if (users.taluk_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            settaluks(users.taluk_details)
            // setAllValues({
            //     ...allValues,
            //     taluks: users.taluk_details,
            //     // alltaluk: allValues.taluk.concat(users.taluk_details)
            // })
        }
    }, [users.taluk_details]);

    useEffect(() => {
        if (allValues.taluk !== "" && allValues.taluk !== null){
            dispatch(getCityDetails(allValues.taluk))}

    }, [allValues.taluk]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.city_details !== null && users.city_details.error !== undefined && users.city_details.error !== null && users.city_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.city_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.city_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.city_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.city_details.errors
                )}
            showToast("ERROR", "Network Error, Please Try Again Later!")
        }
        else if (users.city_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setcities(users.city_details)
            // setAllValues({
            //     ...allValues,
            //     cities: users.city_details
            // })
        }
    }, [users.city_details]);

    useEffect(() => {
        if (allValues.city !== "" && allValues.city !== null ){
            dispatch(getVillageDetails(allValues.city))}

    }, [allValues.city]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.village_details !== null && users.village_details.error !== undefined && users.village_details.error !== null && users.village_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.village_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.village_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.village_details.errors))
            if(allValues.getUserDetails !== undefined){
            saveLog(
                allValues.getUserDetails.personal_details[0].mobile_no,
                allValues.getUserDetails.personal_details[0].first_name,
                allValues.getUserDetails.personal_details[0].id,
                users.village_details.errors
                )}
            showToast("ERROR", "Network Error, Please Try Again Later!")
        }
        else if (users.village_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setvillages(users.village_details)
            // setAllValues({
            //     ...allValues,
            //     villages: users.village_details
            // })
        }
    }, [users.village_details]);

    useEffect(() => {
        console.log("in useEffect [users.catalog_details]", JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Relation") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setRelations(users.catalog_details)
            // setAllValues({
            //     ...allValues,
            //     relations: users.catalog_details
            // });
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Nature of Work") {
            // console.error("elseif category: " + JSON.stringify(users.catalog_details))
            setNatureOfWork(users.catalog_details)
            // setAllValues({
            //     ...allValues,
            //     naturesOfWork: users.catalog_details
            // });
        }
    }, [users.catalog_details]);



    const handleChange = (e) => {
        // console.log(e.target)
        console.log(e.target.name + " : " + e.target.value)

        var nameError = false
        var nameNomineeError = false
        var phoneError = false
        var phoneNomineeError = false

        if (e.target.name === "name") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isAlpha(e.target.value))
                nameError = false
            else
                nameError = true
        }
        if (e.target.name === "name_nominee") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isAlpha(e.target.value))
                nameNomineeError = false
            else
                nameNomineeError = true
        }
        if (e.target.name === "phone") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                phoneNomineeError = false
            else
                phoneNomineeError = true
        }
        if (e.target.name === "phone_nominee") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                phoneNomineeError = false
            else
                phoneNomineeError = true
        }

        var usernameError = null
        var passwordError = null

        if (e.target.name === "username" || e.target.name === "password") {
            usernameError = false
            passwordError = false
        }

        setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
            usernameError: usernameError,
            passwordError: passwordError
        })

        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }
    const handleChangePersonal = (e) => {
        // console.log(e.target)
        console.log(e.target.name + " : " + e.target.value)

        var nature_of_work = null

        if (e.target.value === "false") {
            // setAllValues({
            //     ...allValues,
            //     getUserDetails: {
            //         personal_details: [
            //             ...allValues.getUserDetails.personal_details,
            //             {
            //                 [e.target.name]: ""
            //             }
            //         ]
            //     }
            // })
        }
        else {

            if (e.target.name === "nature_of_work_id") {
                nature_of_work = NatureOfWork[e.target.value - 1].value
            }
            // setAllValues({
            //     ...allValues,
            //     getUserDetails: {
            //         personal_details: [
            //             {
            //                 ...allValues.getUserDetails.personal_details[0],
            //                 [e.target.name]: e.target.value,
            //                 nature_of_work: nature_of_work
            //             }
            //         ]
            //     }
            // })

        }
    }

    const handleAadharChange = (e, num) => {
        // console.log('handleAadharChange num:' + num)
        // console.log(e.target.value)
        // console.log(e.target.value.length)

        if (e.target.value.length === 4) {
            const nextSibling = document.querySelector(
                `.aadhardiv${num + 1}>div>input`
            );
            if (num === 1) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum1: e.target.value,
                    })
                }
            }
            else if (num === 2) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum2: e.target.value,
                    })
                }
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                    aadhar: allValues.aadharnum1 + allValues.aadharnum2 + e.target.value,
                });
            }
        }
        else {
            // console.log("num:"+num)
            if (num === 1) {
                setAllValues({
                    ...allValues,
                    aadharnum1: e.target.value,
                })
            }
            else if (num === 2) {
                setAllValues({
                    ...allValues,
                    aadharnum2: e.target.value,
                })
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                })
            }
        }
    }

    const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

    const [tsTransIDAfterOtp, settsTransIDAfterOtp] = React.useState("");
    const [openBackdrop, setOpenBackdrop] = React.useState(true);
    const [otpSent, setotpSent] = React.useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    

    const verifyBank = () => {
        if(
            allValues.accountNumber !== null &&
            allValues.ifsc !== null &&
            allValues.accountNumber !== "" &&
            allValues.ifsc !== "" &&
            allValues.accountNumber !== "null" &&
            allValues.ifsc !== "null"
            ){
            setLoadingVerifyBank(true)

            var randNum = Math.floor(100000 + Math.random() * 900000)
            console.log("randNum: " + randNum);

            var data = JSON.stringify({
                "transID": randNum,
                "docType": "92",
                "beneAccNo": allValues.accountNumber,
                "ifsc": allValues.ifsc
              });
              
              var config = {
                method: 'post',
                url: SERVER_2+'/global/encrypt_string',
                headers: { 
                  'Content-Type': 'application/json', 
                  'username': TS_USERNAME
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log("bank step1 response: "+JSON.stringify(response.data, undefined, 2));

                var data2 = JSON.stringify({
                    "requestData": response.data.data
                  });
                  var config2 = {
                    method: 'post',
                    url: SERVER_2+'/global/auth_bridge_bank_request',
                    headers: { 
                      'Content-Type': 'application/json', 
                      'username': TS_USERNAME
                    },
                    data : data2
                  };
                  
                  axios(config2)
                  .then(function (response2) {
                    console.log("bank step2 response: "+JSON.stringify(response2.data, undefined, 2));

                    var data3 = JSON.stringify({
                        "responseData": response2.data.data.responseData
                      });
                      var config3 = {
                        method: 'post',
                        url: SERVER_2+'/global/decrypt_string',
                        headers: { 
                          'Content-Type': 'application/json', 
                          'username': TS_USERNAME
                        },
                        data : data3
                      };
                      
                      axios(config3)
                      .then(function (response3) {
                        console.log("bank step3 response: "+JSON.stringify(response3.data,undefined,2));


                        if(response3.data.data.msg.description === "Success" && response3.data.data.msg.status === "Verified" ){
                            showToast("SUCCESS", "Bank Account Verified Successfully")
                            setLoadingVerifyBank(false)
                            setcheckBank(true)
                            setAllValues({
                                ...allValues,
                                
                            })
                            setAllValues({
                                ...allValues,
                                bankFetchedData: response3.data.data.msg,
                                is_bank_verified: true
                            })
                        }
                        else{
                            console.error("bank step3 fail");
                            if(allValues.getUserDetails !== undefined){
                            saveLog(
                                allValues.getUserDetails.personal_details[0].mobile_no,
                                allValues.getUserDetails.personal_details[0].first_name,
                                allValues.getUserDetails.personal_details[0].id,
                                response3.data.data.msg
                                )}
                            setLoadingVerifyBank(false)
                            showToast("ERROR", "Bank Verification Failed:  "+response3.data.data.msg.status)
                        }

                      })
                      .catch(function (error3) {
                        console.error("bank step3 error: "+error3);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error3
                            )}
                        setLoadingVerifyBank(false)
                        showToast("ERROR", "Some error occurred while verifying bank details!")
                      });
                      
                  })
                  .catch(function (error2) {
                    console.error("bank step2 error: "+error2);
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error2
                        )}

                    setLoadingVerifyBank(false)
                    showToast("ERROR", "Some error occurred while verifying bank details!")
                  });
              })
              .catch(function (error) {
                console.error("bank step1 error: "+error);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    error
                    )}
                setLoadingVerifyBank(false)
                showToast("ERROR", "Some error occurred while verifying bank details!")
              });

        }
        else{
            showToast("ERROR", "Please fill Correct Bank Account Details !")
                                   
        }
    }


    const handleValidateClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);

            var randNum = Math.floor(100000 + Math.random() * 900000)
            console.log("randNum: " + randNum);


            var data = JSON.stringify({
                "transID": randNum,
                "docType": 347,
                "docNumber": allValues.aadhar,
                "secretToken": secretToken,
                "tsTransID": tsTransID,
                "captchaCode": allValues.captchaText,
                "actionType": "otp",
                "consent": 1
            });

            var config = {
                method: 'post',
                url: SERVER_2 + '/global/encrypt_string',
                headers: {
                    'Content-Type': 'application/json',
                    'username': TS_USERNAME
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log("step4 response: " + JSON.stringify(response.data));

                    var data = JSON.stringify({
                        "requestData": response.data.data
                    });
                    var config = {
                        method: 'post',
                        url: SERVER_2 + '/global/auth_bridge_id_search',
                        headers: {
                            'Content-Type': 'application/json',
                            'username': TS_USERNAME
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log("step5 response: " + JSON.stringify(response.data));

                            var data = JSON.stringify({
                                "responseData": response.data.data.responseData
                            });

                            var config = {
                                method: 'post',
                                url: SERVER_2 + '/global/decrypt_string',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'username': TS_USERNAME
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    console.log("step6 response: " + JSON.stringify(response.data))

                                    if (response.data.data.msg === "OTP sent successfully") {
                                        setLoading(false)
                                        setotpSent(true)

                                        settsTransIDAfterOtp(response.data.data.tsTransID)
                                    }
                                    else {
                                        setLoading(false)
                                        showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                                    }

                                })
                                .catch(function (error) {
                                    setLoading(false)
                                    console.error("step6 error:" + error);
                                    if(allValues.getUserDetails !== undefined){
                                    saveLog(
                                        allValues.getUserDetails.personal_details[0].mobile_no,
                                        allValues.getUserDetails.personal_details[0].first_name,
                                        allValues.getUserDetails.personal_details[0].id,
                                        error
                                        )}
                                    showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                                });
                        })
                        .catch(function (error) {
                            setLoading(false)
                            console.error("step5 error:" + error);
                            showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                        });
                })
                .catch(function (error) {
                    setLoading(false)
                    console.error("step4 error: " + error);
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error
                        )}
                    showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                });



            // timer.current = window.setTimeout(() => {
            // setSuccess(true);
            // setLoading(false);
            // }, 2000);
        }
    }

    const validateAadhaar = () => {

        var data = JSON.stringify({
            "transID": tsTransIDAfterOtp,
            "shareCode": "1234",
            "mobileCode": allValues.otp
        });

        var config = {
            method: 'post',
            url: SERVER_2 + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step7 response: " + JSON.stringify(response.data));

                var data = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER_2 + '/global/auth_bridge_aadhar_request',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        console.log("step8 response: " + JSON.stringify(response.data));

                        var data = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER_2 + '/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step9 response: " + JSON.stringify(response.data));

                                if (response.data.data.status === "1") {
                                    showToast("SUCCESS", "Aadhar Verified Successfully")
                                    setcaptchaDivShow(false)
                                    setotpSent(false)
                                    // setAllValues({
                                    //     ...allValues,
                                    //     aadharcheck: true,
                                    //     is_aadhar_verified: true,
                                    //     aadhaarFetchedData: response.data.data.msg
                                    // })
                                    setAllValues({
                                        ...allValues,
                                        aadharcheck: true,
                                        aadhaarFetchedData: response.data.data.msg,
                                        is_aadhar_verified: true
                                    })
                                }

                            })
                            .catch(function (error) {
                                console.error("step9 error: " + error);
                                if(allValues.getUserDetails !== undefined){
                                saveLog(
                                    allValues.getUserDetails.personal_details[0].mobile_no,
                                    allValues.getUserDetails.personal_details[0].first_name,
                                    allValues.getUserDetails.personal_details[0].id,
                                    error
                                    )}
                            });

                    })
                    .catch(function (error) {
                        console.error("step8 error: " + error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                    });

            })
            .catch(function (error) {
                console.error("step7 error: " + error);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    error
                    )}
            });
    }


    const getNewCaptcha = () => {

        setcaptcha("")
        setsecretToken("")
        settsTransID("")


        var randNum = Math.floor(100000 + Math.random() * 900000)
        console.log("randNum: " + randNum);

        var data = JSON.stringify({
            "transID": randNum,
            "docType": "347"
        });
        var config = {
            method: 'post',
            url: SERVER_2 + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step1 response:" + JSON.stringify(response.data));


                // STEP 2
                var data2 = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER_2+'/global/auth_bridge_generate_captcha',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data2
                };

                axios(config)
                    .then(function (response) {
                        console.log("step2 response:" + JSON.stringify(response.data));

                        //step3

                        var data3 = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER_2+'/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data3
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step3 response: " + JSON.stringify(response.data));
                                setcaptcha(response.data.data.msg.captchaCode)
                                // setAllValues({
                                //     ...allValues,
                                //     secretToken: response.data.data.msg.secretToken,
                                //     tsTransID: response.data.data.msg.tsTransID,
                                // })
                                setsecretToken(response.data.data.msg.secretToken)
                                settsTransID(response.data.data.msg.tsTransID)
                            })
                            .catch(function (error) {
                                console.error("step3 error: " + error);
                                if(allValues.getUserDetails !== undefined){
                                saveLog(
                                    allValues.getUserDetails.personal_details[0].mobile_no,
                                    allValues.getUserDetails.personal_details[0].first_name,
                                    allValues.getUserDetails.personal_details[0].id,
                                    error
                                    )}
                            });

                    })
                    .catch(function (error) {
                        console.error("step2 error: " + error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        setLoading(false);
                    });

            })
            .catch(function (error) {
                console.error("step1 error: " + error);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    error
                    )
                }
                setLoading(false);
            });
    }

    const [openSaveDraft, setopenSaveDraft] = React.useState(false);
    const handleClickOpenSaveDraft = () => {
        setopenSaveDraft(true);
    };
    const handleCloseSaveDraft = () => {
        setopenSaveDraft(false);
    };
    const handleClickSaveDraft = () => {
        setopenSaveDraft(false)
        setLoadingBackdrop(true)
        setbackdropText("Saving Details. Please Wait!")
        
        updateApplicationHistory()
        
        // timer.current = window.setTimeout(() => {
            //     setLoadingBackdrop(false)
            // }, 2000);
        };
    
    
    const [openFinalSubmit, setopenFinalSubmit] = React.useState(false)
    const handleClickOpenFinalSubmit = () => {
        setopenFinalSubmit(true);
    }
    const handleCloseFinalSubmit = () => {
        setopenFinalSubmit(false);
    }


    const submitPersonalDetails = () => {

        return new Promise((resolve, reject) => {
            // console.log('SON')
            var val = allValues.getUserDetails.personal_details[0]
            // console.log('SON2', val)
            var data = {
                "user_id": val.id,
                "first_name": allValues.first_name,
                "first_name_kannada": val.first_name_kannada,
                "first_name_hindi": val.first_name_hindi,
                "middle_name_kannada": val.middle_name_kannada,
                "middle_name_hindi": val.middle_name_hindi,
                "middle_name": allValues.middle_name,
                "last_name": allValues.last_name,
                "last_name_kannada": val.last_name_kannada,
                "last_name_hindi": val.last_name_hindi,
                "email": val.mail_id,
                "aadhar_no": allValues.aadhar,
                "pan_no": val.pan_no,
                "martial_status_id": val.catalog_value_marital_status_id,
                "caste_id": val.caste_id,
                "gender_id": allValues.gender,
                "is_inter_state_migrant": val.is_inter_state_migrant,
                "migrate_from_state_id": val.migrate_from_state_id,
                "user_photo_id": val.user_photo_id,
                "dob": allValues.dob,
                "registration_code": allValues.registration_code,
                "nature_of_work": allValues.natureOfWork,
                "is_aadhar_verified": allValues.is_aadhar_verified,
                "is_bank_verified": allValues.is_bank_verified
            }
            // console.log('SON 3')
            // console.error("data for personal: " + data)

            var config = {
                method: 'post',
                url: SERVER_2 + '/user/add-labour-personal-details',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    resolve(response)
                })
                .catch(function (error) {
                    console.error(error);
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error
                        )}
                    reject(error)
                });
        })
    }

    const submitAddressDetails = () => {

        return new Promise((resolve, reject) => {

            var val = allValues.getUserDetails.personal_details[0]
            var data = {
                "user_id": val.id,
                "addresses": [
                    {
                        "address_base_type_id": 10,
                        "address_type_id": 59,
                        "door_no": 0,
                        "street_name": null,
                        "village_area_id": allValues.village,
                        "city_town_gram_panchayat_id": allValues.city,
                        "taluk_id": allValues.taluk,
                        "district_id": allValues.district,
                        "state_id": allValues.state,
                        "land_mark": null,
                        "pin_code": allValues.pincode
                    }
                ]
            }

            // console.error("data for address: " + data)

            if (allValues.getUserDetails.address_details.length === 0) {
                var config = {
                    method: 'post',
                    url: SERVER_2 + '/user/add-labour-address',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }
            else {
                var config = {
                    method: 'patch',
                    url: SERVER_2 + '/user/update-labour-address',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }






        })
    }

    const submitNomineeDetails = () => {

        return new Promise((resolve, reject) => {


            if (allValues.getUserDetails.family_details.length === 0) {

                var val = allValues.getUserDetails.personal_details[0]
                var data = {
                    "user_id": val.id,
                    "ration_card_no": null,
                    "ration_card_image_id": null,
                    "ration_card_type": null,
                    "families": [
                        {
                            "relation_type_id": allValues.relation_nominee,
                            "first_name": allValues.first_name_nominee,
                            "last_name": allValues.last_name_nominee,
                            "middle_name": allValues.middle_name_nominee,
                            "aadhar_no": null,
                            "dob": "1900-01-01",
                            "profession": 0,
                            "education": 0,
                            "is_nominee": 1,
                            "is_registered": 0,
                            "registeration_no": allValues.registration_code,
                            "labour_user_id": val.id
                        }
                    ]
                }
                // console.error("data for nominee: " + data)

                var config = {
                    method: 'post',
                    url: SERVER_2 + '/user/add-labour-family-details',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }
            else {
                var val = allValues.getUserDetails.personal_details[0]
                var famval = allValues.getUserDetails.family_details[0]

                var data = {
                    "user_id": val.id,
                    "ration_card_no": null,
                    "ration_card_image_id": null,
                    "ration_card_type": null,
                    "families": [
                        {
                            "relation_id": famval.relation_id,
                            "labour_user_id": famval.labour_user_id,
                            "parent_child_user_id": famval.parent_child_user_id,
                            "catalog_value_parent_child_type_id": famval.catalog_value_parent_child_type_id,
                            "isinsert": 0,
                            "isdeleted": 1,
                            "isactive": 0,
                            "relation_type_id": famval.catalog_value_parent_child_type_id,
                            "first_name": famval.first_name,
                            "last_name": famval.last_name,
                            "middle_name": famval.middle_name,
                            "aadhar_no": famval.aadhar_no,
                            "dob": famval.date_of_birth,
                            "profession": famval.catalog_value_profession_id,
                            "education": famval.catalog_value_qualification_id,
                            "is_nominee": 1,
                            "is_registered": famval.is_regisrered_user,
                            "registeration_no": famval.registration_code
                        },
                        {
                            "relation_id": 0,
                            "relation_type_id": allValues.relation_nominee,
                            "isinsert": 1,
                            "isdeleted": 0,
                            "isactive": 1,
                            "parent_child_user_id": 0,
                            "catalog_value_parent_child_type_id": 0,
                            "first_name": allValues.first_name_nominee,
                            "last_name": allValues.last_name_nominee,
                            "middle_name": allValues.middle_name_nominee,
                            "aadhar_no": null,
                            "dob": "1900-01-01",
                            "profession": 0,
                            "education": 0,
                            "is_nominee": 1,
                            "is_registered": 0,
                            "registeration_no": null,
                            "labour_user_id": val.id
                        }
                    ]
                }
                // console.error("data for nominee update: " + data)

                var config = {
                    method: 'patch',
                    url: SERVER_2 + '/user/update-labour-family-details',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }






        })
    }

    const submitBankDetails = () => {

        return new Promise((resolve, reject) => {

            var val = allValues.getUserDetails.personal_details[0]
            var data = {
                "user_id": val.id,
                "banks": [
                    {
                        "labour_user_id": val.id,
                        "nominee_for_user_id": null,
                        "nominee_first_name": allValues.first_name,
                        "nominee_middle_name": allValues.middle_name,
                        "nominee_last_name": allValues.last_name,
                        "ifsc_code": allValues.ifsc,
                        "bank_name": allValues.bankName,
                        "bank_branch": allValues.branch,
                        "bank_address": null,
                        "account_no": allValues.accountNumber,
                        "bank_passbook_upload_id": null,
                        "is_nominee": false
                    }
                ]
            }
            // console.error("data for bank add: " + data)

            if (allValues.getUserDetails.bank_details.length === 0) {
                var config = {
                    method: 'post',
                    url: SERVER_2 + '/user/add-labour-bank-details',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }
            else {
                var config = {
                    method: 'patch',
                    url: SERVER_2 + '/user/update-labour-bank-details',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.error(error);
                        if(allValues.getUserDetails !== undefined){
                        saveLog(
                            allValues.getUserDetails.personal_details[0].mobile_no,
                            allValues.getUserDetails.personal_details[0].first_name,
                            allValues.getUserDetails.personal_details[0].id,
                            error
                            )}
                        reject(error)
                    });
            }






        })
    }

    const updateApplicationHistory = () => {
        // let promises = [ submitPersonalDetails(), submitAddressDetails(),  submitBankDetails() ];
        let promises = [];
        promises.push(submitPersonalDetails())

        if (
            allValues.state !== null &&
            allValues.district !== null &&
            allValues.taluk !== null &&
            allValues.city !== null &&
            allValues.village !== null &&
            allValues.pincode !== null
        ) {
            promises.push(submitAddressDetails())
        }
        
        if (
            allValues.relation_nominee !== null
        ) {
            promises.push(submitNomineeDetails())
        }

        promises.push(submitBankDetails())

        console.log("promises: " + promises)

        Promise.all(promises).then((results) => {

            console.log("results: " + JSON.stringify(results))
            // console.log("loginData: " + JSON.stringify(loginData))
            // console.log("departmentUserRoleData: " + JSON.stringify(departmentUserRoleData))
            
            console.log("cookie.load('LoginData'): " + cookie.load('LoginData'))
            console.log("cookie.load('LoginData') JSON: " + JSON.stringify(cookie.load('LoginData')))
            
            var loginData = cookie.load('LoginData')

            var data = JSON.stringify({
                "labour_user_id": allValues.getUserDetails.personal_details[0].id,
                "application_status": 45,
                "description": allValues.description,
                "department_user_id": loginData.department_user_id
            });

            var config = {
                method: 'post',
                url: SERVER_2 + '/user/update_application_history',
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));

                    showToast("SUCCESS", "Details Saved Successfully!")

                    setLoadingBackdrop(false)
                    setAllValues({
                        ...allValues,
                        labourFound: false,
                        checkmarkShow: true,
                        Message1: "Details Saved Successfully!",
                        Message2: "",

                    })
                })
                .catch(function (error) {
                    console.error(error);
                    if(allValues.getUserDetails !== undefined){
                    saveLog(
                        allValues.getUserDetails.personal_details[0].mobile_no,
                        allValues.getUserDetails.personal_details[0].first_name,
                        allValues.getUserDetails.personal_details[0].id,
                        error
                        )}
                    setLoadingBackdrop(false)
                    showToast("Error", "Some error occured. Plaese try saving again.")
                });


        })
            .catch(err => {
                // err of responses
                setLoadingBackdrop(false)
                showToast("ERROR", "Some Error Occurred, Please Try Again Later!")
            })
    }

    const SubmitButtonPressed = () => {
        console.log("in SubmitButtonPressed")
        if (
            allValues.registration_code === null ||
            allValues.first_name === null ||
            // allValues.middle_name === null ||
            allValues.last_name === null ||
            allValues.mobile_no === null ||
            allValues.natureOfWork === null ||
            allValues.dob === null ||

            allValues.state === null ||
            allValues.district === null ||
            allValues.taluk === null ||
            allValues.city === null ||
            allValues.village === null ||
            allValues.pincode === null ||
            
            allValues.first_name_nominee === null ||
            // allValues.middle_name_nominee === null ||
            allValues.last_name_nominee === null ||
            allValues.relation_nominee === null ||
            // allValues.phone_nominee === null ||

            allValues.accountNumber === null ||
            allValues.ifsc === null ||
            allValues.bankName === null ||
            allValues.branch === null ||

            allValues.aadhar === null ||
            allValues.gender === null ||


            allValues.registration_code === "" ||
            allValues.first_name === "" ||
            // allValues.middle_name === "" ||
            allValues.last_name === "" ||
            allValues.mobile_no === "" ||
            allValues.natureOfWork === "" ||
            allValues.dob === "" ||

            allValues.state === "" ||
            allValues.district === "" ||
            allValues.taluk === "" ||
            allValues.city === "" ||
            allValues.village === "" ||
            allValues.pincode === "" ||
            
            allValues.first_name_nominee === "" ||
            // allValues.middle_name_nominee === "" ||
            allValues.last_name_nominee === "" ||
            allValues.relation_nominee === "" ||
            // allValues.phone_nominee === "" ||

            allValues.accountNumber === "" ||
            allValues.ifsc === "" ||
            allValues.bankName === "" ||
            allValues.branch === "" ||

            allValues.aadhar === "" ||
            allValues.gender === ""


        ) {
            console.log("in if")
            showToast("ERROR", "Please fill all the required fields!")
        }
        else {
            console.log("in else")
            // finalUpdate()
            // handleClickOpenFinalSubmit()
            setopenFinalSubmit(true)
        }
    }

    const finalUpdate1 = () => {
            // let promises = [ submitPersonalDetails(), submitAddressDetails(),  submitBankDetails() ];
            let promises = [];
            promises.push(submitPersonalDetails())
    
            if (
                allValues.state !== null &&
                allValues.district !== null &&
                allValues.taluk !== null &&
                allValues.city !== null &&
                allValues.village !== null &&
                allValues.pincode !== null
            ) {
                promises.push(submitAddressDetails())
            }
            
            if (
                allValues.relation_nominee !== null
            ) {
                promises.push(submitNomineeDetails())
            }
    
            promises.push(submitBankDetails())
    
            console.log("promises: " + promises)
    
            Promise.all(promises).then((results) => {
    
                console.log("results: " + JSON.stringify(results))
                // console.log("loginData: " + JSON.stringify(loginData))
                // console.log("departmentUserRoleData: " + JSON.stringify(departmentUserRoleData))
                
                finalUpdate()
    
    
            })
                .catch(err => {
                    // err of responses
                    setLoadingBackdrop(false)
                    showToast("ERROR", "Some Error Occurred, Please Try Again Later!")
                })
        
    }
    const finalUpdate = () => {

        setopenFinalSubmit(false)

        var val = allValues.getUserDetails.personal_details[0]

        var loginData = cookie.load('LoginData')

        var data = JSON.stringify({
            "labour_user_id": val.id,
            "application_status": 47,
            "description": allValues.description_final,
            "department_user_id": loginData.department_user_id
        });
        var config = {
            method: 'post',
            url: SERVER_2 + '/user/update_application_history',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.error(error);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    error
                    )}
            });


        // Review and submit API call

        var data1 = JSON.stringify({
            "user_id": val.id
        });
        var config1 = {
            method: 'post',
            url: SERVER_2 + '/user/final-submit',
            data: data1
        };

        axios(config1)
            .then(function (response) {
                console.log(JSON.stringify(response.data));

                showToast("SUCCESS", "Details Saved Successfully!")

                setLoadingBackdrop(false)
                setAllValues({
                    ...allValues,
                    labourFound: false,
                    checkmarkShow: true,
                    Message1: "Details Saved Successfully!",
                    Message2: "",

                })
            })
            .catch(function (error) {
                console.error(error);
                if(allValues.getUserDetails !== undefined){
                saveLog(
                    allValues.getUserDetails.personal_details[0].mobile_no,
                    allValues.getUserDetails.personal_details[0].first_name,
                    allValues.getUserDetails.personal_details[0].id,
                    error
                    )}
                showToast("Error", "Some error occured. Plaese try saving again.")
            });


    }

    const logoutClicked = () => {

        cookie.remove('DepartmentUserJWT', { path: '/' })
        cookie.remove('LoginData', { path: '/' })
        setLoadingBackdrop(true)
        setbackdropText("Fetching Data. Please Wait.")
        setopenLogin(true)
        // setAllValues({
        //     ...allValues,
        //     labourFound: false,
        // })

    }

    const [openHistory, setOpenHistory] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const handleClickOpenHistory = () => {
        setOpenHistory(true);
      };
    const handleCloseHistory = () => {
        setOpenHistory(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (openHistory) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [openHistory]);

    React.useEffect(() => {
        console.log("in useEffect Apphistory: "+JSON.stringify(AppHistory,undefined,2))
    }, [AppHistory]);

    

    return (
        <>

            <Dialog
                    open={openHistory}
                    onClose={handleCloseHistory}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Application History</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >

                    {/* <Stepper 
                    orientation="vertical"
                    >
                            {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                <Typography>{getStepContent[index]}</Typography>
                                
                                </StepContent>
                            </Step>
                            ))}
                    </Stepper> */}

                    <Timeline align="alternate">
                    {
                        AppHistory.data !== undefined && 
                        AppHistory.data.length > 0 ?
                                AppHistory.data.map((i, index) => {
                                    return (
                                    <>
                                    <TimelineItem>
                                        <TimelineOppositeContent>
                                            <Typography className="typographyBy" variant="body2" color="textSecondary">
                                                {
                                                moment.utc(i.added_date).format('LLL')
                                                }
                                            </Typography>
                                        </TimelineOppositeContent>

                                        <TimelineSeparator>
                                            <TimelineDot color="primary">
                                                <EventNote />
                                            </TimelineDot>
                                            {
                                                index === AppHistory.data.length - 1 ?
                                                <>
                                                </>
                                                :
                                                <TimelineConnector />
                                            }
                                        </TimelineSeparator>

                                        <TimelineContent>
                                            <Paper elevation={3} className={classes.paper} style={{padding: '6px 16px', textAlign: 'left', marginBottom: "35px"}}>
                                                <Typography variant="h6" component="h1">
                                                <b>{i.comment}</b>
                                                </Typography>
                                                <br/>
                                                <Typography className="typographyBy" 
                                                // style={{fontSize: '14px!important', color: "gray"}}
                                                >
                                                - {i.status_added_by}
                                                </Typography>
                                            </Paper>
                                        </TimelineContent>

                                    </TimelineItem>
                                    </>
                                    )
                                
                                })
                        :
                        <p>No History Yet !</p>
                    }
                        
                        </Timeline>





                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseHistory} color="primary">
                        Okay
                    </Button>
                    </DialogActions>
            </Dialog>


            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                aria-labelledby="confirmation-dialog-title"
                open={openLogin}
                onClose={handleCloseLogin}
            >
            {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"> */}
                <DialogTitle id="form-dialog-title">LOGIN</DialogTitle>
                <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <TextField
                    autoFocus
                    margin="dense"
                    label="Username"
                    type="text"
                    style={{ width: "500px" }}
                    fullWidth
                    name="username"
                    value={allValues.username}
                    onChange={handleChange}
                    error={allValues.usernameError}
                    helperText={allValues.usernameError ? allValues.usernameErrorMessage : ""}
                />
                <br/>
                <TextField
                    margin="dense"
                    label="Password"
                    type="password"
                    style={{ width: "500px" }}
                    fullWidth
                    name="password"
                    value={allValues.password}
                    onChange={handleChange}
                    error={allValues.passwordError}
                    helperText={allValues.passwordError ? allValues.passwordErrorMessage : ""}
                />
                </DialogContent>
                <DialogActions>
                {/* <Button onClick={handleCloseLogin} variant="outline-secondary">
                    Cancel
                </Button> */}
                <Button onClick={login_func} variant="primary">
                    Signin
                </Button>
                </DialogActions>
            </Dialog>


            {/* <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                aria-labelledby="confirmation-dialog-title"
                open={openFinalSubmit}
                onClose={handleCloseFinalSubmit}
            >
                <DialogTitle id="alert-dialog-title">{"Final Submit"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to Submit all the details?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseFinalSubmit} color="primary">
                    No
                </Button>
                <Button onClick={finalUpdate} color="primary" autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog> */}


            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                aria-labelledby="confirmation-dialog-title"
                open={openSaveDraft}
                onClose={handleCloseSaveDraft}
            >
                <DialogTitle id="form-dialog-title">Save As Draft</DialogTitle>
                <DialogContent>
                    <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={3}
                        style={{ width: "500px" }}
                        placeholder="Remarks"
                        name="description"
                        value={allValues.description}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSaveDraft} variant="outline-secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSaveDraft} variant="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                aria-labelledby="confirmation-dialog-title"
                open={openFinalSubmit}
                onClose={handleCloseFinalSubmit}
            >
                <DialogTitle id="alert-dialog-title">{"Final Submit"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Submit all the details?
                    </DialogContentText>
                    <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={3}
                        style={{ width: "500px" }}
                        placeholder="Remarks"
                        name="description_final"
                        value={allValues.description_final}
                        onChange={handleChange}
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFinalSubmit} variant="outline-secondary">
                        Cancel
                    </Button>
                    <Button onClick={finalUpdate1} variant="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div div2">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col> */}
                    </Row>

                </Row>
            </div>
            {
                loadingBackdrop ?
                    <Backdrop className={classes.backdrop} open={openBackdrop}
                        onClick={loadingBackdrop ? null : handleCloseBackdrop}
                    >
                        <div style={{ display: "block" }}>
                            <CircularProgress color="inherit" />
                            <p className="backdropText">{backdropText}</p>
                        </div>
                    </Backdrop>
                    : null
            }




            {
                !allValues.labourFound ?
                    <>
                        <div className="errormsg">
                            {allValues.checkmarkShow ?
                                <>
                                    <CheckCircle style={{ color: '#6CC17A', height: "auto", width: "150px" }} />
                                    <br />
                                </>
                                :
                                null
                            }
                            {allValues.Message1}<br />
                            {allValues.Message2}<br />
                            {/* No Labour Found! <br />
                            Please Try Searching With Another Mobile Number. */}
                        </div>
                    </>
                    :
                    <>
                        <Row className="search-title-row">
                            <Col xs={8}>
                                <h1>Construction Workers Details</h1>
                            </Col>

                            <Col xs={2}>
                                <div 
                                >
                                    <Link to="#" onClick={handleClickOpenHistory}>
                                        Application History
                                    </Link>
                                </div>
                            </Col>
                            <Col xs={2} className="userDropdown" >

                                <Select
                                    className="select-language"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    labelId="demo-simple-select-required-label"
                                    value=""
                                    displayEmpty
                                >
                                    <MenuItem value="" >
                                        <ListItemIcon>
                                            <img alt="..." src={userCircle} className="language-img" />
                                        </ListItemIcon>
                                        {
                                            cookie.load('LoginData') !== undefined ?
                                            <ListItemText primary={cookie.load('LoginData').first_name} />
                                            :
                                            null
                                        }
                                    </MenuItem>
                                    <MenuItem value="1" >
                                        <Link to="#" onClick={logoutClicked}>
                                            Logout
                                        </Link>
                                    </MenuItem>

                                </Select>
                            </Col>
                        </Row>

                        <Row className="search-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Registration Details</p>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 config-form searchform">
                            <Col xs={12} md={6}>
                                <Required className="mb-2" title="Registration Number" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Your Registration Number"
                                        name="registration_code"
                                        // value={allValues.getUserDetails.personal_details.length>0 && allValues.getUserDetails.personal_details[0].registration_code}
                                        value={allValues.registration_code}
                                        onChange={handleChange}
                                    // onFocus={handleFormFocus()}
                                    />
                                </FormControl>
                            </Col>

                        </Row>


                        <Row className="search-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Personal Details</p>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 config-form searchform">

                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="First Name" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Your First Name"
                                        name="first_name"
                                        // value={allValues.getUserDetails !== null && allValues.getUserDetails.personal_details[0].first_name}
                                        value={allValues.first_name}
                                        onChange={handleChange}
                                    // error={allValues.nameError}
                                    // helperText={allValues.nameError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                {/* <Required className="mb-2" title="Middle Name" /> */}
                                <p className="mb-2 mt-4">Middle Name </p>
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Your Middle Name"
                                        name="middle_name"
                                        // value={allValues.getUserDetails !== null && allValues.getUserDetails.personal_details[0].middle_name}
                                        value={allValues.middle_name}
                                        onChange={handleChange}
                                    // error={allValues.nameError}
                                    // helperText={allValues.nameError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Last Name" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Your Last Name"
                                        name="last_name"
                                        // value={allValues.getUserDetails !== null && allValues.getUserDetails.personal_details[0].last_name}
                                        value={allValues.last_name}
                                        onChange={handleChange}
                                    // error={allValues.nameError}
                                    // helperText={allValues.nameError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <Required className="mb-2 mt-4" title="Mobile Number" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol2">
                                    <TextField
                                        type="phone"
                                        name="mobile_no"
                                        // value={allValues.getUserDetails !== null && allValues.getUserDetails.personal_details[0].mobile_no}
                                        value={allValues.mobile_no}
                                        onChange={handleChange}
                                        // error={allValues.phoneError}
                                        // helperText={allValues.phoneError ? "Please enter correct phone number" : ""}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <img src={mobile} alt="..." className="phone-icon" />
                                                    <p className="countrycode">+91</p>
                                                </InputAdornment>
                                        }}
                                        variant="outlined"
                                        disabled
                                    ></TextField>
                                </FormControl>
                            </Col>

                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="Nature of Work" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        // value={allValues.natureOfWork}
                                        // value={allValues.getUserDetails !== null && allValues.getUserDetails.personal_details[0].nature_of_work_id}
                                        value={allValues.natureOfWork}
                                        onChange={handleChange}
                                        // onChange={handleChangePersonal}
                                        name="natureOfWork"
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="Select" />
                                        </MenuItem>
                                        {NatureOfWork.length > 0 && NatureOfWork.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="Date of Birth" />
                                <MuiPickersUtilsProvider utils={MomentUtils} >
                                    <KeyboardDatePicker
                                        maxDate={new Date()}
                                        onClick={() => setPickerStatus(true)}
                                        onClose={() => setPickerStatus(false)}
                                        open={allValues.openDatePicker}
                                        className="datepicker"
                                        margin="normal"
                                        format="D/MM/yyyy"
                                        value={dob}
                                        onChange={(date) => { setDob(date) }}
                                        placeholder="DD/MM/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>

                            <Col xs={12} md={6}>
                                {/* <p className="mt-4 mb-2">AADHAAR Card Number*</p> */}
                                <Required className="mt-4 mb-2" title="AADHAAR Card Number" />
                                <div className="aadhar-div singlepage">
                                    <img alt="..." />
                                    <FormControl className="formcontrol7 aadharouter">
                                        <TextField
                                            className="aadhardiv1"
                                            variant="outlined"
                                            placeholder="1234"
                                            onChange={(e) => { handleAadharChange(e, 1) }}
                                            value={allValues.aadharnum1}
                                            disabled={allValues.is_aadhar_verified}
                                            // onFocus={handleFormFocus("aadhar1")}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                        />
                                    </FormControl>
                                    <FormControl className="formcontrol7 aadharouter">
                                        <TextField
                                            className="aadhardiv2 "
                                            variant="outlined"
                                            placeholder="2345"
                                            onChange={(e) => { handleAadharChange(e, 2) }}
                                            value={allValues.aadharnum2}
                                            disabled={allValues.is_aadhar_verified}
                                            // onFocus={handleFormFocus("aadhar2")}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                        />
                                    </FormControl>
                                    <FormControl className="formcontrol7 aadharouter">
                                        <TextField
                                            className="aadhardiv3 "
                                            variant="outlined"
                                            placeholder="3456"
                                            onChange={(e) => { handleAadharChange(e, 3) }}
                                            value={allValues.aadharnum3}
                                            disabled={allValues.is_aadhar_verified}
                                            // onFocus={handleFormFocus("aadhar3")}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                        />
                                    </FormControl>
                                    {
                                        allValues.aadharcheck ?
                                            (<img alt="..." src={checkgreen} />)
                                            :
                                            (
                                                <div className="checkDiv">
                                                    <img alt="..." src={checkgrey} />
                                                    {/* <Button
                                                variant="outline-primary"
                                                // color="primary"
                                                // className={buttonClassname}
                                                disabled={loading}
                                                onClick={handleValidateClick}
                                            >
                                                Verify
                                        </Button>
                                            {loading && <CircularProgress size={24} className="buttonProgress" />} */}

                                                </div>
                                            )
                                    }
                                </div>


                                {
                                    captchaDivShow ?
                                        <div className="otp-div singlepage mt-3 ">
                                            <div className="captchaDiv">
                                                <Tooltip title="Get New Captcha">
                                                    <Cached className="refreshIcon" onClick={getNewCaptcha} />
                                                </Tooltip>
                                                <img className="captchaImg"
                                                    src={`data:image/png;base64,${captcha}`}
                                                />

                                            </div>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter Captcha"
                                                name="captchaText"
                                                value={allValues.captchaText}
                                                onChange={handleChange}
                                                style={{
                                                    maxHeight: "46px",
                                                    alignSelf: "center"
                                                }}
                                            />
                                            {/* <Button
                                // variant="light"
                                variant="outline-primary"
                                // color="primary"
                                // className={buttonClassname}
                                disabled={loading}
                                onClick={handleValidateClick}
                                >
                                Send OTP
                            </Button> */}

                                            <div className="checkDiv">
                                                <Button
                                                    variant="outline-primary"
                                                    // color="primary"
                                                    // className={buttonClassname}
                                                    disabled={loading}
                                                    onClick={handleValidateClick}
                                                >
                                                    Verify
                        </Button>
                                                {loading && <CircularProgress size={24} className="buttonProgress" />}

                                            </div>
                                        </div>

                                        :
                                        null
                                }

                                {
                                    otpSent ?
                                        <>
                                            <div className="otp-div mt-3">
                                                <TextField
                                                    fullWidth
                                                    label="Enter OTP"
                                                    name="otp"
                                                    value={allValues.otp}
                                                    onChange={handleChange} />
                                                <Button
                                                    variant="light"
                                                    // color="primary"
                                                    // className={buttonClassname}
                                                    disabled={loading}
                                                    onClick={validateAadhaar}
                                                >
                                                    Submit
                                </Button>
                                            </div>
                                        </>
                                        :
                                        null

                                }

                            </Col>

                            <Col xs={12} md={6}>
                            <Required className="mt-5 mb-0" title="Select Your Gender" />
                            <div className="gender-div">
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="27" id="male"
                                        onChange={handleChange} checked={allValues.gender === "27"} />
                                    <label
                                        htmlFor="male"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                                        <img alt="..." src={male} />
                                        <span>Male</span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="28" id="female" onChange={handleChange} checked={allValues.gender === "28"} />
                                    <label
                                        htmlFor="female"
                                    >
                                        <img alt="..." src={female} />
                                        <span>Female</span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="29" id="others" onChange={handleChange} checked={allValues.gender === "29"} />
                                    <label
                                        htmlFor="others"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                                        <img alt="..." src={radioon} />
                                        <span>Others</span>
                                    </label>
                                </div>
                            </div>
                            <div className="gender-below-border"></div>
                            </Col>

                            {
                                // console.log("Object.keys(props.files).length: "+Object.keys(allValues.aadhaarFetchedData).length)
                                Object.keys(allValues.aadhaarFetchedData).length > 0 ?
                                    <>
                                        <Col xs={12} md={6}
                                            style={{ marginTop: "30px", border: "solid 1px green", padding: "10px" }}
                                        >
                                            {/* Object.keys(allValues.aadhaarFetchedData).map((key) => { */}
                                            {/* <> */}
                                            <p style={{ color: "green", textAlign: "center" }}>Fetched Details from AADHAAR</p>
                                            <p>Name : {allValues.aadhaarFetchedData.Name}</p>
                                            <p>DOB : {allValues.aadhaarFetchedData.DOB}</p>
                                            <p>Gender : {allValues.aadhaarFetchedData.Gender}</p>
                                            <p>State : {allValues.aadhaarFetchedData.State}</p>
                                            <p>District : {allValues.aadhaarFetchedData.District}</p>
                                            {/* </> */}


                                            {/* }) */}
                                        </Col>
                                    </>
                                    :
                                    null
                            }

                        </Row>

                        <Row className="search-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Address Details</p>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 config-form searchform">

                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="State" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.state}
                                        name="state"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {states.length > 0 && states.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="District" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.district}
                                        name="district"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {districts.length > 0 && districts.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="Taluk" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.taluk}
                                        name="taluk"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {taluks.length > 0 && taluks.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="City" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.city}
                                        name="city"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {cities.length > 0 && cities.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="Village" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.village}
                                        name="village"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {villages.length > 0 && villages.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Pincode" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Pincode"
                                        name="pincode"
                                        value={allValues.pincode}
                                        onChange={handleChange}
                                    // error={allValues.nameNomineeError}
                                    // helperText={allValues.nameNomineeError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>


                        </Row>

                        <Row className="search-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Nominee Details</p>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 config-form searchform">

                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Nominee's First Name" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Nominee's First Name"
                                        name="first_name_nominee"
                                        value={allValues.first_name_nominee}
                                        onChange={handleChange}
                                    // error={allValues.nameNomineeError}
                                    // helperText={allValues.nameNomineeError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                {/* <Required className="mb-2" title="Nominee's Mi Name" /> */}
                                <p className="mb-2 mt-4">Nominee's Middle Name </p>
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Nominee's Middle Name"
                                        name="middle_name_nominee"
                                        value={allValues.middle_name_nominee}
                                        onChange={handleChange}
                                    // error={allValues.nameNomineeError}
                                    // helperText={allValues.nameNomineeError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Nominee's Last Name" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Nominee's Last Name"
                                        name="last_name_nominee"
                                        value={allValues.last_name_nominee}
                                        onChange={handleChange}
                                    // error={allValues.nameNomineeError}
                                    // helperText={allValues.nameNomineeError ? "Please enter correct name" : ""}
                                    />
                                </FormControl>
                            </Col>
                            {/* <Col xs={12} md={6}>
                                <Required className="mb-2 mt-4" title="Nominee Mobile Number" />
                                <FormControl fullWidth className="formcontrol2">
                                    <TextField
                                        name="phone_nominee"
                                        value={allValues.phone_nominee}
                                        type="phone"
                                        onChange={handleChange}
                                        error={allValues.phoneNomineeError}
                                        helperText={allValues.phoneNomineeError ? "Please enter correct phone number" : ""}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <img src={mobile} alt="..." className="phone-icon" />
                                                    <p className="countrycode">+91</p>
                                                </InputAdornment>
                                        }}
                                        variant="outlined"
                                    ></TextField>
                                </FormControl>
                            </Col> */}
                            <Col xs={12} md={6} >
                                <Required className="mt-4 mb-2" title="Relationship" />
                                <FormControl variant="outlined" fullWidth className="formcontrol5">
                                    <Select
                                        className="select-marital"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={allValues.relation_nominee}
                                        name="relation_nominee"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="Select" />
                                        </MenuItem>
                                        {Relations.length > 0 && Relations.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                            </Col>

                        </Row>

                        <Row className="search-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Bank Details</p>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 config-form searchform">
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Account Number" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Account Number"
                                        name="accountNumber"
                                        value={allValues.accountNumber}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={5} >
                                <Required className="mb-2 mt-4" title="IFSC Code" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter IFSC Code"
                                        name="ifsc"
                                        value={allValues.ifsc}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={1} >
                                {/* <Button variant="outline-primary" style={{marginTop: "35px"}}
                                    onClick={verifyBank}>
                                    Verify
                                </Button> */}
                                <div className="checkDiv">
                                    
                                    {
                                        checkBank ? 
                                        <>
                                            <Tooltip title="Bank Account is Verified !">
                                                <img alt="..." src={checkgreen} style={{marginTop: "60px"}}/>
                                            </Tooltip>
                                        </>
                                        :
                                        <Button
                                            variant="outline-primary"
                                            disabled={loadingVerifyBank}
                                            onClick={verifyBank}
                                            style={{marginTop: "60px"}}
                                        >
                                            
                                            { loadingVerifyBank ? <>Verifying...</> : <>Verify</>}
                                        </Button>
                                    }
                                    {loadingVerifyBank && 
                                    <CircularProgress size={24} style={{marginTop: "60px"}} className="buttonProgress" />}
                                </div>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Bank Name" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Bank Name"
                                        name="bankName"
                                        value={allValues.bankName}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <Required className="mb-2 mt-4" title="Branch" />
                                {/* <p className="mb-2">Enter Your Full Name </p>  */}
                                <FormControl fullWidth className="formcontrol1">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Branch"
                                        name="branch"
                                        value={allValues.branch}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Col>


                        </Row>


                        <Row className="search-subtitle-row pb-5">
                            {/* <Col xs={12} className="searchform-subtitle">
                        <div className="submit-button-div centerdiv">
                            <Link to="#"
                                // onClick={nextButtonPressed}
                                // style={{ flexGrow: "1" }}
                            >
                                <Button variant="outline-primary" className="next-button">
                                    Submit
                                </Button>
                            </Link>
                        </div>
                    </Col> */}

                            <Col xs={12} className="next-back-button-row mt-4">

                                <Link to="#"
                                    // onClick={props.backButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                >
                                    <Button variant="outline-primary" className="back-button"
                                        onClick={handleClickOpenSaveDraft}>
                                        Save Draft
                            </Button>
                                </Link>

                                <Link to="#"
                                    // onClick={SubmitButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                >
                                    <Button variant="outline-primary" className="next-button"
                                    onClick={SubmitButtonPressed}
                                    >
                                        Submit
                            </Button>
                                </Link>

                            </Col>
                        </Row>
                    </>
            }


        </>
    )
}

export default SearchDataForm;