import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const EKYCWEBHOOK = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_ekyc_token`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserOnVaultReferenceNumber = async (payLoad) => {
  try {
    const { data } = await axios.post(`${SERVER}/user/get-user`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAadhaarDetails = async (vaultRefNumber) => {
  try {
    const { data } = await axios.get(
      console.log('vaultRefNumber', vaultRefNumber),
      `${SERVER}/user/get_ad_details/${vaultRefNumber}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateNameAndAaadhaarVerified = async (payload) => {
  try {
    const { data } = await axios.put(`${SERVER}/seva_sindhu/aadhar`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
