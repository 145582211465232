import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Col, Row } from 'react-bootstrap';

import Moment from 'react-moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import { useSelector } from 'react-redux';
import Backdrop from '../../loadingScreen/Backdrop';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Link } from 'react-router-dom';

const SchemeSanctionOrder = () => {
  const {
    schemeId,
    schemeStatus,
    schemeAvailId,
    laboutUserId,
    labourName,
    appliedDate,
  } = useParams();
  console.log(schemeAvailId, 'schemeAvailId');

  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schemeData, setSchemeData] = useState(null);
  const [statusApproved, setStatusApproved] = useState(null);
  const [signatureData, setSignatureData] = useState(null);

  const users = useSelector((state) => state.users);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${SERVER}/schemes/get_scheme_status?schme_avail_id=${schemeAvailId}`,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        },
      )
      .then((response) => {
        setSchemeData(response.data);
        setStatusApproved(response.data.data.recordset[0].final_status);
        // setSignatureID(response.data.data.recordsets[1][1].signature_upload_id);
      })
      .catch((error) => {
        console.log('Error fetching scheme status:', error);
      })
      .finally(() => setLoading(false));
  }, [schemeAvailId]);

  useEffect(() => {
    const payLoad = JSON.stringify({
      board_id: 1,
      labour_user_id: laboutUserId,
    });

    axios
      .post(SERVER + '/schemes/get_schemes_by_labor', payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((response) => {
        setResponseData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [laboutUserId]);
  // console.log('statusApproved', statusApproved);

  useEffect(() => {
    if (schemeData) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${schemeData.data.recordsets[1][1].signature_upload_id}`,
        )
        .then((res) => {
          setSignatureData(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [schemeData]);

  var circleName = '';
  if (
    users.getUserRegistrationDetails.certificate_details[0].inspector_circle !==
      '' &&
    users.getUserRegistrationDetails.certificate_details[0].inspector_circle !==
      null
  ) {
    circleName =
      users.getUserRegistrationDetails.certificate_details[0].inspector_circle;
  }

  if ((responseData && responseData.length < 1) || statusApproved === null) {
    // return <div>Loading...</div>;
    return <Backdrop open={loading} />;
  }

  return (
    <div className="w-100 p-5">
      <Link to="/admin/dashboard/3" className="cal-icon-hover-icn">
        <img alt="..." className="cal-icon hover-icn" src={back} />
      </Link>
      <Box>
        {statusApproved && statusApproved === 'Approved' ? (
          <div>
            <Paper elevation={3}>
              <Box textAlign="center">
                <img
                  src={logo}
                  alt="logo"
                  height={'80'}
                  style={{ marginLeft: '50px' }}
                />
                <Typography variant="h5">
                  ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕ ಕ ಇ
                  ನಿ ಕಾ ಕ ಮಂ)
                </Typography>
                <Typography variant="h5">
                  Karnataka Building & Other Construction Workers Welfare Board
                  (K B O C W W B)
                </Typography>
                <Row className="ack-subtitle-row">
                  <Col className="profile-title" style={{ display: 'block' }}>
                    <h1>ಮಂಜೂರಾತಿ ಆದೇಶ/ Sanction Order</h1>
                  </Col>
                </Row>
              </Box>
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        ಕಟ್ಟಡ ಕಾರ್ಮಿಕರ ನೋಂದಣಿ ಸಂಖ್ಯೆ/ Registration Date
                      </TableCell>
                      <TableCell>
                        {
                          users?.getUserRegistrationDetails?.personal_details[0]
                            ?.registration_code
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ನೋಂದಣಿ ಸಂಖ್ಯೆ/Application Reference Number
                      </TableCell>
                      <TableCell>
                        {schemeData &&
                          schemeData?.data?.recordsets[1][0]
                            ?.scheme_application_code}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ಅರ್ಜಿದಾರರ ಹೆಸರು/Name of the Applicant
                      </TableCell>
                      <TableCell>{labourName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ಅರ್ಜಿಯ ದಿನಾಂಕ/Date of Application</TableCell>
                      <TableCell>
                        {appliedDate && (
                          <Moment format="DD MMMM YYYY">{appliedDate}</Moment>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ಯೋಜನೆ/ಸೇವೆಯ ಹೆಸರು/Scheme Service Name
                      </TableCell>
                      <TableCell>
                        {schemeData &&
                          schemeData?.data?.recordsets[1][0]?.scheme_name}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} align="justify">
                        {schemeData?.data?.recordsets[1][0]?.scheme_name}{' '}
                        ಯೋಜನೆಯನ್ನು ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು
                        ಮಾಡಲಾಗಿದೆ ಹಾಗು ಮಂಜೂರಾದ ಮೊತ್ತ{' '}
                        {schemeData?.data.recordsets[1][1].sanctioned_Amount}{' '}
                        ಆಗಿರುತ್ತದೆ
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="d-flex justify-content-between align-items-center">
                <div className="self-start">
                  <div className="px-3">
                    <p>ವೃತ್ತದ ಹೆಸರು / Circle Name :{circleName} </p>
                    <p>
                      ಮಂಜೂರಾತಿ ದಿನಾಂಕ / Date of Sanction :{' '}
                      <Moment format="DD MMMM YYYY">
                        {schemeData &&
                          schemeData.data.recordsets[1][1].approved_on}
                      </Moment>
                    </p>
                  </div>
                </div>
                <div className="p-3">
                  <div className="w-50">
                    <img
                      width={'200'}
                      height={'100'}
                      src={`data:image/jpeg;base64,${signatureData}`}
                      alt="signature"
                      style={{ border: '2px solid grey' }}
                    />
                    <p className="text-center">
                      {' '}
                      ಮಂಜೂರಾತಿ ಅಧಿಕಾರಿ <br /> (Signature OF LO / ALC){' '}
                    </p>

                    <p className="text-center">
                      Karmika Bhavan, Dairy Circle Bannerghata Road,
                      Bangalore-29
                    </p>
                    <p className="text-center"> (Office Address) </p>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        ) : (
          <div>
            <Paper elevation={3}>
              <Box textAlign="center">
                <img
                  src={logo}
                  alt="logo"
                  height={'80'}
                  style={{ marginLeft: '50px' }}
                />
                <Typography variant="h5">
                  ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕ ಕ ಇ
                  ನಿ ಕಾ ಕ ಮಂ)
                </Typography>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Karnataka Building & Other Construction Workers Welfare Board
                  (K B O C W W B)
                </Typography>
                <Row className="ack-subtitle-row">
                  <Col className="profile-title" style={{ display: 'block' }}>
                    <h1>ನಿರಾಕರಣೆ ಆದೇಶ/ Rejection Order</h1>
                  </Col>
                </Row>
              </Box>
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        ಕಟ್ಟಡ ಕಾರ್ಮಿಕರ ನೋಂದಣಿ ಸಂಖ್ಯೆ/ Registration Date
                      </TableCell>
                      <TableCell>
                        {
                          users?.getUserRegistrationDetails?.personal_details[0]
                            ?.registration_code
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ನೋಂದಣಿ ಸಂಖ್ಯೆ/Application Reference Number
                      </TableCell>
                      <TableCell>
                        {schemeData &&
                          schemeData?.data?.recordsets[1][0]
                            ?.scheme_application_code}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ಅರ್ಜಿದಾರರ ಹೆಸರು/Name of the Applicant
                      </TableCell>
                      <TableCell>{labourName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ಅರ್ಜಿಯ ದಿನಾಂಕ/Date of Application</TableCell>
                      <TableCell>
                        {appliedDate && (
                          <Moment format="DD MMMM YYYY">{appliedDate}</Moment>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        ಯೋಜನೆ/ಸೇವೆಯ ಹೆಸರು/Scheme Service Name
                      </TableCell>
                      <TableCell>
                        {schemeData &&
                          schemeData?.data?.recordsets[1][0]?.scheme_name}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} align="justify">
                        <Row className="sanction-lines ">
                          {schemeData &&
                            schemeData?.data?.recordsets[1][0]
                              ?.scheme_name}{' '}
                          ಯೋಜನೆಯನ್ನು ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು
                          ತಿರಸ್ಕರಿಸಲಾಗಿದೆ
                        </Row>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="d-flex justify-content-between align-items-center">
                <div className="self-start">
                  <div className="px-3">
                    <p>ವೃತ್ತದ ಹೆಸರು / Circle Name :{circleName} </p>
                    <p>
                      ನಿರಾಕರಣೆ ದಿನಾಂಕ / Date of Rejection :{' '}
                      <Moment format="DD MMMM YYYY">
                        {schemeData &&
                          schemeData.data.recordsets[1][1].approved_on}
                      </Moment>
                    </p>
                  </div>
                </div>
                <div className="p-3">
                  <div className="w-50">
                    <img
                      width={'200'}
                      height={'100'}
                      src={`data:image/jpeg;base64,${signatureData}`}
                      alt="signature"
                      style={{ border: '2px solid grey' }}
                    />
                    <p className="text-center">
                      {' '}
                      ಮಂಜೂರಾತಿ ಅಧಿಕಾರಿ <br /> (Signature OF LO / ALC){' '}
                    </p>

                    <p className="text-center">
                      Karmika Bhavan, Dairy Circle Bannerghata Road,
                      Bangalore-29
                    </p>
                    <p className="text-center"> (Office Address) </p>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        )}
      </Box>
    </div>
  );
};

export default SchemeSanctionOrder;
