import {
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';
import { DataContext } from 'context/config';

const MajorAilmentsAssistance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  loading,
  kannadaSelected,
}) => {
  const { data: configData } = useContext(DataContext);

  console.log(formData?.['Hospital/Clinic name']);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3">
            Please wait fetching fields....
          </p>
        </div>
      ) : formData && formData['Person Hospitalized- Selection'] === 175 ? (
        dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map((field) => (
          <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
            <Required
              className="mb-2 mt-4"
              title={
                kannadaSelected ? field.field_name_kannada : field.field_name
              }
            />
            {field.field_type === 'Text' && (
              <>
                {field.field_name !== 'Name of the applicant' && (
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      type={'text'}
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                      variant="outlined"
                      // helperText={allValues['error_' + field.id]}
                      // error={allValues.globalError}
                      disabled={
                        field.field_name === 'Duration of treatment' ||
                        field.field_name === 'Dependent Date of Birth'
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                )}
                {field.field_name === 'Name of the applicant' && (
                  <>
                    {formData &&
                      formData['Person Hospitalized- Selection'] === 174 && (
                        <FormControl fullWidth className="formcontrol1">
                          <TextField
                            type={'text'}
                            name={field.field_name}
                            onChange={(ev) =>
                              handleChange(ev, field, field.field_name)
                            }
                            disabled
                            value={
                              formData &&
                              formData['Person Hospitalized- Selection'] ===
                                174 &&
                              formData[field.field_name]
                            }
                            variant="outlined"

                            // helperText={allValues['error_' + field.id]}
                            // error={allValues.globalError}
                            // disabled={
                            //   field.field_name.includes(
                            //     'Name of the dependent ',
                            //   ) && allValues.medicalAssistanceSelf
                            // }
                          />
                        </FormControl>
                      )}
                    {formData &&
                      formData['Person Hospitalized- Selection'] === 175 && (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            name={field.field_name}
                            onChange={(ev) =>
                              handleChange(ev, field, field.field_name)
                            }
                            value={formData && formData[field.field_name]}
                          >
                            <MenuItem value="">
                              <ListItemText primary="Select" />
                            </MenuItem>
                            {familyDetails &&
                              familyDetails.length &&
                              familyDetails.map((fam) => (
                                <MenuItem
                                  value={`${fam?.first_name?.trim() ?? ''} ${
                                    fam?.middle_name?.trim() ?? ''
                                  } ${fam?.last_name?.trim() ?? ''}`}
                                >
                                  <ListItemText
                                    primary={`${
                                      fam?.first_name?.trim() ?? ''
                                    } ${fam?.middle_name?.trim() ?? ''} ${
                                      fam?.last_name?.trim() ?? ''
                                    }`}
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                  </>
                )}
              </>
            )}

            {field.field_type === 'Date' &&
              (field.field_name === 'Date of admission to Hospital /Clinic' ? (
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment('2023-10-17', 'YYYY-MM-DD').subtract(
                        6,
                        'months',
                      )}
                      maxDate={new Date()}
                      // onClick={(e) => onChangeDate(e, field)}
                      // onClose={() => setPickerStatus(false)}
                      // open={allValues.openDatePicker}
                      className="datepicker"
                      margin="normal"
                      format="DD/MM/YYYY"
                      name={field.field_name}
                      // id={id}
                      value={
                        formData && formData[field.field_name]
                          ? formData[field.field_name]
                          : null
                      }
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      // onChange={setDate}
                      placeholder="DD/MM/YYYY"
                      // error={allValues.globalError}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              ) : field.field_name ===
                'Date of Discharge from Hospital/Clinic' ? (
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment(
                        formData &&
                          formData[`Date of admission to Hospital /Clinic`],
                      )}
                      maxDate={new Date()}
                      // onClick={(e) => onChangeDate(e, field)}
                      // onClose={() => setPickerStatus(false)}
                      // open={allValues.openDatePicker}
                      className="datepicker"
                      margin="normal"
                      format="DD/MM/YYYY"
                      name={field.field_name}
                      // id={id}
                      value={
                        formData && formData[field.field_name]
                          ? formData[field.field_name]
                          : null
                      }
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      disabled={
                        formData &&
                        formData[`Date of admission to Hospital /Clinic`]
                          ? false
                          : true
                      }
                      // onChange={setDate}
                      placeholder="DD/MM/YYYY"
                      // error={allValues.globalError}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              ) : null)}

            {(field.field_type === 'Dropdown' ||
              field.field_type === 'Radio Button') &&
              (field.field_name === 'Hospital/Clinic name' ? (
                <>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={(formData && formData[field.field_name]) || '0'}
                    >
                      <MenuItem value="0">
                        <ListItemText primary={`Select`} />
                      </MenuItem>
                      {field.field_data
                        .filter((field) => {
                          const treatmentValue =
                            formData[
                              'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                            ];

                          const treatmentType = formData['Treatment type'];

                          if (treatmentType === 173) {
                            return true;
                          } else {
                            return field.field_value !== 'Other';
                          }
                        })
                        .map((field) => (
                          <MenuItem
                            key={field.field_value_id}
                            value={field.field_value_id}
                          >
                            <ListItemText primary={field.field_value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formData['Hospital/Clinic name'] ==
                    configData?.otherHospitalIdForMajorMedicalScheme && (
                    <>
                      <Required
                        className="mb-2 mt-4"
                        title={
                          kannadaSelected
                            ? 'ಆಸ್ಪತ್ರೆ/ಕ್ಲಿನಿಕ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ'
                            : 'Enter Hospital/Clinic Name'
                        }
                      />
                      <FormControl fullWidth className="formcontrol5">
                        <TextField
                          type={'text'}
                          name={'Enter Hospital/Clinic Name'}
                          onChange={(ev) =>
                            handleChange(
                              ev,
                              field,
                              'Enter Hospital/Clinic Name',
                            )
                          }
                          value={
                            (formData &&
                              formData['Enter Hospital/Clinic Name']) ||
                            ''
                          }
                          variant="outlined"
                        />
                      </FormControl>
                    </>
                  )}
                </>
              ) : field.field_name ===
                'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment' ? (
                <>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={(formData && formData[field.field_name]) || '0'}
                    >
                      <MenuItem value="0">
                        <ListItemText primary={`Select`} />
                      </MenuItem>
                      {field.field_data
                        .filter((field) => {
                          const treatmentValue =
                            formData[
                              'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                            ];

                          const treatmentType = formData['Treatment type'];

                          if (treatmentType === 173) {
                            return (
                              field.field_value_id == 147 ||
                              field.field_value_id == 148 ||
                              field.field_value == 'Other'
                            );
                          } else {
                            return field.field_value !== 'Other';
                          }
                        })
                        .map((field) => (
                          <MenuItem
                            key={field.field_value_id}
                            value={field.field_value_id}
                          >
                            <ListItemText primary={field.field_value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formData[
                    'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                  ] == configData?.otherTreatmentIdForMajorMedicalScheme && (
                    <>
                      <Required
                        className="mb-2 mt-4"
                        title={
                          kannadaSelected
                            ? 'ಚಿಕಿತ್ಸೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ'
                            : 'Enter Treatment Name'
                        }
                      />
                      <FormControl fullWidth className="formcontrol5">
                        <TextField
                          type={'text'}
                          name={'Enter Treatment Name'}
                          onChange={(ev) =>
                            handleChange(ev, field, 'Enter Treatment Name')
                          }
                          value={
                            (formData && formData['Enter Treatment Name']) || ''
                          }
                          variant="outlined"
                        />
                      </FormControl>
                    </>
                  )}
                </>
              ) : (
                <>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={(formData && formData[field.field_name]) || '0'}
                    >
                      <MenuItem value="0">
                        <ListItemText primary={`Select`} />
                      </MenuItem>
                      {field.field_data.map((field) => (
                        <MenuItem value={field.field_value_id}>
                          <ListItemText primary={field.field_value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ))}
          </Col>
        ))
      ) : (
        <>
          {' '}
          {dynamicFields &&
            dynamicFields.length &&
            dynamicFields.map(
              (field) =>
                field.field_name !== 'Dependent Date of Birth' && (
                  <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
                    <Required
                      className="mb-2 mt-4"
                      title={
                        kannadaSelected
                          ? field.field_name_kannada
                          : field.field_name
                      }
                    />
                    {field.field_type === 'Text' && (
                      <>
                        {field.field_name !== 'Name of the applicant' && (
                          <FormControl fullWidth className="formcontrol1">
                            <TextField
                              type={'text'}
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={formData && formData[field.field_name]}
                              variant="outlined"
                              // helperText={allValues['error_' + field.id]}
                              // error={allValues.globalError}
                              disabled={
                                field.field_name === 'Duration of treatment'
                                  ? true
                                  : false
                              }
                            />
                          </FormControl>
                        )}
                        {field.field_name === 'Name of the applicant' && (
                          <>
                            {formData &&
                              formData['Person Hospitalized- Selection'] ===
                                174 && (
                                <FormControl fullWidth className="formcontrol1">
                                  <TextField
                                    type={'text'}
                                    name={field.field_name}
                                    onChange={(ev) =>
                                      handleChange(ev, field, field.field_name)
                                    }
                                    disabled
                                    value={
                                      formData &&
                                      formData[
                                        'Person Hospitalized- Selection'
                                      ] === 174 &&
                                      formData[field.field_name]
                                    }
                                    variant="outlined"

                                    // helperText={allValues['error_' + field.id]}
                                    // error={allValues.globalError}
                                    // disabled={
                                    //   field.field_name.includes(
                                    //     'Name of the dependent ',
                                    //   ) && allValues.medicalAssistanceSelf
                                    // }
                                  />
                                </FormControl>
                              )}
                            {formData &&
                              formData['Person Hospitalized- Selection'] ===
                                175 && (
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  className="formcontrol5"
                                >
                                  <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name={field.field_name}
                                    onChange={(ev) =>
                                      handleChange(ev, field, field.field_name)
                                    }
                                    value={
                                      formData && formData[field.field_name]
                                    }
                                  >
                                    <MenuItem value="">
                                      <ListItemText primary="Select" />
                                    </MenuItem>
                                    {familyDetails &&
                                      familyDetails.length &&
                                      familyDetails.map((fam) => (
                                        <MenuItem
                                          value={`${
                                            fam?.first_name?.trim() ?? ''
                                          } ${fam?.middle_name?.trim() ?? ''} ${
                                            fam?.last_name?.trim() ?? ''
                                          }`}
                                        >
                                          <ListItemText
                                            primary={`${
                                              fam?.first_name?.trim() ?? ''
                                            } ${
                                              fam?.middle_name?.trim() ?? ''
                                            } ${fam?.last_name?.trim() ?? ''}`}
                                          />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              )}
                          </>
                        )}
                      </>
                    )}

                    {field.field_type === 'Date' &&
                      (field.field_name ===
                      'Date of admission to Hospital /Clinic' ? (
                        <>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              minDate={moment(
                                '2023-10-17',
                                'YYYY-MM-DD',
                              ).subtract(6, 'months')}
                              maxDate={new Date()}
                              // onClick={(e) => onChangeDate(e, field)}
                              // onClose={() => setPickerStatus(false)}
                              // open={allValues.openDatePicker}
                              className="datepicker"
                              margin="normal"
                              format="DD/MM/YYYY"
                              name={field.field_name}
                              // id={id}
                              value={
                                formData && formData[field.field_name]
                                  ? formData[field.field_name]
                                  : null
                              }
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              // onChange={setDate}
                              placeholder="DD/MM/YYYY"
                              // error={allValues.globalError}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </>
                      ) : field.field_name ===
                        'Date of Discharge from Hospital/Clinic' ? (
                        <>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              minDate={moment(
                                formData &&
                                  formData[
                                    `Date of admission to Hospital /Clinic`
                                  ],
                              )}
                              maxDate={new Date()}
                              // onClick={(e) => onChangeDate(e, field)}
                              // onClose={() => setPickerStatus(false)}
                              // open={allValues.openDatePicker}
                              className="datepicker"
                              margin="normal"
                              format="DD/MM/YYYY"
                              name={field.field_name}
                              // id={id}
                              value={
                                formData && formData[field.field_name]
                                  ? formData[field.field_name]
                                  : null
                              }
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              disabled={
                                formData &&
                                formData[
                                  `Date of admission to Hospital /Clinic`
                                ]
                                  ? false
                                  : true
                              }
                              // onChange={setDate}
                              placeholder="DD/MM/YYYY"
                              // error={allValues.globalError}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </>
                      ) : null)}

                    {(field.field_type === 'Dropdown' ||
                      field.field_type === 'Radio Button') &&
                      (field.field_name === 'Hospital/Clinic name' ? (
                        <>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className="formcontrol5"
                          >
                            <Select
                              className="select-marital"
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={
                                (formData && formData[field.field_name]) || '0'
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary={`Select`} />
                              </MenuItem>
                              {field.field_data
                                .filter((field) => {
                                  const treatmentValue =
                                    formData[
                                      'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                                    ];

                                  const treatmentType =
                                    formData['Treatment type'];

                                  if (treatmentType === 173) {
                                    return true;
                                  } else {
                                    return field.field_value !== 'Other';
                                  }
                                })
                                .map((field) => (
                                  <MenuItem
                                    key={field.field_value_id}
                                    value={field.field_value_id}
                                  >
                                    <ListItemText primary={field.field_value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          {formData['Hospital/Clinic name'] ==
                            configData?.otherHospitalIdForMajorMedicalScheme && (
                            <>
                              <Required
                                className="mb-2 mt-4"
                                title={
                                  kannadaSelected
                                    ? 'ಆಸ್ಪತ್ರೆ/ಕ್ಲಿನಿಕ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ'
                                    : 'Enter Hospital/Clinic Name'
                                }
                              />
                              <FormControl fullWidth className="formcontrol5">
                                <TextField
                                  type={'text'}
                                  name={'Enter Hospital/Clinic Name'}
                                  onChange={(ev) =>
                                    handleChange(
                                      ev,
                                      field,
                                      'Enter Hospital/Clinic Name',
                                    )
                                  }
                                  value={
                                    (formData &&
                                      formData['Enter Hospital/Clinic Name']) ||
                                    ''
                                  }
                                  variant="outlined"
                                />
                              </FormControl>
                            </>
                          )}
                        </>
                      ) : field.field_name ===
                        'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment' ? (
                        <>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className="formcontrol5"
                          >
                            <Select
                              className="select-marital"
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={
                                (formData && formData[field.field_name]) || '0'
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary={`Select`} />
                              </MenuItem>
                              {field.field_data
                                .filter((field) => {
                                  const treatmentValue =
                                    formData[
                                      'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                                    ];

                                  const treatmentType =
                                    formData['Treatment type'];

                                  if (treatmentType === 173) {
                                    return (
                                      field.field_value_id == 147 ||
                                      field.field_value_id == 148 ||
                                      field.field_value == 'Other'
                                    );
                                  } else {
                                    return field.field_value !== 'Other';
                                  }
                                })
                                .map((field) => (
                                  <MenuItem
                                    key={field.field_value_id}
                                    value={field.field_value_id}
                                  >
                                    <ListItemText primary={field.field_value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          {formData[
                            'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
                          ] ==
                            configData?.otherTreatmentIdForMajorMedicalScheme && (
                            <>
                              <Required
                                className="mb-2 mt-4"
                                title={
                                  kannadaSelected
                                    ? 'ಚಿಕಿತ್ಸೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ'
                                    : 'Enter Treatment Name'
                                }
                              />
                              <FormControl fullWidth className="formcontrol5">
                                <TextField
                                  type={'text'}
                                  name={'Enter Treatment Name'}
                                  onChange={(ev) =>
                                    handleChange(
                                      ev,
                                      field,
                                      'Enter Treatment Name',
                                    )
                                  }
                                  value={
                                    (formData &&
                                      formData['Enter Treatment Name']) ||
                                    ''
                                  }
                                  variant="outlined"
                                />
                              </FormControl>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className="formcontrol5"
                          >
                            <Select
                              className="select-marital"
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={
                                (formData && formData[field.field_name]) || '0'
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary={`Select`} />
                              </MenuItem>
                              {field.field_data.map((field) => (
                                <MenuItem value={field.field_value_id}>
                                  <ListItemText primary={field.field_value} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      ))}
                  </Col>
                ),
            )}
        </>
      )}
    </>
  );
};

export default MajorAilmentsAssistance;
