import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { FixedSizeList } from 'react-window';

function MultiSelectAll({
  options = [],
  onChange,
  selected = [],
  label = 'Select Items',
  disabled = false,
}) {
  console.log(
    ' options, onChange, selected, disabled:',
    options,
    onChange,
    selected,
    disabled,
  );
  // Check if all options are selected
  const isAllSelected =
    options?.length > 0 &&
    options.every((option) => selected?.includes(option.value));

  // Check if some but not all options are selected (for indeterminate state)
  const isPartialSelected =
    selected?.length > 0 && selected?.length < options?.length;

  // Handle selection changes
  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      console.log('handleChange ~ value:', value);

      if (value.includes('all')) {
        onChange(isAllSelected ? [] : options.map((option) => option.value));
      } else {
        onChange(value);
      }
    },
    [isAllSelected, onChange, options],
  );

  // Row renderer for virtualized list
  const renderRow = ({ index, style }) => {
    const item = options[index];

    return (
      <MenuItem key={item.value} style={style} value={item.value}>
        <ListItemIcon>
          <Checkbox checked={selected.includes(item.value)} />
        </ListItemIcon>
        <ListItemText primary={item.label} />
      </MenuItem>
    );
  };

  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      {options && selected && (
        <Select
          multiple
          sx={{ border: '1px solid gray' }}
          value={selected}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected?.length === 0) return <span>{label}</span>;
            if (isAllSelected) return 'All';
            return (
              options
                .filter((option) => selected?.includes(option.value))
                .map((option) => option.label.trim())
                .slice(0, 4)
                .join(', ') + '...'
            );
          }}
        >
          {/* "Select All" option */}
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={isPartialSelected}
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>

          {/* Virtualized List for Large Options */}
          <FixedSizeList
            height={300}
            width="100%"
            itemSize={48}
            itemCount={options.length}
          >
            {({ index, style }) => (
              <MenuItem
                key={options[index].value}
                style={style}
                value={options[index].value}
                onClick={() => {
                  const newSelection = selected.includes(options[index].value)
                    ? selected.filter((val) => val !== options[index].value)
                    : [...selected, options[index].value];
                  onChange(newSelection);
                }}
              >
                <ListItemIcon>
                  <Checkbox checked={selected.includes(options[index].value)} />
                </ListItemIcon>
                <ListItemText primary={options[index].label} />
              </MenuItem>
            )}
          </FixedSizeList>
        </Select>
      )}
    </FormControl>
  );
}

export default React.memo(MultiSelectAll);
