import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import Card from './Card';
import AdminHospitalList from 'components/adminPortal/hospitals/AdminHospitalList';
import back from '../../assets/images/Icon awesome-arrow-circle-left.svg';
import datareport from '../../assets/images/data-report.png';
import { withBackButton } from 'HOC/WithBackButton';
import { LocalHospital } from '@mui/icons-material';
import { DataContext } from 'context/config';
import { Box, Paper } from '@material-ui/core';
function Home(props) {
  const [view, setView] = useState('');
  const HospitalListWithBackButton = withBackButton(
    AdminHospitalList,
    back,
    setView,
  );
  const { data: configData } = useContext(DataContext);
  return (
    <>
      {view === '' && (
        <Grid container spacing={1} style={{ marginInline: 'auto' }}>
          <Grid item xs={6} lg={2.5}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: 200,
                },
              }}
            >
              {configData && configData?.showHospitalList == 'Y' ? (
                <Paper>
                  <div
                    onClick={() => setView('hospital')}
                    className="d-flex justify-content-center align-items-center flex-column p-3"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={datareport} alt="" width={'100'} height={'100'} />
                    <p style={{ fontSize: '25px' }}>Hospital List</p>
                  </div>
                </Paper>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      )}
      {view === 'hospital' && <HospitalListWithBackButton />}
    </>
  );
}

export default Home;
