import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const ChildListAPI = async (labourUserID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/thayi-magu/child-list?labourUserId=${labourUserID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const ChildDetailsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/thayi-magu/child-details`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getThayiMaguFiedls = async (labourUserID, schemeAvailID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/get_scheme_fields_values?labour_user_id=${labourUserID}&scheme_avail_id=${schemeAvailID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
