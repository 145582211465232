import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import logo from 'assets/images/Karnataka_logo_100_Pixels.png';

import { getUnionList } from './unionList.service';
import { FormControl, TextField, InputAdornment } from '@material-ui/core';
import search from 'assets/images/Icon ionic-ios-search.svg';
import closeIcon from 'assets/images/close-01.svg';

import PaginationFunction from 'components/adminPortal/Pagination';
import UnionListTableComponent from './UnionListTableComponent';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import { Col, Row, Button } from 'react-bootstrap';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
const UnionList = () => {
  const [unionList, setUnionList] = useState(null);

  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  const PER_PAGE = 10;

  const callUnionList = () => {
    getUnionList(setUnionList);
  };

  useEffect(callUnionList, []);

  useEffect(() => {
    if (query) {
      const keys = ['name', 'registeration_no', 'spoke_person', 'phone_no'];
      const result =
        unionList &&
        unionList.filter((item) =>
          keys.some((key) => {
            return (
              item['name'].includes(query) ||
              item['name'].toLowerCase().includes(query) ||
              item['name'].toUpperCase().includes(query) ||
              item['registeration_no'].includes(query) ||
              item['registeration_no'].toLowerCase().includes(query) ||
              item['registeration_no'].toUpperCase().includes(query) ||
              item['spoke_person'].includes(query) ||
              item['spoke_person'].toLowerCase().includes(query) ||
              item['spoke_person'].toUpperCase().includes(query) ||
              item['phone_no'].toLowerCase().includes(query) ||
              item['phone_no'].toUpperCase().includes(query) ||
              item['phone_no'].includes(query)
            );
          }),
        );

      setFilteredData(result);
    }
  }, [query, unionList]);

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : unionList && Math.ceil(unionList.length / PER_PAGE);
  // console.log(count, 'count');
  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(unionList || [], PER_PAGE);

  // console.log(_DATA, 'PAGIN');
  // console.log(_DATA.maxPage, 'maxPage');

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            width: '100%',
            height: 120,
          },
        }}
      >
        <>
          <div className="root">
            <Row className="top-div">
              <Row className="topbar-row">
                <Col xs={12} md={8} lg={9} className="top-bar-col">
                  <div className="logo-div-profile">
                    <Link to={'/admin/home'}>
                      <img
                        id="logo"
                        src={logo}
                        alt="..."
                        className="logo-img"
                      />
                    </Link>
                    <h1 className="logo-text">
                      Government of Karnataka <br />
                      Karnataka Building & Other Construction Workers Welfare
                      Board (KBOCWWB)
                    </h1>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </>
      </Box>
      {unionList && unionList.length > 0 ? (
        <>
          <div className="position-relative ms-5">
            <div
              className="position-absolute"
              style={{ top: '10px', left: 25 }}
            >
              <Link to={'/admin/home'}>
                <img alt="..." className="cal-icon hover-icn" src={back} />
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-3">
            <h3 style={{ color: '#7D7D7D', fontWeight: 'bolder' }}>
              Union Master
            </h3>
          </div>
          <div className="d-flex justify-content-end align-items-center px-3  bg">
            <Link to={'/admin/unionInsert'}>
              <Button className="btn btn-success py-2">
                <span className="px-1 py-1">
                  <AddCircleRoundedIcon />
                </span>
                <span className="px-1 py-1">Add Union</span>
              </Button>
            </Link>
          </div>
          <div className="d-flex justify-content-end align-items-center px-3 py-3">
            <FormControl fullWidth className="phno-field searchbar-schemes">
              <TextField
                placeholder={'Search Union'}
                type="text"
                onChange={(ev) => setQuery(ev.target.value)}
                value={query}
                variant="outlined"
                InputProps={
                  query && query !== ''
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              alt="..."
                              src={closeIcon}
                              onClick={() => setQuery('')}
                              className="language-img"
                              style={{ cursor: 'pointer' }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              alt="..."
                              src={search}
                              className="language-img"
                            />
                          </InputAdornment>
                        ),
                      }
                }
              ></TextField>
            </FormControl>
          </div>
          <UnionListTableComponent
            count={count}
            page={page}
            handlePageChange={handlePageChange}
            unionData={_DATA.currentData()}
          />
        </>
      ) : (
        <SimpleBackdrop />
      )}
    </>
  );
};

export default UnionList;
