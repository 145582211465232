import React, { useEffect, useRef } from "react";
import PersonalDetails from "./LabourDetails/PersonalDetails";
import AddressDetails from "./LabourDetails/AddressDetails";
import FamilyDetails from "./LabourDetails/FamilyDetails";
import BankDetails from "./LabourDetails/BankDetails";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import Aadhaar from "./Aadhaar/Aadhaar";
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  getUserOnVaultReferenceNumber,
  updateNameAndAaadhaarVerified,
} from "./SevaSindhuServices/Aadhaar.service";
import { showToast } from "utils/tools";
import stringSimilarity from "utils/nameMatching";
import SimpleBackdrop from "components/loadingScreen/Backdrop";
import {
  getAllStates,
  getCategory,
  getEducation,
  getIFSCDetails,
  getNatureOfWork,
  getProfession,
  getRelation,
  getReligion,
  getResidenceAPI,
  getSubmissionLocationAPI,
  transferSevaSindhuAPI,
} from "./SevaSindhuServices/SevaSindhu.service";

import Divider from "@mui/material/Divider";
import { Button, Col, Row } from "react-bootstrap";
import { Translate } from "react-redux-i18n";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import moment from "moment";
import { PersonalDetailsValidator } from "./SevaSindhuValidators/PersonalValidators";
import { AddressValidator } from "./SevaSindhuValidators/AddressValidators";
import { hasEmptyPropertiesFamilyDetails } from "utils/checkEmptyArray";
import { FamilyValidator } from "./SevaSindhuValidators/FamilyValidators";
import { BankValidator } from "./SevaSindhuValidators/BankValidator";
import { SevaSindhuFileUpload } from "./FileUpload";
import SuccessBackdrop from "./SuccessBackdrop";
import { Typography } from "@mui/material";
import { CheckCircle } from "@material-ui/icons";
import { Backdrop, makeStyles } from "@material-ui/core";
import { BASE_URL } from "store/actions/user.actions";

const wifeMandatory = {
  relation: 51,
  firstName: null,
  middleName: null,
  lastName: null,
  dob: null,
  profession: null,
  education: null,
  nominee: false,
  isRegistered: 0,
  registerationNo: null,
};

const husbandMandatory = {
  relation: 52,
  firstName: null,
  middleName: null,
  lastName: null,
  dob: null,
  profession: null,
  education: null,
  nominee: false,
  isRegistered: 0,
  registerationNo: null,
};
const SevaSindhuRegistration = (props) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [personalDetails, setPersonalDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [familyDetails, setFamilyDetails] = useState([
    {
      relation: 49,
      firstName: null,
      middleName: null,
      lastName: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isRegistered: 0,
      registerationNo: null,
    },
    {
      relation: 50,
      firstName: null,
      middleName: null,
      lastName: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isRegistered: 0,
      registerationNo: null,
    },
  ]);
  const [bankDetails, setBankDetails] = useState([
    {
      bankAccountNumber: null,
      bankIFSCCODE: null,
      bankName: null,
      bankBranchName: null,
      bankAddress: null,
      isVerified: false,
      isNominee: false,
    },
  ]);
  const [open, setOpen] = React.useState(true);

  const [vaultReferenceNumber, setVaultReferenceNumber] = useState(null);

  const [loading, setLoading] = useState(false);
  const [aadharVerified, setAadhaarVerified] = useState(false);

  const [circleData, setCircleData] = useState(null);
  const [fetchResidence, setFetchResidence] = useState(null);
  const [residence, setResidence] = useState(null);
  const [stateID, setStateID] = useState(12);
  const [districtID, setDistrictID] = useState(null);
  const [talukID, setTalukID] = useState(null);
  const [cityID, setCityID] = useState(null);
  const [villageID, setVillageID] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [houseNumber, setHouseNumber] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [pincode, setPinCode] = useState(null);

  const [genderID, setGenderID] = useState(null);
  const [applicantName, setApplicantName] = useState(null);
  const [dob, setDOB] = useState(null);
  const [fetchCategory, setFetchCategory] = useState(null);
  const [fetchMaritialStatus, setFetchMaritialStatus] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [maritialStatusID, setMaritialStatusID] = useState(null);
  const [fetchReligion, setFetchReligion] = useState(null);
  const [religionID, setReligionID] = useState(null);
  const [fetchNatureOfWork, setFetchNatureOfWork] = useState(null);
  const [natureOfWorkID, setNatureOfWorkID] = useState(null);
  const [fetchStates, setFetchStates] = useState(null);

  const [isMigrant, setIsMigrant] = useState(false);
  const [migrantID, setMigrantID] = useState(null);

  const [isAyushman, setIsAyushman] = useState(false);
  const [ayushmanCardNumber, setAyushmanCardNumber] = useState(null);
  const [userPhotoID, setUserPhotoID] = useState(null);

  const [existingCardDoc, setExistingCardDoc] = useState([]);

  const existingCardRef = useRef(null);
  const [bankPassbookDoc, setBankPassbookDoc] = useState([]);

  const bankPassbookRef = useRef(null);
  const [nomineePassbookDoc, setNomineePassbookDoc] = useState([]);
  const nomineePassbookRef = useRef(null);

  const [fetchRelation, setFetchRelation] = useState(null);
  const [fetchProfession, setFetchProfession] = useState(null);
  const [fetchEducation, setFetchEducation] = useState(null);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const currentUrl = window.location.href;

  const searchParams = new URLSearchParams(
    currentUrl.slice(currentUrl.indexOf("?"))
  );

  const isUpdated = useRef(false);

  useEffect(() => {
    if (!isUpdated.current && maritialStatusID === 30) {
      if (genderID === "27") {
        const arr = [...familyDetails, wifeMandatory];
        setFamilyDetails(arr);
        isUpdated.current = true;
      } else if (genderID === "28") {
        const arr = [...familyDetails, husbandMandatory];
        setFamilyDetails(arr);
        isUpdated.current = true;
      }
    }
  }, [familyDetails, genderID, maritialStatusID]);

  const {
    registrationNumber,
    referenceNumber,
    mobileNumber,
    referenceID,
    labourName,
    submissionLocation,
  } = Object.fromEntries(searchParams.entries());

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleVerifyAadhaar = async () => {
    try {
      //Production Data

      const payLoad = {
        responseRedirectURL: `${BASE_URL}/seva-sindhu-registration?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&referenceID=${referenceID}&labourName=${labourName}&submissionLocation=${submissionLocation}`,
      };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/seva-sindhu-registration?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&referenceID=${referenceID}&labourName=${labourName}&submissionLocation=${submissionLocation}`,
      // };
      // DEV DATA
      // const payLoad = {
      //   responseRedirectURL: `http://gcdmsdbac.hostg.in:5353/seva-sindhu-registration?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&referenceID=${referenceID}&labourName=${labourName}&submissionLocation=${submissionLocation}`,
      // };

      const response = await EKYCWEBHOOK(payLoad);
      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        "_self"
      );
    } catch (error) {
      showToast(
        "ERROR",
        "Something went wrong! Please try again after some time."
      );
    }
  };

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    setLoading(true);
    try {
      const payLoad = {
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      };

      const { data: aadhaarDuplicateResponse } =
        await getUserOnVaultReferenceNumber(payLoad);

      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);

      if (aadhaarDuplicateResponse.length !== 0) {
        setLoading(false);
        showToast(
          "ERROR",
          "Aadhaar already registered with other mobile number"
        );

        const updateAadhaarVerifiedPayload = {
          type: "ALREADY_VERIFIED",
          sevasinduID: +referenceID,
          regNo: registrationNumber,
          refNo: referenceNumber,
          sevasindhuMobileNo: mobileNumber,
          registeredMobileNo: aadhaarDuplicateResponse[0]?.mobile_no,
          labourUserID: aadhaarDuplicateResponse[0]?.labour_user_id,
          vaultRefNo: vaultRefNo,
          sevasindhuName: labourName,
          aadharName: aadhaarData[0].name,
          nameMatchingScore: 0,
        };

        await updateNameAndAaadhaarVerified(updateAadhaarVerifiedPayload);

        return;
      }

      if (aadhaarData.length > 0) {
        const { name } = aadhaarData[0];
        const { gender } = aadhaarData[0];
        const { dob } = aadhaarData[0];
        const _gender = gender === "M" ? "27" : gender === "F" ? "28" : "29";
        const { image } = aadhaarData[0];
        const sevaSindhuLabourName = labourName.toLowerCase();
        // const sevaSindhuLabourName = 'Murali R Bharadwaj'.toLowerCase();
        const aadhaarName = name.toLowerCase();

        const nameMatchingScore = stringSimilarity(
          sevaSindhuLabourName,
          aadhaarName
        );
        if (nameMatchingScore >= 0.75) {
          setLoading(false);
          setAadhaarVerified(true);
          setApplicantName(name);
          setDOB(dob);
          setGenderID(_gender);
          setUserPhotoID(image);
          showToast("SUCCESS", "Name Matched as per the Aadhaar");
        } else {
          setLoading(false);
          showToast(
            "ERROR",
            "Name Matching Failed,name did not matched as per the aadhaar"
          );
          const updateNameMacthingPayload = {
            type: "NAME_MISMATCH",
            sevasinduID: +referenceID,
            regNo: registrationNumber,
            refNo: referenceNumber,
            sevasindhuMobileNo: mobileNumber,
            registeredMobileNo: "1234",
            labourUserID: 1,
            vaultRefNo: vaultRefNo,
            sevasindhuName: labourName,
            aadharName: aadhaarData[0].name,
            nameMatchingScore: nameMatchingScore * 100,
          };

          await updateNameAndAaadhaarVerified(updateNameMacthingPayload);
        }
      } else {
        setLoading(false);
        setAadhaarVerified(false);
        showToast("ERROR", "Aadhaar Verification failed, please try again. ");
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callGetLocationDetails = async (submissionLocation) => {
    try {
      const payLoad = {
        ref_seva_sindhu_circle_id: +submissionLocation,
      };
      const response = await getSubmissionLocationAPI(payLoad);
      if (response.success) {
        setCircleData(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callResidence = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Type of Residence",
      };
      const response = await getResidenceAPI(payLoad);
      if (response.success) {
        setFetchResidence(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callMaritialStatus = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Marital Status",
      };
      const response = await getCategory(payLoad);
      if (response.success) {
        setFetchMaritialStatus(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callCategory = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Category",
      };
      const response = await getCategory(payLoad);
      if (response.success) {
        setFetchCategory(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callReligion = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Religion",
      };
      const response = await getReligion(payLoad);
      if (response.success) {
        setFetchReligion(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callNatureOfWork = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Nature of Work",
      };
      const response = await getNatureOfWork(payLoad);
      if (response.success) {
        setFetchNatureOfWork(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callRelation = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Relation",
      };
      const response = await getRelation(payLoad);
      if (response.success) {
        setFetchRelation(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callProfession = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Profession",
      };
      const response = await getProfession(payLoad);
      if (response.success) {
        setFetchProfession(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callEducation = async () => {
    try {
      const payLoad = {
        board_id: 1,
        catalog_name: "Education",
      };
      const response = await getEducation(payLoad);
      if (response.success) {
        setFetchEducation(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  const callAllStates = async () => {
    try {
      const response = await getAllStates();
      if (response.success) {
        setFetchStates(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  useEffect(() => {
    const currentUrl = window.location.toString();
    const queryString = new URLSearchParams(location.search);
    if (currentUrl.includes("Success")) {
      const vaultRefNo = queryString.get("vaultrefno");
      if (vaultRefNo) {
        setVaultReferenceNumber(vaultRefNo);
        callGetUserOnVaultReferenceNumber(vaultRefNo);
      }
    } else if (currentUrl.includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.");
    }
  }, [location.search]);

  useEffect(() => {
    if (aadharVerified && submissionLocation) {
      callGetLocationDetails(submissionLocation);
    }
  }, [submissionLocation, aadharVerified]);

  useEffect(() => {
    if (aadharVerified) {
      callResidence();
    }
  }, [aadharVerified]);

  useEffect(() => {
    if (aadharVerified && isMigrant === "1") {
      callAllStates();
    }
  }, [aadharVerified, isMigrant]);

  useEffect(() => {
    if (aadharVerified) {
      callMaritialStatus();
      callCategory();
      callReligion();
      callNatureOfWork();
      callRelation();
      callEducation();
      callProfession();
    }
  }, [aadharVerified]);

  const handleFileUpload = (ev) => {
    if (ev.target.name === "existingCard") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...existingCardDoc, ...ev.target.files];
          setExistingCardDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB");
        }
      } else {
        showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
      }
    } else if (ev.target.name === "bankPassbook") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...bankPassbookDoc, ...ev.target.files];
          setBankPassbookDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB");
        }
      } else {
        showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
      }
    } else if (ev.target.name === "nomineePassbook") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...nomineePassbookDoc, ...ev.target.files];
          setNomineePassbookDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB");
        }
      } else {
        showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
      }
    }
  };

  const handleRemoveFile = (index, documentType) => {
    if (documentType === "existingCard") {
      const newFiles = [...existingCardDoc];
      newFiles.splice(index, 1);
      setExistingCardDoc(newFiles);
      existingCardRef.current.value = "";
    } else if (documentType === "bankPassbook") {
      const newFiles = [...bankPassbookDoc];
      newFiles.splice(index, 1);
      setBankPassbookDoc(newFiles);
      bankPassbookRef.current.value = "";
    } else if (documentType === "nomineePassbook") {
      const newFiles = [...nomineePassbookDoc];
      newFiles.splice(index, 1);
      setNomineePassbookDoc(newFiles);
      nomineePassbookRef.current.value = "";
    }
  };

  const handleFormChange = (index, event) => {
    let data = [...familyDetails];
    if (event.target.name === "firstName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "middleName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "lastName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFamilyDetails(data);
  };

  const handleDateChange = (date, index, from) => {
    let data = [...familyDetails];
    data[index][from] = new Date(moment(date));
    setFamilyDetails(data);
  };

  const handleCheckBox = (index, event, from) => {
    let data = [...familyDetails];

    // Uncheck the previous checkbox in the array
    if (event.target.checked) {
      for (let i = 0; i < data.length; i++) {
        if (i !== index) {
          data[i][from] = 0;
        }
      }
    }

    // Update the current checkbox state
    data[index][from] = event.target.checked ? 1 : 0;
    setFamilyDetails(data);
  };

  const addNewFamilyMember = () => {
    let newfield = {
      relation: null,

      firstName: null,
      middleName: null,
      lastName: null,
      parentChildUniqueID: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: 1,
      isActive: 1,
      isDeleted: 0,
      isShowing: true,
      isRegistered: 0,
      registerationNo: null,
    };

    setFamilyDetails([...familyDetails, newfield]);
  };

  const handleKeyDown = (ev) => {
    ev.preventDefault();
  };

  const removeFamilyMembers = (index) => {
    let data = [...familyDetails];
    data.splice(index, 1);
    setFamilyDetails(data);
  };

  const handleBank = (index, event) => {
    let data = [...bankDetails];
    if (event.target.name === "bankAccountNumber") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else {
      data[index][event.target.name] = event.target.value;
    }

    setBankDetails(data);
  };

  const handleBankCheckBox = (index, event, from) => {
    let data = [...bankDetails];

    // Uncheck the previous checkbox in the array
    if (event.target.checked) {
      for (let i = 0; i < data.length; i++) {
        if (i !== index) {
          data[i][from] = 0;
        }
      }
    }

    // Update the current checkbox state
    data[index][from] = event.target.checked ? 1 : 0;
    setBankDetails(data);
  };

  const addNomineeBankDetails = () => {
    let newfield = {
      bankAccountNumber: null,
      bankIFSCCODE: null,
      bankName: null,
      bankBranchName: null,
      bankAddress: null,
      isVerified: false,
      isNominee: true,
    };

    setBankDetails([...bankDetails, newfield]);
  };

  const removeBankNomineeDetails = (index) => {
    let data = [...bankDetails];
    data.splice(index, 1);
    setBankDetails(data);
  };

  const handleVerfiyIFSC = async (index, IFSCCODE) => {
    if (IFSCCODE) {
      try {
        const response = await getIFSCDetails(IFSCCODE);
        if (response.success) {
          const _data = response.data;
          let data = [...bankDetails];
          data[index]["bankName"] = _data.BANK;
          data[index]["bankBranchName"] = _data.BRANCH;
          data[index]["bankAddress"] = _data.ADDRESS;
          data[index]["isVerified"] = true;
          setBankDetails(data);
        }
      } catch (error) {
        if (error.response.data.errors) {
          showToast("ERROR", `${error.response.data.errors.msg}`);
        } else {
          showToast("ERROR", "Something went wrong");
        }
      }
    } else {
      showToast("ERROR", "Please enter IFSC CODE");
    }
  };

  const resetIFSCCODE = (index) => {
    let data = [...bankDetails];
    data[index]["bankIFSCCODE"] = "";
    data[index]["bankName"] = "";
    data[index]["bankAddress"] = "";
    data[index]["bankBranchName"] = "";
    data[index]["isVerified"] = false;
    setBankDetails(data);
  };

  const handleSubmit = async () => {
    try {
      const personalResult = await PersonalDetailsValidator(
        maritialStatusID,
        categoryID,
        religionID,
        natureOfWorkID,
        isMigrant,
        isAyushman,
        migrantID,
        ayushmanCardNumber,
        existingCardDoc
      );

      const addressResult = await AddressValidator(
        residence,
        districtID,
        talukID,
        cityID,
        villageID,
        streetName,
        houseNumber,
        pincode
      );

      const familyResult = await FamilyValidator(familyDetails);
      const bankResult = await BankValidator(
        bankDetails,
        bankPassbookDoc,
        nomineePassbookDoc
      );
      if (
        personalResult.success &&
        addressResult.success &&
        familyResult.success &&
        bankResult.success
      ) {
        setLoading(true);

        const response = await SevaSindhuFileUpload(
          bankResult.data,
          existingCardDoc,
          bankPassbookDoc,
          bankDetails.length > 1 ? nomineePassbookDoc : []
        );

        if (response.success) {
          transferSevaSindhu(
            personalResult.data,
            addressResult.data,
            familyResult.data,
            bankResult.data,
            response.sevaSindhuCard,
            response.bankPassbook,
            response.nomineePassbook
          );
        }
      }
    } catch (error) {
      setLoading(false);
      showToast("ERROR", `${error.msg}`);
    }
  };

  const transferSevaSindhu = async (
    personalDetails,
    addressDetails,
    family,
    bank,
    sevaSindhuCard,
    bankPassbook,
    nomineePassbook
  ) => {
    const {
      categoryID,
      religionID,
      natureOfWorkID,
      isMigrant,
      migrantID,
      isAyushman,
      ayushmanCardNumber,
    } = personalDetails;

    const {
      residence,
      districtID,
      talukID,
      cityID,
      villageID,
      streetName,
      houseNumber,
      pincode,
    } = addressDetails;

    const { familyDetails } = family;
    const { bankDetails } = bank;

    const payLoad = {
      boardId: 1,
      sevasindhuId: +referenceID,
      vaultRefNo: vaultReferenceNumber,
      mobile: +mobileNumber,
      isInterStateMigrant: isMigrant,
      migrateFromStateId: migrantID === 0 ? null : migrantID,
      natureofwork: natureOfWorkID,
      isAyushmanCardHolder: isAyushman,
      ayushmanCardNumber: ayushmanCardNumber,
      religion: religionID,
      cast: categoryID,
      maritalStatus: maritialStatusID,
      sevasindhuCard: sevaSindhuCard,
      address: [
        {
          addressBaseType: 10,
          addressType: residence,
          doorNo: houseNumber,
          streetName: streetName,
          villageAreaId: villageID,
          cityTownGramPanchayatId: cityID,
          talukId: talukID,
          districId: districtID,
          stateId: 12,
          landMark: landmark ? landmark : "",
          pinCode: pincode,
        },
      ],
      family: familyDetails.map((_data) => ({
        relationType: _data.relation,
        firstName: `${_data?.firstName?.trim()}`,
        middleName: null,
        lastName: null,
        dob: moment(_data.dob).format("YYYY-MM-DD"),
        profession: _data.profession,
        education: _data.education,
        isNominee: _data.nominee ? 1 : 0,
        isRegistered: 0,
        registerationNo: null,
      })),
      bank:
        bankDetails.length === 1
          ? [
            {
              ifsc: bankDetails[0].bankIFSCCODE,
              accountNo: +bankDetails[0].bankAccountNumber,
              name: bankDetails[0].bankName,
              branch: bankDetails[0].bankBranchName,
              address: bankDetails[0].bankAddress,
              passbookId: "-",
              passbook: bankPassbook,
              isNominee: bankDetails[0].isNominee ? 1 : 0,
              isVerified: bankDetails[0].isVerified ? 1 : 0,
            },
          ]
          : bankDetails.length === 2
            ? [
              {
                ifsc: bankDetails[0].bankIFSCCODE,
                accountNo: +bankDetails[0].bankAccountNumber,
                name: bankDetails[0].bankName,
                branch: bankDetails[0].bankBranchName,
                address: bankDetails[0].bankAddress,
                passbookId: "-",
                passbook: bankPassbook,
                isNominee: bankDetails[0].isNominee ? 1 : 0,
                isVerified: bankDetails[0].isVerified ? 1 : 0,
              },
              {
                ifsc: bankDetails[1].bankIFSCCODE,
                accountNo: +bankDetails[1].bankAccountNumber,
                name: bankDetails[1].bankName,
                branch: bankDetails[1].bankBranchName,
                address: bankDetails[1].bankAddress,
                passbookId: "-",
                passbook: nomineePassbook,
                isNominee: bankDetails[1].isNominee ? 1 : 0,
                isVerified: bankDetails[1].isVerified ? 1 : 0,
              },
            ]
            : [],
    };

    try {
      const response = await transferSevaSindhuAPI(payLoad);
      if (response.success) {
        setLoading(false);
        setSubmitSuccess(true);
        showToast("SUCCESS", "Labour Transferred Successfully");
      }
    } catch (error) {
      setLoading(false);
      setSubmitSuccess(false);
      if (error?.response?.data?.errors?.msg) {
        showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
      } else {
        showToast("ERROR", "Something went wrong");
      }
    }
  };

  return (
    <>
      {submitSuccess && (
        <SuccessBackdrop
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
        />
      )}
      {/* {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={loading ? null : handleCloseBackdrop}
        >
          <div style={{ display: 'block' }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: 'auto', width: '200px' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="final-success-title">Successfully Submitted</p>
              <Link>
                <Typography variant="h4">
                  {' '}
                  Please login to access your E-Card
                </Typography>
              </Link>
            </div>
          </div>
        </Backdrop>
      ) : null} */}
      <Row className='top-div '>
        <Row className='topbar-row'>
          <Col xs={12} md={4} lg={6} className='top-bar-col'>
            <div className='logo-div-profile'>
              <a href='/login'>
                <img id='logo' src={logo} alt='...' className='logo-img' />
              </a>
              <h1 className='logo-text'>
                {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                <Translate value='header.commonTitle' />
                <br />
                <Translate value='header.subcommonTitle' />
              </h1>
            </div>
          </Col>
          <div className='d-flex justify-content-end'>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              {/* <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                // value={allValues.language}
                name="language"
                displayEmpty
                // onChange={handleChange1}
              >
                <MenuItem value="">
                  <ListItemIcon>
                    <img alt="..." src={language} className="language-img" />
                  </ListItemIcon>
                  <ListItemText primary="Select Language" />
                </MenuItem>

                <MenuItem value="en">
                  <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                  <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
              </Select> */}
            </Col>
          </div>
        </Row>
      </Row>
      <Aadhaar
        handleVerifyAadhaar={handleVerifyAadhaar}
        loading={loading}
        aadharVerified={aadharVerified}
      />

      {loading ? <SimpleBackdrop /> : null}
      {aadharVerified ? (
        <>
          <PersonalDetails
            applicantName={applicantName}
            dob={dob}
            genderID={genderID}
            userPhotoID={userPhotoID}
            existingCardDoc={existingCardDoc}
            existingCardRef={existingCardRef}
            handleFileUpload={handleFileUpload}
            handleRemoveFile={handleRemoveFile}
            fetchMaritialStatus={fetchMaritialStatus}
            fetchCategory={fetchCategory}
            fetchReligion={fetchReligion}
            fetchNatureOfWork={fetchNatureOfWork}
            maritialStatusID={maritialStatusID}
            setMaritialStatusID={setMaritialStatusID}
            categoryID={categoryID}
            setCategoryID={setCategoryID}
            religionID={religionID}
            setReligionID={setReligionID}
            natureOfWorkID={natureOfWorkID}
            setNatureOfWorkID={setNatureOfWorkID}
            isMigrant={isMigrant}
            setIsMigrant={setIsMigrant}
            isAyushman={isAyushman}
            setIsAyushman={setIsAyushman}
            ayushmanCardNumber={ayushmanCardNumber}
            setAyushmanCardNumber={setAyushmanCardNumber}
            fetchStates={fetchStates}
            migrantID={migrantID}
            setMigrantID={setMigrantID}
          />

          <AddressDetails
            fetchResidence={fetchResidence}
            residence={residence}
            setResidence={setResidence}
            circleData={circleData}
            stateID={stateID}
            districtID={districtID}
            setDistrictID={setDistrictID}
            talukID={talukID}
            setTalukID={setTalukID}
            cityID={cityID}
            setCityID={setCityID}
            villageID={villageID}
            setVillageID={setVillageID}
            streetName={streetName}
            setStreetName={setStreetName}
            houseNumber={houseNumber}
            setHouseNumber={setHouseNumber}
            landmark={landmark}
            setLandmark={setLandmark}
            pincode={pincode}
            setPincode={setPinCode}
          />

          <FamilyDetails
            familyDetails={familyDetails}
            removeFamilyMembers={removeFamilyMembers}
            handleFormChange={handleFormChange}
            fetchRelation={fetchRelation}
            handleKeyDown={handleKeyDown}
            handleCheckBox={handleCheckBox}
            handleDateChange={handleDateChange}
            fetchEducation={fetchEducation}
            fetchProfession={fetchProfession}
            addNewFamilyMember={addNewFamilyMember}
            dob={dob}
          />

          <BankDetails
            bankDetails={bankDetails}
            handleBank={handleBank}
            bankPassbookDoc={bankPassbookDoc}
            bankPassbookRef={bankPassbookRef}
            nomineePassbookDoc={nomineePassbookDoc}
            nomineePassbookRef={nomineePassbookRef}
            handleFileUpload={handleFileUpload}
            handleRemoveFile={handleRemoveFile}
            handleBankCheckBox={handleBankCheckBox}
            addNomineeDetails={addNomineeBankDetails}
            removeBankNomineeDetails={removeBankNomineeDetails}
            handleVerfiyIFSC={handleVerfiyIFSC}
            resetIFSCCODE={resetIFSCCODE}
          />

          <div className='d-flex justify-content-center align-items-center my-3'>
            <Button
              onClick={handleSubmit}
              className='btn btn-lg btn-success px-5'
            >
              Submit
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SevaSindhuRegistration;
