// import React, { useEffect, useRef, useState } from "react";
// import { Button, Col, Row } from "react-bootstrap";
// import logo from "assets/images/Karnataka_logo_100_Pixels.png";
// import { Translate } from "react-redux-i18n";
// import Box from "@mui/material/Box";
// import { AddCircleRounded, CheckCircle, Visibility } from "@material-ui/icons";

// import TextField from "@mui/material/TextField";
// import {
//   makeStyles,
//   Backdrop,
//   CircularProgress,
//   FormControl,
//   IconButton,
//   InputAdornment,
//   ListItemText,
//   MenuItem,
//   Paper,
//   Select,
//   TextareaAutosize,
// } from "@material-ui/core";

// import {
//   // Link,
//   useHistory,
//   useLocation,
//   useParams,
// } from "react-router-dom/cjs/react-router-dom.min";

// import {
//   EKYCWEBHOOK,
//   getAadhaarDetails,
//   getUserOnVaultReferenceNumber,
//   updateEKYCVaultRefNumber,
// } from "./Aadhaar/Aadhaar.service";

// // import Select from '@mui/material/Select';

// // import MenuItem from '@mui/material/MenuItem';
// // import FormControl from '@mui/material/FormControl';
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// import closeIcon from "assets/images/close.png";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
// import CancelIcon from "@mui/icons-material/Cancel";
// import FolderIcon from "@mui/icons-material/Folder";

// import axios from "axios";
// import { SERVER } from "store/actions/user.actions";
// import { getTokenCookie, Required, showToast } from "utils/tools";
// import moment from "moment";
// import { Typography } from "@mui/material";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import uploadFileAPI from "utils/fileupload";
// import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload";
// import { useSelector } from "react-redux";

// import successIcon from "assets/images/check (3).svg";
// import SimpleBackdrop from "components/loadingScreen/Backdrop";
// import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg";
// import {
//   CheckUserAPI,
//   // InsertPensionAPI,
//   CheckUserAPIforBackLogFuneralAccidentDeath,
//   UpdatebacklogFuneralAccidentDeath,
//   CheckUserByMobileNoAPI,
// } from "services/CheckUserPension.service";
// import {
//   cityData,
//   districtData,
//   talukData,
//   villageData,
// } from "components/SevaSindhu/LabourDetails/AddressLogic";
// import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service";
// import { ContinuationPensionFileUpload } from "./FIleUpload";
// import {
//   BASE_URL,
//   // SERVER,
//   // addLabourPersonalDetails,
//   // getUserRegistrationDetails,
// } from "store/actions/user.actions";
// import { pl } from "date-fns/locale";
// import { set } from "store";


// const BacklogFormFuneral = () => {

//   const location = useLocation();
//   const { labourUserID, isEdited } = useParams();

//   const users = useSelector((state) => state.users);
//   const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id;
//   const [mobileNumber, setMobileNumber] = useState(null);
//   const [applicantName, setApplicantName] = useState(null);
//   const [deathAge, setdeathAge] = useState(null);
//   const [applicantAge, setApplicantAge] = useState(null);
//   const [deathPlace, setDeathPlace] = useState(null);
//   const [causeofDeath, setCauseofDeath] = useState(null);
//   const [relationship, setRelationship] = useState(null);
//   const [scheme_avail_id, setScheme_avail_id] = useState(null);
//   const [scheme_availed_id, setScheme_availed_id] = useState(null);
//   const [labour_userID, setlabour_userID] = useState(null);

//   const [labour_user_id, setLabour_user_id] = useState(null);

//   const [registrationNumber, setRegistrationNumber] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState(null);
//   const [permanentAddress, setPermanentAddress] = useState(null);


//   const [causeofDeathId, setCauseofDeathId] = useState(null);
//   const [deathPlaceId, setDeathPlaceId] = useState(null);
//   const [relationshipId, setRelationshipId] = useState(null);
//   const [applicantAgeId, setApplicantAgeId] = useState(null);
//   const [applicantNameId, setApplicantNameId] = useState(null);
//   const [permanentAddressId, setPermanentAddressId] = useState(null);
//   const [registrationDateId, setRegistrationDateId] = useState(null);
//   const [dodId, setDodId] = useState(null);
//   const [deathAgeId, setDeathAgeId] = useState(null);
//   const [mobileNumberId, setMobileNumberId] = useState(null);
//   const [referenceNumberId, setReferenceNumberId] = useState(null);
//   const [department_user_id, setDepartment_user_id] = useState(null);
//   console.log(department_user_id, "department_user_id")
//   // const [stateID, setStateID] = useState(null);
//   // const [districtID, setDistrictID] = useState(null);
//   // const [talukID, setTalukID] = useState(null);
//   // const [cityID, setCityID] = useState(null);
//   // const [villageID, setVillageID] = useState(null);
//   // const [circleID, setCircleID] = useState(null);

//   // const [stateName, setStateName] = useState(null);
//   // const [districtName, setDistrictName] = useState(null);
//   // const [talukName, setTalukName] = useState(null);
//   // const [cityName, setCityName] = useState(null);
//   // const [villageName, setVillageName] = useState(null);
//   // const [circleName, setCircleName] = useState(null);

//   // const [district, setDistrict] = useState(null);
//   // const [tauluk, setTaluk] = useState(null);
//   // const [city, setCity] = useState(null);
//   // const [village, setVillage] = useState(null);
//   // const [circle, setCircle] = useState(null);

//   const [dod, setDod] = useState(null);

//   // const [pensionAvailDate, setPensionAvaliDate] = useState(null);
//   // const [pensionRecievedDate, setPensionRecievedDate] = useState(null);

//   const [registrationDate, setRegistrationDate] = useState(null);
//   // const [validityFromDate, setValidityFromDate] = useState(null);
//   // const [validityToDate, setValidityToDate] = useState(null);

//   const [isVerified, setIsVerified] = useState(false);
//   const [isMobileVerified, setIsMobileVerified] = useState(false);
//   const [isUserVerified, setIsUserVerified] = useState(false);
//   const [isAadhaarVerified, setisAadhaarVerified] = useState(false)
//   const [loading, setLoading] = useState(false);
//   const [numberLoading, setNumberLoading] = useState(false);

//   const [submitLoader, setSubmitLoader] = useState(false);

//   const labourCardFileRef = useRef(null);
//   const benificiaryaadharFileRef = useRef(null);
//   const nomineeaadhaarFileRef = useRef(null);
//   const deathcertificateFileRef = useRef(null);

//   const [nomineeaadhaar, setnomineeaadhaar] = useState([]);
//   // const benificiaryaadhar = useRef(null);


//   const [labourCardDoc, setLabourCardDoc] = useState([]);
//   const [nomineeaadhaarCardDoc, setnomineeaadhaarCardDoc] = useState([]);
//   const [deathcertificateDoc, setdeathcertificateDoc] = useState([]);
//   const [benificiaryaadharDoc, setbenificiaryaadharDoc] = useState([]);




//   const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

//   const [isTransferred, setIsTransferred] = useState(false);
//   const [isDataFound, setIsDataFound] = useState(false);
//   const [circleData, setCircleData] = useState(null);

//   const [sevaSindhuID, setSevaSindhuID] = useState(null);

//   // const [gender, setGender] = useState(null);

//   // const [dataUri, setDataUri] = useState('');

//   // Address Drop Down API's CALL

//   // useEffect(() => {
//   //   if (deptUserID && !isDataFound) {
//   //     axios
//   //       .get(`${SERVER}/admin/alc-district?departmentUserID=${deptUserID}`, {
//   //         headers: {
//   //           Authorization: `Bearer ${getTokenCookie()}`,
//   //         },
//   //       })
//   //       .then((res) => {
//   //         setDistrict(res.data.data);
//   //       })
//   //       .catch((err) => {
//   //         console.log(err);
//   //       });
//   //   }
//   // }, [deptUserID, isDataFound]);

//   // useEffect(() => {
//   //   if (districtID) {
//   //     axios
//   //       .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
//   //       .then((res) => {
//   //         setTaluk(res.data.data);
//   //       })
//   //       .catch((err) => {
//   //         console.log(err);
//   //       });
//   //   }
//   // }, [districtID]);

//   // useEffect(() => {
//   //   if (talukID) {
//   //     axios
//   //       .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
//   //       .then((res) => {
//   //         setCity(res.data.data);
//   //       })
//   //       .catch((err) => {
//   //         console.log(err);
//   //       });
//   //   }
//   // }, [talukID]);

//   // useEffect(() => {
//   //   if (cityID) {
//   //     axios
//   //       .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
//   //       .then((res) => {
//   //         setVillage(res.data.data);
//   //       })
//   //       .catch((err) => {
//   //         console.log(err);
//   //       });
//   //   }
//   // }, [cityID]);

//   // useEffect(() => {
//   //   if (districtID && talukID && cityID) {
//   //     const circlePayload = {
//   //       district_id: districtID,
//   //       taluk_id: null,
//   //       gram_panchayat_id: null,
//   //       village_area_ward: null,
//   //     };
//   //     axios
//   //       .post(`${SERVER}/config/circle/get_circles_on_area`, circlePayload, {
//   //         headers: {
//   //           Authorization: `Bearer ${getTokenCookie()}`,
//   //         },
//   //       })
//   //       .then((res) => {
//   //         // setCircleID(res.data.data);

//   //         const filteredArr = res.data.data.reduce((acc, current) => {
//   //           const x = acc.find((item) => item.circle_id === current.circle_id);
//   //           if (!x) {
//   //             return acc.concat([current]);
//   //           } else {
//   //             return acc;
//   //           }
//   //         }, []);

//   //         setCircle(filteredArr);
//   //       });
//   //   }
//   // }, [districtID, talukID, cityID]);

//   // const verifyAadharNo = async () => {
//   //   setisAadhaarVerified(true);


//   // }

//   const [showProfileImage, setShowProfileImage] = useState(null);
//   const [imageEKYCID, setImageEKYCID] = useState(null);

//   const [vaultRefNumber, setVaultRefNumber] = useState(null);

//   const aadhaar = async () => {
//     // useEffect(() => {

//     const currentUrl = window.location.toString();
//     // const queryString = new URLSearchParams(location.search);
//     // if (currentUrl.includes("Success")) {
//     // const vaultRefNo = queryString.get("vaultrefno");
//     const vaultRefNo = '4736068402104452';
//     // if (vaultRefNo) {
//     setVaultRefNumber(vaultRefNo);
//     callGetUserOnVaultReferenceNumber(vaultRefNo);
//     // }
//     // } else if (currentUrl.includes("Failure")) {
//     //   showToast("ERROR", "Aadhaar Verification failed, please try again.");
//     // }
//     // }, [location.search]);
//   }

//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//     const year = date.getFullYear();

//     return `${day}/${month}/${year}`;
//   }


//   const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
//     try {
//       const payLoad = {
//         key: "aadhar_no",
//         value: vaultRefNo,
//         board_id: 1,
//       };

//       // const response = await getUserOnVaultReferenceNumber(payLoad);

//       // if (response.data.length !== 0) {
//       //   showToast(
//       //     "ERROR",
//       //     "Aadhaar already registered with other mobile number"
//       //   );

//       //   return;
//       // }

//       const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);

//       console.log("aadhaarData", aadhaarData);
//       // const updateEKYCPayLoad = {
//       //   labourUserId: +labourUserID,
//       //   aadharNo: vaultRefNo,
//       // };

//       if (aadhaarData.length > 0) {
//         const aadhaarDOB =
//           aadhaarData && moment().diff(aadhaarData[0].dob, "years");
//         if (aadhaarDOB >= 18 && aadhaarDOB < 60) {
//           // const updateEKYCResponse =
//           //   await updateEKYCVaultRefNumber(updateEKYCPayLoad);
//           // if (updateEKYCResponse.success) {
//           showToast("SUCCESS", "Aadhaar Verified Successfully");
//           // Allow
//           setShowProfileImage(
//             `data:image/png;base64,${aadhaarData[0].document_link}`
//           );
//           // setImageEKYCID(aadhaarData[0].image);
//           // Split into FirstName,MiddleName,LastName
//           // console.log('ghg', data[0].dob && moment(data[0].dob, 'YYYY-MM-DD'));
//           // var first_name = "";
//           // var middle_name = "";
//           // var last_name = "";
//           // var nameArr = aadhaarData[0].name.split(" ");

//           // if (nameArr.length === 1) {
//           //   first_name = nameArr[0];
//           // } else if (nameArr.length === 2) {
//           //   first_name = nameArr[0];
//           //   last_name = nameArr[1];
//           // } else if (nameArr.length >= 3) {
//           //   first_name = nameArr[0];
//           //   middle_name = nameArr[1];
//           //   last_name = nameArr[2];
//           // }
//           // const fullName = data[0].name.split(' ');
//           // const lastName = fullName.pop(); // 'Panakkal'
//           // const middleName = fullName.pop();
//           // const firstName = fullName.join(' ');
//           // const deathAge = new Date(dod).getFullYear() - new Date(aadhaarDOB).getFullYear() - (new Date(dod).getMonth() < new Date(aadhaarDOB).getMonth() || (new Date(dod).getMonth() === new Date(aadhaarDOB).getMonth() && new Date(dod).getDate() < new Date(aadhaarDOB).getDate()) ? 1 : 0);

//           setisAadhaarVerified(true);
//           // setApplicantName(first_name + last_name);
//           // if (deathAge) {
//           //   setdeathAge(deathAge);
//           // }
//           // setDob(moment().diff(aadhaarData[0].dob, "years"));
//           // setBirthPlace(aadhaarData[0].co);
//           // setPermanentAddress(aadhaarData[0].house_no + ',' + aadhaarData[0].street + ',' + aadhaarData[0].locality + ',' + aadhaarData[0].landmark + ',' + aadhaarData[0].village_city_town + ',' + aadhaarData[0].district + ',' + aadhaarData[0].sub_district + ',' + aadhaarData[0].state + ',' + aadhaarData[0].pincode);
//           // setPermanentAddress(
//           //   [
//           //     aadhaarData[0].house_no,
//           //     aadhaarData[0].street,
//           //     aadhaarData[0].locality,
//           //     aadhaarData[0].landmark,
//           //     aadhaarData[0].village_city_town,
//           //     aadhaarData[0].district,
//           //     aadhaarData[0].sub_district,
//           //     aadhaarData[0].state,
//           //     aadhaarData[0].pincode
//           //   ]
//           //     .filter(Boolean) // Removes undefined, null, or empty values
//           //     .join(', ') // Joins non-empty values with a comma and space
//           // );

//           // setPersonalFields((prev) => ({
//           //   ...prev,
//           //   first_name: aadhaarData[0].name,
//           // }));
//           // setDob(aadhaarData[0].dob && moment(aadhaarData[0].dob));

//           // setPersonalFields((prev) => ({
//           //   ...prev,
//           //   gender:
//           //     aadhaarData[0].gender === "M"
//           //       ? 27
//           //       : aadhaarData[0].gender === "F"
//           //         ? 28
//           //         : 29,
//           // }));
//           // }
//         } else {
//           showToast(
//             "ERROR",
//             `Your age is ${aadhaarDOB}. As per the KBOCWWB ACT you are not eligible for the registration`
//           );
//         }
//       } else {
//         setisAadhaarVerified(false);
//         showToast("ERROR", "Aadhaar Verification failed, please try again. ");
//       }
//     } catch (error) {
//       showToast("ERROR", "Something went wrong");
//     }
//   };




//   const handleVerifyAadhaar = async () => {
//     try {
//       //Production Data

//       const payLoad = {
//         responseRedirectURL: `${BASE_URL}/profile/personal/${labourUserID}`,
//       };

//       // Local Production Data

//       // const payLoad = {
//       //   responseRedirectURL: `http://localhost:3000/profile/personal/${labourUserID}`,
//       // };

//       // DEV DATA
//       //  const payLoad = {
//       //    responseRedirectURL: `http://gcdmsdbac.hostg.in:5353/profile/personal/${labourUserID}`,
//       //  };

//       const response = await EKYCWEBHOOK(payLoad);

//       const { token } = response.data;
//       // const {token} = response.data.token;

//       // window.open(
//       //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
//       //   '_self',
//       // );
//       // window.open(
//       //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
//       //   "_self"
//       // );
//       aadhaar();

//     } catch (error) {
//       showToast(
//         "ERROR",
//         "Something went wrong! Please try again after some time."
//       );
//     }
//   };

//   const history = useHistory(); // Move useHistory to the component scope

//   const verifySevaSindhu = async () => {
//     // console.log("verifying seva sindhu");
//     if (registrationNumber && referenceNumber) {
//       setLoading(true);
//       const payload = {
//         regNo: registrationNumber,
//         refNo: referenceNumber,
//       };
//       try {
//         const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload);
//         console.log("data**********", data);
//         setLoading(false);
//         setIsUserVerified(true);
//         if (data && data?.length > 0) {
//           if (data[0]?.department_user_id !== deptUserID) {
//             showToast(
//               "ERROR",
//               "Application cannot be submitted under this jurisdiction"
//             );
//             setIsUserVerified(false);
//             setIsVerified(false);
//             return;
//           }
//           if (data[0]?.labour_user_id) {
//             showToast("ERROR", "Application has already been submited.");
//             setIsUserVerified(false);
//             setIsVerified(false);
//             return;
//           }

//           // if (data[0]?.is_transferred === true) {
//           setIsVerified(true);
//           // setIsTransferred(data[0]?.is_transferred);
//           // setMobileNumber(data[0]?.mobileno);
//           // setDob(data[0]?.DOB);
//           // setDod(data[0]?.DOD);
//           // setStateName(data[0]?.state_name);
//           // setDistrictName(data[0]?.district_name);
//           // setTalukName(data[0]?.taluk_name);
//           // setCityName(data[0]?.city_town_gram_panchayat_name);
//           // setVillageName(data[0]?.village_area_name);

//           // setPermanentAddress(data[0]?.permanent_address);
//           // }
//           setIsDataFound(false);
//           // console.log("data", data[0]?.data[0], "******************");
//           // console.log("data[0]?.data[0].field_value", data[0]?.data[0].field_value);

//           // if (data[0]?.data[0].field == 'Cause of death') {
//           //   setCauseofDeath(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field == 'Death Age') {
//           //   setdeathAge(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field == 'Place of death') {
//           //   setDeathPlace(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field == 'Date of death') {
//           //   setDod(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field === 'Age at the time of death') {
//           //   setApplicantAge(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field == 'Age of Applicant') {
//           //   setApplicantName(data[0]?.data[0].field_value);
//           // } else if (data[0]?.data[0].field == 'Permanent Address') {
//           //   setPermanentAddress(data[0]?.data[0].field_value);
//           // }

//           const loop = data[0]?.data.map((item) => {
//             if (item.field === "Cause of death") {
//               setCauseofDeath(item.field_value);
//               setCauseofDeathId(item.field_id);
//             }
//             if (item.field === "Place of death") {
//               setDeathPlace(item.field_value);
//               setDeathPlaceId(item.field_id);
//             }
//             if (item.field === "Date of death") {
//               setDod(item.field_value);
//               setDodId(item.field_id);
//             }
//             if (item.field === "Age at the time of death") {
//               setdeathAge(item.field_value);
//               setDeathAgeId(item.field_id);
//             }
//             if (item.field === "Age of Applicant") {
//               setApplicantAge(item.field_value);
//               setApplicantAgeId(item.field_id);
//             }

//             if (item.field === "Name of the Nominee") {
//               setApplicantName(item.field_value);
//               setApplicantNameId(item.field_id);
//             }

//             if (item.field === "Permanent Address") {
//               setPermanentAddress(item.field_value);
//               setPermanentAddressId(item.field_id);
//             }

//             if (item.field === "Relationship with Beneficiary") {
//               setRelationship(item.field_value);
//               setRelationshipId(item.field_id);
//             }


//           });
//           setScheme_avail_id(data[0]?.scheme_availed_id);
//           setLabour_user_id(data[0]?.labour_user_id);
//           setDepartment_user_id(data[0]?.department_user_id);
//           // console.log("loop", loop);
//           // setCauseofDeath(data[0]?.data[0].causeofDeath);
//           // setdeathAge(data[0]?.data[0].deathAge);
//           // setDeathPlace(data[0]?.data[0].deathPlace);


//           // setCircleName(data[0]?.circle_name);
//           // setApplicantName(data[0]?.applicantname);
//           // setBirthPlace(data[0]?.deathPlace);
//           // setCauseofDeath(data[0]?.data[0].causeofDeath);
//           // setdeathAge(data[0]?.deathAge);
//           // setApplicantAge(data[0]?.applicantAge);
//           callGetLocationDetails(data[0]?.submissionlocation);
//           setRegistrationDate(data[0]?.registrationdate);
//           // setValidityFromDate(data[0]?.validity_fromdate);
//           // setValidityToDate(data[0]?.validity_todate);
//           setSevaSindhuID(data[0]?.id);
//           // setGender(data[0]?.gender);
//           // setCircleID(data[0]?.circle_id);
//         }
//       } catch (error) {
//         setLoading(false);
//         if (error?.response?.data?.errors?.msg) {
//           showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
//         }
//         showToast("ERROR", "Something went wrong");
//       }
//     } else {
//       showToast("ERROR", "Please enter registration and reference number");
//     }
//   };


//   // const verifySevaSindhu = async () => {
//   //   if (registrationNumber && referenceNumber) {
//   //     setLoading(true);
//   //     const payload = {
//   //       regNo: registrationNumber,
//   //       refNo: referenceNumber,
//   //     };
//   //     try {
//   //       const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload);
//   //       console.log("data**********", data);
//   //       setLoading(false);
//   //       setIsUserVerified(true);
//   //       if (data && data?.length > 0) {
//   //         history.push(`/newSchemeForm/${data[0]?.labour_user_id}/15/Funeral Expense and Death Assistance/1/false/false/false/0/0/1/0`); // Use the history instance here
//   //       }

//   //       // path = "/newSchemeForm/:labourUserID/:schemeID/:schemeName/:roleID/:renewal?/:child?/:death?/:thayimaguSchemeAvailID?/:renewalSequence?/:totalCountAvailed?/:totalAvailedByUser?"
//   //       // http://localhost:3000/view/schemeack/Funeral%20Expense%20and%20Death%20Assistance/3405780/1043643
//   //     } catch (error) {
//   //       setLoading(false);
//   //       if (error?.response?.data?.errors?.msg) {
//   //         showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
//   //       }
//   //       showToast("ERROR", "Something went wrong");
//   //     }
//   //   } else {
//   //     showToast("ERROR", "Please enter registration and reference number");
//   //   }
//   // };

//   const verifyMobileNumber = async () => {
//     if (mobileNumber) {
//       setNumberLoading(true);

//       const pensionDetails = await CheckUserByMobileNoAPI({
//         mobile: mobileNumber,
//       });

//       if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
//         setNumberLoading(false);
//         setIsMobileVerified(false);
//         setIsVerified(false);
//         showToast("ERROR", "The Mobile Number is Already Manually Registered");
//         return;
//       }

//       axios
//         .get(`${SERVER}/user/${mobileNumber}`)
//         .then((res) => {
//           setNumberLoading(false);
//           setIsMobileVerified(false);
//           setIsVerified(false);
//           showToast("ERROR", "Mobile Number Already Registered, Please Login!");
//         })
//         .catch((err) => {
//           if (err.response.data.error) {
//             setNumberLoading(false);
//             setIsMobileVerified(true);
//             setIsVerified(true);
//           }
//         });
//     } else {
//       showToast("ERROR", "Please enter mobile number");
//     }
//   };

//   const handleReset = () => {
//     setMobileNumber("");
//     setApplicantName("");
//     setDeathPlace("");
//     setCauseofDeath("");
//     setdeathAge("");
//     setApplicantAge("");
//     setRegistrationNumber("");
//     setReferenceNumber("");
//     setDod(null);
//     setPermanentAddress("");
//     // setPensionAvaliDate(null);
//     // setPensionRecievedDate(null);

//     setnomineeaadhaarCardDoc([]);
//     setLabourCardDoc([]);
//     setnomineeaadhaar([]);
//     setdeathcertificateDoc([]);
//     setbenificiaryaadharDoc([]);
//     setIsVerified(false);
//     setIsMobileVerified(false);
//     setIsUserVerified(false);
//     setisAadhaarVerified(false);
//     setIsSubmitSuccess(false);
//   };
//   /* FILE UPLOAD CHANGE */
//   const handleFileChange = (ev) => {
//     const files = Array.from(ev.target.files);
//     const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

//     if (!files.every(file => validTypes.includes(file.type))) {
//       showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
//       return;
//     }

//     if (!files.every(file => file.size < 2000001)) {
//       showToast("ERROR", "Please upload file size less than 2MB.");
//       return;
//     }

//     switch (ev.target.name) {
//       case 'nomineeaadhaar':
//         setnomineeaadhaarCardDoc(prev => [...prev, ...files]);
//         break;
//       case 'labourPhoto':
//         setnomineeaadhaar(prev => [...prev, ...files]);
//         break;
//       case 'labourCard':
//         setLabourCardDoc(prev => [...prev, ...files]);
//         break;
//       case 'deathcertificate':
//         setdeathcertificateDoc(prev => [...prev, ...files]);
//         break;
//       case 'benificiaryaadhar':
//         setbenificiaryaadharDoc(prev => [...prev, ...files]);
//         break;
//     }
//   };


//   const handleRemoveFile = (index, documentType) => {
//     if (documentType === "labourCard") {
//       const newFiles = [...labourCardDoc];
//       newFiles.splice(index, 1);
//       setLabourCardDoc(newFiles);
//       labourCardFileRef.current.value = "";
//     } else if (documentType === "aadhaarCard") {
//       const newFiles = [...nomineeaadhaarCardDoc];
//       newFiles.splice(index, 1);
//       setnomineeaadhaarCardDoc(newFiles);
//       nomineeaadhaarFileRef.current.value = "";
//     } else if (documentType === "deathcertificate") { // Fixed document type
//       const newFiles = [...deathcertificateDoc];
//       newFiles.splice(index, 1);
//       setdeathcertificateDoc(newFiles);
//       deathcertificateFileRef.current.value = "";
//     } else if (documentType === "benificiaryaadhar") {
//       const newFiles = [...benificiaryaadharDoc];
//       newFiles.splice(index, 1);
//       setbenificiaryaadharDoc(newFiles);
//       benificiaryaadharFileRef.current.value = "";
//     }
//   };


//   const updateFuneralData = async (
//     labourCardDoc,
//     nomineeaadhaarCardDoc,
//     benificiaryaadharDoc,
//     deathcertificateDoc
//   ) => {
//     const payload = {
//       boardID: 1,
//       departmentUserID: +department_user_id,
//       sevasindhuID: +sevaSindhuID,
//       mobile: +mobileNumber,
//       regNo: registrationNumber,
//       refNo: referenceNumber,
//       registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
//       applicantName: applicantName,
//       dod: moment(dod).format("YYYY-MM-DD"),
//       causeofDeath: causeofDeath,
//       deathAge: +deathAge,
//       deathPlace: deathPlace,
//       relationship: relationship,
//       applicantAge: +applicantAge,
//       scheme_avail_id: +scheme_avail_id,
//       labour_user_id: +labour_user_id,
//       permanentAddress: permanentAddress,

//       placeofDeathId: deathPlaceId,
//       causeofDeathId: causeofDeathId,
//       relationshipId: relationshipId,
//       applicantAgeId: applicantAgeId,
//       applicantNameId: applicantNameId,
//       permanentAddressId: permanentAddressId,
//       registrationDateId: registrationDateId,
//       dodId: dodId,
//       deathAgeId: deathAgeId,
//       mobileNumberId: mobileNumberId,
//       referenceNumberId: referenceNumberId,

//       nomineeaadhaar: nomineeaadhaar,
//       labourCard: labourCardDoc,
//       deathCertificate: deathcertificateDoc,
//       benificiaryaadhar: benificiaryaadharDoc,
//       nomineeaadhaarCardDoc: nomineeaadhaarCardDoc
//     };

//     try {
//       console.log(payload, 'payload**************')
//       const response = await UpdatebacklogFuneralAccidentDeath(payload);
//       console.log(response, 'response**************')
//       setSubmitLoader(false);
//       if (response.success) {
//         setScheme_availed_id(response.data[0].data[0].scheme_availed_id);
//         setlabour_userID(response.data[0].data[0].labour_user_id);
//         setOpenBackdrop(true);

//         setIsSubmitSuccess(true);
//         showToast(
//           "SUCCESS",
//           "Application Submitted successfully."
//         );
//       } else {
//         showToast("ERROR", "Application Submission Failed");
//       }
//     } catch (err) {
//       setSubmitLoader(false);
//       if (err?.response?.data?.errors?.msg) {
//         showToast("ERROR", `${err?.response?.data?.errors?.msg}`);
//       } else {
//         showToast("ERROR", "Something went wrong");
//       }
//     }
//   };

//   const handleSubmitData = async (ev) => {
//     if (
//       mobileNumber &&
//       applicantName &&
//       dod &&
//       permanentAddress &&
//       causeofDeath &&
//       applicantAge &&
//       deathPlace &&
//       deathAge &&
//       relationship &&
//       labourCardDoc &&
//       labourCardDoc.length > 0 &&
//       nomineeaadhaarCardDoc.length > 0 &&
//       nomineeaadhaarCardDoc &&
//       deathcertificateDoc.length > 0 &&
//       deathcertificateDoc &&
//       benificiaryaadharDoc.length > 0 &&
//       benificiaryaadharDoc
//     ) {
//       setSubmitLoader(true);

//       try {
//         // Create separate FormData for each file type
//         const uploadFiles = await backlogFuneralAccidentDeathFileUpload(
//           labourCardDoc,
//           nomineeaadhaarCardDoc,
//           benificiaryaadharDoc,
//           deathcertificateDoc,

//         );

//         if (uploadFiles.success) {
//           // Log the files before updating to verify correct structure

//           await updateFuneralData(
//             uploadFiles.labourCardDoc,
//             uploadFiles.nomineeaadhaarCardDoc,
//             uploadFiles.benificiaryaadharDoc,
//             uploadFiles.deathcertificateDoc, // Make sure this is used correctly
//             causeofDeath,
//             deathAge,
//             deathPlace,
//             dod,
//             relationship,
//             applicantAge,
//             mobileNumber,
//             applicantName,
//             permanentAddress,
//             sevaSindhuID,
//             registrationNumber,
//             referenceNumber,
//             registrationDate
//           );
//         } else {
//           showToast("ERROR", "File upload failed");
//         }
//       } catch (error) {
//         console.error('Upload error:', error);
//         showToast("ERROR", "Error uploading files");
//       } finally {
//         setSubmitLoader(false);
//       }
//     } else {
//       showToast("ERROR", "Please fill all the mandatory details");
//     }
//   };


//   const callGetLocationDetails = async (submissionLocation) => {
//     try {
//       const payLoad = {
//         ref_seva_sindhu_circle_id: +submissionLocation,
//       };
//       const response = await getSubmissionLocationAPI(payLoad);
//       if (response.success) {
//         setCircleData(response.data);
//       }
//     } catch (error) {
//       showToast("ERROR", "Something went wrong");
//     }
//   };

//   // useEffect(() => {
//   //   if ((isTransferred === null || isTransferred === false) && circleData) {
//   //     console.log('called');
//   //     const _districtData = districtData(circleData && circleData);

//   //     setDistrict(_districtData);
//   //     const _talukData = talukData(circleData && circleData);
//   //     setTaluk(_talukData);
//   //     const _villageData = villageData(circleData && circleData);
//   //     setVillage(_villageData);

//   //     const _cityData = cityData(circleData && circleData);

//   //     if (talukID) {
//   //       const result = _cityData.filter((_data) => _data.taluk_id === talukID);
//   //       setCity(result);
//   //     }
//   //     if (cityID) {
//   //       const result = _villageData.filter(
//   //         (_data) => _data.gram_panchayat_city_town_id === cityID,
//   //       );

//   //       setVillage(result);
//   //     }
//   //   }
//   // }, [isTransferred, circleData, talukID, cityID]);


//   const useStyles = makeStyles((theme) => ({
//     backdrop: {
//       zIndex: theme.zIndex.drawer + 1,
//       backgroundColor: "#ffffffee",
//       color: "#000",
//       display: "block",
//       textAlign: "center",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//     },
//     backdropCheck: {
//       zIndex: theme.zIndex.drawer + 1,
//       color: "#6CC17A",
//     },
//     backdropCheckp: {
//       fontSize: "30px",
//       fontWeight: "bold",
//     },
//   }));

//   const classes = useStyles();

//   const [openBackdrop, setOpenBackdrop] = useState(true);

//   const handleCloseBackdrop = () => {
//     setOpenBackdrop(false);
//   };

//   return (
//     <>
//       {/* {submitLoader && <SimpleBackdrop />} */}

//       {submitLoader ? <SimpleBackdrop /> : null}
//       {isSubmitSuccess ? (
//         <Backdrop
//           className={classes.backdrop}
//           open={openBackdrop}
//           onClick={handleCloseBackdrop}
//         >
//           <div style={{ display: 'block' }}>
//             <CheckCircle
//               className={classes.backdropCheck}
//               style={{ height: 'auto', width: '200px' }}
//             />
//             <p className="final-success-title">
//               Successfully submitted for
//               <br />
//               Scheme!
//             </p>
//             <Link
//               to={`/view/schemeack/Funeral Expense and Death Assistance/${labour_userID}/${scheme_availed_id}`}
//               style={{ flexGrow: '0.5' }}
//               target="_blank"
//             >
//               <p className="final-success-link-p">
//                 View Acknowledgement Letter{' '}
//                 <span variant="outline-primary" className="final-success-link">
//                   {' '}
//                   Click Here
//                 </span>
//               </p >
//             </Link >
//           </div >
//         </Backdrop >
//       ) : null}

//       <div>
//         <div className='root'>
//           <Row className='top-div '>
//             <Row className='topbar-row'>
//               <Col xs={12} md={4} lg={6} className='top-bar-col'>
//                 <div className='logo-div-profile'>
//                   <a href='/admin/dashboard/20'>
//                     <img id='logo' src={logo} alt='...' className='logo-img' />
//                   </a>
//                   <h1 className='logo-text'>
//                     {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
//                     <Translate value='header.title' /> <br />
//                     <Translate value='header.sub' />
//                   </h1>
//                 </div>
//               </Col>
//             </Row>
//           </Row>
//         </div>
//         <div className='d-flex justify-content-start align-items-start ps-3 mt-3'>
//           <Link to={"/admin/dashboard/20"}>
//             <img src={backIcon} alt='' />
//           </Link>
//         </div>
//         <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
//           {!isSubmitSuccess && (
//             <div className='d-flex justify-content-start align-items-center gap-5'>
//               <Col md={6}>
//                 <TextField
//                   className='w-100'
//                   id='standard-basic'
//                   label='Registration number'
//                   variant='standard'
//                   value={registrationNumber}
//                   disabled={isUserVerified}
//                   // inputProps={{ minLength: 10, maxLength: 10 }}
//                   onChange={(ev) => setRegistrationNumber(ev.target.value)}
//                 />
//               </Col>
//               <Col md={6}>
//                 <TextField
//                   className='w-100'
//                   id='standard-basic'
//                   label='Reference number'
//                   variant='standard'
//                   value={referenceNumber}
//                   disabled={isUserVerified}
//                   // inputProps={{ minLength: 10, maxLength: 10 }}
//                   onChange={(ev) => setReferenceNumber(ev.target.value)}
//                 />
//               </Col>
//               <Col className='mt-3 d-flex'>
//                 {loading ? (
//                   <Box sx={{ display: "flex" }}>
//                     <CircularProgress />
//                   </Box>
//                 ) : (
//                   <Button
//                     disabled={
//                       !registrationNumber || !referenceNumber || isUserVerified
//                     }
//                     onClick={verifySevaSindhu}
//                     variant={isUserVerified ? "success" : "primary"}
//                     className='mx-3'
//                   >
//                     {isUserVerified ? "Verified" : "Verify"}
//                   </Button>
//                 )}

//                 <Button
//                   disabled={!registrationNumber || !referenceNumber}
//                   onClick={handleReset}
//                   variant='primary'
//                 >
//                   {"Reset"}
//                 </Button>
//               </Col>
//             </div>
//           )}
//         </div>



//         {isUserVerified && (
//           <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
//             {!isSubmitSuccess && (
//               <div className='d-flex justify-content-start align-items-center gap-5 w-100'>
//                 <Col md={3}>
//                   <TextField
//                     className='w-100'
//                     id='standard-basic'
//                     label='Nominee Mobile number'
//                     variant='standard'
//                     value={mobileNumber}
//                     disabled={isMobileVerified || isTransferred}
//                     inputProps={{ minLength: 10, maxLength: 10 }}
//                     onChange={(ev) =>
//                       setMobileNumber(ev.target.value.replace(/[^0-9]/g, ""))
//                     }
//                   />
//                 </Col>
//                 {!isTransferred && (
//                   <Col md={3} className='mt-3 d-flex'>
//                     {numberLoading ? (
//                       <Box sx={{ display: "flex" }}>
//                         <CircularProgress />
//                       </Box>
//                     ) : (
//                       <Button
//                         disabled={
//                           (mobileNumber && mobileNumber.length < 10) ||
//                           isMobileVerified
//                         }
//                         onClick={verifyMobileNumber}
//                         variant={isMobileVerified ? "success" : "primary"}
//                         className=''
//                       >
//                         {isMobileVerified ? "Verified" : "Verify"}
//                       </Button>
//                     )}

//                     {/* <Button
//                       disabled={!mobileNumber}
//                       onClick={handleReset}
//                       variant="primary"
//                     >
//                       {'Reset'}
//                     </Button> */}
//                   </Col>
//                 )}
//               </div>
//             )}
//           </div>
//         )}


//         {isUserVerified && (
//           <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
//             {!isSubmitSuccess && (
//               <div className='d-flex justify-content-start align-items-center gap-5 w-100'>
//                 <Col md={3}>
//                   <TextField
//                     className='w-100'
//                     id='standard-basic'
//                     label='Verify Nominee Aadhar No'
//                     variant='standard'
//                     value={nomineeaadhaar}
//                     disabled={isAadhaarVerified}
//                     inputProps={{ minLength: 12, maxLength: 12 }}
//                     onChange={(ev) =>
//                       setnomineeaadhaar(ev.target.value.replace(/[^0-9]/g, ""))
//                     }
//                   />
//                 </Col>
//                 {!isTransferred && (
//                   <Col md={3} className='mt-3 d-flex'>
//                     {numberLoading ? (
//                       <Box sx={{ display: "flex" }}>
//                         <CircularProgress />
//                       </Box>
//                     ) : (
//                       <Button
//                         disabled={
//                           (nomineeaadhaar && nomineeaadhaar.length < 12) ||
//                           isAadhaarVerified
//                         }
//                         onClick={handleVerifyAadhaar}
//                         variant={isAadhaarVerified ? "success" : "primary"}
//                         className=''
//                       >
//                         {isAadhaarVerified ? "Verified" : "Verify"}
//                       </Button>
//                     )}

//                     {/* <Button
//                       disabled={!mobileNumber}
//                       onClick={handleReset}
//                       variant="primary"
//                     >
//                       {'Reset'}
//                     </Button> */}
//                   </Col>
//                 )}
//               </div>
//             )}
//           </div>
//         )}

//         <div className='d-flex justify-content-center align-items-center px-3 py-2'>
//           <div>
//             {isSubmitSuccess ? (
//               <>
//                 <div className='d-flex justify-content-center align-items-center flex-column'>
//                   <img width={80} height={80} src={successIcon} alt='...' />
//                   <h2 className='my-3'>Successfully Submitted</h2>
//                   <Button variant='primary' color='info' onClick={handleReset}>
//                     Click here to submit another application
//                   </Button>
//                 </div>
//               </>
//             ) : null}

//             <Row className='px-5 py-2'>
//               {/* <Col xs={12} md={4} className="mt-3"></Col> */}

//               <Row className='mt-3'>
//                 {!isSubmitSuccess && isVerified ? (
//                   <>
//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Name of the Nominee"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         // label="Name of the Beneficiary"
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={applicantName}
//                         onChange={(ev) => setApplicantName(ev.target.value)}
//                       />
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Place of Death"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={deathPlace}
//                         onChange={(ev) => setDeathPlace(ev.target.value)}
//                       />
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Cause of Death"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={causeofDeath}
//                         onChange={(ev) => setCauseofDeath(ev.target.value)}
//                       />
//                     </Col>



//                     <Col xs={12} md={4}>
//                       <Required title={"Date of Death"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100 '
//                           // className="datepicker"
//                           // margin="normal"

//                           format='DD/MM/YYYY'

//                           maxDate={
//                             // !isTransferred &&
//                             moment(Date())
//                           }
//                           value={dod}
//                           onChange={(date) => {
//                             setDod(date);
//                           }}
//                           placeholder='DOD'
//                           disabled={isTransferred}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>


//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Age at the time of Death"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         // label="Name of the Beneficiary"
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={deathAge}
//                         onChange={(ev) => setdeathAge(ev.target.value)}
//                       />
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Age of Nominee"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         // label="Name of the Beneficiary"
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={applicantAge}
//                         onChange={(ev) => setApplicantAge(ev.target.value)}
//                       />
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required title={"Permanent address"} />

//                       <TextareaAutosize
//                         id='standard-basic'
//                         className='w-100'
//                         variant='standard'
//                         value={permanentAddress}
//                         disabled={isTransferred}
//                         onChange={(ev) => setPermanentAddress(ev.target.value)}
//                       />
//                     </Col>

//                     {/* •	Relationship with Beneficiary(Dropdown). */}

//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Relationship with Beneficiary"}
//                       />
//                       <Select
//                         className='w-100'
//                         value={relationship}
//                         onChange={(ev) => setRelationship(ev.target.value)}
//                       >
//                         <MenuItem value={relationship}>
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"Father"}>Father</MenuItem>
//                         <MenuItem value={"Mother"}>Mother</MenuItem>
//                         <MenuItem value={"Husband"}>Husband</MenuItem>
//                         <MenuItem value={"Wife"}>Wife</MenuItem>
//                         <MenuItem value={"Son"}>Son</MenuItem>
//                         <MenuItem value={"Daughter"}>Daughter</MenuItem>
//                         <MenuItem value={"Brother"}>Brother</MenuItem>
//                         <MenuItem value={"Sister"}>Sister</MenuItem>
//                         <MenuItem value={"Other"}>Other</MenuItem>
//                       </Select>
//                     </Col>


//                     <div className=''>
//                       <Row>
//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={<Translate value='Labour Card' />}
//                             />
//                             {labourCardDoc && labourCardDoc.length > 0 ? (
//                               labourCardDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "labourCard"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={labourCardFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='labourCard'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => labourCardFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>

//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={<Translate value='Nominee Aadhar' />}
//                             />
//                             {nomineeaadhaarCardDoc && nomineeaadhaarCardDoc.length > 0 ? (
//                               nomineeaadhaarCardDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "nomineeaadhaar"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={nomineeaadhaarFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='nomineeaadhaar'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => nomineeaadhaarFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={
//                                 <Translate value='Death Certificate' />
//                               }
//                             />
//                             {deathcertificateDoc && deathcertificateDoc.length > 0 ? (
//                               deathcertificateDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "deathcertificate"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={deathcertificateFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='deathcertificate' // Make sure this matches exactly
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => deathcertificateFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>




//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={
//                                 <Translate value='Benificiary Aadhar Number' />
//                               }
//                             />
//                             {benificiaryaadharDoc && benificiaryaadharDoc.length > 0 ? (
//                               benificiaryaadharDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "benificiaryaadhar"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={benificiaryaadharFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='benificiaryaadhar'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => benificiaryaadharFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>



//                       </Row>
//                     </div>
//                     <Col xs={12} md={6} className='w-100 text-center'>
//                       <p className='text-danger font-weight-bolder fs-5 my-4'>
//                         Note: Supported file format is JPG/JPEG/PNG, PDF and
//                         maximum file size is 2 MB.
//                       </p>
//                     </Col>
//                   </>
//                 ) : null}
//               </Row>

//               {!isSubmitSuccess && isVerified ? (
//                 <Row className='mt-5 d-flex justify-content-end w-100'>
//                   <Col
//                     xs={12}
//                     md={4}
//                     className='d-flex justify-content-end align-items-center gap-2'
//                   >
//                     <Button className='' variant='danger' size='lg'>
//                       <Link
//                         style={{ color: "inherit" }}
//                         to={"/admin/dashboard/20"}
//                       >
//                         Back
//                       </Link>
//                     </Button>

//                     <Button
//                       onClick={handleSubmitData}
//                       className=''
//                       variant='success'
//                       size='lg'
//                       disabled={submitLoader}
//                     >
//                       Submit
//                     </Button>
//                   </Col>
//                 </Row>
//               ) : null}
//             </Row>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default BacklogFormFuneral;



// "use client"

// import { useRef, useState, useEffect } from "react"
// import { Button, Col, Row } from "react-bootstrap"
// import logo from "assets/images/Karnataka_logo_100_Pixels.png"
// import { Translate } from "react-redux-i18n"
// import { CheckCircle } from "@material-ui/icons"
// import TextField from "@mui/material/TextField"
// import { makeStyles, Backdrop, IconButton, Select, MenuItem, CircularProgress } from "@material-ui/core"
// import { useHistory, useLocation, useParams, Link } from "react-router-dom"
// import { EKYCWEBHOOK } from "./Aadhaar/Aadhaar.service"
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
// import MomentUtils from "@date-io/moment"
// import CancelIcon from "@mui/icons-material/Cancel"
// import FolderIcon from "@mui/icons-material/Folder"
// import { SERVER, BASE_URL } from "store/actions/user.actions"
// import { Required, showToast } from "utils/tools"
// import moment from "moment"
// import { useSelector } from "react-redux"
// import SimpleBackdrop from "components/loadingScreen/Backdrop"
// import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg"
// import {
//   CheckUserAPIforBackLogFuneralAccidentDeath,
//   UpdatebacklogFuneralAccidentDeath,
//   CheckUserByMobileNoAPI,
// } from "services/CheckUserPension.service"
// import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service"
// import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload"
// import Box from "@mui/material/Box";

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     backgroundColor: "#ffffffee",
//     color: "#000",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   backdropCheck: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#6CC17A",
//   },
//   backdropCheckp: {
//     fontSize: "30px",
//     fontWeight: "bold",
//   },

//   'label + .css-1eed5fa-MuiInputBase-root-MuiInput-root': {
//     marginTop: '43px',
//   },
// }))

// const BacklogFormFuneral = () => {
//   const classes = useStyles()
//   const location = useLocation()
//   const history = useHistory()
//   const { labourUserID, isEdited } = useParams()

//   const users = useSelector((state) => state.users)
//   const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id

//   // State variables
//   const [registrationNumber, setRegistrationNumber] = useState("")
//   const [referenceNumber, setReferenceNumber] = useState("")
//   const [mobileNumber, setMobileNumber] = useState("")
//   const [applicantName, setApplicantName] = useState("")
//   const [deathAge, setDeathAge] = useState("")
//   const [applicantAge, setApplicantAge] = useState("")
//   const [deathPlace, setDeathPlace] = useState("")
//   const [causeofDeath, setCauseofDeath] = useState("")
//   const [relationship, setRelationship] = useState("")
//   const [scheme_avail_id, setScheme_avail_id] = useState(null)
//   const [scheme_availed_id, setScheme_availed_id] = useState(null)
//   const [labour_userID, setLabour_userID] = useState(null)
//   const [labour_user_id, setLabour_user_id] = useState(null)
//   const [permanentAddress, setPermanentAddress] = useState("")
//   const [dod, setDod] = useState(null)
//   const [registrationDate, setRegistrationDate] = useState(null)
//   const [isVerified, setIsVerified] = useState(false)
//   const [isMobileVerified, setIsMobileVerified] = useState(false)
//   const [isUserVerified, setIsUserVerified] = useState(false)
//   const [isAadhaarVerified, setIsAadhaarVerified] = useState(false)
//   const [loading, setLoading] = useState(false)
//   const [numberLoading, setNumberLoading] = useState(false)
//   const [submitLoader, setSubmitLoader] = useState(false)
//   const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
//   const [isDataFound, setIsDataFound] = useState(false)
//   const [circleData, setCircleData] = useState(null)
//   const [sevaSindhuID, setSevaSindhuID] = useState(null)
//   const [department_user_id, setDepartment_user_id] = useState(null)

//   // Beneficiary details
//   const [beneficiaryName, setBeneficiaryName] = useState("")
//   const [beneficiaryMobile, setBeneficiaryMobile] = useState("")
//   const [beneficiaryRegNo, setBeneficiaryRegNo] = useState("")
//   const [beneficiaryRefNo, setBeneficiaryRefNo] = useState("")
//   const [beneficiaryDOB, setBeneficiaryDOB] = useState(null)
//   const [beneficiaryAddress, setBeneficiaryAddress] = useState("")
//   const [beneficiaryRegDate, setBeneficiaryRegDate] = useState(null)
//   const [beneficiaryValidityFrom, setBeneficiaryValidityFrom] = useState(null)
//   const [beneficiaryValidityTo, setBeneficiaryValidityTo] = useState(null)
//   const [applicationDate, setApplicationDate] = useState(null)
//   // File upload refs
//   const labourCardFileRef = useRef(null)
//   const nomineeaadhaarFileRef = useRef(null)
//   const deathcertificateFileRef = useRef(null)
//   const benificiaryaadharFileRef = useRef(null)

//   // File upload states
//   const [labourCardDoc, setLabourCardDoc] = useState([])
//   const [nomineeaadhaarCardDoc, setNomineeaadhaarCardDoc] = useState([])
//   const [deathcertificateDoc, setDeathcertificateDoc] = useState([])
//   const [benificiaryaadharDoc, setBenificiaryaadharDoc] = useState([])

//   const [openBackdrop, setOpenBackdrop] = useState(true)

//   useEffect(() => {
//     if (beneficiaryDOB && dod) {
//       const birthDate = moment(beneficiaryDOB);
//       const deathDate = moment(dod);

//       // Calculate age at time of death
//       const ageAtDeath = deathDate.diff(birthDate, 'years');

//       if (ageAtDeath >= 0) {
//         setDeathAge(ageAtDeath.toString());
//       } else {
//         setDeathAge('');
//         showToast("ERROR", "Date of death cannot be before date of birth");
//       }
//     }
//   }, [beneficiaryDOB, dod]);

//   const handleCloseBackdrop = () => {
//     setOpenBackdrop(false)
//   }

//   const verifySevaSindhu = async () => {
//     if (registrationNumber && referenceNumber) {
//       setLoading(true)
//       const payload = {
//         regNo: registrationNumber,
//         refNo: referenceNumber,
//       }
//       try {
//         const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload)
//         setLoading(false)
//         setIsUserVerified(true)
//         if (data && data?.length > 0) {
//           if (data[0]?.department_user_id !== deptUserID) {
//             showToast("ERROR", "Application cannot be submitted under this jurisdiction")
//             setIsUserVerified(false)
//             setIsVerified(false)
//             return
//           }
//           if (data[0]?.labour_user_id && data[0]?.scheme_id != 15) {
//             showToast("ERROR", "Application has already been submitted.")
//             setIsUserVerified(false)
//             setIsVerified(false)
//             return
//           }

//           setIsVerified(true)
//           setIsDataFound(false)
//           console.log(data, "data for dob")

//           // Set beneficiary details
//           setBeneficiaryName(data[0]?.applicantname)
//           setBeneficiaryMobile(data[0]?.mobileno)
//           setMobileNumber(data[0]?.mobileno)
//           setBeneficiaryRegNo(data[0]?.regno)
//           setBeneficiaryRefNo(data[0]?.spdv_appl_ref_no)
//           setBeneficiaryDOB(data[0]?.DOB)
//           setBeneficiaryAddress(data[0]?.permanent_address)
//           setBeneficiaryRegDate(data[0]?.registrationdate)
//           setBeneficiaryValidityFrom(data[0]?.validity_fromdate)
//           setBeneficiaryValidityTo(data[0]?.validity_todate)
//           setApplicationDate(data[0]?.spdv_application_date)

//           // Set other form fields
//           data[0]?.data.forEach((item) => {
//             if (item.field === "Cause of death") setCauseofDeath(item.field_value)
//             if (item.field === "Place of death") setDeathPlace(item.field_value)
//             if (item.field === "Date of death") setDod(item.field_value)
//             if (item.field === "Age at the time of death") setDeathAge(item.field_value)
//             if (item.field === "Age of Applicant") setApplicantAge(item.field_value)
//             if (item.field === "Name of the Nominee") setApplicantName(item.field_value)
//             if (item.field === "Permanent Address") setPermanentAddress(item.field_value)
//             if (item.field === "Relationship with Beneficiary") setRelationship(item.field_value)
//           })

//           setScheme_avail_id(data[0]?.scheme_availed_id)
//           setLabour_user_id(data[0]?.labour_user_id)
//           setDepartment_user_id(data[0]?.department_user_id)
//           callGetLocationDetails(data[0]?.submissionlocation)
//           setRegistrationDate(data[0]?.registrationdate)
//           setSevaSindhuID(data[0]?.id)
//         }
//       } catch (error) {
//         setLoading(false)
//         if (error?.response?.data?.errors?.msg) {
//           showToast("ERROR", `${error?.response?.data?.errors?.msg}`)
//         }
//         showToast("ERROR", "Something went wrong")
//       }
//     } else {
//       showToast("ERROR", "Please enter registration and reference number")
//     }
//   }

//   const verifyMobileNumber = async () => {
//     if (mobileNumber) {
//       setNumberLoading(true)

//       const pensionDetails = await CheckUserByMobileNoAPI({
//         mobile: mobileNumber,
//       })

//       if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
//         setNumberLoading(false)
//         setIsMobileVerified(false)
//         setIsVerified(false)
//         showToast("ERROR", "The Mobile Number is Already Manually Registered")
//         return
//       }

//       try {
//         const response = await fetch(`${SERVER}/user/${mobileNumber}`)
//         if (response.ok) {
//           setNumberLoading(false)
//           setIsMobileVerified(false)
//           setIsVerified(false)
//           showToast("ERROR", "Mobile Number Already Registered, Please Login!")
//         } else {
//           setNumberLoading(false)
//           setIsMobileVerified(true)
//           setIsVerified(true)
//         }
//       } catch (err) {
//         setNumberLoading(false)
//         setIsMobileVerified(true)
//         setIsVerified(true)
//       }
//     } else {
//       showToast("ERROR", "Please enter mobile number")
//     }
//   }

//   const handleVerifyAadhaar = async () => {
//     try {
//       const payLoad = {
//         responseRedirectURL: `${BASE_URL}/profile/personal/${labourUserID}`,
//       }
//       const response = await EKYCWEBHOOK(payLoad)
//       const { token } = response.data
//       // Simulating Aadhaar verification for this example
//       setIsAadhaarVerified(true)
//       showToast("SUCCESS", "Aadhaar Verified Successfully")
//     } catch (error) {
//       showToast("ERROR", "Something went wrong! Please try again after some time.")
//     }
//   }

//   const handleFileChange = (ev) => {
//     const files = Array.from(ev.target.files)
//     const validTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

//     if (!files.every((file) => validTypes.includes(file.type))) {
//       showToast("ERROR", "Please upload file in PNG/JPEG or PDF")
//       return
//     }

//     if (!files.every((file) => file.size < 2000001)) {
//       showToast("ERROR", "Please upload file size less than 2MB.")
//       return
//     }

//     switch (ev.target.name) {
//       case "nomineeaadhaar":
//         setNomineeaadhaarCardDoc((prev) => [...prev, ...files])
//         break
//       case "labourCard":
//         setLabourCardDoc((prev) => [...prev, ...files])
//         break
//       case "deathcertificate":
//         setDeathcertificateDoc((prev) => [...prev, ...files])
//         break
//       case "benificiaryaadhar":
//         setBenificiaryaadharDoc((prev) => [...prev, ...files])
//         break
//     }
//   }

//   const handleRemoveFile = (index, documentType) => {
//     switch (documentType) {
//       case "labourCard":
//         setLabourCardDoc((prev) => prev.filter((_, i) => i !== index))
//         break
//       case "nomineeaadhaar":
//         setNomineeaadhaarCardDoc((prev) => prev.filter((_, i) => i !== index))
//         break
//       case "deathcertificate":
//         setDeathcertificateDoc((prev) => prev.filter((_, i) => i !== index))
//         break
//       case "benificiaryaadhar":
//         setBenificiaryaadharDoc((prev) => prev.filter((_, i) => i !== index))
//         break
//     }
//   }


//   const handleSubmitData = async () => {
//     if (
//       beneficiaryMobile &&
//       applicantName &&
//       dod &&
//       permanentAddress &&
//       causeofDeath &&
//       applicantAge &&
//       deathPlace &&
//       deathAge &&
//       relationship &&
//       labourCardDoc.length > 0 &&
//       nomineeaadhaarCardDoc.length > 0 &&
//       deathcertificateDoc.length > 0 &&
//       benificiaryaadharDoc.length > 0
//     ) {
//       setSubmitLoader(true)

//       try {
//         const uploadFiles = await backlogFuneralAccidentDeathFileUpload(
//           labourCardDoc,
//           nomineeaadhaarCardDoc,
//           benificiaryaadharDoc,
//           deathcertificateDoc,
//         )

//         if (uploadFiles.success) {
//           const payload = {
//             boardID: 1,
//             departmentUserID: +department_user_id,
//             sevasindhuID: +sevaSindhuID,
//             mobile: +beneficiaryMobile,
//             regNo: registrationNumber,
//             refNo: referenceNumber,
//             registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
//             applicantName: applicantName,
//             dod: moment(dod).format("YYYY-MM-DD"),
//             causeofDeath: causeofDeath,
//             deathAge: +deathAge,
//             deathPlace: deathPlace,
//             relationship: relationship,
//             applicantAge: +applicantAge,
//             scheme_avail_id: +scheme_avail_id,
//             labour_user_id: +labour_user_id,
//             permanentAddress: permanentAddress,
//             validityFromDate: moment(beneficiaryValidityFrom).format("YYYY-MM-DD"),
//             validityToDate: moment(beneficiaryValidityTo).format("YYYY-MM-DD"),
//             applicationDate: moment(applicationDate).format("YYYY-MM-DD"),
//             nomineeaadhaar: nomineeaadhaarCardDoc,
//             labourCard: uploadFiles.labourCardDoc,
//             deathCertificate: uploadFiles.deathcertificateDoc,
//             benificiaryaadhar: uploadFiles.benificiaryaadharDoc,
//             nomineeaadhaarCardDoc: uploadFiles.nomineeaadhaarCardDoc,
//           }
//           console.log(payload, 'payload****');
//           const response = await UpdatebacklogFuneralAccidentDeath(payload)
//           setSubmitLoader(false)
//           if (response.success) {
//             setScheme_availed_id(response.data[0].data[0].scheme_availed_id)
//             setLabour_userID(response.data[0].data[0].labour_user_id)
//             setOpenBackdrop(true)
//             setIsSubmitSuccess(true)
//             showToast("SUCCESS", "Application Submitted successfully.")
//           } else {
//             showToast("ERROR", "Application Submission Failed")
//           }
//         } else {
//           showToast("ERROR", "File upload failed")
//         }
//       } catch (error) {
//         console.error("Upload error:", error)
//         showToast("ERROR", "Error uploading files")
//       } finally {
//         setSubmitLoader(false)
//       }
//     } else {
//       showToast("ERROR", "Please fill all the mandatory details")
//     }
//   }

//   const handleReset = () => {
//     setMobileNumber("")
//     setApplicantName("")
//     setDeathPlace("")
//     setCauseofDeath("")
//     setDeathAge("")
//     setApplicantAge("")
//     setRegistrationNumber("")
//     setReferenceNumber("")
//     setDod(null)
//     setPermanentAddress("")
//     setNomineeaadhaarCardDoc([])
//     setLabourCardDoc([])
//     setDeathcertificateDoc([])
//     setBenificiaryaadharDoc([])
//     setIsVerified(false)
//     setIsMobileVerified(false)
//     setIsUserVerified(false)
//     setIsAadhaarVerified(false)
//     setIsSubmitSuccess(false)
//     // Reset beneficiary details
//     setBeneficiaryName("")
//     setBeneficiaryMobile("")
//     setBeneficiaryRegNo("")
//     setBeneficiaryRefNo("")
//     setBeneficiaryDOB(null)
//     setBeneficiaryAddress("")
//     setBeneficiaryRegDate(null)
//     setBeneficiaryValidityFrom(null)
//     setBeneficiaryValidityTo(null)
//     setApplicationDate(null)
//   }

//   const callGetLocationDetails = async (submissionlocation) => {
//     try {
//       const res = await getSubmissionLocationAPI(submissionlocation)
//       setCircleData(res.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   return (
//     <>
//       {submitLoader && <SimpleBackdrop />}
//       {isSubmitSuccess && (
//         <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
//           <div style={{ display: "block" }}>
//             <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "200px" }} />
//             <p className="final-success-title">
//               Successfully submitted for
//               <br />
//               Scheme!
//             </p>
//             <Link
//               to={`/view/schemeack/Funeral Expense and Death Assistance/${labour_userID}/${scheme_availed_id}`}
//               style={{ flexGrow: "0.5" }}
//               target="_blank"
//             >
//               <p className="final-success-link-p">
//                 View Acknowledgement Letter{" "}
//                 <span variant="outline-primary" className="final-success-link">
//                   {" "}
//                   Click Here
//                 </span>
//               </p>
//             </Link>
//           </div>
//         </Backdrop>
//       )}

//       <div className="root">
//         <Row className="top-div">
//           <Row className="topbar-row">
//             <Col xs={12} md={4} lg={6} className="top-bar-col">
//               <div className="logo-div-profile">
//                 <a href="/admin/dashboard/20">
//                   <img id="logo" src={logo || "/placeholder.svg"} alt="..." className="logo-img" />
//                 </a>
//                 <h1 className="logo-text">
//                   <Translate value="header.title" /> <br />
//                   <Translate value="header.sub" />
//                 </h1>
//               </div>
//             </Col>
//           </Row>
//         </Row>
//         <div className="d-flex justify-content-start align-items-start ps-3 mt-3">
//           <Link to={"/admin/dashboard/20"}>
//             <img src={backIcon || "/placeholder.svg"} alt="" />
//           </Link>
//         </div>

//         {/* Form content */}
//         <div className="d-flex justify-content-center align-items-center px-3 py-2 w-100">
//           <div>
//             {!isSubmitSuccess && (
//               <>
//                 {/* Registration and Reference Number Fields */}
//                 <Row className="px-5 py-2 w-100">
//                   <Col md={6}>
//                     <TextField
//                       className="w-100"
//                       label="Registration number"
//                       variant="standard"
//                       value={registrationNumber}
//                       disabled={isUserVerified}
//                       onChange={(ev) => setRegistrationNumber(ev.target.value)}
//                     />
//                   </Col>
//                   <Col md={5}>
//                     <TextField
//                       className="w-100"
//                       label="Reference number"
//                       variant="standard"
//                       value={referenceNumber}
//                       disabled={isUserVerified}
//                       onChange={(ev) => setReferenceNumber(ev.target.value)}
//                     />
//                   </Col>

//                   {/* 
//                   <Col className='mt-3 d-flex'>
//                  {loading ? (
//                   <Box sx={{ display: "flex" }}>
//                     <CircularProgress />
//                   </Box>
//                 ) : (
//                   <Button
//                     disabled={
//                       !registrationNumber || !referenceNumber || isUserVerified
//                     }
//                     onClick={verifySevaSindhu}
//                     variant={isUserVerified ? "success" : "primary"}
//                     className='mx-3'
//                   >
//                     {isUserVerified ? "Verified" : "Verify"}
//                   </Button>
//                 )} */}


//                   <Col md={4} className="mt-3 d-flex">
//                     {loading ? (
//                       <Box sx={{ display: "flex" }}>
//                         <CircularProgress />
//                       </Box>
//                     ) : (
//                       <>
//                         <Button
//                           disabled={!registrationNumber || !referenceNumber || isUserVerified}
//                           onClick={verifySevaSindhu}
//                           variant={isUserVerified ? "success" : "primary"}
//                         >
//                           {isUserVerified ? "Verified" : "Verify"}
//                         </Button>
//                         <Button
//                           disabled={!registrationNumber || !referenceNumber}
//                           onClick={handleReset}
//                           variant="primary"
//                           className="ms-2"
//                         >
//                           Reset
//                         </Button>
//                       </>
//                     )}
//                   </Col>
//                 </Row>

//                 {/* Beneficiary Details Section */}
//                 {isUserVerified && (
//                   <Row className="px-5 py-2 mt-4">
//                     <h3>Beneficiary Details</h3>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Name of the beneficiary"
//                         variant="standard"
//                         value={beneficiaryName}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Mobile Number"
//                         variant="standard"
//                         value={beneficiaryMobile}
//                         onChange={(e) => setBeneficiaryMobile(e.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Registration Number"
//                         variant="standard"
//                         value={beneficiaryRegNo}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Reference Number"
//                         variant="standard"
//                         value={beneficiaryRefNo}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Date of Birth"
//                         variant="standard"
//                         value={beneficiaryDOB ? moment(beneficiaryDOB).format("DD/MM/YYYY") : ""}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Permanent Address"
//                         variant="standard"
//                         value={beneficiaryAddress}
//                         disabled
//                       // multiline
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Registration Date"
//                         variant="standard"
//                         value={beneficiaryRegDate ? moment(beneficiaryRegDate).format("DD-MM-YYYY") : ""}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Validity From Date"
//                         variant="standard"
//                         value={beneficiaryValidityFrom ? moment(beneficiaryValidityFrom).format("DD-MM-YYYY") : ""}
//                         disabled
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Validity To Date"
//                         variant="standard"
//                         value={beneficiaryValidityTo ? moment(beneficiaryValidityTo).format("DD-MM-YYYY") : ""}
//                         disabled
//                       />
//                     </Col>
//                   </Row>
//                 )}

//                 {/* Rest of the form fields */}
//                 {isUserVerified && (
//                   <Row className="px-5 py-2 mt-4">
//                     <h3>Nominee Details</h3>
//                     {/* Add the rest of your form fields here */}
//                     {/* For example: */}
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Name of the Nominee"
//                         variant="standard"
//                         value={applicantName}
//                         onChange={(ev) => setApplicantName(ev.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Place of Death"
//                         variant="standard"
//                         value={deathPlace}
//                         onChange={(ev) => setDeathPlace(ev.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Cause of Death"
//                         variant="standard"
//                         value={causeofDeath}
//                         onChange={(ev) => setCauseofDeath(ev.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className="w-100 mb-3"
//                           format="DD/MM/YYYY"
//                           label="Date of Death"
//                           value={dod}
//                           onChange={(date) => setDod(date)}
//                           maxDate={moment()}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Age at the time of death"
//                         variant="standard"
//                         value={deathAge}
//                         onChange={(ev) => setDeathAge(ev.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Age of Applicant"
//                         variant="standard"
//                         value={applicantAge}
//                         onChange={(ev) => setApplicantAge(ev.target.value)}
//                       />
//                     </Col>
//                     <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Permanent Address"
//                         variant="standard"
//                         value={permanentAddress}
//                         onChange={(ev) => setPermanentAddress(ev.target.value)}
//                       // multiline
//                       />
//                     </Col>
//                     {/* <Col md={4}>
//                       <TextField
//                         className="w-100 mb-3"
//                         label="Relationship with Beneficiary"
//                         variant="standard"
//                         value={relationship}
//                         onChange={(ev) => setRelationship(ev.target.value)}
//                       />
//                     </Col> */}
//                     <Col md={4}>
//                       <Required
//                         title={"Relationship with Beneficiary"}
//                       />
//                       <Select
//                         className='w-100'
//                         value={relationship}
//                         onChange={(ev) => setRelationship(ev.target.value)}
//                       >
//                         <MenuItem value={relationship}>
//                           <em>select relationship with Beneficiary</em>
//                         </MenuItem>
//                         <MenuItem value={"Father"}>Father</MenuItem>
//                         <MenuItem value={"Mother"}>Mother</MenuItem>
//                         <MenuItem value={"Husband"}>Husband</MenuItem>
//                         <MenuItem value={"Wife"}>Wife</MenuItem>
//                         <MenuItem value={"Son"}>Son</MenuItem>
//                         <MenuItem value={"Daughter"}>Daughter</MenuItem>
//                         <MenuItem value={"Brother"}>Brother</MenuItem>
//                         <MenuItem value={"Sister"}>Sister</MenuItem>
//                         <MenuItem value={"Other"}>Other</MenuItem>
//                       </Select>
//                     </Col>
//                   </Row>
//                 )}

//                 {/* File upload section */}
//                 {isUserVerified && (
//                   <Row className="px-5 py-2 mt-4">
//                     <h3>Document Upload</h3>
//                     {/* Add your file upload fields here */}
//                     {/* For example: */}
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Required title="Labour Card" />
//                         <input
//                           type="file"
//                           ref={labourCardFileRef}
//                           onChange={handleFileChange}
//                           hidden
//                           name="labourCard"
//                         />
//                         <Button onClick={() => labourCardFileRef.current.click()} startIcon={<FolderIcon />}>
//                           Browse
//                         </Button>
//                         {labourCardDoc.map((file, index) => (
//                           <div key={index} className="mt-2">
//                             {file.name}
//                             <IconButton onClick={() => handleRemoveFile(index, "labourCard")}>
//                               <CancelIcon />
//                             </IconButton>
//                           </div>
//                         ))}
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Required title="Nominee Aadhaar Card" />
//                         <input
//                           type="file"
//                           ref={nomineeaadhaarFileRef}
//                           onChange={handleFileChange}
//                           hidden
//                           name="nomineeaadhaar"
//                         />
//                         <Button onClick={() => nomineeaadhaarFileRef.current.click()} startIcon={<FolderIcon />}>
//                           Browse
//                         </Button>
//                         {nomineeaadhaarCardDoc.map((file, index) => (
//                           <div key={index} className="mt-2">
//                             {file.name}
//                             <IconButton onClick={() => handleRemoveFile(index, "nomineeaadhaar")}>
//                               <CancelIcon />
//                             </IconButton>
//                           </div>
//                         ))}
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Required title="Beneficiary Aadhaar Card" />
//                         <input
//                           type="file"
//                           ref={benificiaryaadharFileRef}
//                           onChange={handleFileChange}
//                           hidden
//                           name="benificiaryaadhar"
//                         />
//                         <Button onClick={() => benificiaryaadharFileRef.current.click()} startIcon={<FolderIcon />}>
//                           Browse
//                         </Button>
//                         {benificiaryaadharDoc.map((file, index) => (
//                           <div key={index} className="mt-2">
//                             {file.name}
//                             <IconButton onClick={() => handleRemoveFile(index, "benificiaryaadhar")}>
//                               <CancelIcon />
//                             </IconButton>
//                           </div>
//                         ))}
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Required title="Death Certificate" />
//                         <input
//                           type="file"
//                           ref={deathcertificateFileRef}
//                           onChange={handleFileChange}
//                           hidden
//                           name="deathcertificate"
//                         />
//                         <Button onClick={() => deathcertificateFileRef.current.click()} startIcon={<FolderIcon />}>
//                           Browse
//                         </Button>
//                         {deathcertificateDoc.map((file, index) => (
//                           <div key={index} className="mt-2">
//                             {file.name}
//                             <IconButton onClick={() => handleRemoveFile(index, "deathcertificate")}>
//                               <CancelIcon />
//                             </IconButton>
//                           </div>
//                         ))}
//                       </div>
//                     </Col>
//                   </Row>
//                 )}

//                 {/* Submit button */}
//                 {isUserVerified && (
//                   <Row className="px-5 py-2 mt-4">
//                     <Col className="d-flex justify-content-end">
//                       <Button onClick={handleSubmitData} variant="success" size="lg" disabled={submitLoader}>
//                         Submit
//                       </Button>
//                     </Col>
//                   </Row>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default BacklogFormFuneral



"use client"

import { useRef, useState, useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import logo from "assets/images/Karnataka_logo_100_Pixels.png"
import { Translate } from "react-redux-i18n"
import { CheckCircle } from "@material-ui/icons"
import TextField from "@mui/material/TextField"
import { makeStyles, Backdrop, IconButton } from "@material-ui/core"
import { useHistory, useLocation, useParams, Link } from "react-router-dom"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import CancelIcon from "@mui/icons-material/Cancel"
import FolderIcon from "@mui/icons-material/Folder"
import { Required, showToast } from "utils/tools"
import moment from "moment"
import { useSelector } from "react-redux"
import SimpleBackdrop from "components/loadingScreen/Backdrop"
import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg"
import {
  CheckUserAPIforBackLogFuneralAccidentDeath,
  UpdatebacklogFuneralAccidentDeath,
  CheckUserByMobileNoAPI,
} from "services/CheckUserPension.service"
import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service"
import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload"
import { Select, MenuItem, FormControl, Paper, Typography, InputAdornment } from "@mui/material"
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
import DescriptionIcon from "@mui/icons-material/Description"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import Container from "react-bootstrap/Container"
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  getUserOnVaultReferenceNumber,
} from "components/SevaSindhu/SevaSindhuServices/Aadhaar.service"
import { BASE_URL, SERVER } from "store/actions/user.actions"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffffee",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backdropCheck: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#6CC17A",
  },
  backdropCheckp: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  sectionIcon: {
    width: "30px",
    height: "30px",
    color: "white",
    backgroundColor: "GrayText",
    borderRadius: "100%",
    marginRight: "10px",
  },
  sectionTitle: {
    fontWeight: "bolder",
    color: "GrayText",
  },
  formPaper: {
    margin: "20px",
    borderRadius: "10px",
    padding: "20px 0",
  },
  formRow: {
    padding: "20px",
  },
  fileDisplay: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  fileButton: {
    marginLeft: "10px",
  },
  actionButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  formField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      border: "1px solid #ddd",
      "& fieldset": {
        borderColor: "#ddd",
      },
      "&:hover fieldset": {
        borderColor: "#aaa",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1976d2",
      },
    },
  },
  selectField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      border: "1px solid #ddd",
      "& fieldset": {
        borderColor: "#ddd",
      },
      "&:hover fieldset": {
        borderColor: "#aaa",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1976d2",
      },
    },
    "& .MuiSelect-select": {
      padding: "10px 14px",
      minHeight: "1.4375em",
      lineHeight: "26px"
    },
  },
  verifyButton: {
    marginTop: "10px",
    backgroundColor: "#4CAF50",
    color: "white",
    "&:hover": {
      backgroundColor: "#388E3C",
    },
  },
  aadhaarVerificationContainer: {
    textAlign: "center",
    padding: "20px",
    marginBottom: "20px",
  },
  aadhaarVerificationTitle: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  aadhaarVerificationDescription: {
    marginBottom: "20px",
  },
  aadhaarVerifiedBadge: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "5px 10px",
    borderRadius: "4px",
    display: "inline-block",
    marginBottom: "10px",
  },

}))

const BacklogFormFuneral = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { labourUserID, isEdited } = useParams()

  const users = useSelector((state) => state.users)
  const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id

  // State variables
  const [registrationNumber, setRegistrationNumber] = useState("")
  const [referenceNumber, setReferenceNumber] = useState("")
  const [aadhaarNumber, setAadhaarNumber] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [applicantName, setApplicantName] = useState("")
  const [deathAge, setDeathAge] = useState("")
  const [applicantAge, setApplicantAge] = useState("")
  const [deathPlace, setDeathPlace] = useState("")
  const [causeofDeath, setCauseofDeath] = useState("")
  const [relationship, setRelationship] = useState("")
  const [natureOfWork, setNatureOfWork] = useState("")

  const [scheme_avail_id, setScheme_avail_id] = useState(null)
  const [scheme_availed_id, setScheme_availed_id] = useState(null)
  const [labour_userID, setLabour_userID] = useState(null)
  const [labour_user_id, setLabour_user_id] = useState(null)
  const [permanentAddress, setPermanentAddress] = useState("")
  const [dod, setDod] = useState(null)
  const [registrationDate, setRegistrationDate] = useState(null)
  const [isVerified, setIsVerified] = useState(false)
  const [isMobileVerified, setIsMobileVerified] = useState(false)
  const [isUserVerified, setIsUserVerified] = useState(false)
  const [isAadhaarVerified, setIsAadhaarVerified] = useState(true)
  const [vaultReferenceNumber, setVaultReferenceNumber] = useState(null)

  const [loading, setLoading] = useState(false)
  const [numberLoading, setNumberLoading] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
  const [isDataFound, setIsDataFound] = useState(false)
  const [circleData, setCircleData] = useState(null)
  const [sevaSindhuID, setSevaSindhuID] = useState(null)
  const [department_user_id, setDepartment_user_id] = useState(null)

  // Beneficiary details
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({
    name: "",
    mobileNumber: "",
    registrationNumber: "",
    referenceNumber: "",
    dateOfBirth: null,
    permanentAddress: "",
    registrationDate: null,
    validityFromDate: null,
    validityToDate: null,
    applicationDate: null,
  })

  // File upload refs
  const labourCardFileRef = useRef(null)
  // const nomineeaadhaarFileRef = useRef(null)
  const deathcertificateFileRef = useRef(null)
  // const benificiaryaadharFileRef = useRef(null)

  // File upload states
  const [labourCardDoc, setLabourCardDoc] = useState([])
  // const [nomineeaadhaarCardDoc, setNomineeaadhaarCardDoc] = useState([])
  const [deathcertificateDoc, setDeathcertificateDoc] = useState([])
  // const [benificiaryaadharDoc, setBenificiaryaadharDoc] = useState([])

  const [openBackdrop, setOpenBackdrop] = useState(true)

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false)
  }

  // Aadhaar verification functions
  const handleVerifyAadhaar = async () => {
    if (!aadhaarNumber || aadhaarNumber.length !== 12) {
      showToast("ERROR", "Please enter a valid 12-digit Aadhaar number")
      return
    }

    try {
      setLoading(true)
      const payLoad = {
        responseRedirectURL: `${BASE_URL}/admin/dashboard/backlogformfuneral?aadhaarNumber=${aadhaarNumber}`,
      }

      const response = await EKYCWEBHOOK(payLoad)
      const { token } = response.data

      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        "_self",
      )
    } catch (error) {
      setLoading(false)
      showToast("ERROR", "Something went wrong with Aadhaar verification. Please try again.")
    }
  }

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    setLoading(true)
    try {
      const payLoad = {
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      }

      const { data: aadhaarDuplicateResponse } = await getUserOnVaultReferenceNumber(payLoad)
      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo)

      if (aadhaarDuplicateResponse.length !== 0) {
        setLoading(false)
        showToast("ERROR", "Aadhaar already registered with other mobile number")
        return
      }

      if (aadhaarData.length > 0) {
        const { name, address, dob, gender } = aadhaarData[0]

        // Calculate age from DOB
        const birthDate = moment(dob, "DD-MM-YYYY")
        const today = moment()
        const age = today.diff(birthDate, "years")

        // Set nominee details from Aadhaar
        setApplicantName(name)
        setPermanentAddress(address || "")
        setApplicantAge(age.toString())
        setIsAadhaarVerified(true)
        setVaultReferenceNumber(vaultRefNo)
        showToast("SUCCESS", "Aadhaar verification successful")
      } else {
        setLoading(false)
        setIsAadhaarVerified(false)
        showToast("ERROR", "Aadhaar Verification failed, please try again.")
      }
    } catch (error) {
      setLoading(false)
      showToast("ERROR", "Something went wrong during Aadhaar verification")
    } finally {
      setLoading(false)
    }
  }

  const verifySevaSindhu = async () => {
    if (registrationNumber && referenceNumber) {
      setLoading(true)
      const payload = {
        regNo: registrationNumber,
        refNo: referenceNumber,
      }
      try {
        const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload)
        console.log(data, 'data*****')
        setLoading(false)
        setIsUserVerified(true)
        if (data && data?.length > 0) {
          if (data[0]?.department_user_id !== deptUserID) {
            showToast("ERROR", "Application cannot be submitted under this jurisdiction")
            setIsUserVerified(false)
            setIsVerified(false)
            return
          }
          if ((data[0]?.labour_user_id && data[0]?.scheme_id == 15) || (data[1]?.labour_user_id && data[1]?.scheme_id == 15)) {
            showToast("ERROR", "Application has already been submitted.")
            setIsUserVerified(false)
            setIsVerified(false)
            return
          }

          setIsVerified(true)
          setIsDataFound(false)

          // Set beneficiary details
          setBeneficiaryDetails({
            name: data[0]?.applicantname,
            mobileNumber: data[0]?.mobileno,
            registrationNumber: data[0]?.regno,
            referenceNumber: data[0]?.spdv_appl_ref_no,
            dateOfBirth: data[0]?.DOB,
            permanentAddress: data[0]?.permanent_address,
            registrationDate: data[0]?.registrationdate,
            validityFromDate: data[0]?.validity_fromdate,
            validityToDate: data[0]?.validity_todate,
            applicationDate: data[0]?.spdv_application_date,
          })

          // Set mobile number from beneficiary details
          // setMobileNumber(data[0]?.mobileno)

          // If Aadhaar is already verified, don't override Aadhaar data
          if (!isAadhaarVerified) {
            // Set other form fields
            data[0]?.data.forEach((item) => {
              if (item.field === "Cause of death") setCauseofDeath(item.field_value)
              if (item.field === "Place of death") setDeathPlace(item.field_value)
              if (item.field === "Date of death") setDod(item.field_value)
              if (item.field === "Age at the time of death") setDeathAge(item.field_value)
              if (item.field === "Age of Applicant") setApplicantAge(item.field_value)
              if (item.field === "Name of the Nominee") setApplicantName(item.field_value)
              if (item.field === "Permanent Address") setPermanentAddress(item.field_value)
              if (item.field === "Relationship with Beneficiary") setRelationship(item.field_value)
            })
          } else {
            // If Aadhaar is verified, only set non-Aadhaar fields
            data[0]?.data.forEach((item) => {
              if (item.field === "Cause of death") setCauseofDeath(item.field_value)
              if (item.field === "Place of death") setDeathPlace(item.field_value)
              if (item.field === "Date of death") setDod(item.field_value)
              if (item.field === "Age at the time of death") setDeathAge(item.field_value)
              if (item.field === "Relationship with Beneficiary") setRelationship(item.field_value)

            })
          }

          setScheme_avail_id(data[0]?.scheme_availed_id)
          setLabour_user_id(data[0]?.labour_user_id)
          setDepartment_user_id(data[0]?.department_user_id)
          callGetLocationDetails(data[0]?.submissionlocation)
          setRegistrationDate(data[0]?.registrationdate)
          setSevaSindhuID(data[0]?.id)
        }
      } catch (error) {
        setLoading(false)
        if (error?.response?.data?.errors?.msg) {
          showToast("ERROR", `${error?.response?.data?.errors?.msg}`)
        }
        showToast("ERROR", "Something went wrong")
      }
    } else {
      showToast("ERROR", "Please enter registration and reference number")
    }
  }

  const verifyMobileNumber = async () => {
    if (mobileNumber) {
      setNumberLoading(true)

      const pensionDetails = await CheckUserByMobileNoAPI({
        mobile: mobileNumber,
      })

      if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
        setNumberLoading(false)
        setIsMobileVerified(false)
        setIsVerified(false)
        showToast("ERROR", "The Mobile Number is Already Manually Registered")
        return
      }

      try {
        const response = await fetch(`${SERVER}/user/${mobileNumber}`)
        if (response.ok) {
          setNumberLoading(false)
          setIsMobileVerified(false)
          setIsVerified(false)
          showToast("ERROR", "Mobile Number Already Registered, Please Login!")
        } else {
          setNumberLoading(false)
          setIsMobileVerified(true)
          setIsVerified(true)
        }
      } catch (err) {
        setNumberLoading(false)
        setIsMobileVerified(true)
        setIsVerified(true)
      }
    } else {
      showToast("ERROR", "Please enter mobile number")
    }
  }

  const handleFileChange = (ev) => {
    const files = Array.from(ev.target.files)
    const validTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

    if (!files.every((file) => validTypes.includes(file.type))) {
      showToast("ERROR", "Please upload file in PNG/JPEG or PDF")
      return
    }

    if (!files.every((file) => file.size < 2000001)) {
      showToast("ERROR", "Please upload file size less than 2MB.")
      return
    }

    switch (ev.target.name) {
      // case "nomineeaadhaar":
      //   setNomineeaadhaarCardDoc((prev) => [...prev, ...files])
      //   break
      case "labourCard":
        setLabourCardDoc((prev) => [...prev, ...files])
        break
      case "deathcertificate":
        setDeathcertificateDoc((prev) => [...prev, ...files])
        break
      // case "benificiaryaadhar":
      //   setBenificiaryaadharDoc((prev) => [...prev, ...files])
      //   break
    }
  }

  const handleRemoveFile = (index, documentType) => {
    switch (documentType) {
      case "labourCard":
        setLabourCardDoc((prev) => prev.filter((_, i) => i !== index))
        break
      // case "nomineeaadhaar":
      //   setNomineeaadhaarCardDoc((prev) => prev.filter((_, i) => i !== index))
      //   break
      case "deathcertificate":
        setDeathcertificateDoc((prev) => prev.filter((_, i) => i !== index))
        break
      // case "benificiaryaadhar":
      //   setBenificiaryaadharDoc((prev) => prev.filter((_, i) => i !== index))
      //   break
    }
  }

  const handleSubmitData = async () => {
    if (!isAadhaarVerified) {
      showToast("ERROR", "Please verify nominee Aadhaar before submitting")
      return
    }

    if (
      mobileNumber &&
      applicantName &&
      dod &&
      permanentAddress &&
      causeofDeath &&
      applicantAge &&
      deathPlace &&
      deathAge &&
      relationship &&
      labourCardDoc.length > 0 &&
      // nomineeaadhaarCardDoc.length > 0 &&
      deathcertificateDoc.length > 0
      // benificiaryaadharDoc.length > 0
    ) {
      setSubmitLoader(true)

      try {
        const uploadFiles = await backlogFuneralAccidentDeathFileUpload(
          labourCardDoc,
          // nomineeaadhaarCardDoc,
          // benificiaryaadharDoc,
          deathcertificateDoc,
        )

        if (uploadFiles.success) {
          const payload = {
            boardID: 1,
            departmentUserID: +department_user_id,
            sevasindhuID: +sevaSindhuID,
            mobile: +mobileNumber,
            regNo: registrationNumber,
            refNo: referenceNumber,
            registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
            applicantName: applicantName,
            dod: moment(dod).format("YYYY-MM-DD"),
            causeofDeath: causeofDeath,
            deathAge: +deathAge,
            deathPlace: deathPlace,
            relationship: relationship,
            applicantAge: +applicantAge,
            scheme_avail_id: +scheme_avail_id,
            labour_user_id: +labour_user_id,
            permanentAddress: permanentAddress,
            validityFromDate: moment(beneficiaryDetails.validityFromDate).format("YYYY-MM-DD"),
            validityToDate: moment(beneficiaryDetails.validityToDate).format("YYYY-MM-DD"),
            applicationDate: beneficiaryDetails.applicationDate
              ? moment(beneficiaryDetails.applicationDate).format("YYYY-MM-DD")
              : null,
            // nomineeaadhaar: nomineeaadhaarCardDoc,
            labourCard: uploadFiles.labourCardDoc,
            deathCertificate: uploadFiles.deathcertificateDoc,
            // benificiaryaadhar: uploadFiles.benificiaryaadharDoc,
            // nomineeaadhaarCardDoc: uploadFiles.nomineeaadhaarCardDoc,
            vaultReferenceNumber: vaultReferenceNumber, // Add Aadhaar vault reference
            natureOfWork: natureOfWork,
          }
          console.log(payload, 'payload***');
          const response = await UpdatebacklogFuneralAccidentDeath(payload)
          setSubmitLoader(false)
          if (response.success) {
            setScheme_availed_id(response.data[0].data[0].scheme_availed_id)
            setLabour_userID(response.data[0].data[0].labour_user_id)
            setOpenBackdrop(true)
            setIsSubmitSuccess(true)
            showToast("SUCCESS", "Application Submitted successfully.")
          } else {
            showToast("ERROR", "Application Submission Failed")
          }
        } else {
          showToast("ERROR", "File upload failed")
        }
      } catch (error) {
        console.error("Upload error:", error)
        showToast("ERROR", "Error uploading files")
      } finally {
        setSubmitLoader(false)
      }
    } else {
      showToast("ERROR", "Please fill all the mandatory details")
    }
  }

  const handleReset = () => {
    setMobileNumber("")
    setApplicantName("")
    setDeathPlace("")
    setCauseofDeath("")
    setDeathAge("")
    setApplicantAge("")
    setRegistrationNumber("")
    setReferenceNumber("")
    setAadhaarNumber("")
    setDod(null)
    setPermanentAddress("")
    // setNomineeaadhaarCardDoc([])
    setLabourCardDoc([])
    setDeathcertificateDoc([])
    // setBenificiaryaadharDoc([])
    setIsVerified(false)
    setIsMobileVerified(false)
    setIsUserVerified(false)
    setIsAadhaarVerified(false)
    setVaultReferenceNumber(null)
    setIsSubmitSuccess(false)
    setBeneficiaryDetails({
      name: "",
      mobileNumber: "",
      registrationNumber: "",
      referenceNumber: "",
      dateOfBirth: null,
      permanentAddress: "",
      registrationDate: null,
      validityFromDate: null,
      validityToDate: null,
      applicationDate: null,
    })
  }

  const callGetLocationDetails = async (submissionlocation) => {
    try {
      const res = await getSubmissionLocationAPI(submissionlocation)
      setCircleData(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  // Calculate age at time of death when DOB or DOD changes
  useEffect(() => {
    if (beneficiaryDetails.dateOfBirth && dod) {
      const birthDate = moment(beneficiaryDetails.dateOfBirth)
      const deathDate = moment(dod)
      const ageAtDeath = deathDate.diff(birthDate, "years")
      if (ageAtDeath >= 0) {
        setDeathAge(ageAtDeath.toString())
      } else {
        setDeathAge("")
        showToast("ERROR", "Date of death cannot be before date of birth")
      }
    }
  }, [beneficiaryDetails.dateOfBirth, dod])

  // Check for Aadhaar verification response in URL
  useEffect(() => {
    const currentUrl = window.location.toString()
    const queryString = new URLSearchParams(location.search)

    if (currentUrl.includes("Success")) {
      const vaultRefNo = queryString.get("vaultrefno")
      if (vaultRefNo) {
        callGetUserOnVaultReferenceNumber(vaultRefNo)
      }
    } else if (currentUrl.includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.")
    }
  }, [location.search])

  return (
    <>
      {submitLoader && <SimpleBackdrop />}
      {isSubmitSuccess && (
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
          <div style={{ display: "block" }}>
            <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "200px" }} />
            <p className="final-success-title">
              Successfully submitted for
              <br />
              Scheme!
            </p>
            <Link
              to={`/view/schemeack/Funeral Expense and Death Assistance/${labour_userID}/${scheme_availed_id}`}
              style={{ flexGrow: "0.5" }}
              target="_blank"
            >
              <p className="final-success-link-p">
                View Acknowledgement Letter{" "}
                <span variant="outline-primary" className="final-success-link">
                  {" "}
                  Click Here
                </span>
              </p>
            </Link>
          </div>
        </Backdrop>
      )}

      <div className="root">
        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={4} lg={6} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/admin/dashboard/20">
                  <img id="logo" src={logo || "/placeholder.svg"} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
          </Row>
        </Row>

        <div className="d-flex justify-content-start align-items-start ps-3 mt-3">
          <Link to={"/admin/dashboard/20"}>
            <img src={backIcon || "/placeholder.svg"} alt="" />
          </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center px-3 py-2">
          <div className="w-100">
            {!isSubmitSuccess && (
              <>
                {/* Aadhaar Verification Section */}
                <Paper elevation={3} className={classes.formPaper}>
                  <Container>
                    <Row className={`g-3 px-3 ${classes.formRow}`}>
                      <div className={classes.sectionHeading}>
                        <PersonRoundedIcon className={classes.sectionIcon} />
                        <Typography variant="h5" className={classes.sectionTitle}>
                          Nominee Aadhaar Verification
                        </Typography>
                      </div>

                      {isAadhaarVerified ? (
                        <div className={classes.aadhaarVerificationContainer}>
                          <div className={classes.aadhaarVerifiedBadge}>
                            <CheckCircle style={{ marginRight: "5px", fontSize: "16px" }} />
                            Aadhaar Verified
                          </div>
                        </div>
                      ) : (
                        <>
                          <Col md={12}>
                            <Typography variant="body1" className={classes.aadhaarVerificationDescription}>
                              Please verify the nominee's Aadhaar details before proceeding with the application.
                            </Typography>
                          </Col>
                          <Col md={6}>
                            <Required title="Nominee Aadhaar number" />
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                className={classes.formField}
                                variant="outlined"
                                value={aadhaarNumber}
                                onChange={(ev) => setAadhaarNumber(ev.target.value.replace(/[^0-9]/g, ""))}
                                inputProps={{ maxLength: 12 }}
                                placeholder="Enter 12-digit Aadhaar number"
                              />
                            </FormControl>
                          </Col>
                          <Col md={6} className="d-flex align-items-end">
                            <Button
                              onClick={handleVerifyAadhaar}
                              variant="contained"
                              className={classes.verifyButton}
                              disabled={!aadhaarNumber || aadhaarNumber.length !== 12 || loading}
                            >
                              {loading ? "Verifying..." : "Verify Aadhaar"}
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Container>
                </Paper>

                {/* Registration and Reference Number Fields */}
                <Paper elevation={3} className={classes.formPaper}>
                  <Container>
                    <Row className={`g-3 px-3 ${classes.formRow}`}>
                      <div className={classes.sectionHeading}>
                        <PersonRoundedIcon className={classes.sectionIcon} />
                        <Typography variant="h5" className={classes.sectionTitle}>
                          Verification Details
                        </Typography>
                      </div>

                      <Col md={4}>
                        <Required title="Registration number" />
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            className={classes.formField}
                            variant="outlined"
                            value={registrationNumber}
                            disabled={isUserVerified}
                            onChange={(ev) => setRegistrationNumber(ev.target.value)}
                          />
                        </FormControl>
                      </Col>
                      <Col md={4}>
                        <Required title="Reference number" />
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            className={classes.formField}
                            variant="outlined"
                            value={referenceNumber}
                            disabled={isUserVerified}
                            onChange={(ev) => setReferenceNumber(ev.target.value)}
                          />
                        </FormControl>
                      </Col>

                      <Col md={4} className="d-flex align-items-end">
                        <Button
                          disabled={!registrationNumber || !referenceNumber || isUserVerified}
                          onClick={verifySevaSindhu}
                          variant={isUserVerified ? "success" : "primary"}
                          className="me-2"
                        >
                          {isUserVerified ? "Verified" : "Verify"}
                        </Button>
                        <Button variant="primary" onClick={handleReset}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Paper>

                {/* Beneficiary Details Section */}
                {isUserVerified && (
                  <Paper elevation={3} className={classes.formPaper}>
                    <Container>
                      <Row className={`g-3 px-3 ${classes.formRow}`}>
                        <div className={classes.sectionHeading}>
                          <PersonRoundedIcon className={classes.sectionIcon} />
                          <Typography variant="h5" className={classes.sectionTitle}>
                            Beneficiary Details
                          </Typography>
                        </div>

                        <Col md={4}>
                          <Required title="Name" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={beneficiaryDetails.name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Mobile Number" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={beneficiaryDetails.mobileNumber}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Registration Number" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={beneficiaryDetails.registrationNumber}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Reference Number" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={beneficiaryDetails.referenceNumber}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Date of Birth" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={
                                beneficiaryDetails.dateOfBirth
                                  ? moment(beneficiaryDetails.dateOfBirth).format("DD/MM/YYYY")
                                  : ""
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Permanent Address" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={beneficiaryDetails.permanentAddress}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Registration Date" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={
                                beneficiaryDetails.registrationDate
                                  ? moment(beneficiaryDetails.registrationDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Validity From Date" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={
                                beneficiaryDetails.validityFromDate
                                  ? moment(beneficiaryDetails.validityFromDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Validity To Date" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={
                                beneficiaryDetails.validityToDate
                                  ? moment(beneficiaryDetails.validityToDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>


                        <Col md={4}>
                          <Required title="Nature of Work" style={{ marginBottom: "6px" }} />
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                              className={classes.selectField}
                              value={natureOfWork}
                              onChange={(ev) => setNatureOfWork(ev.target.value)}
                              variant="outlined"
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return <text style={{ lineHeight: "26px" }}>Select nature of work</text>;
                                }
                                return selected;
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem value="" disabled>
                                <text sx={{ lineHeight: "26px" }}>Select nature of work</text>
                              </MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Mason">Mason</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Plumber">Plumber</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Welder">Welder</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Glazier">Glazier</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Plasterer">Plasterer</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Electrician">Electrician</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Carpenter">Carpenter</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Helper">Helper</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Painter">Painter</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Mechanic">Mechanic</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Road Construction Worker">Road Construction Worker</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Tiles Work">Tiles Work</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Stone Cutting">Stone Cutting</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Black Smith">Black Smith</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Gold Smith">Gold Smith</MenuItem>
                              <MenuItem sx={{ lineHeight: "26px" }} value="Domestic Worker">Domestic Worker</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>

                      </Row>
                    </Container>
                  </Paper>
                )}

                {/* Nominee Details Section */}
                {isUserVerified && (
                  <Paper elevation={3} className={classes.formPaper}>
                    <Container>
                      <Row className={`g-3 px-3 ${classes.formRow}`}>
                        <div className={classes.sectionHeading}>
                          <DescriptionIcon className={classes.sectionIcon} />
                          <Typography variant="h5" className={classes.sectionTitle}>
                            Nominee Details
                          </Typography>
                        </div>

                        <Col md={4}>
                          <Required title="Name of the Nominee" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={applicantName}
                              onChange={(ev) => setApplicantName(ev.target.value)}
                            // disabled={isAadhaarVerified} // Disable if Aadhaar verified
                            />
                          </FormControl>
                        </Col>

                        <Col md={4}>
                          <Required title="Age of Applicant" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={applicantAge}
                              onChange={(ev) => setApplicantAge(ev.target.value.replace(/[^0-9]/g, ""))}
                            // disabled={isAadhaarVerified} // Disable if Aadhaar verified
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Mobile Number" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))}
                              inputProps={{ maxLength: 10 }}
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Permanent Address" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={permanentAddress}
                              onChange={(ev) => setPermanentAddress(ev.target.value)}
                            // disabled={isAadhaarVerified} // Disable if Aadhaar verified
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Relationship with Beneficiary" />
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              style={{ border: "1px solid #ced4da", borderRadius: "4px" }}

                              className={classes.selectField}
                              value={relationship || ""}
                              onChange={(ev) => setRelationship(ev.target.value)}
                              variant="outlined"
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return <text style={{ lineHeight: "26px" }}>Select relationship</text>
                                }
                                return selected
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem value="" disabled>
                                <text style={{ lineHeight: "26px" }}>Select relationship</text>
                              </MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Father">Father</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Mother">Mother</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Husband">Husband</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Wife">Wife</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Son">Son</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Daughter">Daughter</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Brother">Brother</MenuItem>
                              <MenuItem style={{ lineHeight: "26px" }} value="Sister">Sister</MenuItem>
                              {/* <MenuItem value="Other">Other</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Container>
                  </Paper>
                )}

                {/* Death Details Section */}
                {isUserVerified && (
                  <Paper elevation={3} className={classes.formPaper}>
                    <Container>
                      <Row className={`g-3 px-3 ${classes.formRow}`}>
                        <div className={classes.sectionHeading}>
                          <DescriptionIcon className={classes.sectionIcon} />
                          <Typography variant="h5" className={classes.sectionTitle}>
                            Death Details
                          </Typography>
                        </div>

                        <Col md={4}>
                          <Required title="Place of Death" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={deathPlace}
                              onChange={(ev) => setDeathPlace(ev.target.value)}
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Cause of Death" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              className={classes.formField}
                              variant="outlined"
                              value={causeofDeath}
                              onChange={(ev) => setCauseofDeath(ev.target.value)}
                            />
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <Required title="Date of Death" />
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              className={classes.formField}
                              fullWidth
                              inputVariant="outlined"
                              format="DD/MM/YYYY"
                              value={dod}
                              onChange={(date) => setDod(date)}
                              maxDate={moment()}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col md={4}>
                          <Required title="Age at the time of death" />
                          <FormControl variant="outlined" fullWidth>
                            <TextField className={classes.formField} variant="outlined" value={deathAge} disabled />
                          </FormControl>
                        </Col>
                      </Row>
                    </Container>
                  </Paper>
                )}

                {/* Document Upload Section */}
                {isUserVerified && (
                  <Paper elevation={3} className={classes.formPaper}>
                    <Container>
                      <Row className={`g-3 px-3 ${classes.formRow}`}>
                        <div className={classes.sectionHeading}>
                          <UploadFileIcon className={classes.sectionIcon} />
                          <Typography variant="h5" className={classes.sectionTitle}>
                            Document Upload
                          </Typography>
                        </div>

                        <Col md={6}>
                          <div className="mb-4">
                            <Required title="Labour Card" />
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                className={classes.formField}
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                value={labourCardDoc.length > 0 ? labourCardDoc[0].name : ""}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {labourCardDoc.length > 0 && (
                                        <IconButton onClick={() => handleRemoveFile(0, "labourCard")}>
                                          <CancelIcon />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                            <input
                              type="file"
                              ref={labourCardFileRef}
                              onChange={handleFileChange}
                              hidden
                              name="labourCard"
                            />
                            <Button
                              className="mt-2"
                              onClick={() => labourCardFileRef.current.click()}
                              variant="primary"
                            >
                              <FolderIcon className="me-1" /> Browse
                            </Button>
                          </div>
                        </Col>

                        {/* <Col md={6}>
                          <div className="mb-4">
                            <Required title="Nominee Aadhaar Card" />
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                className={classes.formField}
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                value={nomineeaadhaarCardDoc.length > 0 ? nomineeaadhaarCardDoc[0].name : ""}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {nomineeaadhaarCardDoc.length > 0 && (
                                        <IconButton onClick={() => handleRemoveFile(0, "nomineeaadhaar")}>
                                          <CancelIcon />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                            <input
                              type="file"
                              ref={nomineeaadhaarFileRef}
                              onChange={handleFileChange}
                              hidden
                              name="nomineeaadhaar"
                            />
                            <Button
                              className="mt-2"
                              onClick={() => nomineeaadhaarFileRef.current.click()}
                              variant="primary"
                            >
                              <FolderIcon className="me-1" /> Browse
                            </Button>
                          </div>
                        </Col> */}

                        {/* <Col md={6}>
                          <div className="mb-4">
                            <Required title="Beneficiary Aadhaar Card" />
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                className={classes.formField}
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                value={benificiaryaadharDoc.length > 0 ? benificiaryaadharDoc[0].name : ""}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {benificiaryaadharDoc.length > 0 && (
                                        <IconButton onClick={() => handleRemoveFile(0, "benificiaryaadhar")}>
                                          <CancelIcon />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                            <input
                              type="file"
                              ref={benificiaryaadharFileRef}
                              onChange={handleFileChange}
                              hidden
                              name="benificiaryaadhar"
                            />
                            <Button
                              className="mt-2"
                              onClick={() => benificiaryaadharFileRef.current.click()}
                              variant="primary"
                            >
                              <FolderIcon className="me-1" /> Browse
                            </Button>
                          </div>
                        </Col> */}

                        <Col md={6}>
                          <div className="mb-4">
                            <Required title="Death Certificate" />
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                className={classes.formField}
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                value={deathcertificateDoc.length > 0 ? deathcertificateDoc[0].name : ""}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {deathcertificateDoc.length > 0 && (
                                        <IconButton onClick={() => handleRemoveFile(0, "deathcertificate")}>
                                          <CancelIcon />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                            <input
                              type="file"
                              ref={deathcertificateFileRef}
                              onChange={handleFileChange}
                              hidden
                              name="deathcertificate"
                            />
                            <Button
                              className="mt-2"
                              onClick={() => deathcertificateFileRef.current.click()}
                              variant="primary"
                            >
                              <FolderIcon className="me-1" /> Browse
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Paper>
                )}

                {/* Submit button */}
                {isUserVerified && (
                  <div className={classes.actionButtons}>
                    <Button onClick={handleReset} variant="secondary" size="lg" disabled={submitLoader}>
                      Reset
                    </Button>
                    <Button
                      onClick={handleSubmitData}
                      variant="success"
                      size="lg"
                      disabled={submitLoader || !isAadhaarVerified}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BacklogFormFuneral

