import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  Tooltip,
  Checkbox,
  TextareaAutosize,
  Popover,
  Typography,
  Backdrop,
  CircularProgress,
  makeStyles,
  ListItemIcon,
} from '@material-ui/core';
import {
  PhoneAndroid,
  CameraAlt,
  Visibility,
  CheckBoxRounded,
  CheckBoxOutlineBlankRounded,
  CheckCircle,
} from '@material-ui/icons';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import aadhar from 'assets/images/Aadhar black-01.svg';
import personal from 'assets/images/f1-01.svg';
import pan from 'assets/images/PAN Black-01.svg';
import male from 'assets/images/Mask Group 1.svg';
import female from 'assets/images/Mask Group 2.svg';
import moment from 'moment';
import Moment from 'react-moment';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import address from 'assets/images/f2-01.svg';
import { Required, getTokenCookie } from 'utils/tools';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import family from 'assets/images/f3-01.svg';
import { Translate } from 'react-redux-i18n';
import { useCallback } from 'react';
import PopOverImage from '../globals/popOverImage';

const FamilyDetails = ({ rationCard, data }) => {
  const [RationDocs, setRationDocs] = React.useState({});

  const [currentImgClick, setCurrentImgClick] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selected_document_type, setselected_document_type] =
    React.useState('');

  const openration = Boolean(anchorEl);
  const idration = openration ? 'simple-popover' : undefined;

  const handleClick = (event, data, selecteddoc) => {
    setselected_document_type(selecteddoc);

    if (data === 'ration') {
      setCurrentImgClick('ration');
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event, data) => {
    if (data === 'ration') {
      setCurrentImgClick('ration');
      setAnchorEl(null);
    }
  };

  const popImgCall = useCallback(() => {}, [currentImgClick]);

  const handleClick2 = (event, data, selecteddoc) => {
    let pdfWindow = window.open('');
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(selecteddoc) +
        "'></iframe>",
    );
  };

  const getfilebase64 = (config) => {
    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (
      rationCard &&
      rationCard.ration_card_files_xml !== '' &&
      rationCard.ration_card_files_xml !== '-' &&
      rationCard.ration_card_files_xml !== undefined &&
      rationCard.ration_card_files_xml !== null
    ) {
      // console.log("RationCardXML",rationCard.ration_card_files_xml);
      var jsondocs =
        typeof rationCard.ration_card_files_xml === 'object' &&
        JSON.parse(rationCard.ration_card_files_xml);

      var docsFromApi = [];

      let promises = [];
      for (var i = 0; i < jsondocs.length; i++) {
        var config = {
          method: 'get',
          url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        };

        promises.push(getfilebase64(config));
        if (promises.length === jsondocs.length) {
          Promise.all(promises).then((results) => {
            var certdoc = [];
            results.forEach((j, index) => {
              // console.log("jsondocs_inside: "+JSON.stringify(j));
              // console.log("jsondocs_inside index: "+index);

              var cert = {
                ['name']: jsondocs[index].file_name,
                ['base_64_content']: j.base_64_content,
                ['contentType']: j.contentType,
              };
              certdoc.push(cert);
            });

            setRationDocs(certdoc);
            // console.log("certdoc: "+JSON.stringify(certdoc));
          });
        }
      }
    }
  }, [rationCard]);

  const _rationCard =
    rationCard && rationCard.length > 0 ? (
      <>
        <Col xs={12} md={12} className="profile-title">
          <h2>Ration Card Details</h2>
        </Col>
        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p className="mb-0" title={<Translate value="rationCardNumber" />} />
          <FormControl fullWidth>
            <TextField value={rationCard.ration_card_no} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p className="mb-0" title="Ration Card Type" />
          <FormControl fullWidth>
            <TextField value={rationCard.ration_card_type} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p
            className="mb-0"
            title={<Translate value="rationCardDocument" />}
          />

          {Object.keys(RationDocs).map((id, i) => (
            <>
              <div className="browsebutton2-div final">
                <FormControl>
                  <TextField
                    variant="outlined"
                    value={
                      RationDocs !== undefined &&
                      RationDocs.length > 0 &&
                      RationDocs[i].name
                    }
                    style={{ width: '-webkit-fill-available' }}
                    disabled
                  />
                </FormControl>
                {RationDocs[0].contentType !== 'application/pdf' ? (
                  <label
                    className="browse-button2"
                    onClick={(e) =>
                      handleClick(e, 'ration', RationDocs[i].base_64_content)
                    }
                  >
                    <Translate value="view" /> <Visibility />
                  </label>
                ) : (
                  <label
                    className="browse-button2"
                    onClick={(e) =>
                      handleClick2(e, 'ration', RationDocs[i].base_64_content)
                    }
                  >
                    <Translate value="view" /> <Visibility />
                  </label>
                )}
              </div>
            </>
          ))}
          {currentImgClick == 'ration' && (
            <PopOverImage
              id={idration}
              open={openration}
              anchorEl={anchorEl}
              selected_document_type={selected_document_type}
              handleClose={handleClose}
              popImgCall={popImgCall}
              imgTitle="ration"
            />
          )}
        </Col>
      </>
    ) : null;

  return (
    <>
      <Row className="mt-5 mb-0 final all-details-subtitle-row">
        <Col xs={12} md={12} className="profile-title">
          <img alt="..." src={family} />
          <h1>Family Details</h1>
        </Col>
      </Row>
      {_rationCard}
      <Row className="form-row-final mt-4">
        <Col xs={12} className="profile-title mmb-0">
          <h2>Family Member Details</h2>
        </Col>

        {data &&
          data.map((family, i) => {
            // console.log("in family_details map: i=" + i)
            return (
              <>
                <Row className={'mt-0 familymember-card final row' + i}>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>Relation :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField
                            value={family.parent_child_relation}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>First Name :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField value={family.first_name} disabled />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>Middle Name :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField value={family.middle_name} disabled />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>Last Name :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField value={family.last_name} disabled />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>DOB :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField
                            value={moment(family.date_of_birth).format(
                              'DD.MM.YYYY',
                            )}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>Profession :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <FormControl fullWidth>
                            <TextField value={family.proffession} disabled />
                          </FormControl>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        <p>Education :</p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <FormControl fullWidth>
                            <TextField value={family.education} disabled />
                          </FormControl>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="member-p">
                    <FormControlLabel
                      control={
                        <>
                          <Checkbox checked={family.is_nominee} disabled />
                        </>
                      }
                      label="Nominee"
                    />
                  </Col>
                  <Col xs={12} className="member-p">
                    <Row>
                      <Col xs={12} md={6} className="member-p mt-2">
                        <FormControl
                          className="interstateRadio"
                          variant="outlined"
                          fullWidth
                          component="fieldset"
                        >
                          <RadioGroup
                            row
                            value={family.is_regisrered_user === 1 ? '1' : '0'}
                            name={'alreadyRegistered' + i}
                            disabled
                          >
                            <p className="mt-2 mb-2">
                              Already Registered in K.B.O.C.W.W.B
                            </p>
                            <FormControlLabel
                              className="col-2"
                              value="1"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="col-2"
                              value="0"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                      <Col xs={6} className="member-p mt-2">
                        {family.is_regisrered_user === '1' ? (
                          <div className="regnum">
                            <p className="mt-2 mb-2">
                              K.B.O.C.W.W.B Registration Number
                            </p>
                            <FormControl>
                              <TextField
                                variant="outlined"
                                disabled
                                // value={users.family_details.membersList[i]["registrationNo" + i]}
                                // InputProps={{
                                //     endAdornment:
                                //         <InputAdornment position="start">
                                //             {
                                //                 users.family_details.pancheck ?
                                //                     (<img alt="..." src={checkgreen} />)
                                //                     :
                                //                     (<img alt="..." src={checkgrey} />)
                                //             }
                                //         </InputAdornment>,
                                // }}
                              />
                            </FormControl>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );
          })}
      </Row>
    </>
  );
};

export default FamilyDetails;
