import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import language from 'assets/images/translate (1).svg';
import { Translate } from 'react-redux-i18n';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Required, getTokenCookie, showToast } from 'utils/tools';
import moment from 'moment';
import { SERVER } from 'store/actions/user.actions';
import axios from 'axios';
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import FolderIcon from '@mui/icons-material/Folder';
import closeIcon from 'assets/images/close.png';
import { AddCircleRounded } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import uploadFileAPI from 'utils/fileupload';
import { hasEmptyPropertiesFamilyDetails } from 'utils/checkEmptyArray';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
const EditProfile = () => {
  const { labourUserID } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [fetchRelation, setFetchRelation] = useState(null);
  const [fetchProfession, setFetchProfession] = useState(null);
  const [fetchEducation, setFetchEducation] = useState(null);
  const [fetchRationCardType, setFetchRationCardType] = useState(null);
  const [rationCardNumber, setRationCardNumber] = useState(null);
  const [rationCardDocID, setRationCardDocID] = useState(null);

  const rationCardFileRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);

  const [rationCardDoc, setRationCardDoc] = useState([]);
  const [rationCardID, setRationCardID] = useState(null);

  const [familyDetails, setFamilyDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    accountNumber: null,
    IFSC_Code: null,
    bankName: null,
    bankBranch: null,
    bankAddress: null,
    IFSC_Is_Verified: false,
  });

  const [nomineeBankDetails, setNomineeBankDetails] = useState({
    nomineeAccountNumber: null,
    nominee_IFSC_Code: null,
    nomineeBankName: null,
    nomineeBankBranch: null,
    nomineeBankAddress: null,
    nomineeIFSCS_Is_Verified: false,
  });

  const bankPassbookRef = useRef(null);
  const nomineeBankPassbookRef = useRef(null);
  const [bankPassbookDoc, setBankPassbookDoc] = useState([]);
  const [nomineeBankPassbookDoc, setNomineeBankPassbookDoc] = useState([]);

  const [bankPassbookID, setBankPassbookID] = useState(null);
  const [nomineeBankPassbookID, setNomineeBankPassbookID] = useState(null);

  const [nomineeCheck, setNomineeCheck] = useState(false);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Relation',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchRelation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Education',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchEducation(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          setSubmitLoading(false);
          showToast('ERROR', `${err.response.data.errors.msg}`);
        } else {
          setSubmitLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Ration card type',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchRationCardType(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          showToast('ERROR', `${err.response.data.errors.msg}`);
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Profession',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchProfession(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          showToast('ERROR', `${err.response.data.errors.msg}`);
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'family_details',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          // setSubmitLoading(false);
          const { data } = res.data;
          // const { family_details } = data;

          const result = data.map((data) => ({
            relationId: data.relation_id,
            parentChildUserId: data.parent_child_user_id,
            parentChildUniqueID: data.parent_child_unic_id,
            relation: data.catalog_value_parent_child_type_id,
            firstName: `${data?.first_name?.trim()} ${
              data?.middle_name?.trim() ?? ''
            } ${data?.last_name?.trim() ?? ''}`,
            middleName: null,
            lastName: null,

            dob: data.date_of_birth && moment(data.date_of_birth),
            profession: data.catalog_value_profession_id,
            education: data.catalog_value_qualification_id,
            nominee: data.is_nominee ? 1 : 0,
            isInsert: data.parent_child_user_id ? 0 : 1,
            isDeleted: data.isdeleted ? 1 : 0,
            isActive: data.isactive ? 1 : 0,
            isShowing: true,
          }));
          setFamilyDetails(result);
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          setSubmitLoading(false);
          showToast('ERROR', `${err.response.data.errors.msg}`);
        } else {
          setSubmitLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, [labourUserID]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'bank_details',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        if (data.data.length > 0) {
          const { data: data_ } = data;
          // const res = data_.filter((record) => !record.is_nominee);
          // console.log(res);

          setBankDetails((prev) => ({
            ...prev,
            IFSC_Code: data_[0].ifsc_code,
            bankName: data_[0].bank_name,
            bankBranch: data_[0].bank_branch,
            accountNumber: data_[0].account_no,
            bankAddress: data_[0].bank_address,
            IFSC_Is_Verified: data_[0].is_bank_verified,
          }));
          setNomineeCheck(data_.length > 1 && data_[1].is_nominee ? 1 : 0);

          if (data_[1]) {
            setNomineeBankDetails((prev) => ({
              ...prev,
              nominee_IFSC_Code: data_[1].ifsc_code,
              nomineeAccountNumber: data_[1].account_no,
              nomineeBankName: data_[1].bank_name,
              nomineeBankBranch: data_[1].bank_branch,
              nomineeBankAddress: data_[1].bank_address,
              nomineeIFSCS_Is_Verified: data_[1].is_bank_verified,
            }));
          }

          if (data_.length > 0 && Array.isArray(data_) && data_.length > 0) {
            const bankPassBookDoc = JSON.parse(data_[0].bank_upload_files_xml);

            if (Array.isArray(bankPassBookDoc) && bankPassBookDoc.length > 0) {
              setBankPassbookID(bankPassBookDoc);
            }

            if (data_[1]) {
              const nomineeBankPassbook = JSON.parse(
                data_[1].bank_upload_files_xml,
              );

              if (
                Array.isArray(nomineeBankPassbook) &&
                nomineeBankPassbook.length > 0
              ) {
                setNomineeBankPassbookID(nomineeBankPassbook);
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err) {
          console.log('ERROR', 'Something went wrong');
        }
      });
  }, [labourUserID]);

  const handleRationCardFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...rationCardDoc, ...ev.target.files];
        setRationCardDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...rationCardDoc];
    newFiles.splice(index, 1);
    setRationCardDoc(newFiles);
  };

  const handleFormChange = (index, event) => {
    let data = [...familyDetails];
    if (event.target.name === 'firstName') {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        '',
      );
    } else if (event.target.name === 'middleName') {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        '',
      );
    } else if (event.target.name === 'lastName') {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        '',
      );
    } else if (event.target.name === 'aadhaar1') {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        '',
      );
    } else if (event.target.name === 'aadhaar2') {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        '',
      );
    } else if (event.target.name === 'aadhaar3') {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        '',
      );
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFamilyDetails(data);
  };

  const handleDateChange = (date, index, from) => {
    let data = [...familyDetails];
    data[index][from] = new Date(moment(date));
    setFamilyDetails(data);
  };

  const handleCheckBox = (index, event, from) => {
    console.log('Nominees', index, event.target.checked, from);
    let data = [...familyDetails];

    // Uncheck the previous checkbox in the array
    if (event.target.checked) {
      for (let i = 0; i < data.length; i++) {
        if (i !== index) {
          data[i][from] = 0;
        }
      }
    }

    // Update the current checkbox state
    data[index][from] = event.target.checked ? 1 : 0;
    setFamilyDetails(data);
  };

  const addNewFamilyMember = () => {
    let newfield = {
      relation: null,
      firstName: null,
      middleName: null,
      lastName: null,
      parentChildUniqueID: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: 1,
      isActive: 1,
      isDeleted: 0,
      isShowing: true,
    };

    setFamilyDetails([...familyDetails, newfield]);
  };

  const removeFamilyMember = (index) => {
    console.log('famIndex', index);

    const data = [];

    setFamilyDetails((prevFamilyDetails) => {
      const selectedItemId = prevFamilyDetails[index].relationId;
      if (selectedItemId) {
        console.log('SELCTEDID', selectedItemId);
        const updatedFamilyDetails = prevFamilyDetails.map((item) => {
          console.log('ITEMID', item.re);
          if (item.relationId === selectedItemId) {
            // Mark the selected member as deleted
            return {
              ...item,
              isInsert: 0,
              isDeleted: 1,
              isActive: 0,
              isShowing: false,
            };
          } else if (item.isDeleted === 1) {
            // Update the next deleted item
            return {
              ...item,
              isDeleted: 1,
              isActive: 0,
              isShowing: false,
            };
          }
          return item;
        });

        return updatedFamilyDetails;
      } else {
        let data = [...familyDetails];
        data.splice(index, 1);
        return data;
      }
    });

    //  else {
    //    let data = [...familyDetails];
    //    data.splice(index, 1);
    //    setFamilyDetails(data);
    //  }
  };

  const handleBankDetails = (ev) => {
    if (ev.target.name === 'accountNumber') {
      setBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value.replace(/[^0-9]/g, ''),
      }));
    } else {
      setBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    }
  };

  const handleNomineeBankDetails = (ev) => {
    if (ev.target.name === 'nomineeAccountNumber') {
      setNomineeBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    } else {
      setNomineeBankDetails((prev) => ({
        ...prev,
        [ev.target.name]: ev.target.value,
      }));
    }
  };

  const handleFetchIFSC = () => {
    if (bankDetails.IFSC_Code) {
      axios
        .get(`${SERVER}/global/get_ifsc_details?ifsc=${bankDetails.IFSC_Code}`)
        .then(({ data }) => {
          console.log(data);
          const { data: _data } = data;
          setBankDetails((prev) => ({
            ...prev,
            bankName: _data.BANK,
            bankBranch: _data.BRANCH,
            bankAddress: _data.ADDRESS,
            IFSC_Is_Verified: true,
          }));
        })
        .catch((err) => {
          console.log(err);
          showToast('ERROR', 'Please enter valid IFSC CODE');
        });
    } else {
      showToast('ERROR', 'Please Enter IFSC Code');
    }
  };

  const handleNomineeFetchIFSC = () => {
    if (nomineeBankDetails.nominee_IFSC_Code) {
      axios
        .get(
          `${SERVER}/global/get_ifsc_details?ifsc=${nomineeBankDetails.nominee_IFSC_Code}`,
        )
        .then(({ data }) => {
          console.log(data);
          const { data: _data } = data;
          setNomineeBankDetails((prev) => ({
            ...prev,
            nomineeBankName: _data.BANK,
            nomineeBankBranch: _data.BRANCH,
            nomineeBankAddress: _data.ADDRESS,
            nomineeIFSCS_Is_Verified: true,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast('ERROR', 'Please Enter IFSC Code');
    }
  };

  const handleBankPassbookFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...bankPassbookDoc, ...ev.target.files];
        setBankPassbookDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleNomineeBankPassbookFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...nomineeBankPassbookDoc, ...ev.target.files];
        setNomineeBankPassbookDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemovePassbookFile = (index) => {
    const newFiles = [...bankPassbookDoc];
    newFiles.splice(index, 1);
    setBankPassbookDoc(newFiles);
    bankPassbookRef.current.value = '';
  };

  const handleRemoveNomineeFile = (index) => {
    const newFiles = [...nomineeBankPassbookDoc];
    newFiles.splice(index, 1);
    setNomineeBankPassbookDoc(newFiles);
    nomineeBankPassbookRef.current.value = '';
  };

  const handleKeyDown = (ev) => {
    ev.preventDefault();
  };
  const handleBack = () => {};

  const uploadFiles = async () => {
    if (nomineeCheck) {
      if (
        nomineeBankPassbookDoc &&
        nomineeBankPassbookDoc.length > 0 &&
        bankPassbookDoc &&
        bankPassbookDoc
      ) {
        const fileUpload = [];
        const promises1 = [];
        const bankUploadAPI = bankPassbookDoc.forEach((doc) => {
          promises1.push(uploadFileAPI(doc));
        });
        const promises2 = [];
        const nomineeBankUploadAPI = nomineeBankPassbookDoc.forEach((doc) => {
          promises2.push(uploadFileAPI(doc));
        });

        const allPromises = [...promises1, ...promises2];

        Promise.all(allPromises)
          .then((responses) => {
            // Handle responses from all promises

            // Split the responses for promises1 and promises2 if needed
            const responses1 = responses.slice(0, promises1.length);
            const responses2 = responses.slice(promises1.length);

            const bankResponse = responses1.map((doc) => doc.image);
            const nomineeBankResponse = responses2.map((doc) => doc.image);
            // Continue processing or handle the responses as needed
            insertBankDetails(bankResponse, nomineeBankResponse);
          })
          .catch((error) => {
            // Handle errors from any promise in allPromises
            console.error('Error:', error);
          });
      } else {
        insertBankDetails();
      }

      // console.log('FILESRESPONSE', fileUpload);
      // insertBankDetails(fileUpload);
    } else {
      if (bankPassbookDoc && bankPassbookDoc.length > 0) {
        const promises = [];
        bankPassbookDoc.forEach((doc) => {
          promises.push(uploadFileAPI(doc));
        });
        Promise.all(promises)
          .then((promiseResponse) => {
            const mappedData = promiseResponse.map((doc) => doc.image);

            insertBankDetails(mappedData);
          })
          .catch((err) => {
            console.log('ERROR', err);
          });
      } else {
        insertBankDetails();
      }
    }
  };

  const insertBankDetails = (bankPassbook, nomineePassbook) => {
    const familyData = familyDetails.map((data) => ({
      relation_id: data.relationId ? data.relationId : 0,
      parent_child_user_id: data.parentChildUserId ? data.parentChildUserId : 0,
      relation_type_id: data.relation,
      parent_child_unic_id: data.parentChildUniqueID,
      first_name: `${data?.firstName?.trim()}`,
      middle_name: null,
      last_name: null,

      dob: data.dob && moment(data.dob).format('YYYY-MM-DD'),
      profession: data.profession,
      education: data.education,
      is_nominee: data.nominee ? 1 : 0,
      is_registered: 0,
      registeration_no: null,
      labour_user_id: +labourUserID,
      isinsert: data.isInsert,
      isdeleted: data.isDeleted,
      isactive: data.isActive,
    }));

    if (nomineeCheck) {
      if (
        bankPassbookID &&
        bankPassbookID.length > 0 &&
        nomineeBankPassbookID &&
        nomineeBankPassbookID.length > 0
      ) {
        const payLoad = {
          boardId: 1,
          labourUserId: +labourUserID,
          rationCardType: rationCardID,
          rationCardNo: rationCardNumber,
          rationCardImageId: 0,
          rationCardFiles: [],
          families: familyData,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankPassbookID,
              bank_passbook_upload_id: '-',
              is_nominee: 0,
              is_verified: true ? 1 : 0,
            },
            {
              ifsc_code: nomineeBankDetails.nominee_IFSC_Code,
              account_no: nomineeBankDetails.nomineeAccountNumber,
              bank_name: nomineeBankDetails.nomineeBankName,
              bank_branch: nomineeBankDetails.nomineeBankBranch,
              bank_address: nomineeBankDetails.nomineeBankAddress,
              files: nomineeBankPassbookID,
              bank_passbook_upload_id: '-',
              is_nominee: 1,
              is_verified: true ? 1 : 0,
            },
          ],
        };

        axios
          .patch(`${SERVER}/user/v2/profile`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setFinalSubmitLoading(false);
            showToast('SUCCESS', 'Edit Profile Submitted Successfully');
            history.push('/dashboard-user');
          })
          .catch((err) => {
            if (err) {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            } else {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            }
          });
      } else {
        const bankPassbook_ = bankPassbook.map((doc) => ({
          file_id: doc.fileId,
          file_name: doc.originalFileName,
        }));
        const bankExist =
          bankPassbookID && bankPassbookID.length > 0 ? bankPassbookID : [];
        const bankArray = [...bankExist, ...bankPassbook_];
        const nomineePassbook_ = nomineePassbook.map((doc) => ({
          file_id: doc.fileId,
          file_name: doc.originalFileName,
        }));
        const nomineeExist =
          nomineeBankPassbookID && nomineeBankPassbookID.length > 0
            ? nomineeBankPassbookID
            : [];
        const nomineeBankArray = [...nomineeExist, ...nomineePassbook_];
        const payLoad = {
          boardId: 1,
          labourUserId: +labourUserID,
          rationCardType: rationCardID,
          rationCardNo: rationCardNumber,
          rationCardImageId: 0,
          rationCardFiles: [],
          families: familyData,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankArray,
              bank_passbook_upload_id: '-',
              is_nominee: 0,
              is_verified: true ? 1 : 0,
            },
            {
              ifsc_code: nomineeBankDetails.nominee_IFSC_Code,
              account_no: nomineeBankDetails.nomineeAccountNumber,
              bank_name: nomineeBankDetails.nomineeBankName,
              bank_branch: nomineeBankDetails.nomineeBankBranch,
              bank_address: nomineeBankDetails.nomineeBankAddress,
              files: nomineeBankArray,
              bank_passbook_upload_id: '-',
              is_nominee: 1,
              is_verified: true ? 1 : 0,
            },
          ],
        };

        axios
          .patch(`${SERVER}/user/v2/profile`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setFinalSubmitLoading(false);
            showToast('SUCCESS', 'Edit Profile Submitted Successfully');
            history.push('/dashboard-user');
          })
          .catch((err) => {
            if (err) {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            } else {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            }
          });
      }
    } else {
      if (bankPassbookID && bankPassbookID.length > 0) {
        const payLoad = {
          boardId: 1,
          labourUserId: +labourUserID,
          rationCardType: rationCardID,
          rationCardNo: rationCardNumber,
          rationCardImageId: 0,
          rationCardFiles: [],
          families: familyData,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankPassbookID,
              bank_passbook_upload_id: '-',
              is_nominee: 0,
              is_verified: true ? 1 : 0,
            },
          ],
        };

        axios
          .patch(`${SERVER}/user/v2/profile`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setFinalSubmitLoading(false);
            showToast('SUCCESS', 'Edit Profile Submitted Successfully');
            history.push('/dashboard-user');
          })
          .catch((err) => {
            if (err) {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            } else {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            }
          });
      } else {
        const bankPassbook_ = bankPassbook.map((doc) => ({
          file_id: doc.fileId,
          file_name: doc.originalFileName,
        }));
        const bankArray = [...bankPassbookID, ...bankPassbook_];
        const payLoad = {
          boardId: 1,
          labourUserId: +labourUserID,
          rationCardType: rationCardID,
          rationCardNo: rationCardNumber,
          rationCardImageId: 0,
          rationCardFiles: [],
          families: familyData,
          banks: [
            {
              ifsc_code: bankDetails.IFSC_Code,
              account_no: bankDetails.accountNumber,
              bank_name: bankDetails.bankName,
              bank_branch: bankDetails.bankBranch,
              bank_address: bankDetails.bankAddress,
              files: bankArray,
              bank_passbook_upload_id: '-',
              is_nominee: 0,
              is_verified: true ? 1 : 0,
            },
          ],
        };
        console.log('BenficiaryNew', payLoad);
        axios
          .patch(`${SERVER}/user/v2/profile`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            console.log(data);
            setFinalSubmitLoading(false);
            showToast('SUCCESS', 'Edit Profile Submitted Successfully');
            history.push('/dashboard-user');
          })
          .catch((err) => {
            if (err) {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            } else {
              setFinalSubmitLoading(false);
              showToast('ERROR', 'Something went wrong');
            }
          });
      }
    }
  };

  const handleSubmit = () => {
    const familyDetailsCheck = familyDetails.filter(
      (item) => !hasEmptyPropertiesFamilyDetails(item),
    );
    const isNoNomineeSelected = familyDetails.every((item) => !item.nominee);
    if (familyDetails.length === familyDetailsCheck.length) {
      if (isNoNomineeSelected) {
        showToast('ERROR', 'Please select nominee');
      } else {
        if (nomineeCheck) {
          if (
            bankDetails.accountNumber &&
            bankDetails.IFSC_Code &&
            bankDetails.bankName &&
            bankDetails.bankBranch &&
            bankDetails.bankAddress &&
            nomineeBankDetails.nomineeAccountNumber &&
            nomineeBankDetails.nomineeBankName &&
            nomineeBankDetails.nominee_IFSC_Code &&
            nomineeBankDetails.nomineeBankBranch &&
            nomineeBankDetails.nomineeBankAddress
          ) {
            if (
              (bankPassbookDoc && bankPassbookDoc.length > 0) ||
              (bankPassbookID && bankPassbookID.length > 0)
            ) {
              if (
                (nomineeBankPassbookDoc && nomineeBankPassbookDoc.length > 0) ||
                (nomineeBankPassbookID && nomineeBankPassbookID.length > 0)
              ) {
                setFinalSubmitLoading(true);
                uploadFiles();
              } else {
                showToast('ERROR', 'Please upload nominee passbook document');
              }
            } else {
              showToast('ERROR', 'Please upload passbook document');
            }
          } else {
            showToast('Please fill all mandatory details');
          }
        } else {
          if (
            bankDetails.accountNumber &&
            bankDetails.IFSC_Code &&
            bankDetails.bankName &&
            bankDetails.bankBranch &&
            bankDetails.bankAddress
          ) {
            if (
              (bankPassbookDoc && bankPassbookDoc.length > 0) ||
              (bankPassbookID && bankPassbookID.length > 0)
            ) {
              setSubmitLoading(true);
              uploadFiles();
            } else {
              showToast('ERROR', 'Please upload bank passbook document');
            }
          } else {
            showToast('ERROR', 'Please fill all mandatory details');
          }
        }
      }
    } else {
      showToast('ERROR', 'Please fill all family details');
    }
  };

  return (
    <>
      <Row className="personal-div">
        {finalSubmitLoading ? <SimpleBackdrop /> : null}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: '100%' }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              value={language}
              name="language"
              displayEmpty
              //   onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>
        <Row className="m-0">
          {/* {loadingBackDrop ? <SimpleBackdrop /> : null} */}
          <Row className="family-title-row">
            <Col xs={12} className="profile-title">
              <h1>
                <Translate value="rationCardDetails" />
              </h1>
            </Col>
          </Row>

          <Row className="form-row">
            <Col xs={12} className="family-col-1">
              <Row>
                <Col xs={12} md={6}>
                  <p className="mt-3 mb-2">
                    <Translate value="rationCardNumber" />
                  </p>

                  {/* <Required className="mt-3 mb-2" title="Ration Card Number"/> */}
                  <FormControl
                    fullWidth
                    className="formcontrol1"
                    // onKeyPress={(event) => {
                    //   if (event.key === 'Enter') {
                    //     handleEnter();
                    //   }
                    // }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Enter Your Ration Card Number"
                      // name="rationCardNo"
                      value={rationCardNumber}
                      onChange={(ev) => setRationCardNumber(ev.target.value)}
                      // error={allValues.rationCardNoError}
                      // helperText={
                      //   allValues.rationCardNoError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <p className="mt-3 mb-2">
                    <Translate value="rationCardType" />
                  </p>

                  {/* <Required className="mt-3 mb-2" title="Ration Card Type"/> */}
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={rationCardID || '0'}
                      // name="rationCardType"
                      displayEmpty
                      onChange={(ev) => setRationCardID(ev.target.value)}
                    >
                      <MenuItem value="0">
                        <ListItemText primary="Select Ration Card Type" />
                      </MenuItem>
                      {fetchRationCardType &&
                        fetchRationCardType.length &&
                        fetchRationCardType.map((row) => (
                          <MenuItem value={row.value_id}>
                            <ListItemText primary={row.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={6} className="family-col-1">
              <p className="mt-5 mb-2">
                <Translate value="uploadYourRationCardDocument" />
              </p>

              <div
                className="browsebutton-outerdiv"
                style={{ marginLeft: '5px' }}
              >
                <div className="w-100">
                  {rationCardDoc &&
                    rationCardDoc.length > 0 &&
                    rationCardDoc.map((file, index) => (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          // name="mail_id"
                          disabled
                          value={file.name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <IconButton
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))}
                  {rationCardDoc && rationCardDoc.length === 0 && (
                    <FormControl fullWidth className="formcontrol3 w-100">
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        // name="mail_id"
                        disabled
                        // value={file.name}
                      />
                    </FormControl>
                  )}
                  {rationCardDocID && rationCardDocID.length > 0 && (
                    <FormControl fullWidth className="formcontrol3 w-100">
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        disabled
                        value={
                          rationCardDocID &&
                          rationCardDocID.length > 0 &&
                          rationCardDocID[0].file_name
                        }
                      />
                    </FormControl>
                  )}
                </div>
                <div className="browsebutton2-div">
                  <div className="">
                    <input
                      type="file"
                      ref={rationCardFileRef}
                      onChange={handleRationCardFile}
                      multiple
                      hidden
                    />
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className="py-2 mx-2"
                      startIcon={<FolderIcon />}
                      onClick={() => rationCardFileRef.current.click()}
                    >
                      Browse
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <p className="d-flex justify-content-start fs-5 text-danger text-center w-100 my-2">
              <span>
                <Translate value="instructions" />
                *:
              </span>
              <br />
              {/* Format supporting document should be in JPEG, PNG, or PDF
                            (Size contains 500 KB to 2 MB) */}
              <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
              <br />
              <Translate value="sizecontains500KBto2MB" />
            </p>

            <Col xs={12} className="family-col-3 form-inner-card mt-4">
              <p>
                <Translate value="listofFamilyMembers" />
              </p>
            </Col>

            <Col xs={12} className="family-col-3">
              {familyDetails.map(
                (data, index) =>
                  data.isShowing && (
                    <Row className={'familymember-card row'}>
                      {data.relation !== 49 &&
                        data.relation !== 50 &&
                        data.relation !== 51 &&
                        data.relation !== 52 && (
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: '0',
                              width: '30px',
                              height: '30px',
                            }}
                          >
                            <img
                              alt="..."
                              src={closeIcon}
                              className="closebtn"
                              onClick={() => removeFamilyMember(index)}
                            />
                          </div>
                        )}
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >Relation :</p> */}
                            <Required title={<Translate value="relation" />} />
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                // value={allValues.membersList[i]["relation_id" + i]}
                                name="relation"
                                displayEmpty
                                value={data.relation}
                                disabled={
                                  data.relation === 49 ||
                                  data.relation === 50 ||
                                  data.relation === 51 ||
                                  data.relation === 52
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {data.relation === 49 ||
                                data.relation === 50 ||
                                data.relation === 51 ||
                                data.relation === 52
                                  ? fetchRelation &&
                                    fetchRelation.length &&
                                    fetchRelation.map((i) => (
                                      <MenuItem value={i.value_id}>
                                        <ListItemText primary={i.value} />
                                      </MenuItem>
                                    ))
                                  : fetchRelation &&
                                    fetchRelation.length &&
                                    fetchRelation
                                      .filter(
                                        (row) =>
                                          row.value_id !== 49 &&
                                          row.value_id !== 50 &&
                                          row.value_id !== 51 &&
                                          row.value_id !== 52,
                                      )
                                      .map((i) => (
                                        <MenuItem value={i.value_id}>
                                          <ListItemText primary={i.value} />
                                        </MenuItem>
                                      ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >First Name :</p> */}
                            <Required
                              title={<Translate value="labourName" />}
                            />
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <TextField
                                placeholder="Enter Your Name"
                                value={data.firstName}
                                name="firstName"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                // error={allValues.firstnameError}
                                // helperText={allValues.firstnameError ? "Please enter in correct format" : ""}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >DOB :</p> */}
                            <Required title={<Translate value="dOB" />} />
                          </Col>
                          <Col
                            xs={9}
                            style={{ borderBottom: '1px solid grey' }}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                maxDate={
                                  data.relation === 49 || data.relation === 50
                                    ? moment(
                                        users &&
                                          users.getUserRegistrationDetails
                                            .personal_details[0].date_of_birth,
                                      ).subtract(18, 'years')
                                    : moment()
                                }
                                minDate={moment().subtract(100, 'years')}
                                // onClick={() => setPickerStatus(true)}
                                // onClose={() => setPickerStatus(false)}
                                // open={allValues.openDatePicker}
                                className="datepicker"
                                margin="normal"
                                format="DD/MM/YYYY"
                                // value={allValues.dob}
                                // value={selectedDate}
                                // onChange={ handleDateChange }
                                onKeyDown={handleKeyDown}
                                // onChange={(date) => { handleDateChange(date) }}
                                onChange={(date) =>
                                  handleDateChange(date, index, 'dob')
                                }
                                value={data.dob && moment(data.dob)}
                                name="dob"
                                placeholder="DD/MM/YYYY"
                                // disabled={isAadhaarVerified ? true : false}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            <p>
                              <Translate value="profession" />* :
                            </p>
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                displayEmpty
                                value={data.profession}
                                name="profession"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {fetchProfession &&
                                  fetchProfession.length &&
                                  fetchProfession.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            <p>
                              <Translate value="education" />*
                            </p>
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                displayEmpty
                                value={data.education}
                                name="education"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {fetchEducation &&
                                  fetchEducation.length &&
                                  fetchEducation.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="member-p">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(ev) =>
                                handleCheckBox(index, ev, 'nominee')
                              }
                              color="primary"
                              checked={data.nominee}
                              name="nominee"
                            />
                          }
                          label={<Translate value="nominee" />}
                        />
                      </Col>
                    </Row>
                  ),
              )}
            </Col>

            <Col
              xs={12}
              className="addMemberCol mt-4 family-col-10"
              onClick={() => addNewFamilyMember('')}
            >
              <AddCircleRounded />
              <p>
                <Translate value="addMoreFamilyMembers" />
              </p>
            </Col>
          </Row>

          <Row className="m-0">
            {/* {loadingBackDrop ? <SimpleBackdrop /> : null} */}
            <Row className="bank-title-row">
              <Col xs={12} className="profile-title">
                {/* <h1>Bank Passbook / Statement</h1> */}
                <h1>
                  <Translate value="bankPassbookStatement" />
                </h1>
              </Col>
            </Row>

            <Row className="form-row">
              <Col xs={12} md={6} className="bank-col-1">
                <Row className="form-inner-card-right">
                  <Col xs={12}>
                    {/* <p className="mt-3 mb-2">Account Number*</p> */}
                    <Required
                      className="mt-3 mb-2"
                      title={<Translate value="accountNumber" />}
                    />
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Your Account Number"
                        name="accountNumber"
                        value={bankDetails.accountNumber}
                        onChange={handleBankDetails}
                        // error={allValues.accountNumberError}
                        // helperText={
                        //   allValues.accountNumberError
                        //     ? 'Please enter in correct format'
                        //     : ''
                        // }
                      />
                    </FormControl>
                  </Col>

                  <Col xs={12}>
                    {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                    <Required
                      className="mt-4 mb-2"
                      title={<Translate value="enterYourBankName" />}
                    />
                    <FormControl
                      fullWidth
                      className="formcontrol1"
                      // onKeyPress={(event) => {
                      //   if (event.key === 'Enter') {
                      //     handleEnter();
                      //   }
                      // }}
                    >
                      <Tooltip
                        title="Field is disabled,Click FETCH IFSC CODE Button"
                        placement="top-end"
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Enter Your Bank Name"
                          name="bankName"
                          value={bankDetails.bankName}
                          onChange={handleBankDetails}
                          disabled

                          // error={allValues.bankNameError}
                          // helperText={
                          //   allValues.bankNameError
                          //     ? 'Please enter in correct format'
                          //     : ''
                          // }
                        />
                      </Tooltip>
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className="bank-col-2">
                <Row className="form-inner-card-right">
                  <Col xs={8} md={12}>
                    <Required
                      className="mt-3 mb-2"
                      title={<Translate value="enterYourIFSCcode" />}
                    />
                    <div className="d-flex justify-content-center align-items-center">
                      <FormControl
                        fullWidth
                        className="w-100"
                        // onKeyPress={(event) => {
                        //   if (event.key === 'Enter') {
                        //     handleEnter();
                        //   }
                        // }}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Enter Your Bank's IFSC Code"
                          name="IFSC_Code"
                          value={bankDetails.IFSC_Code}
                          onChange={handleBankDetails}
                        />
                      </FormControl>
                      <Button
                        className="px-2 mx-2 w-50 py-2 text-nowrap"
                        variant="contained"
                        onClick={handleFetchIFSC}
                      >
                        {bankDetails.IFSC_Is_Verified
                          ? 'Verified'
                          : 'Fetch Bank Details'}
                      </Button>
                    </div>
                  </Col>

                  <Col xs={12}>
                    {/* <Col xs={6} md={12}>
                                {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                    <Required
                      className="mt-4 mb-2"
                      title={<Translate value="enterYourBankBranch" />}
                    />
                    <FormControl fullWidth>
                      <Tooltip
                        title="Field is disabled,Click FETCH IFSC CODE Button"
                        placement="top-end"
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Enter Your Bank Branch"
                          name="bankBranch"
                          value={bankDetails.bankBranch}
                          onChange={handleBankDetails}
                          disabled
                        />
                      </Tooltip>
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className="bank-col-3">
                <div
                  className="form-inner-card-right row"
                  style={{ marginLeft: '4px', marginRight: '2px' }}
                >
                  {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                  <Required
                    className="mt-0 mb-2"
                    title={<Translate value="enterYourBankAddress" />}
                  />
                  <FormControl
                    fullWidth
                    className="formcontrol1"
                    // onKeyPress={(event) => {
                    //   if (event.key === 'Enter') {
                    //     handleEnter();
                    //   }
                    // }}
                  >
                    <Tooltip
                      title="Field is disabled,Click FETCH IFSC CODE Button"
                      placement="top-end"
                    >
                      <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={3}
                        // rowsMax={4}
                        // defaultValue="Default Value"
                        placeholder="Enter Your Bank Address"
                        name="bankAddress"
                        value={bankDetails.bankAddress}
                        onChange={handleBankDetails}
                        disabled
                        style={{ resize: 'none' }}
                      />
                    </Tooltip>
                  </FormControl>
                </div>
              </Col>
              <Col xs={12} md={6} className="bank-col-4">
                <div
                  className=" mt-4"
                  style={{ marginLeft: '22px', marginRight: '40px' }}
                >
                  {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                  <Required
                    className="mt-4 mb-2"
                    title={<Translate value="uploadYourPassbookDocument" />}
                  />

                  <div className="browsebutton-outerdiv">
                    <div className="w-100">
                      {bankPassbookDoc &&
                        bankPassbookDoc.length > 0 &&
                        bankPassbookDoc.map((file, index) => (
                          <FormControl fullWidth className="formcontrol3 w-100">
                            <TextField
                              className="w-100"
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              // name="mail_id"
                              disabled
                              value={file.name}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {
                                      <IconButton
                                        onClick={() =>
                                          handleRemovePassbookFile(index)
                                        }
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    }
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))}
                      {bankPassbookID && bankPassbookID.length > 0 && (
                        <FormControl fullWidth className="formcontrol3 w-100">
                          <TextField
                            className="w-100"
                            variant="outlined"
                            placeholder="Click Browse to Upload"
                            disabled
                            value={
                              bankPassbookID &&
                              bankPassbookID.length > 0 &&
                              bankPassbookID[0].file_name
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    <IconButton
                                      onClick={() => setBankPassbookID([])}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      )}
                      {bankPassbookDoc &&
                        bankPassbookDoc.length === 0 &&
                        bankPassbookID &&
                        bankPassbookID.length === 0 && (
                          <FormControl fullWidth className="formcontrol3 w-100">
                            <TextField
                              className="w-100"
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              disabled
                            />
                          </FormControl>
                        )}
                    </div>
                    <div className="browsebutton2-div">
                      <div className="">
                        <input
                          type="file"
                          ref={bankPassbookRef}
                          onChange={handleBankPassbookFile}
                          multiple
                          hidden
                        />
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className="py-2 mx-2"
                          startIcon={<FolderIcon />}
                          onClick={() => bankPassbookRef.current.click()}
                        >
                          Browse
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className="mt-3 instructions2">
                    {/* <span><Translate value="instructions"/>*</span><br />
                                Format supporting document should be in JPEG, PNG, or PDF<br />
                                (Size contains 500 KB to 2 MB) */}
                    <span>
                      <Translate value="instructions" />*
                    </span>
                    <br />
                    <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                    <br />
                    <Translate value="sizecontains500KBto2MB" />
                  </p>
                </div>
              </Col>
              <Col xs={12} className="note2 mt-4 ml-3 bank-col-5">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nomineeCheck}
                      onChange={(ev) => setNomineeCheck(ev.target.checked)}
                      name="nomineeCheck"
                      // color="primary"
                    />
                  }
                  label={
                    <Translate value="clickheretoaddtheNomineeBankDetails" />
                  }
                />
              </Col>
            </Row>

            {nomineeCheck ? (
              <>
                <Row className="bank-title-row">
                  <Col xs={12} className="profile-title">
                    {/* <h1>Nominee's Bank Passbook / Statement</h1> */}
                    <h1>
                      <Translate value="nomineesBankPassbookStatement" />
                    </h1>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col xs={12} md={6} className="bank-col-1">
                    <Row className="form-inner-card-right">
                      <Col xs={12}>
                        {/* <p className="mt-3 mb-2">Account Number*</p> */}
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="accountNumber" />}
                        />
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            placeholder="Enter Your Account Number"
                            name="nomineeAccountNumber"
                            value={nomineeBankDetails.nomineeAccountNumber}
                            onChange={handleNomineeBankDetails}
                            // error={allValues.accountNumberNomineeError}
                            // helperText={
                            //   allValues.accountNumberNomineeError
                            //     ? 'Please enter in correct format'
                            //     : ''
                            // }
                          />
                        </FormControl>
                      </Col>
                      <Col xs={12}>
                        {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                        <Required
                          className="mt-4 mb-2"
                          title={<Translate value="enterYourBankName" />}
                        />
                        <FormControl
                          fullWidth
                          className="formcontrol1"
                          // onKeyPress={(event) => {
                          //   if (event.key === 'Enter') {
                          //     handleEnter();
                          //   }
                          // }}
                        >
                          <TextField
                            variant="outlined"
                            placeholder="Enter your Bank Name"
                            name="nomineeBankName"
                            value={nomineeBankDetails.nomineeBankName}
                            onChange={handleNomineeBankDetails}
                            disabled
                            // error={allValues.bankNameNomineeError}
                            // helperText={
                            //   allValues.bankNameNomineeError
                            //     ? 'Please enter in correct format'
                            //     : ''
                            // }
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className="bank-col-2">
                    <Row className="form-inner-card-right">
                      <Col xs={8} md={12}>
                        {/* <p className="mt-3 mb-2">IFSC Code*</p> */}
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="enterYourIFSCcode" />}
                        />
                        <div className="d-flex justify-content-center align-items-center">
                          <FormControl
                            fullWidth
                            className="w-100"
                            // onKeyPress={(event) => {
                            //   if (event.key === 'Enter') {
                            //     handleEnter();
                            //   }
                            // }}
                          >
                            <TextField
                              variant="outlined"
                              placeholder="Enter Your Bank's IFSC Code"
                              name="nominee_IFSC_Code"
                              value={nomineeBankDetails.nominee_IFSC_Code}
                              onChange={handleNomineeBankDetails}
                            />
                          </FormControl>
                          <Button
                            className="px-2 mx-2 w-50 py-2 text-nowrap"
                            variant="contained"
                            onClick={handleNomineeFetchIFSC}
                          >
                            {nomineeBankDetails.nomineeIFSCS_Is_Verified
                              ? 'Verified'
                              : 'Fetch Bank Details'}
                          </Button>
                        </div>
                      </Col>

                      <Col xs={12}>
                        {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                        <Required
                          className="mt-4 mb-2"
                          title={<Translate value="enterYourBankBranch" />}
                        />
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            placeholder="Enter Your Bank Branch"
                            name="nomineeBankBranch"
                            value={nomineeBankDetails.nomineeBankBranch}
                            onChange={handleNomineeBankDetails}
                            disabled
                            // error={allValues.bankBranchNomineeError}
                            // helperText={allValues.bankBranchNomineeError ? "Please enter in correct format" : ""}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className="bank-col-3">
                    <div
                      className="form-inner-card-right row"
                      style={{ marginLeft: '5px' }}
                    >
                      {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                      <Required
                        className="mt-0 mb-2"
                        title={<Translate value="enterYourBankAddress" />}
                      />
                      <FormControl
                        fullWidth
                        className="formcontrol1"
                        // onKeyPress={(event) => {
                        //   if (event.key === 'Enter') {
                        //     handleEnter();
                        //   }
                        // }}
                      >
                        <TextareaAutosize
                          variant="outlined"
                          multiline
                          rows={3}
                          // rowsMax={4}
                          // defaultValue="Default Value"
                          placeholder="Enter Your Bank Address"
                          name="nomineeBankAddress"
                          value={nomineeBankDetails.nomineeBankAddress}
                          onChange={handleNomineeBankDetails}
                          disabled
                          style={{ resize: 'none' }}
                        />
                      </FormControl>
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="bank-col-4">
                    <div className="form-inner-card-right mt-4">
                      <Required
                        className="mt-4 mb-2"
                        title={<Translate value="uploadYourPassbookDocument" />}
                      />

                      <div className="browsebutton-outerdiv">
                        <div className="w-100">
                          {nomineeBankPassbookDoc &&
                            nomineeBankPassbookDoc.length > 0 &&
                            nomineeBankPassbookDoc.map((file, index) => (
                              <FormControl
                                fullWidth
                                className="formcontrol3 w-100"
                              >
                                <TextField
                                  className="w-100"
                                  variant="outlined"
                                  placeholder="Click Browse to Upload"
                                  // name="mail_id"
                                  disabled
                                  value={file.name}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {
                                          <IconButton
                                            onClick={() =>
                                              handleRemoveNomineeFile(index)
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        }
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            ))}

                          {nomineeBankPassbookID &&
                            nomineeBankPassbookID.length > 0 && (
                              <FormControl
                                fullWidth
                                className="formcontrol3 w-100"
                              >
                                <TextField
                                  className="w-100"
                                  variant="outlined"
                                  placeholder="Click Browse to Upload"
                                  disabled
                                  value={
                                    nomineeBankPassbookID &&
                                    nomineeBankPassbookID.length > 0 &&
                                    nomineeBankPassbookID[0].file_name
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {
                                          <IconButton
                                            onClick={() =>
                                              setNomineeBankPassbookID([])
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        }
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            )}
                          {nomineeBankPassbookDoc &&
                            nomineeBankPassbookDoc.length === 0 &&
                            nomineeBankPassbookID &&
                            nomineeBankPassbookID.length === 0 && (
                              <FormControl
                                fullWidth
                                className="formcontrol3 w-100"
                              >
                                <TextField
                                  className="w-100"
                                  variant="outlined"
                                  placeholder="Click Browse to Upload"
                                  disabled
                                />
                              </FormControl>
                            )}
                        </div>
                        <div className="browsebutton2-div">
                          <div className="">
                            <input
                              type="file"
                              ref={nomineeBankPassbookRef}
                              onChange={handleNomineeBankPassbookFile}
                              multiple
                              hidden
                            />
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className="py-2 mx-2"
                              startIcon={<FolderIcon />}
                              onClick={() =>
                                nomineeBankPassbookRef.current.click()
                              }
                            >
                              Browse
                            </Button>
                          </div>
                        </div>
                      </div>

                      <p className="mt-3 instructions2">
                        <span>
                          <Translate value="instructions" />*
                        </span>
                        <br />
                        <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                        <br />
                        <Translate value="sizecontains500KBto2MB" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
          </Row>

          <Row className="button-inside-form-row mb-5">
            <Col xs={12} className="next-back-button-row mt-4 ">
              <Button
                variant="contained"
                color="error"
                style={{ width: '15%' }}
                className="py-3 mx-1"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: '15%', whiteSpace: 'nowrap' }}
                color="success"
                className="py-3 mx-1"
                type="button"
                onClick={handleSubmit}
              >
                Save your details
              </Button>
            </Col>
          </Row>
        </Row>
      </Row>
    </>
  );
};

export default EditProfile;
