import React, { useEffect, useState } from 'react';

import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  boardDetails,
  getUnionMappedDetails,
  insertMappingUnion,
  unmapUnion,
} from './unionMapping.service';
import { showToast } from 'utils/tools';
import { useSelector } from 'react-redux';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

const UnionMappingUI = () => {
  const users = useSelector((state) => state.users);

  const deptUserID =
    users &&
    users.getDeptUserRole &&
    users.getDeptUserRole.data &&
    users.getDeptUserRole.data[0].department_user_id;
  const { unionID, unionName } = useParams();
  const history = useHistory();

  const [board, setBoard] = useState(null);
  const [boardID, setBoardID] = useState(null);
  const [disable, setDisable] = useState(null);
  const [mappedUnion, setMappedUnion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const getAllBoards = async () => {
    const result = await boardDetails();

    setBoard(result);
  };

  const getUnionMapped = async (unionID) => {
    const result = await getUnionMappedDetails(unionID);

    setMappedUnion(result);
  };
  useEffect(() => getAllBoards(), []);
  useEffect(() => getUnionMapped(unionID), [unionID]);
  useEffect(() => {
    if (board && mappedUnion && mappedUnion.length > 0) {
      const mappedData =
        board &&
        board.filter((_data) =>
          mappedUnion.some((b) => b.board_id === _data.id),
        );

      setBoardID(mappedData && mappedData[0].id);
    }
  }, [mappedUnion, board]);

  const handleSubmit = async () => {
    if (boardID && +boardID) {
      setDisable(true);
      setLoading(true);
      try {
        const payLoad = {
          unionId: +unionID,
          boards: [
            {
              id: +boardID,
            },
          ],
          addedBy: deptUserID,
        };
        const result = await insertMappingUnion(payLoad);
        if (result.success) {
          setLoading(false);
          showToast('SUCCESS', `${result.message}`);
          history.push(`/admin/unionList`);
        }
      } catch (error) {
        setDisable(false);
        setLoading(false);
        showToast('ERROR', 'Something went wrong');
      }
    } else {
      showToast('ERROR', 'Please select the board');
    }
  };

  const handleUnmap = async () => {
    setLoading(true);
    const filteredMapData = mappedUnion.filter((union) => union.board_id === 1);
    const payLoad = {
      unionId: +unionID,
      mapId: filteredMapData && filteredMapData[0].board_union_map_id,
      editedBy:
        users &&
        users.getDeptUserRole.data &&
        users.getDeptUserRole.data[0].department_user_id,
    };
    try {
      const result = await unmapUnion(payLoad);
      if (result.success) {
        setLoading(false);
        showToast('SUCCESS', `${result.message}`);
        history.push(`/admin/unionList`);
      }
    } catch (error) {
      setLoading(false);
      showToast('ERROR', 'Something went wrong');
    }
  };

  return (
    <>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <div className="root">
          <Row className="top-div">
            <Row className="topbar-row">
              <Col xs={12} md={8} lg={9} className="top-bar-col">
                <div className="logo-div-profile">
                  <Link to={'/admin/home'}>
                    <img id="logo" src={logo} alt="..." className="logo-img" />
                  </Link>
                  <h1 className="logo-text">
                    Government of Karnataka <br />
                    Karnataka Building & Other Construction Workers Welfare
                    Board (KBOCWWB)
                  </h1>
                </div>
              </Col>
            </Row>
          </Row>
          <div className="position-relative ms-5">
            <div
              className="position-absolute"
              style={{ top: '10px', left: 25 }}
            >
              <Link to={'/admin/unionList'}>
                <img alt="..." className="cal-icon hover-icn" src={back} />
              </Link>
            </div>
          </div>
          <Row className="form-row role-config-form">
            <Col xs={12} md={4} className="personal-col-1 py-5">
              <p className="mb-2 mt-3">Select Board</p>
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital"
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name="board_id"
                  value={boardID || '0'}
                  onChange={(event) => setBoardID(event.target.value)}
                >
                  <MenuItem value="0">
                    <ListItemText primary="Select" />
                  </MenuItem>
                  {board &&
                    board.map(
                      (row) =>
                        row.id === 1 && (
                          <MenuItem value={row.id}>
                            <ListItemText primary={row.code} />
                          </MenuItem>
                        ),
                    )}
                </Select>
              </FormControl>
            </Col>
            <Col xs={12} md={4} className="personal-col-1 py-5">
              <p className="mb-2 mt-3">Union Name</p>
              <FormControl
                fullWidth
                className="formcontrol1"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    // handleEnter();
                  }
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Enter Union Name"
                  name="union_name"
                  value={unionName}
                  disabled
                />
              </FormControl>
            </Col>

            {mappedUnion && mappedUnion.length > 0 && (
              <Col xs={12} md={4} className="personal-col-1 py-5">
                <Button
                  variant="outline-danger"
                  className="mt-5 py-2"
                  onClick={handleUnmap}
                >
                  Unmap
                </Button>
              </Col>
            )}
          </Row>
          <div className="d-flex justify-content-center align-align-items-center my-3 gap-3">
            <Link to={'/admin/unionList'}>
              <Button className="btn btn-lg bg-danger px-5">Back</Button>
            </Link>
            <Button
              disabled={disable}
              className="btn btn-lg bg-success px-5"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default UnionMappingUI;
