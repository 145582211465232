import 'date-fns';
import { withStyles, Menu, MenuItem, ListItemText, Select, FormControl, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTokenCookie, showToast } from 'utils/tools';
import { SERVER } from 'store/actions/user.actions'
import axios from 'axios';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '350px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const UnionMappingForm = (props) => {
    const users = useSelector(state => state.users);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElRole, setAnchorElRole] = React.useState(null);

    const { location } = props;
    console.log("MappingLOC",location);
    const [roles, setRolesMaster] = useState([]);
    const [boardsMaster, setBoardsMaster] = useState([]);

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
     
        
    });


    const [addValues,setAddValues] = useState([{
        board_id:"",
        union_name:location.rowdata.name
    }])


    const handleValueChange = (index,ev) => {
        console.log(index,ev.target.value);
        const values = [...addValues]
        const Val = values[index][ev.target.name] = ev.target.value
        console.log("CHANGED:",Val);   
        setAddValues(values)
        
        
    }


    useEffect(()=>{
        axios.get(`${SERVER}/config/union/get_union_board_mapping/${location.rowdata.labour_union_id}`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        }).then((res)=>{
            console.log("UnionMAPPING",res.data.data);
            
        })
    },[location])

    const setBoards = () => {
        var config = {
            method: 'get',
            url: SERVER + '/global/get_board_details?id=1',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_board_details Response', response.data.data)
                setBoardsMaster(response.data.data)
            })
            .catch(function (error) {
                console.log('error', error)
            });
    }

    // const handleSelect = (id) => {
    //     console.log(id);
    // }
    useEffect(() => {
        console.log("location: ", location)
        if (location.rowdata !== undefined && location.rowdata !== null && location.rowdata !== "") {
            // getRole()
            setBoards()
            setAllValues({
                ...allValues,
                recieved_rowdata: location.rowdata
            })
        }
    }, [])

    useEffect(() => {
        console.log("allvalues: ", allValues)
    }, [allValues])

    

  

    const addNewRow = () => {
        setAddValues([...addValues, {board_id:'',union_name:''}])
    }

    const deleteRow = (item) => {
        console.log("in deleteRow: item=" + item)
        var array = [...allValues.rows];
        var index = array.indexOf(item)
        array.splice(index, 1);
        setAllValues({
            ...allValues,
            rows: array,
        });
    }
    
    const saveButtonPressed = () => {
        console.log("in saveButtonPressed")
        console.log("VALUESBOARD",addValues);
        if(addValues.find((board)=> board.board_id === ''))
        {
            showToast("ERROR","Please select the board")
        }
        else{
            console.log("API CALLED");
                var boards = []

            addValues.forEach((i,key) => {
                boards = boards.concat({
                    "board_id": i.board_id,
                })
            })
            var data = JSON.stringify({
            "id": location.rowdata.labour_union_id,
            "boards": boards,
            "added_by": users.getDeptUserRole.data[0].department_user_id
            });
                var config = {
            method: 'post',
            url: SERVER+'/config/union/insert_union_board_mapping',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data : data
            };

            axios(config)
            .then(function (response) {
                console.log("role_board_mapping response:",response.data)
                showToast("SUCCESS","Unions Saved Successfully!")
                props.history.push("/admin/union-master")
            })
            .catch(function (error) {
                console.log(error);
                showToast("ERROR","Some error occurred while saving Roles.")
            });
        }
        // if(allValues.rows.length <= 0 ){
        //     showToast("ERROR","Please add a Role")
        // }
        // else{

        //     var boards = []

        //     allValues.rows.forEach((i,key) => {
        //         boards = boards.concat({
        //             "board_id": i.board_id,
        //         })
        //     })

        //     var data = JSON.stringify({
        //                 "id": location.rowdata.labour_union_id,
        //                 "boards": boards,
        //                 "added_by": users.getDeptUserRole.data[0].department_user_id
        //                 });

        //     var config = {
        //     method: 'post',
        //     url: SERVER+'/config/union/insert_union_board_mapping',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${getTokenCookie()}`
        //     },
        //     data : data
        //     };

        //     axios(config)
        //     .then(function (response) {
        //         console.log("role_board_mapping response:",response.data)
        //         showToast("SUCCESS","Unions Saved Successfully!")
        //         props.history.push("/admin/union-master")
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //         showToast("ERROR","Some error occurred while saving Roles.")
        //     });
        // }
    }
console.log("BOARDVALUES:",addValues.map((data)=> data.board_id));
    

    return (

        <>
            <div className="root">
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                    Government of Karnataka <br />
                                    Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                                </h1>
                            </div>

                        </Col>

                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={8} className="title-col">
                    <Link 
                        // to="#"
                        to ={{ 
                            pathname: "/admin/union-master", 
                            userId: location.state!==undefined && location.state.userId,
                            userrow: location.state!==undefined && location.state.userrow
                            }} 
                        // onClick={() => props.history.push("/admin/user-role-mapping-form",  
                        //         { 
                        //             userId: location.state.userId,
                        //             userrow: location.state.userrow
                        //         })}
                                >
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Union Mapping</p>
                    </Link>
                </Col>
                <Col xs={4} className="title-col" >
                    <p className="title">Union Name : {location.rowdata.name}</p>
                </Col>
            </Row>
            <Row className="form-row mt-4 role-config-form-row" >
            {addValues.map((addValue,index)=>(
                <Row className="form-row role-config-form">
                
                <Col xs={12} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Board</p>
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            name='board_id'
                            value={addValue.board_id}
                            onChange={(event) =>handleValueChange(index,event)}
                        >
                            <MenuItem value="">
                                <ListItemText primary="-" />
                            </MenuItem>
                            {
                                boardsMaster && boardsMaster.map((board)=>(
                                    <MenuItem value={board.id} >
                                        <ListItemText primary={board.name}/>
                                    </MenuItem>
                                ))
                            }
                            
                        </Select>
                    </FormControl>
                
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                       <p className="mb-2 mt-3">Union Name</p> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Union Name"
                                name="union_name"
                                value={location.rowdata.name}
                                disabled 
                                onChange={(event) =>handleValueChange(index,event)}
                                // helperText = {allValues.firstnameError ? "Please enter alphabets only" : "" }
                                // error = {allValues.firstnameError}


                            />
                        </FormControl>
                </Col>
                            
             
                <Col xs={1} className="mt-5 p-1 pl-5" align="center">
                    <Button variant="outline-danger" className="" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                </Col>
            </Row>       
                ))}
                
                <Row className="form-row mt-1" >
                    <Col xs={12} className="mt-4 pr-1" align="center">
                        <Button variant="outline-danger" className="add-more-button" onClick={() => addNewRow()}>
                            Add More +
                        </Button>
                        <Button variant="outline-primary" className="save-button" onClick={()=> saveButtonPressed()}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default UnionMappingForm;