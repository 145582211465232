
import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, Popover, MenuItem, ListItemIcon, ListItemText, CircularProgress, FormControlLabel, Checkbox, makeStyles, Backdrop } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import checkGreen from 'assets/images/Tick-01.png'
import closeicon from 'assets/images/closeIcon.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import Moment from 'react-moment';
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import usericon from 'assets/images/Icon awesome-user.svg'
import { getUserRegistrationDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link, useParams } from 'react-router-dom';
import { Required, showToast, getTokenCookie, dataURLtoFile } from 'utils/tools';
import { CheckCircle } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Translate } from 'react-redux-i18n';
import { Visibility } from '@material-ui/icons';

import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import validator from 'validator';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const WelfareMedical = (props) => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const i18nlang = useSelector(state => state.i18n)
    const hiddenFileInput = React.useRef(null);
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        worker_name:"",
        worker_name_error:false,
        mobile_number:"",
        mobile_number_error:false,
        organization_name:"",
        organization_name_error:false,
        designation:"",
        designation_error:"",
        monthly_salary:"",
        monthly_salary_error:"",
        nature_disease:"",
        nature_disease_error:"",
        duration_treatment:"",
        duration_treatment_error:"",
        amount_spent:"",
        amount_spent_error:"",
        account_no:"",
        account_no_error:"",
        ifsc_code:"",
        ifsc_code_error:"",
        openDatePicker: false,
        postalAddress:"",
        organization_address : "",
        date_of_joining : "" ,
        otherSource : "",
        bank_name : "",
        bank_branch : "",
        amount_spent_bank : "",
        props_this : props




    })


    const inputRef = useRef(null);
    const [schemeData,setSchemeData] =useState([]);
    const [schemeDetailsfiltered,setSchemeDetailsFiltered] =useState([]);
    const [schemeDetails,setSchemeDetails] =useState(null);
    const [schemeDetailsFilteredData,setSchemeDetailsFilteredData] =useState([]);
    const [selected_document_type, setselected_document_type] = React.useState("");




    const [selectedfiles, setselectedfiles] = React.useState({})
    const [selectedfilesAttested, setselectedfilesAttested] = React.useState({})
    const [selectedfilesMedical, setselectedfilesMedical] = React.useState({})
    const [selectedfilesMedicalCert, setselectedfilesMedicalCert] = React.useState({})
    const [selectedfilesXerox, setselectedfilesXerox] = React.useState({})
    const [photoUpload, setphotoUpload] = React.useState("")
    const [employerDocUpload, setEmployerDocUpload] = React.useState("")
    const [medical_documentUpload, setmedical_documentUpload] = React.useState("")
    const [medical_certificatetUpload, setmedical_certificateUpload] = React.useState("")
    const [passbookUpload, setpassbookUpload] = React.useState("")





    const [anchorElmarriage_cert, setAnchorElmarriage_cert] = React.useState(null);
    const openmarriage_cert = Boolean(anchorElmarriage_cert);
    const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;


    const [anchorElemployerdoc, setAnchorElemployerdoc] = React.useState(null);
    const openemployerdoc = Boolean(anchorElemployerdoc);
    const idemployerdoc = openemployerdoc ? "simple-popover" : undefined;




    const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false)

    const [loadingfiles, setloadingfiles] = React.useState(true)
    const [attestedDoc, setAttestedDoc] = React.useState({})
    const [medicalDoc, setMedicalDoc] = React.useState({})
    const [medicalDocCert, setMedicalDocCert] = React.useState({})
    const [filesXerox, setFilesXerox] = React.useState({})



    const [filelist, setfilelist] = React.useState({})
    const [filelistAttested, setfilelistAttested] = React.useState({})
    const [filelistMedical, setfilelistMedical] = React.useState({})
    const [filelistMedicalCert, setfilelistMedicalCert] = React.useState({})
    const [filelistFilesXerox, setfilelistFilesXerox] = React.useState({})

    

    // const handleFileChange = event => {
    //     setFileObj( event.target.files && event.target.files);
    //     if (!fileObj) {
    //       return;
    //     }
    // }

    // const resetFileInput = (event) => {
    //     // reset input value
    //     setFileObj(event.target.value=null)
    //   };
   

    const handleChange1 = (e) => {
        console.log("kkkkk", e.target.value)
        if (e.target.value === "ka") {

            setAllValues({
                ...allValues,
                descriptionsKannada: true,
                [e.target.name]: e.target.value,

            })

        }
        else {
            setAllValues({
                ...allValues,
                descriptionsKannada: false,
                [e.target.name]: e.target.value,

            })
        }


        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }
    const handleChange = event => {
        setAllValues({
            ...allValues,
            ...allValues,
            [event.target.name]: event.target.value
        })
      };
    //   const handleClick = event => {
    //     hiddenFileInput.current.click();
    //   };
      const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

    const handleClick = (event, data, selecteddoc) => {
        console.error("handleclick data:" + data)
        console.error("handleclick selecteddoc:" + selecteddoc)

        setselected_document_type(selecteddoc)

        if (data === "photoDoc"){
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if (data === "employerDoc"){
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if(data === "medDoc")
            {
                setAnchorElmarriage_cert(event.currentTarget);
    
            }
        else if(data === "medicalCert")
        {
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if(data === "passbookDoc")
        {
            setAnchorElmarriage_cert(event.currentTarget);

        }

        
        
    
    };
     
    const handleClose = (event, data) => {
            if(data === "photoDoc"){
                setAnchorElmarriage_cert(null);

            }
            else if(data === "employerDoc" ){
                setAnchorElemployerdoc(null)

            }
            else if(data === "medDoc" ){
                setAnchorElemployerdoc(null)

            }
            else if(data === "medicalcert" ){
                setAnchorElemployerdoc(null)

            }
            

    };

    const getfilebase64 = (config) => {
        return new Promise((resolve, reject) => {

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));


                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error)
                });

        })
    }


     


    // const nextButtonPressed = () => {

    //     // try {
    //     //     // console.log(schemeDetailsFilteredData.id, "approve data");
    //     //     // if(schemeDetailsFilteredData.id !== undefined){
    //         var dataSubmit = JSON.stringify({
    //             "id": schemeDetailsFilteredData.id,
    //             "board_id": 1006,
    //             "scheme_id": 29,
    //             "approved_rejected_by": 501,
    //             "approver_remarks": "Approved ",
    //             "amount_sanctioned_by_approver": 0,
    //             "approver_documents": "Dd"
    //         })
            
    //         var config = {
    //             method: 'post',
    //             url: SERVER+'/welfare/approve_reject_welfare_scheme',
    //             headers:{
    //                 'Authorization':`Bearer ${getTokenCookie()}`
    //                 },
    //             data : dataSubmit
    //             };
        
    //             axios(config)
    //             .then(function (response) {
    //                 console.log(JSON.stringify(response.data, "approve data"));
    //                 showToast("SUCCESS", "Scheme has been approved successfully")
    //                 props.history.push("/welfaredashboard")                
              
        
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    // }

    // const rejectButtonPressed = () => {

    //     // try {
    //     //     // console.log(schemeDetailsFilteredData.id, "approve data");
    //     //     // if(schemeDetailsFilteredData.id !== undefined){
    //         var dataSubmit = JSON.stringify({
    //             "id": schemeDetailsFilteredData.id,
    //             "board_id": schemeDetailsFilteredData.id,
    //             "scheme_id": 32,
    //             "approved_rejected_by": 501,
    //             "approver_remarks": "Approved ",
    //             "amount_sanctioned_by_approver": 0,
    //             "approver_documents": 46
    //         })
            
    //         var config = {
    //             method: 'post',
    //             url: SERVER+'/welfare/approve_reject_welfare_scheme',
    //             headers:{
    //                 'Authorization':`Bearer ${getTokenCookie()}`
    //                 },
    //             data : dataSubmit
    //             };
        
    //             axios(config)
    //             .then(function (response) {
    //                 console.log(JSON.stringify(response.data, "approve data"));
    //                 showToast("SUCCESS", "Scheme has been rejected successfully")
    //                 props.history.push("/welfaredashboard")
              
        
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    // }



     useEffect(() => {
        console.log("selectedfiles",selectedfiles)
     },[selectedfiles])

     useEffect(() => {
        var config = {
            method: 'get',
            url: SERVER+'/welfare/get_welfare_scheme_availed_details?board_id=1006&scheme_id=29&catalog_value_application_status=47',
            headers: { 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
          
        };
        
        axios(config)
        .then(function (response) {
            console.log("response get_fields_value: "+JSON.stringify(response.data.data));
            setSchemeData(response.data.data)
            // setphotoUpload(response.data.data[0].photo)
            // setlabourName(response.data.data.labour_name)
        })
        .catch(function (error) {
            console.error(error);
        });

        // console.log("propsID: "+ props);
        console.log("in useEffect props userid:" + allValues.props_this.location.userid + " scheme_avail_id:" + allValues.props_this.location.scheme_avail_id)
 
        
     },[])

    //  const scheme_name_ad = users.getSchemesOnStatus.data.recordsets[0].find(o => o.scheme_avail_id === allValues.props_this.location.scheme_avail_id)


    //  useEffect(() => {
    //     if(photoUpload !== null){
    //         console.log("photoUpload",photoUpload[0])
    //         // var photoUpload1 = JSON.stringify(photoUpload)
    //         var doc = JSON.parse(JSON.stringify(photoUpload));
    //         console.log("doc1",doc[0])
            


    //     }
    //  },[photoUpload])

     useEffect(() => {
        console.log("idScheme2", schemeData.data)
        if(schemeData !== undefined){
            let schemeDetailsFiltered = schemeData.filter(o => o.id === allValues.props_this.location.scheme_avail_id)
            console.log("schemeDetailsFiltered", schemeDetailsFiltered)
            if(schemeDetailsFiltered[0] !== undefined && schemeDetailsFiltered[0] !== null){
                setSchemeDetailsFilteredData(schemeDetailsFiltered[0])

            }
        }

     },[schemeData])

     useEffect(() => {
        console.log("schemeDetailsFilteredData",schemeDetailsFilteredData.photo)
        if (
            schemeDetailsFilteredData.photo !== undefined &&
            schemeDetailsFilteredData.photo !== undefined &&
            schemeDetailsFilteredData.photo.length > 0 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.photo)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setphotoUpload(certdoc)
                            // console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }


        if (
            schemeDetailsFilteredData.employer_document !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.employer_document)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setEmployerDocUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        if (
            schemeDetailsFilteredData.medical_document !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.medical_document)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setmedical_documentUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        if (
            schemeDetailsFilteredData.medical_certificate !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.medical_certificate)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setmedical_certificateUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        if (
            schemeDetailsFilteredData.passbook !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.passbook)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setpassbookUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        

     },[schemeDetailsFilteredData])

    //  useEffect(() => {
    //     if(schemeDetailsfiltered !== null && schemeDetailsfiltered.length > 0){
    //         console.log("schemeDetailsfiltered",schemeDetailsfiltered)
    //         console.log("schemeDetailsfiltered1",schemeDetailsfiltered[0])
    //         setSchemeDetailsFilteredData(schemeDetailsfiltered)


    //     }
    //  },[schemeDetailsfiltered])

    useEffect(() => {
        console.log("employerDocUpload", employerDocUpload[0])
    }, [employerDocUpload])


  return (
    <div>
    <div className="root">



<Row className="top-div ">
    <Row className="topbar-row">
        <Col xs={12} md={4} lg={6} className="top-bar-col">
            <div className="logo-div-profile">
                <a href="/boardsHome">
                    <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                    {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                    <Translate value="labourTitle" /> 
                    
                </h1>
            </div>

        </Col>
        <div className='d-flex justify-content-end'>
        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
            <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={allValues.language}
                name="language"
                displayEmpty
                onChange={handleChange1}
            >
                <MenuItem value="" >
                    <ListItemIcon>
                        <img alt="..." src={language} className="language-img" />
                    </ListItemIcon>
                    <ListItemText primary="Select Language" />
                </MenuItem>
              
                <MenuItem value="en">
                    <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                    <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
            </Select>
        </Col>
        </div>
        
    </Row>

</Row>
</div>

<Row className="schemes-title-row">
                <Col xs={12} md={9}>
                    
                    <h1> <Translate value="schemes" /> |
                    <Translate value="medicalTitle"/>  
                    </h1>

                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Worker Details</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
           
                 <Col xs={12} md={4}>
                     <p className="mb-2"> Name of the Worker </p>

                     <FormControl fullWidth className="formcontrol1">
                         <TextField
                             variant="outlined"
                             name="worker_name"
                             value={schemeDetailsFilteredData.labour_name}
                             disabled
     
                         />
                     </FormControl>
                     </Col>
                
                    
                   
             
                <Col xs={12} md={4}>
                
                    <p className="mb-2">Postal Address of the Worker</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="postalAddress"
                             value={schemeDetailsFilteredData.labour_address}
                             disabled
                            
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                    
                    <p className="mb-2">Mobile Number</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="mobile_number"
                            value={schemeDetailsFilteredData.phone_number}
                            disabled
                          
                        />
                    </FormControl>
                </Col>
            </Row>
            
            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                  
                    <p>Organization Details</p>
                </Col>
            </Row>
            
            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} md={4}>
                    
                    <p className="mb-2">Name of the Organization </p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="organization_name"
                            value={schemeDetailsFilteredData.organisation_name}
                            disabled
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                
                    <p className="mb-2">Full Address of the Organiszation</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="organization_address"
                            value={schemeDetailsFilteredData.date_of_joining}
                            disabled
                        
                            // value={""}
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                
                    <p className="mb-2">Date of Joining</p>
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.date_of_joining}
                            disabled
                            
                        />
                    </FormControl>
                   </Col>
            
                <Col xs={12} md={4} className="mt-2">
                
                    <p className="mb-2">Designation</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.designation}
                            disabled
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-2">
                
                    <p className="mb-2">Monthly Salary</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.bank_name}
                            disabled
                            
                        />
                    </FormControl>
                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Bank Details</p>
                </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">

               
               
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2">Bank Name</p>
                    
                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.bank_name}
                            disabled
                   
                           
                        />
                        
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">
                    Branch
                    </p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
             
                            variant="outlined"
                            value={schemeDetailsFilteredData.bank_branch}
                            disabled
                           
                        />
                    </FormControl>
                </Col>
            
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">Account No.</p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.ifsc_code}
                            disabled
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">IFSC Code</p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.ifsc_code}
                            disabled
                            
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                <p className="mb-2 w-75">Amount of Financial assistance sought</p>

                <FormControl fullWidth className="formcontrol1 w-75">
                    <TextField
                        variant="outlined"
                        value={schemeDetailsFilteredData.amount_sought}
                        disabled
                        // helperText={allValues.amount_spent_error ? "Please Enter Numbers only":""}
                        // error = {allValues.amount_spent_error}
                    />
                </FormControl>
            </Col>

                
                </Row>
                
            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Medical Details</p>
                </Col>
            </Row>


            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} md={4} >
                    
                    <p>Nature of Disease</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.nature_of_disease}
                            disabled

                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-2">
                
                    <p className="mb-2">Duration of the Treatment</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.duration_of_treatment}
                            disabled
                          
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-2">
                
                    <p className="mb-2">Medical assistance from Any other source </p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.medical_assistance_from_other_source}
                            disabled
                           
                        />
                    </FormControl>
                    <p className='medical-assistance-instruction'>/ Details of Financial Assistance if received</p>
                </Col>
                
                <Col xs={12} md={4} className="mt-1">
                
                    <p className="mb-2">
                        Total amount spent on Treatment
                    </p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.amount_sought}
                            disabled
                            
                        />
                    </FormControl>
                    <p className='medical-assistance-instruction'>(Relevant original bills should be attached along with the attestation of the Doctor who gave the treatment)</p>
                </Col>
            

                {/* <Col xs={12} md={4} lg={8} xl={8} className="mt-2">
                
                    <p className="mb-2 w-50">Medical assistance from any other source / Details of Financial Assistance if received</p>

                    <FormControl fullWidth className="formcontrol1 w-50">
                        <TextField
                            variant="outlined"
                            value={allValues.otherSource}
                            name="otherSource"
                            onChange={handleChangeValidation}
                         
                           
                        />
                    </FormControl>
                </Col> */}

                
                </Row>
                
            




         

                <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Uploaded Documents</p>
                </Col>
            </Row>

            

            <Row className="form-row mt-4 config-form searchform">



                <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                    <div className="">
                        <p className="mt-4 mb-2">Photo</p>

                                {
                                        schemeDetailsFilteredData.photo !== undefined &&
                                        JSON.parse(schemeDetailsFilteredData.photo).map((doc) =>
                                            <>
                                                <div className="browsebutton2-div">
                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name={doc.file_name}
                                                            value={doc.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                    <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                                    <label className="schemes-doc-view"
                                                        onClick={(e) => handleClick(e, "photoDoc", photoUpload[0].base_64_content)}
                                                    >
                                                        <Visibility />
                                                    </label>
                                                </div>
                                            </>
                                        )
                                }       
                    </div>
                

              
    



                <Popover
                    id={idmarriage_cert}
                    open={openmarriage_cert}
                    anchorEl={anchorElmarriage_cert}
                    onClose={(e) => handleClose(e, "photoDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >

                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}

                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                }}
                            // src={allValues.selected_document_type}

                         
                        />
                    </a>
                </Popover>
                </Col>
           

            <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                    <div className="">
                        <p className="mt-4 mb-2">Attested Employer Document</p>

                                {
                                        schemeDetailsFilteredData.photo !== undefined &&
                                        JSON.parse(schemeDetailsFilteredData.employer_document).map((doc) =>
                                            <>
                                                <div className="browsebutton2-div">
                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name={doc.file_name}
                                                            value={doc.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                    <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                                    <label className="schemes-doc-view"
                                                        onClick={(e) => handleClick(e, "employerDoc", employerDocUpload[0].base_64_content)}
                                                    >
                                                        <Visibility />
                                                    </label>
                                                </div>
                                            </>
                                        )
                                }       
                    </div>
             
                <Popover
                    id={idemployerdoc}
                    open={openemployerdoc}
                    anchorEl={anchorElemployerdoc}
                    onClose={(e) => handleClose(e, "employerDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >

                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}

                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                }}
                            // src={allValues.selected_document_type}

                         
                        />
                    </a>
                </Popover>
                </Col>

                <Col xs={6} className="final-personal-col-1 px-3 mt-2">
                    <div className="">
                        <p className="mt-4 mb-2">Attested Medical  Document</p>

                                {
                                        schemeDetailsFilteredData.medical_document !== undefined &&
                                        JSON.parse(schemeDetailsFilteredData.medical_document).map((doc) =>
                                            <>
                                                <div className="browsebutton2-div">
                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name={doc.file_name}
                                                            value={doc.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                    <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                                    <label className="schemes-doc-view"
                                                        onClick={(e) => handleClick(e, "medicalCert", medical_documentUpload[0].base_64_content)}
                                                    >
                                                        <Visibility />
                                                    </label>
                                                </div>
                                            </>
                                        )
                                }       
                    </div>
                

              
    



                <Popover
                    id={idmarriage_cert}
                    open={openmarriage_cert}
                    anchorEl={anchorElmarriage_cert}
                    onClose={(e) => handleClose(e, "medicalCert")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >

                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}

                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                }}
                            // src={allValues.selected_document_type}

                         
                        />
                    </a>
                </Popover>
                </Col>
           

            <Col xs={6} className="final-personal-col-1 px-3 mt-2">
                    <div className="">
                        <p className="mt-4 mb-2">Medical Certificate</p>

                                {
                                        schemeDetailsFilteredData.medical_certificate !== undefined &&
                                        JSON.parse(schemeDetailsFilteredData.medical_certificate).map((doc) =>
                                            <>
                                                <div className="browsebutton2-div">
                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name={doc.file_name}
                                                            value={doc.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                    <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                                    <label className="schemes-doc-view"
                                                        onClick={(e) => handleClick(e, "medDoc", medical_certificatetUpload[0].base_64_content)}
                                                    >
                                                        <Visibility />
                                                    </label>
                                                </div>
                                            </>
                                        )
                                }       
                    </div>
             
                <Popover
                    id={idemployerdoc}
                    open={openemployerdoc}
                    anchorEl={anchorElemployerdoc}
                    onClose={(e) => handleClose(e, "medDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >

                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}

                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                }}
                            // src={allValues.selected_document_type}

                         
                        />
                    </a>
                </Popover>
                </Col>

                <Col xs={6} className="final-personal-col-1 px-3 mt-2">
                    <div className="">
                        <p className="mt-4 mb-2">Must attach the xerox copy of Pass book of SB account held by the applicant in a Nationalized Bank</p>

                                {
                                        schemeDetailsFilteredData.passbook !== undefined &&
                                        JSON.parse(schemeDetailsFilteredData.passbook).map((doc) =>
                                            <>
                                                <div className="browsebutton2-div">
                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name={doc.file_name}
                                                            value={doc.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                    <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                                    <label className="schemes-doc-view"
                                                        onClick={(e) => handleClick(e, "passbookDoc", passbookUpload[0].base_64_content)}
                                                    >
                                                        <Visibility />
                                                    </label>
                                                </div>
                                            </>
                                        )
                                }       
                    </div>
                

              
    



                <Popover
                    id={idmarriage_cert}
                    open={openmarriage_cert}
                    anchorEl={anchorElmarriage_cert}
                    onClose={(e) => handleClose(e, "passbookDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >

                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}

                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                }}
                            // src={allValues.selected_document_type}

                         
                        />
                    </a>
                </Popover>
                </Col>

                </Row>

          



            




    </div>
  )
}

export default WelfareMedical