import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import state from 'assets/images/state-01.svg'
import district from 'assets/images/district-01-01.svg'
import taluk from 'assets/images/taluk-01.svg'
import city from 'assets/images/city-01.svg'
import gram from 'assets/images/village-01.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigMinistryDetails, getLabourStatusDetails, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';


const MappingMaster = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })


    useEffect(() => {
        // console.log("in useEffect [users.user]")
            dispatch(getConfigMinistryDetails())
    }, []);


    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Ministry</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                    <Col xs={12} className="title-col">
                        <Link to="/admin/home">
                            <img alt="..." className="cal-icon hover-icn" src={home}/>
                        </Link>
                            <p className="title-p">Mapping Master</p>
                    </Col>
                </Row>
                <Row className="config-page mt-5">                    
                    <Col xs={12} className="mapping-cards-col">
                        
                        <Link to="/admin/mapping-master-state">
                        <div className="mapping-card">
                            <img alt="..." src={state}/>
                            <p>State</p>
                        </div>
                        </Link>
                        <Link to="/admin/mapping-master-district">
                        <div className="mapping-card">
                            <img alt="..." src={district}/>
                            <p>District</p>
                        </div>
                        </Link>
                        <Link to="/admin/mapping-master-taluk">
                        <div className="mapping-card">
                            <img alt="..." src={taluk}/>
                            <p>Taluk</p>
                        </div>
                        </Link>
                        <Link to="/admin/mapping-master-city">
                        <div className="mapping-card">
                            <img alt="..." src={city}/>
                            <p>City / Gram Panchayat</p>
                        </div>
                        </Link>
                        <Link to="/admin/mapping-master-village">
                        <div className="mapping-card">
                            <img alt="..." src={gram}/>
                            <p>Village</p>
                        </div>
                        </Link>
                    </Col>
                </Row>
        </>
    )
}

export default MappingMaster;