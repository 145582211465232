import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Sakala from 'assets/images/sakala.png';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import moment from 'moment';

export default function MoreOptions({ data }) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ background: 'white' }}>
            <img className="" style={{}} alt="..." src={Sakala} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Sakala Number"
          secondary={data.gsc_no ?? 'N/A'}
        />
      </ListItem>
      {(data.scheme_avail_status === 'Pending' ||
        data.scheme_avail_status === 'Transferred back Scheme Application' ||
        data.scheme_avail_status === 'Transferred Scheme Application' ||
        data.scheme_avail_status === 'Forwarded' ||
        data.scheme_avail_status === 'Draft Approved' ||
        data.scheme_avail_status === 'Draft Rejected') && (
        <>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ background: 'white' }}>
                <img className="" style={{}} alt="..." src={Sakala} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Sakala Timeline"
              secondary={`${data.sla_days} days left for Approval`}
            />
          </ListItem>
        </>
      )}
      {(data.scheme_avail_status === 'Transferred back Scheme Application' ||
        data.scheme_avail_status === 'Transferred Scheme Application' ||
        data.scheme_avail_status === 'Forwarded' ||
        data.scheme_avail_status === 'Approved' ||
        data.scheme_avail_status === 'Rejected' ||
        data.scheme_avail_status === 'Draft Approved' ||
        data.scheme_avail_status === 'Draft Rejected') &&
        data.is_transferred === 1 && (
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ background: 'white' }}>
                <CompareArrowsIcon sx={{ fontSize: 35, color: 'grey' }} />
              </Avatar>
            </ListItemAvatar>
            <div className="d-flex flex-column">
              <ListItemText primary="Transfer Details" />
              <div>From circle: {data?.from_circle_name ?? ''}</div>
              <div>To Circle: {data?.transfer_to_circle_name ?? ''}</div>
              <div>
                Transferred on :{' '}
                {moment(data?.transferred_date, 'YYYY-MM-DD hh:mm:ss A').format(
                  'DD/MM/YYYY hh:mm:ss A',
                ) ?? ''}
              </div>
              {data?.transferred_back_date && (
                <div>
                  Transferred Back on:{' '}
                  {moment(
                    data?.transferred_back_date,
                    'YYYY-MM-DD hh:mm:ss A',
                  ).format('DD/MM/YYYY hh:mm:ss A') ?? ''}
                </div>
              )}
            </div>
          </ListItem>
        )}
      {data.scheme_avail_status === 'Pending' ||
      data.scheme_avail_status === 'Transferred back Scheme Application' ||
      data.scheme_avail_status === 'Transferred Scheme Application' ? null : (
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ background: 'white' }}>
              <ReceiptTwoToneIcon sx={{ fontSize: 35, color: 'grey' }} />
            </Avatar>
          </ListItemAvatar>
          <div className="d-flex flex-column">
            <ListItemText primary="Transaction Details" />

            <div>
              Forwarded on:{' '}
              {data?.forwarded_date
                ? moment(data?.forwarded_date, 'YYYY-MM-DD hh:mm:ss A').format(
                    'DD/MM/YYYY hh:mm:ss A',
                  )
                : 'N/A'}
            </div>

            <div>
              {data.scheme_avail_status === 'Approved'
                ? `Approved on: ${
                    moment(
                      data?.approved_rejected_date,
                      'YYYY-MM-DD hh:mm:ss A',
                    ).format('DD/MM/YYYY hh:mm:ss A') ?? ''
                  }`
                : data.scheme_avail_status === 'Rejected'
                  ? `Rejected on: ${
                      moment(
                        data?.approved_rejected_date,
                        'YYYY-MM-DD hh:mm:ss A',
                      ).format('DD/MM/YYYY hh:mm:ss A') ?? ''
                    }`
                  : ''}
            </div>
          </div>
        </ListItem>
      )}
    </List>
  );
}
