import React,{useState,useEffect} from 'react'
import DBTMISReports from './DBTMISReports'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead, TextField, InputAdornment, Select, Backdrop, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getLabourStatusDetails, getSchemesOnStatus, get_schemes, SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { Button, Col, Row } from 'react-bootstrap';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
const TotalDBTRecords = () => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        schemesSelected: "",
        start_date : null,
        end_date : null,  
    })

    const [goBack,setGoBack] = useState(false)
    const [schemesSel, setschemesSel] = React.useState(null)
    const [anchorElschemes, setAnchorElschemes] = React.useState(null);
    const [startDate, setstartDate] = React.useState(null);
    const [endDate, setendDate] = React.useState(null);

        const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
            width: '350px'
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));
useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
        console.log("allValuesSchemeSelect",allValues.schemesSelected)
        let datatoSubmit = users.getDeptUserRole.data[0].board_id
        dispatch(get_schemes(datatoSubmit))
        


    }, [allValues]);

        useEffect(() => {
            console.log("SchemsData:",users);
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.get_schemes !== null &&
            users.get_schemes.error !== undefined &&
            users.get_schemes.error !== null &&
            users.get_schemes.error === true) {
            console.log("ERROR: " + JSON.stringify(users.get_schemes.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.get_schemes !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setschemesSel(users.get_schemes.data)
        }
    }, [users.get_schemes]);


    const handleClick = () => {
        setGoBack(true)
    }
    const onSelectClickHandlerkboc = (event, data) => {
        
        if (data === "schemes") {
            setAnchorElschemes(event.currentTarget);
        }
        
    }
    const handleClosekboc = (event, data) => {
        
         if (data === "schemes") {
            setAnchorElschemes(null);
        }
         
    }

    const onSelectSchemesHandler = (event, data) => {
        setAllValues({
            ...allValues,
            schemesSelected: data
        })
        setAnchorElschemes(null);
    }
  return (
    
    <>
    {goBack ? <DBTMISReports/> : null}
    {goBack === false ?
    <>
    <div className='dbt-info-back-btn'>
    <button style={{background:'transparent',outline:'none',border:'none'}} onClick={handleClick}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    <h1>Total Records</h1>
    
    </div>
    <div className='dbt-dropdown-filter'>
                <Col xs={3} className="kbocwwb-col">
                            <div aria-controls="customized-menu" aria-haspopup="true"
                                className={`kbocwwb-col-div hvr-icon-hang ${allValues.statusActive}`}
                                onClick={(e) => onSelectClickHandlerkboc(e, "schemes")}
                            >
                                <p>Schemes</p>
                                <img alt="..." className="hvr-icon" />
                            </div>
                            <StyledMenu
                                id="customized-menu"
                                className="customized-menu"
                                anchorEl={anchorElschemes}
                                keepMounted
                                open={Boolean(anchorElschemes)}
                                onClose={(e) => handleClosekboc(e, "schemes")}
                            >

                                {
                                    schemesSel !== undefined &&
                                    schemesSel !== null &&
                                    schemesSel.map((row) => (
                                        <MenuItem
                                            onClick={(e) => onSelectSchemesHandler(e, row)}
                                            className={allValues.schemesSelected === row ? "active" : null}
                                        >
                                            <ListItemText primary={row.name} />
                                        </MenuItem>
                                    )
                                    )}

                            </StyledMenu>

                        </Col>
                        <Col xs={2} className="benificiaries-col w-25">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        
                                        className="datepicker"
                                        // margin="normal"
                                        format="DD/MM/YYYY"
                                        value = {allValues.start_date}                       
                                        onChange={(date) => { setstartDate(date)}}                                                          
                                        placeholder="Start Date"
                                        
                                  
                                    />
                                </MuiPickersUtilsProvider>
                        </div>
                    </Col>
                    <Col xs={2} className="benificiaries-col w-25">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                     
                                        className="datepicker"
                                        // margin="normal"
                                        format="DD/MM/YYYY"
                                        value = {allValues.end_date}                       
                                        onChange={(date) => { setendDate(date)}}                                                          
                                        placeholder="End Date"
                                        
                                  
                                    />
                                </MuiPickersUtilsProvider>
                        </div>
                    </Col>
                    
    </div>
    </>
     : null}
    
    </>
  )
}

export default TotalDBTRecords