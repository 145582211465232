export const ThayiMaguForwardValidator = (data) => {
  if (
    (data.verificationDoc && data.verificationDoc.length > 0) ||
    (data.verificationDocument && data.verificationDocument.length > 0)
  ) {
    if (data.remarks) {
      return { success: true, data: data };
    } else {
      return { success: false, msg: 'Please fill the remarks' };
    }
  } else {
    return { success: false, msg: 'Please upload verification document' };
  }
};

export const ThayiMaguDraftValidator = (data) => {
  if (data.from === 'DraftApprove') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  } else if (data.from === 'DraftReject') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  }
};

export const ThayiMaguApproveRejectValidator = (data) => {
  if (data.from === 'Approve') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  } else if (data.from === 'Reject') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  }
};
