import ClearIcon from '@mui/icons-material/Clear';
import { InputAdornment, IconButton } from '@mui/material';
export const ClearButton = ({ filters, name, handler }) => {
  return (
    <>
      {filters[name] && (
        <InputAdornment position="end">
          <IconButton
            edge="end"
            // sx={{ position: 'absolute', right: 80 }}
            onClick={handler}
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )}
    </>
  );
};
