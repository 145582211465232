import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AddCircleRounded, IndeterminateCheckBox } from "@material-ui/icons";

// import folder from 'assets/images/folder (3).svg'
import folder from "assets/images/Folderwhite-01-01.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
// import aadhar from 'assets/images/id-card.svg';
import aadhar from "assets/images/Aadhar black-01.svg";
import checkgreen from "assets/images/Subtraction 1.svg";
import checkgrey from "assets/images/Subtraction 2.svg";
// import close from 'assets/images/close.png'
import close from "assets/images/close-01.svg";
import closeicon from "assets/images/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addLabourFamilyDetails,
  getCatalogDetails,
  getUser,
  getUserRegistrationDetails,
  SERVER,
  updateFamilyData,
  updateUploadFile,
  uploadFile,
} from "store/actions/user.actions";
import {
  dataURLtoFile,
  getTokenCookie,
  Required,
  showToast,
} from "utils/tools";
import axios from "axios";
import { Translate } from "react-redux-i18n";
import validator from "validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import { Translate } from 'react-redux-i18n';

const Family = (props) => {
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [allValues, setAllValues] = React.useState({
    rationCardNo: users.family_details.rationCardNo,
    rationCardType: users.family_details.rationCardType,
    imgfile: users.family_details.imgfile,
    webcamEnabled: users.family_details.webcamEnabled,
    open: users.family_details.open,
    setOpen: users.family_details.setOpen,
    openDatePicker: users.family_details.openDatePicker,

    rationDocumentName: users.family_details.rationDocumentName,

    formcontrolnum: 1,

    membersList: users.family_details.membersList,
    // membersList: [{
    //     "id": 0,
    //     "relation0": users.family_details.membersList[0]['relation0'],
    //     "firstname0": users.family_details.membersList[0]['firstname0'],
    //     "middlename0": users.family_details.membersList[0]['middlename0'],
    //     "lastname0": users.family_details.membersList[0]['lastname0'],
    //     "dob0": users.family_details.membersList[0]['dob0'],
    //     "profession0": users.family_details.membersList[0]['profession0'],
    //     "education0": users.family_details.membersList[0]['education0'],
    //     "nominee0": users.family_details.membersList[0]['nominee0'],
    //     "alreadyRegistered0": users.family_details.membersList[0]['alreadyRegistered0'],
    //     "registrationNo0": users.family_details.membersList[0]['registrationNo0'],
    //     "aadharnum1_0": users.family_details.membersList[0]['aadharnum1_0'],
    //     "aadharnum2_0": users.family_details.membersList[0]['aadharnum2_0'],
    //     "aadharnum3_0": users.family_details.membersList[0]['aadharnum3_0'],
    //     "aadhar0": users.family_details.membersList[0]['aadhar0'],
    //     "formcontrolnum0": users.family_details.membersList[0]['formcontrolnum0'],
    // }],
    noOfFamilyMembers: users.family_details.noOfFamilyMembers,
    rationCardTypes: [],
    relations: [],
    professions: [],
    educations: [],
    documentUploadResponse: "",
    rationCardNoError: false,
  });
  const [dob1, setDob1] = useState([]);
  const dob2 = moment(dob1).subtract(18, "years");
  const dob3 = moment(dob2).toDate();
  // console.log("dob2",dob2);
  // console.log("dob3",dob3);

  useEffect(() => {
    var dateData = {
      labour_user_id: users.user.id,
    };
    var config = {
      method: "post",
      url: SERVER + "/user/get_ad_details",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: dateData,
    };

    axios(config)
      .then(function (response) {
        console.log("DateData:", response.data.data[0].dob);
        setDob1(response.data.data[0].dob);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log("in useEffect [allValues]: ", allValues);
    dispatch(updateFamilyData(allValues));
  }, [allValues]);

  const [defaultnominee, setdefaultnominee] = React.useState(false);
  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState({});

  const [aadhaarValidation, setAadhaarValidation] = React.useState(false);

  const [firstNameError, setFirstNameError] = React.useState("");
  const [middleNameError, setMiddleNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [fileuploadresults, setfileuploadresults] = React.useState({});

  const [AadhaarDecrypted, setAadhaarDecrypted] = React.useState([]);

  const [phone, setPhone] = React.useState("");
  // const [rationCardTypes, setRationCardTypes] = React.useState([])
  // const [relations, setRelations] = React.useState([])
  // const [professions, setProfessions] = React.useState([])
  // const [educations, setEducations] = React.useState([])

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone });
  }, [phone]);

  const [documentUploaded, setdocumentUploaded] = React.useState(false);
  useEffect(() => {
    // console.log("in useEffect []")

    setdocumentUploaded(false);

    dispatch(addLabourFamilyDetails("delete"));

    if (users.generate_otp !== null) {
      setPhone(users.generate_otp.data.phone_number);
      dispatch(getUser(users.generate_otp.data.phone_number));
    } else if (users.generate_otp_registration !== null) {
      setPhone(users.generate_otp_registration.data.phone_number);
      dispatch(getUser(users.generate_otp_registration.data.phone_number));
    } else {
      props.history.push("/");
    }

    console.log("aadhar00", allValues.membersList);
  }, []);

  useEffect(() => {
    // console.log("in useEffect [users.user]")

    if (users.user !== null && users.user.board_id !== null) {
      let dataForRegDetails = {
        key: "user_id",
        value: users.user.id,
        board_id: users.user.board_id,
        procedure_name: "all",
      };
      dispatch(getUserRegistrationDetails(dataForRegDetails));

      let dataForCatalog = {
        board_id: users.user.board_id,
        catalog_name: "Ration card type",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog));

      let dataForCatalog2 = {
        board_id: users.user.board_id,
        catalog_name: "Relation",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog2));

      let dataForCatalog3 = {
        board_id: users.user.board_id,
        catalog_name: "Profession",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog3));

      let dataForCatalog4 = {
        board_id: users.user.board_id,
        catalog_name: "Education",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog4));
    }
  }, [users.user]);

  useEffect(() => {
    // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
    // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
    // console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)

    if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null
    ) {
      var rationCardNo = "";
      var catalog_value_ration_card_type_id = "";
      var ration_card_document_upload_id = "";
      var membersListData = [];
      var noOfFamilyMembers = 0;

      if (
        users.getUserRegistrationDetails !== null &&
        users.getUserRegistrationDetails.family_details !== undefined &&
        users.getUserRegistrationDetails.family_details.length > 0
      ) {
        if (
          users.getUserRegistrationDetails.family_details !== undefined &&
          users.getUserRegistrationDetails.family_details[0] !== undefined
        ) {
          console.error(
            "in useeffect users.getUserRegistrationDetails.family_details: " +
              JSON.stringify(users.getUserRegistrationDetails.family_details)
          );

          var val = users.getUserRegistrationDetails.family_details;

          noOfFamilyMembers = val.length;

          var newarrAadhaar = [];

          for (var i = 0; i < val.length; i++) {
            var str = val[i].aadhar_no;
            var aadharArr = [];
            var chunkSize = 4;

            while (str) {
              if (str.length < chunkSize) {
                aadharArr.push(str);
                break;
              } else {
                aadharArr.push(str.substr(0, chunkSize));
                str = str.substr(chunkSize);
              }
            }

            console.error("aadharArr: " + aadharArr);

            var AadhaarDecrypted = "";
            if (
              val[i].encrypted_aadhar !== undefined &&
              val[i].encrypted_aadhar !== null &&
              val[i].encrypted_aadhar !== ""
            ) {
              var data = JSON.stringify({
                decryptString: val[i].encrypted_aadhar,
              });

              var config = {
                method: "post",
                url: SERVER + "/global/decrypt_data",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  // console.log("AadhaarDecrypted: "+JSON.stringify(response.data));
                  // console.log("AadhaarDecrypted: "+JSON.stringify(response.data.data.decryptedData));

                  if (!response.data.data.decryptedData.includes("X")) {
                    newarrAadhaar.push(response.data.data.decryptedData);
                    setAadhaarDecrypted(newarrAadhaar);
                  }
                  // AadhaarDecrypted = response.data.data.decryptedData
                })
                .catch(function (error) {
                  console.log(error);
                });
            }

            var Data = {
              id: i,
              ["relation" + i]: val[i].catalog_value_parent_child_type_id,
              ["firstname" + i]: val[i].first_name,
              ["middlename" + i]: val[i].middle_name,
              ["lastname" + i]: val[i].last_name,
              ["dob" + i]: val[i].date_of_birth,
              ["profession" + i]: val[i].catalog_value_profession_id,
              ["education" + i]: val[i].catalog_value_qualification_id,
              ["nominee" + i]: val[i].is_nominee === true ? 1 : 0,
              ["alreadyRegistered" + i]: "" + val[i].is_regisrered_user,
              ["registrationNo" + i]: val[i].registration_code,
              ["aadharnum1_" + i]: aadharArr[0],
              ["aadharnum2_" + i]: aadharArr[1],
              ["aadharnum3_" + i]: aadharArr[2],
              ["aadhar" + i]: val[i].aadhar_no,
              relation_id: val[i].relation_id,
              labour_user_id: val[i].labour_user_id,
              parent_child_user_id: val[i].parent_child_user_id,

              // AadhaarDecrypted: AadhaarDecrypted
            };

            membersListData.push(Data);
            // noOfFamilyMembers = noOfFamilyMembers++

            console.error("noOfFamilyMembers: ", noOfFamilyMembers);

            var dateString = dob1;
            var dateObj = new Date(dateString);
            var momentObj = moment(dateObj);
            var momentString = momentObj.format("DD-MM-YYYY");

            console.log("date of relation birth" + momentString);
          }

          if (
            users.getUserRegistrationDetails.ration_card_details !==
              undefined &&
            users.getUserRegistrationDetails.ration_card_details[0] !==
              undefined
          ) {
            var val1 = users.getUserRegistrationDetails.ration_card_details[0];
            rationCardNo = val1.ration_card_no;
            catalog_value_ration_card_type_id =
              val1.catalog_value_ration_card_type_id;
            ration_card_document_upload_id =
              val1.ration_card_document_upload_id;
          }

          if (
            users.getUserRegistrationDetails.ration_card_details[0]
              .ration_card_files_xml !== undefined &&
            users.getUserRegistrationDetails.ration_card_details[0]
              .ration_card_files_xml !== null &&
            users.getUserRegistrationDetails.ration_card_details[0]
              .ration_card_files_xml.length > 0
          ) {
            setfilesxmlPresent(true);
          }

          console.log(
            "found:",
            users.getUserRegistrationDetails.personal_details[0].marital_status
          );
        }
      } else if (
        users.getUserRegistrationDetails.personal_details !== undefined &&
        users.getUserRegistrationDetails.personal_details !== null &&
        users.getUserRegistrationDetails.personal_details[0] !== undefined &&
        users.getUserRegistrationDetails.personal_details[0] !== null &&
        users.getUserRegistrationDetails.personal_details[0]
          .catalog_value_marital_status_id === 30 &&
        users.getUserRegistrationDetails.personal_details[0].marital_status ===
          "Married"
      ) {
        if (
          users.getUserRegistrationDetails.personal_details[0]
            .catalog_value_gender_id === 27 &&
          users.getUserRegistrationDetails.personal_details[0].gender === "Male"
        ) {
          setdefaultnominee(true);
          Data = {
            id: 0,
            relation0: 51,
            firstname0: "",
            middlename0: "",
            lastname0: "",
            dob0: null,
            profession0: "",
            education0: "",
            nominee0: 1,
            alreadyRegistered0: "",
            registrationNo0: "",
            aadharnum1_0: "",
            aadharnum2_0: "",
            aadharnum3_0: "",
            aadhar0: "",
            relation_id0: "",
            labour_user_id0: "",
            parent_child_user_id0: "",
            alreadyRegistered0: "",
            registrationNo0: "",
          };
          membersListData.push(Data);
        } else if (
          users.getUserRegistrationDetails.personal_details[0]
            .catalog_value_gender_id === 28 &&
          users.getUserRegistrationDetails.personal_details[0].gender ===
            "Female"
        ) {
          setdefaultnominee(true);
          Data = {
            id: 0,
            relation0: 52,
            firstname0: "",
            middlename0: "",
            lastname0: "",
            dob0: null,
            profession0: "",
            education0: "",
            nominee0: 1,
            alreadyRegistered0: "",
            registrationNo0: "",
            aadharnum1_0: "",
            aadharnum2_0: "",
            aadharnum3_0: "",
            aadhar0: "",
            relation_id0: "",
            labour_user_id0: "",
            parent_child_user_id0: "",
            alreadyRegistered0: "",
            registrationNo0: "",
          };
          membersListData.push(Data);
        } else {
          var Data = {
            id: 0,
            relation0: "",
            firstname0: "",
            middlename0: "",
            lastname0: "",
            dob0: null,
            profession0: "",
            education0: "",
            nominee0: 0,
            alreadyRegistered0: "",
            registrationNo0: "",
            aadharnum1_0: "",
            aadharnum2_0: "",
            aadharnum3_0: "",
            aadhar0: "",
            relation_id0: "",
            labour_user_id0: "",
            parent_child_user_id0: "",
            alreadyRegistered0: "",
            registrationNo0: "",
          };
          membersListData.push(Data);
        }
      } else {
        var Data = {
          id: 0,
          relation0: "",
          firstname0: "",
          middlename0: "",
          lastname0: "",
          dob0: null,
          profession0: "",
          education0: "",
          nominee0: 0,
          alreadyRegistered0: "",
          registrationNo0: "",
          aadharnum1_0: "",
          aadharnum2_0: "",
          aadharnum3_0: "",
          aadhar0: "",
          relation_id0: "",
          labour_user_id0: "",
          parent_child_user_id0: "",
          // "alreadyRegistered0":"",
          // "registrationNo0":""
        };
        membersListData.push(Data);
      }
      // membersListData.push(Data)

      noOfFamilyMembers = membersListData.length;

      setAllValues({
        ...allValues,
        rationCardNo: rationCardNo,
        rationCardType: catalog_value_ration_card_type_id,
        rationDocumentName: ration_card_document_upload_id,
        membersList: membersListData,
        noOfFamilyMembers: noOfFamilyMembers,
      });
    }
  }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.catalog_details !== null &&
      users.catalog_details.error !== undefined &&
      users.catalog_details.error !== null &&
      users.catalog_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.catalog_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Ration card type"
    ) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setRationCardTypes(users.catalog_details)
      setAllValues({
        ...allValues,
        rationCardTypes: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Relation"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setRelations(users.catalog_details)
      setAllValues({
        ...allValues,
        relations: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Profession"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setProfessions(users.catalog_details)
      setAllValues({
        ...allValues,
        professions: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Education"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setEducations(users.catalog_details)
      setAllValues({
        ...allValues,
        educations: users.catalog_details,
      });
    }
  }, [users.catalog_details]);

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleChangeCheckbox = (event) => {
    for (var i = 1; i < users.family_details.noOfFamilyMembers; i++) {
      console.error("in handleChangeCheckbox i=" + i);
      console.error(
        "nominee:" + allValues.family_details.membersList[i]["nominee" + i]
      );
    }

    // setAllValues({ ...allValues, [event.target.name]: event.target.checked });
  };

  const handleChange = (event) => {
    if (event.target.name.includes("rationCardNo")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlphanumeric(event.target.value) &&
        event.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          rationCardNoError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          rationCardNoError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeMemberList = (e, index) => {
    // console.log("in handleChangeMemberList: index=" + index)
    console.log(
      "in handleChangeMemberList: event=" + JSON.stringify(e.target.value)
    );
    if (e.target.name.includes("firstname")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(e.target.value) &&
        e.target.value.length !== 0 &&
        e.target.value.length < 15
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          ["error_" + e.target.name]: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          ["error_" + e.target.name]: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else if (e.target.name.includes("lastname")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        (validator.isAlpha(e.target.value) && e.target.value.length < 15) ||
        e.target.value.length === 0
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          ["error_" + e.target.name]: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          ["error_" + e.target.name]: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else if (e.target.name.includes("middlename")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        (validator.isAlpha(e.target.value) && e.target.value.length < 15) ||
        e.target.value.length === 0
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          ["error_" + e.target.name]: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          ["error_" + e.target.name]: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else if (e.target.name.includes("registration")) {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlphanumeric(e.target.value) &&
        e.target.value.length === 18
      ) {
        setAllValues({
          ...allValues,
          // middlenameError: false,
          ["error_" + e.target.name]: false,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          // middlenameError: true,
          ["error_" + e.target.name]: true,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [e.target.name]: e.target.value,
                })
              : obj
          ),
        });
      }
    } else {
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.map((obj) =>
          obj.id === index
            ? Object.assign(obj, {
                [e.target.name]: e.target.value,
              })
            : obj
        ),
      });
    }
  };

  const handleChangeCheckboxMemberList = (event, index) => {
    // console.log("in handleChangeCheckboxMemberList: index=" + index)
    // console.log("in handleChangeCheckboxMemberList: checked=" + JSON.stringify(event.target.checked))

    var check = event.target.checked === true ? 1 : 0;

    setAllValues({
      ...allValues,
      membersList: allValues.membersList.map((obj) =>
        obj.id === index
          ? Object.assign(obj, { [event.target.name]: check })
          : Object.assign(obj, { ["nominee" + obj.id]: 0 })
      ),
    });
  };

  const handleDateChangeMemberList = (date, index) => {
    // console.log("in handleDateChangeMemberList: index=" + index)
    // console.log("in handleDateChangeMemberList: event=" + JSON.stringify(date))
    // console.log(new Date(date))
    setAllValues({
      ...allValues,
      membersList: allValues.membersList.map((obj) =>
        obj.id === index
          ? Object.assign(obj, {
              ["dob" + index]: new Date(date),
            })
          : obj
      ),
    });
  };

  const handleAAdharChangeMemberList = (e, index, num) => {
    // console.log('handleAadharChange num:' + num)
    // console.log('handle change called')
    // console.log(e.target.value)
    // console.log(e.target.value.length)

    var formcontnum = 0;
    // ["formcontrolnum" + index]: formcontnum,

    if (num === 1) {
      formcontnum = 1;
    } else if (num === 2) {
      formcontnum = 2;
    } else if (num === 3) {
      formcontnum = 3;
    }

    if (e.target.value.length === 4) {
      // console.log("inside")
      // console.log("formcontnum:" + formcontnum)
      // console.log(`${formcontnum + 1}`)
      const nextSibling = document.querySelector(
        `.row${index} .aadhardiv${formcontnum + 1}>div>input`
      );

      if (formcontnum === 1) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum1_" + index]: e.target.value,
                })
              : obj
          ),
        });
        if (nextSibling !== null) {
          nextSibling.focus();
          setAllValues({
            ...allValues,
            membersList: allValues.membersList.map((obj) =>
              obj.id === index
                ? Object.assign(obj, {
                    ["formcontrolnum" + index]:
                      obj["formcontrolnum" + index] + 1,
                  })
                : obj
            ),
          });
        }
      }
      // else if (allValues.membersList[index]["formcontrolnum" + index] === 2) {
      else if (formcontnum === 2) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum2_" + index]: e.target.value,
                })
              : obj
          ),
        });
        if (nextSibling !== null) {
          nextSibling.focus();
          setAllValues({
            ...allValues,
            membersList: allValues.membersList.map((obj) =>
              obj.id === index
                ? Object.assign(obj, {
                    ["formcontrolnum" + index]:
                      obj["formcontrolnum" + index] + 1,
                  })
                : obj
            ),
          });
        }
      }
      // else if (allValues.membersList[index]["formcontrolnum" + index] === 3) {
      else if (formcontnum === 3) {
        setAllValues({
          ...allValues,
          ["formcontrolnum" + index]: formcontnum + 1,
          membersList: allValues.membersList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  ["aadharnum3_" + index]: e.target.value,
                  ["aadhar" + index]:
                    allValues.membersList[index]["aadharnum1_" + index] +
                    allValues.membersList[index]["aadharnum2_" + index] +
                    e.target.value,
                })
              : obj
          ),
        });

        checkAadhaarDuplicate(index);

        // console.log(allValues)
        // const nextSibling = document.querySelector(
        //     `.datepicker-family>div>input`
        // );
        // nextSibling.focus();
      }
    }
    // else if (e.target.value.length > 4) {
    //     const nextSibling = document.querySelector(
    //         `.row${index} .datepicker-family>div>input`
    //     );
    //     nextSibling.focus();
    // }
    else if (e.target.value.length < 4) {
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.map((obj) =>
          obj.id === index
            ? Object.assign(obj, {
                [e.target.name]: e.target.value,
              })
            : obj
        ),
      });
    }
  };

  const checkAadhaarDuplicate = (index) => {
    // setLoading(true)
    // setaadharDuplicateError(false)

    var data = JSON.stringify({
      key: "aadhar_no",
      value: allValues.membersList[index]["aadhar" + index],
      board_id: 1,
    });

    var config = {
      method: "post",
      url: SERVER + "/user/get-user",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // setLoading(false)
        // console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data,undefined,2));

        // var check = true
        var checkValue = null;

        response.data.data.map((value) => {
          checkValue = value;
        });

        if (checkValue.length > 0) {
          // console.log("checkValue :"+JSON.stringify(checkValue,undefined,2));
          // setAllValues({
          //     ...allValues,
          //     membersList: allValues.membersList.map(
          //         obj => (obj.id === index ? Object.assign(obj,
          //             {
          //                 [e.target.name]: e.target.value
          //                 }
          //             {
          //                 "id": index,
          //                 // "relation0": ,
          //                 "firstname": "Vikram ",
          //                 "middlename0": "",
          //                 "lastname0": "Singh",
          //                 "dob0": null,
          //                 "profession0": "",
          //                 "education0": "",
          //                 "nominee0": "",
          //                 "alreadyRegistered0": "0",
          //                 "registrationNo0": "",
          //                 "aadharnum1_0": "3434",
          //                 "aadharnum2_0": "3453",
          //                 "aadharnum3_0": "5556",
          //                 "aadhar0": "343434535556",
          //                 "formcontrolnum0": 5
          //               }
          //         ) : obj)
          //     ),
          // })
        }
      })
      .catch(function (error) {
        // setLoading(false)
        console.log("checkAadhaarDuplicate errir:" + error);
      });
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );

      setselectedfiles(joined);
    }
    setloadingfiles(false);

    // var selectedblob = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(selectedblob);
    // reader.onloadend = function () {
    //     var base64data = reader.result;
    //     console.log(base64data);

    //     sessionStorage.setItem('rationCard',base64data)
    //     setAllValues({
    //         ...allValues,
    //         rationDocumentName: event.target.files[0].name
    //     })
    // }
  };

  const removefile = (index) => {
    setloadingfiles(true);
    // console.log("removefile index: "+index)
    // selectedfiles.splice(1,index)
    selectedfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  // useEffect(() => {
  //     // console.error("in useEffect imgfile ")
  //     // if (users.imgfile !== null && users.user !== null) {
  //     if (sessionStorage.getItem('rationCard') !== null && users.user !== null) {

  //         let formdata = {
  //             'file': sessionStorage.getItem('rationCard'),
  //             'userId': users.user.id,
  //             'fileType': 'ration_card_image'
  //         }

  //         console.error("formdata: " + JSON.stringify(formdata))
  //         dispatch(uploadFile(formdata))
  //     }
  // }, [users.family_details.imgfile])

  const handleEnter = () => {
    // console.log("in handleEnter")

    const nextSibling = document.querySelector(
      `.formcontrol${
        allValues.residentialData.formcontrolnum + 1
      }>div>div>input`
    );
    // console.log("nextSibling:" + nextSibling.className)

    if (nextSibling !== null) {
      nextSibling.focus();
    }
  };

  const addNewFamilyMember = (memberNumber) => {
    // console.log("in addNewFamilyMember")
    // console.error("in addNewFamilyMember memberNumber=" + memberNumber)

    if (
      memberNumber !== undefined &&
      memberNumber !== null &&
      memberNumber !== ""
    ) {
      // var familymem = memberNumber
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.concat({
          id: memberNumber,
          ["relation" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "relation" + memberNumber
            ],
          ["firstname" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "firstname" + memberNumber
            ],
          ["middlename" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "middlename" + memberNumber
            ],
          ["lastname" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "lastname" + memberNumber
            ],
          ["dob" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "dob" + memberNumber
            ],
          ["profession" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "profession" + memberNumber
            ],
          ["education" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "education" + memberNumber
            ],
          ["nominee" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "nominee" + memberNumber
            ],
          ["alreadyRegistered" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "alreadyRegistered" + memberNumber
            ],
          ["registrationNo" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "registrationNo" + memberNumber
            ],
          ["aadharnum1_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum1_" + memberNumber
            ],
          ["aadharnum2_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum2_" + memberNumber
            ],
          ["aadharnum3_" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadharnum3_" + memberNumber
            ],
          ["aadhar" + memberNumber]:
            users.family_details.membersList[memberNumber][
              "aadhar" + memberNumber
            ],
        }),
        // noOfFamilyMembers: familymem
      });
    } else {
      var familymem = allValues.noOfFamilyMembers + 1;
      setAllValues({
        ...allValues,
        membersList: allValues.membersList.concat({
          id: allValues.noOfFamilyMembers,
          ["relation" + allValues.noOfFamilyMembers]: "",
          ["firstname" + allValues.noOfFamilyMembers]: "",
          ["middlename" + allValues.noOfFamilyMembers]: "",
          ["lastname" + allValues.noOfFamilyMembers]: "",
          ["dob" + allValues.noOfFamilyMembers]: null,
          ["profession" + allValues.noOfFamilyMembers]: "",
          ["education" + allValues.noOfFamilyMembers]: "",
          ["nominee" + allValues.noOfFamilyMembers]: 0,
          ["alreadyRegistered" + allValues.noOfFamilyMembers]: "",
          ["registrationNo" + allValues.noOfFamilyMembers]: "",
          ["aadharnum1_" + allValues.noOfFamilyMembers]: "",
          ["aadharnum2_" + allValues.noOfFamilyMembers]: "",
          ["aadharnum3_" + allValues.noOfFamilyMembers]: "",
          ["aadhar" + allValues.noOfFamilyMembers]: "",
          ["formcontrolnum" + allValues.noOfFamilyMembers]: 1,
        }),
        noOfFamilyMembers: familymem,
      });
    }
  };

  const removeFamilyMember = (item) => {
    // console.log("in removeFamilyMember: item=" + item)
    var array = [...allValues.membersList];
    var index = array.indexOf(item);
    array.splice(index, 1);
    setAllValues({
      ...allValues,
      membersList: array,
      noOfFamilyMembers: allValues.noOfFamilyMembers - 1,
    });
  };

  const familyMembers = allValues.membersList.map((id, i) => {
    return (
      <>
        <img
          alt='...'
          src={close}
          className='closebtn'
          onClick={() => removeFamilyMember(i)}
        />
        <Row className={"familymember-card row" + i}>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Relation :</p> */}
                <Required title={<Translate value='relation' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    value={allValues.membersList[i]["relation" + i]}
                    name={"relation" + i}
                    displayEmpty
                    disabled={i === 0 && defaultnominee ? true : false}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.relations.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >First Name :</p> */}
                <Required title={<Translate value='firstName' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["firstname" + i]}
                    name={"firstname" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={firstNameError}
                    // error={allValues.firstnameError}
                    helperText={firstNameError}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z]/gi,
                        ""
                      );
                      if (e.target.value.length === 0) {
                        setFirstNameError("Please enter first name");
                      } else {
                        setFirstNameError("");
                      }
                    }}
                    inputProps={{ minLength: 0, maxLength: 30 }}
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                <p>
                  <Translate value='middleName' />
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["middlename" + i]}
                    name={"middlename" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    // error={allValues.middlenameError}
                    error={middleNameError}
                    helperText={middleNameError}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z]/gi,
                        ""
                      );
                      if (e.target.value.length === 0) {
                        setMiddleNameError("Please enter first name");
                      } else {
                        setMiddleNameError("");
                      }
                    }}
                    inputProps={{ minLength: 0, maxLength: 30 }}
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Last Name :</p> */}
                <p>
                  <Translate value='lastName' />
                </p>
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <TextField
                    placeholder='Enter Your Name'
                    value={allValues.membersList[i]["lastname" + i]}
                    name={"lastname" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={lastNameError}
                    helperText={lastNameError}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z]/gi,
                        ""
                      );
                      if (e.target.value.length === 0) {
                        setLastNameError("Please enter first name");
                      } else {
                        setLastNameError("");
                      }
                    }}
                    inputProps={{ minLength: 0, maxLength: 30 }}
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >Aadhar :</p> */}
                <Required title={<Translate value='aadhar' />} />
              </Col>
              <Col xs={9}>
                <div className='aadhar-div'>
                  <img alt='...' src={aadhar} />
                  <FormControl>
                    <TextField
                      className='aadhardiv1'
                      placeholder='1234'
                      type='number'
                      value={allValues.membersList[i]["aadharnum1_" + i]}
                      name={"aadharnum1_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 1);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className='aadhardiv2'
                      placeholder='2345'
                      type='number'
                      value={allValues.membersList[i]["aadharnum2_" + i]}
                      name={"aadharnum2_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 2);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className='aadhardiv3'
                      placeholder='3456'
                      type='number'
                      value={allValues.membersList[i]["aadharnum3_" + i]}
                      name={"aadharnum3_" + i}
                      onChange={(e) => {
                        handleAAdharChangeMemberList(e, i, 3);
                      }}
                      inputProps={{ minLength: 0, maxLength: 4 }}
                    />
                  </FormControl>
                  {/* {
                                        allValues.membersList[i]["aadharcheck" + i] ?
                                            (<img alt="..." src={checkgreen} />)
                                            :
                                            (<img alt="..." src={checkgrey} />)
                                    } */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p >DOB :</p> */}
                <Required title={<Translate value='dOB' />} />
              </Col>
              <Col xs={9}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    onClick={() => setPickerStatus(true)}
                    onClose={() => setPickerStatus(false)}
                    open={allValues.openDatePicker}
                    className='datepicker-family'
                    margin='normal'
                    format='DD/MM/YYYY'
                    placeholder='DD/MM/YYYY'
                    value={allValues.membersList[i]["dob" + i]}
                    // value={(allValues.membersList[i][`relation + ${i}`] === 49 || allValues.membersList[i][`relation + ${i}`] === 50) &&  dob3 }
                    name={"dob" + i}
                    onChange={(date) => {
                      handleDateChangeMemberList(date, i);
                    }}
                    // maxDate={new Date()}
                    // maxDate={ (allValues.membersList[i][`relation + ${i}`] === 49 || allValues.membersList[i][`relation + ${i}`] === 50) ? dob3 : new Date()  }
                    maxDate={
                      allValues.membersList[i]["relation" + i] === 49 ||
                      allValues.membersList[i]["relation" + i] === 50
                        ? dob3
                        : new Date()
                    }
                  />
                </MuiPickersUtilsProvider>
                {/*<DatePicker
                                className='datepicker1'
                                dateFormat="dd/MM/yyyy"
                                placeholderText='DD-MM-YYYY'
                                selected={(allValues.membersList[i]["dob" + i] !== null) ? new Date(allValues.membersList[i]["dob" + i]) : null}
                                onChange={(date)=> handleDateChangeMemberList(date,i)}
                                maxDate={ allValues.membersList[i]["relation" + i] === 49 || allValues.membersList[i]["relation" + i] === 50 ? dob3 : new Date()}
                                showYearDropdown
                                
                                scrollableMonthYearDropdown
                                ></DatePicker> */}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p ><Translate value="profession"/> :</p> */}
                <Required title={<Translate value='profession' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    displayEmpty
                    value={allValues.membersList[i]["profession" + i]}
                    name={"profession" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.professions.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} className='member-p'>
                {/* <p ><Translate value="education"/> :</p> */}
                <Required title={<Translate value='education' />} />
              </Col>
              <Col xs={9}>
                <FormControl fullWidth>
                  <Select
                    className='select-marital'
                    displayEmpty
                    value={allValues.membersList[i]["education" + i]}
                    name={"education" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.educations.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className='member-p'>
            <FormControlLabel
              control={
                <Checkbox
                  className='disabled-checkbox'
                  // onChange={handleChangeCheckbox}
                  // color="primary"
                  disabled={defaultnominee}
                  checked={allValues.membersList[i]["nominee" + i] === 1}
                  name={"nominee" + i}
                  onChange={(e) => {
                    handleChangeCheckboxMemberList(e, i);
                  }}
                />
              }
              label={<Translate value='nominee' />}
            />
          </Col>
          <Col xs={12} className='member-p mt-2'>
            <FormControl
              className='interstateRadio'
              variant='outlined'
              fullWidth
              component='fieldset'
            >
              <RadioGroup
                row
                value={allValues.membersList[i]["alreadyRegistered" + i]}
                name={"alreadyRegistered" + i}
                onChange={(e) => {
                  handleChangeMemberList(e, i);
                }}
              >
                {/* <p className="mt-2 mb-2">Already Registered in KBOCWWB</p> */}

                <Required
                  className='mt-2 mb-2'
                  title={<Translate value='Already Registered in KSUWSSB' />}
                />
                <FormControlLabel
                  className='col-2'
                  value='1'
                  control={<Radio />}
                  label={<Translate value='yes' />}
                />
                <FormControlLabel
                  className='col-2'
                  value='0'
                  control={<Radio />}
                  label={<Translate value='no' />}
                />
              </RadioGroup>
            </FormControl>
          </Col>
          <Col xs={12} className='member-p mt-2'>
            {allValues.membersList[i]["alreadyRegistered" + i] === "1" ? (
              <div className='regnum'>
                <p className='mt-2 mb-2'>KSUWSSB Registration Number</p>
                <FormControl style={{ flexGrow: "1" }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    placeholder='Enter Your KSUWSSB Registration Number'
                    value={allValues.membersList[i]["registrationNo" + i]}
                    name={"registrationNo" + i}
                    onChange={(e) => {
                      handleChangeMemberList(e, i);
                    }}
                    error={allValues["error_registrationNo" + i]}
                    // helperText={allValues.lastnameError ? "Please enter in correct format" : ""}
                    helperText={
                      allValues["error_registrationNo" + i] !== undefined &&
                      allValues["error_registrationNo" + i]
                        ? "Please enter in correct format"
                        : ""
                    }
                    // InputProps={{
                    //     endAdornment:
                    //         <InputAdornment position="start">
                    //             {
                    //                 allValues.pancheck ?
                    //                     (<img alt="..." src={checkgreen} />)
                    //                     :
                    //                     (<img alt="..." src={checkgrey} />)
                    //             }
                    //         </InputAdornment>,
                    // }}
                  />
                </FormControl>
              </div>
            ) : null}
          </Col>
        </Row>
      </>
    );
  });

  // const [documentUploaded, setdocumentUploaded] = React.useState(false)
  // useEffect(() => {
  //     if(documentUploaded){
  //             if (users.profile_details.editmode) {
  //                 props.saveEditButtonPressed()
  //             }
  //             else {
  //                 props.nextButtonPressed()
  //             }
  //         }
  // }, [documentUploaded]);

  // useEffect(() => {
  //     // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'rationCard') {

  //         if (users.upload_file.userFileName === "rationCard") {
  //             // setAllValues({
  //             //     ...allValues,
  //             //     documentUploadResponse: users.upload_file.image
  //             // })
  //             setdocumentUploaded(true)

  //             submitFamily(users.upload_file.image)
  //             // if (users.profile_details.editmode) {
  //             //     props.saveEditButtonPressed()
  //             // }
  //             // else {
  //             //     props.nextButtonPressed()
  //             // }
  //         }

  //     }
  //     else if(users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR","Cannot upload the ration image. Please try later.")
  //     }
  // }, [users.upload_file]);

  const nextButtonPressed = () => {
    console.log("next pressed");
    try {
      var i = 0;
      var errors = false;
      console.log("errors: ", errors);

      for (i = 0; i < users.family_details.noOfFamilyMembers; i++) {
        if (
          // users.family_details.rationCardNo === "" ||
          // users.family_details.rationCardType === "" ||
          // users.family_details.imgfile === "" ||
          // sessionStorage.getItem('rationCard') === null ||

          users.family_details.membersList[i]["firstname" + i] === "" ||
          // users.family_details.membersList[i]["lastname" + i] === "" ||
          users.family_details.membersList[i]["relation" + i] === "" ||
          users.family_details.membersList[i]["aadhar" + i] === "" ||
          users.family_details.membersList[i]["dob" + i] === "" ||
          users.family_details.membersList[i]["profession" + i] === "" ||
          users.family_details.membersList[i]["education" + i] === "" ||
          users.family_details.membersList[i]["alreadyRegistered" + i] === "" ||
          users.personal_details.firstnameError === true ||
          users.personal_details.lastnameError === true ||
          allValues.membersList[i]["aadharnum1_0"] === "" ||
          allValues.membersList[i]["aadharnum2_0"] === "" ||
          allValues.membersList[i]["aadharnum3_0"] === ""
          // aadhaarValidation(true)
          // users.family_details.firstnameError === true ||
          // users.family_details.lastnameError === true ||
          // users.family_details.middlenameError === true
        ) {
          // console.log("users.family_details.membersList.firstname=" + users.family_details.membersList[i]["firstname" + i])
          showToast("WARN", "Please Fill All the Required Fields to Continue!");
          errors = true;
          break;
        }
      }

      console.log("errors1: ", errors);

      var nomineeSelected = false;
      for (var j = 0; j < users.family_details.noOfFamilyMembers; j++) {
        if (users.family_details.membersList[j]["nominee" + j] === 1) {
          nomineeSelected = true;
          break;
        }
      }

      console.log("errors2: ", errors);

      if (!nomineeSelected) {
        showToast("WARN", "Please Select a Nominee!");
      }

      if (!errors && nomineeSelected) {
        if (i === users.family_details.noOfFamilyMembers) {
          // submitFamily()

          if (selectedfiles !== null && selectedfiles.length > 0) {
            uploadRationCard();
          } else {
            submitFamily(
              JSON.parse(
                users.getUserRegistrationDetails.ration_card_details[0]
                  .ration_card_files_xml
              )
            );
          }
          // uploadRationCard()
        }
      }
    } catch (err) {
      showToast("ERROR", "Error Saving the Details");
      console.error("in catch-> Error:", err);
    }
  };

  const uploadfile = (data1, filename) => {
    // console.log("inside uploadFile: "+ JSON.stringify(data1))
    // console.log("inside uploadFile: filename= "+ JSON.stringify(filename))
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (fileuploadresults !== null && fileuploadresults.length > 0) {
      var filelist = [];
      fileuploadresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };
        filelist.push(filejson);
      });
      // console.log("filelist: "+ JSON.stringify(filelist))
      // setfilelist(filelist)
      submitFamily(filelist);
    }
  }, [fileuploadresults]);

  const uploadRationCard = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            // console.log("results of promise: ")
            // console.log(results)
            setfileuploadresults(results);
          });
        }
      };
    });

    // if( sessionStorage.getItem('rationCard') !== null && sessionStorage.getItem('rationCard') !== undefined && users.user !== null ){
    //     let formdata = {
    //         'file': sessionStorage.getItem('rationCard'),
    //         'userId': users.user.id,
    //         'fileType': 'rationCard'
    //     }
    //     console.error("formdata rationCard: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata, "rationCard"))
    // }
    // else{
    //     submitFamily()
    // }
  };

  const submitFamily = (documentUploadResponse) => {
    let families = [];

    console.log(
      "users.family_details.noOfFamilyMembers",
      users.family_details.noOfFamilyMembers
    );

    for (var i = 0; i < users.family_details.noOfFamilyMembers; i++) {
      console.log("Going Inside ForLoop");
      var AadhaarDecryptedFinal = "";
      console.log("AadhaarDecrypted: " + AadhaarDecrypted);
      if (AadhaarDecrypted.length > i) {
        if (users.family_details.membersList[i]["aadhar" + i].includes("X")) {
          AadhaarDecryptedFinal = AadhaarDecrypted[i];
          // console.log("in if AadhaarDecrypted: "+JSON.stringify(AadhaarDecrypted))
        } else {
          AadhaarDecryptedFinal =
            users.family_details.membersList[i]["aadhar" + i];
        }
      } else {
        // console.log("in else users.family_details: "+JSON.stringify(users.family_details))
        AadhaarDecryptedFinal =
          users.family_details.membersList[i]["aadhar" + i];
      }

      console.log("AadhaarDecryptedFinal: " + AadhaarDecryptedFinal);

      if (users.family_details.membersList[i].relation_id !== undefined) {
        families.push({
          relation_id: users.family_details.membersList[i].relation_id,
          labour_user_id: users.user.id,
          parent_child_user_id:
            users.family_details.membersList[i].parent_child_user_id,

          isinsert: 0,
          isdeleted: 0,
          isactive: 1,
          relation_type_id: users.family_details.membersList[i]["relation" + i],

          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          // "aadhar_no": users.family_details.membersList[i]["aadhar" + i],
          aadhar_no: AadhaarDecryptedFinal,
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered:
            users.family_details.membersList[i]["alreadyRegistered" + i],
          registeration_no:
            users.family_details.membersList[i]["registrationNo" + i],
        });
      } else {
        families.push({
          relation_id: 0,
          labour_user_id: users.user.id,
          parent_child_user_id: 0,
          isinsert: 1,
          isdeleted: 0,
          isactive: 1,
          relation_type_id: users.family_details.membersList[i]["relation" + i],
          first_name: users.family_details.membersList[i]["firstname" + i],
          last_name: users.family_details.membersList[i]["lastname" + i],
          middle_name: users.family_details.membersList[i]["middlename" + i],
          // "aadhar_no": users.family_details.membersList[i]["aadhar" + i],
          aadhar_no: AadhaarDecryptedFinal,
          dob: users.family_details.membersList[i]["dob" + i],
          profession: users.family_details.membersList[i]["profession" + i],
          education: users.family_details.membersList[i]["education" + i],
          is_nominee: users.family_details.membersList[i]["nominee" + i],
          is_registered:
            users.family_details.membersList[i]["alreadyRegistered" + i],
          registeration_no:
            users.family_details.membersList[i]["registrationNo" + i],
          labour_user_id: users.user.id,
        });
      }
      console.log("Families", families);
    }

    // var documentUploadResponse1 = null
    // if(documentUploadResponse !== null && documentUploadResponse !== undefined){
    //     documentUploadResponse1 = documentUploadResponse.fileId
    // }

    let dataToSubmit = {
      user_id: users.user.id,
      ration_card_no: users.family_details.rationCardNo,
      // "ration_card_image_id": users.upload_file.image.fileId,
      // "ration_card_image_id": documentUploadResponse1,
      ration_card_image_id: "-",
      ration_card_type: users.family_details.rationCardType,
      ration_card_files_xml: documentUploadResponse,
      families: families,
    };

    // console.error("dataToSubmit", JSON.stringify(dataToSubmit,undefined,2))
    // console.error("users.getUserRegistrationDetails: ", users.getUserRegistrationDetails)

    if (
      users.getUserRegistrationDetails.family_details !== undefined &&
      users.getUserRegistrationDetails.family_details.length === 0
    ) {
      dispatch(addLabourFamilyDetails(dataToSubmit));
    } else {
      dispatch(addLabourFamilyDetails(dataToSubmit, "update"));
    }
  };

  const init_family_details = {
    rationCardNo: "",
    rationCardType: "",
    imgfile: "",
    webcamEnabled: false,
    open: false,
    setOpen: false,
    openDatePicker: false,

    rationDocumentName: "",

    formcontrolnum: 1,

    membersList: [
      {
        id: 0,
        relation0: "",
        firstname0: "",
        middlename0: "",
        lastname0: "",
        dob0: null,
        profession0: "",
        education0: "",
        nominee0: "",
        alreadyRegistered0: "",
        registrationNo0: "",
        aadharnum1_0: "",
        aadharnum2_0: "",
        aadharnum3_0: "",
        aadhar0: "",
        formcontrolnum0: 1,
      },
    ],
    noOfFamilyMembers: 1,
    documentUploadResponse: "",
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.success !== undefined &&
      users.addLabourFamilyDetails.success !== null &&
      users.addLabourFamilyDetails.success === true
    ) {
      // showToast("SUCCESS","Family Details Saved Successfully!")
      // props.nextButtonPressed()

      showToast("SUCCESS", "Family Details Saved Successfully!");
      // props.nextButtonPressed()
      dispatch(updateFamilyData(init_family_details));
      dispatch(addLabourFamilyDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.success !== undefined &&
      users.addLabourFamilyDetails.success !== null &&
      users.addLabourFamilyDetails.success === "false"
    ) {
      showToast("ERROR", "Error Saving Family Details!");
    } else if (
      users.addLabourFamilyDetails !== null &&
      users.addLabourFamilyDetails.error !== undefined &&
      users.addLabourFamilyDetails.error === true
    ) {
      showToast("ERROR", "Error Saving Family Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabourFamilyDetails]);

  return (
    <>
      <Row className='m-0'>
        <Row className='family-title-row'>
          <Col xs={12} className='profile-title'>
            <h1>
              <Translate value='rationCardDetails' />
            </h1>
          </Col>
        </Row>

        <Row className='form-row'>
          <Col xs={12} className='family-col-1'>
            <Row>
              <Col xs={12} md={6}>
                <p className='mt-3 mb-2'>
                  <Translate value='rationCardNumber' />
                </p>

                {/* <Required className="mt-3 mb-2" title="Ration Card Number"/> */}
                <FormControl
                  fullWidth
                  className='formcontrol1'
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    }
                  }}
                >
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Ration Card Number'
                    name='rationCardNo'
                    value={allValues.rationCardNo}
                    onChange={handleChange}
                    error={allValues.rationCardNoError}
                    // error={allValues.rationCardTypeError}
                    helperText={
                      allValues.rationCardNoError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>

              <Col xs={12} md={6}>
                <p className='mt-3 mb-2'>
                  <Translate value='rationCardType' />
                </p>

                {/* <Required className="mt-3 mb-2" title="Ration Card Type"/> */}
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={allValues.rationCardType}
                    name='rationCardType'
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {allValues.rationCardTypes.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>

          <Col xs={12} className='family-col-1'>
            <p className='mt-5 mb-2'>
              <Translate value='uploadYourRationCardDocument' />
            </p>

            <div
              className='browsebutton-outerdiv'
              style={{ marginLeft: "5px" }}
            >
              <div className='browsebutton2-div filesNames'>
                {loadingfiles &&
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails !== null &&
                users.getUserRegistrationDetails.length > 0 &&
                users.getUserRegistrationDetails.ration_card_details[0] !==
                  undefined &&
                users.getUserRegistrationDetails.ration_card_details[0] !==
                  null &&
                users.getUserRegistrationDetails.ration_card_details[0]
                  .ration_card_files_xml !== undefined &&
                users.getUserRegistrationDetails.ration_card_details[0]
                  .ration_card_files_xml !== null &&
                filesxmlPresent
                  ? Array.from(
                      JSON.parse(
                        users.getUserRegistrationDetails.ration_card_details[0]
                          .ration_card_files_xml
                      )
                    ).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='passbookDocumentName'
                          value={i.file_name}
                          disabled
                        />
                      </FormControl>
                    ))
                  : null}

                {loadingfiles ? (
                  <></>
                ) : (
                  selectedfiles !== null &&
                  selectedfiles.length > 0 &&
                  Array.from(selectedfiles).map((i, index) => (
                    <FormControl>
                      <TextField
                        variant='outlined'
                        name='passbookDocumentName'
                        value={i.name}
                        onChange={handleChange}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <img
                                alt='...'
                                src={closeicon}
                                className='removefilebtn'
                                onClick={() => removefile(index)}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  ))
                )}
              </div>
              <div className='browsebutton2-div'>
                <label htmlFor='upload-photo' className='browse-button btn2'>
                  <img alt='...' src={folder} /> <Translate value='browse' />
                </label>
                <input
                  type='file'
                  name='photo'
                  id='upload-photo'
                  onChange={handleFileChange}
                  multiple
                />
              </div>
            </div>

            {/* <div className="browsebutton2-div">

                        <FormControl>
                            <TextField
                                variant="outlined"
                                name="rationDocumentName"
                                value={allValues.rationDocumentName}
                                onChange={handleChange}
                                disabled
                            />
                        </FormControl>
                        <label
                                htmlFor="upload-photo"
                                className="browse-button" style={{fontSize: "24px"}}>
                                <img alt="..." src={folder} /> <Translate value="browse"/>
                        </label>
                        <input type="file" name="photo" id="upload-photo" onChange={handleFileChange} />
                    </div> */}

            <p className='mt-4 instructions-ration'>
              <span>
                <Translate value='instructions' />*
              </span>
              <br />
              {/* Format supporting document should be in JPEG, PNG, or PDF
                            (Size contains 500 KB to 2 MB) */}
              <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
              <br />
              <Translate value='sizecontains500KBto2MB' />
            </p>
          </Col>

          <Col xs={12} className='family-col-3 form-inner-card mt-4'>
            <p>
              <Translate value='listofFamilyMembers' />
            </p>
          </Col>

          <Col xs={12} className='family-col-3'>
            {familyMembers}
          </Col>

          <Col
            xs={12}
            className='addMemberCol mt-4 family-col-10'
            onClick={() => addNewFamilyMember("")}
          >
            <AddCircleRounded />
            <p>
              <Translate value='addMoreFamilyMembers' />
            </p>
          </Col>

          <Col xs={12} className='note2 text-center mt-4 family-col-11'>
            {/* <p><span>Note :</span> After the completion of the mandatory fields in Family Details, then only the system will allow the further process.</p> */}
            <p>
              <span>
                <Translate value='note' />
                <Translate value='noteafterfurtherprocess' />
              </span>
            </p>
          </Col>
        </Row>

        {/* <Row className="button-inside-form-row mb-5">
                <Col xs={12} className="next-back-button-row mt-4 family-col-8">
                    <Link to="#" onClick={props.backButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="danger" className="back-button" >
                            Back
                                </Button>
                    </Link>
                    <Link to="#" onClick={nextButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="outline-primary" className="next-button">
                            Next
                            </Button>
                    </Link>
                </Col>

            </Row> */}

        <Row className='button-inside-form-row mb-5'>
          <Col xs={12} className='next-back-button-row mt-4 '>
            {!users.profile_details.editmode ? (
              <>
                <Link
                  to='#'
                  onClick={props.backButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='danger' className='back-button'>
                    <Translate value='back' />
                  </Button>
                </Link>
                <Link
                  to='#'
                  onClick={nextButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='outline-primary' className='next-button'>
                    <Translate value='saveandContinue' />
                  </Button>
                </Link>
              </>
            ) : (
              <Link
                to='#'
                onClick={nextButtonPressed}
                style={{ flexGrow: "0.5" }}
              >
                <Button variant='outline-primary' className='next-button'>
                  <Translate value='saveyourDetails' />
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Family;
