/* eslint-disable no-throw-literal */

import uploadFileAPI from 'utils/fileupload';

export const ContinuationPensionFileUpload = async (
  labourCardDoc,
  labourPhotoDoc,

  pensionOrderDoc,
) => {
  // Check if bankData length is 2 and existingCard and bankPassbook have length > 0
  if (
    labourCardDoc &&
    labourCardDoc.length > 0 &&
    labourPhotoDoc &&
    labourPhotoDoc.length > 0 &&
    pensionOrderDoc &&
    pensionOrderDoc.length > 0
  ) {
    const promises1 = [];
    labourCardDoc.forEach((doc) => {
      promises1.push(uploadFileAPI(doc));
    });
    const promises2 = [];
    labourPhotoDoc.forEach((doc) => {
      promises2.push(uploadFileAPI(doc));
    });

    const promises3 = [];
    pensionOrderDoc.forEach((doc) => {
      promises3.push(uploadFileAPI(doc));
    });
    const allPromises = [...promises1, ...promises2, ...promises3];

    return Promise.all(allPromises)
      .then((responses) => {
        const responses1 = responses.slice(0, promises1.length);
        const responses2 = responses.slice(
          promises1.length,
          promises1.length + promises2.length,
        );
        const responses3 = responses.slice(promises1.length + promises2.length);

        const labourCardDocResponses = responses1.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
          mime_type: doc.image.mimeType,
        }));
        const labourPhotoDocResponses = responses2.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
          mime_type: doc.image.mimeType,
        }));

        const pensionOrderDocResponses = responses3.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
          mime_type: doc.image.mimeType,
        }));
        // Continue processing or handle the responses as needed
        return {
          success: true,
          labourCardDoc: labourCardDocResponses,
          labourPhotoDoc: labourPhotoDocResponses,

          pensionOrderDoc: pensionOrderDocResponses,
        };
      })
      .catch((error) => {
        throw {
          success: false,
          msg: 'Error in uploading file! Please try again',
        };
      });
  } else {
    // Return null if conditions are not met
    return null;
  }
};
