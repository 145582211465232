import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

export const ECardServiceAPI = async (labourUserID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/user/ecard/details?boardId=1&labourUserId=${labourUserID}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
