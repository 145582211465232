import {
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

const ThayiMaguAssistance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  loading,
  kannadaSelected,
  childStatus,
  thayiMaguFields,
  isRenewal,
}) => {
  const renderFields = () => {
    let fields =
      dynamicFields &&
      dynamicFields.filter((field) => {
        if (formData && formData['Place of Birth'] === 1242) {
          if (formData && formData['Hospital type'] === 1749) {
            return field.field_name !== 'Name of the hospital';
          } else if (formData && formData['Hospital type'] === 1750) {
            return (
              field.field_name !== 'Name of the Hospital where  child was born'
            );
          }
          return (
            field.field_name !== 'Name of the Hospital where  child was born' &&
            field.field_name !== 'Name of the hospital'
          );
        }

        return (
          field.field_name !== 'Hospital type' &&
          field.field_name !== 'Name of the Hospital where  child was born' &&
          field.field_name !== 'Name of the hospital'
        );
      });

    return (
      fields &&
      fields.length > 0 &&
      fields.map((field) => (
        <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
          <Required
            className="mb-2 mt-4"
            title={
              kannadaSelected ? field.field_name_kannada : field.field_name
            }
          />
          {field.field_type === 'Text' && (
            <>
              <FormControl fullWidth className="formcontrol1">
                <TextField
                  type={'text'}
                  name={field.field_name}
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  value={formData && formData[field.field_name]}
                  variant="outlined"
                  disabled={field.field_name === 'Current age of child'}
                />
              </FormControl>
            </>
          )}
          {field.field_type === 'Date' && (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                minDate={moment('2023-10-17', 'YYYY-MM-DD').subtract(
                  6,
                  'months',
                )}
                maxDate={new Date()}
                className="datepicker"
                margin="normal"
                format="DD/MM/YYYY"
                name={field.field_name}
                // id={id}
                value={
                  formData && formData[field.field_name]
                    ? formData[field.field_name]
                    : null
                }
                onChange={(ev) => handleChange(ev, field, field.field_name)}
                // onChange={setDate}
                placeholder="DD/MM/YYYY"
                // error={allValues.globalError}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )}

          {(field.field_type === 'Dropdown' ||
            field.field_type === 'Radio Button') && (
            <FormControl variant="outlined" fullWidth className="formcontrol5">
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                name={field.field_name}
                onChange={(ev) => handleChange(ev, field, field.field_name)}
                value={(formData && formData[field.field_name]) || '0'}
              >
                <MenuItem value="0">
                  <ListItemText primary={`Select`} />
                </MenuItem>
                {field.field_data.map((field) =>
                  field.field_name === 'Claimed for child' ? (
                    childStatus.childStatus1 === 1 ? (
                      field.field_value !== 'Child 1' && (
                        <MenuItem value={field.field_value_id}>
                          <ListItemText primary={field.field_value} />
                        </MenuItem>
                      )
                    ) : childStatus.childStatus2 === 1 ? (
                      field.field_value !== 'Child 2' && (
                        <MenuItem value={field.field_value_id}>
                          <ListItemText primary={field.field_value} />
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem value={field.field_value_id}>
                        <ListItemText primary={field.field_value} />
                      </MenuItem>
                    )
                  ) : (
                    <MenuItem value={field.field_value_id}>
                      <ListItemText primary={field.field_value} />
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        </Col>
      ))
    );
  };

  return (
    <>
      {isRenewal === 'false' && (
        <>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <CircularProgress color="inherit" />
              <p className="align-self-center py-3">
                Please wait fetching fields....
              </p>
            </div>
          ) : (
            renderFields()
          )}
        </>
      )}

      <>
        {isRenewal === 'true' && (
          <>
            {thayiMaguFields &&
              thayiMaguFields[0].length > 0 &&
              thayiMaguFields[0].map((_field) => (
                <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
                  <Required
                    className="mb-2 mt-4"
                    title={
                      kannadaSelected ? _field.field_name_kannada : _field.field
                    }
                  />

                  <>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        value={_field.field_value}
                        variant="outlined"
                        disabled
                      />
                    </FormControl>
                  </>
                </Col>
              ))}
          </>
        )}
      </>
    </>
  );
};

export default ThayiMaguAssistance;
