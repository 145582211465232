import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

export const districtAPI = (setFetchDistrict) => {
  axios
    .get(`${SERVER}/global/get_district_details?state_id=${12}`)
    .then((res) => {
      const { data } = res.data;
      setFetchDistrict(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const talukAPI = (districtID, setFetchTaluk) => {
  if (districtID) {
    axios
      .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchTaluk(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const cityAPI = (talukID, setFetchCity) => {
  if (talukID) {
    axios
      .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchCity(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const villageAPI = (cityID, setFetchVillage) => {
  if (cityID) {
    axios
      .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchVillage(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
