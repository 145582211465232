import React from 'react';
import Popover from '@mui/material/Popover';

import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
const PopOver = ({ id, open, anchorEl, onClose, handleNewTab, fileID }) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <img
          src={`${SERVER}/upload/v2/file/${fileID}?token=${getTokenCookie()}`}
          width={200}
          height={200}
          onClick={() =>
            handleNewTab(
              `${SERVER}/upload/v2/file/${fileID}?token=${getTokenCookie()}`,
            )
          }
          alt="..."
        />
      </Popover>
    </>
  );
};

export default PopOver;
