import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

export const EmployerDetailsAPI = (setFetchEmployerDetails) => {
  const payLoad = {
    board_id: 1,
    catalog_name: 'Employer details',
  };
  axios
    .post(`${SERVER}/global/get_catalog_details`, payLoad)
    .then(({ data }) => {
      setFetchEmployerDetails(data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const NatureOfWorkAPI = (setFetchNatureOfWork) => {
  const payLoad = {
    board_id: 1,
    catalog_name: 'Nature of Work',
  };
  axios
    .post(`${SERVER}/global/get_catalog_details`, payLoad)
    .then(({ data }) => {
      setFetchNatureOfWork(data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TypeOfIssuerAPI = (setFetchTypeOfIssuer) => {
  const payLoad = {
    board_id: 1,
    catalog_name: 'Type of Issuer',
  };
  axios
    .post(`${SERVER}/global/get_catalog_details`, payLoad)
    .then(({ data }) => {
      setFetchTypeOfIssuer(data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
