import {
  FormControl,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton,
  Typography,
  DialogContentText,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useEffect, useParams, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import graphic from 'assets/images/undraw_under_construction_46pa.svg';
import doc1 from 'assets/documents/Employement certificate by the Employeer.pdf';
import doc2 from 'assets/documents/Employement certificate by the grama panchayath.pdf';
import doc3 from 'assets/documents/Employement certificate by the labour inspector.pdf';
import doc4 from 'assets/documents/Employement certificate by the Registration trade union.pdf';
import selfDec from 'assets/documents/Self-declaration-form.pdf';
import userManualEng from 'assets/documents/user_manual_eng.pdf';
import userManualKan from 'assets/documents/user_manual_kan.pdf';
import validator from 'validator';
import mobile from 'assets/images/Phone 1-01.svg';
import otp from 'assets/images/Phone otp-01.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  generateOtp,
  getFile,
  getUser,
  getUserRegistrationDetails,
  loginUser,
  loginAdmin,
  SERVER,
  updateUser,
} from 'store/actions/user.actions';
import {
  getTokenCookie,
  getTokenCookieAdmin,
  removeTokenCookie,
  removeTokenCookieAdmin,
  saveTokenCookie,
  showToast,
} from 'utils/tools';
import closeIcon from 'assets/images/close-01.svg';
import axios from 'axios';
import Accident from 'components/mainScreens/schemesDetails/accidentScheme';
import { useLocation } from 'react-router-dom';
import { set } from 'date-fns';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
const Login = (props) => {
  // 6 month removal
  const [isActiveLogin, setIsActiveLogin] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [isActiveExist, setIsActiveExist] = useState(false);
  const [unorganziedRegisterBtn, setUnorganizedRegisterBtn] = useState(false);
  const [color, setColor] = useState(false);
  const [allValues, setAllValues] = React.useState({
    open: false,
    phone: '',
    otp: '',
    users: props.users,
    generate_otp: props.generate_otp,
    login_user: props.login_user,
    otp_sent: false,
    otp_sending: false,
    logging_in: false,
    phoneError: false,
    phoneErrorMessage: '',
    otpError: false,
    otpErrorMessage: '',
    openSnackbar: false,
    snackbarText: '',
    snackbarType: '',
    vertical: 'top',
    horizontal: 'right',
    // seconds: 60,
    resendReset: false,
  });

  // const testvalue = useParams();

  // console.log('incoming_props1: ',testvalue)
  const location = useLocation();

  // useEffect(() => {
  //     // result: '?query=abc'
  //    console.log("getschemeDataboards",location.state.state); // result: 'some_value'
  // }, [location]);

  // console.log("incoming_props: ", props.match.params.fromPage)

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    removeTokenCookie();
    removeTokenCookieAdmin();
    dispatch(updateUser(''));
    // dispatch(deleteUser())
    dispatch(getUser('delete'));
    dispatch(loginUser('delete'));

    dispatch(generateOtp('delete'));
    dispatch(getUserRegistrationDetails('delete'));
    dispatch(getFile('delete'));

    localStorage.clear();
    sessionStorage.clear();
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeHandler = (e) => {
    // console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

    if (e.target.name === 'otp') {
      setAllValues({ ...allValues, otpError: false, otpErrorMessage: '' });
    }
    if (e.target.name === 'phone') {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        setAllValues({
          ...allValues,
          phoneError: false,
          phoneErrorMessage: '',
          otp: '',
          otpError: false,
          otpErrorMessage: '',
          otp_sent: false,
        });
      else
        setAllValues({
          ...allValues,
          phoneError: true,
          phoneErrorMessage: 'Enter Correct Mobile Number!',
          otp: '',
          otpError: false,
          otpErrorMessage: '',
          otp_sent: false,
        });
    }

    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const handleDownloadListOpen = () => {
    setAllValues((prevState) => ({
      ...prevState,
      open: !prevState.open,
    }));
    // setAllValues({...allValues, [e.target.name]: e.target.value})
  };

  const handleLoginClick = () => {
    setIsActiveLogin(true);
    setIsActiveExist(false);
  };

  const handleExisitingClick = () => {
    props.history.push('/register');
    setIsActiveExist(true);
    setIsActiveLogin(false);
  };

  const handleClose = () => {
    setAllValues((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const getOtp = (event) => {
    event.preventDefault();
    // console.log("in getOtp-> phone:" + allValues.phone)
    // console.log("in getOtp-> users:" + JSON.stringify(users))

    if (!allValues.phoneError && allValues.phone.length === 10) {
      setAllValues((prevState) => ({
        ...prevState,
        otp_sending: !prevState.otp_sending,
        generate_otp: '',
      }));

      let dataToLogin = { phone_number: allValues.phone };
      // console.log("dataToLogin: " + JSON.stringify(dataToLogin))

      dispatch(generateOtp(dataToLogin));
    } else {
      setAllValues({
        ...allValues,
        phoneError: true,
        phoneErrorMessage: 'Enter Correct Mobile Number!',
      });
    }
  };

  const login_func = (event) => {
    event.preventDefault();

    // console.log("in login_func: hash: " + JSON.stringify(users.generate_otp.data.hash))
    // const hash = users.generate_otp.data.hash

    if (
      users.generate_otp !== null &&
      !allValues.phoneError &&
      allValues.phone.length === 10 &&
      allValues.otp !== ''
    ) {
      setAllValues({
        ...allValues,
        logging_in: !allValues.logging_in,
      });

      let dataToLogin = {
        phone_number: allValues.phone,
        otp: allValues.otp,
        hash: users.generate_otp.data.hash,
      };
      // console.log("dataToLogin: " + JSON.stringify(dataToLogin))

      dispatch(loginUser(dataToLogin));
    } else if (allValues.phoneError || allValues.phone.length !== 10) {
      setAllValues({
        ...allValues,
        phoneError: true,
        phoneErrorMessage: 'Enter Correct Mobile Number!',
      });
    } else if (otp === '') {
      setAllValues({
        ...allValues,
        otpError: true,
        otpErrorMessage: 'Please enter correct OTP!',
      });
    } else if (
      users.generate_otp === null ||
      users.generate_otp.data === undefined ||
      users.generate_otp.data.hash === undefined
    ) {
      setAllValues({
        ...allValues,
        otpError: true,
        otpErrorMessage: 'Please enter correct OTP!',
      });
    } else {
      setAllValues({
        ...allValues,
        phoneError: true,
        phoneErrorMessage: 'Error Sending OTP',
        otpError: true,
        otpErrorMessage: 'Please enter correct OTP!',
      });
    }
  };

  // useEffect(() => {
  //     // result: '?query=abc'
  //     try{
  //         if(location.path&&location.state){
  //             props.history.push("/scheme-form/",location.state,"_id=10")
  //         }
  //     }
  //     catch{

  //     }
  //    console.log("getschemeDataboards",location.state); // result: 'some_value'
  // }, [location]);

  useEffect(() => {
    // console.log("users.generate_otp.message:"+users.generate_otp.message)
    try {
      if (
        users.generate_otp !== null &&
        users.generate_otp.message !== null &&
        users.generate_otp.message === 'Success'
      ) {
        // console.log("OTP generated Successfully");
        setAllValues({
          ...allValues,
          otp_sending: false,
          otp_sent: true,
        });
        showToast('SUCCESS', 'OTP Sent Successfully!');
        users.generate_otp.message = null;
      } else if (users.generate_otp !== null && users.generate_otp.error) {
        // console.log("OTP generate Error");
        setAllValues({
          ...allValues,
          otp_sending: false,
          otp_sent: false,
          phoneError: true,
          phoneErrorMessage: users.generate_otp.message,
        });
        showToast('ERROR', users.generate_otp.errors.msg);
        users.generate_otp = null;
      } else if (
        users.generate_otp !== null &&
        users.generate_otp.success === false &&
        users.generate_otp.error !== undefined &&
        users.generate_otp.error !== ''
      ) {
        // console.log("OTP generate Error");
        setAllValues({
          ...allValues,
          otp_sending: false,
          otp_sent: false,
        });
        showToast('ERROR', users.generate_otp.errors.msg);
        showToast('ERROR', 'Please try after 30 minutes');
        users.generate_otp = null;
      } else if (users.generate_otp !== null) {
        // console.log("In else: error");

        // setAllValues({
        //     ...allValues,
        //     otp_sending: false,
        //     otp_sent: false,
        //     phoneError: true,
        //     phoneErrorMessage: users.generate_otp,
        // })
        // showToast("ERROR", "Error Sending OTP")
        users.generate_otp = null;
      }
    } catch (e) {
      console.error(e);
    }
  }, [users.generate_otp]);

  useEffect(() => {
    try {
      if (
        users.user !== undefined &&
        users.user !== null &&
        users.user !== ''
      ) {
        const validityDate = moment(users.user?.validity_to_date);
        const oneYearBeforeToday = moment().clone().subtract(1, 'year');
        const daysDiff = oneYearBeforeToday.diff(validityDate, 'days');
        const dayCanReg = 89 - daysDiff;
        const allowInactiveFlag = dayCanReg <= 0 ? true : false;
        if (
          users.user.board_id === 1 &&
          (users.user.ISACTIVEUSER === 2 ||
            users.user.ISACTIVEUSER === 1 ||
            users.user.IS_Freezed === true ||
            allowInactiveFlag)
        ) {
          props.history.push('/dashboard-user');
        } else if (
          users.user.board_id === 1 &&
          (users.user.ISACTIVEUSER === 0 || users.user.IS_Freezed === true)
        ) {
          showToast('ERROR', 'Contact your Labour Inspector');
        } else if (users.user.board_id === 4) {
          props.history.push('/dashboard-user-unorganized');
        }
      } else if (users.user === null) {
      }
    } catch (e) {
      console.error(e);
    }
  }, [users.user]);

  useEffect(() => {
    // console.log("users.login_user.message:"+users.login_user.message)

    try {
      if (
        users.login_user !== null &&
        users.login_user.message !== null &&
        users.login_user.message === 'Valid OTP'
      ) {
        // console.log("in if : Valid OTP");
        setAllValues({
          ...allValues,
          logging_in: false,
        });
        saveTokenCookie(users.login_user.jwt);
        // showToast("SUCCESS", "Login Successfull!")
        users.login_user.message = null;

        // console.log("users.user.board_id:"+users.user.board_id)
        // if(users.user !== null && users.user.board_id === 1){
        //     props.history.push("/dashboard-user");
        // }
        // else if(users.user !== null && users.user.board_id === 4){
        //     props.history.push("/dashboard-user-unorganized");
        // }
      } else if (
        users.login_user !== null &&
        users.login_user.message !== null &&
        users.login_user.message === 'Invalid OTP'
      ) {
        // console.log("In elseif: In Valid OTP");
        setAllValues({
          ...allValues,
          logging_in: false,
          otpError: true,
        });
        showToast('WARN', 'Invalid OTP!');

        users.login_user.message = null;
      } else if (
        users.login_user !== null &&
        users.login_user.message === 'Access Denied'
      ) {
        setAllValues({
          ...allValues,
          logging_in: false,
        });
        showToast('ERROR', 'Something went wrong.');

        users.login_user = null;
      } else if (
        users.login_user !== null &&
        users.login_user.message !== null
      ) {
        setAllValues({
          ...allValues,
          logging_in: false,
        });
        showToast('ERROR', 'Error Logging-in!');

        users.login_user = null;
      }
    } catch (e) {
      console.error(e);
    }
  }, [users.login_user]);

  const [seconds, setSeconds] = React.useState(60);
  const [resendReset, setResendReset] = React.useState(false);
  const ResendOTP = () => {
    if (seconds > 0) {
      setTimeout(
        () => setSeconds(seconds - 1),
        // setAllValues({
        //     ...allValues,
        //     seconds: allValues.seconds - 1
        // })
        1000,
      );
      return (
        // <Link to="#" disabled>
        <p className="generate-otp2">Resend OTP after {seconds} seconds</p>
        // </Link>
      );
    } else {
      setResendReset(true);
      setSeconds(60);
      setAllValues({
        ...allValues,
        otp_sent: false,
        // seconds: 60,
        // resendReset: true
      });
      return null;
    }
  };

  return (
    <Row className="login-div">
      <Col xs={12} lg={6} xl={6} xxl={6}>
        <Row>
          <Col sm={12}>
            <div className="logo-div d-flex justify-content-start text-center">
              <img
                id="logo"
                src={logo}
                alt="..."
                className="logo-img-admin1 d-flex justify-content-start"
              />
              <div className="logo-head d-flex justify-content-start text-center">
                <h1
                  className="logo-text d-flex justify-content-start text-center"
                  style={{ color: '#CA0027', fontSize: '25px' }}
                >
                  ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ
                </h1>
                <h1
                  className="logo-text d-flex justify-content-start text-center"
                  style={{ color: '#CA0027', fontSize: '25px' }}
                >
                  Labour Department, Government of Karnataka
                  {/* Karnataka Building & Other Construction <br />Workers Welfare Board (KBOCWWB) */}
                </h1>
              </div>
            </div>
          </Col>
          <Col sm={12} className="login-img-col">
            <img className="login-img" alt="..." src={graphic} />
          </Col>
          <Col sm={12} className="copyright">
            {/* <p>Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB), Government of Karnataka</p> */}
          </Col>
        </Row>
      </Col>

      {/* <a style={{color:'black',textDecoration:'underline'}} href={userManual} download="User Manual" target='_blank' rel="noreferrer">
            Click here to download the user manual
            </a> */}

      <Col xs={12} lg={6} className="login-form container">
        <div className="d-flex justify-content-center align-items-center">
          <Typography color="error" className="text-center w-75 p-2 mt-2">
            Please go through once with the user manual before using the
            Application.
            <button
              style={{
                color: 'blue',
                outline: 'none',
                background: 'none',
                border: 'none',
              }}
              onClick={handleClickOpenDialog}
            >
              Click here to download the user manual
            </button>
          </Typography>
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
              User Manual for KBOCWWB Application
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <a
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  href={userManualKan}
                  download="User Manual Kannada"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button color="info" startIcon={<DownloadIcon />}>
                    Download in Kannada
                  </Button>
                </a>
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                <a
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  href={userManualEng}
                  download="User Manual English"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button color="info" startIcon={<DownloadIcon />}>
                    Download in English
                  </Button>
                </a>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="login-form-container">
          {/* <h2 className="login-form-head" style={{color:'#535353'}}>ಲಾಗಿನ್ / LOGIN </h2> */}

          {/* 6 month removal */}

          <div className="div-bar text-center row">
            <Button
              variant="contained"
              className="col w-50 btn-switch text-capitalize text-center"
              size="lg"
              onClick={handleLoginClick}
              style={{
                backgroundColor: isActiveLogin ? '#CA0027' : '#dee2e6',
                color: isActiveExist ? 'black' : '',
              }}
            >
              Login
              {/* Login with Mobile Number */}
            </Button>
            <Button
              className="col w-50 btn-switch text-capitalize text-center"
              variant="contained"
              size="lg"
              onClick={handleExisitingClick}
              style={{
                backgroundColor: isActiveExist ? '#CA0027' : '#dee2e6',
                color: isActiveExist ? '' : 'black',
              }}
            >
              Register
              {/* Already Registered Construction Worker */}
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <p
              className="w-100 text-center note-new-reg"
              style={{ color: '#CA0027' }}
            >
              Note: "If you are already existing Seva Sindhu Construction Worker
              / New Construction Worker then, use 'Register' tab above"
            </p>
          </div>

          <p className="phno-label" style={{ fontSize: '18px' }}>
            ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ / Enter your mobile number
          </p>

          <form>
            <FormControl fullWidth className="phno-field">
              <TextField
                className="size21"
                name="phone"
                value={allValues.phone}
                type="phone"
                onChange={changeHandler}
                error={allValues.phoneError}
                helperText={
                  allValues.phoneError
                    ? 'Please enter correct phone number'
                    : ''
                }
                inputProps={{ minLength: 0, maxLength: 10 }}
                // error={allValues.phoneErrorMessage.length > 0}
                // helperText={allValues.phoneErrorMessage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={mobile} alt="..." className="phone-icon" />
                      <p className="countrycode">+91</p>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              ></TextField>
            </FormControl>

            {allValues.otp_sending ? (
              <CircularProgress className="loader-otp" />
            ) : (
              <>
                {/* (<Link to="#" onClick={(event) => getOtp(event)}>
                                        <p className="generate-otp">
                                        {allValues.otp_sent ? "Resend OTP":"Generate OTP"}
                                        </p>
                                </Link>)
                                <br/> */}

                {!allValues.otp_sent ? (
                  <Link to="#" onClick={(event) => getOtp(event)}>
                    <p
                      className="generate-otp mb-5"
                      style={{ color: '#CA0027' }}
                    >
                      {resendReset ? 'Resend new OTP  ' : 'Generate OTP'}
                    </p>
                  </Link>
                ) : (
                  <ResendOTP />
                )}
              </>
            )}

            <p
              className="phno-label  mb-0"
              style={{ fontSize: '18px', marginTop: '100px' }}
            >
              ನೀವು ಸ್ವೀಕರಿಸಿದ OTP ಅನ್ನು ನಮೂದಿಸಿ / Enter your Received OTP
            </p>
            <FormControl fullWidth className="otp-form">
              <TextField
                className="size31"
                name="otp"
                value={allValues.otp}
                type="password"
                onChange={changeHandler}
                error={allValues.otpError}
                placeholder=""
                helperText={
                  allValues.otpError ? 'Please enter correct OTP!' : ''
                }
                // helperText={allValues.otpErrorMessage}
                // helperText
                // helperText=""
                variant="outlined"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    login_func(event);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={otp} alt="..." className="otp-icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            {allValues.logging_in ? (
              <>
                <CircularProgress className="loader-otp" />
              </>
            ) : (
              <>
                <FormControl fullWidth className="loginbuttondiv">
                  <Link to="#" onClick={(event) => login_func(event)}>
                    <p className="login-button" type="submit">
                      ಲಾಗಿನ್ / Login
                    </p>
                  </Link>
                </FormControl>
              </>
            )}
          </form>

          {/* <div className="register-button-div">
                   

                                <Link to="/registration"
                                style={{ flexGrow: "1" }}
                                >
                                <p className="registration-button1 btn-ripple" >
                                    Register for Construction Worker
                                </p>
                                </Link>
                            
                            
                        </div> */}

          {/* {
                        getTokenCookieAdmin() !== undefined &&
                        users.login_admin !== null &&
                        users.login_admin !== undefined &&
                        users.login_admin.data !== null &&
                        users.login_admin.data !== undefined &&
                        users.login_admin.data.department_user_id !== null &&
                        users.login_admin.data.department_user_id !== undefined &&
                        users.login_admin.data.department_user_id !== "" ? 

                        <div className="register-button-div">
                   

                                <Link to="/registration"
                                style={{ flexGrow: "1" }}
                                >
                                <p className="registration-button1 btn-ripple" >
                                    Register for Construction Worker
                                </p>
                                </Link>
                            
                            
                        </div>

                        :
                        null
                    } */}

          <div className="cert-template-div" onClick={handleDownloadListOpen}>
            Click here to download Employment Certificate Template / <br />
            ಉದ್ಯೋಗ ಪ್ರಮಾಣಪತ್ರ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ
          </div>

          <a
            style={{ color: 'inherit' }}
            href={selfDec}
            download="Self Declaration Form"
            target="_blank"
            rel="noreferrer"
          >
            <div className="cert-template-div">
              Click here to download Self Declaration Form Template / <br />
              ಸ್ವಯಂ ಘೋಷಿತ ಪತ್ರ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ
            </div>
          </a>

          {/* <div className="cert-template-div" onClick={() => {props.history.push("/privacy-policy")}}>
                        Privacy Policy
                </div>
                <div className="cert-template-div" onClick={() => {props.history.push("/terms&conditions")}}>
                    Terms and Conditions
                </div> */}

          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={allValues.open}
            onClose={handleClose}
          >
            {/* <DialogTitle className="download-dialog-title">Download Employee Certificate</DialogTitle> */}
            <DialogTitle className="download-dialog-title">
              Download Employee Certificate
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  className="dwnldclosebtn"
                  onClick={handleClose}
                >
                  {/* <CloseIcon /> */}
                  <img
                    alt="..."
                    src={closeIcon}
                    style={{ width: '30px', height: '30px' }}
                  />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent className="download-dialog">
              <List component="nav" aria-label="mailbox folders">
                <a
                  href={doc1}
                  download="Employment certificate by the Employeer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText primary="Employment certificate by the Employer" />
                    <GetApp />
                  </ListItem>
                </a>
                <Divider light />
                <a
                  href={doc2}
                  download="Employment certificate by the Grama Panchayat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText primary="Employment certificate by the Grama Panchayat" />
                    <GetApp />
                  </ListItem>
                </a>
                <Divider light />
                <a
                  href={doc3}
                  download="Employment certificate by the Labour Inspector"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText primary="Employment certificate by the Labour Inspector" />
                    <GetApp />
                  </ListItem>
                </a>
                <Divider light />
                <a
                  href={doc4}
                  download="Employment certificate by the Registration Trade Union"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText primary="Employment certificate by the Registration Trade Union" />
                    <GetApp />
                  </ListItem>
                </a>
              </List>
            </DialogContent>
            <DialogActions className="download-dialog-button">
              <Button autoFocus color="primary" onClick={handleClose}>
                Back
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Col>

      <Col sm={12} className="copyright">
        {/* <Link to="/admin"><p>Admin</p></Link> */}
      </Col>

      {/* <Snackbar
                anchorOrigin={{ vertical: allValues.vertical, horizontal: allValues.horizontal }}
                open={allValues.openSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allValues.snackbarType} >
                    {allValues.snackbarText}
                </MuiAlert>
            </Snackbar> */}
    </Row>
  );
};

export default Login;
