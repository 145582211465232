import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  TextareaAutosize,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

import { Col, Row } from 'react-bootstrap';

import moment from 'moment';

import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

import { getTokenCookie } from 'utils/tools';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { Translate } from 'react-redux-i18n';

import nintydays from 'assets/images/f5-01.svg';
import mobilepic from 'assets/images/Icon awesome-mobile-alt.svg';
import PopOver from './PopOver';

const NintyDaysDetails = ({ certificate, data, personalDetails }) => {
  const { labourUserID, page, status } = useParams();

  const [nintyDaysDocs, setNintyDaysDocs] = React.useState({});
  const [paySlipDocs, setPaySlipDocs] = React.useState({});

  const [renewalDetails, setRenewalDetails] = React.useState([]);

  const [existingLabourCard, setExistingLabourCard] = useState(null);
  const [existingLabourCardID, setExistingLabourCardID] = useState(null);
  const [selfDeclarationForm, setSelfDeclarationForm] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleNewTab = (url) => {
    window.open(url, '_blank', 'noopener');
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/v2/file/${fileID}?token=${getTokenCookie()}`;
    window.open(url, '_blank', 'noopener');
  };

  useEffect(() => {
    if (page === 'Renewal') {
      var data = JSON.stringify({
        key: 'user_id',
        value: labourUserID,
        board_id: 1,
        procedure_name: 'renewal_90_days_certificate_details',
      });

      var config = {
        method: 'post',
        url: SERVER + '/user/get-user-renewal-details',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          setRenewalDetails(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [page, labourUserID]);

  useEffect(() => {
    if (
      certificate &&
      certificate.document_uplaod_files_xml !== '' &&
      certificate.document_uplaod_files_xml !== '-' &&
      certificate.document_uplaod_files_xml !== undefined &&
      certificate.document_uplaod_files_xml !== null
    ) {
      let jsondocs = JSON.parse(certificate.document_uplaod_files_xml);

      setNintyDaysDocs(jsondocs);
    }
    if (
      certificate &&
      certificate.document_upload_selfdeclaration_files_xml !== '' &&
      certificate.document_upload_selfdeclaration_files_xml !== '-' &&
      certificate.document_upload_selfdeclaration_files_xml !== undefined &&
      certificate.document_upload_selfdeclaration_files_xml !== null
    ) {
      let jsondocs2 = JSON.parse(
        certificate.document_upload_selfdeclaration_files_xml,
      );
      setSelfDeclarationForm(jsondocs2);
    }
  }, [certificate]);

  useEffect(() => {
    if (
      renewalDetails &&
      renewalDetails[0] !== undefined &&
      renewalDetails[0].document_upload_payslip_files_xml !== undefined &&
      renewalDetails[0].document_upload_payslip_files_xml.length > 0 &&
      renewalDetails[0].document_upload_payslip_files_xml !== undefined &&
      renewalDetails[0].document_upload_payslip_files_xml !== null &&
      renewalDetails[0].document_upload_payslip_files_xml !== ''
    ) {
      let jsondocs = JSON.parse(
        renewalDetails[0].document_upload_payslip_files_xml,
      );

      setPaySlipDocs(jsondocs);
    }
    if (
      renewalDetails &&
      renewalDetails !== undefined &&
      renewalDetails !== null &&
      renewalDetails.document_upload_selfdeclaration_files_xml !== undefined &&
      renewalDetails.document_upload_selfdeclaration_files_xml.length > 0 &&
      renewalDetails.document_upload_selfdeclaration_files_xml !== undefined &&
      renewalDetails.document_upload_selfdeclaration_files_xml !== null &&
      renewalDetails.document_upload_selfdeclaration_files_xml !== ''
    ) {
      let jsondocs2 = JSON.parse(
        renewalDetails.document_upload_selfdeclaration_files_xml,
      );
      setSelfDeclarationForm(jsondocs2);
    }
  }, [renewalDetails]);

  useEffect(() => {
    if (personalDetails && personalDetails.is_sevasindhu) {
      if (personalDetails && personalDetails.old_reg_card) {
        console.log('Exists', typeof personalDetails.old_reg_card);
        if (personalDetails.old_reg_card.length > 24) {
          const jsonDoc = JSON.parse(personalDetails.old_reg_card);
          setExistingLabourCard(jsonDoc);
        } else if (personalDetails.old_reg_card.length <= 24) {
          setExistingLabourCardID(personalDetails.old_reg_card);
        }
      }
    }
  }, [personalDetails]);

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      {status === 'Approved' &&
      page === 'Registration' &&
      personalDetails.is_sevasindhu ? null : (
        <>
          <Row className="mt-5 mb-0 final all-details-subtitle-row">
            <Col xs={12} md={12} className="profile-title">
              <img alt="..." src={nintydays} />
              <h1>
                Details of 90 Days Working Certificate & Employer/Owner Details
              </h1>
            </Col>
          </Row>

          {data &&
            data.map((employee, i) => {
              return (
                <>
                  <Row className="form-row-final mt-3">
                    <Row
                      className={'mt-0 pt-4 familymember-card final row' + i}
                    >
                      <Col xs={12} md={4} className="final-personal-col-1 px-3">
                        <p className="mb-0">Employer/Owner Details</p>
                        <FormControl fullWidth>
                          <TextField
                            value={employee.employement_status}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={12} md={4} className="final-personal-col-1 px-3">
                        <p className="mb-0"> Employer/Owner Name </p>
                        <FormControl fullWidth>
                          <TextField
                            value={employee.contractor_name}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={12} md={4} className="final-personal-col-1 px-3">
                        <p className="mb-0">Employer/Owner Company Name </p>
                        <FormControl fullWidth>
                          <TextField
                            value={employee.contractor_company_name}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0">Mobile Number (Incharge Person) </p>
                        <FormControl fullWidth>
                          <TextField value={employee.mobile_no} disabled />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={8}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0">Workplace/Site Address </p>
                        <FormControl fullWidth>
                          <TextareaAutosize
                            variant="outlined"
                            multiline
                            rows={2}
                            value={employee.site_address}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> State </p>
                        <FormControl fullWidth>
                          <TextField value={employee.state} disabled />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> Workplace/District </p>
                        <FormControl fullWidth>
                          <TextField value={employee.district} disabled />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> Taluk </p>
                        <FormControl fullWidth>
                          <TextField value={employee.taluk} disabled />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> Town/City/Gram Panchayath </p>
                        <FormControl fullWidth>
                          <TextField
                            value={employee.panhayat_city_town}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0">Village/Ward Circle </p>
                        <FormControl fullWidth>
                          <TextField
                            value={employee.ward_area_village}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> Pincode </p>
                        <FormControl fullWidth>
                          <TextField value={employee.pin_code} disabled />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="final-personal-col-1 px-3 mt-3"
                      >
                        <p className="mb-0"> Nature of Work </p>
                        <FormControl fullWidth>
                          <TextField value={employee.nature_of_work} disabled />
                        </FormControl>
                      </Col>
                    </Row>
                  </Row>
                </>
              );
            })}

          <Row className="form-row-final mt-4">
            <Col xs={12} md={12} className="profile-title">
              <h2>90 Days Work Certificate Details</h2>
            </Col>
            <Col xs={12} md={6} className="final-personal-col-1 px-3">
              <p className="mb-0"> Type of Issuer </p>
              <FormControl fullWidth>
                <TextField value={certificate.issuer_type} disabled />
              </FormControl>
            </Col>
            <Col xs={12} md={6} className="final-personal-col-1 px-3">
              <p className="mb-0">Issued Date </p>
              <FormControl fullWidth>
                <TextField
                  value={moment(certificate.issue_date).format('DD/MM/YYYY')}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={11} md={12} className="profile-title profile-title-new">
              {/* <Row> */}

              {
                <>
                  {certificate.catalog_value_type_of_issuer_id === 55 ? (
                    <>
                      <Col
                        xs={12}
                        md={3}
                        className="issuerTypeForm"
                        style={{ marginLeft: '20px' }}
                      >
                        {/* <p className="mt-0 mb-2">Labour Inspector Name</p> */}
                        <p className="mt-0 mb-2">Labour Inspector Name</p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.inspector_first_name}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="issuerTypeForm"
                        style={{ marginLeft: '30px' }}
                      >
                        {/* <p className="mt-0 mb-2">Circle of the Labour Inspector </p> */}
                        <p className="mt-0 mb-2">
                          Circle of the Labour Inspector{' '}
                        </p>

                        <FormControl fullWidth>
                          <TextField
                            value={certificate.inspector_circle}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="issuerTypeForm"
                        style={{ marginLeft: '30px' }}
                      >
                        {/* <p className="mt-0 mb-2 ">Mobile Number of the Labour Inspector </p> */}
                        <p className="mt-0 mb-2">
                          Mobile Number of the Labour Inspector{' '}
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="mb-3 mt-1"
                                >
                                  <img
                                    src={mobilepic}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            value={certificate.inspector_mobile_no}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </>
                  ) : null}
                  {certificate.catalog_value_type_of_issuer_id === 56 ? (
                    <>
                      <Col xs={10} md={3} className="issuerTypeForm">
                        {/* <p className="mt-0 mb-2">Union Name</p> */}
                        <p className="mt-0 mb-2">Union Name</p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.labour_union_name}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={10} md={3} className="issuerTypeForm">
                        {/* <p className="mt-0 mb-2">Union Registration Number</p> */}
                        <p className="mt-0 mb-2">
                          <Translate value="unionRegistrationNumber" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.labour_union_registration_no}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={10} md={3} className="issuerTypeForm">
                        {/* <p className="mt-0 mb-2">Name of Issuer</p> */}
                        <p className="mt-0 mb-2">
                          <Translate value="nameofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.labour_union_spoke_person}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={10} md={3} className="issuerTypeForm">
                        {/* <p className="mt-0 mb-2">Mobile Number (If Available)</p> */}
                        <p className="mt-0 mb-2">
                          <Translate value="mobileNumberIfAvailable" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobilepic}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            value={certificate.aslabour_union_phone_no}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </>
                  ) : null}
                  {certificate.catalog_value_type_of_issuer_id === 57 ? (
                    <>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">Name of Issuer</p>
                        <FormControl fullWidth>
                          <TextField value={certificate.issuer_name} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">Place of Issuer</p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.issuer_place}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">
                          Mobile Number (If Available)
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobilepic}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            value={certificate.mobile_no}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </>
                  ) : null}
                  {certificate.catalog_value_type_of_issuer_id === 58 ? (
                    <>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">Name of Issuer</p>
                        <FormControl fullWidth>
                          <TextField value={certificate.issuer_name} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">Designation of Issuer</p>
                        <FormControl fullWidth>
                          <TextField
                            value={certificate.issuer_designation}
                            disabled
                          />
                        </FormControl>
                      </Col>
                      <Col xs={4} className="issuerTypeForm">
                        <p className="mt-0 mb-2">
                          Mobile Number (If Available)
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobilepic}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            value={certificate.mobile_no}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </>
                  ) : null}
                </>
              }
            </Col>
            <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
              <p className="mb-0"> 90 Days Working Certificate</p>
              {nintyDaysDocs && nintyDaysDocs.length ? (
                nintyDaysDocs.map((row, index) => (
                  <>
                    <div className="browsebutton2-div final">
                      <FormControl>
                        <TextField
                          variant="outlined"
                          value={row.file_name}
                          style={{ width: '-webkit-fill-available' }}
                          disabled
                        />
                      </FormControl>
                      {!row.file_name.includes('.pdf') ? (
                        <label
                          className="browse-button2"
                          onClick={(ev) =>
                            handleViewImage(ev, row.file_id, row.file_name)
                          }
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      ) : (
                        <label
                          className="browse-button2"
                          onClick={() => handleViewPDF(row.file_id)}
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      )}
                    </div>
                  </>
                ))
              ) : (
                <div className="browsebutton2-div final">
                  <FormControl>
                    <TextField
                      variant="outlined"
                      value={'N/A'}
                      style={{ width: '-webkit-fill-available' }}
                      disabled
                    />
                  </FormControl>
                </div>
              )}
            </Col>

            {page === 'Renewal' ? (
              <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                <p className="mb-0">Pay slip / Muster Roll</p>
                {paySlipDocs && paySlipDocs.length ? (
                  paySlipDocs.map((row, index) => (
                    <>
                      <div className="browsebutton2-div final">
                        <FormControl>
                          <TextField
                            variant="outlined"
                            value={row.file_name}
                            style={{ width: '-webkit-fill-available' }}
                            disabled
                          />
                        </FormControl>
                        {!row.file_name.includes('.pdf') ? (
                          <label
                            className="browse-button2"
                            onClick={(ev) =>
                              handleViewImage(ev, row.file_id, row.file_name)
                            }
                          >
                            <Translate value="view" /> <Visibility />
                          </label>
                        ) : (
                          <label
                            className="browse-button2"
                            onClick={() => handleViewPDF(row.file_id)}
                          >
                            <Translate value="view" /> <Visibility />
                          </label>
                        )}
                      </div>
                    </>
                  ))
                ) : (
                  <div className="browsebutton2-div final">
                    <FormControl>
                      <TextField
                        variant="outlined"
                        value={'N/A'}
                        style={{ width: '-webkit-fill-available' }}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
              </Col>
            ) : null}
          </Row>
        </>
      )}
      {
        <>
          <Row className="mt-5 mb-0 final all-details-subtitle-row">
            <Col xs={12} md={12} className="profile-title">
              <h1>Self Declaration Form</h1>
            </Col>
          </Row>
          <Row className="form-row-final mt-4">
            <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
              <p className="mb-0">Self Declaration Form</p>
              {selfDeclarationForm && selfDeclarationForm.length ? (
                selfDeclarationForm.map((row, index) => (
                  <>
                    <div className="browsebutton2-div final">
                      <FormControl>
                        <TextField
                          variant="outlined"
                          value={row.file_name}
                          style={{ width: '-webkit-fill-available' }}
                          disabled
                        />
                      </FormControl>
                      {!row.file_name.includes('.pdf') ? (
                        <label
                          className="browse-button2"
                          onClick={(ev) =>
                            handleViewImage(ev, row.file_id, row.file_name)
                          }
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      ) : (
                        <label
                          className="browse-button2"
                          onClick={() => handleViewPDF(row.file_id)}
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      )}
                    </div>
                  </>
                ))
              ) : (
                <div className="browsebutton2-div final">
                  <FormControl>
                    <TextField
                      variant="outlined"
                      value={'N/A'}
                      style={{ width: '-webkit-fill-available' }}
                      disabled
                    />
                  </FormControl>
                </div>
              )}
            </Col>
          </Row>
        </>
      }
      {personalDetails.is_sevasindhu ? (
        <>
          <Row className="mt-5 mb-0 final all-details-subtitle-row">
            <Col xs={12} md={12} className="profile-title">
              <h1>Existing Labour Card</h1>
            </Col>
          </Row>
          <Row className="form-row-final mt-4">
            <Col xs={12} md={12} className="final-personal-col-1 px-3 mt-3">
              {existingLabourCard && existingLabourCard.length ? (
                existingLabourCard.map((row, index) => (
                  <>
                    <div className="browsebutton2-div final">
                      <FormControl>
                        <TextField
                          variant="outlined"
                          value={row.file_name}
                          style={{ width: '-webkit-fill-available' }}
                          disabled
                        />
                      </FormControl>

                      <label
                        className="browse-button2"
                        onClick={() => handleViewPDF(row.file_id)}
                      >
                        <Translate value="view" /> <Visibility />
                      </label>
                    </div>
                  </>
                ))
              ) : existingLabourCardID && existingLabourCardID.length ? (
                <>
                  <div className="browsebutton2-div final">
                    <FormControl>
                      <TextField
                        variant="outlined"
                        value={existingLabourCardID}
                        style={{ width: '-webkit-fill-available' }}
                        disabled
                      />
                    </FormControl>
                    <label
                      className="browse-button2"
                      onClick={() => handleViewPDF(existingLabourCardID)}
                    >
                      <Translate value="view" /> <Visibility />
                    </label>
                    {/* <label
                      className="browse-button2"
                      onClick={() => handleViewPDF(existingLabourCardID)}
                    >
                      <Translate value="Open in new tab" /> <Visibility />
                    </label> */}
                  </div>
                </>
              ) : (
                'N/A'
              )}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default NintyDaysDetails;
