import 'date-fns';
import { FormControl, TextField, Select, MenuItem, ListItemIcon, ListItemText, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import { searchuser, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { encrypt, removeTokenCookie, showToast } from 'utils/tools';
import { SERVER_2 } from '../../store/actions/user.actions'
import axios from 'axios';


const SearchDataForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        searchValueError: false,
        submitting: false,
    })
    
    const [searchPlaceholder, setsearchPlaceholder] = React.useState("Enter Mobile Number / Aadhar Number / Registration ID")



    useEffect(() => {
        removeTokenCookie()
        dispatch(searchuser("delete"))
        
    }, []);


    useEffect(() => {
        console.error("allValues.searchOption: "+allValues.searchOption)
        if(allValues.searchOption === "mobile"){
            setsearchPlaceholder("Enter Mobile Number")
        }
        else if(allValues.searchOption === "aadhaar"){
            setsearchPlaceholder("Enter AADHAR Number")
        }
        else if(allValues.searchOption === "regid"){
            setsearchPlaceholder("Enter Registration ID")
        }
    }, [allValues.searchOption]);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    const submitButtonPressed = () => {

        if(allValues.searchValue !== undefined && allValues.searchValue !== "" ){

            setAllValues({
                ...allValues,
                submitting: true
            })

            console.log("allValues.searchOption="+allValues.searchOption)
            var search_type = ""
            if(allValues.searchOption === "mobile"){
                search_type = "phone"
            }
            else if(allValues.searchOption === "aadhaar"){
                search_type = "aadhar_no"
            }
            else if(allValues.searchOption === "regid"){
                search_type = "registration_job_card_no"
            }

            let dataForRegDetails = {
                "search_type": search_type,
                "search_value": allValues.searchValue
            }

            dispatch(searchuser(dataForRegDetails))
        }
        else{
            setAllValues({
                ...allValues,
                searchValueError: true,
                submitting: false
            })
        }
    }

    useEffect(() => {
        // console.log("users.login_user.message:"+users.login_user.message)

        if (users.searchuser !== null && users.searchuser.success !== null && users.searchuser.success !== undefined &&users.searchuser.success === true) {
            console.log("users.searchuser.data: "+JSON.stringify(users.searchuser.data))
            if(users.searchuser.data !== null && users.searchuser.data !== undefined 
                // && users.searchuser.data.length > 0 
                ){

                // setAllValues({
                //     ...allValues,
                //     submitting: false,
                // })
                // showToast("SUCCESS", "Data Found Successfully!")




                if(users.searchuser.data[0].is_final_submit === true){
                    console.log("already registered")
                    showToast("SUCCESS", "Labour is already registered!")
                    setAllValues({
                        ...allValues,
                        submitting: false,
                    })
                }
                else if(users.searchuser.data[0].unique_id_after_update === null){
                    console.log("have to call register-labor Next")

                    let dataForReg = {
                        "key": "id",
                        "value": users.searchuser.data[0].id
                    }
                    axios.post(SERVER_2+"/user/register-labor",dataForReg)
                    .then((response) => {
                        console.log(response);
                        if(response.data.success){
                            
                            if(users.searchuser.data[0].is_initial_submit === null || users.searchuser.data[0].is_initial_submit === false){
                                let dataForReg = {
                                    "key": "id",
                                    "value": users.searchuser.data[0].id
                                }
                                axios.post(SERVER_2+"/user/initial-update-labor",dataForReg)
                                .then((response) => {
                                    console.log("initial-update-labor response: "+JSON.stringify(response))
                                })
                                .catch((error)=>{
                                    console.log("initial-update-labor error: "+JSON.stringify(error))
                                })
                            }

                            // ------ call /user/get-user \/\/\/\/
                            let dataForGetUser = {
                                "key": "mobile",
                                "value": users.searchuser.data[0].phone,
                                "board_id": 1
                            }
                            axios.post(SERVER_2+"/user/get-user",dataForGetUser)
                            .then((response) => {
                                console.log("get-user response: "+JSON.stringify(response))
                                console.log("response.data.data[0].id.toString(): "+response.data.data[0].id.toString())
                                var encode = encrypt(response.data.data[0].id.toString())
                                console.log("encode: "+encode)
                                props.history.push("/update-registration/"+encode);
                                dispatch(searchuser("delete"))
                                setAllValues({
                                    ...allValues,
                                    submitting: false,
                                })
                            })
                            .catch((error)=>{
                                console.log("get-user error: "+JSON.stringify(error))
                                setAllValues({
                                    ...allValues,
                                    submitting: false,
                                })
                            })

                        }
                    })
                    .catch((error)=>{
                        console.log("register-labor error: "+JSON.stringify(error))
                        showToast("ERROR", "Some error occured. Please try again later!")
                        setAllValues({
                            ...allValues,
                            submitting: false,
                        })
                    })
                }
                else if(users.searchuser.data[0].unique_id_after_update !== null){
                    console.log("have to call initial-update-labor")

                    if(users.searchuser.data[0].is_initial_submit === null || users.searchuser.data[0].is_initial_submit === false){
                        let dataForReg = {
                            "key": "id",
                            "value": users.searchuser.data[0].id
                        }
                        axios.post(SERVER_2+"/user/initial-update-labor",dataForReg)
                        .then((response) => {
                            console.log("initial-update-labor response: "+JSON.stringify(response))
                        })
                        .catch((error)=>{
                            console.log("initial-update-labor error: "+JSON.stringify(error))
                        })
                    }
                    
                    var encode = encrypt(users.searchuser.data[0].unique_id_after_update.toString())
                    console.log("encode: "+encode)
                    props.history.push("/update-registration/"+encode);
                    dispatch(searchuser("delete"))
                    setAllValues({
                        ...allValues,
                        submitting: false,
                    })
                            

                }
                // else{
                //     console.log("have to call initial-update-labor")

                //     var encode = encrypt(users.searchuser.data[0].unique_id_after_update.toString())
                //     console.log("encode: "+encode)            
                //     props.history.push("/update-registration/"+encode);
                //     dispatch(searchuser("delete"))
                // }
                
            }
        }
        else if (users.searchuser !== null && users.searchuser.message !== null) {
            setAllValues({
                ...allValues,
                submitting: false,
            })
            showToast("ERROR", "Data Not Found!")

            users.searchuser = null
        }
        else if (users.searchuser !== null) {
            setAllValues({
                ...allValues,
                submitting: false,
            })
            showToast("ERROR", "Error Searching!")

            users.searchuser = null
        }
    }, [users.searchuser])


    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div div2">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="search-title-row">
                <Col xs={12}>
                    <h1>Registration Details</h1>
                </Col>
            </Row>

            <Row className="search-form mt-4">
                {/* <p className="mb-4 fieldtitle">Enter Mobile Number / Aadhar Number / Registration ID</p> */}
                <p className="mb-4 fieldtitle">Select Any One Option to Search</p>
                <FormControl className="radio-options-field">
                    <RadioGroup aria-label="searchOption" name="searchOption" value={allValues.searchOption} onChange={handleChange}>
                        <FormControlLabel value="mobile" control={<Radio />} label="Mobile Number" />
                        <FormControlLabel value="aadhaar" control={<Radio />} label="AADHAAR Number" />
                        <FormControlLabel value="regid" control={<Radio />} label="Registration ID" />
                    </RadioGroup>
                </FormControl>
                <FormControl fullWidth className="phno-field">
                    <TextField
                        // className="size21"
                        name="searchValue"
                        value={allValues.searchValue}
                        placeholder={searchPlaceholder}
                        type="text"
                        onChange={handleChange}
                        error={allValues.searchValueError}
                        helperText={allValues.searchValueError ? "Please enter correct data" : ""}
                        variant="outlined"
                        disabled={allValues.searchOption === "" || allValues.searchOption === undefined}
                    >
                    </TextField>
                </FormControl>

                <div className="note-full-red mt-3">
                    <p>NOTE: Mobile Number / Aadhar Number should be same which was provided during benificiary registration</p>
                </div>

                <div className="submit-button-div">
                    {
                        allValues.submitting ?
                            <CircularProgress className="loader-otp" /> 
                            :
                            <Link to="#"
                                onClick={submitButtonPressed}
                                // style={{ flexGrow: "1" }}
                            >
                                <Button variant="outline-primary" className="next-button">
                                    Submit
                                </Button>
                            </Link>
                    }
                    
                </div>



            </Row>
        </>
    )
}

export default SearchDataForm;