/* eslint-disable no-throw-literal */
import { hasEmptyPropertiesBankDetails } from 'utils/checkEmptyArray';

export const BankValidator = async (
  bankDetails,
  bankPassbookDoc,
  nomineePassbookDoc,
) => {
  const bankDetailsCheck = bankDetails.filter(
    (item) => !hasEmptyPropertiesBankDetails(item),
  );
  if (bankDetails.length !== bankDetailsCheck.length) {
    throw {
      success: false,
      msg: 'Please fill all mandatory bank details',
    };
  } else if (
    bankDetails.length === 1 &&
    bankPassbookDoc &&
    bankPassbookDoc.length === 0
  ) {
    throw {
      success: false,
      msg: 'Please upload passbook document',
    };
  } else if (
    bankDetails.length === 2 &&
    ((bankPassbookDoc && bankPassbookDoc.length === 0) ||
      (nomineePassbookDoc && nomineePassbookDoc.length === 0))
  ) {
    throw {
      success: false,
      msg: 'Please upload passbook and nominee passbook documents',
    };
  } else {
    return {
      success: true,
      data: {
        bankDetails,
      },
    };
  }
};
