import React, { lazy, Suspense, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { Button } from '@mui/material';

import { useState } from 'react';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { useSelector } from 'react-redux';
import { getTokenCookie } from 'utils/tools';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Eye from 'assets/images/eye.svg';
import { Button } from 'react-bootstrap';
import { IconButton, Pagination, Stack, Tooltip } from '@mui/material';
import PaginationFunction from '../Pagination';
import { Delete } from '@mui/icons-material';
import useError from 'hooks/useError';
import { CircularLoader } from 'components/ui/Loader';
const ContinuationPensionDeleteModal = lazy(
  () => import('./ContinuationPensionDeleteModal'),
);
const PensionList = () => {
  const [registrationNumber, setRegistrationNumber] = useState(null);
  const [user, setUser] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [pensionList, setPensionList] = useState(null);
  const [loading, setLoading] = useState(false);

  const users = useSelector((state) => state.users);

  const [page, setPage] = useState(1);

  const PER_PAGE = 10;

  const count = pensionList && Math.ceil(pensionList.length / PER_PAGE);

  let _DATA = PaginationFunction(pensionList || [], PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleReset = () => {
    setRegistrationNumber('');
    setMobileNumber('');
  };

  const getPensionData = () => {
    setLoading(true);
    const payLoad = {
      alcId: users && users.getDeptUserRole.data[0].department_user_id,
      registrationNumber:
        registrationNumber && registrationNumber.length > 0
          ? registrationNumber
          : null,
      mobile: mobileNumber && mobileNumber.length === 10 ? +mobileNumber : null,
    };
    axios
      .post(`${SERVER}/schemes/continuation-pension`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        setPensionList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = useCallback((data) => {
    console.log(data);
    setUser(data);
  }, []);
  const closeHandler = useCallback(() => setUser(null), []);
  const handleSuccess = useCallback((data) => {
    // console.log(data);
    console.log(' onSuccess={useCallback ~ data:', data);
    closeHandler();
    getPensionData();
  }, []);
  return (
    <div className="w-100 my-4 d-flex justify-content-center flex-column align-items-center">
      <p className="text-center" style={{ fontSize: '20px' }}>
        Search Submitted Applications
      </p>

      <div className="d-flex justify-content-center align-items-center w-100 mx-3 my-3">
        <input
          type="text"
          className="w-50 form-control mx-2 text-center"
          placeholder="Registration Number"
          value={registrationNumber}
          onChange={(ev) => setRegistrationNumber(ev.target.value)}
        />

        <input
          type="text"
          className="w-50 form-control mx-2 text-center"
          placeholder="Mobile Number"
          value={mobileNumber}
          onChange={(ev) => setMobileNumber(ev.target.value.slice(0, 10))}
        />
        <Button onClick={getPensionData} className=" mx-2 btn btn-success">
          Search
        </Button>
        <Button
          disabled={!mobileNumber && !registrationNumber && true}
          onClick={handleReset}
          className=" mx-2 btn btn-danger"
        >
          Reset
        </Button>
      </div>
      {pensionList && pensionList.length > 0 && (
        <div className="d-flex justify-content-end align-self-end">
          <Stack spacing={2} className="w-100">
            <Pagination
              count={count}
              size="medium"
              page={page}
              color="primary"
              variant="outlined"
              shape="circular"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      )}
      {loading ? (
        <SimpleBackdrop />
      ) : pensionList && pensionList.length ? (
        <TableContainer style={{ width: '100%' }} component={Paper}>
          <Table style={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Beneficiary Name
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Labour Registration Number
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Mobile Number
                </TableCell>

                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_DATA.currentData() &&
                _DATA.currentData().map((data, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {data.beneficiary_name}
                    </TableCell>
                    <TableCell align="center">{data.registration_no}</TableCell>
                    <TableCell align="center">{data.mobile_number}</TableCell>

                    <TableCell align="center">
                      <Link
                        to={`/admin/dashboard/pension-details?registrationNumber=${data.registration_no}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img alt="..." src={Eye} className="px-1" />
                          <div>View</div>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          data.is_transferred
                            ? 'User Already Transferred Cannot Do this Operation'
                            : 'Delete Pension User'
                        }
                      >
                        <IconButton
                          color="error"
                          sx={{
                            '&:hover': {
                              backgroundColor: 'red',
                              color: 'white',
                            },
                          }}
                          className="bounce"
                          onClick={() => {
                            handleDelete(data);
                          }}
                          disabled={data.is_transferred}
                        >
                          <Delete />
                        </IconButton>{' '}
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : pensionList && pensionList.length === 0 ? (
        'No Applications Found'
      ) : null}
      {user && (
        <Suspense fallback={<CircularLoader />}>
          <ContinuationPensionDeleteModal
            {...user}
            closeHandler={closeHandler}
            onSuccess={handleSuccess}
            onError={(error) => {}}
          />
        </Suspense>
      )}
    </div>
  );
};

export default PensionList;
