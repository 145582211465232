import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead, TextField, InputAdornment, Select, Backdrop, makeStyles, FormControl, Paper } from '@material-ui/core';
import { Button, ButtonBase, TableFooter } from "@mui/material";
import axios from 'axios';
import { getCatalogDetails, getLabourStatusDetails, getSchemesOnStatus, get_schemes, SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { getTokenCookie, showToast } from 'utils/tools';
import { CSVLink } from 'react-csv';
import Excel from "../../assets/images/excel.png";
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
const MISGridScheme = ({deptID,roleID}) => {

    const [fetchSchemes,setFetchSchemes] = useState([])
    const [schemeId,setSchemeId] = useState("")
    const [fetchDistrict,setFetchDistrict] = useState([]) 
    const [fetchTaluk,setFetchTaluk] = useState([]) 
    const [fetchCircle,setFetchCircle] = useState([]) 
    const [showDistrict, setShowDistrict] = useState(false);
    const [showTaluk, setShowTaluk] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [misReportData, setMISReportData] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [CSVOverall,setCSVOverall] = useState([])
    const [CSVOverallTaluk,setCSVOverallTaluk] = useState([])
    const [CSVOverallCircle,setCSVOverallCircle] = useState([])


    const excelRef = useRef(null)

  console.log(deptID,roleID);
    useEffect(()=>{
        axios.get(`${SERVER}/reports/get_scheme_mis_report?board_id=${1}&role_id=${roleID}&department_user_id=${deptID}`).then((res)=>{
            console.log(res.data.data);
            setFetchSchemes(res.data.data[0])
        }).catch((err)=>{
            console.log(err);
        })
    },[])


    const handleSchemeChange = (ev) => {
        setSchemeId(ev.target.value)
    }

    const callMISSchemeAPI = async() => {
        console.log(schemeId);
        if(schemeId !== ""){

        
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id":schemeId

        }
        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report_by_district',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
          }

        await axios(config).then((res)=>{
            console.log(res.data.data);
            const sortedDistrict = res.data.data[0].sort((a,b)=> {
             const aDistrict = a.district_name  
             const bDistrict = b.district_name
             if(aDistrict < bDistrict){
                return -1
             }
             if(aDistrict > bDistrict)
             {
                return 1
             }
             return 0
            })
            console.log("SORTEDARR:",sortedDistrict);
            setFetchDistrict(sortedDistrict)
            handleFormatCSV(sortedDistrict)
            setShowDistrict(true)
        })
        .catch((err)=>{
            console.log(err);
        })
        }
        else {
            showToast("WARN","Please select the Schemes")
        }

    }

    const talukHandler = async(talukId) => {
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id":schemeId,
            "district_id":talukId
        }
        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report_by_taluk',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
          }

        await axios(config).then((res)=>{
            console.log(res.data.data);
            setFetchTaluk(res.data.data[0])
            handleFormatCSVTaluk(res.data.data[0])
            setShowDistrict(false)
            setShowTaluk(true)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const cityHandler = async (talukId,districtId) =>{
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id":schemeId,
            "district_id":districtId,
            "taluk_id":talukId
        }
        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report_by_circle',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
          }

        await axios(config).then((res)=>{
            console.log("Circles",res.data.data);
            setFetchCircle(res.data.data[0])
            handleFormatCSVCircle(res.data.data[0])
            setShowDistrict(false)
            setShowTaluk(false)
            setShowCity(true)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const handleCSV = async(districtId) => {

        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id": 15,
            "district_id": districtId,
            "taluk_id": null,
            "circle_id": null
            }

        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })
    

    }

    const handleCSVTaluk = async(talukId) => {
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id": schemeId,
            "district_id": null,
            "taluk_id": talukId,
            "circle_id": null
            }

        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })
    

    }

    const handleCSVCircle = async (circleId) => {
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
            "scheme_id": schemeId,
            "district_id": null,
            "taluk_id": null,
            "circle_id": circleId
            }

        var config = {
            method: 'post',
                url: SERVER+'/reports/get_scheme_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })
    

    }


      let initialValue = 0
  const totalCount = fetchDistrict.reduce(function(accum,curValue){
        return accum + curValue.total_counts
    },initialValue)

console.log("TotalCount",totalCount);

var initialValue2 = 0
        const totalCountTaluk = fetchTaluk.reduce(function(accum,curValue){
        return accum + curValue.total_counts
        },initialValue2)

console.log("TotalCount",totalCountTaluk);

var initialValue3 = 0
        const totalCountCircle = fetchCircle.reduce(function(accum,curValue){
        return accum + curValue.total_counts
        },initialValue3)

        console.log("TotalCount",totalCountCircle);


  const headersDistrict = [
  { label: "District", key: "district_name" },
  { label: "No of registration", key: "total_counts" },
  
];

const headersTaluk = [
  { label: "Taluk", key: "taluk_name" },
  { label: "No of registration", key: "total_counts" },
  
];

const headersCircle = [
    { label: "Circle", key: "circle_name" },
    { label: "No of registration", key: "total_counts" },
 
]


        const handleFormatCSV = (data) => {
        console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_counts
      },initialValue)
        arr.push({
          district_name: "Total",
          total_counts: Total
        })
        setCSVOverall(arr)
        console.log("Overall",arr);


    }

       const handleFormatCSVTaluk = (data) => {
        console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_counts
      },initialValue)
        arr.push({
          taluk_name: "Total",
          total_counts: Total
        })
        setCSVOverallTaluk(arr)
        console.log("Overall",arr);


    }

    const handleFormatCSVCircle = (data) => {
      console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_counts
      },initialValue)
        arr.push({
          circle_name: "Total",
          total_counts: Total
        })
        setCSVOverallCircle(arr)
        console.log("Overall",arr);
    }





    const handleBack = (value) => {
        if(value === "Taluk")
    {
      setShowDistrict(true)
      setShowTaluk(false)
    }
    else if(value === "Circle"){
      setShowTaluk(true)
      setShowCity(false)
    }
    }
    
 

  return (
    <>
    <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        <Row className='container-fluid d-flex justify-content-center align-items-center'>
            <Col xs={3} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital w-100"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={schemeId}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleSchemeChange}
                                defaultValue={"Select"}
                            >
                                <MenuItem value= {""} >
                                    <ListItemText primary="Select Schemes" />
                                </MenuItem>
                                {
                                    fetchSchemes && fetchSchemes.map((scheme)=>(
                                    <MenuItem value= {scheme.scheme_id} >
                                        <ListItemText primary={scheme.scheme_name} />
                                    </MenuItem>
                                    ))
                                }
                                
                               
                            </Select>
                        </FormControl>
                </Col>

                <Col xs={3} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                <Button color='success' variant='contained' 
                onClick={callMISSchemeAPI} 
                className='select-marital' style={{padding:'10px 0px'}}>Search</Button>

                </FormControl>  
                </Col>

                </Row>
                </div>
                <Row className='container-fluid d-flex justify-content-center align-items-center'>
                    <TableContainer component={Paper}>
      {showDistrict ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        
          <Table sx={{ minWidth: 650}} style={{width:'80%'}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>District</TableCell>
                
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Total Counts
                </TableCell>
                 
             <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { fetchDistrict.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: "pointer" }}
                    onClick={() => talukHandler(row.district_id)}
                  >
                    {row.district_name === null ? "NA" : row.district_name}
                  </TableCell>
                  <TableCell align="center">{row.total_counts}</TableCell>
                  
                  {/* <TableCell align="center">30</TableCell> */}
                  
                  <TableCell align="center">
                  
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef}>
                    </CSVLink>
                    
                      <Button
                        onClick={() => handleCSV(row.district_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
                </TableRow>
              ))}
              <ButtonBase />
            </TableBody>
            
            <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCount}
              </TableCell>
              <CSVLink data={CSVOverall} headers={headersDistrict} filename='District Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              
            </TableHead>
          </Table>
        </div>
      ) : null}
      {showTaluk ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        <div style={{margin:'10px 4px'}}>
      <button style={{background:'transparent',outline:'none',border:'none'}} onClick={() => handleBack("Taluk")}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    </div>
        <Table sx={{ minWidth: 650,}} style={{width:'80%'}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Taluk</TableCell>
              
                <TableCell style={{ fontWeight: "bold" }} align="center">
                 Total Counts
                </TableCell>
                 
                 
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              
            </TableRow>
          </TableHead>
          
          <TableBody>
            {fetchTaluk.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer" }}
                  onClick={() => cityHandler(row.taluk_id,row.district_id)}
                >
                  {row.taluk_name}
                </TableCell>
                <TableCell align="center">{row.total_counts}</TableCell>
                <TableCell align="center">
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef} >
                    </CSVLink>
                      <Button
                        onClick={() => handleCSVTaluk(row.taluk_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCountTaluk}
              </TableCell>
              <CSVLink data={CSVOverallTaluk} headers={headersTaluk} filename='Taluk Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              
            </TableHead>
        </Table>
        </div>
      ) : null}
      {showCity ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        <div style={{margin:'10px 4px'}}>
      <button style={{background:'transparent',outline:'none',border:'none'}} onClick={() => handleBack("Circle")}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    </div>
        <Table sx={{ minWidth: 650,}} style={{width:'80%'}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Circle</TableCell>
              
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Total Counts
                </TableCell>
                
                 
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchCircle.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer" }}
                //   onClick={() => cityHandler(row.id)}
                >
                  {row.circle_name}
                </TableCell>
                <TableCell align="center">{row.total_counts}</TableCell>
                <TableCell align="center">
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef}>
                    </CSVLink>
                      <Button
                        onClick={() => handleCSVCircle(row.circle_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCountCircle}
              </TableCell>
              <CSVLink data={CSVOverallCircle} headers={headersCircle} filename='Circle Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              
            </TableHead>
        </Table>
        </div>
      ) : null}
      
    </TableContainer>
    </Row>
    </>
  )
}

export default MISGridScheme