import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

const ShramaSamarthyaAssistance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  kannadaSelected,
}) => {
  return (
    <>
      {dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map((field) => (
          <Col xs={12} md={4} style={{ alignSelf: 'end' }}>
            <Required
              className="mb-2 mt-4"
              title={
                kannadaSelected ? field.field_name_kannada : field.field_name
              }
            />
            {field.field_type === 'Text' && (
              <>
                {field.field_name !==
                  'Name of the dependent  (If Dependent is selected above)' && (
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      type={'text'}
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                      variant="outlined"

                      // helperText={allValues['error_' + field.id]}
                      // error={allValues.globalError}
                      // disabled={
                      //   field.field_name.includes(
                      //     'Name of the dependent ',
                      //   ) && allValues.medicalAssistanceSelf
                      // }
                    />
                  </FormControl>
                )}
                {field.field_name ===
                  'Name of the dependent  (If Dependent is selected above)' && (
                  <>
                    {formData && formData['Person Hospitalized'] === 38 && (
                      <FormControl fullWidth className="formcontrol1">
                        <TextField
                          type={'text'}
                          name={field.field_name}
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          value={
                            formData &&
                            formData['Person Hospitalized'] === 38 &&
                            formData[field.field_name]
                          }
                          variant="outlined"

                          // helperText={allValues['error_' + field.id]}
                          // error={allValues.globalError}
                          // disabled={
                          //   field.field_name.includes(
                          //     'Name of the dependent ',
                          //   ) && allValues.medicalAssistanceSelf
                          // }
                        />
                      </FormControl>
                    )}
                    {formData && formData['Person Hospitalized'] === 39 && (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className="formcontrol5"
                      >
                        <Select
                          className="select-marital"
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          name={field.field_name}
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          value={formData && formData[field.field_name]}
                        >
                          <MenuItem value="">
                            <ListItemText primary="Select" />
                          </MenuItem>
                          {familyDetails &&
                            familyDetails.length &&
                            familyDetails.map((fam) => (
                              <MenuItem
                                value={`${fam.first_name.trim() ?? ''} ${
                                  fam.middle_name.trim() ?? ''
                                } ${fam.last_name.trim() ?? ''}`}
                              >
                                <ListItemText
                                  primary={`${fam.first_name ?? ''} ${
                                    fam.middle_name ?? ''
                                  } ${fam.last_name ?? ''}`}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </>
            )}
            {field.field_type === 'Date' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDate={
                    field.field_name.includes('Date Of Marriage')
                      ? moment().subtract(6, 'months')
                      : new Date(-999999)
                  }
                  maxDate={new Date()}
                  // onClick={(e) => onChangeDate(e, field)}
                  // onClose={() => setPickerStatus(false)}
                  // open={allValues.openDatePicker}
                  className="datepicker"
                  margin="normal"
                  format="DD/MM/YYYY"
                  name={field.field_name}
                  // id={id}
                  value={
                    formData && formData[field.field_name]
                      ? formData[field.field_name]
                      : null
                  }
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  // onChange={setDate}
                  placeholder="DD/MM/YYYY"
                  // error={allValues.globalError}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}

            {(field.field_type === 'Dropdown' ||
              field.field_type === 'Radio Button') && (
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital"
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name={field.field_name}
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  value={(formData && formData[field.field_name]) || '0'}
                >
                  <MenuItem value="0">
                    <ListItemText primary={`Select`} />
                  </MenuItem>
                  {field.field_data.map((field) => (
                    <MenuItem value={field.field_value_id}>
                      <ListItemText primary={field.field_value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Col>
        ))}
    </>
  );
};

export default ShramaSamarthyaAssistance;
