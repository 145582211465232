import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import { Translate } from "react-redux-i18n";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
  Tooltip,
} from "@material-ui/core";
import language from "assets/images/translate (1).svg";
import usericon from "assets/images/Icon awesome-user.svg";
import logout from "assets/images/Logout.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import {
  Required,
  dataURLtoFile,
  getTokenCookie,
  showToast,
} from "utils/tools";
import moment from "moment";
import folder from "assets/images/Folderwhite-01-01.svg";
import closeicon from "assets/images/closeIcon.svg";
import PopOverImage from "../globals/popOverImage";
import { Visibility } from "@material-ui/icons";

const EducationValidationForm = () => {
  const params = useParams();
  const { roleId, userID, recordID } = params;

  const history = useHistory();

  const [fetchData, setFetchData] = useState(null);
  const [newSatID, setNewSatID] = useState("");
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [remarks, setRemarks] = useState("");

  const [selectedFiles, setSelectedFiles] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [isUpdateCalled, setIsUpdatedCalled] = useState(false);
  const [verificationDocumentList, setVerificationDocumentList] = useState("");

  const [currentImgClick, setCurrentImgClick] = React.useState("");
  const [anchorElVerification, setAnchorElVerification] = React.useState(null);
  const [selected_document_type, setselected_document_type] =
    React.useState("");
  const [verificationDoc, setVerificationDoc] = useState({});

  const openVerification = Boolean(anchorElVerification);
  const idVerification = openVerification ? "simple-popover" : undefined;

  useEffect(() => {
    axios
      .get(
        `${SERVER}/schemes/education_scheme_status?roleid=${roleId}&userid=${userID}&recordid=${recordID}`,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setFetchData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (fetchData && fetchData.length && fetchData[0].is_updated === 1) {
      setNewSatID(fetchData[0].New_Sat_id);
      setNewMobileNumber(fetchData[0].New_Mobile_No);
      setRemarks(fetchData[0].remarks);
    }
  }, [fetchData]);

  const handleClick = (event, data, selecteddoc) => {
    console.log("handleclick data:" + data, event, selecteddoc);

    setselected_document_type(selecteddoc);

    if (data === "verification") {
      setCurrentImgClick("verification");
      setAnchorElVerification(event.currentTarget);
    }
  };

  const handleClose = (event, data) => {
    console.error("handleClose data:" + data);
    if (data === "verification") {
      setCurrentImgClick("verification");
      setAnchorElVerification(null);
    }
  };

  const popImgCall = useCallback(() => {}, [currentImgClick]);

  const handleClick2 = (event, data, selecteddoc) => {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(selecteddoc) +
        "'></iframe>"
    );
  };

  useEffect(() => {
    if (fetchData && fetchData.length && fetchData[0].is_updated) {
      console.log("INSIDE USEEFFECT::");
      console.log(fetchData, "fetchData");

      if (fetchData && fetchData[0] && fetchData[0].verifcation_document) {
        const fileData = fetchData && fetchData[0].verifcation_document;
        console.log("fileData", fileData);

        console.log(fileData, "FILEDATA");
        const newFileData = JSON.parse(fileData);
        console.log(newFileData, "newFileData");
        const MappedData = newFileData.map((data) => data);
        console.log("MAPPEDDATA", MappedData);
        var jsondocs = MappedData;

        console.log("jsondocs: " + JSON.stringify(jsondocs));
        console.log("jsondocs.length(): " + jsondocs.length);
        var docsFromApi = [];

        let promises = [];
        for (var i = 0; i < jsondocs.length; i++) {
          var config = {
            method: "get",
            url: SERVER + "/upload/download_base_64/" + jsondocs[i].file_id,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          };

          promises.push(getfilebase64(config));
          if (promises.length === jsondocs.length) {
            Promise.all(promises).then((results) => {
              var certdoc = [];
              results.forEach((j, index) => {
                // console.log("jsondocs_inside: "+JSON.stringify(j));
                // console.log("jsondocs_inside index: "+index);

                var cert = {
                  ["name"]: jsondocs[index].file_name,
                  ["base_64_content"]: j.base_64_content,
                  ["contentType"]: j.contentType,
                };
                certdoc.push(cert);
              });
              console.log("CERTIFICATE", certdoc);
              setVerificationDoc(certdoc);
              // console.log("certdoc: "+JSON.stringify(certdoc));
            });
          }
        }
      }
    }
  }, [fetchData]);

  const getfilebase64 = (config) => {
    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          resolve(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const handleFileChange = (ev) => {
    console.log(ev.target.files);
    setLoadingFiles(true);

    if (ev.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !ev.target.files[0].type === "image/png" ||
      !ev.target.files[0].type === "image/jpeg" ||
      !ev.target.files[0].type === "image/jpg" ||
      !ev.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedFiles).concat(
        Array.from(ev.target.files)
      );

      setSelectedFiles(joined);
    }

    setLoadingFiles(false);
  };

  console.log(selectedFiles, "selectedFiles");

  const uploadDocumentCallUpdateBtn = () => {
    if (
      newSatID &&
      newMobileNumber &&
      remarks &&
      selectedFiles &&
      selectedFiles.length > 0
    ) {
      let promises = [];
      selectedFiles.forEach(async (i) => {
        var selectedblob = i;
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        var formdataobj = "";
        var fileName = "";
        reader.onloadend = await function (event) {
          fileName = selectedblob.name;
          var base64data = event.target.result;
          formdataobj = {
            file: base64data,
            fileType: fileName,
          };
          promises.push(uploadfile(formdataobj, fileName, userID));
          if (promises.length === selectedFiles.length) {
            Promise.all(promises).then((results) => {
              console.log("results of promise: ");
              console.log(results);

              var filelist = [];
              results.forEach((i) => {
                var filejson = {
                  file_id: i.image.fileId,
                  file_name: i.image.fileType,
                };
                filelist.push(filejson);
              });

              console.log("filelist: ", filelist);
              updateDetails(filelist);
            });
          }
        };
      });
    } else {
      showToast("ERROR", "Please fill all mandatory details");
    }
  };

  const removefile = (index, docname) => {
    setLoadingFiles(true);
    // console.log("selectedfiles: ", selectedfiles)
    selectedFiles.splice(index, 1);
    setTimeout(() => setLoadingFiles(false), 1);
  };

  console.log(verificationDocumentList, "verificatuonDOC");

  const updateDetails = (fileList) => {
    const payLoad = {
      department_user_id: userID,
      scheme_application_reference_no: fetchData && fetchData[0].applreference,
      record_id: recordID,
      new_mobile_no: newMobileNumber,
      sat_id: newSatID,
      remarks: remarks,
      verification_document: null,
    };

    console.log(payLoad, "PayLoad");

    const config = {
      method: "patch",
      url: `${SERVER}/schemes/education_scheme_status`,
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payLoad,
    };

    axios(config)
      .then((res) => {
        console.log(res.data.message);
        showToast("SUCCESS", "Updated Successfully");
        history.push("/admin/dashboard");
      })
      .catch((err) => {
        console.log(err);
        showToast("ERROR", "Please fill the mandatory details");
      });
  };

  const uploadfile = (data1, filename, userID) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", userID);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          console.log("FIle response", response.data);
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/dashboard-user'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='header.title' /> <br />
                  <Translate value='header.sub' />
                </h1>
              </div>
            </Col>

            {/* <Col xs={12} md={4} lg={3} style={{ display: "flex",position:'absolute',right:0,paddingTop:'25px'}}>
                            <div className="usericon-header-logout">
                                <img alt="..." src={usericon} className="usericon-img" />

                                <p>{users.user !== undefined && users.user !== null && users.user.first_name}</p>
                                <Tooltip title="Logout" placement="top-start" arrow interactive>
                                    <img alt="..." src={logout} className="logout-img" 
                                    onClick={() => history.push("/admin") }

                                    />
                                </Tooltip>
                            </div>
                        </Col> */}
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          {/* <h1>Schemes | {idName}</h1> */}
          <div
            onClick={() => history.push("/admin/dashboard/11")}
            className='cal-icon-hover-icn'
          >
            <img
              alt='...'
              className='cal-icon hover-icn'
              src={back}
              style={{ marginTop: "10px" }}
            />
          </div>
          <h1>
            {" "}
            Education Assistance Validation Form
            {/* {allValues.descriptionsKannada ? kannadaSchemeName : idName} */}
          </h1>
        </Col>
      </Row>
      {fetchData && fetchData.length ? (
        <>
          <Row className='scheme-subtitle-row mt-4'>
            <Col xs={12} className='searchform-subtitle'>
              <p>
                <Translate value='applicantDetails' />
              </p>
            </Col>
          </Row>
          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Name of the Beneficiary</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].NameofTheBeneficiary}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Registration Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].RegistrationNumber}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Reference Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].ApplicationReferenceNumber}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Scheme Reference Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].applreference}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Mobile Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].MobileNumber}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Applicant's Address</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].ApplicantAddress}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>District</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].District}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Taluk</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].Taluk}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Submission Location</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].SubmissionLocation}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Gender</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].Gender}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Registration</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={moment(fetchData[0].DateofRegistration).format(
                    "DD/MM/YYYY"
                  )}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>From Date</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={moment(fetchData[0].FromDate).format("DD/MM/YYYY")}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>To Date</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={moment(
                    fetchData[0].FromDateEnddateofregistration
                  ).format("DD/MM/YYYY")}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4}>
              <p className='mb-2'>Name of the child</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].NameofchildAsperaadhar}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Birth of the Child</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={moment(fetchData[0].DateofBirthofChild).format(
                    "DD/MM/YYYY"
                  )}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Age of the Child</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].AgeofChild}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Gender of the Child</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].childGender}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Current Course Studying</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].CurrentStudyingCourse}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>SAT ID</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].SatsIDStudentsID}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Bank Name</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].BankName}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Bank Branch</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].BankBranch}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>IFSC Code</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].IFSCCode}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Account Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={fetchData[0].AccountNumber}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Application Submission Date</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={moment(fetchData[0].applicationsubmissiondate).format(
                    "DD/MM/YYYY"
                  )}
                  disabled
                />
              </FormControl>
            </Col>
          </Row>
        </>
      ) : null}

      {fetchData && fetchData.length ? (
        <>
          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Validate / Update Details</p>
            </Col>
          </Row>
          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={6}>
              <p className='mb-2'>Updated SAT/STUDENT ID</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={newSatID}
                  onChange={(ev) => setNewSatID(ev.target.value)}
                  disabled={fetchData[0].is_updated ? true : false}
                />
              </FormControl>
            </Col>
            <Col xs={12} md={6}>
              <p className='mb-2'>Beneficiary Mobile Number</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={newMobileNumber}
                  onChange={(ev) => setNewMobileNumber(ev.target.value)}
                  disabled={fetchData[0].is_updated ? true : false}
                />
              </FormControl>
            </Col>
            <Col xs={12} md={6}>
              <p className='mb-2'>Remarks</p>

              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={remarks}
                  onChange={(ev) => setRemarks(ev.target.value)}
                  disabled={fetchData[0].is_updated ? true : false}
                />
              </FormControl>
            </Col>
            <>
              {/* {
                    fetchData[0].is_updated === 0 ? 
                <Col md={6} className="amount-sanctioned mt-2 bank-col-5">
                                            <Required className="mb-2" title="Verification Document" />
                                            <div className="browsebutton-outerdiv">
                                                <div className="browsebutton2-div filesNames">
                                                    {
                                                        loadingFiles ?
                                                            <>
                                                            </>
                                                            :

                                                            selectedFiles !== null &&
                                                            selectedFiles.length > 0 &&
                                                            Array.from(selectedFiles).map((i, ind) =>
                                                                <FormControl >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        name="verificationDocumentName"
                                                                        value={i.name}
                                                                        disabled
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="start">
                                                                                    <img alt="..." src={closeicon} className="removefilebtn"
                                                                                        onClick={() => removefile(ind)} />
                                                                                </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>

                                                            )

                                                    }

                                                </div>
                                                <div className="browsebutton2-div">
                                                    <label
                                                        htmlFor={`upload-photo`}
                                                        className="browse-button btn2 schemes">
                                                        <img alt="..." src={folder} />
                                                        <Translate value="browse" />
                                                    </label>
                                                  
                                                    <input type="file" name="photo" id={`upload-photo`}
                                                        onChange={(e) => handleFileChange(e)} 
                                                        multiple />

                                                </div>
                                            </div>

                                        </Col>

                                        : 

                                          <Col xs={12} md={6} className="amount-sanctioned mt-2 bank-col-5">
                                <p className="mb-0">Verification Document</p>
                                {
                                    Object.keys(verificationDoc).map((id, i) =>
                                        

                                        <>

                                            <div className="browsebutton2-div final">
                                                <FormControl>
                                                    <TextField
                                                        variant="outlined"
                                                        value={
                                                            verificationDoc !== undefined &&
                                                            verificationDoc.length > 0 &&
                                                            verificationDoc[i].name
                                                        }
                                                        style={{ width: "-webkit-fill-available" }}
                                                        disabled
                                                    />
                                                </FormControl>
                                                {verificationDoc[0].contentType !== "application/pdf" ?
                                                <label className="browse-button2"
                                                    onClick={(e) => handleClick(e, "verification", verificationDoc[i].base_64_content)}
                                            
                                                >
                                                    <Translate value="view" /> <Visibility />
                                                </label> : 
                                                <label className="browse-button2"
                                                    onClick={(e) => handleClick2(e, "verification", verificationDoc[i].base_64_content)}
                                                
                                                >
                                                    <Translate value="view" /> <Visibility />
                                                </label>
                                                }


                                            </div>

                                        </>

                                    )
                                }
                                {currentImgClick === 'verification' && <PopOverImage id={idVerification} open={openVerification} anchorEl={anchorElVerification} selected_document_type={selected_document_type} handleClose={handleClose} popImgCall={popImgCall} imgTitle="verification" />}
                            </Col>
                            } */}
            </>
          </Row>

          <Row className='search-subtitle-row pb-5'>
            <Col xs={12} className='next-back-button-row mt-4'>
              {fetchData[0].is_updated ? (
                <Button
                  onClick={() => history.push("/admin/dashboard/11")}
                  variant='outline-primary'
                  className='final-button-accept'
                >
                  Back
                </Button>
              ) : (
                <Button
                  onClick={updateDetails}
                  variant='outline-primary'
                  className='final-button-accept'
                >
                  Update
                </Button>
              )}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default EducationValidationForm;
