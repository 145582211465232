import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import StatusModal from '../StatusModal';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Asterick from 'components/Asterick';
import { validateAddHospital } from './schema/validateHospitalScheme';
import usePostApi from 'components/apiRequest/usePostApi';
import { headersWithToken } from 'http/customHeaders';
import { showToast } from 'utils/tools';
import { apiURL } from 'http/url';
import { SCHEMES_UNDER_MEDICAL } from 'assets/constants';

export default function AddHospitalModal(props) {
  const { schemeList, closeHandler } = props;
  const initialState = {
    name: '',
    schemeId: 0,
  };
  const { data, error, loading, postApiCall } = usePostApi({
    url: apiURL.hospitalAdd,
    method: 'post',
    options: { headers: headersWithToken() },
  });
  const { touched, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialState,
      validationSchema: validateAddHospital,
      onSubmit: (data) => {
        // console.log(data);
        postApiCall(data);
      },
    });
  useEffect(() => {
    if (data) {
      showToast('SUCCESS', 'Hospital Added Successfully');
      closeHandler();
    }
  }, [data]);
  return (
    <StatusModal>
      <form onSubmit={handleSubmit}>
        <StatusModal.Title
          render={() => {
            return (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography textAlign={'center'}>
                  Add Hospital to Scheme
                </Typography>
                <IconButton
                  onClick={closeHandler}
                  sx={{ position: 'absolute', right: -15, top: -10 }}
                >
                  <Close />
                </IconButton>
              </Box>
            );
          }}
        ></StatusModal.Title>
        <StatusModal.Content
          render={() => {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <InputLabel>
                  Select Scheme
                  <Asterick />
                </InputLabel>
                <Autocomplete
                  name="schemeId"
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  options={
                    schemeList?.data
                      .filter((item) => SCHEMES_UNDER_MEDICAL.includes(item.id))
                      .filter((item) => item.isactive) ?? []
                  }
                  sx={{ minWidth: '50%' }}
                  onChange={(_, newValue) => {
                    //
                    if (newValue) setFieldValue('schemeId', newValue.id);
                  }}
                  helperText={touched.schemeId && errors.schemeId}
                  renderInput={(params) => (
                    <TextField
                      placeholder={'Select Scheme'}
                      sx={{
                        mb: 2,
                        '& .MuiInputBase-root': {
                          px: '0 !important',
                          py: '0 !important',
                          lineHeight: 2,
                          border: '1px solid black',
                          // borderRadius: 4,
                          borderRadius: '2px !important',
                        },
                      }}
                      error={errors.schemeId}
                      helperText={touched.schemeId && errors.schemeId}
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
                <InputLabel>
                  Name of Hospital
                  <Asterick />
                </InputLabel>
                <TextField
                  placeholder="Name of Hospital"
                  name="name"
                  sx={{
                    width: '100%',
                    border: '1px solid black',
                    borderRadius: 1,
                    mb: 2,
                  }}
                  variant="outlined"
                  onChange={handleChange}
                  error={errors.name}
                  helperText={touched.name && errors.name}
                ></TextField>
              </Box>
            );
          }}
        ></StatusModal.Content>
        <StatusModal.Actions
          render={() => (
            <>
              <Button variant="contained" color="info" onClick={closeHandler}>
                Back
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                disabled={loading}
              >
                Submit
              </Button>
            </>
          )}
        ></StatusModal.Actions>
      </form>
    </StatusModal>
  );
}
