import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, TableFooter, TablePagination, makeStyles, useTheme, FormControl, TextField, InputAdornment, MenuItem, ListItemText, Select, FormControlLabel, RadioGroup, Radio, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import search from 'assets/images/Icon ionic-ios-search.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import prev from 'assets/images/Icon ionic-ios-arrow-dropright-circle.svg'
// import closeIcon from '@material-ui/icons/Close';
import closeIcon from 'assets/images/close-01.svg'
import next from 'assets/images/Icon ionic-ios-arrow-dropright-circle (1).svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getConfigMinistryDetails, getLabourStatusDetails, getStateMapping, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { getTokenCookie, Required } from 'utils/tools';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';


const CircleMaster = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        searchValue: "",
        ministry_id_selected: null,
        board_id_selected: null,

    })

    const [rows, setrows] = React.useState("");
    const [rowsBackup, setrowsBackup] = React.useState("");


    useEffect(() => {
        callget_catalog_master()

        dispatch(deleteReduxData("getDistrictDetails"))
        dispatch(deleteReduxData("getTalukDetails"))
        dispatch(deleteReduxData("getCityDetails"))
        dispatch(deleteReduxData("getVillageDetails"))


    }, []);

    function callget_catalog_master() {
        var data = '';

        var board_id_selected_here = allValues.board_id_selected
        if(allValues.board_id_selected === null){
            board_id_selected_here = 0
        }

        if(allValues.ministry_id_selected === "1"){
            board_id_selected_here = 0
        }

        var config = {
        method: 'get',
        url: `${SERVER}/config/catalog/get_catalog_master?ministry_id=${allValues.ministry_id_selected}&board_id=${ board_id_selected_here }`,
        // url: SERVER+'/config/circle/get_circle_details',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenCookie()}`
        },
        data : data
        };

        axios(config)
        .then(function (response) {
        console.log(JSON.stringify(response.data, undefined,2))
        // setAllValues({
        //     ...allValues,
        //     get_circle_details: response.data.data
        // })
        setrows(response.data.data)
        setrowsBackup(response.data.data)
        })
        .catch(function (error) {
        console.log(error);
        });
    }


    useEffect(() => {
        console.log(JSON.stringify(allValues, undefined,2))
    }, [allValues]);


    useEffect(() => {
        setrows("")
        callget_catalog_master()
    }, [allValues.ministry_id_selected]);

    useEffect(() => {
        setrows("")
        callget_catalog_master()
    }, [allValues.board_id_selected]);


    useEffect(() => {

        if(rows!==undefined && allValues.searchValue !== ""){
            console.log("searchValue filter item:")

            // console.log(rows.filter(row => row.name.toLowerCase().includes(allValues.searchValue.toLowerCase())))

            setrows(rows.filter(row => 
                
                    row.name.toLowerCase().includes(allValues.searchValue.toLowerCase()) ? 
                    row.name.toLowerCase().includes(allValues.searchValue.toLowerCase()) : 
                    row.code.toLowerCase().includes(allValues.searchValue.toLowerCase())
                
                )
                
                )
        }
        else{
            setrows(rowsBackup)
        }

    }, [allValues.searchValue]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var emptyRows = ""

    if(rows !== undefined && rows.length > 0){
        emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event) => {
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value
        })
    }

    const useStyles1 = makeStyles((theme) => ({
        root: {
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        },
      }));

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;
      
        // const handleFirstPageButtonClick = (event) => {
        //   onChangePage(event, 0);
        // };
      
        const handleBackButtonClick = (event) => {
          onChangePage(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onChangePage(event, page + 1);
        };
      
        // const handleLastPageButtonClick = (event) => {
        //   onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        // };
      
        return (
          <div className={classes.root}>
            {/* <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton> */}
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {/* <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton> */}
          </div>
        );
      }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <a href="/admin/catalog-master-form">
                                <div className="add-div">
                                <img alt="..." className="cal-icon" src={add}/>
                                <p>Add Catalog</p>
                                </div>
                            </a>
                        </Col>
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                    <Col xs={8} className="title-col">
                        <Link to="#" onClick={() => props.history.goBack()}>
                            <img alt="..." className="cal-icon hover-icn" src={back}/>
                        </Link>
                            <p className="title-p">Catalog Master</p>
                    </Col>
                </Row>
                <Row className="config-page-row-title nextrow">
                <Col xs={12} md={4} className="personal-col-1">
                    <FormControl className="interstateRadio formcontrol9" 
                        variant="outlined" fullWidth component="fieldset">
                        <RadioGroup row name="ministry_id_selected" value={allValues.ministry_id_selected} onChange={handleChange}>
                            <p className="mb-2" style={{ flexGrow: 1, alignSelf: "center"}}>
                                Ministry Level
                            </p>
                            <FormControlLabel value="1" control={<Radio />} label={<Translate value="yes"/>} />
                            <FormControlLabel value="0" control={<Radio />} label={<Translate value="no"/>} />
                        </RadioGroup>
                    </FormControl>
                </Col>
                </Row>

                {
                    allValues.ministry_id_selected !== null && 
                    allValues.ministry_id_selected !== "1" ?

                    <Row className="config-page-row-title nextrow">
                        <Col xs={12} md={4} className="personal-col-1">
                            <FormControl fullWidth className="formcontrol1">
                                <Select
                                    className="select-marital pl-2"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.board_id_selected}
                                    name="board_id_selected"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null}>
                                        <ListItemText primary="- Select Board - " />
                                    </MenuItem>
                                    <MenuItem value="1">
                                        <ListItemText primary='KBOCWWB' />
                                    </MenuItem>
                                    <MenuItem value="4">
                                        <ListItemText primary='KSUWSSB' />
                                    </MenuItem>
                                    {/* {
                                        allValues.state_details !== undefined &&
                                        allValues.state_details !== null &&
                                        allValues.state_details !== "" &&
                                        allValues.state_details.map((i) =>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )} */}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    :
                    null
                }

                <Row className="config-page mt-3">
                    
                    <Col xs={12} className="table-col">

                    <TableContainer 
                    component={Paper}
                    >
                        <Table 
                            className="config-table"
                            aria-label="simple table"
                            >
                             <TableHead>
                                <TableRow>
                                    <TableCell align="right" width="190px">
                                        <Link to="/admin/home">
                                            <img alt="..." className="cal-icon hover-icn" src={home}/>
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left" width="240px">Name</TableCell>
                                    <TableCell align="center">Board</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                    <TableCell width="200pxpx"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                rows !== undefined &&
                                rows !== "" &&
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell></TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="center">
                                            {row.board_id === null ? "NULL":row.board_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link to ={{ 
                                                    pathname: "/admin/catalog-value", 
                                                    rowdata: row,
                                                    }}
                                                className="td-link mr-2"
                                            >
                                                View Catalog
                                            </Link>
                                            |
                                            <Link to ={{ 
                                                    pathname: "/admin/catalog-master-form", 
                                                    rowdata: row,
                                                    }}
                                                className="td-link ml-2"
                                            >
                                                Edit <img alt="..." src={Edit} className="ml-2"/>
                                            </Link>


                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}

                                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )} */}
                            </TableBody>
                           
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                    className="table-pagination-footer"
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    count={rows!==undefined && rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={""}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    />
                                    
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                    </Col>
                            
                </Row>
        </>
    )
}

export default CircleMaster;