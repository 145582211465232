import React, { useContext, useEffect, useRef, useState } from 'react';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import RemarksOrder from './RemarksOrder';
import { showToast } from 'utils/tools';
import { Button, Col, Row } from 'react-bootstrap';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import Transaction from './Transaction/Transaction';
import { MedicalTransaction } from './Transaction/SchemeWiseTransaction/MedicalTransaction';
import { ThayiMaguTransaction } from './Transaction/SchemeWiseTransaction/ThayiMaguTransaction';
import { useSelector } from 'react-redux';
import { getCircleAPI } from '../services/circle.service';
import { TransferSchemeAPI } from '../services/transferScheme.service';
import { documentUploadTransferBack } from '../services/transferBack.service';
import { draftSchemeApprove } from '../services/draftScheme.service';
import {
  getSchemeInfoAPI,
  rejectReasonDraftAPI,
  rejectedReasonsAPI,
  schemeStatusAPI,
} from '../services/schemes.service';
import { FormControl, TextareaAutosize } from '@material-ui/core';
import { ApproveSchemeAPI } from '../services/approveScheme.service';
import SanctionedAmount from './SanctionedAmount';
import { DeliveryTransaction } from './Transaction/SchemeWiseTransaction/DeliveryTransaction';
import {
  ApproveRejectValidator,
  DraftValidator,
  ForwardValidator,
  MedicalValidator,
} from './Transaction/SchemeValidator/MedicalValidator';
import {
  DeliveryApproveRejectValidator,
  DeliveryDraftValidator,
  DeliveryForwardValidator,
} from './Transaction/SchemeValidator/DeliveryValidator';
import {
  MajorAilmentsApproveRejectValidator,
  MajorAilmentsDraftValidator,
  MajorAilmentsForwardValidator,
} from './Transaction/SchemeValidator/MajorAilments';
import { MajorAilmentsTransaction } from './Transaction/SchemeWiseTransaction/MajorAilmentsTransaction';
import {
  MarriageApproveRejectValidator,
  MarriageDraftValidator,
  MarriageForwardValidator,
} from './Transaction/SchemeValidator/MarriageValidator';
import { MarriageTransaction } from './Transaction/SchemeWiseTransaction/MarriageTransaction';
import {
  PensionApproveRejectValidator,
  PensionDraftValidator,
  PensionForwardValidator,
} from './Transaction/SchemeValidator/PensionValidator';
import {
  ContinuationPensionApproveRejectValidator,
  ContinuationPensionDraftValidator,
} from './Transaction/SchemeValidator/ContinuationPensionValidator';
import {
  DisabilityApproveRejectValidator,
  DisabilityDraftValidator,
  DisabilityForwardValidator,
} from './Transaction/SchemeValidator/DisabilityPensionValidator';
import { DisabilityTransaction } from './Transaction/SchemeWiseTransaction/DisabilityTransaction';
import {
  ContinuationDisabilityApproveRejectValidator,
  ContinuationDisabilityDraftValidator,
} from './Transaction/SchemeValidator/ContinuationDisabilityValidator';
import {
  ThayiMaguApproveRejectValidator,
  ThayiMaguDraftValidator,
  ThayiMaguForwardValidator,
} from './Transaction/SchemeValidator/ThayiMaguValidator';
import {
  AccidentApproveRejectValidator,
  AccidentDraftValidator,
  AccidentForwardValidator,
} from './Transaction/SchemeValidator/AccidentValidator';
import { AccidentTransaction } from './Transaction/SchemeWiseTransaction/AccidentTranscation';
import {
  FuneralApproveRejectValidator,
  FuneralDraftValidator,
  FuneralForwardValidator,
} from './Transaction/SchemeValidator/FuneralValidator';
import { FuneralTransaction } from './Transaction/SchemeWiseTransaction/FuneralTransaction';
import { missingDocumentUpload } from './DocumentUpload/missingDocument.service';
import ConfirmationDialog from 'components/adminPortal/globals/ConfirmationDialog';
import { DataContext } from 'context/config';
import { set } from 'date-fns';
import { radioClasses } from '@mui/material';

const SchemeTransactions = ({ fields, setLoading, status, loading }) => {
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const {
    labourUserID,
    schemeAvailID,
    schemeID,
    schemeStatus,
    schemeName,
    schemeTransactionID,
    approvalAuthority,
    renewalSequence,
  } = useParams();

  const { data: configData } = useContext(DataContext);

  const deptUserID =
    users &&
    users.getDeptUserRole &&
    users.getDeptUserRole.data &&
    users.getDeptUserRole.data[0].department_user_id;

  const roleID =
    users &&
    users.getDeptUserRole.data &&
    users.getDeptUserRole.data[0].role_id;

  const [verificationDoc, setVerificationDoc] = useState([]);
  const [verificationDocID, setVerificationDocID] = useState(null);
  const verificationDocRef = useRef(null);

  const [missingDoc, setMissingDoc] = useState([]);
  const [missingDocID, setMissingDocID] = useState(null);
  const missingDocRef = useRef(null);

  const [missingDocSecondLevel, setMissingDocSecondLevel] = useState([]);
  const [missingDocSecondLevelID, setMissingDocSecondLevelID] = useState(null);
  const missingDocSecondLevelRef = useRef(null);

  const [remarks, setRemarks] = useState(null);
  const [order, setOrder] = useState(null);
  const [applicationNoByLi, setApplicationNoByLi] = useState(null);
  const [applicationNoByLiValue, setApplicationNoByLiValue] = useState(null);
  const [reasonByLi, setReasonByLi] = useState(null);

  console.log(reasonByLi, 'reasonByLi');

  const [reasonByLiValue, setReasonByLiValue] = useState(null);
  const [forwardedDate, setForwardedDate] = useState(null);
  const [approveRejectDate, setApproveRejectDate] = useState(null);

  const [opentransfer, setopentransfer] = useState(false);
  const [transferRemarks, setTransferRemarks] = useState(null);
  const [transferBackRemarks, setTransferBackRemarks] = useState(null);
  const [circle, setCircle] = useState(null);
  const [circleID, setCircleID] = useState(null);

  const [openRejection, setOpenRejection] = useState(false);
  const [toggleLang, setToggleLang] = useState(false);

  const [amount, setAmount] = useState(null);

  const [verificationDocument, setVerificationDocument] = useState(null);
  const [missingDocument, setMissingDocument] = useState(null);
  const [secondLevelMissingDocument, setSecondLevelMissingDocument] =
    useState(null);

  const [amountLimit, setAmountLimit] = useState(null);
  const [treatmentAmount, setTreatmentAmount] = useState(null);

  const [rejectReasons, setRejectReasons] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [accidentResultedIn, setAccidentResultedIn] = useState(null);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [confirmationType, setConfirmationType] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [confirmationNote, setConfirmationNote] = useState(null);

  const hideButtons =
    schemeName === 'Continuation of Pension' ||
    schemeName === 'Continuation of Disability Pension';

  const wordLimit = 200;

  const callCircle = async () => {
    const result = await getCircleAPI();

    setCircle(result.data);
  };

  const callSchemesInfo = async () => {
    try {
      const response = await getSchemeInfoAPI();
      if (response.success) {
        const singleScheme = response.data.filter(
          (_data) => _data.name === schemeName,
        );

        setAmountLimit(singleScheme && singleScheme[0].max_sanction_ammount);
      }
    } catch (error) {
      // console.log(callSchemesInfo);
      showToast('ERROR', 'Something went wrong');
    }
  };

  const callSchemeStatus = async (schemeAvailID) => {
    try {
      const result = await schemeStatusAPI(schemeAvailID);

      setVerificationDocument(
        result.data.scheme_approvalLevel_status[0].verification_document_id,
      );
      setMissingDocument(
        result.data.scheme_approvalLevel_status[0].missing_document_id,
      );
      setSecondLevelMissingDocument(
        result?.data?.scheme_approvalLevel_status[1]
          ?.final_level_missing_document_id,
      );

      setRemarks(result.data.scheme_approvalLevel_status[0].description);
      setApplicationNoByLi(result.data.scheme_approvalLevel_status[0].application_no_by_li);
      setReasonByLi(result.data.scheme_approvalLevel_status[0].reason_by_li);

      setForwardedDate(
        result.data.scheme_approvalLevel_status[0].forwarded_date,
      );
      const secondLevelApprovalDetails =
        result.data.scheme_approvalLevel_status.filter(
          (_data) => _data.is_final === true,
        );

      // const draftRejectReasons =
      // result.data.rejectionReason.filter(
      //   (_data) => _data.id === true
      // )

      if (
        schemeStatus === 'Draft Approved' ||
        schemeStatus === 'Draft Rejected'
      ) {
        setAmount(
          secondLevelApprovalDetails &&
          secondLevelApprovalDetails[0].draft_appr_sanctioned_Amount,
        );
        setOrder(
          secondLevelApprovalDetails &&
          secondLevelApprovalDetails[0].draft_appr_rej_order_remarks,
        );
      } else if (schemeStatus === 'Approved' || schemeStatus === 'Rejected') {
        setOrder(
          secondLevelApprovalDetails &&
          secondLevelApprovalDetails[0].order_remarks,
        );
        setAmount(
          secondLevelApprovalDetails &&
          secondLevelApprovalDetails[0].sanctioned_Amount,
        );
        setApproveRejectDate(
          secondLevelApprovalDetails &&
          secondLevelApprovalDetails[0].approved_rejected_date,
        );
      } else if (schemeStatus === 'Transferred Scheme Application') {
        setTransferRemarks(
          result.data.scheme_approvalLevel_status[0].transfer_reason_remarks,
        );
      } else if (schemeStatus === 'Transferred back Scheme Application') {
        setTransferRemarks(
          result.data.scheme_approvalLevel_status[0].transfer_reason_remarks,
        );
        setTransferBackRemarks(
          result.data.scheme_approvalLevel_status[0]
            .transfer_back_reason_remarks,
        );
        setVerificationDocument(
          result.data.scheme_approvalLevel_status[0]
            .transfer_back_document_details,
        );
      }
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  const callRejectDraftReason = async (schemeAvailID, reasonType) => {
    try {
      const result = await rejectReasonDraftAPI(schemeAvailID, reasonType);
      const initialSelectedOptions = result.data.map((item) => ({
        value: item.id.toString(),
      }));
      setSelectedOptions(initialSelectedOptions);
      //console.log(result, 'Draft_reject_reasons');
    } catch (err) {
      console.log(err);
    }
  };

  const callRejectReasons = async (schemeID) => {
    const result = await rejectedReasonsAPI(schemeID);

    setRejectReasons(result.data);
  };


  useEffect(() => {
    if (
      schemeStatus === 'Draft Approved' ||
      schemeStatus === 'Draft Rejected' ||
      schemeStatus === 'Pending' ||
      schemeStatus === 'Forwarded'
    ) {
      callSchemesInfo();
    }
  }, [schemeStatus]);

  useEffect(() => {
    if (schemeID) {
      callRejectReasons(schemeID);
    }
  }, [schemeID]);

  useEffect(() => {
    if (schemeStatus === 'Pending') {
      callCircle();
    }
  }, [schemeStatus]);

  useEffect(() => callSchemeStatus(schemeAvailID), [schemeAvailID]);

  useEffect(() => callRejectDraftReason(schemeAvailID), [schemeAvailID]);

  useEffect(() => {
    if (
      (schemeName === 'Medical Assistance\r\n' ||
        schemeName === 'Medical Assistance') &&
      fields
    ) {
      const fieldValues = fields.filter(
        (_data) => _data.field === 'Cost of treatment',
      );
      setTreatmentAmount(
        fieldValues && fieldValues.length > 0 && fieldValues[0]?.field_value,
      );
    } else if (schemeName === 'Assistance For Major Ailments' && fields) {
      const fieldValues = fields.filter(
        (_data) => _data.field === 'Cost of treatment',
      );
      setTreatmentAmount(
        fieldValues && fieldValues.length > 0 && fieldValues[0].field_value,
      );
    } else if (schemeName === 'Disability Pension' && fields) {
      const fieldValues = fields.filter(
        (_data) => _data.field === 'Cost of treatment',
      );
      setTreatmentAmount(
        fieldValues && fieldValues.length > 0 && fieldValues[0].field_value,
      );
    } else if (schemeName === 'Accident Assistance' && fields) {
      const fieldValues = fields.filter(
        (_data) => _data.field === 'Accident Resulted In',
      );
      setAccidentResultedIn(
        fieldValues && fieldValues.length > 0 && fieldValues[0].field_value,
      );
    }
  }, [fields, schemeName]);

  const handleCloseTransfer = () => {
    setopentransfer(false);
    setTransferRemarks('');
    setCircleID(null);
  };

  const handleTransferChange = (ev) => {
    if (ev.target.name === 'remarkTransfer') {
      setTransferRemarks(ev.target.value);
    } else if (ev.target.name === 'circleID') {
      setCircleID(ev.target.value);
    }
  };

  //Handle Rejection

  const handleCloseRejection = () => {
    setOpenRejection(false);
  };

  const handleToggle = () => {
    setToggleLang(!toggleLang);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // If checked, add the option to selectedOptions, else remove it
    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, { value }]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option.value !== value),
      );
    }
  };

  const handleSubmitReject = () => {
    if (selectedOptions && selectedOptions.length > 0) {
      const rejectReason = selectedOptions.map((option) => ({
        id: +option.value,
      }));
      handleApproveRejectTransaction('Reject', rejectReason);
    } else {
      showToast('ERROR', 'Please select atleast any one of the reason');
    }
  };
  const handleDraftSubmitReject = () => {
    if (selectedOptions && selectedOptions.length > 0) {
      const rejectReason = selectedOptions.map((option) => ({
        id: +option.value,
      }));
      handleDraftTransaction('DraftReject', rejectReason);
    } else {
      showToast('ERROR', 'Please select atleast any one of the reason');
    }
  };

  const handleOpenRejectionDialog = () => {
    if (order) {
      //console.log('Opening dialog...');
      setOpenRejection(true);
    } else {
      showToast('ERROR', 'Please fill the order');
    }
  };

  const handleVerificationDoc = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...verificationDoc, ...ev.target.files];
        setVerificationDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveVerificationDoc = (index) => {
    const newFiles = [...verificationDoc];
    newFiles.splice(index, 1);
    setVerificationDoc(newFiles);
    verificationDocRef.current.value = '';
  };

  const handleMissingDoc = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...missingDoc, ...ev.target.files];
        setMissingDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleMissingSecondLevelDoc = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...missingDocSecondLevel, ...ev.target.files];
        setMissingDocSecondLevel(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveMissingDoc = (index) => {
    const newFiles = [...missingDoc];
    newFiles.splice(index, 1);
    setMissingDoc(newFiles);
    missingDocRef.current.value = '';
  };

  const handleRemoveMissingDocSecondLevel = (index) => {
    const newFiles = [...missingDocSecondLevel];
    newFiles.splice(index, 1);
    setMissingDocSecondLevel(newFiles);
    missingDocSecondLevelRef.current.value = '';
  };

  const handleRemarks = (ev) => {
    setRemarks(ev.target.value);
  };

  const handleApplicationNoByLi = (ev) => {
    setApplicationNoByLiValue(ev.target.value);
  };

  const handleReasonByLi = (ev) => {
    setReasonByLiValue(ev.target.value);
  };

  const handleTransferBackRemarks = (ev) => {
    setTransferBackRemarks(ev.target.value);
  };

  const handleOrder = (ev) => {
    setOrder(ev.target.value);
  };

  const handleClickTransfer = () => {
    console.log(applicationNoByLiValue, reasonByLiValue, 'values***')
    if (
      verificationDoc &&
      verificationDoc.length === 0 &&
      missingDoc &&
      missingDoc.length === 0 &&
      applicationNoByLiValue === null &&
      reasonByLiValue === null
    ) {
      setopentransfer(true);
    } else if (verificationDoc && verificationDoc.length > 0) {
      showToast(
        'ERROR',
        'Please remove verification document while transferring',
      );
    } else if (missingDoc && missingDoc.length > 0) {
      showToast('ERROR', 'Please remove missing document while transferring');
    } else if (applicationNoByLiValue) {
      showToast(
        'ERROR',
        'Please Select No for Application Number',
      );
    } else if (reasonByLiValue) {
      showToast(
        'ERROR',
        'Please Select Yes for Reasons while transferring',
      );
    }
  };

  const handleForwardTransaction = async () => {
    const data = {
      status: 'Forward',
      labourUserID,
      deptUserID,
      schemeTransactionID,
      order,
      remarks,
      verificationDoc,
      verificationDocument,
      missingDoc,
      amount,
      renewalSequence,
    };

    if (
      schemeName === 'Medical Assistance\r\n' ||
      schemeName === 'Medical Assistance'
    ) {
      const validationResult = ForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await MedicalTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Delivery Assistance') {
      const validationResult = DeliveryForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await DeliveryTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Assistance For Major Ailments') {
      const validationResult = MajorAilmentsForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await MajorAilmentsTransaction(
              validationResult.data,
            );

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Marriage Assistance') {
      const validationResult = MarriageForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await MarriageTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Pension Scheme') {
      const validationResult = PensionForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await MarriageTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Disability Pension') {
      const validationResult = DisabilityForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await DisabilityTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
      const validationResult = ThayiMaguForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            const response = await ThayiMaguTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Accident Assistance') {
      // console.log(applicationNoByLi, reasonByLi, 'reasonByLi');
      const validationResult = AccidentForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                missingDocumentResponse,
                null,
                applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No',
                reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes',
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                verificationDocument,
                [],
                null,
                applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No',
                reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes',
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            validationResult.data.applicationNoByLi = applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No';
            validationResult.data.reasonByLi = reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes';
            const response = await AccidentTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Funeral Expense and Death Assistance') {
      const validationResult = FuneralForwardValidator(data);
      if (validationResult.success) {
        setLoading(true);
        if (schemeStatus === `Transferred back Scheme Application`) {
          try {
            if (missingDoc && missingDoc.length > 0) {
              const missingDocumentResponse =
                await missingDocumentUpload(missingDoc);
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No',
                reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes',
                verificationDocument,
                missingDocumentResponse,
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            } else {
              const result = await ApproveSchemeAPI(
                'Forward',
                labourUserID,
                deptUserID,
                schemeTransactionID,
                order,
                remarks,
                applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No',
                reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes',
                verificationDocument,
                [],
                null,
              );

              if (result.success) {
                setLoading(false);
                showToast('SUCCESS', 'Scheme Forwarded Successfully');
                history.goBack();
              }
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        } else {
          try {
            validationResult.data.applicationNoByLi = applicationNoByLi ? applicationNoByLi : applicationNoByLiValue ? applicationNoByLiValue : 'No';
            validationResult.data.reasonByLi = reasonByLi ? reasonByLi : reasonByLiValue ? reasonByLiValue : 'Yes';

            console.log(applicationNoByLi, reasonByLi, 'applicationNoByLi, reasonByLi');
            const response = await FuneralTransaction(validationResult.data);

            if (response.success) {
              setLoading(false);
              showToast('SUCCESS', 'Scheme Forwarded Successfully');
              history.goBack();
            }
          } catch (error) {
            setLoading(false);
            showToast('ERROR', 'Something went wrong');
          }
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    }
  };

  const handleDraftTransaction = async (from, rejectReason) => {
    if (
      schemeName === 'Medical Assistance\r\n' ||
      schemeName === 'Medical Assistance'
    ) {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount,
        amountCost,
        amountLimit,
        rejectReasons,
      };
      const validationResult = DraftValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +amount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };

          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Delivery Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = DeliveryDraftValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Assistance For Major Ailments') {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const treatmentType = fields.find(
        (field) => field.field_id === 108,
      )?.field_value_id;

      console.log(
        treatmentType,
        configData?.otherHospitalIdForMajorMedicalScheme,
      );
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount:
          configData?.otherHospitalIdForMajorMedicalScheme &&
            (treatmentType == 136 || treatmentType == 139)
            ? 500000
            : sanctionedAmount,
        amountCost,
        amountLimit:
          configData?.otherHospitalIdForMajorMedicalScheme &&
            (treatmentType == 136 || treatmentType == 139)
            ? 500000
            : amountLimit,
        rejectReasons,
      };
      const validationResult = MajorAilmentsDraftValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +amount,

            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Marriage Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = MarriageDraftValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Pension Scheme') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = PensionDraftValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Continuation of Pension') {
      const sanctionedAmount = amountLimit;
      const data = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = ContinuationPensionDraftValidator(data);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Disability Pension') {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount,
        amountCost,
        amountLimit,
        rejectReasons,
      };
      const validationResult = DisabilityDraftValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +amount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Continuation of Disability Pension') {
      const sanctionedAmount = amountLimit;
      const data = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = ContinuationDisabilityDraftValidator(data);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = ThayiMaguDraftValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Accident Assistance') {
      const enteredAmount = parseInt(amount);

      let sanctionedAmount = '';
      if (accidentResultedIn === 'Death') {
        sanctionedAmount = amountLimit;
      } else if (accidentResultedIn === 'Permanent Partial Disablement') {
        sanctionedAmount = 100000;
      } else if (accidentResultedIn === 'Permanent Total Disablement') {
        sanctionedAmount = 200000;
      }
      const dataToValidate = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        accidentResultedIn,
        sanctionedAmount,
        enteredAmount,
        rejectReasons,
      };
      const validationResult = AccidentDraftValidator(dataToValidate);

      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount:
              accidentResultedIn === 'Death' ? amountLimit : +amount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };

          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Funeral Expense and Death Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        rejectReasons,
      };
      const validationResult = FuneralDraftValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          const draftPayLoad = {
            boardId: 1,
            departmentUserId: +deptUserID,
            availedTransactionId: +schemeTransactionID,
            statusId:
              from === 'DraftApprove'
                ? 1152
                : from === 'DraftReject'
                  ? 1153
                  : null,
            order: order,
            remarks: null,
            sanctionedAmount: +sanctionedAmount,
            rejectionReason: from === 'DraftApprove' ? [] : rejectReason,
          };
          const response = await draftSchemeApprove(draftPayLoad);
          if (response.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Draft Saved Successfully');
            history.goBack();
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    }
  };

  const handleApproveRejectTransaction = async (from, rejectReason) => {
    if (
      schemeName === 'Medical Assistance\r\n' ||
      schemeName === 'Medical Assistance'
    ) {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount,
        amountCost,
        amountLimit,
        rejectReason,
      };
      const validationResult = ApproveRejectValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Delivery Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult = DeliveryApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);

        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Assistance For Major Ailments') {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const treatmentType = fields.find(
        (field) => field.field_id === 108,
      )?.field_value_id;
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount:
          configData?.otherHospitalIdForMajorMedicalScheme &&
            (treatmentType == 136 || treatmentType == 139)
            ? 500000
            : sanctionedAmount,
        amountCost,
        amountLimit:
          configData?.otherHospitalIdForMajorMedicalScheme &&
            (treatmentType == 136 || treatmentType === 139)
            ? 500000
            : amountLimit,
      };
      const validationResult = MajorAilmentsApproveRejectValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Marriage Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult = MarriageApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Pension Scheme') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult = PensionApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Continuation of Pension') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult =
        ContinuationPensionApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Disability Pension') {
      const enteredAmount = parseInt(amount);
      const sanctionedAmount = parseInt(amountLimit);
      const amountCost = parseInt(treatmentAmount);
      const medicalData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        amount,
        enteredAmount,
        sanctionedAmount,
        amountCost,
        amountLimit,
      };
      const validationResult = DisabilityApproveRejectValidator(medicalData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              amount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Continuation of Disability Pension') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult =
        ContinuationDisabilityApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);

        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
      };
      const validationResult = ThayiMaguApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              renewalSequence,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              rejectReason,
              renewalSequence,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          setLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Accident Assistance') {
      const enteredAmount = parseInt(amount);
      let sanctionedAmount = '';
      if (accidentResultedIn === 'Death') {
        sanctionedAmount = amountLimit;
      } else if (accidentResultedIn === 'Permanent Partial Disablement') {
        sanctionedAmount = 100000;
      } else if (accidentResultedIn === 'Permanent Total Disablement') {
        sanctionedAmount = 200000;
      }
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        accidentResultedIn,
        enteredAmount,
        sanctionedAmount,
        applicationNoByLi,
        reasonByLi,
      };
      const validationResult = AccidentApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              accidentResultedIn === 'Death' ? amountLimit : +amount,
              applicationNoByLi,
              reasonByLi,
              rejectReason,
              0,
              missingDocumentResponse,
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              accidentResultedIn === 'Death' ? amountLimit : +amount,
              applicationNoByLi,
              reasonByLi,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          setLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    } else if (schemeName === 'Funeral Expense and Death Assistance') {
      const sanctionedAmount = amountLimit;
      const deliveryData = {
        from,
        labourUserID,
        deptUserID,
        schemeTransactionID,
        order,
        remarks,
        verificationDoc,
        missingDoc,
        sanctionedAmount,
        applicationNoByLi,
        reasonByLi,
      };
      const validationResult = FuneralApproveRejectValidator(deliveryData);
      if (validationResult.success) {
        setLoading(true);
        try {
          if (missingDocSecondLevel && missingDocSecondLevel.length > 0) {
            const missingDocumentResponse = await missingDocumentUpload(
              missingDocSecondLevel,
            );
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              applicationNoByLi,
              reasonByLi,
              rejectReason,
              0,
              missingDocumentResponse,

            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          } else {
            const response = await ApproveSchemeAPI(
              from,
              labourUserID,
              deptUserID,
              schemeTransactionID,
              order,
              remarks,
              verificationDoc,
              missingDoc,
              sanctionedAmount,
              applicationNoByLi,
              reasonByLi,
              rejectReason,
              0,
              [],
            );
            if (response.success) {
              setLoading(false);
              showToast(
                'SUCCESS',
                `${from === 'Approve'
                  ? 'Scheme Approved Successfully'
                  : 'Scheme Rejected Successfully'
                } `,
              );
              history.goBack();
            }
          }
        } catch (error) {
          setLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', `${validationResult.msg}`);
      }
    }
  };

  const handleSubmitTransfer = () => {
    if (+circleID !== 0) {
      if (transferRemarks) {
        setConfirmationDialog(true);
        setConfirmationType('Transfer Scheme Application');
        setConfirmationMessage('transfer');

        // handleTransferTransaction();
      } else {
        showToast('ERROR', 'Please fill remarks');
      }
    } else {
      showToast('ERROR', 'Please select circle');
    }
  };

  const handleTransferTransaction = async () => {
    setLoading(true);
    try {
      const payLoad = {
        availedId: +schemeAvailID,
        availedTransactionId: +schemeTransactionID,
        transferRole: +roleID,
        remarks: transferRemarks,
        transferCircle: +circleID,
        departmentUserId: +deptUserID,
      };
      const result = await TransferSchemeAPI(payLoad);

      if (result.success) {
        setLoading(false);
        showToast('SUCCESS', 'Scheme Transferred Successfully');
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      showToast('ERROR', 'Something went wrong');
    }
  };

  const handleTransferBackTransaction = async () => {
    if (verificationDoc && verificationDoc.length > 0) {
      if (transferBackRemarks) {
        setLoading(true);
        try {
          const result = await documentUploadTransferBack(
            schemeAvailID,
            schemeTransactionID,
            transferBackRemarks,
            verificationDoc,
            deptUserID,
          );
          if (result.success) {
            setLoading(false);
            showToast('SUCCESS', 'Scheme Transferred Back Successfully');
            history.goBack();
          }
        } catch (error) {
          setLoading(false);
          showToast('ERROR', 'Something went wrong');
        }
      } else {
        showToast('ERROR', 'Please fill the remarks before transferring');
      }
    } else {
      showToast('ERROR', 'Please upload verification document');
    }
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const handleSuccessConfirmationDialog = () => {
    setConfirmationDialog(false);
    setopentransfer(false);
    handleTransferTransaction();
  };

  return (
    <>
      <ConfirmationDialog
        openDialog={confirmationDialog}
        handleCloseConfirmationDialog={handleCloseConfirmationDialog}
        handleSuccessConfirmationDialog={handleSuccessConfirmationDialog}
        confirmationType={confirmationType}
        confirmationMessage={confirmationMessage}
      />
      {hideButtons ? null : (
        <DocumentUpload
          verificationDoc={verificationDoc}
          verificationDocID={verificationDocID}
          verificationDocRef={verificationDocRef}
          handleRemoveVerificationDoc={handleRemoveVerificationDoc}
          handleVerificationDoc={handleVerificationDoc}
          missingDoc={missingDoc}
          missingDocID={missingDocID}
          missingDocRef={missingDocRef}
          handleRemoveMissingDoc={handleRemoveMissingDoc}
          handleMissingDoc={handleMissingDoc}
          missingDocSecondLevel={missingDocSecondLevel}
          missingDocSecondLevelID={missingDocSecondLevelID}
          missingDocSecondLevelRef={missingDocSecondLevelRef}
          handleRemoveMissingDocSecondLevel={handleRemoveMissingDocSecondLevel}
          handleMissingSecondLevelDoc={handleMissingSecondLevelDoc}
          verificationDocument={verificationDocument}
          missingDocument={missingDocument}
          secondLevelMissingDocument={secondLevelMissingDocument}
        />
      )}
      {(schemeName === 'Medical Assistance\r\n' ||
        schemeName === 'Medical Assistance' ||
        schemeName === 'Assistance For Major Ailments' ||
        schemeName === 'Disability Pension' ||
        (schemeName === 'Accident Assistance' &&
          accidentResultedIn !== 'Death')) && (
          <SanctionedAmount
            schemeStatus={schemeStatus}
            approvalAuthority={approvalAuthority}
            amount={amount}
            setAmount={setAmount}
          />
        )}

      {((schemeName === 'Accident Assistance' && accidentResultedIn === 'Death') || (schemeName === 'Funeral Expense and Death Assistance')) && (!applicationNoByLi && !reasonByLi && schemeStatus === 'Pending') && (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>Notes</p>
            </Col>
          </Row>
          <Row className="scheme-subtitle-row">
            <Col>
              <div className="container">
                <Row>
                  {/* NOTE 1 */}
                  <Col md={6}>
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5 className="card-title">NOTE 1:</h5>
                        <p>ಈ ಅರ್ಜಿಯು ಸೇವಾ ಸಿಂಧು ತಂತ್ರಾಂಶದಲ್ಲಿ ಈಗಾಗಲೇ ಸಲ್ಲಿಸಲಾಗಿದೆಯೇ?</p>
                        <div className="mt-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="alreadySubmitted"
                              id="alreadySubmittedYes"
                              value="yes"
                              onChange={(e) => {
                                const showAppNumberField = e.target.value === "yes";
                                document.getElementById("applicationNumberField").style.display = showAppNumberField
                                  ? "block"
                                  : "none";
                              }}
                            />
                            <label className="form-check-label" htmlFor="alreadySubmittedYes">
                              YES
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="alreadySubmitted"
                              id="alreadySubmittedNo"
                              value="no"
                              onChange={(e) => {
                                const showAppNumberField = e.target.value !== "no";
                                document.getElementById("applicationNumberField").style.display = showAppNumberField
                                  ? "block"
                                  : "none";

                                if (e.target.value === "no") {
                                  setApplicationNoByLiValue(null)
                                  document.getElementById("applicationNumber").value = ""
                                }
                              }}
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="alreadySubmittedNo">
                              NO
                            </label>
                          </div>
                        </div>
                        <div id="applicationNumberField" className="mt-3" style={{ display: "none" }}>
                          <label htmlFor="applicationNumber" className="form-label">
                            Application Number:
                          </label>
                          <input
                            type="text" className="form-control"
                            id="applicationNumber"
                            value={applicationNoByLi}
                            onChange={handleApplicationNoByLi}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* NOTE 2 */}
                  <Col md={6}>
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5 className="card-title">NOTE 2:</h5>
                        <p>
                          ದಿನಾಂಕ 17/10/2023ಕ್ಕೆ ಹಾಗೂ ನಂತರದ ದಿನಾಂಕಕ್ಕೆ ಅಪಘಾತ ಮರಣ ಧನ ಸಹಾಯ ಕೋರಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿಯಮಾನುಸಾರ 49 C ಕಾಲ ಮಿತಿ ಅಡಿ ಇರುವ ಅರ್ಜಿಯೇ.
                        </p>
                        <div className="mt-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="timeLimit"
                              id="timeLimitYes"
                              value="yes"
                              onChange={(e) => {
                                const showReasonField = e.target.value !== "yes";
                                document.getElementById("reasonField").style.display = showReasonField ? "block" : "none";
                                if (e.target.value === "yes") {
                                  setReasonByLiValue(null)
                                  document.getElementById("reason").value = ""
                                }
                              }}

                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="timeLimitYes">
                              YES
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="timeLimit"
                              id="timeLimitNo"
                              value="no"
                              onChange={(e) => {
                                const showReasonField = e.target.value === "no";
                                document.getElementById("reasonField").style.display = showReasonField ? "block" : "none";
                              }}
                            />
                            <label className="form-check-label" htmlFor="timeLimitNo">
                              NO
                            </label>
                          </div>
                        </div>
                        <div id="reasonField" className="mt-3" style={{ display: "none" }}>
                          <label htmlFor="reason" className="form-label">
                            Reason:
                          </label>
                          <textarea
                            className="form-control"
                            id="reason" rows="1"
                            onChange={handleReasonByLi}
                            value={reasonByLi}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}


      {((schemeName === 'Accident Assistance' && accidentResultedIn === 'Death') || (schemeName === 'Funeral Expense and Death Assistance')) && (applicationNoByLi || reasonByLi) && (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>Notes</p>
            </Col>
          </Row>
          <Row >
            <Col>
              <div className="container" style={{ marginTop: "20px" }}>
                <Row>
                  {applicationNoByLi && (

                    <Col md={12}>
                      <div className="card mb-3">
                        <div className="card-body">
                          <p>
                            ಈ ಅರ್ಜಿಯು ಸೇವಾ ಸಿಂಧು ತಂತ್ರಾಂಶದಲ್ಲಿ ಈಗಾಗಲೇ ಸಲ್ಲಿಸಲಾಗಿದೆಯೇ?
                          </p>
                          <div className="card mb-3">
                            <div className="card-body">
                              <div id="applicationNumberField" className="mt-3" >
                                {applicationNoByLi == 'No' ? (
                                  <></>
                                ) : (
                                  <label htmlFor="applicationNumber" className="form-label">
                                    Application Number:
                                  </label>
                                )}
                                <input
                                  type="text" className="form-control"
                                  id="applicationNumber"
                                  value={applicationNoByLi}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}

                  {reasonByLi && (
                    <Col md={12}>
                      <div className="card mb-3">
                        <div className="card-body">

                          <div className="card mb-3">
                            <div className="card-body">
                              <p>
                                ದಿನಾಂಕ 17/10/2023ಕ್ಕೆ ಹಾಗೂ ನಂತರದ ದಿನಾಂಕಕ್ಕೆ ಅಪಘಾತ ಮರಣ ಧನ ಸಹಾಯ ಕೋರಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನಿಯಮಾನುಸಾರ 49 C ಕಾಲ ಮಿತಿ ಅಡಿ ಇರುವ ಅರ್ಜಿಯೇ.
                              </p>
                              <div id="reasonField" className="mt-3">
                                {reasonByLi == 'Yes' ? (
                                  <></>
                                ) : (
                                  <label htmlFor="reason" className="form-label">
                                    Reason:
                                  </label>
                                )}
                                <textarea
                                  className="form-control"
                                  id="reason" rows="1"
                                  value={reasonByLi}
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}





      <RemarksOrder
        order={order}
        remarks={remarks}
        forwardedDate={forwardedDate}
        approveRejectDate={approveRejectDate}
        handleRemarks={handleRemarks}
        handleOrder={handleOrder}
        wordLimit={wordLimit}
        handleTransferBackRemarks={handleTransferBackRemarks}
        transferBackRemarks={transferBackRemarks}
        transferRemarks={transferRemarks}
        status={status}
      />
      <div className="d-flex justify-content-end container">
        <Row className="">
          <Col>
            <Transaction
              loading={loading}
              handleForwardTransaction={handleForwardTransaction}
              opentransfer={opentransfer}
              handleCloseTransfer={handleCloseTransfer}
              transferRemarks={transferRemarks}
              circle={circle}
              circleID={circleID}
              handleClickTransfer={handleClickTransfer}
              handleTransferChange={handleTransferChange}
              handleSubmitTransfer={handleSubmitTransfer}
              handleTransferBackTransaction={handleTransferBackTransaction}
              handleDraftTransaction={handleDraftTransaction}
              handleApproveRejectTransaction={handleApproveRejectTransaction}
              openRejection={openRejection}
              handleCloseRejection={handleCloseRejection}
              toggleLang={toggleLang}
              handleToggle={handleToggle}
              handleCheckboxChange={handleCheckboxChange}
              handleSubmitReject={handleSubmitReject}
              handleOpenRejectionDialog={handleOpenRejectionDialog}
              rejectReasons={rejectReasons}
              handleDraftSubmitReject={handleDraftSubmitReject}
              selectedOptions={selectedOptions}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SchemeTransactions;
