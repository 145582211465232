import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

// import kbocwwb from 'assets/images/Image 8.png';
// import kbocwwb2 from 'assets/images/Karnataka_logo_100_Pixels.png';
// import kbocwwbyellow from 'assets/images/kbocwwb_kannada.svg';
// import Header from './Header';
import construction from 'assets/images/construction.svg';
import welfare from 'assets/images/welfare.svg';
import unorganised from 'assets/images/unorganised.svg';
// import migrant from 'assets/images/migrant.svg';
import language from 'assets/images/translate (1).svg';
import { setLocaleWithFallback } from 'store/actions/user.actions';
// import labourDept from 'assets/images/Labour Department.svg';
// import boiler from 'assets/images/Factories.svg';
import Header2 from './Header2';

const BoardsHome = () => {
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);

  const handlemouseOver1 = () => {
    setIsHover1(true);
  };

  const handlemouseOut1 = () => {
    setIsHover1(false);
  };

  const handlemouseOver2 = () => {
    setIsHover2(true);
  };

  const handlemouseOut2 = () => {
    setIsHover2(false);
  };

  const handlemouseOver3 = () => {
    setIsHover3(true);
  };

  const handlemouseOut3 = () => {
    setIsHover3(false);
  };

  const handlemouseOver4 = () => {
    setIsHover4(true);
  };

  const handlemouseOut4 = () => {
    setIsHover4(false);
  };

  const handlemouseOver5 = () => {
    setIsHover5(true);
  };

  const handlemouseOut5 = () => {
    setIsHover5(false);
  };

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });
  const handleChange1 = (e) => {
    console.log('kkkkk', e.target.value);
    if (e.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === 'language')
      dispatch(setLocaleWithFallback(e.target.value));
  };

  return (
    <div className="container-fluid my-0 p-0">
      <Header2 lang={allValues.language} />
      <div className="d-flex justify-content-end container-fluid m-0 p-0">
        <Col
          xs={12}
          md={3}
          lg={3}
          className="d-flex justify-content-end mt-2 me-2 container-fluid m-0 p-0"
        >
          <Select
            className="select-language"
            style={{ width: '100%' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.language}
            name="language"
            displayEmpty
            onChange={handleChange1}
          >
            <MenuItem value="">
              <ListItemIcon>
                <img alt="..." src={language} className="language-img" />
              </ListItemIcon>
              <ListItemText primary="Select Language" />
            </MenuItem>
            {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
            <MenuItem value="en">
              <ListItemText primary="English" />
            </MenuItem>
            <MenuItem value="ka">
              <ListItemText primary="ಕನ್ನಡ" />
            </MenuItem>
          </Select>
        </Col>
      </div>
      <div className="container-fluid my-5 p-0">
        <Row className="homepage-row main-screens-boardsHome container-fluid m-0 p-0">
          <Col
            xs={6}
            sm={6}
            md={6}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <Link to="/schemesHomePage">
              <div
                className={
                  isHover1
                    ? 'homepage-image-div boards-hover'
                    : 'homepage-image-div boards'
                }
                onMouseOver={handlemouseOver1}
                onMouseOut={handlemouseOut1}
                style={{ height: '260px' }}
              >
                {isHover1 ? (
                  <div className="outer-img-boards-hover mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={construction}
                      onMouseOver={handlemouseOver1}
                    />
                  </div>
                ) : (
                  <div className="outer-img-boards mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={construction}
                      onMouseOut={handlemouseOut1}
                    />
                  </div>
                )}
                {/* <p>Karnataka Building & Other<br />Construction Workers Welfare Board</p> */}
                <p></p>
                <p
                  className={isHover1 ? 'text-hover' : 'text-nohover'}
                  onMouseOver={handlemouseOver1}
                  onMouseOut={handlemouseOut1}
                >
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="kbocwwbTitle1"
                  />
                  <br />
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="kbocwwbTitle2"
                  />
                  <br />
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="kbocwwbTitle3"
                  />
                </p>
              </div>
            </Link>
          </Col>

          <Col
            xs={6}
            sm={6}
            md={6}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <Link to="/labourschemesHomePage">
              <div
                className={
                  isHover2
                    ? 'homepage-image-div boards-hover'
                    : 'homepage-image-div boards'
                }
                onMouseOver={handlemouseOver2}
                onMouseOut={handlemouseOut2}
                style={{ height: '260px' }}
              >
                {isHover2 ? (
                  <div className="outer-img-boards-hover mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={welfare}
                      onMouseOver={handlemouseOver2}
                    />
                  </div>
                ) : (
                  <div className="outer-img-boards mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={welfare}
                      onMouseOut={handlemouseOut2}
                    />
                  </div>
                )}
                <p
                  className={isHover2 ? 'text-hover' : 'text-nohover'}
                  onMouseOver={handlemouseOver2}
                  onMouseOut={handlemouseOut2}
                >
                  <Translate style={{ fontSize: '17px' }} value="klwbTitle1" />{' '}
                  <br />
                  <Translate style={{ fontSize: '17px' }} value="klwbTitle2" />
                </p>
              </div>
            </Link>
          </Col>

          <Col
            xs={12}
            className="mt-5 mb-5"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <Link to="/socialsecurityschemesHomePage">
              <div
                className={
                  isHover3
                    ? 'homepage-image-div boards-hover'
                    : 'homepage-image-div boards'
                }
                onMouseOver={handlemouseOver3}
                onMouseOut={handlemouseOut3}
                style={{ height: '260px' }}
              >
                {isHover3 ? (
                  <div className="outer-img-boards-hover mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={unorganised}
                      onMouseOver={handlemouseOver3}
                    />
                  </div>
                ) : (
                  <div className="outer-img-boards mt-3">
                    <img
                      className="position-absolute top-50 start-50 translate-middle"
                      alt="..."
                      src={unorganised}
                      onMouseOut={handlemouseOut3}
                    />
                  </div>
                )}
                <p
                  className={isHover3 ? 'text-hover' : 'text-nohover'}
                  onMouseOver={handlemouseOver3}
                  onMouseOut={handlemouseOut3}
                >
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="ksuwssbTitle1"
                  />
                  <br />
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="ksuwssbTitle2"
                  />
                  <br />
                  <Translate
                    style={{ fontSize: '17px' }}
                    value="ksuwssbTitle3"
                  />
                </p>
              </div>
            </Link>
          </Col>
          {/* <Col xs={12} sm={6} md={6}  className='mt-5 mb-5'  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
<a  href='https://esuraksha.karnataka.gov.in/english'  target='_blank'>
    <div className={isHover4 ? "homepage-image-div boards-hover":"homepage-image-div boards"} 
                onMouseOver={handlemouseOver4} onMouseOut={handlemouseOut4} style={{height:'260px'}} >
    {isHover4 ?<div className='outer-img-boards-hover mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={boiler} onMouseOver={handlemouseOver4} /></div> :<div className='outer-img-boards mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={boiler} onMouseOut={handlemouseOut4}/></div> }
        <p className={isHover4 ? "text-hover":"text-nohover" } onMouseOver={handlemouseOver4} onMouseOut={handlemouseOut4}><Translate style={{fontSize:'17px'}} value='boilerTitle1'/><br /><Translate style={{fontSize:'17px'}} value='boilerTitle2'/><br/></p>
    </div>
    </a>
</Col>
<Col xs={12}   className='mt-5 mb-5'  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
<a href='https://labour.karnataka.gov.in/english' target='_blank'>
    <div  className={isHover5 ? "homepage-image-div boards-hover":"homepage-image-div boards"} 
                onMouseOver={handlemouseOver5} onMouseOut={handlemouseOut5} style={{height:'260px'}} >
    {isHover5 ?<div className='outer-img-boards-hover mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={labourDept} onMouseOver={handlemouseOver5} /></div> :<div className='outer-img-boards mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={labourDept} onMouseOut={handlemouseOut5}/></div> }
        <p className={isHover5 ? "text-hover":"text-nohover" } onMouseOver={handlemouseOver5} onMouseOut={handlemouseOut5}><Translate style={{fontSize:'17px'}} value='LabourTitle1'/></p>
    </div>
    </a>
</Col> */}

          {/* <Col xs={6} sm={6} md={6} className='mt-5'  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} >
<Link to='/migrantpage'>
    <div  className={isHover4 ? "homepage-image-div boards-hover":"homepage-image-div boards"} 
                onMouseOver={handlemouseOver4} onMouseOut={handlemouseOut4} style={{height:'260px'}}>
   
    {isHover4 ?<div className='outer-img-boards-hover mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={migrant} onMouseOver={handlemouseOver4} /></div> :<div className='outer-img-boards mt-3'><img className='position-absolute top-50 start-50 translate-middle' alt="..." src={migrant} onMouseOut={handlemouseOut4}/></div> }
        <p className={isHover4 ? "text-hover":"text-nohover" } onMouseOver={handlemouseOver4} onMouseOut={handlemouseOut4}><Translate value='migrantTitle'/></p>
        </div>
        </Link>
</Col> */}
        </Row>
      </div>
    </div>
  );
};

export default BoardsHome;
