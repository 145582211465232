import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { SERVER } from 'store/actions/user.actions';

export default function usePostApi({ url, method = 'post', options = {} }) {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null); // Store the abort controller reference

  const postApiCall = async (data) => {
    // Abort the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new abort controller
    const controller = new AbortController();
    abortControllerRef.current = controller;

    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        url: SERVER + url,
        method,
        data,
        headers: options?.headers ?? {},
      });

      if (response) {
        setResponseData(response.data);
      }
    } catch (err) {
      setError(err?.response?.data);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
  return { data: responseData, error, loading, postApiCall };
}
