import { FormControl, TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { singleRecordAPI } from '../services/schemes.service';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

const RemarksOrder = ({
  remarks,
  order,
  handleOrder,
  handleRemarks,
  wordLimit,
  handleTransferBackRemarks,
  transferBackRemarks,
  transferRemarks,
  status,
  forwardedDate,
  approveRejectDate,
}) => {
  const {
    schemeStatus,
    approvalAuthority,
    schemeAvailID,
    schemeTransactionID,
    schemeName,
  } = useParams();

  const [reasons, setReasons] = useState(null);
  const [toggleLang, setToggleLang] = useState(false);
  const callRejectionReason = async (schemeAvailID, schemeTransactionID) => {
    const result = await singleRecordAPI(schemeAvailID, schemeTransactionID);
  };

  useEffect(() => {
    if (schemeStatus === 'Rejected' && status) {
      setReasons(status.rejection_reason);
    }
  }, [schemeStatus, status]);

  const hideButtons =
    schemeName === 'Continuation of Pension' ||
    schemeName === 'Continuation of Disability Pension';

  const hideWordsLimits =
    schemeStatus === 'Approved' || schemeStatus === 'Rejected';

  const hideTransferWordLimits =
    schemeStatus === 'Transferred Scheme Application' &&
    approvalAuthority === '1';
  const hideTransferBackWordLimits =
    schemeStatus === 'Transferred back Scheme Application' &&
    approvalAuthority === '1';

  return (
    <>
      {(schemeStatus === 'Pending' ||
        schemeStatus === 'Forwarded' ||
        schemeStatus === 'Draft Approved' ||
        schemeStatus === 'Draft Rejected' ||
        schemeStatus === 'Approved' ||
        schemeStatus === 'Rejected') && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                {schemeStatus !== 'Pending' && +approvalAuthority === 1 ? (
                  <p>Remarks / Order</p>
                ) : hideButtons ? (
                  <p>Order</p>
                ) : (
                  <p>Remarks</p>
                )}
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              {forwardedDate && forwardedDate.length > 0 && (
                <div style={{ position: 'relative' }} className="">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      position: 'absolute',
                      top: '0px',
                      right: '-100px',
                    }}
                  >
                    Forwarded on:
                    {moment(forwardedDate, 'YYYY-MM-DD hh:mm:ss A').format(
                      'DD/MM/YYYY hh:mm:ss A',
                    )}
                  </p>
                </div>
              )}
              {approveRejectDate && approveRejectDate.length > 0 && (
                <div style={{ position: 'relative' }} className="">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      position: 'absolute',
                      top: '20px',
                      right: '-100px',
                    }}
                  >
                    {schemeStatus === 'Approved' && 'Approved on:'}
                    {schemeStatus === 'Rejected' && 'Rejected on:'}
                    {moment(approveRejectDate, 'YYYY-MM-DD hh:mm:ss A').format(
                      'DD/MM/YYYY hh:mm:ss A',
                    )}
                  </p>
                </div>
              )}
              {hideButtons ? null : (
                <>
                  <Col xs={12} md={6} className="note2 schemes mt-5 bank-col-5">
                    <FormControl fullWidth className="formcontrol1">
                      <TextareaAutosize
                        variant="outlined"
                        multiline
                        maxLength={wordLimit}
                        rows={4}
                        name="commentInspector"
                        placeholder="Remarks"
                        value={remarks}
                        onChange={handleRemarks}
                        disabled={schemeStatus !== 'Pending' ? true : false}
                      />
                    </FormControl>
                    {!hideWordsLimits && (
                      <>
                        <div className="d-flex justify-content-end align-items-center py-2">
                          {/* <p className="fs-6">
                          Forwarded Date:
                          {moment(forwardedDate).format('DD/MM/YYYY hh:mm  a')}
                        </p> */}
                          <p>
                            Words limit: {remarks?.length}/{wordLimit}
                          </p>
                        </div>
                      </>
                    )}
                  </Col>
                </>
              )}
              {hideButtons
                ? null
                : schemeStatus !== 'Pending' &&
                +approvalAuthority === 1 && (
                  <Col xs={12} md={6} className="note2 schemes mt-5 bank-col-5">
                    <FormControl fullWidth className="formcontrol1">
                      <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={4}
                        // name="commentInspector"
                        placeholder="Order"
                        maxLength={wordLimit}
                        value={order}
                        onChange={handleOrder}
                        disabled={
                          schemeStatus === 'Approved' ||
                            schemeStatus === 'Rejected'
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                    {!hideWordsLimits && (
                      <div className="d-flex justify-content-end align-items-center py-2">
                        <p>
                          Words limit: {order?.length}/{wordLimit}
                        </p>
                      </div>
                    )}
                  </Col>
                )}
              {hideButtons && (
                <Col xs={12} md={6} className="note2 schemes mt-4 bank-col-5">
                  <FormControl fullWidth className="formcontrol1">
                    <TextareaAutosize
                      variant="outlined"
                      multiline
                      rows={4}
                      maxLength={wordLimit}
                      // name="commentInspector"
                      placeholder="Order"
                      value={order}
                      onChange={handleOrder}
                      disabled={
                        schemeStatus === 'Approved' || schemeStatus === 'Rejected'
                          ? true
                          : false
                      }
                    />
                  </FormControl>

                  {!hideWordsLimits && (
                    <div className="d-flex justify-content-end align-items-center py-2">
                      <p>
                        Words limit: {order?.length}/{wordLimit}
                      </p>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </>
        )}
      {(schemeStatus === 'Transferred Scheme Application' ||
        schemeStatus === 'Transferred back Scheme Application') && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>Transfer Remarks</p>
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} md={6} className="note2 schemes mt-4 bank-col-5">
                <p>Remarks: Transfer for verification</p>
                <FormControl fullWidth className="formcontrol1">
                  <TextareaAutosize
                    variant="outlined"
                    multiline
                    rows={4}
                    maxLength={wordLimit}
                    name="commentInspector"
                    // placeholder="Remarks"
                    value={transferRemarks}
                    // onChange={handleRemarks}
                    disabled
                  />
                </FormControl>
                {hideTransferWordLimits && (
                  <div className="d-flex justify-content-end align-items-center py-2">
                    <p>
                      Words limit: {transferRemarks?.length}/{wordLimit}
                    </p>
                  </div>
                )}
              </Col>

              {((schemeStatus === 'Transferred Scheme Application' &&
                +approvalAuthority === 1) ||
                schemeStatus === 'Transferred back Scheme Application') && (
                  <Col xs={12} md={6} className="note2 schemes mt-4 bank-col-5">
                    <p>Remarks: Transferred back verification</p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={4}
                        maxLength={wordLimit}
                        // name="commentInspector"
                        placeholder="Enter Transfer Back Remarks"
                        value={transferBackRemarks}
                        onChange={handleTransferBackRemarks}
                        disabled={
                          schemeStatus === 'Transferred back Scheme Application'
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                    {!hideTransferBackWordLimits && (
                      <div className="d-flex justify-content-end align-items-center py-2">
                        <p>
                          Words limit: {transferBackRemarks?.length}/{wordLimit}
                        </p>
                      </div>
                    )}
                  </Col>
                )}
            </Row>
            {schemeStatus === 'Transferred back Scheme Application' &&
              +approvalAuthority === 1 && (
                <>
                  <Row className="scheme-subtitle-row">
                    <Col xs={12} className="searchform-subtitle">
                      <p>Remarks</p>
                    </Col>
                  </Row>
                  <Row className="form-row mt-4 config-form searchform">
                    <Col xs={12} md={6} className="note2 schemes mt-4 bank-col-5">
                      <FormControl fullWidth className="formcontrol1">
                        <TextareaAutosize
                          variant="outlined"
                          multiline
                          rows={4}
                          maxLength={wordLimit}
                          name="commentInspector"
                          placeholder="Remarks"
                          value={remarks}
                          onChange={handleRemarks}
                        // disabled={schemeStatus !== 'Pending' ? true : false}
                        />
                      </FormControl>
                      {!hideWordsLimits && (
                        <div className="d-flex justify-content-end align-items-center py-2">
                          <p>
                            Words limit: {remarks?.length}/{wordLimit}
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
          </>
        )}

      {schemeStatus === 'Rejected' && (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>Rejected Reasons</p>
            </Col>
          </Row>

          <Row className="form-row mt-4 config-form searchform">
            <div className="d-flex justify-content-end align-items-center mx-5 px-5 py-1">
              {reasons && reasons.length > 0 && (
                <Button onClick={() => setToggleLang(!toggleLang)}>
                  {toggleLang
                    ? 'Click here to view in English'
                    : 'Click here to view in Kannada'}
                </Button>
              )}
            </div>
            {!status ? (
              <CircularProgress />
            ) : reasons && reasons.length > 0 ? (
              reasons.map((row, index) => (
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    border: '1px solid black',
                  }}
                >
                  <ListItem style={{ position: 'relative', padding: '10px' }}>
                    {toggleLang ? (
                      <ListItemText
                        style={{ position: 'absolute' }}
                        primary={`${index + 1}. ${row.rejection_reason_kannada
                          }`}
                      />
                    ) : (
                      <ListItemText
                        style={{ position: 'absolute' }}
                        primary={`${index + 1}. ${row.rejection_reason}`}
                      />
                    )}
                  </ListItem>
                </List>
              ))
            ) : (
              <div>1.{order}</div>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default RemarksOrder;
