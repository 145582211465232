import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { Translate } from "react-redux-i18n";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  SERVER,
  addLabourAddressDetails,
  addLabourFamilyDetails,
  addLabourPersonalDetails,
  getUserRegistrationDetails,
} from "store/actions/user.actions";
import { Required, getTokenCookie, showToast } from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import closeIcon from "assets/images/close.png";
import { AddCircleRounded } from "@material-ui/icons";
import Webcam from "react-webcam";
import folder from "assets/images/Folderwhite-01-01.svg";
// import webcam from 'assets/images/web-cam.svg'
import webcam from "assets/images/web cam-01.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import uploadFileAPI from "utils/fileupload";
import FolderIcon from "@mui/icons-material/Folder";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import avatar from "assets/images/avatar.png";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  findDuplicates,
  hasEmptyPropertiesFamilyDetails,
} from "utils/checkEmptyArray";
import SimpleBackdrop from "components/loadingScreen/Backdrop";

const wifeMandatory = {
  relationId: null,
  parentChildUserId: null,
  parentChildUniqueID: null,
  relation: 51,
  firstName: null,
  middleName: null,
  lastName: null,

  dob: null,
  profession: null,
  education: null,
  nominee: false,
  isInsert: null,
  isActive: null,
  isDeleted: null,
  isShowing: true,
};

const husbandMandatory = {
  relationId: null,
  parentChildUserId: null,
  parentChildUniqueID: null,
  relation: 52,
  firstName: null,
  middleName: null,
  lastName: null,

  dob: null,
  profession: null,
  education: null,
  nominee: false,
  isInsert: null,
  isActive: null,
  isDeleted: null,
  isShowing: true,
};

const FamilyDetails = () => {
  const { labourUserID, isEdited } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [fetchRelation, setFetchRelation] = useState(null);
  const [fetchProfession, setFetchProfession] = useState(null);
  const [fetchEducation, setFetchEducation] = useState(null);
  const [fetchRationCardType, setFetchRationCardType] = useState(null);
  const [rationCardNumber, setRationCardNumber] = useState(null);
  const [rationCardDocID, setRationCardDocID] = useState(null);

  const rationCardFileRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [rationCardDoc, setRationCardDoc] = useState([]);
  const [rationCardID, setRationCardID] = useState(null);

  const [familyDetails, setFamilyDetails] = useState([
    {
      relationId: null,
      parentChildUserId: null,
      parentChildUniqueID: null,
      relation: 49,
      firstName: null,
      middleName: null,
      lastName: null,

      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: null,
      isActive: null,
      isDeleted: null,
      isShowing: true,
    },
    {
      relationId: null,
      parentChildUserId: null,
      parentChildUniqueID: null,
      relation: 50,
      firstName: null,
      middleName: null,
      lastName: null,

      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: null,
      isActive: null,
      isDeleted: null,
      isShowing: true,
    },
  ]);

  const isUpdated = useRef(false);

  console.log(users);

  useEffect(() => {
    if (
      !isUpdated.current &&
      users &&
      users.getUserRegistrationDetails.personal_details[0].marital_status ===
        "Married"
    ) {
      if (
        users &&
        users.getUserRegistrationDetails.personal_details[0].gender === "Male"
      ) {
        const arr = [...familyDetails, wifeMandatory];
        setFamilyDetails(arr);
        isUpdated.current = true;
      } else if (
        users &&
        users.getUserRegistrationDetails.personal_details[0].gender === "Female"
      ) {
        const arr = [...familyDetails, husbandMandatory];
        setFamilyDetails(arr);
        isUpdated.current = true;
      }
    }
  }, [users, familyDetails]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "ration_card_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        const { data: data_ } = data;

        if (data_.length > 0) {
          setRationCardNumber(data_[0].ration_card_no);
          setRationCardID(data_[0].catalog_value_ration_card_type_id);
          const rationCard =
            data_[0].ration_card_files_xml &&
            JSON.parse(data_[0].ration_card_files_xml);

          setRationCardDocID(rationCard);
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          //  setSubmitLoading(false);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          //  setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "family_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          // setSubmitLoading(false);
          const { data } = res.data;
          // const { family_details } = data;

          const result = data.map((data) => ({
            relationId: data.relation_id,
            parentChildUserId: data.parent_child_user_id,
            parentChildUniqueID: data.parent_child_unic_id,
            relation: data.catalog_value_parent_child_type_id,
            firstName: `${data.first_name} ${data.middle_name ?? ""} ${
              data.last_name ?? ""
            }`,
            middleName: null,
            lastName: null,

            dob: data.date_of_birth && moment(data.date_of_birth),
            profession: data.catalog_value_profession_id,
            education: data.catalog_value_qualification_id,
            nominee: data.is_nominee ? 1 : 0,
            isInsert: data.parent_child_user_id ? 0 : 1,
            isDeleted: data.isdeleted ? 1 : 0,
            isActive: data.isactive ? 1 : 0,
            isShowing: true,
          }));
          setFamilyDetails(result);
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          setSubmitLoading(false);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Relation",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchRelation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Education",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchEducation(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          setSubmitLoading(false);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Ration card type",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchRationCardType(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Profession",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchProfession(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  const handleFormChange = (index, event) => {
    let data = [...familyDetails];
    if (event.target.name === "firstName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "middleName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "lastName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar1") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar2") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar3") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFamilyDetails(data);
  };

  const handleDateChange = (date, index, from) => {
    let data = [...familyDetails];
    data[index][from] = new Date(moment(date));
    setFamilyDetails(data);
  };

  // const handleCheckBox = (index, event, from) => {
  //   console.log('Nominees', index, event.target.checked, from);
  //   let data = [...familyDetails];
  //   data[index][from] = event.target.checked ? 1 : 0;
  //   setFamilyDetails(data);
  // };

  const handleCheckBox = (index, event, from) => {
    let data = [...familyDetails];

    // Uncheck the previous checkbox in the array
    if (event.target.checked) {
      for (let i = 0; i < data.length; i++) {
        if (i !== index) {
          data[i][from] = 0;
        }
      }
    }

    // Update the current checkbox state
    data[index][from] = event.target.checked ? 1 : 0;
    setFamilyDetails(data);
  };

  const addNewFamilyMember = () => {
    let newfield = {
      relation: null,

      firstName: null,
      middleName: null,
      lastName: null,
      parentChildUniqueID: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: 1,
      isActive: 1,
      isDeleted: 0,
      isShowing: true,
    };

    setFamilyDetails([...familyDetails, newfield]);
  };

  const handleRationCardFile = (ev) => {
    if (
      ev.target.files[0].type === "image/png" ||
      ev.target.files[0].type === "image/jpeg" ||
      ev.target.files[0].type === "image/jpg" ||
      ev.target.files[0].type === "application/pdf"
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...rationCardDoc, ...ev.target.files];
        setRationCardDoc(newFiles);
      } else {
        showToast("ERROR", "Please upload file size less than 2MB");
      }
    } else {
      showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...rationCardDoc];
    newFiles.splice(index, 1);
    setRationCardDoc(newFiles);
  };

  const handleRemoveExistingFile = (index) => {
    const newFiles = [...rationCardDocID];
    newFiles.splice(index, 1);
    setRationCardDocID(newFiles);
  };

  const uploadFiles = () => {
    if (rationCardDoc && rationCardDoc.length > 0) {
      const promises = [];
      rationCardDoc.forEach((doc) => {
        promises.push(uploadFileAPI(doc));
      });
      Promise.all(promises)
        .then((promiseResponse) => {
          const mappedData = promiseResponse.map((doc) => doc.image);
          insertFamilyDetails(mappedData);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    } else {
      insertFamilyDetails([]);
    }
  };

  const insertFamilyDetails = (rationCard) => {
    const familyData = familyDetails.map((data) => ({
      relation_type_id: data.relation,
      first_name: data.firstName.trim(),
      middle_name: null,
      last_name: null,

      dob: data.dob && moment(data.dob).format("YYYY-MM-DD"),
      profession: data.profession,
      education: data.education,
      is_nominee: data.nominee ? 1 : 0,
      is_registered: 0,
      registeration_no: null,
      labour_user_id: +labourUserID,
    }));

    if (isEdited === "edit" || isEdited === "back") {
      // Update

      const updateFamilyData = familyDetails.map((data) => ({
        relation_id: data.relationId ? data.relationId : 0,
        parent_child_user_id: data.parentChildUserId
          ? data.parentChildUserId
          : 0,
        relation_type_id: data.relation,
        parent_child_unic_id: data.parentChildUniqueID,
        first_name: data.firstName.trim(),
        middle_name: null,
        last_name: null,

        dob: data.dob && moment(data.dob).format("YYYY-MM-DD"),
        profession: data.profession,
        education: data.education,
        is_nominee: data.nominee ? 1 : 0,
        is_registered: 0,
        registeration_no: null,
        labour_user_id: +labourUserID,
        isinsert: data.isInsert,
        isdeleted: data.isDeleted,
        isactive: data.isActive,
      }));

      if (
        (rationCardDoc && rationCardDoc.length > 0) ||
        (rationCardDocID && rationCardDocID.length > 0)
      ) {
        let rationCardDocument = [];
        const rationCardObj = rationCard.map((data) => ({
          file_id: data.fileId,
          file_name: data.originalFileName,
        }));

        if (
          rationCardDoc &&
          rationCardDoc.length > 0 &&
          rationCardDocID &&
          rationCardDocID.length > 0
        ) {
          rationCardDocument = [...rationCardDocID, ...rationCardObj];
        } else if (rationCardDocID && rationCardDocID.length > 0) {
          rationCardDocument = rationCardDocID;
        } else {
          rationCardDocument = rationCardObj;
        }

        const updatePayLoad = {
          labourUserId: +labourUserID,
          rationCardNo:
            rationCardNumber && rationCardNumber.length > 0
              ? rationCardNumber
              : null,

          rationCardImageId: null,
          rationCardType: rationCardID ? rationCardID : null,
          rationCardFiles: rationCardDocument,
          families: updateFamilyData,
        };

        axios
          .patch(`${SERVER}/user/v2/family-details`, updatePayLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast("SUCCESS", "Family Details Updated Successfully");
            history.push(
              `/profile/${
                isEdited === "back"
                  ? "bank"
                  : isEdited === "edit"
                    ? "review"
                    : ""
              }/${labourUserID}`
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast("ERROR", `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
              showToast("ERROR", "Something went wrong");
            }
          });
      } else {
        const updatePayLoad = {
          labourUserId: +labourUserID,
          rationCardNo: rationCardNumber ? +rationCardNumber : null,
          rationCardImageId: null,
          rationCardType: rationCardID ? rationCardID : null,
          rationCardFiles: [],
          families: updateFamilyData,
        };

        axios
          .patch(`${SERVER}/user/v2/family-details`, updatePayLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast("SUCCESS", "Family Details Updated Successfully");
            history.push(
              `/profile/${
                isEdited === "back"
                  ? "bank"
                  : isEdited === "edit"
                    ? "review"
                    : ""
              }/${labourUserID}`
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast("ERROR", `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
              showToast("ERROR", "Something went wrong");
            }
          });
      }
    } else {
      // Insert
      console.log({ rationCardDocID, rationCardDoc });
      if (
        (rationCardDoc && rationCardDoc.length > 0) ||
        (rationCardDocID && rationCardDocID.length > 0)
      ) {
        let rationCardDocument = [];
        const rationCardObj = rationCard.map((data) => ({
          file_id: data.fileId,
          file_name: data.originalFileName,
        }));

        if (
          rationCardDoc &&
          rationCardDoc.length > 0 &&
          rationCardDocID &&
          rationCardDocID.length > 0
        ) {
          rationCardDocument = [...rationCardDocID, ...rationCardObj];
        } else if (rationCardDocID && rationCardDocID.length > 0) {
          rationCardDocument = rationCardDocID;
        } else {
          rationCardDocument = rationCardObj;
        }

        const payLoad = {
          labourUserId: +labourUserID,
          rationCardNo:
            rationCardNumber && rationCardNumber.length > 0
              ? rationCardNumber
              : null,
          rationCardImageId: null,
          rationCardType: rationCardID,
          rationCardFiles: rationCardDocument,
          families: familyData,
        };

        axios
          .put(`${SERVER}/user/v2/family-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast("SUCCESS", "Family Details Saved Successfully");
            history.push(`/profile/bank/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              setSubmitLoading(false);
              showToast("ERROR", `${err.response.data.errors.msg}`);
            } else {
              setSubmitLoading(false);
              showToast("ERROR", "Something went wrong");
            }
          });
      } else {
        const payLoad = {
          labourUserId: +labourUserID,
          rationCardNo:
            rationCardNumber && rationCardNumber.length > 0
              ? rationCardNumber
              : null,
          rationCardImageId: null,
          rationCardType: rationCardID,
          rationCardFiles: [],
          families: familyData,
        };

        axios
          .put(`${SERVER}/user/v2/family-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast("SUCCESS", "Family Details Saved Successfully");
            history.push(`/profile/bank/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              setSubmitLoading(false);
              showToast("ERROR", `${err.response.data.errors.msg}`);
            } else {
              setSubmitLoading(false);
              showToast("ERROR", "Something went wrong");
            }
          });
      }
    }
  };

  const handleSubmit = () => {
    // uploadFiles([]);

    const familyDetailsCheck = familyDetails.filter(
      (item) => !hasEmptyPropertiesFamilyDetails(item)
    );
    // const duplicateNames = findDuplicates(familyData, 'aadhar_no');

    if (familyDetails.length === familyDetailsCheck.length) {
      // if (duplicateNames && duplicateNames.length === 0) {
      const isNoNomineeSelected = familyDetails.every((item) => !item.nominee);

      if (isNoNomineeSelected) {
        showToast("ERROR", "Please select nominee");
      } else {
        if (rationCardDoc && rationCardDoc.length) {
          setSubmitLoading(true);
          uploadFiles();
        } else {
          setSubmitLoading(true);
          uploadFiles();
        }
      }
      // } else {
      //   showToast('ERROR', `Family Member's Aadhaar number cannot be same`);
      // }
    } else {
      // console.log('Failed');
      showToast("ERROR", "Please fill the mandatory fields");
    }
  };

  const handleBack = () => {
    history.push(`/profile/address/${labourUserID}/back`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const removeFamilyMember = (index) => {
    const data = [];

    if (isEdited === "edit" || isEdited === "back") {
      // const clickedElement = data
      //   .filter((_, i) => i === index)
      //   .map((item) => ({
      //     ...item,
      //     isinsert: 0,
      //     isdeleted: 1,
      //     isactive: 0,
      //     isShowing: false,
      //   }));

      // setFamilyDetails((prevFamilyDetails) =>
      //   prevFamilyDetails.map((item, i) => ({
      //     ...item,
      //     isInsert: 0,
      //     isDeleted: i === index ? 1 : 0, // Mark the selected member as deleted
      //     isActive: i === index ? 0 : 1, // Deactivate the selected member
      //     isShowing: i === index ? false : true, // Hide the selected member
      //   })),
      // );

      // setFamilyDetails((prevFamilyDetails) => {
      //   console.log(prevFamilyDetails);
      //   let prevDeletedIndex = -1;

      //   const updatedFamilyDetails = prevFamilyDetails.map((item, i) => {
      //     console.log('outside;', i, index);
      //     if (i === index) {
      //       // Mark the selected member as deleted
      //       console.log('INside', i, index);
      //       prevDeletedIndex = i;
      //       return {
      //         ...item,
      //         isInsert: 0,
      //         isDeleted: 1,
      //         isActive: 0,
      //         isShowing: false,
      //       };
      //     } else {
      //       // Update the next deleted item
      //       const isNextDeleted =
      //         prevDeletedIndex !== -1 && i === prevDeletedIndex + 1;
      //       return {
      //         ...item,
      //         isDeleted: isNextDeleted ? 1 : 0,
      //         isActive: isNextDeleted ? 0 : 1,
      //         isShowing: isNextDeleted ? false : true,
      //       };
      //     }
      //   });

      //   return updatedFamilyDetails;
      // });

      setFamilyDetails((prevFamilyDetails) => {
        const selectedItemId = prevFamilyDetails[index].relationId;
        if (selectedItemId) {
          const updatedFamilyDetails = prevFamilyDetails.map((item) => {
            if (item.relationId === selectedItemId) {
              // Mark the selected member as deleted
              return {
                ...item,
                isInsert: 0,
                isDeleted: 1,
                isActive: 0,
                isShowing: false,
              };
            } else if (item.isDeleted === 1) {
              // Update the next deleted item
              return {
                ...item,
                isDeleted: 1,
                isActive: 0,
                isShowing: false,
              };
            }
            return item;
          });

          return updatedFamilyDetails;
        } else {
          let data = [...familyDetails];
          data.splice(index, 1);
          return data;
        }
      });
    } else {
      let data = [...familyDetails];
      data.splice(index, 1);
      setFamilyDetails(data);
    }

    // console.log('data', data);
  };

  const handleKeyDown = (ev) => {
    ev.preventDefault();
  };

  return (
    <>
      <Row className="personal-div">
        {submitLoading ? <SimpleBackdrop /> : null}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: "100%" }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              // value={allValues.language}
              name="language"
              displayEmpty
              // onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>

        <Row className="profile-title-row">
          <Col xs={10} className="profile-title">
            {/* <h1>Construction Workers Profile Details</h1> */}
            <Link
              to="/dashboard-user"
              className="profile-title-image"
              // onClick={() => props.history.goBack()}
            >
              <img alt="..." className="cal-icon hover-icn" src={back} />
            </Link>
            <h1>
              <Translate value="profileTitle" />
            </h1>
          </Col>
        </Row>

        <Row className="m-0">
          {/* {loadingBackDrop ? <SimpleBackdrop /> : null} */}
          <Row className="family-title-row">
            <Col xs={12} className="profile-title">
              <h1>
                <Translate value="rationCardDetails" />
              </h1>
            </Col>
          </Row>

          <Row className="form-row">
            <Col xs={12} className="family-col-1">
              <Row>
                <Col xs={12} md={6}>
                  <p className="mt-3 mb-2">
                    <Translate value="rationCardNumber" />
                  </p>

                  {/* <Required className="mt-3 mb-2" title="Ration Card Number"/> */}
                  <FormControl
                    fullWidth
                    className="formcontrol1"
                    // onKeyPress={(event) => {
                    //   if (event.key === 'Enter') {
                    //     handleEnter();
                    //   }
                    // }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Enter Your Ration Card Number"
                      // name="rationCardNo"
                      value={rationCardNumber}
                      onChange={(ev) => setRationCardNumber(ev.target.value)}
                      // error={allValues.rationCardNoError}
                      // helperText={
                      //   allValues.rationCardNoError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <p className="mt-3 mb-2">
                    <Translate value="rationCardType" />
                  </p>

                  {/* <Required className="mt-3 mb-2" title="Ration Card Type"/> */}
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      className="select-marital"
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={rationCardID || "0"}
                      // name="rationCardType"
                      displayEmpty
                      onChange={(ev) => setRationCardID(ev.target.value)}
                    >
                      <MenuItem value="0">
                        <ListItemText primary="Select Ration Card Type" />
                      </MenuItem>
                      {fetchRationCardType &&
                        fetchRationCardType.length &&
                        fetchRationCardType.map((row) => (
                          <MenuItem value={row.value_id}>
                            <ListItemText primary={row.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={6} className="family-col-1">
              <p className="mt-5 mb-2">
                <Translate value="uploadYourRationCardDocument" />
              </p>

              <div
                className="browsebutton-outerdiv"
                style={{ marginLeft: "5px" }}
              >
                <div className="w-100">
                  {rationCardDoc &&
                    rationCardDoc.length > 0 &&
                    rationCardDoc.map((file, index) => (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          // name="mail_id"
                          disabled
                          value={file.name || file.file_name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <IconButton
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))}
                  {rationCardDoc && rationCardDoc.length === 0 && (
                    <FormControl fullWidth className="formcontrol3 w-100">
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        // name="mail_id"
                        disabled
                        // value={file.name}
                      />
                    </FormControl>
                  )}
                  {rationCardDocID &&
                    rationCardDocID.length > 0 &&
                    rationCardDocID.map((file, index) => (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          disabled
                          value={file.file_name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveExistingFile(index)
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))}
                </div>
                <div className="browsebutton2-div">
                  <div className="">
                    <input
                      type="file"
                      ref={rationCardFileRef}
                      onChange={handleRationCardFile}
                      multiple
                      hidden
                    />
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className="py-2 mx-2"
                      startIcon={<FolderIcon />}
                      onClick={() => rationCardFileRef.current.click()}
                    >
                      Browse
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <p className="d-flex justify-content-start fs-5 text-danger text-center w-100 my-2">
              <span>
                <Translate value="instructions" />
                *:
              </span>
              <br />
              {/* Format supporting document should be in JPEG, PNG, or PDF
                            (Size contains 500 KB to 2 MB) */}
              <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
              <br />
              <Translate value="sizecontains500KBto2MB" />
            </p>

            <Col xs={12} className="family-col-3 form-inner-card mt-4">
              <p>
                <Translate value="listofFamilyMembers" />
              </p>
            </Col>

            <Col xs={12} className="family-col-3">
              {familyDetails.map(
                (data, index) =>
                  data.isShowing && (
                    <Row className={"familymember-card row"}>
                      {data.relation !== 49 &&
                        data.relation !== 50 &&
                        data.relation !== 51 &&
                        data.relation !== 52 && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: "0",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <img
                              alt="..."
                              src={closeIcon}
                              className="closebtn"
                              onClick={() => removeFamilyMember(index)}
                            />
                          </div>
                        )}
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >Relation :</p> */}
                            <Required title={<Translate value="relation" />} />
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                // value={allValues.membersList[i]["relation_id" + i]}
                                name="relation"
                                displayEmpty
                                value={data.relation}
                                disabled={
                                  !(
                                    users?.getUserRegistrationDetails
                                      ?.personal_details[0]
                                      ?.re_registration_count >= 1 ||
                                    !users?.getUserRegistrationDetails
                                      ?.personal_details[0]?.is_approved
                                  ) &&
                                  (data.relation === 49 ||
                                    data.relation === 50 ||
                                    data.relation === 51 ||
                                    data.relation === 52)
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {data.relation === 49 ||
                                data.relation === 50 ||
                                data.relation === 51 ||
                                data.relation === 52
                                  ? fetchRelation &&
                                    fetchRelation.length &&
                                    fetchRelation.map((i) => (
                                      <MenuItem value={i.value_id}>
                                        <ListItemText primary={i.value} />
                                      </MenuItem>
                                    ))
                                  : fetchRelation &&
                                    fetchRelation.length &&
                                    fetchRelation
                                      .filter(
                                        (row) =>
                                          row.value_id !== 49 &&
                                          row.value_id !== 50 &&
                                          row.value_id !== 51 &&
                                          row.value_id !== 52
                                      )
                                      .map((i) => (
                                        <MenuItem value={i.value_id}>
                                          <ListItemText primary={i.value} />
                                        </MenuItem>
                                      ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >First Name :</p> */}
                            <Required
                              title={<Translate value="labourName" />}
                            />
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <TextField
                                placeholder="Enter Your Name"
                                value={data.firstName}
                                name="firstName"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                // error={allValues.firstnameError}
                                // helperText={allValues.firstnameError ? "Please enter in correct format" : ""}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            {/* <p >DOB :</p> */}
                            <Required title={<Translate value="dOB" />} />
                          </Col>
                          <Col
                            xs={9}
                            style={{ borderBottom: "1px solid grey" }}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                maxDate={
                                  data.relation === 49 || data.relation === 50
                                    ? moment(
                                        users &&
                                          users.getUserRegistrationDetails
                                            .personal_details[0].date_of_birth
                                      ).subtract(18, "years")
                                    : moment()
                                }
                                minDate={moment().subtract(100, "years")}
                                // onClick={() => setPickerStatus(true)}
                                // onClose={() => setPickerStatus(false)}
                                // open={allValues.openDatePicker}
                                className="datepicker"
                                margin="normal"
                                format="DD/MM/YYYY"
                                // value={allValues.dob}
                                // value={selectedDate}
                                // onChange={ handleDateChange }
                                onKeyDown={handleKeyDown}
                                // onChange={(date) => { handleDateChange(date) }}
                                onChange={(date) =>
                                  handleDateChange(date, index, "dob")
                                }
                                value={data.dob && moment(data.dob)}
                                name="dob"
                                placeholder="DD/MM/YYYY"
                                // disabled={isAadhaarVerified ? true : false}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            <p>
                              <Translate value="profession" />* :
                            </p>
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                displayEmpty
                                value={data.profession}
                                name="profession"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {fetchProfession &&
                                  fetchProfession.length &&
                                  fetchProfession.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={3} className="member-p">
                            <p>
                              <Translate value="education" />*
                            </p>
                          </Col>
                          <Col xs={9}>
                            <FormControl fullWidth>
                              <Select
                                className="select-marital"
                                displayEmpty
                                value={data.education}
                                name="education"
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              >
                                <MenuItem value="">
                                  <ListItemText primary="-Select-" />
                                </MenuItem>
                                {fetchEducation &&
                                  fetchEducation.length &&
                                  fetchEducation.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="member-p">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(ev) =>
                                handleCheckBox(index, ev, "nominee")
                              }
                              color="primary"
                              checked={data.nominee}
                              name="nominee"
                            />
                          }
                          label={<Translate value="nominee" />}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </Col>

            <Col
              xs={12}
              className="addMemberCol mt-4 family-col-10"
              onClick={() => addNewFamilyMember("")}
            >
              <AddCircleRounded />
              <p>
                <Translate value="addMoreFamilyMembers" />
              </p>
            </Col>

            <Col xs={12} className="note2 text-center mt-4 family-col-11">
              {/* <p><span>Note :</span> After the completion of the mandatory fields in Family Details, then only the system will allow the further process.</p> */}
              <p>
                <span>
                  <Translate value="note" />
                  <Translate value="noteafterfurtherprocess" />
                </span>
              </p>
            </Col>
          </Row>

          <Row className="button-inside-form-row mb-5">
            <Col xs={12} className="next-back-button-row mt-4 ">
              <Button
                variant="contained"
                color="error"
                style={{ width: "15%" }}
                className="py-3 mx-1"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: "15%", whiteSpace: "nowrap" }}
                color="success"
                className="py-3 mx-1"
                type="button"
                onClick={handleSubmit}
              >
                Save your details
              </Button>
            </Col>
          </Row>
        </Row>
      </Row>
    </>
  );
};

export default FamilyDetails;
