import React from 'react';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import useApi from 'components/apiRequest/useApi';
import { CircularLoader } from 'components/ui/Loader';
import { headersWithToken } from 'http/customHeaders';
import { TableRoot } from 'components/TableRoot';

import { columns } from '../columns/DOBSurvey.columns.tsx';
const DOBSurveyReport = (props) => {
  const { data, loading, error } = useApi({
    url: '/survey/reports',
    options: {
      headers: headersWithToken(),
    },
  });

  return (
    <>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <div style={{ margin: '10px 4px' }}>
            <button
              style={{
                background: 'transparent',
                outline: 'none',
                border: 'none',
              }}
              onClick={props.handleBack}
            >
              <img alt="..." className="cal-icon hover-icn" src={back} />
            </button>
          </div>
          <TableRoot
            data={data?.data ?? []}
            columns={columns}
            containerStyles={{
              paddingBottom: '8px',
              minHeight: '50vh',
            }}
            tableStyle={{
              minWidth: '50vw',
              width: '80vw',
              overflowX: 'auto',
            }}
            rowStyles={{ paddingBlock: '4px', overflow: 'auto' }}
            cellStyles={{
              padding: '0.5rem',
              textAlign: 'center',
              border: '1px solid gray',
            }}
            paginationStyles={{
              minWidth: '50vw',
              width: '80vw',
              paddingBlock: '0.5rem',
              display: 'flex',
              justifyContent: 'end',
              gap: '0.5rem',
            }}
          />
        </>
      )}
    </>
  );
};

export default DOBSurveyReport;
