import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SERVER } from 'store/actions/user.actions';
import moment from 'moment';
import PopOver from '../PopOver';

const NintyDaysSelfDec = ({ status, union, fields }) => {
  const { schemeName } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileID, setFileId] = useState(null);
  const [deathFields, setDeathFields] = useState(null);

  const [isShowSelfDeclaration, setIsShowSelfDeclaration] = useState(false);

  const handleViewImage = (event, fileId) => {
    setAnchorEl(event.currentTarget);
    setFileId(fileId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleNewTab = (url) => {
    window.open(url, '_blank', 'noopener');
    handleClose();
  };
  const handleViewPDF = (fileId) => {
    const url = `${SERVER}/upload/file/${fileId}`;
    window.open(url, '_blank', 'noopener');
  };

  const shouldNotRender =
    schemeName === 'Funeral Expense and Death Assistance' ||
    schemeName === 'Continuation of Pension' ||
    schemeName === 'Continuation of Disability Pension';

  // const shouldNotRenderSelfDeclaration =
  //   schemeName === 'Funeral Expense and Death Assistance' ||
  //   schemeName === 'Continuation of Disability Pension';

  useEffect(() => {
    let showSelfDeclaration = true;
    if (
      schemeName === 'Funeral Expense and Death Assistance' ||
      schemeName === 'Continuation of Disability Pension'
    ) {
      showSelfDeclaration = false;
    } else if (schemeName === 'Accident Assistance' && fields) {
      const fieldValues = fields.filter(
        (_data) => _data.field === 'Accident Resulted In',
      );

      if (fieldValues.length > 0 && fieldValues[0].field_value === 'Death' || fieldValues[0].field_value === '27') {
        showSelfDeclaration = false;
      }
    }

    setIsShowSelfDeclaration(showSelfDeclaration);
  }, [fields, schemeName]);

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      {!shouldNotRender &&
        status &&
        status.catalog_value_type_of_issuer_id === 55 && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>
                  <Translate value="daysWorkCertificateDetails" />
                </p>
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} className="nintydays-col-5">
                <Row>
                  <Col xs={12} md={3}>
                    <p className="mb-2 mt-4">
                      <Translate value="typeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        value={'Labour Inspector'}
                        variant="outlined"
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="issuedDate" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={
                          status &&
                          moment(status.issue_date).format('DD-MM-YYYY')
                        }
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="labourInspectorName" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.labour_inspector_name}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="circleoftheLabourInspector" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.circle_name}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Row>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                      <div className="">
                        <p className="mt-4 mb-2"> 90 Days Work Certificate</p>
                        <div className="">
                          {status &&
                            status.employement_certificate_document_uploaded_id &&
                            status.employement_certificate_document_uploaded_id.map(
                              (document, index) => (
                                <FormControl fullWidth key={index}>
                                  <TextField
                                    value={document.file_name}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          {(document.file_name.includes(
                                            '.png',
                                          ) ||
                                            document.file_name.includes(
                                              '.jpeg',
                                            ) ||
                                            document.file_name.includes(
                                              '.jpg',
                                            )) && (
                                              <IconButton
                                                onClick={(ev) =>
                                                  handleViewImage(
                                                    ev,
                                                    document.file_id,
                                                    document.file_name,
                                                  )
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                          {document.file_name.includes(
                                            '.pdf',
                                          ) && (
                                              <IconButton
                                                onClick={() =>
                                                  handleViewPDF(document.file_id)
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    disabled
                                  />
                                </FormControl>
                              ),
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </>
        )}

      {!shouldNotRender &&
        status &&
        status.catalog_value_type_of_issuer_id === 56 && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>
                  <Translate value="daysWorkCertificateDetails" />
                </p>
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} className="nintydays-col-5">
                <Row>
                  <Col xs={12} md={3}>
                    <p className="mb-2 mt-4">
                      <Translate value="typeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={'Registered Trade Union'}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="issuedDate" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={
                          status &&
                          moment(status.issue_date).format('DD-MM-YYYY')
                        }
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="unionName" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={union && union.name}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="unionRegistrationNumber" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={
                          union && union.registeration_no
                            ? union.registeration_no
                            : 'NA'
                        }
                        disabled
                      />
                    </FormControl>
                  </Col>
                  {/* <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_name}
                        disabled
                      />
                    </FormControl>
                  </Col>  */}
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="placeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_place}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Row>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                      <div className="">
                        <p className="mt-4 mb-2"> 90 Days Work Certificate</p>
                        <div className="">
                          {status &&
                            status.employement_certificate_document_uploaded_id &&
                            status.employement_certificate_document_uploaded_id.map(
                              (document, index) => (
                                <FormControl fullWidth key={index}>
                                  <TextField
                                    value={document.file_name}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          {(document.file_name.includes(
                                            '.png',
                                          ) ||
                                            document.file_name.includes(
                                              '.jpeg',
                                            ) ||
                                            document.file_name.includes(
                                              '.jpg',
                                            )) && (
                                              <IconButton
                                                onClick={(ev) =>
                                                  handleViewImage(
                                                    ev,
                                                    document.file_id,
                                                    document.file_name,
                                                  )
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                          {document.file_name.includes(
                                            '.pdf',
                                          ) && (
                                              <IconButton
                                                onClick={() =>
                                                  handleViewPDF(document.file_id)
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    disabled
                                  />
                                </FormControl>
                              ),
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </>
        )}

      {!shouldNotRender &&
        status &&
        status.catalog_value_type_of_issuer_id === 57 && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>
                  <Translate value="daysWorkCertificateDetails" />
                </p>
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} className="nintydays-col-5">
                <Row>
                  <Col xs={12} md={3}>
                    <p className="mb-2 mt-4">
                      <Translate value="typeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={'Gram Panchayat PDO/Secretary'}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="issuedDate" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={
                          status &&
                          moment(status.issue_date).format('DD-MM-YYYY')
                        }
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_name}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="placeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_place}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Row>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                      <div className="">
                        <p className="mt-4 mb-2"> 90 Days Work Certificate</p>{' '}
                        <div className="">
                          {status &&
                            status.employement_certificate_document_uploaded_id &&
                            status.employement_certificate_document_uploaded_id.map(
                              (document, index) => (
                                <FormControl fullWidth key={index}>
                                  <TextField
                                    value={document.file_name}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          {(document.file_name.includes(
                                            '.png',
                                          ) ||
                                            document.file_name.includes(
                                              '.jpeg',
                                            ) ||
                                            document.file_name.includes(
                                              '.jpg',
                                            )) && (
                                              <IconButton
                                                onClick={(ev) =>
                                                  handleViewImage(
                                                    ev,
                                                    document.file_id,
                                                    document.file_name,
                                                  )
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                          {document.file_name.includes(
                                            '.pdf',
                                          ) && (
                                              <IconButton
                                                onClick={() =>
                                                  handleViewPDF(document.file_id)
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    disabled
                                  />
                                </FormControl>
                              ),
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </>
        )}

      {!shouldNotRender &&
        status &&
        status.catalog_value_type_of_issuer_id === 58 && (
          <>
            <Row className="scheme-subtitle-row">
              <Col xs={12} className="searchform-subtitle">
                <p>
                  <Translate value="daysWorkCertificateDetails" />
                </p>
              </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">
              <Col xs={12} className="nintydays-col-5">
                <Row>
                  <Col xs={12} md={3}>
                    <p className="mb-2 mt-4">
                      <Translate value="typeofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={'Employer of the construction activity'}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="issuedDate" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={
                          status &&
                          moment(status.issue_date).format('DD-MM-YYYY')
                        }
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_name}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={3}>
                    <p className="mt-4 mb-2">
                      <Translate value="designationofIssuer" />
                    </p>
                    <FormControl fullWidth className="formcontrol1">
                      <TextField
                        variant="outlined"
                        value={status && status.issuer_designation}
                        disabled
                      />
                    </FormControl>
                  </Col>
                  <Row>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                      <div className="">
                        <p className="mt-4 mb-2"> 90 Days Work Certificate</p>
                        <div className="">
                          {status &&
                            status.employement_certificate_document_uploaded_id &&
                            status.employement_certificate_document_uploaded_id.map(
                              (document, index) => (
                                <FormControl fullWidth key={index}>
                                  <TextField
                                    value={document.file_name}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          {(document.file_name.includes(
                                            '.png',
                                          ) ||
                                            document.file_name.includes(
                                              '.jpeg',
                                            ) ||
                                            document.file_name.includes(
                                              '.jpg',
                                            )) && (
                                              <IconButton
                                                onClick={(ev) =>
                                                  handleViewImage(
                                                    ev,
                                                    document.file_id,
                                                    document.file_name,
                                                  )
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                          {document.file_name.includes(
                                            '.pdf',
                                          ) && (
                                              <IconButton
                                                onClick={() =>
                                                  handleViewPDF(document.file_id)
                                                }
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    disabled
                                  />
                                </FormControl>
                              ),
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </>
        )}

      {isShowSelfDeclaration && (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>Self Declaration Document</p>
            </Col>
          </Row>
          <Row className="form-row mt-4 config-form searchform">
            <Col xs={6} className="final-personal-col-1 px-3 mt-4">
              <div className="">
                <p className="mt-4 mb-2">Self Declaration Form</p>
                <div className="">
                  {status &&
                    status.document_upload_selfdeclaration_files_xml &&
                    status.document_upload_selfdeclaration_files_xml.map(
                      (document, index) => (
                        <FormControl fullWidth key={index}>
                          <TextField
                            value={document.file_name}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {(document.file_name.includes('.png') ||
                                    document.file_name.includes('.jpeg') ||
                                    document.file_name.includes('.jpg')) && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handleViewImage(
                                            ev,
                                            document.file_id,
                                            document.file_name,
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                  {document.file_name.includes('.pdf') && (
                                    <IconButton
                                      onClick={() =>
                                        handleViewPDF(document.file_id)
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            disabled
                          />
                        </FormControl>
                      ),
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default NintyDaysSelfDec;
