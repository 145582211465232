import {
  Backdrop,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import appeal from 'assets/images/Appeal-05.svg';
import failedRed from 'assets/images/Failed svg-01.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
import arrowForward from 'assets/images/Icon ionic-ios-arrow-forward.svg';
import homewhite from 'assets/images/Icon material-dashboard.svg';
import home from 'assets/images/Icon material-home.svg';
import reports from 'assets/images/Icon material-report.svg';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import logout from 'assets/images/Logout.svg';
import pending from 'assets/images/Pending SVG-01.svg';
import pendingyellow from 'assets/images/Pending-01.svg';
import renewal from 'assets/images/Renewal-01.svg';
import schemes from 'assets/images/Schemes svg-01.svg';
import checkgreen from 'assets/images/checked-01.svg';
import closed from 'assets/images/clsoed-01 (1).svg';
import registerRed from 'assets/images/register-01-red.svg';
import register from 'assets/images/register-01.svg';
import tick from 'assets/images/tick-01 (1).svg';
import 'date-fns';
import React, { lazy, useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  SERVER,
  loginAdmin,
  setLocaleWithFallback,
} from 'store/actions/user.actions';
// import ClearIcon from '@mui/icons-material/Clear';
// import { getCatalogDetails,  SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'axios';
import ChangeNumber from 'components/adminPortal/changeNumber';
import { Link, useHistory } from 'react-router-dom';
import { getTokenCookie } from 'utils/tools';
import RenewalDetails from './RenewalDetails';
import BenificiarySchemes from './benificiarySchemes';
import KBOCWWB from './kbocwwb';
import DBTMISReports from './DBTMISReports';
import NEWMISReport from './NEWMISReport';
// import EducationSchemeForm from './EducationAssistanceScheme/EducationSchemeForm';
import EducationSchemeList from './EducationAssistanceScheme/EducationSchemeList';
import ReportDashboard from './ReportsDashboard/ReportDashboard';
import pension from 'assets/images/Continuation-of-pension-01-admin.svg';
import PensionData from './PensionData/PensionData';
import RegistrationDriveList from './registrationDriveList';
import SchemesList from './SchemesList';
import RegistrationList from './RegistrationList';
import BenificiarySchemesNew from './benificiarySchemes';
import RenewalList from './RenewalList';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import UpdateNominee from './NomineeNameUpdate';
import BacklogDashboard from './BackLog/BacklogDashboard';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SuspenseProvider from 'components/context/SuspenseProvider';
import { DataContext } from 'context/config';
import Home from 'components/schemeMeta';
const AdminHospitalList = lazy(() => import('./hospitals/AdminHospitalList'));
const Dashboard = (props) => {
  const history = useHistory();

  const [topBar, setBar] = useState(100);
  const topbarRef = useRef();
  let id = props.location.pathname.split('/').at(-1);
  if (id > 0 || id < 10) {
    id = +id;
  } else {
    id = 1;
  }

  const dispatch = useDispatch();

  const { data: configData } = useContext(DataContext);

  const users = useSelector((state) => state.users);

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    // activestep: users.profile_details.activestep,
    activestep: id || 1,
    percentComplete: users.profile_details.percentComplete,
    editmode: users.profile_details.editmode,

    // get_registration_counts: null
    // get_registration_counts: {
    //     "approved": {
    //         "total_registrations": "-"
    //     },
    //     "pending": {
    //         "total_registrations": "-"
    //     },
    //     "rejected": {
    //         "total_registrations": "-"
    //     },
    //     "seekClarificationData": {
    //         "total_registrations": "-"
    //     },
    //     "total": {
    //         "total_registrations": "-"
    //     }
    // },
    // get_scheme_counts: null
    // get_scheme_counts: {
    //     "approved": {
    //         "total_scheme_for_approval": "-"
    //     },
    //     "pending": {
    //         "total_scheme_for_approval": "-"
    //     },
    //     "rejected": {
    //         "total_scheme_for_approval": "-"
    //     },
    //     "total": {
    //         "total_scheme_for_approval": "-"
    //     }
    // }
  });

  //useEffect(()=>{
  //    if(allValues.activestep === 1)
  //    {
  //        setOpenBackdrop(true)
  //    }
  //
  //},[allValues.activestep])
  //Routing
  //useEffect(()=>{
  //    let activestep = +(props.location.pathname.split('/').at(-1))
  //    if(isNaN(activestep)){
  //        activestep=1
  //    }
  //    setAllValues({
  //        ...allValues,
  //        activestep
  //    })
  //
  //},[props.location.pathname])

  const handleChange = (event) => {
    console.log(event.target);
    console.log(event.target.value);
    setAllValues({
      ...allValues,

      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'language')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  //Routing
  // useEffect(()=>{
  //     let activestep = +(props.location.pathname.split('/').at(-1))
  //     if(isNaN(activestep)){
  //         activestep=1
  //     }
  //     setAllValues({
  //         ...allValues,
  //         activestep
  //     })
  //
  // },[props.location.pathname])
  //
  // const onItemClickHandler = (num) => {
  //
  //     // setAllValues({
  //     //     ...allValues,
  //     //     activestep: num
  //     // })
  //     const url = "/admin/dashboard/"+num
  //     props.history.push(url)
  //
  //     if(num === 4) {
  //         localStorage.setItem("Tab_name","Renewals")
  //     }
  //     else if(num === 2)
  //     {
  //          localStorage.setItem("Tab_name","Registartion")
  //     }
  // }

  const onItemClickHandler = (num) => {
    setAllValues({
      ...allValues,
      activestep: num,
    });
    //const url = "/admin/dashboard/"+num
    //props.history.push(url);

    history.push(`/admin/dashboard/${num}`);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: '350px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElksu, setAnchorElksu] = React.useState(null);
  const [anchorElconfig, setAnchorElconfig] = React.useState(null);
  const [anchorElmapping, setAnchorElmapping] = React.useState(null);
  const [circlename, setcirclename] = React.useState(null);
  const [profilebtn, setprofilebtn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffffee',
      color: '#000',
      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));

  const classes = useStyles();

  const onSelectClickHandlerkboc = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosekboc = (event, data) => {
    setAnchorEl(null);
  };

  const onSelectClickHandlerksu = (event, data) => {
    setAnchorElksu(event.currentTarget);
  };
  const handleCloseksu = (event, data) => {
    setAnchorElksu(null);
  };

  const onSelectClickHandlerconfig = (event, data) => {
    setAnchorElconfig(event.currentTarget);
  };
  const handleCloseconfig = (event, data) => {
    setAnchorElconfig(null);
  };

  const onSelectClickHandlermapping = (event, data) => {
    setAnchorElmapping(event.currentTarget);
  };
  const handleClosemapping = (event, data) => {
    setAnchorElmapping(null);
  };

  const logoutClicked = () => {
    dispatch(loginAdmin('delete'));
    props.history.push('/admin');
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  useEffect(() => {}, [allValues]);

  // useEffect(() => {
  //     if(users.login_admin.data === null) {
  //         props.history.goBack()

  //     }
  // },[users.login_admin])

  useEffect(() => {
    if (
      users.getDeptUserRole.data[0].role_name !== undefined &&
      users.getDeptUserRole.data[0].role_name === 'Joint Secretary'
    ) {
      // setAllValues({
      //     ...allValues,
      //     activestep: 5,
      // })
    } else if (allValues.activestep === 1) {
      callDataApis();
    }
  }, [allValues.activestep]);

  useEffect(() => {
    const handlePopstate = () => {
      // Reload the page on popstate (back or forward button)
      window.location.reload();
    };

    // Add the event listener when the component mounts
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const callDataApis = () => {
    var get_registration_counts = null;
    var get_scheme_counts = null;
    var data = JSON.stringify({
      board_id: 1,
      department_user_id:
        users.login_admin !== null
          ? users.login_admin.data.department_user_id
          : null,
      status: 'all',
    });
    var config = {
      method: 'post',
      url: SERVER + '/dashboard/get_registration_counts',
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        get_registration_counts = response.data.data;

        var data2 = JSON.stringify({
          board_id: 1,
          department_user_id: users.login_admin.data.department_user_id,
          status: 'all',
        });

        var config2 = {
          method: 'post',
          url: SERVER + '/dashboard/get_scheme_counts',
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data2,
        };

        axios(config2)
          .then(function (response) {
            setAllValues({
              ...allValues,
              // ...allValues.get_registration_counts,

              get_registration_counts: get_registration_counts,
              get_scheme_counts: response.data.data,
            });
            //setOpenBackdrop(false)
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // console.log("in useEffect [users.user]")
    // if (users.user !== null && users.user.board_id !== null) {

    // let dataForCatalog = { "board_id": 1, "catalog_name": "Registration Status" };
    // // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
    // dispatch(getCatalogDetails(dataForCatalog))
    // }
    // }

    if (
      users.getDeptUserRole !== null &&
      users.getDeptUserRole.data !== undefined &&
      users.getDeptUserRole.data.length > 0
    ) {
      let data = JSON.stringify({
        board_id: users.getDeptUserRole.data[0].board_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
      });
      var config = {
        method: 'post',
        url: SERVER + '/config/circle/get_user_mapped_to_circle',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setcirclename(response.data.data);
        })
        .catch(function (error) {});
    }
  }, [users.user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.clear();

    var myItem = localStorage.getItem('persist:users');
    localStorage.clear();
    localStorage.setItem('persist:users', myItem);

    if (users.login_admin === null || users.login_admin.data === undefined) {
      props.history.push('/admin');
      // props.history.goBack();
    }
    // else{
    //     callDataApis()
    // }
    else if (
      users.getDeptUserRole.data[0].role_name !== undefined &&
      users.getDeptUserRole.data[0].role_name === 'Joint Secretary'
    ) {
      setAllValues({
        ...allValues,
        activestep: 5,
      });
    }

    // dispatch(deleteReduxData("getUserRegistrationDetails"))
  }, []);

  const goBackDashboard = () => {
    setprofilebtn(false);
  };

  const openDrawer = () => {
    setOpen(!open);
  };

  const toggleDrawer = () => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    setOpen(false);
    // setState({ ...state, [anchor]: open });
  };
  useEffect(() => {
    const resizeHandler = () => {
      setBar(topbarRef.current.clientHeight);
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
  return (
    <>
      <div className="root">
        {/* <CssBaseline /> */}
        <Row className="top-div" ref={topbarRef}>
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="dashboard-drawer">
                <MenuIcon fontSize="large" onClick={openDrawer} />
              </div>
              <div className="logo-div-profile">
                <a href="/admin/home">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  Karnataka Building & Other Construction Workers Welfare Board
                  (KBOCWWB) <br />
                  Government of Karnataka
                </h1>
              </div>
            </Col>

            <Col className="notifications-logout-usericon-dashboard">
              <div className="usericon-header-logout">
                <img
                  alt="..."
                  src={usericon}
                  onClick={() => setprofilebtn(true)}
                  className="usericon-img"
                />
                <Modal
                  open={profilebtn}
                  onClose={() => setprofilebtn(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="modal-circle-main"
                >
                  <Box className="modal_box1">
                    {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                      Duis mollis, est non commod
                                      
                                      <Co luctus, nisi erat porttitor ligula.
                                      </Typography> */}
                    <div className="logo-div-modal">
                      {/* <a href="/admin/dashboard"> */}
                      <img
                        id="logo"
                        style={{ marginTop: '60px' }}
                        src={logo}
                        alt="..."
                        className="logo-modal"
                      />
                      {/* </a> */}
                      {/* <img id="logo" src={logo} alt="..." className="logo-img" /> */}
                      <h1 className="logo-modal1" style={{ marginTop: '60px' }}>
                        Karnataka Building & Other Construction Workers Welfare
                        Board(K.B.O.C.W.W.B)
                        <br />
                        Government of Karnataka
                      </h1>
                      <div
                        style={{ marginLeft: '50px', marginTop: '50px' }}
                        onClick={goBackDashboard}
                      >
                        <CancelIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="modal_box-circle">
                      <div className="modal-username">
                        Username
                        <div className="modal-username1">
                          {users?.login_admin?.data?.name}
                        </div>
                      </div>

                      <div className="modal-circle1">
                        <div className="modal-circle2">
                          Circle Name
                          <div className="modal-circle3">
                            {circlename !== null &&
                              circlename.map((x, index) => (
                                <li key={index} className="list_circle">
                                  {x.circle_name}
                                </li>
                              ))}
                          </div>
                        </div>
                        <div className="modal-circle4">
                          Circle ID
                          <div className="modal-circle3">
                            {circlename !== null &&
                              circlename.map((x, index) => (
                                <li key={index} className="list_circle">
                                  {x.circle_id}
                                </li>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <p>{users?.login_admin?.data?.name}</p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    alt="..."
                    src={logout}
                    className="logout-img"
                    onClick={() => {
                      props.history.push('/admin');
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>

        <Drawer
          anchor="left"
          open={open}
          className="dashboard-drawer"
          onClose={() => toggleDrawer()}
          // className="drawer dashboard"
          // variant="permanent"
        >
          <div
            className="drawerContainer2"
            style={{
              // marginTop: `${topBar}px !important`,
              marginTop: `${topbarRef.current?.clientHeight}px !important`,
            }}
            // itemRef={topbarRef}
          >
            <List
              className="drawer-list"
              style={{
                marginTop: `${topBar}px !important`,
              }}
            >
              {users.getDeptUserRole.data[0].role_name !== undefined &&
              (users.getDeptUserRole.data[0].role_name === 'Admin' ||
                users.getDeptUserRole.data[0].role_name ===
                  'Joint Secretary') ? null : (
                <>
                  <ListItem
                    button
                    key=""
                    className={`listitem ${
                      allValues.activestep === 1 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>

                  {users.getDeptUserRole.data[0].board_id !== undefined &&
                  users.getDeptUserRole.data[0].board_id === 1 &&
                  users.getDeptUserRole.data[0].role_id !== undefined &&
                  users.getDeptUserRole.data[0].role_id === 1 ? (
                    <Link
                      style={{ color: 'black' }}
                      to={{
                        pathname: '/',
                      }}
                      // params={{frompage: "admin"}}
                      target="_blank"
                    >
                      <ListItem
                        button
                        key=""
                        className={`listitem 
                                      ${
                                        allValues.activestep === 7 &&
                                        'activeitem'
                                      }`}
                      >
                        <ListItemIcon>
                          <img
                            alt="..."
                            className={
                              allValues.activestep === 7
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            src={register}
                          />
                        </ListItemIcon>
                        <ListItemText primary="New Registration" />
                      </ListItem>
                    </Link>
                  ) : null}

                  <ListItem
                    button
                    key=""
                    className={`listitem ${
                      allValues.activestep === 2 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(2)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 2
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={register}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Registration Details" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem ${
                      allValues.activestep === 3 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(3)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 3
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={schemes}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Schemes Beneficiaries" />
                    <ListItemText primary="Schemes Beneficiaries" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem ${
                      allValues.activestep === 4 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(4)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 4
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={renewal}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Renewals" />
                  </ListItem>
                  <ListItem
                    button
                    key=""
                    className={`listitem ${
                      allValues.activestep === 6 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(6)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 6
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={reports}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Report" />
                  </ListItem>
                </>
              )}
            </List>
          </div>
        </Drawer>

        <Drawer
          className="drawer dashboard"
          variant="permanent"
          style={{
            // position: 'absolute',
            // marginTop: '0 !important',
            // marginTop: document.querySelector('.top-div'),
            // marginTop: `${topBar}px !important`,
            marginTop: `${topbarRef.current?.clientHeight}px !important`,
          }}
        >
          <div
            className="drawerContainer2"
            style={{
              // position: 'absolute',
              // marginTop: '0 !important',
              // marginTop: document.querySelector('.top-div'),
              marginTop: `${topBar}px !important`,
            }}
          >
            {users.getDeptUserRole.data[0].role_name !== undefined &&
            users.getDeptUserRole.data[0].role_name === 'Joint Secretary' ? (
              <ListItem
                button
                key="3"
                className={`listitem ${
                  allValues.activestep === 3 && 'activeitem'
                }`}
                onClick={() => onItemClickHandler(3)}
              >
                <ListItemIcon>
                  <img
                    alt="..."
                    className={
                      allValues.activestep === 3
                        ? 'dashboardIconActive'
                        : 'dashboardIcon'
                    }
                    src={schemes}
                  />
                </ListItemIcon>
                <ListItemText primary="Schemes Beneficiaries" />
              </ListItem>
            ) : (
              <div
                style={{
                  paddingTop: `${topBar}px !important`,
                  // backgroundColor: 'yellow',
                  height: '100%',
                  marginTop: `${topBar}px`,
                }}
              >
                <List className="drawer-list">
                  <ListItem
                    button
                    key="1"
                    className={`listitem ${
                      allValues.activestep === 1 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(1)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        src={allValues.activestep === 1 ? homewhite : home}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  {users.getDeptUserRole.data[0].board_id !== undefined &&
                  users.getDeptUserRole.data[0].board_id === 1 &&
                  users.getDeptUserRole.data[0].role_id !== undefined &&
                  (users.getDeptUserRole.data[0].role_id === 1 ||
                    users.getDeptUserRole.data[0].role_name !==
                      'Registration Drive') ? (
                    <Link
                      style={{ color: 'black' }}
                      to={`/register`}
                      // params={{frompage: "admin"}}
                      target="_blank"
                    >
                      <ListItem
                        button
                        key=""
                        className={`listitem ${
                          allValues.activestep === 7 && 'activeitem'
                        }`}
                      >
                        <ListItemIcon>
                          <img
                            alt="..."
                            className={
                              allValues.activestep === 7
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            src={register}
                          />
                        </ListItemIcon>
                        <ListItemText primary="New Registration" />
                      </ListItem>
                    </Link>
                  ) : null}
                  {users.getDeptUserRole.data[0].role_name ===
                  'Registration Drive' ? (
                    <Link
                      style={{ color: 'black' }}
                      to={`/register/${users.getDeptUserRole.data[0].department_user_id}`}
                      // params={{frompage: "admin"}}
                      target="_blank"
                    >
                      <ListItem
                        button
                        key=""
                        className={`listitem ${
                          allValues.activestep === 15 && 'activeitem'
                        }`}
                      >
                        <ListItemIcon>
                          <img
                            alt="..."
                            className={
                              allValues.activestep === 15
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            src={register}
                          />
                        </ListItemIcon>
                        <ListItemText primary="New Registration Drive" />
                      </ListItem>
                    </Link>
                  ) : null}
                  {users.getDeptUserRole.data[0].role_name !==
                    'Registration Drive' && (
                    <ListItem
                      button
                      key="2"
                      className={`listitem ${
                        allValues.activestep === 2 && 'activeitem'
                      }`}
                      onClick={() => onItemClickHandler(2)}
                    >
                      <ListItemIcon>
                        <img
                          alt="..."
                          className={
                            allValues.activestep === 2
                              ? 'dashboardIconActive'
                              : 'dashboardIcon'
                          }
                          src={register}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Registration Details" />
                    </ListItem>
                  )}
                  <ListItem
                    button
                    key="4"
                    className={`listitem ${
                      allValues.activestep === 4 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(4)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 4
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={renewal}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Renewal Details" />
                  </ListItem>
                  {users.getDeptUserRole.data[0].role_name ===
                    'Registration Drive' && (
                    <ListItem
                      button
                      key="16"
                      className={`listitem ${
                        allValues.activestep === 16 && 'activeitem'
                      }`}
                      onClick={() => onItemClickHandler(16)}
                    >
                      <ListItemIcon>
                        <img
                          alt="..."
                          className={
                            allValues.activestep === 16
                              ? 'dashboardIconActive'
                              : 'dashboardIcon'
                          }
                          src={register}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Registration Drive Details" />
                    </ListItem>
                  )}
                  {/* {users.getDeptUserRole.data[0].role_name ===
                    'Labour Inspector' && (
                    <ListItem
                      button
                      key="17"
                      className={`listitem ${
                        allValues.activestep === 17 && 'activeitem'
                      }`}
                      onClick={() => onItemClickHandler(16)}
                    >
                      <ListItemIcon>
                        <img
                          alt="..."
                          className={
                            allValues.activestep === 17
                              ? 'dashboardIconActive'
                              : 'dashboardIcon'
                          }
                          src={register}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Registration Drive Details" />
                    </ListItem>
                  )} */}
                  <ListItem
                    button
                    key="3"
                    className={`listitem ${
                      allValues.activestep === 3 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(3)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 3
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={schemes}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Schemes Beneficiaries" />
                  </ListItem>
                  {users.getDeptUserRole.data[0].role_name ===
                  'Assistant Labour Commissioner' ? (
                    <>
                      <ListItem
                        button
                        key="12"
                        className={`listitem ${
                          allValues.activestep === 12 && 'activeitem'
                        }`}
                        onClick={() => onItemClickHandler(12)}
                      >
                        <ListItemIcon>
                          <img
                            width={30}
                            height={30}
                            alt="..."
                            className={
                              allValues.activestep === 12
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            src={pension}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Manual Pension Data Update" />
                      </ListItem>
                    </>
                  ) : null}

                  {users.getDeptUserRole.data[0].role_name ===
                    'Labour Inspector' && (
                    <>
                      <ListItem
                        button
                        key="20"
                        className={`listitem ${
                          allValues.activestep === 20 && 'activeitem'
                        }`}
                        onClick={() => onItemClickHandler(20)}
                      >
                        <ListItemIcon>
                          <img
                            width={30}
                            height={30}
                            alt="..."
                            className={
                              allValues.activestep === 20
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            src={pension}
                          />
                        </ListItemIcon>
                        <ListItemText primary="BackLog Data Updation" />
                      </ListItem>
                    </>
                  )}

                  <ListItem
                    button
                    key="17"
                    className={`listitem ${
                      allValues.activestep === 17 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(17)}
                  >
                    <ListItemIcon>
                      <>
                        <ManageHistoryIcon
                          className={
                            allValues.activestep === 17
                              ? 'dashboardIconActive'
                              : 'dashboardIcon'
                          }
                          sx={{ color: 'grey', fontSize: 28 }}
                        />
                      </>
                    </ListItemIcon>
                    <ListItemText primary="Renewal Schemes Beneficiaries" />
                  </ListItem>
                  {/* <ListItem
                    button
                    key="11"
                    className={`listitem ${
                      allValues.activestep === 11 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(11)}
                  >
                    <ListItemIcon>
                      <img
                        alt="..."
                        className={
                          allValues.activestep === 11
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        src={schemes}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Education Assitance Validation" />
                  </ListItem> */}
                  <ListItem
                    button
                    key="5"
                    className={`listitem ${
                      allValues.activestep === 6 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(6)}
                  >
                    <ListItemIcon>
                      <AssessmentRoundedIcon
                        className={
                          allValues.activestep === 6
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                  </ListItem>
                  {/* {users.login_admin.data.department_user_id === 421 ? 
                                      <ListItem button key="10" className={`listitem ${allValues.activestep === 10 && 'activeitem'}`}
                                          onClick={() => onItemClickHandler(10)} >
                                          <ListItemIcon>
                                              <img alt="..."
                                                  width={"23"} height={"23"}
                                                  style={{borderRadius:'50%',}}
                                                  className={allValues.activestep === 10 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                  src={msi} />
                                          </ListItemIcon>
                                          <ListItemText primary="MIS Reports" />

                                      </ListItem>
                                      : null} */}
                  <Link
                    style={{ color: 'black' }}
                    to={{
                      pathname: '/newmisreport',
                    }}
                    // params={{frompage: "admin"}}
                    target="_blank"
                  >
                    {/* <ListItem button key="10" className={`listitem ${allValues.activestep === 10 && 'activeitem'}`}
                                          // onClick={() => onItemClickHandler(10)}
                                          >
                                          <ListItemIcon>
                                              <img alt="..."
                                                  width={"23"} height={"23"}
                                                  style={{borderRadius:'50%',}}
                                                  className={allValues.activestep === 10 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                  src={msi} />
                                          </ListItemIcon>
                                          <ListItemText primary="Seva Sindhu Reports" />

                                      </ListItem> */}
                  </Link>
                  {users.getDeptUserRole.data[0].role_name !== undefined &&
                  users.getDeptUserRole.data[0].role_name === 'Admin' ? (
                    <>
                      {/* <ListItem button key="7" className={`listitem ${allValues.activestep === 7 && ''}`}
                                              onClick={() => onItemClickHandler(7)} >
                                              <ListItemIcon>
                                                  <img alt="..."
                                                      className={allValues.activestep === 7 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                      src={reports} />
                                              </ListItemIcon>
                                              <ListItemText primary="DBT XML" />

                                          </ListItem> */}
                      {/* <ListItem button key="7" className={`listitem ${allValues.activestep === 9 && 'activeitem'}`}
                                              onClick={() => onItemClickHandler(9)} >
                                              <ListItemIcon>
                                                  <CurrencyRupeeRoundedIcon
                                                      className={allValues.activestep === 9 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                      />
                                              </ListItemIcon>
                                              <ListItemText primary="DBT Report" />

                                          </ListItem> */}
                    </>
                  ) : null}
                  {/*
                                  
                                      {users.getDeptUserRole.data[0].role_name !== undefined &&
                                          users.getDeptUserRole.data[0].role_name === "Admin" ?
                                          <>

                                          <ListItem button key="7" className={`listitem ${allValues.activestep === 7 && ''}`}
                                              onClick={() => onItemClickHandler(7)} >
                                              <ListItemIcon>
                                                  <img alt="..."
                                                      className={allValues.activestep === 7 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                      src={reports} />
                                              </ListItemIcon>
                                              <ListItemText primary="DBT XML" />

                                          </ListItem>
                                          <ListItem button key="7" className={`listitem ${allValues.activestep === 9 && ''}`}
                                              onClick={() => onItemClickHandler(9)} >
                                              <ListItemIcon>
                                                  <img alt="..."
                                                      className={allValues.activestep === 9 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                      src={reports} />
                                              </ListItemIcon>
                                              <ListItemText primary="DBT Report" />

                                          </ListItem>
                                          </>

                                          

                                          : null
                                      }
                                  */}
                  {users.getDeptUserRole.data[0].board_id !== undefined &&
                  users.getDeptUserRole.data[0].board_id === 1 &&
                  users.getDeptUserRole.data[0].role_id !== undefined &&
                  users.getDeptUserRole.data[0].role_id === 1 ? (
                    <ListItem
                      button
                      key="6"
                      className={`listitem ${
                        allValues.activestep === 8 && 'activeitem'
                      }`}
                      onClick={() => onItemClickHandler(8)}
                    >
                      <ListItemIcon>
                        <img
                          alt="..."
                          className={
                            allValues.activestep === 8
                              ? 'dashboardIconActive'
                              : 'dashboardIcon'
                          }
                          src={renewal}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Change Mobile Number" />
                    </ListItem>
                  ) : null}

                  <ListItem
                    button
                    key="18"
                    className={`listitem ${
                      allValues.activestep === 18 && 'activeitem'
                    }`}
                    onClick={() => onItemClickHandler(18)}
                  >
                    <ListItemIcon>
                      <SyncAltIcon
                        className={
                          allValues.activestep === 18
                            ? 'dashboardIconActive'
                            : 'dashboardIcon'
                        }
                        sx={{ color: 'grey', fontSize: 28 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Nominee Update" />
                  </ListItem>
                  {configData &&
                    configData?.showSchemeMetaData == 'Y' &&
                    users.getDeptUserRole.data[0].role_id === 5 && (
                      <ListItem
                        button
                        key="19"
                        className={`listitem ${
                          allValues.activestep === 19 && 'activeitem'
                        }`}
                        onClick={() => onItemClickHandler(19)}
                      >
                        <ListItemIcon>
                          <SyncAltIcon
                            className={
                              allValues.activestep === 18
                                ? 'dashboardIconActive'
                                : 'dashboardIcon'
                            }
                            sx={{ color: 'grey', fontSize: 28 }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Scheme MetaData" />
                      </ListItem>
                    )}
                </List>
              </div>
            )}
          </div>
        </Drawer>

        <main className="content dashboard">
          {
            {
              1: (
                <>
                  {/* {callDataApis()} */}
                  <Row
                    className="userpage-row"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    <Col xs={12} className="title">
                      Registration Status
                    </Col>

                    <Col xs={0} sm={0} md={1} className="blankcol"></Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={2}
                      className="dashboard-registration-col"
                      style={{ flexShrink: 0 }}
                    >
                      <div className="dashboard-registration-div ">
                        <div>
                          <p>
                            {allValues.get_registration_counts !== undefined &&
                              allValues.get_registration_counts !== null &&
                              allValues.get_registration_counts.total
                                .total_registrations}
                            <br />
                            Registrations
                          </p>
                          <div>
                            <img alt="..." src={registerRed} />
                          </div>
                        </div>
                        <p>Total Registrations Count</p>
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={2}
                      className="dashboard-registration-col"
                      style={{ flexShrink: 0 }}
                    >
                      <div className="dashboard-registration-div-approved ">
                        <div>
                          <p>
                            {allValues.get_registration_counts !== undefined &&
                              allValues.get_registration_counts !== null &&
                              allValues.get_registration_counts.approved
                                .total_registrations}{' '}
                            Registrations Approved
                          </p>
                          <img alt="..." src={tick} />
                        </div>
                        <p>Approved Registrations Count</p>
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={2}
                      className="dashboard-registration-col"
                      style={{ flexShrink: 0 }}
                    >
                      <div className="dashboard-registration-div-appealed ">
                        <div>
                          <p>
                            {allValues.get_registration_counts !== undefined &&
                              allValues.get_registration_counts !== null &&
                              allValues.get_registration_counts.appealed
                                .total_registrations}{' '}
                            Appealed
                          </p>
                          <img alt="..." src={appeal} />
                        </div>
                        <p>Appealed Registrations Count</p>
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={2}
                      className="dashboard-registration-col"
                      style={{ flexShrink: 0 }}
                    >
                      <div className="dashboard-registration-div-pending ">
                        <div>
                          <p>
                            {allValues.get_registration_counts !== undefined &&
                              allValues.get_registration_counts !== null &&
                              allValues.get_registration_counts.pending
                                .total_registrations}{' '}
                            Registrations Pending
                          </p>
                          <img alt="..." src={pending} />
                        </div>
                        <p>Pending Registrations Count</p>
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={2}
                      className="dashboard-registration-col"
                      style={{ flexShrink: 0 }}
                    >
                      <div className="dashboard-registration-div failed-div">
                        <div>
                          <p>
                            {allValues.get_registration_counts !== undefined &&
                              allValues.get_registration_counts !== null &&
                              allValues.get_registration_counts.rejected
                                .total_registrations}{' '}
                            Registrations Rejected
                          </p>
                          <img alt="..." src={failedRed} />
                        </div>
                        <p>Rejected Registrations Count</p>
                      </div>
                    </Col>
                    <Col xs={0} sm={0} md={1} className="blankcol"></Col>
                  </Row>

                  <Row className="userpage-row dashboard-schemes-row">
                    <Col xs={12} className="title">
                      Beneficiaries Schemes
                    </Col>
                    <Col xs={4} sm={4} md={4} className="dashboard-schemes-col">
                      <div className="dashboard-schemes-div">
                        <div>
                          <div className="innerdiv1">
                            <p>
                              {allValues.get_scheme_counts !== undefined &&
                                allValues.get_scheme_counts !== null &&
                                allValues.get_scheme_counts.total
                                  .total_scheme_for_approval}
                              <br />
                              Schemes
                            </p>
                            <img alt="..." src={schemes} />
                          </div>
                          <div className="innerdiv2">
                            <p>Total Beneficiaries Schemes</p>
                            <img alt="..." src={arrowForward} />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} md={4} className="dashboard-schemes-col">
                      <div className="dashboard-schemes-approved-div">
                        <div>
                          <div className="imgbackwhite">
                            <img alt="..." src={checkgreen} />
                          </div>
                          <p>
                            {allValues.get_scheme_counts !== undefined &&
                              allValues.get_scheme_counts !== null &&
                              allValues.get_scheme_counts.approved
                                .total_scheme_for_approval}{' '}
                            Schemes Approved
                          </p>
                          <div className="innerdiv2">
                            <p>Total Beneficiaries Schemes Approved</p>
                            <img alt="..." src={arrowForward} />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} md={4} className="dashboard-schemes-col">
                      <Row className="dashboard-row-schemes">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          className="dashboard-rejected-col-inner"
                        >
                          <div className="rejectedineerdiv1">
                            <img alt="..." src={closed} />
                          </div>
                          <div className="rejectedineerdiv2">
                            <p className="inner-p-1">
                              {allValues.get_scheme_counts !== undefined &&
                                allValues.get_scheme_counts !== null &&
                                allValues.get_scheme_counts.rejected
                                  .total_scheme_for_approval}{' '}
                              Schemes Rejected
                            </p>
                            <p className="inner-p-2">
                              Total Beneficiaries Schemes Rejected
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          className="dashboard-rejected-col-inner inner-2"
                        >
                          <div className="rejectedineerdiv1">
                            <img alt="..." src={pendingyellow} />
                          </div>
                          <div className="rejectedineerdiv2">
                            <p className="inner-p-1">
                              {allValues.get_scheme_counts !== undefined &&
                                allValues.get_scheme_counts !== null &&
                                allValues.get_scheme_counts.pending
                                  .total_scheme_for_approval}{' '}
                              Schemes Pending
                            </p>
                            <p className="inner-p-2">
                              Total Beneficiaries Schemes Pending
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ),

              2: <RegistrationList />,
              3: <SchemesList />,
              4: <RenewalList />,
              5: (
                <>
                  <iframe
                    title="powerbi"
                    width="100%"
                    height="85%"
                    src="https://app.powerbi.com/view?r=eyJrIjoiZjE0ZGZkZDgtMDRiYS00YmM2LThhOWMtMmI1MmE0YzdiOWRiIiwidCI6ImQyMjdhZTJmLThhZTUtNDBjYy1hN2YzLWZjMjQ5MTM1ZmEyYiJ9"
                    frameborder="0"
                    allowFullScreen="true"
                  ></iframe>
                </>
              ),
              6: <ReportDashboard />,

              8: <ChangeNumber />,
              9: <DBTMISReports />,

              10: <NEWMISReport />,
              11: <EducationSchemeList />,
              12: <PensionData />,
              16: <RegistrationDriveList />,
              17: <SchemesList />,
              18: <UpdateNominee />,
              19: <Home />,
              20: (
                <SuspenseProvider>
                  <AdminHospitalList />
                </SuspenseProvider>
              ),
              20: <BacklogDashboard />,
            }[allValues.activestep]
          }
        </main>
      </div>
    </>
  );
};

export default Dashboard;
// {/* 9:<DBTReport />, */}
// 7:<DBTXML />
