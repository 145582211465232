import React, { useEffect } from 'react';
import PersonalDetails from './LabourRegistrationDetails/PersonalDetails';
import AddressDetails from './LabourRegistrationDetails/AddressDetails';
import FamilyDetails from './LabourRegistrationDetails/FamilyDetails';
import BankDetails from './LabourRegistrationDetails/BankDetails';
import NintyDaysDetails from './LabourRegistrationDetails/NintyDaysDetails';

const LabourDetails = ({
  personalDetails,
  addressDetails,
  familyDetails,
  rationCardDetails,
  bankDetails,
  certificateDetails,
  employerDetails,
}) => {
  return (
    <div>
      <PersonalDetails data={personalDetails} />
      <AddressDetails data={addressDetails} />
      <FamilyDetails rationCard={rationCardDetails} data={familyDetails} />
      <BankDetails data={bankDetails} personalData={personalDetails} />
      <NintyDaysDetails
        certificate={certificateDetails}
        data={employerDetails}
        personalDetails={personalDetails}
      />
    </div>
  );
};

export default LabourDetails;
