import * as React from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';

import { CircularProgress } from '@material-ui/core';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RejectedReasonsDialog({
  open,
  handleClose,
  reasons,
  oldReasons,
  serviceName,
  loading,
  value,
  handleChange,
}) {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} style={{ width: '100%' }}>
        <DialogTitle>
          ತಿರಸ್ಕರಿಸಿದ ಕಾರಣಗಳು / Rejected Reasons{' '}
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Choose Language
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="eng"
                control={<Radio />}
                label="English"
              />
              <FormControlLabel
                value="kan"
                control={<Radio />}
                label="Kannada"
              />
            </RadioGroup>
          </FormControl>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{serviceName}</DialogContentText>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <CircularProgress />
              </div>
            ) : reasons && reasons.length ? (
              reasons.map((row, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemText
                      primary={`${index + 1}. ${
                        value === 'eng'
                          ? row.rejection_reason
                          : value === 'kan'
                            ? row.rejection_reason_kannada
                            : ''
                      }`}
                    />
                  </ListItem>
                );
              })
            ) : (
              <div>1.{oldReasons}</div>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
