import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MenuItem, Select } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import MomentUtils from '@date-io/moment';
import {
  CircularProgress,
  Backdrop,
  ListItemText,
  FormControl,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment/moment';
import DownloadIcon from '@mui/icons-material/Download';
import Button2 from '@mui/material/Button';

const CountLabourOfficer = () => {
  const [responseData, setResponseData] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [toggle, setToggle] = useState('');
  console.log(toggle, 'SelectedToggle');
  const [endDate, setEndDate] = useState(null);
  console.log(startDate, endDate);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  // const [searchQuery, setSearchQuery] = useState('');

  const users = useSelector((state) => state.users);

  const handleChange = (e) => {
    const opt = e.target.value;
    setToggle(opt);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  //Fatching Data from API
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // if (!startDate || !endDate) {
      //   setLoading(false);
      //   return;
      // }
      if (moment(startDate).isAfter(moment(endDate))) {
        console.error('Start date must be less than or equal to end date');
        setLoading(false);
        return;
      }
      try {
        if (toggle !== '' && (toggle === 'REG' || toggle === 'REN')) {
          const res = await axios.post(
            `${SERVER}/reports/count/labour-officer`,
            {
              boardId: users.getDeptUserRole.data[0].board_id,
              departmentUserId:
                users.getDeptUserRole.data[0].department_user_id,
              roleId: users.getDeptUserRole.data[0].role_id,
              reportType: toggle,
              isDateSelected: isDateSelected,
              startDate: startDate,
              endDate: endDate,
            },
            {
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
            },
          );
          console.log(res);
          setResponseData(res.data);
        }
      } catch (error) {
        console.log('Error fetching data:', error);
        if (error.resposne) {
          console.log('Server Response:', error.resposne.data);
          console.log('Server Status:', error.resposne.status);
          console.log('Server Headers:', error.response.headers);
        } else if (error.request) {
          console.log('No response from the server');
        } else {
          console.log('Error', error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, toggle]);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    responseData && responseData.data
      ? responseData.data.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  //DropDown handleChange
  const handleChangePage = (event, value) => {
    console.log('handleChangePage called with value:', value);
    setCurrentPage(value);
    if (toggle === '') {
      setResponseData(null);
    }
  };

  const handleStartDateChange = (date) => {
    if (endDate && moment(date).isAfter(endDate)) {
      setEndDate(date);
    }
    setStartDate(moment(date).format('YYYY-MM-DD'));
  };

  const handleEndDateChange = (date) => {
    if (startDate && moment(date).isBefore(startDate)) {
      setStartDate(date);
    }
    setEndDate(moment(date).format('YYYY-MM-DD'));
  };

  //CSV-Format Download
  const downloadCSV = () => {
    // if (
    //   !responseData ||
    //   typeof responseData !== 'object' ||
    //   !Array.isArray(responseData.data)
    // ) {
    //   console.error('No data to download or data format is incorrect');
    //   return;
    // }

    const csvContent = [
      //Headers
      [
        'ALC',
        'LO',
        'LI',
        'Circle ID',
        'Circle Name',
        'Rejected',
        'Approved',
        'Active',
        'Inactive',
        'Pending',
        '0-45',
        '46-90',
        '91-180',
        '181-300',
        'More than 300',
        'Total',
      ],

      ...responseData.data.map((item) => [
        item.ALC,
        item.LO,
        item.LI,
        item.circle_id,
        item.circle_name.trim(),
        item.Rejected,
        item.Approved,
        item.active,
        item.Inactive,
        item.Pending,
        item['0-45'],
        item['46-90'],
        item['91-180'],
        item['181-300'],
        item.more_than_300,
        item.Total,
      ]),
    ].map((row) => row.join(','));

    const blob = new Blob([csvContent.join('\n')], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'LO-Wise-Report.csv';

    link.click();
  };

  return (
    <div>
      <br />
      <h1 style={{ textAlign: 'center' }}>
        Labour Officer Wise Registration and Renewal Report
      </h1>
      {/* <br /> */}
      <div className="d-flex justify-content-center align-items-center gap-2">
        <Col xs={2} className="Lo-col">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className="datepicker form-control"
              format="YYYY-MM-DD"
              value={startDate}
              onChange={handleStartDateChange}
              placeholder="Start Date"
              InputProps={{
                startAdornment: (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setStartDate(null);
                      setIsDateSelected(0);
                    }}
                    style={{ position: 'absolute', right: '20%' }}
                  >
                    {startDate && <CancelIcon color="error" />}
                  </div>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={2} className="Lo-col">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className="datepicker form-control"
              format="YYYY-MM-DD"
              value={endDate}
              onChange={handleEndDateChange}
              placeholder="End Date"
              InputProps={{
                startAdornment: (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setEndDate(null);
                    }}
                    style={{ position: 'absolute', right: '20%' }}
                  >
                    {endDate && <CancelIcon color="error" />}
                  </div>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={2} className="Lo-col">
          <FormControl variant="outlined" fullWidth className="formcontrol5">
            <Select
              className="select-marital"
              value={toggle}
              onChange={handleChange}
              labelId="Select Report Type"
              style={{ border: '1px solid grey' }}
              name="reports"
              variant="outlined"
              displayEmpty
              placeholder="Select Report Type"
            >
              <MenuItem value="">
                <ListItemText primary="Select Report Type" />
              </MenuItem>
              <MenuItem value="REG">
                <ListItemText primary="Registration" />
              </MenuItem>
              <MenuItem value="REN">
                <ListItemText primary="Renewal" />
              </MenuItem>
            </Select>
          </FormControl>
        </Col>
      </div>
      {responseData && (
        <Stack spacing={2} mt={2} alignItems="flex-end">
          <Pagination
            count={Math.ceil((responseData?.data?.length || 0) / itemsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            color="primary"
          />
        </Stack>
      )}
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {responseData && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ALC</TableCell>
                <TableCell>LO</TableCell>
                <TableCell>LI</TableCell>
                <TableCell>Circle ID</TableCell>
                <TableCell>Circle Name</TableCell>
                <TableCell>Rejected</TableCell>
                <TableCell>Approved</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Inactive</TableCell>
                <TableCell>Pending</TableCell>
                <TableCell>0-45 Days</TableCell>
                <TableCell>46-90 Days</TableCell>
                <TableCell>91-180 Days</TableCell>
                <TableCell>181-300 Days</TableCell>
                <TableCell>More than 300 Days</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.ALC ? item.ALC : 'NA'}</TableCell>
                  <TableCell>{item.LO ? item.LO : 'NA'}</TableCell>
                  <TableCell>{item.LI}</TableCell>
                  <TableCell>{item.circle_id}</TableCell>
                  <TableCell>{item.circle_name.trim()}</TableCell>
                  <TableCell>{item.Rejected}</TableCell>
                  <TableCell>{item.Approved}</TableCell>
                  <TableCell>{item.active}</TableCell>
                  <TableCell>{item.Inactive}</TableCell>
                  <TableCell>{item.Pending}</TableCell>
                  <TableCell>{item['0-45']}</TableCell>
                  <TableCell>{item['46-90']}</TableCell>
                  <TableCell>{item['91-180']}</TableCell>
                  <TableCell>{item['181-300']}</TableCell>
                  <TableCell>{item.more_than_300}</TableCell>
                  <TableCell>{item.Total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {responseData && (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <Col
              xs={4}
              className="d-flex justify-content-center align-items-center"
            >
              <p className="downloadtitle-p p-2">
                <Button2
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={downloadCSV}
                >
                  Download CSV Format
                </Button2>
              </p>
            </Col>
          </div>
        </>
      )}
    </div>
  );
};

export default CountLabourOfficer;
