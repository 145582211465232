import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
  TextareaAutosize,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddCircleRounded } from "@material-ui/icons";
// import folder from 'assets/images/folder (3).svg'
import folder from "assets/images/Folderwhite-01-01.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import aadhar from "assets/images/id-card.svg";
import checkgreen from "assets/images/Subtraction 1.svg";
import checkgrey from "assets/images/Subtraction 2.svg";
// import close from 'assets/images/close.png'
import close from "assets/images/close-01.svg";
// import mobile from 'assets/images/Icon awesome-mobile-alt.svg'
import closeicon from "assets/images/closeIcon.svg";
import mobile from "assets/images/Phone 1-01.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addLabour90DaysDetails,
  getCatalogDetails,
  getCityDetails,
  getDistrictDetails,
  getFile,
  getInspectorDetails,
  getStateDetails,
  getTalukDetails,
  getUnionDetails,
  getUser,
  getUserRegistrationDetails,
  getVillageDetails,
  SERVER,
  update90DaysData,
  uploadFile,
} from "store/actions/user.actions";
import {
  dataURLtoFile,
  getTokenCookie,
  showToast,
  Required,
} from "utils/tools";
import { Translate } from "react-redux-i18n";
import axios from "axios";
import validator from "validator";
import moment from "moment";

const NintyDays = (props) => {
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [allValues, setAllValues] = React.useState({
    noOfEmployementDetails: users.nintydays_details.noOfEmployementDetails,

    selectedDate: users.nintydays_details.selectedDate,
    imgfile: users.nintydays_details.imgfile,
    documentName: users.nintydays_details.documentName,
    openDatePicker: users.nintydays_details.openDatePicker,
    employmentsList: users.nintydays_details.employmentsList,
    issuerType: users.nintydays_details.issuerType,

    presentDetailsGiven: users.nintydays_details.presentDetailsGiven,
    presentDetailsGivenIndex: users.nintydays_details.presentDetailsGivenIndex,

    inspectorName: users.nintydays_details.inspectorName,
    inspectorCircle: users.nintydays_details.inspectorCircle,
    inspectorPhone: users.nintydays_details.inspectorPhone,

    unionName: users.nintydays_details.unionName,
    unionRegistrationNumber: users.nintydays_details.unionRegistrationNumber,
    unionIssuerName: users.nintydays_details.unionIssuerName,
    unionPhone: users.nintydays_details.unionPhone,

    issuerName: users.nintydays_details.issuerName,
    issuerPlace: users.nintydays_details.issuerPlace,
    issuerPhone: users.nintydays_details.issuerPhone,
    issuerDesignation: users.nintydays_details.issuerDesignation,

    employerDetails: [],
    states: [],
    district: [],
    taluk: [],
    city: [],
    village: [],
    natureOfWork: [],
    issuerTypes: [],
    // presentDetailsGiven: false,
    // presentDetailsGivenIndex: '',
    issuedDate: users.nintydays_details.dob,
    documentUploadResponse: users.nintydays_details.documentUploadResponse,
    pinError: false,
    contractorNameError: false,
    contractorCompanyNameError: false,
    inchargePhoneNumberError: false,
    contractorNameError: false,
    contractorCompanyNameError: false,
    issuerPhoneError: false,
    pinError: false,
    mobileNumberInchargeError: false,
    mobileNumberInchargePersonError: false,
    issuerPlaceError: false,
    issuerDesignationError: false,
    issuerNameError: false,
  });

  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState({});
  const [fileuploadresults, setfileuploadresults] = React.useState({});

  const [issuerGramName, setIssuerName] = React.useState(false);
  const [issuerGramPhone, setIssuerPhone] = React.useState(false);
  const [issuerGramPlace, setIssuerPlace] = React.useState(false);

  const [IssuerDesignation, setissuerDesignation] = React.useState(false);

  const [dropdownNotselected, setDropdownNotSelected] = React.useState(false);

  const [removedFile, setRemovedFile] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  // const [employerDetails, setEmployerDetails] = React.useState([])
  // const [states, setStates] = React.useState([])
  // const [district, setDIstrict] = React.useState([])
  // const [taluk, setTaluk] = React.useState([])
  // const [city, setCity] = React.useState([])
  // const [village, setVillage] = React.useState([])
  // const [natureOfWork, setNatureOfWork] = React.useState([])
  // const [issuerTypes, setIssuerTypes] = React.useState([])

  // const [presentDetailsGiven, setPresentDetailsGiven] = React.useState(false)
  // const [presentDetailsGivenIndex, setPresentDetailsGivenIndex] = React.useState('')

  const [issuedDate, setIssuedDate] = React.useState(
    users.nintydays_details.dob
  );

  // useEffect(() => {
  //     setAllValues({...allValues,
  //         presentDetailsGiven: presentDetailsGiven,
  //         presentDetailsGivenIndex: presentDetailsGivenIndex,
  //     });
  // }, [presentDetailsGiven]);

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone });

    dispatch(getStateDetails());
  }, [phone]);

  useEffect(() => {
    // console.log("in useEffect []")
    if (users.generate_otp !== null) {
      setPhone(users.generate_otp.data.phone_number);
      dispatch(getUser(users.generate_otp.data.phone_number));
    } else if (users.generate_otp_registration !== null) {
      setPhone(users.generate_otp_registration.data.phone_number);
      dispatch(getUser(users.generate_otp_registration.data.phone_number));
    } else {
      props.history.push("/");
    }

    dispatch(getFile("delete"));
    dispatch(addLabour90DaysDetails("delete"));

    dispatch(getStateDetails());

    console.error(
      "in useEffect users.nintydays_details.noOfEmployementDetails: " +
        users.nintydays_details.noOfEmployementDetails
    );

    // if (users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.employer_details !== undefined) {
    //     for (var i = 0; i < users.getUserRegistrationDetails.employer_details.length; i++) {
    //         addNewEmploymentDetail(i,"saved")
    //         console.error("in useEffect [addNewEmploymentDetail]: i=" + i)
    //     }
    // }
    // else

    //  if (users.nintydays_details.noOfEmployementDetails > 1) {
    //     for (var i = 1; i < users.nintydays_details.noOfEmployementDetails; i++) {
    //         addNewEmploymentDetail(i)
    //         console.error("in useEffect [addNewEmploymentDetail]: i=" + i)
    //     }
    // }
  }, []);

  const addNewEmploymentDetail = (employementNumber, str) => {
    console.log("in addNewEmploymentDetail");
    console.error(
      "in addNewEmploymentDetail employementNumber=" +
        employementNumber +
        " str:" +
        str
    );

    if (
      employementNumber !== undefined &&
      employementNumber !== null &&
      str === "saved"
    ) {
      var val =
        users.getUserRegistrationDetails.employer_details[employementNumber];

      console.error(
        "val.catalog_value_employement_status_id: " +
          val.catalog_value_employement_status_id
      );
      console.error(
        "allValues.presentDetailsGivenIndex: " +
          allValues.presentDetailsGivenIndex
      );

      var thispresentDetailsGiven = "";
      var thispresentDetailsGivenIndex = "";

      if (
        val.catalog_value_employement_status_id === 25 &&
        allValues.presentDetailsGivenIndex === ""
      ) {
        console.error("inside if: make present true");
        thispresentDetailsGiven = true;
        thispresentDetailsGivenIndex = employementNumber;
      }

      // if(allValues.employmentsList !== undefined){
      setAllValues({
        ...allValues,
        presentDetailsGiven: thispresentDetailsGiven,
        presentDetailsGivenIndex: thispresentDetailsGivenIndex,
        employmentsList: allValues.employmentsList.concat({
          id: employementNumber,
          employerDetails: val.catalog_value_employement_status_id,
          workplaceSiteaddress: val.site_address,
          inchargePhoneNumber: val.mobile_no,
          contractorDeveloperName: val.contractor_name,
          contractorCompanyName: val.contractor_company_name,
          pincode: val.pin_code,
          state: val.state_id,
          districtWorkplace: val.district_id,
          cityGram: val.city_town_gram_panchayat_id,
          talukWorkplace: val.taluk_id,
          villageWard: val.village_area_id,
          natureWorkplace: val.catalog_value_nature_of_work,
          // "issuerType": val.,
          // "selectedDate": val.
        }),
      });
    } else if (employementNumber !== undefined && employementNumber !== null) {
      // var familymem = employementNumber
      if (
        users.nintydays_details.employmentsList[employementNumber] !== undefined
      ) {
        setAllValues({
          ...allValues,
          employmentsList: allValues.employmentsList.concat({
            id: employementNumber,
            employerDetails:
              users.nintydays_details.employmentsList[employementNumber][
                "employerDetails"
              ],
            workplaceSiteaddress:
              users.nintydays_details.employmentsList[employementNumber][
                "workplaceSiteaddress"
              ],
            inchargePhoneNumber:
              users.nintydays_details.employmentsList[employementNumber][
                "inchargePhoneNumber"
              ],
            contractorDeveloperName:
              users.nintydays_details.employmentsList[employementNumber][
                "contractorDeveloperName"
              ],
            contractorCompanyName:
              users.nintydays_details.employmentsList[employementNumber][
                "contractorCompanyName"
              ],
            pincode:
              users.nintydays_details.employmentsList[employementNumber][
                "pincode"
              ],
            state:
              users.nintydays_details.employmentsList[employementNumber][
                "state"
              ],
            districtWorkplace:
              users.nintydays_details.employmentsList[employementNumber][
                "districtWorkplace"
              ],
            cityGram:
              users.nintydays_details.employmentsList[employementNumber][
                "cityGram"
              ],
            talukWorkplace:
              users.nintydays_details.employmentsList[employementNumber][
                "talukWorkplace"
              ],
            villageWard:
              users.nintydays_details.employmentsList[employementNumber][
                "villageWard"
              ],
            natureWorkplace:
              users.nintydays_details.employmentsList[employementNumber][
                "natureWorkplace"
              ],
            issuerType:
              users.nintydays_details.employmentsList[employementNumber][
                "issuerType"
              ],
            selectedDate:
              users.nintydays_details.employmentsList[employementNumber][
                "selectedDate"
              ],
          }),
        });
      }
    } else {
      var EmployementDetailNum = allValues.noOfEmployementDetails + 1;
      setAllValues({
        ...allValues,
        employmentsList: allValues.employmentsList.concat({
          id: allValues.noOfEmployementDetails,
          employerDetails: "",
          workplaceSiteaddress: "",
          inchargePhoneNumber: "",
          contractorDeveloperName: "",
          contractorCompanyName: "",
          pincode: "",
          state: "",
          districtWorkplace: "",
          cityGram: "",
          talukWorkplace: "",
          villageWard: "",
          natureWorkplace: "",
          issuerType: "",
        }),
        noOfEmployementDetails: EmployementDetailNum,
      });
    }
  };

  const removeEmployerDetail = (item) => {
    console.log("in removeFamilyMember: item=" + item);
    var array = [...allValues.employmentsList];
    // var index = array.indexOf(item)
    array.splice(item, 1);

    if (users.nintydays_details.presentDetailsGivenIndex === item) {
      // setPresentDetailsGiven(false)
      // setPresentDetailsGivenIndex('')
      setAllValues({
        ...allValues,
        presentDetailsGiven: false,
        presentDetailsGivenIndex: "",

        employmentsList: array,
        noOfEmployementDetails: allValues.noOfEmployementDetails - 1,
      });
    } else {
      setAllValues({
        ...allValues,
        employmentsList: array,
        noOfEmployementDetails: allValues.noOfEmployementDetails - 1,
      });
    }
  };

  useEffect(() => {
    // console.log("in useEffect [users.user]")

    if (users.user !== null && users.user.board_id !== null) {
      let dataForRegDetails = {
        key: "user_id",
        value: users.user.id,
        board_id: users.user.board_id,
        procedure_name: "all",
      };
      dispatch(getUserRegistrationDetails(dataForRegDetails));

      let dataForCatalog = {
        board_id: users.user.board_id,
        catalog_name: "Employer details",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog));

      let dataForCatalog2 = {
        board_id: users.user.board_id,
        catalog_name: "Nature of Work",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog2));

      let dataForCatalog3 = {
        board_id: users.user.board_id,
        catalog_name: "Type of Issuer",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog3));

      // let dataForCatalog4 = { "board_id": users.user.board_id, "catalog_name": "Education" };
      // // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      // dispatch(getCatalogDetails(dataForCatalog4))
    }
  }, [users.user]);

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });
  };

  const handleChange = (event) => {
    console.log("eventSelected", event.target.name);

    // if (e.target.name.includes("issuerName"),"en-US",{ignore: ' '}) {

    //     if (validator.isAlpha(e.target.value)){
    //         setIssuerName(false)
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value
    //         })
    //     }

    //     else{
    //         setIssuerName(true)
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value
    //         })
    //     }

    // }

    // else if (e.target.name.includes("issuerPhone")) {

    //         if (validator.isNumeric(e.target.value) && e.target.value.length === 10 ){
    //             setIssuerPhone(false)
    //             setAllValues({
    //                 ...allValues,
    //                 [e.target.name]: e.target.value
    //             })
    //         }

    //         else{
    //             setIssuerPhone(true)
    //             setAllValues({
    //                 ...allValues,
    //                 [e.target.name]: e.target.value
    //             })
    //         }

    // }

    // else if (e.target.name === "issuerDesignation","en-US",{ignore: ' '}) {

    //     if (validator.isAlpha(e.target.value)){
    //         setissuerDesignation(false)
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value
    //         })
    //     }

    //     else{
    //         setissuerDesignation(true)
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value
    //         })
    //     }

    // }
    // else {

    if (event.target.name === "nameOfContractor") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          contractorNameError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          contractorNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (event.target.value.length === 0) {
        setIssuerName(true);
      }

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerNameError: false,
          [event.target.name]: event.target.value,
        });
        setIssuerName(false);
      } else {
        setAllValues({
          ...allValues,
          issuerNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerDesignation") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerDesignationError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          issuerDesignationError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerPlace") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (event.target.value.length === 0) {
        setIssuerPlace(true);
      }

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerPlaceError: false,
          [event.target.name]: event.target.value,
        });
        setIssuerPlace(false);
      } else {
        setAllValues({
          ...allValues,
          issuerPlaceError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "contractorCompanyName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerPhone") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (event.target.value.length === 0) {
        setIssuerPhone(true);
      }

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length == 10
      ) {
        setAllValues({
          ...allValues,
          issuerPhoneError: false,
          [event.target.name]: event.target.value,
        });
        setIssuerPhone(false);
      } else {
        setAllValues({
          ...allValues,
          issuerPhoneError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "mobileNumberIncharge") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length == 10
      ) {
        setAllValues({
          ...allValues,
          mobileNumberInchargeError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          mobileNumberInchargeError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "mobileNumberIfAvailable") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length == 10
      ) {
        setAllValues({
          ...allValues,
          mobileNumberInchargePersonError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          mobileNumberInchargePersonError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "pinCode") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length === 6
      ) {
        setAllValues({
          ...allValues,

          [event.target.name]: event.target.value,
          pinError: false,
        });
      } else {
        setAllValues({
          ...allValues,

          [event.target.name]: event.target.value,
          pinError: true,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDateChange = (date) => {
    console.log(date);
    console.log(new Date(date));
    setAllValues({
      ...allValues,
      selectedDate: new Date(date),
    });
  };

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    setAllValues({ ...allValues, selectedDate: issuedDate });
  }, [issuedDate]);

  useEffect(() => {
    if (users.unionDetails !== null && users.unionDetails !== "Network Error") {
      setAllValues({
        ...allValues,
        unionName: users.unionDetails[0].name,
        unionRegistrationNumber: users.unionDetails[0].registeration_no,
        unionIssuerName: users.unionDetails[0].spoke_person,
        unionPhone: users.unionDetails[0].phone_no,
      });
    }
  }, [users.unionDetails]);

  useEffect(() => {
    if (
      users.inspectorDetails !== "Network Error" &&
      users.inspectorDetails !== null &&
      users.inspectorDetails.length > 0
    ) {
      var inspectorName = users.nintydays_details.inspectorName;
      if (users.inspectorDetails[0].first_name !== null) {
        inspectorName = users.inspectorDetails[0].first_name + " ";
      }
      if (users.inspectorDetails[0].middle_name !== null) {
        inspectorName = inspectorName + users.inspectorDetails[0].middle_name;
      }
      if (users.inspectorDetails[0].last_name !== null) {
        inspectorName = inspectorName + users.inspectorDetails[0].last_name;
      }

      setAllValues({
        ...allValues,
        inspectorName: inspectorName,
        inspectorCircle: users.inspectorDetails[0].circle,
        inspectorPhone: users.inspectorDetails[0].mobile_no,
      });
    }
  }, [users.inspectorDetails]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (
      users.user !== null &&
      users.user.board_id !== undefined &&
      allValues.presentDetailsGivenIndex !== undefined &&
      allValues.presentDetailsGivenIndex !== ""
    ) {
      dispatch(
        getInspectorDetails(
          users.user.board_id,
          users.getUserRegistrationDetails.address_details[0].village_area_id
          // allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard
        )
      );

      if (users.nintydays_details.issuerType === 55) {
        // console.error("allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard:" + allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard)

        getInspectorDetails(
          users.user.board_id,
          users.getUserRegistrationDetails.address_details[0].village_area_id
          // allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard
        );
        // if (allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard !== "") {
        //     dispatch(
        //         getInspectorDetails(users.user.board_id,
        //             allValues.employmentsList[allValues.presentDetailsGivenIndex].villageWard)
        //     )
        // }
        // else {
        //     showToast("WARN", "Please Select Village/Ward in the Present Employer Details!")
        // }
      }

      if (users.nintydays_details.issuerType === 56) {
        dispatch(getUnionDetails(users.user.board_id));
      }
    }
  }, [users.nintydays_details.issuerType]);

  const handleFileChange = (event) => {
    console.log("event.target.files: ", event.target.files);
    console.log(event.target.files);

    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    }
    // else if(event.target.files[0] === null || ){
    //     showToast("ERROR", "Please upload file of size less than 2MB.")
    // }
    else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );
      setselectedfiles(joined);
    }

    setloadingfiles(false);

    // var selectedblob = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(selectedblob);
    // reader.onloadend = function () {
    //     var base64data = reader.result;
    //     console.log(base64data);

    //     sessionStorage.setItem('90_days_certificate',base64data)
    //     setAllValues({...allValues,
    //         // imgfile: base64data,
    //         documentName: event.target.files[0].name
    //     })
    // }
  };

  const removefile = (index) => {
    setloadingfiles(true);
    console.log("removefile index: " + index);
    setRemovedFile(true);
    // selectedfiles.splice(1,index)
    selectedfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  // useEffect(() => {
  //     console.error("in useEffect upload_file: "+JSON.stringify(users.upload_file))
  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true) {
  //         if (users.upload_file.userFileName === "90_days_certificate") {
  //             setAllValues({...allValues,
  //                 documentUploadResponse: users.upload_file.image
  //             })

  //             console.error("documentUploadResponse:"+JSON.stringify(allValues.documentUploadResponse))

  //             submit90Days(users.upload_file.image)
  //         }
  //     }
  //     else if (users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR", "Cannot upload the certificate image. Please try later.")
  //     }
  // }, [users.upload_file])

  useEffect(() => {
    if (fileuploadresults !== null && fileuploadresults.length > 0) {
      var filelist = [];
      fileuploadresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };
        filelist.push(filejson);
      });
      console.log("filelist: " + JSON.stringify(filelist));
      // setfilelist(filelist)
      submit90Days(filelist);
    }
  }, [fileuploadresults]);

  const submit90Days = (uploadResponse) => {
    let employers = [];

    for (var i = 0; i < users.nintydays_details.noOfEmployementDetails; i++) {
      employers.push({
        catalog_value_employement_status_id:
          users.nintydays_details.employmentsList[i].employerDetails,
        contractor_company_name:
          users.nintydays_details.employmentsList[i].contractorCompanyName,
        contractor_name:
          users.nintydays_details.employmentsList[i].contractorDeveloperName,
        mobile_country_code: "+91",
        mobile_no:
          users.nintydays_details.employmentsList[i].inchargePhoneNumber,
        catalog_value_nature_of_work:
          users.nintydays_details.employmentsList[i].natureWorkplace,
        state_id: users.nintydays_details.employmentsList[i].state,
        site_address:
          users.nintydays_details.employmentsList[i].workplaceSiteaddress,
        district_id:
          users.nintydays_details.employmentsList[i].districtWorkplace,
        taluk_id: users.nintydays_details.employmentsList[i].talukWorkplace,
        city_town_gram_panchayat_id:
          users.nintydays_details.employmentsList[i].cityGram,
        village_area_id: users.nintydays_details.employmentsList[i].villageWard,
        pin_code: users.nintydays_details.employmentsList[i].pincode,
      });
    }

    var labour_incpector_id = "";
    var labour_union_id = "";
    var spoke_person = "";
    var address = "";
    var phone_no = "";
    var circle_id = "";
    var designation = "";

    if (
      users.inspectorDetails !== "Network Error" &&
      users.inspectorDetails !== null &&
      users.inspectorDetails.length > 0
    ) {
      labour_incpector_id = users.inspectorDetails[0].department_user_id;
      // phone_no = users.inspectorDetails[0].mobile_no
      circle_id = users.inspectorDetails[0].circle_id;
    }
    if (
      users.nintydays_details.issuerType === 55 &&
      users.inspectorDetails !== "Network Error" &&
      users.inspectorDetails !== null &&
      users.inspectorDetails.length > 0
    ) {
      labour_incpector_id = users.inspectorDetails[0].department_user_id;
      phone_no = users.inspectorDetails[0].mobile_no;
      circle_id = users.inspectorDetails[0].circle_id;
    }

    if (
      users.nintydays_details.issuerType === 56 &&
      users.unionDetails !== "Network Error" &&
      users.unionDetails !== null &&
      users.unionDetails.length > 0
    ) {
      labour_union_id = users.unionDetails[0].labour_union_id;
      spoke_person = users.unionDetails[0].spoke_person;
      address = users.unionDetails[0].address;
      phone_no = users.unionDetails[0].phone_no;
    }
    if (users.nintydays_details.issuerType === 57) {
      spoke_person = users.nintydays_details.issuerName;
      address = users.nintydays_details.issuerPlace;
      phone_no = users.nintydays_details.issuerPhone;
    }
    if (users.nintydays_details.issuerType === 58) {
      spoke_person = users.nintydays_details.issuerName;
      phone_no = users.nintydays_details.issuerPhone;
      designation = users.nintydays_details.issuerDesignation;
    }

    let dataToSubmit = {
      user_id: users.user.id,
      board_id: users.user.board_id,
      catalog_value_type_of_issuer_id: users.nintydays_details.issuerType,
      issue_date: users.nintydays_details.selectedDate,
      // "document_uploaded_id": allValues.documentUploadResponse.fileId,
      // "document_uploaded_id": uploadResponse.fileId,
      document_uploaded_id: "-",
      files: uploadResponse,
      labour_incpector_id: labour_incpector_id,
      labour_union_id: labour_union_id,
      issuer_name: spoke_person,
      issuer_place: address,
      issuer_designation: designation,
      mobile_country_code: "+91",
      mobile_no: phone_no,
      catalog_value_registration_status_id: 45,
      circle_id: circle_id,

      employers: employers,
    };

    console.error("dataToSubmit", JSON.stringify(dataToSubmit));

    if (users.getUserRegistrationDetails.certificate_details === []) {
      dispatch(addLabour90DaysDetails(dataToSubmit));
    } else {
      dispatch(addLabour90DaysDetails(dataToSubmit, "update"));
    }
  };

  const handleEnter = () => {
    console.log("in handleEnter");

    const nextSibling = document.querySelector(
      `.formcontrol${
        allValues.residentialData.formcontrolnum + 1
      }>div>div>input`
    );
    console.log("nextSibling:" + nextSibling.className);

    if (nextSibling !== null) {
      nextSibling.focus();
    }
  };

  useEffect(() => {
    console.log("in useEffect [allValues]: ", allValues);
    dispatch(update90DaysData(allValues));
  }, [allValues]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.catalog_details !== null &&
      users.catalog_details.error !== undefined &&
      users.catalog_details.error !== null &&
      users.catalog_details.error === true
    ) {
      console.error("ERROR: " + JSON.stringify(users.catalog_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Employer details"
    ) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setEmployerDetails(users.catalog_details)
      setAllValues({
        ...allValues,
        employerDetails: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Nature of Work"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setNatureOfWork(users.catalog_details)
      setAllValues({
        ...allValues,
        natureOfWork: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Type of Issuer"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setIssuerTypes(users.catalog_details)
      setAllValues({
        ...allValues,
        issuerTypes: users.catalog_details,
      });
    }
    // else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Education") {
    //     // console.error("elseif category: "+JSON.stringify(users.catalog_details))
    //     setEducations(users.catalog_details)
    // }
  }, [users.catalog_details]);

  useEffect(() => {
    // setStates(users.state_details)
    setAllValues({
      ...allValues,
      states: users.state_details,
    });
  }, [users.state_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.district_details !== null &&
      users.district_details.error !== undefined &&
      users.district_details.error !== null &&
      users.district_details.error === true
    ) {
      console.error("ERROR: " + JSON.stringify(users.district_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.district_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setDIstrict(users.district_details)
      setAllValues({
        ...allValues,
        district: users.district_details,
      });
    }
  }, [users.district_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.taluk_details !== null &&
      users.taluk_details.error !== undefined &&
      users.taluk_details.error !== null &&
      users.taluk_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.taluk_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.taluk_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setTaluk(users.taluk_details)
      setAllValues({
        ...allValues,
        taluk: users.taluk_details,
      });
    }
  }, [users.taluk_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.city_details !== null &&
      users.city_details.error !== undefined &&
      users.city_details.error !== null &&
      users.city_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.city_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.city_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setCity(users.city_details)
      setAllValues({
        ...allValues,
        city: users.city_details,
      });
    }
  }, [users.city_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.village_details !== null &&
      users.village_details.error !== undefined &&
      users.village_details.error !== null &&
      users.village_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.village_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.village_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setVillage(users.village_details)
      setAllValues({
        ...allValues,
        village: users.village_details,
      });
    }
  }, [users.village_details]);

  const handleChangeEmploymentList = (event, index) => {
    console.log("in handleChangeEmploymentList: index=" + index);
    console.log(
      "in handleChangeEmploymentList: value=" +
        JSON.stringify(event.target.value)
    );
    console.log(
      "in handleChangeEmploymentList: name=" + JSON.stringify(event.target.name)
    );

    if (event.target.name === "state") {
      if (event.target.value === "") {
        showToast("ERROR", "Please select state");
      } else {
        funcDispatchDistrict(index, event.target.value);
        setDropdownNotSelected(false);
      }
    }
    if (event.target.name === "districtWorkplace") {
      if (event.target.value === "") {
        showToast("ERROR", "Please select district");
        setDropdownNotSelected(true);
      } else {
        funcDispatchTaluk(index, event.target.value);
        setDropdownNotSelected(false);
      }
    }
    if (event.target.name === "talukWorkplace") {
      if (event.target.value === "") {
        showToast("ERROR", "Please select taluk");
        setDropdownNotSelected(true);
      } else {
        funcDispatchCity(index, event.target.value);
        setDropdownNotSelected(false);
      }
    }
    if (event.target.name === "cityGram") {
      if (event.target.value === "") {
        showToast("ERROR", "Please select village");
        setDropdownNotSelected(true);
      } else {
        funcDispatchVillage(index, event.target.value);
        setDropdownNotSelected(false);
      }
    }

    var thispresentDetailsGiven = allValues.presentDetailsGiven;
    var thispresentDetailsGivenIndex = allValues.presentDetailsGivenIndex;

    if (event.target.name === "employerDetails") {
      console.error(
        "in event.target.name === 'employerDetails'-> event.target.value" +
          event.target.value
      );

      if (
        event.target.value === 25 &&
        allValues.presentDetailsGivenIndex === ""
      ) {
        // setPresentDetailsGiven(true)
        // setPresentDetailsGivenIndex(index)

        console.error("in if");

        thispresentDetailsGiven = true;
        thispresentDetailsGivenIndex = index;
        // setAllValues({...allValues,
        //     presentDetailsGiven: true,
        //     presentDetailsGivenIndex: index,
        // });
        setAllValues({
          ...allValues,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else if (
        event.target.value !== 25 &&
        allValues.presentDetailsGivenIndex === index
      ) {
        // setPresentDetailsGiven(false)
        // setPresentDetailsGivenIndex("")
        console.error("in else if");

        thispresentDetailsGiven = false;
        thispresentDetailsGivenIndex = "";
        // setAllValues({...allValues,
        //     presentDetailsGiven: false,
        //     presentDetailsGivenIndex: "",
        // });
        setAllValues({
          ...allValues,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        console.error("in else");
        thispresentDetailsGiven = false;
        thispresentDetailsGivenIndex = "";
        setAllValues({
          ...allValues,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else if (event.target.name === "inchargePhoneNumber") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length === 10
      ) {
        setAllValues({
          ...allValues,
          inchargePhoneNumberError: false,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          inchargePhoneNumberError: true,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else if (event.target.name === "pincode") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length === 6
      ) {
        setAllValues({
          ...allValues,
          pinError: false,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          pinError: true,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else if (event.target.name === "issuerPhone") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length === 6
      ) {
        setAllValues({
          ...allValues,
          pinError: false,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          pinError: true,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else if (event.target.name === "contractorDeveloperName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 40
      ) {
        setAllValues({
          ...allValues,
          contractorNameError: false,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          contractorNameError: true,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else if (event.target.name === "contractorCompanyName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 40
      ) {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: false,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      } else {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: true,
          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: allValues.employmentsList.map((obj) =>
            obj.id === index
              ? Object.assign(obj, {
                  [event.target.name]: event.target.value,
                })
              : obj
          ),
        });
      }
    } else {
      setAllValues({
        ...allValues,
        presentDetailsGiven: thispresentDetailsGiven,
        presentDetailsGivenIndex: thispresentDetailsGivenIndex,

        employmentsList: allValues.employmentsList.map((obj) =>
          obj.id === index
            ? Object.assign(obj, {
                [event.target.name]: event.target.value,
              })
            : obj
        ),
      });
    }
  };

  const funcDispatchDistrict = (index, data) => {
    console.error("in funcDispatchDistrict [index]:" + index);
    console.error("in funcDispatchDistrict [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getDistrictDetails(data));
    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };
  const funcDispatchTaluk = (index, data) => {
    console.error("in funcDispatchTaluk [index]:" + index);
    console.error("in funcDispatchTaluk [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getTalukDetails(data));
    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };
  const funcDispatchCity = (index, data) => {
    console.error("in funcDispatchCity [index]:" + index);
    console.error("in funcDispatchCity [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getCityDetails(data));

    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };
  const funcDispatchVillage = (index, data) => {
    console.error("in funcDispatchVillage [index]:" + index);
    console.error("in funcDispatchVillage [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getVillageDetails(data));

    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };

  const employmentDetails =
    allValues.employmentsList !== undefined &&
    allValues.employmentsList.map((id, i) => {
      return (
        <>
          <img
            alt='...'
            src={close}
            className='closebtn'
            onClick={() => removeEmployerDetail(i)}
          />

          <Row className={"form-row employer-card row" + i}>
            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='employerDetails' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol5'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].employerDetails}
                  name='employerDetails'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.employerDetails !== null &&
                    allValues.employerDetails.length > 0 &&
                    allValues.employerDetails.map((index) => (
                      <MenuItem
                        value={index.value_id}
                        // disabled={index.value==="Present" && presentDisabledList[i].check}
                        disabled={
                          index.value === "Present" &&
                          users.nintydays_details.presentDetailsGiven &&
                          index !==
                            users.nintydays_details.presentDetailsGivenIndex
                        }
                      >
                        <ListItemText primary={index.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              {/* <p className="mt-3 mb-2">Name of Contractor / Developer :</p> */}
              <Required
                className='mt-3 mb-2'
                title={<Translate value='nameofContractorDeveloper' />}
              />
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  placeholder='Enter Your Employer/Owner Name'
                  name='contractorDeveloperName'
                  value={allValues.employmentsList[i].contractorDeveloperName}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                  error={allValues.contractorNameError}
                  helperText={
                    allValues.contractorNameError
                      ? "Please enter in correct format"
                      : ""
                  }
                />
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='workplaceSiteAddress' />}
              />
              <FormControl fullWidth className='formcontrol1'>
                <TextareaAutosize
                  variant='outlined'
                  multiline
                  rows={1}
                  // rowsMax={4}
                  // defaultValue="Default Value"
                  placeholder='Enter Your Workplace/Site Address'
                  name='workplaceSiteaddress'
                  style={{ height: "46px" }}
                  value={allValues.employmentsList[i].workplaceSiteaddress}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                />
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='contractorCompanyName' />}
              />
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  placeholder='Enter Employer/Owner Company Name'
                  name='contractorCompanyName'
                  value={allValues.employmentsList[i].contractorCompanyName}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                  error={allValues.contractorCompanyNameError}
                  helperText={
                    allValues.contractorCompanyNameError
                      ? "Please enter in correct format"
                      : ""
                  }
                />
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='mobileNumberInchargePerson' />}
              />
              <FormControl fullWidth className='formcontrol2'>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={mobile} alt='...' className='phone-icon' />
                        <p className='countrycode'>+91</p>
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                  placeholder='987xxxxxxx'
                  name='inchargePhoneNumber'
                  value={allValues.employmentsList[i].inchargePhoneNumber}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                  error={allValues.inchargePhoneNumberError}
                  helperText={
                    allValues.inchargePhoneNumberError
                      ? "Please enter in correct format"
                      : ""
                  }
                />
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='state' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol5'
              >
                <Select
                  className='select-marital'
                  name='state'
                  value={allValues.employmentsList[i].state}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                  displayEmpty
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.states !== undefined &&
                    allValues.states !== null &&
                    allValues.states.length > 0 &&
                    allValues.states.map(
                      (i) =>
                        i.name === "Karnataka" && (
                          //    <>
                          <MenuItem value={i.id}>
                            <ListItemText
                              primary={i.short_name + " | " + i.name}
                            />
                          </MenuItem>
                        )
                      // </>
                    )}
                  {allValues.addressSameCheckbox == true ? (
                    <MenuItem value={allValues.residentialData.state}>
                      {allValues.residentialData.state}
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='selectYourDistrictoftheWorkplace' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol5'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].districtWorkplace}
                  name='districtWorkplace'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.district !== null &&
                    allValues.district.length > 0 &&
                    allValues.district.map((i) => (
                      <MenuItem value={i.id}>
                        <ListItemText primary={i.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='talukoftheWorkplace' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol5'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].talukWorkplace}
                  name='talukWorkplace'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.taluk !== null &&
                    allValues.taluk.length > 0 &&
                    allValues.taluk.map((i) => (
                      <MenuItem value={i.id}>
                        <ListItemText primary={i.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='townCityGramPanchayat' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol1'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].cityGram}
                  name='cityGram'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.city !== null &&
                    allValues.city.length > 0 &&
                    allValues.city.map((i) => (
                      <MenuItem value={i.id}>
                        <ListItemText primary={i.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='villageWardCircle' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol1'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].villageWard}
                  name='villageWard'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.village !== undefined &&
                    allValues.village.length > 0 &&
                    allValues.village.map((i) => (
                      // <MenuItem value={i.ref_district_code}>
                      <MenuItem value={i.id}>
                        <ListItemText primary={i.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='pincode' />}
              />
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  placeholder='Enter Your Pincode'
                  name='pincode'
                  value={allValues.employmentsList[i].pincode}
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                  error={allValues.pinError}
                  helperText={
                    allValues.pinError ? "Please enter in correct format" : ""
                  }
                />
              </FormControl>
            </Col>

            <Col xs={12} md={6}>
              <Required
                className='mt-3 mb-2'
                title={<Translate value='natureoftheWorkplace' />}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className='formcontrol5'
              >
                <Select
                  className='select-marital'
                  value={allValues.employmentsList[i].natureWorkplace}
                  name='natureWorkplace'
                  displayEmpty
                  onChange={(e) => {
                    handleChangeEmploymentList(e, i);
                  }}
                >
                  <MenuItem value=''>
                    <ListItemText primary='-Select-' />
                  </MenuItem>
                  {allValues.natureOfWork !== null &&
                    allValues.natureOfWork.length > 0 &&
                    allValues.natureOfWork.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
        </>
      );
    });

  const LabourInspectorForm = () => {
    var inspectorName = users.nintydays_details.inspectorName;
    var circle = users.nintydays_details.inspectorCircle;
    var mobile_no = users.nintydays_details.inspectorPhone;

    if (
      users.inspectorDetails !== "Network Error" &&
      users.inspectorDetails !== null &&
      users.inspectorDetails.length > 0
    ) {
      if (users.inspectorDetails[0].first_name !== null) {
        inspectorName = users.inspectorDetails[0].first_name + " ";
      }
      if (users.inspectorDetails[0].middle_name !== null) {
        inspectorName = inspectorName + users.inspectorDetails[0].middle_name;
      }
      if (users.inspectorDetails[0].last_name !== null) {
        inspectorName = inspectorName + users.inspectorDetails[0].last_name;
      }

      if (users.inspectorDetails[0].circle !== null) {
        circle = users.inspectorDetails[0].circle;
      }

      if (users.inspectorDetails[0].mobile_no !== null) {
        mobile_no = users.inspectorDetails[0].mobile_no;
      }
    }

    return (
      <>
        {allValues.employmentsList[allValues.presentDetailsGivenIndex] ===
        undefined ? null : (
          <>
            <Col xs={12} md={6} className='issuerTypeForm'>
              <p className='mt-5 mb-2'>
                <Translate value='labourInspectorName' />
              </p>
              <FormControl fullWidth>
                <TextField
                  name='inspectorName'
                  onChange={handleChange}
                  value={inspectorName}
                  disabled
                />
              </FormControl>

              <p className='mt-4 mb-2'>
                <Translate value='circleoftheLabourInspector' />{" "}
              </p>
              <FormControl fullWidth>
                <TextField
                  name='inspectorCircle'
                  onChange={handleChange}
                  value={circle}
                  disabled
                />
              </FormControl>

              <p className='mt-4 mb-2'>
                <Translate value='mobileNumberoftheLabourInspector' />{" "}
              </p>
              <FormControl fullWidth>
                <TextField
                  name='inspectorPhone'
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={mobile} alt='...' className='phone-icon' />
                        <p className='countrycode'>+91</p>
                      </InputAdornment>
                    ),
                  }}
                  value={mobile_no}
                  disabled
                />
              </FormControl>
            </Col>
          </>
        )}
      </>
    );
  };

  const [handleLabourUnion, setHandleLabourUnion] = React.useState("");

  const handleLabourChange = (e) => {
    console.log(e.target.value, "calculator");

    const unionId = e.target.value;
    setHandleLabourUnion(e.target.value);
  };

  const LabourUnionForm = () => {
    // if(users.unionDetails !== null){

    const selectedUnionData =
      users.unionDetails !== null && users.unionDetails !== "Network Error"
        ? users.unionDetails.find((values) => values.name === handleLabourUnion)
        : undefined;

    // var name = users.nintydays_details.unionName
    var registeration_no =
      selectedUnionData !== undefined ? selectedUnionData.registeration_no : "";
    var spoke_person =
      selectedUnionData !== undefined ? selectedUnionData.spoke_person : "";
    var phone_no =
      selectedUnionData !== undefined ? selectedUnionData.phone_no : "";

    console.log(selectedUnionData);

    // var name = users.nintydays_details.unionName
    // var registeration_no = users.nintydays_details.unionRegistrationNumber
    // var spoke_person = users.nintydays_details.unionIssuerName
    // var phone_no = users.nintydays_details.unionPhone

    // if (users.unionDetails !== null) {
    //     if (users.unionDetails[0].name !== null) {
    //         name = users.unionDetails[0].name
    //     }
    //     if (users.unionDetails[0].registeration_no !== null) {
    //         registeration_no = users.unionDetails[0].registeration_no
    //     }
    //     if (users.unionDetails[0].spoke_person !== null) {
    //         spoke_person = users.unionDetails[0].spoke_person
    //     }
    //     if (users.unionDetails[0].phone_no !== null) {
    //         phone_no = users.unionDetails[0].phone_no
    //     }

    // }

    return (
      <>
        <Col xs={12} md={6} className='issuerTypeForm'>
          <p className='mt-5 mb-2'>
            <Translate value='unionName' />
          </p>

          <FormControl variant='outlined' fullWidth>
            <Select
              className='select-marital'
              value={handleLabourUnion}
              name='issuerType'
              displayEmpty
              onChange={handleLabourChange}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>

              {users.unionDetails !== "Network Error" &&
                users.unionDetails !== null &&
                users.unionDetails.map((union) => {
                  return (
                    <MenuItem value={union.name}>
                      <ListItemText primary={union.name} />
                    </MenuItem>
                  );
                })}
              {/* {allValues.issuerTypes !== null  && 
                                allValues.issuerTypes.length>0 && allValues.issuerTypes.map((i) =>
                                    <MenuItem value={i.value_id}>
                                        <ListItemText primary={i.value} />
                                    </MenuItem>
                                )} */}
            </Select>
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='unionRegistrationNumber' />
          </p>
          <FormControl fullWidth>
            <TextField
              name='unionRegistrationNumber'
              onChange={handleChange}
              value={registeration_no}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='nameofIssuer' />
          </p>
          <FormControl fullWidth>
            <TextField
              name='unionIssuerName'
              onChange={handleChange}
              value={spoke_person}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='mobileNumberIfAvailable' />
          </p>
          <FormControl fullWidth>
            <TextField
              name='unionPhone'
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={mobile} alt='...' className='phone-icon' />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              value={phone_no}
              disabled
            />
          </FormControl>
        </Col>
      </>
    );

    // }
  };

  const SecretaryForm = () => {
    return (
      <>
        <Col xs={6} className='issuerTypeForm'>
          <p className='mt-4 mb-2'>
            <Translate value='nameofIssuer' />
          </p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Issuer Name'
              name='issuerName'
              type='text'
              // onChange={handleChange}
              onChange={(e) => {
                handleChange(e);
              }}
              value={allValues.issuerName}
              helperText={
                allValues.issuerNameError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerNameError}
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='placeofIssuer' />
          </p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Place of Issuer'
              name='issuerPlace'
              type='text'
              onChange={handleChange}
              value={allValues.issuerPlace}
              helperText={
                allValues.issuerPlaceError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerPlaceError}
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='mobileNumberIfAvailable' />
          </p>
          <FormControl fullWidth>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={mobile} alt='...' className='phone-icon' />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              placeholder='Enter Mobile Number of Issuer'
              name='issuerPhone'
              onChange={handleChange}
              value={allValues.issuerPhone}
              helperText={
                allValues.issuerPhoneError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerPhoneError}
            />
          </FormControl>
        </Col>
      </>
    );
  };

  const EmployerConstructionForm = () => {
    return (
      <>
        <Col xs={6} className='issuerTypeForm'>
          <p className='mt-4 mb-2'>
            <Translate value='nameofIssuer' />
          </p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Name of Issuer'
              name='issuerName'
              type='text'
              onChange={handleChange}
              value={allValues.issuerName}
              helperText={
                allValues.issuerNameError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerNameError}
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='designationofIssuer' />
          </p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Designation of Issuer'
              name='issuerDesignation'
              onChange={handleChange}
              value={allValues.issuerDesignation}
              helperText={
                allValues.issuerDesignationError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerDesignationError}
            />
          </FormControl>

          <p className='mt-4 mb-2'>
            <Translate value='mobileNumberIfAvailable' />
          </p>
          <FormControl fullWidth>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={mobile} alt='...' className='phone-icon' />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              placeholder='Enter Mobile Number of Issuer'
              name='issuerPhone'
              onChange={handleChange}
              value={allValues.issuerPhone}
              helperText={
                allValues.issuerPhoneError
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.issuerPhoneError}
            />
          </FormControl>
        </Col>
      </>
    );
  };

  const submitButtonPressed = () => {
    console.log("issuerNameSubmit", allValues.issuerName);
    // console.log("issuerNameSubmit",users.nintydays_details.employmentsList[i].issuer_name)
    // console.log("issuerNameSubmit",users.nintydays_details.employmentsList[i].issuer_place)
    // console.log("issuerNameSubmit",users.nintydays_details.employmentsList[i].mobile_no)

    if (
      Object.keys(users.getUserRegistrationDetails.certificate_details)
        .length !== 0
    ) {
      console.log("ISSUE1");

      if (
        (issuerGramName === false &&
          users.nintydays_details.employmentsList[0].issuer_name === "") ||
        (issuerGramPhone === false &&
          users.nintydays_details.employmentsList[0].mobile_no === "") ||
        (issuerGramPlace == false &&
          users.nintydays_details.employmentsList[0].issuer_place === "")
      ) {
        showToast(
          "WARN",
          "Please Fill All the 90 Days Certificate Fields correctly to Continue!"
        );
      }
    } else {
      console.log("ISSUE2");

      if (
        issuerGramName === false ||
        issuerGramPhone === false ||
        issuerGramPlace == false
      ) {
        showToast(
          "WARN",
          "Please Fill All the 90 Days Certificate Fields correctly to Continue!"
        );
      }
    }

    for (var i = 0; i < users.nintydays_details.noOfEmployementDetails; i++) {
      if (
        users.nintydays_details.employmentsList[i].employerDetails === "" ||
        users.nintydays_details.employmentsList[i].workplaceSiteaddress ===
          "" ||
        users.nintydays_details.employmentsList[i].pincode === "" ||
        users.nintydays_details.employmentsList[i].state === "" ||
        users.nintydays_details.employmentsList[i].districtWorkplace === "" ||
        users.nintydays_details.employmentsList[i].cityGram === "" ||
        users.nintydays_details.employmentsList[i].talukWorkplace === "" ||
        users.nintydays_details.employmentsList[i].villageWard === "" ||
        users.nintydays_details.employmentsList[i].state === null ||
        users.nintydays_details.employmentsList[i].districtWorkplace === null ||
        users.nintydays_details.employmentsList[i].cityGram === null ||
        users.nintydays_details.employmentsList[i].talukWorkplace === null ||
        users.nintydays_details.employmentsList[i].villageWard === null ||
        users.nintydays_details.employmentsList[i].natureWorkplace === "" ||
        users.nintydays_details.employmentsList[i].natureWorkplace === null ||
        users.nintydays_details.employmentsList[i].pincode === "" ||
        users.nintydays_details.employmentsList[i].issuerName === "" ||
        users.nintydays_details.employmentsList[i].issuerDesignation === "" ||
        allValues.issuerTypes === null ||
        (users.certificate_details !== undefined &&
          users.certificate_details[0].catalog_value_type_of_issuer_id == 58 &&
          allValues.issuerName === "") ||
        (users.certificate_details !== undefined &&
          users.certificate_details[0].catalog_value_type_of_issuer_id == 58 &&
          allValues.issuerDesignation === "") ||
        (users.nintydays_details.issuerType == 58 &&
          allValues.issuerName === "") ||
        (users.nintydays_details.issuerType == 58 &&
          allValues.issuerDesignation === "") ||
        users.nintydays_details.employmentsList[i].issuer_name === "" ||
        users.nintydays_details.employmentsList[i].issuer_place === "" ||
        users.nintydays_details.employmentsList[i].mobile_no === "" ||
        allValues.issuerTypes === null ||
        dropdownNotselected ||
        allValues.issuerPhoneError
      ) {
        console.log(dropdownNotselected, "dropdownNotselected");
        showToast(
          "WARN",
          "Please Fill All the Required Fields correctly to Continue!"
        );
        break;
      }
    }

    console.log("var i1=" + users.nintydays_details.noOfEmployementDetails);

    if (i === users.nintydays_details.noOfEmployementDetails) {
      // console.log("SAVE0",users.nintydays_details.employmentsList[i].villageWard)
      if (
        // users.nintydays_details.imgfile === "" ||
        // sessionStorage.getItem('90_days_certificate') === null ||
        users.nintydays_details.selectedDate === null
        // users.nintydays_details.documentUploadResponse.fileId === ""
      ) {
        console.log("SAVE1");
        if (!users.nintydays_details.presentDetailsGiven) {
          showToast("WARN", "Please Add Present Working Details!");
        } else {
          showToast(
            "WARN",
            "Please Fill All 90 Days Certificate Details to Continue!"
          );
        }
      } else {
        console.log("SAVE2");

        if (selectedfiles !== null && selectedfiles.length > 0) {
          uploadNintyDays();
        } else {
          // if(users.getUserRegistrationDetails.certificate_details[0] !== undefined &&
          //     users.getUserRegistrationDetails.certificate_details[0] !== null &&
          //     users.getUserRegistrationDetails.certificate_details[0].length > 0){
          console.log(
            "SAVE3",
            Object.keys(users.getUserRegistrationDetails.certificate_details)
              .length === 0
          );
          console.log("SAVE4", Object.keys(selectedfiles).length === 0);

          if (
            Object.keys(selectedfiles).length === 0 &&
            Object.keys(users.getUserRegistrationDetails.certificate_details)
              .length === 0
          ) {
            showToast("ERROR", "Please add the certificate");
            console.log("SAVE10", selectedfiles);
          } else if (
            (selectedfiles !== null && selectedfiles.length > 0) ||
            Object.keys(selectedfiles).length !== 0
          ) {
            console.log("SAVE4");
            console.log("SAVE5", selectedfiles);

            if (
              users.getUserRegistrationDetails.certificate_details[0]
                .document_uplaod_files_xml !== undefined
            ) {
              if (
                users.getUserRegistrationDetails.certificate_details[0]
                  .document_uplaod_files_xml !== null &&
                !removedFile
              ) {
                submit90Days(
                  JSON.parse(
                    users.getUserRegistrationDetails.certificate_details[0]
                      .document_uplaod_files_xml
                  )
                );
              } else {
                showToast("ERROR", "Please add the certificate");
              }
            }
          } else {
            if (removedFile) {
              console.log("SAVE9", selectedfiles);
              showToast("ERROR", "Please add the certificate");
            } else {
              if (
                Object.keys(
                  users.getUserRegistrationDetails.certificate_details
                ).length !== 0 &&
                users.getUserRegistrationDetails.certificate_details !== [] &&
                users.getUserRegistrationDetails.certificate_details[0]
                  .document_uplaod_files_xml !== undefined
              ) {
                if (
                  users.getUserRegistrationDetails.certificate_details[0]
                    .document_uplaod_files_xml !== null &&
                  !removedFile
                ) {
                  submit90Days(
                    JSON.parse(
                      users.getUserRegistrationDetails.certificate_details[0]
                        .document_uplaod_files_xml
                    )
                  );
                }
              } else {
                showToast("ERROR", "Please add the certificate");
              }
            }
          }
          console.log("SAVE6");

          // }
        }
        // uploadNintyDays()

        // if (users.profile_details.editmode) {
        //     props.saveEditButtonPressed()
        // }
        // else {
        //     props.submitButtonPressed()
        // }
      }
    }
  };

  const uploadNintyDays = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setfileuploadresults(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('90_days_certificate') !== null && users.user !== null){
    //     let formdata = {
    //         'file': sessionStorage.getItem('90_days_certificate'),
    //         'userId': users.user.id,
    //         'fileType': '90_days_certificate'
    //     }
    //     console.error("formdata 90_days_certificate: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"90_days_certificate"))
    // }
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const init_90_days_details = {
    employmentsList: [
      {
        id: 0,
        employerDetails: "",
        workplaceSiteaddress: "",
        inchargePhoneNumber: "",
        contractorDeveloperName: "",
        contractorCompanyName: "",
        pincode: "",
        state: "",
        districtWorkplace: "",
        cityGram: "",
        talukWorkplace: "",
        villageWard: "",
        natureWorkplace: "",
      },
    ],

    noOfEmployementDetails: 1,
    selectedDate: null,
    imgfile: "",
    documentName: "",
    openDatePicker: false,
    issuerType: "",
    presentDetailsGiven: false,
    presentDetailsGivenIndex: "",
    inspectorName: "",
    inspectorCircle: "",
    inspectorPhone: "",
    unionName: "",
    unionRegistrationNumber: "",
    unionIssuerName: "",
    unionPhone: "",
    issuerName: "",
    issuerPlace: "",
    issuerPhone: "",
    issuerDesignation: "",
    documentUploadResponse: null,
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabour90DaysCertDetails !== null &&
      users.addLabour90DaysCertDetails.success !== undefined &&
      users.addLabour90DaysCertDetails.success !== null &&
      users.addLabour90DaysCertDetails.success === true
    ) {
      showToast("SUCCESS", "90 Days Certificate Details Saved Successfully!");

      dispatch(update90DaysData(init_90_days_details));
      dispatch(addLabour90DaysDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabour90DaysCertDetails !== null &&
      users.addLabour90DaysCertDetails.success !== undefined &&
      users.addLabour90DaysCertDetails.success !== null &&
      users.addLabour90DaysCertDetails.success === "false"
    ) {
      showToast("ERROR", "Error Saving Address Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabour90DaysCertDetails]);

  useEffect(() => {
    // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
    // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
    // console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)
    if (users.getUserRegistrationDetails !== null) {
      if (
        users.getUserRegistrationDetails.employer_details !== undefined &&
        users.getUserRegistrationDetails.employer_details.length > 0
      ) {
        var membersListData = [];
        var thispresentDetailsGiven = "";
        var thispresentDetailsGivenIndex = "";

        for (
          var i = 0;
          i < users.getUserRegistrationDetails.employer_details.length;
          i++
        ) {
          var val = users.getUserRegistrationDetails.employer_details[i];
          var Data = {
            id: i,
            employerDetails: val.catalog_value_employement_status_id,
            workplaceSiteaddress: val.site_address,
            inchargePhoneNumber: val.mobile_no,
            contractorDeveloperName: val.contractor_name,
            contractorCompanyName: val.contractor_company_name,
            pincode: val.pin_code,
            state: val.state_id,
            districtWorkplace: val.district_id,
            cityGram: val.city_town_gram_panchayat_id,
            talukWorkplace: val.taluk_id,
            villageWard: val.village_area_id,
            natureWorkplace: val.catalog_value_nature_of_work,
          };
          membersListData.push(Data);

          funcDispatchDistrict(i, val.state_id);
          funcDispatchTaluk(i, val.district_id);
          funcDispatchCity(i, val.taluk_id);
          funcDispatchVillage(i, val.city_town_gram_panchayat_id);

          console.error(
            "val.catalog_value_employement_status_id: " +
              val.catalog_value_employement_status_id
          );
          console.error(
            "allValues.presentDetailsGivenIndex: " +
              allValues.presentDetailsGivenIndex
          );

          if (
            val.catalog_value_employement_status_id === 25 &&
            allValues.presentDetailsGivenIndex === ""
          ) {
            console.error("inside if: make present true");
            thispresentDetailsGiven = true;
            thispresentDetailsGivenIndex = i;
          }
        }

        var documentName = "";
        var selectedDate = "";
        var issuerType = "";
        var inspectorName = "";
        var inspectorCircle = "";
        var inspectorPhone = "";

        var unionName = "";
        var unionRegistrationNumber = "";
        var unionIssuerName = "";
        var unionPhone = "";

        var issuerName = "";
        var issuerPlace = "";
        var issuerPhone = "";
        var issuerDesignation = "";

        var issuedDate = "";

        if (users.getUserRegistrationDetails.certificate_details.length > 0) {
          var val1 = users.getUserRegistrationDetails.certificate_details[0];

          documentName = val1.document_uploaded_id;
          selectedDate = val1.issue_date;
          issuerType = val1.catalog_value_type_of_issuer_id;
          inspectorName = `${val1.inspector_first_name} ${val1.inspector_middle_name} ${val1.inspector_last_name} `;
          inspectorCircle = val1.inspector_circle_id;
          inspectorPhone = val1.inspector_mobile_no;

          unionName = val1.labour_union_name;
          unionRegistrationNumber = val1.labour_union_registration_no;
          unionIssuerName = val1.labour_union_spoke_person;
          unionPhone = val1.aslabour_union_phone_no;

          issuerName = val1.issuer_name;
          issuerPlace = val1.issuer_place;
          issuerPhone = val1.mobile_no;
          issuerDesignation = val1.issuer_designation;

          issuedDate = val1.issue_date;

          dispatch(
            getFile(
              users.getUserRegistrationDetails.certificate_details[0]
                .document_uploaded_id,
              "90_days_certificate"
            )
          );
        }

        if (
          users.getUserRegistrationDetails.certificate_details[0]
            .document_uplaod_files_xml !== undefined &&
          users.getUserRegistrationDetails.certificate_details[0]
            .document_uplaod_files_xml !== null &&
          users.getUserRegistrationDetails.certificate_details[0]
            .document_uplaod_files_xml.length > 0
        ) {
          setfilesxmlPresent(true);
        }

        setAllValues({
          ...allValues,

          noOfEmployementDetails:
            users.getUserRegistrationDetails.employer_details.length,

          selectedDate: selectedDate,
          // imgfile: users.nintydays_details.imgfile,
          documentName: documentName,
          // openDatePicker: users.nintydays_details.openDatePicker,
          // employmentsList: users.nintydays_details.employmentsList,
          issuerType: issuerType,

          inspectorName: inspectorName,
          inspectorCircle: inspectorCircle,
          inspectorPhone: inspectorPhone,

          unionName: unionName,
          unionRegistrationNumber: unionRegistrationNumber,
          unionIssuerName: unionIssuerName,
          unionPhone: unionPhone,

          issuerName: issuerName,
          issuerPlace: issuerPlace,
          issuerPhone: issuerPhone,
          issuerDesignation: issuerDesignation,

          issuedDate: users.nintydays_details.dob,
          documentUploadResponse:
            users.nintydays_details.documentUploadResponse,

          presentDetailsGiven: thispresentDetailsGiven,
          presentDetailsGivenIndex: thispresentDetailsGivenIndex,

          employmentsList: membersListData,
        });
      }
    }
  }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    if (users.getFile !== null) {
      sessionStorage.setItem("90_days_certificate", users.getFile.data);
      // setimagesavedinlocal(true)
      // dispatch(updateGetFile(""))
    }
  }, [users.getFile]);

  return (
    <>
      <Row className='m-0'>
        <Row className='family-title-row'>
          <Col
            xs={12}
            className='profile-title'
            style={{ marginBottom: "-20px" }}
          >
            <h1>
              <Translate value='nintyDays' />
            </h1>
          </Col>
        </Row>
        <Row className='family-title-row'>
          <Col xs={12} className='family-col-4'>
            {employmentDetails}
          </Col>
        </Row>
        <Row className='form-row'>
          <Col
            xs={12}
            className='addMemberCol mt-4 '
            // onClick={addNewEmploymentDetail}
            onClick={() => addNewEmploymentDetail()}
          >
            <AddCircleRounded />
            <p>
              <Translate value='addMoreEmplymentDetails' />
            </p>
          </Col>

          <Col xs={12} className='note2 text-center mt-5 personal-col-7'>
            {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
            <p>
              <span>
                <Translate value='note' />{" "}
              </span>
              <Translate value='afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess2' />
            </p>
          </Col>
        </Row>

        {users.nintydays_details.presentDetailsGiven ? (
          <>
            <Row className='nintydays-cert-title-row'>
              <Col xs={12} className='profile-title'>
                <h1 style={{ color: "#ca0027" }}>
                  <Translate value='daysWorkCertificateDetails' />
                </h1>
              </Col>
            </Row>

            <Row className='form-row ninydays-row'>
              <Col xs={12} className='nintydays-col-5'>
                <Row className='form-inner-card-plain'>
                  <Col xs={12} md={6}>
                    <Required
                      className='mt-2 mb-2'
                      title={<Translate value='typeofIssuer' />}
                    />
                    <FormControl variant='outlined' fullWidth>
                      <Select
                        className='select-marital'
                        value={users.nintydays_details.issuerType}
                        name='issuerType'
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value=''>
                          <ListItemText primary='-Select-' />
                        </MenuItem>
                        {allValues.issuerTypes !== null &&
                          allValues.issuerTypes.length > 0 &&
                          allValues.issuerTypes.map((i) => (
                            <MenuItem value={i.value_id}>
                              <ListItemText primary={i.value} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={12} md={6}>
                    <Required
                      className='mt-2 mb-2'
                      title={<Translate value='issuedDate' />}
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        minDate={moment().subtract(90, "days")}
                        maxDate={new Date()}
                        onClick={() => setPickerStatus(true)}
                        onClose={() => setPickerStatus(false)}
                        open={users.nintydays_details.openDatePicker}
                        className='datepicker'
                        margin='normal'
                        format='D/MM/yyyy'
                        value={allValues.selectedDate}
                        // onChange={(date) => handleDateChange(date) }
                        onChange={(date) => {
                          setIssuedDate(date);
                        }}
                        placeholder='DD/MM/YYYY'
                        // KeyboardButtonProps={{
                        //     'aria-label': 'change date',
                        // }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>

                  {
                    {
                      55: <LabourInspectorForm />,
                      56: <LabourUnionForm />,
                      57: (
                        <Col xs={12} md={6} className='issuerTypeForm'>
                          <p className='mt-4 mb-2'>
                            <Translate value='nameofIssuer' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Issuer Name'
                              name='issuerName'
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={allValues.issuerName}
                              helperText={
                                allValues.issuerNameError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerNameError}
                            />
                          </FormControl>

                          <p className='mt-4 mb-2'>
                            <Translate value='placeofIssuer' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Place of Issuer'
                              name='issuerPlace'
                              onChange={handleChange}
                              value={allValues.issuerPlace}
                              helperText={
                                allValues.issuerPlaceError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerPlaceError}
                            />
                          </FormControl>

                          <p className='mt-4 mb-2'>
                            <Translate value='mobileNumberIfAvailable' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      src={mobile}
                                      alt='...'
                                      className='phone-icon'
                                    />
                                    <p className='countrycode'>+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder='Enter Mobile Number of Issuer'
                              name='issuerPhone'
                              onChange={handleChange}
                              value={allValues.issuerPhone}
                              helperText={
                                allValues.issuerPhoneError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerPhoneError}
                            />
                          </FormControl>
                        </Col>
                      ),
                      58: (
                        <Col xs={12} md={6} className='issuerTypeForm'>
                          <p className='mt-4 mb-2'>
                            <Translate value='nameofIssuer' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Name of Issuer'
                              name='issuerName'
                              // type = "text"
                              onChange={handleChange}
                              value={allValues.issuerName}
                              helperText={
                                allValues.issuerNameError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerNameError}
                            />
                          </FormControl>

                          <p className='mt-4 mb-2'>
                            <Translate value='designationofIssuer' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Designation of Issuer'
                              name='issuerDesignation'
                              type='text'
                              onChange={handleChange}
                              value={allValues.issuerDesignation}
                              helperText={
                                allValues.issuerDesignationError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerDesignationError}
                            />
                          </FormControl>

                          <p className='mt-4 mb-2'>
                            <Translate value='mobileNumberIfAvailable' />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      src={mobile}
                                      alt='...'
                                      className='phone-icon'
                                    />
                                    <p className='countrycode'>+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder='Enter Mobile Number of Issuer'
                              name='issuerPhone'
                              onChange={handleChange}
                              value={allValues.issuerPhone}
                              helperText={
                                allValues.issuerPhoneError
                                  ? "Please enter in correct format"
                                  : ""
                              }
                              error={allValues.issuerPhoneError}
                            />
                          </FormControl>
                        </Col>
                      ),
                    }[users.nintydays_details.issuerType]
                  }
                </Row>
              </Col>

              <Col xs={12} className='nintydays-col-6'>
                {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='uploadYourDaysWorkingCertificate' />}
                />

                <div className='browsebutton-outerdiv'>
                  <div className='browsebutton2-div filesNames'>
                    {loadingfiles &&
                    users.getUserRegistrationDetails.certificate_details[0] !==
                      undefined &&
                    users.getUserRegistrationDetails.certificate_details[0]
                      .document_uplaod_files_xml !== undefined &&
                    filesxmlPresent
                      ? Array.from(
                          JSON.parse(
                            users.getUserRegistrationDetails
                              .certificate_details[0].document_uplaod_files_xml
                          )
                        ).map((i, index) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='passbookDocumentName'
                              value={i.file_name}
                              disabled
                            />
                          </FormControl>
                        ))
                      : null}

                    {loadingfiles ? (
                      <></>
                    ) : (
                      selectedfiles !== null &&
                      selectedfiles.length > 0 &&
                      Array.from(selectedfiles).map((i, index) => (
                        <FormControl>
                          <TextField
                            variant='outlined'
                            name='passbookDocumentName'
                            value={i.name}
                            onChange={handleChange}
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <img
                                    alt='...'
                                    src={closeicon}
                                    className='removefilebtn'
                                    onClick={() => removefile(index)}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      ))
                    )}
                  </div>
                  <div className='browsebutton2-div'>
                    <label
                      htmlFor='upload-photo'
                      className='browse-button btn2'
                    >
                      <img alt='...' src={folder} />{" "}
                      <Translate value='browse' />
                    </label>
                    <input
                      type='file'
                      name='photo'
                      id='upload-photo'
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>

                <p className='mt-4 instructions-ration'>
                  <span>
                    <Translate value='instructions' />* :{" "}
                  </span>
                  <br />
                  <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                  <br />
                  <Translate value='sizecontains500KBto2MB' />
                </p>
              </Col>
            </Row>
          </>
        ) : null}

        <Row className='button-inside-form-row mb-5'>
          <Col xs={12} className='next-back-button-row mt-4 '>
            {!users.profile_details.editmode ? (
              <>
                <Link
                  to='#'
                  onClick={props.backButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='danger' className='back-button'>
                    <Translate value='back' />
                  </Button>
                </Link>
                <Link
                  to='#'
                  onClick={submitButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='outline-primary' className='next-button'>
                    <Translate value='saveandContinue' />
                  </Button>
                </Link>
              </>
            ) : (
              <Link
                to='#'
                onClick={submitButtonPressed}
                style={{ flexGrow: "0.5" }}
              >
                <Button variant='outline-primary' className='next-button'>
                  <Translate value='saveyourDetails' />
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default NintyDays;
