export function hasEmptyPropertiesFamilyDetails(obj) {
  const propertiesToCheck = [
    'firstName',
    'relation',
    'education',
    'dob',

    'profession',
  ];

  for (const key of propertiesToCheck) {
    if (!obj[key] && obj[key] !== 0) {
      return true;
    }
  }
  return false;
}

export function hasEmptyPropertiesBankDetails(obj) {
  const propertiesToCheck = [
    'bankAccountNumber',
    'bankIFSCCODE',
    'bankBranchName',
    'bankName',
    'bankAddress',
  ];

  for (const key of propertiesToCheck) {
    if (!obj[key] && obj[key] !== 0) {
      return true;
    }
  }
  return false;
}

export function hasEmptyPropertiesEditBankDetails(obj) {
  const propertiesToCheck = [
    'accountNumber',
    'ifsc_code',
    'bankName',
    'bankBranch',
    'bankAddress',
  ];

  for (const key of propertiesToCheck) {
    if (!obj[key] && obj[key] !== 0) {
      return true;
    }
  }
  return false;
}

export function hasEmptyPropertiesEmployerDetails(obj) {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === '' || obj[key] === null || obj[key] === undefined)
    ) {
      return true;
    }
  }
  return false;
}

export const hasEmptyValues = (arr, key) => {
  for (let i = 0; i < arr.length; i++) {
    if (
      arr[i][key] === null ||
      arr[i][key] === undefined ||
      arr[i][key] === ''
    ) {
      return true;
    }
  }
  return false;
};

export const findDuplicates = (arr, prop) => {
  const count = arr.reduce((acc, obj) => {
    const key = obj[prop];
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(count)
    .filter(([key, value]) => value > 1)
    .map(([key]) => key);
};
