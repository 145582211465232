import 'date-fns';
import { Select, MenuItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, Drawer, List, ListItem, Divider, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, Paper, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-dashboard 2.svg'
import homewhite from 'assets/images/Icon material-dashboard.svg'
import user from 'assets/images/Icon awesome-user-edit.svg'
import userwhite from 'assets/images/Icon awesome-user-edit 2.svg'
import board from 'assets/images/Icon awesome-chalkboard-teacher.svg'
import boardwhite from 'assets/images/Icon awesome-chalkboard-teacher 2.svg'
import config from 'assets/images/Icon awesome-tools.svg'
import configwhite from 'assets/images/Icon awesome-tools 2.svg'
import mapping from 'assets/images/Icon awesome-link.svg'
import mappingwhite from 'assets/images/Icon awesome-link 2.svg'
import reports from 'assets/images/Icon material-report.svg'
import reportswhite from 'assets/images/Icon material-report.svg'
import vector from 'assets/images/Wall post-amico.svg'
import kbocwwb from 'assets/images/Image 8.png'
import Check from 'assets/images/check (3).svg'
import Eye from 'assets/images/eye.svg'
import Pending from 'assets/images/wall-clock.svg'
import notification from 'assets/images/Icon material-notifications-active.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getLabourStatusDetails, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { Translate } from "react-redux-i18n";

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import axios from 'axios';
import { getTokenCookie } from 'utils/tools';
import { CancelRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const KSUWSSB = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        // activestep: users.profile_details.activestep,
        activestep: 1,
        percentComplete: users.profile_details.percentComplete,
        editmode: users.profile_details.editmode,
        statusActive: "",
        statusActiveLi: "",

    })

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,

            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    const onItemClickHandler = (num) => {
        setAllValues({
            ...allValues,
            activestep: num
        })
    }

    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
            width: '350px'
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));



    const [Loading, setLoading] = React.useState(true);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElksu, setAnchorElksu] = React.useState(null);
    const [anchorElconfig, setAnchorElconfig] = React.useState(null);
    const [anchorElmapping, setAnchorElmapping] = React.useState(null);

    const onSelectStatusHandler = (event, data) => {

        dispatch(getLabourStatusDetails("delete"))

        setAllValues({
            ...allValues,
            statusActiveLi: data
        })
        handleClosekboc()
    }


    const onSelectClickHandlerkboc = (event, data) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosekboc = (event, data) => {
        setAnchorEl(null);
    }

    const [regstatus, setregstatus] = React.useState([])

    useEffect(() => {

        if (regstatus !== [] && regstatus.length > 0) {
            var pendingStatus = null
            console.error("regstatus.length: " + regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {
                console.error("regstatus: " + JSON.stringify(regstatus[i]))
                if (regstatus[i].value === "Pending") {
                    console.error("pending status: " + regstatus[i].value_id)
                    pendingStatus = regstatus[i].value_id
                    setAllValues({
                        ...allValues,
                        statusActive: "active",
                        statusActiveLi: regstatus[i].value
                    })
                }
            }
            let dataToSubmit = {
                "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                "role_id": 2,
                "board_id": 4,
                "registration_status_id": pendingStatus,
                "is_date_selected": 0,
                "start_date":null,
                "end_date":null,
                "acck_no" : null,
                "mobile_no": null,
                "aadhaar_no": null,
            }
            dispatch(getLabourStatusDetails(dataToSubmit))
        }

    }, [regstatus]);

    useEffect(() => {
        console.error("useeffect allValues.statusActiveLi: " + allValues.statusActiveLi)
        if (regstatus !== [] && regstatus.length > 0) {
            setLoading(true)
            var selectedStatus = null
            console.error("regstatus.length: " + regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {

                console.error("regstatus: " + JSON.stringify(regstatus[i]))

                if (regstatus[i].value === allValues.statusActiveLi) {
                    console.error("selected status: " + regstatus[i].value_id)
                    selectedStatus = regstatus[i].value_id
                }
            }


            let dataToSubmit = {
                "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                "role_id": 2,
                "board_id": 4,
                "registration_status_id": selectedStatus,
                "is_date_selected": 0,
                "start_date":null,
                "end_date":null,
                "acck_no" : null,
                "mobile_no": null,
                "aadhaar_no": null,
            }

            dispatch(getLabourStatusDetails(dataToSubmit))
        }

    }, [allValues.statusActiveLi]);

    useEffect(() => {
        if (
            users.getLabourStatusDetails !== undefined && users.getLabourStatusDetails !== null
            // && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0
        ) {
            setLoading(false)
        }
    }, [users.getLabourStatusDetails]);

    useEffect(() => {
        // console.log("in useEffect [users.user]")
        // if (users.user !== null && users.user.board_id !== null) {

        let dataForCatalog = { "board_id": 1, "catalog_name": "Registration Status" };
        // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))

        dispatch(getCatalogDetails(dataForCatalog))
        // }
        // }
    }, [users.user]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Registration Status") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setregstatus(users.catalog_details)
        }
    }, [users.catalog_details]);



    return (
        <>
            {/* <div className="root"> */}
            {/* <CssBaseline /> */}
            {/* <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={notification} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.login_admin.data.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
                </div> */}

            <Row className="kbocwwb-page">
                <Col xs={12}>
                    <p className="title">Unorganized Worker Registration Details</p>
                </Col>
                {/* <Col xs={3} className="kbocwwb-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <img alt="..." className="cal-icon"/>
                            <p>From Date</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}
                {/* <Col xs={2} className="kbocwwb-col todate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <img alt="..." className="cal-icon"/>
                            <p>To Date</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}
                <Col xs={2} className="kbocwwb-col">
                    <div aria-controls="customized-menu" aria-haspopup="true" className={`kbocwwb-col-div hvr-icon-hang ${allValues.statusActive}`} onClick={(e) => onSelectClickHandlerkboc(e, "kbocwwb")}>
                        <p>Status</p>
                        <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                        id="customized-menu"
                        className="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(e) => handleClosekboc(e, "kbocwwb")}
                    >
                        {/* <MenuItem onClick={(e) => onSelectStatusHandler(e,"Pending")} className={allValues.statusActiveLi === "Pending" ? "active" : null}>
                                <ListItemText primary="Pending"/>
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Approved")} className={allValues.statusActiveLi === "Approved" ? "active" : null}> 
                                <ListItemText primary="Approved" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Rejected")} className={allValues.statusActiveLi === "Rejected" ? "active" : null}>
                                <ListItemText primary="Rejected" />
                            </MenuItem> */}

                        {regstatus.map((row) => (

                            // users.getDeptUserRole.data[0].role_id === 3 ?
                            // ?                                                                
                            <MenuItem onClick={(e) => onSelectStatusHandler(e, row.value)} className={allValues.statusActiveLi === row.value ? "active" : null}>
                                <ListItemText primary={row.value} />
                            </MenuItem>
                            // :
                            // null


                        )
                        )}

                    </StyledMenu>

                </Col>
                {/* <Col xs={3} className="kbocwwb-col">
                        <div aria-controls="customized-menu2" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang">
                            <p>Due for Approval</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col>
                    <Col xs={2} className="kbocwwb-col">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>Circle</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}

                <Col xs={12}>

                    <TableContainer
                    // component={Paper}
                    >
                        <Table
                            className="mt-4"
                            aria-label="simple table"
                            style={{ border: "0.5px solid #D9D4D4" }}>
                            <TableBody>

                                {
                                    Loading ?
                                        <CircularProgress />
                                        :
                                        <>
                                            {
                                                {
                                                    'Pending':
                                                        <>

                                                            {
                                                                users.getLabourStatusDetails !== undefined && users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails !== undefined && users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 && users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Pending} className="mr-2" />Pending</TableCell>
                                                                                <TableCell align="center">
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: "/admin/unorganized/details",
                                                                                            userid: row.labour_user_id,
                                                                                            showbuttons: true,
                                                                                            frompage: "Pending"
                                                                                        }}
                                                                                    >
                                                                                        <img alt="..." src={Eye} className="mr-2" />View
                                                        </Link>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No Pending applications</p>
                                                            }
                                                        </>
                                                    ,
                                                    'Approved':
                                                        <>
                                                            {
                                                                users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center" className="approved-li">
                                                                                    <img alt="..." src={Check} className="mr-2" />Approved</TableCell>
                                                                                <TableCell align="center" className="details-li">
                                                                                    {/* <img alt="..." src={Eye} className="mr-2"/> */}
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: "/admin/unorganized/details",
                                                                                            userid: row.labour_user_id,
                                                                                            showbuttons: false
                                                                                        }}
                                                                                    >
                                                                                        <img alt="..." src={Eye} className="mr-2" />View
                                                        </Link>
                                                                                    {/* Details */}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No Approved applications</p>
                                                            }
                                                        </>
                                                    ,
                                                    'Rejected':
                                                        <>
                                                            {
                                                                users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center" style={{ color: '#DB0909' }}>
                                                                                    {/* <img alt="..." src={Pending} className="mr-2"/> */}
                                                                                    <CancelRounded style={{ color: '#DB0909' }} className="mr-2" />
                                                                                    Rejected</TableCell>
                                                                                <TableCell align="center" style={{ color: '#DB0909' }}>Application Rejected reason</TableCell>
                                                                                <TableCell align="center" className="details-li">
                                                                                    {/* <img alt="..." src={Eye} className="mr-2"/> */}
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: "/admin/unorganized/details",
                                                                                            userid: row.labour_user_id,
                                                                                            showbuttons: false
                                                                                        }}
                                                                                    >
                                                                                        <img alt="..." src={Eye} className="mr-2" />View
                                                        </Link>
                                                                                    {/* Details */}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No Rejected applications</p>
                                                            }
                                                        </>
                                                    ,
                                                    'Appealed':
                                                        <>
                                                            {
                                                                users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Pending} className="mr-2" />Appealed</TableCell>
                                                                                <TableCell align="center">
                                                                                    <Link
                                                                                        // to="/admin/unorganized/details" 
                                                                                        to={{
                                                                                            pathname: "/admin/unorganized/details",
                                                                                            userid: row.labour_user_id,
                                                                                            showbuttons: true,
                                                                                            frompage: "Appealed"
                                                                                        }}
                                                                                    >
                                                                                        <img alt="..." src={Eye} className="mr-2" />View
                                                        </Link>
                                                                                    {/* <img alt="..." src={Eye} className="mr-2"/>View</TableCell> */}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No Appealed applications</p>
                                                            }
                                                        </>
                                                    ,
                                                    'Seek Clarification':
                                                        <>
                                                            {
                                                                users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Pending} className="mr-2" />Seek Clarification</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Eye} className="mr-2" />Details</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No 'Seek Clarification' applications</p>
                                                            }
                                                        </>
                                                    ,
                                                    'Transfered':
                                                        <>
                                                            {
                                                                users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                                                    <>
                                                                        {users.getLabourStatusDetails.map((row) => (
                                                                            <TableRow key={row.name}>
                                                                                <TableCell align="center">{row.labour_full_name}</TableCell>
                                                                                <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Pending} className="mr-2" />Transfered</TableCell>
                                                                                <TableCell align="center">
                                                                                    <img alt="..." src={Eye} className="mr-2" />Details</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>

                                                                    :

                                                                    <p>No 'Transfered' applications</p>
                                                            }
                                                        </>
                                                    ,
                                                }[allValues.statusActiveLi]

                                            }
                                        </>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Col>

            </Row>
        </>
    )
}

export default KSUWSSB;