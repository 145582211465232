
import Paper from "@mui/material/Paper";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from 'react-bootstrap'
import axios from "axios";
import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead, TextField, InputAdornment, Select, Backdrop, makeStyles, FormControl } from '@material-ui/core';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { getCatalogDetails, getLabourStatusDetails, getSchemesOnStatus, get_schemes, SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { getTokenCookie } from 'utils/tools';
import { Button, ButtonBase, TableFooter } from "@mui/material";
import { CSVLink } from "react-csv";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Excel from "../../assets/images/excel.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

export default function MISGrid({selectedReg,selectedActive,selectedFreeze,searchBtn,deptID,roleID}) {
  const [districtData, setDistrictData] = useState([]);
  const [districtDataRen, setDistrictDataRen] = useState([]);
  const [talukData, setTalukData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showTaluk, setShowTaluk] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [districtMIS, setDistrictMIS] = useState([]);
  const [talukMIS, setTalukMIS] = useState([]);
  const [cityMIS, setCityMIS] = useState([]);
  const [misReportCSVData, setMISReportCSVData] = useState([]);
  const [misReportData, setMISReportData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloadCSV,setDownloadCSV] = useState(false)
    const [regSelect,setRegSelect] = useState("")
    const [activeSelect,setActiveSelect] = useState("")
    const [freezeSelect,setFreezeSelect] = useState("")
    const [search,setSearch] = useState(false)
    const [CSVOverall,setCSVOverall] = useState([])
    const [CSVOverallTaluk,setCSVOverallTaluk] = useState([])
    const [CSVOverallCircle,setCSVOverallCircle] = useState([])


    const excelRef = useRef(null)
    console.log(deptID,roleID);

  const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffffee',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));
    const classes = useStyles();

  
     const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
        
    };

     


    const handleChange = (e) => {
        console.log(e.target.value);
        setRegSelect(e.target.value);

    }
    const handleChange2 = (e) => {
        console.log(e.target.value);
        setActiveSelect(e.target.value);

    }
    const handleChange3 = (e) => {
        console.log(e.target.value);
        setFreezeSelect(e.target.value);

    }


    const callMISAPI = async () => {
      setOpenBackdrop(true)
        const data = {
          "board_id": 1,
          "role_id": roleID,
          "department_user_id": deptID,
          "registration_reneal": regSelect,
          "is_freezed": freezeSelect,
          "isActive": activeSelect
        }
        var config = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report_by_district',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
          }

        await axios(config)
          .then((res) => {
          console.log(res.data.data[0]);
          const sortedDistrict = res.data.data[0].sort((a,b)=> {
             const aDistrict = a.district 
             const bDistrict = b.district
             if(aDistrict < bDistrict){
                return -1
             }
             if(aDistrict > bDistrict)
             {
                return 1
             }
             return 0
            })
            console.log("SORTEDARR:",sortedDistrict);
            setOpenBackdrop(false)
          setDistrictData(sortedDistrict)
          setShowDistrict(true)
          handleFormatCSV(sortedDistrict)
          // setStateData(res.data.data);
          })
          .catch((err) => {
          console.log(err);
          });
  
        
      }


  let initialValue = 0
  const totalCount = districtData.reduce(function(accum,curValue){
        return accum + curValue.total_field
    },initialValue)

console.log("TotalCount",totalCount);


  const handleCSV = async (district_id) => {
    setOpenBackdrop(true)

    const data = {
      "board_id": 1,
      "role_id": roleID,
      "department_user_id": deptID,
      "registration_reneal": regSelect,
      "isActive_User": activeSelect,
      "IS_Freezed": freezeSelect,
      "mobile_number": null,
      "spdv_appl_ref_no": null,
      "district_id": district_id,
      "taluk_id": null,
      "circle_id": null,
      "from": 0,
      "next": 1000000
}

var config = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })


    
  };

    const handleCSVTaluk = async (taluk_id) => {
    setOpenBackdrop(true)

    const data = {
      "board_id": 1,
      "role_id": roleID,
      "department_user_id": deptID,
      "registration_reneal": regSelect,
      "isActive_User": activeSelect,
      "IS_Freezed": freezeSelect,
      "mobile_number": null,
      "spdv_appl_ref_no": null,
      "district_id": null,
      "taluk_id": taluk_id,
      "circle_id": null,
      "from": 0,
      "next": 1000000
}

var config = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])  
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })


    
  };

    const handleCSVCity = async (circle_id) => {
    setOpenBackdrop(true)

    const data = {
      "board_id": 1,
      "role_id": roleID,
      "department_user_id": deptID,
      "registration_reneal": regSelect,
      "isActive_User": activeSelect,
      "IS_Freezed": freezeSelect,
      "mobile_number": null,
      "spdv_appl_ref_no": null,
      "district_id": null,
      "taluk_id": null,
      "circle_id": circle_id,
      "from": 0,
      "next": 1000000
}

var config = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }

      await axios(config).then((res)=>{
            console.log(res.data.data[0]);
            // districtMIS(res.data.data[0])
            setMISReportData(res.data.data[0]);
            setTimeout(()=>{
              excelRef.current.link.click()
            },5000)
            setOpenBackdrop(false)
          //   var arr = [];
          //   res.data.data[0].forEach((i, key) => {
          //   console.log(i);
          //   arr.push(i);
          // }
          // );
        

        }).catch((err)=>{
          console.log(err);
        })


    
  };

  // useEffect(()=>{
  //   if(misReportData.length > 0){
  //     console.log("MISReportData",misReportData);
  //     setMISReportCSVData(misReportData)
  //     setDownloadCSV(true)
  //     excelRef.current.link.click()
  //   }
    
  // },[misReportData])

const talukHandler = async (id) => {
    console.log(id);

      const dataT = {
        "board_id": 1,
      "role_id": roleID,
      "department_user_id": deptID,
    "registration_reneal": regSelect,
    "is_freezed": freezeSelect,
    "isActive": activeSelect,
    "district_id": id
}
var configT = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report_by_taluk',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : dataT
        }

    await axios(configT)
      .then((res) => {
        console.log(res.data.data[0]);
        setTalukData(res.data.data[0]);
        handleFormatCSVTaluk(res.data.data[0])
        setShowDistrict(false);
        setShowTaluk(true);
      })
      .catch((err) => {
        console.log(err);
      });

        



  };
  var initialValue2 = 0
        const totalCountTaluk = talukData.reduce(function(accum,curValue){
        return accum + curValue.total_field
        },initialValue2)

        console.log("TotalCount",totalCountTaluk);

  const cityHandler = async (tID,dID) => {
    // console.log(id);
    console.log(tID,dID);
    const dataCircle = {
      "board_id": 1,
      "role_id": roleID,
      "department_user_id": deptID,
    "registration_reneal": regSelect,
    "is_freezed": freezeSelect,
    "isActive": activeSelect,
    "district_id": dID,
    "taluk_id":tID
}
var configCircle = {
            method: 'post',
                url: SERVER+'/reports/get_mis_report_by_circle',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : dataCircle
        }


    await axios(configCircle)
      .then((res) => {
        console.log("Circle",res.data.data[0]);
        setCityData(res.data.data[0]);
        handleFormatCSVCircle(res.data.data[0])
        setShowDistrict(false);
        setShowTaluk(false);
        setShowCity(true);
      })
      .catch((err) => {
        console.log(err);
      });

        


  };

  var initialValue3 = 0
        const totalCountCity = cityData.reduce(function(accum,curValue){
        return accum + curValue.total_field
        },initialValue3)

      console.log("TotalCount",totalCountCity);

  const handlePrintPDF = () => {
    const input = document.getElementById("printPDF");

    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        // pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        pdf.addImage(imgData, "JPEG", 0, 0, 150, 150);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.save("ecard.pdf");
        // window.open(pdf.output('bloburl'), '_blank')

        window.open(
          pdf.output("bloburl"),
          "_blank",
          "toolbar=0,location=0,menubar=0"
        );
      })
      .then(() => {
        // input.style.display = 'none';
        // dwnldicn.style.display = 'block';
        // dwnldicn.style.padding = "0px";
        // setLoading(false);
        // setOpenBackdrop(false);
      });
  };


  const headersDistrict = [
  { label: "District", key: "district" },
  { label: "No of registration", key: "total_field" },
  
];

const headersTaluk = [
  { label: "Taluk", key: "taluk_name" },
  { label: "No of registration", key: "total_field" },
  
];

const headersCircle = [
    { label: "Circle", key: "circle_name" },
    { label: "No of registration", key: "total_field" },
 
]


        const handleFormatCSV = (data) => {
        console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_field
      },initialValue)
        arr.push({
          district: "Total",
          total_field: Total
        })
        setCSVOverall(arr)
        console.log("Overall",arr);


    }

       const handleFormatCSVTaluk = (data) => {
        console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_field
      },initialValue)
        arr.push({
          taluk_name: "Total",
          total_field: Total
        })
        setCSVOverallTaluk(arr)
        console.log("Overall",arr);


    }

    const handleFormatCSVCircle = (data) => {
      console.log("FormatData",data);
        const arr = [...data];
        let initialValue = 0
        const Total = data.reduce(function(accum,curValue){
        return accum + curValue.total_field
      },initialValue)
        arr.push({
          circle_name: "Total",
          total_labours: Total
        })
        setCSVOverallCircle(arr)
        console.log("Overall",arr);
    }




  const handleBack = (value) => {
    console.log("BAck",value);
    if(value === "Taluk")
    {
      setShowDistrict(true)
      setShowTaluk(false)
    }
    else if(value === "Circle"){
      setShowTaluk(true)
      setShowCity(false)
    }
  }

  return (
    <>
    <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
                <Row className='container-fluid'>
                <>
                <Col xs={3}>
                    <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital w-100"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={regSelect}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleChange}
                                defaultValue={"Select"}
                            >
                                <MenuItem value={""} >
                                    <ListItemText primary="Select Registration / Renewal" />
                                </MenuItem>
                                <MenuItem value={"205"} >
                                    <ListItemText primary={"Registration"} />
                                </MenuItem>
                                <MenuItem value={"216"} >
                                    <ListItemText primary={"Renewal"} />
                                </MenuItem>
                                <MenuItem value={"217"} >
                                    <ListItemText primary={"Duplicate Cards"} />
                                </MenuItem>
                               <MenuItem value={"300"} >
                                    <ListItemText primary={"E-Karmika"} />
                                </MenuItem>
                            </Select>
                            </FormControl>
                        
                </Col>
                <Col xs={3} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={activeSelect}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleChange2}
                                defaultValue={"Select"}
                            >
                                <MenuItem value= {""} >
                                    <ListItemText primary="Select Active / Inactive" />
                                </MenuItem>
                                <MenuItem value={1} >
                                    <ListItemText primary={"Active"} />
                                </MenuItem>
                                <MenuItem value={0} >
                                    <ListItemText primary={"Inactive"} />
                                </MenuItem>
                                <MenuItem value={2} >
                                    <ListItemText primary={"1 year buffer pending for renewal"} />
                                </MenuItem>
                               
                            </Select>
                            </FormControl>
                        
                </Col>
                <Col xs={3} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={freezeSelect}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleChange3}
                                defaultValue={"Select"}
                            >
                                <MenuItem value= {""} >
                                    <ListItemText primary="Select Freezed / Unfreezed" />
                                </MenuItem>
                                <MenuItem value={1} >
                                    <ListItemText primary={"Freezed"} />
                                </MenuItem>
                                <MenuItem value={0} >
                                    <ListItemText primary={"Unfreezed"} />
                                </MenuItem>
                                
                               
                            </Select>
                        </FormControl>
                </Col>

                <Col xs={3} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                <Button color='success' variant='contained' onClick={callMISAPI} className='select-marital' style={{padding:'10px 0px'}}>Search</Button>

                </FormControl>  
                </Col>
                </>
                
                    </Row>
                    </div>
    
    <Backdrop className={classes.backdrop} open={openBackdrop}
                                    // onClick={loading ? null : handleCloseBackdrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
    
    <TableContainer component={Paper}>
      {showDistrict ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        
          <Table sx={{ minWidth: 650}} style={{width:'80%'}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>District</TableCell>
                {regSelect === "205" ?
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Registration
                </TableCell>
                 : regSelect === "216" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Renewals
                </TableCell> : 
                regSelect === "217" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Duplicates
                </TableCell>
                : 
                regSelect === "300" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of E-Karmika
                </TableCell> :
                null}
                
                
               
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { districtData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: "pointer" }}
                    onClick={() => talukHandler(row.district_id)}
                  >
                    {row.district === null ? "NA" : row.district}
                  </TableCell>
                  <TableCell align="center">{row.total_field}</TableCell>
                  
                  {/* <TableCell align="center">30</TableCell> */}
                  
                  <TableCell align="center">
                  
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef}>
                    </CSVLink>
                    
                      <Button
                        onClick={() => handleCSV(row.district_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
                </TableRow>
              ))}
              <ButtonBase />
            </TableBody>
            
            <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCount}
              </TableCell>
              <TableCell>
                <CSVLink data={CSVOverall} headers={headersDistrict} filename='District Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              </TableCell>
              
            </TableHead>
          </Table>
        </div>
      ) : null}
      {showTaluk ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        <div style={{margin:'10px 4px'}}>
      <button style={{background:'transparent',outline:'none',border:'none'}} onClick={() => handleBack("Taluk")}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    </div>
        <Table sx={{ minWidth: 650,}} style={{width:'80%'}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Taluk</TableCell>
              {regSelect === "205" ?
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Registration
                </TableCell>
                 : regSelect === "216" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Renewals
                </TableCell> : 
                regSelect === "217" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Duplicates
                </TableCell>
                : 
                regSelect === "300" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of E-Karmika
                </TableCell> :
                null }
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              
            </TableRow>
          </TableHead>
          
          <TableBody>
            {talukData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer" }}
                  onClick={() => cityHandler(row.taluk_id,row.district_id)}
                >
                  {row.taluk_name}
                </TableCell>
                <TableCell align="center">{row.total_field}</TableCell>
                <TableCell align="center">
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef}>
                    </CSVLink>
                      <Button
                        onClick={() => handleCSVTaluk(row.taluk_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCountTaluk}
              </TableCell>
              <CSVLink data={CSVOverallTaluk} headers={headersTaluk} filename='Taluk Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              
            </TableHead>
        </Table>
        </div>
      ) : null}
      {showCity ? (
        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
        <div style={{margin:'10px 4px'}}>
      <button style={{background:'transparent',outline:'none',border:'none'}} onClick={() => handleBack("Circle")}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    </div>
        <Table sx={{ minWidth: 650,}} style={{width:'80%'}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
              {regSelect === "205" ?
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Registration
                </TableCell>
                 : regSelect === "216" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Renewals
                </TableCell> : 
                regSelect === "217" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of Duplicates
                </TableCell> :
                regSelect === "300" ?
                 <TableCell style={{ fontWeight: "bold" }} align="center">
                  No of E-Karmika
                </TableCell> :
                null}
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Extract to CSV/Excel
                </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {cityData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer" }}
                //   onClick={() => cityHandler(row.id)}
                >
                  {row.circle_name}
                </TableCell>
                <TableCell align="center">{row.total_field}</TableCell>
                <TableCell align="center">
                    <CSVLink data={misReportData} filename={"MIS Reports"} ref={excelRef}>
                    </CSVLink>
                      <Button
                        onClick={() => handleCSVCity(row.circle_id)}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={<img src={Excel} width="20" height="20" />}
                      >
                        Download
                      </Button>
                    
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
              <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {totalCountCity}
              </TableCell>
              <CSVLink data={CSVOverallCircle} headers={headersCircle} filename='Circle Extract'>
                <div className='d-flex justify-content-center align-items-center my-3'>
                 <Button variant='contained' color="success"
                        startIcon={<img src={Excel} width="20" height="20" alt='' />}
                        >
                        Extract to CSV</Button>
                 </div>
                 </CSVLink>
              
            </TableHead>
        </Table>
        </div>
      ) : null}
      
    </TableContainer>
    </>
  );
}


// {
//         (
//           showDistrict ? 
//       <div style={{display:'flex',justifyContent:'center'}}>
//       <ButtonBase>
//         <Button
//           style={{ padding: "8px", margin: "6px 5px" }}
//           variant="contained"
//           size="small"
//           color="success"
//           startIcon={<img src={Excel} width="20" height="20" />}
//         >
//           Download to Excel
//         </Button>
//         {/* <Button
//           style={{ padding: "8px", margin: "6px 5px" }}
//           variant="contained"
//           size="small"
//           color="error"
//           startIcon={<PictureAsPdfIcon />}
//           onClick={handlePrintPDF}
//         >
//           Download to PDF
//         </Button> */}
//       </ButtonBase>
//       </div>
//       : null
//         )
        
//       }









