import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Button, Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const FuneralAssitance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  loading,
  kannadaSelected,
  handleFetchIFSC,
  IFSCCode,
  bankVerified,
  handleResetIFSC,
  aadharVerified,

  aadhaarLoading,
}) => {
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3">
            Please wait fetching fields....
          </p>
        </div>
      ) : (
        aadharVerified &&
        dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map((field, index) => (
          <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={index}>
            <Required
              className="mb-2 mt-4"
              title={
                kannadaSelected ? field.field_name_kannada : field.field_name
              }
            />
            {field.field_type === 'Text' &&
              (field.field_name === 'Applicant  Bank IFSC Code' ? (
                <>
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      type={'text'}
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={IFSCCode}
                      variant="outlined"
                      InputProps={
                        field.field_name === 'Applicant  Bank IFSC Code' && {
                          endAdornment: (
                            <InputAdornment position="end">
                              {bankVerified ? (
                                <>
                                  <IconButton>
                                    <VerifiedIcon color="success" />
                                  </IconButton>
                                  <IconButton onClick={handleResetIFSC}>
                                    <RestartAltIcon color="error" />
                                  </IconButton>
                                </>
                              ) : (
                                <Button
                                  variant="outline-primary"
                                  className="m-0"
                                  onClick={handleFetchIFSC}
                                >
                                  Fetch Bank Details
                                </Button>
                              )}
                            </InputAdornment>
                          ),
                        }
                      }
                    // helperText={allValues['error_' + field.id]}
                    // error={allValues.globalError}
                    // disabled={
                    //   field.field_name.includes(
                    //     'Name of the dependent ',
                    //   ) && allValues.medicalAssistanceSelf
                    // }
                    />
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      type={'text'}
                      name={field.field_name}
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      value={formData && formData[field.field_name]}
                      variant="outlined"
                      // helperText={allValues['error_' + field.id]}
                      // error={allValues.globalError}
                      disabled={
                        field.field_name === 'Name of the Applicant' ||
                          field.field_name === 'Relationship with Beneficiary' ||
                          field.field_name === `Age of Applicant` ||
                          field.field_name === 'Applicant Bank Name' ||
                          field.field_name === 'Applicant Bank Branch'
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                </>
              ))}
            {field.field_type === 'Date' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDate={moment('2023-10-17', 'YYYY-MM-DD').subtract(
                    12,
                    'months',
                  )}
                  maxDate={new Date()}
                  // onClick={(e) => onChangeDate(e, field)}
                  // onClose={() => setPickerStatus(false)}
                  // open={allValues.openDatePicker}
                  className="datepicker"
                  margin="normal"
                  format="DD/MM/YYYY"
                  name={field.field_name}
                  // id={id}
                  value={
                    formData && formData[field.field_name]
                      ? formData[field.field_name]
                      : null
                  }
                  onChange={(ev) => handleChange(ev, field, field.field_name)}
                  // onChange={setDate}
                  placeholder="DD/MM/YYYY"
                  // error={allValues.globalError}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}

            {(field.field_type === 'Dropdown' ||
              field.field_type === 'Radio Button') && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                >
                  <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    name={field.field_name}
                    onChange={(ev) => handleChange(ev, field, field.field_name)}
                    value={149}
                    disabled
                  >
                    <MenuItem value={149} key={index}>
                      <ListItemText primary={'Nominee'} />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
          </Col>
        ))
      )}
    </>
  );
};

export default FuneralAssitance;
