import MomentUtils from '@date-io/moment';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { SERVER } from 'store/actions/user.actions';
import { Required, getTokenCookie, showToast } from 'utils/tools';

import MarriageAssistance from './MarriageAssistance';
import PensionAssistance from './PensionAssistance';
import ContinuationPensionAssistance from './ContinuationPensionAssistance';

import DisabilityPensionAssistance from './DisabilityPensionAssistance';
import ContinuationDisabilityPensionAssitance from './ContinuationDisabilityPensionAssitance';
import AccidentAssitance from './AccidentAssitance';
import DeliveryAssistance from './DeliveryAssistance';
import FuneralAssitance from './FuneralAssitance';
import MedicalAssistance from './MedicalAssistance';
import MajorAilmentsAssistance from './MajorAilmentsAssistance';
import ThayiMaguAssistance from './ThayiMaguAssistance';
import EducationAssitance from './EducationAssitance';
import ShramaSamarthyaAssistance from './ShramaSamarthyaAssistance';

import FolderIcon from '@mui/icons-material/Folder';
import uploadFileAPI from 'utils/fileupload';
import { hasEmptyValues } from 'utils/checkEmptyArray';
import Select2 from 'react-select';
import mobile from 'assets/images/Phone 1-01.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@material-ui/icons';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';

import { useSelector } from 'react-redux';

import { getThayiMaguFiedls } from 'services/ThaiMagu.service';
import convertToNullOrNumber from 'utils/convertToNullorNumber';
import { DataContext } from 'context/config';
const DynamicFields = ({
  labourData,
  kannadaSelected,
  schemes,
  aadhaarVerified,
  aadhaarLoading,
  handleVerifyAadhaar,
  death,
}) => {
  const {
    labourUserID,
    schemeID,
    schemeName,
    roleID,
    renewal,
    thayimaguSchemeAvailID,
    renewalSequence,
  } = useParams();

  const users = useSelector((state) => state.users);
  const history = useHistory();
  const location = useLocation();

  const isActiveUser = users?.user?.ISACTIVEUSER;

  const [fetchDynamic, setFetchDynamic] = useState(null);
  const [getDocuments, setGetDocuments] = useState(null);

  // const [dynamicFields, setDynamicFields] = useState(null);
  const [formData, setFormData] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [personalDetails, setPersonalDetails] = useState(null);
  const [familyDetails, setFamilyDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [employerDetails, setEmployerDetails] = useState(null);
  const [isSelfSelected, setIsSelfSelected] = useState(true);
  const [personID, setPersonID] = useState(null);
  const documentUpload = useRef([]);

  const [fetchTypeOfIssuer, setFetchTypeOfIssuer] = useState(null);
  const [issuerTypeID, setIssuerTypeID] = useState(null);
  const [issueDate, setIssueDate] = useState(null);

  const [villageID, setVillageID] = useState(null);
  const [inspectorID, setInspectorID] = useState(null);
  const [inspectorName, setInspectorName] = useState(null);
  const [circleName, setCirlceName] = useState(null);
  const [circleID, setCircleID] = useState(null);
  const [labourInspectormobileNum, setLabourInspectorMobileNumber] =
    useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [union, setUnion] = useState(null);
  const [unionID, setUnionID] = useState(null);
  const [unionAddress, setUnionAddress] = useState(null);
  const [unionRegistrationNumber, setUnionRegistrationNumber] = useState(null);
  const [unionIssuerName, setUnionIssuerName] = useState(null);
  const [unionMobileNumber, setUnionMobileNumber] = useState(null);

  const [gramIssuerName, setGramIssuerName] = useState(null);
  const [gramIssuerPlace, setGramIssuerPlace] = useState(null);
  const [gramIssuerMobileNumber, setGramIssuerMobileNumber] = useState(null);

  const [constructionIssuerName, setConstructionIssuerName] = useState(null);
  const [constructionDesignation, setConstructionDesignation] = useState(null);
  const [constructionMobileNumber, setConstructionMobileNumber] =
    useState(null);

  // console.log(labourData);

  const [nintyDaysDoc, setNintyDaysDoc] = useState([]);
  const [nintyDaysDocID, setNintyDaysDocID] = useState(null);
  const nintyDaysDocRef = useRef(null);
  const [selfDeclarationDoc, setSelfDeclarationDoc] = useState([]);
  const selfDeclarationFileRef = useRef(null);
  const [loadingFields, setLoadingFields] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [aadhaarNumber, setAadhaarNumber] = useState(null);
  const [declaration, setDeclaration] = useState(false);
  const [declaration2, setDeclaration2] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [isCalledAPI, setIsCalledAPI] = useState(false);
  const [isKutumbaVerified, setIsKutumbaVerified] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState(null);
  const [sakalaNumber, setSakalaNumber] = useState(null);
  const [applicationDate, setApplicationDate] = useState(null);
  const [disableAadhaarBtn, setDisableAadhaarBtn] = useState(false);
  const [schemesAvailed, setSchemesAvailed] = useState(null);
  const [disableSelfMarriage, setDisableSelfMarriage] = useState(null);
  const [disableDeliveryChild, setDisableDeliveryChild] = useState(null);
  const [disableThayiMaguChild, setDisableThayiMaguChild] = useState(null);
  const [dateOfAdmission, setDateOfAdmission] = useState(null);
  const [dateOfDischarge, setDateOfDischarge] = useState(null);
  const [IFSC_CODE, setIFSC_CODE] = useState(null);
  const [bankVerified, setBankVerified] = useState(false);
  const [isSchemeSubmitted, setIsSchemeSubmitted] = useState(false);
  const [schemeAvailID, setSchemeAvailID] = useState(false);

  const [thayimaguFields, setThayimaguFields] = useState(null);

  const { data: configData } = useContext(DataContext);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffffee',
      color: '#000',
      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));

  const classes = useStyles();

  const callThayiMaguFields = async (labourUserID, schemeAvailID) => {
    try {
      const response = await getThayiMaguFiedls(+labourUserID, +schemeAvailID);
      if (response.success) {
        setThayimaguFields(response.data);
      }
    } catch (error) {
      if (error?.response?.data?.errors?.msg)
        showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
      else {
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      labour_user_id: labourUserID,
      scheme_id: 0,
      check_for: 'all',
    };
    axios
      .post(`${SERVER}/schemes/check_user_availed_schemes`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        setSchemesAvailed(data.data);
        // const selfData = data.data.self;
      })
      .catch((err) => {
        if (err) {
          showToast('ERROR', 'Something went wrong');
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, [labourUserID]);

  useEffect(() => {
    if (schemeName === 'Marriage Assistance') {
      const foundSchemes =
        schemesAvailed &&
        schemesAvailed.self.find(
          (data) => data.scheme_name === 'Marriage Assistance',
        );

      const availed = foundSchemes && foundSchemes.total_aviled_by_user;

      setDisableSelfMarriage(availed && availed);
    } else if (schemeName === 'Delivery Assistance') {
      const findDelivery =
        schemesAvailed &&
        schemesAvailed.self.find(
          (data) => data.scheme_name === 'Delivery Assistance',
        );

      const deliveryChildAvailed = {
        childStatus1: findDelivery && findDelivery.delivery_1_status,
        childStatus2: findDelivery && findDelivery.delivery_2_status,
      };

      setDisableDeliveryChild(deliveryChildAvailed);
    } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
      const findThayiMagu =
        schemesAvailed &&
        schemesAvailed.self.find(
          (data) => data.scheme_name === 'Thayi Magu Sahaya Hasta',
        );

      const thayiMaguChildAvailed = {
        childStatus1: findThayiMagu && findThayiMagu.child_1_status,
        childStatus2: findThayiMagu && findThayiMagu.child_2_status,
      };

      setDisableThayiMaguChild(thayiMaguChildAvailed);
    }
  }, [schemeName, schemesAvailed]);

  useEffect(() => {
    setLoadingFields(true);
    if (schemeID) {
      const payLoad = {
        board_id: 1,
        scheme_id: schemeID,
        role_id: null,
        tab_id: null,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/schemes/get_dynamic_fields_and_value`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payLoad,
      };
      axios(config)
        .then((res) => {
          setFetchDynamic(res.data.data);
          setLoadingFields(false);
        })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
            setLoadingFields(false);
          } else {
            showToast('ERROR', 'Something went wrong');
            setLoadingFields(false);
          }
        });
    }
  }, [schemeID]);

  useEffect(() => {
    if (schemeID) {
      setLoadingDocuments(true);
      axios
        .get(
          `${SERVER}/schemes/get_scheme_details?board_id=1&scheme_id=${schemeID}`,
        )
        .then(({ data }) => {
          setGetDocuments(data.data.document_details);
          setLoadingDocuments(false);
        })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
            setLoadingDocuments(false);
          } else {
            showToast('ERROR', 'Something went wrong');
            setLoadingDocuments(false);
          }
        });
    }
  }, [schemeID]);
  // console.log('disableSelfMarriage', disableSelfMarriage);
  useEffect(() => {
    if (labourData) {
      setPersonalDetails(labourData.personal_details);
      setVillageID(
        labourData.address_details &&
        labourData.address_details[0].village_area_id,
      );
      setFamilyDetails(labourData.family_details);
      setAddressDetails(labourData.address_details);
      setCertificateDetails(labourData.certificate_details);
      setEmployerDetails(labourData.employer_details);
    }
  }, [labourData]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Type of Issuer',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then(({ data }) => {
        setFetchTypeOfIssuer(data.data);
      })
      .catch((err) => {
        if (err) {
          showToast('ERROR', 'Something went wrong');
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  useEffect(() => {
    if (villageID) {
      axios
        .get(
          `${SERVER}/global/get_inspector_details?board_id=1&village_area_id=${villageID}`,
        )
        .then(({ data }) => {
          if (data?.data?.length > 0) {
            setInspectorName(
              data.data[0].first_name +
              ' ' +
              data.data[0].middle_name +
              ' ' +
              data.data[0].last_name,
            );
            setCirlceName(data.data[0].circle);
            setLabourInspectorMobileNumber(data.data[0].mobile_no);
            setInspectorID(data.data[0].department_user_id);
            setCircleID(data.data[0].circle_id);
          } else {
            showToast(
              'ERROR',
              'Problem in fetching the details of the inspector. Please contact your respective jurisdiction labour office.',
            );
          }
        })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
          } else {
            showToast('ERROR', 'Something went wrong');
          }
        });
    }
  }, [villageID]);

  useEffect(() => {
    if (issuerTypeID === 56) {
      axios
        .get(`${SERVER}/global/get_union_details?board_id=1`)
        .then((res) => {
          const newData = res.data.data.map((item) => ({
            ...item,
            value: item.labour_union_id,
            label: item.name,
          }));
          setUnion(newData);
        })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
          } else {
            showToast('ERROR', 'Something went wrong');
          }
        });
    }
  }, [issuerTypeID]);

  useEffect(() => {
    if (roleID) {
      const payLoad = {
        board_id: 1,
        role_hierarchy_master_id: roleID,
      };
      axios
        .post(`${SERVER}/schemes/get_role_hirarchy`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(({ data }) => { })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
          } else {
            showToast('ERROR', 'Something went wrong');
          }
        });
    }
  }, [roleID]);

  // useEffect(() => {
  //   if (roleID && villageID) {
  //     const payLoad = {
  //       board_id: 1,
  //       role_id_from_hirarchy: roleID,
  //       village_area_id: villageID,
  //     };
  //     axios
  //       .post(`${SERVER}/schemes/get_department_user_by_area`, payLoad, {
  //         headers: {
  //           Authorization: `Bearer ${getTokenCookie()}`,
  //         },
  //       })
  //       .then(({ data }) => {
  //         setCircleID(data.data[0].circle_id);
  //       })
  //       .catch((err) => {
  //         if (err) {
  //           showToast('ERROR', 'Something went wrong');
  //         } else {
  //           showToast('ERROR', 'Something went wrong');
  //         }
  //       });
  //   }
  // }, [villageID, roleID]);

  useEffect(() => {
    if (dateOfAdmission && dateOfDischarge) {
      setFormData((prev) => ({
        ...prev,
        [`Duration of treatment`]: `${moment(dateOfDischarge).diff(
          dateOfAdmission,
          'days',
        )} Days`,
      }));
    }
  }, [dateOfAdmission, dateOfDischarge]);

  useEffect(() => {
    if (aadhaarVerified) {
      if (schemeName === 'Funeral Expense and Death Assistance') {
        if (
          familyDetails &&
          familyDetails.filter((nominee) => nominee.is_nominee === true)
            .length > 0
        ) {
          const nomineeSelected =
            familyDetails &&
            familyDetails.filter((nominee) => nominee.is_nominee === true);

          const nominee = `${nomineeSelected[0]?.first_name?.trim() ?? ''} ${nomineeSelected[0]?.middle_name?.trim() ?? ''
            } ${nomineeSelected[0]?.last_name?.trim() ?? ''}`;
          const relationship = nomineeSelected[0]?.parent_child_relation;
          const age = nomineeSelected[0]?.date_of_birth;

          setFormData((prev) => ({
            ...prev,
            [`Name of the Applicant`]: nominee,
            [`Relationship with Beneficiary`]: relationship,
            [`Age of Applicant`]: moment().diff(age, 'years'),
            [`Applicant Type`]: 149,
          }));
        }
      } else if (schemeName === 'Accident Assistance') {
        if (
          familyDetails &&
          familyDetails.filter((nominee) => nominee.is_nominee === true)
            .length > 0
        ) {
          const nomineeSelected =
            familyDetails &&
            familyDetails.filter((nominee) => nominee.is_nominee === true);

          const nominee = `${nomineeSelected[0]?.first_name?.trim() ?? ''} ${nomineeSelected[0]?.middle_name?.trim() ?? ''
            } ${nomineeSelected[0]?.last_name?.trim() ?? ''}`;
          const relationship = nomineeSelected[0]?.parent_child_relation;
          const age = nomineeSelected[0]?.date_of_birth;

          setFormData((prev) => ({
            ...prev,
            [`Name of the Applicant`]: nominee,
            [`Relationship with Beneficiary`]: relationship,
            [`Age of Applicant`]: moment().diff(age, 'years'),
            [`Accident Resulted In`]: 27,
            [`Applicant Type`]: 149,
          }));
        }
      }
    } else {
      setFormData((prev) => ({
        ...prev,
      }));
    }
  }, [aadhaarVerified, familyDetails, schemeName]);

  useEffect(() => {
    if (labourUserID && thayimaguSchemeAvailID) {
      callThayiMaguFields(labourUserID, thayimaguSchemeAvailID);
    }
  }, [labourUserID, thayimaguSchemeAvailID]);

  const handleSelfDeclarationFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...selfDeclarationDoc, ...ev.target.files];
        setSelfDeclarationDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveSelfDeclarationFile = (index) => {
    const newFiles = [...selfDeclarationDoc];
    newFiles.splice(index, 1);
    setSelfDeclarationDoc(newFiles);
    selfDeclarationFileRef.current.value = '';
  };

  const handleFetchIFSC = () => {
    if (IFSC_CODE) {
      axios
        .get(`${SERVER}/global/get_ifsc_details?ifsc=${IFSC_CODE}`)
        .then(({ data }) => {
          const { data: _data } = data;
          setBankVerified(true);
          setFormData((prev) => ({
            ...prev,
            [`Applicant Bank Name`]: _data.BANK,
            [`Applicant Bank Branch`]: _data.BRANCH,
          }));
        })
        .catch((err) => {
          setBankVerified(false);

          showToast('ERROR', 'Something went wrong');
        });
    } else {
      showToast('ERROR', 'Please Enter IFSC Code');
    }
  };

  const handleChange = (ev, fields, fieldName) => {
    // const { name, value } = ev.target;
    // const dateValue = ev.target.value;

    if (
      fields.field_type === 'Dropdown' ||
      fields.field_type === 'Radio Button'
    ) {
      if (fieldName === 'Treatment type') {
        setFormData((prev) => ({
          ...prev,
          [`Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment`]:
            '',
          [`Hospital/Clinic name`]: '',
          [`Enter Treatment Name`]: '',
          [`Enter Hospital/Clinic Name`]: '',
        }));
      }
      if (fieldName === 'Person Hospitalized') {
        if (ev.target.value === 38) {
          setFormData((prev) => ({
            ...prev,
            [`Name of the dependent  (If Dependent is selected above)`]:
              personalDetails &&
              `${personalDetails[0]?.first_name?.trim()} ${personalDetails[0]?.middle_name?.trim() ?? ''
              } ${personalDetails[0]?.last_name?.trim() ?? ''}`,
            [fieldName]: ev.target.value,
          }));
          setIsSelfSelected(true);
        } else if (ev.target.value === 39) {
          setFormData((prev) => ({
            ...prev,
            [`Name of the dependent  (If Dependent is selected above)`]:
              ev.target.value,
            [fieldName]: ev.target.value,
          }));
          setIsSelfSelected(false);
        }
      } else if (fieldName === 'Person Hospitalized- Selection') {
        if (ev.target.value === 174) {
          setFormData((prev) => ({
            ...prev,
            [`Name of the applicant`]:
              personalDetails &&
              `${personalDetails[0]?.first_name?.trim()} ${personalDetails[0]?.middle_name?.trim() ?? ''
              } ${personalDetails[0]?.last_name?.trim() ?? ''}`,
            [fieldName]: ev.target.value,
          }));
          setIsSelfSelected(true);
        } else if (ev.target.value === 175) {
          setFormData((prev) => ({
            ...prev,
            [`Name of the applicant`]: ev.target.value,
            [fieldName]: ev.target.value,
          }));
          setIsSelfSelected(false);
        }
      } else if (fieldName === 'Marriage Assistance is for') {
        if (ev.target.value === 10) {
          if (personalDetails && personalDetails[0].gender === 'Male') {
            setFormData((prev) => ({
              ...prev,
              [`Groom Name`]:
                personalDetails &&
                `${personalDetails[0]?.first_name?.trim() ?? ''} ${personalDetails[0]?.middle_name?.trim() ?? ''
                } ${personalDetails[0]?.last_name?.trim() ?? ''}`,
              [`Age`]: moment().diff(
                personalDetails && personalDetails[0].date_of_birth,
                'years',
              ),
              [fieldName]: ev.target.value,
            }));
          } else if (
            personalDetails &&
            personalDetails[0].gender === 'Female'
          ) {
            setFormData((prev) => ({
              ...prev,
              [`Bride Name`]:
                personalDetails &&
                `${personalDetails[0]?.first_name?.trim() ?? ''} ${personalDetails[0]?.middle_name?.trim() ?? ''
                } ${personalDetails[0]?.last_name?.trim() ?? ''}`,
              [`Age`]: moment().diff(
                personalDetails && personalDetails[0].date_of_birth,
                'years',
              ),
              [fieldName]: ev.target.value,
            }));
          }

          setIsSelfSelected(true);
        } else if (ev.target.value === 8) {
          if (
            familyDetails &&
            familyDetails.filter(
              (member) => member.catalog_value_parent_child_type_id === 54,
            ).length > 0
          ) {
            let findSon =
              familyDetails &&
              familyDetails.filter(
                (member) => member.catalog_value_parent_child_type_id === 54,
              );
            let sonName = [
              findSon.first_name,
              findSon.middle_name,
              findSon.last_name,
            ].join(' ');

            setFormData((prev) => ({
              ...prev,
              [`Groom Name`]: sonName,
              [fieldName]: ev.target.value,
              [`Bride Name`]: '',
            }));
            setIsSelfSelected(false);
          } else {
            setFormData((prev) => ({
              ...prev,
              // [`Groom Name`]: ev.target.value,
              // [fieldName]: ev.target.value,
            }));
            showToast(
              'ERROR',
              `Selected Dependent (Son) is not added in the family details`,
            );
          }
          // setFormData((prev) => ({
          //   ...prev,
          //   [`Groom Name`]: ev.target.value,
          //   [fieldName]: ev.target.value,
          // }));
          // setIsSelfSelected(false);
        } else if (ev.target.value === 9) {
          if (
            familyDetails &&
            familyDetails.filter(
              (member) => member.catalog_value_parent_child_type_id === 53,
            ).length > 0
          ) {
            let findDaughter =
              familyDetails &&
              familyDetails.filter(
                (member) => member.catalog_value_parent_child_type_id === 53,
              );
            let daughterName = [
              findDaughter.first_name,
              findDaughter.middle_name,
              findDaughter.last_name,
            ].join(' ');

            setFormData((prev) => ({
              ...prev,
              [`Bride Name`]: daughterName,
              [fieldName]: ev.target.value,
              [`Groom Name`]: '',
            }));
            setIsSelfSelected(false);
          } else {
            setFormData((prev) => ({
              ...prev,
              // [`Groom Name`]: ev.target.value,
              // [fieldName]: ev.target.value,
            }));
            showToast(
              'ERROR',
              `Selected Dependent (Daughter) is not added in the family details`,
            );
          }
        }
      } else if (fieldName === 'Applicant Type') {
        if (ev.target.value === 179 || ev.target.value === 149) {
          if (
            familyDetails &&
            familyDetails.filter((nominee) => nominee.is_nominee === true)
              .length > 0
          ) {
            const nomineeSelected =
              familyDetails &&
              familyDetails.filter((nominee) => nominee.is_nominee === true);

            const nominee = `${nomineeSelected[0]?.first_name?.trim() ?? ''} ${nomineeSelected[0]?.middle_name?.trim() ?? ''
              } ${nomineeSelected[0]?.last_name?.trim() ?? ''}`;
            const relationship = nomineeSelected[0].parent_child_relation;

            setFormData((prev) => ({
              ...prev,
              [`Name of the Applicant`]: nominee,
              [`Relationship with Beneficiary`]: relationship,
              [fieldName]: ev.target.value,
            }));
          }
        } else if (ev.target.value === 180 || ev.target.value === 150) {
          setFormData((prev) => ({
            ...prev,
            [`Name of the Applicant`]: '',
            [`Relationship with Beneficiary`]: '',
            [fieldName]: ev.target.value,
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value,
        }));
      }
    } else if (fields.field_type === 'Text') {
      if (fieldName === 'Name of the Applicant') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === 'Cost of treatment') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, ''),
        }));
      } else if (fieldName === 'Child Aadhaar Number') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: +aadhaarNumber,
        }));
      } else if (fieldName === 'Description of Treatment') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (
        fieldName === 'Name of the treating Doctor/ Hospital authority'
      ) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === 'Place of Marriage') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Bride's Aadhaar number`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 12),
        }));
      } else if (fieldName === `Employer Name at the time of accident`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Phone Number`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 10),
        }));
      } else if (fieldName === `Applicant Phone Number`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 10),
        }));
      } else if (fieldName === `Age of Applicant`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 3),
        }));
      } else if (fieldName === `Age`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 3),
        }));
      } else if (fieldName === `Relationship with Beneficiary`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z]/gi, ''),
        }));
      } else if (fieldName === `Aadhaar Number`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 12),
        }));
      } else if (fieldName === `Applicant Bank Name`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Applicant Account Number`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, ''),
        }));
      } else if (fieldName === `Applicant Bank Branch`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Applicant  Bank IFSC Code`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Z0-9]/gi, '').slice(0, 11),
        }));
        setIFSC_CODE(ev.target.value.replace(/[^A-Z0-9]/gi, '').slice(0, 11));
      } else if (fieldName === `Current age of Child`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, ''),
        }));
      } else if (fieldName === `Duration of Treatment`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Hospital/Clinic name `) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Cause of death`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Duration of Treatment in days`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, ''),
        }));
      } else if (fieldName === `Percentage of Disability`) {
        let newValue = ev.target.value.replace(/[^0-9]/gi, ''); // Remove non-numeric characters
        // Ensure the value is not above 100
        if (newValue !== '' && parseInt(newValue) > 100) {
          // If the value is above 100, set it to 100
          newValue = '100';
        }
        setFormData((prev) => ({
          ...prev,
          [fieldName]: newValue,
        }));
      } else if (fieldName === `Place of death`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Age at the time of death`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^0-9]/gi, '').slice(0, 3),
        }));
      } else if (fieldName === `Name of the Doctor Treated`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Student Name`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (fieldName === `Bride Name`) {
        const name = ev.target.value;

        if (formData && formData[`Marriage Assistance is for`] === 9) {
          const findName = familyDetails.filter((data) =>
            name.includes(
              `${data?.first_name?.trim()} ${data?.middle_name?.trim() ?? ''} ${data?.last_name?.trim() ?? ''
              }`,
            ),
          );
          console.log('findName', findName);
          const findAge = findName[0]?.date_of_birth;
          setFormData((prev) => ({
            ...prev,
            [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
            [`Age`]: moment().diff(findAge, 'years'),
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
          }));
        }
      } else if (fieldName === `Groom Name`) {
        const name = ev.target.value;
        if (formData && formData[`Marriage Assistance is for`] === 8) {
          const findName = familyDetails.filter((data) =>
            name.includes(
              `${data?.first_name?.trim()} ${data?.middle_name?.trim() ?? ''} ${data?.last_name?.trim() ?? ''
              }`,
            ),
          );
          const findAge = findName[0]?.date_of_birth;
          setFormData((prev) => ({
            ...prev,
            [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
            [`Age`]: moment().diff(findAge, 'years'),
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
          }));
        }
      } else if (fieldName === `School/ College Name`) {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value.replace(/[^A-Za-z ]/gi, ''),
        }));
      } else if (
        fieldName ===
        'Name of the dependent  (If Dependent is selected above)' ||
        fieldName === 'Name of the applicant'
      ) {
        const name = ev.target.value;

        const findName = familyDetails.filter((data) =>
          name.includes(
            `${data?.first_name?.trim()} ${data?.middle_name?.trim() ?? ''} ${data?.last_name?.trim() ?? ''
            }`,
          ),
        );
        const findAge = findName[0].date_of_birth;
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value,
          [`Dependent Date of Birth`]: moment(findAge).format('DD/MM/YYYY'),
        }));
        setIsSelfSelected(false);
      } else {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: ev.target.value,
        }));
      }
    } else if (fields.field_type === 'Date') {
      if (fieldName === 'Date of Birth') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
          [`Age of the child`]: moment().diff(ev, 'years'),
        }));
      } else if (fieldName === 'Date of admission to Hospital /Clinic') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
        }));
        setDateOfAdmission(moment(ev).format('YYYY-MM-DD'));
      } else if (fieldName === 'Date of Discharge from Hospital/Clinic') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
        }));
        setDateOfDischarge(moment(ev).format('YYYY-MM-DD'));
      } else if (fieldName === 'Date of Child Birth') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
          [`Current age of Child`]:
            moment().diff(ev, 'days') >= 365
              ? `${moment().diff(ev, 'years')} Year`
              : moment().diff(ev, 'days') >= 31
                ? `${moment().diff(ev, 'months')} Month`
                : moment().diff(ev, 'days') <= 31
                  ? `${moment().diff(ev, 'days')} Days`
                  : '',
          [`Current age of child`]:
            moment().diff(ev, 'days') >= 365
              ? `${moment().diff(ev, 'years')} Year`
              : moment().diff(ev, 'days') >= 31
                ? `${moment().diff(ev, 'months')} Month`
                : moment().diff(ev, 'days') <= 31
                  ? `${moment().diff(ev, 'days')} Days`
                  : '',
        }));
      } else if (fieldName === 'Date of death') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
          [`Age at the time of death`]: moment(ev).diff(
            personalDetails[0].date_of_birth,
            'years',
          ),
        }));
      } else if (fieldName === 'Date of Accident') {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
          [`Age at the time of accident`]: moment().diff(
            personalDetails[0].date_of_birth,
            'years',
          ),
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: moment(ev).format('YYYY-MM-DD'),
        }));
      }
    }
  };

  const handleAadhaar = (ev) => {
    // console.log('Aadhaar Number', ev.target.value.replace(/[^0-9]/gi, ''));
    setAadhaarNumber(ev.target.value.replace(/[^0-9]/gi, ''));
    setFormData((prev) => ({
      ...prev,
      [`Child Aadhaar Number`]: ev.target.value,
    }));
  };

  const handleReset = () => {
    setAadhaarNumber('');
    setDisableAadhaarBtn(false);
    setIsKutumbaVerified(false);
    setIsCalledAPI(false);
  };

  const handleResetIFSC = () => {
    setIFSC_CODE('');
    setBankVerified(false);
    setFormData((prev) => ({
      ...prev,
      [`Applicant Bank Name`]: '',
      [`Applicant Bank Branch`]: '',
    }));
  };

  const handleVerifyClick = () => {
    if (aadhaarNumber) {
      if (+aadhaarNumber.length === 12) {
        setIsCalledAPI(true);
        // Call KUTUMBA API'
        const payLoad = {
          aadharNo: +aadhaarNumber,
        };
        axios
          .post(`${SERVER}/schemes/verifyAadhar`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setIsKutumbaVerified(true);
            setDisableAadhaarBtn(true);
          })
          .catch((err) => {
            if (err) {
              setIsKutumbaVerified(false);
              setDisableAadhaarBtn(true);
              showToast(
                'ERROR',
                `${err.response.data.errors.msg && err.response.data.errors.msg
                }`,
              );
            } else {
              setIsKutumbaVerified(false);
              setDisableAadhaarBtn(true);
              showToast('ERROR', 'Something went wrong');
            }
          });
      } else {
        showToast('ERROR', 'Aadhaar number should contain 12 digits');
      }
    } else {
      showToast('ERROR', 'Please enter Aadhaar Number to verify');
    }
  };

  const handleNintyDaysFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...nintyDaysDoc, ...ev.target.files];
        setNintyDaysDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleDateChange = (date, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: moment(date).format('YYYY-MM-DD'),
    }));
  };

  // const handleUpload = (ev, docID, schemeID, docName) => {
  //   const { name, files } = ev.target;
  //   // console.log(name, value);

  //   setDocuments((prev) => ({
  //     ...prev,
  //     [`${docID}_${schemeID}_${docName}`]: files,
  //   }));
  // };

  // Multilpe Upload

  // const handleUpload = (ev, docID, schemeID, docName) => {
  //   const newFiles = [
  //     ...((documents && documents[`${docID}_${schemeID}_${docName}`]) || []),
  //     ...ev.target.files,
  //   ];

  //   setDocuments((prev) => ({
  //     ...prev,
  //     [`${docID}_${schemeID}_${docName}`]: newFiles,
  //   }));
  // };

  //NEw upload to check file format

  const handleUpload = (ev, docID, schemeID, docName) => {
    const allowedExtensions = ['png', 'jpeg', 'jpg', 'pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    const newFiles = Array.from(ev.target.files);
    const invalidFiles = newFiles.filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size;
      return !allowedExtensions.includes(extension) || fileSize > maxSize;
    });

    if (invalidFiles.length > 0) {
      // Show error message for invalid file formats or sizes
      showToast(
        'ERROR',
        'Please upload file in the PNG/JPEG/JPG/PDF format less than 2MB',
      );
      return;
    }

    const updatedFiles = [
      ...((documents && documents[`${docID}_${schemeID}_${docName}`]) || []),
      ...newFiles,
    ];

    setDocuments((prev) => ({
      ...prev,
      [`${docID}_${schemeID}_${docName}`]: updatedFiles,
    }));
  };

  // Define the handleRemoveDocuments function
  const handleRemoveDocuments = (docIndex, id, schemeId, documentName) => {
    // Make a copy of the current documents state
    const updatedDocuments = { ...documents };

    // Get the key for the document to be removed
    const key = `${id}_${schemeId}_${documentName}`;

    // Check if the document exists in the state
    if (updatedDocuments[key]) {
      // Remove the document at the specified index
      updatedDocuments[key].splice(docIndex, 1);

      documentUpload.current[documentName].value = '';

      // If there are no documents left for this key, remove the key from the state
      if (updatedDocuments[key].length === 0) {
        delete updatedDocuments[key];
      }

      // Update the documents state
      setDocuments(updatedDocuments);
    }
  };

  const handleRemoveNintyDaysFile = (index) => {
    const newFiles = [...nintyDaysDoc];
    newFiles.splice(index, 1);
    setNintyDaysDoc(newFiles);
    nintyDaysDocRef.current.value = '';
  };

  // console.log('DOCUMENTUPLOAD', documents && documents);

  const handleSubmit = () => {
    const d1 = formData && formData['Person Hospitalized'];
    const d2 = formData && formData['Marriage Assistance is for'];

    const res =
      d1 === 39
        ? familyDetails
          .filter(
            (fam) =>
              formData &&
              formData[
                `Name of the dependent  (If Dependent is selected above)`
              ].includes(
                `${fam?.first_name?.trim()} ${fam?.middle_name?.trim() ?? ''
                } ${fam?.last_name?.trim() ?? ''}`,
              ),
          )
          .map((data) => data.parent_child_user_id)
        : 0;

    const res2 =
      d2 === 8
        ? familyDetails
          .filter(
            (fam) =>
              formData &&
              formData[`Groom Name`].includes(
                `${fam?.first_name?.trim()} ${fam?.middle_name?.trim() ?? ''
                } ${fam?.last_name?.trim() ?? ''}`,
              ),
          )
          .map((data) => data.parent_child_user_id)
        : d2 === 9
          ? familyDetails
            .filter(
              (fam) =>
                formData &&
                formData[`Bride Name`].includes(
                  `${fam?.first_name?.trim()} ${fam?.middle_name?.trim() ?? ''
                  } ${fam?.last_name?.trim() ?? ''}`,
                ),
            )
            .map((data) => data.parent_child_user_id)
          : 0;

    const personID = d1 ? res && res[0] : d2 ? res2 && res2[0] : +labourUserID;

    const fields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic.map((field) => {
        if (
          formData['Hospital/Clinic name'] ==
          (configData?.otherHospitalIdForMajorMedicalScheme || 0) &&
          field.id == 95
        ) {
          return {
            field_id: field.id,
            field_value: formData['Enter Hospital/Clinic Name'],
            is_text_or_value: 0,
          };
        }
        if (
          formData[
          'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
          ] == (configData?.otherTreatmentIdForMajorMedicalScheme || 0) &&
          field.id == 108
        ) {
          return {
            field_id: field.id,
            field_value: formData['Enter Treatment Name'],
            is_text_or_value: 0,
          };
        }
        return {
          field_id: field.id,
          field_value: formData && formData[field.field_name],
          is_text_or_value: field.is_text_or_value ? 1 : 0,
        };
      });

    const accidentFields = [
      {
        field_id: 41,
        field_value: formData && formData[`Accident Resulted In`],
        is_text_or_value: 1,
      },
      {
        field_id: 43,
        field_value:
          formData && formData[`Employer Name at the time of accident`],
        is_text_or_value: 0,
      },
      {
        field_id: 44,
        field_value:
          formData && formData[`Employer address at the time of accident`],
        is_text_or_value: 0,
      },
      {
        field_id: 256,
        field_value: formData && formData[`Percentage of Disability`],
        is_text_or_value: 0,
      },
      {
        field_id: 253,
        field_value: formData && formData[`Date of Accident`],
        is_text_or_value: 0,
      },
      {
        field_id: 258,
        // field_id: 260,
        field_value: formData && formData[`Age at the time of accident`],
        is_text_or_value: 0,
      },
    ];

    const medicalFields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic
        .filter(
          (field) => field.field_name !== 'Dependent Date of Birth' && field,
        )
        .map((field) => {
          if (
            formData['Hospital/Clinic name'] ==
            (configData?.otherHospitalIdForMajorMedicalScheme || 0) &&
            field.id == 95
          ) {
            return {
              field_id: field.id,
              field_value: formData['Enter Hospital/Clinic Name'],
              is_text_or_value: 0,
            };
          }
          if (
            formData[
            'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment'
            ] == (configData?.otherTreatmentIdForMajorMedicalScheme || 0) &&
            field.id == 108
          ) {
            return {
              field_id: field.id,
              field_value: formData['Enter Treatment Name'],
              is_text_or_value: 0,
            };
          }
          return {
            field_id: field.id,
            field_value: formData && formData[field.field_name],
            is_text_or_value: field.is_text_or_value ? 1 : 0,
          };
        });

    const accidentDeathFields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic
        .filter(
          (field) => field.field_name !== 'Percentage of Disability' && field,
        ) // Filter out false values
        .map((field) => ({
          field_id: field.id,
          field_value: formData && formData[field.field_name],
          is_text_or_value: field.is_text_or_value ? 1 : 0,
        }));

    const docs =
      getDocuments &&
      getDocuments.length &&
      getDocuments.map((doc) => ({
        document:
          documents &&
          documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
      }));

    const MarriageAllDocs =
      getDocuments &&
      getDocuments.length &&
      getDocuments.map((doc) => ({
        document:
          documents &&
          documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
      }));

    const MarriageCertificateDocs = {
      document: documents && documents[`${1}_${1}_${`Marriage Certificate`}`],
    };

    const PostmortemDoc =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'Postmortem Report'
            ? null
            : {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            },
        )
        .filter(Boolean);

    const deathDocs =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'FIR COPY' ||
            doc.document_name === 'Nominee Adhar card' ||
            doc.document_name === 'Postmortem Report' ||
            doc.document_name === 'Death Certificate'
            ? {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            }
            : null,
        )
        .filter(Boolean);

    const nonDeathDocs =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'Medical Certificate' ||
            doc.document_name === 'FIR COPY' ||
            doc.document_name === 'Medical Disability Certificate'
            ? {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            }
            : null,
        )
        .filter(Boolean);

    //Thayi Magu Scheme Docs and fields

    const _thayiMaguFields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic
        .filter(
          (field) =>
            field.field_name !== 'Name of the Hospital where  child was born' &&
            field.field_name !== 'Hospital type' &&
            field.field_name !== 'Name of the hospital' &&
            field,
        ) // Filter out false values
        .map((field) => ({
          field_id: field.id,
          field_value: formData && formData[field.field_name],
          is_text_or_value: field.is_text_or_value ? 1 : 0,
        }));

    const privateHospitalFields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic
        .filter((field) => field.field_name !== 'Name of the hospital' && field) // Filter out false values
        .map((field) => ({
          field_id: field.id,
          field_value: formData && formData[field.field_name],
          is_text_or_value: field.is_text_or_value ? 1 : 0,
        }));

    const governmentHospitalFields =
      fetchDynamic &&
      fetchDynamic.length &&
      fetchDynamic
        .filter(
          (field) =>
            field.field_name !== 'Name of the Hospital where  child was born' &&
            field,
        ) // Filter out false values
        .map((field) => ({
          field_id: field.id,
          field_value: formData && formData[field.field_name],
          is_text_or_value: field.is_text_or_value ? 1 : 0,
        }));

    const thayiMaguRenewalFields =
      thayimaguFields &&
      thayimaguFields[0].length > 0 &&
      thayimaguFields[0].map((_field) => ({
        field_id: _field.field_id,
        field_value: _field.field_value_id,
        is_text_or_value: _field.is_text_value ? 1 : 0,
      }));

    const thayiMaguDocsHome =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'Photo of Child' ||
            doc.document_name === 'Birth Certificate' ||
            doc.document_name === 'Affidavit'
            ? {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            }
            : null,
        )
        .filter(Boolean);

    const thayiMaguDocsHospital =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'Photo of Child' ||
            doc.document_name === 'Birth Certificate' ||
            doc.document_name === 'Discharge hospital Summary'
            ? {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            }
            : null,
        )
        .filter(Boolean);

    const thayiMaguRenewalDocs =
      getDocuments &&
      getDocuments.length &&
      getDocuments
        .map((doc) =>
          doc.document_name === 'Photo of Child'
            ? {
              document:
                documents &&
                documents[`${doc.id}_${doc.scheme_id}_${doc.document_name}`],
            }
            : null,
        )
        .filter(Boolean);

    // console.log('deathDocs', deathDocs);

    const admissionDate =
      formData && formData[`Date of admission to Hospital /Clinic`];

    const dischargeDate =
      formData && formData[`Date of Discharge from Hospital/Clinic`];

    const differenceDate = moment(dischargeDate).diff(admissionDate, 'days');

    if (schemeName === 'Marriage Assistance') {
      if (formData && formData['Married Outside Karnataka'] === 178) {
        if (!hasEmptyValues(fields, 'field_value')) {
          if (!hasEmptyValues(MarriageCertificateDocs, 'document')) {
            if (issuerTypeID && issueDate) {
              if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                if (declaration && declaration2) {
                  if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                    setSubmitLoading(true);
                    uploadFiles(fields, personID);
                  } else {
                    showToast('ERROR', 'Please upload self Declaration');
                  }
                } else {
                  showToast('ERROR', 'Please accept the declaration');
                }
              } else {
                showToast('ERROR', 'Please upload employment certificate');
              }
            } else {
              showToast(
                'ERROR',
                `Please fill mandatory 90 Days Work Certificate Details`,
              );
            }
          } else {
            showToast('ERROR', 'Please upload mandatory scheme documents');
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      } else {
        if (!hasEmptyValues(fields, 'field_value')) {
          if (!hasEmptyValues(MarriageAllDocs, 'document')) {
            if (issuerTypeID && issueDate) {
              if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                if (declaration && declaration2) {
                  if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                    setSubmitLoading(true);
                    uploadFiles(fields, personID);
                  } else {
                    showToast('ERROR', 'Please upload self Declaration');
                  }
                } else {
                  showToast('ERROR', 'Please accept the declaration');
                }
              } else {
                showToast('ERROR', 'Please upload employment certificate');
              }
            } else {
              showToast(
                'ERROR',
                'Please fill mandatory 90 Days Work Certificate Details',
              );
            }
          } else {
            showToast('ERROR', `Please upload mandatory scheme's documents`);
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      }
    } else if (schemeName === 'Medical Assistance') {
      if (formData && formData['Person Hospitalized'] === 38) {
        if (!hasEmptyValues(medicalFields, 'field_value')) {
          if (differenceDate > 1) {
            if (!hasEmptyValues(docs, 'document')) {
              if (issuerTypeID && issueDate) {
                if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                  if (declaration && declaration2) {
                    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                      setSubmitLoading(true);
                      uploadFiles(medicalFields, personID);
                    } else {
                      showToast('ERROR', 'Please upload self Declaration');
                    }
                  } else {
                    showToast('ERROR', 'Please accept the declaration');
                  }
                } else {
                  showToast('ERROR', 'Please upload employment certificate');
                }
              } else {
                showToast(
                  'ERROR',
                  `Please fill mandatory 90 Days Work Certificate Details`,
                );
              }
            } else {
              showToast('ERROR', `Please upload mandatory scheme's documents`);
            }
          } else {
            showToast(
              'ERROR',
              'Minimum required date is 48 hours after admission.',
            );
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      } else {
        if (!hasEmptyValues(fields, 'field_value')) {
          if (differenceDate > 1) {
            if (!hasEmptyValues(docs, 'document')) {
              if (issuerTypeID && issueDate) {
                if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                  if (declaration && declaration2) {
                    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                      setSubmitLoading(true);
                      uploadFiles(fields, personID);
                    } else {
                      showToast('ERROR', 'Please upload self Declaration');
                    }
                  } else {
                    showToast('ERROR', 'Please accept the declaration');
                  }
                } else {
                  showToast('ERROR', 'Please upload employment certificate');
                }
              } else {
                showToast(
                  'ERROR',
                  `Please fill mandatory 90 Days Work Certificate Details`,
                );
              }
            } else {
              showToast('ERROR', `Please upload mandatory scheme's documents`);
            }
          } else {
            showToast(
              'ERROR',
              'Minimum required date is 48 hours after admission.',
            );
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      }
    } else if (schemeName === 'Assistance For Major Ailments') {
      if (formData && formData['Person Hospitalized- Selection'] === 174) {
        if (!hasEmptyValues(medicalFields, 'field_value')) {
          if (!hasEmptyValues(docs, 'document')) {
            if (issuerTypeID && issueDate) {
              if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                if (declaration && declaration2) {
                  if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                    setSubmitLoading(true);
                    uploadFiles(medicalFields, personID);
                  } else {
                    showToast('ERROR', 'Please upload self Declaration');
                  }
                } else {
                  showToast('ERROR', 'Please accept the declaration');
                }
              } else {
                showToast('ERROR', 'Please upload employment certificate');
              }
            } else {
              showToast(
                'ERROR',
                `Please fill mandatory 90 Days Work Certificate Details`,
              );
            }
          } else {
            showToast('ERROR', `Please upload mandatory scheme's documents`);
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      } else {
        if (!hasEmptyValues(fields, 'field_value')) {
          if (!hasEmptyValues(docs, 'document')) {
            if (issuerTypeID && issueDate) {
              if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                if (declaration && declaration2) {
                  if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                    setSubmitLoading(true);
                    uploadFiles(fields, personID);
                  } else {
                    showToast('ERROR', 'Please upload self Declaration');
                  }
                } else {
                  showToast('ERROR', 'Please accept the declaration');
                }
              } else {
                showToast('ERROR', 'Please upload employment certificate');
              }
            } else {
              showToast(
                'ERROR',
                `Please fill mandatory 90 Days Work Certificate Details`,
              );
            }
          } else {
            showToast('ERROR', `Please upload mandatory scheme's documents`);
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      }
    } else if (schemeName === 'Accident Assistance') {
      if (formData && formData['Accident Resulted In'] === 27) {
        if (!hasEmptyValues(accidentDeathFields, 'field_value')) {
          if (!hasEmptyValues(deathDocs, 'document')) {
            if (declaration && declaration2) {
              setSubmitLoading(true);
              uploadFiles(accidentDeathFields, personID);
            } else {
              showToast('ERROR', 'Please accept the declaration');
            }
          } else {
            showToast('ERROR', `Please upload mandatory scheme's documents`);
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      } else {
        if (!hasEmptyValues(accidentFields, 'field_value')) {
          if (!hasEmptyValues(nonDeathDocs, 'document')) {
            if (issuerTypeID && issueDate) {
              if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                if (declaration && declaration2) {
                  if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                    setSubmitLoading(true);
                    uploadFiles(accidentFields, personID);
                  } else {
                    showToast('ERROR', 'Please upload self Declaration');
                  }
                } else {
                  showToast('ERROR', 'Please accept the declaration');
                }
              } else {
                showToast('ERROR', 'Please upload employment certificate');
              }
            } else {
              showToast(
                'ERROR',
                `Please fill mandatory 90 Days Work Certificate Details`,
              );
            }
          } else {
            showToast('ERROR', `Please upload mandatory scheme's documents`);
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory');
        }
      }
    } else if (schemeName === 'Funeral Expense and Death Assistance') {
      if (!hasEmptyValues(fields, 'field_value')) {
        if (!hasEmptyValues(docs, 'document')) {
          if (declaration && declaration2) {
            setSubmitLoading(true);
            uploadFiles(fields, personID);
          } else {
            showToast('ERROR', 'Please accept the declaration');
          }
        } else {
          showToast('ERROR', `Please upload mandatory scheme's documents`);
        }
      } else {
        showToast('ERROR', 'Please fill all mandatory');
      }
    } else if (schemeName === 'Pension Scheme') {
      if (issuerTypeID && issueDate) {
        if (nintyDaysDoc && nintyDaysDoc.length > 0) {
          if (declaration && declaration2) {
            setSubmitLoading(true);
            uploadFiles([], personID);
          } else {
            showToast('ERROR', 'Please accept the declaration');
          }
        } else {
          showToast('ERROR', 'Please upload employment certificate ');
        }
      } else {
        showToast(
          'ERROR',
          `Please fill mandatory 90 Days Work Certificate Details`,
        );
      }
    } else if (schemeName === 'Continuation of Pension') {
      if (!hasEmptyValues(docs, 'document')) {
        if (declaration && declaration2) {
          setSubmitLoading(true);
          uploadFiles([], personID);
        } else {
          showToast('ERROR', 'Please accept the declaration');
        }
      } else {
        showToast('ERROR', `Please upload mandatory scheme's documents`);
      }
    } else if (schemeName === 'Continuation of Disability Pension') {
      if (!hasEmptyValues(docs, 'document')) {
        if (declaration && declaration2) {
          setSubmitLoading(true);
          uploadFiles([], personID);
        } else {
          showToast('ERROR', 'Please accept the declaration');
        }
      } else {
        showToast('ERROR', `Please upload mandatory scheme's documents`);
      }
    } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
      if (renewal === 'true') {
        if (!hasEmptyValues(thayiMaguRenewalDocs, 'document')) {
          if (issuerTypeID && issueDate) {
            if (nintyDaysDoc && nintyDaysDoc.length > 0) {
              if (declaration && declaration2) {
                if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                  setSubmitLoading(true);
                  uploadFiles(thayiMaguRenewalFields, personID);
                } else {
                  showToast('ERROR', 'Please upload self declaration');
                }
              } else {
                showToast('ERROR', 'Please accept the declaration');
              }
            } else {
              showToast('ERROR', 'Please upload employment certificate');
            }
          } else {
            showToast(
              'ERROR',
              `Please fill mandatory 90 Days Work Certificate Details`,
            );
          }
        } else {
          showToast('ERROR', `Please upload mandatory scheme's documents`);
        }
      } else {
        if (formData && formData['Place of Birth'] === 1243) {
          if (!hasEmptyValues(_thayiMaguFields, 'field_value')) {
            if (!hasEmptyValues(thayiMaguDocsHome, 'document')) {
              if (issuerTypeID && issueDate) {
                if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                  if (declaration && declaration2) {
                    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                      setSubmitLoading(true);
                      uploadFiles(_thayiMaguFields, personID);
                    } else {
                      showToast('ERROR', 'Please upload self Declaration');
                    }
                  } else {
                    showToast('ERROR', 'Please accept the declaration');
                  }
                } else {
                  showToast('ERROR', 'Please upload employment certificate');
                }
              } else {
                showToast(
                  'ERROR',
                  `Please fill mandatory 90 Days Work Certificate Details`,
                );
              }
            } else {
              showToast('ERROR', `Please upload mandatory scheme's documents`);
            }
          } else {
            showToast('ERROR', 'Please fill all mandatory fields');
          }
        } else if (formData && formData['Hospital type'] === 1749) {
          if (!hasEmptyValues(privateHospitalFields, 'field_value')) {
            if (!hasEmptyValues(thayiMaguDocsHospital, 'document')) {
              if (issuerTypeID && issueDate) {
                if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                  if (declaration && declaration2) {
                    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                      setSubmitLoading(true);
                      uploadFiles(privateHospitalFields, personID);
                    } else {
                      showToast('ERROR', 'Please upload self Declaration');
                    }
                  } else {
                    showToast('ERROR', 'Please accept the declaration');
                  }
                } else {
                  showToast('ERROR', 'Please upload employment certificate');
                }
              } else {
                showToast(
                  'ERROR',
                  `Please fill mandatory 90 Days Work Certificate Details`,
                );
              }
            } else {
              showToast('ERROR', `Please upload mandatory scheme's documents`);
            }
          } else {
            showToast('ERROR', 'Please fill all mandatory fields');
          }
        } else if (formData && formData['Hospital type'] === 1750) {
          if (!hasEmptyValues(governmentHospitalFields, 'field_value')) {
            if (!hasEmptyValues(thayiMaguDocsHospital, 'document')) {
              if (issuerTypeID && issueDate) {
                if (nintyDaysDoc && nintyDaysDoc.length > 0) {
                  if (declaration && declaration2) {
                    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                      setSubmitLoading(true);
                      uploadFiles(governmentHospitalFields, personID);
                    } else {
                      showToast('ERROR', 'Please upload self Declaration');
                    }
                  } else {
                    showToast('ERROR', 'Please accept the declaration');
                  }
                } else {
                  showToast('ERROR', 'Please upload employment certificate');
                }
              } else {
                showToast(
                  'ERROR',
                  `Please fill mandatory 90 Days Work Certificate Details`,
                );
              }
            } else {
              showToast('ERROR', `Please upload mandatory scheme's documents`);
            }
          } else {
            showToast('ERROR', 'Please fill all mandatory fields');
          }
        } else {
          showToast('ERROR', 'Please fill all mandatory fields');
        }
      }
    } else {
      if (!hasEmptyValues(fields, 'field_value')) {
        if (!hasEmptyValues(docs, 'document')) {
          if (issuerTypeID && issueDate) {
            if (nintyDaysDoc && nintyDaysDoc.length > 0) {
              if (declaration && declaration2) {
                if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
                  setSubmitLoading(true);
                  uploadFiles(fields, personID);
                } else {
                  showToast('ERROR', 'Please upload self Declaration');
                }
              } else {
                showToast('ERROR', 'Please accept the declaration');
              }
            } else {
              showToast('ERROR', 'Please upload employment certificate');
            }
          } else {
            showToast(
              'ERROR',
              `Please fill mandatory 90 Days Work Certificate Details`,
            );
          }
        } else {
          showToast('ERROR', `Please upload mandatory scheme's documents`);
        }
      } else {
        showToast('ERROR', 'Please fill all mandatory');
      }
    }
  };

  const handleDropdownSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUnionID(selectedOption.labour_union_id);
    setUnionAddress(selectedOption.address);
    setUnionRegistrationNumber(selectedOption.registeration_no);
    setUnionIssuerName(selectedOption.spoke_person);
    setUnionMobileNumber(selectedOption.phone_no);
  };

  const uploadFiles = async (fields, personID) => {
    const promises = [];
    const files =
      getDocuments &&
      getDocuments.length &&
      getDocuments.map((item, index) => {
        const fileID = `${item.id}_${item.scheme_id}_${item.document_name}`;

        if (documents && documents[fileID] && documents[fileID]) {
          // console.log(documents[`${item.id}_${item.scheme_id}`]);
          Array.from(documents[fileID]).map((_doc) => {
            promises.push(
              uploadFileAPI(_doc, null, null, null, {
                fileID: `${fileID}_${index}`,
                ...item,
              }),
            );
            return undefined;
          });
        }
        return undefined;
      });

    const fileObj = {};

    Promise.all(promises)
      .then((promiseResponse) => {
        promiseResponse.map((doc) => {
          const fileID = doc.options.fileID;
          if (!fileObj[fileID]) {
            fileObj[fileID] = {
              ...doc.options,
              files: [
                {
                  file_id: doc.image.fileId,
                  file_name: doc.image.originalFileName,
                },
              ],
            };
          } else {
            // console.log('fileObj', fileObj);
            fileObj[fileID].files.push({
              file_id: doc.image.fileId,
              file_name: doc.image.originalFileName,
            });
          }
        });
        // console.log('MAPPEDDATA', typeof mappedData);
        // insertSchemes(fields, fileObj);
        uploadNintyDaysAndSelfDeclaration(fields, personID, fileObj);
      })
      .catch((error) => {
        // console.log('ERROR', err);
        setSubmitLoading(false);
        if (error?.response?.data?.errors?.msg) {
          showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
        } else {
          showToast(
            'ERROR',
            'Something went wrong while uploading the file.Please try again!',
          );
        }
      });
  };

  const uploadNintyDaysAndSelfDeclaration = (fields, personID, documents) => {
    if (
      nintyDaysDoc &&
      nintyDaysDoc.length > 0 &&
      selfDeclarationDoc &&
      selfDeclarationDoc.length > 0
    ) {
      const promises1 = [];
      const promises2 = [];
      const nintyDaysAPI = nintyDaysDoc.forEach((doc) => {
        promises1.push(uploadFileAPI(doc));
      });
      const selfDeclarationAPI = selfDeclarationDoc.forEach((doc) => {
        promises2.push(uploadFileAPI(doc));
      });
      const allPromises = [...promises1, ...promises2];

      Promise.all(allPromises).then((responses) => {
        const responses1 = responses.slice(0, promises1.length);
        const responses2 = responses.slice(promises1.length);
        const nintyDaysResponse = responses1.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
        }));
        const selfDeclarationResponse = responses2.map((doc) => ({
          file_id: doc.image.fileId,
          file_name: doc.image.originalFileName,
        }));
        insertSchemes(
          fields,
          personID,
          documents,
          nintyDaysResponse,
          selfDeclarationResponse,
        );
      });
    } else if (nintyDaysDoc && nintyDaysDoc.length > 0) {
      const promises = [];

      const nintyDaysDocAPI = nintyDaysDoc.forEach((doc) => {
        promises.push(uploadFileAPI(doc));
      });

      Promise.all(promises)
        .then((promiseResponse) => {
          const nintyDaysResponse = promiseResponse.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));
          insertSchemes(fields, personID, documents, nintyDaysResponse, []);
        })
        .catch((error) => {
          setSubmitLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast(
              'ERROR',
              'Something went wrong while uploading the file.Please try again!',
            );
          }
        });
    } else if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
      const promises = [];

      const selfDeclarationAPI = selfDeclarationDoc.forEach((doc) => {
        promises.push(uploadFileAPI(doc));
      });

      Promise.all(promises)
        .then((promiseResponse) => {
          const selfDeclarationResponse = promiseResponse.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));
          insertSchemes(
            fields,
            personID,
            documents,
            [],
            selfDeclarationResponse,
          );
        })
        .catch((error) => {
          setSubmitLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast(
              'ERROR',
              'Something went wrong while uploading the file.Please try again!',
            );
          }
        });
    } else {
      insertSchemes(fields, personID, documents, [], []);
    }
  };

  const insertSchemes = (
    fields,
    personID,
    schemeDocs,
    employmentDoc,
    selfDeclarationDoc,
  ) => {
    const documents = Object.values(schemeDocs).map((doc) => ({
      document_upload_id: '-',
      description: doc.document_name,
      document_type: doc.document_name,
      scheme_document_id: doc.id,
      files: doc.files,
    }));

    const payLoad = {
      boardId: 1,
      schemeId: +schemeID,
      userId: +labourUserID,
      isSelf: isSelfSelected ? 1 : 0,
      // isSelf: isSelfSelected ? 1 : +schemeID === 30 ? 1 : 1,
      appliedForPersonId: isSelfSelected
        ? +labourUserID
        : personID === 0
          ? +labourUserID
          : personID,
      appliedDate: moment().format('YYYY-MM-DD'),
      isRenewal: renewal === 'true' ? 1 : 0,
      renewalSequenceYear: convertToNullOrNumber(renewalSequence),
      departmentUserId: labourData?.certificate_details[0]?.labour_incpector_id,
      roleId:
        schemeName === 'Continuation of Disability Pension' ||
          schemeName === 'Continuation of Pension'
          ? 3
          : 1,
      circleId: circleID,
      typeOfIssuer: issuerTypeID,
      childAadharNo: aadhaarNumber ? +aadhaarNumber : null,
      isApiVerified: isKutumbaVerified ? 1 : 0,
      issueDate: issueDate ? moment(issueDate).format('YYYY-MM-DD') : null,
      labourIncpectorId: inspectorID,
      labourUnionId: issuerTypeID === 56 ? unionID : null,
      issuerName:
        issuerTypeID === 56
          ? unionIssuerName === ''
            ? null
            : unionIssuerName
          : issuerTypeID === 57
            ? gramIssuerName
            : issuerTypeID === 58
              ? constructionIssuerName
              : null,
      issuerPlace:
        issuerTypeID === 56
          ? unionAddress
          : issuerTypeID === 57
            ? gramIssuerPlace
            : null,
      issuerDesignation: constructionDesignation
        ? constructionDesignation
        : null,
      fields: fields,
      employementCertificate: employmentDoc,
      documents: documents,
      selfDeclaration: selfDeclarationDoc,
    };

    // console.log('SchemesPayload', payLoad);

    axios
      .put(`${SERVER}/schemes/apply`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        setSubmitLoading(false);
        setSubmitSuccess(true);

        setOpenBackdrop(true);
        setIsSchemeSubmitted(true);
        showToast('SUCCESS', 'Scheme Applied Successfully');
        setSchemeAvailID(
          data.data.scheme_avail_details[0].Scheme_Avail_id_acknowledgement_id,
        );
        setApplicationNumber(
          data.data.scheme_aplication_details[0].scheme_application_code,
        );
        setSakalaNumber(data.data.gsc_data[0].gsc);
        setApplicationDate(data.data.scheme_aplication_details[0].applied_date);
      })
      .catch((err) => {
        if (err?.response?.data?.errors?.msg) {
          setSubmitLoading(false);
          setSubmitSuccess(false);
          setOpenBackdrop(false);
          setIsSchemeSubmitted(false);
          showToast('ERROR', `${err?.response?.data?.errors?.msg}`);
        } else {
          setSubmitLoading(false);
          setSubmitSuccess(false);
          setOpenBackdrop(false);
          setIsSchemeSubmitted(false);
          showToast('ERROR', 'Something went wrong');
        }
      });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    history.push('/kbocwwb-schemes-home');
  };

  return (
    <>
      {submitLoading ? <SimpleBackdrop /> : null}
      {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={handleCloseBackdrop}
        >
          <div style={{ display: 'block' }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: 'auto', width: '200px' }}
            />
            <p className="final-success-title">
              Successfully submitted for
              <br />
              Scheme!
            </p>
            <Link
              to={`/view/schemeack/${schemeName}/${labourUserID}/${schemeAvailID}`}
              style={{ flexGrow: '0.5' }}
              target="_blank"
            >
              <p className="final-success-link-p">
                View Acknowledgement Letter{' '}
                {/* <span variant="outline-primary" className="final-success-link">
                  {' '}
                  Click Here
                </span> */}
              </p>
            </Link>
          </div>
        </Backdrop>
      ) : null}
      {fetchDynamic && fetchDynamic.length === 0 ? null : (
        <Row className="scheme-subtitle-row">
          <Col xs={12} className="searchform-subtitle">
            <p>
              {kannadaSelected
                ? schemes &&
                schemes.length &&
                schemes
                  .filter((scheme) => scheme.name === schemeName)
                  .map((row) => row.name_kannada)
                : schemes &&
                schemes.length &&
                schemes
                  .filter((scheme) => scheme.name === schemeName)
                  .map((row) => row.name)}
            </p>
          </Col>
        </Row>
      )}
      <Row className="form-row mt-4 config-form searchform">
        {+schemeID === 1 && (
          <MarriageAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            disableSelf={disableSelfMarriage}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 4 && (
          <PensionAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 7 && (
          <ContinuationPensionAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 8 && (
          <DisabilityPensionAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 9 && (
          <ContinuationDisabilityPensionAssitance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 10 && (
          <AccidentAssitance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
            handleFetchIFSC={handleFetchIFSC}
            IFSCCode={IFSC_CODE}
            bankVerified={bankVerified}
            handleResetIFSC={handleResetIFSC}
            handleVerifyAadhaar={handleVerifyAadhaar}
            aadharVerified={aadhaarVerified}
            aadhaarLoading={aadhaarLoading}
            death={death}
            isactive={isActiveUser}
          />
        )}
        {+schemeID === 12 && (
          <DeliveryAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
            childStatus={disableDeliveryChild}
          />
        )}
        {+schemeID === 15 && (
          <FuneralAssitance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
            handleFetchIFSC={handleFetchIFSC}
            IFSCCode={IFSC_CODE}
            bankVerified={bankVerified}
            handleResetIFSC={handleResetIFSC}
            aadharVerified={aadhaarVerified}
            aadhaarLoading={aadhaarLoading}
          />
        )}
        {+schemeID === 16 ? (
          <MedicalAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        ) : null}
        {+schemeID === 22 && (
          <MajorAilmentsAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 23 && (
          <ThayiMaguAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
            childStatus={disableThayiMaguChild}
            isRenewal={renewal}
            thayiMaguFields={thayimaguFields}
          />
        )}
        {+schemeID === 18 && (
          <ShramaSamarthyaAssistance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
        {+schemeID === 30 && (
          <EducationAssitance
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            dynamicFields={fetchDynamic && fetchDynamic}
            formData={formData && formData}
            familyDetails={familyDetails && familyDetails}
            isAPICalled={isCalledAPI}
            handleAadhaar={handleAadhaar}
            handleVerifyClick={handleVerifyClick}
            aadhaarNumber={aadhaarNumber}
            handleReset={handleReset}
            isVerified={isKutumbaVerified}
            disableBtn={disableAadhaarBtn}
            loading={loadingFields}
            kannadaSelected={kannadaSelected}
          />
        )}
      </Row>
      {schemeName !== 'Pension Scheme' && (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>
                <Translate value="documents" />
              </p>
            </Col>
          </Row>
          <Row className="form-row mt-4 config-form searchform">
            {loadingDocuments ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <CircularProgress color="inherit" />
                <p className="align-self-center py-3">
                  Please wait fetching documents....
                </p>
              </div>
            ) : schemeName === 'Marriage Assistance' &&
              formData &&
              formData['Married Outside Karnataka'] === 178 ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  item.document_name === 'Marriage Certificate' && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : schemeName === 'Accident Assistance' &&
              formData &&
              formData['Accident Resulted In'] !== 27 ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  (item.document_name === 'FIR COPY' ||
                    item.document_name === 'Medical Certificate' ||
                    item.document_name ===
                    'Medical Disability Certificate') && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : schemeName === 'Accident Assistance' &&
              formData &&
              formData['Accident Resulted In'] === 27 ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  (item.document_name === 'FIR COPY' ||
                    item.document_name === 'Postmortem Report' ||
                    item.document_name === 'Nominee Adhar card' ||
                    item.document_name === 'Death Certificate') && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : schemeName === 'Thayi Magu Sahaya Hasta' &&
              formData &&
              formData['Place of Birth'] === 1243 ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  (item.document_name === 'Photo of Child' ||
                    item.document_name === 'Birth Certificate' ||
                    item.document_name === 'Affidavit') && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : schemeName === 'Thayi Magu Sahaya Hasta' &&
              renewal === 'true' ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  item.document_name === 'Photo of Child' && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : schemeName === 'Thayi Magu Sahaya Hasta' &&
              formData &&
              formData['Place of Birth'] === 1242 ? (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map(
                (item, index) =>
                  (item.document_name === 'Photo of Child' ||
                    item.document_name === 'Birth Certificate' ||
                    item.document_name === 'Discharge hospital Summary') && (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-4 browsecol"
                    >
                      <Required
                        className="mt-4 mb-2"
                        title={
                          kannadaSelected
                            ? item.document_name_kannada
                            : item.document_name
                        }
                      />
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex flex-column w-100">
                          {!documents && (
                            <div className="browsebutton2-div w-100 filesNames"></div>
                          )}
                          {documents &&
                            !documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] && (
                              <div className="browsebutton2-div w-100 filesNames"></div>
                            )}
                          {documents &&
                            documents[
                            `${item.id}_${item.scheme_id}_${item.document_name}`
                            ] &&
                            Array.from(
                              documents[
                              `${item.id}_${item.scheme_id}_${item.document_name}`
                              ],
                            ).map((docItem, docIndex) => (
                              <div className="" key={docIndex}>
                                <FormControl
                                  fullWidth
                                  className="formcontrol3 w-100"
                                >
                                  <TextField
                                    className="w-100"
                                    variant="outlined"
                                    placeholder="Click Browse to Upload"
                                    disabled
                                    value={docItem.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDocuments(
                                                  docIndex,
                                                  item.id,
                                                  item.scheme_id,
                                                  item.document_name,
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </div>
                            ))}
                        </div>

                        {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                        <div className="mx-2 d-flex justify-content-center">
                          <input
                            type="file"
                            // ref={documentUpload}
                            ref={(input) =>
                            (documentUpload.current[item.document_name] =
                              input)
                            }
                            name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                            onChange={(ev) =>
                              handleUpload(
                                ev,
                                item.id,
                                item.scheme_id,
                                item.document_name,
                              )
                            }
                            multiple
                            hidden
                          />

                          <Button
                            type="button"
                            className=""
                            onClick={() =>
                              documentUpload.current[item.document_name].click()
                            }
                          >
                            <FolderIcon />
                            <Translate value="browse" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ),
              )
            ) : (
              getDocuments &&
              getDocuments.length &&
              getDocuments.map((item, index) => (
                <Col
                  key={index}
                  xs={12}
                  md={6}
                  className="final-personal-col-1 px-3 mt-4 browsecol"
                >
                  <Required
                    className="mt-4 mb-2"
                    title={
                      kannadaSelected
                        ? item.document_name_kannada
                        : item.document_name
                    }
                  />
                  <div className="d-flex flex-row w-100">
                    <div className="d-flex flex-column w-100">
                      {!documents && (
                        <div className="browsebutton2-div w-100 filesNames"></div>
                      )}
                      {documents &&
                        !documents[
                        `${item.id}_${item.scheme_id}_${item.document_name}`
                        ] && (
                          <div className="browsebutton2-div w-100 filesNames"></div>
                        )}
                      {documents &&
                        documents[
                        `${item.id}_${item.scheme_id}_${item.document_name}`
                        ] &&
                        Array.from(
                          documents[
                          `${item.id}_${item.scheme_id}_${item.document_name}`
                          ],
                        ).map((docItem, docIndex) => (
                          <div className="" key={index}>
                            <FormControl
                              fullWidth
                              className="formcontrol3 w-100"
                            >
                              <TextField
                                className="w-100"
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                disabled
                                value={docItem.name}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveDocuments(
                                              docIndex,
                                              item.id,
                                              item.scheme_id,
                                              item.document_name,
                                            )
                                          }
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          </div>
                        ))}
                    </div>

                    {/* {documents &&
                documents[
                  `${item.id}_${item.scheme_id}_${item.document_name}`
                ] &&
                Array.from(
                  documents[
                    `${item.id}_${item.scheme_id}_${item.document_name}`
                  ],
                ).map((data) => <p>{data.name}</p>)} */}
                    <div className="mx-2 d-flex justify-content-center">
                      <input
                        type="file"
                        // ref={documentUpload}
                        ref={(input) =>
                          (documentUpload.current[item.document_name] = input)
                        }
                        name={`${item.id}_${item.scheme_id}_${item.document_name}`}
                        onChange={(ev) =>
                          handleUpload(
                            ev,
                            item.id,
                            item.scheme_id,
                            item.document_name,
                          )
                        }
                        multiple
                        hidden
                      />

                      <Button
                        type="button"
                        className=""
                        onClick={() =>
                          documentUpload.current[item.document_name].click()
                        }
                      >
                        <FolderIcon />
                        <Translate value="browse" />
                      </Button>
                    </div>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </>
      )}
      {schemeName === 'Funeral Expense and Death Assistance' ||
        schemeName === 'Continuation of Pension' ||
        schemeName === 'Continuation of Disability Pension' ? null : (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="profile-title">
              <h1 style={{}}>
                <Translate value="daysWorkCertificateDetails" />
              </h1>
            </Col>
          </Row>

          <Row className="form-row ninydays-row">
            <Col xs={12} className="nintydays-col-5">
              <Row className="form-inner-card-plain">
                <Col xs={12} md={6}>
                  {formData && formData['Accident Resulted In'] === 27 ? (
                    <p>
                      <Translate value="typeofIssuer" />
                    </p>
                  ) : (
                    <Required
                      className="mt-2 mb-2"
                      title={<Translate value="typeofIssuer" />}
                    />
                  )}
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      className="select-marital"
                      value={issuerTypeID || '0'}
                      name="issuerType"
                      displayEmpty
                      onChange={(ev) => setIssuerTypeID(ev.target.value)}
                    >
                      <MenuItem value="0">
                        <ListItemText primary="Select Type of Issuer" />
                      </MenuItem>
                      {fetchTypeOfIssuer &&
                        fetchTypeOfIssuer.length &&
                        fetchTypeOfIssuer.map((i, index) => (
                          <MenuItem value={i.value_id} key={index}>
                            <ListItemText primary={i.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  {formData && formData['Accident Resulted In'] === 27 ? (
                    <p>
                      <Translate value="issuedDate" />
                    </p>
                  ) : (
                    <Required
                      className="mt-2 mb-2"
                      title={<Translate value="issuedDate" />}
                    />
                  )}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment().subtract(90, 'days')}
                      maxDate={new Date()}
                      // onClick={() => setPickerStatus(true)}
                      // onClose={() => setPickerStatus(false)}
                      // open={users.nintydays_details.openDatePicker}
                      className="datepicker"
                      margin="normal"
                      format="D/MM/yyyy"
                      value={issueDate}
                      onChange={(date) => setIssueDate(date)}
                      // onChange={(date) => {
                      //     setIssuedDate(date)
                      // }}
                      placeholder="DD/MM/YYYY"
                    // KeyboardButtonProps={{
                    //     'aria-label': 'change date',
                    // }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>
                {issuerTypeID === 55 && (
                  <Col xs={12} md={6} className="issuerTypeForm">
                    <p className="mt-5 mb-2">
                      <Translate value="labourInspectorName" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name="inspectorName"
                        // onChange={handleChange}
                        value={inspectorName && inspectorName}
                        disabled
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="circleoftheLabourInspector" />{' '}
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name="inspectorCircle"
                        // onChange={handleChange}
                        value={circleName}
                        disabled
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="mobileNumberoftheLabourInspector" />{' '}
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name="inspectorPhone"
                        // onChange={handleChange}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <img
                        //         src={mobile}
                        //         alt="..."
                        //         className="phone-icon"
                        //       />
                        //       <p className="countrycode">+91</p>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        value={
                          labourInspectormobileNum && labourInspectormobileNum
                        }
                        type="number"
                        disabled
                      />
                    </FormControl>
                  </Col>
                )}
                {issuerTypeID === 56 && (
                  <Col xs={12} md={6} className="issuerTypeForm">
                    <p className="mt-5 mb-2">
                      <Translate value="unionName" />
                    </p>

                    <FormControl fullWidth>
                      <Select2
                        options={union}
                        className="select-marital"
                        onChange={handleDropdownSelect}
                        isSearchable={true}
                        value={selectedOption && selectedOption}
                        placeholder="Search Union"
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="unionRegistrationNumber" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name="unionRegistrationNumber"
                        // onChange={handleChange}
                        value={unionRegistrationNumber}
                        disabled
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        // name="unionIssuerName"
                        onChange={(ev) =>
                          setUnionIssuerName(
                            ev.target.valuereplace(/[^A-Z]/gi, ''),
                          )
                        }
                        value={unionIssuerName}
                      // disabled
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="mobileNumberIfAvailable" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        // name="unionPhone"
                        onChange={(ev) =>
                          setUnionMobileNumber(
                            ev.target.value
                              .replace(/[^0-9]/gi, '')
                              .slice(0, 10),
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={mobile}
                                alt="..."
                                className="phone-icon"
                              />
                              <p className="countrycode">+91</p>
                            </InputAdornment>
                          ),
                        }}
                        value={unionMobileNumber}
                      />
                    </FormControl>
                  </Col>
                )}
                {issuerTypeID === 57 && (
                  <Col xs={12} md={6} className="issuerTypeForm">
                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Enter Issuer Name"
                        name="issuerName"
                        onChange={(ev) => setGramIssuerName(ev.target.value)}
                        value={gramIssuerName}
                      // helperText={
                      //   allValues.issuerNameError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                      // error={allValues.issuerNameError}
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="placeofIssuer" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Enter Place of Issuer"
                        name="issuerPlace"
                        onChange={(ev) => setGramIssuerPlace(ev.target.value)}
                        value={gramIssuerPlace}
                      // helperText={
                      //   allValues.issuerPlaceError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                      // error={allValues.issuerPlaceError}
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="mobileNumberIfAvailable" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={mobile}
                                alt="..."
                                className="phone-icon"
                              />
                              <p className="countrycode">+91</p>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter Mobile Number of Issuer"
                        name="issuerPhone"
                        onChange={(ev) =>
                          setGramIssuerMobileNumber(
                            ev.target.value
                              .replace(/[^0-9]/gi, '')
                              .slice(0, 10),
                          )
                        }
                        value={gramIssuerMobileNumber}
                      // helperText={
                      //   allValues.issuerPhoneError
                      //     ? 'Please enter in correct format'
                      //     : ''
                      // }
                      // error={allValues.issuerPhoneError}
                      />
                    </FormControl>
                  </Col>
                )}
                {issuerTypeID === 58 && (
                  <Col xs={12} md={6} className="issuerTypeForm">
                    <p className="mt-4 mb-2">
                      <Translate value="nameofIssuer" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Enter Name of Issuer"
                        name="issuerName"
                        // type = "text"
                        onChange={(ev) =>
                          setConstructionIssuerName(ev.target.value)
                        }
                        value={constructionIssuerName}
                      // helperText={allValues.issuerNameError ? "Please enter in correct format" : ""}
                      // error = {allValues.issuerNameError}
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="designationofIssuer" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Enter Designation of Issuer"
                        type="text"
                        onChange={(ev) =>
                          setConstructionDesignation(ev.target.value)
                        }
                        value={constructionDesignation}
                      // helperText={allValues.issuerDesignationError ? "Please enter in correct format" : ""}
                      // error = {allValues.issuerDesignationError}
                      />
                    </FormControl>

                    <p className="mt-4 mb-2">
                      <Translate value="mobileNumberIfAvailable" />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={mobile}
                                alt="..."
                                className="phone-icon"
                              />
                              <p className="countrycode">+91</p>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter Mobile Number of Issuer"
                        name="issuerPhone"
                        onChange={(ev) =>
                          setConstructionMobileNumber(
                            ev.target.value
                              .replace(/[^0-9]/gi, '')
                              .slice(0, 10),
                          )
                        }
                        value={constructionMobileNumber}
                      // helperText={allValues.issuerPhoneError ? "Please enter in correct format" : ""}
                      // error = {allValues.issuerPhoneError}
                      />
                    </FormControl>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={12} className="nintydays-col-6">
              {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
              {formData && formData['Accident Resulted In'] === 27 ? (
                <p>
                  <Translate value="uploadYourDaysWorkingCertificate" />
                </p>
              ) : (
                <Required
                  className="mt-3 mb-2"
                  title={<Translate value="uploadYourDaysWorkingCertificate" />}
                />
              )}
              <div className="browsebutton-outerdiv">
                <div className="w-75">
                  {nintyDaysDoc &&
                    nintyDaysDoc.length > 0 &&
                    nintyDaysDoc.map((file, index) => (
                      <FormControl
                        fullWidth
                        className="formcontrol3 w-100"
                        key={index}
                      >
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          // name="mail_id"
                          disabled
                          value={file.name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveNintyDaysFile(index)
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))}
                  {nintyDaysDocID && nintyDaysDocID.length > 0 && (
                    <FormControl fullWidth className="formcontrol3 w-100">
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        // name="mail_id"
                        disabled
                        value={nintyDaysDocID[0].file_name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {
                                <IconButton
                                // onClick={() =>
                                //   handleRemoveNintyDaysFile(index)
                                // }
                                >
                                  <CancelIcon />
                                </IconButton>
                              }
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  )}
                  {nintyDaysDoc && nintyDaysDoc.length === 0 && (
                    <FormControl fullWidth className="formcontrol3 w-100">
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        disabled
                      />
                    </FormControl>
                  )}
                </div>
                <div className="browsebutton2-div">
                  <div className="">
                    <input
                      type="file"
                      ref={nintyDaysDocRef}
                      onChange={handleNintyDaysFile}
                      multiple
                      hidden
                    />
                    <Button
                      type="button"
                      className="py-2 mx-2"
                      onClick={() => nintyDaysDocRef.current.click()}
                    >
                      <FolderIcon /> <Translate value="browse" />
                    </Button>
                  </div>
                </div>
              </div>

              <p className="mt-4 text-center instructions-ration">
                <span>
                  <Translate value="instructions" />* :{' '}
                </span>
                <br />
                <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                <br />
                <Translate value="sizecontains500KBto2MB" />
              </p>
            </Col>
          </Row>
        </>
      )}
      <Row className="scheme-subtitle-row">
        <Col xs={12} className="searchform-subtitle">
          {/* <p>Declaration</p> */}
          <p>
            <Translate value="declaration" />
          </p>
        </Col>
      </Row>
      <Row className="form-row mt-4 config-form searchform">
        {(formData && formData['Accident Resulted In'] === 27) ||
          schemeName === 'Funeral Expense and Death Assistance' ||
          schemeName === 'Continuation of Disability Pension' ||
          schemeName === 'Continuation of Pension' ? null : (
          <Row className="ack-table-row-last">
            <Col xs={2}></Col>
            <Col xs={12} className=" schemes mt-4 bank-col-5">
              <>
                <p className="mb-2 mt-3 text-left" style={{ color: 'black' }}>
                  <Translate value="selfdeclaration" />
                </p>
                <div className="browsebutton-outerdiv d-flex justify-content-center align-items-center">
                  <div className="w-50">
                    {selfDeclarationDoc &&
                      selfDeclarationDoc.length > 0 &&
                      selfDeclarationDoc.map((file, index) => (
                        <FormControl
                          fullWidth
                          className="formcontrol3 w-100"
                          key={index}
                        >
                          <TextField
                            className="w-100"
                            variant="outlined"
                            placeholder="Click Browse to Upload"
                            // name="mail_id"
                            disabled
                            value={file.name}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    <IconButton
                                      onClick={() =>
                                        handleRemoveSelfDeclarationFile(index)
                                      }
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      ))}
                    {selfDeclarationDoc && selfDeclarationDoc.length === 0 && (
                      <FormControl fullWidth className="formcontrol3 w-100">
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          disabled
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className="browsebutton2-div">
                    <div className="">
                      <input
                        type="file"
                        ref={selfDeclarationFileRef}
                        onChange={handleSelfDeclarationFile}
                        multiple
                        hidden
                      />
                      <Button
                        type="button"
                        className="py-2 mx-2"
                        onClick={() => selfDeclarationFileRef.current.click()}
                      >
                        <FolderIcon /> <Translate value="browse" />
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            </Col>
          </Row>
        )}
        <Col
          xs={12}
          className="note2 schemes bank-col-5 d-flex justify-content-center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={declaration}
                onChange={(ev) => setDeclaration(ev.target.checked)}
                name="declaration"
                color="primary"
              />
            }
            label={
              <Translate value="theFactsMentionedaboveisBestofMyKnowledgeandInformation" />
            }
          />
        </Col>
        <Col
          xs={12}
          className="note2 schemes bank-col-5 d-flex justify-content-center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={declaration2}
                onChange={(ev) => setDeclaration2(ev.target.checked)}
                name="declaration"
                color="primary"
              />
            }
            label={<Translate value="declarationmessage4" />}
          />
        </Col>
      </Row>
      <div className="w-100 d-flex justify-content-end align-items-center px-5 py-3 my-2">
        <Button
          style={{ width: '15%' }}
          className="py-3 mx-2 bg-danger"
          onClick={() => {
            history.push(`/scheme/${schemeName}_id=${schemeID}/${roleID}`);
          }}
        >
          <Translate value="backbtn" />
        </Button>
        <Button
          style={{ width: '15%' }}
          className="py-3 mx-2 bg-success"
          onClick={handleSubmit}
          disabled={submitLoading}
        >
          <Translate value="submit" />
        </Button>
      </div>
    </>
  );
};

export default DynamicFields;
