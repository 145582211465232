import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Translate } from 'react-redux-i18n';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  BASE_URL,
  SERVER,
  addLabourPersonalDetails,
  getUserRegistrationDetails,
} from 'store/actions/user.actions';
import { Required, getTokenCookie, showToast } from 'utils/tools';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import language from 'assets/images/translate (1).svg';
import Header from './Header';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { CameraAlt, PhoneAndroid } from '@material-ui/icons';
import Webcam from 'react-webcam';
import folder from 'assets/images/Folderwhite-01-01.svg';
// import webcam from 'assets/images/web-cam.svg'
import webcam from 'assets/images/web cam-01.svg';
import male from 'assets/images/Mask Group 1.svg';
import female from 'assets/images/Mask Group 2.svg';
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import uploadFileAPI from 'utils/fileupload';
import FolderIcon from '@mui/icons-material/Folder';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import avatar from 'assets/images/avatar.png';
import moment from 'moment';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  getUserOnVaultReferenceNumber,
  updateEKYCVaultRefNumber,
} from './Aadhaar/Aadhaar.service';
import PersonalTest from './PersonalTest';
import { DataContext } from 'context/config';

const PersonalDetails = (props) => {
  const { labourUserID, isEdited } = useParams();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { data: configData } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();
  const [personalDetails, setPersonalDetails] = useState(null);
  const [personalFields, setPersonalFields] = useState({
    first_name: null,
    middle_name: null,
    last_name: null,
    pan_no: null,
    mobile_no: null,
    mail_id: null,
    ayushManCardNumber: null,
    rdNumber: null,
    maritialStatus: null,
    gender: null,
    caste: null,
    religion: null,
    education: null,
  });
  const [dob, setDob] = useState(null);
  const [fetchReligion, setFetchReligion] = useState(null);
  const [fetchCategory, setFetchCategory] = useState(null);
  const [fetchGender, setFetchGender] = useState(null);
  const [labourInactiveState, setlabourInactiveState] = React.useState(() => {
    const validityDate = moment(users.user?.validity_to_date);
    const oneYearBeforeToday = moment().clone().subtract(1, 'year');
    const daysDiff = Math.abs(oneYearBeforeToday.diff(validityDate, 'days'));
    const dayCanReg = 89 - daysDiff;
    const allowInactiveFlag =
      dayCanReg <= 0 && users?.user?.tab_name === '90_days_certificate_details'
        ? true
        : false;

    return allowInactiveFlag;
  });
  const [fetchMaritialStatus, setFetchMaritialStatus] = useState(null);
  const [fetchEducation, setFetchEducation] = useState(null);
  const [fetchState, setFetchState] = useState(null);
  const [fetchDistrict, setFetchDistrict] = useState(null);
  const [genderID, setGenderID] = useState(null);
  const [casteID, setCasteID] = useState(null);
  const [maritialID, setMaritialID] = useState(null);
  const [religionID, setReligionID] = useState(null);
  const [educationID, setEducationID] = useState(null);
  const [isInterState, setIsInterState] = useState(null);
  const [migrantStateID, setMigrantStateID] = useState(null);
  const [isInterDistrict, setIsInterDistrict] = useState(null);
  const [migrantDistrictID, setMigrantDistrictID] = useState(null);
  const [isAyushmanHolder, setIsAyushmanHolder] = useState(null);
  // const [ayushManCardNumber, setAyushManCardNumber] = useState(null);
  const [aadhaarNo, setAadhaarNo] = useState(null);
  const [isAadhaarVerified, setIsAadhaarVerified] = useState(false);

  const [profilePic, setProfilePic] = React.useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [showProfileImage, setShowProfileImage] = useState(null);
  const [educationDoc, setEducationDoc] = useState(null);
  const [imageEKYCID, setImageEKYCID] = useState(null);
  const [showEducationDoc, setShowEducationDoc] = useState(null);
  const [showCameraImage, setShowCameraImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [vaultRefNumber, setVaultRefNumber] = useState(null);
  const [aadhaarDuplicate, setAadharDuplicate] = useState(null);
  const [aadhaarLoading, setAadhaarLoading] = useState(null);
  const [aadhaarValidated, setAadharValidated] = useState(null);
  const [profileImageID, setProfileImageID] = useState(null);
  const [educationDocID, setEducationDocID] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const profileImageRef = useRef(null);
  const webCamImageRef = useRef(null);
  const educationFileRef = useRef(null);

  const [errors, setErrors] = useState(null);

  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: 'user',
    // facingMode: { exact: "environment" }
  };

  // useEffect(() => {
  //   if (personalFields && personalFields) {
  //     setErrors(validationForm(personalFields));
  //   }
  // }, [personalFields]);

  const validationForm = (values) => {
    const errors = {};

    if (values.first_name === '') {
      errors.first_name = 'Field is required';
    }
    if (isAyushmanHolder && +isAyushmanHolder) {
      if (values.ayushManCardNumber && values.ayushManCardNumber === '') {
        errors.ayushManCardNumber = 'Field is required';
      }
    }
    if (+values.maritialStatus === 0) {
      errors.maritialStatus = 'Field is required';
    }
    if (+values.caste === 0) {
      errors.caste = 'Field is required';
    }
    if (+values.religion === 0) {
      errors.religion = 'Field is required';
    }
    if (+values.gender === 0) {
      errors.gender = 'Field is required';
    }
    if (+values.education === 0) {
      errors.education = 'Field is required';
    }
    return errors;
  };

  const errorStyles = {
    borderColor: 'red',
  };

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'all',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        const { personal_details } = data;
        // console.log(personal_details);
        setSubmitLoading(false);
        setPersonalDetails(data.personal_details);
        setPersonalFields((prev) => ({
          ...prev,
          mobile_no: personal_details[0].mobile_no,
          first_name: personal_details[0].first_name,
          middle_name: personal_details[0].middle_name,
          last_name: personal_details[0].last_name,
          ayushManCardNumber: personal_details[0].ayushman_card_number,
          rdNumber: personal_details[0].rd_no,
          caste: personal_details[0].catalog_value_caste_id,
          maritialStatus: personal_details[0].is_rejected
            ? null
            : personal_details[0].catalog_value_marital_status_id,
          gender: personal_details[0].catalog_value_gender_id,
          education: personal_details[0].catalog_value_qualification_id,
          religion: personal_details[0].catalog_value_religion_id,
        }));
        setIsAadhaarVerified(
          personal_details[0].is_aadhar_verified &&
            !personal_details[0].is_rejected,
        );
        if (personal_details[0].aadhar_no & !personal_details[0].is_rejected) {
          setVaultRefNumber(personal_details[0].aadhar_no);
        }

        setProfileImageID(personal_details[0].user_photo_id);
        setEducationID(personal_details[0].catalog_value_qualification_id);
        setReligionID(personal_details[0].catalog_value_religion_id);
        setCasteID(personal_details[0].catalog_value_caste_id);
        setMaritialID(personal_details[0].catalog_value_marital_status_id);
        setGenderID(personal_details[0].catalog_value_gender_id);
        setIsInterState(personal_details[0].is_inter_state_migrant ? '1' : '0');
        setIsInterDistrict(personal_details[0].is_inter_district ? '1' : '0');
        setIsAyushmanHolder(
          personal_details[0].is_ayushman_card_holder ? '1' : '0',
        );

        setDob(
          personal_details[0].date_of_birth &&
            moment(personal_details[0].date_of_birth),
        );
        const eduDoc =
          personal_details[0].qualification_document_id &&
          JSON.parse(personal_details[0].qualification_document_id);

        setEducationDocID(eduDoc);
        setMigrantStateID(personal_details[0].migrate_from_state_id);
        setMigrantDistrictID(personal_details[0].inter_district_id);
      })

      .catch((err) => {
        if (err) {
          setSubmitLoading(false);
          // showToast('ERROR', `${err.response.data.errors.msg}`);
          showToast('ERROR', 'Something went wrong,try after some time');
        } else {
          showToast('ERROR', 'Something went wrong,try after some time');
        }
      });
  }, [labourUserID]);

  useEffect(() => {
    if (profileImageID) {
      axios
        .get(`${SERVER}/upload/download_base_64/${profileImageID}`)
        .then((res) => {
          const { data } = res.data;
          setShowProfileImage(`data:image/png;base64,${data.base_64_content}`);
        })
        .catch((err) => {});
    }
  }, [profileImageID]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Religion',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchReligion(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Category',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchCategory(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Religion',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchReligion(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Marital Status',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchMaritialStatus(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Education',
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchEducation(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/global/get_state_details`)
      .then((res) => {
        const { data } = res.data;
        setFetchState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/global/get_district_details?state_id=12`)
      .then((res) => {
        const { data } = res.data;
        setFetchDistrict(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const currentUrl = window.location.toString();
    const queryString = new URLSearchParams(location.search);

    if (currentUrl.includes('Success')) {
      const vaultRefNo = queryString.get('vaultrefno');

      if (vaultRefNo) {
        setVaultRefNumber(vaultRefNo);
        callGetUserOnVaultReferenceNumber(vaultRefNo);
      }
    } else if (currentUrl.includes('Failure')) {
      showToast('ERROR', 'Aadhaar Verification failed, please try again.');
    }
  }, [location.search]);

  const handleVerifyAadhaar = async () => {
    try {
      //Production Data

      const payLoad = {
        responseRedirectURL: `${BASE_URL}/profile/personal/${labourUserID}`,
      };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/profile/personal/${labourUserID}`,
      // };

      // DEV DATA
      //  const payLoad = {
      //    responseRedirectURL: `http://gcdmsdbac.hostg.in:5353/profile/personal/${labourUserID}`,
      //  };

      const response = await EKYCWEBHOOK(payLoad);

      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        '_self',
      );
    } catch (error) {
      showToast(
        'ERROR',
        'Something went wrong! Please try again after some time.',
      );
    }
  };

  useEffect(() => {
    if (users) {
      const validityDate = moment(users.user?.validity_to_date);
      const oneYearBeforeToday = moment().clone().subtract(1, 'year');
      const daysDiff = Math.abs(oneYearBeforeToday.diff(validityDate, 'days'));
      const dayCanReg = 89 - daysDiff;
      const allowInactiveFlag =
        dayCanReg <= 0 &&
        users?.user?.tab_name === '90_days_certificate_details'
          ? true
          : false;
      setlabourInactiveState(allowInactiveFlag);
    }
  }, [users]);

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    try {
      const payLoad = {
        key: 'aadhar_no',
        value: vaultRefNo,
        board_id: 1,
      };

      const response = await getUserOnVaultReferenceNumber(payLoad);

      const renewalCheck =
        users?.user?.tab_name === '90_days_certificate_details' &&
        users.getUserRegistrationDetails.personal_details &&
        users.getUserRegistrationDetails.personal_details.length &&
        users.getUserRegistrationDetails.personal_details[0].is_rejected &&
        users?.getUserRegistrationDetails?.application_details &&
        moment().diff(
          moment(
            users?.getUserRegistrationDetails?.personal_details[0]
              .approved_date,
          ),
          'days',
        ) > 90;

      if (response.data.length !== 0 && !renewalCheck && !labourInactiveState) {
        showToast(
          'ERROR',
          'Aadhaar already registered with other mobile number',
        );

        return;
      }

      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);
      const updateEKYCPayLoad = {
        labourUserId: +labourUserID,
        aadharNo: vaultRefNo,
      };

      if (aadhaarData.length > 0) {
        const aadhaarDOB =
          aadhaarData && moment().diff(aadhaarData[0].dob, 'years');
        if (aadhaarDOB >= 18 && aadhaarDOB < 60) {
          const updateEKYCResponse =
            await updateEKYCVaultRefNumber(updateEKYCPayLoad);
          if (updateEKYCResponse.success) {
            showToast('SUCCESS', 'Aadhaar Verified Successfully');
            // Allow
            setShowProfileImage(
              `data:image/png;base64,${aadhaarData[0].document_link}`,
            );
            setImageEKYCID(aadhaarData[0].image);
            // Split into FirstName,MiddleName,LastName
            // console.log('ghg', data[0].dob && moment(data[0].dob, 'YYYY-MM-DD'));
            var first_name = '';
            var middle_name = '';
            var last_name = '';
            var nameArr = aadhaarData[0].name.split(' ');

            if (nameArr.length === 1) {
              first_name = nameArr[0];
            } else if (nameArr.length === 2) {
              first_name = nameArr[0];
              last_name = nameArr[1];
            } else if (nameArr.length >= 3) {
              first_name = nameArr[0];
              middle_name = nameArr[1];
              last_name = nameArr[2];
            }
            // const fullName = data[0].name.split(' ');
            // const lastName = fullName.pop(); // 'Panakkal'
            // const middleName = fullName.pop();
            // const firstName = fullName.join(' ');
            setIsAadhaarVerified(true);
            setPersonalFields((prev) => ({
              ...prev,
              first_name: aadhaarData[0].name,
            }));
            setDob(aadhaarData[0].dob && moment(aadhaarData[0].dob));

            setPersonalFields((prev) => ({
              ...prev,
              gender:
                aadhaarData[0].gender === 'M'
                  ? 27
                  : aadhaarData[0].gender === 'F'
                    ? 28
                    : 29,
            }));
          }
        } else {
          showToast(
            'ERROR',
            `Your age is ${aadhaarDOB}. As per the KBOCWWB ACT you are not eligible for the registration`,
          );
        }
      } else {
        setIsAadhaarVerified(false);
        showToast('ERROR', 'Aadhaar Verification failed, please try again. ');
      }
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  const handlePersonalChange = (ev) => {
    const { name, value } = ev.target;

    setErrors(
      validationForm({
        [name]: value,
      }),
    );
    if (ev.target.name === 'first_name') {
      setPersonalFields((prev) => ({
        ...prev,
        first_name: ev.target.value.replace(/[^a-zA-Z ]/gi, ''),
      }));
    } else if (ev.target.name === 'middle_name') {
      setPersonalFields((prev) => ({
        ...prev,
        middle_name: ev.target.value.replace(/[^a-zA-Z]/gi, ''),
      }));
    } else if (ev.target.name === 'last_name') {
      setPersonalFields((prev) => ({
        ...prev,
        last_name: ev.target.value.replace(/[^a-zA-Z]/gi, ''),
      }));
    } else if (ev.target.name === 'mail_id') {
      setPersonalFields((prev) => ({
        ...prev,
        mail_id: ev.target.value,
      }));
    } else if (ev.target.name === 'pan_no') {
      setPersonalFields((prev) => ({
        ...prev,
        pan_no: ev.target.value,
      }));
    } else if (ev.target.name === 'ayushManCardNumber') {
      setPersonalFields((prev) => ({
        ...prev,
        ayushManCardNumber: ev.target.value,
      }));
    } else if (ev.target.name === 'rdNumber') {
      setPersonalFields((prev) => ({
        ...prev,
        rdNumber: ev.target.value,
      }));
    } else if (ev.target.name === 'caste') {
      setPersonalFields((prev) => ({
        ...prev,
        caste: ev.target.value,
      }));
    } else if (ev.target.name === 'religion') {
      setPersonalFields((prev) => ({
        ...prev,
        religion: ev.target.value,
      }));
    } else if (ev.target.name === 'maritialStatus') {
      setPersonalFields((prev) => ({
        ...prev,
        maritialStatus: ev.target.value,
      }));
    } else if (ev.target.name === 'gender') {
      setPersonalFields((prev) => ({
        ...prev,
        gender: ev.target.value,
      }));
    } else if (ev.target.name === 'education') {
      setPersonalFields((prev) => ({
        ...prev,
        education: ev.target.value,
      }));
    }
  };

  // console.log('personalFields', personalFields);

  const handleFileChange = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg'
    ) {
      if (ev.target.files[0].size < 2000001) {
        setShowProfileImage(URL.createObjectURL(ev.target.files[0]));
        setProfileImage(ev.target.files[0]);
        setImageEKYCID(null);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleEducationFileChange = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        setShowEducationDoc(URL.createObjectURL(ev.target.files[0]));
        setEducationDoc(ev.target.files[0]);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const uploadFiles = async () => {
    try {
      if (
        users &&
        (!users.user.inspection_id || users.user.inspection_id === 0)
      ) {
        if (
          personalFields.education === 16 ||
          personalFields.education === 17 ||
          personalFields.education === 18
        ) {
          if (imageEKYCID || profileImageID) {
            const imageEKYCObj = {
              image: {
                fileId: imageEKYCID || profileImageID,
                originalFileName: `Profile_${labourUserID}`,
              },
            };

            // const [educationImageResponse] = await Promise.all([educationDocAPI]);
            insertPersonDetails(imageEKYCObj.image, []);
          } else {
            const profileFileAPI = uploadFileAPI(
              profileImage,
              profileImage.name,
              labourUserID,
              'profileImage',
            );

            const [profileImageResponse] = await Promise.all([profileFileAPI]);
            insertPersonDetails(profileImageResponse.image, []);
          }
        } else {
          if (profileImageID && educationDocID && educationDocID.length > 0) {
            const imageEKYCObj = {
              image: {
                fileId: profileImageID,
                originalFileName: `Profile_${labourUserID}`,
              },
            };
            const educationDocObj = {
              image: educationDocID.map((doc) => ({
                fileId: doc.file_id,
                originalFileName: doc.file_name,
              })),
            };

            insertPersonDetails(imageEKYCObj.image, educationDocObj.image);
          } else if ((imageEKYCID || profileImageID) && educationDoc) {
            const imageEKYCObj = {
              image: {
                fileId: imageEKYCID || profileImageID,
                originalFileName: `Profile_${labourUserID}`,
              },
            };
            const educationDocAPI = uploadFileAPI(
              educationDoc,
              educationDoc.name,
              labourUserID,
              'educationDoc',
            );
            const [educationImageResponse] = await Promise.all([
              educationDocAPI,
            ]);

            insertPersonDetails(imageEKYCObj.image, [
              educationImageResponse.image,
            ]);
          } else {
            const profileFileAPI = uploadFileAPI(
              profileImage,
              profileImage.name,
              labourUserID,
              'profileImage',
            );
            const educationDocAPI = uploadFileAPI(
              educationDoc,
              educationDoc.name,
              labourUserID,
              'educationDoc',
            );
            const [profileImageResponse, educationImageResponse] =
              await Promise.all([profileFileAPI, educationDocAPI]);
            insertPersonDetails(profileImageResponse.image, [
              educationImageResponse.image,
            ]);
          }
        }
      } else {
        if (imageEKYCID || profileImageID) {
          const imageEKYCObj = {
            image: {
              fileId: imageEKYCID || profileImageID,
              originalFileName: `Profile_${labourUserID}`,
            },
          };

          insertPersonDetails(imageEKYCObj.image, []);
        } else {
          const profileFileAPI = uploadFileAPI(
            profileImage,
            profileImage.name,
            labourUserID,
            'profileImage',
          );

          const [profileImageResponse] = await Promise.all([profileFileAPI]);
          insertPersonDetails(profileImageResponse.image, []);
        }
      }

      //Normal File Upload
      // if (imageEKYCID || profileImageID) {
      //   const imageEKYCObj = {
      //     image: {
      //       fileId: imageEKYCID || profileImageID,
      //       originalFileName: `Profile_${labourUserID}`,
      //     },
      //   };
      //   const educationDocAPI = uploadFileAPI(
      //     educationDoc,
      //     educationDoc.name,
      //     labourUserID,
      //     'educationDoc',
      //   );
      //   const [educationImageResponse] = await Promise.all([educationDocAPI]);
      //   insertPersonDetails(imageEKYCObj.image, educationImageResponse.image);
      // } else {
      //   const profileFileAPI = uploadFileAPI(
      //     profileImage,
      //     profileImage.name,
      //     labourUserID,
      //     'profileImage',
      //   );
      //   const educationDocAPI = uploadFileAPI(
      //     educationDoc,
      //     educationDoc.name,
      //     labourUserID,
      //     'educationDoc',
      //   );
      //   const [profileImageResponse, educationImageResponse] =
      //     await Promise.all([profileFileAPI, educationDocAPI]);
      //   insertPersonDetails(
      //     profileImageResponse.image,
      //     educationImageResponse.image,
      //   );
      // }
    } catch (error) {}
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const capture = () => {
    const imageSrc = webCamImageRef.current.getScreenshot();

    sessionStorage.setItem('profilePic', imageSrc);
    // setFileUploaded(true);
    setShowProfileImage(imageSrc);
    setOpenDialog(false);
    // uploadProfilePicEKYC(imageSrc);
  };

  const insertPersonDetails = (profileImgResponse, educationDocResponse) => {
    try {
      if (educationDocResponse.length > 0) {
        const educationDoc = educationDocResponse.map((doc) => ({
          file_id: doc.fileId,
          file_name: doc.originalFileName,
        }));

        const profileImage = [
          {
            file_id: profileImgResponse.fileId,
            file_name: profileImgResponse.originalFileName,
          },
        ];
        const payLoad = {
          first_name: personalFields.first_name,
          first_name_kannada: null,
          first_name_hindi: null,
          middle_name_kannada: null,
          middle_name_hindi: null,
          middle_name: personalFields.middle_name,
          last_name: personalFields.last_name,
          last_name_kannada: null,
          last_name_hindi: null,
          user_id: labourUserID,
          email: personalFields.mail_id,
          catalog_value_religion_id: personalFields.religion,
          // "aadhar_no": users.personal_details.aadhar,
          aadhar_no: vaultRefNumber ?? '',
          // "aadhar_no":"123141231231",
          pan_no: personalFields.pan_no,
          martial_status_id: personalFields.maritialStatus,
          caste_id: personalFields.caste,
          gender_id: personalFields.gender,
          is_inter_state_migrant: isInterState,
          migrate_from_state_id: migrantStateID,
          is_inter_district: isInterDistrict,
          inter_district_id: migrantDistrictID,
          user_photo_id: profileImage[0].file_id,
          dob: moment(dob).format('YYYY-MM-DD'),
          is_aadhar_verified: isAadhaarVerified,
          catalog_value_qualification_id: personalFields.education,
          catalog_value_employement_status: null,
          income_earned: '',
          pf_esi_number: '',
          employer_name: '',
          employer_contact_country_code: '',
          employer_contact_number: '',
          is_ayushman_card_holder: isAyushmanHolder,
          ayushman_card_number: personalFields.ayushManCardNumber,
          qualification_document: educationDoc,
          rd_no: personalFields.rdNumber,
        };

        axios
          .post(`${SERVER}/user/add-labour-personal-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);

            if (isEdited === 'edit') {
              showToast('SUCCESS', 'Personal details updated successfully');
              history.push(`/profile/review/${labourUserID}`);
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } else {
              showToast('SUCCESS', 'Personal details saved successfully');
              history.push(`/profile/address/${labourUserID}`);
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: 'user_id',
                value: labourUserID,
                procedure_name: 'all',
              }),
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast('ERROR', `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              showToast(
                'ERROR',
                'Something went wrong,try again after sometime.',
              );
              setSubmitLoading(false);
            }
          });
      } else {
        const profileImage = [
          {
            file_id: profileImgResponse.fileId,
            file_name: profileImgResponse.originalFileName,
          },
        ];

        const payLoad = {
          first_name: personalFields.first_name,
          first_name_kannada: null,
          first_name_hindi: null,
          middle_name_kannada: null,
          middle_name_hindi: null,
          middle_name: personalFields.middle_name,
          last_name: personalFields.last_name,
          last_name_kannada: null,
          last_name_hindi: null,
          user_id: labourUserID,
          email: personalFields.mail_id,
          catalog_value_religion_id: personalFields.religion,
          // "aadhar_no": users.personal_details.aadhar,
          aadhar_no: vaultRefNumber ?? '',
          // "aadhar_no":"123141231231",
          pan_no: personalFields.pan_no,
          martial_status_id: personalFields.maritialStatus,
          caste_id: personalFields.caste,
          gender_id: personalFields.gender,
          is_inter_state_migrant: isInterState,
          migrate_from_state_id: migrantStateID,
          is_inter_district: isInterDistrict,
          inter_district_id: migrantDistrictID,
          user_photo_id: profileImage[0].file_id,
          dob: moment(dob).format('YYYY-MM-DD'),
          is_aadhar_verified: isAadhaarVerified,
          catalog_value_qualification_id: personalFields.education,
          catalog_value_employement_status: null,
          income_earned: '',
          pf_esi_number: '',
          employer_name: '',
          employer_contact_country_code: '',
          employer_contact_number: '',
          is_ayushman_card_holder: isAyushmanHolder,
          ayushman_card_number: personalFields.ayushManCardNumber,
          qualification_document: [],
          rd_no: personalFields.rdNumber,
        };

        axios
          .post(`${SERVER}/user/add-labour-personal-details`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);

            if (isEdited === 'edit' || isEdited === 'back') {
              showToast('SUCCESS', 'Personal details updated successfully');
              history.push(
                `/profile/${
                  isEdited === 'back'
                    ? 'address'
                    : isEdited === 'edit'
                      ? 'review'
                      : ''
                }/${labourUserID}`,
              );
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } else {
              showToast('SUCCESS', 'Personal details saved successfully');
              history.push(`/profile/address/${labourUserID}`);
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: 'user_id',
                value: labourUserID,
                procedure_name: 'all',
              }),
            );
          })
          .catch((err) => {
            if (err.response.data.errors.msg) {
              showToast('ERROR', `${err.response.data.errors.msg}`);
              setSubmitLoading(false);
            } else {
              showToast(
                'ERROR',
                'Something went wrong,try again after sometime.',
              );
              setSubmitLoading(false);
            }
          });
      }
    } catch (error) {
      // setSubmitLoading(false);
    }
  };

  const handleSubmitData = (ev) => {
    ev.preventDefault();
    setErrors(validationForm(personalFields));
    if (
      personalFields.first_name &&
      dob &&
      +personalFields.maritialStatus !== 0 &&
      +personalFields.caste !== 0 &&
      +personalFields.religion !== 0 &&
      +personalFields.gender !== 0 &&
      +personalFields.education !== 0 &&
      isInterState &&
      isInterDistrict &&
      isAyushmanHolder &&
      (imageEKYCID || profileImageID || profileImage)
    ) {
      if (isInterState === '1' && !migrantStateID) {
        showToast('ERROR', 'Please select migrant state');
        return;
      }
      if (isInterDistrict === '1' && !migrantDistrictID) {
        showToast('ERROR', 'Please select migrant district');
        return;
      }
      if (isAyushmanHolder === '1' && !personalFields.ayushManCardNumber) {
        showToast('ERROR', 'Please enter ayushman bharat card number');
        return;
      }

      if (
        users &&
        (!users.user.inspection_id || users.user.inspection_id === 0)
      ) {
        if (
          personalFields.education !== 16 &&
          personalFields.education !== 17 &&
          personalFields.education !== 18
        ) {
          if (educationDoc || (educationDocID && educationDocID.length > 0)) {
            setSubmitLoading(true);
            uploadFiles();
          } else {
            showToast('ERROR', 'Please fill education documents');
          }
        } else {
          setSubmitLoading(true);
          uploadFiles();
        }
      } else {
        setSubmitLoading(true);
        uploadFiles();
      }
    } else {
      showToast('ERROR', 'Please fill all mandatory details');
    }
  };

  const handleBack = () => {
    history.push(`/dashboard-user`);
  };

  return (
    <>
      <Row className="personal-div">
        {submitLoading ? <SimpleBackdrop /> : null}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: '100%' }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              // value={allValues.language}
              name="language"
              displayEmpty
              // onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>

        <Row className="profile-title-row">
          <Col xs={10} className="profile-title">
            {/* <h1>Construction Workers Profile Details</h1> */}
            <Link
              to="/dashboard-user"
              className="profile-title-image"
              // onClick={() => props.history.goBack()}
            >
              <img alt="..." className="cal-icon hover-icn" src={back} />
            </Link>
            <h1>
              <Translate value="profileTitle" />
            </h1>
          </Col>
        </Row>
      </Row>
      {/* <PersonalTest /> */}
      <div className="d-flex justify-content-center align-items-center">
        {!isAadhaarVerified && (
          <Button
            type="button"
            onClick={handleVerifyAadhaar}
            variant="contained"
            color="info"
            className="py-3 px-5 fs-6"
          >
            Click here to verify your Aadhaar
          </Button>
        )}
        {isAadhaarVerified && (
          <Button
            type="button"
            onClick={handleVerifyAadhaar}
            variant="contained"
            color="success"
            className="py-3 px-5 fs-6"
            disabled={isAadhaarVerified}
            sx={{
              '&.Mui-disabled': {
                background: '#66BB6A',
                color: '#ffffff',
              },
            }}
            startIcon={
              <VerifiedIcon style={{ width: '50px', height: '40px' }} />
            }
          >
            Aadhaar Verified Successfully
          </Button>
        )}
      </div>
      {personalDetails && personalDetails.length && isAadhaarVerified && (
        <Row className="m-0">
          <Row className="form-row mt-4">
            <Col xs={12} md={6} className="personal-col-1">
              <div className="form-inner-card-right">
                <Required
                  className="mb-2"
                  title={<Translate value="labourName" />}
                />

                <FormControl
                  fullWidth
                  className="formcontrol1"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                  style={errors && errors.first_name && errorStyles}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Enter your name"
                    name="first_name"
                    value={personalFields.first_name}
                    type="text"
                    onChange={handlePersonalChange}
                    helperText={errors && errors.first_name}
                    error={errors && errors.first_name}
                    // error={allValues.firstnameError}
                    // helperText={
                    //   allValues.firstnameError
                    //     ? 'Please enter in correct format'
                    //     : ''
                    // }
                    disabled={isAadhaarVerified ? true : false}

                    // onFocus={handleFormFocus()}
                  />
                </FormControl>

                {/* <Required className="mt-4 mb-2" title="Enter Your Mobile Number"/> */}
                <p className="mt-4 mb-2">
                  <Translate value="enterYourMobileNumber" />
                </p>
                <FormControl
                  fullWidth
                  className="formcontrol2"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneAndroid className="phone-icon" />
                          +91
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    value={personalFields.mobile_no}
                    disabled
                  />
                </FormControl>
              </div>
            </Col>
            <Col xs={12} md={6} className="">
              <div className="d-flex justify-content-center align-items-center p-2">
                <img
                  src={showProfileImage ?? avatar}
                  alt="..."
                  className="w-50"
                  height={300}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    padding: '5px',
                    width: '150px',
                  }}
                />
              </div>
              <>
                <div className="d-flex justify-content-center align-items-center w-100">
                  {/* <p className="upload-title">
                                Upload Your Profile Picture</p> */}
                  <div className="px-2">
                    <input
                      type="file"
                      name="profileImage"
                      onChange={handleFileChange}
                      hidden
                      ref={profileImageRef}
                    />
                    <Button
                      type="button"
                      variant="outlined"
                      color="info"
                      startIcon={<FolderIcon />}
                      className="w-100"
                      onClick={() => profileImageRef.current.click()}
                    >
                      Upload Profile Photo
                    </Button>
                  </div>
                  <div className="px-2">
                    <Button
                      type="button"
                      variant="outlined"
                      color="info"
                      className="w-100"
                      onClick={handleClickOpen}
                      startIcon={<CameraAltIcon />}
                    >
                      Upload from web camera
                    </Button>
                  </div>

                  <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    // fullWidth={true}
                    // maxWidth='750px'
                  >
                    <DialogTitle id="scroll-dialog-title">
                      <Translate value="captureImageforProfilePicture" />
                    </DialogTitle>
                    <DialogContent>
                      <Webcam
                        audio={false}
                        height={400}
                        ref={webCamImageRef}
                        screenshotFormat="image/jpeg"
                        width={400}
                        // onChange={handleWebCam}
                        mirrored
                        fullWidth
                        videoConstraints={videoConstraints}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={capture}
                        // onChange={handleWebCam}
                        color="primary"
                      >
                        Capture
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <p className="instructions text-center">
                  <span>
                    <Translate value="instructions" />* :{' '}
                  </span>
                  {/* (Upload your profile picture in jpg/png format only) */}
                  <Translate value="uploadyourprofilepictureinjpgpngformatonly" />
                </p>

                <div className="pic-below-border"></div>
              </>
            </Col>
            <Col xs={12} md={6} className="personal-col-3">
              <div className="form-inner-card-right mt-0">
                <p className="mt-0 mb-2">
                  <Translate value="enterEmailAddress" />{' '}
                  <span className="optional">(Optional)</span>
                </p>
                <FormControl fullWidth className="formcontrol3">
                  {/* onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                        handleEmailSubmit();
                                    }
                                }}> */}
                  <TextField
                    variant="outlined"
                    placeholder="Enter Your Email Address"
                    name="mail_id"
                    value={personalFields.mail_id}
                    onChange={handlePersonalChange}
                    // onFocus={handleFormFocus()}
                    // error={allValues.emailError}
                    // helperText={
                    //   allValues.emailError ? 'Please enter valid Email Id' : ''
                    // }
                  />
                </FormControl>

                <Required
                  className="mt-5 mb-2"
                  title={<Translate value="maritalStatus" />}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                  style={errors && errors.maritialStatus && errorStyles}
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={personalFields.maritialStatus || '0'}
                    name="maritialStatus"
                    onChange={handlePersonalChange}
                    displayEmpty

                    // onChange={(ev) => setMaritialID(ev.target.value)}
                  >
                    <MenuItem value="0">
                      <ListItemText primary="Select Maritial Status" />
                    </MenuItem>
                    {fetchMaritialStatus &&
                      fetchMaritialStatus.length &&
                      fetchMaritialStatus.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
            <Col xs={12} md={6} className="personal-col-4">
              <div className="form-inner-card-right mt-0">
                <p className="mt-4 mb-2">
                  <Translate value="enterYourPANCardNumber" />
                </p>
                <FormControl
                  fullWidth
                  className="formcontrol8 pan-div"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="LLNP•••••P"
                    name="pan"
                    // value={allValues.pan}
                    // onChange={changeHandler}
                    // error={allValues.panError}
                    // helperText={
                    //   allValues.panError ? 'Please Enter valid Pan Card' : ''
                    // }
                    // onFocus={handleFormFocus()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="..." className="panImg" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <Required
                  className="mt-5 mb-0"
                  title={<Translate value="selectYourGender" />}
                />
                <div className="gender-div">
                  <div className="button genderradio">
                    <input
                      type="radio"
                      name="gender"
                      value={personalFields.gender}
                      id="male"
                      onChange={() =>
                        handlePersonalChange({
                          target: { name: 'gender', value: 27 },
                        })
                      }
                      checked={personalFields.gender === 27}
                      disabled={isAadhaarVerified ? true : false}
                    />
                    <label htmlFor="male">
                      <img alt="..." src={male} />
                      <span>
                        <Translate value="male" />
                      </span>
                    </label>
                  </div>
                  <div className="button genderradio">
                    <input
                      type="radio"
                      name="gender"
                      value={personalFields.gender}
                      id="female"
                      onChange={() =>
                        handlePersonalChange({
                          target: { name: 'gender', value: 28 },
                        })
                      }
                      checked={personalFields.gender === 28}
                      disabled={isAadhaarVerified ? true : false}
                    />
                    <label htmlFor="female">
                      <img alt="..." src={female} />
                      <span>
                        <Translate value="female" />
                      </span>
                    </label>
                  </div>
                  <div className="button genderradio">
                    <input
                      type="radio"
                      name="gender"
                      value={personalFields.gender}
                      id="others"
                      onChange={() =>
                        handlePersonalChange({
                          target: { name: 'gender', value: 29 },
                        })
                      }
                      checked={personalFields.gender === 29}
                      disabled={isAadhaarVerified ? true : false}
                    />
                    <label htmlFor="others">
                      <img alt="..." src={radioon} />
                      <span>
                        <Translate value="others" />
                      </span>
                    </label>
                  </div>
                </div>
                <div
                  className="gender-below-border"
                  style={errors && errors.gender && errorStyles}
                ></div>
              </div>
            </Col>
            <Col xs={12} md={6} className="mt-5 personal-col-5">
              <div className="form-inner-card-right mt-0">
                {/* <p className=" mb-2">Date of Birth*</p> */}
                <Required
                  className="mb-2"
                  title={<Translate value="dateofBirth" />}
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    // onClick={() => setPickerStatus(true)}
                    // onClose={() => setPickerStatus(false)}
                    // open={allValues.openDatePicker}
                    maxDate={moment().subtract(18, 'years')}
                    minDate={moment().subtract(60, 'years')}
                    className="datepicker"
                    margin="normal"
                    format="DD/MM/YYYY"
                    value={dob && moment(dob)}
                    // value={allValues.dob}
                    // value={selectedDate}
                    // onChange={ handleDateChange }
                    // onChange={(date) => { handleDateChange(date) }}
                    onChange={(date) => {
                      setDob(date);
                    }}
                    placeholder="DD/MM/YYYY"
                    disabled={isAadhaarVerified ? true : false}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>

                {/* <p className="mt-5 mb-2">
                  <Translate value="category" />
                </p> */}
                <Required
                  className="mt-5 mb-2"
                  title={<Translate value="category" />}
                />

                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol6"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                  style={errors && errors.caste && errorStyles}
                >
                  <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={personalFields.caste || '0'}
                    name="caste"
                    displayEmpty
                    // onChange={(ev) => setCasteID(ev.target.value)}
                    onChange={handlePersonalChange}
                  >
                    <MenuItem value="0">
                      <ListItemText primary="Select Category" />
                    </MenuItem>

                    {fetchCategory &&
                      fetchCategory.length &&
                      fetchCategory.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Required
                  className="mt-4 mb-2"
                  title={<Translate value="religion" />}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                  style={errors && errors.religion && errorStyles}
                >
                  <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={personalFields.religion || '0'}
                    name="religion"
                    displayEmpty
                    onChange={handlePersonalChange}
                  >
                    <MenuItem value="0">
                      <ListItemText primary="Select Religion" />
                    </MenuItem>

                    {fetchReligion &&
                      fetchReligion.length &&
                      fetchReligion.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.description} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Required
                  className="mt-4 mb-2"
                  title={<Translate value="education" />}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                  style={errors && errors.education && errorStyles}
                >
                  <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={personalFields.education || '0'}
                    name="education"
                    displayEmpty
                    onChange={handlePersonalChange}
                  >
                    <MenuItem value="0">
                      <ListItemText primary="Select Education" />
                    </MenuItem>

                    {fetchEducation &&
                      fetchEducation.length &&
                      fetchEducation.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.description} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {users &&
                  (!users.user.inspection_id ||
                    users.user.inspection_id === 0) &&
                  personalFields.education &&
                  personalFields.education !== 16 &&
                  personalFields.education !== 17 &&
                  personalFields.education !== 18 && (
                    <>
                      <p className="mb-2 mt-3">
                        <Translate value="qualificationDoc" />*
                      </p>
                      <div className="browsebutton-outerdiv">
                        <div className="w-100 d-flex justify-content-center align-items-center">
                          <div className="w-100">
                            <FormControl
                              fullWidth
                              className="formcontrol3 w-100"
                            >
                              {/* onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                        handleEmailSubmit();
                                    }
                                }}> */}
                              <TextField
                                className="w-100"
                                variant="outlined"
                                placeholder="Click Browse to Upload"
                                name="mail_id"
                                disabled
                                value={
                                  (educationDoc && educationDoc.name) ||
                                  (educationDocID &&
                                    educationDocID.length > 0 &&
                                    educationDocID[0].file_name)
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {(educationDoc || educationDocID) && (
                                        <IconButton
                                          onClick={() => {
                                            setEducationDocID('');
                                            setEducationDoc('');
                                          }}
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="">
                            <input
                              type="file"
                              ref={educationFileRef}
                              onChange={handleEducationFileChange}
                              multiple
                              hidden
                            />
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className="py-2 mx-2"
                              startIcon={<FolderIcon />}
                              onClick={() => educationFileRef.current.click()}
                            >
                              Browse
                            </Button>
                          </div>
                        </div>
                        <div className=""></div>
                      </div>
                    </>
                  )}
              </div>
            </Col>
            <Col xs={12} md={6} className="personal-col-6 mt-4 ">
              <div className="form-inner-card-right mt-0">
                <FormControl
                  className="mt-2 mb-2 interstateRadio formcontrol9"
                  variant="outlined"
                  fullWidth
                  component="fieldset"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                  <RadioGroup
                    row
                    name="isMigrant"
                    value={isInterState}
                    onChange={(ev) => setIsInterState(ev.target.value)}
                  >
                    <p className="mt-2 mb-2" style={{ flexGrow: 1 }}>
                      <Translate value="interstateMigrantWorker" />
                    </p>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={<Translate value="yes" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={<Translate value="no" />}
                    />
                  </RadioGroup>
                </FormControl>

                {isInterState === '1' ? (
                  <>
                    <p className="mt-2 mb-2">
                      <Translate value="migrantFromState" />
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        className="select-state"
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={migrantStateID || '0'}
                        name="migrantState"
                        displayEmpty
                        onChange={(ev) => setMigrantStateID(ev.target.value)}
                      >
                        <MenuItem value="0">
                          <ListItemText primary="Select State" />
                        </MenuItem>

                        {fetchState &&
                          fetchState.length &&
                          fetchState.map(
                            (i) =>
                              i.name !== 'Karnataka' && (
                                <MenuItem value={i.id}>
                                  <ListItemText
                                    primary={i.short_name + ' | ' + i.name}
                                  />
                                </MenuItem>
                              ),
                          )}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </div>
              {
                <>
                  <div className="form-inner-card-right mt-0">
                    <FormControl
                      className="mt-2 mb-2 interstateRadio formcontrol9"
                      variant="outlined"
                      fullWidth
                      component="fieldset"
                      // onKeyPress={(event) => {
                      //   if (event.key === 'Enter') {
                      //     handleEnter();
                      //   }
                      // }}
                    >
                      {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                      <RadioGroup
                        row
                        name="isDistrict"
                        value={isInterDistrict}
                        onChange={(ev) => setIsInterDistrict(ev.target.value)}
                      >
                        <p className="mt-2 mb-2" style={{ flexGrow: 1 }}>
                          <Translate value="interstateDistict" />
                        </p>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<Translate value="yes" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={<Translate value="no" />}
                        />
                      </RadioGroup>
                    </FormControl>

                    {isInterDistrict === '1' ? (
                      <>
                        <p className="mt-2 mb-2">
                          <Translate value="migrantFromDistrict" />
                        </p>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            className="select-state"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={migrantDistrictID || '0'}
                            name="migrantDistrict"
                            displayEmpty
                            onChange={(ev) =>
                              setMigrantDistrictID(ev.target.value)
                            }
                          >
                            <MenuItem value="0">
                              <ListItemText primary="Select District" />
                            </MenuItem>

                            {fetchDistrict &&
                              fetchDistrict.length &&
                              fetchDistrict.map((i) => (
                                <MenuItem value={i.id}>
                                  <ListItemText
                                    primary={i.short_name + ' | ' + i.name}
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                </>
              }
              <div className="form-inner-card-right mt-0">
                <FormControl
                  className="mt-2 mb-2 interstateRadio formcontrol9"
                  variant="outlined"
                  fullWidth
                  component="fieldset"
                  // onKeyPress={(event) => {
                  //   if (event.key === 'Enter') {
                  //     handleEnter();
                  //   }
                  // }}
                >
                  {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                  <RadioGroup
                    row
                    name="isAyushMan"
                    value={isAyushmanHolder}
                    onChange={(ev) => setIsAyushmanHolder(ev.target.value)}
                  >
                    <p className="mt-2 mb-2" style={{ flexGrow: 1 }}>
                      <Translate value="ayushmanholder" />
                    </p>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={<Translate value="yes" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={<Translate value="no" />}
                    />
                  </RadioGroup>
                </FormControl>

                {isAyushmanHolder === '1' ? (
                  <>
                    <p className="mt-2 mb-2">
                      <Translate value="ayushmancardnumber" />
                    </p>
                    <FormControl className="formcontrol8 pan-div" fullWidth>
                      <TextField
                        // className="select-state"
                        variant="outlined"
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={personalFields.ayushManCardNumber}
                        name="ayushManCardNumber"
                        placeholder="Enter Card Number"
                        onChange={handlePersonalChange}
                        error={errors && errors.ayushManCardNumber}
                        helperText={errors && errors.ayushManCardNumber}
                      ></TextField>
                    </FormControl>
                  </>
                ) : null}
              </div>

              <div className="form-inner-card-right mt-0">
                {/* <FormControl className="mt-2 mb-2 interstateRadio formcontrol9" variant="outlined" fullWidth component="fieldset"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <RadioGroup row name="isAyushMan" value={allValues.isAyushMan} onChange={handleChange}>
                                    <p className="mt-2 mb-2" style={{ flexGrow: 1 }}><Translate value="ayushmanholder"/></p>
                                    <FormControlLabel value="1" control={<Radio />} label={<Translate value="yes"/>} />
                                    <FormControlLabel value="0" control={<Radio />} label={<Translate value="no"/>} />
                                </RadioGroup>
                            </FormControl> */}

                {personalFields.caste && personalFields.caste !== 11 && (
                  <>
                    <div className="form-inner-card-right mt-0">
                      <p className="mt-2 mb-2">
                        <Translate value="rdNumber" />
                      </p>
                      <FormControl className="formcontrol8 pan-div" fullWidth>
                        <TextField
                          variant="outlined"
                          // className="select-state"
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={personalFields.rdNumber}
                          name="rdNumber"
                          placeholder="Enter Card Number"
                          onChange={handlePersonalChange}
                        ></TextField>
                      </FormControl>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} className="note2 text-center mt-5 personal-col-7">
              {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
              <p>
                <span>
                  <Translate value="note" />{' '}
                </span>
                <Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess" />
              </p>
            </Col>
          </Row>
          <Row className="button-inside-form-row mb-5">
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center w-100"
            >
              {isEdited === 'edit' ? null : (
                <Button
                  variant="contained"
                  color="error"
                  style={{ width: '15%' }}
                  className="py-3 mx-1"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                style={{ width: '15%', whiteSpace: 'nowrap' }}
                color="success"
                className="py-3 mx-1"
                type="button"
                onClick={handleSubmitData}
              >
                {isEdited === 'edit'
                  ? 'Save your details'
                  : ' Save and continue'}
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
};

export default PersonalDetails;
