import { Add } from '@material-ui/icons';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Box,
  TablePagination,
  Table,
  TableFooter,
  Autocomplete,
} from '@mui/material';
import { Col, Row } from 'react-bootstrap';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import useApi from 'components/apiRequest/useApi';
import { apiURL } from 'http/url';
import usePostApi from 'components/apiRequest/usePostApi';
import { HOSPITAL_HEADER_KEYS, SCHEMES_UNDER_MEDICAL } from 'assets/constants';
import HospitalModal from './HospitalModal';
import DisabledHospitalList from './DisabledHospitalList';
import AddHospitalModal from './AddHospitalModal';
import { headersWithToken } from 'http/customHeaders';

export default function AdminHospitalList() {
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [modalScheme, setModalScheme] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataList, setDataList] = useState([]);
  const searchRef = useRef('');
  const [hospital, setHospital] = useState(null);
  const [filteredText, setFilteredText] = useState('');
  const {
    data: schemeList,
    loading: schemeListLoading,
    error: schemeError,
    refetch,
  } = useApi({
    url: '/schemes/get_schemes?board_id=1',
  });
  // console.log(schemeList);
  const { data, error, loading, postApiCall } = usePostApi({
    url: apiURL.hostpitalFieldValues,
    method: 'post',
    options: {
      headers: headersWithToken(),
    },
  });
  useEffect(() => {
    if (data) {
      setDataList(
        data.data.filter((item) =>
          HOSPITAL_HEADER_KEYS.includes(item.field_name),
        )[0],
      );
    }
  }, [data]);

  const handleModal = useCallback(
    (name, row = null) => {
      console.log(name);
      setHospital(row);
      if (name === 'enable') {
      } else if (name === 'disable') {
        const scheme = schemeList?.data?.find(
          (item) => item.isactive && item.id === row?.scheme_id,
        );
        setSelectedScheme(scheme);
      }
      // Then set selected scheme if row exists
      if (row) {
      }

      // Finally set the modal name
      setOpenModal(name);
    },
    [schemeList],
  );
  const clearModal = () => {
    setOpenModal(null);
    if (selectedScheme) {
      postApiCall({
        board_id: 1,
        scheme_id: selectedScheme?.id + '',
        role_id: null,
        tab_id: null,
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClear = () => {
    setHospital(null);
    setOpenModal(null);
  };

  return (
    <Box sx={{ position: 'relative', zIndex: 1000 }}>
      <Row className="userpage-row py-2 ">
        <Col lg={6} md={4} className="title">
          <p>Hospitals List</p>
        </Col>
        <Col
          lg={6}
          md={8}
          className="d-flex gap-2 align-items-center justify-content-end"
        >
          <Autocomplete
            name="schemaId"
            loading={schemeListLoading}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={
              schemeList?.data
                .filter((item) => SCHEMES_UNDER_MEDICAL.includes(item.id))
                .filter((item) => item.isactive) ?? []
            }
            sx={{ minWidth: '50%' }}
            onChange={(_, newValue) => {
              if (newValue) {
                setModalScheme(
                  schemeList.data.find((item) => item.id === newValue.id),
                );
                postApiCall({
                  board_id: 1,
                  scheme_id: newValue.id + '',
                  role_id: null,
                  tab_id: null,
                });
              } else {
                setDataList([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                placeholder={loading ? 'Loading...' : 'Select Scheme'}
                sx={{
                  mb: 2,
                  my: 'auto',
                  '.MuiInputBase-input': {
                    padding: '4px !important',
                    borderRadius: 1,
                  },
                  '& .MuiInputBase-root': {
                    px: '2px !important',
                    py: '3px !important',
                    lineHeight: 2,
                    border: '1px solid black',
                    borderRadius: 4,
                  },
                }}
                name="schemaId"
                {...params}
                variant="outlined"
              />
            )}
          />
          <Button
            className="p-2"
            variant="contained"
            color="success"
            size="lg"
            endIcon={<Add />}
            // sx={{ textWrap: 'nowrap' }}
            onClick={() => handleModal('add')}
          >
            Add Hospital
          </Button>
          <Button
            color="error"
            variant="contained"
            // sx={{ textWrap: 'nowrap' }}
            onClick={() => handleModal('disabledList')}
          >
            Disabled Hospital
          </Button>
        </Col>
      </Row>
      {data !== null && (
        <Row>
          <Col>
            <TextField
              placeholder="Search Hospitals"
              className="p-2"
              sx={{
                borderRadius: 2,
                fontSize: 18,
                '.MuiInputBase-input': {
                  py: '4px !important',
                  px: '4px !important',
                },
              }}
              style={{ width: '100%', border: '1px solid gray' }}
              inputRef={searchRef}
              onChange={(e) => setFilteredText(e.target.value)}
            />
          </Col>
        </Row>
      )}
      <Box>
        {data !== null && !loading && (
          <TableContainer sx={{ maxHeight: '62vh', zIndex: 999 }}>
            <Table
              stickyHeader
              sx={{
                minWidth: 650,
                maxHeight: '60vh',
                overflowY: 'auto',
                borderCollapse: 'collapse',
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    '.MuiTableCell-root': {
                      borderRight: '2px solid rgba(224, 224, 224, 1)',
                    },
                    '.MuiTableCell-root:last-child': {
                      borderRight: 'none' /* Prevent double borders */,
                    },
                  }}
                >
                  <TableCell size="small" className="max-w-5">
                    Sno
                  </TableCell>
                  <TableCell>Name Of Hospitals</TableCell>
                  <TableCell align="right" size="small" colSpan={1}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data.length === 0 ||
                  (dataList?.field_data?.length === 0 && (
                    <p>No Hospitals for this Scheme</p>
                  ))}
                {loading ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100vh',
                      backgroundColor: 'gray',
                    }}
                  >
                    loading...
                  </Box>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? filteredText
                        ? dataList?.field_data
                            ?.filter((item) =>
                              item.field_value
                                .toLowerCase()
                                .includes(filteredText.toLowerCase()),
                            )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                        : dataList?.field_data?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                      : dataList?.field_data
                    )?.map((row, index) => (
                      <TableRow
                        key={row.field_value_id}
                        sx={{
                          '.MuiTableCell-root': {
                            borderRight: '2px solid rgba(224, 224, 224, 1)',
                          },
                        }}
                      >
                        <TableCell align="left" size="small">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.field_value}
                        </TableCell>
                        <TableCell align="right" size="small" colSpan={1}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              handleModal('enable', row);
                            }}
                          >
                            Disable
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>

              {dataList?.field_data?.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={
                        loading
                          ? 0
                          : dataList?.field_data?.filter((item) =>
                              item.field_value
                                .toLowerCase()
                                .includes(filteredText.toLowerCase()),
                            ).length ?? 0
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        )}
      </Box>
      {openModal === 'enable' && hospital !== null && (
        <HospitalModal
          data={hospital}
          closeHandler={clearModal}
          isEnable
          schemeInfo={modalScheme}
        />
      )}
      {openModal === 'add' && (
        <AddHospitalModal
          closeHandler={clearModal}
          schemeList={schemeList ?? []}
          schemeInfo={selectedScheme}
        />
      )}

      {openModal === 'disabledList' && (
        <DisabledHospitalList
          closeHandler={clearModal}
          handleModal={handleModal}
          schemeList={
            schemeList.data.filter((item) =>
              SCHEMES_UNDER_MEDICAL.includes(item.id),
            ) ?? []
          }
        />
      )}
      {openModal === 'disable' && hospital !== null && (
        <HospitalModal
          data={hospital}
          closeHandler={clearModal}
          schemeInfo={selectedScheme}
        />
      )}
    </Box>
  );
}
