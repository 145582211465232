function convertToNullOrNumber(value) {
  if (value === undefined || value === null) {
    return 0;
  }
  if (value === 'null') {
    return 0;
  }
  if (!isNaN(value)) {
    return Number(value);
  }
  return value;
}
export default convertToNullOrNumber;

export function convertToNullOrString(value) {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    value === 'null'
  ) {
    return '';
  }
  if (typeof value === 'string') {
    return value;
  }
  return value.toString();
}

export function stringToNull(value) {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    value === 'null'
  ) {
    return null;
  }
  return value;
}
