function mergeNames(first_name, middle_name, last_name) {
  // Helper function to trim and remove extra spaces
  const cleanName = (name) => (name ? name.trim().replace(/\s+/g, ' ') : '');

  // Clean each name component
  const cleanedFirstName = cleanName(first_name);
  const cleanedMiddleName = cleanName(middle_name);
  const cleanedLastName = cleanName(last_name);

  // Concatenate the names, ensuring there are no extra spaces
  let fullName = cleanedFirstName;

  if (cleanedMiddleName) {
    fullName += ' ' + cleanedMiddleName;
  }

  if (cleanedLastName) {
    fullName += ' ' + cleanedLastName;
  }

  return fullName;
}
export default mergeNames;
