import 'date-fns';
import { FormControl, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getDistrictDetails, getStateDetails, getTalukDetails, SERVER } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { Required, getTokenCookie, showToast } from 'utils/tools';
import axios from 'axios';


const TalukMasterForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [state_details, setstate_details] = React.useState("")
    const [district_details, setdistrict_details] = React.useState("")
    const [taluk_details, settaluk_details] = React.useState("")

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        cityName: "",
        code: "",
        state:"",
        district:"",
        taluk:"",
        country: "",
        props: "",
        isEdit: false
    })


    useEffect(() => {
        
        dispatch(getStateDetails())
        
        if(props.location.rowdata !== undefined && 
            props.location.rowdata !== null){

            console.error("props.location: "+JSON.stringify(props.location))
            console.error("props.location.rowdata: "+JSON.stringify(props.location.rowdata))

            var val = props.location.rowdata

            setAllValues({
                ...allValues,
                cityName: val.name,
                code: val.code,
                state: props.location.state,
                district: props.location.district,
                taluk: props.location.taluk,
                props: props.location.rowdata,
                isEdit: true
            })   
        }
        
        
    }, []);

    useEffect(() => {
        console.log("allValues: ")
        console.log(JSON.stringify(allValues, undefined,2))
    }, [allValues]);

    useEffect(() => {
        
        var state_details = allValues.state_details
        
        if(
            users.state_details !== undefined &&
            users.state_details !== null &&
            users.state_details !== ""
            ){
                console.log("users.state_details: "+JSON.stringify(users.state_details))
                
                state_details = users.state_details

                setstate_details(users.state_details)

        }
    }, [users.state_details])

    useEffect(() => {

        if(
            allValues.state !== undefined &&
            allValues.state !== null &&
            allValues.state !== ""
            ){
                
                dispatch(getDistrictDetails(allValues.state))

        }

    }, [allValues.state])

    useEffect(() => {
        var district_details = allValues.district_details
        if(
            users.district_details !== undefined &&
            users.district_details !== null &&
            users.district_details !== ""
            ){
                console.log("users.district_details: "+JSON.stringify(users.district_details))
              
                district_details =  users.district_details

                setdistrict_details(users.district_details)
        }

    }, [users.district_details])

    useEffect(() => {

        if(
            allValues.district !== undefined &&
            allValues.district !== null &&
            allValues.district !== ""
            ){
                
                dispatch(getTalukDetails(allValues.district))

        }

    }, [allValues.district])

    useEffect(() => {
        var taluk_details = allValues.taluk_details
        if(
            users.taluk_details !== undefined &&
            users.taluk_details !== null &&
            users.taluk_details !== ""
            ){
                console.log("users.taluk_details: "+JSON.stringify(users.taluk_details))
              
                taluk_details =  users.taluk_details

                settaluk_details(users.taluk_details)
        }

    }, [users.taluk_details])

    // useEffect(() => {

    //     if(
    //         allValues.taluk !== undefined &&
    //         allValues.district !== null &&
    //         allValues.district !== ""
    //         ){
                
    //             dispatch(getTalukDetails(allValues.district))

    //     }

    // }, [allValues.taluk])

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })   
    }
    
    const resetButtonPressed = () => {
        setAllValues({
            ...allValues,
            cityName: "",
            code: "",
            state: "",
            district: "",
            taluk: "",
            country: "",
        })   
    }

    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
    }, [allValues]);

    const saveButtonPressed = () => {
        console.log("saveButtonPressed")
        if(
            allValues.cityName === "" ||
            allValues.taluk === "" ||
            allValues.code === ""
        ){
            showToast("ERROR", "Please fill all the required fields.")
        
        }
        else if(allValues.isEdit){
            var data = JSON.stringify({

                "id": allValues.props.id,
                // "district_id": allValues.district,
                "name": allValues.cityName,
                // "short_name": allValues.short_name,
                "code": allValues.code,
                "user_id": users.getDeptUserRole.data[0].department_user_id
                
              });
              
              var config = {
                method: 'post',
                url: SERVER +'/config/address/update_city',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                showToast("SUCCESS", "City Updated Successfully!")
                props.history.push("/admin/mapping-master-city")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
        else{
            var data = JSON.stringify({
                "taluk_id": allValues.taluk,
                "name": allValues.cityName,
                // "short_name": allValues.short_name,
                "code": allValues.code,
                "user_id": users.getDeptUserRole.data[0].department_user_id
              });
              
              var config = {
                method: 'post',
                url: SERVER +'/config/address/insert_city',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                showToast("SUCCESS", "City Added Successfully!")
                props.history.push("/admin/mapping-master-city")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
    }

    return (
        <>
            <div className="root">
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                    </Link>
                        <p className="title">Mapping Master {'>'} City {'>'} Add City</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1 mt-4">
                        <Required className="mb-2" title="City Name" />
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="City Name"
                                name="cityName"
                                value={allValues.cityName} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                {/* <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Taluk Short Name" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Taluk Short Name"
                                name="short_name"
                                value={allValues.short_name} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col> */}
                {/* <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Capital City" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Code"
                                name="capitalCity"
                                value={allValues.capitalCity} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col> */}
                <Col xs={12} md={4} className="personal-col-1 mt-4">
                        <Required className="mb-2" title="Code" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Code"
                                name="code"
                                value={allValues.code} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1 mt-4">
                    <Required className="mb-2" title="State" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.state}
                            name="state"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                                state_details !== undefined &&
                                state_details !== null &&
                                state_details !== "" &&
                                state_details.map((i) =>
                                    <MenuItem value={i.id}>
                                        <ListItemText primary={i.name} />
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1 mt-4">
                    <Required className="mb-2" title="District" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.district}
                            name="district"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                                district_details !== undefined &&
                                district_details !== null &&
                                district_details !== "" &&
                                district_details.map((i) =>
                                    <MenuItem value={i.id}>
                                        <ListItemText primary={i.name} />
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1 mt-4">
                    <Required className="mb-2" title="Taluk" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.taluk}
                            name="taluk"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                                taluk_details !== undefined &&
                                taluk_details !== null &&
                                taluk_details !== "" &&
                                taluk_details.map((i) =>
                                    <MenuItem value={i.id}>
                                        <ListItemText primary={i.name} />
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Col>
                
                <Col xs={12} className="next-back-button-row mt-4">

                                    <Link to="#" 
                                        onClick={resetButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            Reset
                                        </Button>
                                    </Link>

                                    <Link to="#" 
                                    onClick={saveButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="save-button">
                                            Save
                                        </Button>
                                    </Link>
                    
                </Col>


            </Row>
        </>
    )
}

export default TalukMasterForm;