// // /* eslint-disable no-throw-literal */

import uploadFileAPI from 'utils/fileupload';

// // export const backlogFuneralAccidentDeathFileUpload = async (
// //   labourCardDoc,
// //   nomineeaadhaarCardDoc,
// //   deathcertificateDoc,
// //   benificiaryaadharDoc
// // ) => {
// //   // Check if all required documents are present
// //   if (
// //     labourCardDoc &&
// //     labourCardDoc.length > 0 &&
// //     nomineeaadhaarCardDoc &&
// //     nomineeaadhaarCardDoc.length > 0 &&
// //     deathcertificateDoc &&
// //     deathcertificateDoc.length > 0 &&
// //     benificiaryaadharDoc &&
// //     benificiaryaadharDoc.length > 0
// //   ) {
// //     const promises1 = [];
// //     labourCardDoc.forEach((doc) => {
// //       promises1.push(uploadFileAPI(doc));
// //     });

// //     const promises2 = [];
// //     nomineeaadhaarCardDoc.forEach((doc) => {
// //       promises2.push(uploadFileAPI(doc));
// //     });

// //     const promises3 = [];
// //     benificiaryaadharDoc.forEach((doc) => {
// //       promises3.push(uploadFileAPI(doc));
// //     });

// //     const promises4 = [];
// //     deathcertificateDoc.forEach((doc) => {
// //       promises4.push(uploadFileAPI(doc)); // Fixed: Changed promises3 to promises4
// //     });

// //     const allPromises = [...promises1, ...promises2, ...promises3, ...promises4];

// //     return Promise.all(allPromises)
// //       .then((responses) => {
// //         const responses1 = responses.slice(0, promises1.length);
// //         const responses2 = responses.slice(
// //           promises1.length,
// //           promises1.length + promises2.length
// //         );
// //         const responses3 = responses.slice(
// //           promises1.length + promises2.length,
// //           promises1.length + promises2.length + promises3.length
// //         );
// //         const responses4 = responses.slice(
// //           promises1.length + promises2.length + promises3.length + promises4.length
// //         );

// //         const labourCardDocResponses = responses1.map((doc) => ({
// //           file_id: doc.image.fileId,
// //           file_name: doc.image.originalFileName,
// //           mime_type: doc.image.mimeType,
// //         }));

// //         const nomineeaadhaarCardDoc = responses2.map((doc) => ({
// //           file_id: doc.image.fileId,
// //           file_name: doc.image.originalFileName,
// //           mime_type: doc.image.mimeType,
// //         }));

// //         const benificiaryaadharDoc = responses3.map((doc) => ({
// //           file_id: doc.image.fileId,
// //           file_name: doc.image.originalFileName,
// //           mime_type: doc.image.mimeType,
// //         }));

// //         const deathcertificateDoc = responses4.map((doc) => ({
// //           file_id: doc.image.fileId,
// //           file_name: doc.image.originalFileName,
// //           mime_type: doc.image.mimeType,
// //         }));

// //         return {
// //           success: true,
// //           labourCardDoc: labourCardDocResponses,
// //           nomineeaadhaarCardDoc: nomineeaadhaarCardDoc,
// //           benificiaryaadharDoc: benificiaryaadharDoc,
// //           deathcertificateDoc: deathcertificateDoc,
// //         };
// //       })
// //       .catch((error) => {
// //         throw {
// //           success: false,
// //           msg: 'Error in uploading file! Please try again',
// //         };
// //       });
// //   } else {
// //     return null;
// //   }
// // };


// import uploadFileAPI from 'utils/fileupload';

// export const backlogFuneralAccidentDeathFileUpload = async (
//   labourCardDoc,
//   nomineeaadhaarCardDoc,
//   deathcertificateDoc,
//   benificiaryaadharDoc
// ) => {
//   // Check if all required documents are present
//   if (
//     labourCardDoc?.length > 0 &&
//     nomineeaadhaarCardDoc?.length > 0 &&
//     deathcertificateDoc?.length > 0 &&
//     benificiaryaadharDoc?.length > 0
//   ) {
//     const documentGroups = [
//       { docs: labourCardDoc, key: 'labourCardDoc' },
//       { docs: nomineeaadhaarCardDoc, key: 'nomineeaadhaarCardDoc' },
//       { docs: deathcertificateDoc, key: 'deathcertificateDoc' },
//       { docs: benificiaryaadharDoc, key: 'benificiaryaadharDoc' },
//     ];

//     console.log(documentGroups, '**************')
//     const allPromises = documentGroups.flatMap(({ docs }) =>
//       docs.map((doc) => uploadFileAPI(doc))
//     );

//     return Promise.all(allPromises)
//       .then((responses) => {
//         const result = {};
//         let startIndex = 0;

//         documentGroups.forEach(({ key, docs }) => {
//           const groupResponses = responses.slice(startIndex, startIndex + docs.length);
//           startIndex += docs.length;

//           result[key] = groupResponses.map((doc) => ({
//             file_id: doc.image.fileId,
//             file_name: doc.image.originalFileName,
//             mime_type: doc.image.mimeType,
//           }));
//         });

//         return {
//           success: true,
//           ...result,
//         };
//       })
//       .catch((error) => {
//         throw {
//           success: false,
//           msg: 'Error in uploading file! Please try again',
//         };
//       });
//   } else {
//     return null;
//   }
// };


export const backlogFuneralAccidentDeathFileUpload = async (
  labourCardDoc,
  // nomineeaadhaarCardDoc,
  deathcertificateDoc
  // benificiaryaadharDoc
) => {
  // Check if all required documents are present
  if (
    labourCardDoc?.length > 0 &&
    // nomineeaadhaarCardDoc?.length > 0 &&
    deathcertificateDoc?.length > 0
    // benificiaryaadharDoc?.length > 0
  ) {
    const documentGroups = [
      { docs: labourCardDoc, key: 'labourCardDoc' },
      // { docs: nomineeaadhaarCardDoc, key: 'nomineeaadhaarCardDoc' },
      // { docs: benificiaryaadharDoc, key: 'benificiaryaadharDoc' },
      { docs: deathcertificateDoc, key: 'deathcertificateDoc' }
    ];

    const allPromises = documentGroups.flatMap(({ docs }) =>
      docs.map((doc) => uploadFileAPI(doc))
    );

    return Promise.all(allPromises)
      .then((responses) => {
        const result = {};
        let startIndex = 0;

        documentGroups.forEach(({ key, docs }) => {
          const groupResponses = responses.slice(startIndex, startIndex + docs.length);
          startIndex += docs.length;

          result[key] = groupResponses.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
            mime_type: doc.image.mimeType,
          }));
        });

        return {
          success: true,
          ...result,
        };
      })
      .catch((error) => {
        throw {
          success: false,
          msg: 'Error in uploading file! Please try again',
        };
      });
  } else {
    return null;
  }
};
