import {
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import PaginationFunction from './adminPortal/Pagination';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import moment from 'moment';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreOptions from './ui/MoreOptions';

const TableComponent = ({ schemesList }) => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedScheme, setSelectedScheme] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedData, setSelectedData] = useState(null);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    console.log(data);
    setSelectedData(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idOptions = open ? 'simple-popover' : undefined;
  const users = useSelector((state) => state.users);
  const PER_PAGE = 10;

  useEffect(() => {
    if (query) {
      const keys = [
        'labour_name',
        'scheme_application_code',
        'gsc_no',
        'mobile_no',
      ];
      const result = schemesList.filter((item) =>
        keys.some((key) => {
          return (
            item['labour_name'].includes(query) ||
            item['scheme_application_code'].includes(query) ||
            item['gsc_no'].includes(query) ||
            item['mobile_no'].includes(query)
          );
        }),
      );

      setFilteredData(result);
    }
  }, [query, schemesList]);

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : schemesList && Math.ceil(schemesList.length / PER_PAGE);

  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(schemesList || [], PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const SchemStatusClicked = (labourUserId, boardId) => {
    const win = window.open(
      `/sanction-order/${labourUserId}/${boardId}`,
      '_blank',
    );
    win.focus();
  };
  // const rowDataToPass = schemesList.map(row => ({
  //   appliedDate: moment(row.applied_date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
  //   labourName: row.labour_name,
  //   schemeApplicationCode: row.scheme_application_code,
  // }));

  return (
    <>
      <Popover
        id={idOptions}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MoreOptions data={selectedData} />
      </Popover>
      <TableContainer style={{ width: '100%' }} component={Paper}>
        <div className="d-flex justify-content-evenly align-items-center m-2 gap-2">
          <div className="w-75">
            <input
              style={{ resize: 'none' }}
              className="text-center w-100 form-control"
              value={query}
              onChange={(ev) => setQuery(ev.target.value)}
              placeholder={
                'Filter by Application Number,Applicant Name,Sakala Number,Mobile Number'
              }
            />
            <p className="text-center  text-danger">
              Note: Please enter as per the details in the{' '}
              <span style={{ fontWeight: 'bold' }}>"Acknowledgment"</span>
            </p>
          </div>
          <div className="align-self-start">
            <Stack spacing={2} className="w-100">
              <Pagination
                count={count}
                size="medium"
                page={page}
                color="primary"
                variant="outlined"
                shape="circular"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Date
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Applicant Name
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Number
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Scheme Name
              </TableCell>
              {/* <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Status
              </TableCell> */}

              {/* {schemesList.some(
                (row) =>
                  row.scheme_avail_status === 'Transferred Scheme Application',
              ) && (
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Transfered To Circle
                </TableCell>
              )}

              {schemesList.some(
                (row) =>
                  row.scheme_avail_status ===
                  'Transferred back Scheme Application',
              ) && (
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Transfered Back From Circle
                </TableCell>
              )} */}
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Action Date
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                View
              </TableCell>

              {schemesList.some(
                (row) =>
                  row.scheme_avail_status === 'Approved' ||
                  row.scheme_avail_status === 'Rejected',
              ) && (
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Scheme Order Status
                </TableCell>
              )}
              {/* <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Sakala Timeline
              </TableCell> */}
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                More Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.currentData() &&
              _DATA.currentData().map((row) => (
                <TableRow key={row.scheme_transaction_id}>
                  <TableCell align="center">
                    {moment(row.applied_date, 'YYYY/MM/DD').format(
                      'DD/MM/YYYY',
                    )}
                  </TableCell>
                  <TableCell align="center">{row.labour_name}</TableCell>
                  <TableCell align="center">
                    {row.scheme_application_code}
                  </TableCell>
                  <TableCell align="center">{row.scheme_name}</TableCell>
                  {/* <TableCell align="center">
                    {row.scheme_avail_status}
                  </TableCell> */}
                  {/* 
                  {row.scheme_avail_status ===
                    'Transferred Scheme Application' && (
                    <TableCell align="center">
                      {row.transfer_to_circle_name}
                    </TableCell>
                  )}

                  {row.scheme_avail_status ===
                    'Transferred back Scheme Application' && (
                    <TableCell align="center">{row.from_circle_name}</TableCell>
                  )} */}

                  <TableCell align="center">
                    {row?.scheme_avail_status === 'Pending' &&
                      (moment(row?.applied_date, 'YYYY/MM/DD').format(
                        'DD/MM/YYYY',
                      ) ??
                        'N/A')}
                    {row?.scheme_avail_status === 'Forwarded' &&
                      (row?.forwarded_date
                        ? moment(
                            row?.forwarded_date,
                            'YYYY-MM-DD hh:mm:ss A',
                          ).format('DD/MM/YYYY hh:mm:ss A')
                        : 'N/A')}
                    {row?.scheme_avail_status ===
                      'Transferred Scheme Application' &&
                      (row?.transferred_date
                        ? moment(
                            row?.transferred_date,
                            'YYYY-MM-DD hh:mm:ss A',
                          ).format('DD/MM/YYYY hh:mm:ss A')
                        : 'N/A')}
                    {row?.scheme_avail_status ===
                      'Transferred back Scheme Application' &&
                      (row?.transferred_back_date
                        ? moment(
                            row?.transferred_back_date,
                            'YYYY-MM-DD hh:mm:ss A',
                          ).format('DD/MM/YYYY hh:mm:ss A')
                        : 'N/A ')}

                    {(row?.scheme_avail_status === 'Draft Approved' ||
                      row?.scheme_avail_status === 'Draft Rejected') &&
                      (row?.draft_approved_draft_rejected_date
                        ? moment(
                            row?.draft_approved_draft_rejected_date,
                            'YYYY-MM-DD hh:mm:ss A',
                          ).format('DD/MM/YYYY hh:mm:ss A') ?? 'N/A'
                        : 'N/A')}
                    {(row?.scheme_avail_status === 'Approved' ||
                      row?.scheme_avail_status === 'Rejected') &&
                      (row?.approved_rejected_date
                        ? moment(
                            row?.approved_rejected_date,
                            'YYYY-MM-DD hh:mm:ss A',
                          ).format('DD/MM/YYYY hh:mm:ss A') ?? 'N/A'
                        : 'N/A')}
                  </TableCell>
                  <TableCell align="center">
                    {row.scheme_avail_status === 'Pending' && (
                      <>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Link
                            // to={{
                            //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Pending'}/${row.scheme_name}`,
                            //   userid: row.labour_user_id,
                            //   showbuttons: true,
                            //   scheme_avail_id: row.scheme_avail_id,
                            //   schemeDetails: row,
                            // }}
                            // onClick={handleLinkClick}
                            to={
                              id === '3'
                                ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                                : id === '17'
                                  ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                                  : null
                            }
                          >
                            <img
                              alt="..."
                              src={Eye}
                              className="mr-2"
                              style={{ marginRight: '8px' }}
                            />
                          </Link>
                        </Suspense>
                      </>
                    )}
                    {row.scheme_avail_status === 'Approved' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Approved'}/${row.scheme_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: false,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}

                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}

                    {row.scheme_avail_status === 'Forwarded' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row?.scheme_id}/${row?.labour_user_id}/${'Forwarded'}/${row?.scheme_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: true,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}

                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.scheme_avail_status === 'Rejected' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Rejected'}/${row.scheme_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: false,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}

                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.scheme_avail_status ===
                      'Transferred Scheme Application' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Transfered Scheme Application'}/${row.scheme_name}/${row.transfer_to_circle_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: true,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}

                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.scheme_avail_status ===
                      'Transferred back Scheme Application' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}}/${row?.labour_user_id}/${'Transfered Back Scheme Application'}/${row.scheme_name}/${row.from_circle_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: true,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}
                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.scheme_avail_status === 'Draft Approved' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Draft Approved'}/${row.scheme_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: true,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}

                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.scheme_avail_status === 'Draft Rejected' && (
                      <Link
                        // to={{
                        //   pathname: `/admin/scheme-details/${row.scheme_id}/${row.labour_user_id}/${'Draft Rejected'}/${row.scheme_name}`,
                        //   userid: row.labour_user_id,
                        //   showbuttons: true,
                        //   scheme_avail_id: row.scheme_avail_id,
                        //   schemeDetails: row,
                        // }}
                        to={
                          id === '3'
                            ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}`
                            : id === '17'
                              ? `/admin/schemeDetails/transaction/${row.labour_user_id}/${row.scheme_id}/${row.scheme_name}/${row.scheme_avail_id}/${row.scheme_transaction_id}/${row.scheme_avail_status}/${row.is_approval_authority}/${row.renewal_sequence_year}`
                              : null
                        }
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                  </TableCell>

                  {(row.scheme_avail_status === 'Approved' ||
                    row.scheme_avail_status === 'Rejected') && (
                    <TableCell align="center">
                      <Link
                        to={
                          row.scheme_avail_status === 'Approved'
                            ? `/view/scheme-sanction-order/${row.scheme_name}/${row.labour_user_id}/${row.scheme_avail_id}`
                            : row.scheme_avail_status === 'Rejected'
                              ? `/view/scheme-rejection-order/${row.scheme_name}/${row.labour_user_id}/${row.scheme_avail_id}`
                              : null
                        }
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    </TableCell>
                  )}

                  {/* <TableCell align="center">
                    <div className="d-flex justify-content-center align-items-center align-self-center w-100">
                      <p className="text-center">
                        {row.sla_days} days left for Approval
                      </p>
                      <img
                        className="img-sakala align-self-start"
                        alt="..."
                        src={Sakala}
                      />
                    </div>
                  </TableCell> */}

                  <TableCell
                    style={{ color: 'black', fontWeight: 'bolder' }}
                    align="center"
                  >
                    <IconButton onClick={(ev) => handleClick(ev, row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableComponent;
