
import React, { useState } from 'react'
import Header from './Header'
import accident from 'assets/images/Accident-01.svg'
import Medical2 from 'assets/images/Medical assistance-01.svg'
import pregnant from 'assets/images/images-02.svg'
import Funeral from 'assets/images/Funeral and EX -Gratia.png'
import { LabourSchemesList } from './schemesList';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg'
import { setLocaleWithFallback } from 'store/actions/user.actions'
import { Col, Row } from 'react-bootstrap'
import education from 'assets/images/Education Assitance-01.svg'
import Header2 from './Header2'
const LabourschemesHomePage = () => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.users)
  const i18nlang = useSelector(state => state.i18n)

  const [allValues, setAllValues] = useState({
      language: users.profile_details.language,
      users: users.profile_details.users,
  })
  const handleChange1 = (e) => {
      console.log("kkkkk", e.target.value)
      if (e.target.value === "ka") {

          setAllValues({
              ...allValues,
              descriptionsKannada: true,
              [e.target.name]: e.target.value,

          })

      }
      else {
          setAllValues({
              ...allValues,
              descriptionsKannada: false,
              [e.target.name]: e.target.value,

          })
      }


      if (e.target.name === "language")
          dispatch(setLocaleWithFallback(e.target.value))
  }
  return (
    <div>
    <Header2 lang={allValues.language}/>
    <div className='d-flex justify-content-end container-fluid'>
        <Col xs={12} md={4} lg={3} className="d-flex justify-content-end mt-2 me-2">
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange1}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        </div>
   <Row className='container-fluid'>
    <div className='schemes-home-div container-fluid m-auto ps-4'>
        <h3 className='schemesHome-title container-fluid'><Translate value='klwbTitle1'/> <Translate value='klwbTitle2'/></h3>
        <p className='schemes-instructions container-fluid'>
          <Translate value='klwbcontent'/>                              
         </p>
         <h5 className='schemes-instructions container-fluid'><Translate value='klwbnote'/></h5>
        <p className='schemes-instructions container-fluid'>
        1. <Translate value='klwbpoint1'/>                               
        </p>
        <p className='schemes-instructions container-fluid'>
        2. <Translate value='klwbpoint2'/>
        </p>
        <p className='schemes-instructions container-fluid'>
        3. <Translate value='klwbpoint3'/>
        </p>
        <h3 className='schemesHome-title container-fluid'><Translate value='schemeTranslate'/></h3>
    </div>
    <div className='schemes-list-div'>
    <div className=''>
    <Row className="">
    <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
        <Link to='/education2'>
        <div className='mapping-card schemes'>
        <img src={education} alt='...' />
            <p><Translate value='laboureducationTitle'/></p>
        </div>
        </Link>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes"   onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
        <Link to='/medical2'>
        <div className='mapping-card schemes'>
        <img src={Medical2} alt='...' />
            <p><Translate value='medicalTitle'/></p>
        </div>
        </Link>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes "   onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
        <Link to='/accident2'>
        <div className='mapping-card schemes'>
        <img src={accident} alt='...'/>
           <p><Translate value='labouraccidentTitle'/></p> 
        </div>
        </Link>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes "   onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
        <Link to='/delivery2'>
        <div className='mapping-card schemes'>
        <img src={pregnant} alt='...' />
            <p><Translate value='labourdeliveryTitle'/></p>
        </div>
        </Link>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes "   onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
        <Link to='/funeral2'>
        <div className='mapping-card schemes'>
        <img src={Funeral} alt='...' />
            <p><Translate value='labourfuneralTitle'/></p>
        </div>
        </Link>
        </Col>
        {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/medicalcamps'>
        <div className='mapping-card schemes'>
        <img src={medicalcamp} alt='...' />
            <p>Medical Check-up Camps</p>
        </div>
        </Link>
        </Col> */}
        {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/sports'>
        <div className='mapping-card schemes'>
        <img src={sports} alt='...' />
            <p>Annual Sports Activities</p>
        </div>
        </Link>
        </Col> */}
        
    </Row>
    
        
    </div>                                  
    
    </div>
    <div className='d-flex justify-content-end mt-5 me-5 mb-5'>
    <Link to='/'>
    <Button className='back-btn-mainScreens'><ArrowBackIosIcon color='secondary' className='back-icon'/><Translate value='backbtn'/></Button>
    </Link>
    </div>
    </Row>
    </div>
  )
}

export default LabourschemesHomePage;