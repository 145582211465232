import 'date-fns';
import { withStyles, Menu,Grid, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, TableFooter, TablePagination, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import prev from 'assets/images/Icon ionic-ios-arrow-dropright-circle.svg'
import next from 'assets/images/Icon ionic-ios-arrow-dropright-circle (1).svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigMinistryDetails, getLabourStatusDetails, getStateMapping, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Pagination, PaginationItem } from '@material-ui/lab';


const MinistryMaster = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })

    const [rows, setrows] = React.useState("");


    useEffect(() => {
        // console.log("in useEffect [users.user]")
            dispatch(getStateMapping())
    }, []);
    
    useEffect(() => {
        if(
            users.getStateMapping !== undefined &&
            users.getStateMapping !== null ){
                setrows(users.getStateMapping.data)
            }
    }, [users.getStateMapping]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const useStyles1 = makeStyles((theme) => ({
        root: {
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        },
      }));

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;
      
        // const handleFirstPageButtonClick = (event) => {
        //   onChangePage(event, 0);
        // };
      
        const handleBackButtonClick = (event) => {
          onChangePage(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onChangePage(event, page + 1);
        };
      
        // const handleLastPageButtonClick = (event) => {
        //   onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        // };
      
        return (
          <div className={classes.root}>
            {/* <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton> */}
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {/* <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton> */}
          </div>
        );
      }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <a href="/admin/mapping-master-state-form">
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add State</p>
                            </div>
                            </a>
                        </Col>
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                        {/* <Link to="/admin/home"> */}
                        <Link to="#" onClick={() => props.history.goBack()}>
                            <img alt="..." className="cal-icon hover-icn" src={back}/>
                        </Link>
                            <p className="title-p">Mapping Master {'>'} State</p>
                    </Col>
                </Row>
                <Row className="config-page">
                    
                    <Col xs={12} className="table-col">
                        <Grid xs={12}>
                        <TableContainer 
                    // component={Paper}
                    >
                        <Table 
                            className="mt-4 config-table"
                            aria-label="simple table"
                            >
                             <TableHead>
                                <TableRow>
                                    <TableCell align="right" width="190px">
                                        <Link to="/admin/home">
                                            <img alt="..." className="cal-icon hover-icn" src={home}/>
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left" width="240px">State Name</TableCell>
                                    <TableCell align="center">State Short Name</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                    <TableCell width="200pxpx"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                rows !== "" &&
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        {/* <TableCell></TableCell> */}
                                        <TableCell></TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="center">{row.short_name}</TableCell>
                                        <TableCell align="center">
                                            <Link to ={{ 
                                                    pathname: "/admin/mapping-master-state-form", 
                                                    rowdata: row
                                                    }}
                                                className="td-link"
                                            >
                                                <img alt="..." src={Edit} className="mr-2"/> Edit
                                            </Link>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                           
                            <TableFooter>
                                {/* <TableRow className="table-pagination-footer-row">
                                    <TableCell colSpan="3">
                                        <img alt="..." src={prev} className="mr-2"/>
                                        <p>Page 1 of 4</p>
                                        <img alt="..." src={next} className="mr-2"/>
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TablePagination
                                    className="table-pagination-footer"
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    // colSpan={6}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    // SelectProps={{
                                    //     inputProps: { 'aria-label': 'rows per page' },
                                    //     // native: true,
                                    // }}
                                    labelRowsPerPage={""}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    />
                                    
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                        </Grid>

                   
                    </Col>
                            
                </Row>
        </>
    )
}

export default MinistryMaster;