import React from 'react';
import { Button } from 'react-bootstrap';

const DraftApprove = ({ handleDraftTransaction, loading }) => {
  return (
    <Button
      onClick={() => handleDraftTransaction('DraftApprove')}
      variant="outline-primary"
      className="final-button-accept"
      disabled={loading}
    >
      Draft Approve
    </Button>
  );
};

export default DraftApprove;
