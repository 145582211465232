import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER, getSchemesOnStatus } from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';

import TableComponentRegistration from 'components/TableComponentRegistration';

const RegistrationList = () => {
  const [registrationCatalog, setRegistrationCatalog] = useState(null);
  const [appStatus, setAppStatus] = useState(null);
  const [getUserMappedToCircle, setgetUserMappedToCircle] = useState(null);
  const [circle, setCirlce] = useState(null);
  const [getSchemes, setGetSchemes] = useState(null);
  const [schemes, setSchemes] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [appStatusBasedOnSearch, setAppStatusBasedOnSearch] = useState(null);

  const [registrationList, setRegistrationList] = useState(null);
  const [loading, setLoading] = useState(false);

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    const payLoad = JSON.stringify({
      board_id: users.getDeptUserRole.data[0].board_id,
      role_id: users.getDeptUserRole.data[0].role_id,
      department_user_id: users.getDeptUserRole.data[0].department_user_id,
    });
    axios
      .post(`${SERVER}/config/circle/get_user_mapped_to_circle`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        setgetUserMappedToCircle(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Registration Status',
    };

    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setRegistrationCatalog(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/schemes/get_schemes?board_id=1`)
      .then((res) => {
        const { data } = res.data;

        setGetSchemes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  const handleSchemeSearch = () => {
    if (appStatus) {
      setLoading(true);
      const payLoad = {
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        board_id: 1,
        registration_status_id: appStatus,
        // days: 1,
        is_date_selected: isDateSelected,
        start_date: startDate,
        end_date: endDate,
        acck_no: null,
        mobile_no: null,
        aadhaar_no: null,
      };

      setAppStatusBasedOnSearch(payLoad.registration_status_id);

      axios
        .post(`${SERVER}/admin/get-labours-status-details`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then((res) => {
          const { data } = res.data;

          //   dispatch(getSchemesOnStatus(payLoad));
          const updatedRegistrationList = data.map((data) => ({
            ...data,
            labour_full_name: data.labour_full_name.toUpperCase().trim(),
            gsc_no: data.gsc_no ?? 'N/A',
            application_no: data.application_no ?? 'N/A',
          }));
          setRegistrationList(updatedRegistrationList);
          setLoading(false);
        });
    } else {
      showToast('ERROR', 'Please select registration status');
    }
  };

  return (
    <div className="">
      {/* <h3 className="text-center">Schemes Details</h3> */}
      <div className="d-flex justify-content-center align-items-center flex-column gap-1">
        <Row className="w-100">
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={appStatus || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setAppStatus(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Registration Status" />
              </MenuItem>
              {registrationCatalog &&
                registrationCatalog.length &&
                registrationCatalog.map(
                  (data) =>
                    (data.value === 'Pending' ||
                      data.value === 'Rejected' ||
                      data.value === 'Approved' ||
                      data.value === 'Forwarded' ||
                      data.value === 'Edit Profile Request Approved' ||
                      data.value === 'Edit Profile Request Pending' ||
                      data.value === 'Draft Approved' ||
                      data.value === 'Draft Rejected') && (
                      <MenuItem value={data.value_id}>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ),
                )}
            </Select>
          </Col>

          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                placeholder="Start Date"
                //   disabled={!showFilters ? true : false}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setStartDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {startDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                placeholder="End Date"
                //   disabled={!showFilters ? true : false}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setEndDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {endDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>

          <Col xs={12} md={2}>
            <Button
              onClick={handleSchemeSearch}
              className="w-100 btn btn-success py-2"
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs={12} md={6}></Col>
        </Row>
      </div>
      {loading && <SimpleBackdrop />}
      {!loading && registrationList && registrationList.length ? (
        <TableComponentRegistration
          registrationList={registrationList}
          appStatus={appStatusBasedOnSearch}
        />
      ) : !loading && registrationList && registrationList ? (
        <p className="text-center fs-3">No Applications Found</p>
      ) : null}
    </div>
  );
};

export default RegistrationList;
