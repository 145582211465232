// import register from "assets/images/register-01.svg";
// import { Col, Row } from "react-bootstrap";
// import { Link, useHistory } from "react-router-dom";

// const BacklogDashboard = () => {
//   return (
//     <>
//       <Row className="userpage-row">

//         <Col xs={0} sm={0} md={1} className="blankcol"></Col>
//         <Col
//           xs={6}
//           sm={6}
//           md={2}
//           className="dashboard-registration-col"
//         >
//           <Link
//             style={{ color: "black" }}
//             to={{
//               pathname: "/admin/dashboard/BacklogFormFuneral",
//               state: { backlog: 'FuneralAssistance' }
//             }}
//           >
//             <div className="dashboard-registration-div ">
//               <div>
//                 <p>
//                   <br />
//                   Funeral Assistance

//                 </p>
//                 <div>
//                   <img alt="..." src={register} />
//                 </div>
//               </div>

//             </div>
//           </Link>
//         </Col>

//         <Col
//           xs={6}
//           sm={6}
//           md={2}
//           className="dashboard-registration-col"
//         >
//           <Link
//             style={{ color: "black" }}
//             to={{
//               pathname: "/admin/dashboard/backlogformaccidentDeath",
//               state: { backlog: 'DeathAccidentAssistance' }
//             }}
//           >
//             <div className="dashboard-registration-div-approved ">
//               <div>
//                 <p>
//                   <br />
//                   Accidental Assistance
//                 </p>
//                 <div>
//                   <img alt="..." src={register} />
//                 </div>
//               </div>

//             </div>
//           </Link>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default BacklogDashboard;


// "use client"

// import { useState } from "react"
// import { Col, Row, Button } from "react-bootstrap"
// import { useHistory } from "react-router-dom"
// import { Popover } from "@material-ui/core"
// import register from "assets/images/register-01.svg"

// const BacklogDashboard = () => {
//   const history = useHistory()
//   const [funeraldetails, setFuneraldetails] = useState(false)
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [selectedScheme, setSelectedScheme] = useState("")

//   // Handle click on any tile
//   const handleTileClick = (event, scheme) => {
//     setAnchorEl(event.currentTarget)
//     setSelectedScheme(scheme)
//     setFuneraldetails(true)
//   }

//   // Apply button handler
//   const applyButtonPressed = () => {
//     setFuneraldetails(false)
//     setAnchorEl(null)

//     // Navigate based on which scheme was selected
//     if (selectedScheme === "funeral") {
//       history.push("/admin/dashboard/BacklogFormFuneral", { backlog: "FuneralAssistance" })
//     } else if (selectedScheme === "accident") {
//       history.push("/admin/dashboard/backlogformaccidentDeath", { backlog: "DeathAccidentAssistance" })
//     }
//   }

//   // Close the popover
//   const handleClose = () => {
//     setFuneraldetails(false)
//     setAnchorEl(null)
//   }

//   const id1 = funeraldetails ? "simple-popover" : undefined

//   // Inline styles for the popover
//   const popoverStyles = {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     zIndex: 9999,
//   }

//   const popoverContentStyles = {
//     width: "600px",
//     maxWidth: "90vw",
//   }

//   return (
//     <>
//       <Row className="userpage-row">
//         <Col xs={0} sm={0} md={1} className="blankcol"></Col>
//         <Col xs={6} sm={6} md={2} className="dashboard-registration-col">
//           <div style={{ color: "black", cursor: "pointer" }} onClick={(e) => handleTileClick(e, "funeral")}>
//             <div className="dashboard-registration-div">
//               <div>
//                 <p>
//                   <br />
//                   Funeral Assistance
//                 </p>
//                 <div>
//                   <img alt="..." src={register || "/placeholder.svg"} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Col>

//         <Col xs={6} sm={6} md={2} className="dashboard-registration-col">
//           <div style={{ color: "black", cursor: "pointer" }} onClick={(e) => handleTileClick(e, "accident")}>
//             <div className="dashboard-registration-div-approved">
//               <div>
//                 <p>
//                   <br />
//                   Accidental Assistance
//                 </p>
//                 <div>
//                   <img alt="..." src={register || "/placeholder.svg"} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Col>

//         {/* Popover that appears in the center of the screen */}
//         {funeraldetails ? (
//           <Popover
//             id={id1}
//             open={funeraldetails}
//             anchorEl={anchorEl}
//             onClose={handleClose}
//             className="funeral-popover"
//             anchorOrigin={{
//               vertical: "center",
//               horizontal: "center",
//             }}
//             transformOrigin={{
//               vertical: "center",
//               horizontal: "center",
//             }}
//             style={popoverStyles}
//             PaperProps={{
//               style: popoverContentStyles,
//             }}
//           >
//             <p style={{ marginLeft: "15px", marginRight: "15px" }} sx={{ p: 5 }}>
//               {" "}
//               <br></br>In case of death due to accident kindly make sure to avail the "Accident Assistance" scheme and
//               the "Medical Assistance" <br></br> scheme before availing the "Funeral Assistance" scheme as the user will
//               be deactivated after the approval of the <br></br> "Funeral Assistance" scheme and hence the user will not
//               be able to avail the benefits from the other two schemes. <br></br> <br></br>
//               ಅಪಘಾತದಿಂದ ಮರಣ ಸಂಭವಿಸಿದಲ್ಲಿ "ಅಪಘಾತ ನೆರವು" ಯೋಜನೆ ಮತ್ತು "ವೈದ್ಯಕೀಯ ನೆರವು" ಪಡೆದುಕೊಳ್ಳುವುದನ್ನು <br></br>ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ. "ಅಂತ್ಯಕ್ರಿಯೆಯ
//               ನೆರವು" ಯೋಜನೆಯನ್ನು ಪಡೆಯುವ ಮೊದಲು ಯೋಜನೆಯು ಅನುಮೋದನೆಯ ನಂತರ ು<br></br> ಬಳಕೆದಾರರನ್ನನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತದೆ "ಅಂತ್ಯಕ್ರಿಯೆಯ ನೆರವು" ಯೋಜನೆ ಮತ್ತು
//               ಅದರಿಂದ ಬಳಕೆದಾರರಿಗೆ ಇತರ ಎರಡುು<br></br> ಯೋಜನೆಗಳಿಂದ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. <br></br> <br></br>
//             </p>
//             <Button
//               style={{
//                 marginLeft: "320px",
//                 marginRight: "10px",
//                 marginBottom: "2px",
//               }}
//               onClick={() => applyButtonPressed()}
//             >
//               Apply
//             </Button>
//             <Button
//               variant="outline-primary"
//               style={{ marginBottom: "2px" }}
//               onClick={handleClose} // Changed to simply close the popup
//             >
//               Back
//             </Button>
//           </Popover>
//         ) : null}
//       </Row>
//     </>
//   )
// }

// export default BacklogDashboard



"use client"

import { useState } from "react"
import { Col, Row, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { Popover } from "@material-ui/core"
import register from "assets/images/register-01.svg"

const BacklogDashboard = () => {
  const history = useHistory()
  const [funeralPopupOpen, setFuneralPopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  // Handle click on Funeral Assistance tile
  const handleFuneralClick = (event) => {
    setAnchorEl(event.currentTarget)
    setFuneralPopupOpen(true)
  }

  // Direct navigation for Accident Assistance
  const navigateToAccident = () => {
    history.push("/admin/dashboard/backlogformaccidentDeath", { backlog: "DeathAccidentAssistance" })
  }

  // Apply button handler for Funeral Assistance
  const applyFuneral = () => {
    setFuneralPopupOpen(false)
    setAnchorEl(null)
    history.push("/admin/dashboard/BacklogFormFuneral", { backlog: "FuneralAssistance" })
  }

  // Close the popover
  const handleClose = () => {
    setFuneralPopupOpen(false)
    setAnchorEl(null)
  }

  const id1 = funeralPopupOpen ? "simple-popover" : undefined

  // Inline styles for the popover
  const popoverStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  }

  const popoverContentStyles = {
    width: "600px",
    maxWidth: "90vw",
  }

  return (
    <>
      <Row className="userpage-row">
        <Col xs={0} sm={0} md={1} className="blankcol"></Col>
        <Col xs={6} sm={6} md={2} className="dashboard-registration-col">
          <div style={{ color: "black", cursor: "pointer" }} onClick={handleFuneralClick}>
            <div className="dashboard-registration-div">
              <div>
                <p>
                  <br />
                  Funeral Assistance
                </p>
                <div>
                  <img alt="..." src={register || "/placeholder.svg"} />
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={6} sm={6} md={2} className="dashboard-registration-col">
          <div style={{ color: "black", cursor: "pointer" }} onClick={navigateToAccident}>
            <div className="dashboard-registration-div-approved">
              <div>
                <p>
                  <br />
                  Accidental Assistance
                </p>
                <div>
                  <img alt="..." src={register || "/placeholder.svg"} />
                </div>
              </div>
            </div>
          </div>
        </Col>

        {/* Popover only for Funeral Assistance */}
        {funeralPopupOpen ? (
          <Popover
            id={id1}
            open={funeralPopupOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            className="funeral-popover"
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            style={popoverStyles}
            PaperProps={{
              style: popoverContentStyles,
            }}
          >
            <p style={{ marginLeft: "15px", marginRight: "15px" }} sx={{ p: 5 }}>
              {" "}
              <br></br>In case of death due to accident kindly make sure to avail the "Accident Assistance" scheme<br></br> scheme before availing the "Funeral Assistance" scheme as the user will
              be deactivated after the approval of the <br></br> "Funeral Assistance" scheme and hence the user will not
              be able to avail the benefits from the other schemes. <br></br> <br></br>
              ಅಪಘಾತದಿಂದ ಮರಣ ಸಂಭವಿಸಿದಲ್ಲಿ "ಅಪಘಾತ ನೆರವು" ಯೋಜನೆ ಪಡೆದುಕೊಳ್ಳುವುದನ್ನು <br></br>ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ. "ಅಂತ್ಯಕ್ರಿಯೆಯ
              ನೆರವು" ಯೋಜನೆಯನ್ನು ಪಡೆಯುವ ಮೊದಲು ಯೋಜನೆಯು ಅನುಮೋದನೆಯ ನಂತರ ು<br></br> ಬಳಕೆದಾರರನ್ನನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತದೆ "ಅಂತ್ಯಕ್ರಿಯೆಯ ನೆರವು" ಯೋಜನೆ ಮತ್ತು
              ಅದರಿಂದ ಬಳಕೆದಾರರಿಗೆ ಇತರ<br></br> ಯೋಜನೆಗಳಿಂದ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. <br></br> <br></br>
            </p>
            <Button
              style={{
                marginLeft: "320px",
                marginRight: "10px",
                marginBottom: "2px",
              }}
              onClick={applyFuneral}
            >
              Apply
            </Button>
            <Button variant="outline-primary" style={{ marginBottom: "2px" }} onClick={handleClose}>
              Back
            </Button>
          </Popover>
        ) : null}
      </Row>
    </>
  )
}

export default BacklogDashboard

