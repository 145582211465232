import {
    LOGIN_USER,
    GENERATE_OTP,
    ERROR_LOGIN,
    ERROR_GENERATE_OTP,
    UPDATE_USER_PROFILE,
    GET_MINISTRY,
    GET_BOARD,
    GENERATE_OTP_REGISTRATION,
    ERROR_GENERATE_OTP_REGISTRATION,
    REGISTER_USER,
    ERROR_REGISTER,
    UPDATE_PERSONAL,
    USER,
    ERROR_GET_USER,
    CATALOG_DETAILS,
    ERROR_CATALOG_DETAILS,
    STATE_DETAILS,
    ERROR_STATE_DETAILS,
    UPLOAD_FILE,
    ERROR_UPLOAD_FILE,
    ADD_LABOUR_PERSONAL,
    ERROR_ADD_LABOUR_PERSONAL,
    UPDATE_ADDRESS,
    DISTRICT_DETAILS,
    ERROR_DISTRICT_DETAILS,
    TALUK_DETAILS,
    ERROR_TALUK_DETAILS,
    CITY_DETAILS,
    ERROR_CITY_DETAILS,
    VILLAGE_DETAILS,
    ERROR_VILLAGE_DETAILS,
    DISTRICT_DETAILS2,
    ERROR_DISTRICT_DETAILS2,
    TALUK_DETAILS2,
    ERROR_TALUK_DETAILS2,
    CITY_DETAILS2,
    ERROR_CITY_DETAILS2,
    VILLAGE_DETAILS2,
    ERROR_VILLAGE_DETAILS2,
    ADD_LABOUR_ADDRESS,
    ERROR_ADD_LABOUR_ADDRESS,
    UPDATE_FAMILY,
    ADD_LABOUR_FAMILY,
    ERROR_ADD_LABOUR_FAMILY,
    ADD_LABOUR_BANK,
    ERROR_ADD_LABOUR_BANK,
    ADD_LABOUR_90DAYS,
    ERROR_ADD_LABOUR_90DAYS,
    UPDATE_90DAYS,
    UPDATE_BANK,
    GET_INSPECTOR,
    ERROR_GET_INSPECTOR,
    GET_UNION,
    ERROR_GET_UNION,
    UPDATE_PROFILE,
    GET_APPLICATION_NO,
    ERROR_GET_APPLICATION_NO,
    UPDATE_UPLOAD_FILE,
    UPDATE_Review,
    GET_USER_REGISTRATION_DETAILS,
    ERROR_GET_USER_REGISTRATION_DETAILS,
    GET_RENEWAL_LABOUR_DETAILS,
    ERROR_GET_RENEWAL_LABOUR_DETAILS,
    GET_FILE,
    ERROR_GET_FILE,
    LOGIN_ADMIN,
    ERROR_LOGIN_ADMIN,
    GET_LABOUR_STATUS,
    ERROR_GET_LABOUR_STATUS,
    FINAL_SUBMIT,
    ERROR_FINAL_SUBMIT,
    SAVE_ACK_ID,
    ERROR_SAVE_ACK_ID,
    UPDATE_GET_FILE,
    UPDATE_USER,
    GET_CONFIG_MINISTRY_DETAILS,
    ERROR_GET_CONFIG_MINISTRY_DETAILS,
    GET_CONFIG_BOARD_DETAILS,
    ERROR_GET_CONFIG_BOARD_DETAILS,
    GET_STATE_MAPPING,
    ERROR_GET_STATE_MAPPING,
    SEARCH_USER,
    ERROR_SEARCH_USER,
    GET_SCHEMES,
    ERROR_GET_SCHEMES,
    GET_SCHEMES_ON_STATUS,
    ERROR_GET_SCHEMES_ON_STATUS,
    GET_DEPT_USER_ROLE,
    ERROR_GET_DEPT_USER_ROLE,
    GET_DEPT_USERS,
    ERROR_GET_DEPT_USERS,
    INSERT_DEPT_USER,
    ERROR_INSERT_DEPT_USER,
    UPDATE_ADMIN_DASHBOARD
} from '../types';



let DEFAULT_USER_STATE = {
    user: null,
    catalog_details: null,
    login_user: null,
    login_admin: null,
    generate_otp: null,
    get_ministry: null,
    get_board: null,
    generate_otp_registration: null,
    register_user: null,
    state_details: null,
    district_details: null,
    taluk_details: null,
    city_details: null,
    village_details: null,
    district_details2: null,
    taluk_details2: null,
    city_details2: null,
    village_details2: null,
    upload_file: null,
    addLabourPersonalDetails: null,
    addLabourAddressDetails: null,
    addLabourFamilyDetails: null,
    addLabourBankDetails: null,
    addLabour90DaysCertDetails: null,
    inspectorDetails: null,
    unionDetails: null,
    personal_details: {
        selectedDate: null,
        phoneNumber: "",
        maritalStatus: "",
        category: "",
        migrantState: "",
        isMigrant: false,
        pancheck: false,
        aadharcheck: false,
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,
        formcontrolnum: 1,
        aadharnum1: null,
        aadharnum2: null,
        aadharnum3: null,
        aadhar: null,
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        fatherHusbandName: "",
        pan: "",
        gender: "",
        dob: null,
        documentUploadResponse: "",
        aadhaarFetchedData: null
    },
    address_details: {
        residentialData: {
            residenceType: "",
            streetRoad: "",
            city: "",
            district: "",
            landmark: "",
            houseBuilding: "",
            areaVillage: "",
            taluk: "",
            state: "",
            pincode: "",
        },
        addressSameCheckbox: false,
        permanentData: {
            residenceType: "",
            streetRoad: "",
            city: "",
            district: "",
            landmark: "",
            houseBuilding: "",
            areaVillage: "",
            taluk: "",
            state: "",
            pincode: "",
        },
    },
    family_details: {
        rationCardNo: "",
        rationCardType: "",
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,

        rationDocumentName: "",

        formcontrolnum: 1,

        membersList: [{
            "id": 0,
            "relation0": "",
            "firstname0": "",
            "middlename0": "",
            "lastname0": "",
            "dob0": null,
            "profession0": "",
            "education0": "",
            "nominee0": "",
            "alreadyRegistered0": "",
            "registrationNo0": "",
            "aadharnum1_0": "",
            "aadharnum2_0": "",
            "aadharnum3_0": "",
            "aadhar0": "",
            "formcontrolnum0": 1,
        }],
        noOfFamilyMembers: 1,
        documentUploadResponse:""
    },
    bank_details:{
        ifscCode: "",
        accountNumber: "",
        bankName: "",
        bankBranch: "",
        bankAddress: "",
        imgfile: "",
        passbookDocumentName:"",
        passbookUploadResponse:"",
        nomineeCheck: false,

        ifscCode_nominee: "",
        accountNumber_nominee: "",
        bankName_nominee: "",
        bankBranch_nominee: "",
        bankAddress_nominee: "",
        imgfile_nominee: "",
        passbookDocumentName_nominee:"",
        passbookUploadResponse_nominee:"",
    },
    nintydays_details:{
        employmentsList: [{
            "id":0,
            "employerDetails": "",
            "workplaceSiteaddress": "",
            "inchargePhoneNumber": "",
            "contractorDeveloperName": "",
            "contractorCompanyName":"",
            "pincode": "",
            "state":"",
            "districtWorkplace": "",
            "cityGram": "",
            "talukWorkplace": "",
            "villageWard": "",
            "natureWorkplace": "",
        }],

        noOfEmployementDetails: 1,
        selectedDate: null,
        imgfile: "",
        documentName: "",
        openDatePicker: false,
        issuerType: "",
        presentDetailsGiven: false,
        presentDetailsGivenIndex: "",
        inspectorName: "",
        inspectorCircle: "",
        inspectorPhone: "",
        unionName: "",
        unionRegistrationNumber: "",
        unionIssuerName: "",
        unionPhone: "",
        issuerName: "",
        issuerPlace: "",
        issuerPhone: "",
        issuerDesignation: "",
        documentUploadResponse: null
    },
    profile_details:{
        language: '',
        users: null,
        activestep: 1,
        percentComplete: 30,
        editmode: false
    },
    review:{
        profliePicUploadResponse: "",
        rationCardUploadResponse: "",
        passbookUploadResponse: "",
        passbookNomineeUploadResponse: "",
        nintyDaysUploadResponse: "",
        // personalSubmitted: false,
        // addressSubmitted: false,
        // familySubmitted: false,
        // bankSubmitted: false,
        // nintyDaysSubmitted: false,
        finalSubmitted: false
    },
    data:{
        fullname:'vish',
        history:[],
        hash:''
    },
    getApplicationNo: "",
    getUserRegistrationDetails: null,
    getFile: null,
    getLabourStatusDetails: null,
    getRenewalLabourDetails:null,
    finalSubmit: null,
    saveAcknowledgementId: null,
    getConfigMinistryDetails: null,
    getConfigBoardDetails: null,
    getStateMapping: null,
    searchuser: null,
    get_schemes: null,
    getSchemesOnStatus: null,
    getDeptUserRole: null,
    getDeptUsers: null,
    insertDeptUser: null,
    adminDashboard: {
        activestep: 1
    }
}

export default function usersReducer(state= DEFAULT_USER_STATE, action){
    // console.log("in usersReducer-> action: "+JSON.stringify(action))
    // console.log("in usersReducer-> action: "+JSON.stringify(action))
    // console.log("action.payload.data001",action);
                
    
    try{
        switch(action.type){
            case GET_LABOUR_STATUS:
                return { ...state,
                    getLabourStatusDetails: action.payload.data
                } 
            case ERROR_GET_LABOUR_STATUS:
                return { ...state,
                    getLabourStatusDetails:  action.payload.data
                } 
            case GET_DEPT_USER_ROLE:
                return { ...state,
                    getDeptUserRole: action.payload.data
                } 
            case ERROR_GET_DEPT_USER_ROLE:
                return { ...state,
                    getDeptUserRole:  action.payload.data
                } 
            case GET_DEPT_USERS:
                return { ...state,
                    getDeptUsers: action.payload.data
                } 
            case ERROR_GET_DEPT_USERS:
                return { ...state,
                    getDeptUsers:  action.payload.data
                } 
            case INSERT_DEPT_USER:
                return { ...state,
                    insertDeptUser: action.payload.data
                } 
            case ERROR_INSERT_DEPT_USER:
                return { ...state,
                    insertDeptUser:  action.payload.data
                } 
            case GET_SCHEMES_ON_STATUS:
                return { ...state,
                    getSchemesOnStatus: action.payload.data
                } 
            case ERROR_GET_SCHEMES_ON_STATUS:
                return { ...state,
                    getSchemesOnStatus:  action.payload.data
                } 
            case GET_SCHEMES:
                return { ...state,
                    get_schemes: action.payload.data
                } 
            case ERROR_GET_SCHEMES:
                return { ...state,
                    get_schemes:  action.payload.data
                } 
            case SEARCH_USER:
                return { ...state,
                    searchuser: action.payload.data
                } 
            case ERROR_SEARCH_USER:
                return { ...state,
                    searchuser:  action.payload.data
                } 
            case GET_STATE_MAPPING:
                return { ...state,
                    getStateMapping: action.payload.data
                } 
            case ERROR_GET_STATE_MAPPING:
                return { ...state,
                    getStateMapping:  action.payload.data
                } 
            case GET_CONFIG_BOARD_DETAILS:
                return { ...state,
                    getConfigBoardDetails: action.payload.data.data
                } 
            case ERROR_GET_CONFIG_BOARD_DETAILS:
                return { ...state,
                    getConfigBoardDetails:  action.payload.data
                } 
            case GET_CONFIG_MINISTRY_DETAILS:
                return { ...state,
                    getConfigMinistryDetails: action.payload.data.data
                } 
            case ERROR_GET_CONFIG_MINISTRY_DETAILS:
                return { ...state,
                    getConfigMinistryDetails:  action.payload.data
                } 
            case SAVE_ACK_ID:
                return { ...state,
                    saveAcknowledgementId: action.payload
                } 
            case ERROR_SAVE_ACK_ID:
                return { ...state,
                    saveAcknowledgementId:  action.payload.data
                } 
            case FINAL_SUBMIT:
                return { ...state,
                    finalSubmit: action.payload
                } 
            case ERROR_FINAL_SUBMIT:
                return { ...state,
                    finalSubmit:  action.payload.data
                } 
            case GET_RENEWAL_LABOUR_DETAILS:
                if(action.payload !== null && action.payload.data !== null)
                {
                    return { ...state,
                    getRenewalLabourDetails: action.payload.data.data
                    } 
                }
                else{
                    return { ...state} 
                }
            case ERROR_GET_RENEWAL_LABOUR_DETAILS:
                return { ...state,
                    getRenewalLabourDetails:  action.payload.data
                } 
            case GET_USER_REGISTRATION_DETAILS:
                if(action.payload !== null && action.payload.data !== null)
                {
                    return { ...state,
                        getUserRegistrationDetails: action.payload.data.data
                    }
                } 
                else{
                    return { ...state,
                        getUserRegistrationDetails: action.payload.data
                    }
                }
                
            case ERROR_GET_USER_REGISTRATION_DETAILS:
                return { ...state,
                    getUserRegistrationDetails:  action.payload.data
                } 
            case GET_APPLICATION_NO:
                return { ...state,
                    getApplicationNo: action.payload.data.data
                } 
            case ERROR_GET_APPLICATION_NO:
                return { ...state,
                    getApplicationNo:  action.payload.data
                } 
            case GET_UNION:
                return { ...state,
                    unionDetails: action.payload.data.data
                } 
            case ERROR_GET_UNION:
                return { ...state,
                    unionDetails:  action.payload.data
                } 
            case GET_INSPECTOR:
                return { ...state,
                    inspectorDetails: action.payload.data.data
                } 
            case ERROR_GET_INSPECTOR:
                return { ...state,
                    inspectorDetails:  action.payload.data
                } 

            case UPDATE_GET_FILE:
                return { ...state,
                    getFile: action.payload 
                } 
            case UPDATE_ADMIN_DASHBOARD:
                return { ...state,
                    adminDashboard: action.payload 
                } 
            case UPDATE_Review:
                return { ...state,
                    review: action.payload 
                } 
            case UPDATE_90DAYS:
                return { ...state,
                    nintydays_details: action.payload 
                } 
            case UPDATE_BANK:
                return { ...state,
                    bank_details: action.payload 
                } 
            case UPDATE_PROFILE:
                return { ...state,
                    profile_details: action.payload 
                } 

            case ADD_LABOUR_90DAYS:
                return { ...state,
                    addLabour90DaysCertDetails: action.payload.data
                } 
            case ERROR_ADD_LABOUR_90DAYS:
                return { ...state,
                    addLabour90DaysCertDetails:  action.payload.data
                } 

            case ADD_LABOUR_BANK:
                return { ...state,
                    addLabourBankDetails: action.payload.data
                } 
            case ERROR_ADD_LABOUR_BANK:
                return { ...state,
                    addLabourBankDetails:  action.payload.data
                } 

            case ADD_LABOUR_FAMILY:
                return { ...state,
                    addLabourFamilyDetails: action.payload.data
                } 
            case ERROR_ADD_LABOUR_FAMILY:
                return { ...state,
                    addLabourFamilyDetails:  action.payload.data
                } 

            case UPDATE_FAMILY:
                return { ...state,
                    family_details: action.payload 
                } 

            case ADD_LABOUR_ADDRESS:
                return { ...state,
                    addLabourAddressDetails: action.payload.data
                } 
            case ERROR_ADD_LABOUR_ADDRESS:
                return { ...state,
                    addLabourAddressDetails:  action.payload.data
                } 

            case VILLAGE_DETAILS2:
                return { ...state,
                    village_details2: action.payload.data.data
                } 
            case ERROR_VILLAGE_DETAILS2:
                return { ...state,
                    village_details2:  action.payload.data
                } 
            case CITY_DETAILS2:
                return { ...state,
                    city_details2: action.payload.data.data
                } 
            case ERROR_CITY_DETAILS2:
                return { ...state,
                    city_details2:  action.payload.data
                } 
            case TALUK_DETAILS2:
                return { ...state,
                    taluk_details2: action.payload.data.data
                } 
            case ERROR_TALUK_DETAILS2:
                return { ...state,
                    taluk_details2:  action.payload.data
                } 
            case DISTRICT_DETAILS2:
                return { ...state,
                    district_details2: action.payload.data.data
                } 
            case ERROR_DISTRICT_DETAILS2:
                return { ...state,
                    district_details2:  action.payload.data
                } 

            case VILLAGE_DETAILS:
                return { ...state,
                    village_details: action.payload.data.data
                } 
            case ERROR_VILLAGE_DETAILS:
                return { ...state,
                    village_details:  action.payload.data
                } 
            case CITY_DETAILS:
                return { ...state,
                    city_details: action.payload.data.data
                } 
            case ERROR_CITY_DETAILS:
                return { ...state,
                    city_details:  action.payload.data
                } 
            case TALUK_DETAILS:
                return { ...state,
                    taluk_details: action.payload.data.data
                } 
            case ERROR_TALUK_DETAILS:
                return { ...state,
                    taluk_details:  action.payload.data
                } 
            case DISTRICT_DETAILS:
                return { ...state,
                    district_details: action.payload.data.data
                } 
            case ERROR_DISTRICT_DETAILS:
                return { ...state,
                    district_details:  action.payload.data
                } 
            case UPDATE_ADDRESS:
                return { ...state,
                    address_details: action.payload 
                } 
            case ADD_LABOUR_PERSONAL:
                return { ...state,
                    addLabourPersonalDetails: action.payload.data
                } 
            case ERROR_ADD_LABOUR_PERSONAL:
                return { ...state,
                    addLabourPersonalDetails:  action.payload.data
                } 
            case UPDATE_UPLOAD_FILE:
                return { ...state,
                    upload_file: action.payload
                } 
            case UPLOAD_FILE:
                return { ...state,
                    upload_file: action.payload.data
                } 
            case ERROR_UPLOAD_FILE:
                return { ...state,
                    upload_file:  action.payload.data
                } 
            case STATE_DETAILS:
                return { ...state,
                    state_details: action.payload.data.data
                } 
            case ERROR_STATE_DETAILS:
                return { ...state,
                    state_details:  action.payload.data
                } 
            case CATALOG_DETAILS:
                return { ...state,
                    catalog_details: action.payload.data.data
                    // catalog_details: { ...state.catalog_details, ...action.payload.data.data }
                } 
            case ERROR_CATALOG_DETAILS:
                return { ...state,
                    catalog_details:  action.payload.data
                } 
            case UPDATE_PERSONAL:
                return { ...state,
                    personal_details: action.payload 
                } 
            case REGISTER_USER:
                return { ...state,
                    register_user:  action.payload.data
                } 
            case ERROR_REGISTER:
                return { ...state,
                    register_user:  action.payload.data
                } 
            case ERROR_GENERATE_OTP_REGISTRATION:
                return { ...state,
                    generate_otp_registration:  action.payload.data
                } 
            case GENERATE_OTP_REGISTRATION:
                return { ...state,
                    generate_otp_registration:  action.payload.data
                } 
            case GET_BOARD:
                return { ...state,
                    get_board:  action.payload.data.data
                } 
            case GET_MINISTRY:
                return { ...state,
                    get_ministry:  action.payload.data.data[0] 
                } 
            case "update":
                return { ...state,
                data: { ...state.data, ...action.payload.data }
                } 
            case GENERATE_OTP:
                return { ...state,
                generate_otp:  action.payload.data 
                } 
            case LOGIN_USER:
                return { ...state,
                login_user:  action.payload.data 
                } 
            case ERROR_LOGIN:
                return { ...state,
                login_user:  action.payload.data 
                } 
            case LOGIN_ADMIN:
                return { ...state,
                login_admin:  action.payload.data 
                } 
            case ERROR_LOGIN_ADMIN:
                return { ...state,
                    login_admin:  action.payload.data 
                } 
            case ERROR_GENERATE_OTP:
                return { ...state,
                generate_otp:  action.payload.data 
                } 
            case UPDATE_USER_PROFILE:
                return { ...state,
                    data: { ...state.data, ...action.payload }
                } 
            case GET_FILE:
                return { ...state,
                    getFile: action.payload.data
                } 
            case ERROR_GET_FILE:
                return { ...state,
                    getFile: action.payload.data
                } 
            case UPDATE_USER:
                return { ...state,
                    user: action.payload
                } 
            case USER:
                return { ...state,
                    user: action.payload.data.data[0]
                } 
            case ERROR_GET_USER:
                return { ...state,
                    user: action.payload.data
                } 
            default:
                return state
        }

    }
    catch(error){
        console.error("error in usersReducer: "+error)
    }
}
