import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const getSubmissionLocationAPI = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/seva_sindhu/get_circle_mapped_to_villages`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getResidenceAPI = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCategory = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getReligion = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const getEducation = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProfession = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRelation = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getNatureOfWork = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_catalog_details`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllStates = async () => {
  try {
    const { data } = await axios.get(`${SERVER}/global/get_state_details`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getIFSCDetails = async (IFSCCODE) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/global/get_ifsc_details?ifsc=${IFSCCODE}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const transferSevaSindhuAPI = async (payLoad) => {
  try {
    const { data } = await axios.put(
      `${SERVER}/seva_sindhu/v2/register`,
      payLoad,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
