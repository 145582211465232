import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { finalSubmit } from "store/actions";
const WelfareMedical = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    worker_name: "",
    worker_name_error: false,
    mobile_number: "",
    mobile_number_error: false,
    organization_name: "",
    organization_name_error: false,
    designation: "",
    designation_error: "",
    monthly_salary: "",
    monthly_salary_error: "",
    nature_disease: "",
    nature_disease_error: "",
    duration_treatment: "",
    duration_treatment_error: "",
    amount_spent: "",
    amount_spent_error: "",
    account_no: "",
    account_no_error: "",
    ifsc_code: "",
    ifsc_code_error: "",
    openDatePicker: false,
    postalAddress: "",
    organization_address: "",
    date_of_join: null,
    dateSelected: false,
    otherSource: "",
    bank_name: "",
    bank_name_error: false,
    bank_branch: "",
    bank_branch_error: false,
    amount_spent_bank: "",
    amount_spent_bank_error: false,
  });

  const inputRef = useRef(null);
  const [fileObj, setFileObj] = useState("");
  const [selectedfiles, setselectedfiles] = React.useState({});
  const [selectedfilesAttested, setselectedfilesAttested] = React.useState({});
  const [selectedfilesMedical, setselectedfilesMedical] = React.useState({});
  const [selectedfilesMedicalCert, setselectedfilesMedicalCert] =
    React.useState({});
  const [selectedfilesXerox, setselectedfilesXerox] = React.useState({});

  const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false);

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [photoUpload, setphotoUpload] = React.useState({});
  const [attestedDoc, setAttestedDoc] = React.useState({});
  const [medicalDoc, setMedicalDoc] = React.useState({});
  const [medicalDocCert, setMedicalDocCert] = React.useState({});
  const [filesXerox, setFilesXerox] = React.useState({});

  const [filelist, setfilelist] = React.useState({});
  const [filelistAttested, setfilelistAttested] = React.useState({});
  const [filelistMedical, setfilelistMedical] = React.useState({});
  const [filelistMedicalCert, setfilelistMedicalCert] = React.useState({});
  const [filelistFilesXerox, setfilelistFilesXerox] = React.useState({});

  const [dateOfJoin, setDateOfJoin] = React.useState(null);

  const [filesResponse, setfilesResponse] = React.useState(null);
  const [filesResponseAttested, setfilesResponseAttested] =
    React.useState(null);
  const [filesResponseMedical, setfilesResponseMedical] = React.useState(null);
  const [filesResponseMedicalCert, setfilesResponseMedicalCert] =
    React.useState(null);
  const [fileXerox, setfileXerox] = React.useState(null);
  const [finalSubmit, setfinalSubmit] = React.useState(false);

  // const handleFileChange = event => {
  //     setFileObj( event.target.files && event.target.files);
  //     if (!fileObj) {
  //       return;
  //     }
  // }

  // const resetFileInput = (event) => {
  //     // reset input value
  //     setFileObj(event.target.value=null)
  //   };

  useEffect(() => {
    if (dateOfJoin !== null) {
      setAllValues({
        ...allValues,
        date_of_join: dateOfJoin,
        dateSelected: true,
      });
      console.log("date_of_join" + allValues.date_of_join);
    }
  }, [dateOfJoin]);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };
  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };
  const [customError, setCustomError] = useState("");
  const handleChangeValidation = (ev) => {
    if (ev.target.name === "worker_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: true,
        });
      }
    } else if (ev.target.name === "mobile_number") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: true,
        });
      }
    } else if (ev.target.name === "organization_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: true,
        });
      }
    } else if (ev.target.name === "designation") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: true,
        });
      }
    } else if (ev.target.name === "monthly_salary") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: true,
        });
      }
    } else if (ev.target.name === "nature_disease") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: true,
        });
      }
    } else if (ev.target.name === "duration_treatment") {
      if (
        validator.isAlphanumeric(ev.target.value) &&
        ev.target.value.length <= 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: true,
        });
      }
    } else if (ev.target.name === "bank_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_name_error: true,
        });
      }
    } else if (ev.target.name === "bank_branch") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_branch_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_branch_error: true,
        });
      }
    } else if (ev.target.name === "amount_spent_bank") {
      if (validator.isNumeric(ev.target.value)) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_bank_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_bank_error: true,
        });
      }
    } else if (ev.target.name === "account_no") {
      if (validator.isNumeric(ev.target.value) && ev.target.value.length < 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: true,
        });
      }
    } else if (ev.target.name === "ifsc_code") {
      if (validator.matches(ev.target.value, "[A-Z]{4}0[A-Z0-9]{6}$")) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: true,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [ev.target.name]: ev.target.value,
      });
    }
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );

      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleFileChangeAttested = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesAttested).concat(
        Array.from(event.target.files)
      );

      setselectedfilesAttested(joined);
      setselectedfilesPhoto(false);
    }

    setloadingfiles(false);
  };

  const handleFileChangeMedical = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesMedical).concat(
        Array.from(event.target.files)
      );

      setselectedfilesMedical(joined);
    }

    setloadingfiles(false);
  };
  const handleFileChangeMedicalCert = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesMedicalCert).concat(
        Array.from(event.target.files)
      );

      setselectedfilesMedicalCert(joined);
    }

    setloadingfiles(false);
  };
  const handleFileChangeFileXerox = (event) => {
    setloadingfiles(true);
    console.log("File Type:", event.target.files[0].type);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesXerox).concat(
        Array.from(event.target.files)
      );

      setselectedfilesXerox(joined);
    }

    setloadingfiles(false);
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": "106cae6f-8276-4688-a747-d7cb20d28c58",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data, "uploadFile"));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  // const uploadfileAttested = (data1, filename) => {
  //     console.log("inside uploadFile: "+ JSON.stringify(data1))
  //     console.log("inside uploadFile: filename= "+ JSON.stringify(filename))
  //     return new Promise((resolve, reject) => {

  //         var FormData = require('form-data');
  //
  //         var data = new FormData();
  //         data.append('file',  dataURLtoFile(data1.file, 'newfile'));
  //         data.append('userId', data1.userId);
  //         data.append('fileType', data1.fileType);

  //         var config = {
  //         method: 'post',
  //         url: SERVER+'/upload/file',
  //         headers:{
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //             'Authorization':`Bearer ${getTokenCookie()}`
  //             },
  //         data : data
  //         };

  //         axios(config)
  //         .then(function (response) {
  //             console.log(JSON.stringify(response.data, "uploadFile"));
  //             resolve(response.data)
  //         // setTimeout(resolve, 100, response.data);

  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //             reject(error)
  //         });

  //     })
  // }

  const uploadDoc = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setphotoUpload(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('passbook') !== null && users.user !== null){
    // let formdata = {
    //     'file': sessionStorage.getItem('passbook'),
    //     'userId': users.user.id,
    //     'fileType': 'passbook'
    // }
    //     console.error("formdata passbook: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"passbook"))
    // }
  };

  const uploadDocAttested = () => {
    let promises = [];

    selectedfilesAttested.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfilesAttested.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setAttestedDoc(results);
          });
        }
      };
    });
  };

  const uploadDocMedical = () => {
    let promises = [];

    selectedfilesMedical.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfilesMedical.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise2: ");
            console.log(results);
            setMedicalDoc(results);
          });
        }
      };
    });
  };

  const uploadDocMedicalCert = () => {
    let promises = [];

    selectedfilesMedicalCert.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfilesMedicalCert.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise2: ");
            console.log(results);
            setMedicalDocCert(results);
          });
        }
      };
    });
  };

  const uploadDocFilesXerox = () => {
    let promises = [];

    selectedfilesXerox.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfilesXerox.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise2: ");
            console.log(results);
            setFilesXerox(results);
          });
        }
      };
    });
  };

  const submitFiles = (
    filesResponse,
    filesResponseAttested,
    filesResponseMedical,
    filesResponseMedicalCert,
    fileXerox
  ) => {
    console.log(filesResponse, "filesResponse");
    console.log(filesResponseAttested, "filesResponseAttested");
    console.log(filesResponseMedical, "filesResponseMedical");
    console.log(filesResponseMedicalCert, "filesResponseMedicalCert");
    console.log(fileXerox, "fileXerox");
    if (
      filesResponse !== undefined &&
      filesResponse.length > 0 &&
      filesResponseAttested !== undefined &&
      filesResponseAttested.length > 0 &&
      filesResponseMedical !== undefined &&
      filesResponseMedical.length > 0 &&
      filesResponseMedicalCert !== undefined &&
      filesResponseMedicalCert.length > 0 &&
      fileXerox !== undefined &&
      fileXerox.length > 0
    ) {
      console.log(filesResponse, "filesResponse");
      console.log(filesResponseAttested, "filesResponseAttested");
      console.log(filesResponseMedical, "filesResponseMedical");
      console.log(filesResponseMedicalCert, "filesResponseMedicalCert");
      console.log(fileXerox, "fileXerox");
      // setfilesResponse(filesResponse)
      // setfilesResponseAttested(filesResponseAttested)
      // setfilesResponseMedical(filesResponseMedical)
      // setfilesResponseMedicalCert(filesResponseMedicalCert)
      // setfileXerox(fileXerox)

      // setfinalSubmit(true)

      // finalSubmit(filesResponse, filesResponseAttested,filesResponseMedical,  filesResponseMedicalCert, fileXerox)

      // let dataToSubmit = {
      //     "user_id": users.user.id,
      //     "banks": [
      //         {
      //             "labour_user_id": users.user.id,
      //             "nominee_for_user_id": null,
      //             "nominee_first_name": null,
      //             "nominee_middle_name": null,
      //             "nominee_last_name": null,
      //             "ifsc_code": users.bank_details.ifscCode,
      //             "account_no": users.bank_details.accountNumber,
      //             "bank_name": users.bank_details.bankName,
      //             "bank_branch": users.bank_details.bankBranch,
      //             "bank_address": users.bank_details.bankAddress,
      //             // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
      //             "bank_passbook_upload_id": '-',
      //             // "files": [{
      //             //     "file_id": "-",
      //             //     "file_name": "-"
      //             // }],
      //             "files": passbookUploadResponse,
      //             "is_nominee": 0,
      //             "is_verified": users.is_bank_verified_nominee ? 1 : 0
      //         },
      //     ]
      // }

      var dataNEW = JSON.stringify({
        board_id: 1006,
        scheme_id: 29,
        labour_name: allValues.worker_name,
        phone_number: allValues.mobile_number,
        aadhaar_number: "604755673677",
        date_of_birth: "2022-06-23",
        labour_address: "ss",
        district_id: 5,
        taluk_id: 31,
        grampanchayat_city_id: 1205,
        village_area_ward_id: 1205,
        address_number_street: "ss",
        organisation_name: allValues.organization_name,
        organisation_address: allValues.organization_address,
        date_of_joining: "2022-06-23",
        designation: allValues.designation,
        salary: allValues.monthly_salary,
        nature_of_disease: allValues.nature_disease,
        duration_of_treatment: allValues.duration_treatment,
        amount_spent_for_treatment: allValues.amount_spent,
        medical_assistance_from_other_source: allValues.otherSource,
        bank_name: allValues.bank_name,
        bank_branch: allValues.bank_branch,
        account_number: allValues.account_no,
        ifsc_code: allValues.ifsc_code,
        amount_sought: allValues.amount_spent_bank,
        application_name: "SOME_APPLICATION NAME",
        applicant_address: "BENGALURU",

        relationship_with_deceased: "Uncle",
        age_of_deceased: "29",
        death_date: "2022-06-22",
        name_legal_hier: "SUbhash",
        relationship_leagl_hier: "Friend",
        gratuity_confimation: "SS",
        amount_sanctioned_by_approver: 2000,
        photo: JSON.stringify(filesResponse),
        employer_document: JSON.stringify(filesResponseAttested),
        medical_document: JSON.stringify(filesResponseMedical),
        medical_certificate: JSON.stringify(filesResponseMedicalCert),
        discharge_summary: "SUMMARY By DOC",
        passbook: JSON.stringify(fileXerox),
        child_photo: null,
        birth_certificate: null,
        signature_of_applicant: null,
        ip_address: "ip_address",
        device_id: "device_id",
        approver_documents: "SSS",
        district: null,
        taluk: null,
        grampanchayat: null,
        village_area_ward: null,
      });
      var config = {
        method: "post",
        url: SERVER + "/welfare/insert_welfare_scheme",
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          console.log(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          showToast("SUCCESS", "Scheme updated successfully");
          props.history.push("/labourschemesHomePage");
        })
        .catch(function (error) {
          console.error(error);
        });

      // finalSubmit(filesResponse, filesResponseAttested,filesResponseMedical,  filesResponseMedicalCert, fileXerox)

      // let dataToSubmit = {
      //     "user_id": users.user.id,
      //     "banks": [
      //         {
      //             "labour_user_id": users.user.id,
      //             "nominee_for_user_id": null,
      //             "nominee_first_name": null,
      //             "nominee_middle_name": null,
      //             "nominee_last_name": null,
      //             "ifsc_code": users.bank_details.ifscCode,
      //             "account_no": users.bank_details.accountNumber,
      //             "bank_name": users.bank_details.bankName,
      //             "bank_branch": users.bank_details.bankBranch,
      //             "bank_address": users.bank_details.bankAddress,
      //             // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
      //             "bank_passbook_upload_id": '-',
      //             // "files": [{
      //             //     "file_id": "-",
      //             //     "file_name": "-"
      //             // }],
      //             "files": passbookUploadResponse,
      //             "is_nominee": 0,
      //             "is_verified": users.is_bank_verified_nominee ? 1 : 0
      //         },
      //     ]
      // }

      // console.error("dataToSubmit", JSON.stringify(dataToSubmit))
    }
  };

  // useEffect(() => {
  //     if(finalSubmit){
  //         var dataNEW = JSON.stringify({
  //             "board_id": 1006,
  //     "scheme_id": 29,
  //     "labour_name": allValues.worker_name,
  //     "phone_number": allValues.mobile_number,
  //     "aadhaar_number": "604755673677",
  //     "date_of_birth": "2022-06-23",
  //     "labour_address": "ss",
  //     "district_id": 5,
  //     "taluk_id": 31,
  //     "grampanchayat_city_id": 1205,
  //     "village_area_ward_id": 1205,
  //     "address_number_street":"ss",
  //     "organisation_name": allValues.organization_name,
  //     "organisation_address": allValues.organization_address,
  //     "date_of_joining": "2022-06-23",
  //     "designation": allValues.designation,
  //     "salary": allValues.monthly_salary,
  //     "nature_of_disease": allValues.nature_disease,
  //     "duration_of_treatment": allValues.duration_treatment,
  //     "amount_spent_for_treatment": allValues.amount_spent,
  //     "medical_assistance_from_other_source": allValues.otherSource,
  //     "bank_name": allValues.bank_name,
  //     "bank_branch": allValues.bank_branch,
  //     "account_number": allValues.account_no,
  //     "ifsc_code": allValues.ifsc_code,
  //     "amount_sought": allValues.amount_spent_bank,
  //     "application_name": "SOME_APPLICATION NAME",
  //     "applicant_address": "BENGALURU",

  //     "relationship_with_deceased": "Uncle",
  //     "age_of_deceased": "29",
  //     "death_date": "2022-06-22",
  //     "name_legal_hier": "SUbhash",
  //     "relationship_leagl_hier": "Friend",
  //     "gratuity_confimation": "SS",
  //     "amount_sanctioned_by_approver": 2000,
  //     "photo":JSON.stringify(filesResponse),
  //     "employer_document": JSON.stringify(filesResponseAttested),
  //     "medical_document": JSON.stringify(filesResponseMedical),
  //     "medical_certificate": JSON.stringify(filesResponseMedicalCert),
  //     "discharge_summary": "SUMMARY By DOC",
  //     "passbook": JSON.stringify(fileXerox),
  //     "child_photo": null,
  //     "birth_certificate": null,
  //     "signature_of_applicant": null,
  //     "ip_address": "ip_address",
  //     "device_id": "device_id",
  //     "approver_documents": "SSS",
  //     "district":  null,
  //     "taluk": null,
  //     "grampanchayat": null,
  //     "village_area_ward":null
  //         });
  //         var config = {
  //                 method: 'post',
  //                 url: SERVER+'/welfare/insert_welfare_scheme',
  //                 data : dataNEW
  //             };

  //             axios(config)
  //             .then(function (response) {
  //                 console.error("response get_fields_value: "+JSON.stringify(response.data));
  //                 console.log("response get_fields_value: "+JSON.stringify(response.data));
  //                 showToast("SUCCESS", "Scheme updated successfully")
  //                 props.history.push("/labourschemesHomePage")

  //             })
  //             .catch(function (error) {
  //                 console.error(error);
  //             });
  //     }
  // },[finalSubmit])

  // const finalSubmit = (filesResponse, filesResponseAttested,filesResponseMedical,  filesResponseMedicalCert, fileXerox) => {

  //     console.log("finalSUbmit")
  //         var dataNEW = JSON.stringify({
  //             "board_id": 1006,
  //     "scheme_id": 29,
  //     "labour_name": allValues.worker_name,
  //     "phone_number": allValues.mobile_number,
  //     "aadhaar_number": "604755673677",
  //     "date_of_birth": "2022-06-23",
  //     "labour_address": "ss",
  //     "district_id": 5,
  //     "taluk_id": 31,
  //     "grampanchayat_city_id": 1205,
  //     "village_area_ward_id": 1205,
  //     "address_number_street":"ss",
  //     "organisation_name": allValues.organization_name,
  //     "organisation_address": allValues.organization_address,
  //     "date_of_joining": "2022-06-23",
  //     "designation": allValues.designation,
  //     "salary": allValues.monthly_salary,
  //     "nature_of_disease": allValues.nature_disease,
  //     "duration_of_treatment": allValues.duration_treatment,
  //     "amount_spent_for_treatment": allValues.amount_spent,
  //     "medical_assistance_from_other_source": allValues.otherSource,
  //     "bank_name": allValues.bank_name,
  //     "bank_branch": allValues.bank_branch,
  //     "account_number": allValues.account_no,
  //     "ifsc_code": allValues.ifsc_code,
  //     "amount_sought": allValues.amount_spent_bank,
  //     "application_name": "SOME_APPLICATION NAME",
  //     "applicant_address": "BENGALURU",

  //     "relationship_with_deceased": "Uncle",
  //     "age_of_deceased": "29",
  //     "death_date": "2022-06-22",
  //     "name_legal_hier": "SUbhash",
  //     "relationship_leagl_hier": "Friend",
  //     "gratuity_confimation": "SS",
  //     "amount_sanctioned_by_approver": 2000,
  //     "photo":JSON.stringify(filesResponse),
  //     "employer_document": JSON.stringify(filesResponseAttested),
  //     "medical_document": JSON.stringify(filesResponseMedical),
  //     "medical_certificate": JSON.stringify(filesResponseMedicalCert),
  //     "discharge_summary": "SUMMARY By DOC",
  //     "passbook": JSON.stringify(fileXerox),
  //     "child_photo": null,
  //     "birth_certificate": null,
  //     "signature_of_applicant": null,
  //     "ip_address": "ip_address",
  //     "device_id": "device_id",
  //     "approver_documents": "SSS",
  //     "district":  null,
  //     "taluk": null,
  //     "grampanchayat": null,
  //     "village_area_ward":null
  //         });
  //         var config = {
  //                 method: 'post',
  //                 url: SERVER+'/welfare/insert_welfare_scheme',
  //                 data : dataNEW
  //             };

  //             axios(config)
  //             .then(function (response) {
  //                 console.error("response get_fields_value: "+JSON.stringify(response.data));
  //                 console.log("response get_fields_value: "+JSON.stringify(response.data));
  //                 showToast("SUCCESS", "Scheme updated successfully")
  //                 props.history.push("/labourschemesHomePage")

  //             })
  //             .catch(function (error) {
  //                 console.error(error);
  //             });

  // }

  const nextButtonPressed = () => {
    if (
      allValues.worker_name !== "" &&
      allValues.worker_name.length !== 0 &&
      allValues.postalAddress !== "" &&
      allValues.postalAddress.length !== 0 &&
      allValues.mobile_number !== "" &&
      allValues.mobile_number.length !== 0 &&
      allValues.organization_name !== "" &&
      allValues.organization_name.length !== 0 &&
      allValues.organization_address !== "" &&
      allValues.organization_address.length > 0 &&
      allValues.date_of_join !== "" &&
      allValues.date_of_join.length !== 0 &&
      allValues.designation !== "" &&
      allValues.designation.length !== 0 &&
      allValues.monthly_salary !== "" &&
      allValues.monthly_salary.length !== 0 &&
      allValues.nature_disease !== "" &&
      allValues.nature_disease.length !== 0 &&
      allValues.duration_treatment !== "" &&
      allValues.duration_treatment.length !== 0 &&
      allValues.otherSource !== "" &&
      allValues.otherSource.length !== 0 &&
      allValues.amount_spent !== "" &&
      allValues.amount_spent.length !== 0 &&
      allValues.bank_name !== "" &&
      allValues.bank_name.length !== 0 &&
      allValues.bank_branch !== "" &&
      allValues.bank_branch.length !== 0 &&
      allValues.account_no !== "" &&
      allValues.account_no.length !== 0 &&
      allValues.ifsc_code !== "" &&
      allValues.ifsc_code.length !== 0 &&
      allValues.amount_spent_bank !== "" &&
      allValues.amount_spent_bank.length !== 0
    ) {
      if (
        selectedfiles !== null &&
        selectedfiles.length > 0 &&
        selectedfilesAttested !== null &&
        selectedfilesAttested.length > 0 &&
        selectedfilesMedical !== null &&
        selectedfilesMedical.length > 0 &&
        selectedfilesMedicalCert !== null &&
        selectedfilesMedicalCert.length > 0 &&
        selectedfilesXerox !== null &&
        selectedfilesXerox.length > 0
      ) {
        // uploadDoc()

        // uploadDocAttested()

        // uploadDocMedical()

        // uploadDocMedicalCert()

        // uploadDocFilesXerox()

        var dataNEW = JSON.stringify({
          board_id: 1006,
          scheme_id: 29,
          labour_name: allValues.worker_name,
          phone_number: allValues.mobile_number,
          aadhaar_number: "604755673677",
          date_of_birth: "2022-06-23",
          labour_address: "ss",
          district_id: 5,
          taluk_id: 31,
          grampanchayat_city_id: 1205,
          village_area_ward_id: 1205,
          address_number_street: "ss",
          organisation_name: allValues.organization_name,
          organisation_address: allValues.organization_address,
          date_of_joining: "2022-06-23",
          designation: allValues.designation,
          salary: allValues.monthly_salary,
          nature_of_disease: allValues.nature_disease,
          duration_of_treatment: allValues.duration_treatment,
          amount_spent_for_treatment: allValues.amount_spent,
          medical_assistance_from_other_source: allValues.otherSource,
          bank_name: allValues.bank_name,
          bank_branch: allValues.bank_branch,
          account_number: allValues.account_no,
          ifsc_code: allValues.ifsc_code,
          amount_sought: allValues.amount_spent_bank,
          application_name: "SOME_APPLICATION NAME",
          applicant_address: "BENGALURU",

          relationship_with_deceased: "Uncle",
          age_of_deceased: "29",
          death_date: "2022-06-22",
          name_legal_hier: "SUbhash",
          relationship_leagl_hier: "Friend",
          gratuity_confimation: "SS",
          amount_sanctioned_by_approver: 2000,
          photo: JSON.stringify(filesResponse),
          employer_document: JSON.stringify(filesResponseAttested),
          medical_document: JSON.stringify(filesResponseMedical),
          medical_certificate: JSON.stringify(filesResponseMedicalCert),
          discharge_summary: "SUMMARY By DOC",
          passbook: JSON.stringify(fileXerox),
          child_photo: null,
          birth_certificate: null,
          signature_of_applicant: null,
          ip_address: "ip_address",
          device_id: "device_id",
          approver_documents: "SSS",
          district: null,
          taluk: null,
          grampanchayat: null,
          village_area_ward: null,
        });
        var config = {
          method: "post",
          url: SERVER + "/welfare/insert_welfare_scheme",
          data: dataNEW,
        };

        axios(config)
          .then(function (response) {
            console.error(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            console.log(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            showToast("SUCCESS", "Scheme updated successfully");
            props.history.push("/labourschemesHomePage");
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        showToast("ERROR", "Please fill all the documents");
      }

      // uploadDoc()
    } else {
      showToast("ERROR", "Please fill all the mandatory fields");
    }
  };

  useEffect(() => {
    if (photoUpload !== null && photoUpload.length > 0) {
      var filelist = [];

      photoUpload.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist));

      setfilelist(filelist);
      setfilesResponse(filelist);

      // submitFiles(filelist)
    }
  }, [photoUpload]);

  useEffect(() => {
    if (attestedDoc !== null && attestedDoc.length > 0) {
      var filelist1 = [];

      attestedDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist1: " + JSON.stringify(filelist1));

      setfilelistAttested(filelist1);
      setfilesResponseAttested(filelist1);

      // submitFiles(filelist, filelist1)
    }
  }, [attestedDoc]);

  useEffect(() => {
    if (medicalDoc !== null && medicalDoc.length > 0) {
      var filelist2 = [];

      medicalDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist2: " + JSON.stringify(filelist2));

      setfilelistMedical(filelist2);
      setfilesResponseMedical(filelist2);

      // submitFiles(filelist, filelistAttested, filelist2)
    }
  }, [medicalDoc]);

  useEffect(() => {
    if (medicalDocCert !== null && medicalDocCert.length > 0) {
      var filelist2 = [];

      medicalDocCert.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist2: " + JSON.stringify(filelist2));

      setfilelistMedicalCert(filelist2);
      setfilesResponseMedicalCert(filelist2);

      // submitFiles(filelist, filelistAttested,filelistMedical, filelist2)
    }
  }, [medicalDocCert]);

  useEffect(() => {
    if (filesXerox !== null && filesXerox.length > 0) {
      var filelist2 = [];

      filesXerox.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist2: " + JSON.stringify(filelist2));

      setfilelistFilesXerox(filelist2);
      setfileXerox(filelist2);

      // submitFiles(filelist, filelistAttested,filelistMedical,filelistMedicalCert, filelist2)
    }
  }, [filesXerox]);

  useEffect(() => {
    console.log("selectedfiles", selectedfiles);
    if (selectedfiles !== null && selectedfiles.length > 0) {
      uploadDoc();
    }
  }, [selectedfiles]);

  useEffect(() => {
    console.log("selectedfilesAttested", selectedfilesAttested);
    if (selectedfilesAttested !== null && selectedfilesAttested.length > 0) {
      uploadDocAttested();
    }
  }, [selectedfilesAttested]);

  useEffect(() => {
    console.log("selectedfilesMedical", selectedfilesMedical);
    if (selectedfilesMedical !== null && selectedfilesMedical.length > 0) {
      uploadDocMedical();
    }
  }, [selectedfilesMedical]);

  useEffect(() => {
    console.log("selectedfilesMedicalCert", selectedfilesMedicalCert);
    if (
      selectedfilesMedicalCert !== null &&
      selectedfilesMedicalCert.length > 0
    ) {
      uploadDocMedicalCert();
    }
  }, [selectedfilesMedicalCert]);

  useEffect(() => {
    console.log("selectedfilesXerox", selectedfilesXerox);
    if (selectedfilesXerox !== null && selectedfilesXerox.length > 0) {
      uploadDocFilesXerox();
    }
  }, [selectedfilesXerox]);

  return (
    <div>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/boardsHome'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='labourTitle' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={allValues.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          <h1>
            {" "}
            <Translate value='schemes' /> |
            <Translate value='medicalTitle' />
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='workerDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            {" "}
            <Translate value='workername' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='worker_name'
              value={allValues.worker_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.worker_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.worker_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='postalAddress' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='postalAddress'
              value={allValues.postalAddress}
              onChange={handleChangeValidation}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='mobileNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='mobile_number'
              value={allValues.mobile_number}
              onChange={handleChangeValidation}
              helperText={
                allValues.mobile_number_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.mobile_number_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='organizationDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='organizationName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='organization_name'
              value={allValues.organization_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.organization_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.organization_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='organizationAddress' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='organization_address'
              value={allValues.organization_address}
              onChange={handleChangeValidation}

              // value={""}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='dateofjoining' /> *
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className='datepicker'
                // margin="normal"
                format='DD/MM/YYYY'
                value={allValues.date_of_join}
                onChange={(date) => {
                  setDateOfJoin(date);
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='designation' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.designation}
              name='designation'
              onChange={handleChangeValidation}
              helperText={
                allValues.designation_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.designation_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='monthlysalary' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.monthly_salary}
              name='monthly_salary'
              onChange={handleChangeValidation}
              helperText={
                allValues.monthly_salary_error
                  ? "Please Enter Numbers only"
                  : ""
              }
              error={allValues.monthly_salary_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='medicalDetails' />
          </p>
        </Col>
      </Row>

      {/* <Row className="form-row mt-4 config-form searchform"> */}
      {/* <Col xs={12} md={4}>    
                    <p className="mb-2">Nature of Disease</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="organization_name"
                            value={allValues.organization_name}
                            onChange={handleChangeValidation}
                            helperText={allValues.organization_name_error ? "Please Enter Alphabets only":""}
                            error = {allValues.organization_name_error}
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={6}>
                
                    <p className="mb-2">Duration of the Treatment 1</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // value={""}
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                
                    <p className="mb-2">Date of Joining</p>
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // value={""}
                            
                        />
                    </FormControl>
                   </Col>
            
                <Col xs={12} md={6} className="mt-2">
                
                    <p className="mb-2">Designation</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.designation}
                            name="designation"
                            onChange={handleChangeValidation}
                            helperText={allValues.designation_error ? "Please Enter Alphabets only":""}
                            error = {allValues.designation_error}
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-2">
                
                    <p className="mb-2">Monthly Salary</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.monthly_salary}
                            name="monthly_salary"
                            onChange={handleChangeValidation}
                            helperText={allValues.monthly_salary_error ? "Please Enter Numbers only":""}
                            error = {allValues.monthly_salary_error}
                            
                        />
                    </FormControl>
                </Col> */}
      {/* </Row> */}

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p>
            <Translate value='natureofDisease' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='nature_disease'
              value={allValues.nature_disease}
              onChange={handleChangeValidation}
              helperText={
                allValues.nature_disease_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.nature_disease_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='durationofTreatment' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.duration_treatment}
              name='duration_treatment'
              onChange={handleChangeValidation}
              helperText={
                allValues.duration_treatment_error
                  ? "Please Enter Correct format only"
                  : ""
              }
              error={allValues.duration_treatment_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate
              value='medicalassistance'
              style={{ whiteSpace: "nowrap" }}
            />
            *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.otherSource}
              name='otherSource'
              onChange={handleChangeValidation}
            />
          </FormControl>
          <p className='medical-assistance-instruction'>
            /<Translate value='medicalassistance2' />
          </p>
        </Col>

        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='totalamount' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.amount_spent}
              name='amount_spent'
              onChange={handleChangeValidation}
              helperText={
                allValues.amount_spent_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.amount_spent_error}
            />
          </FormControl>
          <p className='medical-assistance-instruction'>
            <Translate value='totalamount2' />
          </p>
        </Col>

        {/* <Col xs={12} md={4} lg={8} xl={8} className="mt-2">
                
                    <p className="mb-2 w-50">Medical assistance from any other source / Details of Financial Assistance if received</p>

                    <FormControl fullWidth className="formcontrol1 w-50">
                        <TextField
                            variant="outlined"
                            value={allValues.otherSource}
                            name="otherSource"
                            onChange={handleChangeValidation}
                         
                           
                        />
                    </FormControl>
                </Col> */}
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='bankDetails' />
          </p>
        </Col>
      </Row>
      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2'>
            <Translate value='bankName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.bank_name}
              name='bank_name'
              onChange={handleChangeValidation}
              helperText={
                allValues.bank_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.bank_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='bankBranch' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.bank_branch}
              name='bank_branch'
              onChange={handleChangeValidation}
              helperText={
                allValues.bank_branch_error ? "Please Enter Alphabets Only" : ""
              }
              error={allValues.bank_branch_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='accountNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.account_no}
              name='account_no'
              onChange={handleChangeValidation}
              helperText={
                allValues.account_no_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.account_no_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='iFSCCode' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.ifsc_code}
              name='ifsc_code'
              onChange={handleChangeValidation}
              helperText={
                allValues.ifsc_code_error
                  ? "Please Enter Correct Format only"
                  : ""
              }
              error={allValues.ifsc_code_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-75'>
            <Translate value='amountoffinalassistance' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.amount_spent_bank}
              name='amount_spent_bank'
              onChange={handleChangeValidation}
              helperText={
                allValues.amount_spent_bank_error
                  ? "Please Enter Numbers Only"
                  : ""
              }
              error={allValues.amount_spent_bank_error}
              // helperText={allValues.amount_spent_error ? "Please Enter Numbers only":""}
              // error = {allValues.amount_spent_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='uploadDocs' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='photo' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedfiles !== null &&
                selectedfiles.length > 0 &&
                Array.from(selectedfiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='photo'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                id='upload-photo'
                onChange={handleFileChange}
                multiple
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6} className='mb-2'>
          <p className='mb-2 w-50'>
            <Translate
              value='attestedempdoc'
              style={{ whiteSpace: "nowrap" }}
            />
            *
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedfilesAttested !== null &&
                selectedfilesAttested.length > 0 &&
                Array.from(selectedfilesAttested).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='attestedDoc'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo2`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                id='upload-photo2'
                onChange={handleFileChangeAttested}
                multiple
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate
              value='attestedmeddoc'
              style={{ whiteSpace: "nowrap" }}
            />
            *
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedfilesMedical !== null &&
                selectedfilesMedical.length > 0 &&
                Array.from(selectedfilesMedical).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='medicalDoc'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo3`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handleFileChangeMedical}
                id={`upload-photo3`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='medicalCert' style={{ whiteSpace: "nowrap" }} />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedfilesMedicalCert !== null &&
                selectedfilesMedicalCert.length > 0 &&
                Array.from(selectedfilesMedicalCert).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='medicalCert'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo4`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handleFileChangeMedicalCert}
                id={`upload-photo4`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='savingsPassbook' /> *
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedfilesXerox !== null &&
                selectedfilesXerox.length > 0 &&
                Array.from(selectedfilesXerox).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='filesXerox'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo5`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>

              <input
                type='file'
                onChange={handleFileChangeFileXerox}
                id={`upload-photo5`}
                multiple
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='search-subtitle-row pb-5'>
        <Col xs={12} className='next-back-button-row mt-4 '>
          <>
            <Link
              to='/medical2'
              onClick={props.backButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='danger' className='back-button'>
                <Translate value='back' />
              </Button>
            </Link>
            <Link
              to='#'
              onClick={nextButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='outline-primary' className='save-button'>
                <Translate value='submit' />
              </Button>
            </Link>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default WelfareMedical;
