import 'date-fns';
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  Divider,
  withStyles,
  Menu,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
  TableHead,
  FormHelperText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip2 from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button2 from '@mui/material/Button';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import home from 'assets/images/Icon material-dashboard 2.svg';
import homewhite from 'assets/images/Icon material-dashboard.svg';
import user from 'assets/images/Icon awesome-user-edit.svg';
import userwhite from 'assets/images/Icon awesome-user-edit 2.svg';
import board from 'assets/images/Icon awesome-chalkboard-teacher.svg';
import boardwhite from 'assets/images/Icon awesome-chalkboard-teacher 2.svg';
import config from 'assets/images/Icon awesome-tools.svg';
import configwhite from 'assets/images/Icon awesome-tools 2.svg';
import mapping from 'assets/images/Icon awesome-link.svg';
import mappingwhite from 'assets/images/Icon awesome-link 2.svg';
import reports from 'assets/images/Icon material-report.svg';
import reportswhite from 'assets/images/Icon material-report.svg';
import vector from 'assets/images/Wall post-amico.svg';
import kbocwwb from 'assets/images/Image 8.png';
import Check from 'assets/images/check (3).svg';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import Pending from 'assets/images/wall-clock.svg';
import notification from 'assets/images/Icon material-notifications-active.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCatalogDetails,
  getLabourStatusDetails,
  SERVER,
  SERVER_NAME,
  setLocaleWithFallback,
  update90DaysData,
  updateProfileData,
  updateUploadFile,
} from 'store/actions/user.actions';
import { Translate } from 'react-redux-i18n';

import search from 'assets/images/Icon ionic-ios-search.svg';
import closeIcon from 'assets/images/close-01.svg';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import axios from 'axios';
import { getTokenCookie, showToast } from 'utils/tools';
import { CancelRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { LinearProgress, Pagination, Stack } from '@mui/material';
import usePagination from './Pagination';

const KBOCWWB = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    // activestep: users.profile_details.activestep,
    activestep: 1,
    percentComplete: users.profile_details.percentComplete,
    editmode: users.profile_details.editmode,
    statusActive: '',
    statusActiveLi: '',
    appli_ref_no: null,
    start_date: null,
    end_date: null,
    dateSelected: false,
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [aadhar_no, setaadhar_no] = React.useState(null);
  const [mob_no, setmob_no] = React.useState(null);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [callUpdate, setCallUpdateFreeze] = React.useState(false);
  const handleChange1 = (event) => {
    setaadhar_no(event.target.value);
  };
  const handleChange2 = (event) => {
    setmob_no(event.target.value);
  };
  const handleComment = (ev) => {
    console.log('Comments', ev.target.value);
    setComment(ev.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [dept_id, setDeptId] = React.useState();
  const [userId, setUserId] = React.useState();
  const [btnName2, setBtnName2] = React.useState('');
  const [showRemarks, setShowRemarks] = React.useState('');
  const [aadhaarError, setAadhaarError] = React.useState('');
  const [mobError, setMobError] = React.useState('');
  const [appNoError, setAppNoError] = React.useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const handleOpen = async (dept_user_id, labour_user_id, btn_name) => {
    setOpen(true);
    setDeptId(dept_user_id);
    setUserId(labour_user_id);
    setBtnName2(btn_name);
    // console.log("DialogData",dept_user_id,labour_user_id,btn_name);
  };

  const handleClose = () => {
    // setCallUpdateFreeze(true)
    console.log('Called');
    setComment('');
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target);
    console.log(event.target.value);
    setAllValues({
      ...allValues,

      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'language')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const onItemClickHandler = (num) => {
    setAllValues({
      ...allValues,
      activestep: num,
    });
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: '350px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const [Loading, setLoading] = React.useState(true);
  const [pendingstatus, setpendingStatus] = React.useState(null);

  const [comment, setComment] = React.useState('');
  const [btnName, setBtnName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElksu, setAnchorElksu] = React.useState(null);
  const [anchorElconfig, setAnchorElconfig] = React.useState(null);
  const [anchorElmapping, setAnchorElmapping] = React.useState(null);

  const [startDate, setstartDate] = React.useState(null);
  const [endDate, setendDate] = React.useState(null);

  const [paginationData, setPaginationData] = useState(null);

  const onSelectStatusHandler = (event, data) => {
    dispatch(getLabourStatusDetails('delete'));

    setAllValues({
      ...allValues,
      statusActiveLi: data,
    });
    handleClosekboc();
  };

  const onSelectClickHandlerkboc = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosekboc = (event, data) => {
    setAnchorEl(null);
  };

  const [regstatus, setregstatus] = React.useState([]);

  useEffect(() => {
    if (isUpdate) {
      console.log('Updated');
    }
  }, [isUpdate]);

  const handleShowRemarks = (from_loc, comments) => {
    handleOpen();
    setShowRemarks(comments);
    setBtnName(from_loc);
  };

  const handleActivate = async (dept_user_id, labour_user_id, btn_name) => {
    handleOpen(dept_user_id, labour_user_id, btn_name);

    // handleUpdateFreeze(dept_user_id,labour_user_id,btn_name)

    setBtnName(btn_name);
  };

  console.log('Remarks:', showRemarks);

  const handleUpdateFreeze = async () => {
    console.log('Submit Called');
    console.log(dept_id, userId, btnName2, comment);

    if (comment !== '' && comment !== undefined && comment.length > 0) {
      if (btnName2 === 'activate') {
        // if(isUpdate){
        var data = {
          login_user_id: dept_id,
          id: userId,
          is_seva_sindhu: 0,
          is_freeze: false,
          active_inactive_comment: comment,
        };
        console.log('Freezed Data:', data);
        var config = {
          method: 'post',
          url: SERVER + '/user/update_freeze_or_unfreeze_labour',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };
        await axios(config)
          .then((response) => {
            console.log('FreezeResponse', response);
            setBtnName2('Deactivate');
          })
          .catch((error) => {
            console.log(error);
          });
        showToast('SUCCESS', 'Account has been activated');
      } else if (btnName2 === 'deactivate') {
        var data2 = {
          login_user_id: dept_id,
          id: userId,
          is_seva_sindhu: 0,
          is_freeze: true,
          active_inactive_comment: comment,
        };
        console.log('Freezed Data:', data);
        var config2 = {
          method: 'post',
          url: SERVER + '/user/update_freeze_or_unfreeze_labour',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data2,
        };
        await axios(config2)
          .then((response) => {
            console.log('FreezeResponse', response);
            setBtnName2('Activate');
          })
          .catch((error) => {
            console.log(error);
          });
        showToast('SUCCESS', 'Account has been deactivated');
      }
    }
    setOpen(false);
    setComment('');

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  console.log('StatusAct', allValues.statusActiveLi);

  const count =
    users.getLabourStatusDetails &&
    Math.ceil(users.getLabourStatusDetails.length / PER_PAGE);
  let _DATA = usePagination(
    (users.getLabourStatusDetails && users.getLabourStatusDetails) || [],
    PER_PAGE,
  );

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  console.log('DATAKBOCWWB::', _DATA.currentData());

  useEffect(() => {
    if (regstatus !== [] && regstatus.length > 0) {
      var pendingStatus = null;
      console.error('regstatus.length: ' + regstatus.length);
      for (var i = 0; i < regstatus.length; i++) {
        console.error('regstatus: ' + JSON.stringify(regstatus[i]));
        if (regstatus[i].value === 'Pending') {
          console.error('pending status: ' + regstatus[i].value_id);
          pendingStatus = regstatus[i].value_id;

          setAllValues({
            ...allValues,
            statusActive: 'active',
            statusActiveLi: regstatus[i].value,
          });
        }
      }
      let dataToSubmit = {
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        board_id: users.getDeptUserRole.data[0].board_id,
        registration_status_id: pendingStatus,
        is_date_selected: allValues.dateSelected ? 1 : 0,
        start_date: allValues.start_date,
        end_date: allValues.end_date,
        acck_no: allValues.appli_ref_no,
        mobile_no: mob_no,
        aadhaar_no: aadhar_no,
      };
      dispatch(getLabourStatusDetails(dataToSubmit));
    }
  }, [regstatus]);

  console.log(regstatus, 'RegStatus');

  useEffect(() => {
    if (mob_no !== null && mob_no.length === 10) {
      let dataToSubmit = {
        board_id: users.getDeptUserRole.data[0].board_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        registration_status_id: pendingstatus,
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
        is_date_selected: allValues.dateSelected ? 1 : 0,
        start_date: allValues.start_date,
        end_date: allValues.end_date,
        acck_no: allValues.appli_ref_no,
        mobile_no: mob_no,
        aadhaar_no: aadhar_no,
      };

      dispatch(getLabourStatusDetails(dataToSubmit));
    } else {
      if (mob_no == '') {
        {
          let dataToSubmit = {
            board_id: users.getDeptUserRole.data[0].board_id,
            circle_id: allValues.circleActiveLi,
            role_id: users.getDeptUserRole.data[0].role_id,
            registration_status_id: pendingstatus,
            department_user_id:
              users.getDeptUserRole.data[0].department_user_id,
            is_date_selected: allValues.dateSelected ? 1 : 0,
            start_date: allValues.start_date,
            end_date: allValues.end_date,
            acck_no: allValues.appli_ref_no,
            mobile_no: null,
            aadhaar_no: aadhar_no,
          };

          dispatch(getLabourStatusDetails(dataToSubmit));
        }
      }
    }
  }, [mob_no]);

  useEffect(() => {
    if (aadhar_no !== null && aadhar_no.length === 12) {
      {
        let dataToSubmit = {
          board_id: users.getDeptUserRole.data[0].board_id,
          role_id: users.getDeptUserRole.data[0].role_id,
          registration_status_id: pendingstatus,
          department_user_id: users.getDeptUserRole.data[0].department_user_id,
          is_date_selected: allValues.dateSelected ? 1 : 0,
          start_date: allValues.start_date,
          end_date: allValues.end_date,
          acck_no: allValues.appli_ref_no,
          mobile_no: mob_no,
          aadhaar_no: aadhar_no,
        };

        dispatch(getLabourStatusDetails(dataToSubmit));
      }
    } else {
      if (aadhar_no == '') {
        {
          let dataToSubmit = {
            board_id: users.getDeptUserRole.data[0].board_id,
            role_id: users.getDeptUserRole.data[0].role_id,
            registration_status_id: pendingstatus,
            department_user_id:
              users.getDeptUserRole.data[0].department_user_id,
            is_date_selected: allValues.dateSelected ? 1 : 0,
            start_date: allValues.start_date,
            end_date: allValues.end_date,
            acck_no: allValues.appli_ref_no,
            mobile_no: mob_no,
            aadhaar_no: null,
          };

          dispatch(getLabourStatusDetails(dataToSubmit));
        }
      }
    }
  }, [aadhar_no]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (startDate !== null) {
      setAllValues({
        ...allValues,
        start_date: new Date(startDate),
        dateSelected: true,
      });

      console.log('start_date', allValues.start_date);
    }
  }, [startDate]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (endDate !== null) {
      setAllValues({ ...allValues, end_date: endDate, dateSelected: true });
      console.log('endd_date' + allValues.end_date);
    }
  }, [endDate]);

  useEffect(() => {
    console.error(
      'useeffect allValues.statusActiveLi: ' + allValues.statusActiveLi,
    );
    if (regstatus !== [] && regstatus.length > 0) {
      setLoading(true);
      var selectedStatus = null;
      console.error('regstatus.length: ' + regstatus.length);
      for (var i = 0; i < regstatus.length; i++) {
        console.error('regstatus: ' + JSON.stringify(regstatus[i]));

        if (regstatus[i].value === allValues.statusActiveLi) {
          console.error('selected status: ' + regstatus[i].value_id);
          selectedStatus = regstatus[i].value_id;
        }
      }
      setpendingStatus(selectedStatus);

      let dataToSubmit = {
        department_user_id: users.getDeptUserRole.data[0].department_user_id,
        role_id: users.getDeptUserRole.data[0].role_id,
        board_id: users.getDeptUserRole.data[0].board_id,
        registration_status_id: selectedStatus,
        is_date_selected: allValues.dateSelected ? 1 : 0,
        start_date: allValues.start_date,
        end_date: allValues.end_date,
        acck_no: allValues.appli_ref_no,
        mobile_no: mob_no,
        aadhaar_no: aadhar_no,
      };

      dispatch(getLabourStatusDetails(dataToSubmit));
    }
  }, [allValues]);

  useEffect(() => {
    if (
      users.getLabourStatusDetails !== undefined &&
      users.getLabourStatusDetails !== null
      // && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0
    ) {
      setLoading(false);
    }
  }, [users.getLabourStatusDetails]);

  useEffect(() => {
    // console.log("in useEffect [users.user]")
    // if (users.user !== null && users.user.board_id !== null) {

    let dataForCatalog = { board_id: 1, catalog_name: 'Registration Status' };
    // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))

    dispatch(getCatalogDetails(dataForCatalog));
    // }
    // }
  }, [users.user]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.catalog_details !== null &&
      users.catalog_details.error !== undefined &&
      users.catalog_details.error !== null &&
      users.catalog_details.error === true
    ) {
      console.log('ERROR: ' + JSON.stringify(users.catalog_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]['name'] === 'Registration Status'
    ) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      setregstatus(users.catalog_details);
    }
  }, [users.catalog_details]);

  const EcardClicked = (uid, bid) => {
    console.log('EcardClicked: ', `/e-card/uid=${uid}_bid=${bid}`);
    // props.history.push(`/e-card/uid=${uid}_bid=${bid}`);
    const win = window.open(`/e-card/uid=${uid}_bid=${bid}`, '_blank');
    win.focus();
  };

  return (
    <>
      {/* <div className="root"> */}
      {/* <CssBaseline /> */}
      {/* <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={notification} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.login_admin.data.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
                </div> */}

      <Row className="kbocwwb-page">
        <Col xs={12}>
          <p className="title">Labour Registration Details</p>
        </Col>
        {/* <Col xs={3} className="kbocwwb-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <img alt="..." className="cal-icon"/>
                            <p>From Date</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}
        {/* <Col xs={2} className="kbocwwb-col todate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <img alt="..." className="cal-icon"/>
                            <p>To Date</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}
        <Col xs={2} className="kbocwwb-col">
          <div
            aria-controls="customized-menu"
            aria-haspopup="true"
            className={`kbocwwb-col-div hvr-icon-hang ${allValues.statusActive}`}
            onClick={(e) => onSelectClickHandlerkboc(e, 'kbocwwb')}
            style={{ height: '50px' }}
          >
            <p>Status</p>
            <img alt="..." className="hvr-icon" />
          </div>
          <StyledMenu
            id="customized-menu"
            className="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(e) => handleClosekboc(e, 'kbocwwb')}
          >
            {/* <MenuItem onClick={(e) => onSelectStatusHandler(e,"Pending")} className={allValues.statusActiveLi === "Pending" ? "active" : null}>
                                <ListItemText primary="Pending"/>
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Approved")} className={allValues.statusActiveLi === "Approved" ? "active" : null}> 
                                <ListItemText primary="Approved" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Rejected")} className={allValues.statusActiveLi === "Rejected" ? "active" : null}>
                                <ListItemText primary="Rejected" />
                            </MenuItem> */}

            {regstatus.map((row) => (
              // users.getDeptUserRole.data[0].role_id === 3 ?
              // ?
              <MenuItem
                onClick={(e) => onSelectStatusHandler(e, row.value)}
                className={
                  allValues.statusActiveLi === row.value ? 'active' : null
                }
              >
                <ListItemText primary={row.value} />
              </MenuItem>
              // :
              // null
            ))}
          </StyledMenu>
        </Col>

        <Col xs={2} className="benificiaries-col ">
          <div
            aria-controls="customized-menu"
            aria-haspopup="true"
            className="benificiaries-col-div hvr-icon-hang"
            style={{ height: '50px' }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="datepicker"
                // margin="normal"
                format="DD/MM/YYYY"
                value={allValues.start_date}
                placeholder="Start Date"
                onChange={(date) => {
                  setstartDate(date);
                }}

                // InputProps={{
                //     endAdornment:
                //         <InputAdornment position="end">
                //             <img alt="..." src={search} className="language-img" />

                //             {/* {
                //                 allValues.start_date === null || allValues.start_date === ""  ?
                //                     <img alt="..." src={search} className="language-img" />
                //                     :
                //                     <img alt="..." src={closeIcon}
                //                     onClick={() => {
                //                         setAllValues({
                //                             ...allValues,
                //                             start_date: "",
                //                         })
                //                     }}
                //                     className="language-img closebtnsearch" />
                //             } */}

                //         </InputAdornment>
                // }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Col>
        <Col xs={2} className="benificiaries-col ">
          <div
            aria-controls="customized-menu"
            aria-haspopup="true"
            className="benificiaries-col-div hvr-icon-hang"
            style={{ height: '50px' }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="datepicker"
                // margin="normal"
                format="DD/MM/YYYY"
                value={allValues.end_date}
                onChange={(date) => {
                  setendDate(date);
                }}
                placeholder="End Date"
              />
            </MuiPickersUtilsProvider>
          </div>
        </Col>
        <Col xs={2} className="benificiaries-col ">
          <div
            aria-controls="customized-menu"
            aria-haspopup="true"
            className="benificiaries-col-div hvr-icon-hang"
            style={{ height: '50px' }}
          >
            <TextField
              variant="outlined"
              labelId="demo-simple-select-required-label"
              placeholder="App No"
              name="appli_ref_no"
              value={allValues.appli_ref_no}
              onChange={handleChange}
              error={aadhaarError}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9A-Z]/gi, '');
                if (e.target.value.length === 0) {
                  setAppNoError('');
                } else if (e.target.value.length < 18) {
                  setAppNoError(
                    'Application Number should contain 18 characters',
                  );
                } else {
                  setAppNoError('');
                }
              }}
              inputProps={{ minLength: 0, maxLength: 18 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {allValues.appli_ref_no === null ||
                    allValues.appli_ref_no === '' ? (
                      <img alt="..." src={search} className="language-img" />
                    ) : (
                      <img
                        alt="..."
                        src={closeIcon}
                        onClick={() => {
                          setAllValues({
                            ...allValues,
                            appli_ref_no: '',
                          });
                        }}
                        className="language-img closebtnsearch"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            >
              {' '}
            </TextField>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography style={{ color: '#CA0027' }}>{appNoError}</Typography>
          </div>
        </Col>

        {/* <Col xs={2} className="benificiaries-col ">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <TextField 
                             variant="outlined"
                             labelId="demo-simple-select-required-label"
                            placeholder='Aadhaar No'
                            name = 'aadhar_no'
                            value = {aadhar_no}
                            onChange={handleChange1}
                            error={aadhaarError}
                            onInput={(e)=>{
                                        e.target.value = e.target.value.replace(/[^0-9]/ig,'')
                                        if(e.target.value.length === 0){
                                            setAadhaarError("")
                                        }
                                         else if (e.target.value.length < 12){
                                            setAadhaarError("Aadhaar Number should contain 12 digits")
                                        }
                                        else{
                                            setAadhaarError("")
                                        }
                                    }}
                                    inputProps={{minLength:0,maxLength:12}}
                            
                        
                            >  </TextField>
                            
                            
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'row',width:'100%'}}>
                            <Typography style={{color:'#CA0027'}}>{aadhaarError}</Typography>
                            </div>
                        
                    </Col> */}
        <Col xs={3} className="benificiaries-col ">
          <div
            aria-controls="customized-menu"
            aria-haspopup="true"
            className="benificiaries-col-div hvr-icon-hang"
            style={{ height: '50px' }}
          >
            <TextField
              variant="outlined"
              labelId="demo-simple-select-required-label"
              placeholder="Mobile No"
              name="mob_no"
              value={mob_no}
              onChange={handleChange2}
              error={mobError}
              // helperText={mobError}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/gi, '');
                if (e.target.value.length === 0) {
                  setMobError('');
                } else if (e.target.value.length < 10) {
                  setMobError('Mobile number should contain 10 digits');
                } else {
                  setMobError('');
                }
              }}
              inputProps={{ minLength: 0, maxLength: 10 }}
            ></TextField>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography style={{ color: '#CA0027' }}>{mobError}</Typography>
          </div>
        </Col>

        {/* <Col xs={3} className="kbocwwb-col">
                        <div aria-controls="customized-menu2" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang">
                            <p>Due for Approval</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col>
                    <Col xs={2} className="kbocwwb-col">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>Circle</p>
                            <img alt="..." className="hvr-icon"/>
                        </div>
                    </Col> */}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Remarks</DialogTitle>
          <DialogContent style={{ width: '500px' }}>
            {btnName === 'viewremarks' ? (
              <textarea
                rows={4}
                style={{
                  border: 'none',
                  width: '450px',
                  resize: 'none !important',
                }}
                // autoFocus
                // margin="dense"
                // id="name"
                placeholder="Comment"
                type="email"
                // fullWidth
                value={showRemarks}
                // onChange={handleComment}
                // variant="outlined"
                maxLength={200}
              />
            ) : (
              <textarea
                rows={4}
                draggable="false"
                style={{ border: '0.5px solid black', width: '450px' }}
                // autoFocus
                // margin="dense"
                // id="name"
                placeholder="Comment"
                type="email"
                // fullWidth
                value={comment}
                onChange={handleComment}
                // variant="outlined"
                maxLength={200}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>CANCEL</Button>
            {btnName !== 'viewremarks' ? (
              <Button variant="success" onClick={handleUpdateFreeze}>
                Submit
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>

        <Col xs={12}>
          <div className="footer-buttons">
            <Stack spacing={2} className="p-2 pagination_noti">
              {/* {console.log(totalPages,"TotalPages2:")} */}
              <Pagination
                // showFirstButton showLastButton
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
            {}
            {/* <Button onClick={handleLoadMore}>Load More</Button> */}
          </div>

          <TableContainer
            className="w-100"
            // component={Paper}
          >
            <Table
              className="mt-4 w-100"
              aria-label="simple table"
              style={{ border: '0.5px solid #D9D4D4', overflowX: 'scroll' }}
            >
              {!Loading &&
              users.getLabourStatusDetails !== null &&
              users.getLabourStatusDetails !== undefined &&
              users.getLabourStatusDetails.length > 0 ? (
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#4169e1' }} align="center">
                      Application Date
                    </TableCell>
                    <TableCell style={{ color: '#4169e1' }} align="center">
                      Name
                    </TableCell>
                    <TableCell style={{ color: '#4169e1' }} align="center">
                      Application Number
                    </TableCell>

                    <TableCell style={{ color: '#4169e1' }} align="center">
                      Application Status
                    </TableCell>
                    <TableCell style={{ color: '#4169e1' }} align="center">
                      View
                    </TableCell>
                    <TableCell style={{ color: '#4169e1' }} align="center">
                      Sakala Number
                    </TableCell>
                    {allValues.statusActiveLi === 'Pending' ? (
                      <TableCell style={{ color: '#4169e1' }} align="center">
                        Sakala Timeline
                      </TableCell>
                    ) : null}
                    {allValues.statusActiveLi === 'Approved' ? (
                      <TableCell style={{ color: '#4169e1' }} align="center">
                        View E-Card
                      </TableCell>
                    ) : null}

                    {users.getDeptUserRole.data[0].role_id &&
                    users.getDeptUserRole.data[0].role_id === 1 &&
                    allValues.statusActiveLi === 'Approved' ? (
                      <TableCell style={{ color: '#4169e1' }} align="center">
                        Activate/
                        <br />
                        Deactivate
                      </TableCell>
                    ) : null}
                    {users.getDeptUserRole.data[0].role_id &&
                    users.getDeptUserRole.data[0].role_id === 1 &&
                    allValues.statusActiveLi === 'Approved' ? (
                      <TableCell style={{ color: '#4169e1' }} align="center">
                        Remarks
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody style={{ padding: '0px' }}>
                {Loading ? (
                  <>
                    <LinearProgress />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '24px',
                      }}
                    >
                      <h1 style={{ marginTop: '24px', fontStyle: 'oblique' }}>
                        Loading.....
                      </h1>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      {
                        Pending: (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails !== undefined &&
                                  users.getLabourStatusDetails !== null &&
                                  users.getLabourStatusDetails !==
                                    'Unauthorized' &&
                                  users.getLabourStatusDetails.length > 0 &&
                                  _DATA.currentData() &&
                                  _DATA.currentData().map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>
                                      <TableCell align="center">
                                        <img
                                          alt="..."
                                          src={Pending}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        Pending
                                      </TableCell>

                                      <TableCell align="center">
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            boardid:row.board_id,
                                                            showbuttons: true,
                                                            frompage: "Pending"
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Pending'}/${'Registration'}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />{' '}
                                          {/*View */}
                                        </Link>
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.sla_days} days left for Approval
                                      </TableCell>
                                      <img
                                        className="img-sakala"
                                        alt="..."
                                        src={Sakala}
                                      />
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              // _DATA.currentData().length < 0 && (
                              //     <p>No Data found</p>
                              // )

                              <p>No Pedning Applications</p>
                            )}
                          </>
                        ),
                        Approved: (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails &&
                                  _DATA.currentData() &&
                                  _DATA.currentData().map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="approved-li"
                                      >
                                        <img
                                          alt="..."
                                          src={Check}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        Approved
                                      </TableCell>

                                      <TableCell align="center" className="">
                                        {/* <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: false
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Approved'}/${'Registration'}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />{' '}
                                          {/*View*/}
                                        </Link>
                                        {/* Details */}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>

                                      <TableCell align="center" className="">
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            EcardClicked(row.labour_user_id, 1)
                                          }
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />{' '}
                                          {/* View <br/> E-Card*/}
                                        </Link>
                                      </TableCell>
                                      {users.getDeptUserRole.data[0].role_id &&
                                      users.getDeptUserRole.data[0].role_id ===
                                        1 ? (
                                        <>
                                          <TableCell align="right">
                                            {row.IS_Freezed === true ? (
                                              <Button
                                                onClick={() =>
                                                  handleActivate(
                                                    row.department_user_id,
                                                    row.labour_user_id,
                                                    'activate',
                                                    row.active_inactive_comment,
                                                  )
                                                }
                                                size="sm"
                                                className="w-100"
                                                variant="success"
                                              >
                                                ACTIVATE
                                              </Button>
                                            ) : row.IS_Freezed === false ||
                                              row.IS_Freezed === null ? (
                                              <Button
                                                onClick={() =>
                                                  handleActivate(
                                                    row.department_user_id,
                                                    row.labour_user_id,
                                                    'deactivate',
                                                    row.active_inactive_comment,
                                                  )
                                                }
                                                size="sm"
                                                className="w-100"
                                                variant="danger"
                                              >
                                                DEACTIVATE
                                              </Button>
                                            ) : (
                                              ''
                                            )}
                                          </TableCell>

                                          <TableCell align="center">
                                            {row.active_inactive_comment !==
                                            null ? (
                                              <Tooltip2
                                                title="View Remarks"
                                                placement="top"
                                              >
                                                <img
                                                  onClick={() =>
                                                    handleShowRemarks(
                                                      'viewremarks',
                                                      row.active_inactive_comment,
                                                    )
                                                  }
                                                  alt="..."
                                                  src={Eye}
                                                  className="mr-2"
                                                  style={{
                                                    marginRight: '8px',
                                                    cursor: 'pointer',
                                                  }}
                                                />
                                              </Tooltip2>
                                            ) : (
                                              <VisibilityOffIcon
                                                sx={{
                                                  marginRight: '8px',
                                                  fontSize: 20,
                                                  color: '#5A5353',
                                                }}
                                              />
                                            )}{' '}
                                          </TableCell>
                                        </>
                                      ) : null}
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              <p>No Approved applications</p>
                            )}
                          </>
                        ),
                        Rejected: (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails &&
                                  _DATA.currentData() &&
                                  _DATA.currentData().map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ color: '#DB0909' }}
                                      >
                                        {/* <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        <CancelRounded
                                          style={{
                                            color: '#DB0909',
                                            marginRight: '8px',
                                          }}
                                          className="mr-2"
                                        />
                                        Rejected
                                      </TableCell>
                                      {/* <TableCell align="center" style={{color: '#DB0909'}}>Application Rejected reason</TableCell> */}
                                      <TableCell
                                        align="center"
                                        className="details-li"
                                      >
                                        {/* <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: false
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Rejected'}/${'Registration'}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          View
                                        </Link>
                                        {/* Details */}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              <p>No Rejected applications</p>
                            )}
                          </>
                        ),
                        'Draft Approved': (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails &&
                                  _DATA.currentData() &&
                                  _DATA.currentData().map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="approved-li"
                                      >
                                        <img
                                          alt="..."
                                          src={Check}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        Draft Approved
                                      </TableCell>
                                      {/* <TableCell align="center" style={{color: '#DB0909'}}>Application Rejected reason</TableCell> */}
                                      <TableCell
                                        align="center"
                                        className="details-li"
                                      >
                                        {/* <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: false
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftApproved'}/${'Registration'}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          View
                                        </Link>
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>
                                      {/* Details */}
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              <p>No Draft Approved Applications</p>
                            )}
                          </>
                        ),
                        'Draft Rejected': (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails &&
                                  _DATA.currentData() &&
                                  _DATA.currentData().map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ color: '#DB0909' }}
                                      >
                                        {/* <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        <CancelRounded
                                          style={{
                                            color: '#DB0909',
                                            marginRight: '8px',
                                          }}
                                          className="mr-2"
                                        />
                                        Draft Rejected
                                      </TableCell>
                                      {/* <TableCell align="center" style={{color: '#DB0909'}}>Application Rejected reason</TableCell> */}
                                      <TableCell
                                        align="center"
                                        className="details-li"
                                      >
                                        {/* <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/> */}
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: false
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftRejected'}/${'Registration'}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          View
                                        </Link>
                                        <TableCell align="center">
                                          {row.gsc_no && row.gsc_no
                                            ? row.gsc_no
                                            : 'N/A'}
                                        </TableCell>
                                        {/* Details */}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              <p>No Draft Approved Applications</p>
                            )}
                          </>
                        ),
                        Appealed: (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails.map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(
                                        row.application_date,
                                        'YYYY-MM-DD',
                                      ).format('DD/MM/YYYY')}
                                    </TableCell>

                                    <TableCell align="center">
                                      {row.labour_full_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.application_no}
                                    </TableCell>
                                    <TableCell align="center">
                                      <img
                                        alt="..."
                                        src={Pending}
                                        className="mr-2"
                                        style={{ marginRight: '8px' }}
                                      />
                                      Appealed
                                    </TableCell>

                                    <TableCell align="center">
                                      {/* <Link 
                                                        // to="/admin/details" 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: true,
                                                            frompage: "Appealed"
                                                        }}
                                                        > */}
                                      <Link
                                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Appealed'}/${'Registration'}`}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        View
                                      </Link>
                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>
                                      {/* <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View</TableCell> */}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <p>No Appealed applications</p>
                            )}
                          </>
                        ),
                        'Edit Profile Request Approved': (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails.map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell align="center">
                                      {moment(
                                        row.application_date,
                                        'YYYY-MM-DD',
                                      ).format('DD/MM/YYYY')}
                                    </TableCell>

                                    <TableCell align="center">
                                      {row.labour_full_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.application_no}
                                    </TableCell>

                                    <TableCell align="center">
                                      <img
                                        alt="..."
                                        src={Check}
                                        className="mr-2"
                                        style={{ marginRight: '8px' }}
                                      />
                                      Approved
                                    </TableCell>

                                    {/* <TableCell align="center">
                                                        <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>Details
                                                    </TableCell> */}
                                    <TableCell align="center">
                                      {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: false,
                                                            frompage: "EditPending"
                                                        }}
                                                        > */}
                                      <Link
                                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'EditApproved'}/${'Registration'}`}
                                      >
                                        <img
                                          alt="..."
                                          src={Eye}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        Details
                                      </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.gsc_no && row.gsc_no
                                        ? row.gsc_no
                                        : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <p>
                                No 'Edit Profile Request Approved' applications
                              </p>
                            )}
                          </>
                        ),
                        'Edit Profile Request Pending': (
                          <>
                            {users.getLabourStatusDetails !== undefined &&
                            users.getLabourStatusDetails !== null &&
                            users.getLabourStatusDetails !== 'Unauthorized' &&
                            users.getLabourStatusDetails.length > 0 ? (
                              <>
                                {users.getLabourStatusDetails !== undefined &&
                                  users.getLabourStatusDetails !== null &&
                                  users.getLabourStatusDetails !==
                                    'Unauthorized' &&
                                  users.getLabourStatusDetails.length > 0 &&
                                  users.getLabourStatusDetails.map((row) => (
                                    <TableRow key={row.name}>
                                      <TableCell align="center">
                                        {moment(
                                          row.application_date,
                                          'YYYY-MM-DD',
                                        ).format('DD/MM/YYYY')}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.labour_full_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.application_no}
                                      </TableCell>
                                      <TableCell align="center">
                                        <img
                                          alt="..."
                                          src={Pending}
                                          className="mr-2"
                                          style={{ marginRight: '8px' }}
                                        />
                                        Pending
                                      </TableCell>

                                      <TableCell align="center">
                                        {/* <Link 
                                                        to ={{ 
                                                            pathname: "/admin/details", 
                                                            userid: row.labour_user_id,
                                                            showbuttons: true,
                                                            frompage: "EditPending",
                                                            row: row
                                                        }}
                                                        > */}
                                        <Link
                                          to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'EditPending'}/${'Registration'}/${row.id}`}
                                        >
                                          <img
                                            alt="..."
                                            src={Eye}
                                            className="mr-2"
                                            style={{ marginRight: '8px' }}
                                          />
                                          View
                                        </Link>
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.gsc_no && row.gsc_no
                                          ? row.gsc_no
                                          : 'N/A'}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </>
                            ) : (
                              <p>
                                No 'Edit Profile Request Pending' applications
                              </p>
                            )}
                          </>
                        ),
                      }[allValues.statusActiveLi]
                    }
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
};

export default KBOCWWB;
