// import {
//   Chip,
//   CircularProgress,
//   FormControl,
//   TextField,
// } from '@material-ui/core';
// import moment from 'moment';
// import React, { Suspense, useEffect } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { getFieldsValueAPI } from './services/schemes.service';
// import { useState } from 'react';

// const SchemeFields = ({ fields }) => {
//   const { schemeName } = useParams();

//   return (
//     <>
//       <Suspense fallback={<CircularProgress color="inherit" />}>
//         <Row className="scheme-subtitle-row">
//           <Col xs={12} className="searchform-subtitle">
//             {<p>{schemeName} Fields</p>}
//           </Col>
//         </Row>
//         <Row className="form-row mt-4 config-form searchform">
//           {fields &&
//             fields.map((field) => (
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{ alignSelf: 'end' }}
//                 key={field.field}
//               >
//                 <p className="mb-2 mt-4">
//                   {field.field}

//                   {field?.field ===
//                     'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment' &&
//                     !field?.is_text_value ? (
//                     <>
//                       &nbsp; &nbsp;
//                       <Chip
//                         size="small"
//                         label="Other Treatment"
//                         color="secondary"
//                       />
//                     </>
//                   ) : field?.field === 'Hospital/Clinic name' &&
//                     !field?.is_text_value ? (
//                     <>
//                       &nbsp; &nbsp;
//                       <Chip
//                         size="small"
//                         label="Other Hospital"
//                         color="secondary"
//                       />
//                     </>
//                   ) : null}
//                 </p>
//                 <FormControl fullWidth className="formcontrol1">
//                   <TextField
//                     variant="outlined"
//                     value={field.field_value}
//                     disabled
//                   />
//                 </FormControl>
//               </Col>
//             ))}
//         </Row>
//       </Suspense>
//     </>
//   );
// };

// export default SchemeFields;


import {
  Chip,
  CircularProgress,
  FormControl,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import React, { Suspense } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const beneficiaryFields = [
  'Accident Resulted In',
  'Employer Name at the time of accident',
  'Employer address at the time of accident',
  'Date of death',
  'Age at the time of death',
  'Date of Accident',
  'Age at the time of accident'
];

const SchemeFields = ({ fields }) => {
  const { schemeName } = useParams();

  return (
    <>
      <Suspense fallback={<CircularProgress color="inherit" />}>
        <Row className="scheme-subtitle-row">
          <Col xs={12} className="searchform-subtitle">
            <p>{schemeName} Fields</p>
          </Col>
        </Row>

        {/* Beneficiary Details Section */}
        <Row className="form-row mt-4 config-form searchform">
          <Col xs={12} className="searchform-subtitle">
            <h5>Beneficiary Details</h5>
          </Col>
          {fields &&
            fields
              .filter((field) => beneficiaryFields.includes(field.field))
              .map((field) => (
                <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={field.field}>
                  <p className="mb-2 mt-4">{field.field}</p>
                  <FormControl fullWidth className="formcontrol1">
                    <TextField variant="outlined" value={field.field_value} disabled />
                  </FormControl>
                </Col>
              ))}
        </Row>

        {/* Applicant/Nominee Details Section */}
        <Row className="form-row mt-4 config-form searchform">
          <Col xs={12} className="searchform-subtitle">
            <h5>Applicant/Nominee Details</h5>
          </Col>
          {fields &&
            fields
              .filter((field) => !beneficiaryFields.includes(field.field))
              .map((field) => (
                <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={field.field}>
                  <p className="mb-2 mt-4">
                    {field.field}
                    {field?.field ===
                      'Mention name of major ailment specified below for which the beneficiary/Dependent has taken treatment' &&
                      !field?.is_text_value ? (
                      <>
                        &nbsp; &nbsp;
                        <Chip size="small" label="Other Treatment" color="secondary" />
                      </>
                    ) : field?.field === 'Hospital/Clinic name' && !field?.is_text_value ? (
                      <>
                        &nbsp; &nbsp;
                        <Chip size="small" label="Other Hospital" color="secondary" />
                      </>
                    ) : null}
                  </p>
                  <FormControl fullWidth className="formcontrol1">
                    <TextField variant="outlined" value={field.field_value} disabled />
                  </FormControl>
                </Col>
              ))}
        </Row>
      </Suspense>
    </>
  );
};

export default SchemeFields;
