import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { Required, showToast } from 'utils/tools';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
const RenewalDocuments = ({
  nintyDaysDoc,
  paySlipDoc,
  selfDeclarationDoc,
  nintyDaysDocRef,
  paySlipDocRef,
  selfDeclarationDocRef,
  handleNintyDaysFile,
  handlePaySlipsFile,
  handleSelfDeclarationFile,
  handleRemoveNintyDaysFile,
  handleRemovePaySlipsFile,
  handleRemoveSelfDeclarationFiles,
}) => {
  return (
    <>
      <Row className="nintydays-cert-title-row">
        <Col xs={12} className="profile-title">
          <h1>
            <Translate value="documents" />
          </h1>
        </Col>
      </Row>
      <Row className='form-row ninydays-row"'>
        <Col xs={12} className="nintydays-col-6">
          {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
          <Required
            className="mt-3 mb-2"
            title={<Translate value="uploadYourDaysWorkingCertificate" />}
          />

          <div className="browsebutton-outerdiv">
            <div className="w-100">
              {nintyDaysDoc &&
                nintyDaysDoc.length > 0 &&
                nintyDaysDoc.map((file, index) => (
                  <FormControl fullWidth className="formcontrol3 w-100">
                    <TextField
                      className="w-100"
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              <IconButton
                                onClick={() => handleRemoveNintyDaysFile(index)}
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}

              {nintyDaysDoc && nintyDaysDoc.length === 0 && (
                <FormControl fullWidth className="formcontrol3 w-100">
                  <TextField
                    className="w-100"
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    disabled
                  />
                </FormControl>
              )}
            </div>
            <div className="browsebutton2-div">
              <div className="">
                <input
                  type="file"
                  ref={nintyDaysDocRef}
                  onChange={handleNintyDaysFile}
                  multiple
                  hidden
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="py-2 mx-2"
                  startIcon={<FolderIcon />}
                  onClick={() => nintyDaysDocRef.current.click()}
                >
                  Browse
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="nintydays-col-6">
          <p className="mt-3 mb-2">
            <Translate value="uploadPaySlip" />
          </p>

          <div className="browsebutton-outerdiv">
            <div className="w-100">
              {paySlipDoc &&
                paySlipDoc.length > 0 &&
                paySlipDoc.map((file, index) => (
                  <FormControl fullWidth className="formcontrol3 w-100">
                    <TextField
                      className="w-100"
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              <IconButton
                                onClick={() => handleRemovePaySlipsFile(index)}
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}

              {paySlipDoc && paySlipDoc.length === 0 && (
                <FormControl fullWidth className="formcontrol3 w-100">
                  <TextField
                    className="w-100"
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    disabled
                  />
                </FormControl>
              )}
            </div>
            <div className="browsebutton2-div">
              <div className="">
                <input
                  type="file"
                  ref={paySlipDocRef}
                  onChange={handlePaySlipsFile}
                  multiple
                  hidden
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="py-2 mx-2"
                  startIcon={<FolderIcon />}
                  onClick={() => paySlipDocRef.current.click()}
                >
                  Browse
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="nintydays-col-6">
          {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
          <Required
            className="mt-3 mb-2"
            title={<Translate value="selfdeclaration" />}
          />

          <div className="browsebutton-outerdiv">
            <div className="w-100">
              {selfDeclarationDoc &&
                selfDeclarationDoc.length > 0 &&
                selfDeclarationDoc.map((file, index) => (
                  <FormControl fullWidth className="formcontrol3 w-100">
                    <TextField
                      className="w-100"
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              <IconButton
                                onClick={() =>
                                  handleRemoveSelfDeclarationFiles(index)
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}

              {selfDeclarationDoc && selfDeclarationDoc.length === 0 && (
                <FormControl fullWidth className="formcontrol3 w-100">
                  <TextField
                    className="w-100"
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    disabled
                  />
                </FormControl>
              )}
            </div>
            <div className="browsebutton2-div">
              <div className="">
                <input
                  type="file"
                  ref={selfDeclarationDocRef}
                  onChange={handleSelfDeclarationFile}
                  multiple
                  hidden
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="py-2 mx-2"
                  startIcon={<FolderIcon />}
                  onClick={() => selfDeclarationDocRef.current.click()}
                >
                  Browse
                </Button>
              </div>
            </div>
          </div>

          <p className="mt-4 instructions-ration text-center">
            <span>
              <Translate value="instructions" />* :{' '}
            </span>
            <br />
            <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
            <br />
            <Translate value="sizecontains500KBto2MB" />
          </p>
        </Col>
      </Row>
    </>
  );
};

export default RenewalDocuments;
