import React from 'react';
import { Col, Row } from 'react-bootstrap';
import VerificationDocument from './VerificationDocument';
import MissingDocument from './MissingDocument';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SecondLevelMissingDocument from './SecondLevelMissingDocument';

const DocumentUpload = ({
  verificationDoc,
  verificationDocID,
  verificationDocRef,
  handleVerificationDoc,
  handleRemoveVerificationDoc,
  missingDoc,
  missingDocID,
  missingDocRef,
  handleRemoveMissingDoc,
  handleMissingDoc,
  verificationDocument,
  missingDocument,
  secondLevelMissingDocument,
  missingDocSecondLevel,
  missingDocSecondLevelID,
  missingDocSecondLevelRef,
  handleRemoveMissingDocSecondLevel,
  handleMissingSecondLevelDoc,
}) => {
  const { schemeStatus, approvalAuthority } = useParams();
  return (
    <>
      {schemeStatus === 'Transferred Scheme Application' &&
      +approvalAuthority === 0 ? null : (
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              {schemeStatus === 'Transferred Scheme Application' ||
              schemeStatus === 'Transferred back Scheme Application' ? (
                <p>Verification Document</p>
              ) : (
                <p>Verification Document / Any other missing documents</p>
              )}
            </Col>
          </Row>
          <Row className="form-row config-form searchform">
            <VerificationDocument
              verificationDoc={verificationDoc}
              verificationDocID={verificationDocID}
              verificationDocRef={verificationDocRef}
              handleRemoveVerificationDoc={handleRemoveVerificationDoc}
              handleVerificationDoc={handleVerificationDoc}
              verificationDocument={verificationDocument}
            />
            {schemeStatus === 'Transferred Scheme Application' ? null : (
              <MissingDocument
                missingDoc={missingDoc}
                missingDocID={missingDocID}
                missingDocRef={missingDocRef}
                handleRemoveMissingDoc={handleRemoveMissingDoc}
                handleMissingDoc={handleMissingDoc}
                missingDocument={missingDocument}
              />
            )}
            {schemeStatus === 'Draft Approved' ||
            schemeStatus === 'Draft Rejected' ||
            schemeStatus === 'Approved' ? (
              <SecondLevelMissingDocument
                missingDoc={missingDocSecondLevel}
                missingDocID={missingDocSecondLevelID}
                missingDocRef={missingDocSecondLevelRef}
                handleRemoveMissingDoc={handleRemoveMissingDocSecondLevel}
                handleMissingDoc={handleMissingSecondLevelDoc}
                missingDocument={secondLevelMissingDocument}
              />
            ) : null}
          </Row>
        </>
      )}
    </>
  );
};

export default DocumentUpload;
