import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, InputAdornment, Select, MenuItem, ListItemIcon, ListItemText, CircularProgress, Popover, FormControlLabel, Checkbox, makeStyles, Backdrop } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkGreen from 'assets/images/Tick-01.png'
import usericon from 'assets/images/Icon awesome-user.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import usericn from 'assets/images/Icon awesome-user-circle.svg'
import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import Moment from 'react-moment';
import tick from 'assets/images/check (3) 2.svg'
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getCityDetails, getConfigBoardDetails, getConfigMinistryDetails, getDistrictDetails, getLabourStatusDetails, getStateDetails, getTalukDetails, getUserRegistrationDetails, getVillageDetails, searchuser, SERVER, setLocaleWithFallback, uploadFile } from 'store/actions/user.actions'
import { Link, useParams } from 'react-router-dom';
import { decrypt, encrypt, Required , encryptAuthbridge, decryptAuthbridge, showToast, getTokenCookie} from 'utils/tools';
import { CheckCircle, FormatListBulletedRounded, PhoneAndroid, Visibility } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import validator from 'validator';
import axios from 'axios';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Translate } from 'react-redux-i18n';


const SchemeForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffffee',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        natureOfWork: "",
        nameError: false,
        nameNomineeError: false,
        phoneError: false,
        phoneNomineeError: false,
        formcontrolnum: "",
        aadharnum1: "",
        aadharnum2: "",
        aadharnum3: "",
        aadhar: "",
        openDatePicker: false,

        states: [],
        districts: [],
        taluks: [], 
        cities: [],
        villages: [],
        relations: [],
        naturesOfWork: [],

        state: "",
        district: "",
        taluk: "",
        city: "",
        village: "",
        relation: "",
        natureOfWork: "",

        getUserDetails: null,
        // personal_details: null,

        // applicantName: "",
        get_dynamic_fields: null,

        marriage_assistance_for: "",
        name_of_person_married: "",
        gender: "",
        age: "",
        marriage_date: "",
        marriage_place: "",
        marriage_certifate: "",

        id_cardfileId: "",
        bank_accountfileId: "",
        employee_certfileId: "",
        marriage_certfileId: "",

    })

    let { id } = useParams()
    if(id !== undefined){
        var idName = id.split('_id=')[0]
        var role_id = Number(id.split('_id=')[1])
    }

    const [dob, setDob] = React.useState(users.personal_details.dob)
    
    const classes = useStyles();



    
    const [CircleDetails, setCircleDetails] = React.useState({});


    const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] = React.useState(null);
    const [anchorElninty, setAnchorElninty] = React.useState(null);
    const [anchorElration, setAnchorElration] = React.useState(null);
    
    const openration = Boolean(anchorElration);
    const idration = openration ? "simple-popover" : undefined;
    
    const [anchorElIdCard, setAnchorElIdCard] = React.useState(null);
    const openIdCard = Boolean(anchorElIdCard);
    const idIdCard = openration ? "simple-popover" : undefined;


    const [anchorElbank_account, setAnchorElbank_account] = React.useState(null);
    const openbank_account = Boolean(anchorElbank_account);
    const idbank_account = openration ? "simple-popover" : undefined;

    
    const [anchorElemployee_cert, setAnchorElemployee_cert] = React.useState(null);
    const openemployee_cert = Boolean(anchorElemployee_cert);
    const idemployee_cert = openemployee_cert ? "simple-popover" : undefined;

    
    const [anchorElmarriage_cert, setAnchorElmarriage_cert] = React.useState(null);
    const openmarriage_cert = Boolean(anchorElmarriage_cert);
    const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;
    

    const [loading, setLoading] = React.useState(false);
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);


    const [AckLetterImg, setAckLetterImg] = React.useState("")
    const [ackId, setackId] = React.useState("")

    const getFieldValues = async(field) => {
        return new Promise((resolve, reject) => {
            var dataNEW = JSON.stringify({
                "board_id": 1,
                "field_id": field.id
            });
            var config = {
                    method: 'post',
                    url: SERVER+'/schemes/get_fields_value',
                    headers: { 
                        'Authorization':`Bearer ${getTokenCookie()}`
                    },
                    data : dataNEW
                };
                
                axios(config)
                .then(function (response) {
                    console.error("response get_fields_value: "+JSON.stringify(response.data));
                    resolve({ 
                        fieldName: field.field_name,
                        data: response.data
                    })
                })
                .catch(function (error) {
                    reject(error)
                });
        })
    }

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if(users.user.id !== undefined && users.user.id !== "" && users.user.id !== null){

            let dataForRegDetails = { 
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "all"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))


            var dataNEW = JSON.stringify({
                "board_id": 1,
                "scheme_id": 1,
                "role_id": null,
                "tab_id": null
            });
            var config = {
                method: 'post',
                url: SERVER+'/schemes/get_dynamic_fields',
                headers: { 
                    'Authorization':`Bearer ${getTokenCookie()}`
                },
                data : dataNEW
              };
              
              axios(config)
              .then(function (response) {
                console.error("response get_dynamic_fields: "+JSON.stringify(response.data));
                // setAllValues({
                //     ...allValues,
                //     get_dynamic_fields: JSON.stringify(response.data)
                // })

                var arr1=""
                console.log('Responsse', response.data.data);
                var fields = response.data.data;

                const myPromises = [];
                
                fields.forEach((field) => {
                    if(field.is_text_or_value === true){
                        myPromises.push(getFieldValues(field))
                    }
                })

                Promise.all(myPromises).then((promResponses) => {
                    console.log('Promise Responses', promResponses);

                    setAllValues({
                        ...allValues,
                        promResponses: promResponses
                        // promResponses: JSON.stringify(promResponses)
                    })
                })
                .catch(err => {
                    console.log('Error in Resolve Promise', err)
                })
                // response.data.data.map((item) => {

                //     if(item.is_text_or_value === true){
                //         var dataNEW = JSON.stringify({
                //             "board_id": 1,
                //             "field_id": item.id
                //         });
                //         var config = {
                //             method: 'post',
                //             url: SERVER+'/schemes/get_fields_value',
                //             headers: { 
                //                 'Authorization':`Bearer ${getTokenCookie()}`
                //             },
                //             data : dataNEW
                //           };
                          
                //           axios(config)
                //           .then(function (response) {
                //             console.error("response get_fields_value: "+JSON.stringify(response.data));
                            // setAllValues({
                            //     ...allValues,
                            //     [item.field_name]: JSON.stringify(response.data.data)
                            // })
                //             arr1 = {
                //                 ...arr1,
                //                 [item.field_name]: JSON.stringify(response.data.data)
                //             }

                //             console.error("arr1: "+JSON.stringify(arr1))
                //           })
                //           .catch(function (error) {
                //             console.error(error);
                //           });
                //     }
                // })




              })
              .catch(function (error) {
                console.error(error);
              });
        }
    }, [users.user])

    
    useEffect(() => {
       setAllValues({
                ...allValues,
                marriage_date: dob
            })
    }, [dob]);

    useEffect(() => {
        // console.log("in useEffect getUserRegistrationDetails -> outside if")
        // console.log("getUserRegistrationDetails: "+users.getUserRegistrationDetails)
        // console.log("users.getUserRegistrationDetails.personal_details: "+users.getUserRegistrationDetails.personal_details)
        // console.log("users.getUserRegistrationDetails.personal_details.length: "+users.getUserRegistrationDetails.personal_details.length)
        if(
            users.getUserRegistrationDetails !== undefined && 
            users.getUserRegistrationDetails !== "Network Error" && 
            users.getUserRegistrationDetails !== null && 

            users.getUserRegistrationDetails.personal_details !== undefined &&
            users.getUserRegistrationDetails.personal_details.length > 0 &&

            users.getUserRegistrationDetails.ration_card_details !== undefined &&
            users.getUserRegistrationDetails.ration_card_details.length > 0 &&

            users.getUserRegistrationDetails.address_details !== undefined &&
            users.getUserRegistrationDetails.address_details.length > 0
            ){
                console.log("in useEffect getUserRegistrationDetails -> in if")

                var address = ""
                var state = ""
                var district = ""
                var taluk = ""
                var gram = ""
                var village = ""
                if(users.getUserRegistrationDetails.address_details[0].address_base_type === "Residential"){
                    address = users.getUserRegistrationDetails.address_details[0].door_no + ", " +
                        users.getUserRegistrationDetails.address_details[0].street_name + ", " +
                        users.getUserRegistrationDetails.address_details[0].land_mark
                    state = users.getUserRegistrationDetails.address_details[0].state
                    district = users.getUserRegistrationDetails.address_details[0].district
                    taluk = users.getUserRegistrationDetails.address_details[0].taluk
                    gram = users.getUserRegistrationDetails.address_details[0].panhayat_city_town
                    village = users.getUserRegistrationDetails.address_details[0].ward_area_village
                }
                // else if(users.getUserRegistrationDetails.address_details[1].address_base_type === "Residential"){
                //     address = users.getUserRegistrationDetails.address_details[1].door_no + ", " +
                //         users.getUserRegistrationDetails.address_details[1].street_name + ", " +
                //         users.getUserRegistrationDetails.address_details[1].land_mark
                //     state = users.getUserRegistrationDetails.address_details[1].state
                //     district = users.getUserRegistrationDetails.address_details[1].district
                //     taluk = users.getUserRegistrationDetails.address_details[1].taluk
                //     gram = users.getUserRegistrationDetails.address_details[1].panhayat_city_town
                //     village = users.getUserRegistrationDetails.address_details[1].ward_area_village
                // }


                var bank_name = ""
                var account_no = ""
                var bank_branch = ""
                var ifsc_code = ""
                if(users.getUserRegistrationDetails.bank_details[0].is_nominee === false){
                    bank_name = users.getUserRegistrationDetails.bank_details[0].bank_name
                    account_no = users.getUserRegistrationDetails.bank_details[0].account_no
                    bank_branch = users.getUserRegistrationDetails.bank_details[0].bank_branch
                    ifsc_code = users.getUserRegistrationDetails.bank_details[0].ifsc_code
                }
                else if(users.getUserRegistrationDetails.bank_details[1].is_nominee === false){
                    bank_name = users.getUserRegistrationDetails.bank_details[1].bank_name
                    account_no = users.getUserRegistrationDetails.bank_details[1].account_no
                    bank_branch = users.getUserRegistrationDetails.bank_details[1].bank_branch
                    ifsc_code = users.getUserRegistrationDetails.bank_details[1].ifsc_code
                }

                var dateString = users.getUserRegistrationDetails.personal_details[0].date_of_birth;
                var dateObj = new Date(dateString);
                var momentObj = moment(dateObj);
                var momentString = momentObj.format('DD-MM-YYYY'); 
                console.log("momentString: "+momentString)

                // var dateObj = new Date(dateString);
                var momentObjToday = moment();
                var momentStringToday = momentObjToday.format('DD-MM-YYYY'); 
                console.log("momentStringToday: "+momentStringToday)

                var dateString2 = users.getUserRegistrationDetails.application_details[0].application_date;
                var dateObj2 = new Date(dateString2);
                var momentObj2 = moment(dateObj2);
                var momentString2 = momentObj2.format('DD-MM-YYYY'); 
                console.log("momentString2: "+momentString2)

                var startDate = moment(momentString, 'DD-MM-YYYY');
                var endDate = moment(momentString2, 'DD-MM-YYYY');
                var yearDiff = endDate.diff(startDate, 'years');

                console.log("endDate: "+endDate.format('DD MMM YYYY'))

                // var age_at_registration = moment().diff(momentString, 'years', true);
                var age_at_registration = yearDiff
                console.log("age_at_registration: "+age_at_registration)


                var years_registered = moment().diff(momentString2, 'years', false)
                var renewal_date1 = moment(momentObj2).add(3, 'Y')
                var renewal_date = renewal_date1.format('MMM DD YYYY')

                var age60_1 = moment(momentObj).add(60, 'Y')
                var age60 = age60_1.format('MMM DD YYYY')

                // var age = moment(momentString, "DD-MM-YYYY").fromNow();

                var age = moment(momentStringToday, 'DD-MM-YYYY').diff(moment(momentString, 'DD-MM-YYYY'), 'years');
                console.log("age60: "+age60)
                console.log("age: "+age)

                setAllValues({
                    ...allValues,
                    applicantName: users.getUserRegistrationDetails.personal_details[0].first_name+" "+
                        users.getUserRegistrationDetails.personal_details[0].middle_name+" "+
                        users.getUserRegistrationDetails.personal_details[0].last_name,
                    dob: momentString,
                    age: age,
                    mobile: users.getUserRegistrationDetails.personal_details[0].mobile_no,
                    aadhaar: users.getUserRegistrationDetails.personal_details[0].aadhar_no,

                    ration: users.getUserRegistrationDetails.ration_card_details[0].ration_card_no,

                    address: address,
                    state: state,
                    district: district,
                    taluk: taluk,
                    gram: gram,
                    village: village,

                    bank_name: bank_name,
                    account_no: account_no,
                    bank_branch: bank_branch,
                    ifsc_code: ifsc_code,

                    registration_code: users.getUserRegistrationDetails.personal_details[0].registration_code,
                    registration_date: age,

                    age_at_registration: age_at_registration,
                    years_registered: years_registered,
                    renewal_date: renewal_date,
                    age60: age60,
                })

            // dispatch(getFile(
            //     users.getUserRegistrationDetails.personal_details[0].user_photo_id,
            //     "profilePic")
            // )
            
            // dispatch(getFile(
            //     users.getUserRegistrationDetails.ration_card_details[0].ration_card_document_upload_id,
            //     "rationCard")
            // )
            
            // dispatch(getFile(
            //     // users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id,
            //     users.getUserRegistrationDetails.bank_details[0].account_no,
            //     "passbook")
            // )
            
            // dispatch(getFile(
            //     // users.getUserRegistrationDetails.bank_details[1].bank_passbook_upload_id,
            //     users.getUserRegistrationDetails.bank_details[1].account_no,
            //     "passbook_nominee")
            // )
            
            // dispatch(getFile(
            //     users.getUserRegistrationDetails.certificate_details[0].document_uploaded_id,
            //     "90_days_certificate")
            // )
        }
    }, [users.getUserRegistrationDetails])



    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues))
    }, [allValues]);

    const handleChange = (e) => {
        // console.log(e.target)
        console.log(e.target.name + " : " + e.target.value)

        var nameError = false
        var nameNomineeError = false
        var phoneError = false
        var phoneNomineeError = false

        if (e.target.name === "name") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isAlpha(e.target.value))
                nameError = false
            else
                nameError = true
        }
        if (e.target.name === "name_nominee") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isAlpha(e.target.value))
                nameNomineeError = false
            else
                nameNomineeError = true
        }
        if (e.target.name === "phone") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                phoneNomineeError = false
            else
                phoneNomineeError = true
        }
        if (e.target.name === "phone_nominee") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                phoneNomineeError = false
            else
                phoneNomineeError = true
        }

        setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
        })

        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }

    const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

    const handleClick = (event, data) => {
        console.error("handleclick data:" + data)
        if (data === "id_card")
            setAnchorElIdCard(event.currentTarget);
        else if (data === "bank_account")
            setAnchorElbank_account(event.currentTarget);
        else if (data === "employee_cert")
            setAnchorElemployee_cert(event.currentTarget);
        else if (data === "marriage_cert")
            setAnchorElmarriage_cert(event.currentTarget);
    };

    const handleClose = (event, data) => {
        console.error("handleClose data:" + data)
        if (data === "id_card")
            setAnchorElIdCard(null);
        else if (data === "bank_account")
            setAnchorElbank_account(null);
        else if (data === "employee_cert")
            setAnchorElemployee_cert(null);
        else if (data === "marriage_cert")
            setAnchorElmarriage_cert(null);
    };
    const handleFileChange = (event,data) => {
        console.log("handleFileChange data: "+data)
        if(event.target.files[0].size > 2000001){
            showToast("ERROR", "Please upload file of size less than 2MB.")
        }
        else if(!event.target.files[0].type.includes("image")){
            showToast("ERROR", "Please upload file in JPEG or PNG format.")
        }
        else{
        var selectedblob = event.target.files[0]
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        reader.onloadend = function () {
            var base64data = reader.result;
            console.log(base64data);

            sessionStorage.setItem(data,base64data)
            setAllValues({...allValues,
                // imgfile: base64data,
                [data]: event.target.files[0].name
            })
        }
        }
    }

    const handleChangeCheckbox = (event) => {
        setAllValues({ ...allValues, [event.target.name]: event.target.checked });

        // if (event.target.checked) {
        //     setAllValues({ ...allValues,
        //         permanentData: state.residentialData
        //     }));
        // }
        // else {
        //     setAllValues({ ...allValues,
        //         permanentData: initData
        //     }));
        // }
    }

    const submitButtonPressed = (e) =>{

        setOpenBackdrop(true);
        setLoading(true);
        setSubmitSuccess(false);

        // let formdata = {
        //     'file': sessionStorage.getItem('id_card'),
        //     'userId': users.user.id,
        //     'fileType': 'id_card'
        // }
        // console.error("formdata: " + JSON.stringify(formdata))
        // dispatch(uploadFile(formdata, "id_card"))

        // let formdata2 = {
        //     'file': sessionStorage.getItem('bank_account'),
        //     'userId': users.user.id,
        //     'fileType': 'bank_account'
        // }
        // console.error("formdata2: " + JSON.stringify(formdata2))
        // dispatch(uploadFile(formdata2, "bank_account"))


        // let formdata3 = {
        //     'file': sessionStorage.getItem('employee_cert'),
        //     'userId': users.user.id,
        //     'fileType': 'employee_cert'
        // }
        // console.error("formdata3: " + JSON.stringify(formdata3))
        // dispatch(uploadFile(formdata3, "employee_cert"))

        let formdata4 = {
            'file': sessionStorage.getItem('marriage_cert'),
            'userId': users.user.id,
            'fileType': 'marriage_cert'
        }
        console.error("formdata4: " + JSON.stringify(formdata4))
        dispatch(uploadFile(formdata4, "marriage_cert"))
        
    }
    

    /// TO BE DONE BY PROMISE
    useEffect(() => {
        console.error("in useEffect upload_file: "+JSON.stringify(users.upload_file))
        if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true) {
            if (users.upload_file.userFileName === "id_card") {
                setAllValues({...allValues,
                    id_cardUploadResponse: users.upload_file.image,
                    id_cardfileId: users.upload_file.image.fileId
                })

                console.error("id_cardUploadResponse:"+JSON.stringify(allValues.id_cardUploadResponse))
            }
            else if (users.upload_file.userFileName === "bank_account") {
                setAllValues({...allValues,
                    bank_accountUploadResponse: users.upload_file.image,
                    bank_accountfileId: users.upload_file.image.fileId
                })

                console.error("bank_accountUploadResponse:"+JSON.stringify(allValues.bank_accountUploadResponse))
            }
            else if (users.upload_file.userFileName === "employee_cert") {
                setAllValues({...allValues,
                    employee_certUploadResponse: users.upload_file.image,
                    employee_certfileId: users.upload_file.image.fileId
                })

                console.error("employee_certUploadResponse:"+JSON.stringify(allValues.employee_certUploadResponse))
            }
            else if (users.upload_file.userFileName === "marriage_cert") {
                setAllValues({...allValues,
                    marriage_certUploadResponse: users.upload_file.image,
                    marriage_certfileId: users.upload_file.image.fileId
                })

                console.error("marriage_certUploadResponse:"+JSON.stringify(allValues.marriage_certUploadResponse))



                // Call get_role_hirarchy
                var data = JSON.stringify({
                    "board_id": 1,
                    "role_hierarchy_master_id": role_id
                  });
                  var config = {
                    method: 'post',
                    url: SERVER+'/schemes/get_role_hirarchy',
                    headers: { 
                        'Authorization':`Bearer ${getTokenCookie()}`
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    console.log("get_role_hirarchy response:"+JSON.stringify(response.data));

                    // Call get_department_user_by_area

                    var dataDepUser = JSON.stringify({
                        "board_id": 1,
                        "role_id_from_hirarchy": response.data.data[0].role_id,
                        "village_area_id": users.getUserRegistrationDetails.address_details[1].village_area_id
                      });
                      
                      var configDepUser = {
                        method: 'post',
                        url: SERVER+'/schemes/get_department_user_by_area',
                        headers: { 
                            'Authorization':`Bearer ${getTokenCookie()}`
                        },
                        data : dataDepUser
                      };
                      
                      axios(configDepUser)
                      .then(function (response2) {
                        console.log("get_department_user_by_area response:"+JSON.stringify(response2.data));

                        submitButtonPressed1(response2.data.data)
                      })
                      .catch(function (error) {
                        console.error("get_department_user_by_area error:"+error);
                      });
                      

                  })
                  .catch(function (error) {
                    console.error("get_role_hirarchy error:"+error);
                  });
                  
            }

        }
        else if (users.upload_file !== null && users.upload_file.success === undefined) {
            showToast("ERROR", "Cannot upload the certificate image. Please try later.")
            setLoading(false)
        }
    }, [users.upload_file])

    const submitButtonPressed1 = (deptUserDetails) =>{
        console.log("param_in_schemeform: "+id)
        console.log("deptUserDetails: "+JSON.stringify(deptUserDetails,undefined,2))
        
          
        var dataNEW = {
            "board_id": users.user.board_id,
            "scheme_id": 1,                        ///////// TO BE CHANGED
            "labour_user_id": users.user.id,
            "is_self": 1,                        ///////// TO BE CHANGED
            "applied_for_person_id": users.user.id,                        ///////// TO BE CHANGED
            "applied_date": moment(new Date()).format("YYYY-MM-DD"),
            "scheme_approval_status_id": 45,
            "unique_id": 12456899,                        ///////// TO BE CHANGED
            "circle_id": deptUserDetails[0].circle_id,
            "role_id_from_hirarchy": role_id,
            "is_renewal":0,
            "department_user_id": deptUserDetails[0].department_user_id,
            "documents": [
                // {
                //     "document_upload_id": allValues.id_cardfileId,
                //     "description": "id_card",
                //     "document_type": "id_card"
                // },
                // {
                //     "document_upload_id": allValues.bank_accountfileId,
                //     "description": "bank_account",
                //     "document_type": "bank_account"
                // },
                // {
                //     "document_upload_id": allValues.employee_certfileId,
                //     "description": "employee_cert",
                //     "document_type": "employee_cert"
                // },
                {
                    "document_upload_id": allValues.marriage_certfileId,
                    "description": "marriage_cert",
                    "document_type": "marriage_cert"
                },
            ]
        }

        console.log("dataNEW : "+JSON.stringify(dataNEW,undefined,2));

        var config = {
                method: 'post',
                url: SERVER+'/schemes/create_scheme_txn',
                headers: { 
                    'Authorization':`Bearer ${getTokenCookie()}`
                },
                data : dataNEW
            };
            
            axios(config)
            .then(function (response) {
                console.log("response create_scheme_txn: "+JSON.stringify(response.data));

                submitButtonPressed2()
                
            })
            .catch(function (error) {
                console.error("response error: "+JSON.stringify(error));

                showToast("ERROR", "Some error occured while applying for Scheme!")
                setLoading(false)
            });
    }

    const submitButtonPressed2 = (e) =>{

        var scheme_avail_id = ""
        var data1 = JSON.stringify({
            "board_id": 1,
            "labour_user_id":  users.user.id
          });
          
          var config1 = {
            method: 'post',
            url: SERVER+'/schemes/get_schemes_by_labor',
            headers: { 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
            data : data1
          };
          
          axios(config1)
          .then(function (response) {
            console.log(JSON.stringify(response.data));

            scheme_avail_id = response.data.data[0].scheme_avail_id
            console.log("scheme_avail_id: "+scheme_avail_id)

          



        var dataNEW = JSON.stringify({
            "board_id": 1,
            "labour_id": users.user.id,
            "scheme_avail_id": scheme_avail_id,
            "session_user_id": users.user.id,
            "fields": [
                {
                    "field_id": 1,
                    "field_value": allValues.marriage_assistance_for,
                    "is_text_or_value": 1
                },
                 {
                    "field_id": 2,
                    "field_value": allValues.name_of_person_married,
                    "is_text_or_value": 0
                },
                 {
                    "field_id": 3,
                    "field_value": allValues.gender,
                    "is_text_or_value": 1
                },
                 {
                    "field_id": 8,
                    "field_value": Number(allValues.age),
                    "is_text_or_value": 0
                },
                {
                    "field_id": 10,
                    "field_value": allValues.marriage_date,
                    "is_text_or_value": 0
                },
                {
                    "field_id": 11,
                    "field_value": allValues.marriage_place,
                    "is_text_or_value": 0
                },
                {
                    "field_id": 12,
                    "field_value": Number(allValues.marriage_certifate),
                    "is_text_or_value": 0
                }
            ]
        });
        var config = {
                method: 'post',
                url: SERVER+'/schemes/insert_scheme_values',
                headers: { 
                    'Authorization':`Bearer ${getTokenCookie()}`
                },
                data : dataNEW
            };
            
            axios(config)
            .then(function (response) {
                console.error("response create_scheme_txn: "+JSON.stringify(response.data));

                showToast("SUCCESS", "Applied for Scheme Successfully!")


                setLoading(false)
                setSubmitSuccess(true)
                // setAllValues({
                //     ...allValues,
                //     finalSubmitted: true
                // })

                // props.history.push("/schemes-home")

                
                
            })
            .catch(function (error) {
                console.error("response error: "+JSON.stringify(error));

                showToast("ERROR", "Some error occured while applying for Scheme!")
                setLoading(false)
            });


        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        input.style.display = 'block';
        html2canvas(input, {
            scrollY: -window.scrollY,
            // allowTaint: true,
            // useCORS: true
        })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setAckLetterImg(imgData)
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
            // pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save("acknowledgement.pdf");
            window.open(pdf.output('bloburl'), '_blank')
          })
          .then(() => {
            input.style.display = 'none';
          })
        ;
      }

      var appNo = ""
    if(users.getApplicationNo !== null && users.getApplicationNo !== ""){
        if(users.getApplicationNo.length > 0)
            appNo = users.getApplicationNo[0].application_no
        else
            appNo = "test"
    }
    
    var applicantName = ""
    if(users.user.first_name !== '' && users.user.first_name !== null){
        applicantName += (users.user.first_name + " ")
    }
    if(users.user.middle_name !== '' && users.user.middle_name !== null){
        applicantName += (users.user.middle_name + " ")
    }
    if(users.user.last_name !== '' && users.user.last_name !== null){
        applicantName += (users.user.last_name)
    }

    var date = new Date()

    if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== undefined &&
        users.getUserRegistrationDetails !== "Network Error" && users.getUserRegistrationDetails.address_details !== undefined && users.getUserRegistrationDetails.address_details.length > 0){

        var presentAddress = ""
        // if(users.getUserRegistrationDetails.address_details[1].door_no !== '' && users.getUserRegistrationDetails.address_details[1].door_no !== null){
        //     presentAddress += (users.getUserRegistrationDetails.address_details[1].door_no + " ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].street_name !== '' && users.getUserRegistrationDetails.address_details[1].street_name !== null){
        //     presentAddress += (users.getUserRegistrationDetails.address_details[1].street_name + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].land_mark !== '' && users.getUserRegistrationDetails.address_details[1].land_mark !== null){
        //     presentAddress += (users.getUserRegistrationDetails.address_details[1].land_mark + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].panhayat_city_town !== '' && users.getUserRegistrationDetails.address_details[1].panhayat_city_town !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].panhayat_city_town
        //     presentAddress += (x + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].district !== '' && users.getUserRegistrationDetails.address_details[1].district !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].district
        //     presentAddress += (x + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].ward_area_village !== '' && users.getUserRegistrationDetails.address_details[1].ward_area_village !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].ward_area_village
        //     presentAddress += (x + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].taluk !== '' && users.getUserRegistrationDetails.address_details[1].taluk !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].taluk
        //     presentAddress += (x + ", ")
        // }
        // if(users.getUserRegistrationDetails.address_details[1].state !== '' && users.getUserRegistrationDetails.address_details[1].state !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].state
        //     presentAddress += (x + " - ")
        // }
        // if( users.getUserRegistrationDetails.address_details[1].pin_code !== '' && users.getUserRegistrationDetails.address_details[1].pin_code !== null){
        //     presentAddress += (users.getUserRegistrationDetails.address_details[1].pin_code)
        // }



        var villageWard = ""
        // if(users.getUserRegistrationDetails.address_details[1].ward_area_village !== '' && users.getUserRegistrationDetails.address_details[1].ward_area_village !== null ){
        //     var x = users.getUserRegistrationDetails.address_details[1].ward_area_village
        //     villageWard += (x)
        // }

        var mobile = ""
        if(users.getUserRegistrationDetails.personal_details[0].mobile_no !== '' && users.getUserRegistrationDetails.personal_details[0].mobile_no !== null){
        
            mobile += (users.getUserRegistrationDetails.personal_details[0].mobile_no)
        }

        var occupation = ""
        if(users.getUserRegistrationDetails.employer_details !== '' && users.getUserRegistrationDetails.employer_details.length > 0){
        var y =  users.getUserRegistrationDetails.employer_details.map(function(e) {
                            return e.catalog_value_employement_status_id;
                        }).indexOf(25)
                    
            console.log("y="+y)
            var x = users.getUserRegistrationDetails.employer_details[y].catalog_value_nature_of_work
            
            occupation += (x)
        }
        
        var officerIncharge = ""
        if(users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 55){
            officerIncharge = users.getUserRegistrationDetails.certificate_details[0].inspector_first_name
        }
        else if(users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 56){
            officerIncharge = users.getUserRegistrationDetails.certificate_details[0].labour_union_spoke_person
        }
        else if(users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 57){
            officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
        }
        else if(users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 58){
            officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
        }

        var circleName = ""
        if(users.getUserRegistrationDetails.certificate_details[0].inspector_circle !== "" && users.getUserRegistrationDetails.certificate_details[0].inspector_circle !== null){
            circleName = users.getUserRegistrationDetails.certificate_details[0].inspector_circle
        }
    }
    
    return (
        <>
        
            <div className="root">



            {
                    loading ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : null
                }
                
                {
                    submitSuccess ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <div style={{display: "block"}}>
                                <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "200px"}}/>
                                <p className="final-success-title" >Successfully submitted for<br/>Scheme!</p>

                                <p className="final-success-link-p">View Acknowledgement Letter <Link to="#"
                                    onClick={printDocument}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                    
                                        <span variant="outline-primary" className="final-success-link"> Click Here</span>
                                </Link>
                                    </p>
                            </div>

                            
                        </Backdrop>
                        : null
                }



                <Row className="top-div ">
                    <Row className="topbar-row">
                        <Col xs={12} md={4} lg={6} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction<br/>Workers Welfare Board (KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
                
            </div>



           <Row className="schemes-title-row-red">
                <Col xs={12} md={9}>
                    <h1>
                    <img alt="..." src={home} className="whitefilter " />
                        Schemes</h1>
                </Col>
            </Row>

            <Row className="scheme-subtitle-row-grey mt-4">
                <Col xs={12} className="searchform-subtitle">
                    <p>Disability Pension | Personal Details</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} md={6} className="form-inner-card-scheme">
                    <Col xs={12}>
                        {/* <Required className="mb-2" title="Registration Number" /> */}
                        <p className="mb-2">Name</p> 
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                value={allValues.applicantName}
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                        {/* <Required className="mb-2" title="Registration Number" /> */}
                        <p className="mb-2 mt-4">Mobile Number</p> 
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                value={allValues.mobile}
                                disabled
                            />
                        </FormControl>
                    </Col>
                </Col>
                <Col xs={12} md={6} className="form-inner-card-scheme2">    
                    <Row >
                        
                        <Col xs={6}>
                            {/* <Required className="mb-2" title="Registration Number" /> */}
                            <p className="mb-2">Date of birth</p> 
                            <FormControl fullWidth className="formcontrol1">
                                <TextField
                                    variant="outlined"
                                    value={allValues.dob}
                                    disabled
                                />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            {/* <Required className="mb-2" title="Registration Number" /> */}
                            <p className="mb-2">Age of Applicant</p> 
                            <FormControl fullWidth className="formcontrol1">
                                <TextField
                                    variant="outlined"
                                    value={allValues.age}
                                    disabled
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} >
                            <p className="mb-2 mt-4">Date of Completion of 60 Years</p> 
                            <FormControl fullWidth className="formcontrol1">
                                <TextField
                                    variant="outlined"
                                    value={allValues.age60}
                                    disabled
                                />
                            </FormControl>
                        </Col>
                    
                    </Row>
                </Col>

                
                <Col xs={12} md={4}>
                    {/* <Required className="mb-2" title="Registration Number" /> */}
                    <p className="mb-2 mt-4">AADHAAR Number</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.aadhaar}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                    {/* <Required className="mb-2" title="Registration Number" /> */}
                    <p className="mb-2 mt-4">Ration Card Number</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.ration}
                            disabled
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={8}>
                    {/* <Required className="mb-2" title="Registration Number" /> */}
                    <p className="mb-2 mt-4">Present Address of Applicant</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.address}
                            disabled
                        />
                    </FormControl>
                </Col>

            </Row>



            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Address Where Applicant is Registered</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">

                <Col xs={12} md={4} >
                    <p className="mb-2">State</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.state}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">District</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.district}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">Taluk</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.taluk}
                            disabled
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">Gram Panchayat</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.gram}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">Village / Ward</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.village}
                            disabled
                        />
                    </FormControl>
                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Bank Details</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">

                <Col xs={12} md={4} >
                    <p className="mb-2">Bank Name</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.bank_name}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">Account Number</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.account_no}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">Branch Name</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.bank_branch}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">IFSC Code</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.ifsc_code}
                            disabled
                        />
                    </FormControl>
                </Col>

            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Registration Details</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">

                <Col xs={12} md={4} >
                    <p className="mb-2">Registration Number</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.registration_code}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">Date of Registration</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.registration_date}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2">Date of Completion of 60 Years</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.age60}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">Age at the Time of Registration</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.age_at_registration}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">Number of years Registered</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.years_registered}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <p className="mb-2 mt-4">Renewal date of Registration</p> 
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.renewal_date}
                            disabled
                        />
                    </FormControl>
                </Col>

            </Row>


            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Marriage Details</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
            
                <Col xs={12} md={4} >
                    <Required className="mb-2" title="Marriage Assistance is for" />
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.marriage_assistance_for}
                            name="marriage_assistance_for"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>

                            <MenuItem value="8">
                                <ListItemText primary="Son" />
                            </MenuItem>
                            <MenuItem value="9">
                                <ListItemText primary="Daughter" />
                            </MenuItem>
                            <MenuItem value="10">
                                <ListItemText primary="Self" />
                            </MenuItem>
                            {/* {
                                allValues.promResponses.map((i) =>
                                    i.fieldName === 'Marriage Assistance is for' ?
                                        allValues.promResponses.map((i) =>
                                            <MenuItem value={i.data.data.value_id}>
                                                <ListItemText primary={i.data.data.value} />
                                            </MenuItem>
                                        )
                                        :
                                        null
                                    
                                )
                            } */}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <Required className="mb-2" title="Name of Person Married" />
                    {/* <p className="mb-2">Enter Your Full Name </p>  */}
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // placeholder="Enter Bank Name"
                            name="name_of_person_married"
                            value={allValues.name_of_person_married}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    <Required className="mb-2" title="Spouse Name" />
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="spouse_name"
                            value={allValues.spouse_name}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    <Required className="mb-2 mt-4" title="Spouse's Gender" />
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.gender}
                            name="gender"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            <MenuItem value="1">
                                <ListItemText primary="Male" />
                            </MenuItem>
                            <MenuItem value="2">
                                <ListItemText primary="Female" />
                            </MenuItem>
                            <MenuItem value="6">
                                <ListItemText primary="Others" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    <Required className="mb-2 mt-4" title="Age" />
                    {/* <p className="mb-2">Enter Your Full Name </p>  */}
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // placeholder="Enter Bank Name"
                            name="age"
                            value={allValues.age}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    <Required className="mt-4 mb-2" title="Date of Marriage" />
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <KeyboardDatePicker
                            maxDate={new Date()}
                            onClick={() => setPickerStatus(true)}
                            onClose={() => setPickerStatus(false)}
                            open={allValues.openDatePicker}
                            className="datepicker"
                            margin="normal"
                            format="D/MM/yyyy"
                            value={dob}
                            onChange={(date) => { setDob(date) }}
                            placeholder="DD/MM/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={4} >
                    <Required className="mb-2 mt-4" title="Place of Marriage" />
                    {/* <p className="mb-2">Enter Your Full Name </p>  */}
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // placeholder="Enter Bank Name"
                            name="marriage_place"
                            value={allValues.marriage_place}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    <Required className="mb-2 mt-4" title="Marriage Certificate Number" />
                    {/* <p className="mb-2">Enter Your Full Name </p>  */}
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            // placeholder="Enter Bank Name"
                            name="marriage_certificate"
                            value={allValues.marriage_certificate}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Col>
                


            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Documents</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">

                {/* <Col xs={6} className="final-personal-col-1 px-3">
                    <div className="mt-4">
                        <Required className="mb-2" title="ID Card/ Smart Card" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    name="id_card"
                                    value={allValues.id_card}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            <label
                                htmlFor="upload-photo2"
                                className="browse-button btn2 schemes">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo2" onChange={(e)=> handleFileChange(e,'id_card')} />

                            <label className="schemes-doc-view" onClick={(e) => handleClick(e, "id_card")}>
                                <Visibility />
                            </label>
                            <Popover
                                id={idIdCard} 
                                open={openIdCard} 
                                anchorEl={anchorElIdCard} 
                                onClose={(e) => handleClose(e, "id_card")}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={sessionStorage.getItem('id_card')}
                                />
                            </Popover>
                        </div>
                    </div>
                </Col>
                <Col xs={6} className="final-personal-col-1 px-3">
                    <div className="mt-4">
                        <Required className="mb-2" title="Bank Account Details" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    name="bank_account"
                                    value={allValues.bank_account}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            <label
                                htmlFor="upload-photo3"
                                className="browse-button btn2 schemes">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo3" onChange={(e)=> handleFileChange(e,'bank_account')} />

                            <label className="schemes-doc-view" onClick={(e) => handleClick(e, "bank_account")}>
                                <Visibility />
                            </label>
                            <Popover
                                id={idbank_account} 
                                open={openbank_account} 
                                anchorEl={anchorElbank_account} 
                                onClose={(e) => handleClose(e, "bank_account")}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={sessionStorage.getItem('bank_account')}
                                />
                            </Popover>
                        </div>
                    </div>
                </Col>
                <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                    <div className="">
                        <Required className="mb-2" title="Employment Certificate" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    name="employee_cert"
                                    value={allValues.employee_cert}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            <label
                                htmlFor="upload-photo4"
                                className="browse-button btn2 schemes">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo4" onChange={(e)=> handleFileChange(e,'employee_cert')} />

                            <label className="schemes-doc-view" onClick={(e) => handleClick(e, "employee_cert")}>
                                <Visibility />
                            </label>
                            <Popover
                                id={idemployee_cert} 
                                open={openemployee_cert} 
                                anchorEl={anchorElemployee_cert} 
                                onClose={(e) => handleClose(e, "employee_cert")}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={sessionStorage.getItem('employee_cert')}
                                />
                            </Popover>
                        </div>
                    </div>
                </Col>
                 */}
                <Col xs={6} className="final-personal-col-1 px-3 mt-4">
                    <div className="">
                        {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                        <Required className="mb-2" title="Marriage Certificate" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    name="marriage_cert"
                                    value={allValues.marriage_cert}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            <label
                                htmlFor="upload-photo5"
                                className="browse-button btn2 schemes">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo5" onChange={(e)=> handleFileChange(e,'marriage_cert')} />

                            <label className="schemes-doc-view" onClick={(e) => handleClick(e, "marriage_cert")}>
                                <Visibility />
                            </label>
                            <Popover
                                id={idmarriage_cert} 
                                open={openmarriage_cert} 
                                anchorEl={anchorElmarriage_cert} 
                                onClose={(e) => handleClose(e, "marriage_cert")}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={sessionStorage.getItem('marriage_cert')}
                                />
                            </Popover>
                        </div>
                    </div>
                </Col>

                <Col xs={12} className="final-personal-col-1 px-3 mt-4">
                    <p className="note-scheme">Note : Document should be in JPEG, PNG, and PDF format only and less than 50KB</p>
                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                    <p>Declaration</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allValues.declaration}
                                    onChange={handleChangeCheckbox}
                                    name="declaration"
                                // color="primary"
                                />
                            }
                            label="The facts mentioned above are to the best of my knowledge and information"
                        />
                    </Col>
            </Row>


            <Row className="search-subtitle-row pb-5">
                {/* <Col xs={12} className="searchform-subtitle">
                    <div className="submit-button-div centerdiv">
                        <Link to="#"
                            // onClick={nextButtonPressed}
                            // style={{ flexGrow: "1" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Submit
                            </Button>
                        </Link>
                    </div>
                </Col> */}

                <Col xs={12} className="next-back-button-row mt-4">

                    <Link to="#"
                        // onClick={props.history.goBack()}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="outline-primary" className="back-button" >
                            Cancel
                        </Button>
                    </Link>

                    <Link to="#"
                        onClick={submitButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="outline-primary" className="save-button">
                            Submit
                        </Button>
                    </Link>

                </Col>
            </Row>










            {/* ********************   Acknowledgement Letter HTML ************************ */}
            <div>
                {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
                <div id="divToPrint" className="mt-4" style={{
                    backgroundColor: '#fff',
                    width: '210mm',
                    minHeight: '297mm',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center',
                    // justifyContent: 'center',
                    display: 'none'
                    // border: '2px solid black',
                }}>
                    <div className="logo-div-ack">
                        <img id="logo" src={logo} alt="..." className="logo-img" />
                        <h1 className="logo-text">
                            <Translate value="header.title" /> <br />
                            <Translate value="header.sub" />
                        </h1>
                        <p className="copyright">
                            Government of Karnataka
                        </p>
                    </div>

                    <Row className="ack-subtitle-row">
                        <Col xs={12} className="profile-title">
                            <h1>Acknowledgement</h1>
                        </Col>
                    </Row>

                    <p className="ack-success">
                        <img src={checkGreen} style={{height: "auto", width: "28px", marginRight: "10px"}} />
                        {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
                        Application Status : Successfully submitted to Labour Inspector</p>

                    <Row className="ack-table-row mt-5">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Application Number</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{appNo}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Application Date</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p><Moment format="DD MMMM YYYY">{date}</Moment></p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Requested Service</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{idName}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Applicant's Name</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{applicantName}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Residential Address</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{presentAddress}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Village Ward</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{villageWard}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Mobile Number</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{mobile}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Occupation (Category)</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row>

                    {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Circle Name</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{circleName}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Labour Inspector Incharge</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{officerIncharge}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row-last mt-5">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col-note">
                            <p>NOTE :</p>
                        </Col>
                    </Row>
                    <Row className="ack-table-row-last">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col">
                            <p>1. Submitted application is subject to verification.</p>
                        </Col>
                    </Row>
                    <Row className="ack-table-row-last">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col">
                            <p>2. Login to application to know the status.</p>
                        </Col>
                    </Row>




                </div>


            </div>

        </>
    )
}

export default SchemeForm;