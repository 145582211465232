import { FormControl, TextareaAutosize } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";

const SanctionedAmount = ({
  schemeStatus,
  approvalAuthority,
  amount,
  setAmount,
}) => {
  return (
    <>
      {(schemeStatus === "Draft Approved" ||
        schemeStatus === "Draft Rejected" ||
        schemeStatus === "Approved" ||
        (schemeStatus === "Forwarded" && +approvalAuthority === 1)) && (
        <>
          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              {schemeStatus === "Approved" ? (
                <p>Sanctioned Amount</p>
              ) : (
                <p>Amount to be sanctioned</p>
              )}
            </Col>
          </Row>
          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} className='amount-sanctioned mt-2 bank-col-5'>
              <FormControl fullWidth className='formcontrol1'>
                <TextareaAutosize
                  variant='outlined'
                  multiline
                  placeholder='Enter Your Amount'
                  value={amount}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    const numericValue = value.replace(/[^0-9]/g, "");
                    setAmount(numericValue);
                  }}
                  disabled={schemeStatus === "Approved" ? true : false}
                />
              </FormControl>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SanctionedAmount;
