import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Required } from 'utils/tools';
import CancelIcon from '@mui/icons-material/Cancel';
import FolderIcon from '@mui/icons-material/Folder';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SERVER } from 'store/actions/user.actions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PopOver from '../../PopOver';
const VerificationDocument = ({
  verificationDoc,
  verificationDocID,
  verificationDocRef,
  handleVerificationDoc,
  handleRemoveVerificationDoc,
  verificationDocument,
}) => {
  const { approvalAuthority, schemeStatus } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleNewTab = (url) => {
    window.open(url, '_blank', 'noopener');
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/file/${fileID}`;
    window.open(url, '_blank', 'noopener');
  };

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      <Col xs={6} className="amount-sanctioned mt-2 bank-col-5">
        {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
        <Required className="mt-3 mb-2" title={'Verification Document'} />
        {(schemeStatus === 'Pending' ||
          schemeStatus === 'Transferred Scheme Application') && (
          <div className="browsebutton-outerdiv">
            <div className="w-100">
              {verificationDoc &&
                verificationDoc.length > 0 &&
                verificationDoc.map((file, index) => (
                  <FormControl fullWidth className="formcontrol3 w-100">
                    <TextField
                      className="w-100"
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              <IconButton
                                onClick={() =>
                                  handleRemoveVerificationDoc(index)
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}
              {verificationDocID && verificationDocID.length > 0 && (
                <FormControl fullWidth className="formcontrol3 w-100">
                  <TextField
                    className="w-100"
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    // name="mail_id"
                    disabled
                    value={verificationDocID[0].file_name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            <IconButton
                            // onClick={() =>
                            //   handleRemoveNintyDaysFile(index)
                            // }
                            >
                              <CancelIcon />
                            </IconButton>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )}
              {verificationDoc && verificationDoc.length === 0 && (
                <FormControl fullWidth className="formcontrol3 w-100">
                  <TextField
                    className="w-100"
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    disabled
                  />
                </FormControl>
              )}
            </div>
            <div className="browsebutton2-div">
              <div className="">
                <input
                  type="file"
                  ref={verificationDocRef}
                  onChange={handleVerificationDoc}
                  multiple
                  hidden
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="py-2 mx-2"
                  startIcon={<FolderIcon />}
                  onClick={() => verificationDocRef.current.click()}
                >
                  Browse
                </Button>
              </div>
            </div>
          </div>
        )}
        {schemeStatus !== 'Pending' && (
          <div className="browsebutton-outerdiv">
            <div className="w-100">
              {verificationDocument &&
                verificationDocument.length > 0 &&
                verificationDocument.map((row) => (
                  <FormControl fullWidth>
                    <TextField
                      value={row.file_name}
                      disabled
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {(row.file_name.includes('.png') ||
                              row.file_name.includes('.jpeg') ||
                              row.file_name.includes('.jpg')) && (
                              <IconButton
                                onClick={(ev) =>
                                  handleViewImage(
                                    ev,
                                    row.file_id,
                                    row.file_name,
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                            {row.file_name.includes('.pdf') && (
                              <IconButton
                                onClick={() => handleViewPDF(row.file_id)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}
            </div>
          </div>
        )}
      </Col>
    </>
  );
};

export default VerificationDocument;
