import { useEffect } from 'react';
import { showToast } from 'utils/tools';

export default function useError(error, callback = () => {}) {
  useEffect(() => {
    if (error && error?.errors?.msg)
      showToast('ERROR', error.errors.msg ?? 'Something went wrong');
    else if (error && error.message)
      showToast('ERROR', error.message ?? 'Something went wrong');
    if (callback) callback();
  }, [error]);
}
