export const withBackButton = (WrappedComponent, back, setView) => {
  return (props) => (
    <>
      <img
        src={back}
        alt="Go back"
        onClick={() => setView('')}
        style={{ cursor: 'pointer' }}
      />
      <WrappedComponent {...props} />
    </>
  );
};
