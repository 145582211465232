import React, { useState } from 'react';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import calendar from 'assets/images/calendar.svg';
import time from 'assets/images/time.svg';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import RejectedReasonsDialog from 'components/adminPortal/globals/RejectedReasonsDialog';
import { rejectReasonAPI } from './schemeStatus.service';
import { showToast } from 'utils/tools';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Ecard from 'components/Documents/Ecard';
import { Chip, Typography } from '@mui/material';
const SchemeStatusSection = ({ schemeStatus }) => {
  const { labourUserID } = useParams();
  const [open, setOpen] = React.useState(false);
  const [reasons, setReasons] = useState(null);
  const [schemeName, setSchemeName] = useState(null);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState('eng');

  const handleClickOpen = (schemeAvailID, schemename, status) => {
    setOpen(true);
    setLoading(true);
    setSchemeName(schemename);
    callRejectReasons(schemeAvailID, status);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const callRejectReasons = async (schemeAvailID, status) => {
    try {
      const response = await rejectReasonAPI(schemeAvailID, status);
      if (response.success) {
        setLoading(false);
        setReasons(response.data);
      }
    } catch (error) {
      setLoading(false);
      showToast('ERROR', 'Something went wrong');
    }
  };

  return (
    <>
      {schemeStatus &&
        schemeStatus.data.filter((data) => data.status === 'Approved').length >
          0 && (
          <>
            <Row className="container-fluid m-0 p-0">
              <p className="text_approved py-2">
                <Translate value="Approved" />
              </p>
            </Row>
            <Row className="schemeStatus">
              <>
                {schemeStatus &&
                schemeStatus.data.filter((data) => data.status === 'Approved')
                  .length > 0 ? (
                  schemeStatus &&
                  schemeStatus.data
                    .filter((data) => data.status === 'Approved')
                    .map((row) => (
                      <Col xs={12} md={6}>
                        {/* <div className="success_bar"></div> */}
                        <div className="bar_content approvedscheme">
                          <div className="container">
                            <Row className="box1 d-flex flex-column p-1  border">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="box1_text">
                                  <Translate value="schemeName" />
                                </div>
                                <div className="box1_text">
                                  {row.scheme_name}
                                </div>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Scheme Applied Date</div> */}
                                <div className="box1_text">
                                  <Translate value="schemeAppliedDate" />
                                </div>
                                <div className="box1_text">
                                  {moment(row.applied_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Status</div> */}
                                <div className="box1_text">
                                  <Translate value="status" />
                                </div>
                                <div className="box1_text">
                                  <span>
                                    Approved{' '}
                                    <CheckCircleOutlineRoundedIcon
                                      sx={{
                                        alignItems: 'center',
                                        background: `#55b54a`,
                                        borderRadius: '100%',

                                        color: 'white',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-1">
                              <div className="w_50">
                                <span className="me-2">
                                  <img src={calendar} alt="" />
                                </span>
                                <span>
                                  {moment(row.approved_rejected_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </span>
                              </div>
                              <div className="float-right time">
                                {row.is_renewal && (
                                  <span className="">
                                    <Chip
                                      sx={{
                                        background: '#55B54A',
                                        color: 'white',
                                        padding: '10px',
                                        fontSize: '14px',
                                        fontWeight: 'bolder',
                                      }}
                                      label="Renewal"
                                      variant="outlined"
                                    />
                                  </span>
                                )}
                              </div>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-1">
                              <Link
                                to={`/view/schemeack/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                target="_blank"
                              >
                                <Button
                                  className="w-100 text-start"
                                  variant="outline-secondary"
                                >
                                  <VisibilityRoundedIcon />
                                  View Acknowledgement Letter
                                </Button>
                              </Link>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-1">
                              <Link
                                to={`/view/scheme-sanction-order/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                target="_blank"
                              >
                                <Button
                                  className="w-100 text-start"
                                  variant="outline-secondary"
                                >
                                  <VisibilityRoundedIcon />
                                  View Sanction Order
                                </Button>
                              </Link>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                ) : (
                  <Typography>No approved applications</Typography>
                )}
              </>
            </Row>
          </>
        )}
      {schemeStatus &&
        schemeStatus.data.filter(
          (data) =>
            data.status === 'Pending' ||
            data.status === 'Forwarded' ||
            data.status === 'Transferred Scheme Application' ||
            data.status === 'Transferred back Scheme Application',
        ).length > 0 && (
          <>
            <Row className="container-fluid m-0 p-0">
              <p className="text_approved py-2">
                <Translate value="Pending" />
              </p>
            </Row>
            <Row className="schemeStatus">
              <>
                {schemeStatus &&
                schemeStatus.data.filter(
                  (data) =>
                    data.status === 'Pending' ||
                    data.status === 'Forwarded' ||
                    data.status === 'Transferred Scheme Application' ||
                    data.status === 'Transferred back Scheme Application',
                ).length > 0 ? (
                  schemeStatus &&
                  schemeStatus.data
                    .filter(
                      (data) =>
                        data.status === 'Pending' ||
                        data.status === 'Forwarded' ||
                        data.status === 'Transferred Scheme Application' ||
                        data.status === 'Transferred back Scheme Application',
                    )
                    .map((row) => (
                      <Col xs={12} md={6}>
                        {/* <div className="success_bar"></div> */}
                        <div className="bar_content pendingscheme">
                          <div className="container">
                            <Row className="box1 d-flex flex-column p-1  border">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="box1_text">
                                  <Translate value="schemeName" />
                                </div>
                                <div className="box1_text">
                                  {row.scheme_name}
                                </div>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Scheme Applied Date</div> */}
                                <div className="box1_text">
                                  <Translate value="schemeAppliedDate" />
                                </div>
                                <div className="box1_text">
                                  {moment(row.applied_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Status</div> */}
                                <div className="box1_text">
                                  <Translate value="status" />
                                </div>
                                <div className="box1_text">
                                  <span>
                                    Pending{' '}
                                    <HistoryRoundedIcon
                                      sx={{
                                        alignItems: 'center',
                                        background: `#edc937`,
                                        borderRadius: '100%',

                                        color: 'white',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Row>

                            <Row className="box2 border d-flex align-items-center px-1">
                              <Link
                                to={`/view/schemeack/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                target="_blank"
                              >
                                <Button
                                  className="w-75 text-start"
                                  variant="outline-secondary"
                                >
                                  <VisibilityRoundedIcon />
                                  View Acknowledgement Letter
                                </Button>
                                {row.is_renewal && (
                                  <span className="px-2">
                                    <Chip
                                      sx={{
                                        background: '#EDC937',
                                        color: 'white',
                                        padding: '10px',
                                        fontSize: '14px',
                                        fontWeight: 'bolder',
                                      }}
                                      label="Renewal"
                                      variant="outlined"
                                    />
                                  </span>
                                )}{' '}
                              </Link>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                ) : (
                  <Typography>No pending applications</Typography>
                )}
              </>
            </Row>
          </>
        )}
      {schemeStatus &&
        schemeStatus.data.filter((data) => data.status === 'Draft Approved')
          .length > 0 && (
          <>
            <Row className="container-fluid m-0 p-0">
              <p className="text_approved py-2">
                <Translate value="Draft Approved" />
              </p>
            </Row>
            <Row className="schemeStatus">
              <>
                {schemeStatus &&
                schemeStatus.data.filter(
                  (data) => data.status === 'Draft Approved',
                ).length > 0 ? (
                  schemeStatus &&
                  schemeStatus.data
                    .filter((data) => data.status === 'Draft Approved')
                    .map((row) => (
                      <Col xs={12} md={6}>
                        {/* <div className="success_bar"></div> */}
                        <div className="bar_content approvedscheme">
                          <div className="container">
                            <Row className="box1 d-flex flex-column p-1  border">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="box1_text">
                                  <Translate value="schemeName" />
                                </div>
                                <div className="box1_text">
                                  {row.scheme_name}
                                </div>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Scheme Applied Date</div> */}
                                <div className="box1_text">
                                  <Translate value="schemeAppliedDate" />
                                </div>
                                <div className="box1_text">
                                  {moment(row.applied_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Status</div> */}
                                <div className="box1_text">
                                  <Translate value="status" />
                                </div>
                                <div className="box1_text">
                                  <span>
                                    Draft Approved{' '}
                                    <CheckCircleOutlineRoundedIcon
                                      sx={{
                                        alignItems: 'center',
                                        background: `#55B54A`,
                                        borderRadius: '100%',

                                        color: 'white',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Row>

                            <Row className="box2 border d-flex align-items-center px-1">
                              <Link
                                to={`/view/schemeack/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                target="_blank"
                              >
                                <Button
                                  className="w-75 text-start"
                                  variant="outline-secondary"
                                >
                                  <VisibilityRoundedIcon />
                                  View Acknowledgement Letter
                                </Button>
                                {row.is_renewal && (
                                  <span className="px-2">
                                    <Chip
                                      sx={{
                                        background: '#55B54A',
                                        color: 'white',
                                        padding: '10px',
                                        fontSize: '14px',
                                        fontWeight: 'bolder',
                                      }}
                                      label="Renewal"
                                      variant="outlined"
                                    />
                                  </span>
                                )}{' '}
                              </Link>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                ) : (
                  <Typography>No draft approved applications</Typography>
                )}
              </>
            </Row>
          </>
        )}
      {schemeStatus &&
        schemeStatus.data.filter((data) => data.status === 'Draft Rejected')
          .length > 0 && (
          <>
            <Row className="container-fluid m-0 p-0">
              <p className="text_approved py-2">
                <Translate value="Draft Rejected" />
              </p>
            </Row>
            <Row className="schemeStatus">
              <>
                {schemeStatus &&
                schemeStatus.data.filter(
                  (data) => data.status === 'Draft Rejected',
                ).length > 0 ? (
                  schemeStatus &&
                  schemeStatus.data
                    .filter((data) => data.status === 'Draft Rejected')
                    .map((row) => (
                      <Col xs={12} md={6}>
                        {/* <div className="success_bar"></div> */}
                        <div className="bar_content rejectedscheme">
                          <div className="container">
                            <Row className="box1 d-flex flex-column p-1  border">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="box1_text">
                                  <Translate value="schemeName" />
                                </div>
                                <div className="box1_text">
                                  {row.scheme_name}
                                </div>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Scheme Applied Date</div> */}
                                <div className="box1_text">
                                  <Translate value="schemeAppliedDate" />
                                </div>
                                <div className="box1_text">
                                  {moment(row.applied_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Status</div> */}
                                <div className="box1_text">
                                  <Translate value="status" />
                                </div>
                                <div className="box1_text">
                                  <span>
                                    Draft Rejected{' '}
                                    <CancelRoundedIcon
                                      sx={{
                                        alignItems: 'center',
                                        background: `#db2218`,
                                        borderRadius: '100%',

                                        color: 'white',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Row>

                            <Row className="box2 border d-flex align-items-center px-1">
                              <Link
                                to={`/view/schemeack/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                target="_blank"
                              >
                                <Button
                                  className="w-75 text-start"
                                  variant="outline-secondary"
                                >
                                  <VisibilityRoundedIcon />
                                  View Acknowledgement Letter
                                </Button>
                                {row.is_renewal && (
                                  <span className="px-2">
                                    <Chip
                                      sx={{
                                        background: '#DB2218',
                                        color: 'white',
                                        padding: '10px',
                                        fontSize: '14px',
                                        fontWeight: 'bolder',
                                      }}
                                      label="Renewal"
                                      variant="outlined"
                                    />
                                  </span>
                                )}{' '}
                              </Link>
                            </Row>

                            <Row className="box2 border d-flex align-items-center px-3">
                              <Button
                                className="w-100 text-start"
                                variant="outline-secondary"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleClickOpen(
                                    row.scheme_avail_id,
                                    row.scheme_name,
                                    row.status,
                                  )
                                }
                              >
                                <DoubleArrowIcon /> Click here to view draft
                                rejected reasons
                              </Button>
                              {
                                <RejectedReasonsDialog
                                  open={open}
                                  handleClose={handleClose}
                                  reasons={reasons}
                                  serviceName={schemeName}
                                  loading={loading}
                                  value={value}
                                  handleChange={handleChange}
                                />
                              }
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                ) : (
                  <Typography>No draft rejected applications</Typography>
                )}
              </>
            </Row>
          </>
        )}{' '}
      {schemeStatus &&
        schemeStatus.data.filter((data) => data.status === 'Rejected').length >
          0 && (
          <>
            <Row className="container-fluid m-0 p-0">
              <p className="text_approved py-2">
                <Translate value="Rejected" />
              </p>
            </Row>
            <Row className="schemeStatus">
              <>
                {schemeStatus &&
                schemeStatus.data.filter((data) => data.status === 'Rejected')
                  .length > 0 ? (
                  schemeStatus &&
                  schemeStatus.data
                    .filter((data) => data.status === 'Rejected')
                    .map((row) => (
                      <Col xs={12} md={6}>
                        {/* <div className="success_bar"></div> */}
                        <div className="bar_content rejectedscheme">
                          <div className="container">
                            <Row className="box1 d-flex flex-column p-1  border">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="box1_text">
                                  <Translate value="schemeName" />
                                </div>
                                <div className="box1_text">
                                  {row.scheme_name}
                                </div>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Scheme Applied Date</div> */}
                                <div className="box1_text">
                                  <Translate value="schemeAppliedDate" />
                                </div>
                                <div className="box1_text">
                                  {moment(row.applied_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                {/* <div className="box1_text">Status</div> */}
                                <div className="box1_text">
                                  <Translate value="status" />
                                </div>
                                <div className="box1_text">
                                  <span>
                                    Rejected{' '}
                                    <CancelRoundedIcon
                                      sx={{
                                        alignItems: 'center',
                                        background: `#db2218`,
                                        borderRadius: '100%',

                                        color: 'white',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-1">
                              <div className="w_50">
                                <span className="me-2">
                                  <img src={calendar} alt="" />
                                </span>
                                <span>
                                  {moment(row.approved_rejected_date).format(
                                    'DD/MM/YYYY',
                                  )}
                                </span>
                              </div>
                              <div className="float-right time">
                                {row.is_renewal && (
                                  <span className="px-2">
                                    <Chip
                                      sx={{
                                        background: '#DB2218',
                                        color: 'white',
                                        padding: '10px',
                                        fontSize: '14px',
                                        fontWeight: 'bolder',
                                      }}
                                      label="Renewal"
                                      variant="outlined"
                                    />
                                  </span>
                                )}
                              </div>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-3">
                              <Button
                                className="w-100 text-start"
                                variant="outline-secondary"
                              >
                                <Link
                                  to={`/view/schemeack/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                  target="_blank"
                                  style={{ color: 'inherit' }}
                                >
                                  <VisibilityRoundedIcon />
                                  View Acknowledgement Letter
                                </Link>
                              </Button>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-3">
                              <Button
                                className="w-100 text-start"
                                variant="outline-secondary"
                              >
                                <Link
                                  to={`/view/scheme-rejection-order/${row.scheme_name}/${labourUserID}/${row.scheme_avail_id}`}
                                  target="_blank"
                                  style={{ color: 'inherit' }}
                                >
                                  <VisibilityRoundedIcon />
                                  View Rejection Order
                                </Link>
                              </Button>
                            </Row>
                            <Row className="box2 border d-flex align-items-center px-3">
                              <Button
                                className="w-100 text-start"
                                variant="outline-secondary"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleClickOpen(
                                    row.scheme_avail_id,
                                    row.scheme_name,
                                    row.status,
                                  )
                                }
                              >
                                <DoubleArrowIcon /> Click here to view rejected
                                reasons
                              </Button>
                              {
                                <RejectedReasonsDialog
                                  open={open}
                                  handleClose={handleClose}
                                  reasons={reasons}
                                  serviceName={schemeName}
                                  loading={loading}
                                  value={value}
                                  handleChange={handleChange}
                                />
                              }
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                ) : (
                  <Typography>No rejected appications </Typography>
                )}
              </>
            </Row>
          </>
        )}
    </>
  );
};

export default SchemeStatusSection;
