import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, ListItemText, MenuItem, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigBoardDetails, getConfigMinistryDetails, getLabourStatusDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import { PhoneAndroid } from '@material-ui/icons';
import { Translate } from 'react-redux-i18n';
import axios from 'axios';


const BoardMasterForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,

        props: "",

        catalogValue: "",
        catalogValueNumber: "",
        description: "",

        isEdit: false,
    })


    useEffect(() => {
        console.log("allValues: ",allValues)
    }, [allValues]);

    useEffect(() => {
        console.log("props: ", props)
        if (
            props.location !== undefined &&
            props.location.rowdata !== undefined &&
            props.location.rowdata !== null) {

                var isedit = false
                if(props.location.isEdit === undefined){
                    isedit = false
                }
                else if(props.location.isEdit === true){
                    isedit = true
                }
                else{
                    isedit = false
                }

            setAllValues({
                ...allValues,
                props: props,
                rowdata: props.location.rowdata,
                isEdit: isedit
            })
        }
        else{
            props.history.push("/admin/catalog-master")
        }
    }, []);


    useEffect(() => {
        if (allValues.isEdit) {
            var val = props.location.valRowData
            setAllValues({
                ...allValues,
                catalogValue: val.name,
                catalogValueNumber: val.value,
                description: val.description
            })
        }
    }, [allValues.isEdit]);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })   
    }

    const resetButtonPressed = () => {
        handleClickOpen()
    }

    const saveButtonPressed = () => {
        handleClickOpenSave()
    }

    const [open, setOpen] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleYes = () => {
        setAllValues({
            ...allValues,
            boardName: "",
            short_name: "",
            code: "",
            description: "",
            address: "",
            phone_no: "",
            alternate_phone_no: "",
            fax: "",
            website: "",
        })   
        handleClose()
    };

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };

    const handleCloseSave = () => {
        setOpenSave(false);
    };
    
    const handleYesSave = () => {
        // console.error("in useEffect props:"+props.location.userid )

        if(
            allValues.catalogValue === "" ||
            allValues.catalogValueNumber === "" ||
            allValues.description === ""
        ){
            showToast("ERROR", "Please fill all the required fields.")
        }
        else if(allValues.isEdit){
            console.log("handleYesSave in isEdit")
            var val = allValues.props.location.valRowData
            var data = JSON.stringify({
                "id": val.id,
                "name": allValues.catalogValue,
                "value": allValues.catalogValueNumber,
                "short_name": null,
                "description": allValues.description,
                "code": null,
                "user_id": users.getDeptUserRole.data[0].department_user_id
              });
              var config = {
                method: 'post',
                url: SERVER +'/config/catalog/update_catalog_value',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                showToast("SUCCESS", "Catalog Value Updated Successfully!")
                props.history.push("/admin/catalog-master")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
        else{
            var data = JSON.stringify({
                "master_id": allValues.rowdata.id,
                "value": allValues.catalogValueNumber,
                "name": allValues.catalogValue,
                "short_name": null,
                "description": allValues.description,
                "code": null,
                "parent_id": null,
                "user_id": users.getDeptUserRole.data[0].department_user_id
              });
              
              var config = {
                method: 'post',
                url: SERVER +'/config/catalog/insert_catalog_value',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                showToast("SUCCESS", "Catalog Value Added Successfully!")
                props.history.push("/admin/catalog-master")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
          
        handleCloseSave()
    };


    return (
        <>
            <div className="root">

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Reset the form?"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    This will clear all the data.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleYes} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // fullScreen={fullScreen}
                open={openSave}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Save Changes"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to save the changes made?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseSave} variant="outlined" color="secondary">
                    No
                </Button>
                <Button onClick={handleYesSave} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Board</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
            </div>

            {
                allValues.props !== "" ? 

                <Row className="config-page-row-title">
                    <Col xs={12} className="title-col">
                        <Link to="#" onClick={() => props.history.goBack()}>
                            <img alt="..." className="cal-icon hover-icn" src={back} />
                            <p className="title">Catalog Master {'>'} {allValues.rowdata.name} {'>'} Add {allValues.rowdata.name}</p>
                        </Link>
                    </Col>
                </Row>
                :
                null
            }

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Catalog Value" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Catalog Value"
                                name="catalogValue"
                                value={allValues.catalogValue}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Catalog Value Number" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Catalog Value"
                                name="catalogValueNumber"
                                // type="number"
                                value={allValues.catalogValueNumber}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={8} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Description" />
                        <FormControl fullWidth className="formcontrol1">
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    // rowsMax={4}
                                    // defaultValue="Default Value"
                                    placeholder="Enter Description of Board"
                                    name="description"
                                    value={allValues.description}
                                    onChange={handleChange}
                                />
                            </FormControl>

                    {/* </div> */}
                </Col>

                <Col xs={12} className="next-back-button-row mt-4">

                                    <Link to="#" 
                                        onClick={resetButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            Reset
                                        </Button>
                                    </Link>

                                    <Link to="#" 
                                    onClick={saveButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="save-button">
                                            Save
                                        </Button>
                                    </Link>
                    
                </Col>


            </Row>
        </>
    )
}

export default BoardMasterForm;