import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import male from 'assets/images/Mask Group 1.svg';
import female from 'assets/images/Mask Group 2.svg';
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg';
import { Translate } from 'react-redux-i18n';
import { Required, getTokenCookie } from 'utils/tools';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';

import CancelIcon from '@mui/icons-material/Cancel';
import FolderIcon from '@mui/icons-material/Folder';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { SERVER } from 'store/actions/user.actions';
import Typography from '@mui/material/Typography';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Paper from '@mui/material/Paper';
const PersonalDetails = ({
  applicantName,
  dob,
  genderID,
  userPhotoID,
  existingCardDoc,
  existingCardRef,
  handleFileUpload,
  handleRemoveFile,
  fetchCategory,
  fetchReligion,
  fetchNatureOfWork,
  fetchMaritialStatus,
  maritialStatusID,
  setMaritialStatusID,
  categoryID,
  setCategoryID,
  religionID,
  setReligionID,
  natureOfWorkID,
  setNatureOfWorkID,
  isAyushman,
  setIsAyushman,
  isMigrant,
  setIsMigrant,
  ayushmanCardNumber,
  setAyushmanCardNumber,
  fetchStates,
  migrantID,
  setMigrantID,
}) => {
  return (
    <Paper
      elevation={3}
      style={{ margin: '20px', borderRadius: '10px', padding: '20px 0' }}
    >
      <Container>
        <Row className="g-3 px-3 form-row py-5">
          <Typography
            variant="h5"
            color={'GrayText'}
            className="text-left d-flex justify-content-start align-items-center"
          >
            <PersonRoundedIcon
              style={{ width: '30px', height: '30px' }}
              sx={{
                color: 'white',

                backgroundColor: 'GrayText',
                borderRadius: '100%',
              }}
            />{' '}
            <span className="px-2" style={{ fontWeight: 'bolder' }}>
              Personal Details
            </span>
          </Typography>
          <div className="d-flex justify-content-center align-items-center my-3">
            {userPhotoID ? (
              <img
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '5px',
                }}
                src={`${SERVER}/upload/v2/file/${userPhotoID}?token=${getTokenCookie()}`}
                width={250}
                height={250}
                alt="userPhoto"
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <Col md={6}>
            <Required title={<Translate value="Labour Name" />} />
            <FormControl variant="outlined" fullWidth>
              <TextField value={applicantName} disabled variant="outlined" />
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="Date of Birth" />} />
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={moment(dob).format('DD/MM/YYYY')}
                disabled
                variant="outlined"
              />
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="selectYourGender" />} />

            <div className="d-flex justify-content-center align-items-center">
              <div className="button genderradio">
                <input
                  type="radio"
                  name="gender"
                  value={genderID}
                  id="male"
                  // onChange={handleChange}
                  checked={genderID === '27'}
                  // disabled={aadharValidated ? true : false}
                />
                <label htmlFor="male">
                  {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                  <img alt="..." src={male} />
                  <span>
                    <Translate value="male" />
                  </span>
                </label>
              </div>
              <div className="button genderradio">
                <input
                  type="radio"
                  name="gender"
                  value={genderID}
                  id="female"
                  // onChange={handleChange}
                  checked={genderID === '28'}
                  // disabled={aadharValidated ? true : false}
                />
                <label htmlFor="female">
                  {/* <img alt="..."  className="gender-img" src="https://www.pngkey.com/png/full/203-2037403_flat-faces-icons-circle-girl-flat-icon-png.png" /> */}
                  <img alt="..." src={female} />
                  <span>
                    <Translate value="female" />
                  </span>
                </label>
              </div>
              <div className="button genderradio">
                <input
                  type="radio"
                  name="gender"
                  value={genderID}
                  id="others"
                  // onChange={handleChange}
                  checked={genderID === '29'}
                  // disabled={aadharValidated ? true : false}
                />
                <label htmlFor="others">
                  {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                  <img alt="..." src={radioon} />
                  <span>
                    <Translate value="others" />
                  </span>
                </label>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="maritalStatus" />} />
            <FormControl variant="outlined" fullWidth className="formcontrol6">
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={maritialStatusID || '0'}
                name="maritialStatus"
                displayEmpty
                onChange={(ev) => setMaritialStatusID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Marital Status" />
                </MenuItem>
                {fetchMaritialStatus &&
                  fetchMaritialStatus.length &&
                  fetchMaritialStatus.map((row) => (
                    <MenuItem value={row.value_id}>
                      <ListItemText primary={row.value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="category" />} />
            <FormControl variant="outlined" fullWidth className="formcontrol6">
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={categoryID || '0'}
                name="category"
                displayEmpty
                onChange={(ev) => setCategoryID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Category" />
                </MenuItem>
                {fetchCategory &&
                  fetchCategory.length &&
                  fetchCategory.map((row) => (
                    <MenuItem value={row.value_id}>
                      <ListItemText primary={row.value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="religion" />} />
            <FormControl variant="outlined" fullWidth className="formcontrol5">
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={religionID || '0'}
                name="religion"
                displayEmpty
                onChange={(ev) => setReligionID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Religion" />
                </MenuItem>
                {fetchReligion &&
                  fetchReligion.length &&
                  fetchReligion.map((row) => (
                    <MenuItem value={row.value_id}>
                      <ListItemText primary={row.description} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col>
            <Required title={<Translate value="natureoftheWorkplace" />} />
            <FormControl variant="outlined" fullWidth className="formcontrol5">
              <Select
                className="select-marital"
                value={natureOfWorkID || '0'}
                name="natureOfWork"
                displayEmpty
                onChange={(ev) => setNatureOfWorkID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Nature of Work" />
                </MenuItem>
                {fetchNatureOfWork &&
                  fetchNatureOfWork.length &&
                  fetchNatureOfWork.map((row) => (
                    <MenuItem value={row.value_id}>
                      <ListItemText primary={row.value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <FormControl
              className="interstateRadio formcontrol9"
              variant="outlined"
              fullWidth
              component="fieldset"
            >
              {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
              <RadioGroup
                row
                name="isMigrant"
                value={isMigrant}
                onChange={(ev) => setIsMigrant(ev.target.value)}
              >
                <Required
                  title={<Translate value="Interstate Migrant Worker" />}
                />

                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={<Translate value="yes" />}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={<Translate value="no" />}
                />
              </RadioGroup>
            </FormControl>
            {isMigrant === '1' && (
              <>
                {/* <Required title={<Translate value="Migrant from State" />} /> */}
                <FormControl variant="outlined" fullWidth>
                  <Select
                    className="select-state"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={migrantID || '0'}
                    // name="migrantState"
                    displayEmpty
                    onChange={(ev) => setMigrantID(ev.target.value)}
                  >
                    <MenuItem value="0">
                      <ListItemText primary="Select Migrant State" />
                    </MenuItem>
                    {fetchStates &&
                      fetchStates.length &&
                      fetchStates.map(
                        (row) =>
                          row.name !== 'Karnataka' && (
                            <MenuItem value={row.id}>
                              <ListItemText primary={row.name} />
                            </MenuItem>
                          ),
                      )}
                  </Select>
                </FormControl>
              </>
            )}
          </Col>

          <Col md={6}>
            <div className="my-4">
              <Required title={<Translate value="Existing Labour Card" />} />
              {existingCardDoc &&
                existingCardDoc.length > 0 &&
                existingCardDoc.map((file, index) => (
                  <FormControl variant="outlined" fullWidth className="w-75">
                    <TextField
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              <IconButton
                                onClick={() =>
                                  handleRemoveFile(index, 'existingCard')
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}

              {existingCardDoc && existingCardDoc.length === 0 && (
                <FormControl fullWidth className="w-75">
                  <TextField
                    variant="outlined"
                    placeholder="Click Browse to Upload"
                    disabled
                  />
                </FormControl>
              )}
              <input
                type="file"
                ref={existingCardRef}
                onChange={handleFileUpload}
                multiple
                hidden
                name="existingCard"
              />
              <Button
                className="mx-3 py-2"
                onClick={() => existingCardRef.current.click()}
              >
                <FolderIcon />
                Browse
              </Button>
            </div>
          </Col>

          <Col md={6}>
            <FormControl
              className="interstateRadio formcontrol9"
              variant="outlined"
              fullWidth
              component="fieldset"
            >
              {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
              <RadioGroup
                row
                name="isMigrant"
                value={isAyushman}
                onChange={(ev) => setIsAyushman(ev.target.value)}
              >
                <Required
                  title={<Translate value="Ayushman Bharat Card Holder" />}
                />

                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={<Translate value="yes" />}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={<Translate value="no" />}
                />
              </RadioGroup>
            </FormControl>
            {isAyushman === '1' && (
              <>
                {/* <Required
                  title={<Translate value="Ayushman Card Bharat Number" />}
                /> */}
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    placeholder="Enter Ayushman Bharat Card Number"
                    name="ayushManCard"
                    value={ayushmanCardNumber}
                    onChange={(ev) => setAyushmanCardNumber(ev.target.value)}
                  />
                </FormControl>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Paper>
  );
};

export default PersonalDetails;
