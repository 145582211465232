import 'date-fns';
import { Select, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import { setLocaleWithFallback } from 'store/actions/user.actions'
import usericon from 'assets/images/Icon awesome-user.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import education from 'assets/images/Education Assitance-01.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { Translate } from 'react-redux-i18n';





const SchemeDoc = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        searchValueError: false,
        submitting: false,
    })

    let { id } = useParams()
    console.log("param_in_schemeDoc: "+id)

    // var idName = id.split('_id=')[0]

    // useEffect(() => {
    //     if(users.user !== undefined && users.user !== null){
    //         dispatch(get_schemes(users.user.board_id))
    //     }
    // }, []);


    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }
    const applyButtonPressed = (event) => {
        props.history.push("/scheme-form/continuation-of-pension");
    }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div ">
                    <Row className="topbar-row">
                        <Col xs={12} md={4} lg={6} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/dashboard-user">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction<br/>Workers Welfare Board (KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>

                        
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="schemes-title-row-red">            
                <Col className = "scheme-back" xs={12} md={9}>
                    
                    <a href="/schemes-home" className="cal-icon-hover-icn" style = {{ marginLeft : "-70px" }} >
                            <img alt="..." className="cal-icon hover-icn position-relative start-50 bottom-25" src={back}/>
                    </a>
                    <h1 className='scheme-heading'><Translate value = "schemes" /></h1>
                </Col>
            </Row>
            <div className='edu-div'>           
            <Col xs={4} md={4} lg={3} className="ed-assistance-scheme1">
                    <Button variant="danger" onClick={()=> window.open("https://ssp.karnataka.gov.in/", "_blank")} className="pre-ed-button">
                        <img alt="..." src={education} className="education-img" />
                        <p> <Translate value = "prematric" /> </p>
                    </Button>
                    <Button variant="danger" onClick={()=> window.open("https://ssp.postmatric.karnataka.gov.in/", "_blank")}  className="post-ed-button" >
                        <img alt="..." src={education} className="education-img" />
                        <p> <Translate value = "postmatric" /> </p>
                    </Button>
                {/* </Link>
                <Link to="#" 
                            onClick={applyButtonPressed}
                            style={{ flexGrow: "0.5" }}
                            >
                                <Button variant="outline-primary" className="save-button">
                                    Apply
                                </Button>
                </Link> */}
            </Col>
            </div>
        </>
    )
}

export default SchemeDoc;