import React from 'react';
import { Button } from 'react-bootstrap';

const Forward = ({ handleTransaction, loading }) => {
  return (
    <Button
      onClick={handleTransaction}
      variant="outline-primary"
      className="final-button-accept"
      disabled={loading}
    >
      Forward
    </Button>
  );
};

export default Forward;
