import 'date-fns';
import { FormControl, TextField, Select, MenuItem, ListItemIcon, ListItemText, InputAdornment } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import { get_schemes, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { getTokenCookie, showToast } from 'utils/tools';
import search from 'assets/images/Icon ionic-ios-search.svg'
import MarriageAssistance from 'assets/images/marriage assistance-01.svg'
import usericon from 'assets/images/Icon awesome-user-circle.svg'
import accident from 'assets/images/Accident-01.svg'
import disability from 'assets/images/Continuation of disability pension-01.svg'
import continuationPension from 'assets/images/Continuation-of-pension-01.svg'
import closeIcon from 'assets/images/close-01.svg'
import pension from 'assets/images/Disability Pension-01.svg'
import education from 'assets/images/Education Assitance-01.svg'
import accident_education from 'assets/images/accident_education.png'

// import Funeral from 'assets/images/Funeral-01.svg'
import Funeral from 'assets/images/Funeral and EX -Gratia.png'
import Group from 'assets/images/Group 70.svg'
import ksrtc from 'assets/images/ksrtc bus pass-01.svg'
import LPG from 'assets/images/LPG CONNECTION-01.svg'
import Maga from 'assets/images/Maga-01.svg'
import medical from 'assets/images/medical assisatance.svg'
import Medical2 from 'assets/images/Medical assistance-01.svg'
import UPSC from 'assets/images/Pre Coaching (UPSC and KPSC) Application 2.svg'
// import pregnant from 'assets/images/pregnant woman-01.svg'
import pregnant from 'assets/images/images-02.svg'
import Tool from 'assets/images/shrama samathya Tool kit-01.svg'
import duplicateId from 'assets/images/DUlicate id card-01.svg'
import HomeButton from 'assets/images/Icon material-home.svg'
import axios from 'axios';
import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';

// <<<<<<< kannada-translation-schemes
// import { Translate } from '@material-ui/icons';
import { Translate } from 'react-redux-i18n';
// =======
import moment from 'moment';
// >>>>>>> main
import { useLocation } from "react-router-dom";




const UnorganizedSchemes = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const i18nlang = useSelector(state => state.i18n)



    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        searchValueError: false,
        submitting: false,
        searchValue: "",
        check_user_availed_schemes: null,
        kannadaClick: i18nlang.locale === "ka" ? true : false
    })

    const [SearchRows, setSearchRows] = React.useState("")
    const [marriageLimit, setMarriageLimit] = React.useState("")
    const [deliveryLimit, setDeliveryLimit] = React.useState("")
    const [thayiMaguLimit, setThayiMaguLimit] = React.useState("")
    const [medicalLimit, setMedicalLimit] = React.useState("")
    const [accidentLimit, setAccidentLimit] = React.useState("")


    const location = useLocation();






    useEffect(() => {



        window.scrollTo(0, 0)
        if (users.user !== undefined && users.user !== null) {
            dispatch(get_schemes(users.user.board_id))
        }

        var data = JSON.stringify({
            "board_id": users.user.board_id,
            "labour_user_id": users.user.id,
            "scheme_id": 0,
            "check_for": "all"
        });

        var config = {
            method: 'post',
            url: SERVER + '/schemes/check_user_availed_schemes',
            headers: {
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("check_user_availed_schemes", JSON.stringify(response.data.data));

                setAllValues({
                    ...allValues,
                    check_user_availed_schemes: response.data.data
                })
                setMarriageLimit(response.data.data.self.filter(o => o.scheme_name === "Marriage Assistance"))
                setDeliveryLimit(response.data.data.self.filter(o => o.scheme_name === "Delivery Assistance"))
                setThayiMaguLimit(response.data.data.self.filter(o => o.scheme_name === "Thayi Magu Sahaya Hasta"))
                setMedicalLimit(response.data.data.self.filter(o => o.scheme_name === "Medical Assistance\r\n"))
                setAccidentLimit(response.data.data.self.filter(o => o.scheme_name === "Accident Assistance"))



            })
            .catch(function (error) {
                console.log(error);

            });

    }, []);

    useEffect(() => {


        if (i18nlang.locale === "ka") {
            setAllValues({
                ...allValues,
                kannadaClick: true
            })
            console.log("i18lan", i18nlang.locale)

        }
        else if (i18nlang.locale === "en") {
            setAllValues({
                ...allValues,
                kannadaClick: false
            })
        }
    }, [])

    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
    }, [allValues]);


    useEffect(() => {
        if (
            allValues.searchValue !== "" &&
            allValues.searchValue !== undefined &&
            allValues.searchValue !== null &&
            users.get_schemes !== undefined &&
            users.get_schemes !== null &&
            users.get_schemes.data !== undefined &&
            users.get_schemes.data !== null &&
            users.get_schemes.data.length > 0
        ) {
            setSearchRows(users.get_schemes.data.filter(row =>

                row.name.toLowerCase().includes(allValues.searchValue.toLowerCase())
                // ? 
                // row.scheme_name.toLowerCase().includes(allValues.searchValue.toLowerCase()) : 
                // row.code.toLowerCase().includes(allValues.searchValue.toLowerCase())

            )

            )
        }
        else {

        }

    }, [allValues.searchValue]);

    useEffect(() => {
        console.log("SearchRows: ", SearchRows)

    }, [SearchRows]);



    const handleChangeSearch = (event) => {
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value,

        })
    }

    const handleChange = (event) => {
        console.log(event.target);



        if (event.target.value === "ka") {
            setAllValues({
                ...allValues,
                kannadaClick: true,
                [event.target.name]: event.target.value,

            })

        }
        else {
            setAllValues({
                ...allValues,
                kannadaClick: false,
                [event.target.name]: event.target.value,

            })
        }


        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }



    const schemeClicked = (event, toLink, schemeName, schemeNameKannada, activeStatus) => {
        event.preventDefault()
        if (activeStatus) {



            console.log("schemeNameKannada", schemeNameKannada);
            console.log("Status", activeStatus);
            console.log("calculated: " + allValues.check_user_availed_schemes.self.find((value) => value.scheme_name === "Accident Assistance").total_count_can_be_availed)

            var total_count_can_be_availed = allValues.check_user_availed_schemes.self.find((value) => value.scheme_name === schemeName).total_count_can_be_availed

            var total_aviled_by_user = allValues.check_user_availed_schemes.self.find((value) => value.scheme_name === schemeName).total_aviled_by_user



            if (schemeName === "Accident Assistance" || schemeNameKannada === " ಅಪಘಾತ ನೆರವು") {


                var disabilityEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Disability Pension")
                var accEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Accident Assistance")

                console.log("AccEntry", accEntry)
                if (
                    disabilityEntry.total_aviled_by_user > 0
                ) {
                    showToast("ERROR", "You Are not eligible!")
                }

                else {
                    if (accEntry[0].is_pending_for_approval === 0 || accEntry[1] !== undefined && accEntry[1].is_pending_for_approval === 0) {
                        if (accEntry[1] !== undefined && accEntry[1] !== null && accEntry[1].total_aviled_by_user === accEntry[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")

                        }
                        else {
                            props.history.push(toLink)

                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }

                }
            }

            else if (schemeName === "Assistance For Major Ailments\r\n" || schemeNameKannada === "ಪ್ರಮುಖ ಕಾಯಿಲೆಗಳ ಚಿಕಿತ್ಸೆಗಾಗಿ ವೈದ್ಯಕೀಯ ವೆಚ್ಚಗಳ ನೆರವು") {

                var assistEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Assistance For Major Ailments")
                console.log("assistEntry", assistEntry)

                if (assistEntry[0].is_pending_for_approval === 0 || assistEntry[1] !== undefined && assistEntry[1].is_pending_for_approval === 0) {
                    if (assistEntry[1] !== undefined && assistEntry[1].total_aviled_by_user === assistEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")
                    }
                    else {
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")

                }


            }

            else if (schemeName === "BMTC Bus pass\r\n" || schemeNameKannada === "ಬಿಎಂಟಿಸಿ ಬಸ್ ಪಾಸ್") {
                var bmtcEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "BMTC Bus Pass")
                console.log("BMTC entry", bmtcEntry)
                if (users.getUserRegistrationDetails.address_details[0].address_base_type === "Residential" && users.getUserRegistrationDetails.address_details[0].district === "BANGALORE URBAN" || users.getUserRegistrationDetails.address_details[0].district === "BANGALORE RURAL") {
                    if (bmtcEntry[0].is_pending_for_approval === 0 || bmtcEntry[1] !== undefined && bmtcEntry[1].is_pending_for_approval === 0) {

                        if (bmtcEntry[1] !== undefined && bmtcEntry[1].total_aviled_by_user === bmtcEntry[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")

                        }
                        else {
                            props.history.push(toLink)

                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }
                }

                else {
                    showToast("ERROR", "This scheme is only eligible for users from Bengaluru")
                }


            }

            else if (schemeName === "Continuation of Disability Pension" || schemeNameKannada === "ಅಂಗವೈಕಲ್ಯ (ವಿಶೇಷ ಚೇತನ) ಪಿಂಚಣಿಯ ಮುಂದುವರಿಕೆ") {
                var continuationEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Continuation of Disability Pension")

                if (moment().month() !== 11) {
                    showToast("ERROR", "Only Available in December!")
                }
                else if (
                    (allValues.check_user_availed_schemes.self.find((value) => value.scheme_name === "Disability Pension").total_aviled_by_user
                        > 0)

                ) {
                    if (continuationEntry[1].total_aviled_by_user === continuationEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")

                    }
                    else {
                        props.history.push(toLink)
                    }

                }

                else {
                    showToast("ERROR", "You Are not eligible!")
                }
            }

            else if (schemeName === "Continuation Of Pension" || schemeNameKannada === "ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ") {
                var continuationEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Continuation Of Pension")

                if (moment().month() !== 11) {
                    showToast("ERROR", "Only Available in December!")
                }
                else if (
                    (allValues.check_user_availed_schemes.self.find((value) => value.scheme_name === "Pension Scheme").total_aviled_by_user
                        > 0)

                ) {

                    if (continuationEntry[1].total_aviled_by_user === continuationEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")

                    }
                    else {
                        props.history.push(toLink)

                    }

                }

                else {
                    showToast("ERROR", "You Are not eligible!")
                }
            }

            else if (schemeName === "Delivery Assistance" || schemeNameKannada === "ಹೆರಿಗೆಗೆ ಧನ ಸಹಾಯ") {
                console.log("in Delivery Assistance")
                var deliveryPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Delivery Assistance")

                console.log("users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id: ", users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id)
                if (
                    users.getUserRegistrationDetails !== undefined &&
                    users.getUserRegistrationDetails.personal_details !== undefined &&
                    users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id !== undefined &&
                    users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 28

                ) {
                    // if (total_aviled_by_user < total_count_can_be_availed) {
                    //     props.history.push(toLink)
                    // }
                    // else {
                    //     showToast("ERROR", "Scheme Avail Limit Reached!")
                    // }
                    console.log("in Delivery Assistance", deliveryLimit)

                    if (deliveryPending[0].is_pending_for_approval === 0 || deliveryPending[1] !== undefined && deliveryPending[1].is_pending_for_approval === 0){

                        if (deliveryLimit[1] !== undefined && deliveryLimit[1] !== null && deliveryLimit[1].total_aviled_by_user === deliveryLimit[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")
                        }
                        else {
                            props.history.push(toLink)

                        }
                    }
                    else{
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }

                }

                else {
                    showToast("ERROR", "You Are not eligible!")
                }
            }

            else if (schemeName === "Thayi Magu Sahaya Hasta" || schemeNameKannada === "ಪ್ರಿ-ಸ್ಕೂಲ್ ಶಿಕ್ಷಣಕ್ಕಾಗಿ ಸಹಾಯಕ್ಕಾಗಿ ಅರ್ಜಿ ಮತ್ತು ನೋಂದಾಯಿತ ಮಹಿಳಾ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರಿಗೆ ಪೌಷ್ಠಿಕಾಂಶ ಆಹಾರದ ಸಹಾಯ–ತಾಯಿ ಮಗು ಸಹಾಯ ಹಸ್ತ") {

                var thayiPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Thayi Magu Sahaya Hasta")

                if (
                    users.getUserRegistrationDetails !== undefined &&
                    users.getUserRegistrationDetails.personal_details[0] !== undefined &&
                    users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id !== undefined
                    &&
                    (users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 28)


                ) {
                    console.log("in Delivery Assistance", thayiMaguLimit)
                    console.log("in Delivery Assistance1", thayiPending)

                    if (thayiPending[0].is_pending_for_approval === 0 || thayiPending[1] !== undefined && thayiPending[1].is_pending_for_approval === 0) {

                        if (thayiMaguLimit[1] === undefined && thayiMaguLimit[1] === null && thayiMaguLimit[1].total_aviled_by_user === thayiMaguLimit[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")
                        }
                        else {
                            props.history.push(toLink)
                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }

                }

                else {
                    showToast("ERROR", "You Are not eligible!")
                }
            }

            else if (schemeName === "Shrama Samarthya Toolkit\r\n" || schemeNameKannada === "ತರಬೇತಿ ಯೋಜನೆಯೊಂದಿಗೆ ಶ್ರಮ ಸಮರ್ಥ ಟೂಲ್ ಕಿಟ್‌ಗೆ ಅರ್ಜಿ") {
                var shramaEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Shrama Samarthya Toolkit\r\n")


                if (shramaEntry[0].is_pending_for_approval === 0 || shramaEntry[1] !== undefined && shramaEntry[1] === 0) {
                    if (shramaEntry[1] !== null && shramaEntry[1] !== undefined && shramaEntry[1].total_aviled_by_user === shramaEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")

                    }
                    else {
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")

                }


            }

            else if (schemeName === "Medical Assistance\r\n" || schemeNameKannada === "ವೈದ್ಯಕೀಯ ನೆರವು") {
                // props.history.push(toLink)
                console.log("deliveryLimit", medicalLimit)
                var medicalPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Medical Assistance\r\n")


                if (medicalPending[0].is_pending_for_approval === 0 || medicalPending[1] !== undefined && medicalPending[1].is_pending_for_approval === 0) {
                    if (medicalLimit[1] !== undefined && medicalLimit[1] !== null && medicalLimit[1].total_aviled_by_user === medicalLimit[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")
                    }
                    else {
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")

                }


            }

            else if (schemeName === "Pension Scheme" || schemeNameKannada === "ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ") {
                var dateString = users.getUserRegistrationDetails.personal_details[0].date_of_birth
                var dateObj = new Date(dateString)
                var momentObj = moment(dateObj)
                var age = moment().diff(momentObj, 'years');
                console.log("age today: " + age)


                var pensionEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Pension Scheme")

                if (age >= 60) {
                    if (pensionEntry[0].is_pending_for_approval === 0 || pensionEntry[1] !== undefined && pensionEntry[1].is_pending_for_approval !== undefined) {
                        if (pensionEntry[1] !== null && pensionEntry[1] !== undefined && pensionEntry[1].total_aviled_by_user === pensionEntry[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")

                        }
                        else {
                            props.history.push(toLink)
                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }
                }
                else {
                    showToast("ERROR", "Your Age should be above 60 Years!")
                }

            }

            else if (schemeName === "Marriage Assistance" || schemeNameKannada === "ವಿವಾಹ ನೆರವು") {
                
                var dateString = users.getUserRegistrationDetails.application_details[0].application_date
                var dateObj = new Date(dateString)
                var momentObj = moment(dateObj)
                var registered_age = moment().diff(momentObj, 'years');
                console.log("registered age: " + registered_age)

                var marriagePending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Marriage Assistance")


                if (registered_age >= 1) {
                    if (marriagePending[0].is_pending_for_approval == 0 || marriagePending[1] !== undefined && marriagePending[1].is_pending_for_approval) {
                        // console.log(marriageLimit[1].total_aviled_by_user,"marriageLimit")
                        if (marriageLimit[1] !== undefined && marriageLimit[1].total_aviled_by_user !== undefined) {
                            console.log("marriageLimit1")

                            if (marriageLimit[1].total_aviled_by_user === marriageLimit[1].total_count_can_be_availed) {
                                console.log("marriageLimit123")

                                showToast("ERROR", "Scheme Avail Limit Reached!")
                            }
                            else {
                                props.history.push(toLink)
                            }

                        }
                        else {
                            props.history.push(toLink)
                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }


                }
                else {
                    showToast("ERROR", "You have not completed 1 Year of Registration!")
                }

            }

            else if (schemeName === "Disability Pension" || schemeNameKannada === "ವಿಕಲ ಚೇತನ ಪಿಂಚಣಿ") {
                var accidentEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Accident Assistance")

                var disabilityPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Disability Pension")

                var disabilityEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Disability Pension")

                console.log("AccidentEntry", disabilityPending)

                if (
                    accidentEntry[1] !== undefined && accidentEntry[1].total_aviled_by_user > 0
                ) {
                    showToast("ERROR", "You Are not eligible, since Accident Assistance is already availed.")
                }

                else {
                    if (disabilityPending[0].is_pending_for_approval === 0 || disabilityPending[1] !== undefined && disabilityPending[1].is_pending_for_approval === 0) {
                        if (disabilityEntry[1] !== null && disabilityEntry[1] !== undefined && disabilityEntry[1].total_aviled_by_user === disabilityEntry[1].total_count_can_be_availed) {
                            showToast("ERROR", "Scheme Avail Limit Reached!")

                        }
                        else {
                            props.history.push(toLink)
                        }
                    }
                    else {
                        showToast("ERROR", "Your Scheme is already been applied.")

                    }
                }



            }
            else if (schemeName === "Funeral Expense and Death Assistance" || schemeNameKannada === "ಹೆರಿಗೆಗೆ ಧನ ಸಹಾಯ") {

                var funeralPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Funeral Expense and Death Assistance")


                if (funeralPending[0].is_pending_for_approval === 0 || funeralPending[1] !== undefined && funeralPending[1].is_pending_for_approval === 0) {
                    if (total_aviled_by_user < total_count_can_be_availed) {
                        props.history.push(toLink)
                    }
                    else {
                        // showToast("ERROR", "Scheme Avail Limit Reached!")
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")

                }

            }
            else if (schemeName === "Education Assistance\r\n" || schemeNameKannada === "ಶಿಕ್ಷಣ ನೆರವು") {


                var educationPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Education Assistance\r\n")

                var educationEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Education Assistance\r\n")

                if (educationPending[0].is_pending_for_approval === 0 || educationPending[1] === undefined && educationPending[1].is_pending_for_approval === 0) {
                    if (educationEntry[1] !== null && educationEntry[1] !== undefined && educationEntry[1].total_aviled_by_user === educationEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")

                    }
                    else {
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")
                }

            }
            else if (schemeName === "Pre Coaching (UPSC and KPSC) Application" || schemeNameKannada === "ಪೂರ್ವ ತರಬೇತಿ (ಯು ಪಿ ಎಸ್ ಸಿ ಮತ್ತು ಕೆ ಪಿ ಎಸ್ ಸಿ ) ಅಪ್ಲಿಕೇಶನ್") {
                var preCoachEntry = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Pre Coaching (UPSC and KPSC) Application")
                var preCoachPending = allValues.check_user_availed_schemes.self.filter((value) => value.scheme_name === "Pre Coaching (UPSC and KPSC) Application")

                console.log(preCoachEntry, "preCoachPending")

                if (preCoachPending !== undefined && preCoachPending[0].is_pending_for_approval === 0 || preCoachPending[1] !== undefined && preCoachPending[1].is_pending_for_approval === 0) {
                    if (preCoachEntry[1] !== null && preCoachEntry[1] !== undefined && preCoachEntry[1].total_aviled_by_user === preCoachEntry[1].total_count_can_be_availed) {
                        showToast("ERROR", "Scheme Avail Limit Reached!")
                    }
                    else {
                        props.history.push(toLink)
                    }
                }
                else {
                    showToast("ERROR", "Your Scheme is already been applied.")

                }

            }



            // if(total_aviled_by_user < total_count_can_be_availed){
            //     props.history.push(toLink)
            // }
            // else{
            //     showToast("ERROR", "Scheme Avail Limit Reached!")
            // }





        }
    }

    return (
        <>
            <div className="root">
                <Row className="top-div schemes">
                    <Row className="topbar-row">
                        {/* <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/dashboard-user">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>                                <h1 className="logo-text">
                                    Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br />
                                    Government of Karnataka
                                </h1>
                            </div>

                        </Col> */}
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/dashboard-user-unorganized">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                    <Translate value="header.titleungoranized" /> <br />
                                    <Translate value="header.subungoranized" />
                                </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>

                    </Row>



                </Row>
            </div>

            <Row className="schemes-title-row">
                <Col xs={12} md={8}>

                    <div className="schemes-homebutton-row"  >
                        <a href="/dashboard-user-unorganized">
                            <img className="schemes-homebutton" alt="..." src={HomeButton} />
                        </a>
                        <h2><Translate value="schemes" /></h2>
                    </div>

                </Col>
                <Col className='notifications-logout-usericon-labour-schemes'>
                    <div className="usericon-header-logout" style={{ marginLeft: "100px" }}>
                        <img alt="..." src={usericon} className="usericon-img" />
                        <p>{users.user !== null && users.user.first_name}</p>
                        <Tooltip title="Logout" placement="top-start" arrow interactive>
                            <img alt="..." src={logout} className="logout-img" onClick={() => { props.history.push("/") }} style={{ marginLeft: '40px' }} />
                        </Tooltip>
                    </div>
                </Col>
            </Row>

            <Row className="search-form schemes">
                {/* <p className="mb-4 fieldtitle">Enter Mobile Number / Aadhar Number / Registration ID</p> */}
                {/* <p className="mb-4 fieldtitle searchscheme-title">Search Schemes</p> */}
                <p className="mb-4 fieldtitle searchscheme-title"><Translate value="searchschemes" /></p>
                <FormControl fullWidth className="phno-field searchbar-schemes">
                    <TextField
                        // className="size21"
                        name="searchValue"
                        value={allValues.searchValue}
                        placeholder={allValues.kannadaClick ? "ಉದಾಹರಣೆ: ಅಪಘಾತ ಮತ್ತು ಶಿಕ್ಷಣ ಪ್ರಯೋಜನ ಯೋಜನೆ" : "Example : Accident & Education Benefit Scheme" }
                        type="text"
                        onChange={handleChangeSearch}
                        // error={allValues.searchValueError}
                        // helperText={allValues.searchValueError ? "Please enter correct data" : ""}
                        variant="outlined"
                        // InputProps={{

                        //     endAdornment:
                        //         <InputAdornment position="end">
                        //             <img alt="..." src={search} className="language-img" />
                        //         </InputAdornment>
                        // }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">

                                    {
                                        allValues.searchValue === "" ?
                                            <img alt="..." src={search} className="language-img" />
                                            :
                                            <img alt="..." src={closeIcon}
                                                onClick={() => {
                                                    setAllValues({
                                                        ...allValues,
                                                        searchValue: ""
                                                    })
                                                }}
                                                className="language-img closebtnsearch" />
                                    }


                                </InputAdornment>
                        }}
                    >
                    </TextField>
                </FormControl>
            </Row>
            <Row>
                <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
                    <Link to='/detailtransport'>
                        <div className='mapping-card schemes'>
                            <img src={accident_education} style={{width:"110px"}} alt='...'/>
                            <p><Translate value='accidentandbenefitTitle'/></p>
                        </div>
                    </Link>
                </Col>
            </Row>            
            
        </>
    )
}

export default UnorganizedSchemes;