import { FormControl, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Required, showToast } from 'utils/tools';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { updateNomineeDetailsAPI } from 'services/NomineeUpdate.service';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
const NomineeUpdateForm = () => {
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const departmentUserID = users?.getDeptUserRole?.data[0]?.department_user_id;

  const currentUrl = window.location.href;

  const searchParams = new URLSearchParams(
    currentUrl.slice(currentUrl.indexOf('?')),
  );

  const { parentChildID, labourName, nomineeName, nameMatchingScore } =
    Object.fromEntries(searchParams.entries());

  const [updatedNomineeName, setUpdatedNomineeName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleSubmit = async (from) => {
    let _updatedName = null;
    let _statusID = 46;

    if (from === 'update') {
      _updatedName = updatedNomineeName;
      _statusID = 47;
      if (!updatedNomineeName) {
        showToast('ERROR', 'Please enter nominee name to update');

        return;
      }
    }

    try {
      setLoading(true);
      setIsDisable(true);

      const payload = {
        boardId: 1,
        departmentUserID: departmentUserID,
        parentChildUserID: +parentChildID,
        updatedName: _updatedName,
        statusID: _statusID,
      };
      const toastStatus = _statusID === 46 ? 'ERROR' : 'SUCCESS';
      const response = await updateNomineeDetailsAPI(payload);

      if (response.success) {
        setLoading(false);
        history.push('/admin/dashboard/18');
        showToast(toastStatus, `${response?.message}`);
      }
    } catch (error) {
      setLoading(false);
      setIsDisable(false);
      if (error?.response?.data?.errors?.msg) {
        showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
      } else {
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <Row className="top-div">
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to={'/admin/dashboard/18'}>
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                Government of Karnataka <br />
                Karnataka Building & Other Construction Workers Welfare Board
                (KBOCWWB)
              </h1>
            </div>
          </Col>
        </Row>
      </Row>
      <div className="position-relative ms-5">
        <div className="position-absolute" style={{ top: '10px', left: 25 }}>
          <Link to={'/admin/dashboard/18'}>
            <img alt="..." className="cal-icon hover-icn" src={back} />
          </Link>
        </div>
      </div>
      <Row className="form-row mt-4 config-form">
        <div style={{ position: 'relative' }}>
          <Typography
            variant="h6"
            style={{ position: 'absolute', top: '0px', right: '16px' }}
          >
            Name matching score:{nameMatchingScore}
          </Typography>
        </div>
        <Col xs={12} md={4} className="personal-col-1 mt-5">
          <p className="mb-2 mt-3">Benficiary Name</p>
          <FormControl
            fullWidth
            className="formcontrol1"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Nominee Name"
              value={labourName}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className="personal-col-1 mt-5">
          <p className="mb-2 mt-3">Nominee Name</p>
          <FormControl
            fullWidth
            className="formcontrol1"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Nominee Name"
              value={nomineeName}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className="personal-col-1 mt-5">
          <p className="mb-2 mt-3 w-100 text-nowrap">
            Update Nominee name (as per Aadhaar)
          </p>
          <FormControl
            fullWidth
            className="formcontrol1"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                // handleEnter();
              }
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter Nominee name"
              value={updatedNomineeName}
              onChange={(ev) => setUpdatedNomineeName(ev.target.value)}
            />
          </FormControl>
        </Col>

        <Col xs={12} className="mt-4" align="center">
          <div className="d-flex justify-content-center gap-3">
            <Button
              size="lg"
              onClick={() => handleSubmit('update')}
              className="btn btn-success py-2"
              disabled={isDisable}
            >
              Update
            </Button>
            <Button
              onClick={() => handleSubmit('reject')}
              size="lg"
              className="btn btn-danger py-2"
              disabled={isDisable}
            >
              Reject
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NomineeUpdateForm;
