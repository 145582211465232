import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import TableDriveComponent from './TableDriveComponent';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';

const CountRegistrationDrive = () => {
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [driveID, setDriveID] = useState(null);
  const [driveDetails, setDriveDetails] = useState(null);

  const [fetchDistrcitReports, setFetchDistrcitReports] = useState(null);
  const [loading, setLoading] = useState(false);

  const users = useSelector((state) => state.users);

  useEffect(() => {
    axios
      .get(`${SERVER}/admin/registration-drive-users?boardId=1`, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setDriveDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  const handleDriveSearch = () => {
    setLoading(true);
    const payLoad = {
      boardId: 1,
      departmentUserId: users.getDeptUserRole.data[0].department_user_id,
      roleId: users.getDeptUserRole.data[0].role_id,
      inspectionId:
        users.getDeptUserRole.data[0].role_name === 'Registration Drive'
          ? users.getDeptUserRole.data[0].department_user_id
          : driveID,
      isDateSelected: isDateSelected,
      startDate: startDate,
      endDate: endDate,
      reportType: 'DISTRICT',
    };
    axios
      .post(`${SERVER}/reports/count/inspection`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        const updatedDistrictReports = data.map((data) => ({
          ...data,
          Pending: data.Pending ?? 0,
          Approved: data.Approved ?? 0,
          Rejected: data.Rejected ?? 0,
          total: data.total ?? 0,
        }));
        setFetchDistrcitReports(updatedDistrictReports);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column gap-1">
        <Row className="w-100">
          {users.getDeptUserRole.data[0].role_name !== 'Registration Drive' && (
            <Col xs={12} md={3}>
              <Select
                className="form-control w-100 h-75 py-2"
                style={{ border: '1px solid grey' }}
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={driveID || '0'}
                name="circleActiveLi"
                displayEmpty
                onChange={(ev) => setDriveID(ev.target.value)}
                defaultValue={'0'}
              >
                <MenuItem value={'0'}>
                  <ListItemText primary="Select Registration Drive User" />
                </MenuItem>
                {driveDetails &&
                  driveDetails.length &&
                  driveDetails.map((row) => (
                    <MenuItem value={row.id}>
                      <ListItemText primary={row.inspection_name} />
                    </MenuItem>
                  ))}
              </Select>
            </Col>
          )}
          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                maxDate={new Date()}
                placeholder="Start Date"
                //   disabled={!showFilters ? true : false}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setStartDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {startDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                placeholder="End Date"
                //   disabled={!showFilters ? true : false}
                maxDate={new Date()}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setEndDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {endDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} md={2}>
            <Button
              onClick={handleDriveSearch}
              className="w-100 btn btn-success py-2"
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>
      <div className="mt-2">
        {loading && <SimpleBackdrop />}
        {!loading && fetchDistrcitReports && fetchDistrcitReports.length ? (
          <TableDriveComponent fetchDistrcitReports={fetchDistrcitReports} />
        ) : fetchDistrcitReports && fetchDistrcitReports.length === 0 ? (
          'No Data Found'
        ) : null}
      </div>
    </>
  );
};

export default CountRegistrationDrive;
