import React from 'react';
import { Button } from 'react-bootstrap';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
const Aadhaar = ({ handleVerifyAadhaar, loading, aadharVerified }) => {
  return (
    <div className="d-flex justify-content-center align-items-center py-5">
      <Button
        className={`${
          aadharVerified ? 'btn bg-success btn-lg' : 'btn bg-primary btn-lg'
        }`}
        onClick={handleVerifyAadhaar}
        disabled={aadharVerified ? true : false}
      >
        {aadharVerified ? <VerifiedIcon /> : <DoubleArrowIcon />}
        <span className="px-2 fs-5 font-weight-bolder">
          {aadharVerified
            ? 'Aadhaar Verifed'
            : 'Click here to verify the Aadhaar'}
        </span>
      </Button>
    </div>
  );
};

export default Aadhaar;
