import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const EKYCWEBHOOK = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/global/get_ekyc_token`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserOnVaultReferenceNumber = async (payLoad) => {
  try {
    const { data } = await axios.post(`${SERVER}/user/get-user`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAadhaarDetails = async (vaultRefNumber) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/user/get_ad_details/${vaultRefNumber}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getAadhaarDetailsBasedonID = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/user/get_ad_details`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateNomineeEKYCVaultRefNumber = async (payLoad) => {
  try {
    const { data } = await axios.patch(
      `${SERVER}/user/v2/nominee/aadhar`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
