import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const labourDetailsAPI = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/user/get-user-registration-details`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnionBoardDetails = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/config/union/get_union_board_details`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
