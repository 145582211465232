import 'date-fns';
import {
  Select,
  MenuItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  makeStyles,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  deleteAllCookies,
  getTokenCookie,
  getTokenCookieAdmin,
  showToast,
} from 'utils/tools';
import downloadicn from 'assets/images/Icon material-file-download.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
// import aadhar from 'assets/images/id-card.svg'
// import checkGreen from 'assets/images/Tick-01.svg'
// import pan from 'assets/images/credit-card.svg'
// import { Edit, Person } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  finalSubmit,
  getFile,
  saveAcknowledgementId,
  SERVER,
  SERVER_NAME,
  setLocaleWithFallback,
} from 'store/actions/user.actions';
// import address from 'assets/images/apartment.svg'
import moment from 'moment';
import Moment from 'react-moment';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Link } from 'react-router-dom';

import NoImage from 'assets/images/No signature available-01-01.svg';

import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Translate } from 'react-redux-i18n';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ECard = (props) => {
  const {
    personalDetails,
    addressDetails,
    familyDetails,

    certificateDetails,
    employerDetails,
  } = props;
  console.log(props, 'PROPSDATA');

  const [base64Content, setBase64Content] = useState('');
  const [base64ContentSignature, setBase64ContentSignature] = useState('');

  const familyMapped = familyDetails && familyDetails.map((data) => data);
  const RelationFound =
    familyDetails &&
    familyMapped.find(
      (data) =>
        data.parent_child_relation === 'Wife' ||
        data.parent_child_relation === 'Father' ||
        data.parent_child_relation === 'Mother' ||
        data.parent_child_relation === 'Husband',
    );
  const nameOfTheRelationFounded =
    RelationFound &&
    [
      RelationFound?.first_name && RelationFound?.first_name,
      RelationFound?.middle_name && RelationFound?.middle_name,
      RelationFound?.last_name && RelationFound?.last_name,
    ].join(' ');

  const NomineeFind =
    familyMapped && familyMapped.find((data) => data.is_nominee === true);
  const nameOfTheNomineeFounded = [
    NomineeFind?.first_name && NomineeFind?.first_name,
    NomineeFind?.middle_name && NomineeFind?.middle_name,
    NomineeFind?.last_name && NomineeFind?.last_name,
  ].join(' ');
  const relationOfTheNominee =
    NomineeFind?.parent_child_relation && NomineeFind?.parent_child_relation;

  const address = [
    addressDetails.door_no && addressDetails.door_no,
    addressDetails.street_name && addressDetails.street_name,
    addressDetails.land_mark && addressDetails.land_mark,
    addressDetails.panhayat_city_town && addressDetails.panhayat_city_town,
    addressDetails.district && addressDetails.district,
    addressDetails.ward_area_village && addressDetails.ward_area_village,
    addressDetails.taluk && addressDetails.taluk,
    addressDetails.state && addressDetails.state,
    addressDetails.pin_code && addressDetails.pin_code,
  ].join(' ');

  const age = moment().diff(
    moment(
      personalDetails.date_of_birth && personalDetails.date_of_birth,
    ).format('YYYY-MM-DD'),
    'years',
    false,
  );
  console.log(age, 'AGE');

  useEffect(() => {
    if (personalDetails.user_photo_id) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${personalDetails.user_photo_id}`,
        )
        .then((res) => {
          console.log(res.data.data, 'PhotoId');
          setBase64Content(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (certificateDetails.signature_upload_id) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${certificateDetails.signature_upload_id}`,
        )
        .then((res) => {
          console.log(res.data.data, 'PhotoIdSignature');
          setBase64ContentSignature(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [certificateDetails]);

  return (
    <>
      <div className="d-flex justify-content-center flex-row">
        {/* <div className="mb-5">
                        <button onClick={printDocument}>Print</button>
                    </div> */}
        <div
          id="divToPrint"
          className="my-4 "
          style={{
            backgroundColor: '#fff',
            width: '100%',
            minHeight: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            // border:'2px solid #00000029'
            // boxShadow: '0px 3px 8px #00000029',
          }}
        >
          {/* <img src={downloadicn} id="dwnldicn" alt="..." className="download-icon" onClick={printDocument}/> */}
          <div className="logo-div-ack">
            <img
              id="logo"
              src={logo}
              alt="..."
              className="logo-img"
              style={{ marginLeft: '50px' }}
            />
            {/* <p className="deptoflabourtext">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </p> */}
            <h1 className="logo-text idcard" style={{ fontSize: '18px' }}>
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
              (ಕಕಇನಿಕಾಕಮಂ) <br />
              Karnataka Building & Other Construction Workers Welfare Board
              (KBOCWWB) <br />
            </h1>
          </div>

          <Row className="ack-table-row idcard profile_qr mt-5 d-flex justify-content-center align-items-center">
            <Col xs={6} className="ack-col ">
              <div className="picupload idcard d-flex justify-content-center align-items-center">
                <img
                  src={`data:image/jpeg;base64,${base64Content}`}
                  alt=""
                  width={'200'}
                  height={'200'}
                />
                {/* {
                                   
                                    profilePic === undefined || profilePic === "" || profilePic === null ?
                                        (
                                            <CameraAlt className="cameraAlt2" />
                                        )
                                        :
                                        ( 
                                            <img
                                                alt="..."
                                                
                                                width={"200"}
                                                height={"200"}
                                                
                                                src={`${profilePic}`}
                                               
                                            />
                                        )
                                } */}
              </div>
            </Col>
          </Row>

          <Row className="ack-table-row idcard mt-5">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>ಹೆಸರು / Name:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {[
                  personalDetails.first_name && personalDetails.first_name,
                  personalDetails.middle_name && personalDetails.middle_name,
                  personalDetails.last_name && personalDetails.last_name,
                ].join(' ')}
                {/* {get_e_card.personal_details !== undefined && get_e_card.personal_details[0].first_name.toUpperCase()} {get_e_card.personal_details !== undefined &&  get_e_card.personal_details[0].middle_name.toUpperCase()} {get_e_card.personal_details !== undefined &&  get_e_card.personal_details[0].last_name.toUpperCase()} */}
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }} className="100">
                ತಂದೆ / ತಾಯಿ / ಪತಿ / ಪತ್ನಿ ಹೆಸರು :<br />
                Father / Mother / Husband / Wife Name
              </p>
            </Col>

            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {fathername} */}
                {nameOfTheRelationFounded}
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }} className="w-75">
                ಜನ್ಮ ದಿನಾಂಕ :<br /> Date of Birth
              </p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                <Moment format="DD MMMM YYYY">
                  {personalDetails.date_of_birth &&
                    personalDetails.date_of_birth}
                </Moment>
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>ಲಿಂಗ / Gender :</p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {get_e_card.personal_details && get_e_card.personal_details[0].gender} */}
                {personalDetails.gender && personalDetails.gender}
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>ವಿಳಾಸ / Address :</p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {presentAddress1} */}
                {address}
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                ಕೆಲಸದ ಸ್ವರೂಪ :<br />
                Nature of Work
              </p>
            </Col>
            <Col xs={6} className="ack-col">
              {/* <p>Work</p> */}
              {employerDetails &&
              employerDetails[0].nature_of_work &&
              employerDetails[0].nature_of_work
                ? employerDetails[0].nature_of_work
                : '-'}

              <p style={{ fontSize: '18px' }}>
                {/* {occupation1 === null ? "" : occupation1} */}
              </p>
            </Col>
          </Row>
          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                ಮೊಬೈಲ್ ಸಂಖ್ಯೆ :<br /> Mobile Number
              </p>
            </Col>

            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {get_e_card.personal_details !== undefined && get_e_card.personal_details[0].mobile_no} */}
                {personalDetails.mobile_no && personalDetails.mobile_no}
              </p>
            </Col>
          </Row>
          <Row className="ack-table-row"></Row>

          <div className="d-flex justify-content-center align-items-center">
            <Row className="ack-table-row idcard">
              <Col xs={12} className="ack-col nomineeTitle">
                <p style={{ fontSize: '18px' }}>
                  ನಾಮನಿರ್ದೇಶಿತರ ವಿವರಗಳು / Nominee Details
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                ನಾಮನಿರ್ದೇಶಿತರ ಹೆಸರು :<br />
                Nominee Name
              </p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {NomineeName1} */}
                {nameOfTheNomineeFounded}
              </p>
            </Col>
          </Row>
          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>ಸಂಬಂಧ / Relationship :</p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {NomineeRelation1} */}
                {relationOfTheNominee}
              </p>
            </Col>
          </Row>
          <Row className="ack-table-row idcard">
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>ವಯಸ್ಸು / Age :</p>
            </Col>
            <Col xs={6} className="ack-col">
              <p style={{ fontSize: '18px' }}>
                {/* {NomineeAge1} */}
                {age}
              </p>
            </Col>
          </Row>
          {familyDetails && familyDetails.length > 1 ? (
            <>
              <Row className="ack-table-row"></Row>
              <Row className="ack-table-row idcard">
                <Col
                  xs={12}
                  className="ack-col nomineeTitle d-flex justify-content-center"
                >
                  <p style={{ fontSize: '18px' }}>
                    ಕುಟುಂಬದ ವಿವರಗಳು / Family Details
                  </p>
                </Col>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ fontSize: '18px' }}
              >
                <table className="e-card-table-border" style={{ width: '90%' }}>
                  <tr>
                    <th className="e-card-table-border">SL.No</th>
                    <th className="e-card-table-border">Name</th>
                    <th className="e-card-table-border">Age</th>
                    <th className="e-card-table-border">Relationship</th>
                    <th className="e-card-table-border">Gender</th>
                  </tr>

                  {familyDetails &&
                    familyDetails
                      .filter((data) => data.is_nominee === false)
                      .map((row, index) => (
                        <tr>
                          <td className="e-card-table-border">{index + 1}</td>
                          <td className="e-card-table-border">
                            {[
                              row.first_name,
                              row.middle_name,
                              row.last_name,
                            ].join(' ')}
                          </td>
                          <td className="e-card-table-border">
                            {moment().diff(
                              moment(row.date_of_birth).format('YYYY-MM-DD'),
                              'years',
                              false,
                            )}
                          </td>
                          <td className="e-card-table-border">
                            {row.parent_child_relation}
                          </td>
                          <td className="e-card-table-border">
                            {row.parent_child_relation === 'Father' ||
                            row.parent_child_relation === 'Husband' ||
                            row.parent_child_relation === 'Son'
                              ? 'Male'
                              : row.parent_child_relation === 'Mother' ||
                                  row.parent_child_relation === 'Wife' ||
                                  row.parent_child_relation === 'Daughter'
                                ? 'Female'
                                : ''}
                          </td>
                        </tr>
                      ))}
                </table>
              </Row>
            </>
          ) : null}

          <Row className="ack-table-row"></Row>

          <div className="d-flex justify-content-around align-items-center">
            <div className="mx-5">
              <p className="w-100 pt-4">
                ಕಾರ್ಮಿಕರ ಸಹಿ /<br /> Labour Signature
              </p>
              <div
                className="picupload idcard"
                style={{
                  width: '200px',
                  height: '84px',
                  border: '2px solid grey',
                }}
              ></div>
            </div>

            <div className="mx-5">
              <p className="w-100 pt-4">
                ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಸಹಿ /<br />
                Labour Inspector Signature
              </p>
              <div
                className="picupload idcard"
                style={{ width: '200px', height: '84px' }}
              >
                {base64ContentSignature ? (
                  <img
                    src={`data:image/jpeg;base64,${base64ContentSignature}`}
                    alt=""
                    width={'180'}
                    height={'80'}
                  />
                ) : (
                  <img src={NoImage} alt="..." width={'180'} height={'80'} />
                )}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around align-items-center">
            <div className="w-100 mx-5"></div>
            <div className="w-100 mx-5">
              <p>
                ವೃತ್ತ:
                {certificateDetails.inspector_circle &&
                  certificateDetails.inspector_circle}
                <br />
                ತಾಲ್ಲೂಕು: {addressDetails.taluk && addressDetails.taluk}
                <br />
                ಜಿಲ್ಲೆ: {addressDetails.district && addressDetails.district}
                <br />
                {/* ಅನುಮೋದನೆಗೊಂಡ ದಿನಾಂಕ: <br/><Moment format='DD MMMM YYYY hh:mm:ss A'>{get_e_card && get_e_card.certificate_details !== undefined && get_renewal && get_renewal > 0 ? (renewalApprovedDate !== undefined && renewalApprovedDate) : get_e_card && get_e_card.personal_details && get_e_card.personal_details[0] && get_e_card.personal_details[0].approved_date}</Moment> */}
              </p>
            </div>
          </div>

          <Row className="d-flex justify-content-center align-items-center">
            {/* <Col xs={6} className="ack-col signature-col">
                                <p className='w-100 pt-4'>ಕಾರ್ಮಿಕರ ಸಹಿ /<br/> Labour Signature</p>
                                <div className="picupload idcard" style={{width:'200px',height:'84px',border:'2px solid grey'}}>
                               </div>
                               
                            </Col>
                             <Col xs={6} className="ack-col signature-col">
                                <p className='w-100 pt-4'>ಕಾರ್ಮಿಕರ ಸಹಿ /<br/> Labour Signature</p>
                                <div className="picupload idcard" style={{width:'200px',height:'84px',border:'2px solid grey'}}>
                               </div>
                               
                            </Col> */}

            {/* <Col xs={6} className="ack-col signature-col">
                                <p className='w-100'>ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಸಹಿ /<br/>Labour Inspector Signature</p>
                                <div className="picupload idcard">
                                {
                                    // sessionStorage.getItem('profilePic') === null ?
                                    
                                    (users.getFile !== null && get_e_card.certificate_details !== undefined && get_e_card.certificate_details[0].signature_upload_id !== null || sessionStorage.getItem('signPic') !== undefined && get_e_card.certificate_details !== undefined && get_e_card.certificate_details[0].signature_upload_id !== null || sessionStorage.getItem('signPic') !== "" || sessionStorage.getItem('signPic') !== null) && get_e_card.certificate_details !== undefined && get_e_card.certificate_details[0].signature_upload_id !== null ?
                                        
                                            (
                                                <div className='d-flex justify-content-center flex-column'>
                                                <img
                                                alt="..."
                                                width="200"
                                                height={"84"}
                                                className=''
                                                style={{border:'2px solid gray'}}
                                                // className="avatar idcard border-gray"
                                                src={sessionStorage.getItem('signPic')}
                                                // src={users.getFile}
                                                />
                                                <div className='text-center px-3' style={{textAlign:'left'}}>
                                                <p>ವೃತ್ತ:{get_e_card && get_e_card.certificate_details[0].inspector_circle}<br/>

                                                ತಾಲ್ಲೂಕು: {get_e_card && get_e_card.address_details[0].taluk && get_e_card.address_details[0].taluk} 
                                                     
                                                <br/>ಜಿಲ್ಲೆ: {get_e_card && get_e_card.address_details[0].district}
                                                <br/>ಅನುಮೋದನೆಗೊಂಡ ದಿನಾಂಕ: <br/><Moment format='DD MMMM YYYY hh:mm:ss A'>{get_e_card && get_e_card.personal_details[0].approved_date}</Moment>
                                                </p>
                                                </div>
                                                </div>
                                                
                                            )
                                            
                                        :
                                        
                                           ( 
                                            <img
                                                alt="..."
                                                className="ecard-no-image"
                                                src={NoImage}
                                                // src={users.getFile}
                                                />
                                           )
                                }
                                
                            </div>
                          
                            </Col> */}
          </Row>
          <Typography color="error">
            Note: Registration ID,Registration Date and Card Validity will be
            Displayed in the Labour Dashboard after Registration Approval.
          </Typography>

          {/* <p className="karmikaaddress">
                            Kalyana Suraksha Bhavan, Banerghatta Road,<br/> 
                            ITI Compound, Dairy Circle, Bengaluru, <br/>Karnataka 560029
                            </p> */}
        </div>
      </div>
    </>
  );
};

export default ECard;
