import React, { useState } from 'react';
import Header from './Header';
import { Col, Row } from 'react-bootstrap';
import ambedkar from 'assets/images/ambedkar.svg';
import ashadeepa from 'assets/images/ashadeepa.svg';
import newpension from 'assets/images/newpension.png';
import accident_education from 'assets/images/accident_education.png';
import pmyogiman from 'assets/images/pmyogiman.png';

import { SocialSecuritySchemesList } from './schemesList';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg';
import { setLocaleWithFallback } from 'store/actions/user.actions';
import Header2 from './Header2';

const SocialSecurityBoardHomePage = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);

  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });

  const handleChange1 = (e) => {
    console.log('kkkkk', e.target.value);
    if (e.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === 'language')
      dispatch(setLocaleWithFallback(e.target.value));
  };

  return (
    <div>
      <Header2 lang={allValues.language} />
      <div className="d-flex justify-content-end">
        <Col
          xs={12}
          md={4}
          lg={3}
          className="d-flex justify-content-end mt-2 me-2"
        >
          <Select
            className="select-language"
            style={{ width: '100%' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.language}
            name="language"
            displayEmpty
            onChange={handleChange1}
          >
            <MenuItem value="">
              <ListItemIcon>
                <img alt="..." src={language} className="language-img" />
              </ListItemIcon>
              <ListItemText primary="Select Language" />
            </MenuItem>
            <MenuItem value="en">
              <ListItemText primary="English" />
            </MenuItem>
            <MenuItem value="ka">
              <ListItemText primary="ಕನ್ನಡ" />
            </MenuItem>
          </Select>
        </Col>
      </div>
      <Row className="container-fluid">
        <div className="schemes-home-div container-fluid m-auto ps-4">
          <h3 className="schemesHome-title container-fluid">
            <Translate value="ksuwssbTitle1" />{' '}
            <Translate value="ksuwssbTitle2" />{' '}
            <Translate value="ksuwssbTitle3" />
          </h3>
          <p className="schemes-instructions container-fluid">
            <Translate value="ksuwssbcontent1" />
          </p>
          <p className="schemes-instructions container-fluid">
            <Translate value="ksuwssbcontent2" />
          </p>
          <p className="schemes-instructions container-fluid">
            <Translate value="ksuwssbcontent3" />
          </p>

          <h3 className="schemesHome-title container-fluid">
            <Translate value="eshram" />
          </h3>
          <p className="schemes-instructions container-fluid">
            <Translate value="eshramcontent" />
          </p>
          <h3 className="schemesHome-title container-fluid">
            <Translate value="uncategories" />
          </h3>
          <p className="schemes-instructions container-fluid">
            <Translate value="uncategoriescontent" />
          </p>
          <Link to="/unorganized-registration">
            <div className="socialSecurityBoardHomePage-title container-fluid d-flex align-items-center justify-content-center">
              <button disabled className="login-button2">
                Register as Unorganized Worker / Login
              </button>
            </div>
          </Link>

          <h3 className="schemesHome-title container-fluid">
            <Translate value="schemeTranslate" />
          </h3>
        </div>
      </Row>
      <div className="schemes-list-div">
        <div className="container-fluid">
          <Row className="">
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/ambedkar">
                <div className="mapping-card schemes">
                  <img src={ambedkar} alt="..." />
                  <p>
                    <Translate value="ambedkarTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/ashadeepa">
                <div className="mapping-card schemes">
                  <img src={ashadeepa} alt="..." />
                  <p>
                    <Translate value="ashadeepaTitle" />
                  </p>
                </div>
              </Link>
            </Col>

            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/transport">
                <div className="mapping-card schemes">
                  <img
                    src={accident_education}
                    style={{ width: '110px' }}
                    alt="..."
                  />
                  <p>
                    <Translate value="accidentandbenefitTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/yogimandhan">
                <div className="mapping-card schemes">
                  <img src={pmyogiman} alt="..." />
                  <p>
                    <Translate value="pmtitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/newpension">
                <div className="mapping-card schemes">
                  <img src={newpension} alt="..." />
                  <p>
                    <Translate value="pensionuntitle" />
                  </p>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5 me-5 mb-5">
        <Link
          onClick={() => {
            props.history.goBack();
          }}
        >
          <Button className="back-btn-mainScreens">
            <ArrowBackIosIcon color="secondary" className="back-icon" />
            <Translate value="backbtn" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SocialSecurityBoardHomePage;
