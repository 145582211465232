import React from 'react';
import Paper from '@mui/material/Paper';
import { Translate } from 'react-redux-i18n';
import { Col, Container, Row } from 'react-bootstrap';
import { Required } from 'utils/tools';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import closeIcon from 'assets/images/close.png';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { AddCircleRounded, CheckCircle } from '@material-ui/icons';
import { Typography } from '@mui/material';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
const FamilyDetails = ({
  familyDetails,
  removeFamilyMembers,
  handleFormChange,
  fetchRelation,
  handleKeyDown,
  handleCheckBox,
  handleDateChange,
  fetchEducation,
  fetchProfession,
  addNewFamilyMember,
  dob,
}) => {
  return (
    <>
      <Paper
        elevation={3}
        style={{ margin: '20px', borderRadius: '10px', padding: '20px 0' }}
      >
        <Container>
          <Row className="g-3 px-3 form-row py-5">
            <Typography
              variant="h5"
              color={'GrayText'}
              className="text-left d-flex justify-content-start align-items-center"
            >
              <FamilyRestroomIcon
                style={{ width: '30px', height: '30px' }}
                sx={{
                  color: 'white',

                  backgroundColor: 'GrayText',
                  borderRadius: '100%',
                }}
              />{' '}
              <span className="px-2" style={{ fontWeight: 'bolder' }}>
                Family Details
              </span>
            </Typography>

            <Col xs={12} className="family-col-3">
              {familyDetails &&
                familyDetails.length &&
                familyDetails.map((data, index) => (
                  <Row className={'familymember-card row'}>
                    {data.relation !== 49 &&
                      data.relation !== 50 &&
                      data.relation !== 51 &&
                      data.relation !== 52 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: '0',
                            width: '30px',
                            height: '30px',
                          }}
                        >
                          <img
                            alt="..."
                            src={closeIcon}
                            className="closebtn"
                            onClick={() => removeFamilyMembers(index)}
                          />
                        </div>
                      )}
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className="member-p">
                          {/* <p >Relation :</p> */}
                          <Required title={<Translate value="relation" />} />
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className="select-marital"
                              // value={allValues.membersList[i]["relation_id" + i]}
                              name="relation"
                              displayEmpty
                              value={data.relation || '0'}
                              disabled={
                                data.relation === 49 || data.relation === 50
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary="Select Relation" />
                              </MenuItem>
                              {data.relation === 49 || data.relation === 50
                                ? fetchRelation &&
                                  fetchRelation.length &&
                                  fetchRelation.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))
                                : fetchRelation &&
                                  fetchRelation.length &&
                                  fetchRelation
                                    .filter(
                                      (row) =>
                                        row.value_id !== 49 &&
                                        row.value_id !== 50,
                                    )
                                    .map((i) => (
                                      <MenuItem value={i.value_id}>
                                        <ListItemText primary={i.value} />
                                      </MenuItem>
                                    ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className="member-p">
                          {/* <p >First Name :</p> */}
                          <Required title={<Translate value="labourName" />} />
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <TextField
                              placeholder="Enter Your Name"
                              value={data.firstName}
                              name="firstName"
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              // error={allValues.firstnameError}
                              // helperText={allValues.firstnameError ? "Please enter in correct format" : ""}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className="member-p">
                          {/* <p >DOB :</p> */}
                          <Required title={<Translate value="dOB" />} />
                        </Col>
                        <Col xs={9} style={{ borderBottom: '1px solid grey' }}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              minDate={moment().subtract(100, 'years')}
                              maxDate={
                                data.relation === 49 || data.relation === 50
                                  ? moment(dob && dob).subtract(18, 'years')
                                  : moment()
                              }
                              className="datepicker"
                              margin="normal"
                              format="DD/MM/YYYY"
                              onKeyDown={handleKeyDown}
                              onChange={(date) =>
                                handleDateChange(date, index, 'dob')
                              }
                              value={data.dob && moment(data.dob)}
                              name="dob"
                              placeholder="DD/MM/YYYY"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className="member-p">
                          <p>
                            <Translate value="profession" />* :
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className="select-marital"
                              displayEmpty
                              value={data.profession || '0'}
                              name="profession"
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary="Select Profession" />
                              </MenuItem>
                              {fetchProfession &&
                                fetchProfession.length &&
                                fetchProfession.map((i) => (
                                  <MenuItem value={i.value_id}>
                                    <ListItemText primary={i.value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className="member-p">
                          <p>
                            <Translate value="education" />*
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className="select-marital"
                              displayEmpty
                              value={data.education || '0'}
                              name="education"
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value="0">
                                <ListItemText primary="Select Education" />
                              </MenuItem>
                              {fetchEducation &&
                                fetchEducation.length &&
                                fetchEducation.map((i) => (
                                  <MenuItem value={i.value_id}>
                                    <ListItemText primary={i.value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className="member-p">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(ev) =>
                              handleCheckBox(index, ev, 'nominee')
                            }
                            color="primary"
                            checked={data.nominee}
                            name="nominee"
                          />
                        }
                        label={<Translate value="nominee" />}
                      />
                    </Col>
                  </Row>
                ))}
            </Col>

            <Typography
              variant="button"
              color={'GrayText'}
              className="text-left d-flex justify-content-start align-items-center my-3"
            >
              <span
                className=""
                style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                onClick={() => addNewFamilyMember('')}
              >
                <AddCircleIcon
                  style={{ width: '30px', height: '30px' }}
                  sx={{
                    color: 'green',

                    borderRadius: '100%',
                  }}
                />{' '}
                Add more family members
              </span>
            </Typography>
          </Row>
        </Container>
      </Paper>
    </>
  );
};

export default FamilyDetails;
