import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, MenuItem, RadioGroup, FormControlLabel, Radio, ListItemText, ListItemIcon, CircularProgress, TextareaAutosize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AddCircleRounded, ColorLensOutlined, IndeterminateCheckBox } from '@material-ui/icons';
// import folder from 'assets/images/folder (3).svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// import aadhar from 'assets/images/id-card.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import usericon from 'assets/images/Icon awesome-user.svg'
import language from 'assets/images/translate (1).svg'
import closeicon from 'assets/images/closeIcon.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
// import close from 'assets/images/close.png'
import close from 'assets/images/close-01.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addLabourBankDetails, addLabourFamilyDetails, getCatalogDetails, getFile, getUser, getUserRegistrationDetails, SERVER, updateFamilyData, updateUploadFile, uploadFile } from 'store/actions/user.actions';
import { dataURLtoFile, getTokenCookie, Required, showToast } from 'utils/tools';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';
import validator from 'validator';


const Editprofile = (props) => {

    const myRef = React.createRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        rationCardNo: "",
        rationCardType: "",
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,

        rationDocumentName: "",

        formcontrolnum: 1,

        membersList: [{
            "id": 0,
            "relation0": "",
            "firstname0": "",
            "middlename0": "",
            "lastname0": "",
            "dob0": null,
            "profession0": "",
            "education0": "",
            "nominee0": "",
            "alreadyRegistered0": "",
            "registrationNo0": "",
            "aadharnum1_0": "",
            "aadharnum2_0": "",
            "aadharnum3_0": "",
            "aadhar0": "",
            "formcontrolnum0": 1,
        }],
        noOfFamilyMembers: 1,
        documentUploadResponserationCard: "",
        documentUploadResponsepassbook: "",
        documentUploadResponsepassbook_nominee: "",

        rationCardTypes: [],
        relations: [],
        professions: [],
        educations: [],

        ifscCode: "",
        accountNumber: "",
        bankName: "",
        bankBranch: "",
        bankAddress: "",
        imgfile: "",
        passbookDocumentName: "",
        passbookUploadResponse: "",
        nomineeCheck: false,

        ifscCode_nominee: "",
        accountNumber_nominee: "",
        bankName_nominee: "",
        bankBranch_nominee: "",
        bankAddress_nominee: "",
        imgfile_nominee: "",
        passbookDocumentName_nominee: "",
        passbookUploadResponse_nominee: "",
        accError_nominee: false,
        accnumError_nominee: false

    })

    useEffect(() => {
        console.log("in useEffect [allValues]: " + JSON.stringify(allValues, undefined, 2))
        dispatch(updateFamilyData(allValues))
    }, [allValues]);



    const [filesxmlPresent, setfilesxmlPresent] = React.useState(true)
    const [loadingfiles, setloadingfiles] = React.useState(true)
    const [selectedRationfiles, setSelectedRationfiles] = React.useState([])
    const [fileUploadRationResults,setFileUploadRationResults] = useState({})
    const [selectedPassbookfiles, setSelectedPassbookfiles] = React.useState([])
    const [selectedNomineePassbookfiles, setSelectedNomineePassbookfiles] = React.useState([])
    const [fileUploadPassbookResults,setFileUploadPassbookResults] = useState({})
    const [fileUploadRationCall,setFileUploadRationCall] = useState(null)
    const [selectedNomineefiles, setSelectedNomineefiles] = React.useState({})
    const [fileUploadNomineeResults,setFileUploadNomineeResults] = useState({})
    const [fileuploadresults, setfileuploadresults] = React.useState({})

    const [AadhaarDecrypted, setAadhaarDecrypted] = React.useState({})
    const [AadhaarDecryptedFinal, setAadhaarDecryptedFinal] = React.useState({})
    const [fileRation,setFileRation] = useState(null)
    const [filePassbook,setFilePassbook] = useState(null)
    //New File Upload
    const [bankUpload,setBankUpload] = React.useState()
    const [bankUploadFileName,setBankUploadFileName] = React.useState("")
    const [fileResponseData,setFileResponseData] = React.useState({})
    const [isFileUpload,setIsFileUpload] = React.useState(false)
    const [bankFiles,setBankFiles] = React.useState()
    const [existingImg,setExistingImg] = React.useState([])
    const [isExistingImg,setIsExistingImg] = React.useState(false)

    const [filesxmlPresentBank, setfilesxmlPresentBank] = React.useState(true)
    const [filesxmlPresentNominee, setfilesxmlPresentNominee] = React.useState(true)
    // const [loadingfiles, setloadingfiles] = React.useState(true)
    const [selectedfilesBank, setselectedfilesBank] = React.useState({})
    const [selectedfilesNominee, setselectedfilesNominee] = React.useState({})

    const [passbookuploadresults, setpassbookuploadresults] = React.useState({})
    const [passbookuploadNomineeresults, setpassbookuploadNomineeresults] = React.useState({})

    const [filelist, setfilelist] = React.useState({})
    const [filelistBank, setfilelistBank] = React.useState({})
    const [filelistNominee, setfilelistNominee] = React.useState({})
    const [removedFile, setRemovedFile ] = React.useState(false)
    const [phone, setPhone] = React.useState('')

    useEffect(() => {
        setAllValues({ ...allValues, phoneNumber: phone })
    }, [phone]);
    
    useEffect(() => {
        console.log("useeffect AadhaarDecrypted: ",AadhaarDecrypted)
        setAadhaarDecryptedFinal({
            ...AadhaarDecryptedFinal,
            [AadhaarDecrypted.index]:AadhaarDecrypted 
        })
    }, [AadhaarDecrypted]);
    useEffect(() => {
        console.log("useeffect AadhaarDecryptedFinal: ",AadhaarDecryptedFinal)
    }, [AadhaarDecryptedFinal]);
    
    useEffect(() => {
        console.log("filelistBank: ",filelistBank)
    }, [filelistBank]);


    const [documentUploaded, setdocumentUploaded] = React.useState(false)

    useEffect(() => {
        // console.log("in useEffect []")

        setdocumentUploaded(false)

        if (users.generate_otp !== null) {
            setPhone(users.generate_otp.data.phone_number)
            dispatch(getUser(users.generate_otp.data.phone_number))
        }
        else if (users.generate_otp_registration !== null) {
            setPhone(users.generate_otp_registration.data.phone_number)
            dispatch(getUser(users.generate_otp_registration.data.phone_number))
        }
        else {
            props.history.push("/")
        }

        dispatch(getFile("delete"))
        dispatch(addLabourBankDetails("delete"))
        dispatch(addLabourFamilyDetails("delete"))
        console.log("bandUpload",JSON.stringify(users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id))

     

    }, []);

    useEffect(() => {
        // console.log("in useEffect [users.user]")

        if (users.user !== null && users.user.board_id !== null) {

            let dataForRegDetails = {
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "all"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))

            let dataForCatalog = { "board_id": users.user.board_id, "catalog_name": "Ration card type" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog))

            let dataForCatalog2 = { "board_id": users.user.board_id, "catalog_name": "Relation" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog2))

            let dataForCatalog3 = { "board_id": users.user.board_id, "catalog_name": "Profession" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog3))

            let dataForCatalog4 = { "board_id": users.user.board_id, "catalog_name": "Education" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog4))

        }
    }, [users.user]);

    useEffect(() => {
        if (users.getUserRegistrationDetails !== null &&
            users.getUserRegistrationDetails.family_details !== undefined &&
            users.getUserRegistrationDetails.family_details.length > 0) {

            if (users.getUserRegistrationDetails.family_details !== undefined &&
                users.getUserRegistrationDetails.family_details[0] !== undefined &&
                users.getUserRegistrationDetails.bank_details !== undefined &&
                users.getUserRegistrationDetails.bank_details[0] !== undefined &&
                users.getUserRegistrationDetails.bank_details.length > 0) {

                console.error("in useeffect users.getUserRegistrationDetails.family_details: " + JSON.stringify(users.getUserRegistrationDetails.family_details))

                var family_details = users.getUserRegistrationDetails.family_details
                var val = family_details

                var newarrAadhaar = []
                var membersListData = []

                for (var i = 0; i < family_details.length; i++) {

                    var str = family_details[i].aadhar_no;
                    var aadharArr = [];
                    var chunkSize = 4;

                    while (str) {
                        if (str.length < chunkSize) {
                            aadharArr.push(str);
                            break;
                        }
                        else {
                            aadharArr.push(str.substr(0, chunkSize));
                            str = str.substr(chunkSize);
                        }
                    }

                    console.error("aadharArr: " + aadharArr)

                    // var AadhaarDecrypted = ''
                    if (
                        val[i].encrypted_aadhar !== undefined &&
                        val[i].encrypted_aadhar !== null &&
                        val[i].encrypted_aadhar !== ""
                    ) {
                        var data = JSON.stringify({
                            "decryptString": val[i].encrypted_aadhar
                        });

                        var config = {
                            method: 'post',
                            url: SERVER + '/global/decrypt_data',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data,
                            params: { indexing: i }
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("args: ",JSON.stringify(response.config.params.indexing));
                                if (!response.data.data.decryptedData.includes('X')) {
                                    setAadhaarDecrypted({
                                        value: response.data.data.decryptedData,
                                        index: response.config.params.indexing
                                        })
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    var Data = {
                        "id": i,
                        ["relation" + i]: family_details[i].catalog_value_parent_child_type_id,
                        ["firstname" + i]: family_details[i].first_name,
                        ["middlename" + i]: family_details[i].middle_name,
                        ["lastname" + i]: family_details[i].last_name,
                        ["dob" + i]: family_details[i].date_of_birth,
                        ["profession" + i]: family_details[i].catalog_value_profession_id,
                        ["education" + i]: family_details[i].catalog_value_qualification_id,
                        ["nominee" + i]: family_details[i].is_nominee === true ? 1 : 0,
                        ["alreadyRegistered" + i]: '' + family_details[i].is_regisrered_user,
                        ["registrationNo" + i]: family_details[i].registration_code,
                        ["aadharnum1_" + i]: aadharArr[0],
                        ["aadharnum2_" + i]: aadharArr[1],
                        ["aadharnum3_" + i]: aadharArr[2],
                        ["aadhar" + i]: family_details[i].aadhar_no,
                        relation_id: family_details[i].relation_id,
                        labour_user_id: family_details[i].labour_user_id,
                        parent_child_user_id: family_details[i].parent_child_user_id

                    }



                    membersListData.push(Data)

                }

                if (
                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== undefined &&
                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== null &&
                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml.length > 0
                ) {
                    setfilesxmlPresent(true)
                    // if(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== "[]"){
                        // setselectedfiles(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml)
                    // }
                }

                var rationCardNo = ""
                var catalog_value_ration_card_type_id = ""
                var ration_card_document_upload_id = ""

                if (users.getUserRegistrationDetails.ration_card_details !== undefined && users.getUserRegistrationDetails.ration_card_details[0] !== undefined) {

                    var val1 = users.getUserRegistrationDetails.ration_card_details[0]
                    rationCardNo = val1.ration_card_no
                    catalog_value_ration_card_type_id = val1.catalog_value_ration_card_type_id
                    ration_card_document_upload_id = val1.ration_card_document_upload_id
                }
                var val = users.getUserRegistrationDetails.bank_details[0]

                if (
                    users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== undefined &&
                    users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== null &&
                    users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml.length > 0
                ) {

                    if (users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml.length > 1) {
                        if (users.getUserRegistrationDetails.bank_details[0].is_nominee) {
                            setfilesxmlPresentNominee(true)
                            // setselectedfilesNominee(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)
                        }
                        else if (
                            users.getUserRegistrationDetails.bank_details[1] !== undefined &&
                            users.getUserRegistrationDetails.bank_details[1].is_nominee) {
                            setfilesxmlPresentNominee(true)
                            // setselectedfilesNominee(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml)
                        }

                        if (!users.getUserRegistrationDetails.bank_details[0].is_nominee) {
                            setfilesxmlPresentBank(true)
                            // setselectedfilesBank(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)
                        }
                        else if (
                            users.getUserRegistrationDetails.bank_details[1] !== undefined &&
                            !users.getUserRegistrationDetails.bank_details[1].is_nominee) {
                            setfilesxmlPresentBank(true)
                            // setselectedfilesBank(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml)
                        }


                    }
                    else {
                        setfilesxmlPresentBank(true)
                        // setselectedfilesBank(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)
                    }
                }

                if (users.getUserRegistrationDetails.bank_details.length > 1) {

                    var val1 = users.getUserRegistrationDetails.bank_details[1]


                    setAllValues({
                        ...allValues,
                        rationCardNo: rationCardNo,
                        rationCardType: catalog_value_ration_card_type_id,
                        rationDocumentName: ration_card_document_upload_id,
                        membersList: membersListData,
                        noOfFamilyMembers: family_details.length,


                        ifscCode: val.ifsc_code,
                        accountNumber: val.account_no,
                        bankName: val.bank_name,
                        bankBranch: val.bank_branch,
                        bankAddress: val.bank_address,
                        passbookDocumentName: val.bank_passbook_upload_id,
                        passbookUploadResponse: {
                            fileId: val.bank_passbook_upload_id
                        },

                        nomineeCheck: true,
                        ifscCode_nominee: val1.ifsc_code,
                        accountNumber_nominee: val1.account_no,
                        bankName_nominee: val1.bank_name,
                        bankBranch_nominee: val1.bank_branch,
                        bankAddress_nominee: val1.bank_address,
                        passbookDocumentName_nominee: val1.bank_passbook_upload_id,
                        passbookUploadResponse_nominee: {
                            fileId: val1.bank_passbook_upload_id
                        },
                    })

                }
                else {
                    setAllValues({
                        ...allValues,
                        rationCardNo: rationCardNo,
                        rationCardType: catalog_value_ration_card_type_id,
                        rationDocumentName: ration_card_document_upload_id,
                        membersList: membersListData,
                        noOfFamilyMembers: family_details.length,

                        ifscCode: val.ifsc_code,
                        accountNumber: val.account_no,
                        bankName: val.bank_name,
                        bankBranch: val.bank_branch,
                        bankAddress: val.bank_address,
                        passbookDocumentName: val.bank_passbook_upload_id,
                        passbookUploadResponse: {
                            fileId: val.bank_passbook_upload_id
                        },
                    })
                }

            }
        }
    }, [users.getUserRegistrationDetails]);


    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (
            users.catalog_details !== undefined && 
            users.catalog_details !== null && 
            users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (
            users.catalog_details !== undefined && 
            users.catalog_details !== null && users.catalog_details[0]['name'] === "Ration card type") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setRationCardTypes(users.catalog_details)
            setAllValues({
                ...allValues,
                rationCardTypes: users.catalog_details
            });
        }
        else if (
            users.catalog_details !== undefined && 
            users.catalog_details !== null && users.catalog_details[0]['name'] === "Relation") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            // setRelations(users.catalog_details)
            setAllValues({
                ...allValues,
                relations: users.catalog_details
            });
        }
        else if (
            users.catalog_details !== undefined && 
            users.catalog_details !== null && users.catalog_details[0]['name'] === "Profession") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            // setProfessions(users.catalog_details)
            setAllValues({
                ...allValues,
                professions: users.catalog_details
            });
        }
        else if (
            users.catalog_details !== undefined && 
            users.catalog_details !== null && users.catalog_details[0]['name'] === "Education") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            // setEducations(users.catalog_details)
            setAllValues({
                ...allValues,
                educations: users.catalog_details
            });
        }
    }, [users.catalog_details]);


    const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };



    const handleChange = (event) => {
        if (event.target.name === "bankName") {
            if ( event.target.value.length < 25){
                setAllValues({
                    ...allValues,
                    accError: false,
                    [event.target.name]: event.target.value
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    accError: true,
                    [event.target.name]: event.target.value
                })
            }
        }

        else if (event.target.name === "bankName_nominee") {
            if ( event.target.value.length < 25){
                setAllValues({
                    ...allValues,
                    accError_nominee: false,
                    [event.target.name]: event.target.value
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    accError_nominee: true,
                    [event.target.name]: event.target.value
                })
            }
        }
        
        else if (event.target.name === "accountNumber_nominee") {
            if ( event.target.value.length < 25){
                setAllValues({
                    ...allValues,
                    accnumError_nominee: false,
                    [event.target.name]: event.target.value
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    accnumError_nominee: true,
                    [event.target.name]: event.target.value
                })
            }
        }

        else{
            setAllValues({
                ...allValues,
                [event.target.name]: event.target.value
            })
        }

    }

    const handleChangeMemberList = (event, index) => {
        console.log("in handleChangeMemberList: index=" + index)
        console.log("in handleChangeMemberList: event=" + JSON.stringify(event.target.value))
        setAllValues({
            ...allValues,
            membersList: allValues.membersList.map(
                obj => (obj.id === index ? Object.assign(obj, {
                    [event.target.name]: event.target.value
                }) : obj)
            )
        })

    }

    const handleChangeCheckboxMemberList = (event, index) => {
        console.log("in handleChangeCheckboxMemberList: index=" + index)
        console.log("in handleChangeCheckboxMemberList: checked=" + JSON.stringify(event.target.checked))

        var check = event.target.checked === true ? 1 : 0

        setAllValues({
            ...allValues,
            membersList: allValues.membersList.map(
                obj => (
                    obj.id === index ?
                        Object.assign(obj, { [event.target.name]: check })
                        :
                        Object.assign(obj, { ["nominee" + obj.id]: 0 })
                )
            )
        })

    }

    const handleDateChangeMemberList = (date, index) => {
        console.log("in handleDateChangeMemberList: index=" + index)
        console.log("in handleDateChangeMemberList: event=" + JSON.stringify(date))
        console.log(new Date(date))
        setAllValues({
            ...allValues,
            membersList: allValues.membersList.map(
                obj => (obj.id === index ? Object.assign(obj, {
                    ["dob" + index]: new Date(date)
                }) : obj)
            )
        })
    }

    const handleAAdharChangeMemberList = (e, index, num) => {
        // console.log('handleAadharChange num:' + num)
        // console.log('handle change called')
        // console.log(e.target.value)
        // console.log(e.target.value.length)


        var formcontnum = 0
        // ["formcontrolnum" + index]: formcontnum,

        if (num === 1) {
            formcontnum = 1
        }
        else if (num === 2) {
            formcontnum = 2
        }
        else if (num === 3) {
            formcontnum = 3
        }

        if (e.target.value.length === 4) {
            // console.log("inside")
            // console.log("formcontnum:" + formcontnum)
            // console.log(`${formcontnum + 1}`)
            const nextSibling = document.querySelector(
                `.row${index} .aadhardiv${formcontnum + 1}>div>input`
            );

            if (formcontnum === 1) {
                setAllValues({
                    ...allValues,
                    ["formcontrolnum" + index]: (formcontnum + 1),
                    membersList: allValues.membersList.map(
                        obj => (obj.id === index ? Object.assign(obj, {
                            ["aadharnum1_" + index]: e.target.value
                        }) : obj)
                    )
                })
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        membersList: allValues.membersList.map(
                            obj => (obj.id === index ? Object.assign(obj, {
                                ["formcontrolnum" + index]: obj["formcontrolnum" + index] + 1,
                            }) : obj)
                        )
                    })
                }
            }
            // else if (allValues.membersList[index]["formcontrolnum" + index] === 2) {
            else if (formcontnum === 2) {
                setAllValues({
                    ...allValues,
                    ["formcontrolnum" + index]: (formcontnum + 1),
                    membersList: allValues.membersList.map(
                        obj => (obj.id === index ? Object.assign(obj, {
                            ["aadharnum2_" + index]: e.target.value
                        }) : obj)
                    )
                })
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        membersList: allValues.membersList.map(
                            obj => (obj.id === index ? Object.assign(obj, {
                                ["formcontrolnum" + index]: obj["formcontrolnum" + index] + 1,
                            }) : obj)
                        )
                    })
                }
            }
            // else if (allValues.membersList[index]["formcontrolnum" + index] === 3) {
            else if (formcontnum === 3) {
                setAllValues({
                    ...allValues,
                    ["formcontrolnum" + index]: (formcontnum + 1),
                    membersList: allValues.membersList.map(
                        obj => (obj.id === index ? Object.assign(obj, {
                            ["aadharnum3_" + index]: e.target.value,
                            ["aadhar" + index]:
                                (allValues.membersList[index]["aadharnum1_" + index] +
                                    allValues.membersList[index]["aadharnum2_" + index] +
                                    e.target.value)
                        }) : obj)
                    )
                })

                checkAadhaarDuplicate(index)


                // console.log(allValues)
                // const nextSibling = document.querySelector(
                //     `.datepicker-family>div>input`
                // );
                // nextSibling.focus();
            }
        }
        // else if (e.target.value.length > 4) {
        //     const nextSibling = document.querySelector(
        //         `.row${index} .datepicker-family>div>input`
        //     );
        //     nextSibling.focus();
        // }
        else if (e.target.value.length < 4) {
            setAllValues({
                ...allValues,
                membersList: allValues.membersList.map(
                    obj => (obj.id === index ? Object.assign(obj, {
                        [e.target.name]: e.target.value
                    }) : obj)
                )
            })
        }
    }
    const checkAadhaarDuplicate = (index) => {
        // setLoading(true)
        // setaadharDuplicateError(false)

        var data = JSON.stringify({
            "key": "aadhar_no",
            "value": allValues.membersList[index]['aadhar' + index],
            "board_id": 1
        });

        var config = {
            method: 'post',
            url: SERVER + '/user/get-user',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // setLoading(false)
                console.log("checkAadhaarDuplicate response:" + JSON.stringify(response.data, undefined, 2));

                // var check = true
                var checkValue = null

                response.data.data.map((value) => {
                    checkValue = value
                })

                if (checkValue.length > 0) {
                    console.log("checkValue :" + JSON.stringify(checkValue, undefined, 2));


                    // setAllValues({
                    //     ...allValues,
                    //     membersList: allValues.membersList.map(
                    //         obj => (obj.id === index ? Object.assign(obj, 
                    //             {
                    //                 [e.target.name]: e.target.value
                    //                 }
                    //             {
                    //                 "id": index,
                    //                 // "relation0": ,
                    //                 "firstname": "Vikram ",
                    //                 "middlename0": "",
                    //                 "lastname0": "Singh",
                    //                 "dob0": null,
                    //                 "profession0": "",
                    //                 "education0": "",
                    //                 "nominee0": "",
                    //                 "alreadyRegistered0": "0",
                    //                 "registrationNo0": "",
                    //                 "aadharnum1_0": "3434",
                    //                 "aadharnum2_0": "3453",
                    //                 "aadharnum3_0": "5556",
                    //                 "aadhar0": "343434535556",
                    //                 "formcontrolnum0": 5
                    //               }
                    //         ) : obj)
                    //     ),
                    // })

                }

            })
            .catch(function (error) {
                // setLoading(false)
                console.log("checkAadhaarDuplicate errir:" + error);
            });

    }

    // const handleFileChange = (event) => {

    //     console.log("in handleFileChange")

    //     if (event.target.files[0].size > 2000001) {
    //         showToast("ERROR", "Please upload file of size less than 2MB.")
    //     }
    //     else if (!event.target.files[0].type.includes("image")) {
    //         showToast("ERROR", "Please upload file in JPEG or PNG format.")
    //     }
    //     else {

    //         // var selectedblob = URL.createObjectURL(event.target.files[0])
    //         var selectedblob = event.target.files[0]
    //         var reader = new FileReader();
    //         reader.readAsDataURL(selectedblob);
    //         reader.onloadend = function () {
    //             var base64data = reader.result;
    //             console.log(base64data);

    //             sessionStorage.setItem('rationCard', base64data)
    //             setAllValues({
    //                 ...allValues,
    //                 // imgfile: URL.createObjectURL(event.target.files[0])
    //                 // imgfile: base64data,
    //                 rationDocumentName: event.target.files[0].name
    //             })
    //         }
    //     }

    // }

    const handleFileRation= (event) => {
         console.log("Ration Card Selected",event.target.files[0]);
        setloadingfiles(true)

        if (event.target.files[0].size > 2000001) {
            showToast("ERROR", "Please upload file of size less than 2MB.")
        }
        else if (!event.target.files[0].type === "image/png" || !event.target.files[0].type === "image/jpeg" || !event.target.files[0].type === "image/jpg" || !event.target.files[0].type === "application/pdf" ) {
            showToast("ERROR", "Please upload file in JPEG or PNG format.")
        }
        else {
            const joined = Array.from(selectedRationfiles).concat(Array.from(event.target.files));

            setSelectedRationfiles(joined)
        }
        setloadingfiles(false)

    }

    const handleFileBank= (event) => {
         console.log("Ration Card Selected",event.target.files[0]);
        setloadingfiles(true)

        if (event.target.files[0].size > 2000001) {
            showToast("ERROR", "Please upload file of size less than 2MB.")
        }
        else if (!event.target.files[0].type === "image/png" || !event.target.files[0].type === "image/jpeg" || !event.target.files[0].type === "image/jpg" || !event.target.files[0].type === "application/pdf" ) {
            showToast("ERROR", "Please upload file in JPEG or PNG format.")
        }
        else {
            const joined = Array.from(selectedPassbookfiles).concat(Array.from(event.target.files));

            setSelectedPassbookfiles(joined)
        }
        setloadingfiles(false)

    }
  
    const handleFileNominee = (event) => {

        setloadingfiles(true)

         if (event.target.files[0].size > 2000001) {
            showToast("ERROR", "Please upload file of size less than 2MB.")
        }
        else if (!event.target.files[0].type === "image/png" || !event.target.files[0].type === "image/jpeg" || !event.target.files[0].type === "image/jpg" || !event.target.files[0].type === "application/pdf" ) {
            showToast("ERROR", "Please upload file in JPEG or PNG format.")
        }
       else {
            const joined = Array.from(selectedNomineePassbookfiles).concat(Array.from(event.target.files));

            setSelectedNomineePassbookfiles(joined)
        }
        setloadingfiles(false)

    }

    const removefile = (index) => {
        setloadingfiles(true)
        // setremovedFile(true)
        // console.log("removefile index: "+index)
        // selectedfiles.splice(1,index)
        selectedRationfiles.splice(index, 1)

        setTimeout(() =>
            setloadingfiles(false)
            , 1);
    }
    const removefileBank = (index) => {
        setRemovedFile(true)
        setloadingfiles(true)
        console.log("removefile index: "+index)
        // selectedfiles.splice(1,index)
        selectedPassbookfiles.splice(index,1)

        setTimeout(() => 
            setloadingfiles(false)
            , 1);
    }
    const removefileNominee = (index) => {
        console.log("removefileNomine")

        setloadingfiles(true)
        console.log("removefileNominee index: "+index)
        // selectedfiles.splice(1,index)
        selectedNomineePassbookfiles.splice(index,1)

        setTimeout(() => 
            setloadingfiles(false)
            , 1);
    }

    const handleEnter = () => {
        console.log("in handleEnter")

        const nextSibling = document.querySelector(
            `.formcontrol${allValues.residentialData.formcontrolnum + 1}>div>div>input`
        );
        console.log("nextSibling:" + nextSibling.className)

        if (nextSibling !== null) {
            nextSibling.focus();
        }
    }

    const addNewFamilyMember = (memberNumber) => {
        console.log("in addNewFamilyMember")
        console.error("in addNewFamilyMember memberNumber=" + memberNumber)

        if (memberNumber !== undefined && memberNumber !== null && memberNumber !== "") {
            // var familymem = memberNumber
            setAllValues({
                ...allValues,
                membersList: allValues.membersList.concat({
                    "id": memberNumber,
                    ["relation" + memberNumber]: allValues.membersList[memberNumber]['relation' + memberNumber],
                    ["firstname" + memberNumber]: allValues.membersList[memberNumber]['firstname' + memberNumber],
                    ["middlename" + memberNumber]: allValues.membersList[memberNumber]['middlename' + memberNumber],
                    ["lastname" + memberNumber]: allValues.membersList[memberNumber]['lastname' + memberNumber],
                    ["dob" + memberNumber]: allValues.membersList[memberNumber]['dob' + memberNumber],
                    ["profession" + memberNumber]: allValues.membersList[memberNumber]['profession' + memberNumber],
                    ["education" + memberNumber]: allValues.membersList[memberNumber]['education' + memberNumber],
                    ["nominee" + memberNumber]: allValues.membersList[memberNumber]['nominee' + memberNumber],
                    ["alreadyRegistered" + memberNumber]: allValues.membersList[memberNumber]['alreadyRegistered' + memberNumber],
                    ["registrationNo" + memberNumber]: allValues.membersList[memberNumber]['registrationNo' + memberNumber],
                    ["aadharnum1_" + memberNumber]: allValues.membersList[memberNumber]['aadharnum1_' + memberNumber],
                    ["aadharnum2_" + memberNumber]: allValues.membersList[memberNumber]['aadharnum2_' + memberNumber],
                    ["aadharnum3_" + memberNumber]: allValues.membersList[memberNumber]['aadharnum3_' + memberNumber],
                    ["aadhar" + memberNumber]: allValues.membersList[memberNumber]['aadhar' + memberNumber],

                }),
                // noOfFamilyMembers: familymem
            })
        }
        else {
            var familymem = allValues.noOfFamilyMembers + 1
            setAllValues({
                ...allValues,
                membersList: allValues.membersList.concat({
                    "id": allValues.noOfFamilyMembers,
                    ["relation" + allValues.noOfFamilyMembers]: '',
                    ["firstname" + allValues.noOfFamilyMembers]: '',
                    ["middlename" + allValues.noOfFamilyMembers]: '',
                    ["lastname" + allValues.noOfFamilyMembers]: '',
                    ["dob" + allValues.noOfFamilyMembers]: null,
                    ["profession" + allValues.noOfFamilyMembers]: '',
                    ["education" + allValues.noOfFamilyMembers]: '',
                    ["nominee" + allValues.noOfFamilyMembers]: 0,
                    ["alreadyRegistered" + allValues.noOfFamilyMembers]: '',
                    ["registrationNo" + allValues.noOfFamilyMembers]: '',
                    ["aadharnum1_" + allValues.noOfFamilyMembers]: '',
                    ["aadharnum2_" + allValues.noOfFamilyMembers]: '',
                    ["aadharnum3_" + allValues.noOfFamilyMembers]: '',
                    ["aadhar" + allValues.noOfFamilyMembers]: '',
                    ["formcontrolnum" + allValues.noOfFamilyMembers]: 1,
                }),
                noOfFamilyMembers: familymem
            })
        }
    }

    const removeFamilyMember = (item) => {
        console.log("in removeFamilyMember: item=" + item)
        var array = [...allValues.membersList];
        var index = array.indexOf(item)
        array.splice(index, 1);
        setAllValues({
            ...allValues,
            membersList: array,
            noOfFamilyMembers: allValues.noOfFamilyMembers - 1
        });
    }



    const uploadAllFiles = async () => {
        const finalResult = {
            rationCard: null,
            passbook:null,
            nomineePassbook:null
        }
        
        const rationCardResponses = selectedRationfiles ?  await uploadFiles(selectedRationfiles, users) : [];
    
        const updatedRationCardDetails = rationCardResponses.map(card => {
            if(card.success === true){
                const image  = card.image;

                return {
                    file_id: image.fileId,
                    file_name:image.fileType
                }

            }

            return {}
        })

        const passBookResponses = await uploadFiles(selectedPassbookfiles, users);
         
        const updatedPassbookDetails = passBookResponses.map(card => {
            if(card.success === true){
                const image  = card.image;

                return {
                    file_id: image.fileId,
                    file_name:image.fileType
                }

            }

            return {}
        })
        console.log(selectedNomineePassbookfiles,"selectedNomineePassbookfilesBefore");
        const nomineePassBookResponses = selectedNomineePassbookfiles ? await uploadFiles(selectedNomineePassbookfiles, users) : [];
        console.log(selectedNomineePassbookfiles,"selectedNomineePassbookfilesAfter");
        const updatedNomineePassbookDetails = nomineePassBookResponses.map(card => {
            if(card.success === true){
                const image  = card.image;

                return {
                    file_id: image.fileId,
                    file_name:image.fileType
                }

            }

            return {}
        })
        
        finalResult.passbook = updatedPassbookDetails;
        finalResult.rationCard = updatedRationCardDetails;
        finalResult.nomineePassbook = updatedNomineePassbookDetails;

        callsubmit(finalResult)
    }


    //    const uploadRation = () => {

    //     let promises = [];

    //     selectedRationfiles.forEach(async (i) => {
    //         var selectedblob = i
    //         var reader = new FileReader();
    //         reader.readAsDataURL(selectedblob);
    //         var formdataobj = ""
    //         var fileName = ""
    //         reader.onloadend = await function (event) {
    //             fileName = selectedblob.name;
    //             var base64data = event.target.result;
    //             formdataobj = {
    //                 'file': base64data,
    //                 'userId': users.user.id,
    //                 'fileType': fileName
    //             }
    //             promises.push(uploadfile(formdataobj, fileName))
    //             if (promises.length === selectedRationfiles.length) {
    //                 Promise.all(promises)
    //                     .then(results => {
    //                         console.log("results of promise: ")
    //                         console.log(results)
    //                         setFileUploadRationResults(results)
    //                     })
    //             }
               
    //         }

    //     })
    // }

    //   const uploadPassbook = () => {
    //     console.log("Inside");

    //     let promises = [];

    //     selectedPassbookfiles.forEach(async (i) => {
    //         var selectedblob = i
    //         var reader = new FileReader();
    //         reader.readAsDataURL(selectedblob);
    //         var formdataobj = ""
    //         var fileName = ""
    //         reader.onloadend = await function (event) {
    //             fileName = selectedblob.name;
    //             var base64data = event.target.result;
    //             formdataobj = {
    //                 'file': base64data,
    //                 'userId': users.user.id,
    //                 'fileType': fileName
    //             }
    //             promises.push(uploadfile(formdataobj, fileName))
    //             if (promises.length === selectedPassbookfiles.length) {
    //                 Promise.all(promises)
    //                     .then(results => {
    //                         console.log("results of promise: ")
    //                         console.log(results)
    //                         setFileUploadPassbookResults(results)
    //                     })
    //             }
    //         }

    //     })
    // }


    
    useEffect(() => {
        if(fileUploadRationCall){
            if ((fileUploadRationResults !== null && fileUploadRationResults.length > 0) ) {
            var filelist1 = []
            fileUploadRationResults.forEach((i) => {
                var filejson = {
                    "file_id": i.image.fileId,
                    "file_name": i.image.fileType
                }
                filelist1.push(filejson)
            })
            console.log("filelist: " + JSON.stringify(filelist1))
            // setfilelist(filelist)
            // submit90Days(filelist)
            setFileRation(filelist1)
            // callsubmit()
            setFileUploadRationCall(true)
        }
        
        }
        

    }, [fileUploadRationResults])

    useEffect(() => {
        if ( (fileUploadPassbookResults !== null && fileUploadPassbookResults.length > 0)) {
        
            var filelist2 = []
            fileUploadPassbookResults.forEach((i) => {
                var filejson = {
                    "file_id": i.image.fileId,
                    "file_name": i.image.fileType
                }
                filelist2.push(filejson)
                
            })
            console.log("filelist: " + JSON.stringify(filelist2))
            setFilePassbook(filelist2)
            // callsubmit()
            
        }
        

    }, [fileUploadPassbookResults])


    // useEffect(()=>{
    //     console.log("FilePassebook",filePassbook);
    //     if(filePassbook){
    //         if(!fileRation || (fileRation && fileRation.length > 0)){
    //             // callsubmit()
    //         }
            
    //     }
        

    // },[filePassbook,fileUploadRationCall])

    // useEffect(()=>{
    //     console.log("FileRation",fileRation);
    //     if(fileRation){
    //         callsubmit()
    //     }
    // },[fileRation])

    // const handleBankUpload = async (ev) => {
    //     //   setloadingfiles(true)
    //     //   if (ev.target.files[0].size > 2000001) {
    //     //     showToast("ERROR", "Please upload file of size less than 2MB.")
    //     //     }
    //     //   else if (!ev.target.files[0].type.includes("image")) {
    //     //     showToast("ERROR", "Please upload file in JPEG or PNG format.")
    //     //     }
    //     //   else{
    //     //     console.log("BankUpload",ev.target.files);
    //     //     setBankFiles(ev.target.files)
      
    //     //     setBankUploadFileName(ev.target.files[0].name)
    //     //     const joined = Array.from(selectedfiles).concat(Array.from(ev.target.files));

    //     //     setselectedfiles(joined)
    //     //     setloadingfiles(false)
    //     //     console.log(joined,"JoinedFile");
    //     // }
    // }

//     const convertToBase64Passbook = () => {
//         return new Promise((resolve, reject) => {
//         var passbookFileList = null 
//         let promises = []
    
        
//         passbookFileList = selectedfiles  
//         if(passbookFileList !== null && passbookFileList.length > 0 && passbookFileList !== "[]" ){
//         passbookFileList.forEach(async (i) => {
//         var fileIndex = i;
//         const fileReader = new FileReader();
//         fileReader.readAsDataURL(fileIndex);

//         fileReader.onloadend = await function (event) {
//         var fileName = ""
//         var formdataobj = ""
       
//         var base64data = event.target.result
//         fileName = fileIndex.name
//         formdataobj = {
//                     'file': base64data,
//                     'userId': users.user.id,
//                     'fileType': fileName
//                 }
//                 promises.push(uploadfile(formdataobj,fileName))
//                 // console.log("Base64Res:",fileReader.result);

//         };

//         fileReader.onerror = (error) => {
//         reject(error);
//         };
//         })
    
//         }
//         // }
//         resolve(passbookFileList);
    
//       });
//   };

    const familyMembers = allValues.membersList.map((id, i) => {
        return (
            <>
                <img alt="..." src={close} className="closebtn" onClick={() => removeFamilyMember(i)} />
                <Row className={"familymember-card row" + i}>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                {/* <p >Relation :</p> */}
                                <Required title="Relation" />
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth >
                                    <Select
                                        className="select-marital"
                                        value={allValues.membersList[i]["relation" + i]}
                                        name={"relation" + i}
                                        displayEmpty
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {allValues.relations.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                {/* <p >First Name :</p> */}
                                <Required title="First Name" />
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Enter Your Name"
                                        value={allValues.membersList[i]["firstname" + i]}
                                        name={"firstname" + i}
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                <p >Middle Name :</p>
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Enter Your Name"
                                        value={allValues.membersList[i]["middlename" + i]}
                                        name={"middlename" + i}
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                {/* <p >Last Name :</p> */}
                                <p >Last Name :</p>
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Enter Your Name"
                                        value={allValues.membersList[i]["lastname" + i]}
                                        name={"lastname" + i}
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                {/* <p >Aadhar :</p> */}
                                <Required title={<Translate value="aadhar" />} />
                            </Col>
                            <Col xs={9} >
                                <div className="aadhar-div">
                                    <img alt="..." src={aadhar} />
                                    <FormControl >
                                        <TextField
                                            className="aadhardiv1"
                                            placeholder="1234"
                                            // type = "number"
                                            value={allValues.membersList[i]["aadharnum1_" + i]}
                                            name={"aadharnum1_" + i}
                                            onChange={(e) => { handleAAdharChangeMemberList(e, i, 1) }}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                        />
                                    </FormControl>
                                    <FormControl  >
                                        <TextField
                                            className="aadhardiv2"
                                            placeholder="2345"
                                            // type = "number"
                                            value={allValues.membersList[i]["aadharnum2_" + i]}
                                            name={"aadharnum2_" + i}
                                            onChange={(e) => { handleAAdharChangeMemberList(e, i, 2) }}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                        />
                                    </FormControl>
                                    <FormControl  >
                                        <TextField
                                            className="aadhardiv3"
                                            placeholder="3456"
                                            // type = "number"
                                            value={allValues.membersList[i]["aadharnum3_" + i]}
                                            name={"aadharnum3_" + i}
                                            onChange={(e) => { handleAAdharChangeMemberList(e, i, 3) }}
                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                            
                                        />
                                    </FormControl>
                                    {/* {
                                        allValues.membersList[i]["aadharcheck" + i] ?
                                            (<img alt="..." src={checkgreen} />)
                                            :
                                            (<img alt="..." src={checkgrey} />)
                                    } */}
                                </div>

                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                {/* <p >DOB :</p> */}
                                <Required title="DOB" />
                            </Col>
                            <Col xs={9} >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        maxDate={new Date()}
                                        onClick={() => setPickerStatus(true)}
                                        onClose={() => setPickerStatus(false)}
                                        open={allValues.openDatePicker}
                                        className="datepicker-family"
                                        margin="normal"
                                        format="D/MM/yyyy"
                                        placeholder="DD/MM/YYYY"
                                        value={allValues.membersList[i]["dob" + i]}
                                        name={"dob" + i}
                                        onChange={(date) => { handleDateChangeMemberList(date, i) }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                <p >Profession :</p>
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth >
                                    <Select
                                        className="select-marital"
                                        displayEmpty
                                        value={allValues.membersList[i]["profession" + i]}
                                        name={"profession" + i}
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {allValues.professions.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <Row>
                            <Col xs={3} className="member-p">
                                <p >Education :</p>
                            </Col>
                            <Col xs={9} >
                                <FormControl fullWidth >
                                    <Select
                                        className="select-marital"
                                        displayEmpty
                                        value={allValues.membersList[i]["education" + i]}
                                        name={"education" + i}
                                        onChange={(e) => { handleChangeMemberList(e, i) }}
                                    >
                                        <MenuItem value="">
                                            <ListItemText primary="-Select-" />
                                        </MenuItem>
                                        {allValues.educations.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="member-p">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // onChange={handleChangeCheckbox}
                                    // color="primary"
                                    checked={allValues.membersList[i]["nominee" + i] === 1}
                                    name={"nominee" + i}
                                    onChange={(e) => { handleChangeCheckboxMemberList(e, i) }}
                                />
                            }
                            label="Nominee"
                        />
                    </Col>
                    <Col xs={12} className="member-p mt-2">
                        <FormControl className="interstateRadio" variant="outlined" fullWidth component="fieldset">
                            <RadioGroup row
                                value={allValues.membersList[i]["alreadyRegistered" + i]}
                                name={"alreadyRegistered" + i}
                                onChange={(e) => { handleChangeMemberList(e, i) }}
                            >
                                {/* <p className="mt-2 mb-2">Already Registered in KBOCWWB</p> */}
                                <Required className="mt-2 mb-2" title="Already Registered in KBOCWWB" />
                                <FormControlLabel className="col-2" value="1" control={<Radio />} label="Yes" />
                                <FormControlLabel className="col-2" value="0" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col xs={12} className="member-p mt-2">
                        {
                            allValues.membersList[i]["alreadyRegistered" + i] === "1" ?

                                (
                                    <div className="regnum">
                                        <p className="mt-2 mb-2">KBOCWWB Registration Number</p>
                                        <FormControl style={{ flexGrow: "0.5" }}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Enter Your KBOCWWB Registration Number"
                                                value={allValues.membersList[i]["registrationNo" + i]}
                                                name={"registrationNo" + i}
                                                onChange={(e) => { handleChangeMemberList(e, i) }}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="start">
                                                            {
                                                                allValues.pancheck ?
                                                                    (<img alt="..." src={checkgreen} />)
                                                                    :
                                                                    (<img alt="..." src={checkgrey} />)
                                                            }
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                )

                                : null
                        }
                    </Col>
                </Row>
            </>
        );
    });

    useEffect(() => {
        // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
        if (users.upload_file !== null &&
            users.upload_file.success !== undefined &&
            users.upload_file.success === true
            // && users.upload_file.image.fileType === 'rationCard'
        ) {


            if (users.upload_file.userFileName === "rationCard") {
                setAllValues({
                    ...allValues,
                    documentUploadResponserationCard: users.upload_file.image
                })
            }
            else if (users.upload_file.userFileName === "passbook") {
                setAllValues({
                    ...allValues,
                    documentUploadResponsepassbook: users.upload_file.image
                })
            }
            else if (users.upload_file.userFileName === "passbook_nominee") {
                setAllValues({
                    ...allValues,
                    documentUploadResponsepassbook_nominee: users.upload_file.image
                })
            }
            else if (users.upload_file !== null && users.upload_file.success === undefined) {
                showToast("ERROR", "Cannot upload the ration image. Please try later.")
            }
        }
    }, [users.upload_file])

    const uploadFiles = async (files,users)=>{
        const filePromises = files.map(file=>{
            return new Promise((resolve,reject)=>{
                const reader = new FileReader();

                reader.onloadend = async function () {
                    try {
                        const formdataobj = {
                            'file': reader.result,
                            'userId': users.user.id,
                            'fileType': file.name
                        }
                        const {data} = await uploadFileAPI(formdataobj)
                        // console.log('response', response);
                        resolve(data)
                    } catch (error) {
                        reject(error)
                    }
                }

                reader.onerror = error =>{
                    reject(error)
                }

                reader.readAsDataURL(file);
            })
        });

        const fileInfo = await Promise.all(filePromises);

        return fileInfo;
    }

    const uploadFileAPI= async (payload)=> {
        const formData = new FormData();
            
        formData.append('file', dataURLtoFile(payload.file, 'newfile'));
        formData.append('userId', payload.userId);
        formData.append('fileType', payload.fileType);

        const config = {
            method: 'post',
            url: SERVER + '/upload/file',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: formData
        };

        try {
            const response = await axios(config)
            return response;
        } catch (error) {
            return error
        }
    }

    

    useEffect(()=>{
        const existImg = Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml))
        console.log("ExistImg",existImg);
        setExistingImg(existImg)
   },[users.getUserRegistrationDetails.bank_details]) 
    
    const uploadalldocs = () => {
        uploadAllFiles()
        
    }

  
    const callsubmit = (filesDetails) => {
        // if(newOrExist fileRation=== "new"){
        //     var bankImg = filelistBank1
        //     console.log("New Called");
        // }
        // else if(newOrExist === "exist"){
        //     var exist =  Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml))
        //     console.log("Exist Called");
        // }
        
       
       
    //    console.log("BankImg",bankImg);
        // console.log("filelistBank13: ",filelistBank1.length)
        // console.log("filelistNominee1: ",filelistNominee1)
        // console.log("AadhaarDecryptedFinal: ",allValues.membersList)

        let families = []
        
        for (var i = 0; i < allValues.noOfFamilyMembers; i++) {

            if (allValues.membersList[i].relation_id !== undefined) {
                families.push({
                    "relation_id": allValues.membersList[i].relation_id,
                    "labour_user_id": allValues.membersList[i].parent_child_user_id,
                    // "parent_child_user_id": allValues.membersList[i].parent_child_user_id,
                    "parent_child_user_id": allValues.membersList[i].parent_child_user_id,
                    "isinsert": 0,
                    "isdeleted": 0,
                    "isactive": 1,
                    "relation_type_id": allValues.membersList[i]["relation" + i],
                    "first_name": allValues.membersList[i]["firstname" + i],
                    "last_name": allValues.membersList[i]["lastname" + i],
                    "middle_name": allValues.membersList[i]["middlename" + i],
                    // "aadhar_no": allValues.membersList[i]["aadhar" + i].includes("X") ? AadhaarDecryptedFinal[i].value : allValues.membersList[i]["aadhar" + i],
                    "aadhar_no": allValues.membersList[i]["aadhar" + i],
                    // "aadhar_no": AadhaarDecryptedFinal[i].value,
                    "dob": allValues.membersList[i]["dob" + i],
                    "profession": allValues.membersList[i]["profession" + i],
                    "education": allValues.membersList[i]["education" + i],
                    "is_nominee": allValues.membersList[i]["nominee" + i],
                    "is_registered": allValues.membersList[i]["alreadyRegistered" + i],
                    "registeration_no": allValues.membersList[i]["registrationNo" + i],
                })
            }
            else {
                families.push({
                    "relation_id": 0,
                    "labour_user_id": 0,
                    "parent_child_user_id": 0,
                    "isinsert": 1,
                    "isdeleted": 0,
                    "isactive": 1,
                    "relation_type_id": allValues.membersList[i]["relation" + i],
                    "first_name": allValues.membersList[i]["firstname" + i],
                    "last_name": allValues.membersList[i]["lastname" + i],
                    "middle_name": allValues.membersList[i]["middlename" + i],
                    "aadhar_no": allValues.membersList[i]["aadhar" + i].includes("X") ? AadhaarDecryptedFinal[i].value : allValues.membersList[i]["aadhar" + i],
                    // "aadhar_no": allValues.membersList[i]["aadhar" + i],
                    // "aadhar_no": AadhaarDecryptedFinal[i].value,
                    "dob": allValues.membersList[i]["dob" + i],
                    "profession": allValues.membersList[i]["profession" + i],
                    "education": allValues.membersList[i]["education" + i],
                    "is_nominee": allValues.membersList[i]["nominee" + i],
                    "is_registered": 0,
                    "registeration_no": allValues.membersList[i]["registrationNo" + i],
                    // "labour_user_id": users.user.id,
                })
            }

        }

           
        // const uploadFilesPassbook = JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id)

            

        var data = {
            "user_id": users.user.id,
            "ration_card_no": allValues.rationCardNo,
            // "ration_card_image_id": allValues.documentUploadResponserationCard.fileId,
            "ration_card_image_id": "-",
            "ration_card_files_xml": filesDetails.rationCard,
            "ration_card_type": allValues.rationCardType,
            "board_id": users.user.board_id,
            "families": families,
            "banks": [
                {
                    "labour_user_id": users.user.id,
                    "nominee_for_user_id": null,
                    "nominee_first_name": null,
                    "nominee_middle_name": null,
                    "nominee_last_name": null,
                    "ifsc_code": allValues.ifscCode,
                    "bank_name": allValues.bankName,
                    "bank_branch": allValues.bankBranch,
                    "bank_address": allValues.bankAddress,
                    "account_no": allValues.accountNumber,
                    // "bank_passbook_upload_id": allValues.documentUploadResponsepassbook_nominee.fileId,
                    "bank_passbook_upload_id": '-',
                    "files":filesDetails.passbook,
                    // "files":[{
                    //     file_id:  newOrExist === "new" ? bankImg.image.fileId : newOrExist === "exist" ? exist[0].file_id : "-",
                    //     file_name: newOrExist === "new" ? bankImg.image.fileType : newOrExist === "exist" ? exist[0].file_name : "-" 
                    // }],
                    "is_nominee": 0,
                    "is_verified": allValues.is_bank_verified ? 1 : 0
                },
            ]
        }

        if (allValues.nomineeCheck) {
            // console.log("filelistNominee12: ",filelistNominee1)


            let bank_nominee = {
                "labour_user_id": users.user.id,
                "nominee_for_user_id": null,
                "nominee_first_name": null,
                "nominee_middle_name": null,
                "nominee_last_name": null,
                "ifsc_code": allValues.ifscCode_nominee,
                "account_no": allValues.accountNumber_nominee,
                "bank_name": allValues.bankName_nominee,
                "bank_branch": allValues.bankBranch_nominee,
                "bank_address": allValues.bankAddress_nominee,
                // "bank_passbook_upload_id": allValues.documentUploadResponsepassbook.fileId,
                "bank_passbook_upload_id": '-',
                "files": filesDetails.nomineePassbook,
                "is_nominee": 1,
                "is_verified": allValues.is_bank_verified_nominee ? 1 : 0
            }

            data.banks.push(bank_nominee)
        }
        

        const config = {
            method: 'post',
            url: SERVER + '/user/edit_family_bank',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: data
        };

        console.log('config', config);
        console.log('CALLING');

        axios(config)
        .then(function (response) {
            console.log("edit_family_bank response:", response);

            showToast("SUCCESS", "Details Edited Successfully!")

            props.history.push("/dashboard-user")
        })
        .catch(function (error) {
            console.log("edit_family_bank error:" + JSON.stringify(error));
            showToast("ERROR", "Please fill all the mandatory fields.")
        });
    }


    useEffect(()=>{
        if(isFileUpload)
        {
         console.log(fileResponseData,"Callled");   
    
        try {

            if (
                allValues.ifscCode === "" ||
                allValues.accountNumber === "" ||
                allValues.bankName === "" ||
                allValues.bankBranch === "" ||
                allValues.bankAddress === "" 
                // allValues.membersList[i]["aadharnum1_0"] === "XXXX" ||
                // allValues.membersList[i]["aadharnum2_0"] === "XXXX" ||
                // allValues.membersList[i]["aadharnum3_0"] === "" ||
                
                // allValues.membersList[i]["aadharnum1_0"].length > 4  ||
                // allValues.membersList[i]["aadharnum2_0"].length > 4 ||
                // allValues.membersList[i]["aadharnum3_0"].length > 4
                





                

                // ||
                // sessionStorage.getItem('passbook') === null
                // allValues.passbookUploadResponse.fileId === ""
            ) {
                // console.log("allValues.membersList.firstname=" + allValues.membersList[i]["firstname" + i])
                showToast("WARN", "Please Fill All the Required Fields to Continue!")
            }
            else if (allValues.nomineeCheck && (
                allValues.ifscCode_nominee === "" ||
                allValues.accountNumber_nominee === "" ||
                allValues.bankName_nominee === "" ||
                allValues.bankBranch_nominee === "" ||
                allValues.bankAddress_nominee === "" 
              

                // ||
                // sessionStorage.getItem('passbook_nominee') === null
            )) {
                showToast("WARN", "Please Fill All the Required Fields to Continue!")
            }
            else {

                var i = 0

                for (i = 0; i < allValues.noOfFamilyMembers; i++) {
                    if (
                        allValues.membersList[i]["firstname" + i] === "" ||
                        allValues.membersList[i]["relation" + i] === "" ||
                        allValues.membersList[i]["aadhar_no" + i] === "" ||
                        allValues.membersList[i]["dob" + i] === "" ||
                        allValues.membersList[i]["profession" + i] === "" ||
                        allValues.membersList[i]["education" + i] === "" ||
                        allValues.membersList[i]["alreadyRegistered" + i] === "" ||
                        allValues.membersList[i]["aadharnum1_0"] === "" ||
                        allValues.membersList[i]["aadharnum2_0"] === "" ||
                        allValues.membersList[i]["aadharnum3_0"] === "" 


                        
                    ) {
                        console.log("allValues.membersList.firstname=" + allValues.membersList[i]["firstname" + i])
                        showToast("WARN", "Please Fill All the Required Fields to Continue!")
                        break;
                    }
                    else{
                        console.log("callsubmit_hit")

                        // callsubmit(fileResponseData,"new")
                    }
                }
                // console.log("var i=" + i)
                // console.log("filelist: ",filelist1)
                // console.log("filelistBank: ",filelistBank1)
                // console.log("filelistNominee: ",filelistNominee1)

                // if (i === allValues.noOfFamilyMembers
                //     && allValues.documentUploadResponserationCard !== ""
                //     && allValues.documentUploadResponsepassbook !== ""
                //     && allValues.documentUploadResponsepassbook_nominee !== ""
                // ) {
                    
                // }
            }

        }
        catch (err) {
            showToast("ERROR", "Error Saving the Details")
            console.error("in catch-> Error:", err)
        }


    
        }
        
    },[isFileUpload])


    

    // const uploadPassbook = () => {
    //     return new Promise((resolve, reject) => {
    //         if (sessionStorage.getItem('passbook') !== null && users.user !== null) {
    //             let formdata = {
    //                 'file': sessionStorage.getItem('passbook'),
    //                 'userId': users.user.id,
    //                 'fileType': 'passbook'
    //             }
    //             console.error("formdata passbook: " + JSON.stringify(formdata))
    //             dispatch(uploadFile(formdata, "passbook"))
    //             resolve("upload called uploadPassbook")
    //         }
    //     })
    // }
    // const uploadPassbookNominee = () => {
    //     return new Promise((resolve, reject) => {
    //         if (sessionStorage.getItem('passbook_nominee') !== null && users.user !== null) {
    //             let formdata = {
    //                 'file': sessionStorage.getItem('passbook_nominee'),
    //                 'userId': users.user.id,
    //                 'fileType': 'passbook_nominee'
    //             }
    //             console.error("formdata passbook_nominee: " + JSON.stringify(formdata))
    //             dispatch(uploadFile(formdata, "passbook_nominee"))
    //             resolve("upload called uploadPassbookNominee")
    //         }
    //     })
    // }

    // const uploadPassbookNominee = () => {
    //     return new Promise((resolve, reject) => {
            
    //     let promises = [];
    //     var filelist = null

    //     if(filesxmlPresentNominee){
    //         if(users.getUserRegistrationDetails !== undefined &&
    //             users.getUserRegistrationDetails.bank_details !== undefined &&
    //             users.getUserRegistrationDetails.bank_details[0] !== undefined &&
    //             users.getUserRegistrationDetails.bank_details[0].is_nominee === true && 
    //             users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== "null"
    //             ){
    //             filelist = Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml))
    //         }
    //         else if(
    //             users.getUserRegistrationDetails !== undefined &&
    //             users.getUserRegistrationDetails.bank_details !== undefined &&
    //             users.getUserRegistrationDetails.bank_details[1] !== undefined &&
    //             users.getUserRegistrationDetails.bank_details[1].is_nominee === true
    //         ){
    //             filelist = Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml))
    //         }
    //         setfilelistNominee(filelist)
    //     }
    //     else {
    //         filelist = selectedfilesNominee
    //         if(filelist !== null && filelist.length > 0 && filelist !== "[]"){
    //             filelist.forEach(async (i) => {
    //                 var selectedblob = i
    //                 var reader = new FileReader();
    //                 reader.readAsDataURL(selectedblob);
    //                 var formdataobj = ""
    //                 var fileName = ""
    //                 reader.onloadend = await function (event) {
    //                     fileName = selectedblob.name;
    //                     var base64data = event.target.result;
    //                     formdataobj = {
    //                         'file': base64data,
    //                         'userId': users.user.id,
    //                         'fileType': fileName
    //                     }
    //                     promises.push(uploadfile(formdataobj, fileName))
    //                     if (promises.length === filelist.length) {
    //                         Promise.all(promises)
    //                             .then(results => {
    //                                 console.log("results of promise: ")
    //                                 console.log(results)
    //                                 setpassbookuploadNomineeresults(results)
    
    //                             })
    //                     }
    //                 }
    
    //             })
    //         }
    //     }
        
    //     resolve(filelist)
            
    //                 })
    
    // }
    // const uploadRationCard = () => {

    //     return new Promise((resolve, reject) => {
            

    //     let promises = [];
    //     var filelist = null

    //     if(
    //         filesxmlPresent && 
    //         users.getUserRegistrationDetails.ration_card_details !== undefined &&
    //         users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== undefined &&
    //         users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== null &&
    //         users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== ""
    //         ){     
    //         filelist = Array.from(JSON.parse(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml))
    //         setfilelist(filelist)
    //     }
    //     else {
    //         console.log(selectedfiles,"FileList");
    //         filelist = selectedfiles
            
    //         if (filelist !== null && filelist.length > 0 && filelist !== "[]") {
    //             filelist.forEach(async (i) => {
    //                 var selectedblob = i
    //                 var reader = new FileReader();
    //                 reader.readAsDataURL(selectedblob);
    //                 var formdataobj = ""
    //                 var fileName = ""
    //                 reader.onloadend = await function (event) {
    //                     fileName = selectedblob.name;
    //                     var base64data = event.target.result;
    //                     formdataobj = {
    //                         'file': base64data,
    //                         'userId': users.user.id,
    //                         'fileType': fileName
    //                     }
    //                     console.log(formdataobj,"FORMDATA:");
    //                     promises.push(uploadfile(formdataobj, fileName))
    //                     if (promises.length === filelist.length) {
    //                         Promise.all(promises)
    //                             .then(results => {
    //                                 console.log("results of promise: ")
    //                                 console.log(results)
    //                                 setfileuploadresults(results)
    //                             })
    //                     }
    //                 }
    
    //             })
    //         }
    //     }
        

    //     resolve(filelist)
                        
    //                             })
    //     // return new Promise((resolve, reject) => {
    //     //     if (sessionStorage.getItem('rationCard') !== null && sessionStorage.getItem('rationCard') !== undefined && users.user !== null) {
    //     //         let formdata = {
    //     //             'file': sessionStorage.getItem('rationCard'),
    //     //             'userId': users.user.id,
    //     //             'fileType': 'rationCard'
    //     //         }
    //     //         console.error("formdata rationCard: " + JSON.stringify(formdata))
    //     //         dispatch(uploadFile(formdata, "rationCard"))
    //     //         resolve("upload called uploadRationCard")
    //     //     }
    //     //     // else {
    //     //     //     submitFamily()
    //     //     // }
    //     // })
    // }





    const init_family_details = {
        rationCardNo: "",
        rationCardType: "",
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,

        rationDocumentName: "",

        formcontrolnum: 1,

        membersList: [{
            "id": 0,
            "relation0": "",
            "firstname0": "",
            "middlename0": "",
            "lastname0": "",
            "dob0": null,
            "profession0": "",
            "education0": "",
            "nominee0": "",
            "alreadyRegistered0": "",
            "registrationNo0": "",
            "aadharnum1_0": "",
            "aadharnum2_0": "",
            "aadharnum3_0": "",
            "aadhar0": "",
            "formcontrolnum0": 1,
        }],
        noOfFamilyMembers: 1,
        documentUploadResponse: ""
    }




    // BANK
    const [checkBank_nominee, setcheckBank_nominee] = React.useState(false);
    const [loadingVerifyBank_nominee, setLoadingVerifyBank_nominee] = React.useState(false);

    const [checkBank, setcheckBank] = React.useState(false);
    const [loadingVerifyBank, setLoadingVerifyBank] = React.useState(false);

    const verifyBank = () => {
        if (
            allValues.ifscCode !== null &&
            allValues.ifscCode !== "" &&
            allValues.ifscCode !== "null"
        ) {
            setLoadingVerifyBank(true)

            var config = {
                method: 'get',
                url: SERVER + '/global/get_ifsc_details?ifsc=' + allValues.ifscCode,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: ''
            };

            axios(config)
                .then(function (response) {
                    console.log("bank step1 response: " + JSON.stringify(response.data.data, undefined, 2));

                    if (response.data.success === true &&
                        response.data.data !== undefined &&
                        response.data.data.BANK !== undefined
                    ) {
                        showToast("SUCCESS", "Bank Account Details Fetched Successfully")
                        setLoadingVerifyBank(false)
                        setcheckBank(true)

                        setAllValues({
                            ...allValues,
                            bankFetchedData: response.data.data,
                            is_bank_verified: true,
                            bankName: response.data.data.BANK,
                            bankBranch: response.data.data.BRANCH,
                            bankAddress: response.data.data.ADDRESS,
                        })
                    }
                    else {
                        setLoadingVerifyBank(false)
                        showToast("ERROR", "Bank Details Fetching Failed!")
                        // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
                    }

                })
                .catch(function (error) {
                    console.error("bank step1 error: " + error);
                    console.error("bank step1 error.response: " + JSON.stringify(error.response, undefined, 2));
                    setLoadingVerifyBank(false)
                    showToast("ERROR", "Bank Details Fetching Failed:  " + error.response.data.errors.msg)
                    // showToast("ERROR", "Some error occurred while verifying bank details!")
                });

        }
        else {
            showToast("ERROR", "Please Fill IFSC Code!")

        }
    }

    const verifyBankNominee = () => {
        if (
            allValues.ifscCode_nominee !== null &&
            allValues.ifscCode_nominee !== "" &&
            allValues.ifscCode_nominee !== "null"
        ) {
            setLoadingVerifyBank_nominee(true)

            var config = {
                method: 'get',
                url: SERVER + '/global/get_ifsc_details?ifsc=' + allValues.ifscCode_nominee,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: ''
            };

            axios(config)
                .then(function (response) {
                    console.log("bank step1 response: " + JSON.stringify(response.data.data, undefined, 2));

                    if (response.data.success === true &&
                        response.data.data !== undefined &&
                        response.data.data.BANK !== undefined
                    ) {
                        showToast("SUCCESS", "Bank Account Details Fetched Successfully")
                        setLoadingVerifyBank_nominee(false)
                        setcheckBank_nominee(true)

                        setAllValues({
                            ...allValues,
                            bankFetchedData_nominee: response.data.data,
                            is_bank_verified_nominee: true,
                            bankName_nominee: response.data.data.BANK,
                            bankBranch_nominee: response.data.data.BRANCH,
                            bankAddress_nominee: response.data.data.ADDRESS,
                        })
                    }
                    else {
                        setLoadingVerifyBank_nominee(false)
                        showToast("ERROR", "Bank Details Fetching Failed!")
                        // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
                    }

                })
                .catch(function (error) {
                    console.error("bank step1 error: " + error);
                    console.error("bank step1 error.response: " + JSON.stringify(error.response, undefined, 2));
                    setLoadingVerifyBank(false)
                    showToast("ERROR", "Bank Details Fetching Failed:  " + error.response.data.errors.msg)
                    // showToast("ERROR", "Some error occurred while verifying bank details!")
                });

        }
        else {
            showToast("ERROR", "Please Fill IFSC Code!")

        }
    }

    // const handleFileChangeBank = (event) => {
    //     console.log("in handleFileChangeBank")
    //     if (event.target.files[0] !== null) {

    //         if (event.target.files[0].size > 2000001) {
    //             showToast("ERROR", "Please upload file of size less than 2MB.")
    //         }
    //         else if (!event.target.files[0].type.includes("image")) {
    //             showToast("ERROR", "Please upload file in JPEG or PNG format.")
    //         }
    //         else {

    //             var selectedblob = event.target.files[0]
    //             var reader = new FileReader();
    //             reader.readAsDataURL(selectedblob);
    //             reader.onloadend = function () {
    //                 var base64data = reader.result;
    //                 console.log(base64data);

    //                 sessionStorage.setItem('passbook', base64data);
    //                 setAllValues({
    //                     ...allValues,
    //                     // imgfile: base64data,
    //                     passbookDocumentName: event.target.files[0].name
    //                 })
    //             }
    //         }
    //     }
    // }

    // const handleFileChange_nominee = (event) => {
    //     if (event.target.files[0] !== null) {

    //         if (event.target.files[0].size > 2000001) {
    //             showToast("ERROR", "Please upload file of size less than 2MB.")
    //         }
    //         else if (!event.target.files[0].type.includes("image")) {
    //             showToast("ERROR", "Please upload file in JPEG or PNG format.")
    //         }
    //         else {

    //             var selectedblob = event.target.files[0]
    //             var reader = new FileReader();
    //             reader.readAsDataURL(selectedblob);
    //             reader.onloadend = function () {
    //                 var base64data = reader.result;
    //                 console.log(base64data);

    //                 sessionStorage.setItem('passbook_nominee', base64data);
    //                 setAllValues({
    //                     ...allValues,
    //                     // imgfile_nominee: base64data,
    //                     passbookDocumentName_nominee: event.target.files[0].name
    //                 })
    //             }
    //         }
    //     }
    // }
    const handleChangeCheckboxBank = (event) => {
        setAllValues({ ...allValues, [event.target.name]: event.target.checked });
    }

    // console.log("selectedfiles",selectedfiles);

        return (
        <>
            <Row className="personal-div">
                <Row className="topbar-row">
                    <Col xs={12} md={8} lg={9} className="top-bar-col">
                        <div className="logo-div-profile">
                            <Link to="/dashboard-user">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                            </Link>
                            <h1 className="logo-text">
                                <Translate value="header.title" /> <br />
                                <Translate value="header.sub" />
                            </h1>
                        </div>

                    </Col>
                </Row>

                <Row className="m-0">


                    <Row className="family-title-row">
                        <Col xs={12} className="profile-title">
                            <h1>Ration Card Details</h1>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col xs={12} className="family-col-1">
                            <Row >
                                <Col xs={12} md={6}>
                                    <p className="mt-3 mb-2">Ration Card Number</p>
                                    {/* <Required className="mt-3 mb-2" title="Ration Card Number"/> */}
                                    <FormControl fullWidth className="formcontrol1"
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                handleEnter();
                                            }
                                        }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Enter Your Ration Card Number"
                                            name="rationCardNo"
                                            value={allValues.rationCardNo}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Col>

                                <Col xs={12} md={6}>
                                    <p className="mt-3 mb-2">Ration Card Type</p>
                                    {/* <Required className="mt-3 mb-2" title="Ration Card Type"/> */}
                                    <FormControl variant="outlined" fullWidth >
                                        <Select
                                            className="select-marital"
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={allValues.rationCardType}
                                            name="rationCardType"
                                            displayEmpty
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">
                                                <ListItemText primary="-Select-" />
                                            </MenuItem>
                                            {allValues.rationCardTypes.map((i) =>
                                                <MenuItem value={i.value_id}>
                                                    <ListItemText primary={i.value} />
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>

                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} className="family-col-2">
                            <p className="mt-5 mb-2">Upload Your Ration Card Document</p>

                            <div className="browsebutton-outerdiv">

                                <div className="browsebutton2-div filesNames">

{/* 
                                    {
                                        loadingfiles &&
                                            users.getUserRegistrationDetails !== undefined &&
                                            users.getUserRegistrationDetails.ration_card_details !== undefined &&
                                            users.getUserRegistrationDetails.ration_card_details[0] !== undefined &&
                                            users.getUserRegistrationDetails.ration_card_details[0] !== null &&
                                            users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== undefined &&
                                            users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== null &&
                                            users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== "" &&
                                            filesxmlPresent ?
                                            Array.from(JSON.parse(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml)).map((i, index) =>

                                                <FormControl >
                                                    <TextField
                                                        variant="outlined"
                                                        name="passbookDocumentName"
                                                        value={i.file_name}
                                                        disabled
                                                    />
                                                </FormControl>

                                            )
                                            :
                                            null
                                    } */}

                                    {
                                        loadingfiles ?
                                            <>
                                            </>
                                            :

                                            selectedRationfiles !== null &&
                                            selectedRationfiles.length > 0 &&
                                            Array.from(selectedRationfiles).map((i, index) =>

                                                <FormControl >
                                                    <TextField
                                                        variant="outlined"
                                                        name="rationCardName"
                                                        value={i.name}
                                                        onChange={handleChange}
                                                        disabled
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment position="start">
                                                                    <img alt="..." src={closeicon} className="removefilebtn"
                                                                        onClick={() => removefile(index)} />
                                                                </InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>

                                            )

                                    }

                                </div>
                                <div className="browsebutton2-div">

                                    <label
                                        htmlFor="upload-photo"
                                        className="browse-button btn2">
                                        <img alt="..." src={folder} /> Browse
                                    </label>
                                    <input type="file" name="photo" id="upload-photo" onChange={handleFileRation} multiple />
                                </div>

                            </div>

                            <p className="mt-4 instructions-ration">
                                <span>Instructions*</span><br />
                                The supporting document should be in JPEG or PNG format and size of the document should be less than 2 MB only.
                            </p>
                        </Col>

                        <Col xs={12} className="family-col-3 form-inner-card mt-4">
                            <p>List of Family Members</p>
                        </Col>

                        <Col xs={12} className="family-col-4">
                            {familyMembers}
                        </Col>


                        <Col xs={12} className="addMemberCol mt-4 family-col-10"
                            onClick={() => addNewFamilyMember("")}>
                            <AddCircleRounded /><p>Add More Family Members</p>
                        </Col>

                        <Col xs={12} className="note2 text-center mt-4 family-col-11">
                            <p><span>Note :</span> After the completion of the mandatory fields in Family Details, then only the system will allow the further process.</p>
                        </Col>
                    </Row>


                </Row>


                <Row className="m-0">

                    <Row className="bank-title-row">
                        <Col xs={12} className="profile-title">
                            <h1>Bank Passbook / Statement</h1>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col xs={12} md={6} className="bank-col-1">
                            <Row className="form-inner-card-right">
                                <Col xs={12} >
                                    {/* <p className="mt-3 mb-2">Account Number*</p> */}
                                    <Required className="mt-3 mb-2" title="Account Number" />
                                    <FormControl fullWidth >
                                        <TextField
                                            variant="outlined"
                                            placeholder="Enter Your Account Number"
                                            name="accountNumber"
                                            value={allValues.accountNumber}
                                            onChange={handleChange}
                                           
                                        />
                                    </FormControl>
                                </Col>

                                <Col xs={12} >
                                    {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                                    <Required className="mt-4 mb-2" title="Enter Your Bank Name" />
                                    <FormControl fullWidth className="formcontrol1"
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                handleEnter();
                                            }
                                        }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Enter Your Bank Name"
                                            name="bankName"
                                            value={allValues.bankName}
                                            onChange={handleChange}
                                            error = {allValues.accError}
                                            helperText = {allValues.accError ? "Please enter correctly": ""}
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="bank-col-2">
                            <Row className="form-inner-card-right">
                                <Col xs={10}>
                                    <Required className="mt-3 mb-2" title="IFSC Code" />
                                    <FormControl fullWidth className="formcontrol1"
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                handleEnter();
                                            }
                                        }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Enter Your Bank's IFSC Code"
                                            name="ifscCode"
                                            value={allValues.ifscCode}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={2}>
                                    <div className="checkDiv mt-3">

                                        {
                                            checkBank ?
                                                <>
                                                    {/* <Tooltip title="Bank Account is Verified !"> */}
                                                    <img alt="..." src={checkgreen} style={{ marginTop: "30px" }} />
                                                    {/* </Tooltip> */}
                                                </>
                                                :
                                                <Button
                                                    variant="outline-primary"
                                                    disabled={loadingVerifyBank}
                                                    onClick={verifyBank}
                                                    style={{ marginTop: "20px" }}
                                                >

                                                    {loadingVerifyBank ? <>Fetching...</> : <>Fetch Details</>}
                                                </Button>
                                        }
                                        {loadingVerifyBank &&
                                            <CircularProgress size={24} style={{ marginTop: "30px" }} className="buttonProgress" />}
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <Required className="mt-4 mb-2" title="Enter Your Bank Branch" />
                                    <FormControl fullWidth >
                                        <TextField
                                            variant="outlined"
                                            placeholder="Enter Your Bank Branch"
                                            name="bankBranch"
                                            value={allValues.bankBranch}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="bank-col-3">
                            <div className="form-inner-card-right row">
                                {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                                <Required className="mt-0 mb-2" title="Enter Your Bank Address" />
                                <FormControl fullWidth className="formcontrol1"
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            handleEnter();
                                        }
                                    }}>
                                    <TextareaAutosize
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        // rowsMax={4}
                                        // defaultValue="Default Value"
                                        placeholder="Enter Your Bank Address"
                                        name="bankAddress"
                                        value={allValues.bankAddress}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                            </div>
                        </Col>
                        <Col xs={12} md={6} className="bank-col-4">
                            <div className="form-inner-card-right mt-4">
                                {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                                <Required className="mt-4 mb-2" title="Upload Your Passbook Document" />
                                 <div className="browsebutton-outerdiv">

                                    <div className="browsebutton2-div filesNames">
                                                    
{/*                                     

                                        {
                                            loadingfiles &&
                                                users.getUserRegistrationDetails.bank_details[0] !== undefined &&
                                                users.getUserRegistrationDetails.bank_details[0] !== null &&
                                                users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== undefined &&
                                                users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== null &&
                                                users.getUserRegistrationDetails.bank_details[0].is_nominee === false &&
                                                users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== "null" &&

                                                filesxmlPresentBank ?
                                                Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)).map((i, index) =>

                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name="passbookDocumentName"
                                                            value={i.file_name}
                                                            disabled
                                                        />
                                                    </FormControl>

                                                )
                                                :
                                                null
                                        } */}

                                       

                                        {
                                            loadingfiles ?
                                                <>
                                                </>
                                                :

                                                selectedPassbookfiles !== null &&
                                                selectedPassbookfiles.length > 0 &&
                                                Array.from(selectedPassbookfiles).map((i, index) =>

                                                    <FormControl >
                                                        <TextField
                                                            variant="outlined"
                                                            name="passbookDocumentName"
                                                            value={i.name}
                                                            onChange={handleChange}
                                                            disabled
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="start">
                                                                        <img alt="..." src={close} className="removefilebtn"
                                                                            onClick={() => removefileBank(index)} />
                                                                    </InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>

                                                )

                                        }

                                        

                                    </div>

                                    <div className="browsebutton2-div">


                                        <label
                                            htmlFor="upload-photo2"
                                            className="browse-button btn2">
                                            <img alt="..." src={folder} /> Browse
                                        </label>
                                        <input type="file" name="photo" id="upload-photo2" onChange={handleFileBank} multiple />
                                    </div>

                                </div>

                                <p className="mt-3 instructions2">
                                    <span>Instructions*</span><br />
                                    The supporting document should be in JPEG or PNG format and size of the document should be less than 2 MB only.

                                </p>

                            </div>
                        </Col>
                        <Col xs={12} className="note2 mt-4 ml-3 bank-col-5">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allValues.nomineeCheck}
                                        onChange={handleChangeCheckboxBank}
                                        name="nomineeCheck"
                                    // color="primary"
                                    />
                                }
                                label="Click here to add the Nominee Bank Details"
                            />
                        </Col>

                    </Row>



                    {
                        allValues.nomineeCheck ?
                            <>
                                <Row className="bank-title-row">
                                    <Col xs={12} className="profile-title">
                                        <h1>Nominee's Bank Passbook / Statement</h1>
                                    </Col>
                                </Row>
                                <Row className="form-row">
                                    <Col xs={12} md={6} className="bank-col-1">
                                        <Row className="form-inner-card-right">
                                            <Col xs={12}>
                                                {/* <p className="mt-3 mb-2">Account Number*</p> */}
                                                <Required className="mt-3 mb-2" title="Account Number" />
                                                <FormControl fullWidth >
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Your Account Number"
                                                        name="accountNumber_nominee"
                                                        value={allValues.accountNumber_nominee}
                                                        onChange={handleChange}
                                                        error = {allValues.accnumError_nominee}
                                                        helperText = {allValues.accnumError_nominee ? "Please enter correctly": ""}
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={12}>
                                                {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                                                <Required className="mt-4 mb-2" title="Enter Your Bank Name" />
                                                <FormControl fullWidth className="formcontrol1"
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            handleEnter();
                                                        }
                                                    }}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Your Bank Name"
                                                        name="bankName_nominee"
                                                        value={allValues.bankName_nominee}
                                                        onChange={handleChange}
                                                        error = {allValues.accError_nominee}
                                                        helperText = {allValues.accError_nominee ? "Please enter correctly": ""}
                                                    />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} className="bank-col-2">
                                        <Row className="form-inner-card-right">
                                            <Col xs={10} >
                                                {/* <p className="mt-3 mb-2">IFSC Code*</p> */}
                                                <Required className="mt-3 mb-2" title="IFSC Code" />
                                                <FormControl fullWidth className="formcontrol1"
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            handleEnter();
                                                        }
                                                    }}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Your Bank's IFSC Code"
                                                        name="ifscCode_nominee"
                                                        value={allValues.ifscCode_nominee}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={2}>

                                                <div className="checkDiv mt-3">

                                                    {
                                                        checkBank_nominee ?
                                                            <>
                                                                {/* <Tooltip title="Bank Account is Verified !"> */}
                                                                <img alt="..." src={checkgreen} style={{ marginTop: "30px" }} />
                                                                {/* </Tooltip> */}
                                                            </>
                                                            :
                                                            <Button
                                                                variant="outline-primary"
                                                                disabled={loadingVerifyBank_nominee}
                                                                onClick={verifyBankNominee}
                                                                style={{ marginTop: "20px" }}
                                                            >

                                                                {loadingVerifyBank_nominee ? <>Fetching...</> : <>Fetch Details</>}
                                                            </Button>
                                                    }
                                                    {loadingVerifyBank_nominee &&
                                                        <CircularProgress size={24} style={{ marginTop: "30px" }} className="buttonProgress" />}
                                                </div>
                                            </Col>


                                            <Col xs={6} md={12}>
                                                <Required className="mt-4 mb-2" title="Enter Your Bank Branch" />
                                                <FormControl fullWidth >
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Your Bank Branch"
                                                        name="bankBranch_nominee"
                                                        value={allValues.bankBranch_nominee}
                                                        onChange={handleChange}
                                                        

                                                    />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} className="bank-col-3">
                                        <div className="form-inner-card-right row">
                                            <Required className="mt-0 mb-2" title="Enter Your Bank Address" />
                                            <FormControl fullWidth className="formcontrol1"
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        handleEnter();
                                                    }
                                                }}>
                                                <TextareaAutosize
                                                    variant="outlined"
                                                    multiline
                                                    rows={3}
                                                    placeholder="Enter Your Bank Address"
                                                    name="bankAddress_nominee"
                                                    value={allValues.bankAddress_nominee}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} className="bank-col-4">
                                        <div className="form-inner-card-right mt-4">
                                            <Required className="mt-4 mb-2" title="Upload Your Passbook Document" />

                                            <div className="browsebutton-outerdiv">

                                                <div className="browsebutton2-div filesNames">
{/* 
                                                    {
                                                        loadingfiles &&
                                                            users.getUserRegistrationDetails.bank_details[0] !== undefined &&
                                                            users.getUserRegistrationDetails.bank_details[0] !== null &&
                                                            users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== undefined &&
                                                            users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml !== null &&
                                                            users.getUserRegistrationDetails.bank_details[0].is_nominee === true &&
                                                            filesxmlPresentNominee ?
                                                            Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)).map((i, index) =>

                                                                <FormControl >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        name="passbookDocumentName"
                                                                        value={i.file_name}
                                                                        disabled
                                                                    />
                                                                </FormControl>

                                                            )
                                                            :
                                                            null
                                                    } */}

                                                    {/* {
                                                        loadingfiles &&
                                                            users.getUserRegistrationDetails.bank_details[1] !== undefined &&
                                                            users.getUserRegistrationDetails.bank_details[1] !== null &&
                                                            users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml !== undefined &&
                                                            users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml !== null &&
                                                            users.getUserRegistrationDetails.bank_details[1].is_nominee === true &&
                                                            filesxmlPresentNominee ?
                                                            Array.from(JSON.parse(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml)).map((i, index) =>

                                                                <FormControl >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        name="passbookDocumentName"
                                                                        value={i.file_name}
                                                                        disabled
                                                                    />
                                                                </FormControl>

                                                            )
                                                            :
                                                            null
                                                    } */}

                                                    {
                                                        loadingfiles ?
                                                            <>
                                                            </>
                                                            :

                                                            selectedNomineePassbookfiles !== null &&
                                                            selectedNomineePassbookfiles.length > 0 &&
                                                            selectedNomineePassbookfiles !== "[]" &&
                                                            Array.from(selectedNomineePassbookfiles).map((i, index) =>

                                                                <FormControl >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        name="nomineePassbookDocumentName"
                                                                        value={i.name}
                                                                        onChange={handleChange}
                                                                        disabled
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="start">
                                                                                <img alt="..." src={close} className="removefilebtn"
                                                                                        onClick={() => removefileNominee(index)} />
                                                                                </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>

                                                            )

                                                    }


                                                </div>
                                                <div className="browsebutton2-div">


                                                    <label
                                                        htmlFor="upload-photo3"
                                                        className="browse-button btn2">
                                                        <img alt="..." src={folder} /> Browse
                                                    </label>
                                                    <input type="file" name="photo" id="upload-photo3" onChange={handleFileNominee} multiple />
                                                </div>

                                            </div>



                                            <p className="mt-3 instructions2">
                                                <span>Instructions*</span><br />
                                                The supporting document should be in JPEG or PNG format and size of the document should be less than 2 MB only.

                                            </p>

                                        </div>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                    <Row className="form-row">
                        <Col xs={12} className="note2 text-center mt-4 bank-col-6">
                            <p><span>Note :</span> After the completion of the mandatory fields in Bank Details, then only the system will allow the further process.</p>
                        </Col>
                    </Row>

                    <Row className="button-inside-form-row mb-5">
                        <Col xs={12} className="next-back-button-row mt-4 ">


                            <Link to="#"
                                onClick={() => props.history.push("/profile")}
                                style={{ flexGrow: "0.5" }}
                            >
                                <Button variant="danger" className="back-button" >
                                    Back
                                </Button>
                            </Link>
                            <Link to="#"
                                onClick={uploadalldocs}
                                style={{ flexGrow: "0.5" }}
                            >
                                <Button variant="outline-primary" className="next-button">
                                    Submit
                                </Button>
                            </Link>



                        </Col>
                    </Row>



                </Row>

            </Row>
        </>
    )

}

export default Editprofile