import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTokenCookie, showToast } from "utils/tools";
import { createBrowserHistory } from "history";

const MainLayout = (props) => {
  const [signoutTime, setSignoutTime] = React.useState(1000 * 60 * 20);
  const [warningTime, setWarningTime] = React.useState(1000 * 60 * 19);
  let warnTimeout;
  let logoutTimeout;

  const history = createBrowserHistory();

  const warn = () => {
    console.log("history.location.pathname: ", history.location.pathname);

    if (
      history.location.pathname === "/admin" ||
      history.location.pathname === "/login"
    ) {
    } else {
      showToast("WARN", "You will be logged out automatically in 1 Minute");
    }
  };

  const logout = () => {
    const currentPath = history.location.pathname;

    if (currentPath.includes("/admin") && window.location.href !== "/admin") {
      window.location.href = "/admin";
    } else if (
      currentPath.includes("/login") &&
      window.location.href !== "/login"
    ) {
      window.location.href = "/login";
    }
  };

  const destroy = () => {};
  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    if (getTokenCookie() === undefined) {
      if (
        window.location.href.includes("http://localhost:3000/") ||
        window.location.href.includes("http://localhost") ||
        window.location.href.includes("68.183.85.202") ||
        window.location.href.includes("https://kbocwwb.metahos.com") ||
        window.location.href.includes("http://localhost:9000/") ||
        window.location.href.includes("http://localhost:9000/admin") ||
        window.location.href.includes("http://10.10.134.224:9000/") ||
        window.location.href.includes("http://10.10.134.224:9000/admin") ||
        window.location.href.includes("http://10.10.134.224/") ||
        window.location.href.includes("http://10.10.134.224/admin") ||
        window.location.href.includes("https://kbocwwb.karnataka.gov.in/") ||
        window.location.href.includes(
          "https://kbocwwb.karnataka.gov.in/admin"
        ) ||
        window.location.href.includes("http://localhost:3001/") ||
        window.location.href.includes("http://localhost:3000/admin") ||
        window.location.href.includes("http://localhost:3000/e-card") ||
        window.location.href.includes("https://gcdmsstaging.web.app/") ||
        window.location.href.includes("https://gcdmsstaging.web.app/admin") ||
        window.location.href.includes("https://gcdmsstaging.web.app/e-card") ||
        window.location.href.includes("https://www.karmika.in/") ||
        window.location.href.includes("https://www.karmika.in/admin") ||
        window.location.href.includes("https://www.karmika.in/e-card") ||
        window.location.href.includes("https://gcdmsdev.web.app/") ||
        window.location.href.includes("https://gcdmsdev.web.app/admin") ||
        window.location.href.includes("https://localhost/") ||
        window.location.href.includes("https://localhost/admin") ||
        window.location.href.includes("https://localhost:443/") ||
        window.location.href.includes("https://localhost:443/admin") ||
        window.location.href.includes("http://103.138.197.38/") ||
        window.location.href.includes("http://103.138.197.38/admin") ||
        window.location.href.includes(
          "http://103.138.197.38/admin/dashboard"
        ) ||
        window.location.href.includes("http://103.138.197.38:80/") ||
        window.location.href.includes("http://103.138.197.38:80/admin") ||
        window.location.href.includes("https://103.138.197.38/") ||
        window.location.href.includes("https://103.138.197.38/admin") ||
        window.location.href.includes("https://103.138.197.38:443/") ||
        window.location.href.includes("https://103.138.197.38:443/admin") ||
        window.location.href.includes(
          "http://localhost:3000/admin/dashboard"
        ) ||
        window.location.href.includes(
          "https://gcdmsdev.web.app/global/egov/ekyc_response"
        ) ||
        window.location.href.includes(
          "https://gcdms-c2b78.web.app/egov/ekyc_response"
        ) ||
        window.location.href.includes("http://gcdmsqaback.hostg.in:8801/") ||
        window.location.href.includes(
          "http://gcdmsqaback.hostg.in:8801/admin"
        ) ||
        window.location.href.includes("http://localhost:8801/") ||
        window.location.href.includes("http://localhost:8801/admin") ||
        window.location.href.includes("http://gcdmsalpha.hostg.in:8801/") ||
        window.location.href.includes(
          "http://gcdmsalpha.hostg.in:8801/admin"
        ) ||
        window.location.href.includes("https://gcdmsalpha.hostg.in:7701") ||
        window.location.href.includes(
          "https://gcdmsalpha.hostg.in:7701/admin"
        ) ||
        window.location.href.includes("http://gcdmsdbac.hostg.in:5353") ||
        window.location.href.includes("http://gcdmsdbac.hostg.in:5353/admin") ||
        window.location.href.includes(
          "http://localhost:3000/mobile-registration/"
        ) ||
        window.location.href.includes(
          "https://gcdmsalpha.hostg.in:7701/mobile-registration/"
        ) ||
        window.location.href.includes(
          "http://localhost:3000/admin/dashboard/registration"
        ) ||
        window.location.href.includes("https://gcdms.test.hostg.in:5050") ||
        window.location.href.includes("https://gcdms.test.hostg.in:5050/admin")
      ) {
      } else {
        logout();
      }
    }

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);
  return (
    <>
      {props.children}
      <ToastContainer />
    </>
  );
};

export default MainLayout;
