import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { Typography } from '@mui/material';
import { showToast } from 'utils/tools';
import Button2 from '@mui/material/Button';

const EducationStatus = () => {
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [fetchStatus, setFetchStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const handleStatus = () => {
    if (referenceNumber) {
      setShowStatus(true);
      setLoading(true);
      axios
        .get(
          `${SERVER}/internal/schemes/education/status?refNumber=${referenceNumber}`,
        )
        .then((res) => {
          setFetchStatus(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.errors?.msg) {
            showToast('ERROR', `${err?.response?.data?.errors?.msg}`);
          } else {
            showToast('ERROR', 'Something went wrong');
          }
        });
    } else {
      showToast('ERROR', 'Please enter Application Reference Number');
    }
  };

  return (
    <>
      <div className="root">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: 400,
              height: 420,
            },
          }}
        >
          <Paper elevation={0}>
            <div
              className="d-flex justify-content-center align-items-center flex-column p-2 m-2"
              style={{}}
            >
              <div className="p-2 m-2">
                <input
                  className="change-password-input"
                  type="text"
                  placeholder="Enter Reference Number"
                  // name="oldpassword"
                  value={referenceNumber}
                  onChange={(ev) => setReferenceNumber(ev.target.value)}
                />
              </div>

              <div className="d-flex justify-content-center align-items-center w-100">
                <Button2
                  className="w-50"
                  onClick={handleStatus}
                  variant="contained"
                  color="info"
                >
                  Check
                </Button2>
              </div>

              {showStatus ? (
                <div className="d-flex justify-content-center text-center align-items-center py-5">
                  <Paper
                    style={{ width: '320px', height: '160px' }}
                    elevation={3}
                  >
                    <Typography className="d-flex justify-content-center text-center align-items-center pt-5">
                      {loading
                        ? 'Please wait'
                        : fetchStatus && fetchStatus.length && fetchStatus}
                    </Typography>
                  </Paper>
                </div>
              ) : null}
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default EducationStatus;
