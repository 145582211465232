import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import aadhaarCard from 'assets/images/Aadhar-Black.svg';
import labourCard from 'assets/images/DUlicate id card-01.svg';
import labourPhoto from 'assets/images/Icon awesome-user.svg';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ArticleIcon from '@mui/icons-material/Article';
import PensionList from './PensionList';
import { Button } from 'react-bootstrap';

const PensionData = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {/* <h3>Pension Data for Continuation of Pension</h3> */}

      <Paper
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column text-center">
          <h4 className="text-center">Required Documents</h4>
          <List
            sx={{
              width: '100%',

              bgcolor: 'background.paper',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DocumentScannerIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Labour Card" />
            </ListItem>
            {/* <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <img src={aadhaarCard} alt="aadhaar" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Aadhaar Card" />
            </ListItem> */}
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AssignmentIndIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Latest Photo of Labour" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ArticleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Latest Pension Order" />
            </ListItem>
          </List>
          <Link to="/admin/dashboard/pensionform">
            <Button className="btn btn-primary">
              Click here to fill the details
            </Button>
          </Link>
        </div>
        <>
          <hr
            style={{
              background: 'black',
              color: 'black',
              width: '100%',
              height: '3px',
            }}
          />
          <PensionList />
        </>
      </Paper>
    </div>
  );
};

export default PensionData;
