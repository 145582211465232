import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, MenuItem, ListItemText, Grid, Paper, List, ListItem, ListItemIcon, Checkbox } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getCityDetails, getConfigBoardDetails, getConfigMinistryDetails, getDistrictDetails, getLabourStatusDetails, getTalukDetails, getVillageDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import { PhoneAndroid } from '@material-ui/icons';
import axios from 'axios';


const CircleMapping = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        // props: props.location.rowdata,
        district_details: "",
        district: "",
        taluk_details: "",
        taluk: "",
        city_details: "",
        city: "",
    })

    // const classes = useStyles();
    const [locationprops, setlocationprops] = React.useState([]);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);


    useEffect(() => {
        
        if(props.location.rowdata !== undefined && 
            props.location.rowdata !== null){

            console.error("props.location.rowdata: "+JSON.stringify(props.location.rowdata))
            var val = props.location.rowdata
            setlocationprops(val)
            // setAllValues({
            //     ...allValues,
            //     props: val,
            // })   

            dispatch(getDistrictDetails("12"))


            var config = {
                method: 'get',
                url: SERVER+'/config/circle/get_villages_on_circle?circle_id='+val.id,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
              };
              
              axios(config)
              .then(function (response) {
                console.log("get_villages_on_circle response:");
                console.log(JSON.stringify(response.data));
                // setRight(response.data.data)

                // const arr = JSON.parse(response.data.data)
                const arr = response.data.data
                


                let idModified = arr.map( obj => {
                        return {
                            "id" : obj.village_area_ward_id,
                            "name":obj.village_area_ward,
                        }
                    })
                setRight(idModified)
                

              })
              .catch(function (error) {
                console.log(error);
              });
              

            
        }
        else{
            props.history.push("/admin/circle-master")
        }

    }, []);

    useEffect(() => {
        console.log("allValues: ")
        console.log(JSON.stringify(allValues, undefined,2))
    }, [allValues]);
    
    useEffect(() => {
        
        console.log("users.district_details: "+JSON.stringify(users.district_details))

        if(
            users.district_details !== null &&
            users.district_details !== undefined &&
            users.district_details !== ""
            ){
                setAllValues({
                    ...allValues,
                    district_details: users.district_details,
                })   

        }

    }, [users.district_details]);
    
    useEffect(() => {
        if(
            allValues.district !== ""
            ){
                dispatch(getTalukDetails(allValues.district))  
        }
    }, [allValues.district]);

    useEffect(() => {
        
        console.log("users.taluk_details: "+JSON.stringify(users.taluk_details))

        if(
            users.taluk_details !== null &&
            users.taluk_details !== undefined &&
            users.taluk_details !== ""
            ){
                setAllValues({
                    ...allValues,
                    taluk_details: users.taluk_details,
                })   

        }

    }, [users.taluk_details]);

    useEffect(() => {
        if(
            allValues.taluk !== ""
            ){
                dispatch(getCityDetails(allValues.taluk))  
        }
    }, [allValues.taluk]);

    useEffect(() => {
        
        console.log("users.city_details: "+JSON.stringify(users.city_details))

        if(
            users.city_details !== null &&
            users.city_details !== undefined &&
            users.city_details !== ""
            ){
                setAllValues({
                    ...allValues,
                    city_details: users.city_details,
                })   

        }

    }, [users.city_details]);

    useEffect(() => {
        if(
            allValues.city !== ""
            ){
                dispatch(getVillageDetails(allValues.city))  
        }
    }, [allValues.city]);

    useEffect(() => {
        
        console.log("users.village_details: "+JSON.stringify(users.village_details))

        if(
            users.village_details !== null &&
            users.village_details !== undefined &&
            users.village_details !== ""
            ){
                setLeft(users.village_details)
                setAllValues({
                    ...allValues,
                    village_details: users.village_details,
                })   

        }

    }, [users.village_details]);



    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })
    }

    const resetButtonPressed = () => {
        handleClickOpen()
    }

    const saveButtonPressed = () => {
        handleClickOpenSave()
    }

    const [open, setOpen] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleYes = () => {
        // dispatch(deleteReduxData("getDistrictDetails"))
        dispatch(deleteReduxData("getTalukDetails"))
        dispatch(deleteReduxData("getCityDetails"))
        dispatch(deleteReduxData("getVillageDetails"))

        setLeft([])
        setAllValues({
            ...allValues,
            language: users.profile_details.language,
            users: users.profile_details.users,
            props: props.location.rowdata,
            // district_details: "",
            district: "",
            taluk_details: "",
            taluk: "",
            city_details: "",
            city: "",
        })
        handleClose()
    };

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };

    const handleCloseSave = () => {
        setOpenSave(false);
    };

    const handleYesSave = () => {
        // console.error("in useEffect props:" + props.location.userid)
        console.error("right:" + JSON.stringify(right,undefined,2))
        console.error("left:" + JSON.stringify(left,undefined,2))

        let rightModified = right.map(obj => {
                return {
                    "village_id" : obj.id,
                }
            })

        var data = JSON.stringify({
            // "circle_id": allValues.props.id,
            "circle_id": locationprops.id,
            "wards": rightModified,
            "user_id": users.getDeptUserRole.data[0].department_user_id
          });

        console.error("data:" + data)
          
          var config = {
            method: 'post',
            url: SERVER+'/config/circle/insert_circle_mapping',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            showToast("SUCCESS", "Circle Mapping Saved Successfully!")
            props.history.push("/admin/circle-master")

          })
          .catch(function (error) {
            console.log(error);
            showToast("ERROR", error.response)
            
          });
        
        
        handleCloseSave()
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper
        className="transfer-list"
        >
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                            <ListItemText id={labelId} primary={value.village_area_ward} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    
    const customListVillages = (items) => (
        <Paper
        className="transfer-list"
        >
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <>
            <div className="root">

                <Dialog
                    // fullScreen={fullScreen}
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Reset the form?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will clear all the data.
                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="secondary">
                            Cancel
                </Button>
                        <Button onClick={handleYes} color="primary">
                            Yes
                </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    // fullScreen={fullScreen}
                    open={openSave}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Save Changes"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to save the changes made?
                </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSave} variant="outlined" color="secondary">
                            No
                </Button>
                        <Button onClick={handleYesSave} color="primary">
                            Yes
                </Button>
                    </DialogActions>
                </Dialog>

                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Board</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={8} className="title-col">
                    {/* <Link to="#" onClick={() => props.history.goBack()}> */}
                    <Link to="#" onClick={() => props.history.push("/admin/circle-master")}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Circle Master {'>'} Circle Mapping</p>
                    </Link>
                </Col>
                <Col xs={4} className="title-col">
                        {/* <p className="title">{props.location.rowdata!== undefined && props.location.rowdata.name}</p> */}
                        <p className="title">{locationprops.name !== undefined && locationprops.name}</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1">
                    <Required className="mb-2" title="District" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.district}
                            name="district"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                            allValues.district_details !== "" && 
                            allValues.district_details !== undefined && 
                            allValues.district_details.map((i) =>
                                <MenuItem value={i.id}>
                                    <ListItemText primary={i.name} />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                    <Required className="mb-2" title="Taluk" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.taluk}
                            name="taluk"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                            allValues.taluk_details !== null &&
                            allValues.taluk_details !== undefined &&
                            allValues.taluk_details !== "" &&
                            allValues.taluk_details.map((i) =>
                                <MenuItem value={i.id}>
                                    <ListItemText primary={i.name} />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                    <Required className="mb-2" title="City" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.city}
                            name="city"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                            allValues.city_details !== null &&
                            allValues.city_details !== undefined &&
                            allValues.city_details !== "" &&
                            allValues.city_details.map((i) =>
                                <MenuItem value={i.id}>
                                    <ListItemText primary={i.name} />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Col>


                <Col xs={12} md={12} className="personal-col-1 mt-5">
                    <Grid container spacing={2} justify="center" alignItems="center"
                    // className={classes.root}
                    >
                        <Grid item>
                            <p className="list-title">Unassigned Villages</p>
                            {customListVillages(left)}
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                {/* <Button
                                    variant="outlined"
                                    size="small"
                                    // className={classes.button}
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button> */}
                                <Button
                                    // variant="outlined"
                                    variant="outline-secondary"
                                    size="small"
                                    className="transfer-list-button"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    // variant="outlined"
                                    variant="outline-secondary"
                                    size="small"
                                    className="transfer-list-button"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                {/* <Button
                                    variant="outlined"
                                    size="small"
                                    // className={classes.button}
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button> */}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p className="list-title">Assigned Villages</p>
                            {customListVillages(right)}
                        </Grid>
                    </Grid>
                </Col>


                <Col xs={12} className="next-back-button-row mt-4">

                    <Link to="#"
                        onClick={resetButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="danger" className="back-button" >
                            Reset
                        </Button>
                    </Link>

                    <Link to="#"
                        onClick={saveButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="outline-primary" className="save-button">
                            Save
                        </Button>
                    </Link>

                </Col>


            </Row>
        </>
    )
}

export default CircleMapping;