/* eslint-disable no-throw-literal */
import { hasEmptyPropertiesFamilyDetails } from 'utils/checkEmptyArray';

export const FamilyValidator = async (familyDetails) => {
  const familyDetailsCheck = familyDetails.filter(
    (item) => !hasEmptyPropertiesFamilyDetails(item),
  );
  if (familyDetails.length !== familyDetailsCheck.length) {
    throw {
      success: false,
      msg: 'Please fill all mandatory family details',
    };
  } else if (familyDetails.every((item) => !item.nominee)) {
    throw {
      success: false,
      msg: 'Please select nominee',
    };
  } else {
    return {
      success: true,
      data: {
        familyDetails,
      },
    };
  }
};
