import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
const WelfareFuneral = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    applicant_name: "",
    applicant_name_error: "",
    applicant_address: "",
    applicant_address_error: "",
    labour_name: "",
    labour_name_error: false,
    labour_address: "",
    labour_address_error: "",
    mobile_number: "",
    address: "",
    address_error: false,
    mobile_number_error: false,
    organization_name: "",
    organization_name_error: false,
    organization_address: "",
    organization_address_error: false,
    designation: "",
    designation_error: "",
    monthly_salary: "",
    gratuity_confimation: "",
    gratuity_confimation_error: "",
    medical_assistance: "",
    medical_assistance_error: "",
    relationship_deceased: "",
    relationship_deceased_error: false,
    deceased_worker_name: "",
    deceased_worker_name_error: false,
    monthly_salary_error: "",
    nature_disease: "",
    nature_disease_error: "",
    duration_treatment: "",
    duration_treatment_error: "",
    amount_spent: "",
    amount_spent_error: "",
    account_no: "",
    account_no_error: "",
    ifsc_code: "",
    ifsc_code_error: "",
    openDatePicker: false,
    age: "",
    age_error: false,
    age_of_deceased: "",
    age_of_deceased_error: false,
    date_of_birth: null,
    date_of_death: null,
    dateSelected: false,
  });

  const [dateOfBirth, setDateOfBirth] = React.useState(null);
  const [dateOfDeath, setDateOfDeath] = React.useState(null);

  const [selectedPhotofiles, setselectedPhotofiles] = React.useState({});
  const [selectedDeathCertificatefiles, setselectedDeathCertificatefiles] =
    React.useState({});
  const [selectedSavingsfiles, setselectedSavingsfiles] = React.useState({});
  const [selectedAttestedDeathfiles, setselectedAttestedDeathfiles] =
    React.useState({});

  // const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false)

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [photoUpload, setphotoUpload] = React.useState({});
  const [attestedDeathDoc, setattestedDeathDoc] = React.useState({});
  const [deathDoc, setdeathDoc] = React.useState({});
  const [savingsDoc, setsavingsDoc] = React.useState({});

  const [photofilelist, setphotofilelist] = React.useState({});
  const [deathfileAttestedlist, setdeathfileAttestedlist] = React.useState({});
  const [deathfilelist, setdeathfilelist] = React.useState({});
  const [savingsfilelist, setsavingsfilelist] = React.useState({});

  const [filesPhotoResponse, setfilesPhotoResponse] = React.useState(null);
  const [filesSavingsResponse, setfilesSavingsResponse] = React.useState(null);
  const [filesDeathResponse, setfilesDeathResponse] = React.useState(null);
  const [filesAttestedDeathResponse, setfilesAttestedDeathResponse] =
    React.useState(null);

  const inputRef = useRef(null);
  const [fileObj, setFileObj] = useState("");
  const handleFileChange = (event) => {
    setFileObj(event.target.files && event.target.files[0]);
    if (!fileObj) {
      return;
    }
  };

  // const resetFileInput = (event) => {
  //     // 👇️ reset input value
  //     setFileObj(event.target.value=null)
  //   };

  useEffect(() => {
    if (dateOfBirth !== null) {
      setAllValues({
        ...allValues,
        date_of_birth: dateOfBirth,
        dateSelected: true,
      });
      console.log("date_of_birth" + allValues.date_of_birth);
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (dateOfDeath !== null) {
      setAllValues({
        ...allValues,
        date_of_death: dateOfDeath,
        dateSelected: true,
      });
      console.log("date_of_death" + allValues.date_of_death);
    }
  }, [dateOfDeath]);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };
  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhotoChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedPhotofiles).concat(
        Array.from(event.target.files)
      );

      setselectedPhotofiles(joined);
    }

    setloadingfiles(false);
  };

  const handleDeathCertificateChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedDeathCertificatefiles).concat(
        Array.from(event.target.files)
      );

      setselectedDeathCertificatefiles(joined);
    }

    setloadingfiles(false);
  };

  const handleSavingsDocs = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedSavingsfiles).concat(
        Array.from(event.target.files)
      );

      setselectedSavingsfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleAttestedDeathCert = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedAttestedDeathfiles).concat(
        Array.from(event.target.files)
      );

      setselectedAttestedDeathfiles(joined);
    }

    setloadingfiles(false);
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data, "uploadFile"));
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const uploadPhotoDoc = () => {
    let promises = [];

    selectedPhotofiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedPhotofiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setphotoUpload(results);
          });
        }
      };
    });
  };

  const uploadDeathCertDoc = () => {
    let promises = [];

    selectedDeathCertificatefiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedDeathCertificatefiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setdeathDoc(results);
          });
        }
      };
    });
  };

  const uploadSavingsDoc = () => {
    let promises = [];

    selectedSavingsfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedSavingsfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setsavingsDoc(results);
          });
        }
      };
    });
  };

  const uploadAttestedDeathDoc = () => {
    let promises = [];

    selectedAttestedDeathfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedAttestedDeathfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setattestedDeathDoc(results);
          });
        }
      };
    });
  };

  // const submitFiles = (filesPhotoResponse, filesDeathResponse,filesSavingsResponse,  filesAttestedDeathResponse) => {

  //     console.log(filesDeathResponse,"filesResponse")
  //     console.log(filesSavingsResponse,"filesResponseAttested")
  //     console.log(filesPhotoResponse,"filesResponseMedical")
  //     console.log(filesAttestedDeathResponse,"filesResponseMedicalCert")

  //     // if(filesDeathResponse !== undefined && filesDeathResponse.length > 0 && filesSavingsResponse !== undefined && filesSavingsResponse.length > 0 && filesPhotoResponse !== undefined &&  filesPhotoResponse.length > 0 && filesAttestedDeathResponse !== undefined && filesAttestedDeathResponse.length > 0 ){
  //     console.log(filesDeathResponse,"filesResponse")
  //     console.log(filesSavingsResponse,"filesResponseAttested")
  //     console.log(filesPhotoResponse,"filesResponseMedical")
  //     console.log(filesAttestedDeathResponse,"filesResponseMedicalCert")

  //     // var dataNEW = JSON.stringify({
  //     //     "board_id": 1006,
  //     //     "scheme_id": 34,
  //     //     "labour_name": allValues.applicant_name,
  //     //     "phone_number": allValues.mobile_number,
  //     //     "aadhaar_number": "604755673677",
  //     //     "date_of_birth": allValues.date_of_birth,
  //     //     "labour_address": allValues.labour_address,
  //     //     "district_id": 5,
  //     //     "taluk_id": 31,
  //     //     "grampanchayat_city_id": 1205,
  //     //     "village_area_ward_id": 1205,
  //     //     "address_number_street":"ss",
  //     //     "organisation_name": allValues.organization_name,
  //     //     "organisation_address": allValues.organization_address,
  //     //     "date_of_joining": "2022-06-23",
  //     //     "designation": "sdad",
  //     //     "salary":4000,
  //     //     "nature_of_disease": "sda",
  //     //     "duration_of_treatment": "dsa",
  //     //     "amount_spent_for_treatment": 2000,
  //     //     "medical_assistance_from_other_source": allValues.medical_assistance,
  //     //     "bank_name": "dsad",
  //     //     "bank_branch": "asda",
  //     //     "account_number": 2147483644,
  //     //     "ifsc_code": 99,
  //     //     "amount_sought": 6758,
  //     //     "application_name": allValues.labour_name,
  //     //     "applicant_address": allValues.applicant_address,

  //     //     "relationship_with_deceased": allValues.relationship_deceased,
  //     //     "age_of_deceased": allValues.age_of_deceased,

  //     //     "death_date": allValues.date_of_death,
  //     //     "name_legal_hier": allValues.legal_hier_name,
  //     //     "relationship_leagl_hier": allValues.legal_hier_relation_name,
  //     //     "gratuity_confimation": allValues.gratuity_confimation,
  //     //     "amount_sanctioned_by_approver": 1000,

  //     //     "photo":JSON.stringify(filesPhotoResponse),
  //     //     "employer_document":null,
  //     //     "death_certificate":JSON.stringify(filesDeathResponse),
  //     //     "medical_document": JSON.stringify(filesAttestedDeathResponse),
  //     //     "medical_certificate":null,
  //     //     "discharge_summary": "sss",
  //     //     "passbook":JSON.stringify(filesSavingsResponse),
  //     //     "child_photo":"asdsad" ,
  //     //     "birth_certificate": null,

  //     //     "signature_of_applicant": "sdasd",
  //     //     "ip_address": 123123,
  //     //     "device_id": 123123,
  //     //     "approver_documents": null,
  //     //     "district":  null,
  //     //     "taluk": null,
  //     //     "grampanchayat": null,
  //     //     "village_area_ward":null
  //     // });
  //     // var config = {
  //     //         method: 'post',
  //     //         url: SERVER+'/welfare/insert_welfare_scheme',
  //     //         data : dataNEW
  //     //     };

  //     //     axios(config)
  //     //     .then(function (response) {
  //     //         console.error("response get_fields_value: "+JSON.stringify(response.data));
  //     //         console.log("response get_fields_value: "+JSON.stringify(response.data));
  //     //         showToast("SUCCESS", "Scheme updated successfully")
  //     //         props.history.push("/labourschemesHomePage")

  //     //     })
  //     //     .catch(function (error) {
  //     //         console.error(error);
  //     //     });

  //     // }

  // }

  useEffect(() => {
    if (photoUpload !== null && photoUpload.length > 0) {
      var filelist = [];

      photoUpload.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist));

      setphotofilelist(filelist);

      // submitFiles(filelist)
      setfilesPhotoResponse(filelist);
    }
  }, [photoUpload]);

  useEffect(() => {
    if (deathDoc !== null && deathDoc.length > 0) {
      var filelist1 = [];

      deathDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist1));

      setdeathfilelist(filelist1);

      // submitFiles(photofilelist,filelist1)
      setfilesDeathResponse(filelist1);
    }
  }, [deathDoc]);

  useEffect(() => {
    if (savingsDoc !== null && savingsDoc.length > 0) {
      var filelist2 = [];

      savingsDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist2));

      setsavingsfilelist(filelist2);

      // submitFiles(photofilelist,deathfilelist,filelist2)
      setfilesSavingsResponse(filelist2);
    }
  }, [savingsDoc]);

  useEffect(() => {
    if (attestedDeathDoc !== null && attestedDeathDoc.length > 0) {
      var filelist2 = [];

      attestedDeathDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist2));

      setdeathfileAttestedlist(filelist2);
      setfilesAttestedDeathResponse(filelist2);
      // submitFiles(photofilelist,deathfilelist,savingsfilelist,filelist2)
    }
  }, [attestedDeathDoc]);

  useEffect(() => {
    console.log("selectedPhotofiles", selectedPhotofiles);
    if (selectedPhotofiles !== null && selectedPhotofiles.length > 0) {
      uploadPhotoDoc();
    }
  }, [selectedPhotofiles]);

  useEffect(() => {
    console.log("selectedDeathCertificatefiles", selectedDeathCertificatefiles);
    if (
      selectedDeathCertificatefiles !== null &&
      selectedDeathCertificatefiles.length > 0
    ) {
      uploadDeathCertDoc();
    }
  }, [selectedDeathCertificatefiles]);

  useEffect(() => {
    console.log("selectedSavingsfiles", selectedSavingsfiles);
    if (selectedSavingsfiles !== null && selectedSavingsfiles.length > 0) {
      uploadSavingsDoc();
    }
  }, [selectedSavingsfiles]);

  useEffect(() => {
    console.log("selectedAttestedDeathfiles", selectedAttestedDeathfiles);
    if (
      selectedAttestedDeathfiles !== null &&
      selectedAttestedDeathfiles.length > 0
    ) {
      uploadAttestedDeathDoc();
    }
  }, [selectedAttestedDeathfiles]);

  const nextButtonPressed = () => {
    if (
      allValues.applicant_name !== "" &&
      allValues.applicant_name.length !== 0 &&
      allValues.applicant_address !== "" &&
      allValues.applicant_address.length !== 0 &&
      allValues.relationship_deceased !== "" &&
      allValues.relationship_deceased.length !== 0 &&
      allValues.mobile_number !== "" &&
      allValues.mobile_number.length !== 0 &&
      allValues.labour_name !== "" &&
      allValues.labour_name.length > 0 &&
      allValues.age_of_deceased !== "" &&
      allValues.age_of_deceased.length !== 0 &&
      allValues.labour_address !== "" &&
      allValues.labour_address.length !== 0 &&
      allValues.organization_name !== "" &&
      allValues.organization_name.length !== 0 &&
      allValues.organization_address !== "" &&
      allValues.organization_address.length !== 0 &&
      allValues.date_of_birth !== "" &&
      allValues.date_of_birth.length !== 0 &&
      allValues.date_of_death !== "" &&
      allValues.date_of_death.length !== 0 &&
      allValues.legal_hier_name !== "" &&
      allValues.legal_hier_name.length !== 0 &&
      allValues.legal_hier_relation_name !== "" &&
      allValues.legal_hier_relation_name.length !== 0 &&
      allValues.gratuity_confimation !== "" &&
      allValues.gratuity_confimation.length !== 0 &&
      allValues.medical_assistance !== "" &&
      allValues.medical_assistance.length !== 0
    ) {
      if (
        selectedPhotofiles !== null &&
        selectedPhotofiles.length > 0 &&
        selectedDeathCertificatefiles !== null &&
        selectedDeathCertificatefiles.length > 0 &&
        selectedSavingsfiles !== null &&
        selectedSavingsfiles.length > 0 &&
        selectedAttestedDeathfiles !== null &&
        selectedAttestedDeathfiles.length > 0
      ) {
        // uploadPhotoDoc()

        // uploadDeathCertDoc()

        // uploadSavingsDoc()

        // uploadAttestedDeathDoc()
        var dataNEW = JSON.stringify({
          board_id: 1006,
          scheme_id: 34,
          labour_name: allValues.applicant_name,
          phone_number: allValues.mobile_number,
          aadhaar_number: "604755673677",
          date_of_birth: allValues.date_of_birth,
          labour_address: allValues.labour_address,
          district_id: 5,
          taluk_id: 31,
          grampanchayat_city_id: 1205,
          village_area_ward_id: 1205,
          address_number_street: "ss",
          organisation_name: allValues.organization_name,
          organisation_address: allValues.organization_address,
          date_of_joining: "2022-06-23",
          designation: "sdad",
          salary: 4000,
          nature_of_disease: "sda",
          duration_of_treatment: "dsa",
          amount_spent_for_treatment: 2000,
          medical_assistance_from_other_source: allValues.medical_assistance,
          bank_name: "dsad",
          bank_branch: "asda",
          account_number: 2147483644,
          ifsc_code: 99,
          amount_sought: 6758,
          application_name: allValues.labour_name,
          applicant_address: allValues.applicant_address,

          relationship_with_deceased: allValues.relationship_deceased,
          age_of_deceased: allValues.age_of_deceased,

          death_date: allValues.date_of_death,
          name_legal_hier: allValues.legal_hier_name,
          relationship_leagl_hier: allValues.legal_hier_relation_name,
          gratuity_confimation: allValues.gratuity_confimation,
          amount_sanctioned_by_approver: 1000,

          photo: JSON.stringify(filesPhotoResponse),
          employer_document: null,
          death_certificate: JSON.stringify(filesDeathResponse),
          medical_document: JSON.stringify(filesAttestedDeathResponse),
          medical_certificate: null,
          discharge_summary: "sss",
          passbook: JSON.stringify(filesSavingsResponse),
          child_photo: "asdsad",
          birth_certificate: null,

          signature_of_applicant: "sdasd",
          ip_address: 123123,
          device_id: 123123,
          approver_documents: null,
          district: null,
          taluk: null,
          grampanchayat: null,
          village_area_ward: null,
        });
        var config = {
          method: "post",
          url: SERVER + "/welfare/insert_welfare_scheme",
          data: dataNEW,
        };

        axios(config)
          .then(function (response) {
            console.error(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            console.log(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            showToast("SUCCESS", "Scheme updated successfully");
            props.history.push("/labourschemesHomePage");
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        showToast("ERROR", "Please fill all the documents");
      }

      // uploadDoc()
    } else {
      showToast("ERROR", "Please fill all the mandatory fields");
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };
  const [customError, setCustomError] = useState("");
  const handleChangeValidation = (ev) => {
    if (ev.target.name === "labour_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          labour_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          labour_name_error: true,
        });
      }
    } else if (ev.target.name === "applicant_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          applicant_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          applicant_name_error: true,
        });
      }
    } else if (ev.target.name === "labour_address") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          labour_address_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          labour_address_error: true,
        });
      }
    } else if (ev.target.name === "organization_address") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organisation_address_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_address_error: true,
        });
      }
    } else if (ev.target.name === "age_of_deceased") {
      if (validator.isNumeric(ev.target.value) && ev.target.value.length <= 3) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          age_of_deceased_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          age_of_deceased_error: true,
        });
      }
    } else if (ev.target.name === "relationship_deceased") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 20) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          relationship_deceased_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          relationship_deceased_error: true,
        });
      }
    } else if (ev.target.name === "legal_hier_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 20) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          legal_hier_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          legal_hier_name_error: true,
        });
      }
    } else if (ev.target.name === "legal_hier_relation_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 20) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          legal_hier_relation_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          legal_hier_relation_name_error: true,
        });
      }
    } else if (ev.target.name === "deceased_worker_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 20) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          deceased_worker_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          deceased_worker_name_error: true,
        });
      }
    } else if (ev.target.name === "mobile_number") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 10
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: true,
        });
      }
    } else if (ev.target.name === "gratuity_confimation") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length === 50) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          gratuity_confimation_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          gratuity_confimation_error: true,
        });
      }
    } else if (ev.target.name === "medical_assistance") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length === 50) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          medical_assistance_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          medical_assistance_error: true,
        });
      }
    } else if (ev.target.name === "organization_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: true,
        });
      }
    } else if (ev.target.name === "designation") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: true,
        });
      }
    } else if (ev.target.name === "applicant_address") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          applicant_address_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          applicant_address_error: true,
        });
      }
    } else if (ev.target.name === "monthly_salary") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: true,
        });
      }
    } else if (ev.target.name === "nature_disease") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: true,
        });
      }
    } else if (ev.target.name === "duration_treatment") {
      if (
        validator.isAlphanumeric(ev.target.value) &&
        ev.target.value.length <= 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: true,
        });
      }
    } else if (ev.target.name === "amount_spent") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: true,
        });
      }
    } else if (ev.target.name === "account_no") {
      if (validator.isNumeric(ev.target.value) && ev.target.value.length < 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: true,
        });
      }
    } else if (ev.target.name === "ifsc_code") {
      if (validator.matches(ev.target.value, "[A-Z]{4}0[A-Z0-9]{6}$")) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: true,
        });
      }
    }
  };

  return (
    <div>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/boardsHome'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='labourTitle' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={allValues.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          <h1>
            {" "}
            <Translate value='schemes' /> |
            <Translate value='funeralWelfareTitle' />
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='applicantDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='applicantsName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='applicant_name'
              value={allValues.applicant_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.applicant_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.applicant_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='address' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='applicant_address'
              value={allValues.applicant_address}
              onChange={handleChangeValidation}
              helperText={allValues.applicant_address_error}
              error={allValues.applicant_address_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2  w-100'>
            <Translate
              value='relationwithemployee'
              style={{ whiteSpace: "nowrap" }}
            />
            *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='relationship_deceased'
              value={allValues.relationship_deceased}
              onChange={handleChangeValidation}
              helperText={
                allValues.relationship_deceased_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.relationship_deceased_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='mobileNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='mobile_number'
              value={allValues.mobile_number}
              onChange={handleChangeValidation}
              helperText={
                allValues.mobile_number_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.mobile_number_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='deceasedWorkerDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='deceasedName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='labour_name'
              value={allValues.labour_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.labour_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.labour_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='age' />*
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='age_of_deceased'
              value={allValues.age_of_deceased}
              onChange={handleChangeValidation}
              helperText={
                allValues.age_of_deceased_error
                  ? "Please Enter Number only"
                  : ""
              }
              error={allValues.age_of_deceased_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='completeAddress' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='labour_address'
              value={allValues.labour_address}
              onChange={handleChangeValidation}
              helperText={allValues.labour_address_error}
              error={allValues.labour_address_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='organizationName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.organization_name}
              name='organization_name'
              onChange={handleChangeValidation}
              helperText={
                allValues.organization_name_error
                  ? "Please enter Alphabets Only"
                  : ""
              }
              error={allValues.organization_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='organizationAddress' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='organization_address'
              value={allValues.organization_address}
              onChange={handleChangeValidation}
              helperText={allValues.organization_address_error}
              error={allValues.organization_address_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='otherDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='birthDateofDeceased' />*
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className='datepicker'
                // margin="normal"
                format='DD/MM/YYYY'
                value={allValues.date_of_birth}
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2'>
            <Translate value='deathDateofDeceased' />*
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className='datepicker'
                // margin="normal"
                format='DD/MM/YYYY'
                value={allValues.date_of_death}
                onChange={(date) => {
                  setDateOfDeath(date);
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2' style={{ whiteSpace: "nowrap" }}>
            <Translate value='nameofdeceasedFamily' />*
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='legal_hier_name'
              value={allValues.legal_hier_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.legal_hier_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.legal_hier_name_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-4'>
          <p className='mb-2 mt-1'>
            <Translate value='relationshipdeceasedFamily' />*
          </p>

          <FormControl fullWidth className={"formcontrol1 mt-4"}>
            <TextField
              variant='outlined'
              name='legal_hier_relation_name'
              value={allValues.legal_hier_relation_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.legal_hier_relation_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.legal_hier_relation_name_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2 '>
            <Translate value='gratuity' />*
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='gratuity_confimation'
              value={allValues.gratuity_confimation}
              onChange={handleChangeValidation}
              helperText={allValues.gratuity_confimation_error}
              error={allValues.gratuity_confimation_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className='mt-2'>
          <p className='mb-2 '>
            <Translate value='detailsFinance' />*
          </p>

          <FormControl fullWidth className='formcontrol1 mt-4'>
            <TextField
              variant='outlined'
              name='medical_assistance'
              value={allValues.medical_assistance}
              onChange={handleChangeValidation}
              helperText={allValues.medical_assistance_error}
              error={allValues.medical_assistance_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='uploadDocs' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2'>
            <Translate value='photo' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedPhotofiles !== null &&
                selectedPhotofiles.length > 0 &&
                Array.from(selectedPhotofiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='photo'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                onChange={handlePhotoChange}
                id={`upload-photo`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2'>
            <Translate value='deathCert' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedDeathCertificatefiles !== null &&
                selectedDeathCertificatefiles.length > 0 &&
                Array.from(selectedDeathCertificatefiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='deathcertificatefiles'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo2`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                onChange={handleDeathCertificateChange}
                id={`upload-photo2`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 mt-4 w-75'>
            <Translate value='savingsPassbook' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedSavingsfiles !== null &&
                selectedSavingsfiles.length > 0 &&
                Array.from(selectedSavingsfiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='savingsaccountdetails'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo3`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handleSavingsDocs}
                id={`upload-photo3`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 mt-4 mt-1 w-75'>
            <Translate value='deathCertAttested' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedAttestedDeathfiles !== null &&
                selectedAttestedDeathfiles.length > 0 &&
                Array.from(selectedAttestedDeathfiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='attestedDeathCertDocs'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo4`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handleAttestedDeathCert}
                id={`upload-photo4`}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className='search-subtitle-row pb-5'>
        <Col xs={12} className='next-back-button-row mt-4 '>
          <>
            <Link
              to='/funeral2'
              onClick={props.backButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='danger' className='back-button'>
                <Translate value='back' />
              </Button>
            </Link>
            <Link
              to='#'
              onClick={nextButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='outline-primary' className='save-button'>
                <Translate value='submit' />
              </Button>
            </Link>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default WelfareFuneral;
