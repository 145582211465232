import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import React, { useState } from 'react';

import Card from 'components/schemeMeta/Card';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import { use } from 'react-router-dom';

// import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import MISRegister from './MISRegister';
import MISSchemeV2 from './MISchemeV2';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const itemList = [
  {
    label: 'Registration/ Renewal',
    value: 'gw',
    icon: MapIcon,
  },
  {
    label: 'Scheme Wise',
    value: 'sw',
  },
  {
    label: 'Nature of Work',
    value: 'nw',
  },
  {
    label: 'Religious wise',
    value: 'rw',
  },

  {
    label: 'Age Wise',
    value: 'aw',
  },
];
const REPORT_TYPES = [
  { label: 'Registration Reports', value: 'registration' },
  { label: 'Scheme Reports', value: 'scheme' },
];
function MISCountReport() {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  console.log('MISCountReport ~ query:', query);
  const [value, setValue] = useState('');
  // const [searchParams, setSearchParams] = useSearchParams();
  const reportType = query.get('report') || 'registration';

  const handleReportChange = (type) => {
    history.push(`${location.pathname}?report=${type}`);
  };

  const handleCard = (value) => {
    setValue(value);
    switch (value) {
      case 'gw':
        history.push('/reports/dashboard/mis/district');
        break;
      case 'nw':
        history.push('/reports/dashboard/mis/nature_of_work');
        break;
      case 'aw':
        history.push('/reports/dashboard/mis/age');
        break;
      case 'rw':
        history.push('/reports/dashboard/mis/gender');
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '60vh',
      }}
    >
      {value === '' && (
        <Box sx={{ width: '95%', marginInline: 'auto' }}>
          <ButtonGroup>
            {REPORT_TYPES.map((type) => (
              <Button
                key={type.value}
                variant={reportType === type.value ? 'contained' : 'outlined'}
                onClick={() => handleReportChange(type.value)}
              >
                {type.label}
              </Button>
            ))}
          </ButtonGroup>
          {/* {JSON.stringify(districtData)} */}
          {/* <Grid container spacing={1} sx={{ mx: 'auto' }}>
            {itemList.slice(0, 1).map((item, index) => (
              <Grid item xs={12} lg={3} sm={6}>
                <Card
                  name={item.label}
                  dataKey={item.value}
                  icon={item.icon || FlagIcon}
                  type={'primary'}
                  handleCard={handleCard}
                />
              </Grid>
            ))}
          </Grid> */}
          {reportType === 'registration' && <MISRegister />}
          {/* {reportType === 'scheme' && <MISScheme />} */}
          {reportType === 'scheme' && <MISSchemeV2 />}
        </Box>
      )}
    </div>
  );
}

export default MISCountReport;
