import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const draftSchemeApprove = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/draft/v2/approve-reject`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
