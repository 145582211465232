import "date-fns";
import {
  FormControl,
  TextField,
  FormControlLabel,
  TextareaAutosize,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addLabourBankDetails,
  getFile,
  getUser,
  getUserRegistrationDetails,
  SERVER,
  TS_USERNAME,
  updateBankData,
} from "store/actions/user.actions";
// import folder from 'assets/images/folder (3).svg'
import folder from "assets/images/Folderwhite-01-01.svg";
import {
  dataURLtoFile,
  getTokenCookie,
  Required,
  showToast,
} from "utils/tools";

import checkgreen from "assets/images/Subtraction 1.svg";
import close from "assets/images/closeIcon.svg";
import axios from "axios";
import { Translate } from "react-redux-i18n";
import validator from "validator";

const Bank = (props) => {
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [phone, setPhone] = React.useState("");
  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [filesxmlPresentNominee, setfilesxmlPresentNominee] =
    React.useState(true);
  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState({});
  const [selectedfilesNominee, setselectedfilesNominee] = React.useState({});

  const [passbookuploadresults, setpassbookuploadresults] = React.useState({});
  const [passbookuploadNomineeresults, setpassbookuploadNomineeresults] =
    React.useState({});

  const [filelist, setfilelist] = React.useState({});

  const [removedFile, setRemovedFile] = React.useState(false);

  const [allValues, setAllValues] = React.useState({
    ifscCode: users.bank_details.ifscCode,
    accountNumber: users.bank_details.accountNumber,
    bankName: users.bank_details.bankName,
    bankBranch: users.bank_details.bankBranch,
    bankAddress: users.bank_details.bankAddress,
    imgfile: users.bank_details.imgfile,
    passbookDocumentName: users.bank_details.passbookDocumentName,
    passbookUploadResponse: users.bank_details.passbookUploadResponse,
    nomineeCheck: users.bank_details.nomineeCheck,

    ifscCode_nominee: users.bank_details.ifscCode_nominee,
    accountNumber_nominee: users.bank_details.accountNumber_nominee,
    bankName_nominee: users.bank_details.bankName_nominee,
    bankBranch_nominee: users.bank_details.bankBranch_nominee,
    bankAddress_nominee: users.bank_details.bankAddress_nominee,
    imgfile_nominee: users.bank_details.imgfile_nominee,
    passbookDocumentName_nominee:
      users.bank_details.passbookDocumentName_nominee,
    passbookUploadResponse_nominee:
      users.bank_details.passbookUploadResponse_nominee,

    accountNumberError: false,
    bankNameError: false,
    // bankBranchError:false,
    accountNumberNomineeError: false,
    bankNameNomineeError: false,
    // bankBranchNomineeError:false
  });

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone });
  }, [phone]);

  useEffect(() => {
    // console.log("in useEffect []")
    if (users.generate_otp !== null) {
      setPhone(users.generate_otp.data.phone_number);
      dispatch(getUser(users.generate_otp.data.phone_number));
    } else if (users.generate_otp_registration !== null) {
      setPhone(users.generate_otp_registration.data.phone_number);
      dispatch(getUser(users.generate_otp_registration.data.phone_number));
    } else {
      props.history.push("/");
    }

    dispatch(getFile("delete"));
    dispatch(addLabourBankDetails("delete"));
  }, []);

  useEffect(() => {
    // console.log("in useEffect [users.user]")

    if (users.user !== null && users.user.board_id !== null) {
      let dataForRegDetails = {
        key: "user_id",
        value: users.user.id,
        board_id: users.user.board_id,
        procedure_name: "all",
      };
      dispatch(getUserRegistrationDetails(dataForRegDetails));
    }
  }, [users.user]);

  useEffect(() => {
    // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
    // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
    if (
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.bank_details !== undefined
    ) {
      // if(users.getUserRegistrationDetails !== null){
      if (
        users.getUserRegistrationDetails.bank_details !== undefined &&
        users.getUserRegistrationDetails.bank_details[0] !== undefined &&
        users.getUserRegistrationDetails.bank_details.length > 0
      ) {
        var val = users.getUserRegistrationDetails.bank_details[0];

        if (
          users.getUserRegistrationDetails.bank_details[0]
            .bank_upload_files_xml !== undefined &&
          users.getUserRegistrationDetails.bank_details[0]
            .bank_upload_files_xml !== null &&
          users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml
            .length > 0
        ) {
          if (
            users.getUserRegistrationDetails.bank_details[0]
              .bank_upload_files_xml.length > 1
          ) {
            if (users.getUserRegistrationDetails.bank_details[0].is_nominee) {
              setfilesxmlPresentNominee(true);
            } else if (
              users.getUserRegistrationDetails.bank_details[1] !== undefined &&
              users.getUserRegistrationDetails.bank_details[1].is_nominee
            ) {
              setfilesxmlPresentNominee(true);
            }

            if (!users.getUserRegistrationDetails.bank_details[0].is_nominee) {
              setfilesxmlPresent(true);
            } else if (
              users.getUserRegistrationDetails.bank_details[1] !== undefined &&
              !users.getUserRegistrationDetails.bank_details[1].is_nominee
            ) {
              setfilesxmlPresent(true);
            }
          } else {
            setfilesxmlPresent(true);
          }
        }

        if (users.getUserRegistrationDetails.bank_details.length > 1) {
          var val1 = users.getUserRegistrationDetails.bank_details[1];

          setAllValues({
            ...allValues,
            ifscCode: val1.ifsc_code,
            accountNumber: val1.account_no,
            bankName: val1.bank_name,
            bankBranch: val1.bank_branch,
            bankAddress: val1.bank_address,
            passbookDocumentName: val1.bank_passbook_upload_id,
            // bank_upload_files_xml: JSON.parse(users.getUserRegistrationDetails.bank_details[1].bank_upload_files_xml),
            passbookUploadResponse: {
              fileId: val1.bank_passbook_upload_id,
            },

            nomineeCheck: true,
            ifscCode_nominee: val.ifsc_code,
            accountNumber_nominee: val.account_no,
            bankName_nominee: val.bank_name,
            bankBranch_nominee: val.bank_branch,
            bankAddress_nominee: val.bank_address,
            passbookDocumentName_nominee: val.bank_passbook_upload_id,
            // nomineebank_upload_files_xml: JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml),
            passbookUploadResponse_nominee: {
              fileId: val.bank_passbook_upload_id,
            },
          });
        } else {
          setAllValues({
            ...allValues,
            ifscCode: val.ifsc_code,
            accountNumber: val.account_no,
            bankName: val.bank_name,
            bankBranch: val.bank_branch,
            bankAddress: val.bank_address,
            passbookDocumentName: val.bank_passbook_upload_id,
            passbookUploadResponse: {
              fileId: val.bank_passbook_upload_id,
            },
          });
        }
      }
    }
  }, [users.getUserRegistrationDetails]);

  // useEffect(() => {
  //     console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
  //     console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
  //     console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)
  //     if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.length > 0 ){

  //     }
  // }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    console.log(
      "in useEffect [allValues]: " + JSON.stringify(allValues, undefined, 2)
    );
    dispatch(updateBankData(allValues));
  }, [allValues]);

  // useEffect(() => {
  //     console.log("in useEffect [selectedfiles]: "+ selectedfiles)
  // }, [selectedfiles]);

  const handleDateChange = (date) => {
    console.log(date);
    console.log(new Date(date));
    setAllValues({
      ...allValues,
      dob: new Date(date),
    });
  };

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleClickOpen = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const handleClose = () => {
    setAllValues({
      ...allValues,
      open: !allValues.open,
    });
  };

  const toggleWebcam = () =>
    setAllValues({
      ...allValues,
      webcamEnabled: !allValues.webcamEnabled,
    });

  const capture = () => {
    const imageSrc = myRef.current.getScreenshot();
    console.log(imageSrc);
    sessionStorage.setItem("passbook", imageSrc);
    setAllValues({
      ...allValues,
      // imgfile: imageSrc,
      open: !allValues.open,
    });
  };

  const myFunction = () => {
    props.updateItem(allValues);
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });

    // if (event.target.checked) {
    //     setAllValues({ ...allValues,
    //         permanentData: state.residentialData
    //     }));
    // }
    // else {
    //     setAllValues({ ...allValues,
    //         permanentData: initData
    //     }));
    // }
  };

  const handleChange = (event) => {
    if (event.target.name === "ifscCode") {
      setLoadingVerifyBank(false);
      setcheckBank(false);
    }
    if (event.target.name === "ifscCode_nominee") {
      setLoadingVerifyBank_nominee(false);
      setcheckBank_nominee(false);
    }

    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const changeHandler = (e) => {
    if (e.target.name === "accountNumber") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(e.target.value) &&
        e.target.value.length !== 0 &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          accountNumberError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          accountNumberError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          bankNameError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankNameError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankBranch") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (validator.isAlpha(e.target.value, "en-US", { ignore: " " })) {
        setAllValues({
          ...allValues,
          bankBranchError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankBranchError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "accountNumber_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(e.target.value) &&
        e.target.value.length !== 0 &&
        e.target.value.length < 20
      ) {
        setAllValues({
          ...allValues,
          accountNumberNomineeError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          accountNumberNomineeError: true,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "bankName_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          bankNameNomineeError: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          bankNameNomineeError: true,
          [e.target.name]: e.target.value,
        });
      }
    }

    // else if (e.target.name === "bankBranch_nominee") {
    //     // console.error("validate:" + validator.isNumeric(e.target.value))
    //     // console.error("phone.length:" + e.target.value.length)

    //     if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '})){
    //         setAllValues({
    //             ...allValues,
    //             bankBranchNomineeError: false,
    //             [e.target.name]: e.target.value
    //         })}
    //         else{
    //         setAllValues({
    //             ...allValues,
    //             bankBranchNomineeError: true,
    //             [e.target.name]: e.target.value
    //         })}
    // }
    else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );

      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleFileChange_nominee = (event) => {
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfilesNominee).concat(
        Array.from(event.target.files)
      );

      setselectedfilesNominee(joined);
    }
    setloadingfiles(false);
  };

  // const handleFileChange_nominee = (event) => {
  //     if(event.target.files[0] !== null)
  //     {
  //     var selectedblob = event.target.files[0]
  //     var reader = new FileReader();
  //     reader.readAsDataURL(selectedblob);
  //     reader.onloadend = function () {
  //         var base64data = reader.result;
  //         console.log(base64data);

  //         sessionStorage.setItem('passbook_nominee', base64data);
  //         setAllValues({
  //             ...allValues,
  //             // imgfile_nominee: base64data,
  //             passbookDocumentName_nominee: event.target.files[0].name
  //         })
  //     }
  // }
  // }

  const handleEnter = (event) => {
    console.log("in handleEnter");

    // const nextSibling = document.querySelector(
    //     `.formcontrol${allValues.residentialData.formcontrolnum + 1}>div>div>input`
    // );
    // console.log("nextSibling:" + nextSibling.className)

    // if (nextSibling !== null) {
    //     nextSibling.focus();
    // }
  };

  // useEffect(() => {
  //     // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'passbook') {
  //         if (users.upload_file.userFileName === "passbook") {
  //             setAllValues({
  //                 ...allValues,
  //                 passbookUploadResponse: users.upload_file.image
  //             })

  //             if(users.bank_details.nomineeCheck){
  //                 uploadPassbookNominee()
  //             }
  //             else{
  //                 submitBank(users.upload_file.image, null)
  //             }
  //         }
  //     }
  //     else if(users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR","Cannot upload the passbook image. Please try later.")
  //     }

  //     if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'passbook_nominee') {
  //         if (users.upload_file.userFileName === "passbook_nominee") {
  //             setAllValues({
  //                 ...allValues,
  //                 passbookUploadResponse_nominee: users.upload_file.image
  //             })

  //             submitBank(null, users.upload_file.image)

  //             // if (users.profile_details.editmode) {
  //             //     props.saveEditButtonPressed()
  //             // }
  //             // else {
  //             //     props.nextButtonPressed()
  //             // }
  //         }
  //     }
  //     else if(users.upload_file !== null && users.upload_file.success === undefined) {
  //         showToast("ERROR","Cannot upload the passbook image of nominee. Please try later.")
  //     }
  // }, [users.upload_file]);

  useEffect(() => {
    if (passbookuploadresults !== null && passbookuploadresults.length > 0) {
      // setAllValues({
      //     ...allValues,
      //     passbookUploadResponse: users.upload_file.image
      // })

      if (users.bank_details.nomineeCheck) {
        var filelist = [];

        passbookuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };

          filelist.push(filejson);
        });

        console.log("filelist: " + JSON.stringify(filelist));

        setfilelist(filelist);

        // uploadPassbookNominee()

        if (selectedfilesNominee !== null && selectedfilesNominee.length > 0) {
          uploadPassbookNominee();
        } else {
          // if(allValues.nomineeCheck){

          if (
            users.getUserRegistrationDetails.bank_details[0] !== undefined &&
            users.getUserRegistrationDetails.bank_details[0].is_nominee
          ) {
            submitBank(
              filelist,
              JSON.parse(
                users.getUserRegistrationDetails.bank_details[0]
                  .bank_upload_files_xml
              )
            );
          } else if (
            users.getUserRegistrationDetails.bank_details[1] !== undefined &&
            users.getUserRegistrationDetails.bank_details[1].is_nominee
          ) {
            submitBank(
              filelist,
              JSON.parse(
                users.getUserRegistrationDetails.bank_details[1]
                  .bank_upload_files_xml
              )
            );
          }
          // }
          // else{
          // submitBank(filelist, null)
          // }
        }
      } else {
        var filelist = [];

        passbookuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };

          filelist.push(filejson);
        });

        console.log("filelist: " + JSON.stringify(filelist));

        setfilelist(filelist);

        submitBank(filelist, null);
      }
    }
  }, [passbookuploadresults]);

  useEffect(() => {
    if (
      passbookuploadNomineeresults !== null &&
      passbookuploadNomineeresults.length > 0
    ) {
      // setAllValues({
      //     ...allValues,
      //     passbookUploadResponse: users.upload_file.image
      // })

      // if(users.bank_details.nomineeCheck){
      //     uploadPassbookNominee()
      // }
      // else{

      var filelist1 = [];

      passbookuploadNomineeresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist));
      console.log("filelist1: " + JSON.stringify(filelist1));

      if (filelist !== null && filelist.length > 0) {
        submitBank(filelist, filelist1);
      } else {
        if (
          users.getUserRegistrationDetails.bank_details[0] !== undefined &&
          users.getUserRegistrationDetails.bank_details[0].is_nominee == false
        ) {
          submitBank(
            JSON.parse(
              users.getUserRegistrationDetails.bank_details[0]
                .bank_upload_files_xml
            ),
            filelist1
          );
        } else if (
          users.getUserRegistrationDetails.bank_details[1] !== undefined &&
          users.getUserRegistrationDetails.bank_details[1].is_nominee == false
        ) {
          submitBank(
            JSON.parse(
              users.getUserRegistrationDetails.bank_details[1]
                .bank_upload_files_xml
            ),
            filelist1
          );
        }
        // else{
        //     submitBank(
        //         JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml),
        //         filelist1
        //         )
        // }

        // submitBank(

        //     , filelist1)
      }

      // }
    }
  }, [passbookuploadNomineeresults]);

  const nextButtonPressed = () => {
    try {
      if (
        users.bank_details.ifscCode === "" ||
        users.bank_details.accountNumber === "" ||
        users.bank_details.bankName === "" ||
        users.bank_details.bankBranch === "" ||
        users.bank_details.bankAddress === "" ||
        users.bank_details.accountNumberError === true ||
        users.bank_details.bankNameError === true ||
        users.bank_details.bankBranchError === true
        // ||
        // sessionStorage.getItem('passbook') === null
        // users.bank_details.passbookUploadResponse.fileId === ""
      ) {
        // console.log("users.bank_details.membersList.firstname=" + users.bank_details.membersList[i]["firstname" + i])
        showToast("WARN", "Please Fill All the Required Fields to Continue!");
      } else if (
        users.bank_details.nomineeCheck &&
        (users.bank_details.ifscCode_nominee === "" ||
          users.bank_details.accountNumber_nominee === "" ||
          users.bank_details.bankName_nominee === "" ||
          users.bank_details.bankBranch_nominee === "" ||
          users.bank_details.bankAddress_nominee === "" ||
          users.bank_details.accountNumberNomineeError === true ||
          users.bank_details.bankNameNomineeError === true ||
          users.bank_details.bankBranchNomineeError === true)
        // ||
        // sessionStorage.getItem('passbook_nominee') === null
        // users.bank_details.passbookUploadResponse_nominee.fileId === ""
      ) {
        showToast("WARN", "Please Fill All the Required Fields to Continue!");
      } else {
        if (selectedfiles !== null && selectedfiles.length > 0) {
          uploadPassbook();
        } else if (
          selectedfilesNominee !== null &&
          selectedfilesNominee.length > 0
        ) {
          uploadPassbookNominee();
        } else {
          console.log("selectedfilesNominee1", selectedfilesNominee);
          if (
            (selectedfilesNominee !== null &&
              selectedfilesNominee.length > 0 &&
              selectedfilesNominee !== {}) ||
            (users.getUserRegistrationDetails.bank_details[0]
              .bank_upload_files_xml !== null &&
              !removedFile)
          ) {
            console.log("inside selectedfiles");
            if (
              users.getUserRegistrationDetails.bank_details[0] !== undefined &&
              users.getUserRegistrationDetails.bank_details[0].is_nominee
            ) {
              submitBank(
                JSON.parse(
                  users.getUserRegistrationDetails.bank_details[1]
                    .bank_upload_files_xml
                ),
                JSON.parse(
                  users.getUserRegistrationDetails.bank_details[0]
                    .bank_upload_files_xml
                )
              );
            } else if (
              users.getUserRegistrationDetails.bank_details[1] !== undefined &&
              users.getUserRegistrationDetails.bank_details[1].is_nominee
            ) {
              submitBank(
                JSON.parse(
                  users.getUserRegistrationDetails.bank_details[0]
                    .bank_upload_files_xml
                ),
                JSON.parse(
                  users.getUserRegistrationDetails.bank_details[1]
                    .bank_upload_files_xml
                )
              );
            } else {
              submitBank(
                JSON.parse(
                  users.getUserRegistrationDetails.bank_details[0]
                    .bank_upload_files_xml
                ),
                null
              );
            }
          } else {
            console.log("in passbook doc");

            showToast("ERROR", "Please add the Passbook document");
          }
        }

        // uploadPassbook()
      }
    } catch (err) {
      showToast("ERROR", "Please fill all the mandatory fields");
      console.error("in catch-> Error:", err);
    }
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const uploadPassbook = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setpassbookuploadresults(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('passbook') !== null && users.user !== null){
    // let formdata = {
    //     'file': sessionStorage.getItem('passbook'),
    //     'userId': users.user.id,
    //     'fileType': 'passbook'
    // }
    //     console.error("formdata passbook: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"passbook"))
    // }
  };
  const uploadPassbookNominee = () => {
    let promises = [];

    selectedfilesNominee.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfilesNominee.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setpassbookuploadNomineeresults(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('passbook_nominee') !== null && users.user !== null){
    //     let formdata = {
    //         'file': sessionStorage.getItem('passbook_nominee'),
    //         'userId': users.user.id,
    //         'fileType': 'passbook_nominee'
    //     }
    //     console.error("formdata passbook_nominee: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"passbook_nominee"))
    // }
  };

  // const submitBank = (passbookUploadResponse, passbookUploadResponse_nominee) => {

  //     if(passbookUploadResponse === null) {
  //         passbookUploadResponse = allValues.passbookUploadResponse
  //     }

  //     let dataToSubmit = {
  //         "user_id": users.user.id,
  //         "banks": [
  //             {
  //                 "labour_user_id": users.user.id,
  //                 "nominee_for_user_id": null,
  //                 "nominee_first_name": null,
  //                 "nominee_middle_name": null,
  //                 "nominee_last_name": null,
  //                 "ifsc_code": users.bank_details.ifscCode,
  //                 "account_no": users.bank_details.accountNumber,
  //                 "bank_name": users.bank_details.bankName,
  //                 "bank_branch": users.bank_details.bankBranch,
  //                 "bank_address": users.bank_details.bankAddress,
  //                 // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
  //                 "bank_passbook_upload_id": passbookUploadResponse.fileId,
  //                 "files": [{
  //                     "file_id": "-",
  //                     "file_name": "-"
  //                 }],
  //                 "is_nominee": 0,
  //                 "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //             },
  //         ]
  //     }

  //     if(users.bank_details.nomineeCheck && passbookUploadResponse_nominee !== null ){

  //         let bank_nominee = {
  //             "labour_user_id": users.user.id,
  //             "nominee_for_user_id": null,
  //             "nominee_first_name": null,
  //             "nominee_middle_name": null,
  //             "nominee_last_name": null,
  //             "ifsc_code": users.bank_details.ifscCode_nominee,
  //             "account_no": users.bank_details.accountNumber_nominee,
  //             "bank_name": users.bank_details.bankName_nominee,
  //             "bank_branch": users.bank_details.bankBranch_nominee,
  //             "bank_address": users.bank_details.bankAddress_nominee,
  //             // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse_nominee.fileId,
  //             "bank_passbook_upload_id": passbookUploadResponse_nominee.fileId,
  //             "files": [{
  //                 "file_id": "-",
  //                 "file_name": "-"
  //             }],
  //             "is_nominee": 1,
  //             "is_verified": users.is_bank_verified_nominee ? 1 : 0
  //         }

  //         dataToSubmit.banks.push(bank_nominee)
  //     }

  //     console.error("dataToSubmit", JSON.stringify(dataToSubmit))

  //     if(users.getUserRegistrationDetails.bank_details === []){
  //         dispatch(addLabourBankDetails(dataToSubmit))
  //     }
  //     else{
  //         dispatch(addLabourBankDetails(dataToSubmit, "update"))
  //     }
  // }

  const submitBank = (
    passbookUploadResponse,
    passbookUploadResponse_nominee
  ) => {
    // if(passbookUploadResponse === null) {
    //     passbookUploadResponse = allValues.passbookUploadResponse
    // }

    let dataToSubmit = {
      user_id: users.user.id,
      banks: [
        {
          labour_user_id: users.user.id,
          nominee_for_user_id: null,
          nominee_first_name: null,
          nominee_middle_name: null,
          nominee_last_name: null,
          ifsc_code: users.bank_details.ifscCode,
          account_no: users.bank_details.accountNumber,
          bank_name: users.bank_details.bankName,
          bank_branch: users.bank_details.bankBranch,
          bank_address: users.bank_details.bankAddress,
          // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse.fileId,
          bank_passbook_upload_id: "-",
          // "files": [{
          //     "file_id": "-",
          //     "file_name": "-"
          // }],
          files: passbookUploadResponse,
          is_nominee: 0,
          is_verified: users.is_bank_verified_nominee ? 1 : 0,
        },
      ],
    };

    if (
      users.bank_details.nomineeCheck &&
      passbookUploadResponse_nominee !== null
    ) {
      let bank_nominee = {
        labour_user_id: users.user.id,
        nominee_for_user_id: null,
        nominee_first_name: null,
        nominee_middle_name: null,
        nominee_last_name: null,
        ifsc_code: users.bank_details.ifscCode_nominee,
        account_no: users.bank_details.accountNumber_nominee,
        bank_name: users.bank_details.bankName_nominee,
        bank_branch: users.bank_details.bankBranch_nominee,
        bank_address: users.bank_details.bankAddress_nominee,
        // "bank_passbook_upload_id": users.bank_details.passbookUploadResponse_nominee.fileId,
        // "bank_passbook_upload_id": passbookUploadResponse_nominee.fileId,
        bank_passbook_upload_id: "-",
        files: passbookUploadResponse_nominee,
        is_nominee: 1,
        is_verified: users.is_bank_verified_nominee ? 1 : 0,
      };

      dataToSubmit.banks.push(bank_nominee);
    }

    console.error("dataToSubmit", JSON.stringify(dataToSubmit));

    if (users.getUserRegistrationDetails.bank_details === []) {
      dispatch(addLabourBankDetails(dataToSubmit));
    } else {
      dispatch(addLabourBankDetails(dataToSubmit, "update"));
    }
  };

  const init_bank_details = {
    ifscCode: "",
    accountNumber: "",
    bankName: "",
    bankBranch: "",
    bankAddress: "",
    imgfile: "",
    passbookDocumentName: "",
    passbookUploadResponse: "",
    nomineeCheck: false,

    ifscCode_nominee: "",
    accountNumber_nominee: "",
    bankName_nominee: "",
    bankBranch_nominee: "",
    bankAddress_nominee: "",
    imgfile_nominee: "",
    passbookDocumentName_nominee: "",
    passbookUploadResponse_nominee: "",
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.success !== undefined &&
      users.addLabourBankDetails.success !== null &&
      users.addLabourBankDetails.success === true
    ) {
      showToast("SUCCESS", "Bank Details Saved Successfully!");
      // props.nextButtonPressed()
      dispatch(updateBankData(init_bank_details));
      dispatch(addLabourBankDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.success !== undefined &&
      users.addLabourBankDetails.success !== null &&
      users.addLabourBankDetails.success === "false"
    ) {
      showToast("ERROR", "Error Saving Bank Details!");
    } else if (
      users.addLabourBankDetails !== null &&
      users.addLabourBankDetails.error !== undefined &&
      users.addLabourBankDetails.error === true
    ) {
      showToast("ERROR", "Error Saving Bank Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabourBankDetails]);

  const [checkBank_nominee, setcheckBank_nominee] = React.useState(false);
  const [loadingVerifyBank_nominee, setLoadingVerifyBank_nominee] =
    React.useState(false);

  const [checkBank, setcheckBank] = React.useState(false);
  const [loadingVerifyBank, setLoadingVerifyBank] = React.useState(false);

  const verifyBank1 = () => {
    if (
      allValues.accountNumber !== null &&
      allValues.ifscCode !== null &&
      allValues.accountNumber !== "" &&
      allValues.ifscCode !== "" &&
      allValues.accountNumber !== "null" &&
      allValues.ifscCode !== "null"
    ) {
      setLoadingVerifyBank(true);

      var randNum = Math.floor(100000 + Math.random() * 900000);
      console.log("randNum: " + randNum);

      // var data = JSON.stringify({
      //     "transID": randNum,
      //     "docType": "92",
      //     "beneAccNo": allValues.accountNumber,
      //     "ifsc": allValues.ifscCode
      //   });
      var data = JSON.stringify({
        transID: randNum,
        docType: "430",
        accountNumber: allValues.accountNumber,
        ifscCode: allValues.ifscCode,
      });

      var config = {
        method: "post",
        url: SERVER + "/global/encrypt_string",
        headers: {
          "Content-Type": "application/json",
          username: TS_USERNAME,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data, undefined, 2)
          );

          var data2 = JSON.stringify({
            requestData: response.data.data,
          });
          var config2 = {
            method: "post",
            url: SERVER + "/global/auth_bridge_bank_request",
            headers: {
              "Content-Type": "application/json",
              username: TS_USERNAME,
            },
            data: data2,
          };

          axios(config2)
            .then(function (response2) {
              console.log(
                "bank step2 response: " +
                  JSON.stringify(response2.data, undefined, 2)
              );

              var data3 = JSON.stringify({
                responseData: response2.data.data.responseData,
              });
              var config3 = {
                method: "post",
                url: SERVER + "/global/decrypt_string",
                headers: {
                  "Content-Type": "application/json",
                  username: TS_USERNAME,
                },
                data: data3,
              };

              axios(config3)
                .then(function (response3) {
                  console.log(
                    "bank step3 response: " +
                      JSON.stringify(response3.data, undefined, 2)
                  );

                  // if(response3.data.data.msg.description === "Success" && response3.data.data.msg.status === "Verified" ){
                  if (response3.data.data.status === 1) {
                    showToast("SUCCESS", "Bank Account Verified Successfully");
                    setLoadingVerifyBank(false);
                    setcheckBank(true);
                    // setAllValues({
                    //     ...allValues,

                    // })
                    setAllValues({
                      ...allValues,
                      bankFetchedData: response3.data.data.msg,
                      is_bank_verified: true,
                      bankName: response3.data.data.msg["Bank Name"],
                      bankBranch:
                        response3.data.data.msg["Bank Branch - Address"].Branch,
                      bankAddress:
                        response3.data.data.msg["Bank Branch - Address"]
                          .Address,
                    });
                  } else {
                    console.error("bank step3 fail");
                    // saveLog(
                    //     allValues.getUserDetails.personal_details[0].mobile_no,
                    //     allValues.getUserDetails.personal_details[0].first_name,
                    //     allValues.getUserDetails.personal_details[0].id,
                    //     response3.data.data.msg
                    //     )
                    setLoadingVerifyBank(false);
                    showToast(
                      "ERROR",
                      "Bank Verification Failed:  " +
                        response3.data.data.msg.status
                    );
                  }
                })
                .catch(function (error3) {
                  console.error("bank step3 error: " + error3);
                  // saveLog(
                  //     allValues.getUserDetails.personal_details[0].mobile_no,
                  //     allValues.getUserDetails.personal_details[0].first_name,
                  //     allValues.getUserDetails.personal_details[0].id,
                  //     error3
                  //     )
                  setLoadingVerifyBank(false);
                  showToast(
                    "ERROR",
                    "Some error occurred while verifying bank details!"
                  );
                });
            })
            .catch(function (error2) {
              console.error("bank step2 error: " + error2);
              // saveLog(
              //     allValues.getUserDetails.personal_details[0].mobile_no,
              //     allValues.getUserDetails.personal_details[0].first_name,
              //     allValues.getUserDetails.personal_details[0].id,
              //     error2
              //     )
              setLoadingVerifyBank(false);
              showToast(
                "ERROR",
                "Some error occurred while verifying bank details!"
              );
            });
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          // saveLog(
          //     allValues.getUserDetails.personal_details[0].mobile_no,
          //     allValues.getUserDetails.personal_details[0].first_name,
          //     allValues.getUserDetails.personal_details[0].id,
          //     error
          //     )
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Some error occurred while verifying bank details!"
          );
        });
    } else {
      showToast("ERROR", "Please fill Correct Bank Account Details !");
    }
  };

  const verifyBank = () => {
    if (
      allValues.ifscCode !== null &&
      allValues.ifscCode !== "" &&
      allValues.ifscCode !== "null"
    ) {
      setLoadingVerifyBank(true);

      var config = {
        method: "get",
        url: SERVER + "/global/get_ifsc_details?ifsc=" + allValues.ifscCode,
        headers: {
          "Content-Type": "application/json",
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          if (
            response.data.success === true &&
            response.data.data !== undefined &&
            response.data.data.BANK !== undefined
          ) {
            showToast("SUCCESS", "Bank Account Details Fetched Successfully");
            setLoadingVerifyBank(false);
            setcheckBank(true);

            setAllValues({
              ...allValues,
              bankFetchedData: response.data.data,
              is_bank_verified: true,
              bankName: response.data.data.BANK,
              bankBranch: response.data.data.BRANCH,
              bankAddress: response.data.data.ADDRESS,
            });
          } else {
            setLoadingVerifyBank(false);
            showToast("ERROR", "Bank Details Fetching Failed!");
            // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
          }
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          console.error(
            "bank step1 error.response: " +
              JSON.stringify(error.response, undefined, 2)
          );
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Bank Details Fetching Failed:  " + error.response.data.errors.msg
          );
          // showToast("ERROR", "Some error occurred while verifying bank details!")
        });
    } else {
      showToast("ERROR", "Please Fill IFSC Code!");
    }
  };

  const verifyBankNominee1 = () => {
    if (
      allValues.accountNumber_nominee !== null &&
      allValues.ifscCode_nominee !== null &&
      allValues.accountNumber_nominee !== "" &&
      allValues.ifscCode_nominee !== "" &&
      allValues.accountNumber_nominee !== "null" &&
      allValues.ifscCode_nominee !== "null"
    ) {
      setLoadingVerifyBank(true);

      var randNum = Math.floor(100000 + Math.random() * 900000);
      console.log("randNum: " + randNum);

      // var data = JSON.stringify({
      //     "transID": randNum,
      //     "docType": "92",
      //     "beneAccNo": allValues.accountNumber_nominee,
      //     "ifsc": allValues.ifscCode_nominee
      //   });
      var data = JSON.stringify({
        transID: randNum,
        docType: "430",
        accountNumber: allValues.accountNumber,
        ifscCode: allValues.ifscCode,
      });

      var config = {
        method: "post",
        url: SERVER + "/global/encrypt_string",
        headers: {
          "Content-Type": "application/json",
          username: TS_USERNAME,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data, undefined, 2)
          );

          var data2 = JSON.stringify({
            requestData: response.data.data,
          });
          var config2 = {
            method: "post",
            url: SERVER + "/global/auth_bridge_bank_request",
            headers: {
              "Content-Type": "application/json",
              username: TS_USERNAME,
            },
            data: data2,
          };

          axios(config2)
            .then(function (response2) {
              console.log(
                "bank step2 response: " +
                  JSON.stringify(response2.data, undefined, 2)
              );

              var data3 = JSON.stringify({
                responseData: response2.data.data.responseData,
              });
              var config3 = {
                method: "post",
                url: SERVER + "/global/decrypt_string",
                headers: {
                  "Content-Type": "application/json",
                  username: TS_USERNAME,
                },
                data: data3,
              };

              axios(config3)
                .then(function (response3) {
                  console.log(
                    "bank step3 response: " +
                      JSON.stringify(response3.data, undefined, 2)
                  );

                  // if(response3.data.data.msg.description === "Success" && response3.data.data.msg.status === "Verified" ){
                  //     showToast("SUCCESS", "Bank Account Verified Successfully")
                  //     setLoadingVerifyBank_nominee(false)
                  //     setcheckBank_nominee(true)
                  //     // setAllValues({
                  //     //     ...allValues,

                  //     // })
                  //     setAllValues({
                  //         ...allValues,
                  //         bankFetchedData: response3.data.data.msg,
                  //         is_bank_verified_nominee: true
                  //     })
                  // }
                  if (response3.data.data.status === 1) {
                    showToast("SUCCESS", "Bank Account Verified Successfully");
                    setLoadingVerifyBank(false);
                    setcheckBank(true);
                    // setAllValues({
                    //     ...allValues,

                    // })
                    setAllValues({
                      ...allValues,
                      bankFetchedData: response3.data.data.msg,
                      is_bank_verified_nominee: true,
                      bankName_nominee: response3.data.data.msg["Bank Name"],
                      bankBranch_nominee:
                        response3.data.data.msg["Bank Branch - Address"].Branch,
                      bankAddress_nominee:
                        response3.data.data.msg["Bank Branch - Address"]
                          .Address,
                    });
                  } else {
                    console.error("bank step3 fail");
                    // saveLog(
                    //     allValues.getUserDetails.personal_details[0].mobile_no,
                    //     allValues.getUserDetails.personal_details[0].first_name,
                    //     allValues.getUserDetails.personal_details[0].id,
                    //     response3.data.data.msg
                    //     )
                    setLoadingVerifyBank_nominee(false);
                    showToast(
                      "ERROR",
                      "Bank Verification Failed:  " +
                        response3.data.data.msg.status
                    );
                  }
                })
                .catch(function (error3) {
                  console.error("bank step3 error: " + error3);
                  // saveLog(
                  //     allValues.getUserDetails.personal_details[0].mobile_no,
                  //     allValues.getUserDetails.personal_details[0].first_name,
                  //     allValues.getUserDetails.personal_details[0].id,
                  //     error3
                  //     )
                  setLoadingVerifyBank_nominee(false);
                  showToast(
                    "ERROR",
                    "Some error occurred while verifying bank details!"
                  );
                });
            })
            .catch(function (error2) {
              console.error("bank step2 error: " + error2);
              // saveLog(
              //     allValues.getUserDetails.personal_details[0].mobile_no,
              //     allValues.getUserDetails.personal_details[0].first_name,
              //     allValues.getUserDetails.personal_details[0].id,
              //     error2
              //     )
              setLoadingVerifyBank_nominee(false);
              showToast(
                "ERROR",
                "Some error occurred while verifying bank details!"
              );
            });
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          // saveLog(
          //     allValues.getUserDetails.personal_details[0].mobile_no,
          //     allValues.getUserDetails.personal_details[0].first_name,
          //     allValues.getUserDetails.personal_details[0].id,
          //     error
          //     )
          setLoadingVerifyBank_nominee(false);
          showToast(
            "ERROR",
            "Some error occurred while verifying bank details!"
          );
        });
    } else {
      showToast("ERROR", "Please fill Correct Bank Account Details !");
    }
  };

  const verifyBankNominee = () => {
    if (
      allValues.ifscCode_nominee !== null &&
      allValues.ifscCode_nominee !== "" &&
      allValues.ifscCode_nominee !== "null"
    ) {
      setLoadingVerifyBank_nominee(true);

      var config = {
        method: "get",
        url:
          SERVER +
          "/global/get_ifsc_details?ifsc=" +
          allValues.ifscCode_nominee,
        headers: {
          "Content-Type": "application/json",
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          if (
            response.data.success === true &&
            response.data.data !== undefined &&
            response.data.data.BANK !== undefined
          ) {
            showToast("SUCCESS", "Bank Account Details Fetched Successfully");
            setLoadingVerifyBank_nominee(false);
            setcheckBank_nominee(true);

            setAllValues({
              ...allValues,
              bankFetchedData_nominee: response.data.data,
              is_bank_verified_nominee: true,
              bankName_nominee: response.data.data.BANK,
              bankBranch_nominee: response.data.data.BRANCH,
              bankAddress_nominee: response.data.data.ADDRESS,
            });
          } else {
            setLoadingVerifyBank_nominee(false);
            showToast("ERROR", "Bank Details Fetching Failed!");
            // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
          }
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          console.error(
            "bank step1 error.response: " +
              JSON.stringify(error.response, undefined, 2)
          );
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Bank Details Fetching Failed:  " + error.response.data.errors.msg
          );
          // showToast("ERROR", "Some error occurred while verifying bank details!")
        });
    } else {
      showToast("ERROR", "Please Fill IFSC Code!");
    }
  };

  const removefile = (index) => {
    setloadingfiles(true);
    setRemovedFile(true);
    console.log("removefile index: " + index);
    // selectedfiles.splice(1,index)
    selectedfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };
  const removefileNominee = (index) => {
    setloadingfiles(true);
    console.log("removefileNominee index: " + index);
    // selectedfiles.splice(1,index)
    selectedfilesNominee.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  return (
    <>
      <Row className='m-0'>
        <Row className='bank-title-row'>
          <Col xs={12} className='profile-title'>
            {/* <h1>Bank Passbook / Statement</h1> */}
            <h1>
              <Translate value='bankPassbookStatement' />
            </h1>
          </Col>
        </Row>

        <Row className='form-row'>
          <Col xs={12} md={6} className='bank-col-1'>
            <Row className='form-inner-card-right'>
              <Col xs={12}>
                {/* <p className="mt-3 mb-2">Account Number*</p> */}
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='accountNumber' />}
                />
                <FormControl fullWidth>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Account Number'
                    name='accountNumber'
                    value={allValues.accountNumber}
                    onChange={changeHandler}
                    error={allValues.accountNumberError}
                    helperText={
                      allValues.accountNumberError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>

              <Col xs={12}>
                {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                <Required
                  className='mt-4 mb-2'
                  title={<Translate value='enterYourBankName' />}
                />
                <FormControl
                  fullWidth
                  className='formcontrol1'
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    }
                  }}
                >
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Bank Name'
                    name='bankName'
                    value={allValues.bankName}
                    onChange={changeHandler}
                    error={allValues.bankNameError}
                    helperText={
                      allValues.bankNameError
                        ? "Please enter in correct format"
                        : ""
                    }
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className='bank-col-2'>
            <Row className='form-inner-card-right'>
              <Col xs={8} md={10}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourIFSCcode' />}
                />
                <FormControl
                  fullWidth
                  className='formcontrol1'
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    }
                  }}
                >
                  <TextField
                    variant='outlined'
                    placeholder="Enter Your Bank's IFSC Code"
                    name='ifscCode'
                    value={allValues.ifscCode}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col xs={4} md={2}>
                <div className='checkDiv mt-3'>
                  {checkBank ? (
                    <>
                      {/* <Tooltip title="Bank Account is Verified !"> */}
                      <img
                        alt='...'
                        src={checkgreen}
                        style={{ marginTop: "30px" }}
                      />
                      {/* </Tooltip> */}
                    </>
                  ) : (
                    <Button
                      variant='outline-primary'
                      disabled={loadingVerifyBank}
                      onClick={verifyBank}
                      style={{ marginTop: "20px" }}
                    >
                      {loadingVerifyBank ? (
                        <>
                          <Translate value='fetching' />
                        </>
                      ) : (
                        <>
                          <Translate value='fetchDetails' />
                        </>
                      )}
                    </Button>
                  )}
                  {loadingVerifyBank && (
                    <CircularProgress
                      size={24}
                      style={{ marginTop: "30px" }}
                      className='buttonProgress'
                    />
                  )}
                </div>
              </Col>

              <Col xs={12}>
                {/* <Col xs={6} md={12}>
                                {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                <Required
                  className='mt-4 mb-2'
                  title={<Translate value='enterYourBankBranch' />}
                />
                <FormControl fullWidth>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Bank Branch'
                    name='bankBranch'
                    value={allValues.bankBranch}
                    onChange={changeHandler}
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className='bank-col-3'>
            <div
              className='form-inner-card-right row'
              style={{ marginLeft: "4px", marginRight: "2px" }}
            >
              {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
              <Required
                className='mt-0 mb-2'
                title={<Translate value='enterYourBankAddress' />}
              />
              <FormControl
                fullWidth
                className='formcontrol1'
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleEnter();
                  }
                }}
              >
                <TextareaAutosize
                  variant='outlined'
                  multiline
                  rows={3}
                  // rowsMax={4}
                  // defaultValue="Default Value"
                  placeholder='Enter Your Bank Address'
                  name='bankAddress'
                  value={allValues.bankAddress}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={12} md={6} className='bank-col-4'>
            <div
              className=' mt-4'
              style={{ marginLeft: "22px", marginRight: "40px" }}
            >
              {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
              <Required
                className='mt-4 mb-2'
                title={<Translate value='uploadYourPassbookDocument' />}
              />

              <div className='browsebutton-outerdiv'>
                <div className='browsebutton2-div filesNames'>
                  {loadingfiles &&
                  users.getUserRegistrationDetails.bank_details[0] !==
                    undefined &&
                  users.getUserRegistrationDetails.bank_details[0] !== null &&
                  users.getUserRegistrationDetails.bank_details[0]
                    .bank_upload_files_xml !== undefined &&
                  users.getUserRegistrationDetails.bank_details[0]
                    .bank_upload_files_xml !== null &&
                  users.getUserRegistrationDetails.bank_details[0]
                    .is_nominee === false &&
                  filesxmlPresent
                    ? Array.from(
                        JSON.parse(
                          users.getUserRegistrationDetails.bank_details[0]
                            .bank_upload_files_xml
                        )
                      ).map((i, index) => (
                        <FormControl>
                          <TextField
                            variant='outlined'
                            name='passbookDocumentName'
                            value={i.file_name}
                            disabled
                          />
                        </FormControl>
                      ))
                    : null}

                  {loadingfiles &&
                  users.getUserRegistrationDetails.bank_details[1] !==
                    undefined &&
                  users.getUserRegistrationDetails.bank_details[1] !== null &&
                  users.getUserRegistrationDetails.bank_details[1]
                    .bank_upload_files_xml !== undefined &&
                  users.getUserRegistrationDetails.bank_details[1]
                    .bank_upload_files_xml !== null &&
                  users.getUserRegistrationDetails.bank_details[1]
                    .is_nominee === false &&
                  filesxmlPresent
                    ? Array.from(
                        JSON.parse(
                          users.getUserRegistrationDetails.bank_details[1]
                            .bank_upload_files_xml
                        )
                      ).map((i, index) => (
                        <FormControl>
                          <TextField
                            variant='outlined'
                            name='passbookDocumentName'
                            value={i.file_name}
                            disabled
                          />
                        </FormControl>
                      ))
                    : null}

                  {loadingfiles ? (
                    <></>
                  ) : (
                    selectedfiles !== null &&
                    selectedfiles.length > 0 &&
                    Array.from(selectedfiles).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='passbookDocumentName'
                          value={i.name}
                          onChange={handleChange}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  alt='...'
                                  src={close}
                                  className='removefilebtn'
                                  onClick={() => removefile(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))
                  )}
                </div>
                <div className='browsebutton2-div'>
                  <label htmlFor='upload-photo' className='browse-button btn2'>
                    <img alt='...' src={folder} /> <Translate value='browse' />
                  </label>
                  <input
                    type='file'
                    name='photo'
                    id='upload-photo'
                    onChange={handleFileChange}
                    multiple
                  />
                </div>
              </div>

              <p className='mt-3 instructions2'>
                {/* <span><Translate value="instructions"/>*</span><br />
                                Format supporting document should be in JPEG, PNG, or PDF<br />
                                (Size contains 500 KB to 2 MB) */}
                <span>
                  <Translate value='instructions' />*
                </span>
                <br />
                <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                <br />
                <Translate value='sizecontains500KBto2MB' />
              </p>
            </div>
          </Col>
          <Col xs={12} className='note2 mt-4 ml-3 bank-col-5'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allValues.nomineeCheck}
                  onChange={handleChangeCheckbox}
                  name='nomineeCheck'
                  // color="primary"
                />
              }
              label={<Translate value='clickheretoaddtheNomineeBankDetails' />}
            />
          </Col>
        </Row>

        {allValues.nomineeCheck ? (
          <>
            <Row className='bank-title-row'>
              <Col xs={12} className='profile-title'>
                {/* <h1>Nominee's Bank Passbook / Statement</h1> */}
                <h1>
                  <Translate value='nomineesBankPassbookStatement' />
                </h1>
              </Col>
            </Row>
            <Row className='form-row'>
              <Col xs={12} md={6} className='bank-col-1'>
                <Row className='form-inner-card-right'>
                  <Col xs={12}>
                    {/* <p className="mt-3 mb-2">Account Number*</p> */}
                    <Required
                      className='mt-3 mb-2'
                      title={<Translate value='accountNumber' />}
                    />
                    <FormControl fullWidth>
                      <TextField
                        variant='outlined'
                        placeholder='Enter Your Account Number'
                        name='accountNumber_nominee'
                        value={allValues.accountNumber_nominee}
                        onChange={changeHandler}
                        error={allValues.accountNumberNomineeError}
                        helperText={
                          allValues.accountNumberNomineeError
                            ? "Please enter in correct format"
                            : ""
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12}>
                    {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                    <Required
                      className='mt-4 mb-2'
                      title={<Translate value='enterYourBankName' />}
                    />
                    <FormControl
                      fullWidth
                      className='formcontrol1'
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleEnter();
                        }
                      }}
                    >
                      <TextField
                        variant='outlined'
                        placeholder='Enter your Bank Name'
                        name='bankName_nominee'
                        value={allValues.bankName_nominee}
                        onChange={changeHandler}
                        error={allValues.bankNameNomineeError}
                        helperText={
                          allValues.bankNameNomineeError
                            ? "Please enter in correct format"
                            : ""
                        }
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className='bank-col-2'>
                <Row className='form-inner-card-right'>
                  <Col xs={8} md={10}>
                    {/* <p className="mt-3 mb-2">IFSC Code*</p> */}
                    <Required
                      className='mt-3 mb-2'
                      title={<Translate value='enterYourIFSCcode' />}
                    />
                    <FormControl
                      fullWidth
                      className='formcontrol1'
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleEnter();
                        }
                      }}
                    >
                      <TextField
                        variant='outlined'
                        placeholder="Enter Your Bank's IFSC Code"
                        name='ifscCode_nominee'
                        value={allValues.ifscCode_nominee}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={4} md={2}>
                    <div className='checkDiv mt-3'>
                      {checkBank_nominee ? (
                        <>
                          {/* <Tooltip title="Bank Account is Verified !"> */}
                          <img
                            alt='...'
                            src={checkgreen}
                            style={{ marginTop: "30px" }}
                          />
                          {/* </Tooltip> */}
                        </>
                      ) : (
                        <Button
                          variant='outline-primary'
                          disabled={loadingVerifyBank_nominee}
                          onClick={verifyBankNominee}
                          style={{ marginTop: "20px" }}
                        >
                          {loadingVerifyBank_nominee ? (
                            <>Fetching...</>
                          ) : (
                            <>Fetch Details</>
                          )}
                        </Button>
                      )}
                      {loadingVerifyBank_nominee && (
                        <CircularProgress
                          size={24}
                          style={{ marginTop: "30px" }}
                          className='buttonProgress'
                        />
                      )}
                    </div>
                  </Col>

                  <Col xs={12}>
                    {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                    <Required
                      className='mt-4 mb-2'
                      title={<Translate value='enterYourBankBranch' />}
                    />
                    <FormControl fullWidth>
                      <TextField
                        variant='outlined'
                        placeholder='Enter Your Bank Branch'
                        name='bankBranch_nominee'
                        value={allValues.bankBranch_nominee}
                        onChange={changeHandler}
                        // error={allValues.bankBranchNomineeError}
                        // helperText={allValues.bankBranchNomineeError ? "Please enter in correct format" : ""}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className='bank-col-3'>
                <div
                  className='form-inner-card-right row'
                  style={{ marginLeft: "5px" }}
                >
                  {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                  <Required
                    className='mt-0 mb-2'
                    title={<Translate value='enterYourBankAddress' />}
                  />
                  <FormControl
                    fullWidth
                    className='formcontrol1'
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleEnter();
                      }
                    }}
                  >
                    <TextareaAutosize
                      variant='outlined'
                      multiline
                      rows={3}
                      // rowsMax={4}
                      // defaultValue="Default Value"
                      placeholder='Enter Your Bank Address'
                      name='bankAddress_nominee'
                      value={allValues.bankAddress_nominee}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </Col>
              <Col xs={12} md={6} className='bank-col-4'>
                <div className='form-inner-card-right mt-4'>
                  <Required
                    className='mt-4 mb-2'
                    title={<Translate value='uploadYourPassbookDocument' />}
                  />

                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles &&
                      users.getUserRegistrationDetails.bank_details[0] !==
                        undefined &&
                      users.getUserRegistrationDetails.bank_details[0] !==
                        null &&
                      users.getUserRegistrationDetails.bank_details[0]
                        .bank_upload_files_xml !== undefined &&
                      users.getUserRegistrationDetails.bank_details[0]
                        .bank_upload_files_xml !== null &&
                      users.getUserRegistrationDetails.bank_details[0]
                        .is_nominee === true &&
                      filesxmlPresentNominee
                        ? Array.from(
                            JSON.parse(
                              users.getUserRegistrationDetails.bank_details[0]
                                .bank_upload_files_xml
                            )
                          ).map((i, index) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='passbookDocumentName'
                                value={i.file_name}
                                disabled
                              />
                            </FormControl>
                          ))
                        : null}

                      {loadingfiles &&
                      users.getUserRegistrationDetails.bank_details[1] !==
                        undefined &&
                      users.getUserRegistrationDetails.bank_details[1] !==
                        null &&
                      users.getUserRegistrationDetails.bank_details[1]
                        .bank_upload_files_xml !== undefined &&
                      users.getUserRegistrationDetails.bank_details[1]
                        .bank_upload_files_xml !== null &&
                      users.getUserRegistrationDetails.bank_details[1]
                        .is_nominee === true &&
                      filesxmlPresentNominee
                        ? Array.from(
                            JSON.parse(
                              users.getUserRegistrationDetails.bank_details[1]
                                .bank_upload_files_xml
                            )
                          ).map((i, index) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='passbookDocumentName'
                                value={i.file_name}
                                disabled
                              />
                            </FormControl>
                          ))
                        : null}

                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedfilesNominee !== null &&
                        selectedfilesNominee.length > 0 &&
                        Array.from(selectedfilesNominee).map((i, index) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='passbookDocumentName'
                              value={i.name}
                              onChange={handleChange}
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      alt='...'
                                      src={close}
                                      className='removefilebtn'
                                      onClick={() => removefileNominee(index)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))
                      )}
                    </div>
                    <div className='browsebutton2-div'>
                      <label
                        htmlFor='upload-photo2'
                        className='browse-button btn2'
                      >
                        <img alt='...' src={folder} />{" "}
                        <Translate value='browse' />
                      </label>
                      <input
                        type='file'
                        name='photo'
                        id='upload-photo2'
                        onChange={handleFileChange_nominee}
                        multiple
                      />
                    </div>
                  </div>

                  {/* <div className="browsebutton2-div">
                                            <FormControl >
                                                <TextField
                                                    variant="outlined"
                                                    name="passbookDocumentName_nominee"
                                                    value={allValues.passbookDocumentName_nominee}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </FormControl>
                                            <label
                                                htmlFor="upload-photo2"
                                                className="browse-button btn2">
                                                <img alt="..." src={folder} /> <Translate value="browse"/>
                                            </label>
                                            <input type="file" name="photo" id="upload-photo2" onChange={handleFileChange_nominee} />
                                        </div> */}

                  <p className='mt-3 instructions2'>
                    <span>
                      <Translate value='instructions' />*
                    </span>
                    <br />
                    <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                    <br />
                    <Translate value='sizecontains500KBto2MB' />
                  </p>
                </div>
              </Col>
            </Row>
          </>
        ) : null}

        <Row className='form-row'>
          <Col xs={12} className='note2 text-center mt-4 bank-col-6'>
            <p>
              <span>
                <Translate value='note' />
              </span>
              <Translate value='noteAftercompletion' />
            </p>
          </Col>
        </Row>

        {/* <Row className="button-inside-form-row mb-5">
                    <Col xs={12} className="next-back-button-row mt-4 ">
                        <Link to="#" onClick={props.backButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="danger" className="back-button" >
                                Back
                            </Button>
                        </Link>
                        <Link to="#" onClick={nextButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Next
                        </Button>
                        </Link>
                    </Col>
                </Row> */}

        <Row className='button-inside-form-row mb-5'>
          <Col xs={12} className='next-back-button-row mt-4 '>
            {!users.profile_details.editmode ? (
              <>
                <Link
                  to='#'
                  onClick={props.backButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='danger' className='back-button'>
                    <Translate value='back' />
                  </Button>
                </Link>
                <Link
                  to='#'
                  onClick={nextButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  <Button variant='outline-primary' className='next-button'>
                    <Translate value='saveandContinue' />
                  </Button>
                </Link>
              </>
            ) : (
              <Link
                to='#'
                onClick={nextButtonPressed}
                style={{ flexGrow: "0.5" }}
              >
                <Button variant='outline-primary' className='next-button'>
                  <Translate value='saveyourDetails' />
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Bank;
