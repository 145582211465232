import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
export const insertUnion = async (payLoad) => {
  try {
    const { data } = await axios.put(`${SERVER}/config/union`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUnion = async (payLoad) => {
  try {
    const { data } = await axios.patch(`${SERVER}/config/union`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
