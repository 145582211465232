import React from 'react';
import { Switch, BrowserRouter, Router } from 'react-router-dom';
import Login from 'components/login';
import UnorganizedRegistration from 'components/registration/unorganized-registration';
import Registration from 'components/registration';
import Admin from 'components/adminLogin';
import AdminPortal from 'components/adminPortal';
import SchemeDetails from 'components/adminPortal/schemeDetails';
import Kbocwwb from 'components/adminPortal/kbocwwb';
import Details from 'components/adminPortal/details';
import UnorganizedDetails from 'components/adminPortal/unorganized/unorganizedDetails';
import Dashboard from 'components/adminPortal/dashboard';
import UnorganizedDashboard from 'components/adminPortal/unorganized/dashboard';
import Profile from 'components/profile';
import ProfileMigrant from 'components/profile-migrant';

import UnorganizedLogin from 'components/login/unorganized-login';
import ProfileUnorganized from 'components/profile-unorganized';
import ImageViewPage from 'components/profile/imageviewpage';
import Editprofile from 'components/profile/editprofile';
import DashboardLabour from 'components/profile/dashboardLabour';
import DashboardLabourUnorganized from 'components/profile-unorganized/dashboardLabour';
import MinistryMaster from 'components/configuration/ministryMaster';
import CircleMaster from 'components/mapping/circleMaster';
import CatalogMaster from 'components/configuration/catalogMaster';
import CatalogMasterForm from 'components/configuration/catalogMasterForm';
import CatalogValue from 'components/configuration/catalogValue';
import CatalogValueForm from 'components/configuration/catalogValueForm';

import RoleMaster from 'components/configuration/roleMaster';
import RoleMasterForm from 'components/configuration/roleMasterForm';
import RoleMappingForm from 'components/configuration/roleMappingForm';

import BoardMaster from 'components/configuration/boardMaster';
import BoardMasterForm from 'components/configuration/boardMasterForm';
import MinistryMasterForm from 'components/configuration/ministryMasterForm';
import CircleMasterForm from 'components/mapping/circleMasterForm';
import CircleMapping from 'components/mapping/circleMapping';
import SearchDataForm from 'components/search/searchDataForm';
import Search from 'components/search/searchHome';

import MappingMaster from 'components/mapping/mappingMaster';
import UserRoleMapping from 'components/mapping/userRoleMapping';
import UserRoleMappingForm from 'components/mapping/userRoleMappingForm';
import UserRoleMaster from 'components/mapping/userRoleMaster';
import UserRoleMaster2 from 'components/mapping/userRoleMaster2';
import UserCircleMapping from 'components/mapping/userCircleMapping';

// import CircleMaster from 'components/mapping/circleMaster'
import VillageMappingForm from 'components/mapping/villageMasterForm';
import VillageMapping from 'components/mapping/villageMapping';
import CityMappingForm from 'components/mapping/cityMasterForm';
import CityMapping from 'components/mapping/cityMapping';
import TalukMappingForm from 'components/mapping/talukMasterForm';
import TalukMapping from 'components/mapping/talukMapping';
import DistrictMappingForm from 'components/mapping/districtMasterForm';
import DistrictMapping from 'components/mapping/districtMapping';

import StateMappingForm from 'components/mapping/stateMasterForm';

import StateMapping from 'components/mapping/stateMapping';
import SchemesHome from 'components/schemes/schemesHome';
import UnorganizedSchemesList from 'components/profile-unorganized/unorganizedSchemes';
import SchemeDoc from 'components/schemes/schemeDoc';
import schemeDoc_ContinuationOfPension from 'components/schemes/schemeDoc_ContinuationOfPension';
import schemeForm_ContinuationOfPension from 'components/schemes/schemeForm_ContinuationOfPension';
import schemeDoc_EducationAssistance from 'components/schemes/schemeDoc_EducationAssistance';

import SchemeForm from 'components/schemes/schemeForm';
import RenewalPage from 'components/profile/renewalpage';
import ECardPage from 'components/profile/ecardpage';
import MainLayout from './components/hoc/mainLayout';
import history from './history';
import { Route } from 'react-router-dom';
import schemeStatus from 'components/schemes/schemeStatus';

import PrivacyPolicy from 'components/profile/privacypolicy';
import TermsConditions from 'components/profile/term&conditions';
import EKYC from 'components/global/egov/ekyc_response';
import Header from 'components/mainScreens/Header';
import BoardsHome from 'components/mainScreens/boardsHome';
import schemesHomePage from 'components/mainScreens/schemesHomePage';
import LabourschemesHomePage from 'components/mainScreens/labourboardSchemesHome';
import SocialSecurityBoardHomePage from 'components/mainScreens/socialSecurityBoardHomePage';
import AccidentUnorganizedForm from 'components/profile-unorganized/accidentUnorganizedForm';
import SchemeDetailsUnorganized from 'components/adminPortal/unorganized/schemeDetailsUnorganized';

import Accident from 'components/mainScreens/schemesDetails/accidentScheme';
import Ailments from 'components/mainScreens/schemesDetails/ailmentsScheme';
import ContinuationPension from 'components/mainScreens/schemesDetails/continuePension';
import Delivery from 'components/mainScreens/schemesDetails/delivery';
import DisablePension from 'components/mainScreens/schemesDetails/disablePension';
import ContDisablePension from 'components/mainScreens/schemesDetails/disabilitySchemes';
import Education from 'components/mainScreens/schemesDetails/education';
import Ent from 'components/mainScreens/schemesDetails/ent';
import Funeral from 'components/mainScreens/schemesDetails/funeral';
import IdCard from 'components/mainScreens/schemesDetails/identiycard';
import Ksrtc from 'components/mainScreens/schemesDetails/ksrtc';
import Marriage from 'components/mainScreens/schemesDetails/marriage';
import Lpg from 'components/mainScreens/schemesDetails/lpg';
import Medical from 'components/mainScreens/schemesDetails/medical';
import Pension from 'components/mainScreens/schemesDetails/penson';
import RegistrationScheme from 'components/mainScreens/schemesDetails/registrationscheme';
import Shrama from 'components/mainScreens/schemesDetails/shrama';
import Thayi from 'components/mainScreens/schemesDetails/thayiMaguScheme';
import MedicalCamp from 'components/mainScreens/schemesDetails/medicalCamps';
import Sports from 'components/mainScreens/schemesDetails/sports';
import Ambedkar from 'components/mainScreens/schemesDetails/ambedkar';
import AshaDeepa from 'components/mainScreens/schemesDetails/ashadeepa';
import Migrant from 'components/mainScreens/migrant';
import Accident2 from 'components/mainScreens/schemesDetails/Accidentassistance2';
import Delivery2 from 'components/mainScreens/schemesDetails/deliveryassistance2';
import Education2 from 'components/mainScreens/schemesDetails/educationScheme2';
import Funeral2 from 'components/mainScreens/schemesDetails/funeral2';
import Medical2 from 'components/mainScreens/schemesDetails/medicalAssistance2';
import Transport from 'components/mainScreens/schemesDetails/transport';
import DetailedTransport from 'components/profile-unorganized/detailsTransport';
import YogiManDhan from 'components/mainScreens/schemesDetails/yogimandhan';
import NewPension from 'components/mainScreens/schemesDetails/newpension';

import MigrantRegistration from 'components/registration/migrantRegistration';
import MigrantDashboard from 'components/profile/dashboardMigrant';
import MigrantPersonal from 'components/profile/personalMigrant';

import WelfareMedical from 'components/mainScreens/WelfareSchemeForms/medical';
import Bmtc from 'components/mainScreens/schemesDetails/bmtc';
import PreCoaching from 'components/mainScreens/schemesDetails/precoaching';
import Precoaching from 'components/mainScreens/schemesDetails/precoaching';
import SchemesHomePage from 'components/mainScreens/schemesHomePage';
import WelfareAccident from 'components/mainScreens/WelfareSchemeForms/accident';
import WelfareFuneral from 'components/mainScreens/WelfareSchemeForms/funeral';
import WelfareDelivery from 'components/mainScreens/WelfareSchemeForms/delivery';
import SchemeBeneficiariesWelfare from 'components/mainScreens/WelfareAdminBoard/welfaredashboard';
import SchemeDetailsWelfareMedical from 'components/mainScreens/WelfareAdminBoard/schemeDetailsWelfareMedical';
import SchemeDetailsWelfareAccident from 'components/mainScreens/WelfareAdminBoard/schemeDetailsWelfarelAccident';
import SchemeDetailsWelfareFuneral from 'components/mainScreens/WelfareAdminBoard/schemeDetailsWelfareFuneral';
import SchemeDetailsWelfareDelivery from 'components/mainScreens/WelfareAdminBoard/schemeDetailsWelfareDelivery';
import SchemeDetailsWelfareApproved from 'components/mainScreens/WelfareAdminBoard/schemeMedicalApproved';
import Ashadeepaform from 'components/mainScreens/unorganizedSchemeForms/ashadeepaScheme';
import RegistrationID from 'components/registration/registration-using-id/registration-id';
import RegistrationAddress from 'components/registration/registration-using-id/registration-address';
import AshadeepaformDetails from 'components/adminPortal/unorganized/ashadeepaschemeDetails';
import AshadeepaformApproveDetails from 'components/adminPortal/unorganized/ashadeepaSchemeApproved';
import MobileEKYC from 'components/mobileEkyc/mobile-registration';
import MobileCLick from 'components/mobileEkyc/mobileClick';
import MobileVerified from 'components/mobileEkyc/mobile-verified';
import NEWMISReport from 'components/adminPortal/NEWMISReport';
import UnionMaster from 'components/configuration/UnionMaster';
import UnionMasterForm from 'components/configuration/UnionMasterForm';
import UnionMappingForm from 'components/configuration/unionMappingForm';
import RegistrationProfileDetails from 'components/adminPortal/RegistartionPageDetails';
import Register from 'components/Register';
import Survey from 'components/Survey';
import DashboardRegistration from 'components/adminPortal/Dashboard/Registration';
import NewSchemeForm from 'components/schemes/NewSchemeForm';
import EducationValidationForm from 'components/adminPortal/EducationAssistanceScheme/EducationValidationForm';
import KBOCWWB from 'components/adminPortal/reports';
import AlreadyRegistered from 'components/registration/registration-using-id/AlreadyRegistered';
import EducationStatus from 'components/schemes/EducationStatus';
import NewSchemeForm2 from 'components/schemes/NewSchemeForm/NewSchemeForm2';
import UpdateLabour from 'components/Internal/UpdateLabour';
import PensionForm from 'components/adminPortal/PensionData/PensionForm';
import BacklogFormAccidentDeath from 'components/adminPortal/BackLog/BacklogFormAccidentDeath';
import BacklogFormFuneral from 'components/adminPortal/BackLog/BacklogFormFuneral';
import PensionDetails from 'components/adminPortal/PensionData/PensionDetails';
import PersonalDetails from 'components/profile/NewRegistrationProcess/PersonalDetails';
import AddresDetails from 'components/profile/NewRegistrationProcess/AddresDetails';
import FamilyDetails from 'components/profile/NewRegistrationProcess/FamilyDetails';
import BankDetails from 'components/profile/NewRegistrationProcess/BankDetails';
import NintyDaysDetails from 'components/profile/NewRegistrationProcess/NintyDaysDetails';
import Review from 'components/profile/NewRegistrationProcess/Review';
import CountLabourOfficer from 'components/adminPortal/ReportsDashboard/CountLabourOfficer';
import KBOCWWBSchemeHomePage from 'components/schemes/NewSchemeForm/SchemeHomePage';
import EditProfileV1 from 'components/profile/NewRegistrationProcess/EditProfile';
import Renewals from 'components/profile/NewRenewalProcess/Renewals';
import SchemeSanctionOrder from 'components/adminPortal/PreviewOption/SchemeSanctionOrderAdmin';
import SchemeStatus from 'components/schemes/NewSchemeStatus/SchemeStatus';
import SchemeAcknowledgement from 'components/Documents/SchemeAcknowledgement';
import SchemesSanctionOrder from 'components/Documents/SchemeSanctionOrder';
import SchemesRejectionOrder from 'components/Documents/SchemeRejectionOrder';
import UnionList from 'components/configuration/Union/UnionList/UnionList';
import UnionForm from 'components/configuration/Union/InsertUnion/UnionForm';
import UnionMappingUI from 'components/configuration/Union/UnionMapping/UnionMappingForm';
import SchemesMain from 'components/adminPortal/SchemesAdmin/SchemesMain';
import SevaSindhuRegistration from 'components/SevaSindhu/SevaSindhuRegistration';
import RenewalAcknowledgment from 'components/Documents/RenewalAcknowledgement';
import RegistrationAcknowledgment from 'components/Documents/RegistrationAcknowledgement';
import NomineeUpdateForm from 'components/adminPortal/NomineeNameUpdate/NomineeUpdateForm';
import SevasindhuLabourDetails from 'components/Survey/SevasindhuLabourDetails';
// import EditProfileV2 from 'components/profile/editprofileV2';

const Routes = () => {
  return (
    // <Router history={history}>
    // <BrowserRouter basename={"/"}>
    //   <MainLayout>
    <Switch>
      {/* <App/> */}

      {/* mainScreens */}

      <Route path="/" exact component={BoardsHome} />
      <Route path="/internal" exact component={UpdateLabour} />
      <Route path="/schemesHomePage" component={SchemesHomePage} />
      <Route path="/education-status" component={EducationStatus} />
      <Route path="/labourschemesHomePage" component={LabourschemesHomePage} />
      <Route
        path="/socialsecurityschemesHomePage"
        component={SocialSecurityBoardHomePage}
      />
      <Route
        path="/accidentUnorganizedForm"
        component={AccidentUnorganizedForm}
      />
      <Route path="/migrantpage" component={Migrant} />

      {/* schemesdocs */}
      <Route path="/accident" component={Accident} />
      <Route path="/ailments" component={Ailments} />
      <Route path="/continuePension" component={ContinuationPension} />
      <Route path="/delivery" component={Delivery} />
      <Route path="/disabilityContinuePension" component={ContDisablePension} />
      <Route path="/disablePension" component={DisablePension} />
      <Route path="/education" component={Education} />
      <Route path="/ent" component={Ent} />
      <Route path="/funeral" component={Funeral} />
      <Route path="/identitycard" component={IdCard} />
      <Route path="/ksrtc" component={Ksrtc} />
      <Route path="/lpg" component={Lpg} />
      <Route path="/marriage" component={Marriage} />
      <Route path="/medical" component={Medical} />
      <Route path="/pension" component={Pension} />
      <Route path="/registrationSchemeoneyear" component={RegistrationScheme} />
      <Route path="/shrama" component={Shrama} />
      <Route path="/thayimagu" component={Thayi} />
      <Route path="/medicalcamps" component={MedicalCamp} />
      <Route path="/sports" component={Sports} />
      <Route path="/ambedkar" component={Ambedkar} />
      <Route path="/accident2" component={Accident2} />
      <Route path="/delivery2" component={Delivery2} />
      <Route path="/education2" component={Education2} />
      <Route path="/funeral2" component={Funeral2} />
      <Route path="/medical2" component={Medical2} />
      <Route path="/ashadeepa" component={AshaDeepa} />
      <Route path="/transport" component={Transport} />
      <Route path="/detailtransport" component={DetailedTransport} />
      <Route path="/yogimandhan" component={YogiManDhan} />
      <Route path="/newpension" component={NewPension} />
      <Route path="/bmtc" component={Bmtc} />
      <Route path="/precoaching" component={Precoaching} />

      {/* Welfare Schemes  */}

      <Route path="/welfaremedical" component={WelfareMedical} />
      <Route path="/welfareaccident" component={WelfareAccident} />
      <Route path="/welfarefuneral" component={WelfareFuneral} />
      <Route path="/welfaredelivery" component={WelfareDelivery} />

      <Route path="/welfaredashboard" component={SchemeBeneficiariesWelfare} />
      <Route
        path="/admin/scheme-details-medical"
        component={SchemeDetailsWelfareMedical}
      />
      <Route
        path="/admin/scheme-details-accident"
        component={SchemeDetailsWelfareAccident}
      />
      <Route
        path="/admin/scheme-details-funeral"
        component={SchemeDetailsWelfareFuneral}
      />
      <Route
        path="/admin/scheme-details-delivery"
        component={SchemeDetailsWelfareDelivery}
      />
      <Route
        path="/admin/scheme-medical-approved"
        component={SchemeDetailsWelfareApproved}
      />
      {/* <Route path="/admin/scheme-medical-approved" component={SchemeDetailsWelfareApproved} /> */}

      {/* UNORGANIZED */}
      <Route path="/unorganized-login" component={UnorganizedLogin} />
      <Route
        path="/unorganized-registration"
        component={UnorganizedRegistration}
      />
      <Route
        path="/dashboard-user-unorganized"
        component={DashboardLabourUnorganized}
      />
      <Route path="/profile-unorganized" component={ProfileUnorganized} />
      <Route path="/ashadeepaschemeform" component={Ashadeepaform} />
      <Route
        path="/ashadeepaapproveschemedetails"
        component={AshadeepaformApproveDetails}
      />

      {/* Migrant */}
      <Route path="/migrant-registration" component={MigrantRegistration} />
      <Route path="/dashboard-migrant" component={MigrantDashboard} />
      <Route path="/personal-migrant" component={MigrantPersonal} />
      <Route path="/profile-migrant" component={ProfileMigrant} />

      {/* MobileEKYC */}
      <Route
        path="/mobile-registration/:user_id?/:token?"
        component={MobileEKYC}
      />
      <Route path="/mobileClick" component={MobileCLick} />
      <Route path="/goback/aadhar-verified" component={MobileVerified} />

      {/* CONSTRUCTION */}
      <Route
        path="/scheme-form/continuation-of-pension"
        component={schemeForm_ContinuationOfPension}
      />
      <Route path="/scheme-form/:id?" component={SchemeForm} />
      <Route path="/kbocwwb-schemes-home" component={KBOCWWBSchemeHomePage} />
      <Route
        path="/newSchemeForm/:labourUserID/:schemeID/:schemeName/:roleID/:renewal?/:child?/:death?/:thayimaguSchemeAvailID?/:renewalSequence?/:totalCountAvailed?/:totalAvailedByUser?"
        component={NewSchemeForm2}
      />
      <Route
        path="/scheme/continuation-of-pension"
        component={schemeDoc_ContinuationOfPension}
      />
      <Route
        path="/scheme/educationassistance"
        component={schemeDoc_EducationAssistance}
      />
      <Route
        path="/scheme/:id/:role_hierarchy/:totalCountAvailed?/:totalAvailedByUser?"
        component={SchemeDoc}
      />

      <Route path="/schemes-home" component={SchemesHome} />
      <Route path="/schemes-unorganized" component={UnorganizedSchemesList} />
      <Route
        path="/admin/scheme-details-unorganized"
        component={SchemeDetailsUnorganized}
      />

      {/* <Route path="/e-card" component={ECardPage} /> */}
      <Route path="/e-card/:id?" component={ECardPage} />
      <Route path="/renewal/:labourUserID" component={Renewals} />
      <Route path="/renewals" component={RenewalPage} />

      {/* New Route for Admin Sanction Order Page */}
      <Route
        path="/sanction-order/:schemeId/:schemeStatus/:laboutUserId/:schemeAvailId/:labourName/:appliedDate"
        component={SchemeSanctionOrder}
      />

      <Route path="/update-registration/:id?" component={SearchDataForm} />

      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms&conditions" component={TermsConditions} />

      {/* <Route path="/egov/ekyc_response" component={EKYC} /> */}

      {/* Register using Registration ID */}

      <Route path="/register-id" component={RegistrationID} />
      <Route path="/register-address" component={RegistrationAddress} />
      <Route path="/already-registered" component={AlreadyRegistered} />
      <Route
        path="/seva-sindhu-registration"
        component={SevaSindhuRegistration}
      />
      <Route path="/seva-sindhu-survey" component={SevasindhuLabourDetails} />

      <Route path="/newmisreport" component={NEWMISReport} />

      <Route path="/search-labour" component={Search} />

      <Route
        path="/admin/mapping-master-village-form"
        component={VillageMappingForm}
      />
      <Route path="/admin/mapping-master-village" component={VillageMapping} />

      <Route
        path="/admin/mapping-master-city-form"
        component={CityMappingForm}
      />
      <Route path="/admin/mapping-master-city" component={CityMapping} />

      <Route
        path="/admin/mapping-master-taluk-form"
        component={TalukMappingForm}
      />
      <Route path="/admin/mapping-master-taluk" component={TalukMapping} />

      <Route
        path="/admin/mapping-master-district-form"
        component={DistrictMappingForm}
      />
      <Route
        path="/admin/mapping-master-district"
        component={DistrictMapping}
      />

      <Route
        path="/admin/mapping-master-state-form"
        component={StateMappingForm}
      />
      <Route path="/admin/mapping-master-state" component={StateMapping} />

      <Route path="/admin/userrolemapping" component={UserRoleMapping} />
      <Route
        path="/admin/user-role-mapping-form"
        component={UserRoleMappingForm}
      />
      <Route path="/admin/user-role-master" component={UserRoleMaster} />
      <Route path="/admin/user-role-master-map" component={UserRoleMaster2} />
      <Route path="/admin/user-circle-mapping" component={UserCircleMapping} />

      <Route path="/admin/mapping-master" component={MappingMaster} />

      {/* <Route path="/admin/circle-master" component={CircleMaster} /> */}

      <Route path="/admin/board-master" component={BoardMaster} />
      <Route path="/admin/board-master-form" component={BoardMasterForm} />

      <Route path="/admin/role-master" component={RoleMaster} />
      <Route path="/admin/role-master-form" component={RoleMasterForm} />
      <Route path="/admin/role-master-mapping" component={RoleMappingForm} />

      <Route path="/admin/catalog-master" component={CatalogMaster} />
      <Route path="/admin/catalog-master-form" component={CatalogMasterForm} />
      <Route path="/admin/catalog-value" component={CatalogValue} />
      <Route path="/admin/catalog-value-form" component={CatalogValueForm} />

      <Route path="/admin/unionList" component={UnionList} />
      <Route
        path="/admin/unionInsert/:unionID?/:isEdit?"
        component={UnionForm}
      />
      <Route
        path="/admin/unionMapping/:unionID/:unionName"
        component={UnionMappingUI}
      />

      <Route path="/admin/union-master" component={UnionMaster} />
      <Route path="/admin/union-master-form" component={UnionMasterForm} />
      <Route path="/admin/union-mapping-form" component={UnionMappingForm} />

      <Route path="/admin/circle-master" component={CircleMaster} />
      <Route path="/admin/circle-master-form" component={CircleMasterForm} />
      <Route path="/admin/circle-mapping" component={CircleMapping} />

      <Route path="/admin/ministry-master" component={MinistryMaster} />
      <Route
        path="/admin/ministry-master-form"
        component={MinistryMasterForm}
      />

      <Route path="/admin/dashboard" exact component={Dashboard} />
      <Route path="/admin/dashboard/pensionform" component={PensionForm} />
      <Route
        path="/admin/dashboard/backlogformaccidentDeath"
        component={BacklogFormAccidentDeath}
      />
      <Route
        path="/admin/dashboard/backlogformFuneral"
        component={BacklogFormFuneral}
      />

      <Route
        path="/admin/dashboard/pension-details"
        component={PensionDetails}
      />
      <Route
        path="/admin/dashboard/:id"
        exact
        component={Dashboard}
        key={window.location.pathname}
      />
      <Route path="/admin/dashboard/data-reports" exact component={KBOCWWB} />
      {/* New Route for Labour officer Reports */}
      <Route path="/count-labour-officer" component={CountLabourOfficer} />
      <Route
        path="/admin/dashboard/registration/new"
        exact
        component={DashboardRegistration}
      />
      <Route
        path="/admin/dashboard/education-scheme-form/:roleId/:userID/:recordID"
        exact
        component={EducationValidationForm}
      />

      <Route
        path="/admin/unorganized-dashboard"
        component={UnorganizedDashboard}
      />
      <Route path="/admin/scheme-details" component={SchemeDetails} />
      <Route path="/nominee/update" component={NomineeUpdateForm} />
      <Route
        path="/admin/schemeDetails/transaction/:labourUserID/:schemeID/:schemeName/:schemeAvailID/:schemeTransactionID/:schemeStatus/:approvalAuthority/:renewalSequence?"
        component={SchemesMain}
      />
      <Route
        path="/admin/details/:labourUserID/:boardID/:status/:page/:editProfileID?/:roleName?"
        component={RegistrationProfileDetails}
      />
      <Route path="/admin/unorganized/details" component={UnorganizedDetails} />
      <Route path="/admin/kbocwwb" component={Kbocwwb} />
      <Route path="/admin/home" component={AdminPortal} />
      <Route path="/admin" component={Admin} />
      <Route path="/dashboard-user" component={DashboardLabour} />
      {/* <Route path="/profile" component={Profile} /> */}
      <Route
        path="/profile/personal/:labourUserID/:isEdited?"
        component={PersonalDetails}
      />
      <Route
        path="/profile/address/:labourUserID/:isEdited?"
        component={AddresDetails}
      />
      <Route
        path="/profile/family/:labourUserID/:isEdited?"
        component={FamilyDetails}
      />
      <Route
        path="/profile/bank/:labourUserID/:isEdited?"
        component={BankDetails}
      />
      <Route
        path="/profile/nintyDays/:labourUserID/:isEdited?"
        component={NintyDaysDetails}
      />
      <Route
        path="/view/registrationack/:labourUserID"
        component={RegistrationAcknowledgment}
      />
      <Route
        path="/view/renewalack/:labourUserID"
        component={RenewalAcknowledgment}
      />
      <Route path="/profile/review/:labourUserID" component={Review} />
      <Route path="/profile/edit/:labourUserID" component={EditProfileV1} />
      <Route path="/edit-profile" component={Editprofile} />
      <Route path="/registration" component={Registration} />
      <Route path="/ashadeepaschemedetails" component={AshadeepaformDetails} />
      <Route path="/schemeStatus" component={schemeStatus} />
      <Route path="/scheme-status/:labourUserID" component={SchemeStatus} />
      <Route
        path="/view/schemeack/:schemeName/:labourUserID/:schemeAvailID"
        component={SchemeAcknowledgement}
      />
      <Route
        path="/view/scheme-sanction-order/:schemeName/:labourUserID/:schemeAvailID"
        component={SchemesSanctionOrder}
      />
      <Route
        path="/view/scheme-rejection-order/:schemeName/:labourUserID/:schemeAvailID"
        component={SchemesRejectionOrder}
      />

      {/* New routing admin view reg/ren/scheme/edit-profile */}

      {/* <Route path="/admin/registration/:labourUserID/:boardID/:page" component={RegistrationProfileDetails} /> */}

      <Route path="/login" exact component={Login} />
      <Route path="/register/:deptID?" component={Register} />
      <Route path="/survey" component={Survey} />

      {/* New Routes for admin dashboard */}
      {/* <Route path='/admin/dashboard/registration' exact component={DashboardRegistration}/> */}
      <Route path="/admin/dashboard/schemes" />
      <Route path="/admin/dashboard/renewals" />
    </Switch>
    //   </MainLayout>
    // </BrowserRouter>
    // </Router>
  );
};

export default Routes;
