import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { cityData, districtData, talukData, villageData } from './AddressLogic';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Paper from '@mui/material/Paper';
import { Translate } from 'react-redux-i18n';
import { Required } from 'utils/tools';
const AddressDetails = ({
  fetchResidence,
  residence,
  setResidence,
  circleData,
  stateID,
  districtID,
  setDistrictID,
  talukID,
  setTalukID,
  cityID,
  setCityID,
  villageID,
  setVillageID,
  streetName,
  setStreetName,
  houseNumber,
  setHouseNumber,
  landmark,
  setLandmark,
  pincode,
  setPincode,
}) => {
  const [fetchCity, setFetchCity] = useState(null);
  const [fetchVillage, setFetchVillage] = useState(null);

  const district = districtData(circleData && circleData);
  const taluk = talukData(circleData && circleData);
  const village = villageData(circleData && circleData);
  const city = cityData(circleData && circleData);

  useEffect(() => {
    if (talukID) {
      const result = city.filter((_data) => _data.taluk_id === talukID);
      setFetchCity(result);
    }
    if (cityID) {
      const result = village.filter(
        (_data) => _data.gram_panchayat_city_town_id === cityID,
      );

      setFetchVillage(result);
    }
  }, [talukID, cityID]);

  return (
    <Paper
      elevation={3}
      style={{ margin: '20px', borderRadius: '10px', padding: '20px 0' }}
    >
      <Container>
        <Row className="g-3 px-3 form-row py-5">
          <Typography
            variant="h5"
            color={'GrayText'}
            className="text-left d-flex justify-content-start align-items-center my-2"
          >
            <HomeIcon
              style={{ width: '30px', height: '30px' }}
              sx={{
                color: 'white',

                backgroundColor: 'GrayText',
                borderRadius: '100%',
              }}
            />{' '}
            <span className="px-2" style={{ fontWeight: 'bolder' }}>
              Address Details
            </span>
          </Typography>
          <Col md={6}>
            <Required title={<Translate value="Residence type" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={residence || '0'}
                displayEmpty
                onChange={(ev) => setResidence(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select type of residence" />
                </MenuItem>
                {fetchResidence &&
                  fetchResidence.length &&
                  fetchResidence.map((row) => (
                    <MenuItem value={row.value_id}>
                      <ListItemText primary={row.value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="State" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={stateID}
                displayEmpty
                // onChange={handleDistrict}
              >
                <MenuItem value={12}>
                  <ListItemText primary="Karnataka" />
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="District" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={districtID || '0'}
                displayEmpty
                onChange={(ev) => setDistrictID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select District" />
                </MenuItem>
                {district &&
                  district.length &&
                  district.map((row) => (
                    <MenuItem value={row.district_id}>
                      <ListItemText primary={row.district_name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="Taluk" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={talukID || '0'}
                displayEmpty
                onChange={(ev) => setTalukID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Taluk" />
                </MenuItem>
                {taluk &&
                  taluk.length &&
                  taluk.map((row) => (
                    <MenuItem value={row.taluk_id}>
                      <ListItemText primary={row.taluk_name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="City/Gram Panchayat" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={cityID || '0'}
                displayEmpty
                onChange={(ev) => setCityID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select City / Gram Panchayat" />
                </MenuItem>
                {fetchCity &&
                  fetchCity.length &&
                  fetchCity.map((row) => (
                    <MenuItem value={row.gram_panchayat_city_town_id}>
                      <ListItemText
                        primary={row.gram_panchayatcity_town_name}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="Village" />} />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={villageID || '0'}
                displayEmpty
                onChange={(ev) => setVillageID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Village Area Ward" />
                </MenuItem>
                {fetchVillage &&
                  fetchVillage.length &&
                  fetchVillage.map((row) => (
                    <MenuItem value={row.village_area_ward_id}>
                      <ListItemText primary={row.village_area_ward} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="Street / Road Name" />} />
            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Street/Road Name"
                name="streetRoad"
                onChange={(ev) => setStreetName(ev.target.value)}
                value={streetName}
              />
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="House / Building number" />} />
            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your House / Building Number"
                onChange={(ev) => setHouseNumber(ev.target.value)}
                value={houseNumber}
                inputProps={{ minLength: 0, maxLength: 15 }}
              />
            </FormControl>
          </Col>
          <Col md={6}>
            <Typography>Landmark</Typography>
            <FormControl className="mt-3" variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Landmark"
                value={landmark}
                onChange={(ev) => setLandmark(ev.target.value)}
              />
            </FormControl>
          </Col>
          <Col md={6}>
            <Required title={<Translate value="Pincode" />} />
            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Pincode"
                value={pincode}
                onChange={(ev) =>
                  setPincode(ev.target.value.replace(/[^0-9]/gi, ''))
                }
                inputProps={{ minLength: 0, maxLength: 6 }}
              />
            </FormControl>
          </Col>
        </Row>
      </Container>
    </Paper>
  );
};

export default AddressDetails;
