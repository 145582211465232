import 'date-fns';
import { withStyles, Menu, MenuItem, ListItemText, Select, FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTokenCookie, showToast } from 'utils/tools';
import { SERVER } from 'store/actions/user.actions'
import axios from 'axios';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '350px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const RoleMappingForm = (props) => {
    const users = useSelector(state => state.users);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElRole, setAnchorElRole] = React.useState(null);

    const { location } = props;
    const [roles, setRolesMaster] = useState([]);
    const [boards, setBoardsMaster] = useState([]);

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        user_id: "", //445
        user_name: "", //qqq
        activeBoardItem: [],
        activeRoleItem: [],
        rows: []
    });

    

    const setBoards = () => {
        var config = {
            method: 'get',
            url: SERVER + '/global/get_board_details?id=1',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_board_details Response', response.data.data)
                setBoardsMaster(response.data.data)
            })
            .catch(function (error) {
                console.log('error', error)
            });
    }
    useEffect(() => {
        console.log("location: ", location)
        if (location.rowdata !== undefined && location.rowdata !== null && location.rowdata !== "") {
            getRole()
            setBoards()
            setAllValues({
                ...allValues,
                recieved_rowdata: location.rowdata
            })
        }
    }, [])

    useEffect(() => {
        console.log("allvalues: ", allValues)
    }, [allValues])

    const getRole = () => {
        var config = {
            method: 'get',
            url: SERVER + `/config/role/get_role?role_id=${location.rowdata.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_role Response', response.data.data)
                var rows1 = []

                response.data.data.forEach((i,key) => {
                    // setRoles1(i.board_id,key)
                    // updateDeptUserRole(i,key)
                    rows1 = rows1.concat({
                        "board_id": i.board_id,
                        "board_name": i.board_short_name,
                        "role_id": i.role_id,
                        "role_name": i.role_name
                    })
                    console.log("i: ",i)
                    console.log("rows1: ",rows1)
                })
                
                setAllValues({
                    ...allValues,
                    rows: rows1,
                })
            })
            .catch(function (error) {
                console.error("error:", error)
            });
    }

    const setRoles1 = (board_id,key) => {
        console.log("in setRoles1: board_id=",board_id)
        console.log("in setRoles1: key=",key)
        var config = {
            method: 'get',
            url: SERVER + '/config/role/get_board_roles?board_id='+board_id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_roles Response', response.data.data)
                // setRolesMaster(response.data.data)

                allValues.rows[key].roles = response.data.data
                setAllValues({
                    ...allValues
                })
            })
            .catch(function (error) {
                console.error('error', error)
            });
    }
    
    const updateDeptUserRole = (i,key) => {
        console.log("in updateDeptUserRole: i=",i)
        console.log("in updateDeptUserRole: key=",key)
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({
                "board_id": i.board_id,
                "role_id": i.role_id,
                "user_id": allValues.user_id,
                "added_by": users.getDeptUserRole.data[0].department_user_id
            });
            var config = {
                method: 'post',
                url: SERVER+'/config/dept_user/update_dept_user_role',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
            };
            axios(config)
            .then(function (response) {
            resolve(response);
            })
            .catch(function (error) {
            reject(error);
            });
        })
    }

    useEffect(() => {
        console.log("useeffect allValues.rows",allValues.rows)
        if(allValues.rows.length > 0){
            allValues.rows.forEach((i,index)=>{
                if(i.roles === undefined || i.roles.length <= 0){
                    setRoles1(i.board_id,index)
                }
            })
        }
    }, [allValues.rows])

    const onSelectClickHandlerkboc = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosekboc = () => {
        setAnchorEl(null);
    }

    const onSelectBoardHandler = (event, data, key) => {
        console.log("in onSelectBoardHandler: data= ", data)
        console.log("in onSelectBoardHandler: key= ", key)
        var dataID = data.id
        allValues.rows[key].board_id = data.id
        allValues.rows[key].board_name = data.short_name
        setRoles1(dataID,key)
        // setAllValues({
        //     ...allValues
        // })
        handleClosekboc();
    }
    
    const onSelectClickHandlerRoleboc = (event) => {
        setAnchorElRole(event.currentTarget);
    }

    const handleCloseRoleboc = () => {
        setAnchorElRole(null);
    }

    const onSelectRoleHandler = (event, data, key) => {
        allValues.rows[key].role_id = data.role_id
        allValues.rows[key].role_name = data.role_name
        setAllValues({
            ...allValues
        })
        // setAllValues({
        //     ...allValues,
        //     activeRoleItem: data
        // })
        handleCloseRoleboc();
        console.log('ActiveR', allValues.activeRoleItem)
    }

    const addNewRow = () => {
        if(allValues.rows.length === boards.length){
            showToast("ERROR", "Cannot Add More Roles");
        }
        else{
            setAllValues({
                ...allValues,
                rows: allValues.rows.concat({
                    "board_id": "",
                    "board_name": "",
                    "role_id": "",
                    "role_name": ""
                })
            })
        }
    }

    const deleteRow = (item) => {
        console.log("in deleteRow: item=" + item)
        var array = [...allValues.rows];
        var index = array.indexOf(item)
        array.splice(index, 1);
        setAllValues({
            ...allValues,
            rows: array,
        });
    }
    
    const saveButtonPressed = () => {
        console.log("in saveButtonPressed")
        if(allValues.rows.length <= 0 ){
            showToast("ERROR","Please add a Role")
        }
        else{

            var boards = []

            allValues.rows.forEach((i,key) => {
                boards = boards.concat({
                    "board_id": i.board_id,
                })
            })

            var data = JSON.stringify({
                        "role_id": location.rowdata.id,
                        "is_ministry": 0,
                        "ministry_id": null,
                        "boards": boards,
                        "user_id": users.getDeptUserRole.data[0].department_user_id
                        });

            var config = {
            method: 'post',
            url: SERVER+'/config/role/role_board_mapping',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data : data
            };

            axios(config)
            .then(function (response) {
                console.log("role_board_mapping response:",response.data)
                showToast("SUCCESS","Roles Saved Successfully!")
                props.history.push("/admin/role-master")
            })
            .catch(function (error) {
                console.log(error);
                showToast("ERROR","Some error occurred while saving Roles.")
            });
        }
    }

    const userRolesRows = allValues.rows.map((id, key) => {
        return (
            <Row className="form-row role-config-form">
                {/* <Col xs={12} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Board</p>
                    <div aria-controls="customized-menu" aria-haspopup="true" className="boardpage-col-div-custom boardpage-col-div-mt-1 hvr-icon-hang"
                        onClick={(e) => onSelectClickHandlerkboc(e, "kbocwwb")}>
                        <p>{id.board_name}</p>
                        <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                        id="customized-menu"
                        className="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(e) => handleClosekboc(e, "kbocwwb")}
                    >
                        {
                        boards.map((row) => (
                            <MenuItem
                                disabled = { allValues.rows.filter( (item) => item.board_id === row.id ).length > 0 }
                                onClick={(e) => onSelectBoardHandler(e, row, key)}
                                // className={allValues.statusActiveLi === row.id ? "active" : null}
                                >
                                <ListItemText primary={row.short_name} />
                            </MenuItem>
                        )
                        )
                        }
                    </StyledMenu>
                </Col>
                 */}
                <Col xs={12} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Board</p>
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={
                                boards!== undefined &&
                                allValues.rows[key]!== undefined &&
                                allValues.rows[key].board_id !== undefined &&
                                allValues.rows[key].board_id
                            }
                            displayEmpty
                        >
                            <MenuItem value="">
                                <ListItemText primary="-" />
                            </MenuItem>
                            {
                            boards.map((row) => (
                                <MenuItem
                                    disabled = { allValues.rows.filter( (item) => item.board_id === row.id ).length > 0 }
                                    onClick={(e) => onSelectBoardHandler(e, row, key)}
                                    value={row.id}
                                    >
                                    <ListItemText primary={row.short_name} />
                                </MenuItem>
                            )
                            )
                            }
                        </Select>
                    </FormControl>
                
                </Col>
                <Col xs={11} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Role</p>
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={
                                // allValues.rows[key]!== undefined &&
                                // allValues.rows[key].roles !== undefined &&
                                allValues.rows[key].role_id
                            }
                            displayEmpty
                        >
                            {/* <MenuItem value={'' || false}> */}
                            <MenuItem value="">
                                <ListItemText primary="-" />
                            </MenuItem>

                            {
                            allValues.rows[key]!==undefined &&
                            allValues.rows[key].roles!==undefined &&
                            allValues.rows[key].roles.map((row) => (
                                <MenuItem 
                                    onClick={(e) => onSelectRoleHandler(e, row, key)}
                                    value={row.role_id}
                                >
                                    <ListItemText primary={row.role_name} />
                                </MenuItem>
                            )
                            )}
                        </Select>
                    </FormControl>
                </Col>
                {/* <Col xs={11} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Role</p>
                    <div aria-controls={`customized-menu_${key}`} aria-haspopup="true" className="boardpage-col-div-custom boardpage-col-div-mt-1 hvr-icon-hang" onClick={(e) => onSelectClickHandlerRoleboc(e, "kbocwwb")}>
                        <p>{id.role_name}</p>
                        <img alt="..." className="hvr-icon" />
                    </div>
                    <StyledMenu
                        id={`customized-menu_${key}`}
                        className={`customized-menu_${key}`}
                        anchorEl={anchorElRole}
                        // keepMounted
                        open={Boolean(anchorElRole)}
                        onClose={(e) => handleCloseRoleboc(e, "kbocwwb")}
                    >
                        {
                        allValues.rows[key]!==undefined &&
                        allValues.rows[key].roles!==undefined &&
                        allValues.rows[key].roles.map((row) => (
                            <MenuItem onClick={(e) => onSelectRoleHandler(e, row, key)} 
                            // className={allValues.statusActiveLi === row.id ? "active" : null}
                            >
                                <ListItemText primary={row.role_name} />
                            </MenuItem>
                        )
                        )}
                    </StyledMenu>
                </Col> */}
                <Col xs={1} className="mt-5 p-1 pl-5" align="center">
                    <Button variant="outline-danger" className="" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                </Col>
            </Row>
        )
    })

    return (

        <>
            <div className="root">
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                    Government of Karnataka <br />
                                    Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                                </h1>
                            </div>

                        </Col>

                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={8} className="title-col">
                    <Link 
                        // to="#"
                        to ={{ 
                            pathname: "/admin/role-master", 
                            userId: location.state!==undefined && location.state.userId,
                            userrow: location.state!==undefined && location.state.userrow
                            }} 
                        // onClick={() => props.history.push("/admin/user-role-mapping-form",  
                        //         { 
                        //             userId: location.state.userId,
                        //             userrow: location.state.userrow
                        //         })}
                                >
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Role Mapping</p>
                    </Link>
                </Col>
                <Col xs={4} className="title-col" >
                    <p className="title">Role Name : {location.rowdata.name}</p>
                </Col>
            </Row>
            <Row className="form-row mt-4 role-config-form-row" >
                {userRolesRows}
                <Row className="form-row mt-1" >
                    <Col xs={12} className="mt-4 pr-1" align="center">
                        <Button variant="outline-danger" className="add-more-button" onClick={() => addNewRow()}>
                            Add More +
                        </Button>
                        <Button variant="outline-primary" className="save-button" onClick={()=> saveButtonPressed()}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default RoleMappingForm;