import { FormControl, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button, ButtonBase, TableFooter } from "@mui/material";
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { Pagination, Stack } from '@mui/material';
import usePagination from '../Pagination';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';




const EducationSchemeList = () => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    // console.log("GETDEPTUSER",users.getDeptUserRole);
    const deptUserData = users && users.getDeptUserRole && users.getDeptUserRole.data && users.getDeptUserRole.data.filter((data) => data.board_id === 1)
    // console.log(deptUserData,"deptUserData"); 

    const [regNo,setRegNo] = useState(null) 
    const [refNo,setRefNo] = useState(null) 
    const [schemeRefNo,setSchemeRefNo] = useState(null) 
    const [mobileNo,setMobileNo] = useState(null) 

    const [fetchEducationSchemeData,setFetchEducationSchemeData] = useState(null)
  
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const [loading,setLoading] = useState(false)




  const fetchEducationData = () => {
      setLoading(true)
    const payLoad = {
      "board_id": 1,
      "role_id": deptUserData && deptUserData[0].role_id,
      "department_user_id": deptUserData && deptUserData[0].department_user_id,
      "scheme_application_reference_no": schemeRefNo ? schemeRefNo : null,
      "registration_application_reference_no": refNo ? refNo : null,
      "registration_no": regNo ? regNo : null,
      "mobile_no": mobileNo ? mobileNo : null,
      "circle_id": null,
      "record_id": null
    }

    const config = {
      method:'post',
      url:`${SERVER}/schemes/education_scheme_status`,
      headers:{
              'Authorization': `Bearer ${getTokenCookie()}`
              },
      data: payLoad
    }

    axios(config).then((res)=>{
      console.log(res.data);
      setFetchEducationSchemeData(res.data.data)
      setLoading(false)
    }).catch((err)=>{
      console.log(err);
    })
}


     const count = fetchEducationSchemeData && fetchEducationSchemeData.length && Math.ceil(fetchEducationSchemeData.length / PER_PAGE);
        let _DATA = usePagination((fetchEducationSchemeData && fetchEducationSchemeData.length && fetchEducationSchemeData) || [] , PER_PAGE); 
   
     const handlePageChange = (e, p) => {
        window.scrollTo(0,0)
        setPage(p);
        _DATA.jump(p);
        };
    
        console.log("DATAKBOCWWB::",_DATA.currentData());



  return (
    <>
    <div className='d-flex justify-content-center align-items-center pb-3'>
      <h3 style={{fontWeight:'700',color:'#CA0027'}}>Education Assistance Scheme Validation Report</h3>
    </div>
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <Row className='container-fluid py-3'>
         <Col xs={4}>
            <FormControl fullWidth className="formcontrol5" >
              <input 
              style={{border:'1px solid grey',width:'100%',height:'50px',outline:'none',textAlign:'center'}} 
              type='text' 
              placeholder='Registration Number'
              value={regNo}
              onChange={(ev) => setRegNo(ev.target.value)}  
              />
            </FormControl>
         </Col>
         <Col xs={4}>
            <FormControl fullWidth className="formcontrol5" >
              <input 
              style={{border:'1px solid grey',width:'100%',height:'50px',textAlign:'center'}} 
              type='text' 
              placeholder='Registration Reference Number'
              value={refNo}  
              onChange={(ev) => setRefNo(ev.target.value)}
              />
            </FormControl>
         </Col>
         <Col xs={4}>
            <FormControl fullWidth className="formcontrol5" >
              <input 
              style={{border:'1px solid grey',width:'100%',height:'50px',textAlign:'center'}} 
              type='text' 
              placeholder='Scheme Application Reference Number'
              value={schemeRefNo}  
              onChange={(ev) => setSchemeRefNo(ev.target.value)}  
              />
            </FormControl>
         </Col>
         </Row>
         <Row className='container-fluid'>
         <Col xs={4}>
            <FormControl fullWidth className="formcontrol5" >
              <input 
              style={{border:'1px solid grey',width:'100%',height:'50px',textAlign:'center'}} 
              type='text' 
              placeholder='Mobile Number'
              value={mobileNo}  
              onChange={(ev) => setMobileNo(ev.target.value)}  
              />
            </FormControl>
         </Col>
         {/* <Col xs={4}>
            <FormControl fullWidth className="formcontrol5" >
              <input style={{border:'1px solid grey',width:'100%',height:'50px',textAlign:'center'}} type='text' 
              placeholder='ID'/>
            </FormControl>
         </Col> */}
         <Col xs={4}>
         <FormControl fullWidth className="formcontrol5" >
            <Button variant='contained' color='success' onClick={fetchEducationData} style={{height:'50px'}}>
              Search
            </Button>
            </FormControl>
         </Col>
         </Row>
      

       {loading ? <div className='w-100'>
      <Box
      sx={{
        
        p: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Skeleton
        sx={{color:'black',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'24px'}}
        variant="rectangular"
        width={"100%"}
        height={300}

      > Please wait fetching...</Skeleton>
    </Box>
      </div> :   
      
      <div className='w-100 py-4'>
        <Paper sx={{ width: '100%'}}>
        {fetchEducationSchemeData && fetchEducationSchemeData.length ? 
      <TableContainer style={{width:'100%'}}>
        <Table style={{width: '100%'}} >
          <TableHead>
            <TableRow>
             <TableCell align='center'>
                 Applicant Name
                </TableCell>
                <TableCell align='center'>
                 Registration Number
                </TableCell>
                <TableCell align='center'>
                 Reference Number
                </TableCell>
                <TableCell align='center'>
                Scheme Reference Number
                </TableCell>
                <TableCell align='center'>
                 Status
                </TableCell>
                <TableCell align='center'>
                 View Application
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {fetchEducationSchemeData && fetchEducationSchemeData.length && _DATA.currentData() && _DATA.currentData().map((data)=> (
                 <TableRow hover>
                  <TableCell align='center'>
                       {data.NameofTheBeneficiary}
                        </TableCell>
                        <TableCell align='center'>
                       {data.RegistrationNumber}
                        </TableCell>
                        <TableCell align='center'>
                        {data.ApplicationReferenceNumber}
                        </TableCell>
                        <TableCell align='center'>
                         {data.applreference}
                        </TableCell>
                        <TableCell align='center'>
                        {data.is_updated === 0 ? "Not Updated" : data.is_updated === 1 ? "Updated" : ""}
                        </TableCell>
                        <TableCell align='center'>
                        {/* {data.is_updated === 0 ?   */}
                        <Link to={`/admin/dashboard/education-scheme-form/${deptUserData && deptUserData[0].role_id}/${deptUserData && deptUserData[0].department_user_id}/${data.Id}`}>
                         <Button color='info' style={{background:'none',border:'none'}}>View</Button>
                         </Link> 
                         {/* : data.is_updated === 1 ?  */}
                          {/* <Button disabled color='info' style={{background:'none',border:'none'}}>View</Button> */}
                         {/* : ""} */}
                        </TableCell>
                   
                  </TableRow>
              ))}
                 
              
          </TableBody>
        </Table>
      </TableContainer>
      : null}
  
    </Paper>
         </div>
       }

           {fetchEducationSchemeData && fetchEducationSchemeData.length ? 
           <div className="footer-buttons">
                              
                <Stack 
                    spacing={2} 
                    className="p-2 pagination_noti"
                    >
                    {/* {console.log(totalPages,"TotalPages2:")} */}
                    <Pagination 
                    
                        // showFirstButton showLastButton
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="circular"
                        onChange={handlePageChange} 
                        />
                </Stack>
                {}
                {/* <Button onClick={handleLoadMore}>Load More</Button> */}
            </div>
            : null}
      
    </div>
    </>
  )
}

export default EducationSchemeList