import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import language from 'assets/images/translate (1).svg';
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_schemes,
  SERVER,
  setLocaleWithFallback,
} from 'store/actions/user.actions';

import {
  encrypt,
  getTokenCookie,
  removeTokenCookie,
  showToast,
} from 'utils/tools';
import checkGreen from 'assets/images/Tick-01.png';
import schemeAppliedStatusGroupIcon from 'assets/images/schemeAppliedStatusGroupIcon.svg';
import schemeApprovedStatusSubtraction11 from 'assets/images/schemeApprovedStatusSubtraction11.svg';
import schemependingStatusPendingSVG from 'assets/images/schemependingStatusPendingSVG.svg';
import schemeclosestatus from 'assets/images/schemeclosestatus.svg';
import schemeSubtractionstatus from 'assets/images/schemeSubtractionstatus.svg';
import calendar from 'assets/images/calendar.svg';
import Moment from 'react-moment';
import PendingSmallStatusIcon from 'assets/images/PendingSmallStatusIcon.svg';
import time from 'assets/images/time.svg';
import closeIcon from 'assets/images/closeIcon.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Link } from 'react-router-dom';
import logout from 'assets/images/Logout.svg';
import { Tooltip } from '@material-ui/core';
import { getUser } from 'store/actions';

function ChangeNumber(props) {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // const [allValues, setAllValues] = React.useState({
  //    newmobilenumber : '',
  //    oldmobilenumber : '',
  //    hash : '',
  //    otp:''
  // })

  const [verifyNumber, setverifyNumber] = React.useState(false);
  const [duplicateNumber, setduplicateNumber] = React.useState(false);

  const [newmobilenumber, setnewmobilenumber] = React.useState(null);
  const [oldmobilenumber, setoldmobilenumber] = React.useState('');
  const [otp, setotp] = React.useState('');
  const [hash, sethash] = React.useState(null);

  const [labourUserID, setLabourUserID] = React.useState(null);

  useEffect(() => {
    if (otp !== null && otp.length === 6) {
      var config = {
        method: 'get',
        url: SERVER + '/user/' + oldmobilenumber,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      };

      axios(config)
        .then(function (response) {
          console.log('mobile_response', JSON.stringify(response));

          setLabourUserID(response.data.data[0].id);

          // allValues.props_this.history.goBack()
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            'ERROR',
            'Please enter a valid numbers accordingly to be changed',
          );

          // setLoading(false)
        });
    }
  }, [otp]);

  const handleChange = (event) => {
    console.log('ET', event.target);
    console.log('dataentered', event.target.name);
    if (event.target.name.includes('newmobilenumber')) {
      setnewmobilenumber(event.target.value);
    } else if (event.target.name.includes('oldmobilenumber')) {
      console.log('fff');
      setoldmobilenumber(event.target.value);
    } else if (event.target.name.includes('otp')) {
      console.log('fff');
      setotp(event.target.value);
    }
  };

  const generateOTP = () => {
    console.log('Generate OTP');
    var data = JSON.stringify({
      mobile_number: oldmobilenumber,
      board_id: users.getDeptUserRole.data[0].board_id,
      department_user_id: users.login_admin.data.department_user_id,
    });

    var config = {
      method: 'post',
      url: SERVER + '/admin/get-user-details-to-update-mobile-no',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log('mobile_response', JSON.stringify(response.data.data.msg));

        if (response.data.data.msg == 'User exist') {
          var data = JSON.stringify({
            mobile_code: '+91',
            phone_number: newmobilenumber,
            old_mobile_number: oldmobilenumber,
            board_id: users.getDeptUserRole.data[0].board_id,
          });

          var config = {
            method: 'post',
            url: SERVER + '/auth/request-change-user-mobile',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(
                'mobile_response_hash',
                JSON.stringify(response.data.data.hash),
              );

              if (response.message == 'User Already Exists!') {
                showToast(
                  'ERROR',
                  'User Already Exists, Please enter valid number.',
                );
              } else {
                sethash(response.data.data.hash);
                showToast('SUCCESS', 'OTP has been sent to the new number');
              }

              // allValues.props_this.history.goBack()
            })
            .catch(function (error) {
              console.log(error.message);
              showToast(
                'ERROR',
                'User Already Exists, Please enter valid number.',
              );

              // setLoading(false)
            });
        } else {
          showToast('ERROR', 'Please enter a valid registered user number');
        }

        // allValues.props_this.history.goBack()
      })
      .catch(function (error) {
        console.log(error);
        showToast('ERROR', 'Please enter a valid registered user number');

        // setLoading(false)
      });

    console.log('In loop-end');
  };

  useEffect(() => {
    console.log('newmobilenumber12', newmobilenumber);
    console.log('oldmobilenumber12', oldmobilenumber);
    console.log('hash', hash);
  }, [hash]);

  const changeNumber = () => {
    var data = JSON.stringify({
      hash: hash,
      phone_number: newmobilenumber,
      old_mobile_number: oldmobilenumber,
      mobile_code: '+91',
      board_id: users.getDeptUserRole.data[0].board_id,
      otp: otp,
      labour_user_id: labourUserID,
      department_user_id: users.login_admin.data.department_user_id,
    });

    var config = {
      method: 'post',
      url: SERVER + '/auth/verify-change-user-mobile',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log('NumberValid', JSON.stringify(response.data));

        if (response.message === 'Invalid OTP') {
          showToast('ERROR', 'Please enter correct OTP.');
        } else {
          showToast('SUCCESS', 'The number has been changed successfully');
        }
      })
      .catch(function (error) {
        console.log(error);
        showToast('ERROR', 'Please enter correct OTP.');
      });

    // })
    // .catch(function (error) {
    //     console.log(error);
  };

  return (
    <>
      {/* {/* <div className="root">
                <Row className="top-div schemes">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/dashboard-user">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                    <Translate value="header.title" /> <br />
                                    <Translate value="header.sub" />
                                </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="usericon-header-logout">
                                <img alt="..." src={usericon} className="usericon-img" />
                                <p>{users.user !== null && users.user.first_name}</p>
                                <Tooltip title="Logout" placement="top-start" arrow interactive>
                                    <img alt="..." src={logout} className="logout-img" onClick={() => { props.history.push("/") }} />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                </Row> */}
      <div className="root">
        {/* <Col className='change-number-page'>
                       
                    </Col>
                */}
        <Row className="kbocwwb-page-change-number">
          <Col xs={12}>
            <Row className="mobile-number-row d-flex justify-content-start align-items-start">
              <p className="title">Change Mobile Number</p>
              <div className="mobile-enter-number-admin">
                <p>Enter User's old mobile number :</p>
              </div>
              <div className="mobile-number-textfield">
                <FormControl fullWidth className="formcontrol3">
                  {/* onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            // handleEnter();
                                            handleEmailSubmit();
                                        }
                                    }}> */}
                  <TextField
                    variant="outlined"
                    placeholder="Enter Your Old Mobile Number"
                    name="oldmobilenumber"
                    value={oldmobilenumber}
                    onChange={handleChange}
                    // error={allValues.}
                    // helperText={allValues. ? "Please enter valid Mobile Number" : ""}
                  />
                </FormControl>
              </div>
              {/* <Button className='mobile-number-verify'> */}
              <div className="mobile-enter-number-admin">
                <p>Enter User's new mobile number :</p>
              </div>
              <div className="mobile-number-textfield">
                <FormControl fullWidth className="formcontrol3">
                  {/* onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    // handleEnter();
                                                    handleEmailSubmit();
                                                }
                                            }}> */}
                  <TextField
                    variant="outlined"
                    placeholder="Enter Your new mobile number"
                    name="newmobilenumber"
                    value={newmobilenumber}
                    onChange={handleChange}
                    // error={allValues.}
                    // helperText={allValues. ? "Please enter valid Mobile Number" : ""}
                  />
                </FormControl>
              </div>
              <div className="generate-otp-admin" onClick={generateOTP}>
                Generate OTP
              </div>
              <div className="mobile-enter-number-admin">
                <p>Enter OTP :</p>
              </div>

              <div className="mobile-number-textfield">
                <FormControl fullWidth className="formcontrol3">
                  {/* onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    // handleEnter();
                                                    handleEmailSubmit();
                                                }
                                            }}> */}
                  <TextField
                    variant="outlined"
                    placeholder="Enter OTP"
                    name="otp"
                    value={otp}
                    onChange={handleChange}
                    // error={allValues.}
                    // helperText={allValues. ? "Please enter valid Mobile Number" : ""}
                  />
                </FormControl>
              </div>

              <div className="mobile-number-change" onClick={changeNumber}>
                Submit
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default ChangeNumber;
