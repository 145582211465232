import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

export const schemeWise = [
  columnHelper.accessor('id', {
    header: 'Sno',
    cell: ({ row, table }) => {
      return row?.index + 1;
    },
  }),
  columnHelper.accessor('district', {
    header: 'District',
    enableHiding: true,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('taluk', {
    enableHiding: true,

    header: 'Taluk',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('scheme_name', {
    header: 'Scheme Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_approved', {
    header: 'Total Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_pending', {
    header: 'Total Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_rejected', {
    header: 'Total Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male', {
    header: 'Total Male',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_female', {
    header: 'Total Female',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_others', {
    header: 'Total Others',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
];
export const natureOfWorkWise = [
  columnHelper.accessor('id', {
    header: 'Sno',
    cell: ({ row, table }) => {
      return row?.index + 1;
    },
  }),
  columnHelper.accessor('district', {
    header: 'District',
    enableHiding: true,

    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('taluk', {
    enableHiding: true,
    header: 'Taluk',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('nature_of_work', {
    header: 'Nature Of Work',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_received', {
    header: 'Total Received',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_active', {
    header: 'Total Active',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_inactive', {
    header: 'Total In-Active',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_buffer', {
    header: 'Total In Buffer',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male', {
    header: 'Total Male',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_female', {
    header: 'Total Female',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_others', {
    header: 'Total Others',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
];

export const officerWise = [
  columnHelper.accessor('id', {
    header: 'Sno',
    cell: ({ row, table }) => {
      return row?.index + 1;
    },
  }),
  columnHelper.accessor('first_name', {
    header: 'Officer Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('role_name', {
    header: 'Role',
    enableHiding: true,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_applications', {
    enableHiding: true,
    header: 'Total Applications',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_approved', {
    header: 'Total Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_pending', {
    header: 'Total Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_rejected', {
    header: 'Total Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male', {
    header: 'Total Male',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_female', {
    header: 'Total Female',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
  columnHelper.accessor('total_others', {
    header: 'Total Others',
    cell: (info) => info.getValue(),
    enableHiding: true,
  }),
];

const ApplicationType = {
  ecard: 'E-Card',
  new_software: 'KBOCWWB',
  sevasindhu: 'Seva Sindhu',
  1: 'Seva Sindhu',
};
export const registrationColumns = [
  columnHelper.accessor('id', {
    header: 'Sno',
    cell: ({ row, table }) => {
      return row?.index + 1;
    },
  }),

  columnHelper.accessor('application_type', {
    header: 'Application Type',
    cell: (info) => ApplicationType[info.getValue()],
  }),
  columnHelper.accessor('sevasindhu', {
    header: 'Application Type',
    cell: (info) => ApplicationType[info.getValue()],
  }),
  columnHelper.accessor('is_renewal', {
    header: 'Type',
    cell: (info) => (info.getValue() ? 'Renewal' : 'Register'),
  }),
  columnHelper.accessor('district', {
    header: 'District',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('taluk', {
    header: 'Taluk',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('circle', {
    header: 'Circle',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('office_name', {
    header: 'Officer',
    cell: (info) => info.getValue().trim(),
  }),

  columnHelper.accessor('religion', {
    header: 'Religion',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('category', {
    header: 'Category',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('nature_of_work', {
    header: 'Nature Of Work',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('age', {
    header: 'Age',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_received', {
    header: 'Total Received',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_approved', {
    header: 'Total Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_pending', {
    header: 'Total Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_rejected', {
    header: 'Total Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_active_male', {
    header: 'Active Male Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_active_female', {
    header: 'Active Female Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_active_others', {
    header: 'Active Others Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_buffer_male', {
    header: 'Buffer Male Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_buffer_female', {
    header: 'Buffer Female Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_buffer_others', {
    header: 'Buffer Others Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_inactive_male', {
    header: 'InActive Male Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_inactive_female', {
    header: 'InActive Female Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_inactive_others', {
    header: 'InActive Others Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('active_users', {
    header: 'Active Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('buffer_users', {
    header: 'Buffer Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('inactive_users', {
    header: 'Inactive Users',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male', {
    header: 'Total Male',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_female', {
    header: 'Total Female',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_others', {
    header: 'Total Others',
    cell: (info) => info.getValue(),
  }),
];

// scheme columns
export const schemeColumns = [
  columnHelper.accessor('id', {
    header: 'Sno',
    cell: ({ row, table }) => {
      return row?.index + 1;
    },
  }),
  columnHelper.accessor('application_type', {
    header: 'Application Type',
    cell: (info) => ApplicationType[info.getValue()],
  }),
  columnHelper.accessor('sevasindhu', {
    header: 'Application Type',
    cell: (info) => ApplicationType[info.getValue()],
  }),
  columnHelper.accessor('district', {
    header: 'District',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('taluk', {
    header: 'Taluk',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('circle', {
    header: 'Circle',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('officer', {
    header: 'Officer',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('scheme', {
    header: 'Scheme',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('alc_login', {
    header: 'Applicants at ALC',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('lo_login', {
    header: 'Applicants at LO',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('li_login', {
    header: 'Applicants at LI',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('officer_role', {
    header: 'Role',
    cell: (info) => info.getValue().trim(),
  }),
  columnHelper.accessor('religion', {
    header: 'Religion',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('category', {
    header: 'Category',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('nature_of_work', {
    header: 'Nature Of Work',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('age', {
    header: 'Age',
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor('total_approved', {
    header: 'Total Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_pending', {
    header: 'Total Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_rejected', {
    header: 'Total Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male_approved', {
    header: 'Total Male Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male_rejected', {
    header: 'Total Male Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male_pending', {
    header: 'Total Male Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_female_approved', {
    header: 'Total Female Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_female_rejected', {
    header: 'Total Female Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_female_pending', {
    header: 'Total Female Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_others_approved', {
    header: 'Total Others Approved',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_others_rejected', {
    header: 'Total Others Rejected',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_others_pending', {
    header: 'Total Others Pending',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_male', {
    header: 'Total Male',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_female', {
    header: 'Total Female',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_others', {
    header: 'Total Others',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('total_received', {
    header: 'Total Received',
    cell: (info) => info.getValue(),
  }),
];
