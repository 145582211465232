import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as XLSX from 'xlsx';
import useApi from 'components/apiRequest/useApi';
import usePostApi from 'components/apiRequest/usePostApi';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import MultiSelectAll from './MultiSelect';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { headersWithToken } from 'http/customHeaders';
import { TableRoot } from 'components/TableRoot';
import { registrationColumns } from './mis.columns';
import { saveAs } from 'file-saver';
import { CircularLoader } from 'components/ui/Loader';
import { sortByName } from 'utils/utilFns';
import { Close } from '@material-ui/icons';
import { ClearButton } from './components/ClearButton';

export default function MISRegister() {
  const [pageSize] = useState(10);
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);
  const { data: districtData, districtLoading } = useApi({
    url: `/global/get_district_details?state_id=12`,
  });

  const {
    data: misData,
    loading: misLoading,
    postApiCall: getMISAPI,
  } = usePostApi({
    url: `/reports/v2/registration`,
    method: 'POST',
  });
  const {
    data: officerData,
    loading: officerApiLoading,
    postApiCall: getOfficerAPI,
  } = usePostApi({
    url: `/config/dept_user/get_dept_users`,
    method: 'GET',
    options: {
      headers: headersWithToken(),
    },
  });
  const {
    data: deptUsersData,
    loading: userMappingLoading,
    postApiCall: getUsersMappedToCircle,
  } = usePostApi({
    url: `/internal/circle/mapping/dept-users`,
    method: 'POST',
    options: {
      headers: headersWithToken(),
    },
  });
  const {
    data: circleMappingData,
    loading: circleMappingLoading,
    postApiCall: getCircleMappedToTaluk,
  } = usePostApi({
    url: `/internal/circle/mapping/circle-list/taluk`,
    method: 'POST',
    options: {
      headers: headersWithToken(),
    },
  });
  const validationSchema = Yup.object().shape({
    circle: Yup.array().of(Yup.string()),
    // .min(1, 'Select at least one circle'),
    // .required('Circle is required')
    district: Yup.array().of(Yup.number()),
    officer: Yup.array().of(Yup.number()),
    taluk: Yup.array().of(Yup.number()),
    natureOfWork: Yup.array().of(Yup.string()),
    religion: Yup.array().of(Yup.string()),
    category: Yup.array().of(Yup.string()),
    isRenewal: Yup.array().of(Yup.number()),
    ageWise: Yup.string().nullable(),
    // .oneOf(['yes', 'no'], 'Invalid renewal status'), // Assuming yes/no options
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });
  function getDataById(options, keys, value, selector = null) {
    if (!options) return;
    return options
      .filter((item) => keys.includes(selector ? item[selector] : item.id))
      .map((item) => item[value]);
  }

  const formik = useFormik({
    initialValues: {
      software: null,
      circle: [],
      district: [],
      taluk: [],
      natureOfWork: [],
      religion: [],
      officer: [],
      category: [],
      isRenewal: [],
      startDate: null,
      endDate: null,
      ageWise: null,
      gender: [],
      fromAge: null,
      toAge: null,
    },
    validationSchema,
    validate: (values) => {
      const errors = {};
      if (values.startDate && !dayjs(values.startDate).isValid()) {
        errors.startDate = 'Enter a valid date';
      }
      if (values.endDate && !dayjs(values.endDate).isValid()) {
        errors.endDate = 'Enter a valid date';
      }
      if (values.startDate && +dayjs(values.startDate).format('YYYY') < 1900) {
        errors.startDate = 'Start Date be Greater than 01/01/1900';
      }
      if (values.endDate && +dayjs(values.endDate).format('YYYY') < 1900) {
        errors.endDate = 'End Date be Greater than 01/01/1900';
      }
      if (values.startDate && dayjs(values.startDate) > dayjs())
        errors.startDate = 'Start Date should not be in the future';
      if (values.endDate && dayjs(values.endDate) > dayjs())
        errors.endDate = 'End Date should not be in the future';
      if (
        values.startDate &&
        values.endDate &&
        dayjs(values.endDate) < dayjs(values.startDate)
      ) {
        console.log('update', values.endDate, values.startDate);
        errors.endDate = 'End Date should be greater than Start Date';
      }
      if (
        formik.touched.ageWise &&
        values.ageWise === 'Yes' &&
        !values.fromAge
      ) {
        if (formik.touched.fromAge) errors.fromAge = 'From Age is required';
      }
      if (formik.touched.ageWise && values.ageWise === 'Yes' && !values.toAge) {
        if (formik.touched.toAge) errors.toAge = 'To Age is required';
      }
      if (formik.touched.toAge && values.toAge < values.fromAge) {
        errors.toAge = 'To Age should be greater than From Age';
      }
      return errors;
    },
    onSubmit: (values) => {
      console.log(values);
      const payload = {
        software: values.software,
        district: getDataById(
          districtData?.data,
          formik.values.district,
          'name',
        )?.join(','),
        taluk: getDataById(talukData?.data, formik.values.taluk, 'name')?.join(
          ',',
        ),
        natureOfWork: getDataById(
          natureOfWorkData?.data,
          formik.values.natureOfWork,
          'short_name',
          'value_id',
        )?.join(','),
        circle: getDataById(
          circleData?.data,
          formik.values.circle,
          'name',
        )?.join(','),
        religion: getDataById(
          religionData?.data,
          formik.values.religion,
          'description',
          'value_id',
        )?.join(','),
        category: getDataById(
          categoryData?.data,
          formik.values?.category,
          'value',
          'value_id',
        ).join(','),
        officer: getDataById(
          officerData?.data,
          formik.values?.officer,
          'username',
          'department_user_id',
        ).join(','),
        // startDate: formik.values?.startDate?.format('YYYY-MM-DD'),
        startDate: values.startDate
          ? dayjs(values.startDate).format('YYYY-MM-DD')
          : null,
        endDate: values.endDate
          ? dayjs(values.endDate).format('YYYY-MM-DD')
          : null,
        ageWise: values.ageWise === 'Yes' ? values.ageWise : null,
        isRenewal: values?.isRenewal,
        gender: values.gender?.join(','),
        fromAge: values.fromAge,
        toAge: values.toAge,
      };
      console.log(payload);
      getMISAPI(payload);
    },
  });
  const {
    data: talukData,
    loading: talukLoading,
    postApiCall: talukAPI,
  } = usePostApi({
    url: `/global/taluks_by_districts`,
    method: 'POST',
  });
  const {
    data: natureOfWorkData,
    loading: natureOfWorkLoading,
    postApiCall: getNatureOfWorkAPI,
  } = usePostApi({
    url: `/global/get_catalog_details`,
    method: 'POST',
  });
  const {
    data: categoryData,
    loading: categoryLoading,
    postApiCall: getCategoryAPI,
  } = usePostApi({
    url: `/global/get_catalog_details`,
    method: 'POST',
  });
  const {
    data: religionData,
    loading: religionLoading,
    postApiCall: getReligionAPI,
  } = usePostApi({
    url: `/global/get_catalog_details`,
    method: 'POST',
  });
  const {
    data: circleData,
    loading: circleLoading,
    postApiCall: getCircleAPI,
  } = usePostApi({
    url: `/config/circle/get_circle_details`,
    method: 'GET',
    options: {
      headers: headersWithToken(),
    },
  });

  useEffect(() => {
    getNatureOfWorkAPI({
      board_id: 1,
      catalog_name: 'Nature of Work',
    });
    getCategoryAPI({
      board_id: 1,
      catalog_name: 'Category',
    });
    getReligionAPI({
      board_id: 1,
      catalog_name: 'Religion',
    });

    getCircleAPI();
    getOfficerAPI({ board_id: 1 });
  }, []);

  useEffect(() => {
    formik.values.taluk = [];
    talukAPI({
      districts: formik.values.district,
    });
  }, [formik.values.district]);

  useEffect(() => {
    if (misData) setIsDownloadAvailable(true);
  }, [misData]);

  useEffect(() => {
    setIsDownloadAvailable(false);
    if (formik.values.circle?.length > 0) {
      getUsersMappedToCircle({ circle: formik.values.circle.join(',') });
    }
    if (formik.values.taluk?.length > 0) {
      getCircleMappedToTaluk({ taluk: formik.values.taluk.join(',') });
    }
  }, [formik.values]);

  const visibleColumns = useMemo(() => {
    if (!misData?.data || misData.data.length === 0) return [];

    // Extract keys from API response (first row)
    const availableFields = Object.keys(misData.data[0]);

    // Filter columns based on API response fields
    const filteredColumns = registrationColumns.filter((col) =>
      availableFields.includes(col.accessorKey),
    );
    return [registrationColumns[0], ...filteredColumns];
  }, [misData]);
  const capitaliseFirstLetter = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => capitaliseFirstLetter(item));
    } else if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].charAt(0).toUpperCase() + obj[key].slice(1);
        } else if (typeof obj[key] === 'object') {
          obj[key] = capitaliseFirstLetter(obj[key]);
        }
      }
    }
  };
  const handleDownload = () => {
    if (misData === null) return;
    // if(data?.data)
    const transformKeys = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key
            .split('_') // Split by underscore
            .map((word, index) =>
              index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word,
            ) // Capitalize first letter of each word
            .join(' '), // Join back with space
          value,
        ]),
      );
    };

    // Transform keys for all objects in the array
    const transformedData = misData.data.map(transformKeys);
    const transformValues = capitaliseFirstLetter(transformedData);
    // const worksheet = XLSX.utils.json_to_sheet(transformValues);
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'array' });

    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'count_registration_renewal_' + dayjs().format('YYYY-MM-DD'));
  };
  const handleReset = () => {
    formik.resetForm();
    formik.setFieldValue('ageWise', null);
  };
  const ageOptions = useMemo(
    () => Array.from({ length: 100 }, (_, i) => i + 1),
    [],
  );

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container gap={2} sx={{ py: 2 }}>
          <Grid item lg={1.8}>
            <FormControl fullWidth>
              {!formik.values.software && (
                <InputLabel
                  shrink={false} // Prevent label from shrinking/moving up
                >
                  Select Application Type
                </InputLabel>
              )}
              <Select
                label="Select Application Type"
                sx={{ border: '1px solid gray' }}
                value={formik.values.software}
                fullWidth
                onChange={(ev) => {
                  formik.setFieldValue('software', ev.target.value);
                }}
              >
                <MenuItem value={null} disabled>
                  <ListItemText primary={'Select Application Type'} />
                </MenuItem>
                <MenuItem value={'ecard'}>E-Card</MenuItem>
                <MenuItem value={'new_software'}>KBOCWWB</MenuItem>
                <MenuItem value={'sevasindhu'}>Seva Sindhu</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={[
                { label: 'Register', value: 0 },
                { label: 'Renewal', value: 1 },
              ]}
              selected={formik.values.isRenewal}
              label="Select Data Type"
              onChange={(ev) => formik.setFieldValue('isRenewal', ev)}
            />
          </Grid>
          <Grid
            lg={1.8}
            xs={12}
            md={4}
            sm={8}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <FormControl variant="filled">
              <DatePicker
                maxDate={dayjs()}
                label="Start Date"
                disableFuture
                value={
                  formik.values.startDate
                    ? dayjs(formik.values.startDate)
                    : null
                }
                onChange={(date) => {
                  formik.setFieldValue(
                    'startDate',
                    date ? date.toDate() : null,
                  );
                }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error:
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate),
                    helperText:
                      formik.touched.startDate && formik.errors.startDate,
                  },
                }}
              />
            </FormControl>
            <ClearButton
              filters={formik.values}
              name={'startDate'}
              handler={() => formik.setFieldValue('startDate', null)}
            />
          </Grid>
          <Grid
            lg={1.8}
            xs={12}
            md={4}
            sm={6}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <FormControl variant="outlined">
              <DatePicker
                label="End Date"
                maxDate={dayjs()}
                disableFuture
                minDate={
                  formik.values.startDate
                    ? dayjs(formik.values.startDate)
                    : undefined
                }
                value={
                  formik.values.endDate ? dayjs(formik.values.endDate) : null
                }
                onChange={(date) => {
                  formik.setFieldValue('endDate', date ? date.toDate() : null);
                }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error:
                      formik.touched.endDate && Boolean(formik.errors.endDate),
                    helperText: formik.touched.endDate && formik.errors.endDate,
                  },
                }}
              />
            </FormControl>
            <ClearButton
              filters={formik.values}
              name={'endDate'}
              handler={() => formik.setFieldValue('endDate', null)}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                districtData?.data?.map((district) => ({
                  label: district.name,
                  value: district.id,
                })) ?? [],
                'label', // Sorting by label alphabetically
              )}
              selected={formik.values.district}
              label="Select District"
              onChange={(value) => {
                console.log(value);
                if (value) {
                  formik.setFieldValue(
                    'district',
                    value.map((district) => district),
                  );
                }
              }}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                talukData?.data?.map((district) => ({
                  label: district.name,
                  value: district.id,
                })) ?? [],
                'label',
              )}
              selected={formik.values.taluk}
              label={talukLoading ? 'loading...' : 'Select Taluk'}
              onChange={useCallback(
                (value) => {
                  console.log(value);
                  if (value) {
                    formik.setFieldValue(
                      'taluk',
                      value.map((district) => district),
                    );
                  }
                },
                [formik], // Ensures function updates when formik reference changes
              )}
              disabled={talukData === null}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                natureOfWorkData?.data?.map((district) => ({
                  label: district.short_name,
                  value: district.value_id,
                })) ?? [],
                'label',
              )}
              selected={formik.values.natureOfWork}
              label={
                natureOfWorkLoading ? 'loading...' : 'Select Nature Of Work'
              }
              onChange={(value) => {
                console.log(value);
                if (value) {
                  formik.setFieldValue(
                    'natureOfWork',
                    value.map((district) => district),
                  );
                }
              }}
              disabled={talukData === null}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                categoryData?.data?.map((district) => ({
                  label: district.short_name ?? district.value,
                  value: district.value_id,
                })) ?? [],
                'label',
              )}
              selected={formik.values.category}
              label={categoryLoading ? 'loading...' : 'Select Category'}
              onChange={(value) => {
                console.log(value);
                if (value) {
                  formik.setFieldValue(
                    'category',
                    value.map((district) => district),
                  );
                }
              }}
              disabled={talukData === null}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                religionData?.data?.map((district) => ({
                  label: district.short_name ?? district.description,
                  value: district.value_id,
                })) ?? [],
                'label',
              )}
              selected={formik.values.religion}
              label={religionLoading ? 'loading ...' : 'Select Religion'}
              onChange={(value) => {
                console.log(value);
                if (value) {
                  formik.setFieldValue(
                    'religion',
                    value.map((district) => district),
                  );
                }
              }}
              disabled={talukData === null}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                (circleMappingData?.data?.length > 0
                  ? circleData?.data?.filter((user) =>
                      circleMappingData?.data?.includes(user.id),
                    )
                  : circleData?.data
                )?.map((deptUser) => ({
                  label: deptUser.name ?? deptUser.description,
                  value: deptUser.id,
                })),
                'label',
              )}
              selected={formik.values.circle}
              label={circleLoading ? 'loading..' : 'Select Circle'}
              onChange={(value) => {
                console.log(value);
                if (value) {
                  formik.setFieldValue(
                    'circle',
                    value.map((district) => district),
                  );
                }
              }}
            />
          </Grid>
          <Grid item lg={1.8}>
            <MultiSelectAll
              options={sortByName(
                (deptUsersData?.data?.length > 0
                  ? officerData?.data?.filter((user) =>
                      deptUsersData?.data?.includes(user.department_user_id),
                    )
                  : officerData?.data
                )?.map((deptUser) => ({
                  label: deptUser.username,
                  value: deptUser.department_user_id,
                })),
                'label',
              )}
              selected={formik.values.officer}
              label={officerApiLoading ? 'loading..' : 'Select Officer'}
              onChange={useCallback(
                (value) => {
                  console.log(value);
                  if (value) {
                    formik.setFieldValue(
                      'officer',
                      value.map((district) => district),
                    );
                  }
                },
                [formik],
              )}
            />
          </Grid>

          <Grid item lg={1.8}>
            <MultiSelectAll
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' },
              ]}
              selected={formik.values?.gender}
              label="Select Gender Type"
              onChange={(ev) => formik.setFieldValue('gender', ev)}
            />
          </Grid>
          <Grid item lg={1.8}>
            <FormControl fullWidth className="formcontrol5">
              {!formik.values.ageWise && (
                <InputLabel
                  shrink={false} // Prevent label from shrinking/moving up
                >
                  Select Age Wise
                </InputLabel>
              )}
              <Select
                label="Select Application Type"
                className="select-marital w-100"
                style={{ border: '1px solid grey' }}
                onChange={(ev) =>
                  formik.setFieldValue('ageWise', ev.target.value)
                }
                value={formik.values.ageWise || null}
              >
                <MenuItem value={null} disabled>
                  <ListItemText primary={'Select Age Wise'} />
                </MenuItem>
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formik.values.ageWise === 'Yes' && (
            <Grid style={{ display: 'flex', gap: '20px' }}>
              {/* From Age Dropdown */}
              <FormControl style={{ minWidth: 120 }}>
                {!formik.values.fromAge && <InputLabel>From Age</InputLabel>}
                <Select
                  sx={{ border: '1px solid gray' }}
                  value={formik.values.fromAge}
                  onChange={(e) => {
                    formik.setFieldValue('toDate', null);
                    formik.setFieldValue('fromAge', e.target.value);
                  }}
                >
                  {ageOptions.map((age) => (
                    <MenuItem key={age} value={age}>
                      {age}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors?.fromAge && (
                  <p
                    style={{
                      color: 'hsl(0, 64.40%, 44.10%)',
                      fontSize: '0.875rem',
                      marginTop: 4,
                    }}
                  >
                    {formik.errors?.fromAge}
                  </p>
                )}
              </FormControl>

              {/* To Age Dropdown */}
              <FormControl style={{ minWidth: 120 }}>
                {!formik.values.toAge && <InputLabel>To Age</InputLabel>}
                <Select
                  sx={{ border: '1px solid gray' }}
                  value={formik.values.toAge}
                  onChange={(e) =>
                    formik.setFieldValue('toAge', e.target.value)
                  }
                >
                  {ageOptions
                    .filter(
                      (age) =>
                        !formik.values.fromAge || age >= formik.values.fromAge,
                    ) // Ensures To Age is greater than From Age
                    .map((age) => (
                      <MenuItem key={age} value={age}>
                        {age}
                      </MenuItem>
                    ))}
                </Select>
                {formik.errors?.toAge && (
                  <p
                    style={{
                      color: 'hsl(0, 64.40%, 44.10%)',
                      fontSize: '0.875rem',
                      marginTop: 4,
                    }}
                  >
                    {formik.errors?.toAge}
                  </p>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid
            lg={2.5}
            gap={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={
                misLoading ||
                talukLoading ||
                circleLoading ||
                natureOfWorkLoading ||
                districtLoading
              }
            >
              Search
            </Button>
            <Button
              type="reset"
              variant="contained"
              color="error"
              onClick={handleReset}
            >
              Reset
            </Button>
            {misData?.data?.length > 0 &&
              isDownloadAvailable &&
              !misLoading && (
                <Button onClick={handleDownload} variant="contained">
                  Download
                </Button>
              )}
          </Grid>
        </Grid>
      </form>
      <Box mt={2}>
        {misLoading && <CircularLoader />}
        {misData?.data && !misLoading && (
          <TableRoot
            columns={visibleColumns}
            data={misData?.data}
            pageSize={pageSize}
            cellStyles={{
              paddingBlock: '0.5rem',
              border: '1px solid gray',
              textAlign: 'center',
            }}
            tableStyle={{ width: '100%' }}
          />
        )}
      </Box>
    </Box>
  );
}
